import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import Images from "../../Themes/ImageConstant";
import { Row, Select } from "antd";
import { Modal } from "antd";
import { Tabs } from "antd";
import { Switch } from "antd";
import { Spin } from "antd";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  getCompaniesDetailsAction,
  getCountryListAction,
  updateCountriesData,
} from "../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import {
  companiesIdSearchListAction,
  companiesWithIncidenAction,
  esgCategoryAction,
  esgFactorsAction,
  esgIotAction,
  esgPaiAction,
  esgSdgAction,
  getCategoriesAction,
  getCompaniesAction,
  getIndustryAction,
  getTopSearchAction,
  homePageAction,
  incidentMapAction,
  incindentAction,
  locationIdSearchListAction,
  searchByParamsAction,
  updateCategoriesData,
  updateIncidentData,
} from "../../Redux/Action/incidents";
import {
  getWishListDetailAction,
  getWishlistAction,
} from "../../Redux/Action/watchListActions";

import CheckBox from "../../Componets/checkBox";
import { useNavigate } from "react-router-dom";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";
import { debounce } from "../../Utils";

const selectedSearchArrayItem = [
  { groupName: "Top Searches", selectedItemLength: 0 },
  { groupName: "Company", selectedItemLength: 0 },
  { groupName: "Incident Location", selectedItemLength: 0 },
  { groupName: "Categories", selectedItemLength: 0 },
  { groupName: "Industry", selectedItemLength: 0 },
  { groupName: "Health Care", selectedItemLength: 0 },
  { groupName: "Extractives & Minerals Processing", selectedItemLength: 0 },
  { groupName: "Esg Factors", selectedItemLength: 0 },
  { groupName: "Esg Sdgs", selectedItemLength: 0 },
  { groupName: "Esg Pais", selectedItemLength: 0 },
];
let countries = [];
const TopHeader = (props, ref) => {
  const { showSearch, setShowSearch, advanceSearchState } = props;
  const navigate = useNavigate();
  const [selectedSearchItems, setSelectedSearchItems] = useState(
    selectedSearchArrayItem
  );

  const [isOpen, setIsOpen] = useState(false);
  const [searchChips, setSearchChips] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const _companiesList = useSelector(
    (state) => state?.incidentReducer?.companiesList
  );
  const [companiesList, setCompaniesList] = useState(_companiesList);

  useEffect(() => {
    let count = 0;
    selectedSearchItems?.forEach((val) => {
      if (val.selectedItemLength > 0) {
        count += val.selectedItemLength;
      }
    });
    if (count == 0) {
      setSearchChips(false);
    } else {
      setSearchChips(true);
    }
  }, [selectedSearchItems]);

  const watchListdetails = useSelector(
    (state) => state?.watchListReducer?.watchListDetails
  );
  const searchData = useSelector(
    (state) => state?.incidentReducer?.searchByParams
  );

  const isLoading = useSelector((state) => state?.incidentReducer?.isLoading);

  // model popup....................
  const [modal2Open, setModal2Open] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [openDrop, setOpenDrop] = useState(false);
  const [search, setSearch] = useState("");
  const [companiesIdSearch, setcompanyIdSearchList] = useState([]);
  const [locationIdSearch, setLocationIdSearch] = useState([]);
  const [esg_Id_factors, setEsgIdFactors] = useState([]);
  const [esg_Id_sdgs, setEgsIdSdgs] = useState([]);
  const [esg_Id_pais, setEsgIdPais] = useState([]);
  const locId = [];

  const [isSearched, setIsSearched] = useState(false);

  const _countryList = useSelector(
    (state) => state?.companyPageReducer?.allData
  );

  const activeCountries = useSelector(
    (state) => state?.companyPageReducer?.activeCountries
  );

  const [selectedWatchlist, setSelectedWatchList] = useState({
    name: watchListdetails?.name ? watchListdetails.name : "Select Watchlist",
    val: watchListdetails ? 112 : 0,
  });

  const [countryList, setCountryList] = useState(_countryList);

  const topSearch = useSelector((state) => state?.incidentReducer?.topSearch);
  const [TopSearch, setTopSearch] = useState(topSearch);

  const _categoriesList = useSelector(
    (state) => state?.incidentReducer?.getCategories
  );

  const _categoriesListStatic = useSelector(
    (state) => state?.incidentReducer?._categoriesListStatic
  );

  const [activeCategoriesItemNumber, setActiveCategoriesItemNumber] =
    useState(0);

  const { companyIdSearchList, locationIdSearchList } = useSelector(
    (state) => state?.incidentReducer
  );

  const _industryList = useSelector(
    (state) => state?.incidentReducer?.industryList
  );

  const industryListStatic = useSelector(
    (state) => state?.incidentReducer?.industryListStatic
  );
  const selectedArray = useSelector(
    (state) => state?.incidentReducer?.companies
  );

  const [isServerIncident, setIsServerIncident] = useState(false);
  const [industryList, setIndustryList] = useState(
    _industryList?.length > 0 ? [..._industryList] : []
  );
  const [categoriesList, setCategoriesList] = useState([..._categoriesList]);

  const handleDropdownVisibleChange = (open) => {
    setIsOpen(open);
    setOpenDrop(!openDrop);
  };

  useEffect(() => {
    if (companyIdSearchList?.length === 0) {
      props.selectedCountry([]);
    }
  }, [companyIdSearchList]);

  const onChange = () => {
    setIsServerIncident(!isServerIncident);
  };

  const [lableChip, setLableChip] = useState({
    comapany: false,
    location: false,
    categoires: false,
  });

  let networkData = {
    start: props.start,
    end: props.endDate,
    companies: watchListdetails
      ? watchListdetails?.companies
        ?.filter((i) => {
          return i.id;
        })
        .map((i) => i.id)
      : selectedArray,
    continent_codes: [],
    countries: activeCountries,
    esg_factors:
      esg_Id_factors?.length > 0
        ? esg_Id_factors
        : watchListdetails
          ? watchListdetails?.esg_factors
            ?.filter((i) => {
              return i.id;
            })
            .map((i) => i._id)
          : [],
    esg_SDG:
      esg_Id_sdgs?.length > 0
        ? esg_Id_sdgs
        : watchListdetails
          ? watchListdetails?.esg_pais
            ?.filter((i) => {
              return i.id;
            })
            .map((i) => i._id)
          : [],
    esg_PAI:
      esg_Id_pais?.length > 0
        ? esg_Id_pais
        : watchListdetails
          ? watchListdetails?.esg_sdgs

            ?.filter((i) => {
              return i.id;
            })
            .map((i) => i._id)
          : [],
    industries: watchListdetails
      ? watchListdetails?.industries
        ?.filter((i) => {
          return i.id;
        })
        .map((i) => i._id)
      : [],
    search: searchVal,
    location:
      locationIdSearchList?.length > 0
        ? locationIdSearchList
        : watchListdetails
          ? watchListdetails?.locations
            ?.filter((i) => {
              return i._id;
            })
            .map((i) => i._id)
          : [],
  };

  const modalContentRef = useRef(null);

  const mergedData = {
    ...networkData,
    countries: countries
      ?.filter((i) => {
        if (i.status) {
          return i.name;
        }
      })
      ?.map((i) => i.name),
    companies:
      companyIdSearchList?.length > 0
        ? companyIdSearchList
        : companiesList
          ?.filter((i) => {
            if (i.status) {
              return i.name;
            }
          })
          ?.map((i) => i.id),
    industries: industryList
      ?.filter((e) => e.childs)
      ?.filter((i) => {
        if (i.status) {
          return i.id;
        }
      })
      ?.map((i) => i.id),
  };

  const resetData = () => {
    let resetDataObject = {
      ...topHeaderAPIObject,
      start: props.start,
      end: props.endDate,
    };

    setLableChip({
      comapany: false,
      location: false,
      categoires: false,
    });
    setIsSearched(false);
    //Redux Action
    dispatch(companiesIdSearchListAction([]));
    dispatch(locationIdSearchListAction([]));

    //Component State
    setcompanyIdSearchList(locId);
    setLocationIdSearch(locId);
    setEsgIdFactors(locId);
    setEgsIdSdgs(locId);
    setEsgIdPais(locId);
    dispatch(incidentMapAction({ ...resetDataObject, type: "home" }));
    dispatch(homePageAction(resetDataObject));
    dispatch(incindentAction({ ...resetDataObject, limt: 4, offset: 0 }));
    dispatch(companiesWithIncidenAction(resetDataObject));
    dispatch(esgFactorsAction(resetDataObject));
    dispatch(esgSdgAction(resetDataObject));
    dispatch(esgPaiAction(resetDataObject));
    dispatch(esgIotAction(resetDataObject));
    dispatch(esgCategoryAction(resetDataObject));
  };

  const handleNetworkCall = (data) => {
    const API_object = { ...topHeaderAPIObject };
    if (data === 1) {
      let networkData = { ...topHeaderAPIObject };
      networkData["start"] = props.start;
      networkData["end"] = props.endDate;
      dispatch(homePageAction(networkData));
      dispatch(incindentAction({ ...networkData, limt: 4, offset: 0 }));
      dispatch(companiesWithIncidenAction(networkData));
      dispatch(esgFactorsAction(networkData));
      dispatch(esgSdgAction(networkData));
      dispatch(esgPaiAction(networkData));
      dispatch(esgIotAction(networkData));
      dispatch(esgCategoryAction(networkData));
    } else if (data === 2) {
      props.selectedCountry(companyIdSearchList ? companyIdSearchList : []);
      let networkData = {
        ...topHeaderAPIObject,
        start: props.start,
        end: props.endDate,
        companies: companyIdSearchList ? companyIdSearchList : [],
        esg_factors: esg_Id_factors ? esg_Id_factors : [],
        esg_SDG: esg_Id_sdgs ? esg_Id_sdgs : [],
        esg_PAI: esg_Id_pais ? esg_Id_pais : [],
        locations: locationIdSearchList ? locationIdSearchList : [],
      };

      dispatch(incidentMapAction({ ...networkData, type: "home" }));
      dispatch(homePageAction(networkData));
      dispatch(incindentAction({ ...networkData, limt: 4, offset: 0 }));
      dispatch(companiesWithIncidenAction(networkData));
      dispatch(esgFactorsAction(networkData));
      dispatch(esgSdgAction(networkData));
      dispatch(esgPaiAction(networkData));
      dispatch(esgIotAction(networkData));
      dispatch(esgCategoryAction(networkData));
    } else if (data == 3) {
      dispatch(homePageAction(mergedData));
      dispatch(incindentAction({ ...mergedData, limt: 4, offset: 0 }));
      dispatch(companiesWithIncidenAction(mergedData));
      dispatch(esgFactorsAction(mergedData));
      dispatch(esgSdgAction(mergedData));
      dispatch(esgPaiAction(mergedData));
      dispatch(esgIotAction(mergedData));
      dispatch(esgCategoryAction(mergedData));
    }
  };

  const updateCountryData = ({ continentsIndex, CountryIndex, type }) => {
    dispatch(updateCountriesData({ continentsIndex, CountryIndex, type }));
  };

  useEffect(() => {
    dispatch(getTopSearchAction());
    dispatch(getCompaniesAction());
    dispatch(getIndustryAction());
    dispatch(getCountryListAction());
    dispatch(getWishlistAction());
  }, []);

  useEffect(() => {
    if (watchListdetails?.length > 0) {
      dispatch(homePageAction(networkData));
      dispatch(
        incindentAction({
          ...networkData,
          limt: 4,
          offset: 0,
        })
      );
      dispatch(companiesWithIncidenAction(networkData));
      dispatch(esgFactorsAction(networkData));
      dispatch(esgSdgAction(networkData));
      dispatch(esgPaiAction(networkData));
      dispatch(esgIotAction(networkData));
      dispatch(esgCategoryAction(networkData));
    }
  }, []);

  useEffect(() => {
    let continents = _countryList?.continents?.filter((val) =>
      val.countries?.filter((e) => e.status)
    );
    countries = continents?.map((val) => val.countries)?.flat();
    let selectedItemLength = countries?.filter((val) => val.status);

    let groupName = "Incident Location";

    updateSelectSearchArray(groupName, selectedItemLength?.length);
  }, [_countryList]);

  const updateSelectSearchArray = (groupName, selectedItem) => {
    let updateSearchSelectedItemArray = [...selectedSearchItems];
    updateSearchSelectedItemArray = updateSearchSelectedItemArray.map((val) =>
      val.groupName === groupName
        ? { ...val, selectedItemLength: selectedItem }
        : val
    );

    setSelectedSearchItems(updateSearchSelectedItemArray);
  };

  const handleCompanySearch = (e, itemId) => {
    if (companyIdSearchList.includes(itemId)) {
      let deleteId = companyIdSearchList;
      deleteId = deleteId?.filter((id) => id !== itemId);
      dispatch(companiesIdSearchListAction(deleteId));
    } else {
      let insertId = companyIdSearchList;
      insertId?.push(itemId);
      dispatch(companiesIdSearchListAction(insertId));
    }
    e.stopPropagation();
  };

  const removeAllVal = (data) => {
    let allSelectedItemsZero = false;

    for (let i = 0; i < data?.length; i++) {
      if (data[i].selectedItemLength == 0) {
        allSelectedItemsZero = true;
        break;
      }
    }
    if (allSelectedItemsZero) {
      handleNetworkCall(1);
    }
  };

  const removeSearchSelectedItem = (groupName) => {
    removeAllVal(selectedSearchItems);
    switch (groupName) {
      case "Company":
        for (const val of companiesList) {
          if (!val?.status) {
            break;
          }
          val.status = false;
        }
        updateSelectSearchArray("Company", 0);
        break;
      case "Top Searches":
        topSearch?.forEach((search) => {
          search?.data?.forEach((data) => (data.status = false));
        });
        updateSelectSearchArray("Top Searches", 0);
        break;
      case "Incident Location":
        let newRecord = countryList;
        newRecord?.continents?.forEach((continent) => {
          continent.continent_status = false;
          continent?.countries?.forEach((country) => (country.status = false));
        });
        newRecord.allStatus = false;
        setCountryList(newRecord);
        updateSelectSearchArray("Incident Location", 0);

        break;
      case "Health Care":
      case "Financials":
      case "Extractives & Minerals Processing":
        let clone = [...industryList];
        let index = clone.findIndex((item) => item.name === groupName);
        clone[index]?.childs?.forEach((child) => { });
        // dispatch(updateIncidentData(clone));
        updateSelectSearchArray(groupName, 0);
        break;
      case "Esg Factors":
      case "Esg Sdgs":
      case "Esg Pais":
        let clon = [..._categoriesList];
        let inde = clon.findIndex((item) => item.name === groupName);
        _categoriesList[inde]["status"] = false;
        let valueArray = _categoriesList[inde]["value"];
        for (let i = 0; i <= valueArray?.length - 1; i++) {
          let dataArrya = valueArray[i]["data"];
          for (let j = 0; j <= dataArrya?.length - 1; j++) {
            dataArrya[j]["status"] = false;
          }
        }
        // dispatch(updateCategoriesData(clon));
        updateSelectSearchArray(groupName, 0);
        break;
      default:
        break;
    }
  };

  const selectArrowIcon = isOpen ? (
    <img src={Images.upArrowList} />
  ) : (
    <img src={Images.downArrowList} />
  );

  const advanceSearchCancel = () => {
    removeSearchSelectedItem("Company");
    removeSearchSelectedItem("Top Searches");
    removeSearchSelectedItem("Incident Location");
    removeSearchSelectedItem("Health Care");
    removeSearchSelectedItem("Financials");
    removeSearchSelectedItem("Extractives & Minerals Processing");
    removeSearchSelectedItem("Esg Factors");
    removeSearchSelectedItem("Esg Sdgs");
    removeSearchSelectedItem("Esg Pais");
    setSelectedSearchItems(selectedSearchArrayItem);
  };

  useEffect(() => {
    if (showSearch === "") {
      setShowSearch(false);
    }
  }, [showSearch]);

  useImperativeHandle(ref, () => ({
    handleSearch,
  }));
  const handleSearch = () => {
    if (
      companyIdSearchList?.length === 0 &&
      locationIdSearchList?.length === 0 &&
      esg_Id_factors?.length === 0 &&
      esg_Id_sdgs?.length === 0 &&
      esg_Id_pais?.length === 0
    ) {
      setSearchVal("");
      setShowSearch(!showSearch);
    } else {
      if (searchVal != "") {
        setSearchVal("");
        setIsSearched(true);
        if (companyIdSearchList?.length > 0) {
          setLableChip((prevLabelChip) => ({
            ...prevLabelChip,
            comapany: true,
          }));
        }
        if (locationIdSearchList?.length > 0) {
          setLableChip((prevLabelChip) => ({
            ...prevLabelChip,
            location: true,
          }));
        }
        if (
          esg_Id_factors?.length > 0 ||
          esg_Id_sdgs?.length > 0 ||
          esg_Id_pais?.length > 0
        ) {
          setLableChip((prevLabelChip) => ({
            ...prevLabelChip,
            categoires: true,
          }));
        }
        handleNetworkCall(2);
        setShowSearch(!showSearch);
      }
    }
  };
  console.log(searchData, "searchData");
  const searchComponent = () => {
    return (
      <>
        <div class="search-container">
          <form className="example">
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  minWidth: 0,
                  flexDirection: "row",
                }}
              >
                <input
                  type="text"
                  onstart
                  autoComplete="off"
                  placeholder="Search for companies, locations and keywords related to incidents "
                  name="search2"
                  value={searchVal}
                  onChange={(e) => {
                    setSearchVal(e.target.value);
                    setShowSearch(true);
                    if (e.target.value == "") {
                      setShowSearch(false);
                    }
                    debounce(() => dispatch(searchByParamsAction(e?.target?.value), 500))
                  }}

                />
                <div
                  className="topheader-h1-hv"
                  onClick={() => {
                    if (searchVal != "") {
                      setSearchVal("");
                      setIsSearched(true);
                      if (companyIdSearchList?.length > 0) {
                        setLableChip((prevLabelChip) => ({
                          ...prevLabelChip,
                          comapany: true,
                        }));
                      }
                      if (locationIdSearch?.length > 0) {
                        setLableChip((prevLabelChip) => ({
                          ...prevLabelChip,
                          location: true,
                        }));
                      }
                      if (
                        esg_Id_factors?.length > 0 ||
                        esg_Id_sdgs?.length > 0 ||
                        esg_Id_pais?.length > 0
                      ) {
                        setLableChip((prevLabelChip) => ({
                          ...prevLabelChip,
                          categoires: true,
                        }));
                      }
                      handleNetworkCall(2);
                      setShowSearch(!showSearch);
                    }
                  }}
                >
                  {!isLoading ? (
                    <img src={Images.search} />
                  ) : (
                    <div>
                      <Spin />
                    </div>
                  )}
                </div>
              </div>
              {showSearch > 0 ? (
                <div className="search-modal-dgn-hv">
                  {!isLoading && (searchData?.companies?.length > 0 || searchData?.locations?.length > 0) ?
                    <div>
                      {searchData?.companies?.map((item, index) => {
                        return (
                          <Row
                            style={{ padding: 12 }}
                            align={"middle"}
                            justify={"space-between"}
                            key={index}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                onClick={(e) => {
                                  setSearchVal("");
                                  navigate("/searchdashboardpage", {
                                    state: { mergedData: { companies: [item.id] }, selectedTile: { companies: [item] } },
                                  });
                                  // window.location.reload();
                                  // handleSearch();
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  style={{ height: "24px", width: "24px", objectFit: "contain" }}
                                  src={`https://logos-svg.komo.systems/${item?.id}.png`}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = Images.hotel
                                  }}
                                />

                                <div
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                >
                                  {item?.name}
                                </div>
                              </div>
                            </div>
                          </Row>
                        );
                      })}
                      {searchData?.locations?.map((item, index) => {
                        return (
                          <Row
                            style={{ padding: 12 }}
                            align={"middle"}
                            justify={"space-between"}
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                onClick={(e) => {
                                  setSearchVal("");
                                  navigate("/searchdashboardpage", {
                                    state: {
                                      mergedData: { locations: [item.name] },
                                      selectedTile: { locations: [item] }

                                    },
                                  });
                                  // window.location.reload();
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  style={{ height: "24px", width: "24px" }}
                                  src={Images.locationIcon}
                                />
                                <div style={{ marginLeft: "5px" }}>
                                  {item?.name}
                                </div>
                              </div>
                            </div>
                          </Row>
                        );
                      })}
                    </div> : isLoading ?
                      <div
                        style={{
                          height: "20px",
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="social-media-loader">
                          <div className="loader ">
                            <div className="circle" id="circleE"></div>
                            <div className="circle" id="circleS"></div>
                            <div className="circle" id="circleG"></div>
                          </div>
                        </div>
                      </div> : !isLoading && searchData?.companies?.length == 0 && searchData?.locations?.length == 0 ?
                        <div
                          style={{
                            height: "20px",
                            marginTop: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Data Not Found
                        </div>
                        : null
                  }
                  {/* {searchData?.esg_factors?.map((item, index) => {
                    return (
                      <Row
                        style={{ padding: 12 }}
                        align={"middle"}
                        justify={"space-between"}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            onClick={(e) => {
                              setSearchVal("");
                              navigate("/searchdashboardpage", {
                                state: {
                                  mergedData: { esg_factors: [item.id] },
                                },
                              });
                              // window.location.reload();
                              // handleSearch();
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              style={{ height: "24px", width: "24px" }}
                              src={Images.graygrid}
                            />
                            <div style={{ marginLeft: "5px" }}>
                              {item?.name}
                            </div>
                          </div>
                        </div>
                      </Row>
                    );
                  })}
                  {searchData?.industries?.map((item, index) => {
                    return (
                      <Row
                        style={{ padding: 12 }}
                        align={"middle"}
                        justify={"space-between"}
                        key={index}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            onClick={(e) => {
                              setSearchVal("");
                              navigate("/searchdashboardpage", {
                                state: { mergedData: { industry: [item.id] } },
                              });
                              // window.location.reload();
                              // handleSearch();
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              style={{ height: "24px", width: "24px" }}
                              src={Images.hotel}
                              alt="Hotel Icon"
                            />
                            <div
                              style={{
                                marginLeft: "5px",
                              }}
                            >
                              {item?.name}
                            </div>
                          </div>
                        </div>
                      </Row>
                    );
                  })} */}
                </div>
              ) : null}
            </div>
          </form>
        </div>

        <div className="adv-sech-btn-top">
          <button
            type="text"
            onClick={() => {
              advanceSearchState[1](true);
              setIsSearched(false);
              setSelectedWatchList({ ...selectedWatchlist, val: 0 });
            }}
          >
            Advanced Search
          </button>
        </div>
      </>
    );
  };

  return (
    <div className={!props.scrolled ? "top-header-sec" : "top-sticky-header"}>
      {!props.scrolled ? (
        <div className={"top-bag-image"}>
          <div className="container">
            <div className="global-inner-sec">
              <div>
                <h3>Global ESG Analysis</h3>
                <p>
                  Search for any ESG related topic, category, company and
                  location on any specific dates to explore in all related
                  incidents
                </p>
              </div>
              <div className="adv-search-row mt-lg-3 mt-sm-1">
                {searchComponent()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={
              !props.scrolled ? "top-bag-image" : "sticky-Header adv-search-row"
            }
          >
            <div
              className="container adv-search-row"
              style={{ padding: "1vw 0 1vw 0" }}
            >
              {searchComponent()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default forwardRef(TopHeader);
