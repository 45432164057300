import { Box, Typography } from "@mui/material";

function NodeSelectorItem({ label, sx, onClick }) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: "8px", ...sx }}
      onClick={onClick}
    >
      <Typography variant={"body"}>{label}</Typography>
    </Box>
  );
}

export default NodeSelectorItem;
