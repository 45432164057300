const selectedItemHandler = (prevScreenData, modifiedArray, checkWithName) => {
  for (let i = 0; i <= prevScreenData?.length - 1; i++) {
    for (let j = 0; j <= modifiedArray?.length - 1; j++) {
      if (checkWithName) {
        if (prevScreenData[i] == modifiedArray[j]?.name) {
          modifiedArray[j].status = true;
        }
      } else {
        if (prevScreenData[i] == modifiedArray[j]?.id) {
          modifiedArray[j].status = true;
        }
      }
    }
  }
  return modifiedArray;
};

const hitSelectItemsFinder = () => {};

export { selectedItemHandler };
