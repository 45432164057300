import React, { useEffect, useState } from "react";
import { ChartCard } from "../../../../Layouts/ChartCard";
import { HML, HMN, CHMM } from "../../../../Utils";
import IncidentSeverityBar from "./incidentSeverityBar";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import { incidentSeverityAction } from "../../../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import IncidentDistribution from "./IncidentDistributon";
import { Modal } from "antd";
import { topHeaderAPIObject } from "../../../../Assets/dummyData";
import BubleIncidentsList from "../../../bubleIncidentsList";
import moment from "moment";
import Images from "../../../../Themes/ImageConstant";
import IncidentsListModal from "../../../IncidentsListModal";

const IncidentSeverity = (networkData) => {
  const [showModal, setShowModal] = useState(false);
  const [specificIncidentIds, setSpecificIncidentIds] = useState([]);

  useEffect(() => {
    dispatch(incidentSeverityAction(networkData));
  }, [networkData.dateChange]);

  const { incidentSeverityBarData, incidentSeverityPieData } = useSelector(
    (state) => state?.companyPageReducer
  );
  const makeidListArray = (data) => {
    let Critical = [];
    let allArr = [];
    let Moderate = [];
    let High = [];
    let Minor = [];

    if (
      data.param.name == "S" ||
      data.param.name == "G" ||
      data.param.name == "E"
    ) {
      const Data =
        incidentSeverityPieData[
          data.param.name == "S"
            ? "Social"
            : data.param.name == "E"
            ? "Environmental"
            : "Governance"
        ];
      Critical = Data["Critical"]?.map((x, i) => x.id);
      Moderate = Data["Moderate"]?.map((x, i) => x.id);
      High = Data["High"]?.map((x, i) => x.id);
      Minor = Data["Minor"]?.map((x, i) => x.id);
      allArr = Critical?.concat(Moderate, High, Minor);

      setSpecificIncidentIds(allArr);
    } else {
      const Data = incidentSeverityPieData[data?.Parent];
      let listArray = Data[data.child]?.map((x, i) => x.id);
      setSpecificIncidentIds(listArray);
    }
    if (!document.querySelector("div.ant-modal-wrap.ant-modal-centered")) {
      // check if any modal not already open
      setShowModal(true);
    }
  };
  return (
    <div>
      <section className="left-text">
        <ChartCard
          title={"Incidents Severity"}
          legend={CHMM}
          showTimeBarOnTop={false}
        >
          <div className="Sentiment-grah-halfWidth">
            <IncidentSeverityBar
              setModalVisible={setShowModal}
              setSpecificIncidentIds={setSpecificIncidentIds}
              data={incidentSeverityBarData}
            />
            <IncidentDistribution
              onClick={makeidListArray}
              data={incidentSeverityPieData}
            />
          </div>
        </ChartCard>
      </section>
      {showModal && (
        <IncidentsListModal
          setModalVisible={setShowModal}
          networkData={{ specificIncidentIds }}
        />
      )}
    </div>
  );
};

export default IncidentSeverity;
