import { isDocumentOrPDF } from "../../Utils";

const InputChange = (e, fileType, uploadFileHadler, popStateHandler) => {
    if (isDocumentOrPDF(e.target.files[0]) == fileType) {
        uploadFileHadler(e.target.files[0])
        popStateHandler(false);
        e.target.value = null
        // document.getElementById("fileupload").value = "";

    } else {
        popStateHandler(false);
        // document.getElementById("fileupload").value = "";
    }
};

const downloadJsonFile = (type) => {
    let jsonData = require("../../Assets/dummyData/portfoSample.json");
    if (type == "portfolio") {
        jsonData = require("../../Assets/dummyData/portfoSample.json");
    } else if (type == "supplyChain") {
        jsonData = require("../../Assets/dummyData/supplyChainSimple.json");
    } else {
        jsonData = require("../../Assets/dummyData/watchListSimple.json");
    }
    const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: "application/json",
    });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "custom.json";
    a.click();
    URL.revokeObjectURL(a.href);
};

export { InputChange, downloadJsonFile }