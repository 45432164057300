import { MenuList, Typography } from "@mui/material";
import { StyledBox } from "./RelationItem.style";
import RelationMenuSkeleton from "./RelationMenuSkeleton";
import EntityItem, { TheAllItem } from "./EntityItem";

function EntityList({
  selectedRelation,
  selectedRelationDirection,
  searchQuery,
  allItemIsChecked,
  handleOnAllItemClick,
  data,
  loading,
}) {
  if (loading) return <RelationMenuSkeleton />;
  return (
    <MenuList sx={{ overflowX: "hidden" }}>
      {Array.isArray(data) && data?.length > 0 ? (
        [
          searchQuery.length === 0 && (
            <TheAllItem
              key="item-the-all"
              allItemIsChecked={allItemIsChecked}
              handleOnAllItemClick={handleOnAllItemClick}
            />
          ),
          data?.map((r) => (
            <EntityItem
              key={`item-${r.id}`}
              entity={r}
              selectedRelation={selectedRelation}
              selectedRelationDirection={selectedRelationDirection}
            />
          )),
        ]
      ) : (
        <StyledBox>
          <Typography variant="captionS" marginLeft="4px">
            No relations found for this entity!
          </Typography>
        </StyledBox>
      )}
    </MenuList>
  );
}
export default EntityList;
