export const ValidationConstant = {
  uploadTooltip: "Click here to upload image.",
  changeTooltip: "Click here to change image.",
  validEmail: "Please enter valid email address.",
  emptyEmail: "Please enter email address.",
  minEmail: "Email address should be at least 20 characters.",
  emptyPass: "Please enter password.",
  validPass: "Please enter valid password.",
  minPass: "New password should be at least 6 characters long.",
  matchPass: "New password and confirm new password should be same.",
  confirmPass: "Please enter confirm password.",
  vaildFirstname: "Please enter first name.",
  vaildLastname: "Please enter last name.",
  reqMobile: "Please enter mobile number.",
  validMobile: "Enter valid mobile number.",
  validatePassword:
    "Password must contain at least 8 characters, including at least 1 number, and includes both lower and uppercase letters and special characters.",
  entrvalidFirstName: "Minimun character should be at least 3.",
  plzEntrOldPass: "Please enter old password.",
  plzEntrNewPass: "Please enter new password.",
  validFirstName: "Please enter valid first name.",
  validLastName: "Please enter valid last name.",
  passwordMustMatch: "Password does not match.",
  passShouldBeSame: "New password and repeat new password should be same.",
};
