import { apiConstants as types } from "../../Constants/apiConstants";
import { ESGCatgory } from "../../Utils";
const initialState = {
  topSearchData: [],
  topSearchDataLoader: false,
  topSearchDataSuccess: false,
  companyData: [],
  renderCompany: [],
  companyDataLoader: false,
  companyDataSuccess: false,
  incidentLocationData: [],
  showCountryList: [],
  incidentLocationDataLoader: false,
  incidentLocationDataSuccess: false,
  categoryData: [],
  updateUN_Global: false,
  categoryDataLoader: false,
  categoryDataSuccess: false,
  industryData: [],
  canUpdateIndustry: false,
  industryDataLoader: false,
  industryDataSuccess: false,
  selectedItems: [],
};

function getCountryData(data) {
  let continentArr = [];
  if (data?.length > 0) {
    for (let i in data) {
      data[i].continent_status = false;
      let countryList = data[i].countries;
      for (let j in countryList) {
        countryList[j].status = false;
      }
    }
    continentArr = data;
  }
  return {
    allStatus: false,
    continents: continentArr,
  };
}

const selectAll = (value, allData, newRecord) => {
  for (let i in allData.continents) {
    newRecord.continents[i].continent_status = value;
    for (let j in allData.continents[i].countries) {
      newRecord.continents[i].countries[j].status = value;
    }
  }
};

const AllCheckLoop = (allData) => {
  let newRecord = allData;
  newRecord.allStatus = !allData?.allStatus;
  selectAll(newRecord.allStatus, allData, newRecord);
  return newRecord;
};

const AllCountriesLoop = (allData, payload) => {
  let newRecord = allData;

  let country = allData.continents[payload.continentsIndex];
  let newContinents = newRecord.continents[payload.continentsIndex];
  newContinents.continent_status = !country.continent_status;
  for (let i in country.countries) {
    newContinents.countries[i].status = !country.countries[i].status;
  }

  // to check is all the continents are check or not
  newRecord.allStatus = newRecord.continents.every(
    (item) => item.continent_status
  );

  return newRecord;
};

const SingleCountryCheck = (allData, payload) => {
  let newRecord = allData;
  let country = allData.continents[payload.continentsIndex];
  let newContinents = newRecord.continents[payload.continentsIndex];

  newContinents.countries[payload.CountryIndex].status =
    !country.countries[payload.CountryIndex].status;

  // to check is all the country are check of continent or not

  newContinents.continent_status = newContinents.countries.every(
    (item) => item.status
  );

  newRecord.allStatus = newRecord.continents.every(
    (item) => item.continent_status
  );

  return newRecord;
};

function getUpdatedAllData(payload, allData) {
  const _allData = allData;
  if (payload.type === "All") {
    return AllCheckLoop(_allData);
  } else if (payload.type === "continent") {
    return AllCountriesLoop(_allData, payload);
  } else if (payload.type === "country") {
    return SingleCountryCheck(_allData, payload);
  }
}

export const advanceSearch = (state = initialState, action) => {
  let { payload, key } = action;
  switch (action.type) {
    case types.GET_ADVNC_TOP_SEARCH_DATA_LOAD:
      return {
        ...state,
        topSearchDataLoader: true,
      };

    case types.GET_ADVNC_TOP_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        topSearchData: payload,
        topSearchDataSuccess: true,
        topSearchDataLoader: false,
      };
    case types.UPDATE_ADVNC_TOP_SEARCH_DATA:
      return {
        ...state,
        topSearchData: payload,
        topSearchDataSuccess: true,
      };

    case types.GET_ADVNC_TOP_SEARCH_DATA_FAILURE:
      return {
        ...state,
        topSearchDataLoader: false,
      };

    case types.GET_ADVNC_COMPANY_DATA_LOAD:
      return {
        ...state,
        companyDataLoader: true,
      };

    case types.GET_ADVNC_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        companyData: payload,
        companyDataSuccess: true,
        companyDataLoader: false,
      };
    case types.GET_UPDATE_ADVNC_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        companyData: [],
        companyDataSuccess: false,
        companyDataLoader: false,
      };
    case types.UPDATE_ADVNC_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        renderCompany: payload,
      };

    case types.GET_ADVNC_COMPANY_DATA_FAILURE:
      return {
        ...state,
        companyDataLoader: false,
      };

    case types.GET_ADVNC_INC_LOCATION_DATA_LOAD:
      return {
        ...state,
        incidentLocationDataLoader: true,
      };

    case types.GET_ADVNC_INC_LOCATION_DATA_SUCCESS:
      let allcountryData = getCountryData(payload);
      return {
        ...state,
        incidentLocationData: allcountryData,
        incidentLocationDataSuccess: true,
        incidentLocationDataLoader: false,
      };

    case types.UPDATE_ADVNC_INC_LOCATION_DATA_SUCCESS:
      return {
        ...state,
        incidentLocationData: action.payload,
        incidentLocationDataSuccess: true,
        incidentLocationDataLoader: false,
      };

    case types.GET_ADVNC_INC_LOCATION_DATA_FAILURE:
      return {
        ...state,
        incidentLocationDataLoader: false,
      };

    case types.GET_ADVNC_CATEGORY_DATA_LOAD:
      return {
        ...state,
        categoryDataLoader: true,
      };

    case types.GET_ADVNC_CATEGORY_DATA_SUCCESS:
      const categories = Object.keys(payload).map((key) => ({
        name: ESGCatgory[key],
        status: false,
        value: payload[key],
      }));
      categories.push({
        name: "UN global compact principles",
        status: false,
        value: [
          {
            _id: "Human Rights",
            data: [
              {
                id: "PRINCIPLE 1",
                name: "PRINCIPLE 1",
                status: false,
              },
              {
                id: "PRINCIPLE2",
                name: "PRINCIPLE 2",
                status: false,
              },
            ],
          },
          {
            _id: "Labour",
            data: [
              {
                id: "PRINCIPLE 3",
                name: "PRINCIPLE 3",
                status: false,
              },
              {
                id: "PRINCIPLE 4",
                name: "PRINCIPLE 4",
                status: false,
              },
              {
                id: "PRINCIPLE 5",
                name: "PRINCIPLE 5",
                status: false,
              },
              {
                id: "PRINCIPLE 6",
                name: "PRINCIPLE 6",
                status: false,
              },
            ],
          },
          {
            _id: "Environment",
            data: [
              {
                id: "PRINCIPLE 7",
                name: "PRINCIPLE 7",
                status: false,
              },
              {
                id: "PRINCIPLE 8",
                name: "PRINCIPLE 8",
                status: false,
              },
              {
                id: "PRINCIPLE 9",
                name: "PRINCIPLE 9",
                status: false,
              },
            ],
          },
          {
            _id: "Anti-Corruption",
            data: [
              {
                id: "PRINCIPLE 10",
                name: "PRINCIPLE 10",
                status: false,
              },
            ],
          },
        ],
      });
      return {
        ...state,
        categoryData: categories,
        categoryDataSuccess: true,
        categoryDataLoader: false,
        updateUN_Global: true,
      };

    case types.GET_ADVNC_CATEGORY_DATA_FAILURE:
      return {
        ...state,
        categoryDataLoader: false,
      };

    case types.GET_ADVNC_INDUSTRY_DATA_LOAD:
      return {
        ...state,
        industryDataLoader: true,
      };

    case types.GET_ADVNC_INDUSTRY_DATA_SUCCESS:
      return {
        ...state,
        industryData: payload,
        canUpdateIndustry: true,
        industryDataSuccess: true,
        industryDataLoader: false,
      };
    case types.UPDATE_ADVNC_INDUSTRY_DATA_SUCCESS:
      return {
        ...state,
        industryData: payload,
        industryDataSuccess: true,
        canUpdateIndustry: false,
        industryDataLoader: false,
      };

    case types.GET_ADVNC_INDUSTRY_DATA_FAILURE:
      return {
        ...state,
        industryDataLoader: false,
      };

    case types.UPDATE_INCIDENT_LOCATION_DATA:
      let updateAllData = getUpdatedAllData(
        payload,
        JSON.parse(JSON.stringify(state.incidentLocationData))
      );
      state.incidentLocationData = updateAllData;
      return {
        ...state,
        activeCountries: state.showCountryList,
      };

    case types.UPDATE_CATEGORIES_DATA_ADVANCE:
      return {
        ...state,
        categoryData: payload,
        updateUN_Global: false,
      };

    case types.UPDATE_ADVANCE_SEARCH_DATA:
      state[key] = payload;
      return { ...state };

    default:
      return { ...state };
  }
};
