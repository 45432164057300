import { Box, Chip, Typography, useTheme } from "@mui/material";
import { SEVERITY_THRESH_ENUM } from "../../enums/ThresholdEnum";

function getColorFromScore(_score) {
  if (_score < SEVERITY_THRESH_ENUM.LOW) {
    return "#FFBF00";
  }
  if (_score < SEVERITY_THRESH_ENUM.MODERATE) {
    return "#FF7518";
  }
  if (_score < SEVERITY_THRESH_ENUM.HIGH) {
    return "#FF0000";
  }
  return "#800020";
}
function IncidentItem({ title, index, severity, date, id }) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      gap="8px"
      key={title}
      justifyContent="flex-start"
      alignItems="flex-start"
      onClick={() => window.open(`/incidentdetails?${id}`, "_blank")}
    >
      <Chip
        label={index}
        style={{ ...theme.typography.captionS }}
        size="small"
      />
      <Typography variant="captionL" className="downl-btn">
        {title}
        <Typography
          variant="captionL"
          sx={{
            color: getColorFromScore(severity?.score),
            display: "inline",
            marginLeft: "4px",
          }}
        >
          (Severity: {severity?.level})
        </Typography>
        <Typography
          variant="captionL"
          sx={{
            color: theme.palette.gray[400],
            display: "inline",
            marginLeft: "4px",
          }}
        >
          - {date}
        </Typography>
      </Typography>
    </Box>
  );
}
export default IncidentItem;
