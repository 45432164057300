import { apiConstants as types } from "../../Constants/apiConstants";

// coountry list Action
export const getCountryDataforSupplyChain = (data) => ({
  type: types.API_GET_SUPPLYCHAIN_COUNTRY_LIST_LOAD,
  payload: data,
});

export const getGraphViewDataAction = (data) => ({
  type: types.API_GET_GRAPH_DATA_LOAD,
  data,
});

export const supplyChainAction = (data) => ({
  type: types.API_GET_SUPPLY_CHAIN_LOAD,
  data,
});

//get search categories
export const getIndustriesAction = (data) => ({
  type: types.API_GET_INDUSTRIES_LOAD,
  data,
});

export const riskCompanyAction = (data) => ({
  type: types.API_SUPPLY_RISK_COMPANY_LOAD,
  data,
});
export const riskChartAction = (data) => ({
  type: types.API_SUPPLY_RISK_CHART_LOAD,
  data,
});
export const incidentSeverityChartAction = (data) => ({
  type: types.API_INCIDENT_SEVERITY_CHART_LOAD,
  data,
});

export const getSupplyChainDataAction = (data) => ({
  type: types.API_GET_SUPPLY_CHAIN_LIST_LOAD,
  data,
});

export const getSearchHistory = (data) => ({
  type: types.API_GET_SUPPLY_CHAIN_HISTORY_LOAD,
  data,
});

export const clearSearchAction = (data) => ({
  type: types.API_CLEAR_SUPPLY_CHAIN_HISTORY_LOAD,
  data,
});

export const getCompanyCount = (data) => ({
  type: types.GET_COMPANY_COUNT_LOAD,
  data,
});

export const findPathAction = (data) => ({
  type: types.FIND_PATH_LOAD,
  data,
});

export const uploadDataAction = (data) => ({
  type: types.UPLOAD_DATA_LOAD,
  data,
});

export const deleteUploadedDataLoad = (data) => ({
  type: types.DELET_UPLOADED_DATA_LOAD,
  data,
});

export const deleteUploadedDataSuccess = (data) => ({
  type: types.DELET_UPLOADED_DATA_SUCCESS,
  data,
});

export const deleteUploadedDataFail = (data) => ({
  type: types.DELET_UPLOADED_DATA_FAIL,
  data,
});

export const fileUploadSupplyChainAction = (data) => ({
  type: types.SUPPLY_CHAIN_UPLOAD_FILE_LOAD,
  payload: data,
})