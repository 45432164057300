import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../Themes/ImageConstant";
import { openIncidentDetail } from "../../Utils/globalFunction";
import { useCallback, useEffect } from "react";
import {
	calculateCompanyRisk,
	checkRiskColor,
	getRiskVal,
} from "../../Utils";
import { Select, Table, Tooltip } from "antd";


const CompaniesTable = (props) => {
	const { payloadState, setPayloadState, loading } = props;
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const companiesListForModal = useSelector(
		(state) => state?.incidentReducer?.companiesListForModal
	);
	const countriesListForModal = useSelector(
		(state) => state?.incidentReducer?.countriesListForModal
	);
	const esgChildListForModal = useSelector(
		(state) => state?.incidentReducer?.esgChildListForModal
	);

	const onNameClick = (item) => {
		openIncidentDetail(item?.id);
	};

	const handleSort = (e, f, sort) => {
		const sortKey = sort.column?.dataIndex == "severity_score" ? "severity_score.value" : sort.column?.dataIndex
		setPayloadState((prev) => ({
			...prev,
			sort: sortKey,
			order: sort.order == "descend" ? -1 : 1,
		}));
	};

	const handleInvolvedCompanyChange = (item) => {
		setPayloadState((prev) => ({ ...prev, companies: item }));
	};

	const handleEsgFactorChange = (item, key = "esg_factors") => {
		setPayloadState((prev) => ({ ...prev, [key]: item }));
	};

	const handleCountriesChange = (item) => {
		setPayloadState((prev) => ({ ...prev, locations: item }));
	};

	useEffect(() => {
	}, [companiesListForModal])

	let series2 = (data) => {
		return [
			{
				data: data,
			},
		];
	};

	const columns = useCallback(
		() => [
			{
				title: "Company",
				// title: "Name",
				dataIndex: "name",
				width: "180px",

				render: (text, item, index) => {
					return (
						<a style={{ display: "flex" }}>
							<span className="col-index">{`${index + 1 + payloadState.offset} `}</span>
							<a
								onClick={() => navigate("/companyPage", { state: { id: item.id } })}
								className="titleText theme-dark-text"
								style={{
									display: "block",
									whiteSpace: "nowrap",
									overflow: "hidden",
									maxLines: 1,
									textOverflow: "ellipsis",
								}}
							>{`${text}`}</a>
						</a>
					);
				},
			},
			{
				title: "Ticker Symbol",
				dataIndex: "tickers",
				className: `company-supplyChain`,
				// ellipsis: true,
				width: "120px",
				render: (text, _, i) => {
					return (
						<div
							style={{
								color: "#07132F",
								fontWeight: 500,
							}}
						>
							{text && text.length > 0 ? text.join(", ") : ""}
						</div>
					);
				},
			},
			{
				title: `Industry`,
				width: "120px",
				dataIndex: "industry",
				render: (industry, item) => {

					return (
						<div style={{
							wordBreak:'break-word',
							display:'flex'
						}} >
							{industry}
						</div>
					);
				},
			},
			{
				title: "ESG Risk",
				dataIndex: "risk",
				sorter: true,
				// ellipsis: true,
				width: "120px",
				render: (_, item) => (
					<div
						style={{
							backgroundColor:
								checkRiskColor[getRiskVal(item?.risk)],
							display: "flex",
							justifyContent: "center",
							height: "40px",
							alignItems: "center",
						}}
					>
						<p
							style={{
								borderRadius: '2px',
								textAlign: 'center',
								padding: '4px 8px',
								color: "#fff",
								fontFamily: 'Inter',
								fontSize: '12px',
								fontStyle: 'normal',
								fontWeight: '500',
								lineHeight: '16px'
							}}
						>{(item?.risk && Math.round(item?.risk)) || "0"}</p>
					</div>
				),

			},
			{
				title: "Portfolio Percentage",
				dataIndex: "percentage",
				key: "incidents",
				width: "120px",
				sorter: true,
				render: (percentage, item) => {
					return (
						<span
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",						}}
						>
							{item.percentage+"%"}
						</span>
					);
				},
			},
			{
				title: "Market value",
				dataIndex: "relation",
				width: "160px",
				key: "relation",
				sorter: true,
				render: (relation) => (
					<div>
						<span
							style={{
								textTransform: "capitalize",
								fontFamily: "inter",
								fontSize: 13,
							}}
						>
							{relation}
						</span>
					</div>
				),
			},
			{
				title: "Returns (1M)",
				dataIndex: "industries",
				key: "industries",
				width: "160px",
				className: `${!props.tableHeaderArray.includes("Industry") ? "displayNone" : ""
					}`,
				render: (industries) => <div>{industries?.name}</div>
			},
			{
				title: "Stock Price",
				dataIndex: "location",
				width: "160px",
				key: "location",
				render: (location) => <div>{<span>{location}</span>}</div>,
			},
		],
		[
			props.tableHeaderArray,
			payloadState,
			esgChildListForModal,
			companiesListForModal,
			countriesListForModal,
		]
	);
	return (
		<Table
			scroll={{
				x: 1200,
				y: 500,
			}}
			onChange={handleSort}
			columns={columns()}
			sortDirections={["ascend", "descend", "ascend"]}
			dataSource={props.incidents || []}
			pagination={false}
			loading={loading}
		/>
	);
};

export default CompaniesTable;
