import { Button, Modal } from "antd";
import React, { useCallback } from "react";
import ConfirmModal from "./confirmModal.component";
import ConfirmModal2 from "./confirmModal2.component";
import ImageConst from "../../Themes/ImageConstant";
import ModalCloseButton from "../modalCloseButton";
import ModalHeader from "../modalHeaderComp";
import { icons } from "antd/es/image/PreviewGroup";
import ConfirmModal3 from "./confirmModal3.component";

/* 
    @Params  title {string} title of modal,
    @Params  visible {boolean} visible/hide the modal,
    @Params  cancel { cancelText: string , onCancel :function },
    @Params  ok { okText: string , onOk :function } ,
    @Params  icon {string} icons at top of modal,
    @Params  description {string} description of modal,
    @Params  type {string} modal | modal2,
*/

const ConfirmationModal = (props) => {
  const { title, visible, cancel, ok, icon, description, itemName, type = "modal", contentCenter, ...other } = props;
  const { cancelText, onCancel } = cancel;
  const { okText, onOK } = ok;
  const RenderChild = useCallback(() => {
    return type === "modal" ? (
      <ConfirmModal {...props} />
    ) : type === "modal3" ? (
      <ConfirmModal3 itemName={itemName} icon={icon} description={description}  />
    ) :
    <ConfirmModal2 itemName={itemName} icon={icon} description={description}  />;
  }, [itemName]);

  return (
    <Modal
      title={<ModalHeader title={title} />}
      className={"modal-style rename-modal-ps rename-modal-ps1 delete-incident-page-modal" + props.className || ""}
      centered
      open={visible}
      closeIcon={<ModalCloseButton />}
      onCancel={onCancel}
      footer={[
        <div className={contentCenter ? "modalButtonContainer1" : "modalButtonContainer"}>
          <Button
            type="button"
            className={`can-btn${type !== "modal1" && " " + "can-del"}`}
            onClick={onCancel}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "36px",
            }}
          >
            {cancelText}
          </Button>
          <Button
            type="button"
            className={`rest-btn del-red ${type == "modal1" && " " + "center-btn"
              }`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "36px",

            }}
            onClick={onOK}
          >
            {okText}
          </Button>
        </div>,
      ]}
    >
      <RenderChild />
    </Modal>
  );
};

export default ConfirmationModal;
