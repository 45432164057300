import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

function SearchIcon() {
  const theme = useTheme();
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6 12H18M12 6V18"
          stroke={theme.palette.gray[200]}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
export default SearchIcon;
