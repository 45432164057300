import { apiConstants as types } from "../../Constants/apiConstants";
export const getCountryListAction = (data) => ({
  type: types.API_GET_COUNTRY_LIST_LOAD,
  data,
});

export const updateCountriesData = ({
  continentsIndex,
  CountryIndex,
  type,
}) => {
  return {
    type: types.API_UPDATE_COUNTRY_LIST,
    payload: { continentsIndex, CountryIndex, type },
  };
};
//get company details

export const getCompaniesDetailsAction = (data) => {
  return {
    type: types.API_GET_COMPANY_DETAIL_LOAD,
    payload: data,
  };
};

export const supplyChainIncidentAction = (data) => {
  return {
    type: types.API_GET_SupplyChain_Incident_LOAD,
    payload: data,
  };
};
export const sentimentAnalysisAction = (data) => {
  return {
    type: types.API_GET_Sentiment_Analysis_LOAD,
    payload: data,
  };
};
export const sentimentTrendAction = (data) => {
  return {
    type: types.API_GET_SentimentTrend_LOAD,
    payload: data,
  };
};

export const sentimentScoreAction = (data) => {
  return {
    type: types.API_GET_SENTIMENTSCORE_LOAD,
    payload: data,
  };
};

export const incidentSeverityAction = (data) => {
  return {
    type: types.API_GET_INCIDENT_SEVERITY_LOAD,
    payload: data,
  };
};

export const incidentWithYearAction = (data) => {
  return {
    type: types.API_GET_INCIDENT_WITHYEAR_LOAD,
    payload: data,
  };
};

export const incidentCategorizationAction = (data) => {
  return {
    type: types.API_GET_INCIDENT_CATEGORIZATION_LOAD,
    payload: data,
  };
};

export const PeerCompaniesIncidentRate = (data) => {
  return {
    type: types.API_GET_PEER_COMPANIES_INCIDENT_RATE_LOAD,
    payload: data,
  };
};

export const mostUsedWordsAction = (data) => {
  return {
    type: types.API_GET_MOST_USED_WORDS_LOAD,
    payload: data,
  };
};

export const SeverityTrendAction = (data) => {
  return {
    type: types.API_GET_SERVITY_TREND_LOAD,
    payload: data,
  };
};

export const IncidentDistributionOverTimeAction = (data) => {
  return {
    type: types.API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_LOAD,
    payload: data,
  };
};
export const IncidentComparisonAction = (data) => {
  return {
    type: types.API_GET_INCIDENT_COMPARISON_LOAD,
    payload: data,
  };
};
export const RemoverIncidentComparisonAction = (data) => {
  return {
    type: types.REMOVE_INCIDENT_COMPARISON,
    payload: data,
  };
};
export const ResetDataAction = (data) => {
  return {
    type: types.RESET_DATA,
    payload: data,
  };
};
export const getSeviarityMap = (data) => {
  return {
    type: types.API_GET_SEVIERTY_ONMAP_LOAD,
    payload: data,
  };
};

export const getIncidentseverityAction = (data) => {
  return {
    type: types.API_GET_SERVERITY_MAP_LOAD,
    payload: data,
  };
};

export const getIncidentTimelineAction = (data) => {
  return {
    type: types.API_GET_INCIDENT_TIMELINE_LOAD,
    payload: data,
  };
};

// NEW
export const getESGRiskScoreAction = (payload) => {
  return {
    type: types.GET_ESG_RISK_SCORE_LOAD,
    payload,
  };
};
export const getRegulatoryFinesPenaltiesAction = (payload) => {
  return {
    type: types.GET_REGULATORY_FINES_PENALTIES_LOAD,
    payload,
  };
};
export const getRegulatoryLegalAction = (payload) => {
  return {
    type: types.GET_REGULATORY_LEGAL_ACTION_LOAD,
    payload,
  };
};
export const getSocialMediaCoveragelAction = (payload) => {
  return {
    type: types.GET_SOCIAL_MEDIA_COVER_LOAD,
    payload,
  };
};
export const ungcpBreachesAction = (payload) => {
  return {
    type: types.GET_UNGCP_BREACHES_LOAD,
    payload,
  };
};

export const getRiskTrendAction = (payload) => {
  return {
    type: types.GET_RISK_TREND_LOAD,
    payload,
  };
};

export const getCompanyPeerRiskAction = (payload) => {
  return {
    type: types.GET_COMPANY_RISK_PEER_LOAD,
    payload,
  };
};



export const getCompanyRiskLineAction = (payload) => {
  return {
    type: types.GET_COMPANY_RISK_LINE_LOAD,
    payload,
  };
};

