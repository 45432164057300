import React, { useLayoutEffect, useRef, useEffect, useState, useMemo, useCallback } from "react";
import style from "./style.module.css";
import * as Yup from "yup";
import clsx from "clsx";
import HeaderComponent from "../portfolio/components/HeaderComponent";
import { Checkbox, AutoComplete, Spin, Input, Table, InputNumber, Select, Tooltip } from "antd";
import ImageConst from "../../Themes/ImageConstant";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import "./index.css"
import {
  saveValidWatchListValidCompanyLoad,
} from "../../Redux/Action/watchListActions";
import { debounce } from "../../Utils";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalSpinner from "../../Utils/GlobalSpinner";
import { fileUploadSupplyChainAction, getCountryDataforSupplyChain } from "../../Redux/Action/supplyChainAction";
import { BottomPagination } from "../portfolio/CreatePortfolio/utils-comp";
import { highlightMatch } from "../../Utils/globalFunction";

let autoCompletePaginationOffset = 0;
let stopApicallForGetingCompanies = false;
let lastSearchString = "";
let checkingCompanyIndex;
let outerWeigth;
const tableDataModificationHandler = (data) =>
  data?.length > 0 ? data?.map((item, index) => ({ ...item, index })) : [];

const emptyObj = {
  "company": "",
  Relation: "",
  index: 0,
  isin: "",
  "Relation sub category": "",
  "Relation type id": "",
  importance: "",
  ticker: "",
};

const relationshipTypeconst = [
  {
    value: 'Supplier',
    dataValue: 'Supplier',
    key: 'Supplier'
  },
  {
    value: 'Customer',
    dataValue: 'Customer',
    key: 'Customer'
  },
  {
    value: 'Partner',
    dataValue: 'Partner',
    key: 'Partner'
  }
];


const empArr = [emptyObj, emptyObj, emptyObj, emptyObj]
const UploadSupplyChain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location || {};
  const prevoiusSelectedCompany = state?.id;
  const invalidCompany = state?.data?.invalidCount || 0;
  const validCompany = state?.data?.validCount || 0;
  const uploadMediaFileData = state?.data?.companies || [];
  // Destructure portfolioId with a default value if state is null
  const [validateFormOnChange] = useState(true);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false)

  const duplicateSetFieldValue = useRef({
    setValue: null,
    value: null,
    errors: null,
    setErrors: null,
  });
  const [initValues] = useState({
    holdings: [
      {
        company: "",
        Relation: "",
        "Relation sub category": "",
        "Relation type id": "",
        importance: "",
      },
    ],
  });
  const portfolioData = useSelector((state) => state.portfolio);
  const { uploadFileSuccess, saveSelectedWatchlistCompany } = useSelector(
    (state) => state?.watchListReducer
  );
  const { countryList, supplyChainFileUploadList } = useSelector((state) => state?.supplyChainReducer);
  const {
    companiesSearchListLoader,
    addPortfolioLoading,
    inValidHoldinggList,
  } = portfolioData;

  useLayoutEffect(() => {
    if (uploadMediaFileData && uploadMediaFileData.length > 0) {
      let tempArr = [];
      uploadMediaFileData.map((item, index) => {
        return tempArr.push({
          company: item["Company name"]?.trim() || "",
          Relation: item["Relation"],
          ticker: "",
          isin: "",
          "Relation sub category": item["Relation sub category"],
          "Relation type id": item["Relation type id"],
          importance: item["Importance"] || "",
          isValid: item.isValid ? item.isValid : false,
        });
      });
      dispatch(fileUploadSupplyChainAction(tempArr))
    }
  }, [uploadMediaFileData]);

  useEffect(() => {
    if (checkingCompanyIndex !== undefined && inValidHoldinggList.length > 0) {
      const clone = [...duplicateSetFieldValue.current.value.holdings];
      clone[checkingCompanyIndex].isValid = inValidHoldinggList[0].isValid;
      duplicateSetFieldValue.current.setValue("holdings", clone);
      checkingCompanyIndex = undefined;
    }
  }, [inValidHoldinggList]);

  const validationSchema = Yup.object().shape({
    holdings: Yup.array().of(
      Yup.object().shape({
        company: Yup.string().test(function (value, context) {
          if (
            this.options.index == 0 &&
            (value ? value.trim() == "" : value == undefined)
          ) {
            return this.createError({
              path: this.path,
              message: "Please enter company name or ticker symbol or ISIN.",
            });
          } else if (
            this.options.context.holdings.filter(
              (item, index) =>
                item.company &&
                item.company == value &&
                index != this.options.index
            ).length > 0
          ) {
            return this.createError({
              path: this.path,
              message: "Entered company name should not be same",
            });
          } else {
            return this.resolve({
              path: this.path,
              message: "",
            });
          }
        }),
      })
    ),
  });

  const handleSearch = (searchString, offset) => {
    lastSearchString = searchString;
    let res = [];
    setLoading(true)
    debounce(() => {
      dispatch(
        getCountryDataforSupplyChain({
          search: lastSearchString,
          limit: 10,
          offset: offset || 0,
        })
      );
    });
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  };

  useEffect(() => {
    setOptions([...options, ...countryList]);
  }, [countryList]);

  useLayoutEffect(() => {
    if (uploadFileSuccess) {
      navigate("/watchlist");
    }
  }, [uploadFileSuccess]);

  const saveHandler = () => {
    const data = {
      companyId: prevoiusSelectedCompany,
      companies: tabeldata.filter((item) => item.isValid) || [],
    };
    dispatch(saveValidWatchListValidCompanyLoad(data));
  };

  // New Functionalities
  const [inputWeigth, setInputWeight] = useState("");
  const [makeAuto, setMakeAuto] = useState({ state: false, index: null });
  const [makeAutoRelationType, setMakeAutoRelationType] = useState({ state: false, index: null });

  const [editWeight, setEditWeight] = useState({ state: false, index: null });
  const [activeTableRowIndex, setActiveTableRowIndex] = useState(null);
  const [paginationPayload, setPaginationPayload] = useState({
    currentPage: 1,
    rowLimit: 15
  })
  const autoCompleteRef = useRef(null)
  const autoCompleteRelationTypeRef = useRef(null)

  const editWeightRef = useRef(null)

  // State
  const [addFilePopOver, setAddFilePopOver] = useState(false);

  const tabeldata = useMemo(
    () => tableDataModificationHandler(supplyChainFileUploadList),
    [supplyChainFileUploadList]
  );

  const renderTableData = useMemo(() => tabeldata.slice((paginationPayload.currentPage - 1) * paginationPayload.rowLimit, paginationPayload.currentPage * paginationPayload.rowLimit), [paginationPayload, supplyChainFileUploadList]);
  const tabelDataSelectedItemLength = useMemo(
    () => supplyChainFileUploadList?.length > 0 ? supplyChainFileUploadList?.filter((item) => item?.isSelected)?.length : [],
    [supplyChainFileUploadList]
  );

  // Handler
  const allSelectTableItemsHandler = useCallback(
    (e) => {
      if (addFilePopOver) {
        setAddFilePopOver(false);
      }

      const startIndex = renderTableData[0].index
      const endIndex = renderTableData[renderTableData.length - 1].index
      let arr = tabeldata.map((item) => {
        if (item.index >= startIndex && item.index <= endIndex) {
          return { ...item, isSelected: e.target.checked };
        } else {
          return { ...item }
        }
      })

      dispatch(fileUploadSupplyChainAction(arr));
    },
    [supplyChainFileUploadList, renderTableData]
  );

  const removeTableSelectedItemsHandler = useCallback(() => {
    let arr = supplyChainFileUploadList.filter((item) => !item.isSelected);
    if (arr.length === 0) {
      dispatch(fileUploadSupplyChainAction(empArr));
    }
    else {
      dispatch(fileUploadSupplyChainAction(arr));
    }
  }, [supplyChainFileUploadList])

  useEffect(() => {
    const focusInput = (ref) => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    };
    if (makeAuto.state) {
      focusInput(autoCompleteRef);
    } else if (editWeight.state) {
      focusInput(editWeightRef);
    } else if (makeAutoRelationType.state) {
      focusInput(autoCompleteRelationTypeRef);
    }
  }, [makeAuto, makeAutoRelationType, editWeight]);


  useEffect(() => {
    const isInteger = /^[0-9]+$/;
    if (editWeightRef?.current) {
      if (isInteger.test(inputWeigth)) {
        editWeightRef.current.value = inputWeigth
      }
    }
  }, [inputWeigth]);

  const renderOptions = () => {
    let renderedOptions = options?.map((option, idx) => {
      return {
        value: option.id,
        dataValue: option.label,
        key: option.id,
        label: (
          <DoneItem
            searchString={lastSearchString}
            companyName={option?.label}
            relation={option?.Relation}
            compId={option?.compId}
            key={option?.id}
            alter_name={option?.alter_name || ""}
            alter_names={option?.alter_names?.length > 0 ? option?.alter_names[0] : []}
          />
        ),
      }
    })
    if (loading) {
      renderedOptions.push({
        value: 'loading',
        label: (
          <div style={{ textAlign: 'center' }}>
            <Spin size="small" />
          </div>
        ),
      });
    }
    return renderedOptions;
  };

  const rowEdit = (index) => makeAuto.state && makeAuto.index == index || makeAutoRelationType.state && makeAutoRelationType.index == index || editWeight.state && editWeight.index == index

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (v, r, i) => {
        return (
          <div style={{ display: "flex", gap: "8px" }}>
            <div
              style={{
                marginLeft: 15,
                width: "25px",
                height: "25px",
              }}
            >
              {activeTableRowIndex == i ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer"

                  }}
                  src={ImageConst.plusBtnNew}
                  onClick={() => {
                    const clone = [...tabeldata];
                    const dummyObj = {
                      "company": "",
                      Relation: "",
                      index: 0,
                      isin: "",
                      "Relation sub category": "",
                      "Relation type id": "",
                      importance: "",
                      ticker: "",
                    };
                    clone.splice(r.index + 1, 0, dummyObj);
                    dispatch(fileUploadSupplyChainAction(clone))
                  }}
                />
              ) : null}
            </div>
            <div
              style={{
                minWidth: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "inherit",
              }}
            >
              {activeTableRowIndex == i || r?.isSelected ? (
                <Checkbox
                  checked={r?.isSelected}
                  onChange={(e) => {
                    let arr = [...tabeldata];
                    arr[r.index] = {
                      ...arr[r.index],
                      isSelected: e.target.checked,
                    };
                    dispatch(fileUploadSupplyChainAction(arr))
                  }}
                />
              ) : (
                <>{r.index + 1}</>
              )}
            </div>
          </div>
        );
      },
      width: 70
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "name",
      render: (v, r, i) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              background: "inherit",
            }}
            onClick={() => {
              setMakeAuto({ state: true, index: i });
            }}
          >
            <div style={{ width: "10%" }}>
              {r.isValid ? (
                <div className="error">
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "5px",
                    }}
                    src={ImageConst.validPng}
                  />
                </div>
              ) : r.isValid == false ? (
                <div className="error">
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "5px",
                    }}
                    src={ImageConst.invalidPng}
                  />
                </div>
              ) : null}
            </div>
            <div
              style={{
                flexGrow: 1,
              }}
            >
              {makeAuto.state && makeAuto.index == i ? (
                <div onClick={(e) => {
                  e.stopPropagation()
                }}>
                  <AutoComplete
                    open={true}
                    style={{ width: "98%" }}
                    value={r["company"]}
                    autoComplete="none"
                    options={renderOptions()}
                    onSearch={(e) => {
                      let arr = [...tabeldata];
                      arr[r.index] = {
                        ...arr[r.index],
                        isValid: false,
                        "company": e,
                        name: e,
                      };
                      dispatch(fileUploadSupplyChainAction(arr))
                      stopApicallForGetingCompanies = false;
                      setOptions([]);
                      handleSearch(e);
                    }}
                    onSelect={(e, selectedOption) => {
                      const clone = [...tabeldata];
                      const makeItem = {
                        ...r,
                        index: r.index,
                        isValid: true,
                        company: selectedOption.dataValue,
                        ticker: selectedOption?.ticker?.tickerId || "",
                        isin: selectedOption.isin || "",
                      };
                      clone[makeItem.index] = makeItem;
                      dispatch(fileUploadSupplyChainAction(clone))
                      setMakeAuto({ state: false, index: null });
                      autoCompletePaginationOffset = 0
                    }}
                    onFocus={(e) => {
                      stopApicallForGetingCompanies = false;
                      setOptions([]);
                      handleSearch(e.target.value, 0);
                    }}
                    notFoundContent={
                      options?.length == 0 &&
                      companiesSearchListLoader && (
                        <div className={style.spinLoader}>
                          <Spin size="small" />
                        </div>
                      )
                    }
                    onBlur={() => {
                      if (r["company"].length == 0) {
                        let arr = [...tabeldata];
                        arr[r.index] = {
                          ...arr[r.index],
                          isValid: false,
                        };
                        dispatch(fileUploadSupplyChainAction(arr));
                      }
                      setMakeAuto({ state: false, index: null })
                      autoCompletePaginationOffset = 0
                    }}
                    onPopupScroll={(e) => {
                      if (
                        e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 1
                      ) {
                        autoCompletePaginationOffset =
                          autoCompletePaginationOffset + 10;
                        handleSearch(
                          lastSearchString,
                          autoCompletePaginationOffset
                        );
                      }
                    }}
                  >
                    <Input
                      ref={autoCompleteRef}
                      size="middle"
                    />
                  </AutoComplete>
                </div>
              ) : (
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {r["company"]}
                  </div>
                  <div
                    style={{
                      width: "22px",
                      height: "22px",
                    }}
                  >
                    {activeTableRowIndex == i && makeAuto.index !== i && r["company"]?.length > 0 ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMakeAuto({ state: true, index: i });
                        }}
                        src={ImageConst.editIcon}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </div>

          </div>
        );
      },
    },
    {
      title: "Relationship Type",
      dataIndex: "Relation",
      key: "Relation",
      render: (v, r, i) => {
        return (
          <div
            onClick={() => {
              setMakeAutoRelationType({ state: true, index: i });
            }}
          >
            {makeAutoRelationType.state && makeAutoRelationType.index == i ? (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Select
                  style={{ width: "90%" }}
                  value={r.Relation}
                  onChange={(selectedValue, selectedOption) => {
                    const clone = [...tabeldata];
                    const makeItem = {
                      ...r,
                      Relation: selectedOption.value,
                      "Relation type id": null
                    };
                    clone[makeItem.index] = makeItem;
                    dispatch(fileUploadSupplyChainAction(clone));
                    setMakeAutoRelationType({ state: false, index: null });
                  }}
                  onBlur={() => {
                    setMakeAutoRelationType({ state: false, index: null });
                  }}
                  allowClear={false} // Disable clear button
                  className="autocomplete-dropdown-no-cursor newClass"
                  popupClassName="autocomplete-dropdown-no-cursor"
                >
                  {relationshipTypeconst.map(option => (
                    <Select.Option
                      key={option.key} >
                      <Tooltip title="">
                        {option.dataValue}

                      </Tooltip>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ) : (
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div
                  style={{
                    width: 100,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {r.Relation}
                </div>
                <div
                  style={{
                    width: "22px",
                    height: "22px",
                  }}
                >
                  {activeTableRowIndex == i && makeAutoRelationType.index !== i && r?.Relation?.length > 0 ? (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setMakeAutoRelationType({ state: true, index: i });
                      }}
                      src={ImageConst.editIcon}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: () => <div style={{
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        borderLeft: "1px solid #d8dae0",
        fontWeight: "600",
        fontSize: "13px",
        fontFamily: "inter",
        color: "#3d4353",
      }}>Importance</div>,
      dataIndex: "importance",
      key: "Importance",
      render: (v, r, i) => {
        return (
          <div style={{ paddingLeft: 10, borderLeft: "1px solid #d8dae0", display: 'flex' }}

            onClick={() => {
              if (editWeightRef.current) {
                editWeightRef.current.value = r.importance
              }
              setInputWeight(r.importance);
              outerWeigth = r.importance
              setInputWeight(r["portfolio weight"]);
              setEditWeight({ state: true, index: i });
            }}
          >
            {editWeight.state && editWeight.index == i ? (
              <div
                onClick={(e) => e.stopPropagation()}
              >
                <Input
                  max={100}
                  min={0}
                  ref={editWeightRef}
                  size="small"
                  value={editWeightRef?.current?.value || ""}
                  onChange={(e) => {
                    const isInteger = /^[0-9]+(\.[0-9]*)?$/;
                    const validString = e.target.value
                    if (isInteger.test(validString) || e?.target?.value?.length == 0) {
                      setInputWeight(validString);
                      outerWeigth = validString
                      editWeightRef.current.value = validString
                    }
                  }}
                  onFocus={() => {
                    if (editWeightRef.current) {
                      editWeightRef.current.value = r.importance
                    }
                    setInputWeight(r.importance);
                    outerWeigth = r.importance
                  }}
                  onBlur={() => {
                    if (editWeightRef.current) {
                      editWeightRef.current.value = outerWeigth
                    }
                    const clone = [...tabeldata];
                    const makeItem = {
                      ...r,
                      "importance": inputWeigth,
                    };
                    clone[makeItem.index] = makeItem;
                    dispatch(fileUploadSupplyChainAction(clone))
                    setInputWeight("");
                    setEditWeight((pre) => ({ state: false, index: null }));
                  }}
                  style={{ width: 80 }}
                />

              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  // gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>{r?.importance?.toString()?.length > 0 ? r?.importance?.length > 8 ? r?.importance?.slice(0, 8) + ".." : r?.importance : ""}</div>
                <div
                  style={{
                    width: "22px",
                    height: "22px",
                  }}
                >
                  {activeTableRowIndex == i && editWeight.index !== i && r?.importance?.length > 0 ? (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (editWeightRef.current) {
                          editWeightRef.current.value = r.importance
                        }
                        setInputWeight(r.importance);
                        outerWeigth = r.importance
                        setInputWeight(r["portfolio weight"]);
                        setEditWeight({ state: true, index: i });

                      }}
                      src={ImageConst.editIcon}
                    />
                  ) : null}
                </div>
              </div>
            )}
            <div
              style={{
                marginLeft: 15,
                marginRight: 15,
                height: "26px",
                width: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (renderTableData?.length > 1) {
                  const clone = [...tabeldata];
                  clone.splice(r.index, 1);
                  if (clone.length == 0) {
                    dispatch(fileUploadSupplyChainAction(empArr))
                  } else {
                    dispatch(fileUploadSupplyChainAction(clone))
                  }
                  setEditWeight((pre) => ({ state: false, index: null }));
                }
              }}
            >
              {activeTableRowIndex == i && renderTableData?.length > 1 ? (
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  src={ImageConst.removeBtnNew}
                />
              ) : null}
            </div>
          </div>
        );
      },
      width: 90
    },
    // {
    //   title: "",
    //   dataIndex: "",
    //   key: "",
    //   render: (v, r, i) => {
    //     return (

    //     );
    //   },
    //   width: 40
    // },
  ];

  const isValidData = useMemo(() => tabeldata?.filter(val => val?.company?.length > 0)?.length, [tabeldata])
  const checkIsValid = useMemo(
    () => tabeldata.filter((item) => !item.isValid && item?.company?.length > 0),
    [supplyChainFileUploadList]
  )?.length;

  return (
    <>
      <div className={"containerWidth marginAuto pt20"}>
        <HeaderComponent
          name={"Chain Control"}
          subHeader={"Upload Supply Chain"}
          screen={"/supplychain"}
        />
        <div className={clsx(style.containerWidth)}>
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={() => { }}
            enableReinitialize={true}
            validateOnChange={validateFormOnChange} // Disable validation on change
            validateOnBlur={true} // Enable validation on blur
          >
            {({
              setFieldValue,
              setErrors,
              values,
              errors,
              validateForm,
            }) => {
              duplicateSetFieldValue.current.setValue = setFieldValue;
              duplicateSetFieldValue.current.value = values;
              duplicateSetFieldValue.current.errors = errors;
              duplicateSetFieldValue.current.setErrors = setErrors;
              duplicateSetFieldValue.current.validateForm = validateForm;
              return (
                <Form>
                  <div
                    className="pv10ph16"
                    style={{ cursor: "pointer", width: "fit-content" }}
                    onClick={() => navigate("/supplychain")}
                  >
                    <img
                      style={{ height: 20, width: 20 }}
                      src={ImageConst.backBtn}
                    ></img>
                    <span className="backBtnText"> Back</span>
                  </div>
                  <section className="containerWidth ">
                    <div
                      className={`${style.subComponent} ${style.subComponent1}`}
                    >
                      <span className={style.subComponentText}>
                        My Supply Chain List
                      </span>
                      <>
                        <button
                          type="submit"
                          className={style.smallBtn}
                          style={{
                            width: "fit-content",
                          }}
                          onClick={saveHandler}
                          disabled={!isValidData}

                        >
                          <div
                            className={style.nextBtn}
                            style={{
                              width: "fit-content",
                            }}
                          >
                            {addPortfolioLoading ? (
                              <GlobalSpinner />
                            ) : checkIsValid == 0 ? (
                              "Save"
                            ) : (
                              "Skip Errors and Continue"
                            )}
                            {saveSelectedWatchlistCompany ? (
                              <GlobalSpinner />
                            ) : (
                              <img
                                style={{ height: 20, width: 20 }}
                                src={ImageConst.arrowWhiteRight}
                              />
                            )}
                          </div>
                        </button>
                      </>
                    </div>
                  </section>
                  <>
                    <section className="containerWidth">
                      <span className={style.webBody}>
                        You can review and edit all your list here.
                      </span>
                    </section>
                    {
                      <section className="containerWidth pt32">
                        <div className={style.sucessView}>
                          <img
                            style={{
                              height: "32px",
                              width: "32px",
                              marginRight: "5px",
                            }}
                            src={ImageConst.portfolioInfo}
                          />
                          <div>
                            <span className={style.successMessage}>
                              {invalidCompany == 0
                                ? "Your list of supply chain companies has been processed successfully!"
                                : `Your list of supply chain companies has been processed. Recognized companies have been added to your supply chain management page. Any unrecognized or incorrectly formatted entries can be corrected or skipped by you. Currently, ${state.data.validPercentage}% of the holdings from your file are covered.`}
                              {invalidCompany > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 5,
                                  }}
                                >
                                  <div>
                                    <img
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                      }}
                                      src={ImageConst.validPng}
                                    />
                                    <span
                                      className={style.successMessage}
                                      style={{ marginLeft: 5 }}
                                    >
                                      {"Successfully Uploaded:"}
                                      <span
                                        className={style.successItem}
                                      >{`${validCompany} Items`}</span>
                                    </span>
                                  </div>
                                  <div>
                                    <img
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                      }}
                                      src={ImageConst.invalidPng}
                                    />
                                    <span
                                      className={style.successMessage}
                                      style={{ marginLeft: 5 }}
                                    >
                                      {"Failed to Upload:"}
                                      <span
                                        className={style.successItem}
                                      >{`${invalidCompany} Items`}</span>
                                    </span>
                                  </div>
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                      </section>
                    }

                    {/* Item Selection Records */}
                    <section
                      className="containerWidth "
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        borderBottom: "1px solid #D8DAE0",
                        borderTop: "1px solid #D8DAE0",
                      }}
                    >
                      <div
                        style={{
                          borderRight: ".5px solid #D8DAE0",
                          padding: "6px 10px 6px 10px",
                        }}
                      >
                        <Checkbox
                          onChange={allSelectTableItemsHandler}
                          indeterminate={
                            renderTableData?.length > 0 &&
                            renderTableData?.filter((item) => item?.isSelected)
                              ?.length > 0 &&
                            renderTableData?.filter((item) => item?.isSelected)
                              ?.length !== renderTableData.length
                          }
                          checked={
                            renderTableData?.length > 0 &&
                            renderTableData?.filter((item) => item?.isSelected)
                              ?.length === renderTableData?.length
                          }
                        />
                      </div>
                      <div
                        style={{
                          padding: "8px 0px 8px 10px",
                        }}
                      >
                        <div
                          style={{
                            borderRight: ".5px solid #D8DAE0",
                            color: "#0095CE",
                            paddingRight: "10px",
                            fontSize: 13,
                            fontWeight: 600,
                          }}
                        >
                          {tabelDataSelectedItemLength} selected
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "6px 0px 6px 6px",
                          marginLeft: 18,
                          cursor: "pointer",
                        }}
                        onClick={removeTableSelectedItemsHandler}
                      >
                        <img
                          style={{
                            height: 16,
                            width: 16,
                            marginRight: 9,
                          }}
                          src={ImageConst.trashIcon}
                        />
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          Remove
                        </div>
                      </div>
                    </section>

                    <section className="containerWidth">
                      <Table
                        className="tableSupplyChain"
                        dataSource={renderTableData}
                        columns={columns}
                        rowClassName={(record, index) => rowEdit(index) && "redBG"}

                        pagination={false}
                        onRow={(record, rowIndex) => {
                          return {
                            onMouseEnter: () => {
                              if (editWeightRef.current) {
                                editWeightRef.current.value = outerWeigth
                              }
                              setActiveTableRowIndex(rowIndex)
                            },
                            onMouseLeave: () => {
                              if (editWeightRef.current) {
                                editWeightRef.current.value = outerWeigth
                              }

                              setActiveTableRowIndex(null);
                            },
                          };
                        }}
                      />
                      {tabeldata?.length > 10 ? (<div style={{ marginBottom: "12px" }}>
                        <BottomPagination
                          paginationHandler={setPaginationPayload}
                          paginationPayload={paginationPayload}
                          dataLength={tabeldata?.length || 0}
                        />
                      </div>) : <div style={{ paddingBottom: 10 }} />}
                      {!tabeldata?.length > 0 && (
                        <div className={style.inputContainer2N}>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: 10,
                              padding: "8px 0px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const clone = [];
                              const dummyObj = {
                                "company": "",
                                Relation: "",
                                index: 0,
                                isin: "",
                                "Relation sub category": "",
                                "Relation type id": "",
                                importance: "",
                                ticker: "",
                              };
                              clone.push(dummyObj);
                              dispatch(fileUploadSupplyChainAction(clone))
                            }}
                          >
                            + Add New Row
                          </div>
                        </div>
                      )}

                    </section>
                  </>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UploadSupplyChain;

const DoneItem = ({
  searchString,
  companyName,
  companyIcon,
  key,
  compId = "",
  alter_name = "",
}) => {

  const highlightedCompanyName = highlightMatch(searchString, companyName?.slice(0, 20), "company");
  const companyEllipse = companyName?.length > 25 ? "..." : ""
  const highlightedAlter_names = alter_name?.length > 0 ? highlightMatch(searchString, alter_name?.slice(0, 10), "alter") : "";
  const alterEllipse = alter_name?.length > 10 ? "..." : ""

  return (
    <div
      key={key}
      style={{
        display: "flex",
        paddingBottom: 5,
        justifyContent: "space-between",
        alignItems: "end",
        borderBottom: ".5px solid rgb(216,218,224)",
      }}
    >
      <div style={{ width: "100%", height: "35px", gap: "4px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ width: "12%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={`https://logos-svg.komo.systems/${compId}.png` || companyIcon}
            alt="companyImage"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ImageConst.Apple;
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "65%", height: "100%", }}>
          <span dangerouslySetInnerHTML={{ __html: highlightedCompanyName?.trim() + companyEllipse }} />
        </div>
        <div style={{
          width: "28%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          fontSize: "10px",
          color: "#4E515C",
          fontFamily: "inter",
          textAlign: "end"
        }}>
          <span dangerouslySetInnerHTML={{ __html: highlightedAlter_names?.trim() + alterEllipse }} />
        </div>
      </div>
    </div >
  );
};
