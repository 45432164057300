// 1/07/2023 MM
import { call, put, take, takeLatest } from "redux-saga/effects";
import appconstant from "../../Constants/appConstansts";
import { onError, onFail, onSucces, onUnAuth } from "../../Utils/UnAuthFailFn";
import { apiConstants as type } from "../../Constants/apiConstants";
import { toast as hotToast } from 'react-hot-toast';
import {
  addToCollectionApi,
  addWishListApi,
  deleteWishListApi,
  editWishListApi,
  getWishListApi,
  getWishListDetailApi,
  saveValidSupplyChainCompanyAPI,
  uploadCompanyApi,
  uploadWatchlistApi,
} from "../api";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  getWishListDetailAction,
  getWishlistAction,
  addWishListItemIdAction,
  uploadCompanyFileFail,
} from "../Action/watchListActions";
import { errorToastContainer } from "../../Utils";
const { RoutesPath } = appconstant;
function* addWishListSaga(action) {
  try {
    let data = yield call(addWishListApi, action?.data);
    //on success
    if (data.status === 0) {
      let obj = {
        type: type?.API_ADD_WISHLIST_SUCCESS,
        data,
        show: false,
        navigationLink: false,
      };
      dispatch(getWishlistAction());
      yield call(onSucces, obj);
    }
    // on  auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // on fail
    else if (data?.status === 1) {
      let obj = {
        type: type?.API_ADD_WISHLIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* getWishListSaga(action) {
  try {
    let data = yield call(getWishListApi, action.data);
    if (data.status === 0) {
      let obj = {
        type: type?.API_GET_WISHLIST_SUCCESS,
        data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_WISHLIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* getWishlistDetail(action) {
  try {
    let data = yield call(getWishListDetailApi, action.data);
    if (data.status === 0) {
      let obj = {
        type: type?.API_GET_WISHLIST_DETAILS_SUCCESS,
        data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_WISHLIST_DETAILS_FAIL,
        message: data?.result?.message,
        show: false,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* deleteWishlistSAGA(action) {
  try {
    let data = yield call(deleteWishListApi, action.data);
    if (data?.status === 0) {
      let obj = {
        type: type?.API_DELETE_WISHLIST_SUCCESS,
        data,
        show: false,
        navigationLink: false,
      };
      dispatch(getWishlistAction());
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_ADD_WISHLIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* editWishListSaga(action) {
  try {
    let data = yield call(editWishListApi, action?.data);
    if (data.status === 0) {
      let obj = {
        type: type?.API_EDIT_WISHLIST_SUCCESS,
        data,
        show: false,
        navigationLink: false,
      };
      dispatch(getWishlistAction({ id: action?.data?.wishlist }));
      dispatch(getWishListDetailAction(action?.data?.wishlist));
      dispatch(addWishListItemIdAction(null));
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_EDIT_WISHLIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* addToCollectionSaga(action) {
  try {
    let data = yield call(addToCollectionApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type?.API_ADD_TO_COLLECTION_SUCCESS,
        data,
        show: true,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_ADD_TO_COLLECTION_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(onError);
    }
  } catch (e) { }
}

function* editWishSevereSaga(action) {
  try {
    let data = yield call(editWishListApi, action?.data);
    if (data.status === 0) {
      let obj = {
        type: type?.UPDATE_WATCHLIST_SEVER_SUCCESS,
        data,
        show: false,
        navigationLink: false,
      };
      dispatch(getWishlistAction());

      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_EDIT_WISHLIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* uploadWatchlistSaga(action) {
  try {
    let data = yield call(uploadCompanyApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.UPLOAD_COMPANY_FILE_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      yield put(uploadCompanyFileFail());
      hotToast.success(
        errorToastContainer(data?.result?.message),
        { id: "watchListFileUploadFailed" }
      )
      let obj = {
        type: type?.UPLOAD_DATA_FAIL,
        message: data?.result?.message,
        show: false,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* uploadWatchlistLoadSaga(action) {
  try {
    let data = yield call(uploadWatchlistApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.UPLOAD_WATCHLIST_COMPANY_FILE_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.UPLOAD_WATCHLIST_COMPANY_FILE_FAIL,
        message: data?.result?.message,
        show: false,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}

function* saveValidSupplyChainCompanySaga(action) {
  try {
    let data = yield call(saveValidSupplyChainCompanyAPI, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.SAVE_VALID_SUPPLY_CHAIN_COMPANY_SUCCESS,
        data: data,
        show: false,
        navigationLink: "supplyChain",
        navState: { state: { id: action.data.companyId } },
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.SAVE_VALID_SUPPLY_CHAIN_COMPANY_FAIL,
        message: data?.result?.message,
        show: true,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}

export default function* rootWatchListSaga() {
  yield takeLatest(type?.API_ADD_WISHLIST_LOAD, addWishListSaga);
  yield takeLatest(type?.API_GET_WISHLIST_LOAD, getWishListSaga);
  yield takeLatest(type?.API_GET_WISHLIST_DETAILS_LOAD, getWishlistDetail);
  yield takeLatest(type?.API_DELETE_WISHLIST_LOAD, deleteWishlistSAGA);
  yield takeLatest(type?.API_EDIT_WISHLIST_LOAD, editWishListSaga);
  yield takeLatest(type?.UPDATE_WATCHLIST_SEVER_LOAD, editWishSevereSaga);

  yield takeLatest(type?.API_ADD_TO_COLLECTION_LOAD, addToCollectionSaga);
  yield takeLatest(type?.UPLOAD_COMPANY_FILE_LOAD, uploadWatchlistSaga);
  yield takeLatest(
    type?.UPLOAD_WATCHLIST_COMPANY_FILE_LOAD,
    uploadWatchlistLoadSaga
  );
  yield takeLatest(
    type?.SAVE_VALID_SUPPLY_CHAIN_COMPANY_LOAD,
    saveValidSupplyChainCompanySaga
  );
}
