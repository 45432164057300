import React, { useState, useEffect, useCallback } from "react";
import Images from "../../Themes/ImageConstant";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesAction,
  getEsgCountriesAction,
  getEsgFactorsAction,
  incidentDetailAction,
  incindentAction,
} from "../../Redux/Action/incidents";
import CustomPagination from "../../Componets/Pagination";
import CustomTable from "./components/table.component";
import {
  checkColor,
  getColorForTable,
  getColorForTableText,
} from "../../Utils";
import { navigate } from "../../Utils/dispatchNavigate";
import moment from "moment";

const tableColumn = [
  "Severity",
  "Involved Companies",
  "ESG Factors",
  "Locations",
  "Date",
  "Name",
];

const BubleIncidentsList = (props) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const IPLData = useSelector((state) => state?.incidentReducer?.incident);

  const [search, setSearch] = useState("");

  const [paginationData, setPaginationData] = useState({
    offset: 0,
    limit: 10,
  });

  const [tableHeaderArray, setTableHeaderArray] = useState(tableColumn);

  const esgFactorList = useSelector(
    (state) => state?.incidentReducer?.esgFactorList
  );

  useEffect(() => {
    if (
      props?.incidentIdlist?.length > 0 ||
      props?.esg_categories?.length > 0 ||
      props?.companies?.length > 0
    ) {
      let payload = {
        ...props.apiPayload,
        ...paginationData,
        companies: props?.companies,
        specificIncidentIds: props?.incidentIdlist,
        esg_categories: props?.esg_categories,
      };
      delete payload.id;

      dispatch(incindentAction(payload));
    }
  }, []);

  const onNameClick = (item) => {
    if (pathname == "/incidentdetails") {
      dispatch(incidentDetailAction(item?.id));
      props.setModal((prv) => !prv);
    } else {
      // navigate("/incidentdetails", {
      //   state: { id: item.id },
      // });
      window.open(`/incidentdetails?${item.id}`, "_blank");
    }
  };

  const columns = useCallback(
    () => [
      {
        title: <div style={{ textAlign: "left", fontWeight: 600 }}>Name</div>,
        // title: "Name",
        dataIndex: "title",
        width: 300,
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Name") ? "displayNone" : ""
        }`,
        render: (_, item, index) => (
          <div
            onClick={() => onNameClick(item)}
            style={{
              height: "96px",
              fontWeight: 600,
              cursor: "pointer",
              display: "flex",
              marginLeft: "14px",
              // justifyContent:"center"
              alignItems: "flex-start",
            }}
          >
            <span style={{ color: "#9A9CA2" }}>
              {index + paginationData.offset + 1}
            </span>
            &nbsp;&nbsp;
            <span className="incidentTextHover">{item.title}</span>
          </div>
        ),
      },
      {
        title: (
          <div style={{ textAlign: "left", fontWeight: 600 }}>Severity</div>
        ),
        dataIndex: "severity",
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Severity") ? "displayNone" : ""
        }`,
        sorter: () => {},
        ellipsis: true,
        width: 100,
        render: (_, item) => (
          <div
            style={{
              height: "92px",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: checkColor[item.severity],
                width: "35%",
                height: "32px",
              }}
            >
              <p style={{ color: "white", width: "fit-content" }}>
                {/* {item?.severity.charAt(0)} */}
              </p>
            </div>
          </div>
        ),
      },
      {
        className: `company-supplyChain  table-th-tab-ps ${
          !tableHeaderArray.includes("Involved Companies") ? "displayNone" : ""
        }`,
        width: 250,
        // title: (
        //   <Dropdown
        //     type={1}
        //     width={200}
        //     handleChange={handleIncidentFilter}
        //     multiple={true}
        //     placeholder="Involved Companies"
        //   />
        // ),
        dataIndex: "involvedCompanies",
        render: (_, item) => {
          return (
            <div
              style={{
                height: "92px",
                display: "flex",
                alignItems: "flex-start",
                // justifyContent: "center",
              }}
            >
              {item?.affected_companies?.slice(0, 3).map((data) => (
                <p>{data?.name}</p>
              ))}
            </div>
          );
        },
      },
      {
        className: `company-supplyChain ${
          !tableHeaderArray.includes("ESG Factors") ? "displayNone" : ""
        }`,
        width: 250,
        // title: (
        //   <Dropdown
        //     type={2}
        //     width={200}
        //     handleChange={handleIncidentFilter}
        //     multiple={true}
        //     placeholder="ESG Factors"
        //   />
        // ),
        dataIndex: "ESGFactors",
        ellipsis: {
          showTitle: false,
        },
        render: (_, item) => {
          return item?.esg_factors?.slice(0, 3)?.map((data) =>
            data?.length === 0 ? (
              <p>"N/A</p>
            ) : (
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div className="air-que">
                  <h5
                    style={{
                      backgroundColor: getColorForTable(
                        data?.esg_category?.name
                      ),
                      color: getColorForTableText(data?.esg_category?.name),
                    }}
                  >
                    {data.esg_category?.name
                      ? data.esg_category?.name?.charAt(0)
                      : ""}
                  </h5>
                  <Tooltip placement="top" title={<span>{data?.name}</span>}>
                    <p
                      style={{
                        color: getColorForTableText(data.esg_category.name),
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.name}
                    </p>
                  </Tooltip>
                </div>
              </div>
            )
          );
        },
      },
      {
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Locations") ? "displayNone" : ""
        }`,
        width: 250,
        // title: (
        //   <Dropdown
        //     type={3}
        //     width={200}
        //     handleChange={handleIncidentFilter}
        //     multiple={true}
        //     placeholder="Locations"
        //   />
        // ),
        dataIndex: "Locations",
        render: (_, item) => (
          <div
            style={{
              height: "92px",
              display: "flex",
            }}
          >
            {item.locations.map((item) => item.name).join(", ")}
          </div>
        ),
      },
      {
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Date") ? "displayNone" : ""
        }`,
        width: 65,
        title: "Date",
        dataIndex: "date",
        sorter: () => {},
        render: (_, item) => (
          <div
            style={{
              height: "92px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {moment(item?.discovery_time).fromNow()}
          </div>
        ),
      },
    ],
    [tableHeaderArray, paginationData]
  );

  const paginationPayloadData = {
    ...props.apiPayload,
    specificIncidentIds: props?.incidentIdlist,
    esg_categories: props?.esg_categories,
    companies: props?.companies?.length > 0 ? props?.companies : [],
  };
  return (
    <div className="side-padding list">
      <div className="main-layout table-layout">
        <section className="bg-sec mb-4">
          <div className="breadcrmbs mb-4">
            <Breadcrumb>
              <Breadcrumb.Item
                key="1"
                className="bad-link"
                onClick={() => props?.isComeFromModal && props.setModal(false)}
              >
                <Link to={props.goTo ? props.goTo : "/home"}>
                  <img src={Images.left} />
                  Back
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item key="2" className="inc-link">
                Incidents
              </Breadcrumb.Item>
              <Breadcrumb.Item key="3" className="lie-link">
                List
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="container">
            <div className="table-page-sec">
              <div className="table-box incident">
                <CustomTable dataSource={IPLData?.data} columns={columns()} />
              </div>
              <CustomPagination
                pagination={IPLData.pagination}
                action={incindentAction}
                data={paginationPayloadData}
                cb={(pagination) => {
                  setPaginationData(pagination);
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BubleIncidentsList;
