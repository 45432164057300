import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { OnBoardingScreen } from "../../Componets/onBoardingModal";
import { Button, Modal } from "antd";
import { Popover, Steps } from "antd";

const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
const contents = [
  {
    heading: "Explore ESG Incidents",
    paragraph: <p>With advanced search tools, effortlessly explore ESG incidents by category, industry, location, and specific companies. Stay informed and make data-driven decisions to align with your ESG goals. hello hello hl</p>,
    imageUrl: Images.leftSide1,
  },
  {
    heading: "Supply Chain Transparency",
    paragraph:
      "Dive deep into the supply chain of any company. Discover suppliers, customers, and related incidents. Gain valuable insights into the ESG performance of the entire ecosystem surrounding your chosen companies",
    imageUrl: Images.leftSide2,
  },
  {
    heading: "Tailored ESG Watchlists",
    paragraph: "Create personalized watchlists to keep a close eye on ESG incidents that matter to you. Set filters based on ESG category, industry, location, and companies of interest. Choose how frequently you want updates to stay in the know",
    imageUrl: Images.leftSide3,
  },
  {
    heading: "Powerful Portfolio Management",
    paragraph: "Optimize your investment strategies with our portfolio feature. Create and manage portfolios, add companies, and access in-depth analysis of your investments. Monitor market trends and assess ESG risks to make informed decisions",
    imageUrl: Images.leftSide4,
  },
];

const description = "";



const Notification = (props) => {
  const [modal2Open, setModal2Open] = useState(false);
  const [contentIndex, setContentIndex] = useState(0);
  const [currnetStep, setCurrnetStep] = useState(0)

  const handleContinue = () => {
    setContentIndex((prevIndex) => (prevIndex + 1) % contents.length);
    setCurrnetStep((prevStep) => (prevStep + 1))
  };
  const currentContent = contents[contentIndex];
  const isLastContent = contentIndex === contents.length - 1;
  const isFirstContent = contentIndex === 0;

  return (
    <div className="side-padding ">
      {/* <OnBoardingScreen/> */}

      {/* modal start */}
      <div className="user-onboarding-modal">
        <Modal
          className="cross-model  cross-model1"
          centered
          open={modal2Open}
          closable={false}
          onCancel={() => setModal2Open(false)}
          footer={[]}
        >
          <div className="user-content-modal-ps">
            <div className="left-side-bg-ps">
              <img src={currentContent.imageUrl} />
            </div>

            <div className="back-side-right-ps">
              <div className="back-btn-ps">
                <button className="chnage-btn">
                  <span>
                    <img src={Images.backerrow} />
                  </span>
                  Back
                </button>

                <div className="supply-chain-ps">
                  <div className="steps-ps">
                    <Steps
                      current={currnetStep}
                      progressDot={customDot}
                      items={[
                        {
                          description,
                        },
                        {
                          description,
                        },
                        {
                          description,
                        },
                        {
                          description,
                        },
                      ]}
                    />
                  </div>

                  <h1>{currentContent.heading}</h1>

                  <p>
                    {currentContent.paragraph}
                  </p>
                </div>

                <div className="bott-button-ps">
                  <button className="skip-btn-ps" style={{ visibility: isLastContent || isFirstContent ? "hidden" : "visible" }}>Skip</button>

                  <button className="continue-btn-ps" onClick={handleContinue}>
                    {isLastContent ? "Get Started" : "Continue"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {/* modal end */}

      <div className="containersize">
        <div className="top-head-on">
          <h5>Notifications</h5>
        </div>

        <div className="mssage-delete mt-5">
          <div className="mas-text">
            <h5>Messages</h5>
          </div>
          <div className="del-all-msg">
            <h5 onClick={() => setModal2Open(true)}>Delete all messages</h5>
          </div>
        </div>

        <MessageBox
          icon={Images.star}
          title={"New Feature Update!"}
          time={"2 min ago"}
          paragraph={` Exciting News! A new feature has been added to our application.
          Check it out now and discover how it can enhance your experience.
          You can now Tag your saved incidents and save them based on your
          own tags.`}
        />

        <MessageBox
          icon={Images.starinfo}
          title={"Important Update!"}
          time={"1 hour ago"}
          paragraph={`Our terms and conditions have been revised. Please take a moment
          to review the changes and ensure that you are familiar with our
          policies. See more...`}
        />
        <MessageBox
          icon={Images.star}
          title={"New Feature Update!"}
          time={"2 min ago"}
          paragraph={`Exciting News! A new feature has been added to our application.
          Check it out now and discover how it can enhance your
          experience. You can now Tag your saved incidents and save them
          based on your own tags.`}
        />
        <MessageBox
          icon={Images.starinfo}
          title={"Important Update!"}
          time={"1 hour ago"}
          paragraph={` Our terms and conditions have been revised. Please take a moment
          to review the changes and ensure that you are familiar with our
          policies. See more...`}
        />
      </div>
    </div>
  );
};

const MessageBox = ({ icon, title, time, paragraph }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      paddingTop: "3.5vw",
      paddingBottom: "2vw",
      borderTop: "1px solid #CED2E0",
    }}
  >
    <div className="starpic" style={{ display: "flex" }}>
      <img src={icon} />
    </div>
    <div className="up-timing " style={{ display: "block", marginLeft: "2vw" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1.2vw",
        }}
      >
        <div className="new-text-up">
          <p style={{ paddingLeft: "0px" }}>{title}</p>
        </div>
        <div className="twomin">
          <p>{time}</p>
        </div>
      </div>
      <div className="ex-tag">
        <p>
          {paragraph}
          <a href="#">See more...</a>
        </p>
      </div>
    </div>
  </div>
);
export default Notification;
