import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  // Icon,
  Stack,
  Typography,
  // useTheme,
} from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useState } from 'react';
// import ShowMoreText from 'react-show-more-text';
// import CustomTooltip from '../GraphToolTip/CustomTooltip';
// // import SupplyChainActIcon from './SupplyChainActViolationsIcon';
// import ShowMoreIcon from './ShowMoreIcon';
import IncidentItem from "./incidentItem";

function Top5CompaniesWithMostIncidents({ data }) {
  return (
    <Box>
      <Stack direction="row" gap={1}>
        {/* <SupplyChainActIcon /> */}
        <Typography variant="subtitle1" sx={{ marginBottom: "16px" }}>
          Top 5 with most incidents
        </Typography>
      </Stack>
      <Stack gap={1}>
        {data?.map((item, index) => (
          <Stack gap="16px">
            <IncidentItem
              id={item?.id}
              key={item?.id}
              name={item.name}
              index={index + 1}
              severityCounts={item?.counts}
            />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
export default Top5CompaniesWithMostIncidents;
