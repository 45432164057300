import React, { useEffect, useState } from "react";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import { getIncidentTimelineAction } from "../../../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import BubbleChart, {
  EchartsBubbleChart,
} from "../../../../Componets/BubbleChart";
import { Button, Row, Spin, Switch } from "antd";
import { ChartCardNew } from "../../../../Layouts/ChartCard/ChartCardNew";
import {
  getBubbleXaxis,
  getFilteredArrayforBubbles,
  Timeline,
} from "../../../../Utils";
import Images from "../../../../Themes/ImageConstant";
import Styles from "./IncidentTimeline.module.css";
import { structureData } from "../../../../Utils/chartCommandFunction";
import { convertTimestampinFormatedDate } from "../../../../Utils/globalFunction";
import GlobalLoder from "../../../../Utils/GlobalLoder";

const IncidentTimeline = (networkData) => {
  const enviromentData = useSelector(
    (state) => state?.companyPageReducer?.incidentTimelineData
  );
  const { incidentTimelineData_loader } = useSelector(
    (state) => state?.companyPageReducer
  );
  const [enviromentDataModify, setEenviromentDataModify] = useState([]);
  const [socialDataModify, setSocialDataModify] = useState([]);
  const [governanceDataModify, setGovernanceDataModify] = useState([]);
  const [toggle, setToggle] = useState(false);

  const [activeTab, setActivetab] = useState("ESG Factors");
  useEffect(() => {
    dispatch(
      getIncidentTimelineAction({
        ...networkData,
        ...{
          type:
            activeTab == "ESG Factors"
              ? "esg_factors"
              : activeTab == "SDG"
                ? "esg_SDG"
                : activeTab == "PAI"
                  ? "esg_PAI"
                  : "esg_factors",
        },
        ...{ severity: toggle },
      })
    );
  }, [activeTab, networkData.id, networkData?.dateChange]);

  useEffect(() => {
    if (enviromentData.length > 0) {
      const enviromentDataIndex = enviromentData.findIndex(
        (x) => x.name === "Environmental"
      );
      const socialDataIndex = enviromentData.findIndex(
        (x) => x.name === "Social"
      );
      const governanceDataIndex = enviromentData.findIndex(
        (x) => x.name === "Governance"
      );

      setEenviromentDataModify(enviromentData[enviromentDataIndex].data);
      setSocialDataModify(enviromentData[socialDataIndex].data);
      setGovernanceDataModify(enviromentData[governanceDataIndex].data);
    }
  }, [enviromentData]);

  // let incidentList;
  // const incidentFormatTooltip = ({
  //   series,
  //   seriesIndex,
  //   dataPointIndex,
  //   w,
  // }) => {
  //   incidentList = w?.config.series[seriesIndex].data[dataPointIndex][3].map(
  //     (val) => val.id
  //   );

  //   const type = w?.config.series[seriesIndex].name;
  //   const date = convertTimestampinFormatedDate(
  //     w?.config.series[seriesIndex].data[dataPointIndex][0],
  //     "month"
  //   );

  //   const firstNumberTitle =
  //     w?.config.series[seriesIndex].data[dataPointIndex][1];
  //   const number =
  //     w?.config.seriuseEffectes[seriesIndex].data[dataPointIndex][3]?.length;
  //   return `
  //     <div style="width:200px;margin:5px; border-radius:5px;">
  //     <div style="font-weight:bold;font-size:14px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
  //     ${date}
  //     </div>
  //     <div class="tooltip-item"> Number of Incidents: ${number}</div>
  //   </div>
  //     `;
  // };

  const dataPass = (incidentList) => {
    const data = {
      modalState: true,
      listData: incidentList,
    };
    networkData.incidentListHandler(data);
  };

  // let mind = enviromentDataModify?.map((e) => e[0]).sort();
  // let maxY = enviromentDataModify?.map((e) => e[1]).sort();

  // const enviromentOptions = {
  //   series: [
  //     {
  //       name: "Enviroment",
  //       data: enviromentDataModify?.length > 0 ? enviromentDataModify : [],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: "bubble",
  //       toolbar: {
  //         show: false,
  //         tools: {
  //           zoom: false,
  //         },
  //       },
  //     },
  //     colors: ["#6EBD1F"],
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     xaxis: {
  //       tickAmount: 8,
  //       min: mind[0] - mind[0] * (0.1 / 100) || undefined,
  //       max:
  //         mind[mind.length - 1] + mind[mind.length - 1] * (0.1 / 100) ||
  //         undefined,
  //       type: "datetime",
  //       labels: {
  //         // rotate: 0,
  //         formatter: function (value) {
  //           let eIndex = enviromentData.findIndex(
  //             (x) => x.name == "Environmental"
  //           );
  //           return getBubbleXaxis(value, enviromentData[eIndex]?.groupType);
  //         },
  //       },
  //     },
  //     yaxis: {
  //       max: 2,
  //       tickAmount: 2,
  //       show: false,
  //     },
  //     grid: {
  //       padding: {
  //         left: 40,
  //       },
  //     },
  //     theme: {
  //       palette: "palette2",
  //     },
  //     markers: {
  //       onClick: dataPass,
  //     },
  //     tooltip: {
  //       x: {
  //         format: "dd MMM yyyy",
  //       },
  //       custom: incidentFormatTooltip,
  //     },
  //   },
  //   defaultColor: "red",
  // };

  // let mind2 = socialDataModify?.map((e) => e[0]).sort();
  // let maxY2 = enviromentDataModify?.map((e) => e[1]).sort();

  // const SocialOptions = {
  //   series: [
  //     {
  //       name: "Social",
  //       data: socialDataModify?.length > 0 ? socialDataModify : [],
  //     },
  //   ],
  //   options: {
  //     colors: ["#4A92FB"],
  //     chart: {
  //       height: 350,
  //       type: "bubble",
  //       toolbar: {
  //         show: false,
  //         tools: {
  //           zoom: false,
  //         },
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     xaxis: {
  //       tickAmount: 8,
  //       min: mind2[0] - mind2[0] * (0.1 / 100) || undefined,
  //       max:
  //         mind2[mind2.length - 1] + mind2[mind2.length - 1] * (0.1 / 100) ||
  //         undefined,
  //       type: "datetime",
  //       labels: {
  //         formatter: function (value) {
  //           let eIndex = enviromentData.findIndex((x) => x.name == "Social");
  //           return getBubbleXaxis(value, enviromentData[eIndex]?.groupType);
  //         },
  //       },
  //     },
  //     yaxis: {
  //       max: 2,
  //       tickAmount: 2,
  //       show: false,
  //     },
  //     grid: {
  //       padding: {
  //         left: 40,
  //       },
  //     },
  //     theme: {
  //       palette: "palette2",
  //     },
  //     markers: {
  //       onClick: dataPass,
  //     },
  //     tooltip: {
  //       x: {
  //         format: "dd MMM yyyy",
  //       },
  //       custom: incidentFormatTooltip,
  //     },
  //   },
  //   defaultColor: "red",
  // };

  // let mind3 = governanceDataModify?.map((e) => e[0]).sort();
  // let maxY3 = enviromentDataModify?.map((e) => e[1]).sort();

  // const governanceOptions = {
  //   series: [
  //     {
  //       name: "Govenance",
  //       data: governanceDataModify?.length > 0 ? governanceDataModify : [],
  //     },
  //   ],
  //   options: {
  //     colors: ["#653DD4"],
  //     chart: {
  //       height: 350,
  //       type: "bubble",
  //       toolbar: {
  //         show: false,
  //         tools: {
  //           zoom: false,
  //         },
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     xaxis: {
  //       tickAmount: 8,
  //       min: mind3[0] - mind3[0] * (0.1 / 100) || undefined,
  //       max:
  //         mind3[mind3.length - 1] + mind3[mind3.length - 1] * (0.1 / 100) ||
  //         undefined,
  //       type: "datetime",
  //       labels: {
  //         formatter: function (value) {
  //           let eIndex = enviromentData.findIndex(
  //             (x) => x.name == "Governance"
  //           );
  //           return getBubbleXaxis(value, enviromentData[eIndex]?.groupType);
  //         },
  //       },
  //     },
  //     yaxis: {
  //       max: 2,
  //       tickAmount: 2,
  //       show: false,
  //     },
  //     grid: {
  //       padding: {
  //         left: 40,
  //       },
  //     },
  //     theme: {
  //       palette: "palette2",
  //     },
  //     markers: {
  //       onClick: dataPass,
  //     },
  //     tooltip: {
  //       x: {
  //         format: "dd MMM yyyy",
  //       },
  //       custom: incidentFormatTooltip,
  //     },
  //   },
  //   defaultColor: "red",
  // };

  return (
    <section className="left-text">
      <ChartCardNew
        title={
          <h4>
            Incident Timeline
            <img src={Images.circleInfo} className="info-icon-company-page" />
          </h4>
        }
        network={networkData}
        legend={Timeline}
      >
        {/*<div>
           <Row
            style={{
              borderBottom: "1px solid",
              display: "flex",
              borderColor: "#DEDEDE",
              paddingBottom: 10,
              alignItems: "center",
            }}
            justify="space-between"
          >
            <div className="px-2 ">
              <Row>
                {Timeline?.map((data) => {
                  return (
                    <Button
                      onClick={() => {
                        setActivetab(data?.tittle);
                      }}
                      className={`legendButton ${
                        activeTab === data?.tittle ? "active" : ""
                      }`}
                    >
                      <p>{data.tittle}</p>
                    </Button>
                  );
                })}
              </Row>
            </div>
            <div className="px-2">
              <Row
                style={{
                  marginRight: 5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Switch
                  className="SeveritySwitch"
                  name="Environmental"
                  checked={toggle}
                  onChange={(e) => {
                    dispatch(
                      getIncidentTimelineAction({
                        ...networkData,
                        ...{ severity: e },
                      })
                    );
                    setToggle(e);
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: "normal",
                    marginLeft: 5,
                    fontFamily: "Inter",
                    lineHeight: "16px",
                  }}
                >
                  Show incidents by severity
                </p>
              </Row>
            </div>
          </Row> 
        </div>*/}
        <div className="Sentiment-grah">
          <div className="incidentTimeline-tabs-top-div">
            {!incidentTimelineData_loader ? (
              // <>
              //   {enviromentOptions?.series?.length > 0 &&
              //     enviromentOptions?.series[0]?.data?.length > 0 && (
              //       <div style={{ width: "100%" }}>
              //         <Chart
              //           title={"Enviroment"}
              //           titleImage={Images.greenTile}
              //           titleColor={"#2C4C0D"}
              //           titleIcon={Images.greenGloabe}
              //           chartData={enviromentOptions}
              //         />
              //       </div>
              //     )}
              //   {SocialOptions?.series?.length > 0 &&
              //     SocialOptions?.series[0]?.data?.length > 0 && (
              //       <div>
              //         <Chart
              //           title={"Social"}
              //           titleImage={Images.blueTile}
              //           titleColor={"#0C3358"}
              //           titleIcon={Images.blueSocial}
              //           chartData={SocialOptions}
              //         />
              //       </div>
              //     )}
              //   {governanceOptions?.series?.length > 0 &&
              //     governanceOptions?.series[0]?.data?.length > 0 && (
              //       <div>
              //         <Chart
              //           title={"Governance"}
              //           titleImage={Images.purpleTile}
              //           titleColor={"#331E6A"}
              //           titleIcon={Images.purplebank}
              //           chartData={governanceOptions}
              //         />
              //       </div>
              //     )}
              // </>
              <EchartsBubbleChart
                environmentData={enviromentDataModify}
                socialData={socialDataModify}
                governanceData={governanceDataModify}
                onClick={(ids) => {
                  dataPass(ids);
                }}
              />
            ) : (
              <div
                style={{
                  padding: "20px 0px 20px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "350px",
                  width: "100%",
                }}
              >
                <Spin
                  spinning={incidentTimelineData_loader}
                  indicator={
                    <div className="outer-table-loader">
                      <GlobalLoder />
                    </div>
                  }
                ></Spin>
              </div>
            )}
          </div>
        </div>
      </ChartCardNew>
    </section>
  );
};

const Chart = ({ title, titleImage, titleIcon, chartData, titleColor }) => {
  return (
    <div className={Styles.chartContainer}>
      <div className={Styles.chartBox}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            style={{
              height: "24px",
              width: "7px",
              marginRight: "5px",
              marginTop: 5,
            }}
            src={titleImage}
          />
          <p
            style={{
              marginTop: 6,
              color: titleColor,
              fontFamily: "Inter",
              fontSize: 13,
              fontWeight: 600,
              lineHeight: "24px",
              textAlign: "left",
            }}
          >
            {title}
          </p>
        </div>
        <img
          style={{
            height: "24px",
            width: "24px",
            marginLeft: "15px",
            marginTop: 7,
          }}
          src={titleIcon}
        />
      </div>
      <div style={{ width: "100%" }}>
        <BubbleChart options={chartData} height={200} width={"100%"} />
      </div>
    </div>
  );
};

export default IncidentTimeline;
