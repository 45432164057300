import React, { useState, useEffect, useCallback } from "react";
import Images from "../../Themes/ImageConstant";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Spin } from "antd";
import { Breadcrumb } from "antd";
import ImageConst from "../../Themes/ImageConstant";
import { MapTypeButton } from "../../Componets/MapTypeButton";
import { getSeviarityMap } from "../../Redux/Action/comapanyPageAction";
import { filterESGMapDataAction, filterSevertyMapDataAction, incidentMapAction } from "../../Redux/Action/incidents";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "../../Componets/toggleButton";
import DatePicker from "../../Componets/DatePicker";
import { calculatePreviousDate, dateButtonObj } from "../../Utils";
import moment from "moment/moment";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import { AdvanceSearch } from "../../Componets/AdvanceSearch";
import FullMap from "../../Componets/MapGL/full-map";

const filterESGDataInitialState = [
  {
    isUseAble: false,
    data: {},
  },
  {
    isUseAble: false,
    data: {},
  },
]
const esgMapFilter = {
  env: false,
  soc: false,
  gov: false
};

const severtyMapFilter = {
  critical: false,
  high: false,
  low: false,
  med: false
};
const mapFiltersStrings = {
  env: "environmental",
  soc: "social",
  gov: "governance",
  critical: "critical",
  high: "high",
  low: "minor",
  med: "moderate"
}
const getActiveFilter = (obj) => {
  const data = Object.values(obj).filter(item => item);
  if (data.length > 1) {
    return null
  }
  for (const [key, value] of Object.entries(obj)) {
    if (value === true) {
      return mapFiltersStrings[key];
    }
  }

  return null; // or handle this case as needed
};
const IncidentsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigationData = useLocation();
  const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false);

  const {
    ESGLocationArray,
    companyIdSearchList,
    locationIdSearchList,
    severityDataLocationArray,
    googleMapViewState,
    filterESGMapData,
    filterSevertyMapData,
    mapDataLoad,
  } = useSelector((state) => state?.incidentReducer);

  const location = window.location.pathname;


  useEffect(() => {
    resetFilters()
    if (
      navigationData?.state &&
      navigationData?.state?.comeFrom == "searchdashboardpage"
    ) {
      dispatch(incidentMapAction(navigationData?.state?.mergedData));
    } else if (companyIdSearchList?.length === 0 && location === "/fullMap") {
      dispatch(
        incidentMapAction({
          companies:
            navigationData?.state?.companyId?.length > 0
              ? [navigationData?.state?.companyId]
              : [],

          start: navigationData?.state?.start || "",
          end: navigationData?.state?.end || "",
        })
      );
    }
  }, []);

  useEffect(() => {
    getSeviarityMap({ id: "12345" });
  }, []);

  const lastDate = dateButtonObj[3].type;
  let networkData = { ...topHeaderAPIObject };
  networkData["end"] = moment().format("YYYY-MM-DD");
  networkData["start"] = moment(
    calculatePreviousDate(new Date(), lastDate)
  ).format("YYYY-MM-DD");

  const networkCall = () => {
    const newData = {
      ...networkData,
      companies: navigationData?.state?.companyId
        ? [navigationData?.state?.companyId]
        : [],
    };

    dispatch(incidentMapAction(newData));
  };

  const onDatePickerChange = useCallback((startDate, previousDate) => {
    if (startDate && previousDate) {
      networkData.companies =
        companyIdSearchList?.length > 0 ? companyIdSearchList : [];
      networkData.locations =
        locationIdSearchList?.length > 0 ? locationIdSearchList : [];
      networkData.start = startDate;
      networkData.end = previousDate;
      networkCall();
    }
  });

  const [filterESGData, setFilterESGData] = useState(filterESGDataInitialState);

  const toggleHandler = (payload, type) => {
    let toggleStates;
    let Data;
    if (type == "ESG_Data") {
      toggleStates = [payload.env, payload.gov, payload.soc];
      Data = { ...ESGLocationArray };
    } else {
      toggleStates = [payload.critical, payload.high, payload.low, payload.med];
      Data = { ...severityDataLocationArray };
    }
    let toggleState = true;
    for (const val of toggleStates) {
      if (val) {
        toggleState = true;
        break;
      } else {
        toggleState = false;
      }
    }

    const makeData = [];
    let data = Data?.features;
    if (type == "ESG_Data") {
      if (toggleState) {
        data?.forEach((element) => {
          if (element?.properties?.category == "environment" && payload.env) {
            makeData.push(element);
          }
          if (element?.properties?.category == "governance" && payload.gov) {
            makeData.push(element);
          }
          if (element?.properties?.category == "social" && payload.soc) {
            makeData.push(element);
          }
        });
      }
      const resultData = {
        isUseAble: toggleState,
        data: { type: Data.type, features: makeData },
      };

      setFilterESGData([resultData, filterESGData[1]]);
    } else {
      if (toggleState) {
        data?.forEach((element) => {
          if (element?.properties?.category == "critical" && payload.critical) {
            makeData.push(element);
          }
          if (element?.properties?.category == "high" && payload.high) {
            makeData.push(element);
          }
          if (element?.properties?.category == "low" && payload.low) {
            makeData.push(element);
          }
          if (element?.properties?.category == "medium" && payload.med) {
            makeData.push(element);
          }
        });
      }
      const resultData = {
        isUseAble: toggleState,
        data: { type: Data.type, features: makeData },
      };

      setFilterESGData([filterESGData[0], resultData]);
    }
  };

  const activeState = getActiveFilter(googleMapViewState === "ESG Category" ? filterESGMapData : severityDataLocationArray)

  const resetFilters = () => {
    setFilterESGData(filterESGDataInitialState)
    dispatch(filterESGMapDataAction(esgMapFilter));
    dispatch(filterSevertyMapDataAction(severtyMapFilter));
  }
  return (
    <div className="side-padding">
      {mapDataLoad && <Spin fullscreen />}
      <div className="main-layout table-layout">
        <section className="bg-sec mb-4 full-map-padding">
          <div className="fullmap-header-wrap pad-all-side">
            <div
              className="breadcrmbs"
              style={{
                width: "100vw",
                height: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb>
                <Breadcrumb.Item key="1" className="bad-link">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      resetFilters()
                      navigate(-1)
                    }}
                  >
                    <img src={Images.left} />
                    Back
                  </div>
                </Breadcrumb.Item>
                {/* <Breadcrumb.Item key="2" className="inc-link">
                  Incidents
                </Breadcrumb.Item> */}
                <Breadcrumb.Item key="3" className="lie-link">
                  Incidents Map
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="googleMapFilterBox">
                <div
                  onClick={() => setOpenAdvanceSearch(!openAdvanceSearch)}
                  className="googleMapFilter"
                >
                  <img className="filterIcon" src={ImageConst.filter} />
                </div>
                <DatePicker
                  onDatePickerChange={onDatePickerChange}
                  lastDate={lastDate}
                  resetFullMapFilter={resetFilters}
                />
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <MapTypeButton className="fullMap-btn" />
            <div>
              <FullMap
                type={"fullMap"}
                dataActiveType={googleMapViewState}
                data={
                  googleMapViewState === "ESG Category"
                    ? filterESGData[0]?.isUseAble
                      ? filterESGData[0]?.data
                      : ESGLocationArray
                    : filterESGData[1]?.isUseAble
                      ? filterESGData[1]?.data
                      : severityDataLocationArray
                }
                filterESGMapData={filterESGMapData}
                activeCategory={activeState}
                width={"100%"}
                height={"600px"}
              />
            </div>
            <div style={{ position: "absolute" }} className="fullMap-switch">
              <div className="fullMap-switch-wrap">
                {googleMapViewState === "ESG Category" ? (
                  <>
                    <ToggleButton
                      onClick={toggleHandler}
                      isVisible={filterESGMapData.env}
                      primaryColor={"#6EBD20"}
                      text={"Environment"}
                      disabled={ESGLocationArray?.length == 0}
                    />
                    <ToggleButton
                      onClick={toggleHandler}
                      isVisible={filterESGMapData.soc}
                      primaryColor={"#2191FB"}
                      text={"Social"}
                      disabled={ESGLocationArray?.length == 0}
                    />
                    <ToggleButton
                      onClick={toggleHandler}
                      isVisible={filterESGMapData.gov}
                      primaryColor={"#653DD4"}
                      text={"Governance"}
                      disabled={ESGLocationArray?.length == 0}
                    />
                  </>
                ) : null}
                {googleMapViewState === "Severity" ? (
                  <>
                    <ToggleButton
                      onClick={toggleHandler}
                      isVisible={filterSevertyMapData.critical}
                      primaryColor={"#800020"}
                      text={"Critical"}
                      disabled={severityDataLocationArray?.length == 0}
                    />
                    <ToggleButton
                      onClick={toggleHandler}
                      isVisible={filterSevertyMapData.high}
                      primaryColor={"#D00000"}
                      text={"High"}
                      disabled={severityDataLocationArray?.length == 0}
                    />
                    <ToggleButton
                      onClick={toggleHandler}
                      isVisible={filterSevertyMapData.med}
                      primaryColor={"#F28C28"}
                      text={"Moderate"}
                      disabled={severityDataLocationArray?.length == 0}
                    />
                    <ToggleButton
                      onClick={toggleHandler}
                      isVisible={filterSevertyMapData.low}
                      primaryColor={"#FFC000"}
                      text={"Minor"}
                      disabled={severityDataLocationArray?.length == 0}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
      <AdvanceSearch
        title={"Advance Search"}
        open={openAdvanceSearch}
        typeBar={"fullMap"}
        height={"513px"}
        onclose={() => setOpenAdvanceSearch(!openAdvanceSearch)}
      />
    </div>
  );
};
export default IncidentsList;
