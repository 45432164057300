import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
  isLoading: false,
  result: {},
  status:false,
  errorState: null,
  isExpired: null,
  message: null,
  success: null,
  passChanged: false,
  chngPassLinkVerify: false,
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_LOGIN_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: action.result,
        message: action.message,
      };
    case types.API_LOGIN_FAIL:
    
      return { ...state, isLoading: false, message: action.message ,status:true};

    case types.API_REGISTER_LOAD:
      return { ...state, isLoading: true, errorState: null };

    // SOCIAL LOGING
    case types.API_SOCIAL_LOGIN_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: action.result,
        message: action.message,
      };

    // Forgot Password
    case types.API_FORGOT_PASSWORD_LOAD:
      return { ...state, errorState: null, isLoading: true };
    case types.API_FORGOT_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, success: action.payload };

    //Verify Email Via Link
    case types.API_VERIFY_EMAIL_LOAD:
      return { ...state, errorState: null, isLoading: true };
    case types.API_VERIFY_EMAIL_SUCCESS:
      return { ...state, isLoading: false, chngPassLinkVerify: action.payload };

    case types.API_CHANGE_PASSWORD_LOAD:
      return { ...state, errorState: null, isLoading: true };
    case types.API_CHANGE_PASSWORD_FAIL:
      return { ...state, isLoading: false, success: action.payload };

    // /reset-password Email
    case types.API_RESET_EMAIL_PASSWORD_LOAD:
      return { ...state, errorState: null, isLoading: true };
    case types.API_RESET_EMAIL_PASSWORD_SUCCESS:
      return {
        ...state,
        errorState: null,
        isLoading: false,
        chngPassLinkVerify: false,
        passChanged: true,
      };
    case types.API_RESET_EMAIL_PASSWORD_FAIL:
      return { ...state, isLoading: false, success: action.payload };

    // Check Reset Password
    case types.API_CHECK_RESET_PASSWORD_LOAD:
      return { ...state, errorState: null };
    case types.API_CHECK_RESET_PASSWORD_SUCCESS:
      return { ...state, result: action.payload };

    // Reset Password
    case types.API_RESET_PASSWORD_LOAD:
      return { ...state, errorState: null };
    case types.API_RESET_PASSWORD_SUCCESS:
      return { ...state, isExpired: true, success: action.payload };

    case types.API_CHANGE_PASSWORD_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_CHANGE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, result: action.result };
    // verified Password

    case types.API_VERIFIED_PASSWORD_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_VERIFIED_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, isExpired: true };

    //Resend Email
    case types.API_RESEND_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_RESEND_SUCCESS:
      return { ...state, isLoading: false, isExpired: true };

    //Authentication Failed Case
    case types.API_AUTHENTICATION_FAILED:
      return { ...state, isLoading: false, isExpired: false };
    case types.API_AUTHENTICATION_ERROR:
      return { ...state, isLoading: false, isExpired: false };

    //Default case
    default:
      return { ...state };
  }
};
