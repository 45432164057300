import axios from "axios";
import { getToken } from "../../Utils";
import APIKit from "./apiKit";
import { convertArrayToCSV } from "convert-array-to-csv";
const statusCode = {
  success: 0,
  failure: 1,
  unAuthenticated: 2,
};

// headers
const jsonHeader = { "access-control-allow-origin": "*", "Content-Type": "application/json", Accept: "application/json", language: "en" }
const formDataHeader = { "access-control-allow-origin": "*", "Content-Type": "multipart/form-data", Accept: "application/json", language: "en" }

export const APIMethod = {
  GET(url, data, dataType = "json") {
    if (navigator.onLine) {
      return APIKit.get(url, data, {
        headers: dataType == 'json' ? jsonHeader : formDataHeader,
      })
        .then((data) => {
          if (data.status === 200) {
            return {
              result: data.data,
              status: statusCode.success,
            };
          } else if (data.status === 403 || data.status === 401) {
            return {
              result: { message: data.data.message },
              status: statusCode.unAuthenticated,
            };
          } else {
            return {
              result: { data: data, message: "request failed" },
              status: statusCode.failure,
            };
          }
        })
        .catch((e) => {
          if (e.response){
          if (e.response.status === 403 || e.response.status === 401) {
            return {
              result: { message: e.response.data.message },
              status: statusCode.unAuthenticated,
            };
          }
          if (e.response && e.response.status === 400) {
            return {
              result: { message: e.response.data.message },
              status: statusCode.failure,
            };
          }
        } else {
            return {
              result: {
                data: e.response.data.data,
                message: e.response.data.message,
              },
              status: statusCode.failure,
            };
          }
        });
    } else {
      return {
        result: { message: "Please check your internet connection." },
        status: statusCode.failure,
      };
    }
  },
  PUT(url, data, dataType = "json") {
    if (navigator.onLine) {
      return APIKit.put(url, data, {
        headers: dataType == 'json' ? jsonHeader : formDataHeader,
      })
        .then((data) => {
          if (data.status === 200) {
            return {
              result: data.data,
              status: statusCode.success,
            };
          } else if (data.status === 403 || data.status === 401) {
            return {
              result: { message: data.data.message },
              status: statusCode.unAuthenticated,
            };
          } else {
            return {
              result: { message: "request failed" },
              status: statusCode.failure,
            };
          }
        })
        .catch((e) => {

          if (e.response){
          if ( e.response.status === 403 || e.response.status === 401) {
            return {
              result: { message: e.response.data },
              status: statusCode.unAuthenticated,
            };
          }
          if (e.response && e.response.status === 400) {
            return {
              result: { message: e.response.data.message },
              status: statusCode.failure,
            };
          }
         } else {
            return {
              result: { message: e.response.data.message },
              status: statusCode.failure,
            };
          }
        });
    } else {
      return {
        result: { message: "Please check your internet connection." },
        status: statusCode.failure,
      };
    }
  },
  POST(url, data, dataType = "json") {
    if (navigator.onLine) {
      return APIKit.post(url, data, {
        headers: dataType == 'json' ? jsonHeader : formDataHeader,
      })
        .then((data) => {
          if (data.status === 200) {
            return {
              result: data.data,
              status: statusCode.success,
            };
          } else if (data.status === 403 || data.status === 401) {
            return {
              result: { message: data.data.message },
              status: statusCode.unAuthenticated,
            };
          } else {
            return {
              result: { message: "request failed" },
              status: statusCode.failure,
            };
          }
        })
        .catch((e) => {
            if (e.response) {
            if (e.response && e.response.status === 403 || e.response.status === 401 ) {
              return {
                result: { message: e.response.data.message },
                status: statusCode.unAuthenticated,
              };
            } else {
              return {
                result: { message: e.response.data.message },
                status: statusCode.failure,
              };
            }
            if (e.response && e.response.status === 400) {
              return {
                result: { message: e.response.data.message },
                status: statusCode.failure,
              };
            }
          }
          else{
              return {
            result: {
              message:"Something went wrong.",
            },
            status: statusCode.failure,
          }
          }
        });
    } else {
      return {
        result: { message: "Please check your internet connection." },
        status: statusCode.failure,
      };
    }
  },
  DELETE(url, data) {
    if (navigator.onLine) {
      return APIKit.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return {
              result: data.data,
              status: statusCode.success,
            };
          } else if (data.status === 403 || data.status === 401) {
            return {
              result: { message: data.data.message },
              status: statusCode.unAuthenticated,
            };
          } else {
            return {
              result: { message: "request failed" },
              status: statusCode.failure,
            };
          }
        })
        .catch((e) => {
          if (e.response){
          if ( e.response.status === 403 || e.response.status === 401) {
            return {
              result: { message: e.response.data.message },
              status: statusCode.unAuthenticated,
            };
          }
          if (e.response && e.response.status === 400) {
            return {
              result: { message: e.response.data.message },
              status: statusCode.failure,
            };
          }
        } else {
            return {
              result: { message: "request failed"},
              status: statusCode.failure,
            };
          }
        });
    } else {
      return {
        result: { message: "Please check your internet connection." },
        status: statusCode.failure,
      };
    }
  },
  PATCH(url, data) {
    if (navigator.onLine) {
      return APIKit.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return {
              result: data.data,
              status: statusCode.success,
            };
          } else if (data.status === 403 || data.status === 401) {
            return {
              result: { message: data.data.message },
              status: statusCode.unAuthenticated,
            };
          } else {
            return {
              result: { message: "request failed" },
              status: statusCode.failure,
            };
          }
        })
        .catch((e) => {
          if (e.response){
          if ( e.response.status === 403 || e.response.status === 401) {
            return {
              result: { message: e.response.data },
              status: statusCode.unAuthenticated,
            };
          }
          if (e.response && e.response.status === 400) {
            return {
              result: { message: e.response.data.message },
              status: statusCode.failure,
            };
          }
        } else {
            return {
              result: { message: e.response.data.message },
              status: statusCode.failure,
            };
          }
        });
    } else {
      return {
        result: { message: "Please check your internet connection." },
        status: statusCode.failure,
      };
    }
  },
  GETDATA(url, type) {
    return new Promise((resolve, reject) => {
      APIKit.get(url, {
        // responseType: 'arraybuffer',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/csv",
        },
      })
        .then((result) => {
          if (result.status === 200) {
            let Headers = [
              "Incident Title",
              "Summary",
              "Importance Score",
              "Involved Companies",
              "Esg Factors",
              "Date",
            ];
            if (type) {
              Headers = [
                "Incident Title",
                "Summary",
                "Sevrerity Score" ,
                "Locations",
                "Date",
              ]
            }

            let header = Headers;
            const csvFromArrayOfObjects = convertArrayToCSV(result.data, {
              header,
            });
            // CsvDataService.exportToCsv(`${fileName}.csv`, result.data)
            const url = window.URL.createObjectURL(
              new Blob([csvFromArrayOfObjects])
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "INCIDENT" + ".csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
            // const url = window.URL.createObjectURL(new Blob([result.data]));
            //   const link = document.createElement('a');
            //   link.href = url;
            //   link.setAttribute('download', fileName + '.csv'); //or any other extension
            //   document.body.appendChild(link);
            //   link.click();
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 4,
                error: "Something went wrong.",
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return resolve({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: "Something went Wrong",
            });
          }
        });
    });
  },
  GETEXPORTDATA(url, key) {
    return new Promise((resolve, reject) => {
      APIKit.get(url, {
        // responseType: 'arraybuffer',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/csv",
        },
      })
        .then((result) => {
          if (result.status === 200) {
            let Headers =key?[
              "Company",
              "Risk", 
              "Industry",
              "Percentage",
         
            ]: [
              "Company",
              "Incidents",
              "Relationship Type",
              "Industry",
              "Location",
            ];

            let header = Headers;
            const csvFromArrayOfObjects = convertArrayToCSV(result.data, {
              header,
            });

            const url = window.URL.createObjectURL(
              new Blob([csvFromArrayOfObjects])
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download",(key? "Companies": "INCIDENT") + ".csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 4,
                error: "Something went wrong.",
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status == 500) {
                return resolve({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status == 403) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status == 400 ||
                err.response.status == 401 ||
                err.response.status == 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: "Something went Wrong",
            });
          }
        });
    });
  },
};
