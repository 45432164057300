import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";
import {
  calculateGraphData,
  createGraphSvg,
  drawOuterArc,
  makeCenterPie,
  makeTooltipForOuterArc,
} from "./utils";
import IncidentsListModal from "../../../IncidentsListModal";
let graphCalculateData;
const SunChart = (props) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const graphData = props.graphData.map(e => {
    if (e.name.startsWith(". ")) {
      e.name = e.name.slice(2)
    }
    return e
  })
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  useEffect(() => {
    createGraphSvg(svgRef, props.id);
    makeTooltipForOuterArc(tooltipRef);
    // const graphData = [
    //   { label: "Waste Management", risk: 201, type: "E" },
    //   { label: "Biodiversity Loss", risk: 3, type: "E" },
    //   { label: "Water Scarcity", risk: 33, type: "E" },
    //   { label: "Climate Change", risk: 12, type: "E" },
    //   { label: "Pollution", risk: 55, type: "E" },
    //   { label: "Health and Safety", risk: 12, type: "S" },
    //   { label: "Customer Relations", risk: 3, type: "S" },
    //   { label: "Community Impact", risk: 33, type: "S" },
    //   { label: "Human Rights", risk: 21, type: "S" },
    //   { label: "Labor Practices", risk: 55, type: "S" },
    //   { label: "Board Composition", risk: 12, type: "G" },
    //   { label: "Executive Compensation", risk: 3, type: "G" },
    //   { label: "Shareholder Rights", risk: 33, type: "G" },
    //   { label: "Business Ethics", risk: 21, type: "G" },
    //   { label: "Regulatory Compliance", risk: 55, type: "G" },
    // ];
    if (graphData.length > 0) {
      let x = 0;
      let y = 12.36;
      graphCalculateData = calculateGraphData(graphData, props);
      for (let i = 0; i < graphData.length; i++) {
        drawOuterArc({ x: x, y: x + y }, i, graphData[i], svgRef, tooltipRef, modalVisible);
        x = x + y + 2;
      }
      makeCenterPie(svgRef, graphCalculateData, props.risk, modalVisible);
    }
    return () => {
      document.body.querySelector("#companyChart")?.remove();
      document.body.querySelector(".sun-chart-container-tooltip").remove();
    };
  }, [svgRef, graphData]);

  const modalVisible = (e, key) => {
    if (key) {
     
      props.networkData.esg_categories = e === "E" ? ["Environment"] :e === "S" ? ["Social"] : ["Governance"]
      setModalVisible(true)
    } else {
      props.networkData.esg_factors = [e.id]
      props.networkData.esg_factorsName = [e.name]
      setModalVisible(true)
    }

  }
  return (
    <>
      <div className="companyChartSvgContainer" ref={svgRef} id={props.id} ></div>
      {isModalVisible ? (
        <IncidentsListModal
          setModalVisible={(e) => {
            setModalVisible(e);
          }}
          networkData={{
            ...props.networkData,
            companies: [props.Id],
            companyName: props.companyName,
          }}
        />
      ) : null}
    </>
  );
};

export default SunChart;

