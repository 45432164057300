/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "../reducers";

const initialState = {
  location: undefined,
  selectedRelation: undefined,
  selectedRelationDirection: undefined,
  selectedEntities: [],
  loading: false,
  searchQuery: "",
  targetNodeId: undefined,
  show: false,
};
const contextMenuSlice = createSlice({
  name: "contextMenu",
  initialState,
  reducers: {
    setContextMenuLocation: (state, { payload: contextMenuLocation }) => {
      state.location = contextMenuLocation;
    },
    setContextMenuSelectedRelation: (state, { payload: selectedRelation }) => {
      state.selectedRelation = selectedRelation;
    },
    setContextMenuSelectedRelationDirection: (
      state,
      { payload: selectedRelationDirection }
    ) => {
      state.selectedRelationDirection = selectedRelationDirection;
    },
    setContextMenuLoading: (state, { payload: contextMenuLoading }) => {
      state.loading = contextMenuLoading;
    },
    setContextMenuSelectedEntities: (state, { payload: selectedEntities }) => {
      state.selectedEntities = selectedEntities;
    },
    setContextMenuSearchQuery: (state, { payload: searchQuery }) => {
      state.searchQuery = searchQuery;
    },
    setContextMenuTargetNodeId: (state, { payload: targetNodeId }) => {
      state.targetNodeId = targetNodeId;
    },
    setContextMenuShow: (state, { payload: show }) => {
      state.show = show;
    },
    resetContextMenu: (state) => {
      state.loading = initialState.loading;
      state.location = initialState.location;
      state.selectedRelation = initialState.selectedRelation;
      state.selectedEntities = initialState.selectedEntities;
      state.selectedRelationDirection = initialState.selectedRelationDirection;
      state.searchQuery = initialState.searchQuery;
      state.targetNodeId = initialState.targetNodeId;
      state.show = initialState.show;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});
export const {
  setContextMenuLocation,
  setContextMenuSelectedRelation,
  setContextMenuLoading,
  resetContextMenu,
  setContextMenuSelectedRelationDirection,
  setContextMenuSelectedEntities,
  setContextMenuSearchQuery,
  setContextMenuTargetNodeId,
  setContextMenuShow,
} = contextMenuSlice.actions;
export const contextMenuReducer = contextMenuSlice.reducer;
