import React from "react";
import { Box, IconButton, SvgIcon, useTheme } from "@mui/material";
import { useAppSelector } from "../../../redux/configuareStore";
import { useGraphObject } from "../../context/MyContext";
import CustomTooltip from "../../GraphToolTip/CustomTooltip";

function RedoButton() {
  const canRedo = useAppSelector((state) => state.toolBox.toolBox.canRedo);
  const { graphObject } = useGraphObject();
  const theme = useTheme();
  return (
    <Box>
      <CustomTooltip title="Redo" placement="left">
        <span>
          <IconButton onClick={() => graphObject?.redo()} disabled={!canRedo}>
            {/* Redo Icon */}
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 8H7.5C5.01472 8 3 10.0147 3 12.5C3 14.9853 5.01472 17 7.5 17H21M21 8L18 5M21 8L18 11"
                  stroke={
                    canRedo ? theme.palette.gray.main : theme.palette.gray[200]
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
          </IconButton>
        </span>
      </CustomTooltip>
    </Box>
  );
}
export default RedoButton;
