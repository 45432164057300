import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import { severityBarMapping } from "../../../../Utils";
import BubleIncidentsList from "../../../bubleIncidentsList";
import { Modal } from "antd";

let listArray = [];

const IncidentSeverityBar = ({ data, setSpecificIncidentIds, setModalVisible}) => {
  const seriesData = useMemo(()=>{
    return severityBarMapping(data)
  }, [data])

  const DataPointSelection = useCallback(() => {
    const options =  {
        chart: {
          type: "bar",
          height: 350,
          width:320,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointSelection: (event, chartContext, selectedInfo)=>{
              const ids = data[selectedInfo.dataPointIndex === 0 ? "HIGH" : selectedInfo.dataPointIndex === 1 ? "MEDIUM" : "LOW"]?.map(e => e.id)
              if(!document.querySelector("div.ant-modal-wrap.ant-modal-centered")){ // check if any modal not already open
                setSpecificIncidentIds(ids)
                setModalVisible(true)
              }
            }
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            borderRadiusApplication: "end",
          },
        },
        yaxis: {
          tickAmount: 6,
        },
        legend: {
          show: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            opacityTo: 0.6,
            opacityFrom: 1,
            stops: [0, 90, 100],
            shadeIntensity: 0.1,
          },
        },
        grid: {
          borderColor: "#E7E9F0",
          strokeDashArray: 10,
          padding: {
            right: 25,
            left: 15,
          },
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
    };
    return <ReactApexChart
          options={options}
          series={seriesData}
          type="bar"
          height={300}
          width={320}
        />
    }
  , [data, seriesData])
  
  return (
        <DataPointSelection/>
  );
};

export default IncidentSeverityBar;
