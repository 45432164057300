import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { Checkbox, Radio, Select, Space, Table } from "antd";
import { Breadcrumb } from "antd";
import { Pagination } from "antd";
import { Tooltip } from "antd";
import { Modal } from "antd";
// import CompaniesListModalTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesAction,
  getEsgCountriesAction,
  getEsgFactorListForModalAction,
  getEsgFactorsAction,
  getCountryListForModalAction,
  incindentAction,
  updateIncidentState,
  getIncidentListForModalAction,
  getCompanyListForModal,
} from "../../Redux/Action/incidents";
import { preventInvalidNumberInput } from "../../Utils/globalFunction";
import { useRef } from "react";
import GlobalLoder from "../../Utils/GlobalLoder";
import {
  getCorpCompaniesListLoad,
  getCorpIncidentBarLoad,
} from "../../Redux/Action/corporateProf";
import { checkRiskColor, getRiskVal } from "../../Utils";
import { useNavigate } from "react-router-dom";

const text = <span>Download</span>;

const CompaniesListModal = ({ networkData, setModalVisible }) => {
  const navigate = useNavigate();

  const incidents = useSelector(
    (state) => state?.incidentReducer?.incidentsForModal
  );
  const { isLoading } = useSelector((state) => state?.incidentReducer);

  const { companiesList, companiesPagination, companiesListLoader } =
    useSelector((state) => state.corporateProfile);
  const [perPageValue, setPerPageValue] = useState("");
  const [payloadState, setPayloadState] = useState({
    limit: 25,
    offset: 0,
    ...networkData,
  });

  const companyListColumn = [
    {
      title: "Company",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, _, i) => {
        return (
          <a style={{ display: "flex" }}>
            <span className="col-index">{`${i + 1 + payloadState.offset} `}</span>
            <a
              onClick={() => navigate("/companyPage", { state: { id: _.id } })}
              className="titleText theme-dark-text"
              style={{
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxLines: 1,
                textOverflow: "ellipsis",
              }}
            >{`${text}`}</a>
          </a>
        );
      },
      className: "companyPage-col1",
    },
    {
      title: "Ticker Symbol",
      dataIndex: "tickers",
      key: "tickers",

      render: (text, _, i) => {
        return (
          <div
            style={{
              color: "#07132F",
              fontWeight: 500,
            }}
          >
            {text && text.length > 0 ? text.join(", ") : ""}
          </div>
        );
      },
      className: "companyPage-col1",
    },
    {
      title: "ESG Risk",
      dataIndex: "risk",
      // className: `company-supplyChain`,
      key: "risk",
      sorter: true,
      render: (text, _, item) => (
        <div
          style={{
            backgroundColor: checkRiskColor[getRiskVal(text)],
            display: "flex",
            justifyContent: "center",
            // marginLeft: 10,
            width: "40px",
            height: "40px",
            alignItems: "center",
          }}
        >
          <span>
            <p
              style={{ color: "white", width: "fit-content", fontWeight: 500 }}
            >
              {text ? Math.round(text) : "0"}
            </p>
          </span>
        </div>
      ),
    },
    {
      title: "Market Value",
      dataIndex: "marketValue",
      className: `company-supplyChain`,
      sorter: true,
      render: (text, _, item) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 10,
            width: "50px",
            alignItems: "center",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Return",
      dataIndex: "marketValue",
      className: `company-supplyChain`,
      sorter: true,
      render: (text, _, item) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 10,
            width: "50px",
            alignItems: "center",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Shares",
      dataIndex: "risk_change",
      className: `company-supplyChain`,

      render: (text, _, item) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 10,
            width: "20px",
            alignItems: "center",
          }}
        >
          <span>
            <p
              style={{
                color: text ? (text < 0 ? "#B70000" : "#168535") : "#000",
                width: "fit-content",
                fontWeight: 500,
              }}
            >
              {text ? text + "%" : "N/A"}
            </p>
          </span>
        </div>
      ),
    },
    {
      title: "Stock Price",
      dataIndex: "incident_count",
      className: `company-supplyChain`,
      width: "15%",
      render: (text, _, item) => (
        <span className="supply-chain-incident-column">
          <a className="theme-link-style"></a>
        </span>
      ),
    },
  ];

  const dispatch = useDispatch();
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <span>Previous</span>;
    }
    if (type === "next") {
      return <span>Next</span>;
    }
    return originalElement;
  };

  useEffect(() => {
    // dispatch(getEsgCountriesAction());
    // dispatch(getEsgFactorListForModalAction());
    // dispatch(getCountryListForModalAction());
    // dispatch(getCompanyListForModal());
    dispatch(
      getCorpCompaniesListLoad({
        ...payloadState,
        companyId: payloadState.companies[0],
        relation: [payloadState?.type],
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getCorpCompaniesListLoad({
        ...payloadState,
        companyId: payloadState.companies[0],
        relation: [payloadState?.type],
      })
    );
  }, [payloadState]);
  const handleChange = (value) => {};

  const onPaginationChange = (page) => {
    if (page) {
      setPayloadState((prev) => {
        return { ...prev, offset: (page - 1) * prev.limit };
      });
    }
  };

  const handleLimitChange = (limit) => {
    setPayloadState((prev) => {
      return { ...prev, limit: limit };
    });
  };

  const handeGoToPageInput = (e) => {
    let newVal = e.target.value.replace("e", "");
    if (
      +newVal &&
      +newVal > Math.ceil(companiesPagination?.totalCount / payloadState.limit)
    ) {
      newVal = Math.ceil(companiesPagination?.totalCount / payloadState.limit);
    }
    if ((+newVal || newVal === "0") && 1 > +newVal) {
      newVal = 1;
    }
    setPerPageValue(newVal);
  };

  const handleSort = (e, f, sort) => {
    const sortKey =
      sort.column?.dataIndex == "severity_score"
        ? "severity_score.value"
        : sort.column?.dataIndex;
    setPayloadState((prev) => ({
      ...prev,
      sort: sortKey,
      order: sort.order == "descend" ? -1 : 1,
    }));
  };

  return (
    <Modal
      className="incident-page-table-modal-ps "
      centered
      open={true}
      closable={false}
      onCancel={() => setModalVisible(false)}
      footer={[]}
    >
      <div className="user-content-modal-ps">
        <div className="side-padding inc-outer-padding">
          <div className="bg-sec main-layout table-layout ">
            <section className=" incidents-table-modal-ps">
              <div className="breadcrmbs mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item
                    key="1"
                    className="bad-link"
                    onClick={() => {
                      updateIncidentState([], "");
                      setModalVisible(false);
                    }}
                  >
                    {" "}
                    <img src={Images.left} />
                    Back
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="2" className="inc-link">
                    {" "}
                    Corporate Profile
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="3" className="lie-link">
                    {" "}
                    Supply Chain
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {companiesListLoader && (
                <div className="global-loader-dv">
                  <GlobalLoder />
                </div>
              )}
              <div
                className="table-page-sec "
                style={{ display: companiesListLoader && "none" }}
              >
                <div className="topp-div">
                  <div className="table-head-sec">
                    <div className="serch-bar-in">
                      <span>
                        {" "}
                        <img className="me-2" alt="" src={Images.sear} />
                      </span>
                      <input
                        type="text"
                        placeholder="Search keywords"
                        value={payloadState.search}
                        onChange={(e) => {
                          setPayloadState((prev) => ({
                            ...payloadState,
                            search: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="right-side-colm">
                    <Tooltip placement="top" title={text}>
                      <div className="table-downl-btn">
                        <img src={Images.framedow} />
                      </div>
                    </Tooltip>
                  </div>
                </div>

                <div className="table-box incident">
                  {/* <CompaniesListModalTable
                    incidents={incidents}
                    payloadState={payloadState}
                    setPayloadState={setPayloadState}
                    tableHeaderArray={tableHeaders}
                  /> */}
                  {/* <ReferenceTable/>    */}
                  <Table
                    scroll={{
                      x: 1200,
                      y: 500,
                    }}
                    onChange={handleSort}
                    columns={companyListColumn}
                    sortDirections={["ascend", "descend", "ascend"]}
                    dataSource={companiesList}
                    pagination={false}
                    loading={companiesListLoader}
                  />
                </div>

                <div className="page-nation">
                  <div className="row-per">
                    <span>Rows per page:</span>
                    <Select
                      value={payloadState.limit}
                      style={{ width: 60 }}
                      onChange={handleLimitChange}
                      options={[
                        { value: 25, label: "25" },
                        { value: 30, label: "30" },
                        { value: 35, label: "35" },
                      ]}
                    />
                  </div>

                  <div className="page-num">
                    <span>
                      <img src={Images.chevron} />
                    </span>{" "}
                    <Pagination
                      onChange={onPaginationChange}
                      pageSize={payloadState.limit}
                      total={companiesPagination.totalCount}
                      itemRender={itemRender}
                      current={
                        Math.ceil(payloadState.offset / payloadState.limit) + 1
                      }
                    />{" "}
                    <span>
                      <img src={Images.chevronRighta} />
                    </span>
                  </div>

                  <div className="goto-pg pg-number">
                    <span>Go to page:</span>
                    <input
                      type="number"
                      name="search2"
                      value={perPageValue}
                      onKeyDown={preventInvalidNumberInput}
                      onChange={handeGoToPageInput}
                    />
                    <button
                      type="button"
                      onClick={() => onPaginationChange(perPageValue)}
                    >
                      Go
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CompaniesListModal;
