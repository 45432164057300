import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { Row } from "antd";
import { Modal } from "antd";
import { Tabs } from "antd";
import { Switch } from "antd";
import { dispatch } from "../../Utils/dispatchNavigate";
import { useSelector } from "react-redux";
import CheckBox from "../../Componets/checkBox";
import { useLocation, useNavigate } from "react-router-dom";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";

import "./style.css";
import {
  getAdvncCategoryDataLoad,
  getAdvncCompanyDataLoad,
  getAdvncIncLocationDataLoad,
  getAdvncIndustryDataLoad,
  getAdvncTopSearchDataLoad,
  updateAdvanceCategoriesData,
  getAdvncCompanyDataSuccess,
  updateAdvncTopSearchData,
  updateAdvSearchLocationArray,
  updateAdvanceSearchState,
  updateIndustryArray,
  updateAdvncCompanyDataSuccess,
  getUpdateAdvncCompanyDataSuccess,
} from "../../Redux/Action/advanceSearch";
import { debounce } from "../../Utils";
import { selectedItemHandler } from "./utils-function";
import { updateIncidentData } from "../../Redux/Action/incidents";
import CompanyLoader from "../companyLoader";

const selectedSearchArrayItem = [
  { groupName: "Top Searches", selectedItemLength: 0, data: [] },
  { groupName: "Company", selectedItemLength: 0, data: [] },
  { groupName: "Incident Location", selectedItemLength: 0, data: [] },
  { groupName: "Categories", selectedItemLength: 0, data: [] },
  { groupName: "Industry", selectedItemLength: 0, data: [] },
  { groupName: "Health Care", selectedItemLength: 0, data: [] },
  {
    groupName: "Extractives & Minerals Processing",
    selectedItemLength: 0,
    data: [],
  },
  { groupName: "ESG Factors", selectedItemLength: 0, data: [] },
  { groupName: "SDGs", selectedItemLength: 0, data: [] },
  { groupName: "PAI Indicators", selectedItemLength: 0, data: [] },
  {
    groupName: "UN global compact principles",
    selectedItemLength: 0,
    data: [],
  },
];

const getTopSearchLabel = {
  esg_factor: "ESG Factors",
  company: "Company",
  location: "Incident Location",
  esg_sdg: "SDGs",
  esg_pai: "PAI Indicators",
  industry: "Industry",
};

let countries = [];

let topSearchCompanies = [];
let cacheActiveTab = "topSearch";
let updateTopSearchArray = false;
let cloneCategoryData = null
let cloneLocationData = null
let cloneIndustryData = null


const getSelectedInddustryItem = (data) => {
  let resultArray = [];
  for (let i = 0; i <= data.length - 1; i++) {
    resultArray = [...resultArray, ...data[i].data];
  }
  resultArray = resultArray.filter((i) => i.status).map((i) => i.id);
  return resultArray;
};

let selectedItem = {
  company: 0,
  incidentLocation: 0,
  industry: 0,
  ESGFactors: 0,
};

const updateIncidentLocationArrayWithTopSearch = (
  incedentLocationArray,
  topSearchlocationArray,
  dispatch
) => {
  if (incedentLocationArray && topSearchlocationArray !== undefined) {
    let totalCount = 0;
    let selectedTotalItem = 0;
    const cloneLocationArray = [...incedentLocationArray];
    for (let i = 0; i <= cloneLocationArray?.length - 1; i++) {
      const childArray = cloneLocationArray[i].countries;
      const childArrayLength = childArray.length;
      let selectedChildArrayLength = 0;
      for (let j = 0; j <= childArray?.length - 1; j++) {
        totalCount += 1;
        for (let k = 0; k <= topSearchlocationArray?.length - 1; k++) {
          const element = topSearchlocationArray[k];
          if (element == childArray[j].name) {
            childArray[j].status = true;
            selectedTotalItem += 1;
            selectedChildArrayLength += 1;
            break;
          }
        }
      }
      if (childArrayLength == selectedChildArrayLength) {
        cloneLocationArray[i].continent_status = true;
      }
    }
    selectedItem.incidentLocation = selectedTotalItem;

    const payload = {
      allStatus: totalCount === selectedTotalItem,
      continents: cloneLocationArray,
    };
    dispatch(updateAdvSearchLocationArray(payload));
  }
};
let checkOnlyOneTimeUNGC = false;
const updateCategoryArrayWithTopSearch = (
  categoryData,
  topSearchlcategoryArray,
  dispatch
) => {
  const clone = [...categoryData];
  if (
    categoryData &&
    topSearchlcategoryArray?.esg_factors !== undefined &&
    clone[0]
  ) {
    let firstItemOfCloneArray = clone[0];
    let totalLengthOfFirstItems = 0;
    let selectedtotalLengthOfFirstItems = 0;
    for (let i = 0; i <= firstItemOfCloneArray?.value?.length - 1; i++) {
      const element = firstItemOfCloneArray?.value[i].data;
      for (let j = 0; j <= element?.length - 1; j++) {
        totalLengthOfFirstItems += 1;
        for (let k = 0; k <= topSearchlcategoryArray?.esg_factors?.length - 1; k++) {
          if (element[j].id == topSearchlcategoryArray?.esg_factors[k]) {
            selectedtotalLengthOfFirstItems += 1;
            element[j].status = true;
          }
        }
      }
    }
    selectedItem.ESGFactors = selectedtotalLengthOfFirstItems;
    if (firstItemOfCloneArray) {
      firstItemOfCloneArray.status = totalLengthOfFirstItems > 0 && selectedtotalLengthOfFirstItems > 0 ?
        totalLengthOfFirstItems === selectedtotalLengthOfFirstItems : false;
    }
    const trackAllStatus = clone[0]?.value
      ?.map((item) => item?.data?.map((Val) => Val?.status ? true : false))
      .flat();
    clone[0].status = trackAllStatus?.length > 0 ? trackAllStatus?.includes(false) ? false : true : false;

  }
  if (
    categoryData &&
    topSearchlcategoryArray?.ungc_breaches?.length > 0 &&
    checkOnlyOneTimeUNGC == false
  ) {
    checkOnlyOneTimeUNGC = true;
    const collectedIdDataFromPrev = topSearchlcategoryArray?.ungc_breaches?.map(
      (item) => item.id
    );
    const ungc = clone[1];
    const firstUngc = ungc?.value[0];
    if (firstUngc && firstUngc?.data) {
      firstUngc.data = firstUngc?.data?.map((val) => ({
        ...val,
        status: collectedIdDataFromPrev?.includes(val.id),
      }));
    }

    const secondUngc = ungc?.value[1];
    if (secondUngc && secondUngc.data) {
      secondUngc.data = secondUngc?.data?.map((val) => ({
        ...val,
        status: collectedIdDataFromPrev?.includes(val.id),
      }));
    }
    const thirdUngc = ungc?.value[2];
    if (thirdUngc && thirdUngc.data) {
      thirdUngc.data = thirdUngc?.data?.map((val) => ({
        ...val,
        status: collectedIdDataFromPrev?.includes(val.id),
      }));
    }
    const fourthUngc = ungc?.value[3];
    if (fourthUngc && fourthUngc.data) {
      fourthUngc.data = fourthUngc?.data?.map((val) => ({
        ...val,
        status: collectedIdDataFromPrev?.includes(val?.id),
      }));
    }
    const allSelectedItems = ungc?.value
      .map((item) => item?.data?.map((Val) => Val?.status))
      .flat();
    if (clone && clone[1]) {
      clone[1].status = !allSelectedItems?.includes(false);
    }
  }
  dispatch(updateAdvanceCategoriesData(clone, 2));
};

const updateIndustryArrayWithTopSearch = (
  industryData,
  topSearchlIndustryArray,
  dispatch
) => {
  if (industryData && topSearchlIndustryArray !== undefined) {
    const clone = [...industryData];
    let countSelectedItem = 0;
    for (let i = 0; i <= clone?.length - 1; i++) {
      const element = clone[i].data;
      let childTotalItem = 0;
      let selectedtotalLengthOfFirstItems = 0;
      for (let j = 0; j <= element?.length - 1; j++) {
        childTotalItem += 1;
        for (let k = 0; k <= topSearchlIndustryArray?.length - 1; k++) {
          if (element[j].id == topSearchlIndustryArray[k]) {
            selectedtotalLengthOfFirstItems += 1;
            countSelectedItem += 1;
            element[j].status = true;
          }
        }
      }
      clone[i].status = childTotalItem === selectedtotalLengthOfFirstItems;
    }
    selectedItem.industry = countSelectedItem;
    dispatch(updateIncidentData(clone));
    return countSelectedItem;
  }
};

let cacheDataFillUp = [];
let chache_WhichTaskDone = {
  "Top Searches": false,
  Company: false,
  "Incident Location": false,
  "ESG Factors": false,
  "UN global compact principles": false,
  Industry: false,
};
let shouldUpdateLocation = false
let shouldUpdateESG_Category = false

const selectItemsFinder = (
  currentArray,
  previousArray,
  setChips,
  type,
  itemIndex,
  action,
  setAction
) => {
  const modiFiedMatchItems = [];
  let selectedDataLength = 0;
  let dataFillUp = [
    {
      groupName: "Top Searches",
      selectedItemLength:
        type == "Top Searches"
          ? selectedDataLength
          : cacheDataFillUp[0]?.selectedItemLength,
      data:
        type == "Top Searches"
          ? modiFiedMatchItems
          : cacheDataFillUp[0]?.data?.length > 0
            ? cacheDataFillUp[0].data
            : [],
    },
    {
      groupName: "Company",
      selectedItemLength:
        type == "Company"
          ? selectedDataLength
          : cacheDataFillUp[1]?.selectedItemLength,
      data:
        type == "Company"
          ? modiFiedMatchItems
          : cacheDataFillUp[1]?.data?.length > 0
            ? cacheDataFillUp[1].data
            : [],
    },
    {
      groupName: "Incident Location",
      selectedItemLength:
        type == "Incident Location"
          ? selectedDataLength
          : cacheDataFillUp[2]?.selectedItemLength,
      data:
        type == "Incident Location"
          ? modiFiedMatchItems
          : cacheDataFillUp[2]?.data?.length > 0
            ? cacheDataFillUp[2].data
            : [],
    },
    {
      groupName: "ESG Factors",
      selectedItemLength:
        type == "ESG Factors"
          ? selectedDataLength
          : cacheDataFillUp[3]?.selectedItemLength,
      data:
        type == "ESG Factors"
          ? modiFiedMatchItems
          : cacheDataFillUp[3]?.data?.length > 0
            ? cacheDataFillUp[3].data
            : [],
    },
    {
      groupName: "UN global compact principles",
      selectedItemLength:
        type == "UN global compact principles"
          ? selectedDataLength
          : cacheDataFillUp[4]?.selectedItemLength,
      data:
        type == "UN global compact principles"
          ? modiFiedMatchItems
          : cacheDataFillUp[4]?.data?.length > 0
            ? cacheDataFillUp[4].data
            : [],
    },
    {
      groupName: "Industry",
      selectedItemLength:
        type == "Industry"
          ? selectedDataLength
          : cacheDataFillUp[5]?.selectedItemLength,
      data:
        type == "Industry"
          ? modiFiedMatchItems
          : cacheDataFillUp[5]?.data?.length > 0
            ? cacheDataFillUp[5].data
            : [],
    },
  ];
  const matchItems = [];

  const CheckWithId = [
    "company",
    "esg_factor",
    "esg factors",
    "un global compact principles",
    "industry",
  ];
  const CheckWithName = ["location", "incident location"];
  if (action == "remove" || action == "update") {
    dataFillUp[itemIndex].data = currentArray;
  } else {
    for (let i = 0; i < currentArray?.length; i++) {
      for (let j = 0; j < previousArray?.length; j++) {
        const lowerCaseCompany = type.toLowerCase();
        const checkTypeWithId = currentArray[i]?.type || lowerCaseCompany;
        let checkWithId;
        if (CheckWithId.includes(checkTypeWithId)) {
          if (checkTypeWithId == "un global compact principles") {
            checkWithId = currentArray[i]?.id == previousArray[j].id;
          } else {
            checkWithId = currentArray[i]?.id == previousArray[j];
          }
        }
        if (CheckWithName.includes(checkTypeWithId)) {
          checkWithId = currentArray[i]?.name == previousArray[j];
        }

        if (checkWithId) {
          matchItems.push(currentArray[i]);
          break;
        }
      }
    }
    for (let i = 0; i < matchItems?.length; i++) {
      let isMatched = false;
      let dataArray = cacheDataFillUp[itemIndex]?.data || [];
      for (let j = 0; j < dataArray?.length; j++) {
        const item = dataArray[j];
        const lowerCaseCompany = type.toLowerCase();
        const checkTypeWithId = item.type || lowerCaseCompany;
        let checkWithId;
        if (CheckWithId.includes(checkTypeWithId)) {
          checkWithId = matchItems[i].id == item.id;
        }
        if (CheckWithName.includes(checkTypeWithId)) {
          checkWithId = matchItems[i].name == item.name;
        }
        if (checkWithId) {
          isMatched = true;
          break;
        }
      }
      if (!isMatched) {
        modiFiedMatchItems.push(matchItems[i]);
      }
    }
    dataFillUp[itemIndex].data = [
      ...(cacheDataFillUp[itemIndex]?.data || []),
      ...modiFiedMatchItems,
    ];
  }
  selectedDataLength = dataFillUp[itemIndex]?.data?.length;
  dataFillUp[itemIndex].selectedItemLength = selectedDataLength;
  cacheDataFillUp = dataFillUp;
  if (itemIndex == 1 || itemIndex == 0) {
    if (
      cacheDataFillUp[0]?.data?.length > 0 &&
      cacheDataFillUp[1]?.data?.length > 0
    ) {
      let correctionZeroIndex = cacheDataFillUp[0]?.data;
      let companyArray = cacheDataFillUp[1]?.data.map((val) => val.id);
      const updateCorrectionZeroIndex = [];
      for (let i = 0; i < correctionZeroIndex.length; i++) {
        if (!companyArray.includes(correctionZeroIndex[i].id)) {
          updateCorrectionZeroIndex.push(correctionZeroIndex[i]);
        }
      }
      let uniqueCompanyArray = [...new Set(companyArray)];
      cacheDataFillUp[1].data = cacheDataFillUp[1]?.data
        ?.map((item) => {
          if (uniqueCompanyArray.includes(item.id)) {
            const getIndex = uniqueCompanyArray.indexOf(item.id); // Find the index of the item's id in uniqueCompanyArray
            uniqueCompanyArray.splice(getIndex, 1);
            return item;
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);
      cacheDataFillUp[0].data = updateCorrectionZeroIndex;
    }
  }
  if (itemIndex == 2 || itemIndex == 0) {
    if (
      cacheDataFillUp[0]?.data?.length > 0 &&
      cacheDataFillUp[2]?.data?.length > 0
    ) {
      let correctionZeroIndex = cacheDataFillUp[0]?.data;
      let companyArray = cacheDataFillUp[2]?.data;
      const updateCorrectionZeroIndex = [];
      for (let i = 0; i < correctionZeroIndex.length; i++) {
        if (correctionZeroIndex[i].type !== "location") {
          updateCorrectionZeroIndex.push(correctionZeroIndex[i]);
        }
      }
      cacheDataFillUp[0].data = updateCorrectionZeroIndex;
    }
  }
  if (itemIndex == 3 || itemIndex == 0) {
    if (
      cacheDataFillUp[0]?.data?.length > 0 &&
      cacheDataFillUp[3]?.data?.length > 0
    ) {
      let correctionZeroIndex = cacheDataFillUp[0]?.data;
      let companyArray = cacheDataFillUp[3]?.data;
      const updateCorrectionZeroIndex = [];
      for (let i = 0; i < correctionZeroIndex.length; i++) {
        if (correctionZeroIndex[i].type !== "esg_factor") {
          updateCorrectionZeroIndex.push(correctionZeroIndex[i]);
        }
      }
      cacheDataFillUp[0].data = updateCorrectionZeroIndex;
    }
  }
  if (itemIndex == 5 || itemIndex == 0) {
    if (
      cacheDataFillUp[0]?.data?.length > 0 &&
      cacheDataFillUp[5]?.data?.length > 0
    ) {
      let correctionZeroIndex = cacheDataFillUp[0]?.data;
      const updateCorrectionZeroIndex = [];
      for (let i = 0; i < correctionZeroIndex.length; i++) {
        if (correctionZeroIndex[i].type !== "industry") {
          updateCorrectionZeroIndex.push(correctionZeroIndex[i]);
        }
      }
      cacheDataFillUp[0].data = updateCorrectionZeroIndex;
    }
  }
  setAction && setAction(null);
  setChips(cacheDataFillUp);
};

const updatePreviousData = (data, action, item, type, removeTargetArray) => {
  const cloneData = { ...data };
  if (removeTargetArray) {
    cloneData[type] = [];
  } else {
    if (action == "add") {
      cloneData[type] = [...cloneData[type], item];
    } else if (action == "allAdd") {
      if (type == "esg_factors") {
        cloneData[type] = item.value.map(item => item.data).flat().map(item => item.id)
      }
    } else {
      if (type == "ungc_breaches") {
        let targetArray = cloneData[type];
        targetArray = targetArray?.filter((val) => val.id !== item.id);
        cloneData[type] = targetArray;
      } else {
        let targetArray = cloneData[type];
        targetArray = targetArray?.filter((val) => val !== item);
        cloneData[type] = targetArray;
      }
    }
  }
  return cloneData;
};

let chachePrevScreenData = null;

const resetAllState = (dispatch, setChips,) => {
  chache_WhichTaskDone = {
    "Top Searches": false,
    Company: false,
    "Incident Location": false,
    "ESG Factors": false,
    "UN global compact principles": false,
    Industry: false,
  };
  selectedItem = {
    company: 0,
    incidentLocation: 0,
    industry: 0,
    ESGFactors: 0,
  };
  updateTopSearchArray = false;

  const wrapTopSearchData = [
    { data: [], type: "esg_factor" },
    { data: [], type: "company" },
    { data: [], type: "industry" },
    { data: [], type: "location" },
  ];
  dispatch(updateAdvncTopSearchData(wrapTopSearchData));
  dispatch(getUpdateAdvncCompanyDataSuccess())
  dispatch(updateAdvncCompanyDataSuccess([]));
  const chipsDummyState = [
    {
      groupName: "Top Searches",
      selectedItemLength: 0,
      data: [],
    },
    {
      groupName: "Company",
      selectedItemLength: 0,
      data: []
    },
    {
      groupName: "Incident Location",
      selectedItemLength: 0,
      data: [],
    },
    {
      groupName: "ESG Factors",
      selectedItemLength: 0,
      data: [],
    },
    {
      groupName: "UN global compact principles",
      selectedItemLength: 0,
      data: [],
    },
    {
      groupName: "Industry",
      selectedItemLength: 0,
      data: [],
    },
  ];
  cacheDataFillUp = chipsDummyState
  setChips(cacheDataFillUp)
}

const IndidentSearchMain = (props) => {
  const { modal2Open, setModal2Open, prevScreenData, updatePrevScreenData } =
    props;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [chips, setChips] = useState([]);
  const [search, setSearch] = useState("");

  if (chachePrevScreenData == null) {
    chachePrevScreenData = prevScreenData;
  }

  const [selectedSearchItems, setSelectedSearchItems] = useState(
    selectedSearchArrayItem
  );

  const [offset, setOffset] = useState(0);

  const [canApplyCompanyListPagination, setCanApplyCompanyListPagination] =
    useState(false);

  useLayoutEffect(() => {
    dispatch(getAdvncCompanyDataLoad({ offset: 0, limit: 20 }));
    dispatch(getAdvncIncLocationDataLoad());
    dispatch(getAdvncCategoryDataLoad());
    dispatch(getAdvncIndustryDataLoad());
    dispatch(getAdvncTopSearchDataLoad());
  }, []);

  const hanlderShowMoreCompany = () => {
    chache_WhichTaskDone.Company = false;
    setCanApplyCompanyListPagination(true);
    setOffset((pre) => pre + 20);
  };
  const advanceSearchReducerData = useSelector((state) => state?.advanceSearch);

  const {
    topSearchData,
    companyData: companylist,
    renderCompany,
    incidentLocationData,
    categoryData,
    updateUN_Global,
    industryData,
    canUpdateIndustry,
    activeCountries,
    // LOADER STATE
    companyDataLoader,
    topSearchDataLoader,
    incidentLocationDataLoader,
    categoryDataLoader,
    industryDataLoader,
    selectedItems,
  } = advanceSearchReducerData;

  //  MAKE a clone of categoryData
  if (cloneCategoryData == null && categoryData) {
    cloneCategoryData = categoryData[0]?.value
      ?.map((item) => item.data)
      ?.flat()
  }
  //  MAKE a clone of location
  if (cloneLocationData == null && incidentLocationData?.continents?.length > 0) {
    cloneLocationData = incidentLocationData?.continents?.map(item => item.countries)?.flat()
  }
  if (cloneIndustryData == null && industryData.length > 0) {
    cloneIndustryData = industryData.map(item => item.data).flat()
  }

  useEffect(() => {
    if (canApplyCompanyListPagination) {
      setActiveTab(cacheActiveTab);
      setCanApplyCompanyListPagination(false);
      dispatch(getAdvncCompanyDataLoad({ offset: offset, limit: 20 }));
    }
  }, [offset]);

  useLayoutEffect(() => {
    if (selectedItems.length) {
      setSelectedSearchItems(selectedItems);
    }
  }, []);

  const watchListdetails = useSelector(
    (state) => state?.watchListReducer?.watchListDetails
  );

  const updateSelectSearchArray = (
    groupName,
    selectedItem,
    objName = null,
    specialCase = false
  ) => {
    let updateSearchSelectedItemArray = [...selectedSearchItems];
    updateSearchSelectedItemArray = updateSearchSelectedItemArray.map(
      (val, idx) => {
        if (val.groupName === groupName) {
          let tempDataArr = val.data;
          if (groupName == "Incident Location" && specialCase) {
            let allStatus = [];
            for (let k = 0; k < objName.continents.length; k++) {
              const element = objName.continents[k]["countries"];
              for (let i = 0; i < element.length; i++) {
                if (element[i]["status"]) {
                  allStatus.push(element[i]);
                }
              }
            }
            tempDataArr = allStatus;
          } else if (
            (groupName == "ESG Factors" ||
              groupName == "UN global compact principles") &&
            specialCase
          ) {
            let indData = [];
            for (let i in objName.value) {
              let allData = objName.value[i].data;
              for (let j in allData) {
                if (objName.status) {
                  indData.push(allData[j]);
                }
              }
            }
            tempDataArr = indData;
          } else if (groupName == "Industry" && specialCase) {
            let indusData = [];
            for (let i in objName.data) {
              let allData = objName.data[i];
              // if (objName.status) {
              indusData.push(allData);
              // }
            }
            tempDataArr = indusData;
          } else {
            if (tempDataArr?.length > 0) {
              if (objName) {
                if (objName?.status) {
                  let checkAlreayExist = tempDataArr.filter(
                    (item) => item?.name == objName?.name
                  );
                  if (checkAlreayExist.length > 0) {
                    const index = tempDataArr.findIndex(
                      (item) => item?.name === objName?.name
                    );
                    tempDataArr.splice(index, 1);
                  } else {
                    tempDataArr.push(objName);
                  }
                } else {
                  const index = tempDataArr.findIndex(
                    (item) => item?.name === objName?.name
                  );
                  tempDataArr.splice(index, 1);
                }
              }
            } else {
              tempDataArr = [objName];
            }
          }

          let removeCountIndex = selectedSearchItems.findIndex(
            (item) => item.groupName == groupName
          );
          return {
            ...val,
            selectedItemLength: selectedItem
              ? selectedItem[groupName]
              : selectedSearchItems[removeCountIndex].selectedItemLength - 1,
            data: tempDataArr,
          };
        } else {
          return val;
        }
      }
    );
    setSelectedSearchItems(updateSearchSelectedItemArray);
  };

  const [hitCorrection, setHitCorrection] = useState({
    for: "",
    action: "",
  });

  useEffect(() => {
    let updateTopsearchData = topSearchData;

    const prevScreen_Companies_Data = prevScreenData?.companies;
    let topSearch_companies_data =
      updateTopsearchData !== undefined &&
      updateTopsearchData.filter((val) => val.type == "company")[0]?.data;
    topSearch_companies_data = selectedItemHandler(
      prevScreen_Companies_Data,
      topSearch_companies_data
    );
    const prevScreen_Locaiton_Data =
      prevScreenData?.locations || prevScreenData?.countries;
    let topSearch_location_data =
      updateTopsearchData !== undefined &&
      updateTopsearchData.filter((val) => val.type == "location")[0]?.data;
    topSearch_location_data = selectedItemHandler(
      prevScreen_Locaiton_Data,
      topSearch_location_data
    );

    const prevScreen_esg_factors_Data = prevScreenData?.esg_factors;
    let topSearch_esg_factor_data =
      updateTopsearchData !== undefined &&
      updateTopsearchData.filter((val) => val.type == "esg_factor")[0]?.data;
    topSearch_esg_factor_data = selectedItemHandler(
      prevScreen_esg_factors_Data,
      topSearch_esg_factor_data
    );
    const prevScreen_industry_Data =
      prevScreenData?.industry || prevScreenData?.industries;
    let topSearch_industry_data =
      updateTopsearchData !== undefined &&
      updateTopsearchData.filter((val) => val.type == "industry")[0]?.data;
    topSearch_industry_data = selectedItemHandler(
      prevScreen_industry_Data,
      topSearch_industry_data
    );
    const wrapTopSearchData = [
      { data: topSearch_esg_factor_data || [], type: "esg_factor" },
      { data: topSearch_companies_data || [], type: "company" },
      { data: topSearch_industry_data || [], type: "industry" },
      { data: topSearch_location_data || [], type: "location" },
    ];
    if (
      updateTopsearchData !== undefined
    ) {
      if (updateTopSearchArray == false) {
        updateTopSearchArray = true;
        dispatch(updateAdvncTopSearchData(wrapTopSearchData));
      }
      updateIncidentLocationArrayWithTopSearch(
        incidentLocationData?.continents,
        prevScreen_Locaiton_Data,
        dispatch
      );

      if (categoryData.length > 0) {
        updateCategoryArrayWithTopSearch(
          categoryData,
          prevScreenData,
          dispatch,
          updateSelectSearchArray
        );
      }
      updateIndustryArrayWithTopSearch(
        industryData,
        prevScreen_industry_Data,
        dispatch,
        setSelectedSearchItems
      );
    }
    // Get Only Selected Values

    const flattenedArray = topSearchData
      ?.flatMap((val) => val?.data)
      ?.filter((val) => val?.status);
    const previousSelectedDataArray =
      prevScreenData && Object.values(prevScreenData).flat();
    if (flattenedArray?.length > 0 && hitCorrection?.for == "TopSearch") {
      selectItemsFinder(
        flattenedArray,
        previousSelectedDataArray,
        setChips,
        "Top Searches",
        0,
        hitCorrection?.action || "update",
        setHitCorrection
      );
    } else if (flattenedArray?.length == 0 && hitCorrection !== null) {
      selectItemsFinder(
        [],
        previousSelectedDataArray,
        setChips,
        "Top Searches",
        0,
        "remove",
        setHitCorrection
      );
    } else if (
      flattenedArray?.length > 0 &&
      previousSelectedDataArray?.length > 0
    ) {
      if (!chache_WhichTaskDone["Top Searches"]) {
        selectItemsFinder(
          flattenedArray,
          previousSelectedDataArray,
          setChips,
          "Top Searches",
          0
        );
      }
      chache_WhichTaskDone["Top Searches"] = true;
    }
  }, [topSearchData, hitCorrection]);

  useEffect(() => {
    if (
      canApplyCompanyListPagination == false &&
      companylist?.countries?.length > 0
    ) {
      const topSearchCompanyData = topSearchData
        .filter((val) => val?.type == "company")[0]
        ?.data?.filter((val) => val?.status == true && { ...val });
      const addTopSearchItemInCompanylist = companylist?.countries;
      for (let i = 0; i < addTopSearchItemInCompanylist?.length; i++) {
        for (let j = 0; j < topSearchCompanyData?.length; j++) {
          if (
            addTopSearchItemInCompanylist[i]?.id == topSearchCompanyData[j]?.id
          ) {
            addTopSearchItemInCompanylist[i].status = true;
            break;
          }
        }
      }

      if (prevScreenData?.companies && prevScreenData?.companies?.length > 0) {
        let updateSearchSelectedItemArray = [...selectedSearchItems];
        let allData = [];
        const prevScreenDataCompanies = prevScreenData?.companies;
        for (let i = 0; i <= prevScreenDataCompanies?.length - 1; i++) {
          for (let j = 0; j < addTopSearchItemInCompanylist?.length - 1; j++) {
            if (
              addTopSearchItemInCompanylist[j]?.id == prevScreenDataCompanies[i]
            ) {
              allData.push(addTopSearchItemInCompanylist[j]);
              addTopSearchItemInCompanylist[j].status = true;
              break;
            }
          }
        }

        updateSearchSelectedItemArray[1].data = allData;
        updateSearchSelectedItemArray[1].selectedItemLength =
          allData?.length || 0;
        setSelectedSearchItems(updateSearchSelectedItemArray);
      }
      activeTab.length > 0
        ? dispatch(
          updateAdvncCompanyDataSuccess([
            ...renderCompany,
            ...addTopSearchItemInCompanylist,
          ])
        )
        : dispatch(
          updateAdvncCompanyDataSuccess([...addTopSearchItemInCompanylist])
        );
      topSearchData?.length > 0 && setCanApplyCompanyListPagination(true);
    } else {
      if (renderCompany.length == 0) {
        dispatch(updateAdvncCompanyDataSuccess([]));
      }
    }
  }, [companylist]);

  useEffect(() => {
    let getSelectedItems = []
    if (renderCompany?.length > 0) {
      getSelectedItems = renderCompany.filter(
        (item) => item?.status && item
      );

      if (search.length > 0 && hitCorrection?.for == "Company") {
        const passDataFromTopSearch = topSearchData.filter(item => item.type == "company")[0].data.filter(Item => prevScreenData?.companies.includes(Item.id))
        const passDataFromTopSearchIdsArray = passDataFromTopSearch.map(item => item.id)
        const passDataFromCompanyData = renderCompany.filter(item => prevScreenData?.companies.includes(item.id) && !passDataFromTopSearchIdsArray.includes(item.id))
        selectItemsFinder(
          [...passDataFromTopSearch, ...passDataFromCompanyData],
          prevScreenData?.companies,
          setChips,
          "Company",
          1,
          "update",
          setHitCorrection
        );
      } else if (getSelectedItems?.length > 0 && hitCorrection?.for == "Company") {
        selectItemsFinder(
          getSelectedItems,
          prevScreenData?.companies,
          setChips,
          "Company",
          1,
          hitCorrection?.action,
          setHitCorrection
        );
      } else if (getSelectedItems?.length == 0 && hitCorrection !== null) {
        selectItemsFinder(
          [],
          prevScreenData?.companies,
          setChips,
          "Company",
          1,
          "remove",
          setHitCorrection
        );
      } else if (
        renderCompany?.length > 0 &&
        prevScreenData?.companies?.length > 0
      ) {
        if (!chache_WhichTaskDone.Company) {
          selectItemsFinder(
            renderCompany,
            prevScreenData?.companies,
            setChips,
            "Company",
            1
          );
        }
        chache_WhichTaskDone.Company = true;
      }
    }

  }, [renderCompany, hitCorrection]);

  useEffect(() => {
    if (
      renderCompany &&
      renderCompany?.length > 0 &&
      prevScreenData?.companies &&
      prevScreenData?.companies?.length > 0
    ) {
      for (let i = 0; i < prevScreenData?.companies.length; i++) {
        let singleCompany = prevScreenData?.companies[i];
        for (let k = 0; k < renderCompany.length; k++) {
          if (singleCompany == renderCompany[k].id) {
            renderCompany[k].status = true;
          }
        }
      }

      dispatch(getAdvncCompanyDataSuccess(renderCompany));
    }
  }, []);

  useEffect(() => {
    let count = 0;
    selectedSearchItems?.forEach((val) => {
      if (val.selectedItemLength > 0) {
        count += val.selectedItemLength;
      }
    });
  }, [selectedSearchItems]);

  // model popup....................
  const [currentTab] = useState(1);
  const [esg_Id_factors] = useState([]);
  const [activeTab, setActiveTab] = useState("topSearch");

  const [activeCategoriesItemNumber, setActiveCategoriesItemNumber] =
    useState(0);

  const { locationIdSearchList } = useSelector(
    (state) => state?.incidentReducer
  );

  const selectedArray = useSelector(
    (state) => state?.incidentReducer?.companies
  );

  const [industryList] = useState(
    industryData?.length > 0 ? [...industryData] : []
  );

  let networkData = {
    start: "",
    end: "",
    companies: watchListdetails
      ? watchListdetails?.companies
        ?.filter((i) => {
          return i.id;
        })
        .map((i) => i.id)
      : selectedArray,
    continent_codes: [],
    locations: activeCountries,
    esg_factors:
      esg_Id_factors?.length > 0
        ? esg_Id_factors
        : watchListdetails
          ? watchListdetails?.esg_factors
            ?.filter((i) => {
              return i.id;
            })
            .map((i) => i._id)
          : [],
    industries: watchListdetails
      ? watchListdetails?.industries
        ?.filter((i) => {
          return i.id;
        })
        .map((i) => i._id)
      : [],
    search: "",
    location:
      locationIdSearchList?.length > 0
        ? locationIdSearchList
        : watchListdetails
          ? watchListdetails?.locations
            ?.filter((i) => {
              return i._id;
            })
            .map((i) => i._id)
          : [],
  };

  const modalContentRef = useRef(null);

  const lastDataArray0 =
    categoryData?.length > 0 &&
      categoryData[0]?.value?.length == 3 &&
      categoryData[0]?.value?.length > 0
      ? [
        ...categoryData?.[0]?.value[0]?.data,
        ...categoryData?.[0]?.value[1]?.data,
        ...categoryData?.[0]?.value[2]?.data,
      ]
      : categoryData?.length > 0 &&
        categoryData[0]?.value?.length == 2 &&
        categoryData[0]?.value?.length > 0
        ? [
          ...categoryData?.[0]?.value[0]?.data,
          ...categoryData?.[0]?.value[1]?.data,
        ]
        : categoryData?.length > 0 &&
          categoryData[0]?.value?.length == 1 &&
          categoryData[0]?.value?.length > 0
          ? [...categoryData?.[0]?.value[0]?.data]
          : [];
  const lastDataArray1 =
    categoryData?.length > 0
      ? [
        ...categoryData?.[1]?.value[0]?.data,
        ...categoryData?.[1]?.value[1]?.data,
        ...categoryData?.[1]?.value[2]?.data,
        ...categoryData?.[1]?.value[3]?.data,
      ]
      : [];

  useEffect(() => {
    let totalCount = {
      "Incident Location": 0,
    };
    totalCount["Incident Location"] = countries?.filter((val) => val?.status);
    const prevScreen_Locaiton_Data =
      prevScreenData?.locations || prevScreenData?.countries;
    if (shouldUpdateLocation) {
      shouldUpdateLocation = false
      updateIncidentLocationArrayWithTopSearch(
        incidentLocationData?.continents,
        prevScreen_Locaiton_Data,
        dispatch
      );
    }
    const flatLocationArray =
      incidentLocationData?.continents
        ?.map((item) => item?.countries)
        .flat()
        .filter((item) => item.status && item) || [];
    const flatLocationArrayWithStatus =
      incidentLocationData?.continents?.map((item) => item?.countries).flat() ||
      [];
    if (search.length > 0 && hitCorrection?.for == "Incident Location") {
      const passData = cloneLocationData.filter(item => prevScreenData?.locations.includes(item.name))
      selectItemsFinder(
        passData,
        prevScreenData?.locations,
        setChips,
        "Incident Location",
        2,
        "update",
        setHitCorrection
      );
    } else if (
      flatLocationArray?.length > 0 &&
      hitCorrection?.for == "Incident Location"
    ) {
      selectItemsFinder(
        flatLocationArray,
        prevScreenData?.locations,
        setChips,
        "Incident Location",
        2,
        hitCorrection?.action || "remove",
        setHitCorrection
      );
    } else if (
      flatLocationArray?.length == 0 &&
      hitCorrection !== null &&
      chache_WhichTaskDone["Incident Location"]
    ) {
      selectItemsFinder(
        [],
        prevScreenData?.locations,
        setChips,
        "Incident Location",
        2,
        "remove",
        setHitCorrection
      );
    } else if (
      flatLocationArrayWithStatus.length > 0 &&
      prevScreenData?.locations?.length > 0
    ) {
      if (!chache_WhichTaskDone["Incident Location"]) {
        selectItemsFinder(
          flatLocationArrayWithStatus,
          prevScreenData?.locations,
          setChips,
          "Incident Location",
          2
        );
        chache_WhichTaskDone["Incident Location"] = true;
      }
    }
    let groupName = "Incident Location";
    if (incidentLocationData && incidentLocationData?.continents?.length > 0) {
      updateSelectSearchArray(
        groupName,
        totalCount.length,
        incidentLocationData,
        true
      );
    }
  }, [incidentLocationData, hitCorrection]);

  useEffect(() => {
    if (shouldUpdateESG_Category) {
      shouldUpdateESG_Category = false
      updateCategoryArrayWithTopSearch(
        categoryData,
        prevScreenData,
        dispatch,
        updateSelectSearchArray
      );
    }
    const ESG_Factors = categoryData[0]?.value
      ?.map((item) => item.data)
      ?.flat()
      ?.filter((val) => val?.status && val);
    if (search.length > 0 && hitCorrection?.for == "ESG Factors") {
      const passData = cloneCategoryData.filter(item => prevScreenData?.esg_factors.includes(item.id))
      selectItemsFinder(
        passData,
        prevScreenData?.esg_factors,
        setChips,
        "ESG Factors",
        3,
        "update",
        setHitCorrection
      );
    } else if (
      ESG_Factors?.length > 0 &&
      hitCorrection?.for == "ESG Factors"
    ) {
      selectItemsFinder(
        ESG_Factors,
        prevScreenData?.esg_factors,
        setChips,
        "ESG Factors",
        3,
        hitCorrection?.action || "update",
        setHitCorrection
      );
    } else if (ESG_Factors?.length == 0 && hitCorrection !== null) {
      selectItemsFinder(
        [],
        prevScreenData?.esg_factors,
        setChips,
        "ESG Factors",
        3,
        "remove",
        setHitCorrection
      );

    } else if (
      ESG_Factors?.length > 0 &&
      prevScreenData?.esg_factors?.length > 0
    ) {
      if (!chache_WhichTaskDone["ESG Factors"]) {
        selectItemsFinder(
          ESG_Factors,
          prevScreenData?.esg_factors,
          setChips,
          "ESG Factors",
          3
        );
      }
      chache_WhichTaskDone["ESG Factors"] = true;
    }

    // --------------ungc_breaches---------------------

    const ungc_breaches =
      categoryData[1]?.value
        ?.map((item) => item.data)
        ?.flat()
        ?.filter((val) => val?.status && val) || [];
    const ungc_breaches_with_noSelect =
      categoryData[1]?.value?.map((item) => item.data)?.flat() || [];

    // -----onlyFirstUpdate-----
    if (updateUN_Global) {
      const updateUNGlobal = selectedItemHandler(
        prevScreenData?.ungc_breaches,
        ungc_breaches_with_noSelect
      );
      const checkAllTrue = updateUNGlobal.reduce(
        (accumulator, item) => (item.status ? accumulator + 1 : accumulator),
        0
      );
      const payload = [categoryData[0]];
      payload.push({
        name: "UN global compact principles",
        status: checkAllTrue == 10 ? true : false,
        value: [
          {
            _id: "Human Rights",
            data: [updateUNGlobal[0], updateUNGlobal[1]],
          },
          {
            _id: "Labour",
            data: [
              updateUNGlobal[2],
              updateUNGlobal[3],
              updateUNGlobal[4],
              updateUNGlobal[5],
            ],
          },
          {
            _id: "Environment",
            data: [updateUNGlobal[6], updateUNGlobal[7], updateUNGlobal[8]],
          },
          {
            _id: "Anti-Corruption",
            data: [updateUNGlobal[9]],
          },
        ],
      });
      dispatch(updateAdvanceCategoriesData(payload));
    }
    if (
      ungc_breaches?.length > 0 &&
      hitCorrection?.for == "UN global compact principles"
    ) {
      selectItemsFinder(
        ungc_breaches,
        prevScreenData?.ungc_breaches,
        setChips,
        "UN global compact principles",
        4,
        hitCorrection?.action || "update",
        setHitCorrection
      );
    } else if (ungc_breaches?.length == 0 && hitCorrection !== null) {
      selectItemsFinder(
        ungc_breaches,
        prevScreenData?.ungc_breaches,
        setChips,
        "UN global compact principles",
        4,
        hitCorrection?.action || "update",
        setHitCorrection
      );
    } else if (
      ungc_breaches_with_noSelect?.length > 0 &&
      prevScreenData?.ungc_breaches?.length > 0
    ) {
      if (!chache_WhichTaskDone["UN global compact principles"]) {
        selectItemsFinder(
          ungc_breaches_with_noSelect,
          prevScreenData?.ungc_breaches,
          setChips,
          "UN global compact principles",
          4
        );
        chache_WhichTaskDone["UN global compact principles"] = true;
      }
    }
  }, [categoryData, hitCorrection]);

  useEffect(() => {
    const clonePrevScreenDataIndustry = prevScreenData?.industries;

    if (
      canUpdateIndustry &&
      clonePrevScreenDataIndustry?.length > 0 &&
      industryData?.length > 0
    ) {
      let cloneIndustryData = [...industryData];
      let selectionOpration = 0;
      for (let i = 0; i <= cloneIndustryData.length - 1; i++) {
        let item = cloneIndustryData[i].data;
        let selectedCount = 0;
        for (let j = 0; j <= item.length - 1; j++) {
          const elementId = item[j].id;
          if (clonePrevScreenDataIndustry.includes(elementId)) {
            item[j].status = true;
            selectedCount = selectedCount + 1;
            selectionOpration = selectionOpration + 1;
            if (selectionOpration > clonePrevScreenDataIndustry.length) {
              break;
            }
          }
        }
        cloneIndustryData[i].data = item;
        if (selectedCount == item.length) {
          cloneIndustryData[i].status = true;
        }
        if (selectionOpration > clonePrevScreenDataIndustry.length) {
          break;
        }
      }
      dispatch(updateIndustryArray(cloneIndustryData));
    }

    const IndustryData =
      industryData
        ?.map((val) => val?.data)
        ?.flat()
        ?.filter((item) => item.status && item) || [];
    if (search.length > 0) {
      const passData = cloneIndustryData.filter(item => prevScreenData?.industries?.includes(item.id))
      selectItemsFinder(
        passData,
        prevScreenData?.industries,
        setChips,
        "Industry",
        5,
        "update",
        setHitCorrection
      );
    } else if (IndustryData?.length > 0 && hitCorrection?.for == "Industry") {
      selectItemsFinder(
        IndustryData,
        prevScreenData?.industries,
        setChips,
        "Industry",
        5,
        hitCorrection?.action,
        setHitCorrection
      );
    } else {
      selectItemsFinder(
        IndustryData,
        prevScreenData?.industries,
        setChips,
        "Industry",
        5,
        "remove",
        setHitCorrection
      );
    }
  }, [industryData, hitCorrection]);

  const mergedData = {
    ...networkData,
    locations: countries
      ?.filter((i) => {
        if (i.status) {
          return i.name;
        }
      })
      ?.map((i) => i.name),
    companies:
      renderCompany?.length > 0 &&
      renderCompany
        ?.filter((i) => {
          if (i.status) {
            return i.name;
          }
        })
        ?.map((i) => i.id),
    industries: getSelectedInddustryItem(industryData),
    esg_factors: lastDataArray0
      ?.filter((item) => item.status)
      ?.map((item) => item.id),
    ungc_breaches: lastDataArray1
      ?.filter((item) => item.status)
      ?.map((item) => item.id),
    showSever: false,
  };

  const removeSearchSelectedItem = (groupName, objName = null) => {
    switch (groupName) {
      case "Industry":
        let Clone = [...industryData];
        const statusState = false;
        let ModifyPreviousData = updatePreviousData(
          prevScreenData,
          "remove",
          objName.id,
          "industries"
        );
        updatePrevScreenData((pre) => ModifyPreviousData);
        const gotIndex = Clone.findIndex((item) => item._id == objName.parent);
        Clone[gotIndex].data = Clone[gotIndex]?.data?.map((item) =>
          item.id == objName.id
            ? {
              ...item,
              status: statusState,
            }
            : {
              ...item,
            }
        );
        const getAllStatusCurrentItem = Clone[gotIndex].data.map((item) =>
          item.status ? true : false
        );
        Clone[gotIndex].status = !getAllStatusCurrentItem.includes(false);
        dispatch(updateIndustryArray(Clone));

        const findIds = Clone[gotIndex].data
          ?.filter((item) => !item.status)
          ?.map((Val) => Val.id);
        const cloneTopSearchArray = topSearchData;
        const getCurrentItemIndex = cloneTopSearchArray?.findIndex(
          (item) => item.type === "industry"
        );
        const getCurrentItem = cloneTopSearchArray[
          getCurrentItemIndex
        ].data?.map((Item) =>
          findIds.includes(Item.id)
            ? {
              ...Item,
              status: statusState,
            }
            : { ...Item }
        );
        cloneTopSearchArray[getCurrentItemIndex].data = getCurrentItem;
        dispatch(updateAdvncTopSearchData(cloneTopSearchArray));

        break;
      case "Company":
        let modifyPreviousCompanyData =
          updatePreviousData(
            prevScreenData,
            objName.status && objName.status == true ? "remove" : "add",
            objName.id,
            "companies"
          );
        updatePrevScreenData(
          (pre) => modifyPreviousCompanyData
        );
        let currentObj = null;

        let cloneCompanyData = renderCompany?.map(
          (val) => {
            if (val.id === objName.id) {
              currentObj = {
                ...objName,
                status: !val?.status,
              };
              return {
                ...val,
                status: !val?.status,
              };
            } else {
              return val;
            }
          }
        );
        dispatch(
          updateAdvncCompanyDataSuccess([
            ...cloneCompanyData,
          ])
        );
        let currentCompanyIndex = topSearchData.findIndex(
          (obj) => obj.type === "company"
        );

        let getArray = topSearchData[currentCompanyIndex];
        const indexOfTopSearch = getArray?.data.findIndex(
          (obj) => obj.id === objName.id
        );
        if (indexOfTopSearch >= 0) {
          getArray.data[indexOfTopSearch].status =
            !getArray.data[indexOfTopSearch].status;
        }

        let totalCount = {
          Categories: 0,
          Company: 0,
        };
        const matchIds = [];

        const combineBothArrayTopSearchAndCompanyData = [
          ...cloneCompanyData,
          ...getArray.data,
        ];
        const selectedCount =
          combineBothArrayTopSearchAndCompanyData.filter(
            (val) =>
              val?.status &&
              !matchIds.includes(val.id) &&
              matchIds.push(val.id)
          );
        const topSearchArray = chips[0].data.map(
          (Val) => Val.id
        );

        let haveUpdate = topSearchArray.includes(objName.id);
        if (haveUpdate) {
          setHitCorrection({
            for: "TopSearch",
            action: "update",
          });
        } else {
          setHitCorrection({
            for: "Company",
            action: "update",
          });
        }
        totalCount["Company"] = selectedCount.length;
        let groupName = "Company";
        updateSelectSearchArray(
          groupName,
          totalCount,
          currentObj
        );
        // const matchIds = [];
        // let modifyPreviousCompanyData = updatePreviousData(
        //   prevScreenData,
        //   "remove",
        //   objName.id,
        //   "companies"
        // );
        // updatePrevScreenData((pre) => modifyPreviousCompanyData);
        // setHitCorrection({ for: "Company", action: "remove" });
        // updateSelectSearchArray("Company", 0, objName);
        break;
      case "Top Searches":
        const wrapTopSearchData = [
          { data: topSearchData[0]?.data, type: "esg_factor" },
          { data: topSearchData[1]?.data, type: "company" },
          { data: topSearchData[2]?.data, type: "industry" },
          { data: topSearchData[3]?.data, type: "location" },
        ];
        let modifyPreviousTopData;

        if (objName.type == "company") {
          modifyPreviousTopData = updatePreviousData(
            prevScreenData,
            "remove",
            objName.id,
            "companies"
          );
          const updateTopSearchCompanyArray = wrapTopSearchData[1].data.map(
            (item) =>
              item.id == objName.id ? { ...item, status: false } : item
          );
          wrapTopSearchData[1].data = updateTopSearchCompanyArray;
          updatePrevScreenData((pre) => modifyPreviousTopData);
          setHitCorrection({ for: "Company", action: "remove" });
        }

        dispatch(updateAdvncTopSearchData(wrapTopSearchData));
        setHitCorrection({ for: "TopSearch", action: "remove" });
        updateSelectSearchArray("Top Searches", 0);
        break;
      case "Incident Location":
        let newRecord = incidentLocationData;
        let modifyPreviousData = updatePreviousData(
          prevScreenData,
          "remove",
          objName.name,
          "locations"
        );
        updatePrevScreenData((pre) => modifyPreviousData);
        newRecord?.continents?.forEach((continent, Index) => {
          // continent.continent_status = false;
          continent?.countries?.forEach((country, index) => {
            if (country.name == objName.name) {
              continent.continent_status = false;
              country.status = false;
            }
          });
        });
        newRecord.allStatus = false;
        /*This block for remove whole selection from top search */
        let currentLocationIndex = topSearchData.findIndex(
          (obj) => obj.type === "location"
        );
        if (currentLocationIndex >= 0) {
          for (const val of topSearchData[currentLocationIndex].data) {
            if (val.name == objName.name) val.status = false;
          }
        }

        setHitCorrection({ for: "Incident Location", action: "remove" });
        updateSelectSearchArray("Incident Location", 0, objName);
        break;
      case "Health Care":
      case "Financials":
      case "Extractives & Minerals Processing":
        let clone = [...industryList];
        let index = clone.findIndex((item) => item.name === groupName);
        clone[index]?.childs?.forEach((child) => { });
        updateSelectSearchArray(groupName, 0);
        break;
      case "ESG Factors":
        let cloneEsg = [...categoryData];
        let modifyPreviousESGData = updatePreviousData(
          prevScreenData,
          "remove",
          objName.id,
          "esg_factors"
        );
        updatePrevScreenData((pre) => modifyPreviousESGData);

        // OLD WORK
        let indexEsg = cloneEsg?.findIndex((item) => item.name === "ESG Factors");
        // categoryData[indexEsg]["status"] = false;
        let valueEsgArray = categoryData[indexEsg]["value"];
        for (let i = 0; i <= valueEsgArray?.length - 1; i++) {
          let dataArrya = valueEsgArray[i]["data"];
          for (let j = 0; j <= dataArrya?.length - 1; j++) {
            if (objName.name === dataArrya[j]["name"]) {
              dataArrya[j]["status"] = false;
            }
          }
        }
        /*This block for remove whole selection from top search */
        let currentEsgIndex = topSearchData.findIndex(
          (obj) => obj.type === "esg_factor"
        );
        if (currentEsgIndex >= 0) {
          for (const val of topSearchData[currentEsgIndex].data) {
            if (val.name == objName.name) {
              val.status = false;
            }
          }
        }
        /* end of the block for remove whole selection from top search */


        setHitCorrection({ for: "ESG Factors", action: "remove" });
        updateSelectSearchArray("ESG Factors", 0, objName);
        break;
      case "UN global compact principles":

        let modifyPreviousUNGData = updatePreviousData(
          prevScreenData,
          "remove",
          objName,
          "ungc_breaches"
        );
        updatePrevScreenData((pre) => modifyPreviousUNGData);
        const itemArray = [objName];
        const categoryDatArray =
          categoryData[1]?.value.map((item) => item.data).flat() || [];

        for (let i = 0; i < itemArray.length; i++) {
          for (let j = 0; j < categoryDatArray.length; j++) {
            if (itemArray[i].id == categoryDatArray[j].id) {
              categoryDatArray[j].status = false;
            }
          }
        }

        const checkAllTrue = categoryDatArray.reduce(
          (accumulator, item) => (item.status ? accumulator + 1 : accumulator),
          0
        );
        const payload = [categoryData[0]];
        payload.push({
          name: "UN global compact principles",
          status: checkAllTrue == 10 ? true : false,
          value: [
            {
              _id: "Human Rights",
              data: [categoryDatArray[0], categoryDatArray[1]],
            },
            {
              _id: "Labour",
              data: [
                categoryDatArray[2],
                categoryDatArray[3],
                categoryDatArray[4],
                categoryDatArray[5],
              ],
            },
            {
              _id: "Environment",
              data: [
                categoryDatArray[6],
                categoryDatArray[7],
                categoryDatArray[8],
              ],
            },
            {
              _id: "Anti-Corruption",
              data: [categoryDatArray[9]],
            },
          ],
        });

        dispatch(updateAdvanceCategoriesData(payload));
        setHitCorrection({
          for: "UN global compact principles",
          action: "remove",
        });
        updateSelectSearchArray("UN global compact principles", 0);
        break;
      case "SDGs":
        let cloneSdg = [...categoryData];
        let indexSdg = cloneSdg.findIndex((item) => item.name === groupName);
        categoryData[indexSdg]["status"] = false;
        let valueSdgArray = categoryData[indexSdg]["value"];
        for (let i = 0; i <= valueSdgArray?.length - 1; i++) {
          let dataArrya = valueSdgArray[i]["data"];
          for (let j = 0; j <= dataArrya?.length - 1; j++) {
            dataArrya[j]["status"] = false;
          }
        }
        /*This block for remove whole selection from top search */
        let currentSdgIndex = topSearchData.findIndex(
          (obj) => obj.type === "esg_sdg"
        );
        if (currentSdgIndex >= 0) {
          for (const val of topSearchData[currentSdgIndex].data) {
            val.status = false;
          }
        }
        /*end of the block for remove whole selection from top search */
        updateSelectSearchArray(groupName, 0);
      case "PAI Indicators":
        let clon = [...categoryData];
        let inde = clon.findIndex((item) => item.name === groupName);
        categoryData[inde]["status"] = false;
        let valueArray = categoryData[inde]["value"];
        for (let i = 0; i <= valueArray?.length - 1; i++) {
          let dataArrya = valueArray[i]["data"];
          for (let j = 0; j <= dataArrya?.length - 1; j++) {
            dataArrya[j]["status"] = false;
          }
        }
        /*This block for remove whole selection from top search */
        let currentPieIndex = topSearchData.findIndex(
          (obj) => obj.type === "esg_pai"
        );
        if (currentPieIndex >= 0) {
          for (const val of topSearchData[currentPieIndex].data) {
            val.status = false;
          }
        }
        /*end of the block for remove whole selection from top search */
        updateSelectSearchArray(groupName, 0);
        break;
      default:
        break;
    }
  };

  const onchangeSearchData = (e) => {
    setSearch(e);
    const payload = {
      search: e,
    };
    if (cacheActiveTab == "topSearch") {
      debounce(() => {
        dispatch(getAdvncTopSearchDataLoad(payload));
      });
    }
    if (cacheActiveTab == "company") {
      if (activeTab?.length > 0) {
        setActiveTab("");
      }
      debounce(() => {
        dispatch(getAdvncCompanyDataLoad({ search: e, offset: 0, limit: 20 }));
      });
      if (canApplyCompanyListPagination) {
        setCanApplyCompanyListPagination(false);
      }
    }
    if (cacheActiveTab == "incidentLocation") {
      debounce(() => {
        dispatch(getAdvncIncLocationDataLoad(payload));
      });
    }
    if (cacheActiveTab == "categories") {
      debounce(() => {
        dispatch(getAdvncCategoryDataLoad(payload));
      });
    }
    if (cacheActiveTab == "industry") {
      debounce(() => {
        dispatch(getAdvncIndustryDataLoad(payload));
      });
    }
  };

  const getNameOfSection = (name) => {
    let res = name;
    if (name == "ESG Factors") {
      return (res = "esg_factor");
    } else if (name == "SDGs") {
      return (res = "esg_sdg");
    } else if (name == "PAI Indicators") {
      return (res = "esg_pai");
    } else {
      return res;
    }
  };

  const activeTabHandler = (val) => {
    cacheActiveTab = val;
    if (search.length > 0 && activeTab == "topSearch") {
      debounce(() => {
        dispatch(getAdvncTopSearchDataLoad());
      });
    }
    if (search.length > 0 && activeTab == "company") {
      if (activeTab?.length > 0) {
        setActiveTab("");
      }
      debounce(() => {
        dispatch(getAdvncCompanyDataLoad({ offset: 0, limit: 20 }));
      });
      if (canApplyCompanyListPagination) {
        setCanApplyCompanyListPagination(false);
      }
    }
    if (search.length > 0 && activeTab == "incidentLocation") {
      debounce(() => {
        dispatch(getAdvncIncLocationDataLoad());
      });
    }
    if (search.length > 0 && activeTab == "categories") {
      debounce(() => {
        dispatch(getAdvncCategoryDataLoad());
      });
    }
    if (search.length > 0 && activeTab == "industry") {
      debounce(() => {
        dispatch(getAdvncIndustryDataLoad());
      });
    }
    setActiveTab(val);
    setSearch("");
  };

  return (
    <>
      <Modal
        destroyOnClose
        title={<ModalHeader title={"Advanced Search"} />}
        closeIcon={<ModalCloseButton />}
        className="advanced advanced-search-modal1 cross-model add-location-os-ps watchModal"
        centered
        onAfterOpen={() => {
          if (modalContentRef.current) {
            modalContentRef.current.scrollTop = 0;
          }
        }}
        onCancel={() => {
          resetAllState(dispatch, setChips)
          updatePrevScreenData(chachePrevScreenData);
          setModal2Open(false);
        }}
        open={modal2Open}
        footer={[
          <div className="foot-sec-md2">
            <div className="md-toggle-btn">
              <p>
                <Switch defaultChecked />
                {""} Show Sever Incidents Only
              </p>
            </div>
            <div className="foot-but-md">
              <button
                className="can-btn chang"
                onClick={() => {
                  resetAllState(dispatch, setChips)
                  setModal2Open(false);
                }}
                style={{ fontSize: "14px" }}
              >
                Cancel
              </button>
              <button
                className="rest-btn1"
                onClick={() => {
                  selectedItem = {
                    company: 0,
                    incidentLocation: 0,
                    industry: 0,
                    ESGFactors: 0,
                  };
                  updateTopSearchArray = false;
                  if (pathname == "/dashboard") {
                    dispatch(
                      updateAdvanceSearchState(
                        selectedSearchItems,
                        "selectedItems"
                      )
                    );
                    navigate("/searchdashboardpage", {
                      state: {
                        mergedData: {
                          ...mergedData,
                          companies: [
                            ...mergedData?.companies,
                            ...topSearchCompanies?.map((val) => val.id),
                          ],
                        },
                        selectedTile: {
                          companies: selectedSearchItems[1].data,
                          locations: selectedSearchItems[2].data,
                          esg_factors: selectedSearchItems[7].data,
                          industries: selectedSearchItems[4].data,
                          ungc_breaches: selectedSearchItems[10].data,
                        },
                      },
                    });
                    setModal2Open(false);
                  } else {
                    const cloneChips = chips;
                    const companies =
                      cloneChips[1]?.data?.length > 0
                        ? cloneChips[1]?.data
                        : [];
                    const locations =
                      cloneChips[2]?.data?.length > 0
                        ? cloneChips[2]?.data
                        : [];
                    const esg_factors =
                      cloneChips[3]?.data?.length > 0
                        ? cloneChips[3]?.data
                        : [];
                    const ungc_breaches =
                      cloneChips[4]?.data?.length > 0
                        ? cloneChips[4]?.data
                        : [];
                    const industries =
                      cloneChips[5]?.data?.length > 0
                        ? cloneChips[5]?.data
                        : [];
                    const topSearchData = cloneChips[0]?.data;
                    if (topSearchData?.length > 0) {
                      for (let i = 0; i < topSearchData.length; i++) {
                        if (topSearchData[i].type == "company") {
                          companies.push(topSearchData[i]);
                        } else if (topSearchData[i].type == "location") {
                          locations.push(topSearchData[i]);
                        } else if (topSearchData[i].type == "esg_factor") {
                          esg_factors.push(topSearchData[i]);
                        } else if (topSearchData[i].type == "industry") {
                          industries.push(topSearchData[i]);
                        }
                      }
                    }
                    mergedData.ungc_breaches = ungc_breaches;
                    navigate("/searchdashboardpage", {
                      state: {
                        mergedData: {
                          ...mergedData,
                          locations: locations.map((item) => item.name),
                          companies: companies.map((x) => x.id),
                        },
                        selectedTile: {
                          companies,
                          locations,
                          esg_factors,
                          industries,
                          ungc_breaches,
                        },
                      },
                    });
                    resetAllState(dispatch, setChips)
                    setModal2Open(false);
                  }
                }}
              >
                Search
              </button>
            </div>
          </div>,
        ]}
      >
        {
          <div ref={modalContentRef} className="mod-search-bar">
            <input
              className="search-mad-icon"
              type="text"
              value={search}
              placeholder="Search "
              onChange={({ target }) => {
                updateTopSearchArray = false
                shouldUpdateLocation = true
                shouldUpdateESG_Category = true
                onchangeSearchData(target.value)
              }}
            />
            <div className="selecteditem-searchbar">
              {chips?.map((val, key) => {
                if (val?.selectedItemLength > 0) {
                  return val.data.map((item, idx) => {
                    return (
                      val?.selectedItemLength > 0 && (
                        <div className="selectedItemBox" key={key}>
                          {key == 0 && val.selectedItemLength > 0 ? (
                            <img
                              style={{ width: "15px", height: "15px" }}
                              src={Images.starSharp}
                            />
                          ) : key == 1 && val.selectedItemLength > 0 ? (
                            <img
                              style={{ width: "15px", height: "15px" }}
                              src={Images.hotel}
                            />
                          ) : key == 2 && val.selectedItemLength > 0 ? (
                            <img
                              style={{ width: "15px", height: "15px" }}
                              src={Images.locationIcon}
                            />
                          ) : key == 7 && val.selectedItemLength > 0 ? (
                            <img src={Images.graygrid} />
                          ) : key == 8 && val.selectedItemLength > 0 ? (
                            <img src={Images.graygrid} />
                          ) : key == 9 && val.selectedItemLength > 0 ? (
                            <img src={Images.graygrid} />
                          ) : key == 6 && val.selectedItemLength > 0 ? (
                            <img src={Images.indust} />
                          ) : key == 5 && val.selectedItemLength > 0 ? (
                            <img src={Images.indust} />
                          ) : null}
                          <div className="selectedItemText">{item.name}</div>
                          <div
                            className="deleteSelectedItem"
                            onClick={() => {
                              removeSearchSelectedItem(val.groupName, item);
                            }}
                          >
                            X
                          </div>
                        </div>
                      )
                    );
                  });
                }
              })}
            </div>
          </div>
        }

        <div className="model-tabs1">
          <Tabs
            className="sticky-tabs"
            tabBarExtraContent={
              <div>
                {currentTab == 2 ? (
                  <div className="import-csv1">
                    <p>
                      <img src={Images.uplode} /> Import
                      <span>
                        <img src={Images.circleInfo} />
                        <div className="hover-format1">
                          <span>Upload a CSV file format</span>
                        </div>
                      </span>
                    </p>
                  </div>
                ) : null}
              </div>
            }
          >
            {
              <>
                <Tabs.TabPane
                  tab={
                    <div
                      onClick={() => activeTabHandler("topSearch")}
                      className="md-icons"
                    >
                      <img
                        className={currentTab == 1 ? "filter-img1" : ""}
                        src={Images?.starSharp}
                        alt="img"
                      />
                      Top Searches
                    </div>
                  }
                  key="1"
                >
                  <div className="md-compy-tc" style={{ padding: "0px" }}>
                    {topSearchData &&
                      !topSearchDataLoader &&
                      topSearchData?.map((Item, Index) => {
                        return (
                          <div className="top-sc-cmp-model" key={Index}>
                            <div className="model-comp1">
                              <p>
                                {Item?.type == "esg_factor"
                                  ? "ESG Factor"
                                  : Item?.type == "esg_sdg"
                                    ? "SDGs"
                                    : Item?.type == "esg_pai"
                                      ? "PAI Indicators"
                                      : Item?.type == "location"
                                        ? "Incident Location"
                                        : Item?.type}
                              </p>
                            </div>
                            {Item?.data?.map((item, index) => (
                              <div className="model-comp-name1">
                                <div className="custom-checkbox-top1 mt-2 mb-2">
                                  <CheckBox
                                    visible={item.status}
                                    onClick={(e) => {
                                      let getArray = topSearchData.filter(
                                        (val) => val.type == Item.type
                                      );
                                      let preStatus;
                                      let currentArray =
                                        getArray[0].data[index];
                                      currentArray.type = Item.type;
                                      if (currentArray.status) {
                                        currentArray.status = false;
                                        preStatus = true
                                      } else {
                                        currentArray.status = true;
                                        preStatus = false
                                      }
                                      let totalCount = {
                                        Company: 0,
                                        "ESG Factors": 0,
                                        SDGs: 0,
                                        "PAI Indicators": 0,
                                        "UN global compact principles": 0,
                                        "Incident Location": 0,
                                        "PAI Indicators": 0,
                                        Industry: 0,
                                      };
                                      if (currentArray.type == "company") {
                                        let modifyPreviousCompanyData =
                                          updatePreviousData(
                                            prevScreenData,
                                            preStatus ? "remove" : "add",
                                            currentArray.id,
                                            "companies"
                                          );
                                        updatePrevScreenData(
                                          (pre) => modifyPreviousCompanyData
                                        );
                                        let haveId = false;
                                        const updateCompanyList =
                                          renderCompany?.map((val) => {
                                            if (val.id === currentArray.id) {
                                              haveId = true;
                                              return {
                                                ...val,
                                                status: currentArray.status,
                                              };
                                            } else {
                                              return val;
                                            }
                                          });


                                        topSearchCompanies = topSearchData
                                          ?.filter(
                                            (val) => val.type == "company"
                                          )[0]
                                          ?.data?.map((val) =>
                                            val.id == currentArray.id
                                              ? { ...val, status: currentArray.status }
                                              : val
                                          );
                                        const wrapTopSearchData = [
                                          {
                                            data: topSearchData?.filter(item => item.type == "esg_factor")[0]?.data,
                                            type: "esg_factor",
                                          },
                                          {
                                            data: topSearchCompanies,
                                            type: "company",
                                          },
                                          {
                                            data: topSearchData?.filter(item => item.type == "industry")[0]?.data,
                                            type: "industry",
                                          },
                                          {
                                            data: topSearchData?.filter(item => item.type == "location")[0]?.data,
                                            type: "location",
                                          },
                                        ];
                                        dispatch(
                                          updateAdvncTopSearchData(
                                            wrapTopSearchData
                                          )
                                        );
                                        dispatch(
                                          updateAdvncCompanyDataSuccess([
                                            ...updateCompanyList,
                                          ])
                                        );


                                        const combineBothArrayTopSearchAndCompanyData =
                                          [
                                            ...updateCompanyList,
                                            ...topSearchCompanies,
                                          ];
                                        const matchIds = [];
                                        const selectedCount =
                                          combineBothArrayTopSearchAndCompanyData?.filter(
                                            (val) =>
                                              val?.status &&
                                              !matchIds?.includes(val.id) &&
                                              matchIds?.push(val.id)
                                          );

                                        totalCount["Company"] =
                                          selectedCount.length;
                                        if (haveId) {
                                          setHitCorrection({
                                            for: "Company",
                                            action: "update",
                                          });
                                        } else {
                                          setHitCorrection({
                                            for: "TopSearch",
                                            action: "update",
                                          });
                                        }
                                      } else if (
                                        currentArray.type == "esg_factor"
                                      ) {

                                        let val = categoryData[0]?.value;
                                        let updateParentState = true;
                                        for (
                                          let j = 0;
                                          j <= val?.length - 1;
                                          j++
                                        ) {
                                          let item = val[j].data;
                                          for (
                                            let k = 0;
                                            k <= item?.length - 1;
                                            k++
                                          ) {
                                            if (item[k].id == currentArray.id) {
                                              item[k].status = !item[k].status;
                                            }
                                            if (item[k].status) {
                                              totalCount["ESG Factors"] =
                                                totalCount["ESG Factors"] + 1;
                                            }
                                            if (!item[k].status) {
                                              updateParentState = false;
                                            }
                                          }
                                        }

                                        let modifyPreviousData =
                                          updatePreviousData(
                                            prevScreenData,
                                            preStatus ? "remove" : "add",
                                            currentArray.id,
                                            "esg_factors"
                                          );
                                        updatePrevScreenData(
                                          (pre) => modifyPreviousData
                                        );
                                        setHitCorrection({
                                          for: "ESG Factors",
                                          action: "update",
                                        });
                                      } else if (
                                        currentArray.type == "location"
                                      ) {
                                        let val =
                                          incidentLocationData?.continents;
                                        for (
                                          let j = 0;
                                          j <= val?.length - 1;
                                          j++
                                        ) {
                                          let item = val[j].countries;
                                          for (
                                            let k = 0;
                                            k <= item?.length - 1;
                                            k++
                                          ) {
                                            if (
                                              item[k].name == currentArray.id
                                            ) {
                                              item[k].status =
                                                currentArray.status;
                                            }
                                            // check all child is true or not
                                            val[j].continent_status = val[
                                              j
                                            ].countries.every(
                                              (item) => item.status
                                            );
                                            incidentLocationData.allStatus =
                                              val.every(
                                                (item) => item.continent_status
                                              );
                                            if (item[k].status) {
                                              totalCount["Incident Location"] =
                                                totalCount[
                                                "Incident Location"
                                                ] + 1;
                                            }
                                          }
                                        }
                                        const updateData = {
                                          allStatus: false,
                                          continents: val,
                                        };

                                        dispatch(
                                          updateAdvSearchLocationArray(
                                            updateData
                                          )
                                        );
                                        let modifyPreviousData =
                                          updatePreviousData(
                                            prevScreenData,
                                            preStatus ? "remove" : "add",
                                            currentArray.name,
                                            "locations"
                                          );
                                        updatePrevScreenData(
                                          (pre) => modifyPreviousData
                                        );
                                        setHitCorrection({
                                          for: "Incident Location",
                                          action: "update",
                                        });
                                      } else if (
                                        currentArray.type == "industry"
                                      ) {
                                        let val = industryData;
                                        for (
                                          let j = 0;
                                          j <= val?.length - 1;
                                          j++
                                        ) {
                                          let item = val[j].data;
                                          for (
                                            let k = 0;
                                            k <= item?.length - 1;
                                            k++
                                          ) {
                                            if (
                                              item[k].name == currentArray.name
                                            ) {
                                              item[k].status =
                                                currentArray.status;
                                            }
                                            if (item[k].status) {
                                              totalCount["Industry"] =
                                                totalCount["Industry"] + 1;
                                            }
                                          }
                                        }
                                        let modifyPreviousData =
                                          updatePreviousData(
                                            prevScreenData,
                                            preStatus ? "remove" : "add",
                                            currentArray.id,
                                            "industries"
                                          );
                                        updatePrevScreenData(
                                          (pre) => modifyPreviousData
                                        );
                                        setHitCorrection({
                                          for: "Industry",
                                          action: "update",
                                        });
                                      }
                                      updateSelectSearchArray(
                                        getTopSearchLabel[
                                        topSearchData[Index].type
                                        ],
                                        totalCount,
                                        currentArray
                                      );
                                    }}
                                  />
                                  <label
                                    style={{ fontFamily: "inter" }}
                                    htmlFor="vehicle1"
                                  >
                                    {item?.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    {topSearchDataLoader && (
                      <div
                        style={{
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="social-media-loader">
                          <div className="loader ">
                            <div className="circle" id="circleE"></div>
                            <div className="circle" id="circleS"></div>
                            <div className="circle" id="circleG"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      onClick={() => activeTabHandler("company")}
                      className="md-icons"
                    >
                      <img
                        className={currentTab == 2 ? "filter-img1" : ""}
                        src={Images?.hotel}
                        alt="img"
                      />
                      Company
                    </div>
                  }
                  key="2"
                >
                  <div
                    className="md-compy-tc"

                  // ref={companyListContainerRed
                  // }
                  >
                    {renderCompany?.map((item, index) => (
                      <div className="md-flex-div1" key={index}>
                        <div className="custom-checkbox-top1 mt-2 mb-1">
                          <CheckBox
                            visible={item.status}
                            onClick={(e) => {
                              let currentObj = null;

                              let cloneCompanyData = renderCompany?.map(
                                (val) => {
                                  if (val.id === item.id) {
                                    currentObj = {
                                      ...item,
                                      status: !val?.status,
                                    };
                                    return {
                                      ...val,
                                      status: !val?.status,
                                    };
                                  } else {
                                    return val;
                                  }
                                }
                              );

                              dispatch(
                                updateAdvncCompanyDataSuccess([
                                  ...cloneCompanyData,
                                ])
                              );
                              // setCompanyData(cloneCompanyData);
                              let currentCompanyIndex = topSearchData.findIndex(
                                (obj) => obj.type === "company"
                              );
                              let getArray = topSearchData[currentCompanyIndex];
                              const indexOfTopSearch = getArray?.data.findIndex(
                                (obj) => obj.id === item.id
                              );

                              if (indexOfTopSearch >= 0) {
                                getArray.data[indexOfTopSearch].status =
                                  !getArray.data[indexOfTopSearch].status;
                              }
                              let totalCount = {
                                Categories: 0,
                                Company: 0,
                              };
                              const matchIds = [];

                              const combineBothArrayTopSearchAndCompanyData = [
                                ...cloneCompanyData,
                                ...getArray.data,
                              ];
                              const selectedCount =
                                combineBothArrayTopSearchAndCompanyData.filter(
                                  (val) =>
                                    val?.status &&
                                    !matchIds.includes(val.id) &&
                                    matchIds.push(val.id)
                                );
                              const topSearchArray = chips[0].data.map(
                                (Val) => Val.id
                              );
                              let modifyPreviousCompanyData =
                                updatePreviousData(
                                  prevScreenData,
                                  item.status && item.status == true ? "remove" : "add",
                                  item.id,
                                  "companies"
                                );
                              updatePrevScreenData(
                                (pre) => modifyPreviousCompanyData
                              );
                              let haveUpdate = topSearchArray.includes(item.id);
                              if (haveUpdate) {
                                setHitCorrection({
                                  for: "TopSearch",
                                  action: "update",
                                });
                              } else {
                                setHitCorrection({
                                  for: "Company",
                                  action: "update",
                                });
                              }

                              totalCount["Company"] = selectedCount.length;
                              let groupName = "Company";
                              updateSelectSearchArray(
                                groupName,
                                totalCount,
                                currentObj
                              );
                              // dispatch(selectCompaninesAction(item._id));
                            }}
                          />
                          <label
                            style={{ fontFamily: "inter" }}
                            htmlFor="vehicle1"
                          >
                            {item?.name}
                          </label>
                        </div>

                        <div className="md-ads1">
                          <p>
                            {item.tickers && item.tickers.length > 0
                              ? item.tickers[0]
                              : ""}
                          </p>
                        </div>
                      </div>
                    ))}
                    {companyDataLoader ? (
                      <CompanyLoader />
                    ) : (
                      <div
                        onClick={hanlderShowMoreCompany}
                        className="showMoreButton"
                      >
                        Show More
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      onClick={() => {
                        activeTabHandler("incidentLocation");
                      }}
                      className="md-icons"
                    >
                      <img
                        className={currentTab == 3 ? "filter-img1" : ""}
                        src={Images?.locationIcon}
                        alt="img"
                        onClick={() => { }}
                      />
                      Incident Location
                    </div>
                  }
                  key="3"
                >
                  <div className="md-incident-loc1">
                    <div className="container">
                      <div className="row">
                        {!incidentLocationDataLoader && (
                          <>
                            <div className="col-sm-5 p-0 border-right-md1">
                              <div className=" custom-checkbox-top1 incident-md-active right-arrow">
                                <input
                                  type="checkbox"
                                  checked={incidentLocationData?.allStatus}
                                  onClick={(e) => {
                                    const statusState = e.target.checked;

                                    setHitCorrection({
                                      for: "Incident Location",
                                      action: "update",
                                    });

                                    const changeStatus = (array) =>
                                      array.map((val) => ({
                                        ...val,
                                        status: statusState,
                                      }));
                                    // Root Section

                                    let modifyPreviousData = updatePreviousData(
                                      prevScreenData,
                                      null,
                                      null,
                                      "locations",
                                      true
                                    );
                                    updatePrevScreenData(
                                      (e) => modifyPreviousData
                                    );
                                    const clone = incidentLocationData;
                                    clone.allStatus = statusState;

                                    // First Array
                                    clone.continents[0].countries =
                                      changeStatus(
                                        clone.continents[0].countries
                                      );
                                    clone.continents[0].continent_status =
                                      statusState;

                                    // First Array
                                    clone.continents[1].countries =
                                      changeStatus(
                                        clone.continents[1].countries
                                      );
                                    clone.continents[1].continent_status =
                                      statusState;

                                    // First Array
                                    clone.continents[2].countries =
                                      changeStatus(
                                        clone.continents[2].countries
                                      );
                                    clone.continents[2].continent_status =
                                      statusState;

                                    // First Array
                                    clone.continents[3].countries =
                                      changeStatus(
                                        clone.continents[3].countries
                                      );
                                    clone.continents[3].continent_status =
                                      statusState;

                                    // First Array
                                    clone.continents[4].countries =
                                      changeStatus(
                                        clone.continents[4].countries
                                      );
                                    clone.continents[4].continent_status =
                                      statusState;

                                    // First Array
                                    clone.continents[5].countries =
                                      changeStatus(
                                        clone.continents[5].countries
                                      );
                                    clone.continents[5].continent_status =
                                      statusState;

                                    // First Array
                                    clone.continents[6].countries =
                                      changeStatus(
                                        clone.continents[6].countries
                                      );
                                    clone.continents[6].continent_status =
                                      statusState;
                                    setTimeout(() => {
                                      dispatch(
                                        updateAdvSearchLocationArray(clone)
                                      );
                                    }, 400);
                                  }}
                                />
                                <label
                                  style={{ fontFamily: "inter" }}
                                  for="vehicle1"
                                >
                                  All
                                </label>
                              </div>
                              {incidentLocationData?.continents?.map(
                                (item, index) => (
                                  <div className=" custom-checkbox-top1 incident-md right-arrow">
                                    <input
                                      type="checkbox"
                                      checked={item.continent_status}
                                      onClick={(e) => {
                                        const statusState = e.target.checked;
                                        const changeStatus = (array) =>
                                          array.map((val) => ({
                                            ...val,
                                            status: statusState,
                                          }));
                                        const clone = incidentLocationData;
                                        let clonePrevScreenData =
                                          prevScreenData;

                                        const childArrayId = clone.continents[
                                          index
                                        ].countries
                                          .map((item) => item.name)
                                          .forEach((item) => {

                                            clonePrevScreenData =
                                              updatePreviousData(
                                                clonePrevScreenData,
                                                "remove",
                                                item,
                                                "locations"
                                              );
                                          });

                                        updatePrevScreenData(
                                          (e) => clonePrevScreenData
                                        );

                                        clone.continents[index].countries =
                                          changeStatus(
                                            clone.continents[index].countries
                                          );
                                        clone.continents[
                                          index
                                        ].continent_status = statusState;

                                        const childArraySelectedCache =
                                          clone.continents.map(
                                            (val) => val.continent_status
                                          );
                                        clone.allStatus =
                                          !childArraySelectedCache.includes(
                                            false
                                          );
                                        setHitCorrection({
                                          for: "Incident Location",
                                          action: "update",
                                        });
                                        setTimeout(() => {
                                          dispatch(
                                            updateAdvSearchLocationArray(clone)
                                          );
                                        }, 400);
                                      }}
                                    />
                                    <label
                                      style={{ fontFamily: "inter" }}
                                      for="vehicle1"
                                    >
                                      {item?.continent_code}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>

                            <div
                              className="col-sm-7 "
                              style={{ overflow: "auto", height: "500px" }}
                            >
                              {incidentLocationData?.continents?.map(
                                (item, continentsIndex) => {
                                  return item?.countries?.map(
                                    (itemNew, CountryIndex) => (
                                      <div className="custom-checkbox-top1 mt-2">
                                        <input
                                          type="checkbox"
                                          checked={itemNew?.status}
                                          onClick={(e) => {
                                            const statusState =
                                              e.target.checked;
                                            let modifyPreviousData =
                                              updatePreviousData(
                                                prevScreenData,
                                                statusState ? "add" : "remove",
                                                itemNew.name,
                                                "locations"
                                              );
                                            updatePrevScreenData(
                                              (pre) => modifyPreviousData
                                            );
                                            const clone = incidentLocationData;
                                            // Handler Target Array ---------

                                            clone.continents[
                                              continentsIndex
                                            ].countries = clone?.continents[
                                              continentsIndex
                                            ]?.countries?.map((item) =>
                                              item.name == itemNew?.name
                                                ? {
                                                  ...itemNew,
                                                  status: statusState,
                                                }
                                                : item
                                            );
                                            // Handler Target Array Status ---------
                                            let isTargetArrayIsAllSelectet;
                                            if (!statusState) {
                                              isTargetArrayIsAllSelectet = false;
                                            } else {
                                              isTargetArrayIsAllSelectet =
                                                clone.continents[
                                                  continentsIndex
                                                ].countries.map(
                                                  (val) => val?.status
                                                );
                                              isTargetArrayIsAllSelectet =
                                                !isTargetArrayIsAllSelectet.includes(
                                                  false
                                                );
                                            }
                                            clone.continents[
                                              continentsIndex
                                            ].continent_status =
                                              isTargetArrayIsAllSelectet;

                                            // Handler Root Array Status ---------
                                            const childArraySelectedCache =
                                              clone.continents.map(
                                                (val) => val.continent_status
                                              );
                                            clone.allStatus =
                                              !childArraySelectedCache.includes(
                                                false
                                              );
                                            setHitCorrection({
                                              for: "Incident Location",
                                              action: "update",
                                            });
                                            setTimeout(() => {
                                              dispatch(
                                                updateAdvSearchLocationArray(
                                                  clone
                                                )
                                              );
                                            }, 400);
                                          }}
                                        />
                                        <label
                                          style={{ fontFamily: "inter" }}
                                          for="vehicle1"
                                        >
                                          {itemNew?.name}
                                        </label>
                                      </div>
                                    )
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                        {incidentLocationDataLoader && (
                          <div
                            style={{
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="social-media-loader">
                              <div className="loader ">
                                <div className="circle" id="circleE"></div>
                                <div className="circle" id="circleS"></div>
                                <div className="circle" id="circleG"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      onClick={() => activeTabHandler("categories")}
                      className="md-icons"
                    >
                      <img
                        className={currentTab == 4 ? "filter-img1" : ""}
                        src={Images?.digram}
                        alt="img"
                      />
                      Categories
                    </div>
                  }
                  key="4"
                >
                  <div className="md-incident-loc1">
                    <div className="container">
                      <div className="row">
                        {!categoryDataLoader && (
                          <>
                            <div className="col-sm-5 p-0 border-right-md1">
                              {categoryData?.map((item, index) => (
                                <div
                                  onClick={() => {
                                    setActiveCategoriesItemNumber(index);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className=" custom-checkbox-top1 incident-md right-arrow"
                                >
                                  <input
                                    type="checkbox"
                                    checked={item?.status}
                                    onClick={(e) => {
                                      const statusState = e.target.checked;
                                      let categories = [...categoryData];

                                      // ESG FACTOR MODIFICATION
                                      if (index == 0) {
                                        let clonePrevScreenData = statusState ?
                                          updatePreviousData(
                                            prevScreenData,
                                            "allAdd",
                                            item,
                                            "esg_factors",
                                            false
                                          ) : updatePreviousData(
                                            prevScreenData,
                                            "remove",
                                            item,
                                            "esg_factors",
                                            true
                                          );

                                        updatePrevScreenData(
                                          (e) => clonePrevScreenData
                                        );

                                        const cloneFirstItem = categories[0];
                                        cloneFirstItem.status = statusState;

                                        const cloneFirstItemArrays =
                                          cloneFirstItem.value;
                                        const childFirst =
                                          cloneFirstItemArrays[0];
                                        childFirst.data = childFirst?.data?.map(
                                          (item) => ({
                                            ...item,
                                            status: statusState,
                                          })
                                        );
                                        const childSecond =
                                          cloneFirstItemArrays[1];
                                        childSecond.data =
                                          childSecond?.data?.map((item) => ({
                                            ...item,
                                            status: statusState,
                                          }));
                                        const childThid =
                                          cloneFirstItemArrays[2];
                                        childThid.data = childThid?.data?.map(
                                          (item) => ({
                                            ...item,
                                            status: statusState,
                                          })
                                        );
                                        categories[index].status = statusState;
                                        dispatch(
                                          updateAdvanceCategoriesData(
                                            categories,
                                            "5"
                                          )
                                        );
                                        setHitCorrection({
                                          for: "ESG Factors",
                                          action: "update",
                                        });

                                        const cloneTopSearchArray =
                                          topSearchData;
                                        const getCurrentItemIndex =
                                          cloneTopSearchArray?.findIndex(
                                            (item) => item.type === "esg_factor"
                                          );
                                        const getCurrentItem =
                                          cloneTopSearchArray[
                                            getCurrentItemIndex
                                          ].data?.map((item) => ({
                                            ...item,
                                            status: statusState,
                                          }));

                                        cloneTopSearchArray[
                                          getCurrentItemIndex
                                        ].data = getCurrentItem;
                                        dispatch(
                                          updateAdvncTopSearchData(
                                            cloneTopSearchArray
                                          )
                                        );
                                      } else {
                                        let clonePrevScreenData =
                                          updatePreviousData(
                                            prevScreenData,
                                            "remove",
                                            item,
                                            "ungc_breaches",
                                            true
                                          );
                                        updatePrevScreenData(
                                          (e) => clonePrevScreenData
                                        );
                                        const ungc = categories[1];
                                        ungc.status = statusState;
                                        const firstUngc = ungc?.value[0];
                                        firstUngc.data = firstUngc?.data?.map(
                                          (val) => ({
                                            ...val,
                                            status: statusState,
                                          })
                                        );
                                        const secondUngc = ungc?.value[1];
                                        secondUngc.data = secondUngc?.data?.map(
                                          (val) => ({
                                            ...val,
                                            status: statusState,
                                          })
                                        );
                                        const thirdUngc = ungc?.value[2];
                                        thirdUngc.data = thirdUngc?.data?.map(
                                          (val) => ({
                                            ...val,
                                            status: statusState,
                                          })
                                        );
                                        const fourthUngc = ungc?.value[3];
                                        fourthUngc.data = fourthUngc?.data?.map(
                                          (val) => ({
                                            ...val,
                                            status: statusState,
                                          })
                                        );
                                        setHitCorrection({
                                          for: "UN global compact principles",
                                          action: "update",
                                        });
                                        categories[1] = ungc;
                                        dispatch(
                                          updateAdvanceCategoriesData(
                                            categories,
                                            6
                                          )
                                        );
                                      }
                                    }}
                                  />
                                  <label
                                    style={{ fontFamily: "inter" }}
                                    for="vehicle1"
                                  >
                                    {/* {getItemName(item.name)} */}
                                    {item?.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <div
                              className="col-sm-7 p-0"
                              style={{ overflow: "auto", height: "500px" }}
                            >
                              {categoryData &&
                                categoryData[activeCategoriesItemNumber] &&
                                categoryData[activeCategoriesItemNumber][
                                  "value"
                                ]?.map((itemx, Index) => {
                                  if (itemx.name !== "")
                                    return (
                                      <div className="mm-advance">
                                        <div className="model-comp1">
                                          <p>{itemx._id}</p>
                                        </div>
                                        <div className="px-3">
                                          {itemx?.data?.map((itemy, index) => {
                                            return (
                                              <div className="check-box-style">
                                                <div
                                                  className="custom-checkbox-top1 mt-2"
                                                  key={index}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    checked={itemy.status}
                                                    onClick={(e) => {
                                                      const inputStatus =
                                                        e.target.checked;

                                                      if (
                                                        activeCategoriesItemNumber ==
                                                        0
                                                      ) {
                                                        let modifyPreviousData =
                                                          updatePreviousData(
                                                            prevScreenData,
                                                            inputStatus ? "add" : "remove",
                                                            itemy.id,
                                                            "esg_factors"
                                                          );
                                                        updatePrevScreenData(
                                                          (pre) =>
                                                            modifyPreviousData
                                                        );
                                                        let currentObj = null;
                                                        if (itemy["status"]) {
                                                          itemy["status"] =
                                                            false;
                                                        } else {
                                                          itemy["status"] =
                                                            true;
                                                        }
                                                        let totalCount = {
                                                          Company: 0,
                                                          esg_factor: 0,
                                                          SDGs: 0,
                                                          "PAI Indicators": 0,
                                                          "UN global compact principles": 0,
                                                          "Incident Location": 0,
                                                          "PAI Indicators": 0,
                                                        };
                                                        let val =
                                                          categoryData[
                                                            activeCategoriesItemNumber
                                                          ].value;
                                                        let updateParentState = true;

                                                        /* This block for update the topSearch Values */
                                                        let currentCategoryIndex =
                                                          topSearchData.findIndex(
                                                            (obj) =>
                                                              obj.type ===
                                                              getNameOfSection(
                                                                categoryData[
                                                                  activeCategoriesItemNumber
                                                                ].name
                                                              )
                                                          );
                                                        let getArray =
                                                          topSearchData[
                                                          currentCategoryIndex
                                                          ];

                                                        let indexOfTopSearch =
                                                          -1;
                                                        /* This end of topSearch block */

                                                        for (
                                                          let j = 0;
                                                          j <= val?.length - 1;
                                                          j++
                                                        ) {
                                                          let item =
                                                            val[j].data;
                                                          for (
                                                            let k = 0;
                                                            k <=
                                                            item?.length - 1;
                                                            k++
                                                          ) {
                                                            /* This block for update the topSearch Values */
                                                            indexOfTopSearch =
                                                              getArray
                                                                ? getArray.data.findIndex(
                                                                  (obj) =>
                                                                    obj.id ===
                                                                    item[k].id
                                                                )
                                                                : -1;
                                                            if (
                                                              indexOfTopSearch >=
                                                              0
                                                            ) {
                                                              getArray.data[
                                                                indexOfTopSearch
                                                              ].status =
                                                                item[k].status;
                                                            }
                                                            /* This end of topSearch block */

                                                            if (
                                                              item[k].id ===
                                                              itemy.id
                                                            ) {
                                                              currentObj = {
                                                                ...item[k],
                                                              };
                                                            }
                                                            if (
                                                              item[k].status
                                                            ) {
                                                              totalCount[
                                                                categoryData[
                                                                  activeCategoriesItemNumber
                                                                ].name
                                                              ] =
                                                                totalCount[
                                                                categoryData[
                                                                  activeCategoriesItemNumber
                                                                ].name
                                                                ] + 1;
                                                            }
                                                            if (
                                                              !item[k].status
                                                            ) {
                                                              updateParentState = false;
                                                            }
                                                          }
                                                        }
                                                        categoryData[
                                                          activeCategoriesItemNumber
                                                        ]["status"] =
                                                          updateParentState;
                                                        dispatch(
                                                          updateAdvanceCategoriesData(
                                                            categoryData,
                                                            "7"
                                                          )
                                                        );

                                                        updateSelectSearchArray(
                                                          categoryData[
                                                            activeCategoriesItemNumber
                                                          ].name,
                                                          totalCount,
                                                          currentObj
                                                        );

                                                        setHitCorrection({
                                                          for: "ESG Factors",
                                                          action: "update",
                                                        });
                                                        // setHitCorrection({
                                                        //   for: "UN global compact principles",
                                                        //   action: "update",
                                                        // });
                                                      } else {
                                                        let modifyPreviousData =
                                                          updatePreviousData(
                                                            prevScreenData,
                                                            "remove",
                                                            itemy,
                                                            "ungc_breaches"
                                                          );
                                                        updatePrevScreenData(
                                                          (pre) =>
                                                            modifyPreviousData
                                                        );
                                                        const cloneCategoryData =
                                                          categoryData;
                                                        const groupArray =
                                                          cloneCategoryData[1]
                                                            .value;
                                                        const targetItem =
                                                          groupArray[Index];
                                                        targetItem.data[index] =
                                                        {
                                                          ...itemy,
                                                          status: inputStatus,
                                                        };
                                                        const targetAllStatus =
                                                          groupArray
                                                            .map((Item) =>
                                                              Item.data.map(
                                                                (item) =>
                                                                  item.status
                                                              )
                                                            )
                                                            .flat();
                                                        cloneCategoryData[1].status =
                                                          !targetAllStatus?.includes(
                                                            false
                                                          );
                                                        dispatch(
                                                          updateAdvanceCategoriesData(
                                                            cloneCategoryData
                                                          )
                                                        );
                                                        setHitCorrection({
                                                          for: "UN global compact principles",
                                                          action: "update",
                                                        });
                                                      }
                                                    }}
                                                  />
                                                  <label
                                                    style={{
                                                      fontFamily: "inter",
                                                    }}
                                                    htmlFor="vehicle1"
                                                  >
                                                    {itemy.name}
                                                  </label>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                })}
                            </div>
                          </>
                        )}
                        {categoryDataLoader && (
                          <div
                            style={{
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="social-media-loader">
                              <div className="loader ">
                                <div className="circle" id="circleE"></div>
                                <div className="circle" id="circleS"></div>
                                <div className="circle" id="circleG"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div
                      onClick={() => activeTabHandler("industry")}
                      className="md-icons"
                    >
                      <img
                        className={currentTab == 5 ? "filter-img1" : ""}
                        src={Images?.indust}
                        alt="img"
                      />
                      Industry
                    </div>
                  }
                  key="5"
                >
                  <div className="md-incident-loc1">
                    <div className="container">
                      <div className="row">
                        {!industryDataLoader && (
                          <>
                            <div
                              className="col-md-5 p-0 border-right-md1 heightStyle1"
                              style={{ overflow: "auto", height: "500px" }}
                            >
                              {industryData?.length > 0 &&
                                industryData?.map((item, index) => (
                                  <Row>
                                    <div className=" custom-checkbox-top1 incident-md right-arrow">
                                      <input
                                        type="checkbox"
                                        checked={item?.status}
                                        onClick={(e) => {
                                          //  Remove selected items
                                          let ClonePrevScreenData =
                                            prevScreenData;
                                          const removeLoop = item.data;
                                          removeLoop.forEach((Item) => {
                                            ClonePrevScreenData =
                                              updatePreviousData(
                                                ClonePrevScreenData,
                                                "remove",
                                                Item.id,
                                                "industries"
                                              );
                                          });

                                          updatePrevScreenData(
                                            (e) => ClonePrevScreenData
                                          );

                                          const statusState = e.target.checked;
                                          let clone = [...industryData];
                                          const targetItem = clone[index];
                                          targetItem.status = statusState;
                                          targetItem.data = targetItem.data.map(
                                            (item) => ({
                                              ...item,
                                              status: statusState,
                                            })
                                          );

                                          dispatch(updateIndustryArray(clone));
                                          const findIds = item.data.map(
                                            (item) => item.id
                                          );
                                          const cloneTopSearchArray =
                                            topSearchData;
                                          const getCurrentItemIndex =
                                            cloneTopSearchArray?.findIndex(
                                              (item) => item.type === "industry"
                                            );
                                          const getCurrentItem =
                                            cloneTopSearchArray[
                                              getCurrentItemIndex
                                            ].data?.map((item) =>
                                              findIds.includes(item.id)
                                                ? {
                                                  ...item,
                                                  status: statusState,
                                                }
                                                : { ...item }
                                            );
                                          cloneTopSearchArray[
                                            getCurrentItemIndex
                                          ].data = getCurrentItem;
                                          dispatch(
                                            updateAdvncTopSearchData(
                                              cloneTopSearchArray
                                            )
                                          );
                                        }}
                                      />
                                      <label
                                        style={{ fontFamily: "inter" }}
                                        for="vehicle1"
                                      >
                                        {item._id}
                                      </label>
                                    </div>
                                  </Row>
                                ))}
                            </div>
                            <div
                              className="col-md-7 p-0"
                              style={{ overflow: "auto", height: "500px" }}
                            >
                              {industryData?.map((item, index) => (
                                <>
                                  <div className="model-comp1">
                                    <p>{item._id}</p>
                                  </div>
                                  {item?.data?.map((itemx, Index) => (
                                    <div className="px-3">
                                      <div className=" custom-checkbox-top1 mt-2 mb-1">
                                        <input
                                          type="checkbox"
                                          checked={itemx.status}
                                          onClick={(e) => {
                                            const statusState =
                                              e.target.checked;
                                            let modifyPreviousData =
                                              updatePreviousData(
                                                prevScreenData,
                                                statusState ? "add" : "remove",
                                                itemx.id,
                                                "industries"
                                              );
                                            updatePrevScreenData(
                                              (pre) => modifyPreviousData
                                            );
                                            let clone = [...industryData];
                                            const gotIndex = clone.findIndex(
                                              (item) => item._id == itemx.parent
                                            );
                                            clone[gotIndex].data = clone[
                                              gotIndex
                                            ]?.data?.map((item) =>
                                              item.id == itemx.id
                                                ? {
                                                  ...item,
                                                  status: statusState,
                                                }
                                                : {
                                                  ...item,
                                                }
                                            );

                                            const getAllStatusCurrentItem =
                                              clone[gotIndex].data.map(
                                                (item) =>
                                                  item.status ? true : false
                                              );

                                            clone[gotIndex].status =
                                              !getAllStatusCurrentItem.includes(
                                                false
                                              );

                                            dispatch(
                                              updateIndustryArray(clone)
                                            );

                                            const findIds = clone.map(item => item.data).flat().filter(item => item.status)
                                            const cloneTopSearchArray =
                                              topSearchData;
                                            const getCurrentItemIndex =
                                              cloneTopSearchArray?.findIndex(
                                                (item) =>
                                                  item.type === "industry"
                                              );

                                            cloneTopSearchArray[
                                              getCurrentItemIndex
                                            ].data = cloneTopSearchArray[
                                              getCurrentItemIndex
                                            ].data?.map((Item) =>
                                              Item.id == itemx.id ?
                                                {
                                                  ...Item,
                                                  status: statusState,
                                                } : findIds.includes(Item.id)
                                                  ? {
                                                    ...Item,
                                                    status: true,
                                                  }
                                                  : { ...Item }
                                            );
                                            dispatch(
                                              updateAdvncTopSearchData(
                                                cloneTopSearchArray
                                              )
                                            );
                                          }}
                                        />
                                        <label
                                          style={{ fontFamily: "inter" }}
                                          for="vehicle1"
                                        >
                                          {itemx.name}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ))}
                            </div>
                          </>
                        )}
                        {industryDataLoader && (
                          <div
                            style={{
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="social-media-loader">
                              <div className="loader ">
                                <div className="circle" id="circleE"></div>
                                <div className="circle" id="circleS"></div>
                                <div className="circle" id="circleG"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              </>
            }
          </Tabs>
        </div>
      </Modal>
    </>
  );
};

export default IndidentSearchMain
