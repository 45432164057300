import React from "react";

const ConfirmModal3 = ({ icon, description, itemName = "" }) => {
  return (
    <div className="reset-setting paddingTopBottom">
      <div className="delet-folder-coll">
        <div className="del-image">
          <img src={icon} />
        </div>
        <div className="delete-cll-text">
          <p style={{ wordBreak: "break-all" }} >{`Are you sure you want to remove your own supply chain list for ${itemName} ?`}</p>
        </div>
      </div>
      <div className="rem-fold-delete">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ConfirmModal3;
