import React, { useState } from "react";
import Images from "../../../Themes/ImageConstant";
import InputFeild from "../../../Componets/InputFeild";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { elements } from "chart.js";
import { CustomInput } from "../../../Componets/CustomInput";
import { apiConstants } from "../../../Constants/apiConstants";
import { Button } from "antd";
import { dispatch, navigate } from "../../../Utils/dispatchNavigate";
import { isInternetConnected } from "../../../Utils/InternetConnection";
import { resetPasswordAction } from "../../../Redux/Action/authentication";
import { useFormik } from "formik";
import {
  forgotSchema,
  iv_ForgotPassword,
  validationSchema,
} from "../../../Utils/schema";
const ForgotPassword = () => {
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    isInternetConnected() && dispatch(resetPasswordAction(values));
  };

  const formik = useFormik({
    initialValues: iv_ForgotPassword,
    validationSchema: validationSchema(forgotSchema),
    onSubmit,
  });
  return (
    <div className="page-header">
      <div className="left-right-mg">
        <div className="main-div  mt-4 mb-4 ">
          <div className="login-form">
            <div className="login-page-logo">
              <img onClick={() => navigate("/")} src={Images.logo} />
            </div>

            <div className="log-side">
              <div className="login-text">
                <h5>Reset password</h5>
              </div>

              <div className="em-add">
                <p>
                  Please enter your email address. You will receive an email to
                  reset your password.
                </p>
              </div>
              <form noValidate onSubmit={formik.handleSubmit}>
                <div className="input-text">
                  <label for="fname">Email</label>
                  <CustomInput
                    type="text"
                    id="fname"
                    name="email"
                    placeholder="Enter your email address"
                    onChange={({target})=>formik.setFieldValue("email",target.value.trim())}
                    value={formik.values.email}
                    error={formik.errors.email}
                  />
                </div>

                <div className="login-link conf-btn">
                  <div>
                    <button className="login-link button" type="submit">
                      Confirm and Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
