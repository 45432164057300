import React from "react";
import { Box, IconButton, SvgIcon, useTheme } from "@mui/material";
import { useAppSelector } from "../../../redux/configuareStore";
import { useGraphObject } from "../../context/MyContext";
import CustomTooltip from "../../GraphToolTip/CustomTooltip";

function UndoButton() {
  const canUndo = useAppSelector((state) => state.toolBox.toolBox.canUndo);
  const { graphObject } = useGraphObject();
  const theme = useTheme();
  return (
    <Box>
      <CustomTooltip title="Undo" placement="left">
        <span>
          <IconButton onClick={() => graphObject?.undo()} disabled={!canUndo}>
            {/* Undo Icon */}
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 8H16.5C18.9853 8 21 10.0147 21 12.5C21 14.9853 18.9853 17 16.5 17H3M3 8L6 5M3 8L6 11"
                  stroke={
                    canUndo ? theme.palette.gray.main : theme.palette.gray[200]
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
          </IconButton>
        </span>
      </CustomTooltip>
    </Box>
  );
}
export default UndoButton;
