import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./App.css";
import "../src/style/index.css";
import "../src/style/Responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';

import Routers from "./Router";
import { dispatchFn, naviagetFn } from "./Utils/dispatchNavigate";
import Modal from "./Componets/Modal";
// STE
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show } = useSelector((state) => state.global.modal);


  dispatchFn(dispatch);
  naviagetFn(navigate);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          duration: 2000
        }}
      />
      <Routers />
      {show && <Modal />}
    </>
  );
}

export default App;
