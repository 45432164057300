import React from 'react';
import { SvgIcon } from '@mui/material';

function RecentIncidentIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
      >
        <path
          d="M9.99957 1L2.06859 10.6934C1.72703 11.1109 1.55625 11.3196 1.55471 11.4956C1.55336 11.6486 1.62218 11.7939 1.74148 11.8897C1.87867 12 2.14837 12 2.68776 12H8.99957L7.99957 19L15.9305 9.30659C16.2721 8.88912 16.4429 8.68039 16.4444 8.50439C16.4458 8.35135 16.377 8.20613 16.2577 8.11026C16.1205 8 15.8508 8 15.3114 8H8.99957L9.99957 1Z"
          stroke="url(#paint0_linear_13904_22856)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_13904_22856"
            x1="2.10616"
            y1="10"
            x2="16.4444"
            y2="10"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF0000" />
            <stop offset="1" stopColor="#F28C28" />
            <stop offset="1" stopColor="#FF7518" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}
export default RecentIncidentIcon;
