import React, { useEffect, useState } from "react";
import Images from "../../../Themes/ImageConstant";
import InputFeild from "../../../Componets/InputFeild";
import { Link, useLocation } from "react-router-dom";
import { dispatch, navigate } from "../../../Utils/dispatchNavigate";
import { Row } from "antd";
import ImageConst from "../../../Themes/ImageConstant";
import {
  resendAction,
  resetPasswordAction,
  verifyMailAction,
} from "../../../Redux/Action/authentication";
// import { verifyMail } from "../../../Redux/Action/authentication";

const ConfirmEmail = () => {
  const { state } = useLocation();
  return (
    <div className="page-header confirm-mk">
      <div className="main-div">
        <div className="login-form">
          <div className="login-page-logo">
            <img src={Images.logo} />
          </div>

          <div className="log-side">
            <div className="login-text new-col">
              <h5>Confirm your email </h5>
            </div>

            <div className="em-add">
              <p>
                We’ve sent you an email to verify your email address. Please
                enter with the link to your email.
              </p>
            </div>

            <div className="email-envelop">
              <img src={Images.envelop} />
            </div>

            <div className="email-res">
              <Row className="imgMailRowStyle">
                <div>
                  <span> Did not receive the email? </span>
                  <a
                    onClick={() => {
                      dispatch(resendAction({ email: state?.email }));
                    }}>
                    Resend
                  </a>
                </div>
                <div>
                  {state?.email?.includes("gmail") ? (
                    <Row className="imgMailTextStyle">
                      <img src={ImageConst.ic_gmail} />
                      <p className="imgMailPstyle">Open Gmail</p>
                    </Row>
                  ) : null}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
