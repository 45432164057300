import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import {
  CustomAccordion,
  CustomAccordionSummary,
} from "../../../components/CustomeAccordion";
import {
  FilterParametersEnum,
  TabEnum,
} from "../../../enums/FilterSideBarEnums";
import ClearFilterBar from "./ClearFilterBar";
import TotalIncidentCountAccordion from "./Accordions/TotalIncidentCountAccordion";
import DefaultAccordion from "./Accordions/DefaultAccordion";
import RiskScoreAccordion from "./Accordions/RiskScoreAccordion";
import IndustryAccordion from "./Accordions/IndustryAccordion";
import TierTypeAccordion from "./Accordions/TierTypeAccordion";
import CompanyLocationAccordion from "./Accordions/CompanyLocationAccordion";
import IncidentCategoryAccordion from "./Accordions/IncidentCategoryAccordion";
import CompanyListAccordion from "./Accordions/CompanyListAccordion";
import {
  setExcludeExpandedNodesFromFiltering,
  setExcludeMainCompanyFromFiltering,
} from "../../../redux/GraphExploration/FilterSideBarSlice";
import ToggleParameter from "./toggleParameter";
import { updateHiddenNodes } from "../../../utils";
import { useGraphObject } from "../../context/MyContext";

function NodeTab({ handleCancelFilter }) {
  const filterParameters = useAppSelector(
    (state) => state.filterSideBar.filterParameters
  );
  const excludeMainCompanyFromFiltering = useAppSelector(
    (state) => state.filterSideBar.excludeMainCompanyFromFiltering
  );
  const excludeExpandedNodesFromFiltering = useAppSelector(
    (state) => state.filterSideBar.excludeExpandedNodesFromFiltering
  );
  const centerNode = useAppSelector((state) => state.graph.centerNode);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const border = `1px solid ${theme.palette.gray[50]}`;
  const { graphObject } = useGraphObject();
  const getProperAccordionDetails = (id) => {
    switch (id) {
      case FilterParametersEnum.TOTAL_INCIDENT_COUNT:
        return <TotalIncidentCountAccordion />;
      case FilterParametersEnum.RISK_SCORE:
        return <RiskScoreAccordion />;
      case FilterParametersEnum.INDUSTRY:
        return <IndustryAccordion />;
      case FilterParametersEnum.TIER_TYPE:
        return <TierTypeAccordion />;
      case FilterParametersEnum.COMPANY_LOCATION:
        return <CompanyLocationAccordion />;
      case FilterParametersEnum.INCIDENT_CATEGORY:
        return <IncidentCategoryAccordion />;
      case FilterParametersEnum.COMPANY_LIST:
        return <CompanyListAccordion />;
      default:
        return <DefaultAccordion />;
    }
  };
  return (
    <>
      <ClearFilterBar handleCancelFilter={handleCancelFilter} />
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(100% - 154px)",
        }}
      >
        {/* <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle3">
            Exclude {centerNode?.data?.name || "Main Company"} from filtering
          </Typography>
          <Switch
            checked={excludeMainCompanyFromFiltering}
            onChange={() => {
              dispatch(
                setExcludeMainCompanyFromFiltering(
                  !excludeMainCompanyFromFiltering
                )
              );
            }}
          />
        </Box> */}
        <ToggleParameter
          label={`Exclude '${centerNode?.name || "Main Company"}' From Filtering`}
          checked={excludeMainCompanyFromFiltering}
          onChange={(value) => {
            dispatch(setExcludeMainCompanyFromFiltering(value));
            updateHiddenNodes(graphObject);
          }}
        />
        <ToggleParameter
          label={`Exclude expanded nodes From Filtering`}
          checked={excludeExpandedNodesFromFiltering}
          onChange={(value) => {
            dispatch(setExcludeExpandedNodesFromFiltering(value));
            updateHiddenNodes(graphObject);
          }}
        />
        {filterParameters
          .filter((item) => item.level === 0 && item.tab === TabEnum.NODE)
          .map((filterParameter) => (
            <Box
              sx={{
                // borderTop: border,
                borderBottom: border,
              }}
              key={filterParameter.id}
            >
              <CustomAccordion>
                <CustomAccordionSummary>
                  <Typography variant="subtitle3">
                    {filterParameter.label}
                  </Typography>
                </CustomAccordionSummary>
                {getProperAccordionDetails(filterParameter.id)}
              </CustomAccordion>
            </Box>
          ))}
      </div>
    </>
  );
}
export default NodeTab;
