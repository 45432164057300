import { useEffect, useState } from "react";
import { Input, Pagination, Select } from "antd";
import ImageConst from "../../../Themes/ImageConstant";
import { dispatch } from "../../../Utils/dispatchNavigate";
import { number } from "yup";
import { debounce } from "../../../Utils";

// This use to change total item render in the page
const offSetPage = [
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
];

// This is use to render next and previous text with icons
const pageNavigation = (_, type, originalElement) => {
  return type === "prev" ? (
    <span>
      <img src={ImageConst.chevron} alt="left arrow" />
      &nbsp;
      <span>Previous</span>
    </span>
  ) : type === "next" ? (
    <span>
      <span>Next</span>&nbsp;
      <img src={ImageConst.chevronRighta} alt="right arrow" />
    </span>
  ) : (
    originalElement
  );
};

export const BottomPagination = ({
  paginationHandler,
  paginationPayload,
  dataLength
}) => {

  const [pageSearch, setPageSearch] = useState("")
  // @param value {number}
  const onRowLimitChange = (value) => paginationHandler(pre => ({ ...pre, rowLimit: value }))

  // @param value {number}
  const onPageChange = () => {
    const dataString = Number(pageSearch)
    debounce(() => {
      paginationHandler(pre => ({ ...pre, currentPage: dataString == 0 ? 1 : dataString }))
    })
  };
  return (
    <div className="row-per CustomPagination paginateSupply">
      <span className="offsetContainer">
        <span>Rows per page:&nbsp;</span>
        <Select
          className="offsetSelectPage"
          defaultValue="15"
          style={{ width: 58, alignItems: 'center' }}
          onChange={onRowLimitChange}
          options={offSetPage}
        />
      </span>
      <div className="page-num">
        <Pagination
          current={paginationPayload?.currentPage}
          pageSize={paginationPayload?.rowLimit}
          total={dataLength}
          onChange={(e) => paginationHandler(pre => ({ ...pre, currentPage: e }))}
        />
      </div>
      <div className="goto-pg">
        <span>Go to page:</span>
        <div className="pg-number">
          <Input
            type="number"
            name="search2"
            value={pageSearch}
            onChange={(e) => setPageSearch(e.target.value)}
            style={{ height: "28px" }}
          />
          <button
            type="button"
            onClick={onPageChange}
            style={{ height: "28px" }}
          >
            Go
          </button>
        </div>
      </div>
    </div>
  );
};

