import { Modal, Row, Switch, Tabs } from "antd";
import { useEffect, useState } from "react";
import Images from "../../Themes/ImageConstant";
import { useSelector } from "react-redux";
import CheckBox from "../checkBox";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  getCategoriesAction,
  getCompaniesAction,
  getIndustryAction,
  getTopSearchAction,
  incidentMapAction,
  updateCategoriesData,
  updateIncidentData,
} from "../../Redux/Action/incidents";
import {
  editWishListAction,
  getWishListDetailAction,
} from "../../Redux/Action/watchListActions";
import {
  getCountryListAction,
  updateCountriesData,
} from "../../Redux/Action/comapanyPageAction";
import moment from "moment/moment";
import { calculatePreviousDate, dateButtonObj } from "../../Utils";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import ModalCloseButton from "../modalCloseButton";
import ModalHeader from "../modalHeaderComp";
let countries = [];
let activeBar;
let typeArrayGroup = [];

const item = {
  topSearch: [],
  company: [],
  incidentLocation: [],
  categories: [],
  industry: [],
};

export const AdvanceSearch = ({ title, open, onclose, typeBar, id, height }) => {
  const _countryList = useSelector(
    (state) => state?.companyPageReducer?.allData
  );
  const watchListData = useSelector(
    (state) => state?.watchListReducer?.watchlist
  );
  const industryList = useSelector(
    (state) => state?.incidentReducer?.industryList
  );
  const [IndustryList, setIndustryList] = useState(
    industryList?.length > 0 ? [...industryList] : []
  );
  const selectedSearchArrayItem = [
    { groupName: "Top Searches", selectedItemLength: 0 },
    { groupName: "Company", selectedItemLength: 0 },
    { groupName: "Incident Location", selectedItemLength: 0 },
    { groupName: "Categories", selectedItemLength: 0 },
    { groupName: "Industry", selectedItemLength: 0 },
    { groupName: "Health Care", selectedItemLength: 0 },
    { groupName: "Extractives & Minerals Processing", selectedItemLength: 0 },
    { groupName: "Esg Factors", selectedItemLength: 0 },
    { groupName: "Esg Sdgs", selectedItemLength: 0 },
    { groupName: "Esg Pais", selectedItemLength: 0 },
  ];
  const categoriesList = useSelector(
    (state) => state?.incidentReducer?.getCategories
  );
  const watchListId = useSelector(
    (state) => state?.watchListReducer?.watchListId
  );
  const [selectedSearchItems, setSelectedSearchItems] = useState(
    selectedSearchArrayItem
  );
  const [searchChips, setSearchChips] = useState(true);
  const [search, setSearch] = useState("");
  useEffect(() => {
    let count = 0;
    selectedSearchItems?.forEach((val) => {
      if (val.selectedItemLength > 0) {
        count += val.selectedItemLength;
      }
    });
    if (count == 0) {
      setSearchChips(false);
    } else {
      setSearchChips(true);
    }
  }, [selectedSearchItems]);

  useEffect(() => {
    let count = 0;
    selectedSearchItems?.forEach((val) => {
      if (val.selectedItemLength > 0) {
        count += val.selectedItemLength;
      }
    });
    if (count == 0) {
      setSearchChips(false);
    } else {
      setSearchChips(true);
    }
  }, [selectedSearchItems]);

  const industryListStatic = useSelector(
    (state) => state?.incidentReducer?.industryListStatic
  );

  const _categoriesListStatic = useSelector(
    (state) => state?.incidentReducer?._categoriesListStatic
  );

  const _categoriesList = useSelector(
    (state) => state?.incidentReducer?.getCategories
  );

  const [wategoriesList, setCategoriesList] = useState([..._categoriesList]);

  const lastDataArray0 = categoriesList?.[0]?.value[0]?.data;
  const topSearch = useSelector((state) => state?.incidentReducer?.topSearch);
  const [TopSearch, setTopSearch] = useState(topSearch);
  const esg_factors = lastDataArray0
    ?.filter((item) => item.status)
    ?.map((item) => item.id);
  const lastDataArray1 =
    categoriesList?.[1]?.value?.[categoriesList[0].value?.length - 1]?.data;
  const esg_sdgs = lastDataArray1
    ?.filter((item) => item.status)
    ?.map((item) => item.id);
  const lastDataArray2 =
    categoriesList?.[2]?.value?.[categoriesList[0].value?.length - 1]?.data;
  const esg_pais = lastDataArray2
    ?.filter((item) => item.status)
    ?.map((item) => item.id);
  const [countryList, setCountryList] = useState(_countryList);
  const [activeCategoriesItemNumber, setActiveCategoriesItemNumber] =
    useState(0);
  const [currentTab, setCurrentTab] = useState("");

  useEffect(() => {
    if (typeBar === "Company" || typeBar === "fullMap") {
      //// full-map define because it is also use in fullMap Components
      setCurrentTab("2");
    } else if (typeBar === "Location") {
      setCurrentTab("3");
    } else if (typeBar === "Categories") {
      setCurrentTab("4");
    } else if (typeBar === "Industry") {
      setCurrentTab("5");
    }
  }, [typeBar]);

  const onChange = (checked) => { };
  let CompaniesList = useSelector(
    (state) => state?.incidentReducer?.companiesList
  );
  const [companiesList, setCompaniesList] = useState(CompaniesList);
  let networkData = {};

  const mergedData = {
    ...networkData,
    countries: countries
      ?.filter((i) => {
        if (i.status) {
          return i.id;
        }
      })
      ?.map((i) => i.id),
    companies: CompaniesList?.filter((i) => {
      if (i.status) {
        return i.name;
      }
    })?.map((i) => i.id),
    industries: IndustryList?.filter((e) => e.childs)
      ?.filter((i) => {
        if (i.status) {
          return i.id;
        }
      })
      ?.map((i) => i.id),
    esg_factors,
    esg_pais,
    esg_sdgs,
  };

  networkData = {
    wishlist: watchListId === "" ? watchListData[0]?._id : watchListId,
    name: null,
    locations:
      typeBar === "Location" || typeBar === "fullMap"
        ? mergedData?.countries
        : null,
    companies:
      typeBar === "Company" || typeBar === "fullMap"
        ? mergedData?.companies
        : null,
    esg_factors:
      typeBar === "Categories" || typeBar === "fullMap"
        ? mergedData.esg_factors
        : null,
    esg_sdgs:
      typeBar === "Categories" || typeBar === "fullMap"
        ? mergedData.esg_sdgs
        : null,
    esg_pais:
      typeBar === "Categories" || typeBar === "fullMap"
        ? mergedData.esg_pais
        : null,
    industries:
      typeBar === "Industry" || typeBar === "fullMap"
        ? mergedData?.industries
        : null,
  };

  const onchangeSearchData = (e) => {
    setSearch(e);
    if (currentTab == 1) {
      if (e.trim()?.length > 0) {
        let newArr = JSON.parse(JSON.stringify(topSearch));
        for (let i in newArr) {
          let _Arr = newArr[i].data;
          newArr[i].data = _Arr.filter((x) =>
            x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
          );
          setTopSearch(newArr);
        }
      } else {
        setTopSearch(topSearch);
      }
    }
    if (currentTab == 2) {
      if (e.trim()?.length > 0) {
        let arr = JSON.parse(JSON.stringify(CompaniesList));
        arr = arr.filter((x) =>
          x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
        );
        setCompaniesList(arr);
      } else {
        setCompaniesList(CompaniesList);
      }
    }
    if (currentTab == 3) {
      if (e.trim()?.length > 0) {
        let arrLoc = JSON.parse(JSON.stringify(_countryList));
        for (let n in arrLoc?.continents) {
          let _Arr = arrLoc?.continents[n].countries;
          arrLoc.continents[n].countries = _Arr.filter((x) =>
            x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
          );
        }
        setCountryList(arrLoc);
      } else {
        setCountryList(_countryList);
      }
    }
    if (currentTab == 4) {
      if (e.trim()?.length > 0) {
        const arr = JSON.parse(JSON.stringify(_categoriesListStatic));
        let arrLoc = arr;
        for (let n in arrLoc) {
          for (let k in arrLoc[n]?.value) {
            let _Arr = arrLoc[n]?.value[k].data;
            arrLoc[n].value[k].data = _Arr.filter((x) =>
              x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
            );
          }
        }
        let arrLoc1 = arrLoc;
        setCategoriesList(arrLoc1);
      } else {
        setCategoriesList(_categoriesListStatic);
      }
    }
    if (currentTab == 5) {
      if (e.trim()?.length > 0) {
        const arrLoc = JSON.parse(JSON.stringify(industryListStatic));
        for (let n in arrLoc) {
          let _Arr = arrLoc[n]?.childs;
          arrLoc[n].childs = _Arr.filter((x) =>
            x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
          );
        }
        setIndustryList(arrLoc);
      } else {
        setIndustryList(industryListStatic);
      }
    }
  };

  useEffect(() => {
    dispatch(getCompaniesAction());
    dispatch(getCountryListAction());
    dispatch(getCategoriesAction());
    dispatch(getIndustryAction());
    dispatch(getTopSearchAction());
  }, []);

  useEffect(() => {
    setCountryList(_countryList);
    let continents = _countryList?.continents?.filter((val) =>
      val.countries?.filter((e) => e.status)
    );
    countries = continents?.map((val) => val.countries)?.flat();
    let selectedItemLength = countries?.filter((val) => val.status);

    let groupName = "Incident Location";

    updateSelectSearchArray(groupName, selectedItemLength?.length);
  }, [_countryList]);

  const removeAllVal = (data) => {
    let allSelectedItemsZero = false;

    for (let i = 0; i < data?.length; i++) {
      if (data[i].selectedItemLength == 0) {
        allSelectedItemsZero = true;
        break;
      }
    }
  };

  const removeSearchSelectedItem = (groupName) => {
    removeAllVal(selectedSearchItems);
    switch (groupName) {
      case "Company":
        for (const val of CompaniesList) {
          val.status = false;
        }
        updateSelectSearchArray("Company", 0);
        break;
      case "Top Searches":
        topSearch?.forEach((search) => {
          search?.data?.forEach((data) => (data.status = false));
        });
        updateSelectSearchArray("Top Searches", 0);
        break;
      case "Incident Location":
        let newRecord = countryList;
        newRecord?.continents?.forEach((continent) => {
          continent.continent_status = false;
          continent?.countries?.forEach((country) => (country.status = false));
        });
        newRecord.allStatus = false;
        setCountryList(newRecord);
        updateSelectSearchArray("Incident Location", 0);

        break;
      case "Health Care":
      case "Financials":
      case "Extractives & Minerals Processing":
        let clone = [...IndustryList];
        let index = clone.findIndex((item) => item.name === groupName);
        clone[index]?.childs?.forEach((child) => { });
        // dispatch(updateIncidentData(clone));
        updateSelectSearchArray(groupName, 0);
        break;
      case "Esg Factors":
      case "Esg Sdgs":
      case "Esg Pais":
        // let clon = [..._categoriesList];
        // let inde = clon.findIndex((item) => item.name === groupName);
        // _categoriesList[inde]["status"] = false;
        // let valueArray = _categoriesList[inde]["value"];
        // for (let i = 0; i <= valueArray?.length - 1; i++) {
        //   let dataArrya = valueArray[i]["data"];
        //   for (let j = 0; j <= dataArrya?.length - 1; j++) {
        //     dataArrya[j]["status"] = false;
        //   }
        // }
        // dispatch(updateCategoriesData(clon));
        updateSelectSearchArray(groupName, 0);
        break;
      default:
        break;
    }
  };

  const updateSelectSearchArray = (groupName, selectedItem) => {

    let updateSearchSelectedItemArray = [...selectedSearchItems];
    updateSearchSelectedItemArray = updateSearchSelectedItemArray.map((val) =>
      val.groupName === groupName
        ? { ...val, selectedItemLength: selectedItem }
        : val
    );
    setSelectedSearchItems(updateSearchSelectedItemArray);
  };

  const advanceSearchCancel = () => {
    removeSearchSelectedItem("Company");
    removeSearchSelectedItem("Top Searches");
    removeSearchSelectedItem("Incident Location");
    removeSearchSelectedItem("Health Care");
    removeSearchSelectedItem("Financials");
    removeSearchSelectedItem("Extractives & Minerals Processing");
    removeSearchSelectedItem("Esg Factors");
    removeSearchSelectedItem("Esg Sdgs");
    removeSearchSelectedItem("Esg Pais");
    setSelectedSearchItems(selectedSearchArrayItem);

    // }
    // isSearchItem = false
  };

  const updateCountryData = ({ continentsIndex, CountryIndex, type }) => {
    dispatch(updateCountriesData({ continentsIndex, CountryIndex, type }));
  };

  const networkCall = () => {
    const newData = { ...networkData };
    delete newData["wishlist"];
    delete newData["name"];
    const lastDate = dateButtonObj[3].type;
    newData["end"] = moment().format("YYYY-MM-DD");
    newData["start"] = moment(
      calculatePreviousDate(new Date(), lastDate)
    ).format("YYYY-MM-DD");
    dispatch(incidentMapAction(newData));
  };

  return (
    <Modal
      title={<ModalHeader title={title} />}
      className="custom-modal advanced cross-model map-advanced-search-modal"
      centered
      closeIcon={<ModalCloseButton />}
      onCancel={onclose}
      open={open}
      footer={[
        <div className="foot-sec-md2-full-map">
          <div className="md-toggle-btn">
            <p>
              <Switch defaultChecked onChange={onChange} /> Show Sever Incidents
              Only
            </p>
          </div>
          <div className="foot-but-md">
            <button
              className="can-btn chang"
              onClick={() => {
                onclose();
                advanceSearchCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="rest-btn"
              onClick={() => {
                if (typeBar !== "fullMap") {
                  dispatch(editWishListAction(networkData));
                  dispatch(getWishListDetailAction(watchListId));
                  onclose();
                } else {
                  networkCall();
                  onclose();
                }
              }}
            >
              Add to Watchlist
            </button>
          </div>
        </div>,
      ]}
    >
      <div className="mod-search-bar">
        {!searchChips ? (
          <input
            className="search-mad-icon"
            type="text"
            value={search}
            placeholder="Search "
            onChange={({ target }) => onchangeSearchData(target.value)}
          />
        ) : (
          <div className="selecteditem-searchbar">
            {selectedSearchItems?.map((val, key) => {
              return (
                val?.selectedItemLength > 0 && (
                  <div className="selectedItemBox" key={key}>
                    {key == 0 && val.selectedItemLength > 0 ? (
                      <img
                        style={{ width: "15px", height: "15px" }}
                        src={Images.starSharp}
                      />
                    ) : key == 1 && val.selectedItemLength > 0 ? (
                      <img
                        style={{ width: "15px", height: "15px" }}
                        src={Images.hotel}
                      />
                    ) : key == 2 && val.selectedItemLength > 0 ? (
                      <img
                        style={{ width: "15px", height: "15px" }}
                        src={Images.locationIcon}
                      />
                    ) : key == 7 && val.selectedItemLength > 0 ? (
                      <img src={Images.graygrid} />
                    ) : key == 8 && val.selectedItemLength > 0 ? (
                      <img src={Images.graygrid} />
                    ) : key == 9 && val.selectedItemLength > 0 ? (
                      <img src={Images.graygrid} />
                    ) : key == 6 && val.selectedItemLength > 0 ? (
                      <img src={Images.indust} />
                    ) : key == 5 && val.selectedItemLength > 0 ? (
                      <img src={Images.indust} />
                    ) : null}
                    <div className="selectedItemText">
                      {val.selectedItemLength} {val.groupName}
                    </div>
                    <div
                      className="deleteSelectedItem"
                      onClick={() => {
                        removeSearchSelectedItem(val.groupName);
                      }}
                    >
                      X
                    </div>
                  </div>
                )
              );
            })}
          </div>
        )}
      </div>
      <div className="model-tabs">
        <Tabs
          activeKey={currentTab}
          onChange={(e) => {
            setCurrentTab(e);
          }}
          tabBarExtraContent={
            <div>
              {/* {currentTab == 2 ? (
                <div className="import-csv">
                  <p>
                    <img src={Images.uplode} /> Import
                    <span>
                      <img src={Images.circleInfo} />
                      <div className="hover-format">
                        <span>Upload a CSV file format</span>
                      </div>
                    </span>
                  </p>
                </div>
              ) : null} */}
            </div>
          }
        >
          <Tabs.TabPane
            tab={
              <div className="md-icons">
                <img
                  className={currentTab == 1 ? "filter-img" : ""}
                  src={Images?.starSharp}
                  alt="img"
                />
                Top Searches
              </div>
            }
            key="1"
          >
            <div
              className="topSearch-list-style"
              style={{ maxHeight: "540px" }}
            >
              {topSearch &&
                topSearch?.map((Item, Index) => {
                  return (
                    <div className="top-sc-cmp-model" key={Index}>
                      <div className="model-comp">
                        <p>{Item?.type}</p>
                      </div>
                      {Item?.data?.map((item, index) => (
                        <div className="model-comp-name">
                          <div className="custom-checkbox-top mt-2 mb-2">
                            <CheckBox
                              visible={item?.status}
                              onClick={() => {
                                if (
                                  !typeArrayGroup.includes(
                                    topSearch[Index].type
                                  )
                                ) {
                                  typeArrayGroup = [
                                    ...typeArrayGroup,
                                    topSearch[Index].type,
                                  ];
                                }

                                let getArray = topSearch[Index];
                                let currentArray = getArray.data[index];
                                if (topSearch[Index].type === "company") {
                                  CompaniesList.map((val) =>
                                    val.id === item.id
                                      ? (val.status = !val.status)
                                      : null
                                  );
                                } else if (
                                  topSearch[Index].type === "industry"
                                ) {
                                  let clone = [...industryList];
                                  const INDEX = clone.findIndex(
                                    (val) => val.id === currentArray.id
                                  );
                                  clone[INDEX]["status"] =
                                    !clone[INDEX]["status"];
                                  for (
                                    let i = 0;
                                    i <= clone[INDEX].childs?.length - 1;
                                    i++
                                  ) {
                                    clone[INDEX].childs[i]["status"] =
                                      clone[INDEX]["status"];
                                  }
                                  // for (
                                  //   let i = 0;
                                  //   i <= clone[index].childs.length - 1;
                                  //   i++
                                  // ) {
                                  //   clone[index].childs[i]["status"] =
                                  //     clone[index]["status"];
                                  // }
                                  dispatch(updateIncidentData(clone));
                                }
                                if (currentArray.status) {
                                  currentArray.status = false;
                                } else {
                                  currentArray.status = true;
                                }
                                let count = 0;
                                for (
                                  let i = 0;
                                  i <= topSearch?.length - 1;
                                  i++
                                ) {
                                  for (
                                    let j = 0;
                                    j <= topSearch[i].data?.length - 1;
                                    j++
                                  ) {
                                    if (topSearch[i].data[j].status) {
                                      count = count + 1;
                                    }
                                  }
                                }
                                let groupName = "Top Searches";
                                updateSelectSearchArray(groupName, count);
                              }}
                            />
                            <label
                              style={{ fontFamily: "inter" }}
                              htmlFor="vehicle1"
                            >
                              {item?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                })}
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane
            // disabled={
            //   typeBar === "Company" || typeBar === "fullMap" ? false : true
            // }
            tab={
              <div className="md-icons">
                <img
                  className={currentTab == 2 ? "filter-img" : ""}
                  src={Images?.hotel}
                  alt="img"
                />
                Company
              </div>
            }
            key="2"
          >
            <div className="md-compy-tc" style={{ height: height }}>
              {CompaniesList?.map((item, index) => (
                <div className="md-flex-div" key={index}>
                  <div className="custom-checkbox-top mt-2 mb-1">
                    <CheckBox
                      visible={item?.status}
                      onClick={() => {
                        CompaniesList.map((val) =>
                          val.id === item.id ? (val.status = !val.status) : null
                        );
                        let selectedItemLength = CompaniesList.filter(
                          (val) => val.status === true
                        );
                        let groupName = "Company";
                        updateSelectSearchArray(
                          groupName,
                          selectedItemLength?.length
                        );
                        // dispatch(selectCompaninesAction(item._id));
                      }}
                    />
                    <label htmlFor="vehicle1">{item?.name}</label>
                  </div>

                  <div className="md-ads">
                    <p>ADS</p>
                  </div>
                </div>
              ))}
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane
            // disabled={
            //   typeBar === "Location" || typeBar === "fullMap" ? false : true
            // }
            tab={
              <div className="md-icons">
                <img
                  className={currentTab == 3 ? "filter-img" : ""}
                  src={Images?.locationIcon}
                  alt="img"
                />
                Incident Location
              </div>
            }
            key="3"
          >
            <div className="md-incident-loc1">
              <div className="container">
                <div
                  className="row"
                  style={{ overflow: "auto", height: "540px" }}
                >
                  <div className="col-sm-5 p-0 border-right-md">
                    <div className=" custom-checkbox-top incident-md-active right-arrow">
                      <input
                        type="checkbox"
                        checked={countryList.allStatus}
                        onClick={() => {
                          updateCountryData({ type: "All" });
                        }}
                      />
                      <label for="vehicle1">All</label>
                    </div>
                    {countryList?.continents?.map((item, index) => (
                      <div className=" custom-checkbox-top incident-md right-arrow">
                        <input
                          type="checkbox"
                          checked={item.continent_status}
                          onClick={(e) => {
                            updateCountryData({
                              continentsIndex: index,
                              type: "continent",
                            });
                          }}
                        />
                        <label for="vehicle1">{item?.continent_code}</label>
                      </div>
                    ))}
                  </div>

                  <div
                    className="col-sm-7 "
                    style={{ overflow: "auto", height: "540px" }}
                  >
                    {countryList?.continents?.map((item, continentsIndex) => {
                      return item?.countries?.map((itemNew, CountryIndex) => (
                        <div className=" custom-checkbox-top mt-2">
                          <input
                            type="checkbox"
                            checked={itemNew?.status}
                            onClick={(e) => {
                              updateCountryData({
                                continentsIndex,
                                CountryIndex,
                                type: "country",
                              });
                            }}
                          />
                          <label for="vehicle1">{itemNew?.name}</label>
                        </div>
                      ));
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane
            // disabled={
            //   typeBar === "Categories" || typeBar === "fullMap" ? false : true
            // }
            tab={
              <div className="md-icons">
                <img
                  className={currentTab == 4 ? "filter-img" : ""}
                  src={Images?.digram}
                  alt="img"
                />
                Categories
              </div>
            }
            key="4"
          >
            <div className="md-incident-loc1">
              <div className="container">
                <div
                  className="row"
                  style={{ overflow: "auto", maxHeight: "540px" }}
                >
                  <div className="col-sm-5 p-0 border-right-md">
                    {categoriesList.map((item, index) => (
                      <div
                        onClick={() => setActiveCategoriesItemNumber(index)}
                        className=" custom-checkbox-top incident-md right-arrow"
                      >
                        <CheckBox
                          visible={item.status}
                          onClick={() => {
                            let categories = [...categoriesList];
                            let count = 0;

                            categories[index].status =
                              !categories[index].status;
                            let val = categories[index].value;
                            for (let j = 0; j < val?.length; j++) {
                              let item = val[j].data;
                              for (let k = 0; k < item?.length; k++) {
                                item[k].status = categories[index].status;
                                if (item[k].status) {
                                  count = count + 1;
                                }
                              }
                            }
                            dispatch(updateCategoriesData(categories));

                            updateSelectSearchArray(
                              categories[index].name,
                              count
                            );
                          }}
                        />
                        <label for="vehicle1">{item.name}</label>
                      </div>
                    ))}
                  </div>
                  {categoriesList &&
                    categoriesList[activeCategoriesItemNumber] &&
                    categoriesList[activeCategoriesItemNumber]["value"]?.map(
                      (itemx, index) => (
                        <div
                          className="col-sm-7 p-0"
                          style={{ overflow: "auto", height: "540px" }}
                        >
                          <div className="model-comp">
                            <p>{itemx.name}</p>
                          </div>
                          <div className="px-3">
                            {itemx?.data?.map((itemy, index) => {
                              return (
                                <div className="check-box-style">
                                  <div
                                    className="custom-checkbox-top mt-2"
                                    key={index}
                                  >
                                    <CheckBox
                                      visible={itemy.status}
                                      onClick={() => {
                                        if (itemy["status"]) {
                                          itemy["status"] = false;
                                        } else {
                                          itemy["status"] = true;
                                        }
                                        let count = 0;
                                        let val =
                                          categoriesList[
                                            activeCategoriesItemNumber
                                          ].value;
                                        let updateParentState = true;
                                        for (
                                          let j = 0;
                                          j <= val?.length - 1;
                                          j++
                                        ) {
                                          let item = val[j].data;
                                          for (
                                            let k = 0;
                                            k <= item?.length - 1;
                                            k++
                                          ) {
                                            if (item[k].status) {
                                              count = count + 1;
                                            }
                                            if (!item[k].status) {
                                              updateParentState = false;
                                            }
                                          }
                                        }
                                        categoriesList[
                                          activeCategoriesItemNumber
                                        ]["status"] = updateParentState;
                                        dispatch(
                                          updateCategoriesData(categoriesList)
                                        );
                                        updateSelectSearchArray(
                                          categoriesList[
                                            activeCategoriesItemNumber
                                          ].name,
                                          count
                                        );
                                      }}
                                    />
                                    <label htmlFor="vehicle1">
                                      {itemy.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane
            // disabled={
            //   typeBar === "Industry" || typeBar === "fullMap" ? false : true
            // }
            tab={
              <div className="md-icons">
                <img
                  className={currentTab == 5 ? "filter-img" : ""}
                  src={Images?.indust}
                  alt="img"
                />
                Industry
              </div>
            }
            key="5"
          >
            <div className="md-incident-loc1">
              <div className="container">
                <div
                  className="row"
                  style={{ overflow: "auto", maxHeight: "540px" }}
                >
                  <div
                    className="col-md-5 p-0 border-right-md"
                    style={{ overflow: "auto", height: "540px" }}
                  >
                    {industryList?.map((item, index) => (
                      <Row>
                        <div className=" custom-checkbox-top incident-md right-arrow">
                          <CheckBox
                            visible={item?.status}
                            onClick={() => {
                              let clone = [...industryList];
                              clone[index]["status"] = !clone[index]["status"];
                              for (
                                let i = 0;
                                i <= clone[index]?.childs?.length - 1;
                                i++
                              ) {
                                clone[index].childs[i]["status"] =
                                  clone[index]["status"];
                              }
                              setIndustryList(clone);
                              dispatch(updateIncidentData(clone));
                              if (clone[index]["status"]) {
                                updateSelectSearchArray(
                                  item.name,
                                  item.childs?.length
                                );
                              } else {
                                updateSelectSearchArray(item.name, 0);
                              }
                            }}
                          />
                          <label for="vehicle1">{item.name}</label>
                        </div>
                      </Row>
                    ))}
                  </div>
                  {industryList?.map((item, index) => (
                    <div className="col-md-7 p-0">
                      <div className="model-comp">
                        <p>{item.name}</p>
                      </div>
                      {item?.childs?.map((itemx, Index) => (
                        <div className="px-3">
                          <div className=" custom-checkbox-top mt-2 mb-1">
                            <CheckBox
                              visible={itemx.status}
                              onClick={() => {
                                let clone = [...industryList];
                                clone[index].childs[Index]["status"] =
                                  !clone[index].childs[Index]["status"];

                                dispatch(updateIncidentData(clone));
                                let count = 0;
                                for (
                                  let i = 0;
                                  i <= item.childs?.length - 1;
                                  i++
                                ) {
                                  if (item.childs[i]["status"]) {
                                    count = count + 1;
                                  }
                                }
                                if (count == item?.childs?.length) {
                                  clone[index]["status"] =
                                    !clone[index]["status"];
                                } else if (count == 0) {
                                  clone[index]["status"] = false;
                                }
                                dispatch(updateIncidentData(clone));
                                updateSelectSearchArray(item.name, count);
                              }}
                            />
                            <label for="vehicle1">{itemx.name}</label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};
