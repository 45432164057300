import React from 'react';
import { Box, useTheme } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useAppDispatch, useAppSelector } from '../../redux/configuareStore';
import { setCurrentTab } from '../../redux/GraphExploration/FilterSideBarSlice';
import { TabEnum } from '../../enums/FilterSideBarEnums';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const tabData = [
  { label: 'Nodes', value: TabEnum.NODE, index: 0 },
  { label: 'Edges', value: TabEnum.EDGE, index: 1 },
];
function FilterSideBarTabs() {
  const dispatch = useAppDispatch();
  const handleChange = (event, newValue) => {
    dispatch(setCurrentTab(newValue));
  };
  const value = useAppSelector((state) => state.filterSideBar.currentTab);
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '40px',
        borderBottom: `1px solid ${theme.palette.gray[50]}}`,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          minHeight: '0px',
          '& .MuiTabs-indicator': {
            backgroundColor: '#0195CE',
          },
        }}
      >
        {tabData.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            value={tab.value}
            {...a11yProps(tab.index)}
            sx={{
              textTransform: 'none',
              flex: 1,
              color: theme.palette.gray[500],
              padding: theme.spacing(1, 2),
              minHeight: '0px',
              ...theme.typography.body,
              '&.Mui-selected': {
                color: '#0195CE',
                ...theme.typography.bodySelected,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
export default FilterSideBarTabs;
