import React from "react";
import { Box, useTheme } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FilterParametersEnum } from "../../../../enums/FilterSideBarEnums";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/configuareStore";
import { setModalCurrentTab } from "../../../../redux/GraphExploration/rightClickMenuSlice";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabData = [
  { label: "Customers", value: FilterParametersEnum.CUSTOMER, index: 0 },
  { label: "Suppliers", value: FilterParametersEnum.SUPPLIER, index: 1 },
  { label: "Partners", value: FilterParametersEnum.PARTNER, index: 2 },
];

function SpecificRelationsModalTabs() {
  const dispatch = useAppDispatch();
  const handleChange = (event, newValue) => {
    dispatch(setModalCurrentTab(newValue));
  };
  const value = useAppSelector((state) => state.rightClickMenu.currentTab);
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "40px",
        borderBottom: `1px solid ${theme.palette.gray[50]}}`,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          minHeight: "0px",
          "& .MuiTabs-indicator": {
            backgroundColor: "#0195CE",
          },
        }}
      >
        {tabData.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            value={tab.value}
            {...a11yProps(tab.index)}
            sx={{
              textTransform: "none",

              color: theme.palette.gray[500],
              padding: theme.spacing(1, 2),
              minHeight: "0px",
              ...theme.typography.body,
              "&.Mui-selected": {
                color: "#0195CE",
                ...theme.typography.bodySelected,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
export default SpecificRelationsModalTabs;
