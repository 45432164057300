import moment from "moment";

const RegulatoryFinesPenaltiesCard = ({
  index,
  regulatory_body,
  reason,
  currency,
  amount = 0,
  date,
  target_company,
  screen,
  id,
}) => {
  let formattedAmount = "";
  try {
    formattedAmount = amount.toLocaleString("en-US", {
      style: "currency",
      currency: currency,
    });
  } catch (error) {
    formattedAmount = amount;
  }

  return (
    <div className="principleBox">
      <div className="headerDiv">
        <div className="row">
          <div className="col">
            <div className="srNoView">{index + 1}</div>
          </div>
        </div>
        <div className="col" style={{ marginLeft: "10px" }}>
          <ContentItem label={"Fine Amount"} text={formattedAmount} />
          {regulatory_body && (
            <ContentItem
              label={"Regulator"}
              text={regulatory_body}
              style={{ marginTop: "12px" }}
            />
          )}
          {reason && (
            <ContentItem
              label={"Reason"}
              text={reason}
              style={{ marginTop: "12px" }}
            />
          )}
          <div className="row" style={{ marginTop: "14px" }}>
            <div className="col">
              {screen == "Incident" ? (
                target_company && (
                  <div className="principleTitle">
                    Target Company:{" "}
                    <span style={{ fontWeight: 600 }}>{target_company}</span>
                  </div>
                )
              ) : (
                <div
                  className="principleTitle mt-14 color0195CE downl-btn"
                  onClick={() =>
                    window.open(`/incidentdetails?${id}`, "_blank")
                  }
                >
                  View the Incident
                </div>
              )}
            </div>
            <div className="col">
              {date !== null && (
                <div className="principleTitle ml-12 d-flex justify-content-end">
                  {moment(date).format("MMM, YYYY")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContentItem = ({ label, text, style, extraText }) => {
  return (
    <div className="row" style={style}>
      <span
        style={{
          fontFamily: "Inter",
          fontSize: 13,
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: 0,
          textAlign: "left",
          color: "#3D4353",
        }}
      >
        {label}
      </span>
      <span
        style={{
          marginLeft: 12,
          display: "flex",
          color: "#07132F",
          fontSize: 13,
          fontWeight: "bold",
          lineHeight: "24px",
          letterSpacing: 0,
          textAlign: "left",
        }}
      >
        {text}
      </span>
      {extraText && (
        <span
          style={{
            marginLeft: 12,
            display: "flex",
            fontWeight: "bold",
          }}
        >
          {extraText}
        </span>
      )}
    </div>
  );
};
export default RegulatoryFinesPenaltiesCard;
