import {
  makeFullMap_severityData,
  make_severityData,
} from "../../Componets/MapGL/ map_utils";
import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
  isLoading: false,
  result: {},
  countryList: [],
  allData: [],
  showCountryList: [],
  getCompanyDetails: [],
  supplyChainIncident: [],
  sentimentAnalysis: null,
  sentimentByEsg: [],
  sentimentBySource: {},
  sentimentScore: {},
  incidentSeverityBarData: {},
  incidentSeverityPieData: [],
  incidentWithYear: [],
  incidentCategorization: [],
  peerCompaniesRate: [],
  IncidentDistributionOverTime: [],
  mostUsedWords: [],
  serverityTrend: [],
  IncidentComparison: [],
  continentData: [],
  serverityMap: [],
  mapsData: [],
  incidentTimelineData: [],
  incidentTimelineData_loader: false,
  incidentListLoader: false,
  ESGRiskScoreList: [],
  getRegulatoryFinesPenaltiesList: [],
  regulatoryLegalActionsData: {
    list: [],
    timelineList: {},
  },
  socialMediaCoverage: {
    socialMedia: {},
    sentiment: {},
  },
  riskTrendData: {
    data: {},
    groupType: "",
  },
  peerRiskScore: [],
  riskLineChart: [],
  sentimentLoader:false,
  severityLoader:false,
  allDataState:[]
};

const selectAll = (value, allData, newRecord) => {
  for (let i in allData.continents) {
    newRecord.continents[i].continent_status = value;
    for (let j in allData.continents[i].countries) {
      newRecord.continents[i].countries[j].status = value;
    }
  }
};

const AllCheckLoop = (allData) => {
  let newRecord = allData;
  newRecord.allStatus = !allData?.allStatus;
  selectAll(newRecord.allStatus, allData, newRecord);
  return newRecord;
};

const AllCountriesLoop = (allData, payload) => {
  let newRecord = allData;

  let country = allData.continents[payload.continentsIndex];
  let newContinents = newRecord.continents[payload.continentsIndex];
  newContinents.continent_status = !country.continent_status;
  for (let i in country.countries) {
    newContinents.countries[i].status = !country.countries[i].status;
  }

  // to check is all the continents are check or not
  newRecord.allStatus = newRecord.continents.every(
    (item) => item.continent_status
  );

  return newRecord;
};

const SingleCountryCheck = (allData, payload) => {
  let newRecord = allData;
  let country = allData.continents[payload.continentsIndex];
  let newContinents = newRecord.continents[payload.continentsIndex];

  newContinents.countries[payload.CountryIndex].status =
    !country.countries[payload.CountryIndex].status;

  // to check is all the country are check of continent or not

  newContinents.continent_status = newContinents.countries.every(
    (item) => item.status
  );

  newRecord.allStatus = newRecord.continents.every(
    (item) => item.continent_status
  );

  return newRecord;
};

function getUpdatedAllData(payload, allData) {
  const _allData = allData;
  if (payload.type === "All") {
    return AllCheckLoop(_allData);
  } else if (payload.type === "continent") {
    return AllCountriesLoop(_allData, payload);
  } else if (payload.type === "country") {
    return SingleCountryCheck(_allData, payload);
  }
}

function getCountryData(data) {
  let continentArr = [];
  if (data?.length > 0) {
    for (let i in data) {
      data[i].continent_status = false;
      let countryList = data[i].countries;
      for (let j in countryList) {
        countryList[j].status = false;
      }
    }
    continentArr = data;
  }
  return {
    allStatus: false,
    continents: continentArr,
  };
}

export const companyPageReducer = (state = initialState, action) => {
  let { payload } = action;
  switch (action.type) {
    case types.API_GET_COUNTRY_LIST_LOAD:
      return { ...state, isLoading: true };

    case types.API_GET_COUNTRY_LIST_SUCCESS:
      let allcountryData = getCountryData(payload.data);
      return {
        ...state,
        isLoading: false,
        allData: allcountryData,
        allDataState :allcountryData
      };

    case types.API_UPDATE_COUNTRY_LIST:
      let updateAllData = getUpdatedAllData(
        payload,
        JSON.parse(JSON.stringify(state.allData))
      );
      state.allData = updateAllData;
      return {
        ...state,
        activeCountries: state.showCountryList,
      };

    case types.API_UPDATE_MODAL_WISHLIST_DETAILS:
      if (action?.data?.locations?.length > 0) {
        for (let i in action.data.locations) {
          if (state?.allData?.continents?.length > 0) {
            let All_data = state?.allData?.continents;
            let continentsIndex = All_data?.findIndex(
              (x) =>
                x.continent_code === action?.data?.locations[i]?.continent_code
            );
            let CountryIndex = All_data[continentsIndex].countries.findIndex(
              (x) => x.id === action.data.locations[i]._id
            );
            let payload = {
              continentsIndex,
              CountryIndex,
              type: "country",
            };
            let updateAllData = getUpdatedAllData(
              payload,
              JSON.parse(JSON.stringify(state.allData))
            );
            state.allData = updateAllData;
          }
        }
      }
      return {
        ...state,
      };

    case types.API_GET_COMPANY_DETAIL_SUCCESS:
      return { ...state, isLoading: false, getCompanyDetails: payload?.data };
    case types.API_GET_COMPANY_DETAIL_LOAD:
      return { ...state, isLoading: true };

    case types.API_GET_SupplyChain_Incident_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_SupplyChain_Incident_SUCCESS:
      return { ...state, isLoading: false, supplyChainIncident: payload.data };

    case types.API_GET_SentimentTrend_LOAD:
      return { ...state, isLoading: true,sentimentLoader:true  };
    case types.API_GET_SentimentTrend_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sentimentAnalysis: payload.data,
        sentimentLoader:false 
      };

    case types.API_GET_SentimentTrendt_FAIL:
      return { ...state, isLoading: false, sentimentLoader:false  };

    case types.API_GET_Sentiment_Analysis_LOAD:
      return { ...state, isLoading: true,};
    case types.API_GET_Sentiment_Analysis_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sentimentByEsg: payload.data?.sentimentByEsg,
        sentimentBySource: payload.data?.sentimentBySource,
      };

    case types.API_GET_Sentiment_Analysis_FAIL:
      return { ...state, isLoading: false };

    case types.API_GET_SENTIMENTSCORE_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_SENTIMENTSCORE_SUCCESS:
      return { ...state, isLoading: false, sentimentScore: payload.data };

    case types.API_GET_SENTIMENTSCORE_FAIL:
      return { ...state, isLoading: false };

    case types.API_GET_INCIDENT_SEVERITY_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_INCIDENT_SEVERITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        incidentSeverityBarData: payload.data?.barData,
        incidentSeverityPieData: payload.data?.pieData,
      };

    case types.API_GET_INCIDENT_SEVERITY_FAIL:
      return { ...state, isLoading: false };

    case types.API_GET_INCIDENT_WITHYEAR_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_INCIDENT_WITHYEAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        incidentWithYear: payload.data,
      };

    case types.API_GET_INCIDENT_WITHYEAR_FAIL:
      return { ...state, isLoading: false };

    case types.API_GET_INCIDENT_CATEGORIZATION_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_INCIDENT_CATEGORIZATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        incidentCategorization: payload.data,
      };

    case types.API_GET_INCIDENT_CATEGORIZATION_FAIL:
      return { ...state, isLoading: false };

    case types.API_GET_PEER_COMPANIES_INCIDENT_RATE_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_PEER_COMPANIES_INCIDENT_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        peerCompaniesRate: payload.data,
      };

    case types.API_GET_PEER_COMPANIES_INCIDENT_RATE_FAIL:
      return { ...state, isLoading: false };

    case types.API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        IncidentDistributionOverTime: payload.data,
      };
    case types.API_GET_INCIDENT_COMPARISON_SUCCESS:
      return payload?.data
        ? {
            ...state,
            isLoading: false,
            IncidentComparison:
              action.dataType == "multiple"
                ? payload.data
                : [...state.IncidentComparison, payload.data],
          }
        : { ...state, isLoading: false };
    // remove the incident Comparison
    case types.REMOVE_INCIDENT_COMPARISON:
      const comparisonItemClone = [...state.IncidentComparison];
      const index = comparisonItemClone.findIndex(
        (i) => i.id === action.payload
      );
      if (index >= 0) comparisonItemClone.splice(index, 1);
      return {
        ...state,
        IncidentComparison: [...comparisonItemClone],
        isLoading: false,
      };

    case types.API_GET_MOST_USED_WORDS_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_MOST_USED_WORDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mostUsedWords: payload.data,
      };

    case types.API_GET_MOST_USED_WORDS_FAIL:
      return { ...state, isLoading: false };

    case types.API_GET_SERVITY_TREND_LOAD:
      return { ...state, isLoading: true,severityLoader:true };
    case types.API_GET_SERVITY_TREND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        severityLoader:false,
        severityTrend: payload.data,
      };

    case types.API_GET_SERVITY_TREND_FAIL:
      return { ...state, isLoading: false,severityLoader:false, };
    case types.API_GET_SEVIERTY_ONMAP_SUCCESS:
      return { ...state, isLoading: false, mapsData: payload?.data };
    case types.API_GET_SEVIERTY_ONMAP_LOAD:
      return { ...state, isLoading: true };
    case types.RESET_DATA:
      return {
        ...initialState,
      };

    case types.API_GET_SERVERITY_MAP_LOAD:
      return { ...state, isLoading: true };

    case types.API_GET_SERVERITY_MAP_SUCCESS:
      let serverityMap_modified = make_severityData(payload.data[0]?.mapData);
      serverityMap_modified = makeFullMap_severityData(serverityMap_modified);
      return {
        ...state,
        isLoading: false,
        continentData: payload.data[0]?.continentData || [],
        serverityMap: serverityMap_modified || [],
      };

    case types.API_GET_SERVERITY_MAP_FAIL:
      return { ...state, isLoading: false };
    case types.API_GET_INCIDENT_TIMELINE_LOAD:
      return {
        ...state,
        incidentTimelineData_loader: true,
        isLoading: true,
      };
    case types.API_GET_INCIDENT_TIMELINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        incidentTimelineData_loader: false,
        incidentTimelineData: payload?.data,
      };
    case types.INCIDENT_LIST_LOADER:
      return { ...state, incidentListLoader: payload };

    //NEW
    case types.GET_ESG_RISK_SCORE_SUCCESS:
      return { ...state, ESGRiskScoreList: payload?.data, isLoading: false };
    case types.GET_REGULATORY_FINES_PENALTIES_SUCCESS:
      return {
        ...state,
        getRegulatoryFinesPenaltiesList: payload?.data?.list,
        isLoading: false,
      };
    case types.GET_REGULATORY_LEGAL_ACTION_SUCCESS:
      return {
        ...state,
        regulatoryLegalActionsData: {
          list: payload?.data?.list,
          timelineList: payload?.data?.timeline?.data,
        },
        isLoading: false,
      };
    case types.GET_SOCIAL_MEDIA_COVER_SUCCESS:
      return {
        ...state,
        socialMediaCoverage: {
          socialMedia: payload?.data?.socialMedia,
          sentiment: payload?.data.sentiment,
        },
        isLoading: false,
      };
    case types.GET_UNGCP_BREACHES_SUCCESS:
      return {
        ...state,
        ungcpBreaches: payload?.data,
        isLoading: false,
      };

    case types.GET_RISK_TREND_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_RISK_TREND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        riskTrendData: payload.data,
      };

    case types.GET_COMPANY_RISK_PEER_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_COMPANY_RISK_PEER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        peerRiskScore: payload.data,
      };

    case types.GET_COMPANY_RISK_LINE_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_COMPANY_RISK_LINE_SUCCESS:

      return {
        ...state,
        isLoading: false,
        riskLineChart: payload.data,
      };
      case types.RESET_PORTFOLIO_DATA_ON_PAGE_LOAD:
      return {
        ...state,
        getCompanyDetails: [],
      };

    default:
      return { ...state };
  }
};


