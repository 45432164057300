import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChartCard } from "../../../../Layouts/ChartCard";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import {
  IncidentComparisonAction,
  RemoverIncidentComparisonAction,
} from "../../../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import ImageConst from "../../../../Themes/ImageConstant";
import { AutoComplete, Input, Modal, Row } from "antd";
import { getCountryDataforSupplyChain } from "../../../../Redux/Action/supplyChainAction";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import RadarChart from "../../../socialMediaCoverage/charts/radarChart";
import { useNavigate } from "react-router-dom";
import useDebouncedApiCall from "../../../../hook/useDebouncedSearch";
import { searchCompanyListActionLoad } from "../../../../Redux/Action/global";

const dummyData = {
  series: [
    {
      name: "Chevron",
      data: [10, 10, 65, 155],
    },
  ],
  options: {
    chart: {
      height: 200,
      type: "radar",
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: function (val, i) {
          if (i % 1 === 0) {
            return val;
          } else {
            return "";
          }
        },
      },
    },

    plotOptions: {
      radar: {
        size: 100,
        polygons: {
          fill: {
            colors: ["#CED2E0", "#E7E9F0"],
          },
        },
      },
    },
    colors: ["#FFDC20", "#FFD880"],
    markers: {
      size: 4,
      strokeColor: "#FFDC20",
      strokeWidth: 2,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: [
        "Incident Number",
        "Risk",
        "Importance",
        "Neg Sentiment",
        "Severity",
      ],
    },
  },
};

const PeerCompaniesIncidentComparison = ({
  id,
  start,
  end,
  companyName,
  dateChange,
}) => {
  const companiesList = useSelector(
    (state) => state?.incidentReducer?.companiesList
  );
  const [options, setOptions] = useState([]);
  const [countryname, setcountryName] = useState(null);
  const [comparisonModal, setComparisonModal] = useState(false);

  useEffect(() => {
    dispatch(getCountryDataforSupplyChain());
    dispatch(IncidentComparisonAction({ id, start, end, type: "multiple" }));
  }, [dateChange]);

  const { countryList } = useSelector((state) => state?.supplyChainReducer);
  const { IncidentComparison } = useSelector(
    (state) => state?.companyPageReducer
  );
  const navigate = useNavigate();
  const { searchCompaniesData } = useSelector((state) => state?.global);

  const { searchTerm, handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );
  useEffect(() => {
    setOptions(countryList);
  }, [countryList]);

  const HandleSearch = (value) => {
    let res = [];
    if (!value) {
      res = [];
    } else {
      res = countryList.filter((x) =>
        x?.label?.toUpperCase()?.includes(value?.toUpperCase().trim())
      );
    }
    setOptions(res);
  };

  const Chart = useCallback(() => {
    return IncidentComparison?.map((item) => {
      return (
        <div key={"value"}>
          <ChartBottomTitle title={item.name}>
            <Row justify={"space-between"}>
              <RadarChart
                path={"company"}
                data={{
                  "incident Number": Math.round(item?.totalIncidents),
                  severity: Math.round(item?.severity_score),
                  "neg Sentiment": Math.round(item?.negativeSentiment),
                  risk: Math.round(item?.risk),
                }}
                labelObj={{
                  "incident Number": "Incident Number",
                  severity: "Severity",
                  "neg Sentiment": "Neg Sentiment",
                  risk: "Risk",
                }}
              />
              {/* <ReactApexChart
                options={dummyData.options}
                series={[
                  {
                    name: item?.name,
                    data: [
                      item?.totalIncidents,
                      item?.negativeSentiment,
                      item?.totalIncidents,
                      0,
                      0, // Todo change this if client | backend provide third key
                    ],
                  },
                ]}
                type="radar"
                height={250}
              /> */}
              {IncidentComparison?.length < 2 ? (
                <>
                  <RadarChart
                    path={"company"}
                    data={{
                      "incident Number": 0,
                      risk: 0,

                      "neg Sentiment": 0,
                      severity: 0,
                    }}
                    labelObj={{
                      "incident Number": "Incident Number",
                      risk: "Risk",

                      "neg Sentiment": "Neg Sentiment",
                      severity: "Severity",
                    }}
                  />
                  {/* <ReactApexChart
                    options={dummyData.options}
                    series={[
                      {
                        name: " ",
                        data: [
                          0,
                          0,
                          0,
                          0,
                          0,
                           // Todo change this if client | backend provide third key
                        ],
                      },
                    ]}
                    type="radar"
                    height={250}
                  /> */}
                  <ReactApexChart
                    options={dummyData.options}
                    series={[
                      {
                        name: " ",
                        data: [
                          0,
                          0,
                          0,
                          0,
                          0, // Todo change this if client | backend provide third key
                        ],
                      },
                    ]}
                    type="radar"
                    height={250}
                  />
                </>
              ) : null}
            </Row>
          </ChartBottomTitle>
          {id !== item.id && (
            <Row justify={"center"}>
              <button
                border={0}
                style={{
                  borderColor: "transparent",
                  background: "none",
                  gap: 5,
                }}
                onClick={() =>
                  dispatch(RemoverIncidentComparisonAction(item.id))
                }
              >
                <Row justify="space-between" gutter={2}>
                  <img alt="" src={ImageConst.cross} />
                  <span style={{ color: "#920000", marginLeft: 5 }}>
                    Remove
                  </span>
                </Row>
              </button>
            </Row>
          )}
        </div>
      );
    });
  }, [IncidentComparison]);

  const renderItem = (title, item) => ({
    value: item,
    label: (
      <div
        style={{
          pointerEvents: id === item ? "none" : "",
          cursor: id === item ? "no-drop" : "pointer",
        }}
      >
        {title}
      </div>
    ),
  });

  // country option on first render
  const CreateOptions = useMemo(() => {
    let arr = [];
    for (let i = 0; i < options?.length - 1; i++) {
      if (options[i].id === id) continue;
      arr.push(renderItem(options[i].label, options[i].id));
    }
    return arr;
  }, [options, id]);
  

  return (
    <section className="left-text">
      <ChartCard
        showTimeBarOnTop={false}
        title={"Peer Companies’ Incident Comparison"}
      >
        <div className="peer-company-comparision-chart-container">
          <div className="radar-Title-organizationName">{companyName}</div>
          <div className="radar-subTitle-organizationName">{companyName}</div>

          <div className="comparison-gaph">
            <div className="radar-chart-sp">
              {/* to show dummy if not data found of this particular company */}
              {IncidentComparison?.length === 0 && (
                <div className="rdr-wrap-Um">
                  <ChartBottomTitle title={companyName}>
                    <RadarChart
                      path={"company"}
                      data={{
                        "incident Number": 0,
                        risk: 0,
                        importance: 0,
                        "neg Sentiment": 0,
                        severity: 0,
                      }}
                      labelObj={{
                        "incident Number": "Incident Number",
                        risk: "Risk",
                        "neg Sentiment": "Neg Sentiment",
                        severity: "Severity",
                      }}
                    />
                  </ChartBottomTitle>
                </div>
              )}
              <Chart />
            </div>
            <div
              style={{
                margin: "auto",
                width: "fit-content",
                marginTop: "10px",
              }}
            >
              <button
                type="button"
                className="add-to-Com"
                onClick={() => setComparisonModal(true)}
              >
                <img alt="" src={ImageConst.PlushBlack} />
                <span>Add to Comparison</span>
              </button>
            </div>
          </div>
        </div>
      </ChartCard>
      <Modal
        open={comparisonModal}
        closable
        title={"Advanced Search"}
        width={1000}
        onCancel={() => setComparisonModal(false)}
        footer={() => {}}
        className="comparisonModal"
      >
        <div style={{ overflow: "hidden" }}>
          <AutoComplete
            placeholder={"Search company"}
            style={{
              width: "100%",
              margin: "18px 0",
              border: "none",
              boxShadow: "none",
            }}
            suffixIcon={<img src={ImageConst.search} alt="" />}
            // popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={1000}
            className="autocomplete-input"
            options={searchCompaniesData?.data}
            size="large"
            onSearch={handleSearch}
            value={countryname}
            onSelect={(e, i) => {
              
              i?.id !== id &&
                dispatch(
                  IncidentComparisonAction({
                    id: i?.id,
                    start,
                    end,
                    type: "single",
                  })
                );
              setcountryName(i.label);
              setComparisonModal(false);
            }}
            onChange={(e, i) => {
              setcountryName(e);
            }}
          ></AutoComplete>
        </div>
      </Modal>
    </section>
  );
};

export default PeerCompaniesIncidentComparison;
