import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { useLocation } from "react-router";
import { Button, Popover } from "antd";
import { storage } from "../../Utils";
import { googleLogout } from "@react-oauth/google";
import { toast } from "react-toastify";

const SideBar = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const logOut = () => {
    if (storage.getLocal("access_token")) {
      googleLogout();
    } else if (storage.getSession("access_token")) {
      googleLogout();
    }
    storage.clearStorage();
    toast.success("Logged out successfully");
  };

  let location = useLocation();
  let activeKey = "";

  switch (location.pathname) {
    case "/graphview":
      activeKey = "/supplychain";
      break;
    case "/dashboard":
      activeKey = "/dashboard";
      break;
    case "/companyPage":
      activeKey = "/dashboard";
      break;
    case "/portfolio":
      activeKey = "/portfolio";
      break;
    case "/portfolioDetails":
      activeKey = "/portfolio";
      break;
    case "/createPortfolio":
      activeKey = "/portfolio";
      break;
    case "/incidentslist":
      activeKey = "/home";
      break;
    case "/fullMap":
      activeKey = "/home";
      break;
    case "/graphview":
      activeKey = "/home";
      break;
    case "/incidentdetails":
      activeKey = "/dashboard";
      break;
    case "/sourceLists":
      activeKey = "/dashboard";
      break;

    case "/supplychain":
      activeKey = "/supplychain";
      break;
    case "/searchresult":
      activeKey = "/supplychain";
      break;

      case "/UploadSupplyChain":
        activeKey ="/supplychain";
        break;

    case "/watchlist":
      activeKey = "/watchlist";
      break;

    case "/UploadWatchlist":
      activeKey = "/watchlist";
      break;

    case "/incidentcollection":
      activeKey = "/incidentcollection";
      break;
    case "/notification":
      activeKey = "/notification";
      break;
    case "/profile":
      activeKey = "/profile";
      break;
    case "/password":
      activeKey = "/profile";
      break;
    case "/emailnotification":
      activeKey = "/profile";
      break;
    case "/customizescoring":
      activeKey = "/profile";
      break;
    case "/home":
      activeKey = "/home";
      break;
    case "/plans":
      activeKey = "/profile";
      break;
    case "/overviewpage":
      activeKey = "/dashboard";
      break;
    case "/socialMediaCoverage":
      activeKey = "/dashboard";
      break;
    case "/searchdashboardpage":
      activeKey = "/home";
      break;
    case "/corporateProfile":
      activeKey = "/corporateProfile";
      break;
  }

  return (
    <div className="side-background" style={{ padding: 0 }}>
      <div className="inner-sidebar-sec">
        <div className="side-logo">
          <Link to="/dashboard">
            <img src={Images.sidelogo} />
          </Link>
        </div>

        <div className="nav-sec">
          <div className="side-navbar">
            <ul>
              <li className={activeKey == "/dashboard" ? "active-nav" : ""}>
                <Link to="/dashboard">
                  <img src={Images.iconone} />
                  Dashboard
                </Link>
              </li>
              <li className={activeKey == "/corporateProfile" ? "active-nav" : ""}>
                <Link to="/corporateProfile">
                  <img src={Images.corporateLogo} />
                  Corporate Profile
                </Link>
              </li>

              <li className={activeKey == "/portfolio" ? "active-nav" : ""}>
                <Link to="/portfolio">
                  <img src={Images.icontwo} />
                  Portfolio
                </Link>
              </li>
              <li className={activeKey == "/watchlist" ? "active-nav" : ""}>
                <Link to="/watchlist">
                  <img src={Images.iconthree} />
                  Watchlist
                </Link>
              </li>

              <li className={activeKey == "/supplychain" ? "active-nav" : ""}>
                <Link to="/supplychain">
                  <img src={Images.supplySide} />
                  Supply Chain
                </Link>
              </li>

              <li className={activeKey == "/home" ? "active-nav" : ""}>
                <Link to="/home">
                  <img src={Images.incidentAnalyzer} />
                  Incident Analyzer
                </Link>
              </li>

              <li
                className={
                  activeKey == "/incidentcollection" ? "active-nav" : ""
                }
              >
                <Link to="incidentcollection">
                  <img src={Images.iconfour} />
                  Saved Incidents
                </Link>
              </li>
            </ul>
          </div>

          <div className="side-dott-nav">
            <ul>
              <li className={activeKey == "/profile" ? "active-nav" : ""}>
                <Link to="/profile">
                  <img src={Images.gear} />
                </Link>
              </li>

              <li className={activeKey == "/notification" ? "active-nav" : ""}>
                <Link to="/notification">
                  <img src={Images.bell} />
                </Link>
              </li>

              <li className={activeKey == "/userprofile" ? "active-nav" : ""}>
                <Link to="/userprofile">
                  <img src={Images.userProfile} />
                </Link>
              </li>

              <Popover
                placement="bottomRight"
                overlayClassName="setting"
                content={
                  <div className="user-sett">
                    <div className="user-pro-se">
                      <img src={Images.profile} />
                      <p>email.address</p>
                    </div>
                    <div className="acc-set" onClick={() => setOpen(!open)}>
                      <Link to="/profile">
                        <p>Account Settings</p>
                      </Link>
                    </div>
                    <div className="log-out" onClick={logOut}>
                      <Link to="/">
                        <p>Log out</p>
                      </Link>
                    </div>
                  </div>
                }
                title=""
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
              >
                <li>
                  <img className="user-profile-op" src={Images.frame} />
                </li>
              </Popover>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
