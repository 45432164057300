import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  // Icon,
  Stack,
  Typography,
  // useTheme,
} from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useState } from 'react';
// import ShowMoreText from 'react-show-more-text';
// import CustomTooltip from '../GraphToolTip/CustomTooltip';
// // import SupplyChainActIcon from './SupplyChainActViolationsIcon';
// import ShowMoreIcon from './ShowMoreIcon';
import CompanyRiskItem from "./CompanyRiskItem";

function Top5MostRiskiestConnections({ data }) {
  return (
    <Box>
      <Stack direction="row" gap={1}>
        {/* <SupplyChainActIcon /> */}
        <Typography variant="subtitle1" sx={{ marginBottom: "16px" }}>
          Top 5 riskiest connections
        </Typography>
      </Stack>
      <Stack gap={1}>
        {data?.map((item, index) => (
          <Stack gap="16px">
            <CompanyRiskItem
              id={item?.id}
              key={item?.id}
              name={item.name}
              index={index + 1}
              risk={item?.companyRisk}
            />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
export default Top5MostRiskiestConnections;
