import React from "react";
import { Box, IconButton, SvgIcon, useTheme } from "@mui/material";
import { useGraphObject } from "../../context/MyContext";
import CustomTooltip from "../../GraphToolTip/CustomTooltip";

function MinusButton() {
  const { graphObject } = useGraphObject();
  const theme = useTheme();
  return (
    <Box>
      <CustomTooltip title="Zoom Out" placement="left">
        <IconButton
          onClick={() => {
            if (graphObject) {
              const currentZoomRatio = graphObject?.getZoom();
              graphObject?.zoomTo(0.5 * currentZoomRatio, undefined, {
                duration: 1000,
                easing: "ease-in-out",
              });
            }
          }}
        >
          {/* Minus Icon */}
          <SvgIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 12L18 12"
                stroke={theme.palette.gray.main}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </SvgIcon>
        </IconButton>
      </CustomTooltip>
    </Box>
  );
}
export default MinusButton;
