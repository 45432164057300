import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { Checkbox, Radio, Select, Space } from "antd";
import { Breadcrumb } from "antd";
import { Pagination } from "antd";
import { Tooltip } from "antd";
import { Modal } from "antd";
import CompaniesListModalTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import {
  updateIncidentState,
} from "../../Redux/Action/incidents";
import { preventInvalidNumberInput } from "../../Utils/globalFunction";
import { getPortCompaniesListLoad } from "../../Redux/Action/portfolio";
import { useNavigate } from "react-router-dom";

const text = <span>Download</span>;

const PortfolioCompModal = ({ networkData, setModalVisible }) => {

  const navigate = useNavigate()

  const {

  } = useSelector((state) => state?.portfolio);
  const incidents = useSelector(
    (state) => state?.incidentReducer?.incidentsForModal
  );
  const { isLoading } = useSelector((state) => state?.incidentReducer);
  const {
    portfolioCompaniesList,
    portfolioCompaniesPagination,
    portfolioStatusLoader
  } = useSelector(state => state.portfolio)

  const [payloadState, setPayloadState] = useState({
    limit: 25,
    offset: 0,
    ...networkData,
  });
  const [perPageValue, setPerPageValue] = useState("");
  const [tableHeaders, setTableHeaders] = useState([
    "name",
    "risk",
    "Risk Change",
    "Incidents",
    "Relationship Type",
    "Industry",
    "locations",
  ]);

  const dispatch = useDispatch();
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <span>Previous</span>;
    }
    if (type === "next") {
      return <span>Next</span>;
    }
    return originalElement;
  };

  useEffect(() => {

    dispatch(getPortCompaniesListLoad({ ...payloadState, id: payloadState.companies }));
  }, []);
  useEffect(() => {
    dispatch(getPortCompaniesListLoad({ ...payloadState, id: payloadState.companies }));
  }, [payloadState]);


  const onPaginationChange = (page) => {
    if (page) {
      setPayloadState((prev) => {
        return { ...prev, offset: (page - 1) * prev.limit };
      });
    }
  };

  const handleLimitChange = (limit) => {
    setPayloadState((prev) => {
      return { ...prev, limit: limit };
    });
  };

  const handeGoToPageInput = (e) => {
    let newVal = e.target.value.replace("e", "");
    if (
      +newVal &&
      +newVal > Math.ceil(portfolioCompaniesPagination?.totalCount / payloadState.limit)
    ) {
      newVal = Math.ceil(portfolioCompaniesPagination?.totalCount / payloadState.limit);
    }
    if ((+newVal || newVal === "0") && 1 > +newVal) {
      newVal = 1;
    }
    setPerPageValue(newVal);
  };

  const callDownloadApi = () => {
    dispatch(getPortCompaniesListLoad({ ...payloadState, id: payloadState.companies,export:true }));
  };



  const handleColumnCheckboxChange = (e) => {
    if (e.target.checked && tableHeaders.includes(e.target.value)) return;
    if (e.target.checked && !tableHeaders.includes(e.target.value)) {
      setTableHeaders((prev) => [...prev, e.target.value]);
    }
    if (!e.target.checked && tableHeaders.includes(e.target.value)) {
      setTableHeaders((prev) => prev.filter((f) => f !== e.target.value));
    }
  };

  const handleColumnRadioChange = (e) => {
    const colArr = ["esg_factors", "esg_sdgs", "esg_pais"];

    setTableHeaders((prev) => {
      const newArr = prev.filter((e) => !colArr.includes(e));
      return [...newArr, e.target.value];
    });
  };

  const columnsOptions = [
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"Incidents"}
        >
          Incidents
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"Relationship Type"}
        >
          Relationship Type
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"locations"}
        >
          Locations
        </Checkbox>
      ),
      value: "title",
    },
  ];

  return (
    <Modal
      className="incident-page-table-modal-ps "
      centered
      open={true}
      closable={false}
      onCancel={() => setModalVisible(false)}
      footer={[]}
    >
      <div className="user-content-modal-ps">
        <div className="side-padding inc-outer-padding">
          <div className="bg-sec main-layout table-layout ">
            <section className=" incidents-table-modal-ps">
              <div className="breadcrmbs mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item
                    key="1"
                    className="bad-link"
                    onClick={() => {
                      updateIncidentState([], "");
                      setModalVisible(false);
                    }}
                  >
                    {" "}
                    <img src={Images.left} />
                    Back
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={()=>{
                     setModalVisible(false);
                  }} 
                  key="2" className="inc-link">
                    {" "}
                    Portfolio
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="3" className="lie-link">
                    {" "}
                    Companies
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
      
              <div className="table-page-sec " style={{ display: isLoading && "none" }}>
                <div className="topp-div">
                  <div className="table-head-sec">
                    <div className="serch-bar-in">
                      <span>
                        {" "}
                        <img className="me-2" alt="" src={Images.sear} />
                      </span>
                      <input
                        type="text"
                        placeholder="Search company"
                        value={payloadState.search}
                        onChange={(e) => {
                          setPayloadState((prev) => ({
                            ...payloadState,
                            search: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="right-side-colm">
                    <Tooltip placement="top" title={text}>
                      <div onClick={()=>callDownloadApi()} className="table-downl-btn">
                        <img src={Images.framedow} />
                      </div>
                    </Tooltip>
                  </div>
                </div>

                <div className="table-box incident">
                  <CompaniesListModalTable
                    incidents={portfolioCompaniesList}
                    payloadState={payloadState}
                    setPayloadState={setPayloadState}
                    tableHeaderArray={tableHeaders}
                    loading={portfolioStatusLoader}
                  />
                </div>

                <div className="page-nation">
                  <div className="row-per">
                    <span>Rows per page:</span>
                    <Select
                      value={payloadState.limit}
                      style={{ width: 60 }}
                      onChange={handleLimitChange}
                      options={[
                        { value: 25, label: "25" },
                        { value: 30, label: "30" },
                        { value: 35, label: "35" },
                      ]}
                    />
                  </div>

                  <div className="page-num">
                    <span>
                      <img src={Images.chevron} />
                    </span>{" "}
                    <Pagination
                      onChange={onPaginationChange}
                      pageSize={payloadState.limit}
                      total={portfolioCompaniesPagination?.totalCount}
                      itemRender={itemRender}
                      current={
                        Math.ceil(payloadState.offset / payloadState.limit) +
                        1
                      }
                    />{" "}
                    <span>
                      <img src={Images.chevronRighta} />
                    </span>
                  </div>

                  <div className="goto-pg pg-number">
                    <span>Go to page:</span>
                    <input
                      type="number"
                      name="search2"
                      value={perPageValue}
                      onKeyDown={preventInvalidNumberInput}
                      onChange={handeGoToPageInput}
                    />
                    <button
                      type="button"
                      onClick={() => onPaginationChange(perPageValue)}
                    >
                      Go
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PortfolioCompModal;
