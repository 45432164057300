import { Modal, Popover, Row } from "antd";
import React, { memo, useEffect, useState } from "react";
import ImageConst from "../../../Themes/ImageConstant";
import style from "../style.module.css";
import { cardColor, portfolioCardColor } from "../../../Constants/appConstansts";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { deletePortfolioLoad, getEditPortfolioLoad, portfolioChangeClrLoad, updatePortfolioState } from "../../../Redux/Action/portfolio";
import { useNavigate } from "react-router-dom";

import ModalHeader from "../../../Componets/modalHeaderComp";
import ModalCloseButton from "../../../Componets/modalCloseButton";


const PopoverContent = ({ active, item, setDeleteModalVisible }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  


  const ColorCircle = ({ color, id, setColor }) => {

    const dispatch = useDispatch()

    const handleChangeColor = () => {
      
      let realObj = portfolioCardColor.find(itm => itm.id == id)
      let data = realObj
      data.portfolioId = item._id
      dispatch(portfolioChangeClrLoad(data))
    }

    return (
      <div
      className={style.circle}
      style={{
        background: color,
        border: (active === color) && '3px solid #0195ce',
        cursor:'pointer'
      }}
      onClick={() => handleChangeColor()}
    />
    )
  }

  return (
    <>
      <Row className={style.editDeleteText} style={{color: "#4E515C"}}>Cover Colors</Row>
      <Row justify={"space-around"} style={{ margin: "10px 0 10px 0" }}>
        <ColorCircle color={cardColor.FIRST} id={1} setColor={() => {}} />
        <ColorCircle color={cardColor.SECOND} id={2} setColor={() => {}} />
        <ColorCircle color={cardColor.THIRD} id={3} setColor={() => {}} />
      </Row>
      <Row justify={"space-around"} style={{ marginBottom: '20px'}}>
        <ColorCircle color={cardColor.FOURTH} id={4} setColor={() => {}} />
        <ColorCircle color={cardColor.FIFTH} id={5} setColor={() => {}} />
        <ColorCircle color={cardColor.SIX} id={6} setColor={() => {}} />
      </Row>
      <Row className={clsx(style.editDeleteText, style.editText)}>
      <div onClick={() => navigate('/createPortfolio', {state: { portfolioId: item?._id, editStep: 1, screen: '/portfolio' }})} style={{ display: 'flex', cursor: 'pointer' }}>
        <img src={ImageConst.edit} alt="edit" className={style.editIcon} />
        <p>Edit Portfolio</p>
        </div>
      </Row>
      <Row className={clsx(style.editDeleteText, style.editText)}>
      <div onClick={() => navigate('/createPortfolio', {state: { portfolioId: item?._id, editStep: 2, screen: '/portfolio' }})} style={{ display: 'flex', cursor: 'pointer' }}>
        <img src={ImageConst.edit} alt="edit" className={style.editIcon} />
        <p>Edit Alert Rules</p>
        </div>
      </Row>
      <Row className={clsx(style.editDeleteText,style.deleteText)}>
        <div onClick={() => setDeleteModalVisible(true)} style={{ display: 'flex', cursor: 'pointer' }}>
        <img src={ImageConst.delete} alt="edit" className={style.editIcon} />
        <p>Delete Portfolio</p>
        </div>
      </Row>

      

    </>
  );
};

const CoverColorPopOver = ({ active, item }) => {

  const dispatch = useDispatch()

  const [open, setOpen] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const portfolioReducerData = useSelector(state => state.portfolio)
  const {
    deletePortfolioSuccess
  } = portfolioReducerData

  useEffect(() => {
    if (deletePortfolioSuccess) {
      hide()
      dispatch(updatePortfolioState(false, 'deletePortfolioSuccess'))
      setDeleteModalVisible(false)
    }
  }, [deletePortfolioSuccess])

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
    <Popover
      placement="bottomRight"
      content={<PopoverContent active={active} item={item} setDeleteModalVisible={setDeleteModalVisible} />}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      className={style.popover}
      overlayClassName={"color-pop"}
    >
      {item?.cardColor?.color == "#fff" ?  <img src={ImageConst.whitDots} alt="menu" className={style.menuIconWhite} /> : <img src={ImageConst.dots} alt="menu" className={style.menuIcon} />}
    </Popover>
    <Modal
        title={<ModalHeader title={"Delete Portfolio"} />}
        centered
        closeIcon={<ModalCloseButton />}
        className="custom-second delete-watchlist-ps"
        open={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <div className="modalButtonContainer"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          >
            <button
              className="can-btn can-del"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "36px",
              }}
              onClick={() => setDeleteModalVisible(false)}
            >
              Cancel
            </button>
            <button
              className="rest-btn del-red"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "36px",
              }}
              onClick={() => {
                dispatch(deletePortfolioLoad({ portfolioId: item?._id }))
              }}
            >
              Delete
            </button>
          </div>,
        ]}
      >
        <div className="reset-setting del-test">
          <div className="text-center">
            <div className="del-image">
              <img src={ImageConst.deletError} />
            </div>
            <div className="delete-second-text">
              <p>{`Are you sure you want to delete "${item?.name}"?`}</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(CoverColorPopOver);
