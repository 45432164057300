import { combineReducers } from "redux";
import { authenticationReducer } from "./authenticationReducer";
import { incidentReducer } from "./incidentReducer";
import { accountSettingReducer } from "./accountSettingReducer";
import { companyPageReducer } from "./companyPageReducer";
import { watchListReducer } from "./watchlistReducer";
import { saveIncidenceReducer } from "./saveIncidenceReducer";
import { supplyChainReducer } from "./supplyChainReducer";
import { global } from "./global";
import { portfolio } from "./portfolio";
import { socialMedia } from "./socialMedia";
import { advanceSearch } from "./advanceSearch";
import { incidentAnalyzerReducer } from "./incidentAnalyzerReducer";
import { corporateProfile } from "./corporateProf";
import {dashboardReducer}from "./dashboardReducer"
const rootReducer = combineReducers({
  authenticationReducer,
  incidentReducer,
  accountSettingReducer,
  companyPageReducer,
  watchListReducer,
  saveIncidenceReducer,
  supplyChainReducer,
  global,
  portfolio,
  socialMedia,
  advanceSearch,
  incidentAnalyzerReducer,
  corporateProfile,
  dashboardReducer
});

export default rootReducer;
