import { Box, Button, Drawer, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
import { setShowFilterSideBar } from "../../redux/GraphExploration/FilterSideBarSlice";
import { TabEnum } from "../../enums/FilterSideBarEnums";
import {
  MIN_NODE_COUNT_TO_FIX_G6_RENDERING_BUG,
  fitViewGraph,
  fixG6RenderingBug,
  getConnectingEdgesToShow,
  resetFilterParamatersValue,
} from "../../utils";
import {
  setHiddenEdges,
  setHiddenNodes,
} from "../../redux/GraphExploration/graphSlice";
import FilterSideBarTabs from "./Tabs";
import Title from "./Title";
import NodeTab from "./NodeTab";
import EdgeTab from "./EdgeTab";
import { useGraphObject } from "../context/MyContext";
import {
  setCanRedo,
  setCanUndo,
} from "../../redux/GraphExploration/toolBoxSlice";

function FilterSideBarButtons({ handleCancelFilter }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { graphObject } = useGraphObject();
  const hiddenNodes = useAppSelector((state) => state.graph.hiddenNodes);
  const hiddenEdges = useAppSelector((state) => state.graph.hiddenEdges);
  // const edges = useAppSelector((state: RootState) => state.graph.nodes);
  const handleApplyFilter = () => {
    dispatch(setShowFilterSideBar(false));
    if (hiddenEdges.length > 0 || hiddenNodes.length > 0) {
      graphObject?.startHistoryBatch();
      graphObject.removeData("edge", hiddenEdges);
      graphObject.removeData("node", hiddenNodes);
      graphObject?.stopHistoryBatch();
      dispatch(setHiddenNodes([]));
      dispatch(setHiddenEdges([]));
      graphObject.layout();
      resetFilterParamatersValue();
      graphObject.cleanHistory("undo");
      graphObject.cleanHistory("redo");
      dispatch(setCanUndo(false));
      dispatch(setCanRedo(false));
      setTimeout(() => {
        fitViewGraph(graphObject);
      }, 2000);
    }

    // fixG6RenderingBug(graphObject, nodes);
  };

  return (
    <Box
      display="flex"
      height="64px"
      paddingX="16px"
      paddingY="14px"
      justifyContent="space-between"
      gap="16px"
      borderTop={`1px solid ${theme.palette.gray[50]}`}
    >
      <Box width="100%">
        <Button
          fullWidth
          sx={{
            color: theme.palette.primary["500"],
            backgroundColor: theme.palette.primary.A100,
            border: `1px solid ${theme.palette.primary.A200}`,
            borderRadius: "4px",
          }}
          onClick={() => handleCancelFilter()}
        >
          Cancel Filter
        </Button>
      </Box>
      <Box width="100%">
        <Button
          fullWidth
          variant="contained"
          sx={{
            color: theme.palette.white.main,
            backgroundColor: theme.palette.primary["500"],
            border: `1px solid ${theme.palette.primary["500"]}`,
            borderRadius: "4px",
            boxShadow: "none",
          }}
          onClick={handleApplyFilter}
        >
          Apply Filter
        </Button>
      </Box>
    </Box>
  );
}
function FilterSideBar() {
  const dispatch = useAppDispatch();
  const showFilterSideBar = useAppSelector(
    (state) => state.filterSideBar.showFilterSideBar
  );
  const currentTab = useAppSelector((state) => state.filterSideBar.currentTab);
  const { graphObject } = useGraphObject();
  const nodes = useAppSelector((state) => state.graph.nodes);
  const hiddenNodes = useAppSelector((state) => state.graph.hiddenNodes);
  const hiddenEdges = useAppSelector((state) => state.graph.hiddenEdges);
  const handleCancelFilter = (closeFilterSideBar = true) => {
    if (closeFilterSideBar) {
      dispatch(setShowFilterSideBar(false));
    }
    if (hiddenNodes.length > 0 || hiddenEdges.length > 0) {
      // show all nodes and edges
      dispatch(setHiddenNodes([]));
      dispatch(setHiddenEdges([]));
      graphObject?.showItem(nodes.map((n) => n.id));
      const edges = getConnectingEdgesToShow(nodes.map((n) => n.id));
      graphObject?.showItem(edges);
      resetFilterParamatersValue();
      fixG6RenderingBug(graphObject, 10, 1000);
    } else if (nodes.length > MIN_NODE_COUNT_TO_FIX_G6_RENDERING_BUG) {
      fixG6RenderingBug(graphObject, 10, 1000);
    }
  };
  return (
    <Drawer
      anchor="right"
      open={showFilterSideBar}
      onClose={() => handleCancelFilter()}
      hideBackdrop
      elevation={1}
      variant="persistent"
      PaperProps={{
        sx: {
          boxShadow: "0px 8px 12px 1px rgba(0, 0, 0, 0.30)",
        },
      }}
    >
      <Box sx={{ width: 464, height: "calc(100vh - 64px)" }}>
        <Title handleCancelFilter={handleCancelFilter} />
        <FilterSideBarTabs />
        {currentTab === TabEnum.NODE && (
          <NodeTab handleCancelFilter={handleCancelFilter} />
        )}
        {currentTab === TabEnum.EDGE && <EdgeTab />}
      </Box>
      <FilterSideBarButtons handleCancelFilter={handleCancelFilter} />
    </Drawer>
  );
}
export default FilterSideBar;
