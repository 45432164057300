import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  // Icon,
  Stack,
  Typography,
  // useTheme,
} from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useState } from 'react';
// import ShowMoreText from 'react-show-more-text';
// import CustomTooltip from '../GraphToolTip/CustomTooltip';
// // import SupplyChainActIcon from './SupplyChainActViolationsIcon';
// import ShowMoreIcon from './ShowMoreIcon';
import CompanyConnectionItem from "./companyConnectionItem";

function Top5CompaniesWithMostConnections({ data }) {
  return (
    <Box>
      <Stack direction="row" gap={1}>
        <Typography variant="subtitle1" sx={{ marginBottom: "16px" }}>
          Top 5 with most Connections
        </Typography>
      </Stack>
      <Stack gap={1}>
        {data?.map((item, index) => (
          <Stack gap="16px">
            <CompanyConnectionItem
              key={item?.id}
              id={item?.id}
              name={item.name}
              index={index + 1}
              stat={item?.stat}
              total={item?.total}
            />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
export default Top5CompaniesWithMostConnections;
