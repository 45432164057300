import { Box, Collapse, IconButton, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxLabel from "../../../FilterSideBar/NodeTab/Accordions/CheckBoxLabel";
import EntityList from "./EntityList";
import SearchBar from "../../../components/SearchBar";
import { useAppSelector } from "../../../../redux/configuareStore";
import {
  decodeUnicodeEscapes,
  getBadge,
  getEdgeProperLabel,
  getFilterTagIds,
  prepareIncidentCounts,
  toTitleCase,
} from "../../../../utils";
import { getToken } from "../../../../../../../Utils";
import useAxios from "axios-hooks";
import { useGraphObject } from "../../../context/MyContext";

export default function ExpandingItem({ id, label, count }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [allItemIsChecked, setAllItemIsChecked] = useState(false);

  const theme = useTheme();
  const centerNode = useAppSelector((state) => state.graph.centerNode);
  const from = useAppSelector((state) => state.timeRange.from);
  const to = useAppSelector((state) => state.timeRange.to);
  const params = {
    q: toTitleCase(searchQuery),
    topN: searchQuery.length === 0 ? 1000 : 5,
    centerNodeId: centerNode ? centerNode.id : undefined,
    from,
    to,
  };
  const targetNodeId = useAppSelector(
    (state) => state.rightClickMenu.targetNodeId
  );
  const baseUrlApi = useAppSelector((state) => state.api.baseUrlApi);
  const token = getToken();
  const [{ data, loading }] = useAxios({
    url: `${baseUrlApi}/companies/${targetNodeId}/relations/${id}/companies`,
    params,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      // Add any other headers if needed
    },
  });
  const { graphObject } = useGraphObject();
  const allNodeData = useAppSelector((state) => state.graph.nodes);
  const selectedRelationDirection = id.startsWith("REVERSED")
    ? "IN_COMING"
    : "OUT_GOING";
  const selectedRelation = id;
  const handleOnAllItemClick = async () => {
    if (!allItemIsChecked) {
      const currentNodesIds = allNodeData.map((n) => n.id);
      if (data) {
        const newNodes = data
          .map((n) => {
            const nodeAttributes = {
              incidentCount: prepareIncidentCounts(n.incidentCount),
              tier: n.tier ? Math.abs(n.tier) : undefined,
              sort: n.tier ? 4 - Math.abs(n.tier) : 0,
              layer: n.tier ? Math.abs(n.tier) : 4,
              riskScore: n.riskScore,
              industry: n.industry,
              location: n.location,
              label: decodeUnicodeEscapes(n.name),
              shortRelationName: n.shortRelationName,
            };
            return {
              id: n.id,
              data: {
                isCenterNode: false,
                ...nodeAttributes,
                badge: getBadge(nodeAttributes),
                filterTagIds: getFilterTagIds(nodeAttributes),
                logo: n.logo,
              },
            };
          })
          .filter((n) => !currentNodesIds?.includes(n.id));
        graphObject?.startHistoryBatch();
        graphObject?.addData("node", newNodes);
        const newEdges = newNodes.map((n) => {
          const source =
            selectedRelationDirection === "OUT_GOING" ? targetNodeId : n.id;
          const target =
            selectedRelationDirection === "OUT_GOING" ? n.id : targetNodeId;
          const newEdgeId = `${source}-${target}`;
          return {
            id: newEdgeId,
            source,
            target,
            data: {
              label: getEdgeProperLabel(selectedRelation || ""),
              shortLabel: n.data.shortRelationName,
            },
          };
        });
        graphObject?.addData("edge", newEdges);
        graphObject?.stopHistoryBatch();
      }
    } else if (data) {
      const oldNodeIds = data?.map((r) => r.id);
      const oldEdgesIds = oldNodeIds.map((id) => {
        const source =
          selectedRelationDirection === "OUT_GOING" ? targetNodeId : id;
        const target =
          selectedRelationDirection === "OUT_GOING" ? id : targetNodeId;
        const oldEdgeId = `${source}-${target}`;
        return oldEdgeId;
      });
      graphObject?.startHistoryBatch();
      graphObject?.removeData("edge", oldEdgesIds);
      graphObject?.removeData("node", oldNodeIds);
      graphObject?.stopHistoryBatch();
    }
    // graphObject?.layout();
    setAllItemIsChecked(!allItemIsChecked);
  };

  return (
    <Box
      marginBottom={1}
      border={`1px solid ${theme.palette.gray["100"]}`}
      borderRadius="8px"
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        justifyContent={"space-between"}
        borderRadius="8px"
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: isExpanded ? "" : theme.palette.gray["50"],
          },
          transition: "background-color 0.3s ease",
          padding: theme.spacing(1.5),
        }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <FormControlLabel
          key={label}
          control={
            <Checkbox
              checked={allItemIsChecked}
              onChange={handleOnAllItemClick}
              size="small"
            />
          }
          label={<CheckBoxLabel label={label} count={count} />}
        />
        <IconButton
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <ChevronRightIcon
            sx={{
              transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          />
        </IconButton>
      </Stack>
      <Collapse in={isExpanded}>
        <Box
          sx={{
            paddingX: theme.spacing(1.5),
          }}
        >
          <SearchBar
            value={searchQuery}
            onChange={(value) => {
              setSearchQuery(value);
            }}
          />
          <EntityList
            searchQuery={searchQuery}
            selectedRelation={selectedRelation}
            selectedRelationDirection={selectedRelationDirection}
            allItemIsChecked={allItemIsChecked}
            handleOnAllItemClick={handleOnAllItemClick}
            data={data}
            loading={loading}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
