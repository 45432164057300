import { call, put, takeLatest } from "redux-saga/effects";
import {toast as hotToast } from 'react-hot-toast';
import appconstant from "../../Constants/appConstansts";
import { onError, onFail, onSucces, onUnAuth } from "../../Utils/UnAuthFailFn";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  getSupplyChainDataApi,
  getRiskChartApi,
  getRiskCompanyApi,
  corporateIncidentBar,
  corporateRiskScoreBar,
  corporateIncidentAnalysisApi,
  esgFactorsIncidentsApi,
  esgsdgincidentsApi,
  esgPaiIncidentsApi,
  getChainListingAPi
} from "../api";
import {
  getCorpSupplyChainDetailSuccess,
  getCorpSupplyChainDetailFail,
  getCorpRiskChartDataSuccess,
  getCorpRiskChartDataFail,
  getCorpRiskPieChartSuccess,
  getCorpRiskPieChartFail,
  getCorpIncidentBarSuccess,
  getCorpIncidentBarFail,
  getCorpRiskScoreBarSuccess,
  getCorpRiskScoreBarFail,
  getCorpIncidentAnalysisSuccess,
  getCorpIncidentAnalysisFail,
  getCorpEsgFactorsSuccess,
  getCorpEsgFactorsFail,
  getCorpSdgSuccess,
  getCorpSdgFail,
  getCorpPaiIndicatorsSuccess,
  getCorpPaiIndicatorsFail,
  getCorpCompaniesListSuccess,
  getCorpCompaniesListFail
} from "../Action/corporateProf";
import { toast } from "react-toastify";
import { errorToastContainer, successToastContainer } from "../../Utils";

const { RoutesPath } = appconstant;

function* getCorpSupplyChainDetailSaga(action) {
   
  try {
      
    const data = yield call(getSupplyChainDataApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpSupplyChainDetailSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpSupplyChainDetailFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpRiskLineChartSaga(action) {
   
  try {
      
    const data = yield call(getRiskChartApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpRiskChartDataSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpRiskChartDataFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpRiskPieChartSaga(action) {
  
  try {
      
    const data = yield call(getRiskCompanyApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpRiskPieChartSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpRiskPieChartFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpIncidentBarSaga(action) {
  
  try {
      
    const data = yield call(corporateIncidentBar, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpIncidentBarSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpIncidentBarFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpRiskScoreBarSaga(action) {
  
  try {
      
    const data = yield call(corporateRiskScoreBar, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpRiskScoreBarSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpRiskScoreBarFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpIncidentAnalysisSaga(action) {
  
  try {
      
    const data = yield call(corporateIncidentAnalysisApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpIncidentAnalysisSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpIncidentAnalysisFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpEsgFactorsDataSaga(action) {
   
  try {
      
    const data = yield call(esgFactorsIncidentsApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpEsgFactorsSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpEsgFactorsFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpSdgDataSaga(action) {
   
  try {
      
    const data = yield call(esgsdgincidentsApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpSdgSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpSdgFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpPaiIndicatorsDataSaga(action) {
   
  try {
    const data = yield call(esgPaiIncidentsApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getCorpPaiIndicatorsSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpPaiIndicatorsFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCorpCompaniesListSaga(action) {
   
  try {
    const data = yield call(getChainListingAPi, action);
    // for success
    if (data.status === 0) {
      yield put(getCorpCompaniesListSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getCorpCompaniesListFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

export default function* rootCorporateProfilePageSaga() {
  yield takeLatest(type.GET_CORP_SUPPLY_CHAIN_DETAIL_LOAD, getCorpSupplyChainDetailSaga);
  yield takeLatest(type.GET_CORP_RISK_CHART_DETAIL_LOAD, getCorpRiskLineChartSaga);
  yield takeLatest(type.GET_CORP_RISK_PIE_CHART_LOAD, getCorpRiskPieChartSaga);
  yield takeLatest(type.GET_CORP_INICDENTS_BAR_LOAD, getCorpIncidentBarSaga);
  yield takeLatest(type.GET_CORP_RISK_SCORE_BAR_LOAD, getCorpRiskScoreBarSaga);
  yield takeLatest(type.GET_CORP_INICDENTS_ANALYSIS_LOAD, getCorpIncidentAnalysisSaga);
  yield takeLatest(type.GET_CORP_ESG_FACTORES_LOAD, getCorpEsgFactorsDataSaga);
  yield takeLatest(type.GET_CORP_SDG_LOAD, getCorpSdgDataSaga);
  yield takeLatest(type.GET_CORP_PAI_INDICATORS_LOAD, getCorpPaiIndicatorsDataSaga);
  yield takeLatest(type.GET_CORP_COMPANIES_LIST_LOAD, getCorpCompaniesListSaga);
  
}
