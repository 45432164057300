/* eslint-disable no-param-reassign */
import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/configuareStore";
import { updateFilterParameterField } from "../../../../redux/GraphExploration/FilterSideBarSlice";
import { useGraphObject } from "../../../context/MyContext";
import { updateHiddenNodes } from "../../../../utils";
import { FilterParametersEnum } from "../../../../enums/FilterSideBarEnums";
import { CustomAccordionDetails } from "../../../../components/CustomeAccordion";
import CheckBoxLabel from "./CheckBoxLabel";

export default function CompanyLocationAccordion() {
  const filterParameters = useAppSelector(
    (state) => state.filterSideBar.filterParameters
  );
  const { graphObject } = useGraphObject();
  const dispatch = useAppDispatch();
  const nodes = useAppSelector((state) => state.graph.nodes);
  const handleChange =
    (filterParameter, isParent = false) =>
    (_event, checked) => {
      let nextFilterParametersState = [];
      if (isParent) {
        const contriesFilterParameters = filterParameters.filter(
          (filterParameterItem) =>
            filterParameterItem.parentId === filterParameter.id
        );
        contriesFilterParameters.forEach((countryFilterParameter) => {
          dispatch(
            updateFilterParameterField({
              itemId: countryFilterParameter.id,
              fieldToUpdate: "value",
              newValue: checked,
            })
          );
        });
        nextFilterParametersState = filterParameters.map((item) => {
          if (item.parentId === filterParameter.id) {
            return {
              ...item,
              value: checked,
            };
          }
          return item;
        });
      } else {
        dispatch(
          updateFilterParameterField({
            itemId: filterParameter.id,
            fieldToUpdate: "value",
            newValue: checked,
          })
        );
        nextFilterParametersState = filterParameters.map((item) => {
          if (item.id === filterParameter.id) {
            return {
              ...item,
              value: checked,
            };
          }
          return item;
        });
      }
      const allParentIds = filterParameters
        .filter(
          (filterParameterItem) =>
            filterParameterItem.parentId ===
            FilterParametersEnum.COMPANY_LOCATION
        )
        .map((filterParameterItem) => filterParameterItem.id);
      const activeFilterParameters = nextFilterParametersState.filter(
        (item) => allParentIds.includes(item.parentId) && item.value
      );
      if (activeFilterParameters.length > 0) {
        const activeFilterIds = activeFilterParameters.map((item) => item.id);
        const hiddenNodes = nodes.filter(
          (node) =>
            // nodes that doesn't have any of the active labels in their data.filterTagIds array will be hidden
            !node.data.filterTagIds.some((filterTagId) =>
              activeFilterIds.includes(filterTagId)
            )
        );
        dispatch(
          updateFilterParameterField({
            itemId: FilterParametersEnum.COMPANY_LOCATION,
            fieldToUpdate: "hiddenNodeIds",
            newValue: hiddenNodes.map((n) => n.id),
          })
        );
        updateHiddenNodes(graphObject);
      } else {
        dispatch(
          updateFilterParameterField({
            itemId: FilterParametersEnum.COMPANY_LOCATION,
            fieldToUpdate: "hiddenNodeIds",
            newValue: [],
          })
        );
        updateHiddenNodes(graphObject);
      }
    };
  return (
    <CustomAccordionDetails>
      <FormGroup sx={{ paddingLeft: 1 }}>
        {filterParameters
          .filter(
            (filterParameter) =>
              filterParameter.parentId ===
                FilterParametersEnum.COMPANY_LOCATION &&
              filterParameter.count > 0
            // && filterParameter.label !== ""
          )
          .map((continentFilterParameter) => {
            const countriesFilterParameters = filterParameters.filter(
              (filterParameterItem) =>
                filterParameterItem.parentId === continentFilterParameter.id &&
                filterParameterItem.count > 0
            );
            return (
              <Box key={continentFilterParameter.id}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <IconButton
                    onClick={() => {
                      dispatch(
                        updateFilterParameterField({
                          itemId: continentFilterParameter.id,
                          fieldToUpdate: "value",
                          newValue: !continentFilterParameter.value,
                        })
                      );
                    }}
                  >
                    <ChevronRightIcon
                      sx={{
                        transform: continentFilterParameter.value
                          ? "rotate(90deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </IconButton>
                  <FormControlLabel
                    key={continentFilterParameter.label}
                    control={
                      <Checkbox
                        size="small"
                        checked={countriesFilterParameters.every(
                          (item) => item.value
                        )}
                        indeterminate={
                          countriesFilterParameters.some(
                            (item) => item.value
                          ) &&
                          !countriesFilterParameters.every((item) => item.value)
                        }
                        onChange={handleChange(continentFilterParameter, true)}
                      />
                    }
                    label={
                      <CheckBoxLabel
                        label={continentFilterParameter.label}
                        count={continentFilterParameter.count}
                      />
                    }
                  />
                </Stack>
                <Collapse in={continentFilterParameter.value}>
                  <Box sx={{ display: "flex", flexDirection: "column", ml: 8 }}>
                    {countriesFilterParameters.map((countryFilterParameter) => (
                      <FormControlLabel
                        key={`${countryFilterParameter.id}-${
                          countryFilterParameter.value ? "Yes" : "No"
                        }`}
                        control={
                          <Checkbox
                            size="small"
                            checked={countryFilterParameter.value}
                            onChange={handleChange(
                              countryFilterParameter,
                              false
                            )}
                          />
                        }
                        label={
                          <CheckBoxLabel
                            label={countryFilterParameter.label}
                            count={countryFilterParameter.count}
                          />
                        }
                      />
                    ))}
                  </Box>
                </Collapse>
              </Box>
            );
          })}
      </FormGroup>
    </CustomAccordionDetails>
  );
}
