import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
 dashBoardCompanySearchList:[]
};

export const dashboardReducer = (state = initialState, action) => {
  let { payload, key } = action;
  switch (action.type) {
    case types.DASHBOARD_SEARCH_SUCCESS:
      return {
        ...state,
        dashBoardCompanySearchList: action.data,
      };
    default:
      return { ...state };
  }
};
