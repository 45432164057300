import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import { setShowNodeSelector } from "../../../redux/GraphExploration/toolBoxSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import { useGraphObject } from "../../context/MyContext";
import { NodeSelectorEnum } from "../../../enums/NodeSelectorEnum";
import NodeSelectorItem from "./NodeSelectorItem";
import {
  getConnectingNodesToHide,
  getNearEdgeData,
  getSelectedNodes,
} from "../../../utils";
import { toast } from "react-toastify";

const nodeSelectorTypes = [
  {
    value: NodeSelectorEnum.ALL_NODES,
  },
  {
    value: NodeSelectorEnum.LEAF_NODES,
  },
  {
    value: NodeSelectorEnum.ISOLATE_NODES,
  },
  {
    value: NodeSelectorEnum.LASSO_TOOL,
  },
  {
    value: NodeSelectorEnum.BOX_SELECTOR_TOOL,
  },
];

export default function NodeSelectorPopOver({ anchorEl, setAnchorEl }) {
  const nodes = useAppSelector((state) => state.graph.nodes);
  const edges = useAppSelector((state) => state.graph.edges);

  const dispatch = useAppDispatch();
  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setShowNodeSelector(false));
  };

  const { graphObject } = useGraphObject();
  const toastifyOptions = {
    position: "bottom-center",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      elevation={0}
      sx={{
        transform: "translateX(-13px) translateY(-4px)", // Adjust this value to set the offset
        "& .MuiPopover-paper": {
          padding: "12px",
          borderRadius: "6px",
          boxShadow: "0px 8px 12px 1px #0000001A, 0px -2px 8px 0px #0000001A",
        },
      }}
    >
      <Stack divider={<Divider />}>
        {nodeSelectorTypes.map((item) => {
          return (
            <NodeSelectorItem
              key={item.value}
              label={item.value}
              sx={{
                cursor: "pointer",
                width: "240px",
                color: (theme) => theme.palette.gray[800],
                padding: "8px",
                transition: "background-color 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.gray["A100"],
                },
              }}
              onClick={() => {
                // getSelectedNodes(currentNodeIds, graphObject).forEach(
                //   (node) => {
                //     graphObject?.setItemState(`${node}`, "selected", false);
                //   }
                // );
                if (item.value === NodeSelectorEnum.ALL_NODES) {
                  nodes.forEach((node) => {
                    graphObject?.setItemState(`${node.id}`, "selected", true);
                  });
                } else if (
                  item.value === NodeSelectorEnum.LEAF_NODES ||
                  item.value === NodeSelectorEnum.ISOLATE_NODES
                ) {
                  nodes.forEach((node) => {
                    const nearEdges = getNearEdgeData(node.id, edges);
                    // TODO: fix when there are edges two way between two nodes
                    // if (nearEdges.length === 2 && nearEdges[0].source === nearEdges[1].target && nearEdges[0].target === nearEdges[1].source) {
                    //
                    // }

                    if (
                      (item.value === NodeSelectorEnum.LEAF_NODES &&
                        nearEdges.length === 1) ||
                      (item.value === NodeSelectorEnum.ISOLATE_NODES &&
                        nearEdges.length === 0)
                    ) {
                      graphObject?.setItemState(`${node.id}`, "selected", true);
                    } else {
                      graphObject?.setItemState(
                        `${node.id}`,
                        "selected",
                        false
                      );
                    }
                  });
                } else if (item.value === NodeSelectorEnum.LASSO_TOOL) {
                  graphObject?.setMode("lassoSelect");
                  toast.info(
                    "Hold 'Shift' and drag to select multiple nodes using Lasso selector tool",
                    toastifyOptions
                  );
                } else if (item.value === NodeSelectorEnum.BOX_SELECTOR_TOOL) {
                  graphObject?.setMode("brushSelect");
                  toast.info(
                    "Hold 'Shift' and drag to select multiple nodes using box selector tool",
                    toastifyOptions
                  );
                }
                handleClose();
                // graphObject?.setItemState(`${item.id}`, "selected", true);
              }}
            />
          );
        })}
      </Stack>
    </Popover>
  );
}
