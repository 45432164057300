import React, { useState, useEffect } from "react";
import style from "../../Screens/supplyChain/index.css";
import Images from "../../Themes/ImageConstant";

import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { AutoComplete, Modal, Spin } from "antd";
import {
  clearSearchAction,
  getCompanyCount,
  getSearchHistory,
  uploadDataAction,
} from "../../Redux/Action/supplyChainAction";
import { useDispatch, useSelector } from "react-redux";
import LineChart from "../searchResult/components/lineChart";
import useDebouncedApiCall from "../../hook/useDebouncedSearch";
import { searchCompanyListActionLoad } from "../../Redux/Action/global";
import ImageConst from "../../Themes/ImageConstant";
import { isDocumentOrPDF } from "../../Utils";
import { debounce } from "lodash";
import GlobalSpinner from "../../Utils/GlobalSpinner";
import FileUploadPopUp from "../../Componets/fileUploadPopup";
let series = (supplyData) => [
  {
    data: supplyData.map((entry) => entry.companyRisk),
  },
];

const SupplyChain = (props) => {
  const [modal2Open, setModal2Open] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const { searchTerm, handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );
  const { searchCompaniesData } = useSelector((state) => state?.global);
  const navigate = useNavigate();

  const [countryName, setCountryName] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [uploadedCountryName, setUploadCountryName] = useState(null);
  const [uploadData, setUploadedData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const {
    historyData,
    comapnyCount,
    isSuccess,
    isError,
    supplyChainFileUpload,
    isSupplyUpload,
  } = useSelector((state) => state?.supplyChainReducer);
  useEffect(() => {
    dispatch(getSearchHistory());
    dispatch(getCompanyCount());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setModal2Open(false);
        setUploadCountryName(null);
        setUploadedData(null);
        setFileData(null);
        document.getElementById("fileupload").value = "";
        navigate("/UploadSupplyChain", {
          state: {
            id: uploadData.id,
            detail: uploadData,
            data: isSupplyUpload,
          },
        });
      }, 500);
    }
  }, [isSuccess]);

  const calculateRisk = (data, key) => {
    // Extracting the company risks from the last two entries
    const lastCompanyRisk1 =
      data.length > 1 ? data[data.length - 2]?.companyRisk : 0;
    const lastCompanyRisk2 =
      data.length > 0 ? data[data.length - 1]?.companyRisk : 0;

    // Calculating the percentage change in risk
    const riskPercentageChange =
      ((lastCompanyRisk2 - lastCompanyRisk1) /
        (lastCompanyRisk1 > 0 ? lastCompanyRisk1 : 1)) *
      100;
    return riskPercentageChange.toFixed(2);
  };

  const InputChange = (e, fileType) => {
    setFileData(e);
  };

  const handleSearchOperation = (SelectedData) => {
    if (searchCompaniesData?.data?.length !== 0) {
      navigate("/searchresult", {
        state: {
          record: {
            id: SelectedData.id,
            value: SelectedData.value,
            label: SelectedData.label,
          },
        },
      });
      setError(false);
    } else {
      setError(true);
    }
  };

  const uploadDataApi = () => {
    let formData = new FormData();
    formData.append("file", fileData);
    formData.append("id", uploadData.id);
    dispatch(uploadDataAction(formData));
  };

  return (
    <div className="side-padding">
      <div className="main-layout table-layout">
        <div className="supply-chain-container">
          <div
            className="supply-chain-sec"
            style={{ textAlign: "-webkit-center" }}
          >
            <div className="chain-logo">
              <img src={Images.supply} />
              <h1>Supply chain</h1>
            </div>
            <div className="chain-des mt-4">
              <p>
                Enter the company name and discover all suppliers, customers &
                partners
              </p>
              <p> with advanced filters and graph exploration </p>
            </div>

            <div class="search-container mt-4" style={{ width: "67.7%" }}>
              <div className="example supply-chan-search new-supply-chain-ps">
                <AutoComplete
                  className="autocomplete-inputNew"
                  onSearch={handleSearch}
                  value={countryName}
                  options={searchCompaniesData?.data}
                  placeholder="Enter company name"
                  onSelect={(e, selectedOption) => {
                    const { label } = selectedOption;
                    setCountryName(label);
                    setSelectedData(selectedOption);
                    handleSearchOperation(selectedOption);
                  }}
                  onChange={(e, i) => {
                    setSelectedData(i);
                    setCountryName(e);
                  }}
                />
                <h1
                  type="submit"
                  onClick={() => {
                    if (
                      searchCompaniesData?.data?.length !== 0 &&
                      selectedData &&
                      selectedData.id
                    ) {
                      navigate("/searchresult", {
                        state: { record: selectedData },
                      });
                      setError(false);
                    } else {
                      setError(true);
                    }
                  }}
                >
                  <img src={Images.search} />
                </h1>
              </div>
              <div className="indexing">
                <div className="uplode-the-supply">
                  {error ? (
                    <p
                      style={{
                        color: "#B70000",
                        fontWeight: 500,
                        fontSize: 13,
                      }}
                    >
                      No results found, please try another search!
                    </p>
                  ) : (
                    <p>{`indexing supply chain of over ${comapnyCount || 0} companies`}</p>
                  )}
                  <h6 onClick={() => setModal2Open(true)}>
                    <img src={Images.uplode} /> <span>Upload My List</span>{" "}
                  </h6>
                </div>
              </div>
              {historyData?.length > 0 && (
                <div className="search-history-sec-ps">
                  <div className="clear-search-line">
                    <h6>Search history</h6>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => dispatch(clearSearchAction())}
                    >
                      {" "}
                      Clear Search History
                    </p>
                  </div>
                  <div className="comp-historyps">
                    {historyData?.map((historyVal, historyIndex) => {
                      let value = calculateRisk(historyVal.data.supplyChain);
                      return (
                        <div
                          onClick={() => {
                            navigate("/searchresult", {
                              state: {
                                record: {
                                  id: historyVal.id,
                                  value: historyVal.name,
                                  label: historyVal.name,
                                },
                              },
                            });
                          }}
                          className="apple-inc-box"
                        >
                          <div className="apple-log-image">
                            <div className="icon-apple">
                              <img
                                style={{
                                  height: 50,
                                  width: 50,
                                  objectFit: "contain",
                                }}
                                src={`https://logos-svg.komo.systems/${historyVal.id}.png`}
                                alt="companyImage"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = ImageConst.Apple;
                                }}
                              />
                              <div>
                                <h6>{historyVal?.name}</h6>
                                <p>
                                  {historyVal?.tickers &&
                                    historyVal?.tickers[0]}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="apple-map-supply">
                            <LineChart
                              data={"chartData"}
                              series={series(historyVal?.data?.supplyChain)}
                              colors={
                                value < 0
                                  ? ["#1A9C3E", "green"]
                                  : ["#d00000", "red"]
                              }
                            />
                          </div>
                          <div className="risk-scrore">
                            <h6>Total Risk Score</h6>
                            <p
                              style={{
                                color: value < 0 ? "#1A9C3E" : "#d00000",
                              }}
                            >
                              {historyVal?.data?.supplyChain?.length > 0
                                ? historyVal?.data?.supplyChain[
                                  historyVal?.data?.supplyChain?.length - 1
                                ].companyRisk.toFixed(2)
                                : "-"}
                            </p>
                          </div>
                          <div className="totle-risk">
                            <div className="risk-reso">
                              {<p>Customer</p>}
                              {<p>Supplier</p>}
                              {<p>Partner</p>}
                            </div>
                            <div className="number-of-risk ">
                              <p
                                style={{
                                  color: value < 0 ? "#1A9C3E" : "#d00000",
                                }}
                              >
                                {historyVal?.data?.customers?.companyRisk
                                  ? historyVal?.data?.customers?.companyRisk.toFixed(
                                    2
                                  )
                                  : "-"}
                              </p>
                              <p
                                style={{
                                  color: value < 0 ? "#1A9C3E" : "#d00000",
                                }}
                              >
                                {historyVal?.data?.suppliers?.companyRisk
                                  ? historyVal?.data?.suppliers?.companyRisk.toFixed(
                                    2
                                  )
                                  : "-"}
                              </p>
                              <p
                                style={{
                                  color: value < 0 ? "#1A9C3E" : "#d00000",
                                }}
                              >
                                {historyVal?.data?.partners?.companyRisk
                                  ? historyVal?.data?.partners?.companyRisk.toFixed(
                                    2
                                  )
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="uploade-my-list-modal">
        {modal2Open && (
          <Modal
            title="Upload My Supply Chain List"
            className="uploade-chain"
            centered
            maskClosable={true}
            onCancel={() => setModal2Open(false)}
            open={modal2Open}
            footer={[
              <button
                className="can-btn"
                onClick={() => {
                  setModal2Open(false);
                  setFileData(null);
                }}
              >
                Cancel
              </button>,
              <button
                disabled={fileData ? false : true}
                className="rest-btn"
                onClick={() => {
                  uploadDataApi();
                }}
              >
                Apply
              </button>,
            ]}
          >
            <div className="data-uploade-ps">
              <div className="uploade-your-chain">
                <p>
                  You can upload your own supply chain list. Ensure your data is
                  in the correct format (CSV or Excel) to create a customized
                  view of your supply chain information.{" "}
                </p>
                {/* <span className="downl-btn">Download a sample file</span> */}
                <div className={style.downloadSample}>
                  {/* <div className="download-the-file-ps">
                    <a
                      href={supplyChainCSV}
                      download="supplyChain.csv"
                      rel="noreferrer"
                      className="downloadSample"
                    >
                      Download a sample file{" "}
                    </a>
                  </div> */}
                </div>
              </div>

              <div className="comp-name-chain">
                <label>Company name</label>
                <div className="sub-mit-upload">
                  <div className="text-input-ps">
                    <AutoComplete
                      className="autocomplete-modalInput"
                      onSearch={handleSearch}
                      value={uploadedCountryName}
                      options={searchCompaniesData?.data}
                      placeholder="Company name"
                      onSelect={(e, selectedOption) => {
                        const { label } = selectedOption;
                        setUploadCountryName(label);
                        setUploadedData(selectedOption);
                        // navigate("/companyPage", { state: { id } });
                        setErrorName(false);
                      }}
                      onChange={(e, i) => {
                        setUploadCountryName(e);
                      }}
                    />

                  </div>
                  <FileUploadPopUp
                    screenType={"supplyChain"}
                    uploadFileHadler={(data) => InputChange(data)}
                    children={
                      <div style={{}}>
                        <div
                          className="uplode-file-label"
                          onClick={() =>
                            uploadData ? setErrorName(false) : setErrorName(true)
                          }
                        >
                          {/* {uploadData && (
                            <input
                              type="file"
                              className="uploadInput"
                              id="fileupload"
                              name="Upload"
                              style={{ display: "none" }}
                              onChange={(e) => InputChange(e, "csv")}
                            />
                          )} */}

                          <label className="uplode-button-file">
                            {supplyChainFileUpload ? (
                              <GlobalSpinner />
                            ) : (
                              <span>
                                <img src={Images.whiteUploade} />
                              </span>
                            )}
                            Upload
                          </label>
                        </div>
                      </div>
                    }
                    shouldOpen={uploadData}
                  />

                  {/* <div>{isError?"error":isSuccess?"success":""}</div> */}
                </div>
                <div className="enter-the-comp-name">
                  <span>
                    <img
                      src={errorName ? Images.infoRed : Images.circleInfo}
                    />
                  </span>
                  <p style={{ color: errorName ? "red" : "black" }}>
                    Enter the name of the company for which you want to
                    upload the list of its supply chain
                  </p>
                </div>
                {isError ? (
                  <div className="invalid-error-ps">
                    <div className="error-box-ps">
                      <div className="error-icons">
                        <img src={Images.errorIcons} />
                      </div>
                      <div className="error-massage-ps">
                        <p>
                          Invalid File format! Please upload a file in the
                          correct format, such as CSV or Excel, to proceed
                        </p>
                      </div>
                    </div>
                  </div>
                ) : isSuccess ? (
                  <div className="successfull-ps">
                    <div className="successfull-box-ps">
                      <div className="successfull-icons">
                        <img src={Images.chickIcons} />
                      </div>
                      <div className="successfull-massage-ps">
                        <p>The file is uploaded successfully!</p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};
export default SupplyChain;
