import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import Images from "../../Themes/ImageConstant";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Select,
  Switch,
  Tabs,
  Modal,
  Breadcrumb,
  Collapse,
  Row,
  Input,
  Tooltip as ToolTip,
  AutoComplete,
} from "antd";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { getPortfolioListLoad } from "../../Redux/Action/portfolio";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import {
  BarMapping,
  BarMappingPAI,
  BarMappingSDG,
  ESG,
  HLEA,
  PNN,
  calculatePreviousDate,
  checkColor,
  dateButtonObj,
  generateData,
  getDiffTime,
  getSentimentValue,
  getServerityMapData,
  nFormatter,
  scrollTo,
  severityBarMapping,
} from "../../Utils";
import IncidentTable from "../../Componets/IncidentTable";
import SupplyChainTable from "../../Componets/SupplyChainTable";
import { MultiSlider } from "../../Componets/MultiSlider";
import {
  getCompaniesAction,
  getDownloadDataAction,
  getIncidentCountLoad,
  incindentAction,
  resetMapData,
} from "../../Redux/Action/incidents";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  getCompaniesDetailsAction,
  sentimentScoreAction,
  supplyChainIncidentAction,
  ResetDataAction,
  getIncidentseverityAction,
  getESGRiskScoreAction,
  getRegulatoryFinesPenaltiesAction,
  getRegulatoryLegalAction,
  getSocialMediaCoveragelAction,
  incidentCategorizationAction,
  ungcpBreachesAction,
  getRiskTrendAction,
  getCompanyRiskLineAction,
} from "../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import { columns, columns1, supplyChainColumns } from "./companyColumns";

import {
  SentimentChart,
  SentimentTrend,
  IncidentCategorizationGraph,
  PeerCompanyIncidentGraph,
  IncidentSeverity,
  WordChart,
  PeerCompaniesIncidentComparison,
} from "./chart";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";
import DatePicker from "../../Componets/DatePicker";
import moment from "moment";
import WorldMap from "../../Componets/WorldMap";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";
import IncidentsList from "../incidentsList";
import BubleIncidentsList from "../bubleIncidentsList";
import {
  getDateTwoDaysAgo,
  getGermanSupplyChainColors,
  getLastMonthDates,
  getPrincipleColors,
  getUCBreachesPrincipleColors,
  getUCBreachesSDGColors,
} from "../../Utils/globalFunction";
import SunChart from "./chart/SunChart";
import { dummySupplyChainCompanyData } from "../../Assets/dummyData";
import { ChartHeaderTimeRow } from "./chart/ChartHeaderTimeRow";
import ReactApexChart from "react-apexcharts";
import IncidentsByESGCategories from "./chart/IncidentCategorization/IncidentsByESGCategories";
import EChartsReact from "echarts-for-react";
import { ChartCard } from "../../Layouts/ChartCard";
import TimeLine from "./chart/TimeLine";
import RegulatoryFinesPenaltiesCard from "./utils-components/regulatoryFines&PenaltiesCard";
import RegulatoryLegalActionsCard from "./utils-components/regulatoryLegalActionsCard";
import {
  sentimentChildObj,
  socialMediaChildObj,
} from "../../Utils/socialMediaChartObj";
import UngcpBreaches from "./utils-components/ungcpBreaches";
import { ChartBottomTitle } from "../../Componets/ChartBottomTitle";
import FullMap from "../../Componets/MapGL/full-map";
import IncidentsListModal from "../IncidentsListModal";
import PrincipleBarChat from "../../Componets/PrincipleBarChat";
import useDebouncedApiCall from "../../hook/useDebouncedSearch";
import { searchCompanyListActionLoad } from "../../Redux/Action/global";
import ImageConst from "../../Themes/ImageConstant";
import { index } from "d3";
import PAIBarChat from "../../Componets/PrincipleBarChat/PAI";
import SDGBarChat from "../../Componets/PrincipleBarChat/SDG";
import { getBreachesViolationDataLoad } from "../../Redux/Action/incidentAnalyzerAction";
import {
  IncidentDistributionOverTime,
  IncidentDistributionTotal,
} from "../corporateProfile/chart";
import IncidentTimeline from "../corporateProfile/chart/IncidentTimeline";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import SeverityTrend from "../corporateProfile/chart/sentimentTrend/ServerityTrend";
import { getCorpRiskChartDataLoad } from "../../Redux/Action/corporateProf";
import LineChart from "../searchResult/components/lineChart";
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import ReactCountryFlag from "react-country-flag";
import { highlightMatch } from "../../Utils/globalFunction";
// Register locales with correct imports
countries.registerLocale(enLocale);
countries.registerLocale(frLocale);
let lastSearchString = "";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const lastDate = dateButtonObj[3].type;

let networkData = {
  end: moment().format("YYYY-MM-DD"),
  start: moment(calculatePreviousDate(new Date(), lastDate)).format(
    "YYYY-MM-DD"
  ),
};

const getActiveItem = (number) => {

  if (number == 0 || (number > 0 && number < 480)) {
    return "Overview";
  } else if (number >= 480 && number <= 2016) {
    return "Incidents";
  } else if (number >= 2017 && number <= 2272) {
    return "SupplyChain";
  } else if (number >= 2273 && number <= 3749) {
    return "IncidentsAnalysis";
  } else if (number >= 3750 && number <= 5250) {
    return "severityAnalysis";
  } else if (number >= 5251 && number < 7099) {
    return "SocialMediaCoverage";
  } else if (number >= 7100 && number <= 8300) {
    return "PeerComparison";
  } else if (number >= 8301 && number <= 9050) {
    return "CarbonFootprint";
  } else if (number >= 9051 && number <= 10350) {
    return "UNPrinciple";
  } else if (number >= 10351 && number <= 15400) {
    return "Regulatory&LegalAction";
  }
};

const legend = [
  {
    name: "X corp.",
    image: Images.XCROP,
  },
  // {
  //   name: "Facebook",
  //   image: Images.facebook,
  // },
  {
    name: "Reddit",
    image: Images.Raddit,
  },
  // {
  //   name: "LinkedIn",
  //   image: Images.linkedin1,
  // },
];

const GermanSupplyArr = [
  {
    name: "Human Rights",
    value: 10,
  },
  {
    name: "Labor Rights",
    value: 20,
  },
  {
    name: "Employee Health and Safty",
    value: 30,
  },
  {
    name: "Environment",
    value: 40,
  },
];

let countryDataObj = {
  AF: "Africa",
  AN: "Antarctica",
  AS: "Asia",
  EU: "Europe",
  NA: "North America",
  OC: "Oceania",
  SA: "South America",
};
let autoCompletePaginationOffset = 0;

const Dashboard = () => {
  const [headerSmallState, setHeaderSmallState] = useState(false);
  const [activeItem, setActiveItem] = useState("Overview");
  const [modalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const [modalData, setModalData] = useState(null);
  const [potfolioModel, setPotfolioModel] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [isHovered, setIsHovered] = useState(false);
  const [breachesViolationLimit, setBreachesViolationLimit] = useState(10);
  const [breachesViolationPaiLimit, setBreachesViolationPaiLimit] =
    useState(10);
  const [breachesViolationSdgLimit, setBreachesViolationSdgLimit] =
    useState(10);

  useEffect(() => {
    return () => {
      localStorage.removeItem("companyId");
    };
  }, []);
  let id =
    localStorage.companyId == undefined
      ? location.state.id
      : localStorage.companyId;

  const [rootLevelIncidentModalItem, setRootLevelIncidentModalItem] =
    useState(false);
  const [
    topLevelIncidentListModalPayload,
    setTopLevelIncidentListModalPayload,
  ] = useState({ ...networkData, companies: [id] });
  // toggle........
  const navigate = useNavigate();
  // ........
  const companyData = useSelector(
    (state) => state?.companyPageReducer?.getCompanyDetails
  );

  const {
    supplyChainIncident,
    sentimentScore,
    continentData,
    serverityMap,
    ESGRiskScoreList,
    getRegulatoryFinesPenaltiesList,
    regulatoryLegalActionsData,
    socialMediaCoverage,
    incidentCategorization,
    ungcpBreaches,
    riskTrendData,
    riskLineChart,
  } = useSelector((state) => state?.companyPageReducer);
  const { breachesAndViolationData, breachesAndViolationDataLoader } =
    useSelector((state) => state.incidentAnalyzerReducer);
  let update = continentData.map((val) =>
    countryDataObj[val?.continent_code]
      ? { ...val, continent_code: countryDataObj[val?.continent_code] }
      : { ...val }
  );
  const portfolioList = useSelector((state) => state?.portfolio?.portfolioList);

  const sentimentObj = sentimentChildObj(socialMediaCoverage?.sentiment);
  const socialObj = socialMediaChildObj(socialMediaCoverage?.socialMedia);
  const { incident, totalEsgIncidentCount } = useSelector(
    (state) => state?.incidentReducer
  );
  const [timelineIndidentList, setTimelineIndidentList] = useState(false);
  const [incidentIdArray, setIncidentIdArray] = useState([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [date, setDate] = useState(false);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [legalOrder, setLegalOrder] = useState(true);
  const [order, setOrder] = useState(true);
  const [tabKey, setTabKey] = useState("1");
  const [tabVal, setTabVal] = useState("Environment");
  useEffect(() => {
    dispatch(getPortfolioListLoad());
  }, []);
  useLayoutEffect(() => {
    dispatch(getIncidentCountLoad({ ...networkData }));
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const activeTab = getActiveItem(scrollTop);
      if (activeTab === "Incidents") {
        setHeaderSmallState(true);
      }
      if (activeTab === "Overview") {
        setHeaderSmallState(false);
      }
      setActiveItem(activeTab);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (Object.entries(totalEsgIncidentCount).length > 0) {
      if (totalEsgIncidentCount?.Environmental > 0) {
        setTabKey("1");
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social > 0
      ) {
        setTabKey("2");
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social == 0 &&
        totalEsgIncidentCount?.Governance > 0
      ) {
        setTabKey("3");
      } else {
        setTabKey("1");
      }
    }
  }, [totalEsgIncidentCount]);

  useEffect(() => {
    scrollTo(0.0);
    networkCall(networkData);
    dispatch(getCompaniesAction());
    return () => {
      dispatch(ResetDataAction());
    };
  }, [location]);

  useEffect(() => {
    dispatch(
      incindentAction({
        ...networkData,
        companies: [id],
        esg_categories: [
          tabKey == "1"
            ? "Environment"
            : tabKey == "2"
              ? "Social"
              : "Governance",
        ],
        sort: "discovery_time",
        order: -1,
      })
    );
  }, [tabKey]);

  const networkCall = (data) => {
    dispatch(getCompaniesDetailsAction({ ...data, id }));
    dispatch(
      getBreachesViolationDataLoad({
        ...data,
        companies: [id],
        limit:
          activeTab == 1
            ? breachesViolationLimit
            : activeTab == 3
              ? breachesViolationPaiLimit
              : activeTab == 4
                ? breachesViolationSdgLimit
                : breachesViolationLimit,
        type:
          activeTab == 1
            ? "esg_supply_chain_act"
            : activeTab == 0
              ? "german_supply_chain_act"
              : activeTab == 2
                ? "esg_SDG"
                : "esg_PAI",
      })
    );
    dispatch(getCompanyRiskLineAction({ ...data, id }));
    dispatch(getRiskTrendAction({ ...data, id }));
    dispatch(
      incindentAction({
        ...data,
        companies: [id],
        esg_categories: [tabVal],
        sort: "discovery_time",
        order: -1,
      })
    );
    dispatch(supplyChainIncidentAction({ ...data, id, limit: 4 }));
    dispatch(sentimentScoreAction({ ...data, id }));

    dispatch(getIncidentseverityAction({ ...data, id }));
    //New
    dispatch(getESGRiskScoreAction({ ...data, id }));
    dispatch(getRegulatoryFinesPenaltiesAction({ ...data, id }));
    dispatch(getRegulatoryLegalAction({ ...data, id }));
    dispatch(getSocialMediaCoveragelAction({ ...data, id }));
    dispatch(incidentCategorizationAction({ ...data, id }));
    dispatch(ungcpBreachesAction({ ...data, id }));
  };

  useEffect(() => {
    if (activeTab != 2) {
      dispatch(
        getBreachesViolationDataLoad({
          ...networkData,
          companies: [id],
          limit:
            activeTab == 1
              ? breachesViolationLimit
              : activeTab == 3
                ? breachesViolationPaiLimit
                : activeTab == 4
                  ? breachesViolationSdgLimit
                  : breachesViolationLimit,
          type:
            activeTab == 1
              ? "esg_supply_chain_act"
              : activeTab == 0
                ? "german_supply_chain_act"
                : activeTab == 4
                  ? "esg_SDG"
                  : "esg_PAI",
        })
      );
    }
    else {
      dispatch(
        ungcpBreachesAction({
          ...networkData,
          id,
          limit: breachesViolationLimit,
        })
      );
    }
  }, [
    activeTab,
    breachesViolationLimit,
    breachesViolationPaiLimit,
    breachesViolationSdgLimit,
  ]);


  const createCSeriesData = (data, groupType) => {
    const finalObj = {
      data: [],
      label: [],
    };
    try {
      let a = Object.keys(data).values && Object.keys(data);

      a.map((i, j) => {
        finalObj.data = Object.values(data).map((i) =>
          i.toFixed(2)
        );

        finalObj.label = a.map((i) => {
          return moment(i).format("DD MMM YY HH:mm")
        })
        finalObj.allLabel = a.map((i) => {
          let start_Date = moment(i).format("DD MMM YY HH:mm")

          return start_Date
        });
      });

      return { ...finalObj };
    } catch (e) { }
  };

  const seriesData = useCallback(
    () => createCSeriesData(riskTrendData.Risk, riskTrendData.groupType),
    [riskTrendData]
  );
  const data = riskTrendData && seriesData();

  const onDatePickerChange = (startDate, previousDate) => {
    if (startDate && previousDate) {
      networkData.start = startDate;
      networkData.end = previousDate;
      networkCall(networkData);
      setDate(!date);
    }
  };

  const callDownloadApi = () => {
    dispatch(getDownloadDataAction({ id, start: networkData.start, end: networkData.end }));
  };

  const CompanyRiskScore = () => {
    return (
      <div className="slide-barMain">
        <div className="slide-bar">
          <p className="slider-lables">Low</p>
          <MultiSlider
            name={companyData?.name}
            className="greenSlider"
            lableTextColor={["#2D303A", "#5E0000"]}
            labelBackground={["#E7E9F0", "#F8D9D9"]}
            data={sentimentScore}
          />
          <p className="slider-lables">High</p>
        </div>
        <div className="sliderContent">
          <span style={{ color: "#80838B" }}>
            Industry{" "}
            <span className="ml-2">{sentimentScore?.industry?.name}</span>
          </span>
        </div>
      </div>
    );
  };
  const totalCount = (arr) => {
    let totalCount = 0;
    totalCount = arr?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.incidents.length;
    }, 0);

    return totalCount;
  };

  const calculateRisk = (data, key) => {
    // Extracting the company risks from the last two entries
    const lastCompanyRisk1 =
      data.length > 1
        ? data[data.length - 2]?.risk || data[data.length - 2]?.companyRisk
        : 0;
    const lastCompanyRisk2 =
      data.length > 0
        ? data[data.length - 1]?.risk || data[data.length - 1]?.companyRisk
        : 0;
    // Calculating the percentage change in risk
    const riskPercentageChange =
      ((lastCompanyRisk2 - lastCompanyRisk1) /
        (lastCompanyRisk1 > 0 ? lastCompanyRisk1 : 1)) *
      100;
    return riskPercentageChange.toFixed(2);
  };
  const series = (chart_Data) => [
    {
      data: chart_Data.map((entry) => entry?.risk || entry?.companyRisk),
    },
  ];
  const ChartComponentNew = ({ data, title, count }) => {
    if (data.details) {
      let value = data.riskDetails.risk_change
      return (
        <div className="supply-chain-risk-grph-ps">
          <h5>{title}</h5>
          <div className={`today-per-ps ${value < 0 && "green-ps-map"}`}>
            <p>Today</p>
            {data && (
              <>
                <span>
                  {value != 0 && (
                    <img
                      src={
                        value < 0 ? Images.greenArrowSumUp : Images.arrowSumUp
                      }
                    />
                  )}
                  {value && Math.abs(value.toFixed(2) || 0) + "%"}
                </span>
                <h6>
                  {data?.riskDetails?.risk && data.riskDetails?.risk.toFixed(2)}
                </h6>
              </>
            )}{" "}
          </div>
          <div className="apple-map-supply">
            <LineChart
              data={"chartData"}
              colors={value < 0 ? ["#1A9C3E", "green"] : ["#d00000", "red"]}
              series={series(data.details)}
            />
          </div>
        </div>
      );
    }
  };

  const linesOption =
  {
    color: ["#e74c3c"],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      },
      formatter: function (params) {
        return `
        <div style="width:160px;margin:5px; border-radius:5px;">
            <div style="font-weight:bold;font-size:13px;color:#000; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            ${data.allLabel[params[0].dataIndex]}
            </div>
            <div style="margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            <div class="tooltip-item" >${params[0].seriesName}: ${params[0].data}</div>
            </div>
        </div>
          `;
      },
    },
    toolbox: {
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: data?.label
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    dataZoom: [
      {
        type: "slider", // This is a slider at the bottom
        zoomOnMouseWheel: "ctrl", // Requires the Ctrl key to be pressed to zoom with the mouse wheel
      },
      {
        type: "inside", // This enables zooming and panning with mouse actions
        zoomOnMouseWheel: "ctrl", // Also requires the Ctrl key for the slider, for consistency
      },
    ],
    series: [
      {
        name: 'Risk Score',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        label: {
          show: true,
          position: 'top'
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#e74c3c"
            },
            {
              offset: 1,
              color: "#e74c3c"
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: data?.data ? data?.data : [],
      },

    ]
  };
  const totalIncidentCount = () => {
    return (
      ungcpBreaches?.data?.reduce(
        (total, principle) => total + principle.incidents.length,
        0
      ) || 0
    );
  };
  return (
    <div className="side-padding aaa ">
      <div
        className="dashbored-head"
        style={headerSmallState ? { height: "118px" } : {}}
      >
        <BreadcrumbComponent companyName={companyData?.name} />
      </div>
      <div
        className="main-layout top-space"
        style={headerSmallState ? { marginTop: "154px" } : {}}
      >
        <div className="container mt-3">
          <div className="apple-logo">
            <div className="apple-image">
              {companyData?.id && (
                <img
                  style={{ height: 128, width: 128, objectFit: "contain" }}
                  src={`https://logos-svg.komo.systems/${companyData?.id}.png`}
                  alt="companyImage"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = ImageConst.Apple;
                  }}
                />
              )}
            </div>
            <div className="page-title">
              <div className="add-header-btn">
                <h5>{companyData?.name}</h5>
                {companyData?.name && (
                  <div className="header-add-button">
                    <button
                      onClick={() => {
                        setPotfolioModel(true);
                      }}
                    >
                      <span>
                        <img src={Images.addimage} />
                      </span>{" "}
                      Add{" "}
                      <span>
                        <img src={Images.whiteArrowDn} />
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <p>{companyData?.tickers && companyData?.tickers[0]}</p>
            </div>
          </div>

          <div className="row" style={{ display: "flex" }}>
            <div className="col-lg-3 top-margin-comp containerMidWidth ">
              <div className="hone-left-side1">
                <div className="home-left-list mt-3">
                  <ul>
                    <li
                      className={activeItem == "Overview" ? "tab-active" : ""}
                    >
                      <a onClick={() => scrollTo(0)}> Overview </a>
                    </li>
                    <li
                      className={activeItem == "Incidents" ? "tab-active" : ""}
                    >
                      <a onClick={() => scrollTo(480)}>1 Risk Analysis </a>
                    </li>
                    <li
                      className={
                        activeItem == "SupplyChain" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(2017)}>2 Supply Chain </a>
                    </li>
                    <li
                      className={
                        activeItem == "IncidentsAnalysis" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(2273)}>
                        3 Incidents Analysis{" "}
                      </a>
                    </li>
                    <li
                      className={
                        activeItem == "severityAnalysis" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(3750)}>4 Severity Analysis </a>
                    </li>
                    <li
                      className={
                        activeItem == "SocialMediaCoverage" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(5251)}>
                        {/* // <a onClick={() => document.getElementById("ESGCAT").scrollIntoView()}> */}
                        5 ESG Categories
                      </a>
                    </li>

                    <li
                      className={
                        activeItem == "PeerComparison" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(7100)}>6 Peer Companies </a>
                    </li>
                    <li
                      className={
                        activeItem == "CarbonFootprint" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(8301)}>
                        7 Social Media Coverage
                      </a>
                    </li>
                    <li
                      className={
                        activeItem == "UNPrinciple" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(9051)}>
                        8 Breaches & violation
                      </a>
                    </li>
                    <li
                      className={
                        activeItem == "Regulatory&LegalAction"
                          ? "tab-active"
                          : ""
                      }
                    >
                      <a onClick={() => scrollTo(10351)}>
                        9 Regulatory & Legal Actions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-9 containerWidth ml-32">
              <section>
                <div className="containerWidth g-0">
                  <div className="row  mt-2">
                    <div className={`top-right-home-drop`}>
                      <div>
                        {/* <a href=""> */}
                        <img
                          onClick={() => {
                            callDownloadApi();
                          }}
                          src={Images.framedow}
                        />
                        {/* </a> */}
                      </div>
                      {/* social  share button  model MM  */}
                      <Modal
                        title={"Share"}
                        centered
                        className="share"
                        open={isModalOpen}
                        footer={false}
                        onCancel={() => setIsModalOpen(false)}
                        mask
                      >
                        <div style={{ display: "flex", gap: "22px" }}>
                          <FacebookShareButton
                            url={"www.google.com"}
                            quote={"asasas"}
                          >
                            <FacebookIcon size={32} round={true} />
                          </FacebookShareButton>

                          <LinkedinShareButton
                            url={"www.google.com"}
                            title={"asasas"}
                          >
                            <LinkedinIcon size={32} round={true} />
                          </LinkedinShareButton>

                          <TwitterShareButton
                            url={"www.google.com"}
                            title={"asasasa"}
                          >
                            <XIcon size={32} round={true} />
                          </TwitterShareButton>
                        </div>
                      </Modal>
                      <DatePicker
                        onDatePickerChange={onDatePickerChange}
                        lastDate={lastDate}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className="containerWidth g-0">
                  <div className="row">
                    <div className="col mt-5">
                      <div className="inciden-box">
                        <div
                          className="total-box"
                          onClick={() => setModalVisible(true)}
                        >
                          <h5>All Incidents</h5>
                          <h1>{nFormatter(companyData?.totalIncidents)}</h1>
                        </div>

                        <div
                          className="new-box"
                          onClick={() => {
                            setModalVisible(true);
                            setModalData({
                              ...networkData,
                              esg_categories: ["Environmental"],
                            });
                          }}
                        >
                          <img src={Images.globeblue} />
                          <p>Environmental</p>
                          <h5>{nFormatter(companyData?.environmentIncidents)}</h5>
                        </div>

                        <div
                          className="new-box"
                          onClick={() => {
                            setModalVisible(true);
                            setModalData({
                              ...networkData,
                              esg_categories: ["Social"],
                            });
                          }}
                        >
                          <img src={Images.socialblue} />
                          <p>Social</p>
                          <h5>{nFormatter(companyData?.socialIncidents)}</h5>
                        </div>

                        <div
                          className="new-box"
                          onClick={() => {
                            setModalVisible(true);
                            setModalData({
                              ...networkData,
                              esg_categories: ["Governance"],
                            });
                          }}
                        >
                          <img src={Images.bankblue} />
                          <p>Governance</p>
                          <h5>{nFormatter(companyData?.governanceIncidents)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col mt-5">
                      <div className="seve-main-boxes" style={{ gap: "16px" }}>
                        <div
                          className="fir-box"
                          onClick={() => {
                            setModalVisible(true);
                            setModalData({ ...networkData, showSever: true });
                          }}
                        >
                          <img src={Images.circle} />
                          <p>
                            Severe
                            <span>
                              <img
                                className="hover-page"
                                src={Images.circleInfo}
                              />
                              <div className="infohover-home">
                                <p>
                                  We use AI to determine the severity of an
                                  incident based on its content. However, since
                                  it is often a subjective matter, this
                                  description should not be solely relied on. It
                                  is intended as a helpful clue to assist users.
                                </p>
                              </div>
                            </span>
                          </p>

                          <h5 className="gap">
                            {nFormatter(companyData?.highSeverIncidents)}
                          </h5>
                        </div>

                        <div
                          className="fir-box"
                          onClick={() => {
                            setModalVisible(true);
                            setModalData({
                              ...networkData,
                              start: moment()
                                .subtract(30, "day")
                                .format("YYYY-MM-DD"),
                              end: moment().format("YYYY-MM-DD"),
                            });
                          }}
                        >
                          <img src={Images.clockGreen} />
                          <p>Recent</p>
                          <p>(last month)</p>
                          <h5>{nFormatter(companyData?.lastMonth)}</h5>
                        </div>
                        <div
                          className="fir-box"
                          onClick={() => {
                            setModalVisible(true);
                            setModalData({
                              ...networkData,
                              start: moment()
                                .subtract(2, "day")
                                .format("YYYY-MM-DD"),
                              end: moment().format("YYYY-MM-DD"),
                            });
                          }}
                        >
                          <img src={Images.felame} />
                          <p>New</p>
                          <p>(last 2 days)</p>
                          <h5>{nFormatter(companyData?.lastTwoDays)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="collapse-section-ps containerWidth">
                <Collapse
                  defaultActiveKey={[
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                  ]}
                  className="company-detail-collapse"
                  onChange={(e) => { }}
                  expandIcon={({ isActive }) => {
                    return (
                      <img
                        src={Images.iconDown}
                        style={{
                          transform: `rotate(${isActive ? "0" : "-90"}deg)`,
                        }}
                      />
                    );
                  }}
                >
                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          1
                        </span>
                        Risk Analysis
                      </h4>
                    }
                    key="1"
                  >
                    <section>
                      <div className="risk-per-comp   ">
                        <div className="row">
                          <div className="">
                            <ChartComponentNew
                              data={riskLineChart}
                              title={"Company ESG Risk"}
                              count={12}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="inc-map-pre">
                        <div className="tab-top">
                          <div className="left-text">
                            <Row>
                              <span className="subtitle1"> ESG Risk Score</span>
                              <img
                                className="hover-page"
                                style={{ marginLeft: 5 }}
                                src={Images.circleInfo}
                                alt=""
                              />
                            </Row>
                          </div>
                          <div
                            className="righ-side"

                          // onClick={() => navigate("/fullMap")}
                          ></div>
                        </div>
                        <div className="sun-chart-container">
                          <SunChart
                            networkData={networkData}
                            Id={id}
                            risk={ESGRiskScoreList?.risk}
                            graphData={
                              ESGRiskScoreList.data?.length > 0
                                ? ESGRiskScoreList.data
                                : []
                            }
                            id={"companyChartSvgContainer"}
                            environment={ESGRiskScoreList.environmental}
                            social={ESGRiskScoreList.social}
                            governance={ESGRiskScoreList.governance}
                          />
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="mt-32">
                        <div className="container esg-risk-inner-cont">
                          <div className="esg-heading mb-3">
                            <div className="text-esg-m">
                              <h5>
                                Company vs. Industry Risk score
                                <sapn>
                                  <img
                                    src={Images.circleInfo}
                                    className="info-icon-company-page"
                                  />
                                </sapn>
                              </h5>
                            </div>
                          </div>

                          <CompanyRiskScore />
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="inc-map-pre mt-32">
                        <div className="tab-top backgroundColor">
                          <div className="left-text">
                            <Row>
                              <span className="subtitle1"> Risk Trend</span>
                              <img
                                className="hover-page"
                                style={{ marginLeft: 5 }}
                                src={Images.circleInfo}
                                alt=""
                              />
                            </Row>
                          </div>
                        </div>

                        <div className="line-chart">
                          <ReactECharts
                            echarts={echarts}
                            option={linesOption}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{ width: "100%" }}
                          />
                          {/* <ReactApexChart
                            options={linesOption.options}
                            series={linesOption.series}
                            type="area"
                          /> */}
                        </div>
                      </div>
                    </section>
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          2
                        </span>
                        Supply Chain
                      </h4>
                    }
                    key="2"
                  >
                    <SupplyChainTable
                      id={companyData?.id}
                      companyName={companyData?.name}
                      data={supplyChainIncident}
                      columns={supplyChainColumns}
                      date={{ ...networkData }}
                      goTo={"/searchResult"}
                      title={
                        <h4>
                          Supply Chain Companies
                          <img
                            src={Images.circleInfo}
                            className="info-icon-company-page"
                          />
                        </h4>
                      }
                    />
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          3
                        </span>
                        Incident Analysis
                      </h4>
                    }
                    key="3"
                  >
                    <IncidentTable
                      id={companyData?.id}
                      companyName={[companyData?.name]}
                      data={incident?.data?.slice(0, 4)}
                      columns={columns1}
                      date={{ ...networkData }}
                      goTo={"/companyPage"}
                      title={
                        <h4>
                          Incidents List
                          <img
                            src={Images.circleInfo}
                            className="info-icon-company-page"
                          />
                        </h4>
                      }
                      tabKey={[tabKey, setTabKey]}
                      tabVal={[tabVal, setTabVal]}
                      tabShowCount={totalEsgIncidentCount}
                    />

                    <IncidentTimeline
                      incidentListHandler={(data) => {
                        setTimelineIndidentList(data.modalState);
                        setIncidentIdArray(data.listData);
                      }}
                      {...networkData}
                      id={id}
                      dateChange={date}
                    />
                    {/* <IncidentDistributionTotal
                      {...networkData}
                      id={id}
                      dateChange={date}
                    /> */}
                    <IncidentDistributionOverTime
                      {...networkData}
                      id={id}
                      dateChange={date}
                    />
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          4
                        </span>
                        Severity Analysis
                      </h4>
                    }
                    key="4"
                  >
                    <IncidentSeverity
                      {...networkData}
                      id={id}
                      dateChange={date}
                    />
                    <section>
                      <div className="inc-map-pre mt-4 containerWidth">
                        <div className="tab-top">
                          <div className="left-text">
                            <Row>
                              <h4>Incidents severity Map</h4>
                              <img
                                className="hover-page"
                                style={{ marginLeft: 5 }}
                                src={Images.circleInfo}
                                alt=""
                              />
                            </Row>
                          </div>
                          <div
                            className="righ-side"
                            onClick={() => {
                              dispatch(resetMapData())
                              navigate("/fullMap", {
                                state: {
                                  ...networkData,
                                  companyId: id,
                                },
                              })
                            }
                            }
                          >
                            <a>
                              View Full Map
                              <span>
                                <img src={Images.iconser} />
                              </span>
                            </a>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div
                              className="col-10 mt-3 mb-3"
                              style={{
                                position: "relative",
                                height: "326px",
                              }}
                            >
                              <div className="map-pre">
                                <div
                                  style={{
                                    left: "1vw",
                                    opacity: 0.9,
                                    bottom: "6px",
                                    padding: ".6vw",
                                    position: "absolute",
                                    display: "flex",
                                    zIndex: "1",
                                    backgroundColor: "#ffffff",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      marginBottom: ".4vw",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    Severity
                                  </span>
                                  <div>
                                    <MapItem
                                      color={"#800020"}
                                      name={"Critical"}
                                    />
                                    <MapItem color={"#B70000"} name={"High"} />
                                    <MapItem
                                      color={"#F28C28"}
                                      name={"Moderate"}
                                    />
                                    <MapItem color={"#FFD700"} name={"Minor"} />
                                  </div>
                                </div>
                                <FullMap
                                  width={"600px"}
                                  height={"326px"}
                                  zoom={0}
                                  data={serverityMap || []}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div
                                className="asia-cont"
                                style={{
                                  minWidth: "100px",
                                }}
                              >
                                {update?.map((item, index) => {
                                  return (
                                    <div className="africa-co">
                                      <h5>{item.continent_code}</h5>
                                      <p>{item.total || 0}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <SeverityTrend {...networkData} id={id} dateChange={date} />
                  </Collapse.Panel>

                  <Collapse.Panel
                    id="ESGCAT"
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          5
                        </span>
                        ESG Categories
                      </h4>
                    }
                    key="5"
                  >
                    <IncidentsByESGCategories data={incidentCategorization} />
                    <WordChart {...networkData} id={id} dateChange={date} />
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          6
                        </span>
                        Peer Companies
                      </h4>
                    }
                    key="6"
                  >
                    <PeerCompaniesIncidentComparison
                      {...networkData}
                      id={id}
                      dateChange={date}
                      companyName={companyData?.name}
                    />
                    <PeerCompanyIncidentGraph
                      {...networkData}
                      id={id}
                      dateChange={date}
                    />
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          7
                        </span>
                        Social Media Coverage
                      </h4>
                    }
                    key="7"
                  >
                    <section>
                      <ChartCard
                        showTimeBarOnTop={false}
                        title={"Social Media Coverage"}
                        legend={PNN}
                      >
                        <div className="line-chart">
                          <Row>
                            <div className="col-sm-6 socialChart">
                              <span className="captionXl">
                                Social Media Sentiment Distribution
                              </span>
                              <EChartsReact
                                className="doubleLayer-pieChart"
                                option={{
                                  silent: false,
                                  series: [
                                    {
                                      label: {
                                        color: "#fff",
                                        textBorderColor: "#fff",
                                      },
                                      itemStyle: {
                                        borderRadius: 10,
                                        borderWidth: 5,
                                      },
                                      radius: ["0", "100%"],
                                      type: "sunburst",
                                      emphasis: {
                                        focus: "ancestor",
                                      },
                                      data: [
                                        {
                                          children: sentimentObj?.tweets,
                                          name: "Tweets",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#1DA1F2",
                                                },
                                                {
                                                  offset: 1,
                                                  color:
                                                    "rgba(135, 207, 255, 1)",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },

                                        {
                                          children: sentimentObj?.reddit_posts,
                                          name: "Reddit",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#FF4500",
                                                },
                                                {
                                                  offset: 1,
                                                  color:
                                                    "rgba(255, 171, 140, 1)",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                }}
                              />
                            </div>
                            <div className="col-sm-6  socialChart">
                              <span className="captionXl">
                                Sentiment Distribution by Social Media Platforms
                              </span>
                              <EChartsReact
                                className="doubleLayer-pieChart"
                                option={{
                                  silent: false,
                                  series: [
                                    {
                                      label: {
                                        color: "#fff",
                                        textBorderColor: "#fff",
                                      },
                                      itemStyle: {
                                        borderRadius: 10,
                                        borderWidth: 5,
                                      },
                                      radius: ["0", "100%"],
                                      type: "sunburst",
                                      emphasis: {
                                        focus: "ancestor",
                                      },
                                      data: [
                                        {
                                          children:
                                            socialObj?.positive?.childArray,
                                          name:
                                            socialObj?.positive?.percentage +
                                            " %",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#3aa95a",
                                                },
                                                {
                                                  offset: 1,
                                                  color: "#ffffff",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                        {
                                          children:
                                            socialObj?.negative?.childArray,
                                          name:
                                            socialObj?.negative?.percentage +
                                            " %",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#d72727",
                                                },
                                                {
                                                  offset: 1,
                                                  color: "#ffffff",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                        {
                                          children:
                                            socialObj?.neutral?.childArray,
                                          name:
                                            socialObj?.neutral?.percentage +
                                            " %",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#bec3cd",
                                                },
                                                {
                                                  offset: 1,
                                                  color: "#ffffff",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </Row>
                          <div className="mainLegendDiv">
                            {legend.map((e, i) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    className="legendImg"
                                    src={e.image}
                                    alt={e.name}
                                  />
                                  <div
                                    className="legendSpan"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {" "}
                                    {e.name}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className="reactionAnalyses"
                            onClick={() =>
                              navigate("/socialMediaCoverage", {
                                state: {
                                  companies: [id],
                                  incident: "",
                                  name: "companyPage",
                                  companyName: companyData?.name,
                                  companyDetail: {
                                    incidents: companyData?.totalIncidents,
                                    risk: ESGRiskScoreList,
                                  },
                                },
                              })
                            }
                          >
                            <span className="reactionAnalysesSpan">
                              View All Reaction Analyses
                            </span>
                          </div>
                        </div>
                      </ChartCard>
                    </section>
                  </Collapse.Panel>
                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          8
                        </span>
                        Breaches & violation
                      </h4>
                    }
                    key="4"
                  >
                    <div
                      className="most-sec-over containerWidth"
                      style={{ padding: 20 }}
                    >
                      <div className="violations-ps">
                        <h2>Breaches and Violations</h2>
                      </div>
                      <Tabs
                        onChange={(e) => {
                          setActiveTab(e);
                        }}
                        activeKey={activeTab}
                        className="supply-chain-act-px "
                      >
                        <Tabs.TabPane key="0" tab={"LkSG"}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.breachesIcons}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      German Supply Chain Act (LkSG)
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {totalCount(
                                        breachesAndViolationData?.most_frequent ||
                                        []
                                      )}{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of violations
                                  </span>
                                </Row>
                                <div></div>
                              </div>
                              <div>
                                {breachesAndViolationData?.german_supply_chain_act &&
                                  breachesAndViolationData
                                    .german_supply_chain_act.length > 0 &&
                                  breachesAndViolationData?.german_supply_chain_act.map(
                                    (item, index) => {
                                      return (
                                        <div className="companiesDataDiv">
                                          <span>
                                            {index + 1 + " "}
                                            <b
                                              onClick={() =>
                                                navigate("/companyPage", {
                                                  state: { id: item?.id },
                                                })
                                              }
                                            >
                                              {item.name + " "}
                                            </b>

                                          </span>

                                          <div
                                            style={{
                                              width: 100,
                                              height: 19,
                                              display: "flex",
                                            }}
                                          >
                                            {item.incidentCount + " incidents"}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>

                              {breachesAndViolationData?.esg_supply_chain_act &&
                                breachesAndViolationData.esg_supply_chain_act
                                  .length > 9 && (
                                  <div
                                    className="reactionAnalyses"
                                    onClick={() => {
                                      // setModalVisible(true);
                                      setBreachesViolationLimit(200);
                                    }}
                                  >
                                    <span className="reactionAnalysesSpan downl-btn">
                                      View All
                                    </span>
                                  </div>
                                )}

                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent violations
                                </span>
                              </div>
                              {activeTab == 0 &&
                                breachesAndViolationData?.most_frequent &&
                                breachesAndViolationData.most_frequent.length >
                                0 &&
                                breachesAndViolationData?.most_frequent?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                        value={val._id}
                                        dataKey={val.value}
                                      />
                                    );
                                  }
                                )}

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                  setModalData({
                                    ...modalData,
                                    voilation: "german_supply_chain_act",
                                    start: networkData.start,
                                    end: networkData.end,
                                  });
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="1" tab={"EU Supply Chain Act."}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.breachesIcons}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      Supply Chain Act Violations
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {totalCount(
                                        breachesAndViolationData?.most_frequent ||
                                        []
                                      )}{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of violations
                                  </span>
                                </Row>
                                <div></div>
                              </div>


                              <div>
                                {breachesAndViolationData?.esg_supply_chain_act &&
                                  breachesAndViolationData.esg_supply_chain_act
                                    .length > 0 &&
                                  breachesAndViolationData?.esg_supply_chain_act.map(
                                    (item, index) => {
                                      return (
                                        <div className="companiesDataDiv">
                                          <span>
                                            {index + 1 + " "}
                                            <b
                                              onClick={() =>
                                                navigate("/companyPage", {
                                                  state: { id: item?.id },
                                                })
                                              }
                                            >
                                              {item.name + " "}
                                            </b>

                                          </span>

                                          <div
                                            style={{
                                              width: 100,
                                              height: 19,
                                              display: "flex",
                                            }}
                                          >
                                            {item.incidentCount + " incidents"}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>

                              {breachesAndViolationData?.esg_supply_chain_act &&
                                breachesAndViolationData.esg_supply_chain_act
                                  .length > 9 && (
                                  <div
                                    className="reactionAnalyses"
                                    onClick={() => {
                                      // setModalVisible(true);
                                      setBreachesViolationLimit(200);
                                    }}
                                  >
                                    <span className="reactionAnalysesSpan downl-btn">
                                      View All
                                    </span>
                                  </div>
                                )}

                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent violations
                                </span>
                              </div>
                              {activeTab == 1 &&
                                breachesAndViolationData?.most_frequent &&
                                breachesAndViolationData.most_frequent.length >
                                0 &&
                                breachesAndViolationData?.most_frequent?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                        value={val.value}
                                        dataKey={val.value}
                                      />
                                    );
                                  }
                                )}

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                  setModalData({
                                    ...modalData,
                                    voilation: "esg_supply_chain_act",
                                    start: networkData.start,
                                    end: networkData.end,
                                  });
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="2" tab={"UNGC"}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.un}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      UN global compact principles breaches
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {activeTab == "2" && totalIncidentCount()}{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent breaches
                                </span>
                              </div>
                              {ungcpBreaches?.data?.map((val, ind) => {
                                return (
                                  <UngcpBreaches
                                    networkData={networkData}
                                    activeTab={activeTab}
                                    type={val._id}
                                    list={val?.incidents?.slice(0, 4)}
                                    totalIncidentCount={val?.incidents?.length}
                                    dataKey={val._id}
                                  />
                                );
                              })}
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Total incidents on each principle
                                </span>
                              </div>
                              <>
                                <PrincipleBarChat
                                  series={BarMapping(ungcpBreaches?.data)}
                                  networkData={networkData}
                                />

                                <div className="ellips chart-bottom-legend-New">
                                  {HLEA?.map((data) => {
                                    return (
                                      <p className="legend">
                                        <span
                                          className="legendCircle"
                                          style={{ background: data.color }}
                                        />
                                        {data.tittle}
                                      </p>
                                    );
                                  })}
                                </div>
                              </>

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                  setModalData({
                                    ...modalData,
                                    voilation: "UNGCP_Breaches",
                                    start: networkData.start,
                                    end: networkData.end,
                                  });
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="3" tab={"PAI"}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.pia}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      Principal Adverse Impact (PAI) Violations
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {breachesAndViolationData.mostFrequentCount}{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of breaches
                                  </span>
                                </Row>
                                <div></div>
                              </div>

                              <div>
                                {activeTab == 3 &&
                                  breachesAndViolationData?.esg_PAI &&
                                  breachesAndViolationData?.esg_PAI.length >
                                  0 &&
                                  breachesAndViolationData?.esg_PAI?.map(
                                    (e, index) => (
                                      <div className="companiesDataDiv">
                                        <span>
                                          {index + 1 + " "}
                                          <b
                                            onClick={() =>
                                              navigate("/companyPage", {
                                                state: { id: e?.id },
                                              })
                                            }
                                          >
                                            {e.name + " "}
                                          </b>
                                        </span>

                                        <div className="Incident_on_ESG">
                                          {e.incidentCount + " incidents"}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>

                              {activeTab == 3 &&
                                breachesAndViolationData?.esg_PAI &&
                                breachesAndViolationData?.esg_PAI.length >
                                9 && (
                                  <div
                                    className="reactionAnalyses"
                                    onClick={() => {
                                      // setModalVisible(true);
                                      setBreachesViolationPaiLimit(200);
                                    }}
                                  >
                                    <span className="reactionAnalysesSpan downl-btn">
                                      View All
                                    </span>
                                  </div>
                                )}
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent breaches
                                </span>
                              </div>
                              {activeTab == 3 &&
                                breachesAndViolationData?.most_frequent &&
                                breachesAndViolationData?.most_frequent.length >
                                0 &&
                                breachesAndViolationData?.most_frequent?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                        dataKey={val.value}
                                      />
                                    );
                                  }
                                )}
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Total Number of incidents
                                </span>
                              </div>
                              <>
                                {activeTab == 3 &&
                                  breachesAndViolationData?.most_frequent &&
                                  breachesAndViolationData?.most_frequent
                                    .length > 0 &&
                                  !breachesAndViolationDataLoader && (
                                    <PAIBarChat
                                      series={BarMappingPAI(
                                        breachesAndViolationData?.most_frequent
                                      )}
                                      networkData={{ ...networkData, companies: [companyData.id], companyName: [companyData.name] }}
                                    />
                                  )}
                              </>
                              <div className="pia-bott-hover">
                                <p
                                  className="onhover-image"
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                >
                                  <span>
                                    <img
                                      className={isHovered}
                                      src={
                                        isHovered
                                          ? Images.newImageOnHover
                                          : Images.circleInfo
                                      }
                                      alt=""
                                    />
                                  </span>
                                  14 Principal Adverse Impact (PAI)
                                </p>

                                <div className="hover-content-ps">
                                  <div className="adverse-heading-ps">
                                    <h5>
                                      {" "}
                                      <span>
                                        <img src={Images.colorSky} />
                                      </span>
                                      14 Principal Adverse Impact (PAI)
                                    </h5>
                                  </div>
                                  <div className="number-of-impact-ps">
                                    <p>1. GHG emissions scope 1,2,3, total</p>
                                    <p>2. Carbon footprint</p>
                                    <p>
                                      3. GHG intensity of investee companies
                                    </p>
                                    <p>
                                      4. Exposure to companies active in the
                                      fossil fuel sector
                                    </p>
                                    <p>
                                      5. Share of non-renewable energy
                                      consumption and production
                                    </p>
                                    <p>
                                      6. Energy consumption intensity per high
                                      impact climate sector
                                    </p>
                                    <p>
                                      7. Activities negatively affecting
                                      biodiversity-sensitive areas
                                    </p>
                                    <p>8. Emissions to water</p>
                                    <p>
                                      9. Hazardous waste and radioactive waste
                                      ratio
                                    </p>
                                    <p>
                                      10. Violations of UN Global Compact
                                      principles and OECD guidelines{" "}
                                    </p>
                                    <p>
                                      11. Lack of processes and compliance
                                      mechanisms to monitor compliance with UN
                                      GC and OECD
                                    </p>
                                    <p>12. Unadjusted gender pay gap</p>
                                    <p>13. Board gender diversity</p>
                                    <p>14. Exposure to controversial weapons</p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                  setModalData({
                                    ...modalData,
                                    voilation: "esg_PAI",
                                    start: networkData.start,
                                    end: networkData.end,
                                  });
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="4" tab={"SDG"}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.sdg}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      UN global compact principles breaches
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {breachesAndViolationData.mostFrequentCount}{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of breaches
                                  </span>
                                </Row>
                                <div></div>
                              </div>

                              <div>
                                {activeTab == 4 &&
                                  breachesAndViolationData?.esg_SDG &&
                                  breachesAndViolationData?.esg_SDG.length >
                                  0 &&
                                  breachesAndViolationData?.esg_SDG?.map(
                                    (e, index) => (
                                      <div className="companiesDataDiv">
                                        <span>
                                          {index + 1 + " "}
                                          <b
                                            onClick={() =>
                                              navigate("/companyPage", {
                                                state: { id: e?.id },
                                              })
                                            }
                                          >
                                            {e.name + " "}
                                          </b>
                                        </span>

                                        <div className="Incident_on_ESG">
                                          {e.incidentCount + " incidents"}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>

                              {activeTab == 4 &&
                                breachesAndViolationData?.esg_SDG &&
                                breachesAndViolationData?.esg_SDG.length >
                                9 && (
                                  <div
                                    className="reactionAnalyses"
                                    onClick={() => {
                                      // setModalVisible(true);
                                      setBreachesViolationSdgLimit(200);
                                    }}
                                  >
                                    <span className="reactionAnalysesSpan downl-btn">
                                      View All
                                    </span>
                                  </div>
                                )}

                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent breaches
                                </span>
                              </div>
                              {activeTab == 4 &&
                                breachesAndViolationData?.most_frequent &&
                                breachesAndViolationData?.most_frequent.length >
                                0 &&
                                breachesAndViolationData?.most_frequent?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                        dataKey={val.value}
                                      />
                                    );
                                  }
                                )}
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Total Number of incidents on each goal
                                </span>
                              </div>
                              <>
                                {activeTab == 4 &&
                                  breachesAndViolationData?.most_frequent &&
                                  breachesAndViolationData?.most_frequent
                                    .length > 0 &&
                                  !breachesAndViolationDataLoader && (
                                    <SDGBarChat
                                      series={BarMappingSDG(
                                        breachesAndViolationData?.most_frequent
                                      )}
                                      networkData={{ ...networkData, companies: [companyData.id], companyName: [companyData.name] }}

                                    />
                                  )}
                              </>
                              <div className="pia-bott-hover">
                                <p
                                  className="onhover-image"
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                >
                                  <span>
                                    <img
                                      className={isHovered}
                                      src={
                                        isHovered
                                          ? Images.newImageOnHover
                                          : Images.circleInfo
                                      }
                                      alt=""
                                    />
                                  </span>
                                  17 Sustainable Development Goals
                                </p>

                                <div className="hover-content-ps">
                                  <div className="adverse-heading-ps">
                                    <h5>
                                      {" "}
                                      <span>
                                        <img src={Images.colorSky} />
                                      </span>
                                      17 Sustainable Development Goals
                                    </h5>
                                  </div>
                                  <div className="number-of-impact-ps">
                                    <p>1. No poverty</p>
                                    <p>2. Zero hunger</p>
                                    <p>3. Good health and well-being</p>
                                    <p>4. Quality education</p>
                                    <p>5. Gender equality</p>
                                    <p>6. Clean water and sanitation</p>
                                    <p>7. Affordable and clean energy</p>
                                    <p>8. Decent work and economic growth</p>
                                    <p>
                                      9. Industry, innovation and infrastructure
                                    </p>
                                    <p>10. Reduced inequalities </p>
                                    <p>
                                      11. Sustainable cities and communities
                                    </p>
                                    <p>
                                      12. Responsible consumption and production
                                    </p>
                                    <p>13. Climate action</p>
                                    <p>14. Life below water</p>
                                    <p>15. Life on land</p>
                                    <p>
                                      16. Peace, justice and strong institutions
                                    </p>
                                    <p>17. Partnership for the goals</p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                  setModalData({
                                    ...modalData,
                                    voilation: "esg_SDG",
                                    start: networkData.start,
                                    end: networkData.end,
                                  });
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </Collapse.Panel>

                  <Collapse.Panel
                    id="9Key"
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          9
                        </span>
                        Regulatory & Legal Actions
                      </h4>
                    }
                    key="9"
                  >
                    <section>
                      <div
                        className="container most-sec-over g-0 containerWidth"
                        style={{ padding: 20 }}
                      >
                        <div className="UNclass">
                          <img
                            className="sq104px"
                            src={Images.regulatorLegal}
                            alt="RL"
                          />
                          <div className="d-flex row">
                            <Row>
                              <span className="unSpan">
                                Regulatory & Legal Actions
                                <img
                                  className="hover-page"
                                  style={{ marginLeft: 5 }}
                                  src={Images.circleInfo}
                                  alt=""
                                />
                              </span>
                            </Row>

                            <div className="d-flex">
                              <span className="subtitle1 color05476F pl-16">
                                {companyData?.name}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="CaptionRegularFit pl-16 pt-1">{`Last updated on ${moment(
                                companyData.createdAt
                              ).format("MMM,DD YYYY")}`}</span>
                              <ToolTip placement="left" title={"Sort"}>
                                <img
                                  className="hover-page"
                                  onClick={() => {
                                    dispatch(
                                      getRegulatoryLegalAction({
                                        ...networkData,
                                        id: id,
                                        sort: "datetime",
                                        order: legalOrder ? 1 : -1,
                                      })
                                    );
                                    setLegalOrder(!legalOrder);
                                  }}
                                  style={{ marginLeft: 5 }}
                                  src={Images.sortButton}
                                  alt=""
                                />
                              </ToolTip>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          {regulatoryLegalActionsData?.list?.map((val, i) => {
                            return (
                              <RegulatoryLegalActionsCard
                                index={i}
                                {...val.legal_and_regulatory_actions
                                }
                                id={val.id}
                              />
                            );
                          })}
                        </div>
                        <div className="d-flex flex-column justify-content">
                          <span className="unSpanCount pt-2 pb-0">
                            Regulatory & Legal Actions Timeline
                          </span>
                          {Object.keys(regulatoryLegalActionsData?.timelineList)
                            ?.length > 0 && (
                              <TimeLine
                                {...networkData}
                                id={id}
                                data={regulatoryLegalActionsData?.timelineList}
                              />
                            )}
                        </div>
                      </div>
                    </section>
                    <section>
                      <div
                        className="container most-sec-over g-0 mt-4 containerWidth"
                        style={{ padding: 20 }}
                      >
                        <div className="UNclass">
                          <img
                            className="sq104px"
                            src={Images.regulatorLegal}
                            alt="RL"
                          />
                          <div className="d-flex row">
                            <Row>
                              <span className="unSpan">
                                Regulatory Fines & Penalties
                                <img
                                  className="hover-page"
                                  style={{ marginLeft: 5 }}
                                  src={Images.circleInfo}
                                  alt=""
                                />
                              </span>
                            </Row>

                            <div className="d-flex">
                              <span className="subtitle1 color05476F pl-16">
                                {companyData?.name}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="CaptionRegularFit pl-16 pt-1">{`Last updated on ${moment().format(
                                "MMM,DD YYYY"
                              )}`}</span>
                              <ToolTip placement="left" title={"Sort"}>
                                <img
                                  className="hover-page"
                                  style={{ marginLeft: 5 }}
                                  src={Images.sortButton}
                                  alt=""
                                  onClick={() => {
                                    dispatch(
                                      getRegulatoryFinesPenaltiesAction({
                                        ...networkData,
                                        id: id,
                                        sort: "datetime",
                                        order: order ? 1 : -1,
                                      })
                                    );
                                    setOrder(!order);
                                  }}
                                />
                              </ToolTip>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4">
                          {getRegulatoryFinesPenaltiesList.map((val, i) => {
                            return (
                              <RegulatoryFinesPenaltiesCard
                                id={val.id}
                                {...val.penalties_and_fines}
                                index={i}
                              />
                            );
                          })}
                          <div></div>
                        </div>
                        {getRegulatoryFinesPenaltiesList.length > 0 ? (
                          <>
                            <div className="reactionAnalyses">
                              <span className="reactionAnalysesSpan downl-btn">
                                View More
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="noDataLayout">
                            <span className="noDataText">
                              {" "}
                              There are no significant fines or penalties{" "}
                            </span>
                          </div>
                        )}
                      </div>
                    </section>
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={<ModalHeader title={"Add to Watchlist"} />}
        centered
        closeIcon={<ModalCloseButton />}
        className="model-watch"
        open={modal2Open}
        onCancel={() => setModal2Open(false)}
        footer={[
          <button className="can-btn" onClick={() => setModal2Open(false)}>
            Cancel
          </button>,
          <button className="rest-btn" onClick={() => setModal2Open(false)}>
            Done
          </button>,
        ]}
      >
        <div className="empty-watch">
          <div className="first-watch">
            <h5>Watchlist 1</h5>
            <p>Watchlist is empty</p>
          </div>
          <div className="plus-add-btn">
            <button type="button" onclick={() => { }}>
              <img src={Images.plus} />
              Add
            </button>
          </div>
        </div>

        <div className="empty-watch">
          <div className="first-watch">
            <h5>Watchlist 2</h5>
            <p>3 companies, 8 locations, 1 industry</p>
          </div>
          <div className="plus-add-btn tcik-added">
            <button type="button">
              <img src={Images.check} />
              Added
            </button>
          </div>
        </div>

        <div className="list-nam-md">
          <label>List Name</label>
          <div className="text-fild">
            <input type="text" placeholder="Watchlist 2" />
            <button type="button">
              <img src={Images.plusWhite} />
              Create
            </button>
          </div>
        </div>
      </Modal>
      {rootLevelIncidentModalItem ? (
        <Modal
          className="incidentModal"
          open={rootLevelIncidentModalItem}
          onCancel={() => {
            setRootLevelIncidentModalItem(false);
            dispatch(
              incindentAction({
                ...networkData,
                companies: [id],
                sort: "discovery_time",
                order: -1,
              })
            );
          }}
          footer={null}
          width={1600}
          centered
        >
          <IncidentsList
            payloadRootLevel={topLevelIncidentListModalPayload}
            isComeFromModal={true}
          />
        </Modal>
      ) : null}
      {modalVisible && companyData ? (
        <IncidentsListModal
          setModalVisible={(e) => {
            setModalVisible(e);
            setModalData(null);
            if (!e) {
              networkData = { ...networkData, esg_categories: [] };
            }
          }}
          networkData={
            modalData
              ? {
                ...modalData,
                companies: [id],
                companyName: [companyData?.name],
              }
              : {
                ...networkData,
                companies: [id],
                companyName: [companyData?.name],
              }
          }
        />
      ) : null}
      {timelineIndidentList ? (
        <Modal
          className="incidentModal"
          open={timelineIndidentList}
          onCancel={() => setTimelineIndidentList(false)}
          footer={null}
          width={1600}
          centered
        >
          <BubleIncidentsList
            bubbleData={true}
            incidentIdlist={incidentIdArray}
          />
        </Modal>
      ) : null}

      {potfolioModel &&
        (portfolioList?.length == 0 ? (
          <Modal
            title={"Create a portfolio first..."}
            className="potfolio-modal"
            centered
            onCancel={() => {
              setPotfolioModel(false);
            }}
            closable={false}
            open={potfolioModel}
            destroyOnClose={true}
            footer={[]}
          >
            <div style={{ paddingRight: "25px", paddingLeft: "25px" }}>
              <p>
                To add a company to your portfolio, you need to create a
                portfolio first.
              </p>
              <div
                style={{
                  display: "flex",

                  justifyContent: "center",
                }}
              >
                <img className="potfolio-img" src={ImageConst.potfolio} />
              </div>
              <p>
                A portfolio helps you organize and track your investments
                effectively.
              </p>
              <div className="create-potfolio-btn">
                <button
                  className="can-btn"
                  onClick={() => setPotfolioModel(false)}
                >
                  Cancel
                </button>

                <button
                  className="rest-btn"
                  onClick={() => {
                    navigate("/createPortfolio", {
                      state: {
                        companyName: companyData?.name,
                        companyId: companyData?.id,
                        addStep: true,
                        screen: "/companyPage",
                      },
                    });
                  }}
                >
                  Create New Portfolio
                </button>
              </div>
            </div>
          </Modal>
        ) : (
          <Modal
            title={<ModalHeader title={"Add to Portfolio"} />}
            centered
            closeIcon={<ModalCloseButton />}
            className="model-watch"
            open={potfolioModel}
            onCancel={() => setPotfolioModel(false)}
            footer={[
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "15px",
                  }}
                >
                  <div className="plus-btn-text-ps">
                    <img src={Images.plus} />
                    <button
                      className="can-btn-goto"
                      onClick={() =>
                        navigate("/createPortfolio", {
                          state: {
                            companyName: companyData?.name,
                            companyId: companyData?.id,
                            addStep: true,
                            screen: "/companyPage",
                          },
                        })
                      }
                    >
                      Create New Portfolio
                    </button>
                  </div>
                </div>
                <div>
                  <button
                    className="can-btn"
                    onClick={() => setPotfolioModel(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="rest-btn"
                    onClick={() => setPotfolioModel(false)}
                  >
                    Done
                  </button>
                </div>
              </div>,
            ]}
          >
            {portfolioList.map((item) => (
              <div className="empty-watch">
                <div className="first-watch">
                  <h5>{item.name}</h5>
                  <p>{`${item?.holdings?.length} companies`}</p>
                </div>
                <div
                  className={
                    item?.holdings?.filter(
                      (item) => item.company == companyData.name
                    ).length > 0
                      ? "plus-added-btn"
                      : "plus-add-btn"
                  }
                >
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/createPortfolio", {
                        state: {
                          portfolioId: item?._id,
                          editStep: 1,
                          screen: "/companyPage",
                          companyName: companyData?.name,
                          companyId: companyData?.id,
                        },
                      });
                    }}
                    disabled={
                      item?.holdings?.filter(
                        (item) => item.company == companyData.name
                      ).length > 0 || false
                    }
                  >
                    {item?.holdings?.filter(
                      (item) => item.company == companyData.name
                    ).length > 0 ? (
                      <img src={Images.check} />
                    ) : (
                      <img src={Images.plus} />
                    )}
                    {item?.holdings?.filter(
                      (item) => item.company == companyData.name
                    ).length > 0
                      ? "Added"
                      : "Add"}
                  </button>
                </div>
              </div>
            ))}
          </Modal>
        ))}
    </div>
  );
};

const BreadcrumbComponent = ({ companyName }) => {
  const navigate = useNavigate();
  const { searchCompaniesData } = useSelector((state) => state?.global);
  const { handleSearch, paginationHandler } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 10 }
  );
  const [countryName, setCountryName] = useState(null);
  const handleSearchOperation = (SelectedData) => {
    if (searchCompaniesData?.data?.length !== 0) {
      navigate("/companyPage", { state: { id: SelectedData.value } });
    }
  };
  const [companyList, setCompanyList] = useState([])
  useEffect(() => {
    if (searchCompaniesData.data !== undefined) {
      setCompanyList([...companyList, ...searchCompaniesData?.data])
    }
  }, [searchCompaniesData?.data])
  return (
    <div
      className="container g-0 mt-2 d-flex"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          paddingRight: "30px",
        }}
      >
        <Breadcrumb separator="">
          <Breadcrumb.Item key="1" className="apple-top-bread">
            <Link to="/home">Dashboard </Link>
            {/* <img src={Images.chevronRightWhite} /> */}
            <span>/</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="2" className="apple-top-bread">
            <Link to="/home"> Search result </Link>
            <span>/</span>
            {/* <img src={Images.chevronRightWhite} /> */}
          </Breadcrumb.Item>
          <Breadcrumb.Item key="3" className="apple-top-bread ">
            <a> {companyName}</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="autocomplete-input-search"
          style={{ backgroundColor: "#ffffff" }}
        >
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={500}
            className="serchmedia-icon"
            options={renderOptions(companyList)}
            // options={companyList}

            size="large"
            onSearch={(e) => {
              if (autoCompletePaginationOffset > 0) {
                autoCompletePaginationOffset = 0
              }
              setCompanyList(pre => [])
              handleSearch(e)
            }}
            onBlur={() => {
              if (autoCompletePaginationOffset > 0) {
                autoCompletePaginationOffset = 0
              }
              setCompanyList(pre => [])
            }}
            value={countryName}
            onSelect={(e, selectedOption) => {
              const { dataValue } = selectedOption;
              setCountryName(dataValue);
              handleSearchOperation(selectedOption);
            }}
            onChange={(e, i) => {
              setCountryName(e);
            }}
            onPopupScroll={(e) => {
              if (
                e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 1
              ) {
                autoCompletePaginationOffset =
                  autoCompletePaginationOffset + 10;
                const payload = { offset: autoCompletePaginationOffset, limit: 10 }
                paginationHandler(payload);
              }
            }}
          >
            <Input.Search size="large" placeholder="Search company" />
          </AutoComplete>
        </div>

        {/* <Input
          className="SearchInput"
          placeholder="Search company"
          prefix={<SearchOutlined />}
        /> */}
      </div>
    </div>
  );
};

const MapItem = ({ color, name }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: ".6vw",
        height: ".6vw",
        borderRadius: "100%",
        marginRight: ".5vw",
        backgroundColor: color,
      }}
    ></div>
    <div style={{ fontSize: ".8rem" }}>{name}</div>
  </div>
);

const renderOptions = (searchCompaniesData) => {
  let renderedOptions = searchCompaniesData?.map((option, idx) => {
    return {
      value: option?.id || "",
      dataValue: option?.label || "",
      tickers: option?.tickers || "",
      key: option?.id || "",
      isin: option?.isin || "",
      label: (
        <DoneItem
          searchString={lastSearchString}
          companyName={option?.label || ""}
          ticker={option?.tickers || ""}
          compId={option?.id || ""}
          key={option?.id || ""}
          isin={option?.isin || ""}
          alter_names={option?.alter_names?.length > 0 ? option?.alter_names[0] : ""}
          alter_name={option?.alter_name || ""}
        />
      ),
    }
  })
  return renderedOptions;
};
const DoneItem = ({
  searchString,
  companyName,
  companyIcon,
  ticker,
  key,
  compId = "",
  isin = "",
  alter_name = "",
}) => {
  const highlightedCompanyName = highlightMatch(searchString, companyName?.slice(0, 20), "company");
  const companyEllipse = companyName?.length > 25 ? "..." : ""
  const highlightedTickerSymbol = highlightMatch(searchString, ticker.symbol, "ticker");
  const highlightedTickerID = highlightMatch(searchString, ticker.tickerId, "ticker");
  const highlightedAlter_names = alter_name?.length > 0 ? highlightMatch(searchString, alter_name?.slice(0, 10), "alter") : "";
  const alterEllipse = alter_name?.length > 10 ? "..." : ""
  let highlightedIsin = highlightMatch(searchString, isin, "isin");
  const countryName = ticker?.country?.length > 0 && countries.getName(ticker?.country, "en") || ""
  const countryCode = countryName?.length > 0 && countries.getAlpha2Code(countryName, 'en') || ""
  return (
    <div
      key={key}
      style={{
        display: "flex",
        paddingBottom: 5,
        justifyContent: "space-between",
        alignItems: "end",
        borderBottom: ".5px solid rgb(216,218,224)",
      }}
    >
      <div style={{ width: "100%", height: "35px", gap: "4px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ width: "12%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={`https://logos-svg.komo.systems/${compId}.png` || companyIcon}
            alt="companyImage"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ImageConst.Apple;
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "65%", }}>
          <div
            style={{
              fontSize: "13px",
              fontFamily: "inter",
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: highlightedCompanyName?.trim() + companyEllipse }} />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "10px",
            color: "#4E515C",
            fontFamily: "inter",
          }}>
            <div style={{ overflow: "hidden" }} >
              {countryCode && <ReactCountryFlag countryCode={countryCode} svg={countryName} style={{ width: 12, height: 12, borderRadius: "100%" }} />}
            </div>
            {ticker?.symbol?.length > 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
              {" : "}
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : ticker?.symbol?.length > 0 && ticker?.tickerId?.length == 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
            </div> : ticker?.symbol?.length == 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : null}
          </div>
        </div>
        <div style={{
          width: "28%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          fontSize: "10px",
          color: "#4E515C",
          fontFamily: "inter",
          textAlign: "end"
        }}>
          <span dangerouslySetInnerHTML={{ __html: highlightedAlter_names?.trim() + alterEllipse }} />
          <span dangerouslySetInnerHTML={{ __html: highlightedIsin }} />
        </div>
      </div>
    </div >
  );
};
export default Dashboard;
