import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import RecentIncidentIcon from "./RecentIncidentsIcon";
import IncidentItem from "./incidentItem";

function RecentIncidents({ data, onViewAllIncidents }) {
  const theme = useTheme();
  return (
    <Box>
      <Stack direction="row" gap={1} alignItems="center" marginBottom={1}>
        <RecentIncidentIcon />
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          Recent Incidents
        </Typography>
        {data.recentIncidents.length > 0 ? (
          <Button
            sx={{ color: theme.palette.gray[300] }}
            onClick={onViewAllIncidents}
          >
            View All
          </Button>
        ) : null}
      </Stack>
      <Stack
        gap="16px"
        padding="12px"
        sx={{
          backgroundColor: theme.palette.screenBackground.main,
          borderRadius: "6px",
        }}
      >
        {data?.recentIncidents?.map((recentIncident, index) => (
          <IncidentItem
            key={recentIncident?.title}
            title={recentIncident.title}
            id={recentIncident.id}
            index={index + 1}
            severity={recentIncident?.severity}
            date={recentIncident?.date}
          />
        ))}
      </Stack>
    </Box>
  );
}
export default RecentIncidents;
