import React, { useEffect, useState } from "react";
import { dispatch } from "../../../Utils/dispatchNavigate";
import ImageConst from "../../../Themes/ImageConstant";

/**
 * Description call api on search value
 * @param {string} action action name
 * @param {object} obj other key value object if need to add
 * @returns null
 */

const Search = ({ action, obj, cb = () => {} }) => {
  const [searchTimeout, setSearchTimeout] = useState(null);
  useEffect(() => {
    // Clear the search timeout when the component unmounts
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTimeout]);

  return (
    <div className="serch-bar-in">
      <span>
        <img className="me-2" src={ImageConst.sear} />
      </span>
      <input
        type="text"
        placeholder="Search keywords"
        onChange={(e) => {
          const data = e.target.value.trim().toLowerCase();
          clearTimeout(searchTimeout);
          const newSearchTimeout = setTimeout(() => {
            let locations = localStorage.getItem("location");
            let companies = localStorage.getItem("company");
            let esg_factors = localStorage.getItem("ESG_factor");

            locations = JSON.parse(locations);
            companies = JSON.parse(companies);
            esg_factors = JSON.parse(esg_factors);

            cb(data);
            dispatch(
              action({
                // ...obj,
                start: "",
                end: "",
                search: data,
                companies: [],
                continent_codes: [],
                countries: [],
                esg_factors: [],
                esg_SDG: [],
                esg_PAI: [],
                industries: [],
                offset: 0,
              })
            );
          }, 500); // 1000 milliseconds (adjust as needed)
          setSearchTimeout(newSearchTimeout);
        }}
      />
    </div>
  );
};

export default Search;
