const appconstant = {
  Environment: "Environment",
  Social: "Social",
  Governance: "Governance",
  /*******URLs*******/
  URL: {
    // SERVER_URL: "http://192.168.3.121:9056/api/v1/user", //local
    // imageUrl: "http://192.168.3.121:9056/", //local
    // SERVER_URL: "http://192.168.3.179:9011/api/v1/user", //local
    // imageUrl: "http://192.168.3.179:9011/", // local
    // SERVER_URL: "http://35.193.180.56:3000/api/v1/user", // WORKING Staging
    // imageUrl: "http://35.193.180.56:3000/", // WORKING Staging
    SERVER_URL: "https://backend.syenah.ai/api/v1/user", // WORKING LIVE
    imageUrl: "https://backend.syenah.ai/", // WORKING LIVE
  },

  placeHolder: { entrPass: "Enter your password" },
  lable: { pass: "Password" },

  /*****Function Constants*****/
  functionConstants: {
    areYouSure: (msg) =>
      `Are you sure, you want to ${msg.toLowerCase()} this user?`,
  },

  /*****ROUTES******/
  RoutesPath: {
    login: "/",
    home: "/home",
    dashboard: "/dashboard",
    confirmPass: "/confirmemail",
  },

  //*********STATIC PAYLOAD************/
  staticPayload: {
    deviceType: "web",
    deviceToken: null,
  },

  xAxis: [
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
  ],

  principle: {
    "Principle 1":
      "Businesses should support and respect the protection of internationally proclaimed human rights; and",
    "Principle 2":
      "Make sure that they are not complicit in human rights abuses.",
    "Principle 3":
      "Businesses should uphold the freedom of association and the effective recognition of the right to collective bargaining;",
    "Principle 4":
      "The elimination of all forms of forced and compulsory labour;",
    "Principle 5": "The effective abolition of child labour; and",
    "Principle 6":
      "The elimination of discrimination in respect of employment and occupation.",
    "Principle 7":
      "Businesses should support a precautionary approach to environmental challenges;",
    "Principle 8":
      "Undertake initiatives to promote greater environmental responsibility; and",
    "Principle 9":
      "Encourage the development and diffusion of environmentally friendly technologies.",
    "Principle 10":
      "Businesses should work against corruption in all its forms, including extortion and bribery.",
  },

  impact: {
    "Impact 1": "GHG emissions scope 1,2,3, total",
    "Impact 2": "Carbon footprint",
    "Impact 3": "GHG intensity of investee companies",
    "Impact 4": "Exposure to companies active in the fossil fuel sector",
    "Impact 5": "Share of non-renewable energy consumption and production",
    "Impact 6": "Energy consumption intensity per high impact climate sector",
    "Impact 7": "Activities negatively affecting biodiversity-sensitive areas",
    "Impact 8": "Emissions to water",
    "Impact 9": "Hazardous waste and radioactive waste ratio",
    "Impact 10":
      "Violations of UN Global Compact priciples and OECD guidelines",
    "Impact 11":
      "Lack of processes and compliance mechanisms to monitor compliance with UN GC and OECD",
    "Impact 12": "Unadjusted gender pay gap",
    "Impact 13": "Board gender diversity",
    "Impact 14": "Exposure to controversial weapons",
  },

  goals: {
    "Goal 1": "No poverty",
    "Goal 2": "Zero hunger",
    "Goal 3": "Good health and well-being",
    "Goal 4": "Quality education",
    "Goal 5": "Gender equality",
    "Goal 6": "Clean water and sanitation",
    "Goal 7": "Affordable and clean energy",
    "Goal 8": "Decent work and economic growth",
    "Goal 9": "Industry, innovation and infrastructure",
    "Goal 10": "Reduced inequalities",
    "Goal 11": "Sustainable cities and communities",
    "Goal 12": "Responsible consumption and production",
    "Goal 13": "Climate action",
    "Goal 14": "Life below water",
    "Goal 15": "Life on land",
    "Goal 16": "Peace, justice and strong institutions",
    "Goal 17": "Partnership for the goals",
  },

  //***********MESSAGES**********
  info: "We use AI to determine the severity of an incident based on its content. However, since it is often a subjective matter, this description should not be solely relied on. It is intended as a helpful clue to assist users.",
};
export const cardColor = {
  FIRST: "linear-gradient(225deg, #C6E3FF 4.52%, #E0D8F6 96.07%)",
  SECOND: "linear-gradient(225deg, #F5E1F3 4.52%, #FFEFBF 96.07%)",
  THIRD: "linear-gradient(225deg, #D1EBD8 4.52%, #E5F3D7 96.07%)",
  FOURTH: "linear-gradient(42deg, #000046 0%, #1CB5E0 98.32%)",
  FIFTH: "linear-gradient(42deg, #F64F59 0%, #12C2E9 98.32%)",
  SIX: "linear-gradient(42deg, #3E5151 0%, #DECBA4 98.32%)",
};

export const portfolioCardColor = [
  {
    id: 1,
    color: "#05476F",
    background: cardColor.FIRST,
    Portfolio_Color: "#2191FB",
    ECG__Color: "#F28C28",
  },
  {
    id: 2,
    color: "#794614",
    background: cardColor.SECOND,
    Portfolio_Color: "#2191FB",
    ECG__Color: "#F28C28",
  },
  {
    id: 3,
    color: "#0A3E19",
    Portfolio_Color: "#2191FB",
    background: cardColor.THIRD,
    ECG__Color: "#F28C28",
  },
  {
    id: 4,
    color: "#fff",
    Portfolio_Color: "#B1D8FE",
    background: cardColor.FOURTH,
    ECG__Color: "#F8C694",
  },
  {
    id: 5,
    color: "#fff",
    Portfolio_Color: "#B1D8FE",
    background: cardColor.FIFTH,
    ECG__Color: "#F8C694",
  },
  {
    id: 6,
    color: "#fff",
    Portfolio_Color: "#B1D8FE",
    background: cardColor.SIX,
    ECG__Color: "#F8C694",
  },
];

export const oneDayInterval = [
  { value: "1Hr", label: "1Hr" },
  { value: "2Hr", label: "2Hr" },
  { value: "3Hr", label: "3Hr" },
  { value: "4Hr", label: "4Hr" },
];

export const oneWeekInterval = [
  { value: "1Hr", label: "1Hr" },
  { value: "2Hr", label: "2Hr" },
  { value: "3Hr", label: "3Hr" },
  { value: "4Hr", label: "4Hr" },
  { value: "8Hr", label: "8Hr" },
  { value: "16Hr", label: "16Hr" },
];

export const oneMonthInterval = [
  { value: "2Hr", label: "2Hr" },
  { value: "3Hr", label: "3Hr" },
  { value: "4Hr", label: "4Hr" },
  { value: "8Hr", label: "8Hr" },
  { value: "16Hr", label: "16Hr" },
  { value: "1D", label: "1D" },
];

export const threeMonthInterval = [
  { value: "4Hr", label: "4Hr" },
  { value: "8Hr", label: "8Hr" },
  { value: "16Hr", label: "16Hr" },
  { value: "1D", label: "1D" },
  { value: "1W", label: "1W" },
];

export const oneYearInterval = [
  { value: "8Hr", label: "8Hr" },
  { value: "16Hr", label: "16Hr" },
  { value: "1D", label: "1D" },
  { value: "1W", label: "1W" },
  { value: "1M", label: "1M" },
];

export const allInterval = [
  { value: "1Hr", label: "1Hr" },
  { value: "2Hr", label: "2Hr" },
  { value: "3Hr", label: "3Hr" },
  { value: "4Hr", label: "4Hr" },
  { value: "8Hr", label: "8Hr" },
  { value: "16Hr", label: "16Hr" },
  { value: "1D", label: "1D" },
  { value: "1W", label: "1W" },
  { value: "1M", label: "1M" },
];

export default appconstant;
