import React, { useEffect, useRef, useState } from "react";
import { incidentMapViewStateAction } from "../../Redux/Action/incidents";
import { useDispatch } from "react-redux";
import mapboxgl from "mapbox-gl";
import IncidentsListModal from "../../Screens/IncidentsListModal";
import ReactDOM from "react-dom";
// import HoverComponent from './HoverComponent'

const envImage = require("../../Assets/icon/Env.node.png");
const socialImage = require("../../Assets/icon/Social.node.png");
const govImage = require("../../Assets/icon/Gov.node.png");
const criticalImage = require("../../Assets/icon/critical.node.png");
const highImage = require("../../Assets/icon/high.node.png");
const mediumImage = require("../../Assets/icon/moderate.node.png");
const lowImage = require("../../Assets/icon/low.node.png");

let incidentId = "";
let incidentLength = 0;
let popUpTime;
let industries_Ids = [];

const category_color = {
  social: "#0195CE",
  environmental: "#6EBD20",
  governance: "#653DD4",
  critical: "#800020",
  high: "#FF0000",
  moderate: "#FF7518",
  minor: "#FFD700"
}

const popupHandler = (popup, out, popin) => {
  if (out) {
    incidentId = "";
    incidentLength = 0;
    clearInterval(popUpTime);
    popup.remove();
  } else if (popin) {
    clearInterval(popUpTime);
  } else {
    clearInterval(popUpTime);
    popUpTime = setTimeout(() => {
      incidentId = "";
      popup.remove();
    }, 1500);
  }
};

const getHtmlHover = (incidentsArray, type = null, category) => {
  if (incidentsArray.length === 0) return "";
  const firstDateString = incidentsArray[0].discovery_time;
  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const date = new Date(firstDateString);
  const firstDateFormattedDate = date.toLocaleDateString("en-US", options);
  let withColorIncidents = [];


  if (type == "esgCategory") {
    withColorIncidents = incidentsArray
      .slice(0, 3)
      .map((i) => ({
        ...i,
        color: category ? category_color[category] :
          i["esg_categories"][0].toLowerCase() === "social"
            ? "#0195CE"
            : i["esg_categories"][0].toLowerCase() === "environmental"
              ? "#6EBD20"
              : "#653DD4",
      }));
  } else {
    withColorIncidents = incidentsArray
      .slice(0, 3)
      .map((i) => ({
        ...i,
        color: category ? category_color[category] :
          i["severity"].toLowerCase() === "critical"
            ? "#800020"
            : i["severity"].toLowerCase() === "high"
              ? "#FF0000"
              :
              i["severity"].toLowerCase() === "moderate"
                ? "#FF7518"
                : i["severity"].toLowerCase() === "minor"
                  ? "#FFD700"
                  : "#FFD700",
      }));
  }
  const incidentsContent = withColorIncidents
    .map(
      (incident) => `
        <div>
         <div id="textItem" itemId=${incident.id} class="TextItem" style="display:flex; flex-direction:row; margin-bottom:8px; cursor:pointer">
         <div class="category-box" style='width: 6%;'>
         <div class="category-box" style='background-color: ${incident?.color};width: 12px; height: 12px; margin-top:2px; border-radius:100%;'>
            </div>
         </div>
            <div style='margin-left:10px; color:##676A73; font-wight:600; font-size:15px;'>${incident.title}</div>
         </div>
       </div>
      `
    )
    .join("");

  const popupContent = `
      <div id="container" class="popup-container">
        <div  style="display:flex; flex-direction:row; align-items: center; margin-bottom:8px;justify-content:space-between;">
          <div class="category-box" style='color:##676A73; font-wight:600; font-size:15px;margin-bottom:6px;'>${firstDateFormattedDate}</div>
          <h6 id="viewAllButton" style='margin-left: 10px; margin-right: 10px; cursor:pointer; color:#0195CE'>${incidentsArray?.length > 1 ? "View All" : ""
    }</h6>
        </div>
        <div id="popup" class="popup-info">
          ${incidentsContent}
        </div>
      </div>
          `;
  return popupContent;
};

const FullMap = ({ data, width, height, zoom, type, dataActiveType, path, activeCategory }) => {
  const dispatch = useDispatch();
  const location = window.location.pathname;
  const mapContainerRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    return () => {
      if (location === "/fullMap") {
        dispatch(incidentMapViewStateAction("ESG Category"));
      }
    };
  }, [dispatch, location]);


  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZGVmdHNvZnRtYXAiLCJhIjoiY2xvaWdkZ3QxMWozZzJrcGx3dmh5b3BiYyJ9.ejTkWavtagG42CTeEKNjwQ";
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      zoom: zoom || 0.3,
      latitude: 40.67,
      longitude: -103.59,
      maxZoom: 6,
      maxBounds: [
        [-180, -90], // Southwest coordinates
        [180, 90],
      ],

      style: "mapbox://styles/mapbox/light-v11",
      projection: "mercator",
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on("mouseenter", "earthquake_label", (e) => {
      if (location == "/fullMap") {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { color, incidents, } = e.features[0].properties;
        const incidentsArray = incidents ? JSON.parse(incidents) : [];
        const esgCategoryColors = ["#6EBD20", "#2191FB", "#653DD4"]
        const popupContent = getHtmlHover(
          incidentsArray,
          esgCategoryColors.includes(color) ? "esgCategory" : "severity",
          activeCategory
        );

        popup.setLngLat(coordinates).setHTML(popupContent).addTo(map);
        const viewAllButton = document.getElementById("viewAllButton");
        const popupBox = document.getElementById("container");
        const textItem = document.getElementsByClassName("TextItem");

        Array.from(textItem).forEach((textItem) => {
          textItem.addEventListener("mouseover", (e) => {
            const enteredItemId = e.currentTarget.getAttribute("itemId");
            incidentId = enteredItemId;
          });
        });
        Array.from(textItem).forEach((textItem) => {
          textItem.addEventListener("click", (e) => {
            window.open(`/incidentdetails?${incidentId}`, "_blank");
          });
        });

        popupBox.addEventListener("mouseenter", (event) => {
          popupHandler(popup, false, true);
        });

        popupBox.addEventListener("mouseleave", (event) => {
          popupHandler(popup, true);
        });
        viewAllButton.addEventListener("click", viewAllFunction);
      }
    });

    map.on("mouseleave", "earthquake_label", () => {
      if (!incidentLength?.length > 1) {
        incidentId = "";
        incidentLength = 0;
        popup.remove();
      } else {
        incidentId = "";
        popupHandler(popup);
      }
    });
    map.on("click", function (e) {
      if (incidentId?.length > 0) {
        window.open(`/incidentdetails?${incidentId}`, "_blank");
      }
    });

    if (type !== "fullMap") {
      if (path !== "incidentDetails") {
        map.scrollZoom.disable();
        map.dragPan.disable();
      }
      map.addControl(new mapboxgl.NavigationControl());
    }

    if (dataActiveType === "ESG Category") {
      const mag2 = ["all", ["==", ["get", "category"], "environment"]];
      const mag3 = ["all", ["==", ["get", "category"], "social"]];
      const mag4 = ["all", ["==", ["get", "category"], "governance"]];

      const colors = ["#6EBD20", "#2191FB", "#653DD4", "#fc4e2a"];

      map.on("load", () => {
        map.loadImage(envImage, function (error, environmentIcon) {
          if (error) throw error;
          map.loadImage(socialImage, function (error, socialIcon) {
            if (error) throw error;
            map.loadImage(govImage, function (error, goverenerIcon) {
              if (error) throw error;

              map.addImage("environment", environmentIcon);
              map.addImage("social", socialIcon);
              map.addImage("goverener", goverenerIcon);

              map.addSource("earthquakes", {
                type: "geojson",
                data: data,
                cluster: true,
                clusterRadius: 60,
                clusterProperties: {
                  incidents: ["concat", ["concat", ["get", "incidents"], ","]],
                  mag2: ["+", ["case", mag2, 1, 0]],
                  mag3: ["+", ["case", mag3, 1, 0]],
                  mag4: ["+", ["case", mag4, 1, 0]],
                },
              });

              map.addLayer({
                id: "earthquake_label",
                type: "symbol",
                source: "earthquakes",
                filter: ["!=", "cluster", true],
                layout: {
                  "icon-image": [
                    "match",
                    ["get", "category"],
                    "environment",
                    "environment", // Set icon for "environment"
                    "social",
                    "social", // Set icon for "social"
                    "governance",
                    "goverener", // Set icon for "Governance"
                    "default-icon", // Default icon if category doesn't match any specified value
                  ],
                  "icon-size": 0.34, // Adjust the icon size as needed
                },
                paint: {
                  "text-color": "black",
                  "text-halo-width": 2,
                },
              });
              map.addLayer({
                id: "earthquake_label",
                type: "symbol",
                source: "earthquakes",
                filter: ["!=", "cluster", true],
                layout: {
                  "text-field": [
                    "format",
                    ["get", "category"],
                    // { "min-fraction-digits": 1, "max-fraction-digits": 1 },
                  ],
                  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  "text-size": 0.4,
                },
                paint: {
                  "text-color": "black",
                  // "text-halo-color": "yellow",
                  "text-halo-width": 2,
                },
              });

              // objects for caching and keeping track of HTML marker objects (for performance)
              const markers = {};
              let markersOnScreen = {};
              const popups = {};
              function updateMarkers(map) {
                const newMarkers = {};
                const features = map.querySourceFeatures("earthquakes");
                const addPop = (id) => {
                  map.getCanvas().style.cursor = "pointer";
                  // popupHandler(popups[id], false,true)
                  popups[id].addTo(map);
                };
                const removePop = (id) => {
                  setTimeout(() => popups[id].remove(), 1000);
                  // popupHandler(popups[id])
                  // popups[id].remove()
                };
                for (const feature of features) {
                  const coords = feature.geometry.coordinates;
                  const props = feature.properties;

                  let id = props.cluster_id || props.id; // Use cluster_id for clusters, use id for individual features

                  let marker = markers[id];

                  if (!marker) {
                    const fixedJsonString =
                      "[" +
                      props["incidents"]
                        .slice(
                          0,
                          props["incidents"][props["incidents"].length - 1] ===
                            ","
                            ? -1
                            : props["incidents"].length
                        )
                        .replace(/]\s*,\s*\[/g, ",") +
                      "]";
                    const incidents_array = JSON.parse(fixedJsonString);
                    const incidentsContent = getHtmlHover(
                      incidents_array[0],
                      "esgCategory",
                      activeCategory,
                    );
                    const el = createDonutChart(props);
                    // const markerContentNode = document.createElement('div');
                    // ReactDOM.render(<MyComponent el={el} data={props} />, markerContentNode);
                    // const newel = markerContentNode;
                    // create the popup

                    popups[id] = new mapboxgl.Popup()
                      .setHTML(incidentsContent)
                      .setLngLat(coords);
                    // hover event listener
                    el.addEventListener("mouseenter", () => addPop(id));
                    el.addEventListener("mouseleave", () => removePop(id));
                    marker = markers[id] = new mapboxgl.Marker({
                      element: el,
                    })
                      .setPopup(popup)
                      .setLngLat(coords);
                  }
                  newMarkers[id] = marker;

                  if (!markersOnScreen[id]) marker.addTo(map);
                }
                for (const id in markersOnScreen) {
                  if (!newMarkers[id]) markersOnScreen[id].remove();
                }
                markersOnScreen = newMarkers;
              }

              map.on("render", () => {
                if (!map.isSourceLoaded("earthquakes")) return;
                updateMarkers(map);
              });
            });
          });
        });
      });

      function createDonutChart(props) {
        const offsets = [];
        const counts = [props.mag2, props.mag3, props.mag4];

        let total = 0;
        for (const count of counts) {
          offsets.push(total);
          total += count;
        }
        const fontSize =
          total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
        const r =
          total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
        const r0 = Math.round(r * 0.6);
        const w = r * 2;
        let html = `<div>
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

        for (let i = 0; i < counts.length; i++) {
          html += donutSegment(
            offsets[i] / total,
            (offsets[i] + counts[i]) / total,
            r,
            r0,
            colors[i]
          );
        }
        if (total) {
          html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
          <text dominant-baseline="central" transform="translate(${r}, ${r})">
          ${total}
          </text>
          </svg>
          </div>`;
        }
        const el = document.createElement("div");
        el.innerHTML = html;
        return el.firstChild;
      }

      function donutSegment(start, end, r, r0, color) {
        if (end - start === 1) end -= 0.00001;
        const a0 = 2 * Math.PI * (start - 0.25);
        const a1 = 2 * Math.PI * (end - 0.25);
        const x0 = Math.cos(a0),
          y0 = Math.sin(a0);
        const x1 = Math.cos(a1),
          y1 = Math.sin(a1);
        const largeArc = end - start > 0.5 ? 1 : 0;

        return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
          } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
          } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
          }" fill="${color}" />`;
      }
    } else if (dataActiveType === "Social Media") {
      const mag2 = ["all", ["==", ["get", "category"], "positive"]];
      const mag3 = ["all", ["==", ["get", "category"], "negative"]];
      const mag4 = ["all", ["==", ["get", "category"], "neutral"]];

      const colors = ["#1A9C3E", "#D00000", "#B6BCC7"];

      map.on("load", () => {
        // map.loadImage(criticalImage, function (error, criticalImage) {
        //   if (error) throw error;
        //   map.loadImage(highImage, function (error, highImage) {
        //     if (error) throw error;
        //     map.loadImage(mediumImage, function (error, mediumImage) {
        //       if (error) throw error;
        //       map.loadImage(lowImage, function (error, lowImage) {
        //         if (error) throw error;

        // map.addImage("high", criticalImage);
        // map.addImage("medium", highImage);
        // map.addImage("critical", mediumImage);
        // map.addImage("low", lowImage);
        map.addSource("earthquakes", {
          type: "geojson",
          data: data,
          cluster: true,
          clusterRadius: 100,
          clusterProperties: {
            mag2: ["+", ["case", mag2, 1, 0]],
            mag3: ["+", ["case", mag3, 1, 0]],
            mag4: ["+", ["case", mag4, 1, 0]],
            incidents: ["+", ["get", "incidents"]],
          },
        });
        // set icon on category bases
        // map.addLayer({
        //   id: "earthquake_label",
        //   type: "symbol",
        //   source: "earthquakes",
        //   filter: ["!=", "cluster", true],
        //   layout: {
        //     "icon-image": [
        //       "match",
        //       ["get", "category"],
        //       "high",
        //       "high", // Set icon for "environment"
        //       "medium",
        //       "medium", // Set icon for "social"
        //       "critical",
        //       "critical",
        //       "low",
        //       "low", // Set icon for "Governance"
        //       "default-icon", // Default icon if category doesn't match any specified value
        //     ],
        //     "icon-size": 0.34, // Adjust the icon size as needed
        //     // "text-field": ["format", ["get", "category"], {}],
        //     // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        //     // "text-size": 20,
        //   },
        //   paint: {
        //     "text-color": "black",
        //     "text-halo-width": 2,
        //   },
        // });

        // set color on category bases
        map.addLayer({
          id: "earthquake_label",
          type: "circle",
          source: "earthquakes",
          filter: ["!=", "cluster", true],
          paint: {
            "circle-color": [
              "match",
              ["get", "category"],
              "positive",
              "#1A9C3E", // Set color for "high"
              "negative",
              "#D00000", // Set color for "medium"
              "neutral",
              "#B6BCC7", // Set color for "critical"
              "#808080", // Default color if category doesn't match any specified value
            ],
            "circle-radius": 10, // Adjust the circle radius as needed
            "circle-stroke-width": 2,
            "circle-stroke-color": [
              "match",
              ["get", "category"],
              "positive",
              "#b8dbbb", // Set stroke color for "high"
              "negative",
              "#ddb0aa", // Set stroke color for "medium"
              "neutral",
              "#d5dae2", // Set stroke color for "critical"
              "black", // Default stroke color if category doesn't match any specified value
            ],
          },
        });

        map.addLayer({
          id: "earthquake_label",
          type: "symbol",
          source: "earthquakes",
          filter: ["!=", "cluster", true],
          layout: {
            "text-field": [
              "format",
              ["get", "category"],
              // { "min-fraction-digits": 1, "max-fraction-digits": 1 },
            ],
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-size": 0.4,
          },
          paint: {
            "text-color": "black",
            // "text-halo-color": "yellow",
            "text-halo-width": 2,
          },
        });
        const markers = {};
        let markersOnScreen = {};

        function updateMarkers() {
          const newMarkers = {};
          const features = map.querySourceFeatures("earthquakes");

          for (const feature of features) {
            const coords = feature.geometry.coordinates;
            const props = feature.properties;

            const id = props.cluster_id;

            let marker = markers[id];
            if (!marker) {
              const el = createDonutChart(props);
              marker = markers[id] = new mapboxgl.Marker({
                element: el,
              }).setLngLat(coords);
            }
            newMarkers[id] = marker;

            if (!markersOnScreen[id]) marker.addTo(map);
          }
          for (const id in markersOnScreen) {
            if (!newMarkers[id]) markersOnScreen[id].remove();
          }
          markersOnScreen = newMarkers;
        }

        map.on("render", () => {
          if (!map.isSourceLoaded("earthquakes")) return;
          updateMarkers();
        });
      });
      // });
      //     });
      //   });
      // });

      function createDonutChart(props) {
        const offsets = [];
        const counts = [props.mag2, props.mag3, props.mag4];

        let total = 0;
        for (const count of counts) {
          offsets.push(total);
          total += count;
        }
        const fontSize =
          total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
        const r =
          total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
        const r0 = Math.round(r * 0.6);
        const w = r * 2;
        let html = `<div>
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

        for (let i = 0; i < counts.length; i++) {
          html += donutSegment(
            offsets[i] / total,
            (offsets[i] + counts[i]) / total,
            r,
            r0,
            colors[i]
          );
        }
        if (total) {
          html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
          <text dominant-baseline="central" transform="translate(${r}, ${r})">
          ${total}
          </text>
          </svg>
          </div>`;
        }
        const el = document.createElement("div");
        el.innerHTML = html;
        return el.firstChild;
      }

      function donutSegment(start, end, r, r0, color) {
        if (end - start === 1) end -= 0.00001;
        const a0 = 2 * Math.PI * (start - 0.25);
        const a1 = 2 * Math.PI * (end - 0.25);
        const x0 = Math.cos(a0),
          y0 = Math.sin(a0);
        const x1 = Math.cos(a1),
          y1 = Math.sin(a1);
        const largeArc = end - start > 0.5 ? 1 : 0;

        return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
          } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
          } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
          }" fill="${color}" />`;
      }
    } else {
      const mag2 = ["all", ["==", ["get", "category"], "high"]];
      const mag3 = ["all", ["==", ["get", "category"], "medium"]];
      const mag4 = ["all", ["==", ["get", "category"], "critical"]];
      const mag5 = ["all", ["==", ["get", "category"], "low"]];

      const colors = ["#FF0000", "#FF7518", "#800020", "#FFD700"];

      map.on("load", () => {
        map.loadImage(criticalImage, function (error, criticalImage) {
          if (error) throw error;
          map.loadImage(highImage, function (error, highImage) {
            if (error) throw error;
            map.loadImage(mediumImage, function (error, mediumImage) {
              if (error) throw error;
              map.loadImage(lowImage, function (error, lowImage) {
                if (error) throw error;

                map.addImage("high", highImage);
                map.addImage("medium", mediumImage);
                map.addImage("critical", criticalImage);
                map.addImage("low", lowImage);
                map.addSource("earthquakes", {
                  type: "geojson",
                  data: data,
                  cluster: true,
                  clusterRadius: 100,
                  clusterProperties: {
                    // keep separate counts for each magnitude category in a cluster
                    incidents: [
                      "concat",
                      ["concat", ["get", "incidents"], ","],
                    ],
                    mag2: ["+", ["case", mag2, 1, 0]],
                    mag3: ["+", ["case", mag3, 1, 0]],
                    mag4: ["+", ["case", mag4, 1, 0]],
                    mag5: ["+", ["case", mag5, 1, 0]],
                  },
                });
                map.addLayer({
                  id: "earthquake_label",
                  type: "symbol",
                  source: "earthquakes",
                  filter: ["!=", "cluster", true],
                  layout: {
                    "icon-image": [
                      "match",
                      ["get", "category"],
                      "high",
                      "high", // Set icon for "environment"
                      "medium",
                      "medium", // Set icon for "social"
                      "critical",
                      "critical",
                      "low",
                      "low", // Set icon for "Governance"
                      "default-icon", // Default icon if category doesn't match any specified value
                    ],
                    "icon-size": 0.34, // Adjust the icon size as needed
                    // "text-field": ["format", ["get", "category"], {}],
                    // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                    // "text-size": 20,
                  },
                  paint: {
                    "text-color": "black",
                    "text-halo-width": 2,
                  },
                });
                map.addLayer({
                  id: "earthquake_label",
                  type: "symbol",
                  source: "earthquakes",
                  filter: ["!=", "cluster", true],
                  layout: {
                    "text-field": [
                      "format",
                      ["get", "category"],
                      // { "min-fraction-digits": 1, "max-fraction-digits": 1 },
                    ],
                    "text-font": [
                      "Open Sans Semibold",
                      "Arial Unicode MS Bold",
                    ],
                    "text-size": 0.4,
                  },
                  paint: {
                    "text-color": "black",
                    // "text-halo-color": "yellow",
                    "text-halo-width": 2,
                  },
                });

                const markers = {};
                let markersOnScreen = {};
                const popups = {};
                function updateMarkers(map) {
                  const newMarkers = {};
                  const features = map.querySourceFeatures("earthquakes");
                  const addPop = (id) => {
                    map.getCanvas().style.cursor = "pointer";
                    // popupHandler(popups[id], false,true)
                    popups[id].addTo(map);
                  };
                  const removePop = (id) => {
                    setTimeout(() => popups[id].remove(), 1000);
                    // popupHandler(popups[id])
                    // popups[id].remove()
                  };

                  for (const feature of features) {
                    const coords = feature.geometry.coordinates;
                    const props = feature.properties;

                    const id = props.cluster_id || props.id;

                    let marker = markers[id];
                    if (!marker && location === "/fullMap") {
                      const fixedJsonString =
                        "[" +
                        props["incidents"]
                          .slice(
                            0,
                            props["incidents"][
                              props["incidents"].length - 1
                            ] === ","
                              ? -1
                              : props["incidents"].length
                          )
                          .replace(/]\s*,\s*\[/g, ",") +
                        "]";
                      const incidents_array = JSON.parse(fixedJsonString);

                      const incidentsContent = getHtmlHover(
                        incidents_array[0],
                        "severity",
                        activeCategory
                      );
                      const el = createDonutChart(props);
                      popups[id] = new mapboxgl.Popup()
                        .setHTML(incidentsContent)
                        .setLngLat(coords);
                      // hover event listener
                      el.addEventListener("mouseenter", () => addPop(id));
                      el.addEventListener("mouseleave", () => removePop(id));
                      marker = markers[id] = new mapboxgl.Marker({
                        element: el,
                      })
                        .setPopup(popup)
                        .setLngLat(coords);
                    } else if (!marker) {
                      const el = createDonutChart(props);
                      marker = markers[id] = new mapboxgl.Marker({
                        element: el,
                      }).setLngLat(coords);
                    }
                    newMarkers[id] = marker;

                    if (!markersOnScreen[id]) marker.addTo(map);
                  }
                  for (const id in markersOnScreen) {
                    if (!newMarkers[id]) markersOnScreen[id].remove();
                  }
                  markersOnScreen = newMarkers;
                }

                map.on("render", () => {
                  if (!map.isSourceLoaded("earthquakes")) return;
                  updateMarkers(map);
                });
              });
            });
          });
        });
      });

      function createDonutChart(props) {
        const offsets = [];
        const counts = [props.mag2, props.mag3, props.mag4, props.mag5];

        let total = 0;
        for (const count of counts) {
          offsets.push(total);
          total += count;
        }
        const fontSize =
          total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
        const r =
          total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
        const r0 = Math.round(r * 0.6);
        const w = r * 2;
        let html = `<div>
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

        for (let i = 0; i < counts.length; i++) {
          html += donutSegment(
            offsets[i] / total,
            (offsets[i] + counts[i]) / total,
            r,
            r0,
            colors[i]
          );
        }
        if (total) {
          html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
          <text dominant-baseline="central" transform="translate(${r}, ${r})">
          ${total}
          </text>
          </svg>
          </div>`;
        }
        const el = document.createElement("div");
        el.innerHTML = html;
        return el.firstChild;
      }

      function donutSegment(start, end, r, r0, color) {
        if (end - start === 1) end -= 0.00001;
        const a0 = 2 * Math.PI * (start - 0.25);
        const a1 = 2 * Math.PI * (end - 0.25);
        const x0 = Math.cos(a0),
          y0 = Math.sin(a0);
        const x1 = Math.cos(a1),
          y1 = Math.sin(a1);
        const largeArc = end - start > 0.5 ? 1 : 0;

        return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
          } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
          } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
          }" fill="${color}" />`;
      }
    }

    return () => {
      map.remove();
    };
  }, [data]);

  const viewAllFunction = () => {
    setModalVisible(true);
  };
  return (
    <div>
      <div
        ref={mapContainerRef}
        style={{ width: width || "100vW", height: height || "100vh" }}
      />
      {modalVisible ? (
        <IncidentsListModal
          setModalVisible={(e) => {
            setModalVisible(e);
            // if (!e) {
            //   networkData = { ...networkData, esg_categories: [] };
            // }
          }}
          networkData={{ specificIncidentIds: industries_Ids }}
        />
      ) : null}
    </div>
  );
};

export default FullMap;
