import { Typography, Stack } from "@mui/material";
import {
  getBackgroundColorFromRiskScore,
  getTextColorFromRiskScore,
  getTitleColorFromRiskScore,
} from "../../utils";

function RiskScore({ value, label }) {
  return (
    <Stack
      sx={{
        height: "64px",
        borderRadius: "8px",
        backgroundColor: getBackgroundColorFromRiskScore(value),
      }}
      paddingX="16px"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography
        variant="subtitle3"
        sx={{ color: getTitleColorFromRiskScore(value) }}
      >
        Risk Score
      </Typography>
      <Typography variant="h3" sx={{ color: getTextColorFromRiskScore(value) }}>
        {
          // show integer value using floor
          Math.floor(value)
        }
      </Typography>
      <Typography
        variant="subtitle3"
        sx={{ color: getTextColorFromRiskScore(value) }}
      >
        {label}
      </Typography>
    </Stack>
  );
}
export default RiskScore;
