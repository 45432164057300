import { call, takeLatest } from "redux-saga/effects";
import appconstant from "../../Constants/appConstansts";
import {
  onError,
  Loader,
  onSucces,
  onFail,
  onUnAuth,
} from "../../Utils/UnAuthFailFn";
import { storage } from "../../Utils";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  getProfileApi,
  profileDeleteApi,
  profileEmailNotificationApi,
  updateProfileApi,
} from "../api";
import { toast } from "react-toastify";
const { RoutesPath } = appconstant;
function* getProfileSaga(action) {
  try {
    const data = yield call(getProfileApi, action.data);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_PROFILE_DATA_SUCCESS,
        data,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //   on fail
    else {
      let obj = {
        type: type.API_GET_PROFILE_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}

function* getUpdateProfileSaga(action) {
  try {
    const data = yield call(updateProfileApi, action.data);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_PROFILE_DATA_SUCCESS,
        data,
        show: true,
      };
      yield call(onSucces, obj);
    } else {
      let obj = {
        type: type.API_GET_PROFILE_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}

function* profileEmailNotificationSaga(action) {
  try {
    const data = yield call(profileEmailNotificationApi, action.data);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_UPDATE_PROFILE_EMAIL_NOTIFICATION_SUCCESS,
        data,
        show: true,
      };
      yield call(onSucces, obj);
    }
    // on fail
    else {
      let obj = {
        type: type.API_UPDATE_PROFILE_EMAIL_NOTIFICATION_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* profileDeleteSaga() {
  try {
    const data = yield call(profileDeleteApi);
    // on success
    if (data.status === 0) {
      yield call(onSucces, {
        type: type.API_CHANGE_PASSWORD_SUCCESS,
        data,
        show: true,
        navigationLink: RoutesPath.login,
      });
      storage.clearStorage();
    } else {
      let obj = {
        type: type.API_UPDATE_PROFILE_EMAIL_NOTIFICATION_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}

export default function* rootSettingSaga() {
  yield takeLatest(type.API_GET_PROFILE_LOAD, getProfileSaga);
  yield takeLatest(type.API_UPDATE_PROFILE_LOAD, getUpdateProfileSaga);
  yield takeLatest(
    type.API_UPDATE_PROFILE_EMAIL_NOTIFICATION_LOAD,
    profileEmailNotificationSaga
  );
  yield takeLatest(type.API_PROFILE_DELETE_LOAD, profileDeleteSaga);
}
