import { call, put, take, takeEvery, takeLatest } from "redux-saga/effects";

import appconstant from "../../Constants/appConstansts";
import {
  Loader,
  onError,
  onFail,
  onSucces,
  onUnAuth,
} from "../../Utils/UnAuthFailFn";
import { toast } from "react-toastify";
import { storage, successToastContainer } from "../../Utils";
import { toast as hotToast } from "react-hot-toast";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  companiesWithIncidentApi,
  countriesWithIncidentApi,
  downloadDataApi,
  esgCategoryApi,
  esgFactorsIncidentsApi,
  esgIotApi,
  esgPaiIncidentsApi,
  esgsdgincidentsApi,
  exportDataApi,
  feedbackApi,
  fineAndPaneltyApi,
  getCategoriesApi,
  getCompaniesApi,
  getCountriesWithIncidentsApi,
  getEsgCountriesApi,
  getEsgFactorsApi,
  getEsgTotalIncidentCountApi,
  getIndustryApi,
  getNewsArticleApi,
  getSourceDetailApi,
  getTopSearchApi,
  homePageApi,
  incidentDetailsApi,
  incidentMapDataApi,
  incidentMapDataUpdateApi,
  incidentsApi,
  legalAndPaneltyApi,
  searchBYParamsApi,
  socialMediaCoverageIncidentApi,
  timelineGraphApi,
} from "../api";
import { message } from "antd";
import { getIncidentCountFailure, getIncidentCountSuccess } from "../Action/incidents";
const { RoutesPath } = appconstant;

function* IncidentSaga(action) {
  try {
    // if (window.location.pathname == "/companyPage") {
    yield put({ type: type.INCIDENT_LIST_LOADER, payload: true });
    // }

    const data = yield call(incidentsApi, action.data);
    // for success
    if (data.status === 0) {
      // if (window.location.pathname == "/companyPage") {
      yield put({ type: type.INCIDENT_LIST_LOADER, payload: false });
      // }
      let obj = {
        type: type.API_INCIDENT_SUCCESS,
        data,
        show: false,
        id: "data1",
        navigationLink: false,
      };

      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };

      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side

    console.log(e, "error");
    yield call(onError);
  }
}

function* IncidentListForModalSaga(action) {
  try {
    const data = yield call(incidentsApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_INCIDENT_LIST_FOR_MODAL_SUCCESS,
        data,
        show: false,
        id: "data1",
        navigationLink: false,
      };

      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };

      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side

    console.log(e, "error");
    yield call(onError);
  }
}

function* companiesWithIncidentSaga(action) {
  try {
    const data = yield call(companiesWithIncidentApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_COMPANIES_WITH_INCIDENT_SUCCESS,
        data,
        show: false,
        id: "data1",
        navigationLink: false,
      };

      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* countriesWithIncidentSaga(action) {
  try {
    const data = yield call(countriesWithIncidentApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_COUNTRIES_WITH_INCIDENT_SUCCESS,
        data,
        show: false,
        id: "data1",
        navigationLink: false,
      };

      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* homePageSaga(action) {
  try {
    const data = yield call(homePageApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_HOME_PAGE_SUCCESS,
        data,
        // navigationLink: RoutesPath.home,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* esgFactorsIncidentsSaga(action) {
  try {
    const data = yield call(esgFactorsIncidentsApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_ESG_FACTORS_INCIDENTS_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* esgsdgincidentsSaga(action) {
  try {
    const data = yield call(esgsdgincidentsApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_ESG_SDG_INCIDENTS_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* esgPaiIncidentsSaga(action) {
  try {
    const data = yield call(esgPaiIncidentsApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_ESG_PAI_INCIDENTS_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

// ESG Category Saga
function* esgCategorySaga(action) {
  try {
    const data = yield call(esgCategoryApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_ESG_CAT_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // on fail
    else {
      let obj = {
        type: type.API_ESG_CAT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    
    console.log(e, "error");
    yield call(onError);
  }
}
//esg Iot Saga
function* IotSaga(action) {
  try {
    const data = yield call(esgIotApi, action.data);
    if (data.status === 0) {
      let obj = {
        type: type.API_ESG_IOT_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_ESG_IOT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* getCompaniesSaga(action) {
  try {
    const data = yield call(getCompaniesApi, action?.data);
    //  on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_COMPANIES_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
        toastId: "1",
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // on fail
    else {
      let obj = {
        type: type.API_GET_COMPANIES_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getIndustriesSaga(action) {
  try {
    const data = yield call(getIndustryApi);

    let update = data?.result?.data?.map((val) =>
      !val.status ? { ...val, status: false } : val
    );
    for (let i = 0; i <= update?.length - 1; i++) {
      for (let j = 0; j <= update[i].childs?.length - 1; j++) {
        if (!update[i].childs[j].status) {
          update[i].childs[j] = {
            ...update[i].childs[j],
            status: false,
          };
        }
      }
    }
    data.result["data"] = update;

    //on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_INDUSTRY_SUCCESS,
        data,
        show: false,
        navigationLink: false,
        toastId: "1",
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // on fail
    else {
      let obj = {
        type: type.API_GET_INDUSTRY_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    
    console.log(e, "error");
    yield call(onError);
  }
}
function* getTopSearchSaga(action) {
  try {
    const data = yield call(getTopSearchApi);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_TOP_SEARCH_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }

    // on fail
    else {
      let obj = {
        type: type.API_GET_TOP_SEARCH__FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    
    console.log(e, "error");
    yield call(onError);
  }
}
//get search categories
function* getCategoriesSaga(action) {
  try {
    const data = yield call(getCategoriesApi);
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_CATEGORIES_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.status?.message);
    } else {
      let obj = {
        type: type.API_GET_CATEGORIES_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* incidentDetailSaga(action) {
  try {
    const data = yield call(incidentDetailsApi, action.data);
    if (data.status === 0) {
      let obj = {
        type: type.API_INCIDENT_DETAILS_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.status?.message);
    } else {
      let obj = {
        type: type.API_GET_CATEGORIES_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCountriesWithIncidenceSaga(action) {
  try {
    const data = yield call(getCountriesWithIncidentsApi, action.data);
    if (data.status === 0) {
      let obj = {
        type: type.API_COUNTRIES_WITH_INCIDENT_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.status?.message);
    } else {
      let obj = {
        type: type.API_GET_INCIDENCE_WITH_COUNTRIES_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
   
    console.log(e, "error");
    yield call(onError);
  }
}
function* getSourceDetailSaga(action) {
  try {
    const data = yield call(getSourceDetailApi, action.data);
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_SOURCE_DETAILS_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.status?.message);
    } else {
      let obj = {
        type: type.API_GET_SOURCE_DETAILS_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {

    console.log(e, "error");
    yield call(onError);
  }
}

function* getNewsArticleSaga(action) {
  try {
    const data = yield call(getNewsArticleApi, action.data);
    if (data.status === 0) {
      yield put({
        type: type.API_GET_NEWS_ARTICLE_SUCCESS,
        payload: { data: data.result.data, oldData: action.data.type },
      });
    } else if (data.status === 2) {
      onUnAuth(data?.status?.message);
    } else {
      let obj = {
        type: type.API_GET_NEWS_ARTICLE_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {

    console.log(e, "error");
    yield call(onError);
  }
}
//Incident Map Data
function* incidentMapDataSaga(action) {
  try {
    const data = yield call(incidentMapDataApi, action.data);
    if (data?.status === 0) {
      let obj = {
        type: type?.API_MAP_OLD_DATA_SUCCESS,
        data: data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_INCIDENT_MAP_DATA_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* incidentMapDataUpdateSaga(action) {
  try {
    const data = yield call(incidentMapDataUpdateApi, action.data);

    if (data?.status === 0) {
      let obj = {
        type: type?.API_MAP_DATA_SUCCESS,
        data: data,
        navigationLink: false,
        show: false,
      };

      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_INCIDENT_MAP_DATA_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {

    console.log(e, "error");
    yield call(onError);
  }
}

function* downloadDataSaga(action) {
  try {
    const data = yield call(downloadDataApi, action.data);

    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_DOWNLOAD_INCIDENT_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_DOWNLOAD_INCIDENT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
    }
}
function* searchByParamsSaga(action) {
  try {
    const data = yield call(searchBYParamsApi, action?.data);
    if (data?.status === 0) {
      let obj = {
        type: type?.API_SEARCH_BY_PARAMS_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_SEARCH_BY_PARAMS_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    console.log(e, "error");
    yield call(onError);
  }
}

function* exportDataSaga(action) {
  try {
    const data = yield call(exportDataApi, action.data);

    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_EXPORT_INCIDENT_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_EXPORT_INCIDENT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getEsgFactorsSaga(action) {
  try {
    const data = yield call(getEsgFactorsApi, action.data);
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_ESG_FACTORS_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_ESG_FACTORS_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getEsgChildListForModalSaga(action) {
  try {
    const data = yield call(getCategoriesApi, action.data);
    if (data?.status === 0) {
      let obj = {
        type: type.API_ESG_CHILD_LIST_FOR_MODAL_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_ESG_CHILD_LIST_FOR_MODAL_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCountriesListForModalSaga(action) {
  try {
    const data = yield call(getEsgCountriesApi, action.data);
    if (data?.status === 0) {
      let obj = {
        type: type.API_COUNTRIES_LIST_FOR_MODAL_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_COUNTRIES_LIST_FOR_MODAL_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCompanyListForModalSaga(action) {
  try {
    const data = yield call(getCompaniesApi, action.data);
    if (data?.status === 0) {
      let obj = {
        type: type.API_COMPANIES_LIST_FOR_MODAL_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_COMPANIES_LIST_FOR_MODAL_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getEsgCountiresSaga(action) {
  try {
    const data = yield call(getEsgCountriesApi, action.data);
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_ESG_COUNRTIES_SUCCESS,
        data,
        navigationLink: false,
        show: false,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_ESG_COUNRTIES_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* socialMediaIncidentSaga(action) {
  try {
    const data = yield call(socialMediaCoverageIncidentApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_SOCIAL_MEDIA_INCIDENT_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getFineAndPaneltySaga(action) {
  try {
    const data = yield call(fineAndPaneltyApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_FINE_AND_PANELTY_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getTimelineGraphSaga(action) {
  try {
    const data = yield call(timelineGraphApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_TIMELINE_GRAPH_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getRegulatoryLegalSaga(action) {
  try {
    const data = yield call(legalAndPaneltyApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_REGULATORY_LEGAL_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* postFeedbackSaga(action) {
  try {
    const data = yield call(feedbackApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.POST_FEEDBACK_SUCCESS,
        show: false,
        data,
      };
      hotToast.success(successToastContainer("Thanks for you feedback!"), {
        id: "invalidFileUpload",
        className: "success",
      });
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getTotalEsgIncidentCountSaga(action) {
  try {
    const data = yield call(getEsgTotalIncidentCountApi, action.payload);
    // on success
    if (data.status === 0) {
      yield put(getIncidentCountSuccess(data.result.data));
      // yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
      yield put(getIncidentCountFailure(data));
    } else {
      yield put(getIncidentCountFailure(data));
    }
  } catch (e) {
    console.log(e, "error");
    yield put(getIncidentCountFailure(e));
    yield call(onError);
  }
}

export default function* rootIncidentSaga() {
  yield takeLatest(type.API_GET_ESG_FACTORS_LOAD, getEsgFactorsSaga);
  yield takeLatest(
    type.API_ESG_CHILD_LIST_FOR_MODAL_LOAD,
    getEsgChildListForModalSaga
  );
  yield takeLatest(
    type.API_COUNTRIES_LIST_FOR_MODAL_LOAD,
    getCountriesListForModalSaga
  );

  yield takeLatest(
    type.API_COMPANIES_LIST_FOR_MODAL_LOAD,
    getCompanyListForModalSaga
  );

  yield takeLatest(type.API_INCIDENT_LOAD, IncidentSaga);
  yield takeLatest(
    type.API_INCIDENT_LIST_FOR_MODAL_LOAD,
    IncidentListForModalSaga
  );
  yield takeLatest(type.API_GET_ESG_COUNRTIES_LOAD, getEsgCountiresSaga);
  yield takeLatest(type.API_GET_TOP_SEARCH__LOAD, getTopSearchSaga);
  yield takeLatest(type.API_HOME_PAGE_LOAD, homePageSaga);
  yield takeLatest(type.API_SEARCH_BY_PARAMS_LOAD, searchByParamsSaga);
  yield takeLatest(
    type.API_COMPANIES_WITH_INCIDENT_LOAD,
    companiesWithIncidentSaga
  );
  yield takeLatest(
    type.API_COUNTRIES_WITH_INCIDENT_LOAD,
    countriesWithIncidentSaga
  );
  yield takeLatest(
    type.API_ESG_FACTORS_INCIDENTS_LOAD,
    esgFactorsIncidentsSaga
  );

  yield takeLatest(type.API_ESG_SDG_INCIDENTS_LOAD, esgsdgincidentsSaga);
  yield takeLatest(type.API_ESG_PAI_INCIDENTS_LOAD, esgPaiIncidentsSaga);
  yield takeLatest(type.API_ESG_CAT_LOAD, esgCategorySaga);
  yield takeLatest(type.API_ESG_IOT_LOAD, IotSaga);
  yield takeLatest(type.API_GET_COMPANIES_LOAD, getCompaniesSaga);
  yield takeLatest(type.API_GET_INDUSTRY_LOAD, getIndustriesSaga);
  yield takeLatest(type.API_GET_CATEGORIES_LOAD, getCategoriesSaga);
  yield takeLatest(type.API_INCIDENT_DETAILS_LOAD, incidentDetailSaga);
  yield takeLatest(
    type.API_GET_INCIDENCE_WITH_COUNTRIES_LOAD,
    getCountriesWithIncidenceSaga
  );
  yield takeLatest(type.API_GET_SOURCE_DETAILS_LOAD, getSourceDetailSaga);
  yield takeEvery(type.API_GET_NEWS_ARTICLE_LOAD, getNewsArticleSaga);

  yield takeLatest(type.API_INCIDENT_MAP_DATA_LOAD, incidentMapDataUpdateSaga);

  yield takeLatest(type.API_GET_DOWNLOAD_INCIDENT_LOAD, downloadDataSaga);
  yield takeLatest(type.API_GET_EXPORT_INCIDENT_LOAD, exportDataSaga);
  yield takeLatest(
    type.GET_SOCIAL_MEDIA_INCIDENT_LOAD,
    socialMediaIncidentSaga
  );
  yield takeLatest(type.GET_TIMELINE_GRAPH_LOAD, getTimelineGraphSaga);
  yield takeLatest(type.GET_REGULATORY_LEGAL_LOAD, getRegulatoryLegalSaga);
  yield takeLatest(type.GET_FINE_AND_PANELTY_LOAD, getFineAndPaneltySaga);
  yield takeLatest(type.POST_FEEDBACK_LOAD, postFeedbackSaga);
  yield takeLatest(type.GET_INCIDENT_COUNT_LOAD, getTotalEsgIncidentCountSaga);
}
