import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  FilterParametersEnum,
  TabEnum,
} from '../../../enums/FilterSideBarEnums';
// import { ColorPartial } from '@mui/material/styles/createPalette';
import { updateFilterParameterField } from '../../../redux/GraphExploration/FilterSideBarSlice';
import { getConnectingNodesToHide, updateHiddenNodes } from '../../../utils';
import { useGraphObject } from '../../context/MyContext';
import { useAppDispatch, useAppSelector } from '../../../redux/configuareStore';
import CheckBoxLabel from '../NodeTab/Accordions/CheckBoxLabel';

function EdgeTab() {
  const filterParameters = useAppSelector(
    (state) => state.filterSideBar.filterParameters,
  );
  const dispatch = useAppDispatch();
  const { graphObject } = useGraphObject();
  const edges = useAppSelector((state) => state.graph.edges);
  const handleChange =
    (filterParameter, isParent = false) =>
    (_event, checked) => {
      let nextFilterParametersState = [];
      if (isParent) {
        const shortLabelFilterParameters = filterParameters.filter(
          (filterParameterItem) =>
            filterParameterItem.parentId === filterParameter.id,
        );
        shortLabelFilterParameters.forEach((shortLabelFilterParameter) => {
          dispatch(
            updateFilterParameterField({
              itemId: shortLabelFilterParameter.id,
              fieldToUpdate: 'value',
              newValue: checked,
            }),
          );
        });
        nextFilterParametersState = filterParameters.map((item) => {
          if (item.parentId === filterParameter.id) {
            return {
              ...item,
              value: checked,
            };
          }
          return item;
        });
      } else {
        dispatch(
          updateFilterParameterField({
            itemId: filterParameter.id,
            fieldToUpdate: 'value',
            newValue: checked,
          }),
        );
        nextFilterParametersState = filterParameters.map((item) => {
          if (item.id === filterParameter.id) {
            return {
              ...item,
              value: checked,
            };
          }
          return item;
        });
      }
      const allParentIds = [
        FilterParametersEnum.PARTNER,
        FilterParametersEnum.CUSTOMER,
        FilterParametersEnum.SUPPLIER,
      ];
      const activeFilterParameters = nextFilterParametersState.filter(
        (item) => allParentIds.includes(item.parentId) && item.value,
      );
      if (activeFilterParameters.length > 0) {
        const activeLabels = activeFilterParameters.map((item) => item.label);
        const edgesToHide = edges.filter(
          (edge) =>
            // edges that their data.label or data.shortLabel is not in activeLabels will be hidden
            !activeLabels.includes(edge.data.label) &&
            !activeLabels.includes(edge.data.shortLabel),
        );
        const hiddenNodesIds = getConnectingNodesToHide(
          edgesToHide,
          graphObject,
        );
        dispatch(
          updateFilterParameterField({
            itemId: FilterParametersEnum.SUPPLIER, // no difference between supplier and customer or partner
            fieldToUpdate: 'hiddenNodeIds',
            newValue: hiddenNodesIds,
          }),
        );
        updateHiddenNodes(graphObject);
      } else {
        dispatch(
          updateFilterParameterField({
            itemId: FilterParametersEnum.SUPPLIER, // no difference between supplier and customer or partner
            fieldToUpdate: 'hiddenNodeIds',
            newValue: [],
          }),
        );
        updateHiddenNodes(graphObject);
      }
    };
  return (
    <FormGroup sx={{ paddingLeft: 1 }}>
      {filterParameters
        .filter(
          (filterParameter) =>
            filterParameter.tab === TabEnum.EDGE &&
            filterParameter.level === 0 &&
            filterParameter.count > 0,
        )
        .map((shortLabelFilterParameter) => {
          const longLabelFilterParameters = filterParameters.filter(
            (filterParameterItem) =>
              filterParameterItem.parentId === shortLabelFilterParameter.id &&
              filterParameterItem.count > 0,
          );
          return (
            <Box key={shortLabelFilterParameter.id}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <IconButton
                  onClick={() => {
                    dispatch(
                      updateFilterParameterField({
                        itemId: shortLabelFilterParameter.id,
                        fieldToUpdate: 'value',
                        newValue: !shortLabelFilterParameter.value,
                      }),
                    );
                  }}
                >
                  <ChevronRightIcon
                    sx={{
                      transform: shortLabelFilterParameter.value
                        ? 'rotate(90deg)'
                        : 'rotate(0deg)',
                      transition: 'transform 0.3s ease',
                    }}
                  />
                </IconButton>
                <FormControlLabel
                  key={shortLabelFilterParameter.label}
                  control={
                    <Checkbox
                      size="small"
                      checked={longLabelFilterParameters.every(
                        (item) => item.value,
                      )}
                      indeterminate={
                        longLabelFilterParameters.some((item) => item.value) &&
                        !longLabelFilterParameters.every((item) => item.value)
                      }
                      onChange={handleChange(shortLabelFilterParameter, true)}
                    />
                  }
                  label={
                    <CheckBoxLabel
                      label={shortLabelFilterParameter.label}
                      count={shortLabelFilterParameter.count}
                    />
                  }
                />
              </Stack>
              <Collapse in={shortLabelFilterParameter.value}>
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 8 }}>
                  {longLabelFilterParameters.map((countryFilterParameter) => (
                    <FormControlLabel
                      key={`${countryFilterParameter.id}-${
                        countryFilterParameter.value ? 'Yes' : 'No'
                      }`}
                      control={
                        <Checkbox
                          size="small"
                          checked={countryFilterParameter.value}
                          onChange={handleChange(countryFilterParameter, false)}
                        />
                      }
                      label={
                        <CheckBoxLabel
                          label={countryFilterParameter.label}
                          count={countryFilterParameter.count}
                        />
                      }
                    />
                  ))}
                </Box>
              </Collapse>
            </Box>
          );
        })}
    </FormGroup>
    // <div>
    //   {filterParameters
    //     .filter((item) => item.level === 0 && item.tab === TabEnum.EDGE)
    //     .map((filterParameter) => (
    //       <Box
    //         sx={{
    //           // borderTop: border,
    //           borderBottom: border,
    //         }}
    //         key={filterParameter.id}
    //       >
    //         <CustomAccordion>
    //           <CustomAccordionSummary>
    //             <Typography variant="subtitle3">
    //               {filterParameter.label}
    //             </Typography>
    //           </CustomAccordionSummary>
    //           <CustomAccordionDetails>
    //             Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    //             Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
    //             eget.
    //           </CustomAccordionDetails>
    //         </CustomAccordion>
    //       </Box>
    //     ))}
    // </div>
  );
}
export default EdgeTab;
