/* eslint-disable no-param-reassign */
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { CustomAccordionDetails } from '../../../../components/CustomeAccordion';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../redux/configuareStore';
import { FilterParametersEnum } from '../../../../enums/FilterSideBarEnums';
import { updateFilterParameterField } from '../../../../redux/GraphExploration/FilterSideBarSlice';
import { useGraphObject } from '../../../context/MyContext';
import { updateHiddenNodes } from '../../../../utils';
import CheckBoxLabel from './CheckBoxLabel';

export default function RiskScoreAccordion() {
  const filterParameters = useAppSelector(
    (state) => state.filterSideBar.filterParameters,
  );
  const { graphObject } = useGraphObject();
  const dispatch = useAppDispatch();
  const nodes = useAppSelector((state) => state.graph.nodes);
  const handleChange = (filterParameter) => (_event, checked) => {
    dispatch(
      updateFilterParameterField({
        itemId: filterParameter.id,
        fieldToUpdate: 'value',
        newValue: checked,
      }),
    );
    const nextFilterParametersState = filterParameters.map((item) => {
      if (item.id === filterParameter.id) {
        return {
          ...item,
          value: checked,
        };
      }
      return item;
    });
    const activeFilterParameters = nextFilterParametersState.filter(
      (item) => item.parentId === FilterParametersEnum.RISK_SCORE && item.value,
    );
    if (activeFilterParameters.length > 0) {
      const activeLabels = activeFilterParameters.map((item) => item.label);
      const hiddenNodes = nodes.filter(
        (node) =>
          // nodes that doesn't have any of the active labels in their data.filterTagIds array will be hidden
          !node.data.filterTagIds.some((filterTagId) =>
            activeLabels.includes(filterTagId),
          ),
      );
      dispatch(
        updateFilterParameterField({
          itemId: FilterParametersEnum.RISK_SCORE,
          fieldToUpdate: 'hiddenNodeIds',
          newValue: hiddenNodes.map((n) => n.id),
        }),
      );
      updateHiddenNodes(graphObject);
    } else {
      dispatch(
        updateFilterParameterField({
          itemId: FilterParametersEnum.RISK_SCORE,
          fieldToUpdate: 'hiddenNodeIds',
          newValue: [],
        }),
      );
      updateHiddenNodes(graphObject);
    }
  };
  return (
    <CustomAccordionDetails>
      <FormGroup sx={{ paddingLeft: 2 }}>
        {filterParameters
          .filter(
            (filterParameter) =>
              filterParameter.parentId === FilterParametersEnum.RISK_SCORE &&
              filterParameter.count > 0,
          )
          .map((filterParameter) => (
            <FormControlLabel
              key={filterParameter.id}
              control={
                <Checkbox
                  size="small"
                  checked={filterParameter.value}
                  onChange={handleChange(filterParameter)}
                />
              }
              label={
                <CheckBoxLabel
                  label={filterParameter.label}
                  count={filterParameter.count}
                />
              }
            />
          ))}
      </FormGroup>
    </CustomAccordionDetails>
  );
}
