import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import { setPathFindingMode } from "../../redux/GraphExploration/rightClickMenuSlice";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";

function PathFindingModalRadioButtons() {
  const dispatch = useAppDispatch();
  const handleChange = (event) => {
    dispatch(setPathFindingMode(event.target.value));
  };
  const value = useAppSelector((state) => state.rightClickMenu.pathFindingMode);
  const theme = useTheme();
  return (
    <Box>
      <FormControl>
        <RadioGroup
          aria-labelledby="path-finding-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="shortest"
            control={<Radio sx={{ padding: 0 }} />}
            label={
              <Typography
                variant="captionXL"
                style={{
                  marginLeft: "12px",
                  color: theme.palette.gray[800],
                }}
              >
                The Shortest Path
              </Typography>
            }
            sx={{
              "&.MuiFormControlLabel-root": {
                marginLeft: 0,
              },
              marginBottom: "6px",
            }}
          />
          <FormControlLabel
            value="all"
            control={<Radio sx={{ padding: 0 }} />}
            label={
              <Typography
                variant="captionXL"
                style={{
                  marginLeft: "12px",
                  color: theme.palette.gray[800],
                }}
              >
                All Paths
              </Typography>
            }
            sx={{
              "&.MuiFormControlLabel-root": {
                marginLeft: 0,
              },
            }}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
export default PathFindingModalRadioButtons;
