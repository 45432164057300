import { apiConstants as types } from "../../Constants/apiConstants";

export const getCorpSupplyChainDetailLoad = (data) => ({
  type: types.GET_CORP_SUPPLY_CHAIN_DETAIL_LOAD,
  payload: data,
});

export const getCorpSupplyChainDetailSuccess = (data) => ({
  type: types.GET_CORP_SUPPLY_CHAIN_DETAIL_SUCCESS,
  payload: data,
});

export const getCorpSupplyChainDetailFail = (data) => ({
  type: types.GET_CORP_SUPPLY_CHAIN_DETAIL_FAIL,
  payload: data,
});

export const getCorpRiskChartDataLoad = (data) => ({
  type: types.GET_CORP_RISK_CHART_DETAIL_LOAD,
  payload: data,
});

export const getCorpRiskChartDataSuccess = (data) => ({
  type: types.GET_CORP_RISK_CHART_DETAIL_SUCCESS,
  payload: data,
});

export const getCorpRiskChartDataFail = (data) => ({
  type: types.GET_CORP_RISK_CHART_DETAIL_FAIL,
  payload: data,
});

export const getCorpRiskPieChartLoad = (data) => ({
  type: types.GET_CORP_RISK_PIE_CHART_LOAD,
  payload: data,
});

export const getCorpRiskPieChartSuccess = (data) => ({
  type: types.GET_CORP_RISK_PIE_CHART_SUCCESS,
  payload: data,
});

export const getCorpRiskPieChartFail = (data) => ({
  type: types.GET_CORP_RISK_PIE_CHART_FAIL,
  payload: data,
});

export const getCorpIncidentBarLoad = (data) => ({
  type: types.GET_CORP_INICDENTS_BAR_LOAD,
  payload: data,
});

export const getCorpIncidentBarSuccess = (data) => ({
  type: types.GET_CORP_INICDENTS_BAR_SUCCESS,
  payload: data,
});

export const getCorpIncidentBarFail = (data) => ({
  type: types.GET_CORP_INICDENTS_BAR_FAIL,
  payload: data,
});

export const getCorpRiskScoreBarLoad = (data) => ({
  type: types.GET_CORP_RISK_SCORE_BAR_LOAD,
  payload: data,
});

export const getCorpRiskScoreBarSuccess = (data) => ({
  type: types.GET_CORP_RISK_SCORE_BAR_SUCCESS,
  payload: data,
});

export const getCorpRiskScoreBarFail = (data) => ({
  type: types.GET_CORP_RISK_SCORE_BAR_FAIL,
  payload: data,
});

export const getCorpIncidentAnalysisLoad = (data) => ({
  type: types.GET_CORP_INICDENTS_ANALYSIS_LOAD,
  payload: data,
});

export const getCorpIncidentAnalysisSuccess = (data) => ({
  type: types.GET_CORP_INICDENTS_ANALYSIS_SUCCESS,
  payload: data,
});

export const getCorpIncidentAnalysisFail = (data) => ({
  type: types.GET_CORP_INICDENTS_ANALYSIS_FAIL,
  payload: data,
});

export const getCorpEsgFactorsLoad = (data) => ({
  type: types.GET_CORP_ESG_FACTORES_LOAD,
  payload: data,
});

export const getCorpEsgFactorsSuccess = (data) => ({
  type: types.GET_CORP_ESG_FACTORES_SUCCESS,
  payload: data,
});

export const getCorpEsgFactorsFail = (data) => ({
  type: types.GET_CORP_ESG_FACTORES_FAIL,
  payload: data,
});

export const getCorpSdgLoad = (data) => ({
  type: types.GET_CORP_SDG_LOAD,
  payload: data,
});

export const getCorpSdgSuccess = (data) => ({
  type: types.GET_CORP_SDG_SUCCESS,
  payload: data,
});

export const getCorpSdgFail = (data) => ({
  type: types.GET_CORP_SDG_FAIL,
  payload: data,
});

export const getCorpPaiIndicatorsLoad = (data) => ({
  type: types.GET_CORP_PAI_INDICATORS_LOAD,
  payload: data,
});

export const getCorpPaiIndicatorsSuccess = (data) => ({
  type: types.GET_CORP_PAI_INDICATORS_SUCCESS,
  payload: data,
});

export const getCorpPaiIndicatorsFail = (data) => ({
  type: types.GET_CORP_PAI_INDICATORS_FAIL,
  payload: data,
});

export const getCorpCompaniesListLoad = (data) => ({
  type: types.GET_CORP_COMPANIES_LIST_LOAD,
  data: data,
});

export const getCorpCompaniesListSuccess = (data) => ({
  type: types.GET_CORP_COMPANIES_LIST_SUCCESS,
  payload: data,
});

export const getCorpCompaniesListFail = (data) => ({
  type: types.GET_CORP_COMPANIES_LIST_FAIL,
  payload: data,
});

export const updateCorportateProfState = (data, key) => ({
  type: types.UPDATE_CORP_PROF_STATE,
  payload: data,
  key
})