import React, { useState, useEffect, useLayoutEffect } from "react";
import Images from "../../../Themes/ImageConstant";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Modal,
  Collapse,
  Row,
  Tooltip as ToolTip,
  Progress,
} from "antd";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import {
  calculatePreviousDate,
  dateButtonObj,
  scrollTo,
  checkArrayLengthWithTrueVal,
  checkRiskColor,
  getRiskVal,
} from "../../../Utils";
import IncidentTable from "../../../Componets/IncidentTable";
import SupplyChainTable from "../../../Componets/SupplyChainTable";
import { MultiSlider } from "../../../Componets/MultiSlider";
import {
  getDownloadDataAction,
  incindentAction,
  incidentMapAction,
  getIncidentCountLoad,
} from "../../../Redux/Action/incidents";
import { dispatch, navigate } from "../../../Utils/dispatchNavigate";
import {

  ResetDataAction,
  getIncidentseverityAction,
} from "../../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import { columns, columns1 } from "../../dashboardPage/companyColumns";
import SeverityTrend from "../../dashboardPage/chart/sentimentTrend/ServerityTrend";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import DatePicker from "../../../Componets/DatePicker";
import moment from "moment";
import IncidentsList from "../../incidentsList";
import BubleIncidentsList from "../../bubleIncidentsList";
import {
  getDateTwoDaysAgo,
  getLastMonthDates,
  getStrokeColorGradient,
} from "../../../Utils/globalFunction";
import SunChart from "../../dashboardPage/chart/SunChart";
import { ChartHeaderTimeRow } from "../../dashboardPage/chart/ChartHeaderTimeRow";
import ReactApexChart from "react-apexcharts";
import IncidentsByESGCategories from "../../dashboardPage/chart/IncidentCategorization/IncidentsByESGCategories";
import { ChartCard } from "../../../Layouts/ChartCard";
import EChartsReact from "echarts-for-react";
import style from "../style.module.css";
import PieChartComponent from "../components/pieChart";
import IncidentPreview from "../../dashboardPage/chart/IncidentPreview";
import UnPrinciple from "./UnPrinciple/UnPrinciple";
import RiskSevertyComp from "../components/riskSeverty";
import StockChartComponent from "../components/stokeChart";
import Banner from "../components/banner";
import {
  getAlertReportLoad,
  mostFrequentEsgLoad,
  portfolioCompLoad,
  portfolioDetialLoad,
  unGlobalgraphLoad,
  PortfolioESGRiskLoad,
  getRiskScorePerCompLoad,
  getHighSeverityIncidentLoad,
  distributionGraphLoad,
  clearAlertRulesLoad,
  updatePortfolioState,
  getPortfolioStatusLoad,
  getPortfolioWeightLoad,
  resetPortFolioPageDataAction
} from "../../../Redux/Action/portfolio";
import EsgIncidentCategoriesChart from "../components/esgIncidentCategoriesChart";
import FullMap from "../../../Componets/MapGL/full-map";
import PortfolioCompModal from "../../PortfolioCompModal";
import ImageConst from "../../../Themes/ImageConstant";
import GlobalLoder from "../../../Utils/GlobalLoder";
const supplyChainColumns = [
  {
    title: "Company",
    dataIndex: "name",
    key: "name",

    render: (text, _, i) => {
      return (
        <a style={{ display: "flex" }}>
          <span className="col-index">{`${i + 1} `}</span>
          <a
            onClick={() => navigate("/companyPage", { state: { id: _.id } })}
            className="titleText theme-dark-text"
            style={{
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxLines: 1,
              textOverflow: "ellipsis",
            }}
          >{`${text}`}</a>
        </a>
      );
    },
    className: "companyPage-col1",
  },
  {
    title: "Ticker Symbol",
    dataIndex: "tickers",
    key: "tickers",

    render: (text, _, i) => {
      return (
        <div
          style={{
            color: "#07132F",
            fontWeight: 500,
          }}
        >
          {text && text.length > 0 ? text.join(", ") : "-"}
        </div>
      );
    },
    className: "companyPage-col1",
  },
  {
    title: "ESG Risk",
    dataIndex: "risk",
    className: `company-supplyChain`,
    key: "risk",
    render: (text, _, item) => (
      <div
        style={{
          backgroundColor: checkRiskColor[getRiskVal(text)],
          display: "flex",
          justifyContent: "center",
          height: "40px",
          alignItems: "center",
        }}
      >
        <span>
          <p style={{ color: "white", width: "fit-content", fontWeight: 500 }}>
            {text ? Math.round(text) : "0"}
          </p>
        </span>
      </div>
    ),
  },
  {
    title: "Risk Change",
    dataIndex: "risk_change",
    className: `company-supplyChain`,

    render: (text, _, item) => (
      <div
        style={{
          backgroundColor:
            text > 0 ? "rgba(252, 237, 237, 1)" : "rgba(232, 245, 236, 1)",
          display: "flex",
          justifyContent: "center",
          marginLeft: 10,
          width: "60px",
          alignItems: "center",
        }}
      >
        <span>
          <p
            style={{
              color: text > 0 ? "#D00000" : "rgba(26, 156, 62, 1)",
              width: 48,
              fontWeight: 500,
              height: 24,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={text > 0 ? ImageConst.high : ImageConst.low}
              style={{ height: 16, width: 16 }}
              alt="Risk"
            />
            {Math.abs(text?.toFixed(2))}%
          </p>
        </span>
      </div>
    ),
  },
  {
    title: "Market Value",
    dataIndex: "marketValue",
    className: `company-supplyChain`,
    render: (text, _, item) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: 10,
          width: "50px",
          alignItems: "center",
        }}
      >
        {text}
      </div>
    ),
  },
  {
    title: "Shares",
    dataIndex: "risk_changeD",
    className: `company-supplyChain`,

    render: (text, _, item) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: 10,
          width: "20px",
          alignItems: "center",
        }}
      >
        <span>
          <p
            style={{
              color: text < 0 ? "#B70000" : "#168535",
              width: "fit-content",
              fontWeight: 500,
            }}
          >
            {text ? text + "%" : ""}
          </p>
        </span>
      </div>
    ),
  },

  {
    title: "Stock Price",
    dataIndex: "incident_count",
    className: `company-supplyChain`,
    width: "15%",
    render: (text, _, item) => (
      <span className="supply-chain-incident-column">
        <a className="theme-link-style"></a>
      </span>
    ),
  },
];

const dummyDayaForTesting = {
  _id: "65602e4f470437114e385444",
  Social: {
    esg_factors: [
      {
        _id: "65602e4e470437114e38529d",
        id: "21eaa9d41f4b3e9caea2abcc3075fe92",
        name: "Workplace Health and Safety",
        parent: "Social",
        incidents: 2,
      },
      {
        _id: "65602e4e470437114e3852dd",
        id: "ff581a6e099c3b378311fe904ae2a564",
        name: "Employee Engagement, Diversity, and Inclusion",
        parent: "Social",
        incidents: 1,
      },
    ],
    esg_sdgs: [],
    esg_pais: [
      {
        _id: "65659fb23d1f2f1b8d010a02",
        id: "21eaa9d41f4b3e9caea2abcc3075fe911",
        name: "PAI custom",
        parent: "Social",
        incidents: 1,
      },
    ],
    total: 4,
  },
  Governance: {
    esg_factors: [
      {
        _id: "65602e4e470437114e3852b0",
        id: "593b3c6a662332f5b9371e284c152a87",
        name: "Business Ethics and Compliance",
        parent: "Governance",
        incidents: 2,
      },
      {
        _id: "65602e4e470437114e3852b6",
        id: "778a0f37adc5371f8d96ea7236741cde",
        name: "Product Safety and Standards",
        parent: "Governance",
        incidents: 1,
      },
      {
        _id: "65602e4e470437114e3852f2",
        id: "597c20aca24f308ba8a0306a62ba8978",
        name: ". Market Dynamics and New Entrants",
        parent: "Governance",
        incidents: 1,
      },
    ],
    esg_sdgs: [],
    esg_pais: [],
    total: 4,
  },
  Environmental: {
    esg_factors: [
      {
        _id: "65602e4e470437114e3852aa",
        id: "df552c6a729f342db51ca82db92928a5",
        name: "Energy and Greenhouse Gas Management",
        parent: "Environmental",
        incidents: 2,
      },
      {
        _id: "65602e4e470437114e3852ab",
        id: "aadf11fbacdd308fbee5a19a78170a8e",
        name: "Hazardous Materials Management",
        parent: "Environmental",
        incidents: 1,
      },
      {
        _id: "65602e4e470437114e3852b1",
        id: "ed1691b20a71321ca27215bfd7e783bf",
        name: "Air Quality Management",
        parent: "Environmental",
        incidents: 1,
      },
      {
        _id: "65602e4e470437114e3852f3",
        id: "2d99f3b0264834c1807402ed10499a64",
        name: ". Climate Change Mitigation and Adaptation",
        parent: "Environmental",
        incidents: 1,
      },
    ],
    esg_sdgs: [],
    esg_pais: [],
    total: 5,
  },
};



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const lastDate = dateButtonObj[3].type;

const networkData = {
  end: moment().format("YYYY-MM-DD"),
  start: moment(calculatePreviousDate(new Date(), lastDate)).format(
    "YYYY-MM-DD"
  ),
};

const getActiveItem = (number) => {
  if (number == 0 || (number > 0 && number < 480)) {
    return "Overview";
  } else if (number >= 780 && number <= 3000) {
    return "Risk Analysis";
  } else if (number >= 3001 && number <= 5278) {
    return "Breaches & violation";
  } else if (number >= 5279 && number <= 6275) {
    return "Incident Analysis";
  } else if (number >= 6276 && number <= 25000) {
    return "ESG Incident Categories";
  }
};

let countryDataObj = {
  AF: "Africa",
  AN: "Antarctica",
  AS: "Asia",
  EU: "Europe",
  NA: "North America",
  OC: "Oceania",
  SA: "South America",
};
let mergedData;

let previousPercentageData = {
  percentage: "",
  value: 0,
};
const getProcessBarPercentage = (index, value) => {
  const percentageObj = {
    0: "50%",
    1: "45%",
    2: "35%",
    3: "30%",
    4: "25%",
    5: "20%",
    6: "15%",
    7: "13%",
    8: "10%",
    9: "8%",
  };
  if (value == previousPercentageData.value) {
    return previousPercentageData.percentage;
  }
  previousPercentageData = {
    percentage: percentageObj[index],
    value,
  };
  return percentageObj[index];
};
const PortfolioDetails = () => {
  const [headerSmallState, setHeaderSmallState] = useState(false);
  const [portCompShowMore, setPortCompShowMore] = useState(false);
  const [alertReportShowMore, setAlertReportShowMore] = useState(false);
  const [noOfIncidentShowMore, setNoOfIncidentShowMore] = useState(false);
  const [riskScorePerCompanyShowMore, setRiskScorePerCompanyShowMore] =
    useState(false);
  const [activeItem, setActiveItem] = useState("Overview");
  const location = useLocation();
  const { id, backgroundCardColor, colorText } = location.state;
  const [rootLevelIncidentModalItem, setRootLevelIncidentModalItem] =
    useState(false);
  const [topLevelIncidentListModalPayload] = useState({
    ...networkData,
    companies: [id],
  });
  const [tabKey, setTabKey] = useState("1");
  const [tabVal, setTabVal] = useState("Environment");
  // toggle........
  const navigate = useNavigate();
  // ........
  const companyData = useSelector(
    (state) => state?.companyPageReducer?.getCompanyDetails
  );

  const { IncidentDistributionOverTime: dummyData } = useSelector(
    (state) => state?.companyPageReducer
  );

  const {
    incident,
    severityDataLocationArray,
    continents,
    totalEsgIncidentCount,
  } = useSelector((state) => state?.incidentReducer);

  let update = continents.map((val) =>
    countryDataObj[val?.continent_code]
      ? { ...val, continent_code: countryDataObj[val?.continent_code] }
      : { ...val }
  );

  const {
    companyPortGraphData,
    portfolioDetail,
    alertReportData,
    riskScoreCompData,
    highSeverityIncidentData,
    distributionGraphData,
    clearAlertRulesSuccess,
    portfolioStatusData,
    portfolioStatusLoader,
    portfolioDetailLoader,
    riskScoreCompLoader,
    portfolioWeightDistributionData
  } = useSelector((state) => state?.portfolio);

  const [timelineIndidentList, setTimelineIndidentList] = useState(false);
  const [modalVisibleCompList, setModalVisibleCompList] = useState(false)
  const [incidentIdArray, setIncidentIdArray] = useState([]);
  const [searchToggle, setSearchToggle] = useState(false);
  const [date, setDate] = useState(false);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useLayoutEffect(() => {
    setDate(false)
    dispatch(resetPortFolioPageDataAction())
    dispatch(
      portfolioDetialLoad({
        id,
      })
    );
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const activeTab = getActiveItem(scrollTop);
      if (activeTab === "Incidents") {
        setHeaderSmallState(true);
      }
      if (activeTab === "Overview") {
        setHeaderSmallState(false);
      }
      setActiveItem(activeTab);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let companies =
      portfolioDetail?.holdingsArray?.length > 0
        ? portfolioDetail?.holdingsArray.map((item) => item.id)
        : [];
    scrollTo(0.0);
    dispatch(getIncidentCountLoad({ ...networkData, companies }));
    networkCall(networkData);

    return () => {
      dispatch(ResetDataAction());
    };
  }, [portfolioDetail]);



  useEffect(() => {
    if (Object.entries(portfolioDetail).length > 0) {
      const cdata = {
        companies:
          portfolioDetail?.holdingsArray?.length > 0
            ? portfolioDetail?.holdingsArray.map((item) => item.id)
            : [],
        start: networkData.start,
        end: networkData.end,
        limit: 10,
        offset: 0,
      };
      dispatch(
        incindentAction({
          ...cdata,
          esg_categories: [
            tabKey == "1"
              ? "Environmental"
              : tabKey == "2"
                ? "Social"
                : "Governance",
          ],
        })
      );
    }
  }, [tabKey, portfolioDetail]);

  useEffect(() => {
    if (Object.entries(totalEsgIncidentCount).length > 0) {
      if (totalEsgIncidentCount?.Environmental > 0) {
        setTabKey("1");
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social > 0
      ) {
        setTabKey("2");
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social == 0 &&
        totalEsgIncidentCount?.Governance > 0
      ) {
        setTabKey("3");
      } else {
        setTabKey("1");
      }
    }
  }, [totalEsgIncidentCount]);

  useEffect(() => {
    if (clearAlertRulesSuccess) {
      dispatch(getAlertReportLoad({ id }));
      dispatch(updatePortfolioState(false, "clearAlertRulesSuccess"));
    }
  }, [clearAlertRulesSuccess]);

  const networkCall = (data) => {
    const cdata = {
      companies:
        portfolioDetail?.holdingsArray?.length > 0
          ? portfolioDetail?.holdingsArray.map((item) => item.id)
          : [],
      start: networkData.start,
      end: networkData.end,
      limit: 10,
      offset: 0,
    };
    mergedData = cdata;
    dispatch(incidentMapAction({ ...cdata, type: "home" }));
    dispatch(getPortfolioStatusLoad({ id, start: data.start, end: data.end }));
    dispatch(portfolioCompLoad({ id, start: data.start, end: data.end }));
    dispatch(mostFrequentEsgLoad({ id, start: data.start, end: data.end }));
    dispatch(unGlobalgraphLoad({ id, start: data.start, end: data.end }));
    dispatch(distributionGraphLoad({ id, start: data.start, end: data.end }));
    dispatch(getAlertReportLoad({ id }));
    dispatch(PortfolioESGRiskLoad({ id, start: data.start, end: data.end }));
    dispatch(getRiskScorePerCompLoad({ id, start: data.start, end: data.end }));
    dispatch(
      getHighSeverityIncidentLoad({ id, start: data.start, end: data.end })
    );
    dispatch(getPortfolioWeightLoad({ ...cdata, id }));
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onDatePickerChange = (startDate, previousDate) => {
    if (startDate && previousDate) {
      networkData.start = startDate;
      networkData.end = previousDate;
      dispatch(
        portfolioDetialLoad({
          id,
        })
      );
      networkCall(networkData);
      setDate(!date);
    }
  };

  const callDownloadApi = () => {
   
    dispatch(getDownloadDataAction({companies: id,start: networkData.start,end: networkData.end}));
  };
  return (
    <div className="side-padding aaa ">
      <div
        className="dashbored-head"
        style={headerSmallState ? { height: "118px" } : {}}
      >
        {!searchToggle ? (
          <Banner
            portfolioDetail={portfolioDetail}
            backgroundCardColor={backgroundCardColor}
            colorText={colorText}
          />
        ) : null}
      </div>
      <div
        className="main-layout top-space"
        style={headerSmallState ? { marginTop: "154px" } : {}}
      >
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-3 top-margin-comp containerMidWidth ">
              <div className="hone-left-side1">
                <div className="home-left-list mt-3">
                  <ul>
                    <li
                      className={activeItem == "Overview" ? "tab-active" : ""}
                    >
                      <a onClick={() => scrollTo(0)}> Overview </a>
                    </li>
                    <li
                      className={
                        activeItem == "Risk Analysis" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(780)}>1   Risk Analysis</a>
                    </li>
                    <li
                      className={
                        activeItem == "Breaches & violation"
                          ? "tab-active"
                          : ""
                      }
                    >
                      <a onClick={() => scrollTo(3001)}>
                        2   Breaches & violation
                      </a>
                    </li>
                    <li
                      className={
                        activeItem == "Incident Analysis" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(5279)}>
                        3   Incident Analysis
                      </a>
                    </li>
                    <li
                      className={
                        activeItem == "ESG Incident Categories"
                          ? "tab-active"
                          : ""
                      }
                    >
                      <a onClick={() => scrollTo(6276)}>
                        4   ESG Incident Categories
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-9 containerWidth ml-32">
              <section>
                <div className="containerWidth g-0">
                  <div className="row  mt-2">
                    <div className="top-right-home-drop">
                      <div>
                        {/* <a href=""> */}
                        <img
                          onClick={() => {
                            callDownloadApi();
                          }}
                          src={Images.framedow}
                        />
                        {/* </a> */}
                      </div>
                      {/* social  share button  model MM  */}
                      <Modal
                        title={"Share"}
                        centered
                        className="share"
                        open={isModalOpen}
                        footer={false}
                        onCancel={() => setIsModalOpen(false)}
                        mask
                      >
                        <FacebookShareButton
                          url={"www.google.com"}
                          quote={"asasas"}
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>

                        <LinkedinShareButton
                          url={"www.google.com"}
                          title={"asasas"}
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>

                        <TwitterShareButton
                          url={"www.google.com"}
                          title={"asasasa"}
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                      </Modal>
                      <DatePicker
                        onDatePickerChange={onDatePickerChange}
                        lastDate={lastDate}
                        className="custom_class"
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className="containerWidth g-0 port-bot-spacing"></div>
              </section>
              <div className="collapse-section-ps containerWidth portfolio-collapse">
                <Collapse
                  defaultActiveKey={[
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                  ]}
                  className="company-detail-collapse"
                  expandIcon={({ isActive }) => {
                    return (
                      <img
                        src={Images.iconDown}
                        style={{
                          transform: `rotate(${isActive ? "0" : "-90"}deg)`,
                        }}
                      />
                    );
                  }}
                >
                  <section>
                    <div className="main_heading_container_portfolio">
                      <div className="portfolio_container_1">
                        <div
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className={style.leftDataContainer}>
                            <div className={style.redDot}></div>
                            <span className="heading_portfolio">
                              Alert Report
                            </span>
                            <span
                              className={`${style.CaptionXL} ${style.CaptionXLfw}`}
                            >
                              {`Last Updated on ${moment(portfolioDetail?.updatedAt).format("MMM, DD YYYY")}`}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              dispatch(
                                clearAlertRulesLoad({
                                  portfolioId: portfolioDetail?._id,
                                })
                              )
                            }
                          >
                            <span
                              className={style.ButtonS}
                              style={{ cursor: "pointer" }}
                            >
                              Clear alert report
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="portfolio_container_1 pt0">
                        {alertReportData && alertReportData.length > 0 ? (
                          alertReportData.map((item, index) => {
                            if (!alertReportShowMore && index < 3) {
                             
                              const { riskColorClass } =
                                getStrokeColorGradient(item?.alerts?.risk?.current);
                              return (
                                <Collapse
                                  className={`sub_collapse ${index > 0 && "greater_index_0"
                                    }`}
                                  expandIconPosition="right"
                                  expandIcon={({ isActive }) =>
                                    isActive ? (
                                      <img src={Images.chevronUp} />
                                    ) : (
                                      <img src={Images.chevronDown} />
                                    )
                                  }
                                  items={[
                                    {
                                      key: "1",
                                      label: (
                                        <div style={{ display: "flex" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              width: "35%",
                                            }}
                                          >
                                            <div
                                              className={style.CaptionXL}
                                              style={{
                                                background: "#EFF1F6",
                                                height: "20px",
                                                borderRadius: "50%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "0 7px",
                                              }}
                                            >
                                              <span style={{ minWidth: "" }}>
                                                {index + 1}
                                              </span>
                                            </div>
                                            {/* <Badge count={9}></Badge> */}
                                            <span
                                              className={style.value2}
                                              style={{ paddingLeft: "8px" }}
                                            >
                                              <p>
                                                {item?.holdings?.company || ""}
                                              </p>
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              width: "65%",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span className={style.CaptionXL}>
                                              {
                                                checkArrayLengthWithTrueVal(
                                                  item
                                                ).values
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      ),
                                      children: (
                                        <>
                                          {
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                                justifyContent: "space-between",
                                                padding: "25px 0",
                                                borderBottom:
                                                  "1px solid #E4E6ED",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  color: "#0D0D0E",
                                                  fontFamily: "Inter",
                                                  fontSize: "13px",
                                                  fontStyle: "normal",
                                                  fontWeight: "600",
                                                  lineHeight: "24px",
                                                }}
                                              >
                                                {`${item["alerts"].riskChangeValue.toFixed(2)}% increase in risk score`}
                                              </div>
                                              <div
                                                style={{
                                                  width: "59.6%",
                                                  display: "flex",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    height: "20px",
                                                    minWidth: "30px",
                                                  }}
                                                >
                                                  {item?.risk &&
                                                    Math.ceil(item?.risk) >=
                                                    75 && (
                                                      <img
                                                        style={{
                                                          height: "20px",
                                                          width: "20px",
                                                        }}
                                                        src={Images.dangerIcon}
                                                      />
                                                    )}
                                                </div>
                                                <span
                                                  className={`${style.BodySelected} ${riskColorClass}`}
                                                  style={{
                                                    marginRight: "16px",
                                                  }}
                                                >
                                                  {item?.alerts?.risk?.current
                                                    ? Math.ceil(item?.alerts?.risk?.current)
                                                    : 44}
                                                </span>
                                                <Progress
                                                  className="progress_portfolio_sec"
                                                  percent={item?.alerts?.risk?.current}
                                                  showInfo={false}
                                                  strokeLinecap="round"
                                                  {...getStrokeColorGradient(
                                                    item?.alerts?.risk?.current
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          }
                                          {/* {checkArrayLengthWithTrueVal(
                                            item
                                          )?.arrayVal?.includes(
                                            "Risk score change"
                                          ) && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  width: "100%",
                                                  justifyContent: "space-between",
                                                  padding: "25px 0",
                                                  borderBottom:
                                                    "1px solid #E4E6ED",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#0D0D0E",
                                                    fontFamily: "Inter",
                                                    fontSize: "13px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    lineHeight: "24px",
                                                  }}
                                                >
                                                  {"5% increase in risk score"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "59.6%",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      height: "20px",
                                                      minWidth: "30px",
                                                    }}
                                                  >
                                                    {item?.risk &&
                                                      Math.ceil(item?.risk) >=
                                                      75 && (
                                                        <img
                                                          style={{
                                                            height: "20px",
                                                            width: "20px",
                                                          }}
                                                          src={Images.dangerIcon}
                                                        />
                                                      )}
                                                  </div>
                                                  <span
                                                    className={`${style.BodySelected} ${riskColorClass}`}
                                                    style={{
                                                      marginRight: "16px",
                                                    }}
                                                  >
                                                    {item?.alerts?.risk?.current
                                                      ? Math.ceil(item?.alerts?.risk?.current)
                                                      : 44}
                                                  </span>
                                                  <Progress
                                                    className="progress_portfolio_sec"
                                                    percent={item?.alerts?.risk?.current}
                                                    showInfo={false}
                                                    strokeLinecap="round"
                                                    {...getStrokeColorGradient(
                                                      item?.alerts?.risk?.current
                                                    )}
                                                  />
                                                </div>
                                              </div>
                                            )} */}
                                          {checkArrayLengthWithTrueVal(
                                            item
                                          )?.arrayVal?.includes(
                                            "Sentiment shift"
                                          ) && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  width: "100%",
                                                  borderBottom:
                                                    "1px solid #E4E6ED",
                                                }}
                                              >
                                                <div //
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "50%",
                                                    padding: "25px 0px",
                                                    color: "#0D0D0E",
                                                    fontFamily: "Inter",
                                                    fontSize: "13px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    lineHeight: "24px",
                                                  }}
                                                >
                                                  {"50% negative shift"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "50%",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div //
                                                    style={{
                                                      display: "flex",
                                                      width: "100%",
                                                      flexDirection: "column",
                                                      padding:
                                                        "41px 0px 32px 0px",
                                                    }}
                                                  >
                                                    <MultiSlider
                                                      name={"sdfsdfds"}
                                                      className="triColor  triWidth"
                                                      lableTextColor={[
                                                        "#2D303A",
                                                        "#5E0000",
                                                      ]}
                                                      labelBackground={[
                                                        "#E7E9F0",
                                                        "#F8D9D9",
                                                      ]}
                                                      data={49}
                                                      keyName={"portfolioDetail"}
                                                    />
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          color: "#1A9C3E",
                                                          marginTop: "4px",
                                                          fontFamily: "Inter",
                                                          fontSize: "12px",
                                                          fontStyle: "normal",
                                                          fontWeight: "500",
                                                          lineHeight: "16px",
                                                        }}
                                                      >
                                                        Pos.
                                                      </div>
                                                      <div
                                                        style={{
                                                          color: "#676A73",
                                                          marginTop: "4px",
                                                          fontFamily: "Inter",
                                                          fontSize: "12px",
                                                          fontStyle: "normal",
                                                          fontWeight: "500",
                                                          lineHeight: "16px",
                                                        }}
                                                      >
                                                        Neutral
                                                      </div>
                                                      <div
                                                        style={{
                                                          color: "#D00000",
                                                          marginTop: "4px",
                                                          fontFamily: "Inter",
                                                          fontSize: "12px",
                                                          fontStyle: "normal",
                                                          fontWeight: "500",
                                                          lineHeight: "16px",
                                                        }}
                                                      >
                                                        Neg.
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          {checkArrayLengthWithTrueVal(
                                            item
                                          )?.arrayVal?.includes(
                                            "Severe incident"
                                          ) && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "32px 0px 0px 0px",
                                                    color: "#0D0D0E",
                                                    fontFamily: "Inter",
                                                    fontSize: "13px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    lineHeight: "24px",
                                                    width: "50%",
                                                  }}
                                                >
                                                  {"Two high severity incidents"}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "50",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      width: "100%",
                                                      flexDirection: "column",
                                                      padding: "32px 0px 0px 0px",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        color: "#0195CE",
                                                        fontFamily: "Inter",
                                                        fontSize: "12px",
                                                        fontStyle: "normal",
                                                        fontWeight: "500",
                                                        lineHeight: "16px",
                                                        padding: "0px 16px",
                                                      }}
                                                    >
                                                      View Incidents
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                              );
                            } else if (alertReportShowMore) {
                              return (
                                <Collapse
                                  className={`sub_collapse ${index > 0 && "greater_index_0"
                                    }`}
                                  expandIconPosition="right"
                                  expandIcon={({ isActive }) =>
                                    isActive ? (
                                      <img src={Images.chevronDown} />
                                    ) : (
                                      <img src={Images.chevronDown} />
                                    )
                                  }
                                  items={[
                                    {
                                      key: "1",
                                      label: (
                                        <div style={{ display: "flex" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              width: "35%",
                                            }}
                                          >
                                            <div
                                              className={style.CaptionXL}
                                              style={{
                                                background: "#EFF1F6",
                                                height: "20px",
                                                borderRadius: "50%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "0 7px",
                                              }}
                                            >
                                              <span style={{ minWidth: "" }}>
                                                {index + 1}
                                              </span>
                                            </div>
                                            {/* <Badge count={9}></Badge> */}
                                            <span
                                              className={style.value2}
                                              style={{ paddingLeft: "8px" }}
                                            >
                                              <p>
                                                {item?.holdings?.company || ""}
                                              </p>
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              width: "65%",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span className={style.CaptionXL}>
                                              {
                                                checkArrayLengthWithTrueVal(
                                                  item
                                                ).values
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      ),
                                      children: (
                                        <>
                                          {checkArrayLengthWithTrueVal(
                                            item
                                          )?.arrayVal?.includes(
                                            "Risk score change"
                                          ) && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  width: "100%",
                                                  justifyContent: "space-between",
                                                  padding: "25px 0",
                                                  borderBottom:
                                                    "1px solid #E4E6ED",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#0D0D0E",
                                                    fontFamily: "Inter",
                                                    fontSize: "13px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    lineHeight: "24px",
                                                  }}
                                                >
                                                  {`${item["alerts"].riskChangeValue.toFixed(2)}% increase in risk score`}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "59.6%",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      height: "20px",
                                                      minWidth: "30px",
                                                    }}
                                                  >
                                                    {item?.risk &&
                                                      Math.ceil(item?.risk) >=
                                                      75 && (
                                                        <img
                                                          style={{
                                                            height: "20px",
                                                            width: "20px",
                                                          }}
                                                          src={Images.dangerIcon}
                                                        />
                                                      )}
                                                  </div>
                                                  <span
                                                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                                                    style={{
                                                      marginRight: "16px",
                                                    }}
                                                  >
                                                    {item?.risk
                                                      ? Math.ceil(item?.risk)
                                                      : 44}
                                                  </span>
                                                  <Progress
                                                    className="progress_portfolio_sec"
                                                    percent={70}
                                                    showInfo={false}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          {checkArrayLengthWithTrueVal(
                                            item
                                          )?.arrayVal?.includes(
                                            "Sentiment shift"
                                          ) && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  width: "100%",
                                                  borderBottom:
                                                    "1px solid #E4E6ED",
                                                }}
                                              >
                                                <div //
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "50%",
                                                    padding: "25px 0px",
                                                    color: "#0D0D0E",
                                                    fontFamily: "Inter",
                                                    fontSize: "13px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    lineHeight: "24px",
                                                  }}
                                                >
                                                  {"50% negative shift"}
                                                </div>
                                                <div
                                                  style={{
                                                    width: "50%",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div //
                                                    style={{
                                                      display: "flex",
                                                      width: "100%",
                                                      flexDirection: "column",
                                                      padding:
                                                        "41px 0px 32px 0px",
                                                    }}
                                                  >
                                                    <MultiSlider
                                                      name={"sdfsdfds"}
                                                      className="triColor  triWidth"
                                                      lableTextColor={[
                                                        "#2D303A",
                                                        "#5E0000",
                                                      ]}
                                                      labelBackground={[
                                                        "#E7E9F0",
                                                        "#F8D9D9",
                                                      ]}
                                                      data={49}
                                                      keyName={"portfolioDetail"}
                                                    />
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          color: "#1A9C3E",
                                                          marginTop: "4px",
                                                          fontFamily: "Inter",
                                                          fontSize: "12px",
                                                          fontStyle: "normal",
                                                          fontWeight: "500",
                                                          lineHeight: "16px",
                                                        }}
                                                      >
                                                        Pos.
                                                      </div>
                                                      <div
                                                        style={{
                                                          color: "#676A73",
                                                          marginTop: "4px",
                                                          fontFamily: "Inter",
                                                          fontSize: "12px",
                                                          fontStyle: "normal",
                                                          fontWeight: "500",
                                                          lineHeight: "16px",
                                                        }}
                                                      >
                                                        Neutral
                                                      </div>
                                                      <div
                                                        style={{
                                                          color: "#D00000",
                                                          marginTop: "4px",
                                                          fontFamily: "Inter",
                                                          fontSize: "12px",
                                                          fontStyle: "normal",
                                                          fontWeight: "500",
                                                          lineHeight: "16px",
                                                        }}
                                                      >
                                                        Neg.
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          {checkArrayLengthWithTrueVal(
                                            item
                                          )?.arrayVal?.includes(
                                            "Severe incident"
                                          ) && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "32px 0px 0px 0px",
                                                    color: "#0D0D0E",
                                                    fontFamily: "Inter",
                                                    fontSize: "13px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    lineHeight: "24px",
                                                    width: "50%",
                                                  }}
                                                >
                                                  {"Two high severity incidents"}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "50",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      width: "100%",
                                                      flexDirection: "column",
                                                      padding: "32px 0px 0px 0px",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        color: "#0195CE",
                                                        fontFamily: "Inter",
                                                        fontSize: "12px",
                                                        fontStyle: "normal",
                                                        fontWeight: "500",
                                                        lineHeight: "16px",
                                                        padding: "0px 16px",
                                                      }}
                                                    >
                                                      View Incidents
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                              );
                            }
                          })
                        ) : (
                          <div
                            style={{
                              height: "56px",
                              background: "#fff",
                              borderRadius: "8px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span className="no-alert-data">
                              There are no new updates on your portfolio!
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="portfolio_container_1 pt0">
                        <div
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className={style.leftDataContainer}
                            onClick={() =>
                              navigate("/createPortfolio", {
                                state: {
                                  portfolioId: portfolioDetail?._id,
                                  editStep: 2,
                                  screen: "/portfolioDetails",
                                },
                              })
                            }
                          >
                            <span className={style.ButtonS}>
                              Edit alert rules
                            </span>
                          </div>
                          <div
                            className={style.value}
                            style={{ gap: "8px", cursor: "pointer" }}
                            onClick={() => setAlertReportShowMore(true)}
                          >
                            {!alertReportShowMore &&
                              alertReportData &&
                              alertReportData.length > 3 && (
                                <>
                                  <p>View more</p>
                                  <img
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      marginTop: "2px",
                                    }}
                                    src={Images.blueDownArrow}
                                  />
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="pt24">
                    <div className="main_heading_container_portfolio backgroundTransparent">

                      <div className="portfolio_container_1">
                        <div
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div className={style.leftDataContainer}>
                            <span className="heading_portfolio">
                              Your Portfolio Status
                            </span>
                            <img src={Images.circleInfo} />
                            <div
                              className={style.infoLabel}
                              style={{
                                background: `${Object.keys(portfolioStatusData)?.length >
                                  0 && portfolioStatusData?.charData?.risk
                                  ? portfolioStatusData?.charData?.risk > 75
                                    ? "#FF0000"
                                    : portfolioStatusData?.charData?.risk >=
                                      51
                                      ? "#FF7518"
                                      : portfolioStatusData?.charData?.risk >=
                                        25
                                        ? "#FFBF00"
                                        : "#00A36C"
                                  : "#00A36C"
                                  }`,
                              }}
                            >
                              <span className={style.BodySelected}>
                                {Object.keys(portfolioStatusData)?.length > 0 &&
                                  portfolioStatusData?.charData?.risk
                                  ? portfolioStatusData?.charData?.risk > 75
                                    ? "High Risk"
                                    : portfolioStatusData?.charData?.risk >= 51
                                      ? "Medium Risk"
                                      : portfolioStatusData?.charData?.risk >=
                                        25
                                        ? "Low Risk"
                                        : "Secure"
                                  : "Secure"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!portfolioStatusLoader ?
                        <>
                          <div className="portfolio_container_1 pt0">
                            <div
                              className=""
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                gap: "8px",
                              }}
                            >
                              <div
                                style={{ width: "456px" }}
                                className={style.leftDataContainer}
                              >
                                {Object.keys(portfolioStatusData)?.length > 0 &&
                                  portfolioStatusData?.charData?.risk != null &&
                                  portfolioStatusData?.charData?.risk > 75 ? (
                                  <ul>
                                    <li>

                                      {`Holdings has an average of ${Math.round(portfolioStatusData?.averageIncidents) || 0} incidents each`}
                                    </li>
                                    <li>
                                      {portfolioStatusData?.highRiskHolding &&
                                        portfolioStatusData?.highRiskHolding > 0
                                        ? `${portfolioStatusData?.highRiskHolding || 0} out of ${portfolioStatusData?.totalHoldings || 0} holdings a have high ESG risk score`
                                        : "None of the holdings has a high ESG risk score"}
                                    </li>
                                    <li>
                                      {`Approximately ${portfolioStatusData?.highSeverityIncidentPercentage?.toFixed(2) || 0}% of the incidents are classified as Critical`}
                                    </li>
                                    <li>
                                      {`The Social Network Perception towards the holdings is approximately 
                                      ${Math.round(portfolioStatusData?.negativePercentage) || 0}% negative`}
                                    </li>
                                  </ul>
                                ) : (
                                  <ul>
                                    <li>
                                      {`Holdings has an average of ${Math.round(portfolioStatusData?.averageIncidents) || 0} incidents each`}
                                    </li>
                                    <li>
                                      {portfolioStatusData?.highRiskHolding &&
                                        portfolioStatusData?.highRiskHolding > 0
                                        ? `${portfolioStatusData?.highRiskHolding || 0} out of ${portfolioStatusData?.totalHoldings || 0} holdings a have high ESG risk score`
                                        : "None of the holdings has a high ESG risk score"}
                                    </li>
                                    <li>
                                      {`Approximately ${Math.round(portfolioStatusData?.highSeverityIncidentPercentage) || 0}% of the incidents are classified as Critical`}
                                    </li>
                                    <li>
                                      {portfolioStatusData?.negativePercentage &&
                                        portfolioStatusData?.negativePercentage > 50
                                        ? `The Social Network Perception towards the holdings is approximately 
                                      ${Math.round(portfolioStatusData?.negativePercentage) || 0}% negative`
                                        : "The Social Network Perception towards the holdings is predominantly positive"}
                                    </li>
                                  </ul>
                                )}
                              </div>
                              <div style={{ width: "240px" }}>
                                {Object.keys(portfolioStatusData)?.length > 0 && (
                                  <RiskSevertyComp
                                    data={
                                      Object.keys(portfolioStatusData)?.length > 0
                                        ? portfolioStatusData?.charData
                                        : null
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          {Object.keys(portfolioStatusData)?.length > 0 &&
                            portfolioStatusData?.charData?.risk != null &&
                            portfolioStatusData?.charData?.risk > 75 ? (
                            <div className="portfolio_container_1 pt0">
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className={`${style.leftDataContainer} ${style.customBackgroundColor}`}
                                >
                                  <img src={Images.Alert} />
                                  <span
                                    className={`${style.BodySelected} ${style.customColorAlert}`}
                                  >
                                    Your portfolio carries high risk, necessitating
                                    careful monitoring and strategic adjustments!
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : portfolioStatusData?.charData?.risk >= 51 ? (
                            <div className="portfolio_container_1 pt0">
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className={`${style.leftDataContainer} ${style.customBackgroundColor2}`}
                                >
                                  <img src={Images.Alert2} />
                                  <span
                                    className={`${style.BodySelected} ${style.customColorAlert2}`}
                                  >
                                    Your portfolio exhibits a moderate level of
                                    risk, It requires your proactive management
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : portfolioStatusData?.charData?.risk >= 25 ? (
                            <div className="portfolio_container_1 pt0">
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className={`${style.leftDataContainer} ${style.customBackgroundColor3}`}
                                >
                                  <img src={Images.Alert3} />
                                  <span
                                    className={`${style.BodySelected} ${style.customColorAlert3}`}
                                  >
                                    Your portfolio maintains a low level of risk,
                                    providing stability and security
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="portfolio_container_1 pt0">
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className={`${style.leftDataContainer} ${style.customBackgroundColor4}`}
                                >
                                  <img src={Images.Alert4} />
                                  <span
                                    className={`${style.BodySelected} ${style.customColorAlert4}`}
                                  >
                                    Your portfolio is secure, reflecting a robust
                                    and stable investment position
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </> :
                        <div style={{ display: "flex", marginBottom: "20px", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                          <GlobalLoder />
                        </div>
                      }
                    </div>
                  </section>
                  <Collapse.Panel
                    id="9Key"
                    className="company-collapse-header special_padding_rem"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          1
                        </span>
                        Risk Analysis
                      </h4>
                    }
                    key="1"
                  >
                    <div className="port-page-bot-spacing">
                      {!portfolioDetailLoader ? < PieChartComponent
                        {...networkData}
                        id={id}
                        dateChange={date}
                        companyName={companyData?.name}
                        risk={
                          portfolioDetail?.risk
                            ? Math.round(portfolioDetail?.risk)
                            : 0
                        }
                      /> :
                        <div style={{ display: "flex", marginBottom: "20px", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                          <GlobalLoder />
                        </div>
                      }
                    </div>
                    {/* Portfolio Companies */}
                    <div className="port-page-bot-spacing">
                      <SupplyChainTable
                        id={companyData?.id}
                        data={
                          portCompShowMore
                            ? companyPortGraphData
                            : companyPortGraphData.slice(0, 5)
                        }
                        columns={supplyChainColumns}
                        date={{ ...networkData }}
                        expandDetClick={() => setModalVisibleCompList(true)}
                        goTo={"/company"}
                        title={
                          <h4>
                            Portfolio Companies
                            <img
                              src={Images.circleInfo}
                              className="info-icon-company-page"
                            />
                          </h4>
                        }
                        expandDet={"View all Companies"}
                        showRow={false}
                        expendBtn={[portCompShowMore, setPortCompShowMore]}
                        footerTitle={"View all Companies"}
                      />
                    </div>
                    {/*Risk Score Per Company */}
                    <div className="port-page-bot-spacing">
                      <section className="left-text">
                        <ChartCard
                          showTimeBarOnTop={false}
                          title={"Risk Score Per Company"}
                        >
                          {!riskScoreCompLoader ? <div className="portfolio_container_1 custom_container_portfolio_1">
                            {riskScoreCompData &&
                              riskScoreCompData.length > 0 &&
                              riskScoreCompData.map((item, index) => {
                                if (
                                  !riskScorePerCompanyShowMore &&
                                  index < 10
                                ) {
                                  const { riskColorClass } =
                                    getStrokeColorGradient(item?.risk);
                                  return (
                                    <div
                                      className=""
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{ width: "50%" }}
                                        className={style.leftDataContainer}
                                      >
                                        <div
                                          className={style.CaptionXL}
                                          style={{
                                            background: "#EFF1F6",
                                            height: "20px",
                                            borderRadius: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0 7px",
                                          }}
                                        >
                                          <span style={{ minWidth: "" }}>
                                            {index + 1}
                                          </span>
                                        </div>
                                        {/* <Badge count={9}></Badge> */}
                                        <span
                                          className={style.body}
                                          style={{
                                            paddingLeft: "8px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <p
                                            onClick={() =>
                                              navigate("/companyPage", {
                                                state: { id: item?.id },
                                              })
                                            }
                                          >
                                            {item?.company || ""}
                                          </p>
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: "20px",
                                            minWidth: "30px",
                                          }}
                                        >
                                          {item?.risk
                                            ? Math.ceil(item?.risk) >= 75 && (
                                              <img
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                }}
                                                src={Images.dangerIcon}
                                              />
                                            )
                                            : ""}
                                        </div>
                                        <span
                                          className={`${style.BodySelected} ${riskColorClass}`}
                                          style={{ marginRight: "16px" }}
                                        >
                                          {item?.risk
                                            ? Math.round(item?.risk)
                                            : 0}
                                        </span>
                                        <Progress
                                          className="progress_portfolio_sec"
                                          percent={item?.risk}
                                          showInfo={false}
                                          strokeLinecap="round"
                                          {...getStrokeColorGradient(
                                            item?.risk
                                          )}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else if (riskScorePerCompanyShowMore) {
                                  const { riskColorClass } =
                                    getStrokeColorGradient(item?.risk);
                                  return (
                                    <div
                                      className=""
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{ width: "50%" }}
                                        className={style.leftDataContainer}
                                      >
                                        <div
                                          className={style.CaptionXL}
                                          style={{
                                            background: "#EFF1F6",
                                            height: "20px",
                                            borderRadius: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0 7px",
                                          }}
                                        >
                                          <span style={{ minWidth: "" }}>
                                            {index + 1}
                                          </span>
                                        </div>
                                        {/* <Badge count={9}></Badge> */}
                                        <span
                                          className={style.body}
                                          style={{
                                            paddingLeft: "8px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <p
                                            onClick={() =>
                                              navigate("/companyPage", {
                                                state: { id: item?.id },
                                              })
                                            }
                                          >
                                            {item?.company || ""}
                                          </p>
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: "20px",
                                            minWidth: "30px",
                                          }}
                                        >
                                          {item?.risk &&
                                            Math.ceil(item?.risk) >= 75 && (
                                              <img
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                }}
                                                src={Images.dangerIcon}
                                              />
                                            )}
                                        </div>
                                        <span
                                          className={`${style.BodySelected} ${riskColorClass}`}
                                          style={{ marginRight: "16px" }}
                                        >
                                          {item?.risk
                                            ? Math.round(item?.risk)
                                            : 0}
                                        </span>
                                        <Progress
                                          className="progress_portfolio_sec"
                                          percent={item?.risk || 0}
                                          showInfo={false}
                                          strokeLinecap="round"
                                          {...getStrokeColorGradient(
                                            item?.risk
                                          )}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </div> :
                            <div style={{ display: "flex", marginBottom: "20px", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                              <GlobalLoder />
                            </div>
                          }
                        </ChartCard>
                      </section>
                    </div>
                    {/* Portfolio Weight / Risk Distribution */}
                    <IncidentsByESGCategories
                      title={"Portfolio Weight / Risk Distribution"}
                      data={portfolioWeightDistributionData}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel
                    id="9Key"
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          2
                        </span>
                        Breaches & violation
                      </h4>
                    }
                    key="2"
                  >
                    <UnPrinciple
                      mergedData={networkData}
                      portfolioDetail={portfolioDetail}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel
                    id="9Key"
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          3
                        </span>
                        Incident Analysis
                      </h4>
                    }
                    key="3"
                  >
                    {/* */}
                    <div className="port-page-bot-spacing">
                      <IncidentTable
                        id={companyData?.id}
                        companyName={
                          portfolioDetail?.holdingsArray?.length > 0
                            ? portfolioDetail?.holdingsArray.map((item) => item.name)
                            : []
                        }
                        idsArray={
                          portfolioDetail?.holdingsArray?.length > 0
                            ? portfolioDetail?.holdingsArray.map((item) => item.id)
                            : []
                        }
                        data={incident?.data?.slice(0, 4)}
                        columns={columns1}
                        date={{ ...networkData }}
                        goTo={"/company"}
                        title={
                          <h4>
                            Incidents List
                            <img
                              src={Images.circleInfo}
                              className="info-icon-company-page"
                            />
                          </h4>
                        }
                        heading={"View full list"}
                        tabKey={[tabKey, setTabKey]}
                        tabVal={[tabVal, setTabVal]}
                        tabShowCount={totalEsgIncidentCount}
                      />
                    </div>
                    {/*Total Number of Incidents / High Severity Incidents */}
                    <section className="left-text">
                      <ChartCard
                        showTimeBarOnTop={false}
                        title={
                          "Total Number of Incidents / Critical Severity Incidents"
                        }
                      >
                        <div className="portfolio_container_1 custom_container_portfolio_1">
                          {highSeverityIncidentData &&
                            highSeverityIncidentData.length > 0 &&
                            highSeverityIncidentData.map((item, index) => {
                              if (!noOfIncidentShowMore && index < 3) {
                                return (
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{ width: "50%" }}
                                      className={style.leftDataContainer}
                                    >
                                      <div
                                        className={style.CaptionXL}
                                        style={{
                                          background: "#EFF1F6",
                                          height: "20px",
                                          borderRadius: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          padding: "0 7px",
                                        }}
                                      >
                                        <span style={{ minWidth: "" }}>
                                          {index + 1}
                                        </span>
                                      </div>
                                      {/* <Badge count={9}></Badge> */}
                                      <span
                                        onClick={() =>
                                          navigate("/companyPage", {
                                            state: { id: item.id },
                                          })
                                        }
                                        className={style.body}
                                        style={{
                                          paddingLeft: "8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <p>{item?.name || 0}</p>
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        width: getProcessBarPercentage(
                                          index,
                                          item.incidentCount
                                        ),
                                        display: "flex",
                                      }}
                                    >
                                      <span
                                        className={`${style.BodySelected} ${style.customProgressColor1}`}
                                        style={{ marginRight: "16px" }}
                                      >
                                        {item?.severCount
                                          ? Math.ceil(item?.severCount)
                                          : 0}
                                      </span>
                                      <Progress
                                        className="progress_portfolio_first"
                                        percent={
                                          item?.severCount
                                            ? Math.ceil(
                                              (
                                                (item?.severCount /
                                                  item.incidentCount) *
                                                100
                                              ).toFixed(3)
                                            )
                                            : 0
                                        }
                                        showInfo={false}
                                      />
                                      <span
                                        className={`${style.BodySelected} ${style.customProgressColor2}`}
                                        style={{ marginLeft: "16px" }}
                                      >
                                        {item.incidentCount}
                                      </span>
                                    </div>
                                  </div>
                                );
                              } else if (noOfIncidentShowMore) {
                                return (
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{ width: "50%" }}
                                      className={style.leftDataContainer}
                                    >
                                      <div
                                        className={style.CaptionXL}
                                        style={{
                                          background: "#EFF1F6",
                                          height: "20px",
                                          borderRadius: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          padding: "0 7px",
                                        }}
                                      >
                                        <span style={{ minWidth: "" }}>
                                          {index + 1}
                                        </span>
                                      </div>
                                      {/* <Badge count={9}></Badge> */}
                                      <span
                                        className={style.body}
                                        style={{ paddingLeft: "8px" }}
                                      >
                                        <p>{item?.name || 0}</p>
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        width:
                                          index > 9
                                            ? "10%"
                                            : getProcessBarPercentage(
                                              index,
                                              item.incidentCount
                                            ),
                                        display: "flex",
                                      }}
                                    >
                                      <span
                                        className={`${style.BodySelected} ${style.customProgressColor1}`}
                                        style={{ marginRight: "16px" }}
                                      >
                                        {item?.severCount
                                          ? Math.ceil(item?.severCount)
                                          : 0}
                                      </span>
                                      <Progress
                                        className="progress_portfolio_first"
                                        percent={
                                          item?.severCount
                                            ? Math.ceil(item?.severCount)
                                            : 0
                                        }
                                        showInfo={false}
                                      />
                                      <span
                                        className={`${style.BodySelected} ${style.customProgressColor2}`}
                                        style={{ marginLeft: "16px" }}
                                      >
                                        {item.incidentCount}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            })}

                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {highSeverityIncidentData &&
                              highSeverityIncidentData.length > 3 &&
                              !noOfIncidentShowMore && (
                                <div
                                  className={style.viewMoreContainer}
                                  onClick={() => setNoOfIncidentShowMore(true)}
                                >
                                  <span className={style.buttonM}>
                                    View More
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </ChartCard>
                    </section>

                    {/* Incidents Preview */}
                    <section>
                      <div className="inc-map-pre mt-4 containerWidth">
                        <div className="tab-top">
                          <div className="left-text">
                            <Row>
                              <h4>Incidents Preview</h4>
                              <img
                                className="hover-page"
                                style={{ marginLeft: 5 }}
                                src={Images.circleInfo}
                                alt=""
                              />
                            </Row>
                          </div>
                          <div
                            className="righ-side"
                            onClick={() =>
                              navigate("/fullMap", {
                                state: {
                                  mergedData,
                                  comeFrom: "searchdashboardpage",
                                },
                              })
                            }
                          >
                            <a>
                              View Full Map
                              <span>
                                <img src={Images.iconser} />
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row">
                            <div
                              className="col-10 mt-3 mb-3"
                              style={{
                                position: "relative",
                                height: "326px",
                              }}
                            >
                              <div className="map-pre">
                                <div
                                  style={{
                                    left: "1vw",
                                    opacity: 0.9,
                                    bottom: "6px",
                                    padding: ".6vw",
                                    position: "absolute",
                                    display: "flex",
                                    zIndex: "1",
                                    backgroundColor: "#ffffff",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      marginBottom: ".4vw",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    Severity
                                  </span>
                                  <div>
                                    <MapItem color={"#B70000"} name={"High"} />
                                    <MapItem
                                      color={"#F28C28"}
                                      name={"Medium"}
                                    />
                                    <MapItem color={"#FFD700"} name={"Low"} />
                                  </div>
                                </div>
                                <FullMap
                                  width={"600px"}
                                  height={"326px"}
                                  zoom={0}
                                  data={severityDataLocationArray || []}
                                />
                              </div>
                            </div>

                            {/* <div className="col-9 mt-3 mb-3">
                              <div className="map-pre">
                                <FullMap
                                  width={"600px"}
                                  height={"326px"}
                                  zoom={0}
                                  data={severityDataLocationArray || []}
                                />
                              </div>
                            </div> */}
                            <div className="col-2">
                              <div
                                className="asia-cont"
                                style={{
                                  minWidth: "100px",
                                }}
                              >
                                {update?.map((item, index) => {
                                  return (
                                    <div className="africa-co">
                                      <h5>{item.continent_code}</h5>
                                      <p>{item.incidents}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                  </Collapse.Panel>
                  <Collapse.Panel
                    id="9Key"
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          4
                        </span>
                        ESG Incident Categories
                      </h4>
                    }
                    key="4"
                  >
                    {/* <ChartCard
                      legend={GSE}
                      showTimeBarOnTop={false}
                      title={"Company ESG Incident Distribution"}
                    >
                      <div className="bubble-portfolio-chart-container">
                        <EsgBubbleChart />
                        
                      </div>
                    </ChartCard> */}
                    <EsgIncidentCategoriesChart
                      data={{ ...networkData, data: distributionGraphData }}
                    />
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div >
      </div >

      {
        rootLevelIncidentModalItem ? (
          <Modal
            className="incidentModal"
            open={rootLevelIncidentModalItem}
            onCancel={() => {
              setRootLevelIncidentModalItem(false);
              dispatch(
                incindentAction({
                  ...networkData,
                  companies: [id],
                  esg_categories: [tabVal],
                })
              );
            }}
            footer={null}
            width={1600}
            centered
          >
            <IncidentsList
              payloadRootLevel={topLevelIncidentListModalPayload}
              isComeFromModal={true}
            />
          </Modal >
        ) : null
      }
      {
        timelineIndidentList ? (
          <Modal
            className="incidentModal"
            open={timelineIndidentList}
            onCancel={() => setTimelineIndidentList(false)}
            footer={null}
            width={1600}
            centered
          >
            <BubleIncidentsList
              bubbleData={true}
              incidentIdlist={incidentIdArray}
            />
          </Modal>
        ) : null
      }
      {
        modalVisibleCompList ? (
          <PortfolioCompModal
            setModalVisible={setModalVisibleCompList}
            networkData={{
              ...networkData,
              companies: id,
              companyName: companyData?.name,
            }}
          />
        ) : null
      }
    </div >
  );
};

const MapItem = ({ color, name }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: ".6vw",
        height: ".6vw",
        borderRadius: "100%",
        marginRight: ".5vw",
        backgroundColor: color,
      }}
    ></div>
    <div style={{ fontSize: ".8rem" }}>{name}</div>
  </div>
);

export default PortfolioDetails;
