import { createTheme } from "@mui/material/styles";

const theme = createTheme();
const customTheme = createTheme({
  spacing: 8,
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
  shape: {
    borderRadius: 2,
  },
  palette: {
    primary: {
      900: "#162F59",
      800: "#1A386B",
      700: "#1F427D",
      600: "#1F427D",
      500: "#2C5EB2",
      main: "#2C5EB2",
      400: "#3B71CB",
      300: "#5382D1",
      200: "#6A95DE",
      100: "#81A4E1",
      A200: "#A2C0F1",
      50: "#CEDEF8",
      A100: "#E9F1FE",
    },
    gray: {
      900: "#0D0D0E",
      800: "#07132F",
      700: "#2D303A",
      600: "#3D4353",
      500: "#4E515C",
      main: "#4E515C",
      400: "#676A73",
      300: "#80838B",
      200: "#9A9CA2",
      100: "#B6BCC7",
      A200: "#CED2E0",
      50: "#E7E9F0",
      A100: "#EFF1F7",
    },
    secondary: {
      900: "#136160",
      800: "#136160",
      700: "#1F9C9A",
      600: "#23AFAD",
      500: "#2DC2BF",
      main: "#2DC2BF",
      400: "#3DC9C6",
      300: "#52CFCD",
      200: "#68D5D3",
      100: "#B3EAE9",
      A200: "#D1F2F2",
      50: "#E5F8F7",
      A100: "#F2FBFB",
    },
    orange: {
      900: "#9D5600",
      800: "#B16300",
      700: "#C87800",
      600: "#D07D00",
      500: "#D98A29",
      main: "#D98A29",
      400: "#DF9B48",
      300: "#E4AA65",
      200: "#E9BD87",
      100: "#EECA9F",
      A200: "#F6E3CC",
      50: "#F9EDDF",
      A100: "#FCF6EF",
    },
    green: {
      900: "#0A3E19",
      800: "#0D4E1F",
      700: "#126D2B",
      600: "#168535",
      500: "#1A9C3E",
      main: "#1A9C3E",
      400: "#48B065",
      300: "#5FBA78",
      200: "#76C48B",
      100: "#98D3A8",
      A200: "#AFDCBC",
      50: "#D1EBD8",
      A100: "#E8F5E",
    },
    red: {
      900: "#5E0000",
      800: "#720000",
      700: "#920000",
      600: "#B70000",
      500: "#D00000",
      main: "#D00000",
      400: "#DC4040",
      300: "#E15959",
      200: "#E88080",
      100: "#EFA6A6",
      A200: "#F3BFBF",
      50: "#F8D9D9",
      A100: "#FCEDED",
    },
    violet: {
      900: "#331E6A",
      800: "#3B237B",
      700: "#472B94",
      600: "#5634B4",
      500: "#653DD4",
      main: "#653DD4",
      400: "#7450D8",
      300: "#8B6EDF",
      200: "#A38BE5",
      100: "#BAA8EC",
      A200: "#C9BBF0",
      50: "#E0D8F6",
      A100: "#F0EDFB",
    },
    blueGreen: {
      900: "#00435A",
      800: "#005C7C",
      700: "#007DA9",
      600: "#0096CB",
      500: "#00A7E1",
      main: "#00A7E1",
      400: "#33B9E7",
      300: "#54C4EB",
      200: "#73CFEF",
      100: "#99DCF3",
      A200: "#BDE8F7",
      50: "#D6F1FA",
      A100: "#E3F5FC",
    },
    magenta: {
      900: "#4A1244",
      800: "#681960",
      700: "#7E1E74",
      600: "#A12695",
      500: "#B92CAB",
      main: "#B92CAB",
      400: "#C44EB9",
      300: "#D072C7",
      200: "#D88BD1",
      100: "#E4AFDF",
      A200: "#EDCAEA",
      50: "#F5E1F3",
      A100: "#FAF0F9",
    },
    freshGreen: {
      900: "#2C4C0D",
      800: "#3C6812",
      700: "#4D8416",
      600: "#61A61C",
      500: "#6EBD20",
      main: "#6EBD20",
      400: "#8BCA4D",
      300: "#A1D46E",
      200: "#B7DE90",
      100: "#C8E6AA",
      A200: "#D6EDC1",
      50: "#E5F3D7",
      A100: "#F2F9EB",
    },
    paperCards: {
      main: "#F7F8FA",
    },
    white: {
      main: "#FFFFFF",
    },
    infoCardBackground: {
      main: "#D5DFF0",
    },
    screenBackground: {
      main: "#F7F8FA",
    },
    darkGray: {
      main: "#4E515C",
    },
    mediumGray1: {
      main: "#80838B",
    },
    mediumGray2: {
      main: "#9A9CA2",
    },
    lightGray1: {
      main: "#CED2E0",
    },
    lightGray2: {
      main: "#E7E9F0",
    },
    grays600: {
      main: "#05122D",
    },
    grays50: {
      main: "#E7E9F0",
    },
    alert: {
      main: "#D00000",
    },
    success: {
      main: "#1A9C3E",
    },
    caution: {
      main: "#D07D00",
    },
    chip1: {
      main: "#D7E6FF",
    },
    chip2: {
      main: "#B3EBEC",
    },
    chip3: {
      main: "#F4DCBF",
    },
    chip4: {
      main: "#ECF4F9",
    },
    overlay: {
      main: "rgba(0, 0, 0, 0.5)",
    },
    alertCard: {
      main: "#D36464",
    },
    nod1Graph: {
      main: "#173567",
    },
    nod3Graph: {
      main: "#6B8EC9",
    },
    black: {
      main: "#0C0C0D",
    },
    fontBlack: {
      main: "#07132F",
    },
    fontXBlack: {
      main: "#0D0D0E",
    },
    fontDarkGray: {
      main: "#3D4353",
    },
    fontGray: {
      main: "#676A73",
    },
    fontBlue: {
      main: "#004F9E",
    },
    fontChip1: {
      main: "#37487B",
    },
    fontChip2: {
      main: "#1E4A64",
    },
    fontChip3: {
      main: "#513616",
    },
    primaryHover: {
      main: "#68A3DE",
    },
    nodeMethod: {
      main: "#38B7B3",
    },
    nodePaper: {
      main: "#208541",
    },
    nodeAuthor: {
      main: "#BF1A24",
    },
    nodeMetric: {
      main: "#5E1919",
    },
    nodeDataset: {
      main: "#EC8032",
    },
    nodeTopic: {
      main: "#3EA2EE",
    },
    nodeTask: {
      main: "#0C48AD",
    },
    nodeHyperparameter: {
      main: "#CC6FDB",
    },
    nodeHardware: {
      main: "#50258B",
    },
    nodeSoftware: {
      main: "#6874E8",
    },
  },
  typography: {
    fontFamily: "Inter",
    h1: {
      fontWeight: 700,
      fontSize: "42px",
      lineHeight: "56px",
    },
    h2: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: "32px",
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: "28px",
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "24px",
    },
    subtitle3: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
    },
    body: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "24px",
      fontFamily: "Inter",
    },
    bodySelected: {
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "24px",
    },
    paragraph: {
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    captionS: {
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "16px",
    },
    captionL: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
    captionXL: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
    },
    buttonXL: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "16px",
    },
    buttonL: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "16px",
    },
    buttonS: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      fontFamily: "Inter",
    },
    tag: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
    badge: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 500,
    },
    tooltip: {
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "12px",
    },
    graphCaption: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "12px",
    },
    pathNode: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    pathNodeSelected: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
    },
    pathEdge: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
    },
    label: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
    },
    placeHolder: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
    helper: {
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "16px",
    },
    link: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "24px",
      textDecorationLine: "underline",
    },
  },
  shadows: [
    "none",
    "0px 8px 12px 1px rgba(0, 0, 0, 0.10), 0px -2px 8px 0px rgba(0, 0, 0, 0.10)", // SearchBar
    "0px 0px 20px #AFBEDC", // CardHover
    "0px 4px 8px rgba(115, 133, 139, 0.25)", // ExpandedSideBar
    "0px 4px 6px rgba(115, 133, 139, 0.25)", // FilterPanel
    "0px 4px 6px rgba(115, 133, 139, 0.25)", // Drop
    "0px 8px 12px 1px rgba(0, 0, 0, 0.1);", // Drop Down
    "0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)", // MaterialModal
    "0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)", // MaterialFabElevation
    "0px 8px 12px 1px rgba(0, 0, 0, 0.1)", // SearchbarSourceDropdown + PageTabsFilterDropdown
    "3px 3px 6px 1px rgba(0, 0, 0, 0.1)", // Tooltip
    "4px 0px 8px rgba(115, 133, 139, 0.25);", // Side bar
    "4px 0px 8px rgba(115, 133, 139, 0.25);", // Side bar
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
  ],
  constants: {
    sideBarChevron: {
      bottom: 120,
      size: 24,
      zIndex: theme.zIndex.appBar + 1,
    },
    appSideBar: {
      width: 64,
      zIndex: theme.zIndex.appBar,
    },
    logoutMenu: {
      width: 144,
      emailTextWidth: 116,
    },
    filterBar: {
      width: 280,
      zIndex: theme.zIndex.appBar - 1,
      headerHeight: 56,
      footerHeight: 64,
      manageFilterHeight: 40,
      sectionTitleHeight: 32,
      transition: "all 1.5s",
      filterFacetPanelMaxHeight: 176,
    },
    newAnalysis: {
      searchBar: {
        height: 64,
        expandSearchMenuWidth: 51,
        dropdown: {
          top: 70,
          dateDropdown: {
            width: 352,
            height: 212,
            left: -228,
          },
          sourceDropdown: {
            height: 417,
            width: 304,
            left: -180,
          },
          categoryDropdown: {
            height: 417,
            width: 304,
            left: -180,
          },
        },
        searchButtonHeight: 48,
        searchButtonMinWidth: 115,
        optionItemHeight: 80,
        advanceSearchBarHeight: 40,
      },
      overviewPage: {
        mostMentionedEntities: {
          nameDividerWidth: 36,
          mentionsCountDividerWidth: 57,
        },
      },
      booleanOps: {
        height: 24,
      },
      treeMap: {
        height: 324,
      },
      tabsHeader: {
        height: 104,
      },
      eventItem: {
        height: 204,
        summaryHeight: 60,
      },
      recentEvents: {
        imageHeight: 216,
        imageWidth: 356,
      },
      horizontalScroll: {
        itemWidth: 120,
        itemHeight: 156,
        containerWidth: 680,
      },
      stackTabs: {
        backTabsVisibleHeaderHeight: 24,
        tabsHeaderHeight: 40,
      },
      horizontalTabs: {
        height: 32,
      },
      gridGapMargin: 2,
      gridItemPadding: 2,
      borderRadius: 3,
    },
    taxonomyBar: {
      width: 300,
      zIndex: theme.zIndex.appBar - 2,
    },
    contextMenu: {
      headerHeight: 40,
      searchBarHeight: 64,
      itemHeight: 40,
      searchIconHeight: 32,
      buttonBarHeight: 48,
      width: 220,
      locationYThreshold: 450,
      tooltipDelay: 2000,
      entityListMaxHeight: 285,
    },
    rightClickMenu: {
      width: 216,
      itemHeight: "48px",
    },
    pageTabs: {
      filters: {
        sentimentDropdown: {
          width: 168,
          height: 136,
          top: 40,
          right: 20,
        },
        sortDropdown: {
          width: 168,
          height: 96,
          top: 40,
          right: 0,
        },
      },
    },
    resourceList: {
      aboveTable: 168,
      beneathTable: 104,
      headerHeight: 48,
      rowHeight: 40,
      paginationHeight: 64,
      paginationTopMargin: 5,
      totalCountLeftMargin: "24px",
    },
    quoteTable: {
      aboveTable: 300,
    },
  },
  spacingNumber: (input) => Number(theme.spacing(input).slice(0, -2)),
});
export default customTheme;
