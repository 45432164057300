import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChartCard } from "../../../../Layouts/ChartCard";
import { ESG, ESGT, calculateEndDate } from "../../../../Utils";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import { SeverityTrendAction } from "../../../../Redux/Action/comapanyPageAction";
import moment from "moment";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { Select } from "antd";
import { allInterval, oneDayInterval, oneMonthInterval, oneWeekInterval, oneYearInterval, threeMonthInterval } from "../../../../Constants/appConstansts";
import GlobalSpinner from "../../../../Utils/GlobalSpinner";


const createCSeriesData = (data,tab) => {
  const finalObj = {
    Governance: [],
    Environmental: [],
    Social: [],
    total: [],
  };
  let keys = Object.keys(data);
  try {
    for (let i = 0; i < keys?.length; i++) {
      let key = keys[i];
      if (keys[i] === "esg_categories") {
        let a = Object.keys(data[key]);
        a.map((i, j) => {
          finalObj[i] = Object.values(data[key][i]);
          let a = Object.keys(data[key][i]);
          finalObj.label = a.map((i) => {
            let dataArr = i.split("&")

            return moment(dataArr[0]).format((tab > 2|| tab == null)?"DD MMM YY HH:mm":"DD MMM HH:mm")
          })
          finalObj.allLabel = a.map((i) => {
            let dataArr = i.split("&")
            let start_Date = moment(dataArr[0]).format((tab > 2|| tab == null)?"DD MMM YY HH:mm":"DD MMM HH:mm")
            let end_Date = moment(dataArr[1]).format((tab > 2|| tab == null)?"DD MMM YY HH:mm":"DD MMM HH:mm")
            return start_Date + " " + end_Date
          });
        });
      } else {
        finalObj[key] = Object.values(data[key]);
      }
    }

    return { ...finalObj };
  } catch (e) {
  }
};

const SeverityTrend = (netWorkData) => {
  const [selectedTab, setSelectedTab] = useState(4);
  const [selectedFilter, setSelectedFilter] = useState();

  useEffect(() => {
    localStorage.interval = "Last 3 months"
  }, []);

  useEffect(() => {
    let interval = localStorage.interval
    if (interval == "Last 24 hours") {
      setSelectedTab(1)
    }
    if (interval == "Last week") {
      setSelectedTab(2)
    }
    if (interval == "Last Month") {
      setSelectedTab(3)
    }

    if (interval == "Last 3 months") {
      setSelectedTab(4)
    }
    if (interval == "Last 6 months") {
      setSelectedTab(5)
    }
    if (interval == "Last year") {
      setSelectedTab(6)
    }
    if (interval == "Custom Date") {
      setSelectedTab(null)
    }
  }, [localStorage.interval]);

  useEffect(() => {
    if (selectedTab == 1) {
      setSelectedFilter("1Hr")
    }
    if (selectedTab == 2) {
      setSelectedFilter("3Hr")
    }
    if (selectedTab == 3) {
      setSelectedFilter("8Hr")
    }
    if (selectedTab == 4) {
      setSelectedFilter("16Hr")
    }
    if (selectedTab == 5) {
      setSelectedFilter("1D")
    }
    if (selectedTab == 6) {
      setSelectedFilter("1W")
    }
    if (selectedTab == null) {
      if (moment(netWorkData.end).diff(netWorkData.start, "days") > 7) {
        setSelectedFilter("1D")
        dispatch(SeverityTrendAction({ ...netWorkData, interval: "1D" }))
      } else {
        setSelectedFilter("3Hr")
        dispatch(SeverityTrendAction({ ...netWorkData, interval: "3Hr" }))
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab == null) {
      if (moment(netWorkData.end).diff(netWorkData.start, "days") > 7) {
        setSelectedFilter("1D")
        dispatch(SeverityTrendAction({ ...netWorkData, interval: "1D" }))
      } else {
        setSelectedFilter("3Hr")
        dispatch(SeverityTrendAction({ ...netWorkData, interval: "3Hr" }))
      }
    }
  }, [netWorkData.dateChange]);
  useEffect(() => {
    if (selectedFilter) {
      if (selectedTab !== null) {
        let start = moment(calculateEndDate(new Date(), selectedTab)).format(
          "YYYY-MM-DD"
        )
        let end = moment().format("YYYY-MM-DD")
        dispatch(SeverityTrendAction({ ...netWorkData, start, end, interval: selectedFilter }));
      }
    }
  }, [selectedFilter]);

  const { severityTrend, severityLoader } = useSelector((state) => state?.companyPageReducer);

  const seriesData = useCallback(
    () => createCSeriesData(severityTrend,selectedTab),
    [severityTrend]
  );

  const data = severityTrend && seriesData();
  const option = {
    color: ["#6EBD20", "#2191FB", "#653DD4"],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      },
      formatter: function (params) {
        if (params.length > 0) {
          return `
        <div style="width:160px;margin:5px; border-radius:5px;">
            <div style="font-weight:bold;font-size:13px;color:#000; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            ${data.allLabel[params[0].dataIndex]}
            </div>
            <div style="margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            <div class="tooltip-item" style="color:${params[0].color}">${params[0].seriesName}: ${params[0].data}</div>
            ${params.length > 1 ? `<div class="tooltip-item" style="color:${params[1].color}">${params[1].seriesName}: ${params[1].data}</div>` : ``}
            ${params.length > 2 ? `<div class="tooltip-item" style="color:${params[2].color}">${params[2].seriesName}: ${params[2].data}</div>` : ``}
            
            </div>
            
        </div>
          `;
        }
      },
    },
    toolbox: {
    },
    legend: {
      data: ['Environment', 'Social', 'Governance',],
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: data?.label
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    dataZoom: [
      {
        type: "slider", // This is a slider at the bottom
        zoomOnMouseWheel: "ctrl", // Requires the Ctrl key to be pressed to zoom with the mouse wheel
      },
      {
        type: "inside", // This enables zooming and panning with mouse actions
        zoomOnMouseWheel: "ctrl", // Also requires the Ctrl key for the slider, for consistency
      },
    ],
    series: [
      {
        name: 'Environment',
        type: 'bar',
        showSymbol: false,
        emphasis: {
          focus: 'series'
        },
        data: data?.Environmental ? data?.Environmental : [],
      },
      {
        name: 'Social',
        type: 'bar',
        showSymbol: false,
        emphasis: {
          focus: 'series'
        },
        data: data?.Social ? data?.Social : [],
      },
      {
        name: 'Governance',
        type: 'bar',
        showSymbol: false,
        emphasis: {
          focus: 'series'
        },
        data: data?.Governance ? data?.Governance : []
      },

    ]
  };

  const handleChange = (value) => {
    setSelectedFilter(value)
    if (selectedTab == null) {
      dispatch(SeverityTrendAction({ ...netWorkData, interval: value }))
    }
  };

  const getoption = () => {
    switch (selectedTab) {
      case 1:
        return oneDayInterval
      case 2:
        return oneWeekInterval
      case 3:
        return oneMonthInterval
      case 4:
        return threeMonthInterval
      case 5:
        return threeMonthInterval
      case 6:
        return oneYearInterval
      case null:
        return allInterval

      default:
        break;
    }
  }

  return (
    <section className="left-text">
      <ChartCard
        title={"Incident Severity Trend"}
        className={"mt-32"}
        legend={ESG}
        showTimeBarOnTop={false}
      >
        <div className="top-checkbox">
          <div className="d-flex justify-content-between w-100">
            <div className="custom-buttons">
              <button onClick={() => setSelectedTab(1)} className={selectedTab === 1 ? "active-map-button" : ""}>1D</button>
              <button onClick={() => setSelectedTab(2)} className={selectedTab === 2 ? "active-map-button" : ""}>1W</button>
              <button onClick={() => setSelectedTab(3)} className={selectedTab === 3 ? "active-map-button" : ""}>1M</button>
              <button onClick={() => setSelectedTab(4)} className={selectedTab === 4 ? "active-map-button" : ""}>3M</button>
              <button onClick={() => setSelectedTab(5)} className={selectedTab === 5 ? "active-map-button" : ""}>6M</button>
              <button onClick={() => setSelectedTab(6)} className={selectedTab === 6 ? "active-map-button" : ""}>1Y</button>
            </div>
            <div className="intervalView" >
              <span className="top-checkboxTitle">Time Interval</span>
              <Select
                defaultValue="Interval"
                value={selectedFilter}
                style={{ width: 80 }}
                onChange={handleChange}
                options={getoption()}
              />
            </div>
          </div>

        </div>

        <div className="Sentiment-grah">
          <div className="line-chart">
            {severityLoader == false ? (
              <ReactECharts
                echarts={echarts}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                style={{ width: "100%" }}
              />
            )
              : (
                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: 300 }}>
                  <GlobalSpinner colorx={"#0195ce"} />
                </div>
              )
            }
          </div>
        </div>
      </ChartCard>
    </section>
  );
};

export default SeverityTrend;
