import { apiConstants as types } from "../../Constants/apiConstants";
export const getProfileAction = (data) => ({
  type: types.API_GET_PROFILE_LOAD,
  data,
});
export const updateProfileAction = (data) => ({
  type: types.API_UPDATE_PROFILE_LOAD,
  data,
});

export const profileEmailNotificationAction = (data) => ({
  type: types.API_UPDATE_PROFILE_EMAIL_NOTIFICATION_LOAD,
  data,
});

export const profileDeleteAction = () => ({
  type: types.API_PROFILE_DELETE_LOAD,
});
