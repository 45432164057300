import { SvgIcon } from "@mui/material";

export default function FindPathIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.33464 6.0013V7.4013C4.33464 8.89478 4.33464 9.64151 4.62528 10.2119C4.88095 10.7137 5.2889 11.1217 5.79066 11.3773C6.36109 11.668 7.10783 11.668 8.6013 11.668H10.0013M3.73464 6.0013H4.93464C5.308 6.0013 5.49469 6.0013 5.6373 5.92864C5.76274 5.86472 5.86472 5.76274 5.92864 5.6373C6.0013 5.49469 6.0013 5.308 6.0013 4.93464V3.73464C6.0013 3.36127 6.0013 3.17458 5.92864 3.03198C5.86472 2.90653 5.76274 2.80455 5.6373 2.74063C5.49469 2.66797 5.308 2.66797 4.93464 2.66797H3.73464C3.36127 2.66797 3.17458 2.66797 3.03198 2.74063C2.90653 2.80455 2.80455 2.90653 2.74063 3.03198C2.66797 3.17458 2.66797 3.36127 2.66797 3.73464V4.93464C2.66797 5.308 2.66797 5.49469 2.74063 5.6373C2.80455 5.76274 2.90653 5.86472 3.03198 5.92864C3.17458 6.0013 3.36127 6.0013 3.73464 6.0013ZM11.068 13.3346H12.268C12.6413 13.3346 12.828 13.3346 12.9706 13.262C13.0961 13.1981 13.1981 13.0961 13.262 12.9706C13.3346 12.828 13.3346 12.6413 13.3346 12.268V11.068C13.3346 10.6946 13.3346 10.5079 13.262 10.3653C13.1981 10.2399 13.0961 10.1379 12.9706 10.074C12.828 10.0013 12.6413 10.0013 12.268 10.0013H11.068C10.6946 10.0013 10.5079 10.0013 10.3653 10.074C10.2399 10.1379 10.1379 10.2399 10.074 10.3653C10.0013 10.5079 10.0013 10.6946 10.0013 11.068V12.268C10.0013 12.6413 10.0013 12.828 10.074 12.9706C10.1379 13.0961 10.2399 13.1981 10.3653 13.262C10.5079 13.3346 10.6946 13.3346 11.068 13.3346Z"
          stroke="#9A9CA2"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
