import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/configuareStore";
import { setShowSpecificRelationsModal } from "../../../../redux/GraphExploration/rightClickMenuSlice";
import CloseIcon from "../../../companyInfoSideBar/CloseIcon";
import SpecificRelationsModalTabs from "./Tabs";
import SpecificRelationsModalTabsPane from "./TabPane";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function SpecificRelationsModal() {
  const showSpecificRelationsModal = useAppSelector(
    (state) => state.rightClickMenu.showSpecificRelationsModal
  );
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(setShowSpecificRelationsModal(false));
  };
  return (
    <Dialog
      open={showSpecificRelationsModal}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      sx={{ "& .MuiPaper-root": { borderRadius: "8px" } }}
      hideBackdrop
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">Expand Nodes</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ width: "600px" }}>
        <SpecificRelationsModalTabs />
        <Box
          sx={{
            paddingTop: "0px",
            height: "400px",
            overflow: "auto",
          }}
        >
          <SpecificRelationsModalTabsPane />
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button
          variant="outlined"
          sx={{ borderRadius: "4px" }}
          onClick={handleClose}
        >
          Cancel
        </Button> */}
        <Button
          variant="contained"
          sx={{ borderRadius: "4px" }}
          onClick={handleClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
