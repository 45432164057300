import { Table } from "antd";
import React from "react";

const CustomTable = ({ columns, dataSource }) => {
  return (
    <>
      <Table
        scroll={{
          x: 1200,
          y: 500,
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </>
  );
};

export default CustomTable;
