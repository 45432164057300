export const addDropShadow = (svg, color) => {
  var defs = svg.append("defs");
  const id = "dropShadow" + Math.random();
  var filter = defs.append("filter").attr("id", id);

  filter
    .append("feGaussianBlur")
    .attr("in", "SourceAlpha")
    .attr("stdDeviation", 2)
    .attr("result", "blur");

  filter
    .append("feOffset")
    .attr("in", "blur")
    .attr("dx", 2)
    .attr("dy", 2)
    .attr("result", "offsetBlur");

  filter.append("feFlood").attr("flood-color", color).attr("result", "color");

  filter
    .append("feComposite")
    .attr("in", "color")
    .attr("in2", "offsetBlur")
    .attr("operator", "in")
    .attr("result", "shadow");

  filter
    .append("feComposite")
    .attr("in", "SourceGraphic")
    .attr("in2", "shadow")
    .attr("operator", "over");

  return id;
};

export const getColorsBasedOnType = (type) => {
  let color, arrowColor, fontColor, hoverColor;
  if (type === "E") {
    color = "#E5F3D7";
    arrowColor = "#A1D46E";
    fontColor = "#4D8416";
    hoverColor = "#61A61C";
  } else if (type === "S") {
    color = "#C7E3FE";
    arrowColor = "#64B2FC";
    fontColor = "#196DBC";
    hoverColor = "#1E85E7";
  } else {
    color = "#E0D8F6";
    arrowColor = "#8B6EDF";
    fontColor = "#472B94";
    hoverColor = "#5634B4";
  }
  return {
    color,
    arrowColor,
    fontColor,
    hoverColor,
  };
};

export const getToolTipHtml = (data) => {
  let socialMediaSentimentColor;
  let socialMediaSentimentText;

  let color;
  let backGroundColor;
  let type;
  if (data?.averageIncidentSeverity?.value == null) {
    color = "#EBB100";
    backGroundColor = "#FFE79F";
    type = "( Low )";
  } else {
    if (data?.averageIncidentSeverity?.value > 70) {
      color = "#B70000";
      backGroundColor = "#F3BFBF";
      type = "( High )";
    } else if (
      data?.averageIncidentSeverity?.value > 30 ||
      data?.averageIncidentSeverity?.value < 70
    ) {
      color = "#794614";
      backGroundColor = "#FAD4AE";
      type = "( Medium )";
    } else if (
      data?.averageIncidentSeverity?.value >= 0 ||
      data?.averageIncidentSeverity?.value < 30
    ) {
      color = "#EBB100";
      backGroundColor = "#FFE79F";

      type = "( Low )";
    }
  }

  if (data?.socialMediaSentiment  == null) {
    socialMediaSentimentColor = "#676A73";
    socialMediaSentimentText = "( Neutral )";
  } else {
    if (data?.socialMediaSentiment  < 0) {
      socialMediaSentimentColor = "#B70000";
      socialMediaSentimentText = "( Negative )";
    } else if (data?.socialMediaSentiment == 0) {
      socialMediaSentimentColor = "#676A73";
      socialMediaSentimentText = "( Neutral )";
    } else if (
      data?.socialMediaSentiment >= 0 
    ) {
      socialMediaSentimentColor = "#168535";
      socialMediaSentimentText = "( Positive )";
    }
  }
  return `<div>
    <div class="${
      data.parent === "Environmental"
        ? "sunchart-tooltip-head-green"
        : data.parent === "Social"
        ? "sunchart-tooltip-head-blue"
        : "sunchart-tooltip-head-violet"
    }">
    <span>
    </span>
    ${data.parent}
        </div>
        <p class="sunchart-tooltip-sub-heading">${data.name}</p>
        <ol>
          <li>
          <div class="sunchart-tooltip-item">
            <span class="tooltip-item-text">
            Incident Frequency
            </span>
            <span class="tooltip-item-label">
            ${data.incidents}
            </span>
          </div>
          </li>
          <li>
          <div class="sunchart-tooltip-item">
            <span class="tooltip-item-text">
            Average Incident Severity
            </span>
            <span class="tooltip-item-label" style="color:${color}">
              ${
                data?.averageIncidentSeverity?.value == null
                  ? 0
                  : Math.floor(data?.averageIncidentSeverity?.value)
              } ${" " + type}
            </span>
          </div>
          </li>
          <li>
          <div class="sunchart-tooltip-item">
            <span class="tooltip-item-text">
            News Agency Articles & NGO Reports
            </span>
            <span class="tooltip-item-label">
            ${data.articleAndNgoCount} 
            </span>
          </div>
          </li>
          <li>
          <div class="sunchart-tooltip-item">
            <span class="tooltip-item-text">
            Social Media Sentiment
            </span>
            <span class="tooltip-item-label" style="color:${socialMediaSentimentColor};">
            ${
              data.socialMediaSentiment == null ? 0 : data.socialMediaSentiment.toFixed(1)
            } ${socialMediaSentimentText}
            </span>
          </div>
          </li>
          <li>
          <div class="sunchart-tooltip-item" >
            <span class="tooltip-item-text">
            Social Media Posts
            </span>
            <span class="tooltip-item-label">
            ${data.socialMediaPosts}
            </span>
          </div>
          </li>
        </ol>
        <div class="sunchart-tooltip-foot" style="color:${color}; background-color:${backGroundColor};">
           <div class="sunchart-tooltip-item" >
             <span class="tooltip-item-text">
               Calculated Risk Score
             </span>
             <span class="">
             ${Math.ceil(data.risk)}/100
             </span>
            </div>
        </div>
      </div>`;
};

export const degreeToRadians = (degree) => degree * (Math.PI / 180);
