import React from 'react';
import ButtonContainer from '../ButtonContainer';
import PlusButton from './PlusButton';
import FitViewButton from './FitViewButton';
import MinusButton from './MinusButton';

function ZoomFitViewButtons() {
  return (
    <ButtonContainer>
      <PlusButton />
      <FitViewButton />
      <MinusButton />
    </ButtonContainer>
  );
}
export default ZoomFitViewButtons;
