import { apiConstants as types } from "../../Constants/apiConstants";
export const addWishListAction = (data) => ({
  type: types.API_ADD_WISHLIST_LOAD,
  data,
});
// get wish list

export const getWishlistAction = (data) => ({
  type: types.API_GET_WISHLIST_LOAD,
  data,
});
//   get wishList detail
export const getWishListDetailAction = (data) => {
  return {
    type: types.API_GET_WISHLIST_DETAILS_LOAD,
    data: data ? data : "",
  };
};
//delete wish list
export const deleteWishlistAction = (data) => ({
  type: types.API_DELETE_WISHLIST_LOAD,
  data: data,
});
//edit wish list
export const editWishListAction = (data) => ({
  type: types.API_EDIT_WISHLIST_LOAD,
  data,
});
// current watch list id
export const getCurrentWatchlistAction = (data) => ({
  type: types.GET_CURRENT_WATCHLIST,
  data,
});
//show Result
export const showResult = (data) => ({
  type: types.SHOW_RESULT,
  data,
});

export const selectedIncidentWatchList = (data) => ({
  type: types.SET_SELECTED_WATCHLIST,
  data,
});

export const updateDataForWatchlist = (data) => {
  return {
    type: types.API_UPDATE_MODAL_WISHLIST_DETAILS,
    data,
  };
};

export const editWishListSevereAction = (data) => {
  return {
    type: types.UPDATE_WATCHLIST_SEVER_LOAD,
    data,
  };
};

export const addWishListItemIdAction = (data) => ({
  type: types.UPDATE_ADD_WISHLIST_ID,
  data,
});

export const uploadCompanyFileLoad = (data) => ({
  type: types.UPLOAD_COMPANY_FILE_LOAD,
  data,
});

export const uploadCompanyFileFail = () => ({
  type: types.UPLOAD_COMPANY_FILE_FAIL
});
export const updateWatchList = (data) => ({
  type: types.UPLOAD_WATCHLIST_COMPANY_FILE_LOAD,
  data,
});

export const saveValidWatchListValidCompanyLoad = (data) => ({
  type: types.SAVE_VALID_SUPPLY_CHAIN_COMPANY_LOAD,
  data,
});

export const saveValidWatchListValidCompanySuccess = (data) => ({
  type: types.SAVE_VALID_SUPPLY_CHAIN_COMPANY_SUCCESS,
  data,
});

export const fileUploadAction = (data) => ({
  type: types.UPLOAD_FILE_LOAD,
  payload: data,
})

export const updateWatchListData = (data) => ({
  type: types.API_GET_WISHLIST_DETAILS_UPDATE,
  payload: data,
})


