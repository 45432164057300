//13/7/2023 MM

import { apiConstants as types } from "../../Constants/apiConstants";
//get save incidence list
export const getCollectionAction = (data) => ({
    type: types.API_GET_COLLECTION_LOAD,
    data
});

//ceate save incidence
export const createSaveIncidenceAction = (data) => (
    {
        type: types.API_CREATE_COLLECTION_LOAD,
        data
    });
// add to collection
export const addToCollectionAction = (data) => ({
    type: types.API_ADD_TO_COLLECTION_LOAD,
    data
})
export const deleteCollectionAction = (data) => ({
    type: types.API_DELETE_COLLECTION_LOAD,
    data
})

// REMOVE INCIDENCE
export const removeIncidenceAction=(data)=>({
    type:types.API_REMOVE_COLLECTION_LOAD,
    data
})

export const selectedIncidentCollection=(data)=>({
    type:types.SET_SELECTED_COLLECTION_LOAD,
    data
})

export const renameCollection=(data)=>({
    type:types.SET_RENAME_COLLECTION_LOAD,
    data
})

export const updateIncidentCollectionsState = (data, key) => ({
    type: types.UPDATE_INCIDENT_COLLECTION_SIDEBAR,
    payload: data,
    key
  })


