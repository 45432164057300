import axios from "axios";
import { getToken } from "../../../../../../Utils";
import { resetRightClickMenu } from "../../../redux/GraphExploration/rightClickMenuSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import { useGraphObject } from "../../context/MyContext";
import MenuItem from "./CustomMenuItem";
import {
  decodeUnicodeEscapes,
  getBadge,
  getEdgeProperLabel,
  getFilterTagIds,
  prepareIncidentCounts,
  toTitleCase,
  uniqueArrayByProperty,
} from "../../../utils";
import { toast } from "react-toastify";
import {
  setExpandedNodes,
  setLoadingData,
} from "../../../redux/GraphExploration/graphSlice";

function ExpandVariant({ count, variant }) {
  const dispatch = useAppDispatch();
  const { graphObject } = useGraphObject();
  const companyId = useAppSelector(
    (state) => state.rightClickMenu.targetNodeId
  );
  const centerNodeId = useAppSelector((state) => state.graph.centerNode).id;
  const baseUrlApi = useAppSelector((state) => state.api.baseUrlApi);
  const from = useAppSelector((state) => state.timeRange.from);
  const to = useAppSelector((state) => state.timeRange.to);
  const nodes = useAppSelector((state) => state.graph.nodes);
  const edges = useAppSelector((state) => state.graph.edges);
  const expandedNodes = useAppSelector((state) => state.graph.expandedNodes);
  const handleClick = async () => {
    const currentNodeIds = nodes?.map((node) => node.id);
    const currentEdgeIds = edges?.map((edge) => edge.id);
    try {
      const params = { from, to };
      if (centerNodeId) {
        params.centerNodeId = centerNodeId;
      }
      if (variant !== "ALL") {
        params.shortRelationName = variant;
      }
      const token = getToken();
      const url = `${baseUrlApi}/companies/${companyId}/neighbours`;
      dispatch(setLoadingData(true));
      const data = await axios.get(url, {
        params,
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch(setLoadingData(false));
      const neighbours = data.data;
      const newNodes = neighbours
        ?.map((item) => {
          const nodeAttributes = {
            incidentCount: prepareIncidentCounts(item.incidentCount),
            tier: item.tier ? Math.abs(item.tier) : undefined,
            sort: item.tier ? 4 - Math.abs(item.tier) : 0,
            layer: item.tier ? Math.abs(item.tier) : 4,
            riskScore: item.riskScore,
            industry: item.industry,
            location: item.location,
            label: decodeUnicodeEscapes(item.name),
          };
          return {
            id: item.id,
            data: {
              isCenterNode: false,
              ...nodeAttributes,
              logo: item.logo,
              filterTagIds: getFilterTagIds(nodeAttributes),
              badge: getBadge(nodeAttributes),
            },
          };
        })
        ?.filter(
          (obj, index, self) => self.findIndex((o) => o.id === obj.id) === index
        )
        ?.filter((item) => currentNodeIds?.indexOf(item.id) === -1);
      // graphObject?.cleanHistory();
      graphObject?.startHistoryBatch();
      if (newNodes === undefined) {
        toast.warn("No new nodes is added");
      } else {
        const uniqueNodes = uniqueArrayByProperty(newNodes, "id");
        graphObject?.addData("node", uniqueNodes); // nodesWithParentId);
      }
      const newEdges = neighbours
        ?.map((item) => {
          const source = item?.direction === "inbound" ? companyId : item.id;
          const target = item?.direction === "inbound" ? item.id : companyId;
          return {
            id: `${source}-${target}`,
            source,
            target,
            data: {
              label: getEdgeProperLabel(item?.relationName || ""),
              shortLabel: item?.shortRelationName,
            },
          };
        })
        ?.filter((item) => {
          const idParts = item.id.split("-");
          const reverseId = `${idParts[1]}-${idParts[0]}`;
          return (
            currentEdgeIds?.indexOf(item.id) === -1 &&
            currentEdgeIds?.indexOf(reverseId) === -1
          );
        });
      if (newEdges === undefined) {
        toast.warn("No new edges is added");
      } else {
        const uniqueEdges = uniqueArrayByProperty(newEdges, "id");
        graphObject?.addData("edge", uniqueEdges);
        if (!expandedNodes.includes(companyId)) {
          dispatch(setExpandedNodes([...expandedNodes, companyId]));
        }
      }
      graphObject?.stopHistoryBatch();
    } catch (err) {
      toast.error(`Something went wrong: ${err}`);
    }
  };

  return (
    <MenuItem
      label={variant === "ALL" ? "All relations" : toTitleCase(variant)}
      onClick={() => {
        dispatch(resetRightClickMenu());
        handleClick();
      }}
      count={count}
    />
  );
}

export default ExpandVariant;
