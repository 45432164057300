import { SvgIcon, useTheme } from "@mui/material";

function ForceLayoutIcon({ selected }) {
  const theme = useTheme();
  return (
    <SvgIcon>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.58333 5.66667C4.73393 5.66667 5.66667 4.73393 5.66667 3.58333C5.66667 2.43274 4.73393 1.5 3.58333 1.5C2.43274 1.5 1.5 2.43274 1.5 3.58333C1.5 4.73393 2.43274 5.66667 3.58333 5.66667ZM3.58333 5.66667V12.3333M3.58333 12.3333C2.43274 12.3333 1.5 13.2661 1.5 14.4167C1.5 15.5673 2.43274 16.5 3.58333 16.5C4.73393 16.5 5.66667 15.5673 5.66667 14.4167C5.66667 13.2661 4.73393 12.3333 3.58333 12.3333ZM14.4167 12.3333V6.25C14.4167 5.31658 14.4167 4.84987 14.235 4.49335C14.0752 4.17975 13.8203 3.92478 13.5067 3.76499C13.1501 3.58333 12.6834 3.58333 11.75 3.58333H9M14.4167 12.3333C15.5673 12.3333 16.5 13.2661 16.5 14.4167C16.5 15.5673 15.5673 16.5 14.4167 16.5C13.2661 16.5 12.3333 15.5673 12.3333 14.4167C12.3333 13.2661 13.2661 12.3333 14.4167 12.3333ZM9 3.58333L11.0833 5.66667M9 3.58333L11.0833 1.5"
          stroke={selected ? theme.palette.primary.main : "#B6BCC7"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default ForceLayoutIcon;
