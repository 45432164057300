import { call, put, takeLatest } from "redux-saga/effects";
import appconstant from "../../Constants/appConstansts";
import { onError, onFail, onSucces, onUnAuth } from "../../Utils/UnAuthFailFn";
import { apiConstants as type } from "../../Constants/apiConstants";
import { getCompaniesApi } from "../api";
import {
  searchCompanyListActionFail,
  searchCompanyListActionSuccess,
} from "../Action/global";

const { RoutesPath } = appconstant;

function* searchCompanyListSaga(action) {
  try {
    const data = yield call(getCompaniesApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(searchCompanyListActionSuccess(data.result.data));
    }
    // on fail
  } catch (e) {
    yield put(searchCompanyListActionFail());
    //if some thing went wrong from api side
    yield call(onError);
  }
}
export default function* globalSaga() {
  yield takeLatest(type.SEARCH_COMPANY_LIST_LOAD, searchCompanyListSaga);
}
