
import React, { useState, useEffect } from "react";
import Images from '../../Themes/ImageConstant';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Breadcrumb } from 'antd';






const Plans = (props) => {

    return (
        <div className="side-padding sodebar-left-padding">
            <div className="page-size">
                <div className="breadcrmbs mb-4">
                    <Breadcrumb>
                        <Breadcrumb.Item key="2" className="inc-link"> Benita Avi</Breadcrumb.Item>
                        <Breadcrumb.Item key="3" className="lie-link"> Plans</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="paln-and-bill mt-4">
                    <div className="plan-heading">
                        <h4>Plans and Billing</h4>
                        <p>Fortunately there is the free plan for our users for some time and you can enjoy our free plan with some of unique features. Premium plans will soon be updated and you can upgrade your plan to be a premium member.   </p>
                    </div>

                    <div className="subscribe mt-4">
                        <div className="free-sub ">
                            <div className="fee">
                                <h6>Free</h6>
                            </div>
                            <div className="euro-sign mt-4 pb-3">
                                <h4><span>€</span>0</h4>
                            </div>

                            <div className="unlimited">
                                <p><span><img src={Images.right} /></span>Unlimited Search </p>
                                <p><span><img src={Images.right} /></span>Watch list </p>
                                <p><span><img src={Images.right} /></span>Social Media  </p>
                                <p><span><img src={Images.right} /></span>Email Notification  </p>
                                <p><span><img src={Images.right} /></span>Supply Chain </p>
                            </div>
                            

                        </div>

                        <div className="free-sub outer-border">
                            <div className="fee bg-free">
                                <h6>Pro</h6>
                            </div>
                            <div className="euro-sign mt-4 pb-3 txt-clr">
                                <h4><span>€</span>20/month</h4>
                            </div>

                            <div className="unlimited">
                                <p><span><img src={Images.rightGray} /></span>Unlimited Search </p>
                                <p><span><img src={Images.rightGray} /></span>Watch list </p>
                                <p><span><img src={Images.rightGray} /></span>Social Media  </p>
                                <p><span><img src={Images.rightGray} /></span>Email Notification  </p>
                                <p><span><img src={Images.rightGray} /></span>Supply Chain </p>
                            </div>
                            <div className="subscri-btn">
                                <button type="button">Subscribe</button>
                            </div>
                            

                        </div>


                        <div className="free-sub outer-border">
                            <div className="fee bg-free">
                                <h6>Ultimate</h6>
                            </div>
                            <div className="euro-sign mt-4 pb-3 txt-clr">
                                <h4><span>€</span>50/month</h4>
                            </div>

                            <div className="unlimited">
                                <p><span><img src={Images.rightGray} /></span>Unlimited Search </p>
                                <p><span><img src={Images.rightGray} /></span>Watch list </p>
                                <p><span><img src={Images.rightGray} /></span>Social Media  </p>
                                <p><span><img src={Images.rightGray} /></span>Email Notification  </p>
                                <p><span><img src={Images.rightGray} /></span>Supply Chain </p>
                            </div>

                            <div className="subscri-btn">
                                <button type="button">Subscribe</button>
                            </div>

                        </div>
                    </div>



                </div>



            </div>


        </div>



    )
}
export default Plans;