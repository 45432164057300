import { Modal, Row, Table, Tabs, Spin } from "antd";
import Images from "../../Themes/ImageConstant";
import IncidentsList from "../../Screens/incidentsList";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import { incindentAction } from "../../Redux/Action/incidents";
import appconstant from "../../Constants/appConstansts";
import IncidentsListModal from "../../Screens/IncidentsListModal";
import GlobalLoder from "../../Utils/GlobalLoder";
import { useNavigate } from "react-router-dom";

const SupplyChainTable = ({
  data,
  title,
  expandDet,
  showRow,
  columns,
  scroll,
  id,
  date,
  showModal = false,
  screenName,
  goTo,
  icon,
  expendBtn = null,
  companyName,
  footerTitle,
  expandDetClick=()=>{}
}) => {
  const [modalVisible, setModalVisible] = useState(showModal);
  const [tabKey, setTabKey] = useState("1");
  let simpleApiPayload = { ...topHeaderAPIObject, ...date };
  const { incidentListLoader } = useSelector(
    (state) => state.companyPageReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openModal = () => {
    setModalVisible(true);
  };

  const onChangeTab = (key) => {
    setTabKey(key);
  };
  const closeModal = () => {
    setModalVisible(false);
    !id.length > 0 && dispatch(incindentAction(simpleApiPayload));
  };

  return (
    <section>
      <div className="container g-0">
        <div className="row">
          <div className="col ">
            <div
              className={
                screenName !== "Home"
                  ? `recent-table`
                  : `recent-table containerWidth`
              }
            >
              <div className="tab-top">
                <div className="left-text">
                  <Row>
                    <h4>{title}</h4>
                    {icon ? (
                      <img
                        className="hover-page"
                        style={{ marginLeft: 5 }}
                        src={Images.circleInfo}
                        alt=""
                      />
                    ) : null}
                  </Row>
                </div>
                {/* {(!incidentListLoader && !expendBtn[0]) && (
                  <div className="righ-side" onClick={() => expendBtn[1](!expendBtn[0])}> */}
                {!incidentListLoader && (
                  <div
                    className="righ-side"
                    onClick={() => (
                      expendBtn ?
                      null :
                      goTo == '/searchResult' ?
                      navigate("/searchresult", {
                        state: {
                          record: {
                            id,
                            value: companyName,
                            label: companyName,
                          },
                        },
                      }) :
                      openModal())}
                  >
                    <span>
                      <span onClick={()=>expandDetClick()}>{expandDet ? expandDet : "View full list"}</span>
                      <span>
                        <img src={Images.iconser} />
                      </span>
                    </span>
                  </div>
                )}
              </div>
              <Spin
                spinning={incidentListLoader}
                indicator={
                  <div className="outer-table-loader">
                    <GlobalLoder />
                  </div>
                }
              >
                <Table
                  rowClassName={"incidentTbl"}
                  className="Antdtable-component"
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  scroll={scroll}
                />
              </Spin>

              {showRow && (
                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                  <div
                    style={{
                      padding: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        cursor: "pointer",
                      }}
                      onClick={() => expendBtn[1](!expendBtn[0])}
                    >
                      {!expendBtn[0] && (
                        <>
                          <h4
                            style={{
                              fontSize: "14px",
                              color: " #0195CE",
                              textAlign: "center",
                              marginRight: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {footerTitle ? footerTitle : "View more Companies"}
                          </h4>
                          <span>
                            <img src={Images.blueDownArrow} />
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
      {modalVisible ? (
        <IncidentsListModal
          networkData={{
            ...simpleApiPayload,
            companies: [id],
            companyName: companyName,
          }}
          setModalVisible={setModalVisible}
        />
      ) : null}
    </section>
  );
};
export default memo(SupplyChainTable);
