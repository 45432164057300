import React, { useState, useEffect } from "react";
import { Input, Layout, Menu } from "antd";
import { useLocation } from "react-router";
import { Button, Popover } from "antd";
import { Modal } from "antd";

import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import Images from "../../Themes/ImageConstant";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  createSaveIncidenceAction,
  deleteCollectionAction,
  getCollectionAction,
  selectedIncidentCollection,
  renameCollection,
  updateIncidentCollectionState,
  updateIncidentCollectionsState,
} from "../../Redux/Action/saveIncidence";
import { useSelector } from "react-redux";
import { incindentAction } from "../../Redux/Action/incidents";
import ConfirmationModal from "../../Componets/ConfirmationModal";
import ImageConst from "../../Themes/ImageConstant";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { toast } from "react-toastify";

const { Sider } = Layout;

const IncidentCollectionSideBar = (props) => {
  const { result, deleteCollectionSuccess } = useSelector((state) => state?.saveIncidenceReducer);
  const collectionList = result;
  const [currentIndex, setCurrentindex] = useState(0);
  const [nextcollectionId, setNextcollectionId] = useState(null);
  const [modal2Open, setModal2Open] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [currentName, setCurrentName] = useState();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [firstCall, setfirstCall] = useState(false);
  const [updateListName, setUpdateListName] = useState({
    inputVal: "",
    isCard: false,
  });

  const  deleteCollection = (item) =>{
    setVisible(true);
    setModal2Open(false);
  }

  const hide = (item, index, key) => {
    if (key == "delete") {
      setVisible(true);
      setModal2Open(false);
    } else if (key == "rename") {
      setVisible(false);
      setModal2Open(true);
      setUpdateListName({
        inputVal: item?.name,
        isCard: true,
      });
    }
    setCurrentName(item?.name);
    setCurrentindex(index);
    setCurrentId(item?._id);
    dispatch(selectedIncidentCollection(item));
    dispatch(
      incindentAction({
        start: "",
        end: "",
        companies: [],
        continent_codes: [],
        countries: [],
        esg_factors: [],
        esg_SDG: [],
        esg_PAI: [],
        industries: [],
        search: "",
        collectionId: item._id,
      })
    );
  };
  let navigate = useNavigate();
  let location = useLocation();

  // ..................siderbar-collapse

  const [collapse, setCollapse] = useState(false);

  const handleToggle = () => {
    setCollapse(!collapse);
  };

  let activeKey = "";
  switch (location.pathname) {
    case "/incidentcollection":
      activeKey = "/incidentcollection";
      break;
  }

  useEffect(() => {
    setfirstCall(true);
    dispatch(getCollectionAction());
  }, []);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (deleteCollectionSuccess) {
      if (nextcollectionId) {
        dispatch(
          incindentAction({
            start: "",
            end: "",
            companies: [],
            continent_codes: [],
            countries: [],
            esg_factors: [],
            esg_SDG: [],
            esg_PAI: [],
            industries: [],
            search: "",
            collectionId: nextcollectionId,
          })
        );
      }
      dispatch(updateIncidentCollectionsState(false, 'deleteCollectionSuccess'))
    }

    return () => {
      setNextcollectionId(null)
    }
  }, [deleteCollectionSuccess])

  useEffect(() => {
    if (collectionList?.length > 0 && firstCall) {
      setCurrentindex(0);
      dispatch(selectedIncidentCollection(collectionList[0]));
      dispatch(
        incindentAction({
          start: "",
          end: "",
          companies: [],
          continent_codes: [],
          countries: [],
          esg_factors: [],
          esg_SDG: [],
          esg_PAI: [],
          industries: [],
          search: "",
          collectionId: collectionList[0]._id,
        })
      );
      setfirstCall(false);
    }
  }, [collectionList]);

  return (
    <div className="left-side-padding">
      <Sider collapsed={collapse}>
        <div className="admin-text">
          {collapse ? (
            <>
              <p>Incident</p>
              <p>collection</p>
            </>
          ) : (
            <h2>Incident collection</h2>
          )}
        </div>

        <div className="main-sidebar-div">
          <div>
            <Menu
              mode="inline"
              defaultSelectedKeys={"1"}
              defaultOpenKeys={[activeKey]}
              selectedKeys={[activeKey]}
            >
              {collectionList?.map((item, index) => (
                <Menu.Item
                  key={index}
                  className={index == currentIndex ? "selected" : ""}
                  title={item.name}
                  onClick={(e) => {
                    setCurrentindex(index);
                    dispatch(selectedIncidentCollection(item));
                    dispatch(
                      incindentAction({
                        start: "",
                        end: "",
                        companies: [],
                        continent_codes: [],
                        countries: [],
                        esg_factors: [],
                        esg_SDG: [],
                        esg_PAI: [],
                        industries: [],
                        search: "",
                        collectionId: item._id,
                      })
                    );
                  }}
                >
                  <div
                    className="dots"
                    onClick={(e) => {
                      setCurrentindex(index);
                      dispatch(selectedIncidentCollection(item));
                      dispatch(
                        incindentAction({
                          start: "",
                          end: "",
                          companies: [],
                          continent_codes: [],
                          countries: [],
                          esg_factors: [],
                          esg_SDG: [],
                          esg_PAI: [],
                          industries: [],
                          search: "",
                          collectionId: item._id,
                        })
                      );
                    }}
                  >
                    <span className="line_dash">
                      {collapse ? item.name.slice(0, 4) : item?.name}
                    </span>
                  </div>
                  <Popover
                    overlayClassName="threeDot-pop"
                    placement="bottomRight"
                    content={
                      <div
                        className="popup"
                        onClick={() => { }}
                        style={{ width: "160px" }}
                      >
                        {index === currentIndex ? (
                          <div
                            onClick={() => {
                              hide(item, index, "rename");
                            }}
                            className="edit-rename bg-edit"
                          >
                            <a onClick={() => { }}>
                              <img src={Images.edit} />
                              Rename
                            </a>
                          </div>
                        ) : null}

                        {index === currentIndex ? (
                          <div onClick={() => {
                            let nextcollection = (collectionList && collectionList.length > (index + 1)) ? collectionList[index + 1]._id : null
                            setNextcollectionId(nextcollection)
                            deleteCollection(item)
                            // hide(item, index, "delete");
                          }} className="delet-btn-in">
                            <a
                            >
                              <img src={Images.deleteRedFill} />
                              Delete
                            </a>
                          </div>
                        ) : null}
                      </div>
                    }
                    title=""
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenChange}
                  >
                    <img
                      onClick={() => hide(item, index, "dotsClicked")}
                      src={Images.dots}
                    />
                  </Popover>
                </Menu.Item>
              ))}
              {collectionList?.length > 0 && <p className="collection-info-text">Create collections to save incidents by your own
                category prioritization</p>}
              {/* <div className="angle-btn-bott">
                {collapse ? (
                  <RightOutlined onClick={handleToggle} />
                ) : (
                  <LeftOutlined onClick={handleToggle} />
                )}
              </div> */}
            </Menu>

          </div>
          <div className="foot-sec">
            {collapse ? (
              <>
                <a href="#">
                  <img src={Images.plus} />{" "}
                </a>
              </>
            ) : (
              <a
                style={{
                  color: "#0195CE",
                  display: "flex",
                  justifyContent: "center",

                }}
                onClick={() => {
                  dispatch(
                    createSaveIncidenceAction({
                      name: "collection" + collectionList?.length + 1,
                    })
                  );
                }}
              >
                <img src={Images.plus} />
                <p
                  style={{
                    marginLeft: "3px",
                    color: "#0195CE",
                    fontStyle: "Inter",
                  }}
                >
                  Create New Collection
                </p>
              </a>
            )}
          </div>
        </div>
      </Sider>
      <Modal
        title={<ModalHeader title={"Rename Collection"} height={true} />}
        className=" rename-modal-ps1"
        centered
        closeIcon={<ModalCloseButton />}
        open={modal2Open}
        onCancel={() => setModal2Open(false)}
        footer={[
          <div style={{ marginRight: "27px" }}>
            <button
              className="can-btn chang"
              onClick={() => setModal2Open(false)}
            >
              Cancel
            </button>
            <button
              className="rest-btn"
              onClick={() => {
                if (isInternetConnected()) { 
                  if (updateListName.inputVal.trim() == "") {
                    toast.error("Please enter collection name.", {
                      position: toast.POSITION.TOP_RIGHT,
                      id: 'validFolderName'
                    });
                  } else {
                    dispatch(
                      renameCollection({
                        collectionId: currentId,
                        name: updateListName.inputVal.trim(),
                      })
                    );
                    setModal2Open(false);
                  }
                 
                }
               
              }}
            >
              Save
            </button>
          </div>,
        ]}
      >
        <div className="reset-setting folder-num">
          <p>Collection Name</p>
          <div className="in-put-popup">
            {updateListName.isCard ? (
              <div
                style={{
                  border: "1px solid #4295ce",
                  padding: "12px",
                  display: "flex",
                  borderRadius: "4px",

                }}
                onClick={() =>
                  setUpdateListName({ ...updateListName, isCard: false })
                }
              >
                <div
                  style={{
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    width: "100%",
                    backgroundColor: "#d7f0fa",
                  }}
                >
                  {updateListName.inputVal}
                </div>
              </div>
            ) : (
              <Input
                className="input-watch"
                maxLength={120}
                style={{ paddingRight: "40px" }}
                prefix={
                  <img
                    style={{ marginTop: "-7px" }}
                    src={ImageConst.closeIcon}
                    onClick={() =>
                      setUpdateListName({ inputVal: "", isCard: false })
                    }
                  />
                }
                value={updateListName?.inputVal}
                type="text"
                onChange={(e) => {
                  if (e.target.value > 3) {
                    setUpdateListName({
                      ...updateListName,
                      inputVal: e.target.value,
                    });
                  } else {
                    setUpdateListName({
                      ...updateListName,
                      inputVal: e.target.value,
                    });
                  }
                }}

              />
            )}
          </div>
        </div>
      </Modal>

      <ConfirmationModal
        type="modal2"
        title="Delete Collection"
        visible={visible}
        cancel={{
          cancelText: "Cancel",
          onCancel: () => setVisible(false),
        }}
        ok={{
          okText: "Delete",
          onOK: () => {
            dispatch(deleteCollectionAction({ id: currentId }));
            setVisible(false);
          },
        }}
        itemName={currentName}
        icon={Images.deletError}
        description="By deleting it, all the incidents in this folder will be removed too."
      />
    </div>
  );
};
export default IncidentCollectionSideBar;
