import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChartCard } from "../../../../Layouts/ChartCard";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import {
  IncidentComparisonAction,
  RemoverIncidentComparisonAction,
} from "../../../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import ImageConst from "../../../../Themes/ImageConstant";
import { AutoComplete, Modal, Row } from "antd";
import { getCountryDataforSupplyChain } from "../../../../Redux/Action/supplyChainAction";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import RadarChart from "../../../socialMediaCoverage/charts/radarChart";
import useDebouncedApiCall from "../../../../hook/useDebouncedSearch";
import { searchCompanyListActionLoad } from "../../../../Redux/Action/global";

const PeerCompaniesIncidentComparison = ({
  id,
  start,
  end,
  companyName,
  dateChange,
}) => {

  const [options, setOptions] = useState([]);
  const [countryName, setCountryName] = useState(null);
  const [comparisonModal, setComparisonModal] = useState(false);
  const { searchCompaniesData } = useSelector((state) => state?.global);
  const { handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );
  useEffect(() => {
    dispatch(getCountryDataforSupplyChain());
    dispatch(IncidentComparisonAction({ id, start, end, type: "multiple" }));
  }, [dateChange]);

  const { countryList } = useSelector((state) => state?.supplyChainReducer);
  const { IncidentComparison } = useSelector(
    (state) => state?.companyPageReducer
  );

  useEffect(() => {
    setOptions(countryList);
  }, [countryList]);

  const Chart = useCallback(() => {
    return IncidentComparison?.map((item) => {
      return (
        <div key={"value"}>
          <ChartBottomTitle title={item.name}>
            <Row justify={"space-between"}>
              <RadarChart
                path={"company"}
                data={{
                  "incident Number": Math.round(item?.totalIncidents),
                  severity: Math.round(item?.severity_score),
                  "neg Sentiment": Math.round(item?.negativeSentiment),
                  risk: Math.round(item?.risk),
                }}
                labelObj={{
                  "incident Number": "Incident Number",
                  severity: "Severity",
                  "neg Sentiment": "Neg Sentiment",
                  risk: "Risk",
                }}
              />
              {IncidentComparison?.length < 2 ? (
                <RadarChart
                  path={"company"}
                  data={{
                    "incident Number": 0,
                    risk: 0,
                    "neg Sentiment": 0,
                    severity: 0,
                  }}
                  labelObj={{
                    "incident Number": "Incident Number",
                    risk: "Risk",
                    "neg Sentiment": "Neg Sentiment",
                    severity: "Severity",
                  }}
                />
              ) : null}
            </Row>
          </ChartBottomTitle>
          {id !== item.id && (
            <Row justify={"center"}>
              <button
                border={0}
                style={{
                  borderColor: "transparent",
                  background: "none",
                  gap: 5,
                }}
                onClick={() =>
                  dispatch(RemoverIncidentComparisonAction(item.id))
                }
              >
                <Row justify="space-between" gutter={2}>
                  <img alt="" src={ImageConst.cross} />
                  <span style={{ color: "#920000", marginLeft: 5 }}>
                    Remove
                  </span>
                </Row>
              </button>
            </Row>
          )}
        </div>
      );
    });
  }, [IncidentComparison]);

  const renderItem = (title, item) => ({
    value: item,
    label: (
      <div
        style={{
          pointerEvents: id === item ? "none" : "",
          cursor: id === item ? "no-drop" : "pointer",
        }}
      >
        {title}
      </div>
    ),
  });

  return (
    <section className="left-text">
      <ChartCard
        showTimeBarOnTop={false}
        title={"Peer Companies’ Incident Comparison"}
      >
        <div className="peer-company-comparision-chart-container">
          <div className="radar-Title-organizationName">{companyName}</div>
          <div className="radar-subTitle-organizationName">{companyName}</div>

          <div className="comparison-gaph">
            <div className="radar-chart-sp">
              {/* to show dummy if not data found of this particular company */}
              {IncidentComparison?.length === 0 && (
                <div className="rdr-wrap-Um">
                  <ChartBottomTitle title={companyName}>
                    <RadarChart
                      path={"company"}
                      data={{
                        "incident Number": 0,
                        risk: 0,
                        "neg Sentiment": 0,
                        severity: 0,
                      }}
                      labelObj={{
                        "incident Number": "Incident Number",
                        risk: "Risk",
                        "neg Sentiment": "Neg Sentiment",
                        severity: "Severity",
                      }}
                    />
                  </ChartBottomTitle>
                </div>
              )}
              <Chart />
            </div>
            <div
              style={{
                margin: "auto",
                width: "fit-content",
                marginTop: "10px",
              }}
            >
              <button
                type="button"
                className="add-to-Com"
                onClick={() => setComparisonModal(true)}
              >
                <img alt="" src={ImageConst.PlushBlack} />
                <span>Add to Comparison</span>
              </button>
            </div>
          </div>
        </div>
      </ChartCard>
      <Modal
        open={comparisonModal}
        closable
        title={"Advanced Search"}
        width={1000}
        onCancel={() => setComparisonModal(false)}
        footer={() => {}}
        className="comparisonModal"
      >
        <div style={{ overflow: "hidden" }}>
          <AutoComplete
            placeholder={"Search company"}
            style={{
              width: "100%",
              margin: "18px 0",
              border: "none",
              boxShadow: "none",
            }}
            suffixIcon={<img src={ImageConst.search} alt="" />}
            // popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={1000}
            className="autocomplete-input"
            options={searchCompaniesData?.data}
            size="large"
            onSearch={handleSearch}
            value={countryName}
            onSelect={(e, i) => {
              i?.id !== id &&
                dispatch(
                  IncidentComparisonAction({
                    id: i?.id,
                    start,
                    end,
                    type: "single",
                  })
                );
              setCountryName(i.label);
              setComparisonModal(false);
            }}
            onChange={(e, i) => {
              setCountryName(e);
            }}
          ></AutoComplete>
        </div>
      </Modal>
    </section>
  );
};

export default PeerCompaniesIncidentComparison;
