import { apiConstants as types } from "../../Constants/apiConstants";

export const getSocialMediaDetailLoad = (data) => ({
  type: types.GET_SOCIAL_MEDIA_TAGS_LOAD,
  payload: data,
});

export const getSocialMediaDetailSuccess = (data) => ({
  type: types.GET_SOCIAL_MEDIA_TAGS_SUCCESS,
  payload: data,
});

export const getSocialMediaDetailFail = (data) => ({
  type: types.GET_SOCIAL_MEDIA_TAGS_FAILURE,
  payload: data,
});

export const getTweetWithRepliesLoad = (data) => ({
  type: types.GET_TWEET_WITH_REPLIES_LOAD,
  payload: data,
});

export const getTweetWithRepliesSuccess = (data) => ({
  type: types.GET_TWEET_WITH_REPLIES_SUCCESS,
  payload: data,
});

export const getTweetWithRepliesFail = (data) => ({
  type: types.GET_TWEET_WITH_REPLIES_FAILURE,
  payload: data,
});

export const getIndividualTweetReplyLoad = (data) => ({
  type: types.GET_TWEET_INDIVIDUAL_REPLY_LOAD,
  payload: data,
});

export const getIndividualTweetReplySuccess = (data) => ({
  type: types.GET_TWEET_INDIVIDUAL_REPLY_SUCCESS,
  payload: data,
});

export const getIndividualTweetReplyFail = (data) => ({
  type: types.GET_TWEET_INDIVIDUAL_REPLY_FAILURE,
  payload: data,
});

export const getSocialMediaReactionLoad = (data) => ({
  type: types.GET_SOCIAL_MEDIA_REACTIONS_LOAD,
  payload: data,
});

export const getSocialMediaReactionSuccess = (data) => ({
  type: types.GET_SOCIAL_MEDIA_REACTIONS_SUCCESS,
  payload: data,
});

export const getSocialMediaReactionFail = (data) => ({
  type: types.GET_SOCIAL_MEDIA_REACTIONS_FAILURE,
  payload: data,
});

export const getOverallReactionSentimentLoad = (data) => ({
  type: types.GET_OVERALL_REACTION_SENTIMENT_LOAD,
  payload: data,
});

export const getOverallReactionSentimentSuccess = (data) => ({
  type: types.GET_OVERALL_REACTION_SENTIMENT_SUCCESS,
  payload: data,
});

export const getOverallReactionSentimentFail = (data) => ({
  type: types.GET_OVERALL_REACTION_SENTIMENT_FAILURE,
  payload: data,
});

export const getOverallRedditReactionLoad = (data) => ({
  type: types.GET_OVERALL_REDDIT_REACTION_LOAD,
  payload: data,
});

export const getOverallRedditReactionSuccess = (data) => ({
  type: types.GET_OVERALL_REDDIT_REACTION_SUCCESS,
  payload: data,
});

export const getOverallRedditReactionFail = (data) => ({
  type: types.GET_OVERALL_REDDIT_REACTION_FAILURE,
  payload: data,
});

export const getRadarDataLoad = (data) => ({
  type: types.GET_RADAR_DATA_LOAD,
  payload: data,
});

export const getRadarDataSuccess = (data) => ({
  type: types.GET_RADAR_DATA_SUCCESS,
  payload: data,
});

export const getRadarDataFail = (data) => ({
  type: types.GET_RADAR_DATA_FAILURE,
  payload: data,
});

export const getTotalTweetReactionLoad = (data) => ({
  type: types.TOTAL_TWEET_REACTION_LOAD,
  payload: data,
});

export const getTotalTweetReactionSuccess = (data) => ({
  type: types.TOTAL_TWEET_REACTION_SUCCESS,
  payload: data,
});

export const getTotalTweetReactionFail = (data) => ({
  type: types.TOTAL_TWEET_REACTION_FAILURE,
  payload: data,
});

export const getTotalRedditReactionLoad = (data) => ({
  type: types.TOTAL_REDDIT_REACTION_LOAD,
  payload: data,
});

export const getTotalRedditReactionSuccess = (data) => ({
  type: types.TOTAL_REDDIT_REACTION_SUCCESS,
  payload: data,
});

export const getTotalRedditReactionFail = (data) => ({
  type: types.TOTAL_REDDIT_REACTION_FAILURE,
  payload: data,
});

export const getTotalSocialMediaReactionLoad = (data) => ({
  type: types.TOTAL_SOCIAL_MEDIA_REACTION_LOAD,
  payload: data,
});

export const getTotalSocialMediaReactionSuccess = (data) => ({
  type: types.TOTAL_SOCIAL_MEDIA_REACTION_SUCCESS,
  payload: data,
});

export const getTotalSocialMediaReactionFail = (data) => ({
  type: types.TOTAL_SOCIAL_MEDIA_REACTION_FAILURE,
  payload: data,
});

export const getRedditWithPostLoad = (data) => ({
  type: types.GET_REDDIT_WITH_POST_LOAD,
  payload: data,
});
export const getRedditWithPostSuccess = (data) => ({
  type: types.GET_REDDIT_WITH_POST_SUCCESS,
  payload: data,
});
export const getRedditWithPostFail = (data) => ({
  type: types.GET_REDDIT_WITH_POST_FAIL,
  payload: data,
});

export const getRedditWithCommentLoad = (data) => ({
  type: types.GET_REDDIT_POST_COMMENT_LOAD,
  payload: data,
});
export const getRedditWithCommentSuccess = (data) => ({
  type: types.GET_REDDIT_POST_COMMENT_SUCCESS,
  payload: data,
});
export const getRedditWithCommentFail = (data) => ({
  type: types.GET_REDDIT_POST_COMMENT_FAIL,
  payload: data,
});

export const getMostInfluentialUserLoad = (data) => ({
  type: types.GET_MOST_INFLUENTIAL_USER_LOAD,
  payload: data,
});
export const getMostInfluentialUserSuccess = (data) => ({
  type: types.GET_MOST_INFLUENTIAL_USER_SUCCESS,
  payload: data,
});
export const getMostInfluentialUserFail = (data) => ({
  type: types.GET_MOST_INFLUENTIAL_USER_FAIL,
  payload: data,
});

export const getSummaryDataLoad = (data) => ({
  type: types.GET_SUMMARY_DATA_LOAD,
  payload: data,
});

export const getSummaryDataSuccess = (data) => ({
  type: types.GET_SUMMARY_DATA_SUCCESS,
  payload: data,
});

export const getSummaryDataFail = (data) => ({
  type: types.GET_SUMMARY_DATA_FAIL,
  payload: data,
});

export const updateSocialMediaState = (data, key) => ({
  type: types.UPDATE_SOCIAL_MEDIA_STATE,
  payload: data,
  key,
});

export const socialMediaMapActionLoad = (data, key) => ({
  type: types.SOCIAL_MEDIA_MAP_LOAD,
  payload: data,
  key,
});
