import React from 'react';
import { SvgIcon } from '@mui/material';

function InvisibleIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M2.49854 2.5L17.4985 17.5M8.2021 8.26137C7.76657 8.71129 7.49854 9.32435 7.49854 10C7.49854 11.3807 8.61782 12.5 9.99853 12.5C10.6839 12.5 11.3049 12.2242 11.7565 11.7775M5.4152 5.53929C3.83245 6.58361 2.62689 8.15328 2.04688 9.99997C3.10875 13.3809 6.26734 15.8333 9.99872 15.8333C11.6561 15.8333 13.2005 15.3495 14.4984 14.5153M9.1652 4.20783C9.43934 4.18061 9.71739 4.16667 9.9987 4.16667C13.7301 4.16667 16.8887 6.61909 17.9505 10C17.7166 10.745 17.3808 11.4448 16.9595 12.0833"
          stroke="#80838B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
export default InvisibleIcon;
