import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calculateEndDate } from "../../../../Utils";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import moment from "moment";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { Modal, Select } from "antd";
import {
  allInterval,
  oneDayInterval,
  oneMonthInterval,
  oneWeekInterval,
  oneYearInterval,
  threeMonthInterval,
} from "../../../../Constants/appConstansts";
import GlobalSpinner from "../../../../Utils/GlobalSpinner";
import ESGIncident from "../../../../Componets/esg_incident";
import {
  HOMEIotAction,
  updateLoader,
} from "../../../../Redux/Action/incidents";
import IncidentsListModal from "../../../IncidentsListModal";
import ImageConst from "../../../../Themes/ImageConstant";

const createCSeriesData = (data, tab) => {
  const finalObj = {
    Governance: [],
    Environmental: [],
    Social: [],
    total: [],
  };
  let keys = Object.keys(data);
  try {
    for (let i = 0; i < keys?.length; i++) {
      let key = keys[i];
      if (keys[i] === "esg_categories") {
        let a = Object.keys(data[key]);
        let val = a.pop();
        a.map((i, j) => {
          finalObj[i] = Object.values(data[key][i]);
          // if (
          //   data.groupType == "hour"
          // ) {
          let a = Object.keys(data[key][i]);
          finalObj.label = a.map((i) => {
            let dataArr = i.split("&");
            return moment(dataArr[0]).format(
              tab > 2 || tab == null ? "DD MMM YY HH:mm" : "DD MMM HH:mm"
            );
          });
          finalObj.allLabel = a.map((i) => {
            let dataArr = i.split("&");
            let start_Date = moment(dataArr[0]).format(
              tab > 2 || tab == null ? "DD MMM YY HH:mm" : "DD MMM HH:mm"
            );
            let end_Date = moment(dataArr[1]).format(
              tab > 2 || tab == null ? "DD MMM YY HH:mm" : "DD MMM HH:mm"
            );
            return start_Date + "-" + end_Date;
          });
        });
      } else {
        finalObj[key] = Object.values(data[key]);
      }
    }

    return { ...finalObj };
  } catch (e) {}
};

const IncidentOverTime = (netWorkData) => {
  const [selectedTab, setSelectedTab] = useState(4);
  const [selectedFilter, setSelectedFilter] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(netWorkData);
  const [fullViewModalVisible, setfullViewModalVisible] = useState(false);

  useEffect(() => {
    localStorage.interval = "Last 3 months";
  }, []);

  useEffect(() => {
    let interval = localStorage.interval;
    if (interval == "Last 24 hours") {
      setSelectedTab(1);
    }
    if (interval == "Last week") {
      setSelectedTab(2);
    }
    if (interval == "Last Month") {
      setSelectedTab(3);
    }
    if (interval == "Last 3 months") {
      setSelectedTab(4);
    }
    if (interval == "Last 6 months") {
      setSelectedTab(5);
    }
    if (interval == "Last year") {
      setSelectedTab(6);
    }
    if (interval == "Custom Date") {
      setSelectedTab(null);
    }
  }, [localStorage.interval]);

  useEffect(() => {
    if (selectedTab == 1) {
      setSelectedFilter("1Hr");
    }
    if (selectedTab == 2) {
      setSelectedFilter("3Hr");
    }
    if (selectedTab == 3) {
      setSelectedFilter("8Hr");
    }
    if (selectedTab == 4) {
      setSelectedFilter("16Hr");
    }
    if (selectedTab == 5) {
      setSelectedFilter("1D");
    }
    if (selectedTab == 6) {
      setSelectedFilter("1W");
    }
    if (selectedTab == null) {
      if (moment(netWorkData.end).diff(netWorkData.start, "days") > 7) {
        setSelectedFilter("1D");
        dispatch(HOMEIotAction({ ...netWorkData, interval: "1D" }));
      } else {
        setSelectedFilter("3Hr");
        dispatch(HOMEIotAction({ ...netWorkData, interval: "3Hr" }));
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab == null) {
      if (moment(netWorkData.end).diff(netWorkData.start, "days") > 7) {
        setSelectedFilter("1D");
        dispatch(HOMEIotAction({ ...netWorkData, interval: "1D" }));
      } else {
        setSelectedFilter("3Hr");
        dispatch(HOMEIotAction({ ...netWorkData, interval: "3Hr" }));
      }
    } else {
      dispatch(HOMEIotAction({ ...netWorkData, interval: selectedFilter }));
    }
  }, [netWorkData.dateChange, netWorkData.companies, netWorkData.locations]);

  useEffect(() => {
    if (selectedFilter) {
      if (selectedTab !== null) {
        let start = moment(calculateEndDate(new Date(), selectedTab)).format(
          "YYYY-MM-DD"
        );
        let end = moment().format("YYYY-MM-DD");
        dispatch(
          HOMEIotAction({
            ...netWorkData,
            start,
            end,
            interval: selectedFilter,
          })
        );
      }
    }
  }, [selectedFilter]);

  const { HomeIOTData, serialData, IOTLoader } = useSelector(
    (state) => state.incidentAnalyzerReducer
  );

  const seriesData = useCallback(
    () => createCSeriesData(serialData, selectedTab),
    [serialData]
  );

  const data = serialData && seriesData();
  const option = {
    color: ["#6EBD20", "#2191FB", "#653DD4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },

      formatter: function (params) {
        if (params.length > 0) {
          return `
        <div style="width:200px;margin:5px; border-radius:5px;">
            <div style="font-weight:bold;font-size:13px;color:#000; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            ${data.allLabel[params[0].dataIndex]}
            </div>
            <div style="margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            <div class="tooltip-item" style="color:${params[0].color}">${params[0].seriesName}: ${params[0].data}</div>
            ${params.length > 1 ? `<div class="tooltip-item" style="color:${params[1].color}">${params[1].seriesName}: ${params[1].data}</div>` : ``}
            ${params.length > 2 ? `<div class="tooltip-item" style="color:${params[2].color}">${params[2].seriesName}: ${params[2].data}</div>` : ``}
            </div>

        </div>
          `;
        }
      },
    },
    toolbox: {},
    legend: {
      data: ["Environment", "Social", "Governance"],
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: data.label,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    dataZoom: [
      {
        type: "slider", // This is a slider at the bottom
        zoomOnMouseWheel: "ctrl", // Requires the Ctrl key to be pressed to zoom with the mouse wheel
      },
      {
        type: "inside", // This enables zooming and panning with mouse actions
        zoomOnMouseWheel: "ctrl", // Also requires the Ctrl key for the slider, for consistency
      },
    ],
    series: [
      {
        name: "Environment",
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#6EBD20",
            },
            {
              offset: 1,
              color: "#6EBD20",
            },
          ]),
        },
        emphasis: {
          focus: "series",
          scale: 2,
        },
        data: HomeIOTData?.Environmental ? HomeIOTData?.Environmental : [],
      },
      {
        name: "Social",
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        itemStyle: {
          width: 4,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#2191FB",
            },
            {
              offset: 1,
              color: "#2191FB",
            },
          ]),
        },
        emphasis: {
          focus: "series",
          scale: 2,
        },
        data: HomeIOTData?.Social ? HomeIOTData?.Social : [],
      },
      {
        name: "Governance",
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        itemStyle: {
          width: 4,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#653DD4",
            },
            {
              offset: 1,
              color: "#653DD4",
            },
          ]),
        },
        emphasis: {
          focus: "series",
          scale: 2,
        },
        data: HomeIOTData?.Governance ? HomeIOTData?.Governance : [],
      },
    ],
  };
  const handleChange = (value) => {
    setSelectedFilter(value);
    if (selectedTab == null) {
      dispatch(HOMEIotAction({ ...netWorkData, interval: value }));
    }
  };

  const getoption = () => {
    switch (selectedTab) {
      case 1:
        return oneDayInterval;
      case 2:
        return oneWeekInterval;
      case 3:
        return oneMonthInterval;
      case 4:
        return threeMonthInterval;
      case 5:
        return threeMonthInterval;
      case 6:
        return oneYearInterval;
      case null:
        return allInterval;

      default:
        break;
    }
  };

  const handleClick = (params) => {
    let allData = netWorkData;
    let labelArr = data.allLabel[params.dataIndex].split("-");
    allData = {
      ...allData,
      esg_categories: [
        params.seriesName == "Environment"
          ? "Environmental"
          : params.seriesName,
      ],
      start: moment(labelArr[0]).format("YYYY-MM-DDTHH:mm:ss"),
      end: moment(labelArr[1]).format("YYYY-MM-DDTHH:mm:ss"),
      lineGraph: true,
    };
    setSelectedData(allData);
    setModalVisible(true);
  };
  return (
    <section className="left-text mt-4">
      <ESGIncident title={"Incidents Over Time"}>
        <div className="top-checkbox">
          <div className="d-flex justify-content-between w-100">
            <div className="custom-buttons">
              <button
                onClick={() => setSelectedTab(1)}
                className={selectedTab === 1 ? "active-map-button" : ""}
              >
                1D
              </button>
              <button
                onClick={() => setSelectedTab(2)}
                className={selectedTab === 2 ? "active-map-button" : ""}
              >
                1W
              </button>
              <button
                onClick={() => setSelectedTab(3)}
                className={selectedTab === 3 ? "active-map-button" : ""}
              >
                1M
              </button>
              <button
                onClick={() => setSelectedTab(4)}
                className={selectedTab === 4 ? "active-map-button" : ""}
              >
                3M
              </button>
              <button
                onClick={() => setSelectedTab(5)}
                className={selectedTab === 5 ? "active-map-button" : ""}
              >
                6M
              </button>
              <button
                onClick={() => setSelectedTab(6)}
                className={selectedTab === 6 ? "active-map-button" : ""}
              >
                1Y
              </button>
            </div>
            <div className="intervalView">
              <span className="top-checkboxTitle">Time Interval</span>
              <Select
                defaultValue="Interval"
                value={selectedFilter}
                style={{ width: 80 }}
                onChange={handleChange}
                options={getoption()}
              />
              {!fullViewModalVisible ? (
                <img
                  onClick={() => {
                    dispatch(updateLoader(true));
                    setfullViewModalVisible(true);
                    setTimeout(() => {
                      dispatch(updateLoader(false));
                    }, 100);
                  }}
                  src={ImageConst.fullScreen}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="Sentiment-grah">
          <div className="line-chart pt-0">
            {IOTLoader == false ? (
              <ReactECharts
                echarts={echarts}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                style={{ width: "100%" }}
                onEvents={{
                  click: handleClick,
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 300,
                }}
              >
                <GlobalSpinner colorx={"#0195ce"} />
              </div>
            )}
          </div>
        </div>
      </ESGIncident>
      {modalVisible ? (
        <IncidentsListModal
          setModalVisible={setModalVisible}
          networkData={{
            ...selectedData,
          }}
        />
      ) : null}

      {fullViewModalVisible ? (
        <Modal
          className={"incident-page-table-modal-ps"}
          wrapClassName="incident-list-modal"
          open={fullViewModalVisible}
          footer={[]}
          onCancel={() => {
            setfullViewModalVisible(false);
          }}
        >
          <ESGIncident title={"Incidents Over Time"}>
            <div className="top-checkbox">
              <div className="d-flex justify-content-between w-100">
                <div className="custom-buttons">
                  <button
                    onClick={() => setSelectedTab(1)}
                    className={selectedTab === 1 ? "active-map-button" : ""}
                  >
                    1D
                  </button>
                  <button
                    onClick={() => setSelectedTab(2)}
                    className={selectedTab === 2 ? "active-map-button" : ""}
                  >
                    1W
                  </button>
                  <button
                    onClick={() => setSelectedTab(3)}
                    className={selectedTab === 3 ? "active-map-button" : ""}
                  >
                    1M
                  </button>
                  <button
                    onClick={() => setSelectedTab(4)}
                    className={selectedTab === 4 ? "active-map-button" : ""}
                  >
                    3M
                  </button>
                  <button
                    onClick={() => setSelectedTab(5)}
                    className={selectedTab === 5 ? "active-map-button" : ""}
                  >
                    6M
                  </button>
                  <button
                    onClick={() => setSelectedTab(6)}
                    className={selectedTab === 6 ? "active-map-button" : ""}
                  >
                    1Y
                  </button>
                </div>
                <div className="intervalView">
                  <span className="top-checkboxTitle">Time Interval</span>
                  <Select
                    defaultValue="Interval"
                    value={selectedFilter}
                    style={{ width: 80 }}
                    onChange={handleChange}
                    options={getoption()}
                  />
                </div>
              </div>
            </div>
            <div className="Sentiment-grah">
              <div className="line-chart pt-0">
                {IOTLoader == false ? (
                  <ReactECharts
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={true}
                    style={{ width: "100%" }}
                    onEvents={{
                      click: handleClick,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 300,
                    }}
                  >
                    <GlobalSpinner colorx={"#0195ce"} />
                  </div>
                )}
              </div>
            </div>
          </ESGIncident>
        </Modal>
      ) : null}
    </section>
  );
};

export default IncidentOverTime;
