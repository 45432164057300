import { apiConstants as types } from "../../Constants/apiConstants";

export const getAdvncTopSearchDataLoad = (data) => ({
  type: types.GET_ADVNC_TOP_SEARCH_DATA_LOAD,
  data,
});

export const getAdvncTopSearchDataSuccess = (data) => ({
  type: types.GET_ADVNC_TOP_SEARCH_DATA_SUCCESS,
  payload: data,
});

export const updateAdvncTopSearchData = (data) => ({
  type: types.UPDATE_ADVNC_TOP_SEARCH_DATA,
  payload: data,
});

export const getAdvncTopSearchDataFailure = (data) => ({
  type: types.GET_ADVNC_TOP_SEARCH_DATA_FAILURE,
  payload: data,
});

export const getAdvncCompanyDataLoad = (data) => ({
  type: types.GET_ADVNC_COMPANY_DATA_LOAD,
  payload: data,
});

export const getAdvncCompanyDataSuccess = (data) => ({
  type: types.GET_ADVNC_COMPANY_DATA_SUCCESS,
  payload: data,
});

export const getUpdateAdvncCompanyDataSuccess = () => ({
  type: types.GET_UPDATE_ADVNC_COMPANY_DATA_SUCCESS
});

export const updateAdvncCompanyDataSuccess = (data, number) => {
  return {
    type: types.UPDATE_ADVNC_COMPANY_DATA_SUCCESS,
    payload: data,
  };
};

export const getAdvncCompanyDataFail = (data) => ({
  type: types.GET_ADVNC_COMPANY_DATA_FAILURE,
  payload: data,
});

export const getAdvncIncLocationDataLoad = (data) => ({
  type: types.GET_ADVNC_INC_LOCATION_DATA_LOAD,
  payload: data,
});

export const getAdvncIncLocationDataSuccess = (data) => ({
  type: types.GET_ADVNC_INC_LOCATION_DATA_SUCCESS,
  payload: data,
});

export const getAdvncIncLocationDataFail = (data) => ({
  type: types.GET_ADVNC_INC_LOCATION_DATA_FAILURE,
  payload: data,
});

export const getAdvncCategoryDataLoad = (data) => ({
  type: types.GET_ADVNC_CATEGORY_DATA_LOAD,
  payload: data,
});

export const getAdvncCategoryDataSuccess = (data) => ({
  type: types.GET_ADVNC_CATEGORY_DATA_SUCCESS,
  payload: data,
});

export const getAdvncCategoryDataFail = (data) => ({
  type: types.GET_ADVNC_CATEGORY_DATA_FAILURE,
  payload: data,
});

export const getAdvncIndustryDataLoad = (data) => ({
  type: types.GET_ADVNC_INDUSTRY_DATA_LOAD,
  payload: data,
});

export const getAdvncIndustryDataSuccess = (data) => ({
  type: types.GET_ADVNC_INDUSTRY_DATA_SUCCESS,
  payload: data,
});

export const getAdvncIndustryDataFail = (data) => ({
  type: types.GET_ADVNC_INDUSTRY_DATA_FAILURE,
  payload: data,
});

export const updateIncidentLocationData = ({
  continentsIndex,
  CountryIndex,
  type,
}) => {
  return {
    type: types.UPDATE_INCIDENT_LOCATION_DATA,
    payload: { continentsIndex, CountryIndex, type },
  };
};

export const updateAdvanceCategoriesData = (data) => {
  return {
    type: types.UPDATE_CATEGORIES_DATA_ADVANCE,
    payload: data,
  };
};

export const updateAdvanceSearchState = (data, key) => ({
  type: types.UPDATE_ADVANCE_SEARCH_DATA,
  payload: data,
  key,
});

export const updateAdvSearchLocationArray = (data) => ({
  type: types.UPDATE_ADVNC_INC_LOCATION_DATA_SUCCESS,
  payload: data,
});

export const updateIndustryArray = (data) => {
  return {
    type: types.UPDATE_ADVNC_INDUSTRY_DATA_SUCCESS,
    payload: data,
  };
};
