import {
  resetRightClickMenu,
  setShowSpecificRelationsModal,
} from "../../../redux/GraphExploration/rightClickMenuSlice";
import { useAppDispatch } from "../../../redux/configuareStore";
import CustomMenuItem from "./CustomMenuItem";

function SpecificRelations() {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(setShowSpecificRelationsModal(true));
    dispatch(resetRightClickMenu());
  };
  return (
    <CustomMenuItem
      onClick={() => {
        handleClick();
      }}
      label="Specific relations ..."
    />
  );
}

export default SpecificRelations;
