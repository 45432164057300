import React, { useEffect, useState } from "react";
import BubbleChart from "../../../../Componets/BubbleChart";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import {
  getBubbleRange,
  getFilteredArrayforBubbles,
  PNN,
} from "../../../../Utils";
import Images from "../../../../Themes/ImageConstant";
import Styles from "./socialTimeline.module.css";

import { structureData } from "../../../../Utils/chartCommandFunction";
import { ChartCard } from "../../../../Layouts/ChartCard";
import { convertTimestampinFormatedDate } from "../../../../Utils/globalFunction";
import moment from "moment";

const OverviewTimeline = (networkData) => {
  const [redditPositiveData, setRedditPositiveData] = useState([]);
  const [redditNegativeData, setRedditNegativeData] = useState([]);
  const [redditNeutralData, setRedditNeutralData] = useState([]);

  const [tweetPositiveData, setTweetPositiveData] = useState([]);
  const [tweetNegativeData, setTweetNegativeData] = useState([]);
  const [tweetNeutralData, setTweetNeutralData] = useState([]);

  useEffect(() => {
    if (Object.keys(networkData.redditData).length > 0) {
      let posiData = Object.entries(networkData.redditData.positive.data);
      posiData = structureData(posiData, "default", "Calledx");

      let negData = Object.entries(networkData.redditData.negative.data);
      negData = structureData(negData, "default", "Calledx");

      let nueData = Object.entries(networkData.redditData.neutral.data);
      nueData = structureData(nueData, "default", "Calledx");

      const comind = [
        ...posiData.map((e) => e[2]),
        ...negData.map((e) => e[2]),
        ...nueData.map((e) => e[2]),
      ].sort((a, b) => a - b);
      const min = comind[0],
        max = comind[comind.length - 1];

      setRedditPositiveData(getFilteredArrayforBubbles(posiData, min, max));
      setRedditNegativeData(getFilteredArrayforBubbles(negData, min, max));
      setRedditNeutralData(getFilteredArrayforBubbles(nueData, min, max));
    }
  }, [networkData?.redditData]);

  useEffect(() => {
    if (Object.keys(networkData.tweetData).length > 0) {
      let posiData = Object.entries(networkData.tweetData.positive.data);
      posiData = structureData(posiData, "default", "Calledx2");

      let negData = Object.entries(networkData.tweetData.negative.data);
      negData = structureData(negData, "default", "Calledx2");

      let nueData = Object.entries(networkData.tweetData.neutral?.data);
      nueData = structureData(nueData, "default", "Calledx2");

      const comind = [
        ...posiData.map((e) => e[2]),
        ...negData.map((e) => e[2]),
        ...nueData.map((e) => e[2]),
      ].sort((a, b) => a - b);
      const min = comind[0],
        max = comind[comind.length - 1];
      setTweetPositiveData(getFilteredArrayforBubbles(posiData, min, max));
      setTweetNegativeData(getFilteredArrayforBubbles(negData, min, max));
      setTweetNeutralData(getFilteredArrayforBubbles(nueData, min, max));
    }
  }, [networkData?.tweetData]);

  const seriesData = [
    // TWITTER
    {
      name: "Negative",
      data: tweetNegativeData,
    },
    {
      name: "Positive",
      data: tweetPositiveData,
    },
    {
      name: "Neutral",
      data: tweetNeutralData,
    },

    // REDDIT
    {
      name: "Negative",
      data: redditNegativeData,
    },
    {
      name: "Positive",
      data: redditPositiveData,
    },
    {
      name: "Neutral",
      data: redditNeutralData,
    },
  ];

  let incidentList;
  const incidentFormatTooltip = ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }) => {
    incidentList = w?.config.series[seriesIndex].data[dataPointIndex][3].map(
      (val) => val.id
    );

    const type = w?.config.series[seriesIndex].name;
    const date = convertTimestampinFormatedDate(
      w?.config.series[seriesIndex].data[dataPointIndex][0],
      "month"
    );

    const firstNumberTitle =
      w?.config.series[seriesIndex].data[dataPointIndex][1];
    const number =
      w?.config.series[seriesIndex].data[dataPointIndex][3]?.length;
    return `
      <div style="width:200px;margin:5px; border-radius:5px;">
      <div style="font-weight:bold;font-size:14px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
      ${moment(
        w?.config.series[seriesIndex].data[dataPointIndex][3][0].discovery_time
      ).format("DD-MMM-YYYY")}
      </div>
      <div class="tooltip-item">${"Number of Articles"}: ${number}</div>
    </div>
      `;
  };

  let mind = seriesData
    ?.map((e) => e.data)
    ?.flat(1)
    ?.map((e) => e[0])
    .sort();

  const twitterOptions = {
    series: seriesData,
    options: {
      chart: {
        height: 350,
        type: "bubble",
        toolbar: {
          show: false,
          tools: {
            zoom: false,
          },
        },
      },
      markers: {
        strokeWidth: 0,
      },

      colors: ["#E88080", "#76C48B", "#B6BCC7"],
      plotOptions: {
        bubble: {
          // zScaling: true,
          // minBubbleRadius: 15,
          // maxBubbleRadius: 1000,
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return "234";
            },
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        min: mind[0] - mind[0] * (0.1 / 100) || undefined,
        max:
          mind[mind.length - 1] + mind[mind.length - 1] * (0.1 / 100) ||
          undefined,
        tickAmount: 1,
        type: "datetime",
        labels: {
          rotate: 0,
          show: false,
        },
      },
      yaxis: {
        max: 3,
        tickAmount: 3,
        show: false,
      },
      theme: {
        palette: "palette2",
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
        custom: incidentFormatTooltip,
      },
    },

    // options: {
    //   chart: {
    //     height: 350,
    //     type: "bubble",
    //     toolbar: {
    //       show: false,
    //     }
    //   },
    //   markers: {
    //     strokeWidth: 0,
    //   },

    //   colors: ["#E88080", "#76C48B", "#B6BCC7"],
    //   plotOptions: {
    //     bubble: {
    //       dataLabels: {
    //         enabled: true,
    //         formatter: function (val, opts) {
    //           return "234";
    //         },
    //       },
    //     },
    //   },

    //   dataLabels: {
    //     enabled: true,
    //   },
    //   legend: {
    //     show: false,
    //   },
    //   xaxis: {
    //     tickAmount: 1,
    //     type: "datetime",
    //     labels: {
    //       rotate: 0,
    //     },
    //     min: -30,
    //     max: 900
    //   },
    //   yaxis: {
    //     max: 3,
    //     tickAmount: 3,
    //     show: false
    //   },
    //   theme: {
    //     palette: "palette2",
    //   },
    //   tooltip: {
    //     x: {
    //       format: "dd MMM yyyy",
    //     },
    //     // custom: incidentFormatTooltip,
    //   },
    // },
    defaultColor: "red",
  };

  return (
    <section className="left-text">
      <div className="mt-24">
        <ChartCard
          showTimeBarOnTop={false}
          title="Total Reaction in Social Media"
          network={networkData}
          legend={PNN}
        >
          <div className="Sentiment-grah">
            <div className="incidentTimeline-tabs-top-div">
              <Chart
                title={"twitter"}
                titleImage={Images.greenTile}
                titleIcon={Images.greenGloabe}
                chartData={twitterOptions}
              />
            </div>
          </div>
        </ChartCard>
      </div>
    </section>
  );
};

const Chart = ({ titleImage, titleIcon, chartData }) => {
  return (
    <div className={Styles.chartContainer} style={{ height: "100%" }}>
      <div className={Styles.chartBox} style={{ alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginBottom: "17px",
          }}
        >
          <div className="line-chart-twitter" style={{ display: "flex" }}>
            <img
              style={{ height: "24px", width: "7px", marginRight: "5px" }}
              src={Images.sideLineChart}
            />
            <p>X corp.</p>
          </div>
          <div className="line-chart-twitter" style={{ display: "flex" }}>
            <img
              style={{ height: "24px", width: "7px", marginRight: "5px" }}
              src={Images.sideLineChart}
            />
            <p>Reddit</p>
          </div>
        </div>
        <div
          style={{
            gap: "20px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "19px",
          }}
        >
          <div>
            <img
              style={{ height: "24px", width: "24px", marginLeft: "15px" }}
              src={Images.xcroptwitter}
            />
          </div>
          <div>
            <img
              style={{ height: "24px", width: "24px", marginLeft: "15px" }}
              src={Images.radditChart}
            />
          </div>
        </div>
      </div>
      <div>
        <ChartBottomTitle>
          <BubbleChart
            disableClick={true}
            options={chartData}
            height={200}
            width={"198%"}
          />
        </ChartBottomTitle>
      </div>
    </div>
  );
};

export default OverviewTimeline;
