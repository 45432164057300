import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChartCard } from "../../../Layouts/ChartCard";
import { dispatch } from "../../../Utils/dispatchNavigate";
import {
  IncidentComparisonAction,
  RemoverIncidentComparisonAction,
} from "../../../Redux/Action/comapanyPageAction";
import ImageConst from "../../../Themes/ImageConstant";
import { AutoComplete, Modal, Row } from "antd";
import { getCountryDataforSupplyChain } from "../../../Redux/Action/supplyChainAction";
import { ChartBottomTitle } from "../../../Componets/ChartBottomTitle";
import style from "../style.module.css";
import { useSelector } from "react-redux";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

var graphic = echarts.graphic;

var newOption = (data) => ({
  toolbox: {
    show: false,
    feature: {
      mark: { show: true },
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true },
    },
  },

  series: [
    {
      name: "Access From",
      type: "pie",
      radius: [65, 150],
      center: ["50%", "50%"],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 0,
        borderColor: "#fff",
        borderWidth: 2,
      },
      labelLine: {
        show: true,
      },
      data: data,
    },
  ],
});

const Chart = ({ option, totalRisk }) => {
  return (
    <div key={"value"} className="main_container_portfolio">
      <div className="piechart-portfolio">
        <div className="piechart-portfolio_sub">
          <ReactEcharts
            className={"pieChart_portfolio"}
            option={newOption(option)}
          />
        </div>
        <div className={style.portfolioRiskChartCenter}>
          <div className={style.H3}>ESG</div>
          <div className={style.subtitle2Copy}>Risk Score</div>
          <div className={style.H1} style={{ color: "#FFC000" }}>
            {totalRisk || "0"}
          </div>
        </div>
      </div>
    </div>
  );
};

const PieChartComponent = ({ id, start, end, companyName, dateChange, risk }) => {
  const companiesList = useSelector(
    (state) => state?.incidentReducer?.companiesList
  );
  const { esgRiskData } = useSelector((state) => state?.portfolio);
  // let risk = 0
  // const [risk, setRisk] = useState(0);
  // useEffect(() => {
  //   if (Array.isArray(esgRiskData)) {
  //     const temp = esgRiskData
  //       ?.map((item) => (item?.percentage * item?.risk) / 100)
  //       .reduce((partialSum, a) => partialSum + a, 0);
  //     setRisk(temp.toFixed(0));
  //   }
  // }, [esgRiskData]);
  //
  const [options, setOptions] = useState([]);
  const [countryName, setCountryName] = useState(null);
  const [comparisonModal, setComparisonModal] = useState(false);

  useEffect(() => {
    // dispatch(getCountryDataforSupplyChain());
    dispatch(IncidentComparisonAction({ id, start, end, type: "multiple" }));
  }, [dateChange]);

  const { countryList } = useSelector((state) => state?.supplyChainReducer);
  const { IncidentComparison } = useSelector(
    (state) => state?.companyPageReducer
  );

  useEffect(() => {
    setOptions(countryList);
  }, [countryList]);

  const handleSearch = (value) => {
    let res = [];
    if (!value) {
      res = [];
    } else {
      res = countryList.filter((x) =>
        x?.label?.toUpperCase()?.includes(value?.toUpperCase().trim())
      );
    }
    setOptions(res);
  };

  const renderItem = (title, item) => ({
    value: item,
    label: (
      <div
        style={{
          pointerEvents: id === item ? "none" : "",
          cursor: id === item ? "no-drop" : "pointer",
        }}
      >
        {title}
      </div>
    ),
  });
  // country option on first render
  const CreateOptions = useMemo(() => {
    let arr = [];
    for (let i = 0; i < options?.length - 1; i++) {
      if (options[i].id === id) continue;
      arr.push(renderItem(options[i].label, options[i].id));
    }
    return arr;
  }, [options, id]);


  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const ESGRiskData = () => {
    let colorsArr = [
      "rgba(92, 187, 224, 0.80)",
      "rgba(163, 139, 229, 0.80)",
      "rgba(183, 222, 144, 0.80)",
     
      "rgba(255, 170, 113, 0.8)",
      "rgba(255, 216, 96, 0.8)",
      "rgba(133, 195, 253, 0.8)"

    ];

    if (esgRiskData.length > 0) {
      const data = esgRiskData.map((item, index) => {
        return {
          name: item.company,
          value: item.percentage,
          itemStyle: { color: item.company == "Unrecognized Holding" ? "#D8DAE0" : colorsArr[index % 6] },
        };
      });
      return data;
    }
  };

  // const totalRisk = () => {
  //   let total = 0
  //   if (esgRiskData?.length > 0) {
  //     total = esgRiskData.reduce(
  //       (acc, value) => acc + +value.risk,
  //       0
  //     );

  //     return Math.round(total / esgRiskData.length)
  //   } else {
  //     return total
  //   }

  // }

  return (
    <section className="left-text">
      <ChartCard showTimeBarOnTop={false} title={"Portfolio ESG Risk Score"}>
        <div className="pie-portfolio-chart-container">
          {esgRiskData.length > 0 && (
            <Chart totalRisk={risk} option={ESGRiskData()} />
          )}
        </div>
      </ChartCard>
      <Modal
        open={comparisonModal}
        closable
        title={"Advanced Search"}
        width={1000}
        onCancel={() => setComparisonModal(false)}
        footer={() => { }}
        className="comparisonModal"
      >
        <div style={{ overflow: "hidden" }}>
          <AutoComplete
            placeholder={"Search company"}
            style={{
              width: "100%",
              margin: "18px 0",
              border: "none",
              boxShadow: "none",
            }}
            suffixIcon={<img src={ImageConst.search} alt="" />}
            className="autocomplete-input"
            onSearch={handleSearch}
            value={countryName}
            options={CreateOptions}
            onSelect={(e, i) => {
              i?.value !== id &&
                dispatch(
                  IncidentComparisonAction({
                    id: i?.value,
                    start,
                    end,
                    type: "single",
                  })
                );
              setCountryName(i.label);
              setComparisonModal(false);
            }}
            onChange={(e, i) => {
              setCountryName(e);
            }}
          />
        </div>
        {/* <form className="example supply-chan-search"> */}

        {/* </form> */}
      </Modal>
    </section>
  );
};

export default PieChartComponent;
