/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  FilterParametersEnum,
  TabEnum,
  TierEnum,
} from "../../enums/FilterSideBarEnums";
import { RESET_APP_STATE } from "../reducers";

const initialState = {
  showFilterSideBar: false,
  excludeMainCompanyFromFiltering: true,
  excludeExpandedNodesFromFiltering: true,
  keepOutOfTierNodes: true,
  filterParameters: [
    {
      label: "Risk Score",
      id: FilterParametersEnum.RISK_SCORE,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.NODE,
      value: false,
    },
    {
      label: "Industry",
      id: FilterParametersEnum.INDUSTRY,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.NODE,
      value: false,
    },
    {
      label: "Total Incidents Count",
      id: FilterParametersEnum.TOTAL_INCIDENT_COUNT,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.NODE,
      value: false,
    },
    {
      label: "Incident Category",
      id: FilterParametersEnum.INCIDENT_CATEGORY,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.NODE,
      value: false,
    },
    {
      label: "Company Location",
      id: FilterParametersEnum.COMPANY_LOCATION,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.NODE,
      value: false,
    },
    {
      label: "Tier Type",
      id: FilterParametersEnum.TIER_TYPE,
      value: TierEnum.TIER1_TIER2_TIER3,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.NODE,
    },
    {
      id: TierEnum.TIER1,
      label: TierEnum.TIER1,
      parentId: FilterParametersEnum.TIER_TYPE,
      tab: TabEnum.NODE,
      count: 0,
      level: 1,
      value: false,
    },
    {
      id: TierEnum.TIER1_TIER2,
      label: TierEnum.TIER1_TIER2,
      parentId: FilterParametersEnum.TIER_TYPE,
      tab: TabEnum.NODE,
      count: 0,
      level: 1,
      value: false,
    },
    {
      id: TierEnum.TIER1_TIER2_TIER3,
      label: TierEnum.TIER1_TIER2_TIER3,
      parentId: FilterParametersEnum.TIER_TYPE,
      tab: TabEnum.NODE,
      count: 0,
      level: 1,
      value: false,
    },
    {
      label: "Company List",
      id: FilterParametersEnum.COMPANY_LIST,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.NODE,
      value: false,
    },
    {
      label: "Customers",
      id: FilterParametersEnum.CUSTOMER,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.EDGE,
      value: false,
    },
    {
      label: "Suppliers",
      id: FilterParametersEnum.SUPPLIER,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.EDGE,
      value: false,
    },
    {
      label: "Partners",
      id: FilterParametersEnum.PARTNER,
      parentId: undefined,
      count: 0,
      level: 0,
      tab: TabEnum.EDGE,
      value: false,
    },
  ],
  currentTab: TabEnum.NODE,
};
const filterSideBarSlice = createSlice({
  name: "filterSideBar",
  initialState,
  reducers: {
    setShowFilterSideBar: (state, { payload: showFilterSideBar }) => {
      state.showFilterSideBar = showFilterSideBar;
    },
    clearFilters: (state) => {
      state.hideNodesWithNoIncidents = {
        ...state.hideNodesWithNoIncidents,
        checked: false,
      };
      state.hideNodeWithNoConnectionToMainCompany = {
        ...state.hideNodeWithNoConnectionToMainCompany,
        checked: false,
      };
    },
    setExcludeMainCompanyFromFiltering: (
      state,
      { payload: excludeMainCompanyFromFiltering }
    ) => {
      state.excludeMainCompanyFromFiltering = excludeMainCompanyFromFiltering;
    },
    setCurrentTab: (state, { payload: currentTab }) => {
      state.currentTab = currentTab;
    },
    setFilterParameters: (state, { payload: filterParameters }) => {
      state.filterParameters = filterParameters;
    },
    updateFilterParameterField: (
      state,
      { payload: { itemId, fieldToUpdate, newValue } }
    ) => {
      const itemIndex = state.filterParameters.findIndex(
        (item) => item.id === itemId
      );
      if (itemIndex !== -1) {
        state.filterParameters[itemIndex][fieldToUpdate] = newValue;
      }
    },
    setKeepOutOfTierNodes: (state, { payload: keepOutOfTierNodes }) => {
      state.keepOutOfTierNodes = keepOutOfTierNodes;
    },
    setExcludeExpandedNodesFromFiltering: (
      state,
      { payload: excludeExpandedNodesFromFiltering }
    ) => {
      state.excludeExpandedNodesFromFiltering =
        excludeExpandedNodesFromFiltering;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});
export const {
  setShowFilterSideBar,
  clearFilters,
  setCurrentTab,
  setFilterParameters,
  updateFilterParameterField,
  setExcludeMainCompanyFromFiltering,
  setExcludeExpandedNodesFromFiltering,
  setKeepOutOfTierNodes,
} = filterSideBarSlice.actions;
export const filterSideBarReducer = filterSideBarSlice.reducer;
