import React, { useCallback } from "react";
import { ChartCard } from "../../Layouts/ChartCard";
import { ESG } from "../../Utils";


const ESGIncident = ({ children, title }) => {
  return (
    <ChartCard title={title} legend={ESG} showTimeBarOnTop={false}>
      {children}
    </ChartCard>
  );
};

export default ESGIncident;
