import { Box, IconButton, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Cancel";

function Title({ handleCancelFilter }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderBottom: `1px solid ${theme.palette.gray["50"]}`,
      }}
    >
      <Box>
        <Typography variant="subtitle3">Filters</Typography>
      </Box>
      <Box>
        <IconButton sx={{ padding: 0 }} onClick={handleCancelFilter}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
export default Title;
