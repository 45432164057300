import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { Breadcrumb, Select } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { dispatch, navigate } from "../../Utils/dispatchNavigate";
import { searchByParamsAction } from "../../Redux/Action/incidents";
import { debounce } from "../../Utils";
const { Option } = Select;
const updatePropsAndNavigation = (tilesData, tilesArr, item, type) => {
  let cloneTilesData = tilesData
  let cloneTilesArr = tilesArr
  if (type == "company") {
    cloneTilesData.companies = cloneTilesData.companies.filter(Item => Item !== item.id)
    cloneTilesArr.companies = cloneTilesArr.companies.filter(Item => Item.id !== item.id)
  } else if (type == "locations") {
    cloneTilesData.locations = cloneTilesData.locations.filter(Item => Item !== item.name)
    cloneTilesArr.locations = cloneTilesArr.locations.filter(Item => Item.id !== item.id)
  } else if (type == "esg_factors") {
    cloneTilesData.esg_factors = cloneTilesData.esg_factors.filter(Item => Item !== item.id)
    cloneTilesArr.esg_factors = cloneTilesArr.esg_factors.filter(Item => Item.id !== item.id)
  } else if (type == "industries") {
    cloneTilesData.industries = cloneTilesData.industries.filter(Item => Item !== item.id)
    cloneTilesArr.industries = cloneTilesArr.industries.filter(Item => Item.id !== item.id)
  } else if (type == "ungc_breaches") {
    cloneTilesData.ungc_breaches = cloneTilesData.ungc_breaches.filter(Item => Item.id !== item.id)
    cloneTilesArr.ungc_breaches = cloneTilesArr.ungc_breaches.filter(Item => Item.id !== item.id)
  }
  return {
    cloneTilesData,
    cloneTilesArr
  }


}
const NewDashboaredHeader = (props) => {
  const { searchByParams } = useSelector(
    (state) => state?.incidentReducer
  );

  const [value, setValue] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);

  const sharedProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    options: searchOptions,
    placeholder: 'Search company and location',
    maxTagCount: 'responsive',
  };
  const selectProps = {
    value: value.map(item => item.name),
    onChange: setValue,
  };

  useEffect(() => {
    const company = props?.tilesArr?.companies?.map(item => ({ name: item.name, id: item.id, group: "company" })) || []
    const locations = props?.tilesArr?.locations?.map(item => ({ name: item.name, id: item.id, group: "locations" })) || []
    const esg_factors = props?.tilesArr?.esg_factors?.map(item => ({ name: item.name, id: item.id, group: "esg_factors" })) || []
    const industries = props?.tilesArr?.industries?.map(item => ({ name: item.name, id: item.id, group: "industries" })) || []
    const ungc_breaches = props?.tilesArr?.ungc_breaches?.map(item => ({ name: item.name, id: item.id, group: "ungc_breaches" })) || []
    const preVData = [...company, ...locations, ...esg_factors, ...industries, ...ungc_breaches]
    setValue(pre => preVData)
  }, [props?.tilesArr])

  useEffect(() => {
    const company = searchByParams?.companies?.map(item => ({ label: item.name, from: "company", value: item.name, data: item })) || []
    const locations = searchByParams?.locations?.map(item => ({ label: item.name, from: "locations", value: item.name, data: item })) || []
    const searchData = [...company, ...locations]
    setSearchOptions(searchData)
  }, [searchByParams])

  const handleSelectChange = (selectedValues) => {
    const removedItem = value.filter(item => !selectedValues.includes(item.name))[0];
    if (removedItem) {
      const group = removedItem.group
      const updateData = updatePropsAndNavigation(props.tilesData, props.tilesArr, removedItem, group)
      navigate("/searchdashboardpage", {
        state: {
          replace: true,
          mergedData: updateData.cloneTilesData,
          selectedTile: updateData.cloneTilesArr
        },
      })
      props.updateTilesData(updateData.cloneTilesData)
      props.updateTitleArr(updateData.cloneTilesArr)
    }
  };
  return (
    <div className="das-top-header-fixed">
      <div className="breadcrmbs new-das-bread">
        <Breadcrumb>
          <Breadcrumb.Item key="1" className="inc-bad-link">
            <Link to="/home">Incident Analyzer</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="3" className="detail-lie-link">
            Search Results
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="dasboared-adv-search">
        <div className="des-search-width">
          <div style={{ display: "flex", height: "37px", overflow: "hidden", alignItems: "center" }}>

            <Select
              style={{ width: "100%", height: "100% !important" }}
              onSelect={(e, data) => {
                const cloneTilesData = props.tilesData
                let cloneTilesArr = props.tilesArr
                if (data.from == "locations") {
                  let cloneLocationTitle = cloneTilesData?.locations?.filter(item => item !== data.data.name) || []
                  cloneTilesData.locations = [...cloneLocationTitle, data.data.name]
                  let cloneLocationTitileArr = cloneTilesArr.locations?.filter(item => item.name !== data.data.name) || []
                  cloneTilesArr.locations = [...cloneLocationTitileArr, data.data]
                } else if (data.from == "company") {
                  let cloneCompanyTitle = cloneTilesData?.companies?.filter(item => item !== data.data.id) || []
                  cloneTilesData.companies = [...cloneCompanyTitle, data.data.id]
                  let cloneCompanyTitileArr = cloneTilesArr.companies?.filter(item => item.id !== data.data.id) || []
                  cloneTilesArr.companies = [...cloneCompanyTitileArr, data.data]
                }
                navigate("/searchdashboardpage", {
                  state: {
                    replace: true,
                    mergedData: cloneTilesData,
                    selectedTile: cloneTilesArr
                  },
                })
                props.updateTilesData(cloneTilesData)
                props.updateTitleArr(cloneTilesArr)
              }}
              onSearch={(e) => debounce(() => dispatch(searchByParamsAction(e)))}
              {...sharedProps}
              {...selectProps}
              onChange={handleSelectChange}
              className="searchDashboardPageSearch"
            />
            <div
              className="topheader-h1-hv search-bag-color "
              style={{ height: "100% !important", }}
            >
              <img src={Images.searchWhite} />
            </div>
          </div>

        </div>
        <div className="adv-search-btn">
          <button type="text" onClick={() => {
            props.setAdvanceModalOpen(true)
          }}>
            Advanced Search
          </button>
        </div>
      </div>
    </div>
  );
};
export default NewDashboaredHeader;
