import { Typography } from '@mui/material';
import { CustomAccordionDetails } from '../../../../components/CustomeAccordion';

export default function DefaultAccordion() {
  return (
    <CustomAccordionDetails>
      <Typography variant="body2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        malesuada lacus ex, sit amet blandit leo lobortis eget.
      </Typography>
    </CustomAccordionDetails>
  );
}
