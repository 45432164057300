import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

const useDebouncedApiCall = (apiCallAction, apiPayload, delay = 500) => {
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm) {
        dispatch(apiCallAction({ ...apiPayload, search: searchTerm }));
      }
    }, delay);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const paginationHandler = (payload) => {
    dispatch(apiCallAction({ ...payload, search: searchTerm }));
  }

  return {
    searchTerm,
    handleSearch,
    paginationHandler
  };
};

export default useDebouncedApiCall;
