/* eslint-disable no-param-reassign */
import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CustomAccordionDetails } from "../../../../components/CustomeAccordion";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/configuareStore";
import {
  FilterParametersEnum,
  TierEnum,
} from "../../../../enums/FilterSideBarEnums";
import {
  setKeepOutOfTierNodes,
  updateFilterParameterField,
} from "../../../../redux/GraphExploration/FilterSideBarSlice";
import { useGraphObject } from "../../../context/MyContext";
import { updateHiddenNodes } from "../../../../utils";
import CheckBoxLabel from "./CheckBoxLabel";
import ToggleParameter from "../toggleParameter";

export default function TierTypeAccordion() {
  const filterParameters = useAppSelector(
    (state) => state.filterSideBar.filterParameters
  );
  const { graphObject } = useGraphObject();
  const dispatch = useAppDispatch();
  const nodes = useAppSelector((state) => state.graph.nodes);
  // const [keepOutOfTierNodes, setKeepOutOfTierNodes] = React.useState(true);
  const keepOutOfTierNodes = useAppSelector(
    (state) => state.filterSideBar.keepOutOfTierNodes
  );
  const handleChange = (keepOutOfTiers) => {
    const tierTypeFilterParameter = filterParameters.find(
      (item) => item.id === FilterParametersEnum.TIER_TYPE
    );
    let maxTier = -1;
    if (tierTypeFilterParameter.value === TierEnum.TIER1) {
      maxTier = 1;
    } else if (tierTypeFilterParameter.value === TierEnum.TIER1_TIER2) {
      maxTier = 2;
    } else if (tierTypeFilterParameter.value === TierEnum.TIER1_TIER2_TIER3) {
      maxTier = 3;
    }
    const nodesToHide = nodes.filter((n) => {
      if (n.data.tier === undefined) {
        if (keepOutOfTiers) {
          return false;
        } else {
          return true;
        }
      } else {
        return n.data.tier > maxTier;
      }
    });
    const hiddenNodeIds = nodesToHide.map((n) => n.id);
    dispatch(
      updateFilterParameterField({
        itemId: FilterParametersEnum.TIER_TYPE,
        fieldToUpdate: "hiddenNodeIds",
        newValue: hiddenNodeIds,
      })
    );
    updateHiddenNodes(graphObject);
  };

  const handleRadioGroupChange = (event) => {
    const radioGroupValue = event.target.value;
    dispatch(
      updateFilterParameterField({
        itemId: FilterParametersEnum.TIER_TYPE,
        fieldToUpdate: "value",
        newValue: radioGroupValue,
      })
    );
    handleChange(keepOutOfTierNodes);
  };
  const TierTypeFilterParameter = filterParameters.find(
    (filterParameter) => filterParameter.id === FilterParametersEnum.TIER_TYPE
  );
  return (
    <CustomAccordionDetails>
      <ToggleParameter
        label="Keep out of tier nodes"
        checked={keepOutOfTierNodes}
        onChange={() => {
          const newValue = !keepOutOfTierNodes;
          dispatch(setKeepOutOfTierNodes(newValue));
          handleChange(newValue);
        }}
      />
      <RadioGroup
        aria-labelledby="tier-type-radio-buttons-group-label"
        defaultValue={TierTypeFilterParameter?.value}
        name="radio-buttons-group"
        value={TierTypeFilterParameter?.value}
        onChange={handleRadioGroupChange}
        sx={{ paddingLeft: 2 }}
      >
        {filterParameters
          .filter(
            (filterParameter) =>
              filterParameter.parentId === FilterParametersEnum.TIER_TYPE &&
              filterParameter.count > 0
          )
          .map((filterParameter) => (
            <FormControlLabel
              key={filterParameter.id}
              control={<Radio size="small" />}
              value={filterParameter.id}
              label={
                <CheckBoxLabel
                  label={filterParameter.label}
                  count={
                    filterParameter.id === TierEnum.TIER1_TIER2_TIER3
                      ? nodes.length
                      : filterParameter.count
                  }
                />
              }
            />
          ))}
      </RadioGroup>
    </CustomAccordionDetails>
  );
}
