import ImageConst from "../../Themes/ImageConstant";
import Styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
const SearchDocEmptyDpc = ({ title, msg, key, showBtn }) => {

  const navigate = useNavigate()

  return (
    <div className={Styles.container1} style={{ paddingRight: "30px" }}>
      <img className={Styles.imageStyle} src={ImageConst.searchDoc} />
      {/* <span className={Styles.headerSection}>Your {title} list is empty!</span> */}
      <span className={Styles.headerSection}>{title}</span>
      {title == "watchlist"
        ?
        <><div className="watchlist-empty-text" >
          <p>You can add <span> companies, locations, ESG categories</span> or <span>industries</span> to your watchlist to monitor them consistently as they get updated</p>
        </div>

        </>

        :
        <>
          <span className={Styles.paragraphSection}>{msg}</span>
          {showBtn && <button type="submit" className={Styles.btn}

            onClick={() => navigate("/createPortfolio", {
              state: {
                newCreate: true
              }
            })}>
            <img
              style={{
                height: 20,
                width: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              src={ImageConst.plusLargeWhite}
            />
            New Portfolio
          </button>}
        </>
      }
    </div>
  );
};

export default SearchDocEmptyDpc;
