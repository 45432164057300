import React from 'react';
import { SvgIcon } from '@mui/material';

function VisibleIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
      >
        <path
          d="M11.4983 7.0013C11.4983 8.38201 10.379 9.5013 8.99833 9.5013C7.61762 9.5013 6.49833 8.38201 6.49833 7.0013C6.49833 5.62059 7.61762 4.5013 8.99833 4.5013C10.379 4.5013 11.4983 5.62059 11.4983 7.0013Z"
          stroke="#80838B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.9987 1.16797C5.26734 1.16797 2.10877 3.62037 1.04688 7.00127C2.10875 10.3822 5.26734 12.8346 8.99872 12.8346C12.7301 12.8346 15.8886 10.3822 16.9505 7.00133C15.8887 3.6204 12.7301 1.16797 8.9987 1.16797Z"
          stroke="#80838B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
export default VisibleIcon;
