import moment from "moment";
import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
  IOTLoader: false,
  HomeRiskLoader: false,
  incidentAnalyzer_UN_Breaches: {},
  incidentAnalyzer_SocialMediaCoverage: {},
  homeESGRiskData: [],
  homeESGRiskDataViewBtn: false,
  homeHIGHSeverityData: [],
  homeHIGHSeverityDataViewBtn: false,
  serialData: {},
  HomeIOTData: {
    year: [],
    Environmental: [],
    Governance: [],
    Social: [],
    groupType: "",
  },
  sepratedCategory: {
    Environmental: { data: [], total: 0 },
    Governance: { data: [], total: 0 },
    Social: { data: [], total: 0 },
  },
  socialMediaData: {
    socialMedia: {
      tweets: {
        POSITIVE: 0,
        NEGATIVE: 0,
        NEUTRAL: 0,
      },
      facebooks: {
        POSITIVE: 0,
        NEGATIVE: 0,
        NEUTRAL: 0,
      },
      reddits: {
        POSITIVE: 0,
        NEGATIVE: 0,
        NEUTRAL: 0,
      },
    },
    sentiment: {
      POSITIVE: {
        tweet: 0,
        reddit: 0,
        facebook: 0,
      },
      NEGATIVE: {
        tweet: 0,
        reddit: 0,
        facebook: 0,
      },
      NEUTRAL: {
        tweets: 0,
        reddit: 0,
        facebook: 0,
      },
    },
  },
  dashboardData: {
    Environmental: 0,
    Governance: 0,
    Social: 0,
    HIGH: 0,
    LOW: 0,
    MEDIUM: 0,
  },
  dashboardCompanies: null,
  dashboardPORTFOLIOData: {
    Environmental: 0,
    Governance: 0,
    Social: 0,
    HIGH: 0,
    LOW: 0,
    MEDIUM: 0,
  },
  dashboardPORTFOLIOCompanies: null,
  holdings: null,
  stateLoader: false,
  breachesAndViolationData: [],
  breachesAndViolationDataLoader: false,
  breachesAndViolationDataSuccess: false,
  watchlistUpdatesHoldings: []
};

function getUpdatedIncidentData(data) {
  let year = [];
  let Social = [];
  let Governance = [];
  let Environmental = [];
  let total = []
  let groupType = "";

  if (data) {
    year = Object.keys(data?.esg_categories?.Environmental);
    Social = data?.esg_categories?.Social
      ? Object.values(data?.esg_categories?.Social)
      : [];
    Governance = data?.esg_categories?.Governance
      ? Object.values(data?.esg_categories?.Governance)
      : [];
    Environmental = data?.esg_categories?.Environmental
      ? Object.values(data?.esg_categories?.Environmental)
      : [];
    total = data?.total ?
      Object.values(data?.total)
      : [];

    groupType = data?.esg_categories?.groupType
      ? data?.esg_categories?.groupType
      : "";
  }
  return {
    year,
    Social,
    Governance,
    Environmental,
    groupType,
    total
  };
}

export const incidentAnalyzerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_GET_INCIDENT_ANALYZER_BREACHES_SUCCESS:
      return {
        ...state,
        incidentAnalyzer_UN_Breaches: action?.payload?.data,
      };
    case types.API_GET_INCIDENT_ANALYZER_SOCIAL_MEDIA_COVERAGE_SUCCESS:
      return {
        ...state,
        incidentAnalyzer_SocialMediaCoverage: action?.payload?.data,
      };
    case types.HOME_ESG_RISK_LOAD:
      return {
        ...state,
        HomeRiskLoader: true,
        stateLoader: true
      };
    case types.HOME_ESG_RISK_SUCCESS:
      let temphomeESGRiskData = [];
      if (action.key && action.key.offset == 0) {
        temphomeESGRiskData = action.payload;
      } else {
        temphomeESGRiskData = [state.homeESGRiskData, action.payload].flat();
      }
      return {
        ...state,
        HomeRiskLoader: false,
        stateLoader: false,
        homeESGRiskData: temphomeESGRiskData,
        homeESGRiskDataViewBtn: action?.payload?.length == 10,
      };

    case types.HOME_HIGH_SEVERITY_LOAD:
      return {
        ...state,
        HomeRiskLoader: true,
      };

    case types.HOME_HIGH_SEVERITY_SUCCESS:
      let tempHomeHIGHSeverityData = [];
      if (action.key && action.key.offset == 0) {
        tempHomeHIGHSeverityData = action.payload;
      } else {
        tempHomeHIGHSeverityData = [
          state.homeHIGHSeverityData,
          action.payload,
        ].flat();
      }

      return {
        ...state,
        HomeRiskLoader: false,
        homeHIGHSeverityData: tempHomeHIGHSeverityData,
        homeHIGHSeverityDataViewBtn: action?.payload?.length == 10,
      };

    case types.API_HOME_ESG_IOT_LOAD:
      return {
        ...state,
        HomeRiskLoader: true,
        IOTLoader: true,
      };
    case types.UPDATE_LOADER:
      
      return {
        ...state,
        IOTLoader: action.data
      }
    case types.API_HOME_ESG_IOT_SUCCESS:
      let updatedData = {
        year: [],
        Environmental: [],
        Governance: [],
        Social: [],
        total: [],
        groupType: "",
      };
      if (action.payload.data?.esg_categories) {
        updatedData = getUpdatedIncidentData(action.payload.data);
      }


      return {
        ...state,
        isLoading: false,
        IOTLoader: false,
        HomeIOTData: updatedData,
        serialData: action.payload.data,
      };

    case types.HOME_ESG_RISK_FAIL:
      return {
        ...state,
        HomeRiskLoader: false,
        IOTLoader: false,
      };
    case types.INCIDENT_BY_CATEGORY_LOAD:
      return {
        ...state,
        HomeRiskLoader: false,
        homeESGRiskData: [],
        homeESGRiskDataViewBtn: false

      };
    case types.INCIDENT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        HomeRiskLoader: false,
        sepratedCategory: action.payload.data,
      };

    case types.HOME_SOCIAL_MEDIA_REACTIONS_LOAD:
      return {
        ...state,
        HomeRiskLoader: true,
      };

    case types.HOME_SOCIAL_MEDIA_REACTIONS_SUCCESS:
      return {
        ...state,
        socialMediaData: action.payload.data,
        HomeRiskLoader: false,
      };

    case types.API_GET_DASHBOARD_LOAD:
      return {
        ...state,
        HomeRiskLoader: true,
      };

    case types.API_GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        HomeRiskLoader: false,
        dashboardData: action.payload.data.portfolioOverview,
        dashboardCompanies: action.payload.data.portfolioOverviewCompanyIds,
        holdings: action.payload.data.holdings
      };

    case types.API_GET_DASHBOARD_WATCH_LOAD:
      return {
        ...state,
        HomeRiskLoader: true,
      };

    case types.API_GET_DASHBOARD_WATCH_SUCCESS:
      return {
        ...state,
        HomeRiskLoader: false,
        dashboardPORTFOLIOData: action.payload.data.portfolioOverview,
        dashboardPORTFOLIOCompanies: action.payload.data.portfolioOverviewCompanyIds,
        watchlistUpdatesHoldings: action?.payload?.data?.holdings || []
      };

    case types.GET_BREACHES_VIOLATION_DATA_LOAD:
      return {
        ...state,
        breachesAndViolationDataLoader: true,
      }

    case types.GET_BREACHES_VIOLATION_DATA_SUCCESS:
      return {
        ...state,
        breachesAndViolationData: action.payload,
        breachesAndViolationDataLoader: false,
        breachesAndViolationDataSuccess: true,
      }

    case types.GET_BREACHES_VIOLATION_DATA_FAIL:
      return {
        ...state,
        breachesAndViolationDataLoader: false,
      }

    default:
      return { ...state };
  }
};
