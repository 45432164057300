import { createSlice } from "@reduxjs/toolkit";
import { LayoutEnum } from "../../enums/LayoutsEnum";
import { BadgeEnum } from "../../enums/BadgeEnum";
import { RESET_APP_STATE } from "../reducers";

const initialState = {
  nodes: [],
  edges: [],
  hiddenNodes: [],
  hiddenEdges: [],
  expandedNodes: [],
  centerNode: { id: null, name: null },
  loadingData: false,
  loadingLayout: false,
  layout: LayoutEnum.FORCE_LAYOUT,
  badge: BadgeEnum.INCIDENT_COUNT,
  nodeIdToZoom: undefined,
  edgeIdsToSelect: [],
};
const graphSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    setNodes: (state, { payload: nodes }) => {
      // eslint-disable-next-line no-param-reassign
      state.nodes = nodes;
    },
    setEdges: (state, { payload: edges }) => {
      // eslint-disable-next-line no-param-reassign
      state.edges = edges;
    },
    setHiddenNodes: (state, { payload: hiddenNodes }) => {
      // eslint-disable-next-line no-param-reassign
      state.hiddenNodes = hiddenNodes;
    },
    setHiddenEdges: (state, { payload: hiddenEdges }) => {
      // eslint-disable-next-line no-param-reassign
      state.hiddenEdges = hiddenEdges;
    },
    setCenterNode: (state, { payload: centerNode }) => {
      // eslint-disable-next-line no-param-reassign
      state.centerNode = centerNode;
    },
    setLoadingData: (state, { payload: loadingData }) => {
      // eslint-disable-next-line no-param-reassign
      state.loadingData = loadingData;
    },
    setLoadingLayout: (state, { payload: loadingLayout }) => {
      // eslint-disable-next-line no-param-reassign
      state.loadingLayout = loadingLayout;
    },
    setLayout: (state, { payload: layout }) => {
      // eslint-disable-next-line no-param-reassign
      state.layout = layout;
    },
    setBadge: (state, { payload: badge }) => {
      // eslint-disable-next-line no-param-reassign
      state.badge = badge;
    },
    setExpandedNodes: (state, { payload: expandedNodes }) => {
      // eslint-disable-next-line no-param-reassign
      state.expandedNodes = expandedNodes;
    },
    setNodeIdToZoom: (state, { payload: nodeIdToZoom }) => {
      // eslint-disable-next-line no-param-reassign
      state.nodeIdToZoom = nodeIdToZoom;
    },
    setEdgeIdsToSelect: (state, { payload: edgeIdsToSelect }) => {
      // eslint-disable-next-line no-param-reassign
      state.edgeIdsToSelect = edgeIdsToSelect;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});
export const {
  setNodes,
  setEdges,
  setCenterNode,
  setHiddenNodes,
  setHiddenEdges,
  setLoadingData,
  setLoadingLayout,
  setLayout,
  setBadge,
  setExpandedNodes,
  setNodeIdToZoom,
  setEdgeIdsToSelect,
} = graphSlice.actions;
export const graphReducer = graphSlice.reducer;
