import { Modal, Row, Tooltip } from "antd";
import { navigate } from "../../Utils/dispatchNavigate";
import ImageConst from "../../Themes/ImageConstant";
import { useDispatch } from "react-redux";
import { incidentFilter } from "../../Utils/globalFunction";
import { useState } from "react";
import IncidentsList from "../../Screens/incidentsList";
import { useId } from "react";
import IncidentsListModal from "../../Screens/IncidentsListModal";

export const CompanyIncidentTable = ({
  title,
  titleImg,
  data,
  subTitle,
  date,
  networkData,
}) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalId, setModalId] = useState("");
  const openModal = (id, route) => {
    setModalVisible(true);
    setModalId(id);
    let Data = {
      start: date?.start,
      end: date?.end,
    };
    Data[route] = [id];

    setTimeout(() => {
      incidentFilter(Data, dispatch);
    }, 1000);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const getIncidentPercentage = (MaintValue, subValue) => {
    if (MaintValue == subValue) {
      return 100;
    } else {
      return (subValue / MaintValue) * 100;
    }
  };
  const uniqueId = useId();

  return (
    <div className="companies-rate bot-padding" key={uniqueId}>
      <h5>
        <span className="me-2">
          <img src={titleImg} />
        </span>
        {title}
        <img
          className="hover-page"
          style={{ marginLeft: 5 }}
          src={ImageConst.circleInfo}
          alt=""
        />
      </h5>
      <div className="with-comp-sec" style={{ width: "85%" }}>
        <div className="comp">
          <p>{subTitle}</p>
        </div>
        <div className="incdi">
          <p>Incidents</p>
        </div>
      </div>
      {data?.map(({ incidentCount, name, id }, i) => {
        return (
          <div className="comp-name-sec">
            <div className="comp-full-name">
              <div
                onClick={() => {
                  if (titleImg != ImageConst.flag) {
                    navigate("/companyPage", { state: { id } });
                  } else {
                    openModal(name);
                  }
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "22px",
                      height: "22px",
                      marginRight: "1vw",
                      borderRadius: "100%",
                      backgroundColor: "#EFF1F6",
                      padding: ".4vw",
                      fontSize: ".7rem",
                    }}
                  >
                    {i + 1}
                  </div>
                  <p>
                    <Tooltip placement="top" title={<span>{name}</span>}>
                      <p
                        style={{
                          width: "130px",
                          // color: getColorForTableText(data.esg_category.name),
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {name}
                      </p>
                    </Tooltip>
                  </p>
                </div>
              </div>
            </div>
            <div style={{ width: "30%" }}>
              <div
                style={{
                  width: `${getIncidentPercentage(
                    data[0]?.incidentCount,
                    incidentCount
                  )}%`,
                }}
              >
                <div
                  className="comp-prc-num"
                  onClick={() => {
                    if (titleImg != ImageConst.flag) {
                      openModal(name, "companyArray");
                    } else {
                      openModal(name, "countries");
                    }
                  }}
                >
                  <p style={{ paddingRight: 10 }}>{incidentCount}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {modalVisible ? (
        <IncidentsListModal
          networkData={
            titleImg != ImageConst.flag
              ? { companies: [modalId] }
              : { locations: [modalId] }
          }
          setModalVisible={setModalVisible}
        />
      ) : null}
    </div>
  );
};
