import React, { useState, useEffect } from "react";
import { Outlet } from "react-router";
import SideBar from "../Header";
import Sidebar from "../SideBar";
import IncidentCollectionSideBar from "../incidentCollectionSideBar";
import WatchlistSideBar from "../watchlistSideBar";

const MainLayout = (props) => {
    return (
        <div style={{ width: "100%", background: "white", display: "flex" }}>
            <div  style={{width:70}}>
            <SideBar />
            </div>
            {props.IncidentCollectionSideBar ? <IncidentCollectionSideBar /> : null}
            {props.sidebar ? <Sidebar /> : null}
            {props.WatchlistSideBar ? <WatchlistSideBar /> : null}
            <Outlet />
        </div>

    )
}
export default MainLayout;