import React, { useCallback, useEffect, useState } from "react";
import { ChartCard } from "../../../../Layouts/ChartCard";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import { IncidentDistributionOverTimeAction } from "../../../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import IncidentsChart from "./IncidentsChart";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import {
  CHMM,
  ESG,
  getBubbleXaxis,
  getFilteredArrayforBubbles,
} from "../../../../Utils";
import _ from "lodash";
import {
  formatTooltip,
  structureData,
  tooltipsHandler,
} from "../../../../Utils/chartCommandFunction";
import { Box, FormControlLabel, Switch, styled } from "@mui/material";
import { Typography } from "antd";

const IncidentDistributionOverTime2 = (networkData) => {
  useEffect(() => {
    networkData && dispatch(IncidentDistributionOverTimeAction(networkData));
  }, [networkData.dateChange]);

  const { IncidentDistributionOverTime: data } = useSelector(
    (state) => state?.companyPageReducer
  );

  var options = {
    chart: {
      height: 350,
      type: "bubble",
      toolbar: {
        show: false,
        tools: {
          zoom: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        // rotate: 0,
        formatter: function (value) {
          return getBubbleXaxis(value, data["esg_categories"]?.groupType);
        },
      },
    },
    yaxis: {},
    theme: {
      palette: "palette2",
    },
    markers: {
      onClick: tooltipsHandler,
    },
    tooltip: {
      x: {
        format: "dd MM yyyy",
      },
      custom: formatTooltip,
    },
  };

  // For Incidents By Severity
  const dataMappingSeverity = useCallback(() => {
    const option = _.cloneDeep(options);

    let series = [
      { name: "Environment" },
      { name: "Governance" },
      { name: "Social" },
    ];
    // if (series?.length === data["esg_categories"]?.length)
    //   if (data["esg_categories"]) {
    //     let value = Object.keys(data["esg_categories"]);
    //     value?.forEach((i, index) => {
    //       let valueArr = Object.entries(data["esg_categories"][i].incident);
    //       valueArr.forEach((j, Index) => {
    //         series[index].data = structureData(
    //           valueArr,
    //           data["esg_categories"][i].groupType
    //         );
    //       });
    //     });
    //   }
    if (data["esg_categories"]) {
      let value = Object.keys(data["esg_categories"]);
      value?.forEach((i, index) => {
        if (i !== "groupType") {
          let valueArr = Object.entries(data["esg_categories"][i]);
          valueArr.forEach((j, Index) => {
            let dta = structureData(
              valueArr,
              data["esg_categories"]?.groupType
            );
            let comind = [...dta.map((e) => e[2])].sort((a, b) => a - b);
            let min = comind[0],
              max = comind[comind.length - 1];
            series[index].data = getFilteredArrayforBubbles(
              dta,
              min,
              max,
              [10, 50]
            );
          });
        }
      });
      let mind =
        series && series.length > 0
          ? series
              ?.map((e) => (e.data ? e.data : ""))
              ?.flat(1)
              ?.map((e) => e[0])
              .sort()
          : [];
      let maxY =
        series && series.length > 0
          ? series
              ?.map((e) => (e.data ? e.data : ""))
              ?.flat(1)
              ?.map((e) => e[1])
              .sort()
          : [];
      // option.xaxis.min = mind[0] - mind[0] * (0.1 / 100) || undefined;
      // option.xaxis.max =
      //   mind[mind.length - 1] + mind[mind.length - 1] * (0.1 / 100) ||
      //   undefined;
      // option.yaxis.max = maxY[maxY.length - 1] + 1;
      // option.yaxis.tickAmount = maxY[maxY.length - 1] + 1;

      option.xaxis.labels.show = maxY.length > 0 ? true : false;
      option.xaxis.min = mind[0] - mind[0] * (0.1 / 100) || undefined;
      option.xaxis.max =
        mind[mind.length - 1] + mind[mind.length - 1] * (0.1 / 100) ||
        undefined;

      option.xaxis.tickAmount = 12;

      option.yaxis.min = maxY[0] - 1;
      option.yaxis.max =
        maxY[maxY.length - 1] < 6
          ? maxY[maxY.length - 1] + 5
          : maxY[maxY.length - 1] + maxY[maxY.length - 1] / 6;
      option.yaxis.tickAmount = 6;
      option.yaxis.labels = {
        formatter: function (value) {
          if (value < 0) return "";
          return Math.round(value);
        },
      };
    }

    option.colors = ["#653DD4", "#6EBD20", "#2191FB"];
    return { options: option, series };
  }, [data]);

  // let mind = dataMappingSeverity().serie?.map(e => e.data)?.flat(1)?.map(e => e[0]).sort()

  Object.seal(options);
  // For Incidents By Category
  const dataMapping = useCallback(() => {
    const option = _.cloneDeep(options);
    let series = [
      { name: "Critical" },
      { name: "High" },
      { name: "Moderate" },
      { name: "Minor" },
    ];
    if (data?.sevarity) {
      let a = Object.keys(data?.sevarity);
      let value = a.pop();

      a?.forEach((i, index) => {
        let value1 = Object.entries(data["sevarity"][i]);
        let dta = structureData(value1, data?.sevarity?.groupType, "sevarity");
        let comind = [...dta.map((e) => e[2])].sort((a, b) => a - b);
        let min = comind[0],
          max = comind[comind.length - 1];
        series[index].data = getFilteredArrayforBubbles(
          dta,
          min,
          max,
          [10, 50]
        );
      });
      let mind =
        series.length > 0
          ? series
              ?.map((e) => e.data)
              ?.flat(1)
              ?.map((e) => e[0])
              .sort()
          : [];
      let maxY =
        series.length > 0
          ? series
              ?.map((e) => e.data)
              ?.flat(1)
              ?.map((e) => e[1])
              .sort(function (a, b) {
                return a - b;
              })
          : [];
      // option.xaxis.min = mind[0] - mind[0] * (0.1 / 100) || undefined;
      // option.xaxis.max =
      //   mind[mind.length - 1] + mind[mind.length - 1] * (0.1 / 100) ||
      //   undefined;
      option.xaxis.labels.show = maxY.length > 0 ? true : false;
      option.xaxis.min = mind[0] - mind[0] * (0.1 / 100) || undefined;
      option.xaxis.max =
        mind[mind.length - 1] + mind[mind.length - 1] * (0.1 / 100) ||
        undefined;

      option.xaxis.tickAmount = 12;

      option.yaxis.min = maxY[0] - 1;
      option.yaxis.max =
        maxY[maxY.length - 1] < 6
          ? maxY[maxY.length - 1] + 5
          : maxY[maxY.length - 1] + maxY[maxY.length - 1] / 6;
      option.yaxis.tickAmount = 6;
      option.yaxis.labels = {
        formatter: function (value) {
          if (value < 0) return "";
          return Math.round(value);
        },
      };
    }

    option.colors = ["#800020", "#FF0000", "#FF7518", "#FFBF00"];

    return { options: option, series };
  }, [data]);

  return (
    <>
      <section className="left-text">
        <ChartCard
          title={"Incident Distribution by Category & Severity"}
          className={"mt-32"}
          showTimeBarOnTop={false}
        >
          <div className="Sentiment-grah distribution-by-cat-severity-graph">
            <IncidentsChart
              options={dataMappingSeverity()}
              title={"Distribution of incidents by category over time"}
              legend={ESG}
            />
            <IncidentsChart
              options={dataMapping()}
              title={"Distribution of incidents by severity over time"}
              legend={CHMM}
            />
          </div>
        </ChartCard>
      </section>
    </>
  );
};

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px) translateY(3px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(28px) translateY(3px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 25,
    height: 25,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const IncidentDistributionOverTime = (networkData) => {
  useEffect(() => {
    networkData && dispatch(IncidentDistributionOverTimeAction(networkData));
  }, [networkData.dateChange]);

  const { IncidentDistributionOverTime: data } = useSelector(
    (state) => state?.companyPageReducer
  );
  const [dataZoomRange, setDataZoomRange] = useState({ start: 0, end: 10 });

  // Create a debounced version of your state update function
  const debouncedSetDataZoomRange = debounce((start, end) => {
    setDataZoomRange({ start, end });
  }, 500); // Adjust the delay (500ms in this example) as needed

  const handleDataZoom = (e) => {
    debouncedSetDataZoomRange(e?.start, e?.end);
  };

  // enum for colorType: 'esgCategory' and 'severity'
  const enumColorType = {
    esgCategory: "esg_categories",
    severity: "sevarity",
  };
  const [colorType, setColorType] = useState(enumColorType.severity);
  const categoryColors = {
    Environmental: "rgba(110, 189, 31, 0.85)",
    Social: "rgba(74, 146, 251, 0.85)",
    Governance: "rgba(101, 61, 212, 0.85)",
  };

  const severityColors = {
    critical: "rgba(128, 0, 32, 0.85)", // "#800020",
    high: "rgba(255, 0, 0, 0.85)", // "#FF0000",
    moderate: "rgba(255, 117, 24, 0.85)", // "#FF7518",
    minor: "rgba(255, 191, 0, 0.85)", // "#FFBF00",
  };

  const getLegendArray = () => {
    const colors =
      colorType === enumColorType.severity ? severityColors : categoryColors;
    return Object.entries(colors).map(([title, color]) => ({
      tittle: title,
      color,
    }));
  };

  const getFormattedData = useCallback(() => {
    const formattedData = [];
    if (data["esg_categories"]) {
      let value = Object.keys(data[colorType]);
      value?.forEach((category, index) => {
        if (category !== "groupType") {
          let valueArr = Object.entries(data[colorType][category]);
          for (const [dateString, deepArray] of valueArr) {
            for (let i in deepArray) {
              const color =
                (colorType === enumColorType.esgCategory
                  ? categoryColors
                  : severityColors)[category] || "rgba(0,0,0,0.85)"; // Default color if not found
              formattedData.push({
                value: [
                  Date.parse(deepArray[i]?.discovery_time),
                  deepArray[i].count,
                  Math.log(deepArray[i].count),
                  [deepArray[i].title, deepArray[i].id],
                  category,
                ],
                itemStyle: {
                  color: color, // Use the color based on the category
                },
              });
            }
          }
        }
      });
    }
    return formattedData;
  }, [data, colorType]);

  const option = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `
          <div style="width:400px;margin:5px; border-radius:5px;">
            <div style="font-weight:bold;font-size:11px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              ${params.value[3][0]}
            </div>
            <div style="margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              ${new Date(params.value[0]).toLocaleString()}
            </div>
            <div class="tooltip-item">Article Count: ${params.value[1]}</div>
            <div class="tooltip-item">Category: ${params.value[4]}</div>
        </div>
          `;
      },
    },
    grid: {
      top: "20%",
      left: "7%",
      right: "3%",
      bottom: "10%",
      containLabel: true, // This ensures that the grid contains the labels
    },
    xAxis: {
      type: "time",
      splitLine: {
        show: false,
      },
      name: "Date", // Label for the X axis
      nameLocation: "middle", // Position the label in the middle of the axis
      nameGap: 30,
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: false,
      },
      name: "Article Count", // Label for the Y axis
      nameLocation: "middle", // Position the label in the middle of the axis
      nameGap: 50, // Gap between the axis label and the axis line
      nameRotate: 90, // Rotate the label (optional, if you want vertical text)
    },
    dataZoom: [
      {
        type: "slider", // This is a slider at the bottom
        // start: 0,
        // end: 10,
        start: dataZoomRange.start,
        end: dataZoomRange.end,
        zoomOnMouseWheel: "ctrl", // Requires the Ctrl key to be pressed to zoom with the mouse wheel
      },
      {
        type: "inside", // This enables zooming and panning with mouse actions
        start: dataZoomRange.start,
        end: dataZoomRange.end,
        zoomOnMouseWheel: "ctrl", // Also requires the Ctrl key for the slider, for consistency
      },
    ],
    series: [
      {
        type: "scatter",
        symbolSize: function (data) {
          return Math.log(Math.sqrt(data[1]) * 8 + 1) * 4; // Adjust the multiplication factor to scale bubble sizes
        },
        data: getFormattedData(), // Your data array goes here
        animationDelay: function (idx) {
          return idx * 5;
        },
      },
    ],
  };

  const onEvents = {
    click: function (params) {
      window.open(`/incidentdetails?${params.value[3][1]}`, "_blank");
    },
    dataZoom: handleDataZoom,
  };

  return (
    <>
      <section className="left-text">
        <ChartCard
          title={"Incident Distribution Over Time"}
          className={"mt-32"}
          showTimeBarOnTop={false}
          legend={getLegendArray()}
        >
          <div style={{ height: "400px", width: "100%", position: "relative" }}>
            <div className="Sentiment-grah">
              {getFormattedData().length > 0 && (
                <>
                  <Box sx={{ position: "absolute", right: 0, zIndex: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={() =>
                              setColorType(
                                colorType === enumColorType.severity
                                  ? enumColorType.esgCategory
                                  : enumColorType.severity
                              )
                            }
                            checked={colorType !== enumColorType.severity}
                          />
                        }
                        label={
                          <Typography>
                            Show incidents by ESG Category
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                  <ReactECharts
                    echarts={echarts}
                    option={option}
                    onEvents={onEvents}
                    notMerge={true}
                    lazyUpdate={true}
                    style={{ height: "400px", width: "100%" }}
                  />
                </>
              )}
            </div>
          </div>
        </ChartCard>
      </section>
    </>
  );
};

export default IncidentDistributionOverTime;
