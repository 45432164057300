import {
  Chip,
  Typography,
  useTheme,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import ShortestPathIcon from "./shortestPathIcon";
import { handleShortestPath } from "../../utils";
import { useGraphObject } from "../context/MyContext";
import { useAppSelector } from "../../redux/configuareStore";

// function getColorFromScore(_score) {
//   // TODO: Need to change this to consider score range
//   return '#D00000';
// }
function IncidentItem({ name, index, severityCounts, id }) {
  const theme = useTheme();
  const { graphObject } = useGraphObject();
  const sideBarNodeId = useAppSelector(
    (state) => state.rightClickMenu.sideBarNodeId
  );
  return (
    <>
      <Stack direction="row" gap={1}>
        <div>
          <Chip
            label={index}
            style={{ ...theme.typography.captionS }}
            size="small"
          />
        </div>
        <div style={{ width: "100%" }}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                <Typography variant="bodySelected">{name}</Typography>
                <IconButton
                  sx={{
                    borderRadius: "4px",
                    border: `1px solid ${theme.palette.grey[300]}`,
                    margin: "4px",
                  }}
                  onClick={async () => {
                    const sourceNode = sideBarNodeId;
                    const targetNode = id;
                    await handleShortestPath(
                      sourceNode,
                      targetNode,
                      graphObject
                    );
                  }}
                >
                  <ShortestPathIcon />
                </IconButton>
              </div>
              <div>
                {/* Component located in the far right */}
                <div
                  variant="contained"
                  style={{ ...theme.typography.captionL }}
                >
                  {severityCounts.total} Incident(s)
                </div>
              </div>
            </Stack>
            <Stack
              direction="row"
              spacing={0}
              justifyContent="space-between"
              sx={{ ...theme.typography.captionS }}
            >
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center">
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#800020",
                        borderRadius: "2px",
                        display: "inline-block",
                      }}
                    />
                    <div style={{ display: "inline-block" }}>
                      &nbsp; Critical{" "}
                    </div>
                  </Stack>
                  <div>&nbsp;&nbsp;{severityCounts.Critical || 0}</div>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center">
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#FF0000",
                        borderRadius: "2px",
                        display: "inline-block",
                      }}
                    />
                    <div style={{ display: "inline-block" }}> &nbsp; High </div>
                  </Stack>
                  <div> &nbsp;&nbsp; {severityCounts.High || 0}</div>
                </Stack>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center">
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#FF7518",
                        borderRadius: "2px",
                        display: "inline-block",
                      }}
                    />
                    <div style={{ display: "inline-block" }}>
                      {" "}
                      &nbsp; Moderate{" "}
                    </div>
                  </Stack>
                  <div> &nbsp;&nbsp; {severityCounts.Moderate || 0}</div>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center">
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#FFBF00",
                        borderRadius: "2px",
                        display: "inline-block",
                      }}
                    />
                    <div style={{ display: "inline-block" }}>&nbsp; Minor </div>
                  </Stack>
                  <div>&nbsp;&nbsp; {severityCounts.Minor || 0}</div>
                </Stack>
              </Stack>
            </Stack>
            <Divider orientation="horizontal" flexItem />
          </Stack>
        </div>
      </Stack>
    </>
  );
}
export default IncidentItem;
