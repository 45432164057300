import React from "react";
import { IconButton, SvgIcon } from "@mui/material";

import ButtonContainer from "../ButtonContainer";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import { setShowBadgeSelector } from "../../../redux/GraphExploration/toolBoxSlice";
import BadgeSelectorPopOver from "./BadgeSelectorPopOver";
import CustomTooltip from "../../GraphToolTip/CustomTooltip";

function BadgeSelectorButton() {
  const dispatch = useAppDispatch();
  const showBadgeSelector = useAppSelector(
    (state) => state.toolBox.toolBox.showBadgeSelector
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <>
      <ButtonContainer isActive={showBadgeSelector}>
        <CustomTooltip title="Node Badge Type" placement="left">
          <IconButton
            onClick={(event) => {
              dispatch(setShowBadgeSelector(!showBadgeSelector));
              setAnchorEl(event.currentTarget);
            }}
          >
            <SvgIcon>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
                  stroke="#4E515C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 8L10 7V13M10 13H8M10 13H12"
                  stroke="#4E515C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
          </IconButton>
        </CustomTooltip>
      </ButtonContainer>
      <BadgeSelectorPopOver anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
}

export default BadgeSelectorButton;
