import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Images from "../../Themes/ImageConstant";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./style.module.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

import {
  Collapse,
  Modal,
  Progress,
  Row,
  Tabs,
  Tooltip as TooltipAntd,
} from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import TopHeader from "../../Layouts/TopHeader";
import ReactApexChart from "react-apexcharts";
import moment from "moment/moment";
import { FacebookIcon, LinkedinIcon } from "react-share";
import {
  BarMapping,
  BarMappingPAI,
  BarMappingSDG,
  HLEA,
  PNN,
  calculatePreviousDate,
  dateButtonObj,
  nFormatter,
  scrollTo,
} from "../../Utils";
import {
  companiesWithIncidenAction,
  countriesWithIncidenAction,
  esgFactorsAction,
  esgPaiAction,
  esgSdgAction,
  getCategoriesAction,
  getCountriesWithIncidenceAction,
  getDownloadDataAction,
  homePageAction,
  incidentMapAction,
  incindentAction,
  homeRiskScoreLoad,
  homeHighSeverityLoad,
  getSocialMediaReactionAction,
  getIncidentCountLoad,
} from "../../Redux/Action/incidents";
import { dispatch, navigate } from "../../Utils/dispatchNavigate";
import { useSelector } from "react-redux";
import { SocialBox } from "../../Componets/SocialBox";
import { CompanyIncidentTable } from "../../Componets/CompanyIncidentTable";
import { HoritzontalBar } from "../../Componets/HorizontalBar";
import { getCountryListAction } from "../../Redux/Action/comapanyPageAction";
import DatePicker from "../../Componets/DatePicker";
import { columns1 } from "../dashboardPage/companyColumns";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import BubleIncidentsList from "../bubleIncidentsList";
import IncidentsList from "../incidentsList";
import {
  getGermanSupplyChainColors,
  getPrincipleColors,
  getUCBreachesPrincipleColors,
  getUCBreachesSDGColors,
} from "../../Utils/globalFunction";
import EChartsReact from "echarts-for-react";
import { ChartCard } from "../../Layouts/ChartCard";
import ESGIncident from "../../Componets/esg_incident";
import {
  getBreachesViolationDataLoad,
  getIncidentAnalyzerBreaches,
  incidentAnalyzerSocialMediaCoverageAction,
  incidentByCategoryAction,
} from "../../Redux/Action/incidentAnalyzerAction";
import UngcpBreaches from "../dashboardPage/utils-components/ungcpBreaches";
import HomeIncidentTable from "../../Componets/homeIncidentTable";
import { sentiment_Obj, socialMediaObj } from "../incidentDetails/Chart/utils";
import SearchMain from "../../Componets/SearchMain";
import FullMap from "../../Componets/MapGL/full-map";
import IncidentsListModal from "../IncidentsListModal";
import PrincipleBarChat from "../../Componets/PrincipleBarChat";
import GlobalLoder from "../../Utils/GlobalLoder";
import PAIBarChat from "../../Componets/PrincipleBarChat/PAI";
import SDGBarChat from "../../Componets/PrincipleBarChat/SDG";
import { EchartsSunBurstChartComponent } from "../corporateProfile/chart/sunBurstChart";
import IncidentOverTime from "./chart/incidentOverTime";
import IncidentSearchMain from "../../Componets/IncidentSearchMain";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const lastDate = dateButtonObj[3].type;

let countryDataObj = {
  AF: "Africa",
  AN: "Antarctica",
  AS: "Asia",
  EU: "Europe",
  NA: "North America",
  OC: "Oceania",
  SA: "South America",
};

let networkData = { ...topHeaderAPIObject };
networkData["end"] = moment().format("YYYY-MM-DD");
networkData["start"] = moment(
  calculatePreviousDate(new Date(), lastDate)
).format("YYYY-MM-DD");

const getActiveItem = (number) => {
  if (number == 0 || (number > 0 && number < 150)) {
    return "Overview";
  } else if (number == 150 || (number > 90 && number < 1400)) {
    return "Company Risk Score";
  } else if (number == 1430 || (number > 1400 && number < 3946)) {
    return "Incident Analysis";
  } else if (number == 3947 || (number > 3946 && number < 4690)) {
    return "Social Media Coverage";
  } else if (number == 4691 || (number > 4690 && number < 25000)) {
    return "Breaches & violation";
  }
};
let piaChartData = {};
let selectedEsgCategories = [];
let previousPercentageData = {
  percentage: "",
  value: 0,
};

const getProcessBarPercentage = (index, value) => {
  const percentageObj = {
    0: "50%",
    1: "45%",
    2: "35%",
    3: "30%",
    4: "25%",
    5: "20%",
    6: "15%",
    7: "13%",
    8: "10%",
    9: "8%",
  };
  if (value == previousPercentageData.value) {
    return previousPercentageData.percentage;
  }
  previousPercentageData = {
    percentage: percentageObj[index],
    value,
  };
  return percentageObj[index];
};

const createCSeriesData = (data) => {
  const finalObj = {
    Governance: [],
    Environmental: [],
    Social: [],
    total: [],
  };
  let keys = Object.keys(data);
  try {
    for (let i = 0; i < keys?.length; i++) {
      let key = keys[i];
      if (keys[i] === "esg_categories") {
        let a = Object.keys(data[key]);
        let val = a.pop();
        a.map((i, j) => {
          finalObj[i] = Object.values(data[key][i]);
          // if (
          //   data.groupType == "hour"
          // ) {
          let a = Object.keys(data[key][i]);
          finalObj.label = a.map((i) => {
            let dataArr = i.split("&");
            return moment(dataArr[0]).format("DD MMM HH:mm");
          });
          finalObj.allLabel = a.map((i) => {
            let dataArr = i.split("&");
            let start_Date = moment(dataArr[0]).format("DD MMM HH:mm");
            let end_Date = moment(dataArr[1]).format("DD MMM HH:mm");
            return start_Date + " " + end_Date;
          });
          // }
          // else {
          //   let a = Object.keys(data[key][i]);
          //   finalObj.label = a.map((i) => {
          //     let dataArr = i.split("&")
          //     return moment(dataArr[0]).format("DD MMM YYYY")
          //   }
          //   );
          //   finalObj.allLabel = a.map((i) => {
          //     let dataArr = i.split("&")
          //     let start_Date = moment(dataArr[0]).format("DD MMM YYYY")
          //     let end_Date = moment(dataArr[1]).format("DD MMM YYYY")
          //     return start_Date + " " + end_Date
          //   }
          //   );
          // }
        });
      } else {
        finalObj[key] = Object.values(data[key]);
      }
    }

    return { ...finalObj };
  } catch (e) { }
};

const Home = () => {
  const location = useLocation();
  const { state } = location;
  const reducerData = useSelector((state) => state.incidentReducer);
  const {
    incidentAnalyzer_UN_Breaches,
    homeESGRiskData,
    homeESGRiskDataViewBtn,
    homeHIGHSeverityData,
    homeHIGHSeverityDataViewBtn,
    HomeIOTData,
    serialData,
    sepratedCategory,
    socialMediaData,
    stateLoader,
    breachesAndViolationData,
    breachesAndViolationDataLoader,
  } = useSelector((state) => state.incidentAnalyzerReducer);
  const sentimentObj = sentiment_Obj(socialMediaData?.sentiment || {});
  const socialObj = socialMediaObj(socialMediaData?.socialMedia || {});
  const [homeESGRiskDataMore, sethomeESGRiskDataMore] = useState(false);
  const [noOfIncidentShowMore, setNoOfIncidentShowMore] = useState(false);
  const [advanceModalOpen, setAdvanceModalOpen] = useState(false);
  const [prevPageData, setPrevPageData] = useState({ companies: [], esg_factors: [], locations: [], industries: [], ungc_breaches: [] });
  const [riskScorePerCompOffset, setRiskScorePerCompOffset] = useState(0);
  const [noOfIncidentOffset, setNoOfIncidentOffset] = useState(0);
  const [activeTab, setActiveTab] = useState("0");
  const [breachesViolationLimit, setBreachesViolationLimit] = useState(10);
  const [date, setDate] = useState(false);
  const [breachesViolationPaiLimit, setBreachesViolationPaiLimit] =
    useState(10);
  const [breachesViolationSdgLimit, setBreachesViolationSdgLimit] =
    useState(10);
  const [isHovered, setIsHovered] = useState(false);
  const [tabKey, setTabKey] = useState("1");
  const [tabVal, setTabVal] = useState("Environmental");

  const companyRiskScoreMoreData_Handler = (type) => {
    if (type == "riskScore") {
      sethomeESGRiskDataMore(true);
    } else {
      setNoOfIncidentShowMore(true);
    }
  };

  const {
    iotData: esgIotData,
    mapCountries: mapCountriesList,
    result: severeAndSocial,
    companies: selectedArray,
    incident: IPLData,
    companiesWithIncident,
    countriesWithIncident,
    esgFactors: esgFactorsData,
    countryList,
    esgSdg: esgSdgData,
    esgCategory: esgCategoryData,
    esgPai: esgPaiData,
    companyIdSearchList,
    locationIdSearchList,
    continents,
    severityDataLocationArray,
    totalEsgIncidentCount,
  } = reducerData;

  const seriesData = useCallback(
    () => createCSeriesData(serialData),
    [serialData]
  );
  
  const data = serialData && seriesData();
  let update = continents.map((val) =>
    countryDataObj[val?.continent_code]
      ? { ...val, continent_code: countryDataObj[val?.continent_code] }
      : { ...val }
  );

  const [scroll, setScroll] = useState(false);

  window.addEventListener(
    "scroll",
    () => {
      if (window.pageYOffset > 50) setScroll(true);
      else setScroll(false);
    },
    { passive: true }
  );

  const socialBox = [
    {
      img: Images.file,
      label: "News Articles",
      count: severeAndSocial?.totalArticles,
    },
    {
      img: Images.buil,
      label: "NGO Reports",
      count: severeAndSocial?.totalNgoReports,
    },
    {
      img: Images.socialMediaIcon,
      label: "Social Media",
      count: severeAndSocial?.totalTweets,
    },
  ];

  const legend = [
    {
      name: "X corp.",
      image: Images.XCROP,
    },
    {
      name: "Reddit",
      image: Images.Raddit,
    },
  ];


  const navigate = useNavigate();
  let TopLevelIncidentListModalPayload = { ...topHeaderAPIObject };
  TopLevelIncidentListModalPayload["end"] = moment().format("YYYY-MM-DD");
  TopLevelIncidentListModalPayload["start"] = moment(
    calculatePreviousDate(new Date(), lastDate)
  ).format("YYYY-MM-DD");
  const [
    topLevelIncidentListModalPayload,
    setTopLevelIncidentListModalPayload,
  ] = useState({ ...TopLevelIncidentListModalPayload });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCategoriesModal, setSelectedCategoriesModal] = useState(false);
  const [rootLevelIncidentModalItem, setRootLevelIncidentModalItem] =
    useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const mapData = useSelector((state) => state?.incidentReducer?.mapData);
  const onDatePickerChange = useCallback((startDate, previousDate) => {
    if (startDate && previousDate) {
      networkData.companies =
        companyIdSearchList?.length > 0 ? companyIdSearchList : selectedArray;
      networkData.locations =
        locationIdSearchList?.length > 0 ? locationIdSearchList : [];
      networkData.start = startDate;
      networkData.end = previousDate;
      setRiskScorePerCompOffset(0);
      setNoOfIncidentOffset(0);
      networkCall({ start: startDate, end: previousDate });
      setDate(!date);
    }
  });

  ChartJS.register(ArcElement, Tooltip, Legend);

  let newData = { offset: 0, limit: 10 };
  const mergedData = {
    ...networkData,
    ...newData,
  };

  useLayoutEffect(() => {
    dispatch(getIncidentCountLoad({ ...networkData }));
  }, []);

  useEffect(() => {
    if (Object.entries(totalEsgIncidentCount).length > 0) {
      if (totalEsgIncidentCount?.Environmental > 0) {
        setTabKey("1");
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social > 0
      ) {
        setTabKey("2");
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social == 0 &&
        totalEsgIncidentCount?.Governance > 0
      ) {
        setTabKey("3");
      } else {
        setTabKey("1");
      }
    }
  }, [totalEsgIncidentCount]);

  const networkCall = (data) => {
    const newData = { ...networkData, ...data };
    newData.offset = 0;
    dispatch(
      getBreachesViolationDataLoad({
        ...newData,
        limit:
          activeTab == 1
            ? breachesViolationLimit
            : activeTab == 3
              ? breachesViolationPaiLimit
              : activeTab == 4
                ? breachesViolationSdgLimit
                : breachesViolationLimit,
        type:
          activeTab == 1
            ? "esg_supply_chain_act"
            : activeTab == 0
              ? "german_supply_chain_act"
              : activeTab == 2
                ? "esg_SDG"
                : "esg_PAI",
      })
    );
    dispatch(getIncidentAnalyzerBreaches(newData));
    dispatch(incidentAnalyzerSocialMediaCoverageAction(newData));
    dispatch(
      incindentAction({
        ...newData,
        esg_categories: [tabVal],
        sort: "discovery_time",
        order: -1,
      })
    );
    dispatch(homePageAction(newData));
    dispatch(companiesWithIncidenAction(newData));
    dispatch(esgFactorsAction(newData));
    dispatch(esgSdgAction(newData));
    dispatch(esgPaiAction(newData));
    dispatch(incidentByCategoryAction(newData));
    // dispatch(esgCategoryAction(newData));

    dispatch(getCountriesWithIncidenceAction(newData));
    dispatch(getCountryListAction(newData));
    dispatch(getSocialMediaReactionAction(newData));
  };

  useEffect(() => {
    networkData.companies = selectedArray;
  }, [selectedArray]);

  useEffect(() => {
    mergedData.offset = noOfIncidentOffset;
    dispatch(homeHighSeverityLoad(mergedData));
  }, [noOfIncidentOffset]);
  
  useEffect(() => {
    mergedData.offset = riskScorePerCompOffset;
    dispatch(homeRiskScoreLoad(mergedData));
  }, [riskScorePerCompOffset]);

  useEffect(() => {
    dispatch(
      getBreachesViolationDataLoad({
        ...mergedData,
        limit:
          activeTab == 1
            ? breachesViolationLimit
            : activeTab == 3
              ? breachesViolationPaiLimit
              : activeTab == 4
                ? breachesViolationSdgLimit
                : breachesViolationLimit,
        type:
          activeTab == 1
            ? "esg_supply_chain_act"
            : activeTab == 0
              ? "german_supply_chain_act"
              : activeTab == 2
                ? "esg_SDG"
                : "esg_PAI",
      })
    );
    dispatch(incidentMapAction({ ...networkData, type: "home" }));
    dispatch(incidentByCategoryAction(mergedData));
    dispatch(getIncidentAnalyzerBreaches(mergedData));
    dispatch(homePageAction(mergedData));
    dispatch(countriesWithIncidenAction());
    dispatch(
      incindentAction({
        ...mergedData,
        esg_categories: [tabVal],
        sort: "discovery_time",
        order: -1,
      })
    );
    dispatch(getCategoriesAction());
    dispatch(companiesWithIncidenAction(mergedData));
    dispatch(esgFactorsAction(mergedData));
    dispatch(esgSdgAction(mergedData));
    dispatch(esgPaiAction(mergedData));
    // dispatch(esgCategoryAction(mergedData));
    mergedData.offset = riskScorePerCompOffset;
    dispatch(homeRiskScoreLoad(mergedData));
    mergedData.offset = noOfIncidentOffset;
    dispatch(homeHighSeverityLoad(mergedData));
    mergedData.offset = 0;
    dispatch(getSocialMediaReactionAction(mergedData));
    localStorage.interval = "Last 3 months";
  }, []);

  useEffect(() => {
    if (activeTab != 2) {
      dispatch(
        getBreachesViolationDataLoad({
          ...mergedData,
          limit:
            activeTab == 1
              ? breachesViolationLimit
              : activeTab == 3
                ? breachesViolationPaiLimit
                : activeTab == 4
                  ? breachesViolationSdgLimit
                  : breachesViolationLimit,
          type:
            activeTab == 1
              ? "esg_supply_chain_act"
              : activeTab == 0
                ? "german_supply_chain_act"
                : activeTab == 4
                  ? "esg_SDG"
                  : "esg_PAI",
        })
      );
    } else {
      dispatch(
        getIncidentAnalyzerBreaches({
          ...mergedData,
          limit: breachesViolationLimit,
        })
      );
    }
  }, [
    activeTab,
    breachesViolationLimit,
    breachesViolationPaiLimit,
    breachesViolationSdgLimit,
  ]);

  const callDownloadApi = () => {
    dispatch(
      getDownloadDataAction({
        id: null,
        start: networkData.start,
        end: networkData.end,
      })
    );
  };

  const [activeItem, setActiveItem] = useState("Overview");
  const [showSearch, setShowSearch] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setActiveItem(getActiveItem(scrollTop));
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function onChartClick(param) {
    const parent = {
      S: "Social",
      G: "Governance",
      E: "Environmental",
    };
    let _parent = param?.data.parent;
    let Parent = parent[_parent];
    let child = param?.data?._name;
    if (param.data?.data?.id) {
      networkData = {
        ...networkData,
        esg_categories: [],
        esg_factors: [param.data?.data?.id],
      };
    } else {
      networkData = {
        ...networkData,
        esg_categories: [param?.data?.esgName || Parent],
      };
    }
    setModalVisible(true);
  }

  const titleImg = (parent) => {
    return parent == "Social"
      ? Images.darkblue
      : parent == "Environment"
        ? Images.green
        : Images.pup;
  };

  const childFuncRef = useRef();

  if (esgCategoryData !== undefined) {
    piaChartData = {
      options: {
        chart: {
          type: "pie",
        },
        emphasis: {
          focus: "ancestor",
        },
        legend: {
          show: false,
        },

        labels: ["Environmental", "Social", "Governance"],
        chart: {
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (event, chartContext, { dataPointIndex }) => {
              const itemObj = {
                0: "Environmental",
                1: "Social",
                2: "Governance",
              };
              selectedEsgCategories = [itemObj[dataPointIndex]];
              setSelectedCategoriesModal(true);
            },
          },
        },
        colors: ["#6EBD20", "#2191FB", "#653DD4"],
        plotOptions: {
          pie: {
            dataLabels: {
              style: {
                textAnchor: "center", // Center-align the text
              },
              enabled: true, // Enable data labels
            },
          },
        },
      },
      series: [
        esgCategoryData[1]?.incidents,
        esgCategoryData[0]?.incidents,
        esgCategoryData[2]?.incidents,
      ],
    };
  }

  const SeprateCategoryComponent = ({ name, incidents, onClick }) => {
    return (
      <div className="flx-color-se">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <img style={{ width: ".8vw", height: ".8vw" }} src={titleImg(name)} />
          <h5
            style={{
              marginLeft: "6px",
              fontSize: "12px",
            }}
          >
            {name}
          </h5>
        </div>
        <div
          className="colors"
          style={{
            display: "flex",
            backgroundColor: "red",
            alignSelf: "flex-start",
          }}
        ></div>
        <div className="number">
          <p>{incidents}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        onClick={() => {
          if (childFuncRef?.current && childFuncRef?.current?.handleSearch) {
            childFuncRef?.current?.handleSearch();
          }
          setShowSearch(false);
        }}
        className="side-padding aaa"
      >
        <TopHeader
          showSearch={showSearch}
          ref={childFuncRef}
          setShowSearch={setShowSearch}
          countires={countryList}
          scrolled={scroll}
          start={networkData.start}
          endDate={networkData.end}
          selectedCountry={setSelectedCountry}
          advanceSearchState={[advanceModalOpen, setAdvanceModalOpen]}
        />
        <div
          className={
            scroll
              ? "main-layout top-bar-margin-handle"
              : "main-layout top-fixed"
          }
        >
          <div className="container mt-4">
            <div className="row" style={{ display: "flex" }}>
              <div
                className={`col-lg-3 ${!scroll ? "top-margin-comp" : "top-margin-comp-Scroll"
                  } containerMidWidth `}
              >
                <div className="hone-left-side1">
                  <div className="home-left-list mt-3">
                    <ul>
                      <li
                        className={activeItem == "Overview" ? "tab-active" : ""}
                      >
                        <a onClick={() => scrollTo(0)}> Overview </a>
                      </li>
                      <li
                        className={
                          activeItem == "Company Risk Score" ? "tab-active" : ""
                        }
                      >
                        <a onClick={() => scrollTo(150)}>
                          1 Company Risk Score
                        </a>
                      </li>
                      <li
                        className={
                          activeItem == "Incident Analysis" ? "tab-active" : ""
                        }
                      >
                        <a onClick={() => scrollTo(1430)}>
                          2 Incident Analysis
                        </a>
                      </li>
                      <li
                        className={
                          activeItem == "Social Media Coverage"
                            ? "tab-active"
                            : ""
                        }
                      >
                        <a onClick={() => scrollTo(3947)}>
                          3 Social Media Coverage
                        </a>
                      </li>
                      <li
                        className={
                          activeItem == "Breaches & violation"
                            ? "tab-active"
                            : ""
                        }
                      >
                        <a onClick={() => scrollTo(4691)}>
                          4 Breaches & violation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 containerWidth ml-32">
                <div className="top-right-home-drop">
                  <div style={{ cursor: "pointer" }}>
                    <img
                      onClick={() => {
                        callDownloadApi();
                      }}
                      src={Images.framedow}
                    />
                  </div>
                  <Modal
                    title={"Share"}
                    centered
                    className="share"
                    open={isModalOpen}
                    footer={false}
                    onCancel={() => setIsModalOpen(false)}
                  >
                    <FacebookShareButton
                      url={"www.google.com"}
                      quote={"asasas"}
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>

                    <LinkedinShareButton
                      url={"www.google.com"}
                      title={"asasas"}
                    >
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>

                    <TwitterShareButton
                      url={"www.google.com"}
                      title={"asasasa"}
                    >
                      <XIcon size={32} round={true} />
                    </TwitterShareButton>
                  </Modal>
                  <DatePicker
                    onDatePickerChange={onDatePickerChange}
                    lastDate={lastDate}
                  />
                </div>
                <section>
                  <div className="container g-0 mainHomeContainer">
                    <div className="row">
                      <div className="col mt-5">
                        <div className="inciden-box">
                          <div
                            className="total-box"
                            onClick={() => setModalVisible(true)}
                          >
                            <h5>All Incidents</h5>
                            <h1>
                              {nFormatter(severeAndSocial?.totalIncidents)}
                            </h1>
                          </div>
                          {socialBox.map(({ img, label, count }) => (
                            <SocialBox
                              img={img}
                              label={label}
                              count={nFormatter(count)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col mt-3">
                        <div
                          className="seve-main-boxes"
                          style={{ gap: "16px" }}
                        >
                          <div
                            className="fir-box"
                            onClick={() => {
                              setModalVisible(true);
                              setModalData({ ...networkData, showSever: true });
                            }}
                          >
                            <img src={Images.circle} alt="" />
                            <p>
                              Severe
                              <span>
                                <img
                                  className="hover-page"
                                  src={Images.circleInfo}
                                  alt=""
                                />
                                <div className="infohover-home">
                                  <p>
                                    We use AI to determine the severity of an
                                    incident based on its content. However,
                                    since it is often a subjective matter, this
                                    description should not be solely relied on.
                                    It is intended as a helpful clue to assist
                                    users.
                                  </p>
                                </div>
                              </span>
                            </p>

                            <h5 className="gap">
                              {nFormatter(severeAndSocial?.severeHighCount)}
                            </h5>
                          </div>

                          <div
                            className="fir-box"
                            onClick={() => {
                              setModalVisible(true);
                              setModalData({
                                ...networkData,
                                start: moment()
                                  .subtract(30, "day")
                                  .format("YYYY-MM-DD"),
                                end: moment().format("YYYY-MM-DD"),
                              });
                            }}
                          >
                            <img src={Images.clockGreen} />
                            <p>Recent</p>
                            <p>(last month)</p>
                            <h5>{nFormatter(severeAndSocial?.lastMonth)}</h5>
                          </div>
                          <div
                            className="fir-box"
                            onClick={() => {
                              setModalVisible(true);
                              setModalData({
                                ...networkData,
                                start: moment()
                                  .subtract(2, "day")
                                  .format("YYYY-MM-DD"),
                                end: moment().format("YYYY-MM-DD"),
                              });
                            }}
                          >
                            <img src={Images.felame} />
                            <p>New</p>
                            <p>(last 2 days)</p>
                            <h5> {nFormatter(severeAndSocial?.lastTwoDays)}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="collapse-section-ps containerWidth">
                  <Collapse
                    defaultActiveKey={["1", "2", "3", "4"]}
                    className="company-detail-collapse"
                    expandIcon={({ isActive }) => {
                      return (
                        <img
                          src={Images.iconDown}
                          style={{
                            transform: `rotate(${isActive ? "0" : "-90"}deg)`,
                          }}
                        />
                      );
                    }}
                  >
                    <Collapse.Panel
                      className="company-collapse-header"
                      header={
                        <h4>
                          <span className="company-page-collable-label-text">
                            1
                          </span>
                          Company Risk Score
                        </h4>
                      }
                      key="1"
                    >
                      <section className="left-text">
                        <ChartCard
                          showTimeBarOnTop={false}
                          title={"Risk Score Per Company"}
                        >
                          <CompanyRiskScore
                            data={homeESGRiskData}
                            viewMoreState={homeESGRiskDataMore}
                            viewMoreHandler={companyRiskScoreMoreData_Handler}
                            isViewBtnShow={homeESGRiskDataViewBtn}
                            stateLoader={stateLoader}
                            setRiskScorePerCompOffset={
                              setRiskScorePerCompOffset
                            }
                          />
                        </ChartCard>
                      </section>

                      <div className="mt-4">
                        <ChartCard
                          showTimeBarOnTop={false}
                          title={
                            "Total Number of Incidents / Critical Severity Incidents"
                          }
                        >
                          <CompanyHighSeverity
                            data={homeHIGHSeverityData}
                            viewMoreState={noOfIncidentShowMore}
                            viewMoreHandler={companyRiskScoreMoreData_Handler}
                            isViewBtnShow={homeHIGHSeverityDataViewBtn}
                            setNoOfIncidentOffset={setNoOfIncidentOffset}
                            stateLoader={stateLoader}
                          />
                        </ChartCard>
                      </div>
                    </Collapse.Panel>

                    <Collapse.Panel
                      className="company-collapse-header"
                      header={
                        <h4>
                          <span className="company-page-collable-label-text">
                            2
                          </span>
                          Incident Analysis
                        </h4>
                      }
                      key="2"
                    >
                      <HomeIncidentTable
                        tabShowCount={totalEsgIncidentCount}
                        columns={columns1}
                        id={selectedCountry}
                        icon={true}
                        title={"Incidents List Preview"}
                        data={IPLData?.data?.slice(0, 4)}
                        showModal={state}
                        date={{ ...networkData }}
                        tabKey={[tabKey, setTabKey]}
                        tabVal={[tabVal, setTabVal]}
                      />
                      <div className="inc-map-pre mt-4 containerWidth">
                        <div className="tab-top">
                          <div className="left-text">
                            <Row>
                              <h4>Incidents Preview</h4>
                              <img
                                className="hover-page"
                                style={{ marginLeft: 5 }}
                                src={Images.circleInfo}
                                alt=""
                              />
                            </Row>
                          </div>
                          <div
                            className="righ-side"
                            onClick={() => navigate("/fullMap")}
                          >
                            <a>
                              View Full Map
                              <span>
                                <img src={Images.iconser} />
                              </span>
                            </a>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div
                              className="col-10 mt-3 mb-3"
                              style={{
                                position: "relative",
                                height: "326px",
                              }}
                            >
                              <div className="map-pre">
                                <div
                                  style={{
                                    left: "1vw",
                                    opacity: 0.9,
                                    bottom: "6px",
                                    padding: ".6vw",
                                    position: "absolute",
                                    display: "flex",
                                    zIndex: "1",
                                    backgroundColor: "#ffffff",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      marginBottom: ".4vw",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    Severity
                                  </span>
                                  <div>
                                    <MapItem
                                      color={"#800020"}
                                      name={"Critical"}
                                    />
                                    <MapItem color={"#B70000"} name={"High"} />
                                    <MapItem
                                      color={"#F28C28"}
                                      name={"Moderate"}
                                    />
                                    <MapItem color={"#FFD700"} name={"Minor"} />
                                  </div>
                                </div>
                                <FullMap
                                  width={"600px"}
                                  height={"326px"}
                                  zoom={0}
                                  data={severityDataLocationArray || []}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <div
                                className="asia-cont"
                                style={{
                                  minWidth: "100px",
                                }}
                              >
                                {update?.map((item, index) => {
                                  return (
                                    <div className="africa-co">
                                      <h5>{item.continent_code}</h5>
                                      <p>{item.incidents}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container g-0 pie-char-main-sec mt-4">
                        <div className="category">
                          <Row>
                            <h5>Incidents separated by Category</h5>
                            <img
                              className="hover-page"
                              style={{ marginLeft: 5 }}
                              src={Images.circleInfo}
                              alt=""
                            />
                          </Row>
                        </div>
                        <div className="row pb-4">
                          <div className="col-md-6 pie-chart-sec">
                            <div className="cat-name">
                              <SeprateCategoryComponent
                                name={"Environment"}
                                incidents={
                                  sepratedCategory?.Environmental?.total
                                }
                                onClick={() => {
                                  networkData = {
                                    ...networkData,
                                    esg_factors: [],
                                    esg_categories: ["Environment"],
                                  };
                                  setModalVisible(true);
                                }}
                              />
                              <SeprateCategoryComponent
                                name={"Social"}
                                incidents={sepratedCategory?.Social?.total}
                                onClick={() => {
                                  networkData = {
                                    ...networkData,
                                    esg_factors: [],
                                    esg_categories: ["Social"],
                                  };
                                  setModalVisible(true);
                                }}
                              />
                              <SeprateCategoryComponent
                                name={"Governance"}
                                incidents={sepratedCategory?.Governance?.total}
                                onClick={() => {
                                  networkData = {
                                    ...networkData,
                                    esg_factors: [],
                                    esg_categories: ["Governance"],
                                  };
                                  setModalVisible(true);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="pie-chart-new"
                              style={{ textAlign: "center" }}
                            >
                              <EchartsSunBurstChartComponent
                                onChartClick={onChartClick}
                                sepratedCategory={sepratedCategory}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <IncidentOverTime {...networkData} dateChange={date} />

                      <div className="container g-0 containerWidth">
                        <div className="row mt-4">
                          <div className="col-lg-6 ">
                            <CompanyIncidentTable
                              key={1}
                              data={companiesWithIncident}
                              title={"Companies with Highest Incident Rate"}
                              titleImg={Images.hotel}
                              subTitle={"Company"}
                              networkData={networkData}
                            />
                          </div>

                          <div className="col-lg-6 ">
                            <CompanyIncidentTable
                              key={2}
                              data={countriesWithIncident}
                              title={"Locations with Highest Incident Rate"}
                              titleImg={Images.flag}
                              subTitle={"Country"}
                              networkData={networkData}
                              date={{
                                start: networkData.start,
                                end: networkData.end,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Collapse.Panel>

                    <Collapse.Panel
                      className="company-collapse-header"
                      header={
                        <h4>
                          <span className="company-page-collable-label-text">
                            3
                          </span>
                          Social Media Coverage
                        </h4>
                      }
                      key="3"
                    >
                      <ChartCard
                        showTimeBarOnTop={false}
                        title={"Social Media Coverage"}
                        legend={PNN}
                      >
                        <div className="line-chart">
                          <Row>
                            <div className="col-sm-6 socialChart">
                              <span className="captionXl">
                                Social Media Sentiment Distribution
                              </span>
                              <EChartsReact
                                className="doubleLayer-pieChart mt-4"
                                option={{
                                  silent: false,
                                  series: [
                                    {
                                      nodeClick: false,
                                      label: {
                                        color: "#fff",
                                        fontSize: "18",
                                        textBorderColor: "#fff",
                                      },
                                      itemStyle: {
                                        borderRadius: 10,
                                        borderWidth: 5,
                                      },
                                      radius: ["0", "100%"],
                                      type: "sunburst",
                                      emphasis: {
                                        focus: "ancestor",
                                      },
                                      data: [
                                        {
                                          children: sentimentObj?.tweets,
                                          name: "Tweets",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#1DA1F2",
                                                },
                                                {
                                                  offset: 1,
                                                  color:
                                                    "rgba(135, 207, 255, 1)",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                        {
                                          children: sentimentObj?.reddit,
                                          name: "Reddit",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#FF4500",
                                                },
                                                {
                                                  offset: 1,
                                                  color:
                                                    "rgba(255, 171, 140, 1)",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                }}
                              />
                            </div>
                            <div className="col-sm-6  socialChart">
                              <span className="captionXl">
                                Sentiment Distribution by Social Media Platforms
                              </span>
                              <EChartsReact
                                className="doubleLayer-pieChart  mt-4"
                                option={{
                                  silent: false,

                                  series: [
                                    {
                                      label: {
                                        color: "#fff",
                                        fontSize: "18",
                                        textBorderColor: "#fff",
                                      },
                                      nodeClick: false,
                                      itemStyle: {
                                        borderRadius: 10,
                                        borderWidth: 5,
                                      },
                                      radius: ["0", "100%"],
                                      type: "sunburst",
                                      emphasis: {
                                        focus: "ancestor",
                                      },
                                      data: [
                                        {
                                          children:
                                            socialObj?.positive?.childArray,
                                          name:
                                            socialObj?.positive?.percentage +
                                            " %",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#3aa95a",
                                                },
                                                {
                                                  offset: 1,
                                                  color: "#ffffff",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                        {
                                          children:
                                            socialObj?.negative?.childArray,
                                          name:
                                            socialObj?.negative?.percentage +
                                            " %",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#d72727",
                                                },
                                                {
                                                  offset: 1,
                                                  color: "#ffffff",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                        {
                                          children:
                                            socialObj?.neutral?.childArray,
                                          name:
                                            socialObj?.neutral?.percentage +
                                            " %",
                                          value: 30,
                                          itemStyle: {
                                            color: {
                                              colorStops: [
                                                {
                                                  offset: 0,
                                                  color: "#bec3cd",
                                                },
                                                {
                                                  offset: 1,
                                                  color: "#ffffff",
                                                },
                                              ],
                                              x: 0,
                                              y: 0,
                                              x2: 0,
                                              y2: 1.5,
                                              type: "linear",
                                              global: false,
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </Row>
                          <div className="mainLegendDiv">
                            {legend.map((e, i) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    className="legendImg"
                                    src={e.image}
                                    alt={e.name}
                                  />
                                  <div
                                    className="legendSpan"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {" "}
                                    {e.name}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* <div
                            className="reactionAnalyses"
                            onClick={() => {
                              navigate("/socialMediaCoverage", {
                                state: {
                                  companies: [],
                                  incident: "",
                                  name: "companyPage",
                                  companyName: "companyData?.name",
                                  allIncidentDetails: {
                                    incidents: severeAndSocial?.totalIncidents,
                                    severeIncidents: severeAndSocial?.severeHighCount,
                                  },
                                },
                              })
                            }}
                          >
                            <span className="reactionAnalysesSpan">
                              View All Reaction Analyses
                            </span>
                          </div> */}
                        </div>
                      </ChartCard>
                    </Collapse.Panel>

                    <Collapse.Panel
                      className="company-collapse-header"
                      header={
                        <h4>
                          <span className="company-page-collable-label-text">
                            4
                          </span>
                          Breaches & violation
                        </h4>
                      }
                      key="4"
                    >
                      <div
                        className="most-sec-over containerWidth"
                        style={{ padding: 20 }}
                      >
                        <div className="violations-ps">
                          <h2>Breaches and Violations</h2>
                        </div>
                        <Tabs
                          onChange={(e) => {
                            setActiveTab(e);
                          }}
                          activeKey={activeTab}
                          className="supply-chain-act-px "
                        >
                          <Tabs.TabPane key="0" tab={"LkSG"}>
                            <section>
                              <div className="container  g-0  ">
                                <div className="UNclass">
                                  <img
                                    className="sq104px"
                                    src={Images.breachesIcons}
                                    alt="UN"
                                  />
                                  <div className="d-flex justify-content row">
                                    <Row>
                                      <span className="unSpan">
                                        German Supply Chain Act (LkSG)
                                        <img
                                          className="hover-page"
                                          style={{ marginLeft: 5 }}
                                          src={Images.circleInfo}
                                          alt=""
                                        />
                                      </span>
                                    </Row>

                                    <div className="d-flex justify-content">
                                      <span className="unSpanCount color05476F pl-16">
                                        {
                                          breachesAndViolationData.mostFrequentCount
                                        }{" "}
                                        Incidents
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content">
                                  <Row>
                                    <span className="unSpanCount">
                                      Companies with the most number of
                                      violations
                                    </span>
                                  </Row>
                                  <div></div>
                                </div>

                                <div>
                                  {breachesAndViolationData?.german_supply_chain_act &&
                                    breachesAndViolationData
                                      .german_supply_chain_act.length > 0 &&
                                    breachesAndViolationData?.german_supply_chain_act.map(
                                      (item, index) => {
                                        return (
                                          <div className="companiesDataDiv">
                                            <span>
                                              {index + 1 + " "}
                                              <b
                                                onClick={() =>
                                                  navigate("/companyPage", {
                                                    state: { id: item?.id },
                                                  })
                                                }
                                              >
                                                {item.name + " "}
                                              </b>
                                            </span>

                                            <div
                                              style={{
                                                width: 100,
                                                height: 19,
                                                display: "flex",
                                              }}
                                            >
                                              {item.incidentCount +
                                                " incidents"}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>

                                {breachesAndViolationData?.esg_supply_chain_act &&
                                  breachesAndViolationData.esg_supply_chain_act
                                    .length > 9 && (
                                    <div
                                      className="reactionAnalyses"
                                      onClick={() => {
                                        // setModalVisible(true);
                                        setBreachesViolationLimit(200);
                                      }}
                                    >
                                      <span className="reactionAnalysesSpan downl-btn">
                                        View All
                                      </span>
                                    </div>
                                  )}

                                <div className="d-flex justify-content">
                                  <span className="unSpanCount">
                                    Most frequent violations
                                  </span>
                                </div>
                                {activeTab == 0 &&
                                  breachesAndViolationData?.most_frequent &&
                                  breachesAndViolationData.most_frequent
                                    .length > 0 &&
                                  breachesAndViolationData?.most_frequent?.map(
                                    (val, ind) => {
                                      return (
                                        <UngcpBreaches
                                          networkData={networkData}
                                          activeTab={activeTab}
                                          type={val._id}
                                          list={val?.incidents?.slice(0, 4)}
                                          totalIncidentCount={
                                            val?.incidents?.length
                                          }
                                          value={val._id}
                                          dataKey={val.value}
                                        />
                                      );
                                    }
                                  )}

                                <div
                                  className="reactionAnalyses"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setModalData({
                                      ...modalData,
                                      voilation: "german_supply_chain_act",
                                      start: networkData.start,
                                      end: networkData.end,
                                    });
                                  }}
                                >
                                  <span className="reactionAnalysesSpan downl-btn">
                                    View All Incidents
                                  </span>
                                </div>
                              </div>
                            </section>
                          </Tabs.TabPane>
                          <Tabs.TabPane key="1" tab={"EU Supply Chain Act."}>
                            <section>
                              <div className="container  g-0  ">
                                <div className="UNclass">
                                  <img
                                    className="sq104px"
                                    src={Images.breachesIcons}
                                    alt="UN"
                                  />
                                  <div className="d-flex justify-content row">
                                    <Row>
                                      <span className="unSpan">
                                        Supply Chain Act Violations
                                        <img
                                          className="hover-page"
                                          style={{ marginLeft: 5 }}
                                          src={Images.circleInfo}
                                          alt=""
                                        />
                                      </span>
                                    </Row>

                                    <div className="d-flex justify-content">
                                      <span className="unSpanCount color05476F pl-16">
                                        {
                                          breachesAndViolationData.mostFrequentCount
                                        }{" "}
                                        Incidents
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content">
                                  <Row>
                                    <span className="unSpanCount">
                                      Companies with the most number of
                                      violations
                                    </span>
                                  </Row>
                                  <div></div>
                                </div>

                                <div>
                                  {breachesAndViolationData?.esg_supply_chain_act &&
                                    breachesAndViolationData
                                      .esg_supply_chain_act.length > 0 &&
                                    breachesAndViolationData?.esg_supply_chain_act.map(
                                      (item, index) => {
                                        return (
                                          <div className="companiesDataDiv">
                                            <span>
                                              {index + 1 + " "}
                                              <b
                                                onClick={() =>
                                                  navigate("/companyPage", {
                                                    state: { id: item?.id },
                                                  })
                                                }
                                              >
                                                {item.name + " "}
                                              </b>
                                            </span>

                                            <div
                                              style={{
                                                width: 100,
                                                height: 19,
                                                display: "flex",
                                              }}
                                            >
                                              {item.incidentCount +
                                                " incidents"}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>

                                {breachesAndViolationData?.esg_supply_chain_act &&
                                  breachesAndViolationData.esg_supply_chain_act
                                    .length > 9 && (
                                    <div
                                      className="reactionAnalyses"
                                      onClick={() => {
                                        // setModalVisible(true);
                                        setBreachesViolationLimit(200);
                                      }}
                                    >
                                      <span className="reactionAnalysesSpan downl-btn">
                                        View All
                                      </span>
                                    </div>
                                  )}

                                <div className="d-flex justify-content">
                                  <span className="unSpanCount">
                                    Most frequent violations
                                  </span>
                                </div>
                                {activeTab == 1 &&
                                  breachesAndViolationData?.most_frequent &&
                                  breachesAndViolationData.most_frequent
                                    .length > 0 &&
                                  breachesAndViolationData?.most_frequent?.map(
                                    (val, ind) => {
                                      return (
                                        <UngcpBreaches
                                          networkData={networkData}
                                          activeTab={activeTab}
                                          type={val._id}
                                          list={val?.incidents?.slice(0, 4)}
                                          totalIncidentCount={
                                            val?.incidents?.length
                                          }
                                          value={val.value}
                                          dataKey={val.value}
                                        />
                                      );
                                    }
                                  )}

                                <div
                                  className="reactionAnalyses"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setModalData({
                                      ...modalData,
                                      voilation: "esg_supply_chain_act",
                                      start: networkData.start,
                                      end: networkData.end,
                                    });
                                  }}
                                >
                                  <span className="reactionAnalysesSpan downl-btn">
                                    View All Incidents
                                  </span>
                                </div>
                              </div>
                            </section>
                          </Tabs.TabPane>

                          <Tabs.TabPane key="2" tab={"UNGC"}>
                            <section>
                              <div className="container  g-0  ">
                                <div className="UNclass">
                                  <img
                                    className="sq104px"
                                    src={Images.un}
                                    alt="UN"
                                  />
                                  <div className="d-flex justify-content row">
                                    <Row>
                                      <span className="unSpan">
                                        UN global compact principles breaches
                                        <img
                                          className="hover-page"
                                          style={{ marginLeft: 5 }}
                                          src={Images.circleInfo}
                                          alt=""
                                        />
                                      </span>
                                    </Row>

                                    <div className="d-flex justify-content">
                                      <span className="unSpanCount color05476F pl-16">
                                        {incidentAnalyzer_UN_Breaches.mostFrequentCount ||
                                          0}{" "}
                                        Incidents
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content">
                                  <Row>
                                    <span className="unSpanCount">
                                      Companies with the most number of breaches
                                    </span>
                                  </Row>
                                  <div></div>
                                </div>

                                <div>
                                  {activeTab == 2 &&
                                    incidentAnalyzer_UN_Breaches?.companies_with_breaches?.map(
                                      (e, index) => (
                                        <div className="companiesDataDiv">
                                          <span>
                                            {index + 1 + " "}
                                            <b
                                              onClick={() =>
                                                navigate("/companyPage", {
                                                  state: { id: e?.id },
                                                })
                                              }
                                            >
                                              {e.name + " "}
                                            </b>
                                          </span>

                                          <div
                                            style={{
                                              width: 100,
                                              height: 19,
                                              display: "flex",
                                            }}
                                          >
                                            {e.incidentCount + " incidents"}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                                <div className="d-flex justify-content">
                                  <span className="unSpanCount">
                                    Most frequent breaches
                                  </span>
                                </div>
                                {incidentAnalyzer_UN_Breaches?.most_frequent_breaches?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                        dataKey={val._id}
                                      />
                                    );
                                  }
                                )}
                                <div className="d-flex justify-content">
                                  <span className="unSpanCount">
                                    Total incidents on each principle
                                  </span>
                                </div>
                                <>
                                  <PrincipleBarChat
                                    series={BarMapping(
                                      incidentAnalyzer_UN_Breaches?.most_frequent_breaches
                                    )}
                                    networkData={networkData}
                                  />

                                  <div className="ellips chart-bottom-legend-New">
                                    {HLEA?.map((data) => {
                                      return (
                                        <p className="legend">
                                          <span
                                            className="legendCircle"
                                            style={{ background: data.color }}
                                          />
                                          {data.tittle}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </>

                                <div
                                  className="reactionAnalyses"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setModalData({
                                      ...modalData,
                                      voilation: "UNGCP_Breaches",
                                      start: networkData.start,
                                      end: networkData.end,
                                    });
                                  }}
                                >
                                  <span className="reactionAnalysesSpan downl-btn">
                                    View All Incidents
                                  </span>
                                </div>
                              </div>
                            </section>
                          </Tabs.TabPane>

                          <Tabs.TabPane key="3" tab={"PAI"}>
                            <section>
                              <div className="container  g-0  ">
                                <div className="UNclass">
                                  <img
                                    className="sq104px"
                                    src={Images.pia}
                                    alt="UN"
                                  />
                                  <div className="d-flex justify-content row">
                                    <Row>
                                      <span className="unSpan">
                                        Principal Adverse Impact (PAI)
                                        Violations
                                        <img
                                          className="hover-page"
                                          style={{ marginLeft: 5 }}
                                          src={Images.circleInfo}
                                          alt=""
                                        />
                                      </span>
                                    </Row>

                                    <div className="d-flex justify-content">
                                      <span className="unSpanCount color05476F pl-16">
                                        {
                                          breachesAndViolationData.mostFrequentCount
                                        }{" "}
                                        Incidents
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content">
                                  <Row>
                                    <span className="unSpanCount">
                                      Companies with the most number of breaches
                                    </span>
                                  </Row>
                                  <div></div>
                                </div>

                                <div>
                                  {activeTab == 3 &&
                                    breachesAndViolationData?.esg_PAI &&
                                    breachesAndViolationData?.esg_PAI.length >
                                    0 &&
                                    breachesAndViolationData?.esg_PAI?.map(
                                      (e, index) => (
                                        <div className="companiesDataDiv">
                                          <span>
                                            {index + 1 + " "}
                                            <b
                                              onClick={() =>
                                                navigate("/companyPage", {
                                                  state: { id: e?.id },
                                                })
                                              }
                                            >
                                              {e.name + " "}
                                            </b>
                                          </span>

                                          <div className="Incident_on_ESG">
                                            {e.incidentCount + " incidents"}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>

                                {activeTab == 3 &&
                                  breachesAndViolationData?.esg_PAI &&
                                  breachesAndViolationData?.esg_PAI.length >
                                  9 && (
                                    <div
                                      className="reactionAnalyses"
                                      onClick={() => {
                                        // setModalVisible(true);
                                        setBreachesViolationPaiLimit(200);
                                      }}
                                    >
                                      <span className="reactionAnalysesSpan downl-btn">
                                        View All
                                      </span>
                                    </div>
                                  )}
                                <div className="d-flex justify-content">
                                  <span className="unSpanCount">
                                    Most frequent breaches
                                  </span>
                                </div>
                                {activeTab == 3 &&
                                  breachesAndViolationData?.most_frequent &&
                                  breachesAndViolationData?.most_frequent
                                    .length > 0 &&
                                  breachesAndViolationData?.most_frequent?.map(
                                    (val, ind) => {
                                      return (
                                        <UngcpBreaches
                                          networkData={networkData}
                                          activeTab={activeTab}
                                          type={val._id}
                                          list={val?.incidents?.slice(0, 4)}
                                          totalIncidentCount={
                                            val?.incidents?.length
                                          }
                                          dataKey={val.value}
                                        />
                                      );
                                    }
                                  )}
                                <div className="d-flex justify-content">
                                  <span className="unSpanCount">
                                    Total Number of incidents
                                  </span>
                                </div>
                                <>
                                  {activeTab == 3 &&
                                    breachesAndViolationData?.most_frequent &&
                                    breachesAndViolationData?.most_frequent
                                      .length > 0 &&
                                    !breachesAndViolationDataLoader && (
                                      <PAIBarChat
                                        series={BarMappingPAI(
                                          breachesAndViolationData?.most_frequent
                                        )}
                                        networkData={networkData}
                                      />
                                    )}
                                </>
                                <div className="pia-bott-hover">
                                  <p
                                    className="onhover-image"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                  >
                                    <span>
                                      <img
                                        className={isHovered}
                                        src={
                                          isHovered
                                            ? Images.newImageOnHover
                                            : Images.circleInfo
                                        }
                                        alt=""
                                      />
                                    </span>
                                    14 Principal Adverse Impact (PAI)
                                  </p>

                                  <div className="hover-content-ps">
                                    <div className="adverse-heading-ps">
                                      <h5>
                                        {" "}
                                        <span>
                                          <img src={Images.colorSky} />
                                        </span>
                                        14 Principal Adverse Impact (PAI)
                                      </h5>
                                    </div>
                                    <div className="number-of-impact-ps">
                                      <p>1. GHG emissions scope 1,2,3, total</p>
                                      <p>2. Carbon footprint</p>
                                      <p>
                                        3. GHG intensity of investee companies
                                      </p>
                                      <p>
                                        4. Exposure to companies active in the
                                        fossil fuel sector
                                      </p>
                                      <p>
                                        5. Share of non-renewable energy
                                        consumption and production
                                      </p>
                                      <p>
                                        6. Energy consumption intensity per high
                                        impact climate sector
                                      </p>
                                      <p>
                                        7. Activities negatively affecting
                                        biodiversity-sensitive areas
                                      </p>
                                      <p>8. Emissions to water</p>
                                      <p>
                                        9. Hazardous waste and radioactive waste
                                        ratio
                                      </p>
                                      <p>
                                        10. Violations of UN Global Compact
                                        principles and OECD guidelines{" "}
                                      </p>
                                      <p>
                                        11. Lack of processes and compliance
                                        mechanisms to monitor compliance with UN
                                        GC and OECD
                                      </p>
                                      <p>12. Unadjusted gender pay gap</p>
                                      <p>13. Board gender diversity</p>
                                      <p>
                                        14. Exposure to controversial weapons
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="reactionAnalyses"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setModalData({
                                      ...modalData,
                                      voilation: "esg_PAI",
                                      start: networkData.start,
                                      end: networkData.end,
                                    });
                                  }}
                                >
                                  <span className="reactionAnalysesSpan downl-btn">
                                    View All Incidents
                                  </span>
                                </div>
                              </div>
                            </section>
                          </Tabs.TabPane>

                          <Tabs.TabPane key="4" tab={"SDG"}>
                            <section>
                              <div className="container  g-0  ">
                                <div className="UNclass">
                                  <img
                                    className="sq104px"
                                    src={Images.sdg}
                                    alt="UN"
                                  />
                                  <div className="d-flex justify-content row">
                                    <Row>
                                      <span className="unSpan">
                                        UN global compact principles breaches
                                        <img
                                          className="hover-page"
                                          style={{ marginLeft: 5 }}
                                          src={Images.circleInfo}
                                          alt=""
                                        />
                                      </span>
                                    </Row>

                                    <div className="d-flex justify-content">
                                      <span className="unSpanCount color05476F pl-16">
                                        {
                                          breachesAndViolationData.mostFrequentCount
                                        }{" "}
                                        Incidents
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content">
                                  <Row>
                                    <span className="unSpanCount">
                                      Companies with the most number of breaches
                                    </span>
                                  </Row>
                                  <div></div>
                                </div>

                                <div>
                                  {activeTab == 4 &&
                                    breachesAndViolationData?.esg_SDG &&
                                    breachesAndViolationData?.esg_SDG.length >
                                    0 &&
                                    breachesAndViolationData?.esg_SDG?.map(
                                      (e, index) => (
                                        <div className="companiesDataDiv">
                                          <span>
                                            {index + 1 + " "}
                                            <b
                                              onClick={() =>
                                                navigate("/companyPage", {
                                                  state: { id: e?.id },
                                                })
                                              }
                                            >
                                              {e.name + " "}
                                            </b>
                                          </span>

                                          <div className="Incident_on_ESG">
                                            {e.incidentCount + " incidents"}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>

                                {activeTab == 4 &&
                                  breachesAndViolationData?.esg_SDG &&
                                  breachesAndViolationData?.esg_SDG.length >
                                  9 && (
                                    <div
                                      className="reactionAnalyses"
                                      onClick={() => {
                                        // setModalVisible(true);
                                        setBreachesViolationSdgLimit(200);
                                      }}
                                    >
                                      <span className="reactionAnalysesSpan downl-btn">
                                        View All
                                      </span>
                                    </div>
                                  )}

                                <div className="d-flex justify-content">
                                  <span className="unSpanCount">
                                    Most frequent breaches
                                  </span>
                                </div>
                                {activeTab == 4 &&
                                  breachesAndViolationData?.most_frequent &&
                                  breachesAndViolationData?.most_frequent
                                    .length > 0 &&
                                  breachesAndViolationData?.most_frequent?.map(
                                    (val, ind) => {
                                      return (
                                        <UngcpBreaches
                                          networkData={networkData}
                                          activeTab={activeTab}
                                          type={val._id}
                                          list={val?.incidents?.slice(0, 4)}
                                          totalIncidentCount={
                                            val?.incidents?.length
                                          }
                                          dataKey={val.value}
                                        />
                                      );
                                    }
                                  )}
                                <div className="d-flex justify-content">
                                  <span className="unSpanCount">
                                    Total Number of incidents on each goal
                                  </span>
                                </div>
                                <>
                                  {activeTab == 4 &&
                                    breachesAndViolationData?.most_frequent &&
                                    breachesAndViolationData?.most_frequent
                                      .length > 0 &&
                                    !breachesAndViolationDataLoader && (
                                      <SDGBarChat
                                        series={BarMappingSDG(
                                          breachesAndViolationData?.most_frequent
                                        )}
                                        networkData={networkData}
                                      />
                                    )}
                                </>
                                <div className="pia-bott-hover">
                                  <p
                                    className="onhover-image"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                  >
                                    <span>
                                      <img
                                        className={isHovered}
                                        src={
                                          isHovered
                                            ? Images.newImageOnHover
                                            : Images.circleInfo
                                        }
                                        alt=""
                                      />
                                    </span>
                                    17 Sustainable Development Goals
                                  </p>

                                  <div className="hover-content-ps">
                                    <div className="adverse-heading-ps">
                                      <h5>
                                        {" "}
                                        <span>
                                          <img src={Images.colorSky} />
                                        </span>
                                        17 Sustainable Development Goals
                                      </h5>
                                    </div>
                                    <div className="number-of-impact-ps">
                                      <p>1. No poverty</p>
                                      <p>2. Zero hunger</p>
                                      <p>3. Good health and well-being</p>
                                      <p>4. Quality education</p>
                                      <p>5. Gender equality</p>
                                      <p>6. Clean water and sanitation</p>
                                      <p>7. Affordable and clean energy</p>
                                      <p>8. Decent work and economic growth</p>
                                      <p>
                                        9. Industry, innovation and
                                        infrastructure
                                      </p>
                                      <p>10. Reduced inequalities </p>
                                      <p>
                                        11. Sustainable cities and communities
                                      </p>
                                      <p>
                                        12. Responsible consumption and
                                        production
                                      </p>
                                      <p>13. Climate action</p>
                                      <p>14. Life below water</p>
                                      <p>15. Life on land</p>
                                      <p>
                                        16. Peace, justice and strong
                                        institutions
                                      </p>
                                      <p>17. Partnership for the goals</p>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="reactionAnalyses"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setModalData({
                                      ...modalData,
                                      voilation: "esg_SDG",
                                      start: networkData.start,
                                      end: networkData.end,
                                    });
                                  }}
                                >
                                  <span className="reactionAnalysesSpan downl-btn">
                                    View All Incidents
                                  </span>
                                </div>
                              </div>
                            </section>
                          </Tabs.TabPane>
                        </Tabs>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="hone-left-side">
                  <div className="add-to-wat"></div>
                </div>
              </div>

              {/* ----- */}
              <div className="col-lg-8"></div>
            </div>
          </div>
        </div>
        {rootLevelIncidentModalItem && (
          <Modal
            className="incidentModal"
            open={rootLevelIncidentModalItem}
            onCancel={() => setRootLevelIncidentModalItem(false)}
            footer={null}
            width={1600}
            centered
          >
            <IncidentsList
              payloadRootLevel={topLevelIncidentListModalPayload}
              isComeFromModal={true}
            />
          </Modal>
        )}
        {selectedCategoriesModal && (
          <Modal
            className="incidentModal"
            open={selectedCategoriesModal}
            onCancel={() => setSelectedCategoriesModal(false)}
            footer={null}
            width={1600}
            centered
          >
            <BubleIncidentsList
              apiPayload={networkData}
              bubbleData={true}
              esg_categories={
                selectedEsgCategories.length > 0
                  ? selectedEsgCategories
                  : ["ss"]
              }
            />
          </Modal>
        )}
      </div>
      {modalVisible ? (
        <IncidentsListModal
          setModalVisible={(e) => {
            setModalVisible(e);
            setModalData(null);
            if (!e) {
              networkData = { ...networkData, esg_categories: [] };
            }
          }}
          networkData={modalData ? modalData : networkData}
        />
      ) : null}

      {advanceModalOpen && (
        <IncidentSearchMain
          prevScreenData={prevPageData}
          updatePrevScreenData={setPrevPageData}
          modal2Open={advanceModalOpen}
          setModal2Open={() => {
            setAdvanceModalOpen(false)
          }}
        />
      )}
    </>
  );
};

// Define a rendering function called MapItem that takes color and name as parameters
const MapItem = ({ color, name }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: ".6vw",
        height: ".6vw",
        borderRadius: "100%",
        marginRight: ".5vw",
        backgroundColor: color,
      }}
    ></div>
    <div style={{ fontSize: ".8rem" }}>{name}</div>
  </div>
);

const CompanyRiskScore = ({
  data,
  viewMoreState,
  viewMoreHandler,
  isViewBtnShow,
  setRiskScorePerCompOffset,
  stateLoader,
}) => {
  return (
    <div className="portfolio_container_1 custom_container_portfolio_1 riskScoreOverflow">
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          if (!viewMoreState && index < 10) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "50%" }}
                  className={style.leftDataContainer}
                >
                  <div
                    className={style.CaptionXL}
                    style={{
                      background: "#EFF1F6",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 7px",
                    }}
                  >
                    <span style={{ minWidth: "" }}>{index + 1}</span>
                  </div>
                  {/* <Badge count={9}></Badge> */}
                  <span
                    onClick={() =>
                      navigate("/companyPage", { state: { id: item.id } })
                    }
                    className={style.body}
                    style={{ paddingLeft: "8px" }}
                  >
                    <p>{item?.company || ""}</p>
                  </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      height: "20px",
                      minWidth: "30px",
                    }}
                  >
                    {item?.risk
                      ? Math.ceil(item?.risk) >= 75 && (
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                          src={Images.dangerIcon}
                        />
                      )
                      : ""}
                  </div>
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                    style={{ marginRight: "16px" }}
                  >
                    {item?.risk ? Math.ceil(item?.risk) : 0}
                  </span>
                  <Progress
                    className="home_progress_portfolio_sec"
                    percent={item?.risk || 0}
                    showInfo={false}
                  />
                </div>
              </div>
            );
          } else if (viewMoreState) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "50%" }}
                  className={style.leftDataContainer}
                >
                  <div
                    className={style.CaptionXL}
                    style={{
                      background: "#EFF1F6",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 7px",
                    }}
                  >
                    <span style={{ minWidth: "" }}>{index + 1}</span>
                  </div>
                  {/* <Badge count={9}></Badge> */}
                  <span
                    onClick={() =>
                      navigate("/companyPage", { state: { id: item.company } })
                    }
                    className={style.body}
                    style={{ paddingLeft: "8px" }}
                  >
                    <p>{item?.company || ""}</p>
                  </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      height: "20px",
                      minWidth: "30px",
                    }}
                  >
                    {item?.risk
                      ? Math.ceil(item?.risk) >= 75 && (
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                          src={Images.dangerIcon}
                        />
                      )
                      : ""}
                  </div>
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                    style={{ marginRight: "16px" }}
                  >
                    {item?.risk ? Math.ceil(item?.risk) : 0}
                  </span>
                  <Progress
                    className="home_progress_portfolio_sec"
                    percent={item?.risk || 0}
                    showInfo={false}
                  />
                </div>
              </div>
            );
          }
        })}
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {data.length == 0 && stateLoader && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{<GlobalLoder />}</span>
          </div>
        )}
        {isViewBtnShow && (
          <div
            className={style.viewMoreContainer}
            style={{ height: stateLoader ? 47 : 40 }}
            onClick={() => {
              setRiskScorePerCompOffset((prev) => prev + 10);
              viewMoreHandler("riskScore");
            }}
          >
            <span className={style.buttonM}>
              {stateLoader ? <GlobalLoder /> : "View More"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const CompanyHighSeverity = ({
  data,
  viewMoreState,
  viewMoreHandler,
  isViewBtnShow,
  setNoOfIncidentOffset,
  stateLoader,
}) => {
  return (
    <div className="portfolio_container_1 custom_container_portfolio_1 riskScoreOverflow">
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          if (!viewMoreState && index < 10) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "50%" }}
                  className={style.leftDataContainer}
                >
                  <div
                    className={style.CaptionXL}
                    style={{
                      background: "#EFF1F6",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 7px",
                    }}
                  >
                    <span style={{ minWidth: "" }}>{index + 1}</span>
                  </div>
                  {/* <Badge count={9}></Badge> */}
                  <span
                    onClick={() =>
                      navigate("/companyPage", { state: { id: item._id } })
                    }
                    className={style.body}
                    style={{ paddingLeft: "8px" }}
                  >
                    <p>{item?.name || 0}</p>
                  </span>
                </div>
                <div
                  style={{
                    width: getProcessBarPercentage(index, item.incidentCount),
                    display: "flex",
                  }}
                >
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                    style={{ marginRight: "16px" }}
                  >
                    {item?.severCount ? Math.ceil(item?.severCount) : 0}
                  </span>

                  <Progress
                    className="progress_portfolio_first"
                    percent={
                      item?.severCount
                        ? Math.ceil(
                          (
                            (item?.severCount / item.incidentCount) *
                            100
                          ).toFixed(3)
                        )
                        : 0
                    }
                    showInfo={false}
                  />

                  <span
                    className={`${style.BodySelected} ${style.customProgressColor2}`}
                    style={{ marginLeft: "16px" }}
                  >
                    {item.incidentCount}
                  </span>
                </div>
              </div>
            );
          } else if (viewMoreState) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "50%" }}
                  className={style.leftDataContainer}
                >
                  <div
                    className={style.CaptionXL}
                    style={{
                      background: "#EFF1F6",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 7px",
                    }}
                  >
                    <span style={{ minWidth: "" }}>{index + 1}</span>
                  </div>
                  {/* <Badge count={9}></Badge> */}
                  <span
                    onClick={() =>
                      navigate("/companyPage", { state: { id: item.id } })
                    }
                    className={style.body}
                    style={{ paddingLeft: "8px" }}
                  >
                    <p>{item?.name || 0}</p>
                  </span>
                </div>
                <div
                  style={{
                    width:
                      index > 9
                        ? "10%"
                        : getProcessBarPercentage(index, item.incidentCount),
                    display: "flex",
                  }}
                >
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                    style={{ marginRight: "16px" }}
                  >
                    {item?.severCount ? Math.ceil(item?.severCount) : 0}
                  </span>
                  <Progress
                    className="progress_portfolio_first"
                    percent={
                      item?.severCount
                        ? Math.ceil(
                          (
                            (item?.severCount / item.incidentCount) *
                            100
                          ).toFixed(3)
                        )
                        : 0
                    }
                    showInfo={false}
                  />
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor2}`}
                    style={{ marginLeft: "16px" }}
                  >
                    {item.incidentCount}
                  </span>
                </div>
              </div>
            );
          }
        })}

      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {data.length == 0 && stateLoader && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{<GlobalLoder />}</span>
          </div>
        )}
        {isViewBtnShow && (
          <div
            className={style.viewMoreContainer}
            onClick={() => {
              setNoOfIncidentOffset((prev) => prev + 10);
              viewMoreHandler("highSeverity");
            }}
          >
            <span className={style.buttonM}>View More</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
