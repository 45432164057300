import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactWordcloud from "react-wordcloud";
// import { dispatch } from "../../../../Utils/dispatchNavigate";
import { mostUsedWordsAction } from "../../../../Redux/Action/comapanyPageAction";
import { ESG, getColor } from "../../../../Utils";
import { ChartCard } from "../../../../Layouts/ChartCard";
import Images from "../../../../Themes/ImageConstant";


const WordChart = (data) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(mostUsedWordsAction(data));
  }, [data.dateChange, data.id]);

  const { mostUsedWords } = useSelector((state) => state?.companyPageReducer);
  const minValue = 400;
  const maxValue = 600;

  // const minValue = Math.min(...mostUsedWords?.map((word) => word.value));
  // const maxValue = Math.max(...mostUsedWords?.map((word) =>   word.value));
  const scale = (value) => {
    const minSize = 50;
    const maxSize = 60;
    const scaledValue = (value - minValue) / (maxValue - minValue);
    return Math.round((maxSize - minSize) * scaledValue + minSize);
  };


  const options = {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontStyle: "italic",
    fontSizes: [20, 50],
    rotations: 0,
    spiral: "archimedean",
    scale: scale,
    padding: 2,
  };

  const getUpdatedData = (data) => {
    let arr = [];
      for (let j in data?.esg_factors) {
        if(data?.esg_factors[j].incidentCount > 0){
          let obj = {
            text:  data?.esg_factors[j].name,
            value: data?.esg_factors[j].incidentCount,
            color: getColor(data?.esg_factors[j].parent),
          };
          arr.push(obj);
        }
      }
    return arr;
  };
  

  const callbacks = {
    getWordColor: (word) => word.color,
    getWordTooltip: (word) =>"",
  };

  return (
    <section className="left-text">
      <ChartCard showTimeBarOnTop={false}
        showInfoIcon={false}
        className={"mt-32"}
        title={<h2>Most Used Words<img src={Images.circleInfo} className="info-icon-company-page" /><br />
        </h2>}
        legend={ESG}
        subtitle={<> <span className="chart-card-head-subtitle">Most Frequently ESG Related Used Words in News articles, X corp., and NGO reports</span></>}
        >

        <div className="Sentiment-grah">
          <div className="line-chart">
            <ReactWordcloud
              size={[690, 500]}
              maxWords={1000}
              words={getUpdatedData(mostUsedWords.list)}
              options={options}
              callbacks={callbacks}
            />
          </div>
        </div>
      </ChartCard>
    </section>
  );
};

export default WordChart;
