export const FilterParametersEnum = {
  RISK_SCORE: "RISK_SCORE",
  INDUSTRY: "INDUSTRY",
  INCIDENT_CATEGORY: "INCIDENT_CATEGORY",
  TOTAL_INCIDENT_COUNT: "TOTAL_INCIDENT_COUNT",
  COMPANY_LOCATION: "COMPANY_LOCATION",
  TIER_TYPE: "TIER_TYPE",
  COMPANY_LIST: "COMPANY_LIST",
  CUSTOMER: "CUSTOMER",
  SUPPLIER: "SUPPLIER",
  PARTNER: "PARTNER",
};

export const TierEnum = {
  TIER1: "Tier1",
  TIER1_TIER2: "Tier2",
  TIER1_TIER2_TIER3: "Tier3",
};

export const TabEnum = {
  NODE: "node",
  EDGE: "edge",
};

export const RISK_SCORE_ENUM = {
  HIGH_RISK: "High Risk",
  MEDIUM_RISK: "Medium Risk",
  LOW_RISK: "Low Risk",
  SECURE: "Secure",
};
