/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import { apiReducer } from "./apiSlice";
import { contextMenuReducer } from "./GraphExploration";
import { toolBoxReducer } from "./GraphExploration/toolBoxSlice";
import { graphReducer } from "./GraphExploration/graphSlice";
import { toolTipReducer } from "./GraphExploration/toolTipSlice";
import { filterSideBarReducer } from "./GraphExploration/FilterSideBarSlice";
import { timeRangeReducer } from "./timeRangeSlice";
import { RightClickMenuReducer } from "./GraphExploration/rightClickMenuSlice";
import { incidentReducer } from "../../../../Redux/Reducer/incidentReducer";

export const RESET_APP_STATE = "RESET_APP_STATE";

const appReducer = combineReducers({
  toolBox: toolBoxReducer,
  toolTip: toolTipReducer,
  contextMenu: contextMenuReducer,
  rightClickMenu: RightClickMenuReducer,
  graph: graphReducer,
  api: apiReducer,
  filterSideBar: filterSideBarReducer,
  timeRange: timeRangeReducer,
  incidentReducer: incidentReducer,
});

export default appReducer;
