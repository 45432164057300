import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ChartCard } from "../../../../Layouts/ChartCard";
import LinesChart from "../../../../Componets/LinesChart";
import { PNNT, TPNN } from "../../../../Utils";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import { sentimentTrendAction } from "../../../../Redux/Action/comapanyPageAction";

const SentimentTrend = (data) => {
  useEffect(() => {
    dispatch(sentimentTrendAction(data));
  }, [data.dateChange]);

  const { sentimentAnalysis } = useSelector(
    (state) => state?.companyPageReducer
  );
  return (
    <section>
      <ChartCard title={"Sentiment Trend"} legend={TPNN} className={"mt-32"} showTimeBarOnTop={false}>
        <div className="Sentiment-grah">
          <LinesChart width={"100%"} height={400} data={sentimentAnalysis} />
        </div>
      </ChartCard>
    </section>
  );
};

export default SentimentTrend;
