// 13/07/2023 MM
import { call, put, takeLatest } from "redux-saga/effects";
import { onError, onFail, onSucces, onUnAuth } from "../../Utils/UnAuthFailFn";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  clearChainHistoryAPi,
  findPathAPi,
  getCompaniesApi,
  getChainHistoryAPi,
  getChainListingAPi,
  getGraphViewDataApi,
  getIndustriesApi,
  getRiskChartApi,
  getRiskCompanyApi,
  getSupplyChainDataApi,
  getSupplyIncidentSeverityApi,
  getCompanyAPI,
  uploadApi,
  deleteUploadedDataApi,
  getCompaniesForAddSupplyChain,
  getCompaniesForAddSupplyChainAPI,
} from "../api";
import {
  deleteUploadedDataSuccess,
  deleteUploadedDataFail,
} from "../Action/supplyChainAction";
import { navigate } from "../../Utils/dispatchNavigate";
import { resetSearchPageDataAction } from "../Action/global";

function* getCountryDataSaga(action) {
  try {
    let data = yield call(getCompaniesApi, action.payload);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_SUPPLYCHAIN_COUNTRY_LIST_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getGraphViewDataSaga(action) {
  try {
    let data = yield call(getGraphViewDataApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_GRAPH_DATA_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_GRAPH_DATA_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getSupplyChainDetailSaga(action) {
  try {
    let data = yield call(getSupplyChainDataApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_SUPPLY_CHAIN_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLY_CHAIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getIndustriesSaga(action) {
  try {
    let data = yield call(getIndustriesApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_INDUSTRIES_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLY_CHAIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getRiskCompanySaga(action) {
  try {
    let data = yield call(getRiskCompanyApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_SUPPLY_RISK_COMPANY_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLY_CHAIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getRiskChartsSaga(action) {
  try {
    let data = yield call(getRiskChartApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_SUPPLY_RISK_CHART_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLY_CHAIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getIncidentSeveritySaga(action) {
  try {
    let data = yield call(getSupplyIncidentSeverityApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_INCIDENT_SEVERITY_CHART_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLY_CHAIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getSuplyChainListingDataSaga(action) {
  try {
    let data = yield call(getChainListingAPi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_SUPPLY_CHAIN_LIST_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getSupplyHistoryDataSaga(action) {
  try {
    let data = yield call(getChainHistoryAPi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_SUPPLY_CHAIN_HISTORY_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* getClearHistoryDataSaga(action) {
  try {
    let data = yield call(clearChainHistoryAPi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.API_CLEAR_SUPPLY_CHAIN_HISTORY_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}

function* getCOMPANYCOUNTSaga(action) {
  try {
    let data = yield call(getCompanyAPI, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.GET_COMPANY_COUNT_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}
function* findPathSaga(action) {
  try {
    let data = yield call(findPathAPi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.FIND_PATH_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}

function* uploadDataSaga(action) {
  try {
    let data = yield call(uploadApi, action.data);
    //on success
    if (data?.status === 0) {
      let obj = {
        type: type.UPLOAD_DATA_SUCCESS,
        data: data,
        show: false,
        navigationLink: false,
      };
      yield call(onSucces, obj);
    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      let obj = {
        type: type?.UPLOAD_DATA_FAIL,
        message: data?.result?.message,
        show: false,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError);
  }
}

function* deleteUploadedDataSaga(action) {
  try {
    let data = yield call(deleteUploadedDataApi, action.data);
    //on success
    if (data?.status === 0) {
      yield put(deleteUploadedDataSuccess(data.result.data));
      yield put(resetSearchPageDataAction())
      navigate("/supplychain")
    }
    //on auth fail
    else if (data?.status === 2) {
      yield put(deleteUploadedDataFail(data?.result?.message));
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      yield put(deleteUploadedDataFail(data?.result?.message));
    }
  } catch (e) {
    yield put(deleteUploadedDataFail(e));
    yield call(onError);
  }
}

function* getCompaniesForAddSupplyChainSaga(action) {
  try {
    let data = yield call(getCompaniesForAddSupplyChainAPI, action.data);
    //on success
    if (data?.status === 0) {
      yield put(deleteUploadedDataSuccess(data.result.data));
      window.location.reload();
    }
    //on auth fail
    else if (data?.status === 2) {
      yield put(deleteUploadedDataFail(data?.result?.message));
      onUnAuth(data?.result?.message);
    } else if (data?.status === 1) {
      yield put(deleteUploadedDataFail(data?.result?.message));
    }
  } catch (e) {
    yield put(deleteUploadedDataFail(e));
    yield call(onError);
  }
}

export default function* rootSupplyChainSaga() {
  yield takeLatest(
    type.API_GET_SUPPLYCHAIN_COUNTRY_LIST_LOAD,
    getCountryDataSaga
  );
  yield takeLatest(type.API_GET_GRAPH_DATA_LOAD, getGraphViewDataSaga);
  yield takeLatest(type.API_GET_SUPPLY_CHAIN_LOAD, getSupplyChainDetailSaga);
  yield takeLatest(type.API_GET_INDUSTRIES_LOAD, getIndustriesSaga);
  yield takeLatest(type.API_SUPPLY_RISK_CHART_LOAD, getRiskChartsSaga);

  yield takeLatest(type.API_SUPPLY_RISK_COMPANY_LOAD, getRiskCompanySaga);

  yield takeLatest(
    type.API_INCIDENT_SEVERITY_CHART_LOAD,
    getIncidentSeveritySaga
  );
  yield takeLatest(
    type.API_GET_SUPPLY_CHAIN_LIST_LOAD,
    getSuplyChainListingDataSaga
  );
  yield takeLatest(
    type.API_GET_SUPPLY_CHAIN_HISTORY_LOAD,
    getSupplyHistoryDataSaga
  );
  yield takeLatest(
    type.API_CLEAR_SUPPLY_CHAIN_HISTORY_LOAD,
    getClearHistoryDataSaga
  );
  yield takeLatest(type.GET_COMPANY_COUNT_LOAD, getCOMPANYCOUNTSaga);
  yield takeLatest(type.FIND_PATH_LOAD, findPathSaga);
  yield takeLatest(type.UPLOAD_DATA_LOAD, uploadDataSaga);
  yield takeLatest(type.DELET_UPLOADED_DATA_LOAD, deleteUploadedDataSaga);
  yield takeLatest(
    type.GET_COMPANY_FOR_ADD_SUPPLY_CHAIN_LOAD,
    getCompaniesForAddSupplyChainSaga
  );
}
