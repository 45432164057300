import { Card, Snackbar, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
import { resetTooltip } from "../../redux/GraphExploration/toolTipSlice";
// import GroupUngroupButton from "./GroupUngroupButton";
import { useGraphObject } from "../context/MyContext";

function GraphTooltip() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const graphTooltipLocation = useAppSelector(
    (state) => state.toolTip.location
  );
  const { graphObject } = useGraphObject();
  const graphTooltipShow = useAppSelector((state) => state.toolTip.show);
  const targetEdgeId = useAppSelector((state) => state.toolTip.targetEdgeId);
  let targetEdgeData;
  if (targetEdgeId) {
    targetEdgeData = graphObject?.getEdgeData(targetEdgeId);
  }
  const muiStyles = {
    paper: {
      background: theme.palette.paperCards.main,
      borderRadius: "4px",
      border: `1px solid ${theme.palette.gray.A200}`,
      position: "absolute",
      overflowY: "hidden",
      overflowX: "hidden",
      boxShadow: 0,
      zIndex: 10,
    },
  };
  let tooltipContent;
  if (graphTooltipShow) {
    if (targetEdgeId !== undefined) {
      tooltipContent = (
        <Card
          variant="outlined"
          sx={{
            // width: 200,
            overflow: "visible",
            p: 1,
            "&::before, &::after": {
              content: '""',
              position: "absolute",
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              top: "100%",
              left: "50%",
              marginLeft: "-10px",
            },
            "&::before": {
              borderTop: "10px solid white",
              marginTop: "5px",
            },
            "&::after": {
              borderTop: "10px solid white",
              marginTop: "-4px",
              zIndex: 1,
            },
          }}
        >
          <Typography variant="subtitle3" noWrap>
            {targetEdgeData?.data.label}
          </Typography>
        </Card>
      );
    }
  } else {
    tooltipContent = <div />;
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        top: graphTooltipLocation?.y,
        left: graphTooltipLocation?.x,
        transform: "translate(-50%, -150%)",
        height: 32,
        width: 108,
      }}
      open={graphTooltipShow}
      onClose={() => {
        dispatch(resetTooltip());
      }}
    >
      {tooltipContent}
    </Snackbar>
  );
}
export default GraphTooltip;
