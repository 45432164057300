import { apiConstants as types } from "../../Constants/apiConstants";

const initialState = {
  isLoading: false,
  result: {},
  countryList: [],
  graphData: [],
  resultData: {},
  industries: [],
  severityData: {},
  riskCompany: [],
  riskCharts: {},
  totalHigh: 0,
  totalIncidents: 0,
  companies: [],
  pagination: {},
  historyData: [],
  comapnyCount: null,
  findPathData: {},
  isSuccess: false,
  isError: false,
  deleteUploadedDataLoader: false,
  isSupplyUpload: [],
  supplyChainFileUpload: false,
  supplyChainFileuploadList: []
};

function getAllCountries(data) {
  let allCountriesData = [];
  for (let i in data) {
    let obj = {
      label: data[i].name,
      value: data[i]._id,
      id: data[i].id,
      isin: data[i].isin,
      tickers: data[i].tickers,
      alter_names: data[i].alter_names,
      alter_name: data[i].alter_name,

    };
    allCountriesData.push(obj);
  }
  return allCountriesData;
}

function getAllIndustries(data) {
  let industries = [];
  if (data.length > 0) {
    for (let i in data) {
      let obj = {
        label: data[i].name,
        value: data[i]._id,
        id: data[i].id,
        status: false,
      };
      industries.push(obj);
    }
  }
  return industries;
}

export const supplyChainReducer = (state = initialState, action) => {
  let { payload } = action;
  switch (action.type) {
    case types.API_GET_SUPPLYCHAIN_COUNTRY_LIST_LOAD:
      return { ...state, isLoading: false };
    case types.API_GET_SUPPLYCHAIN_COUNTRY_LIST_SUCCESS:
      let allCountries = getAllCountries(payload?.data?.countries);

      return { ...state, countryList: allCountries, isLoading: true };
    case types.API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL:
      return { ...state, isLoading: false };
    case types.API_GET_GRAPH_DATA_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_GRAPH_DATA_SUCCESS:
      return { ...state, isLoading: false, graphData: payload.data };

    case types.API_GET_SUPPLY_CHAIN_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_SUPPLY_CHAIN_SUCCESS:
      return { ...state, isLoading: false, resultData: payload.data };

    case types.API_GET_INDUSTRIES_LOAD:
      return { ...state, isLoading: true };

    case types.API_GET_INDUSTRIES_SUCCESS:
      const ind = getAllIndustries(payload.data);
      state.industries = ind;
      return { ...state, isLoading: false };

    //supply risk company chart
    case types.API_SUPPLY_RISK_COMPANY_LOAD:
      return { ...state, isLoading: true };

    case types.API_SUPPLY_RISK_COMPANY_SUCCESS:
      return { ...state, isLoading: false, riskCompany: payload.data };

    //supply risk charts
    case types.API_SUPPLY_RISK_CHART_LOAD:
      return { ...state, isLoading: true };

    case types.API_SUPPLY_RISK_CHART_SUCCESS:
      return { ...state, isLoading: false, riskCharts: payload.data };

    //Incident severity chart load
    case types.API_INCIDENT_SEVERITY_CHART_LOAD:
      return { ...state, isLoading: true };

    case types.API_INCIDENT_SEVERITY_CHART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        severityData: payload.data.data,
        totalHigh: payload.data.totalHigh,
        totalIncidents: payload.data.totalIncidents,
      };

    //supply chain list
    case types.API_GET_SUPPLY_CHAIN_LIST_LOAD:
      return { ...state, isLoading: true };

    case types.API_GET_SUPPLY_CHAIN_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companies: payload.data.data,
        pagination: payload.data.pagination,
      };

    case types.API_GET_SUPPLY_CHAIN_HISTORY_LOAD:
      return { ...state, isLoading: true };

    case types.API_GET_SUPPLY_CHAIN_HISTORY_SUCCESS:
      return { ...state, isLoading: false, historyData: payload.data };

    case types.API_CLEAR_SUPPLY_CHAIN_HISTORY_LOAD:
      return { ...state, isLoading: true };

    case types.API_CLEAR_SUPPLY_CHAIN_HISTORY_SUCCESS:
      return { ...state, isLoading: false, historyData: [] };

    case types.API_GET_SUPPLY_CHAIN_FAIL:
      return { ...state, isLoading: true };

    case types.GET_COMPANY_COUNT_LOAD:
      return { ...state, isLoading: true, isSuccess: false, isError: false };

    case types.GET_COMPANY_COUNT_SUCCESS:
      return { ...state, isLoading: false, comapnyCount: payload.data.total };

    case types.API_CLEAR_SUPPLY_CHAIN_HISTORY_LOAD:
      return { ...state, isLoading: true };

    case types.FIND_PATH_LOAD:
      return { ...state, isLoading: true };
    case types.FIND_PATH_SUCCESS:
      return { ...state, isLoading: false, findPathData: payload };

    case types.UPLOAD_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        isSupplyUpload: [],
        supplyChainFileUpload: true,
      };
    case types.UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        isSupplyUpload: payload.data,
        supplyChainFileUpload: false,
      };
    case types.UPLOAD_DATA_FAIL:
      return { ...state, isLoading: false, isSuccess: false, isError: true, supplyChainFileUpload: false, };
    case types.DELET_UPLOADED_DATA_LOAD:
      return {
        ...state,
        deleteUploadedDataLoader: true,
      };
    case types.DELET_UPLOADED_DATA_SUCCESS:
      return {
        ...state,
        deleteUploadedDataLoader: false,
      };

    case types.DELET_UPLOADED_DATA_FAIL:
      return {
        ...state,
        deleteUploadedDataLoader: false,
      };
    case types.SUPPLY_CHAIN_UPLOAD_FILE_LOAD:
      return {
        ...state,
        supplyChainFileUploadList: payload,
      };
    case types.RESET_SEARCH_PAGE_DATA:
      return {
        ...state,
        pagination: {},
        companies: [],
        countryList: [],
        resultData: {},
        industries: [],
        totalIncidents: 0,
        severityData: {},
        totalHigh: 0,
        riskCharts: {},
        riskCompany: [],
      };
    default:
      return { ...state };
  }
};
