import React from "react";
import ReactApexChart from "react-apexcharts";
import IncidentsListModal from "../../Screens/IncidentsListModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

const BubbleChart = ({ height, width, options, disableClick, from }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [specificIncidentIds, setSpecificIncidentIds] = useState([]);
  const isLoading = useSelector((state) => state?.incidentReducer?.isLoading);
  // if(from !=="incidentdetails"){
  //   // delete options.options.tooltip
  // }

  if (!disableClick) {
    options.options.chart.events = {
      dataPointSelection: (_event, _chartContext, selectedInfo) => {
        const selected =
          options?.series[selectedInfo.seriesIndex]?.data[
            selectedInfo.dataPointIndex
          ];
        if (selected && selected[3]) {
          if (
            !document.querySelector("div.ant-modal-wrap.ant-modal-centered")
          ) {
            // check if any modal not already open
            setSpecificIncidentIds(
              selected[3].map((e) => e.id || e.incidentId)
            );
            setModalVisible(true);
          }
        }
      },
    };
  }

  const handleMarkerClick = () => {};
  return (
    <div>
      <ReactApexChart
        options={options.options}
        series={options.series}
        type="bubble"
        height={height}
        width={width}
        events={{
          markerClick: handleMarkerClick,
        }}
      />
      {modalVisible && (
        <IncidentsListModal
          setModalVisible={setModalVisible}
          networkData={{ specificIncidentIds }}
        />
      )}
    </div>
  );
};

export function EchartsBubbleChart({
  environmentData,
  socialData,
  governanceData,
  onClick,
}) {
  if (environmentData === undefined)
    return <div style={{ height: "350px", width: "100%" }} />;
  const data = [environmentData, socialData, governanceData];
  const xTicks = environmentData.map((item) => {
    const { start, end } = item;
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculate the midpoint in time
    const midTime = new Date((startDate.getTime() + endDate.getTime()) / 2);

    // Format the date as needed (here, we're just extracting the date part, you can adjust formatting as desired)
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const midDate = midTime.toLocaleDateString("en-CA", options); // 'en-CA' format is YYYY-MM-DD

    return midDate;
  });

  const categories = ["Environment", "Social", "Governance"];

  // Define colors for each category
  const categoryColors = {
    Environment: "rgba(110, 189, 31, 0.85)",
    Social: "rgba(74, 146, 251, 0.85)",
    Governance: "rgba(101, 61, 212, 0.85)",
  };

  const mapDataToSeries = (data, categoryIndex) =>
    data.map((item, index) => ({
      value: [
        index,
        categoryIndex,
        item.incidents.length,
        item.start,
        item.end,
      ],
      itemStyle: {
        color: Object.values(categoryColors)[categoryIndex], // Assign color based on category
      },
    }));

  // Combine all incidents into a single series array, while assigning a fixed y-value based on category
  const seriesData = [
    ...mapDataToSeries(environmentData, 0), // Environment category at y=0
    ...mapDataToSeries(socialData, 1), // Social category at y=1
    ...mapDataToSeries(governanceData, 2), // Governance category at y=2
  ];

  const option = {
    tooltip: {
      position: "top",
      formatter: function (params) {
        const start = params.value[3].split("T");
        const end = params.value[4].split("T");
        return `${params.value[2]} ${categories[params.value[1]]} incidents between ${start[0]} and ${end[0]}`;
      },
    },
    grid: {
      left: "5%", // Adjust space on the left for the y-axis labels
      right: "5%", // Adjust space on the right if needed
      top: "1%", // Reduce space at the top to increase plot (and thus y-axis) size
      bottom: "5%", // Reduce space at the bottom to increase plot size
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xTicks,
    },
    yAxis: {
      type: "category",
      data: categories,
      show: false, // Hide Y-axis
    },
    series: [
      {
        name: "Incidents",
        type: "scatter",
        data: seriesData,
        symbolSize: function (data) {
          return Math.log(Math.sqrt(data[2]) * 17 + 1) * 4; // Adjust bubble size
        },
        label: {
          show: true, // Show the label
          formatter: function (params) {
            return params.value[2]; // Display the value at the center of the bubble
          },
          position: "inside", // Position the label inside the bubble
          color: "#fff", // Optional: choose a text color that contrasts well with the bubble color
          fontSize: 10, // Optional: adjust text size as needed
          fontWeight: "bold", // Make label text bold
        },
      },
    ],
    graphic: categories
      .slice()
      .reverse()
      .map((category, index) => ({
        type: "text",
        left: "1.5%",
        top: `${index * 30 + 13}%`, // Adjust positioning according to your needs
        style: {
          text: category,
          fill: categoryColors[category],
          font: "bold 14px Arial",
        },
        silent: true, // This makes the graphic item non-interactive
      })),
  };
  const onChartClick = (params) => {
    const ids = data?.[params.value[1]]?.[params.value[0]]?.incidents;
    onClick?.(ids);
  };
  // Define event handlers
  const onEvents = {
    // Adapt this part to handle clicks appropriately for your data
    click: onChartClick,
  };

  return (
    seriesData && (
      <ReactECharts
        echarts={echarts}
        option={option}
        notMerge={true}
        lazyUpdate={true}
        onEvents={onEvents}
        style={{ height: "350px", width: "100%" }}
      />
    )
  );
}

export default BubbleChart;
