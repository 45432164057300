import React, { useState } from "react";
import { Row, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  filterESGMapDataAction,
  filterSevertyMapDataAction,
} from "../../Redux/Action/incidents";

const ToggleButton = ({ primaryColor, text, onSelect, isVisible, onClick, disabled }) => {
  const [isToggled, setIsToggled] = useState(false);
  const dispatch = useDispatch();
  const { googleMapViewState, filterESGMapData, filterSevertyMapData } =
    useSelector((state) => state?.incidentReducer);
  const handleToggle = () => {
    setIsToggled(!isToggled);
    if (googleMapViewState === "ESG Category") {
      const payload = {
        env:
          text == "Environment" ? !filterESGMapData.env : filterESGMapData.env,
        soc: text == "Social" ? !filterESGMapData.soc : filterESGMapData.soc,
        gov:
          text == "Governance" ? !filterESGMapData.gov : filterESGMapData.gov,
      };
      dispatch(filterESGMapDataAction(payload));
      onClick(payload, "ESG_Data");
    } else {
      const payload = {
        critical:
          text == "Critical"
            ? !filterSevertyMapData.critical
            : filterSevertyMapData.critical,
        high:
          text == "High"
            ? !filterSevertyMapData.high
            : filterSevertyMapData.high,
        low:
          text == "Minor" ? !filterSevertyMapData.low : filterSevertyMapData.low,
        med:
          text == "Moderate"
            ? !filterSevertyMapData.med
            : filterSevertyMapData.med,
      };
      dispatch(filterSevertyMapDataAction(payload));
      onClick(payload, "Severty_Data");
    }

    // onSelect()
  };

  const color = {
    Environment: "#6EBD20",
    Social: "#2191FB",
    Governance: "#653DD4",
    Critical: "#800020",
    High: "#D00000",
    Moderate: "#F28C28",
    Minor: "#FFC000",
  };
  return (
    <Row style={{ marginBottom: 10 }}>
      <div className="eniv-switch-th">
        <Switch
          onClick={() => handleToggle()}
          checked={isVisible}
          disabled={disabled}
          className="md-toggle-btn"
          style={{
            justifyContent: "center",
            background: isVisible ? color[text] : null,
          }}
        />
        <p
          style={{
            fontSize: 12,
            fontWeight: "normal",
            marginLeft: 5,
          }}
        >
          {text}
        </p>
      </div>
    </Row>
  );
};

export default ToggleButton;
