import { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { esgBarMapping } from "../../../Utils";
import IncidentsListModal from "../../IncidentsListModal";

const convertAllData = (data) => {
  const result = {};
  for (const category in data) {
    if (data.hasOwnProperty(category)) {
      const initial = category.charAt(0).toUpperCase();
      if (!result[initial]) {
        result[initial] = [];
      }

      for (const severityLevel in data[category]) {
        if (data[category].hasOwnProperty(severityLevel)) {
          data[category][severityLevel].forEach((item) => {
            const newItem = {
              _id: item._id,
              id: item.id,
              title: item.title,
              esg_categories: item.esg_categories,
              severity: {
                level: item.severity.level,
              },
            };
            result[initial].push(newItem);
          });
        }
      }
    }
  }
  return result;
};

const IncidentsNumberGraph = ({ allData }) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [specificIncidentIds, setSpecificIncidentIds] = useState([]);
  let data = convertAllData(allData);
  const options = useMemo(
    () => ({
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          dataPointSelection: (event, chartContext, selectedInfo) => {
            let ids = selectedInfo?.w?.config?.series?.[0].data[selectedInfo.dataPointIndex].data?.map((e) => e.id);
            if(!document.querySelector("div.ant-modal-wrap.ant-modal-centered")){ // check if any modal not already open
              setSpecificIncidentIds(ids)
              setModalVisible(true)
            }
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      yaxis: {
        tickAmount: 6,
        labels: {
          style: {
            colors: "#D2D7E5",
          },
        },
      },
      legend: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityTo: 0.6,
          opacityFrom: 1,
          stops: [0, 90, 100],
          shadeIntensity: 0.1,
        },
      },
      grid: {
        borderColor: "#E7E9F0",
        strokeDashArray: 10,
        padding: {
          right: 25,
          left: 15,
        },
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: ["#61A61C", "#1E85E7", "#5634B4"],
            fontSize: "12px",
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
    }),
    [allData]
  );

  return (
    <>
      <ReactApexChart
        options={options}
        series={esgBarMapping(data)}
        type="bar"
        height={200}
      />
      {modalVisible && (
        <IncidentsListModal
          networkData={{ specificIncidentIds }}
          setModalVisible={setModalVisible}
        />
      )}
    </>
  );
};

export default IncidentsNumberGraph;
