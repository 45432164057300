const ImageConst = {
  logo: require("../Assets/Images/logo.png"),
  google: require("../Assets/Images/google.svg").default,
  envelop: require("../Assets/Images/envelope-pana.svg").default,
  sidelogo: require("../Assets/Images/sidelogo.svg").default,
  iconone: require("../Assets/Images/Iconone.svg").default,
  backerrow: require("../Assets/Images/chevron-left.svg").default,
  // leftSide:require("../Assets/Images/mesh.png"),
  leftSide1: require("../Assets/Images/leftSide1.png"),
  leftSide2: require("../Assets/Images/leftSide2.png"),
  leftSide3: require("../Assets/Images/leftSide3.png"),
  leftSide4: require("../Assets/Images/leftSide4.png"),
  icontwo: require("../Assets/Images/supply-chain.svg").default,
  supplySide: require("../Assets/Images/supply-chain-side.svg").default,
  incidentAnalyzer: require("../Assets/Images/incident-analyzer.svg").default,
  userProfile: require("../Assets/Images/user-profile.svg").default,
  iconEarthGreen: require("../Assets/icon/earthGreen2.svg").default,
  chevronDown: require("../Assets/icon/chevronDown.svg").default,
  greenGloabe: require("../Assets/icon/greenGloabe.png"),
  blueSocial: require("../Assets/icon/blueSocial.png"),
  purplebank: require("../Assets/icon/purpleBank.png"),
  blueTile: require("../Assets/icon/blue_reatangle.png"),
  greenTile: require("../Assets/icon/green_rectangle.png"),
  purpleTile: require("../Assets/icon/purple_rectangle.png"),
  iconEarthGreen2: require("../Assets/icon/earth_green.png"),
  trashIcon: require("../Assets/icon/trash.svg").default,
  FrameTick: require("../Assets/icon/Frame_tick.svg").default,
  dangerIcon: require("../Assets/icon/danger.svg").default,
  plusLargeWhite: require("../Assets/icon/plus-large.svg").default,
  ic_social: require("../Assets/icon/soc_ic.png"),
  enviromentIcon: require("../Assets/icon/enviromentIcon.png"),
  governaceIcon: require("../Assets/icon/governaceIcon.png"),
  socialIcon: require("../Assets/icon/socialIcon.png"),
  redBullet: require("../Assets/icon/redBullet.png"),
  yellowBullet: require("../Assets/icon/yellowBullet.png"),
  orangeBullet: require("../Assets/icon/orangeBullet.png"),
  potfolio: require("../Assets/Images/potfolioIc.png"),
  ic_social_black: require("../Assets/icon/social_black_ic.png"),
  ic_gov: require("../Assets/icon/gov.png"),
  iconthree: require("../Assets/Images/file-bookmark-alt.svg").default,
  iconfour: require("../Assets/Images/bookmark M.svg").default,
  bell: require("../Assets/Images/bell.svg").default,
  gear: require("../Assets/Images/gear.svg").default,
  frame: require("../Assets/Images/frame.svg").default,
  blueGrid: require("../Assets/Images/blueGrid.png"),
  file: require("../Assets/Images/file-alt.svg").default,
  buil: require("../Assets/Images/building-NGO.svg").default,
  feather: require("../Assets/Images/FeatherIcon.svg").default,
  circle: require("../Assets/Images/circle-bolt.svg").default,
  circleInfo: require("../Assets/Images/circle-information.svg").default,
  Alert: require("../Assets/icon/Alert.svg").default,
  Alert2: require("../Assets/icon/Alert2.svg").default,
  Alert3: require("../Assets/icon/Alert3.svg").default,
  Alert4: require("../Assets/icon/Alert4.svg").default,
  felame: require("../Assets/Images/felame.svg").default,
  search: require("../Assets/Images/search.svg").default,
  server: require("../Assets/Images/circle-bolt-ser.svg").default,
  iconser: require("../Assets/Images/Icon-arrow.svg").default,
  blue: require("../Assets/Images/rectangle.svg").default,
  green: require("../Assets/Images/rectangle-green.svg").default,
  orange: require("../Assets/Images/rectangle-orange.svg").default,
  red: require("../Assets/Images/rectangle-red.svg").default,
  brightRed: require("../Assets/Images/brightRed.svg").default,
  skyblue: require("../Assets/Images/rectangle-skyblue.svg").default,
  hotel: require("../Assets/Images/hotel.svg").default,
  flag: require("../Assets/Images/flag.svg").default,
  left: require("../Assets/Images/chevron-left-double.svg").default,
  framedow: require("../Assets/Images/frame-dow.svg").default,
  columns: require("../Assets/Images/columns-3.svg").default,
  sear: require("../Assets/Images/search-table.svg").default,
  sort: require("../Assets/Images/sort.svg").default,
  iconhr: require("../Assets/Images/Icon-hr.svg").default,
  flieIcon: require("../Assets/Images/file-icon.svg").default,
  featherIcon: require("../Assets/Images/FeatherSm.svg").default,
  bulding: require("../Assets/Images/building-block.svg").default,
  gearSett: require("../Assets/Images/gear-sett.svg").default,
  chevron: require("../Assets/Images/chevron-left-er.svg").default,
  chevronRighta: require("../Assets/Images/chevron-right.svg").default,
  ellipse: require("../Assets/Images/ellipse.svg").default,
  apple: require("../Assets/Images/apple.svg").default,
  hotelBlue: require("../Assets/Images/hotel-blue.svg").default,
  clock: require("../Assets/Images/clock-two-thirty.svg").default,
  location: require("../Assets/Images/location-pin.svg").default,
  icon: require("../Assets/Images/Icon-icn.svg").default,
  supplytwo: require("../Assets/Images/supply-chain-two.svg").default,
  esgCate: require("../Assets/Images/grid-sec.svg").default,
  globe: require("../Assets/Images/globe-se.svg").default,
  triangle: require("../Assets/Images/triangle.svg").default,
  info: require("../Assets/Images/circle-information-sec.svg").default,
  arrowRight: require("../Assets/Images/arrow-right.svg").default,
  arrowDown: require("../Assets/Images/arrow-down.svg").default,
  steve: require("../Assets/Images/steve-pic.svg").default,
  twitterLogo: require("../Assets/Images/twitter-bg-blue.svg").default,
  message: require("../Assets/Images/message-circle.svg").default,
  retweet: require("../Assets/Images/retweet.svg").default,
  like: require("../Assets/Images/heart.svg").default,
  fileAlt: require("../Assets/Images/file-alt-sec.svg").default,
  builBlock: require("../Assets/Images/buil-block.svg").default,
  save: require("../Assets/Images/save.svg").default,
  share: require("../Assets/Images/share.svg").default,
  linkedin: require("../Assets/Images/linkedin.svg").default,
  copy: require("../Assets/Images/copy.svg").default,
  plus: require("../Assets/Images/plus.svg").default,
  bookmark: require("../Assets/Images/bookmark.svg").default,
  sortTab: require("../Assets/Images/sort-tab.svg").default,
  gray: require("../Assets/Images/gray.svg").default,
  locationIcon: require("../Assets/Images/location-icon.svg").default,
  supply: require("../Assets/Images/supply-chain-logo.svg").default,
  chart: require("../Assets/Images/chart-network.svg").default,
  password: require("../Assets/Images/shield-keyhole.svg").default,
  mailOpen: require("../Assets/Images/mail-open-arrow-down.svg").default,
  settingIcons: require("../Assets/Images/settings-icons.svg").default,
  settingBtn: require("../Assets/Images/setting-btn.svg").default,
  list: require("../Assets/Images/list-check.svg").default,
  profpic: require("../Assets/Images/propic.svg").default,
  userprof: require("../Assets/Images/user-prof.svg").default,
  global: require("../Assets/Images/global.svg").default,
  emailinfo: require("../Assets/Images/mail-noti.svg").default,
  traingle: require("../Assets/Images/alert.svg").default,
  right: require("../Assets/Images/right.svg").default,
  rightGray: require("../Assets/Images/right-gray.svg").default,
  star: require("../Assets/Images/star.svg").default,
  starinfo: require("../Assets/Images/info-icon-alt.svg").default,
  trash: require("../Assets/Images/trash.svg").default,
  dots: require("../Assets/Images/dots-horizontal.svg").default,
  edit: require("../Assets/Images/pen.svg").default,
  deleteRed: require("../Assets/Images/delete.svg").default,
  deleteRedFill: require("../Assets/Images/deleteFill.svg").default,
  cross: require("../Assets/Images/eye.svg").default,
  deletError: require("../Assets/Images/delete-error.svg").default,
  grayinfo: require("../Assets/Images/gray-info.svg").default,
  graygrid: require("../Assets/Images/gray-grid.svg").default,
  squarePen: require("../Assets/Images/square.svg").default,
  bank: require("../Assets/Images/bank.svg").default,
  users: require("../Assets/Images/users.svg").default,
  social: require("../Assets/Images/Social.svg").default,
  brief: require("../Assets/Images/briefcase.svg").default,
  starSharp: require("../Assets/Images/star-sharp.svg").default,
  digram: require("../Assets/Images/diagram.svg").default,
  indust: require("../Assets/Images/industry-windows.svg").default,
  plusWhite: require("../Assets/Images/plus-white.svg").default,
  check: require("../Assets/Images/check.svg").default,
  darkblue: require("../Assets/Images/darkblue.svg").default,
  yellow: require("../Assets/Images/yellow.svg").default,
  pup: require("../Assets/Images/pup.svg").default,
  empty: require("../Assets/Images/empty.png"),
  update: require("../Assets/Images/refresh.svg").default,
  clockGreen: require("../Assets/Images/clock.svg").default,
  ellipseGreen: require("../Assets/Images/ellipse-green.svg").default,
  ellipseblue: require("../Assets/Images/ellipse-blue.svg").default,
  ellipsebng: require("../Assets/Images/ellipse-bng.svg").default,
  ellipseNgo: require("../Assets/Images/ellipseNGO.svg").default,
  ellipseTwitter: require("../Assets/Images/ellipseTwitter.svg").default,
  ellipsePage: require("../Assets/Images/ellipsePage.svg").default,
  profile: require("../Assets/Images/profile.svg").default,
  chevronRightWhite: require("../Assets/Images/chevron-right-white.svg")
    .default,
  Apple: require("../Assets/Images/apple-logo.svg").default,
  globeblue: require("../Assets/Images/glob-blue.svg").default,
  socialblue: require("../Assets/Images/social-blue.svg").default,
  bankblue: require("../Assets/Images/bankblue.svg").default,
  uplode: require("../Assets/Images/uplode.svg").default,
  twitterBlack: require("../Assets/Images/twitter-black.svg").default,
  fileAltGray: require("../Assets/Images/file-alt-gray.svg").default,
  ngo: require("../Assets/Images/ngo.svg").default,
  circlered: require("../Assets/Images/circle-red.svg").default,
  worldmap: require("../Assets/Images/worldmap.png"),
  cricleOrange: require("../Assets/Images/cricle-orange.svg").default,
  cricleYellow: require("../Assets/Images/cricle-yellow.svg").default,
  ellipseGrey: require("../Assets/Images/ellipseGrey.svg").default,
  ellipseLightBlue: require("../Assets/Images/ellipseLightBlue.svg").default,
  ellipseOrange: require("../Assets/Images/ellipseOrange.svg").default,
  ellipseRed: require("../Assets/Images/ellipseRed.svg").default,
  ellipseYellow: require("../Assets/Images/ellipseYellow.svg").default,
  digramBlue: require("../Assets/Images/diagramblue.svg").default,
  ellipseBlack: require("../Assets/Images/ellipseBlack.svg").default,
  xmark: require("../Assets/Images/xmark.svg").default,
  gauge: require("../Assets/Images/gauge.svg").default,
  hexMap: require("../Assets/Images/hex-map.png"),
  greencricle: require("../Assets/Images/geencricle.svg").default,
  ellipsedarkgreen: require("../Assets/Images/ellipsedarkgreen.svg").default,
  eyeOpen: require("../Assets/Images/eyeOpen.svg").default,
  fullMap: require("../Assets/Images/fullMap.svg").default,
  longline: require("../Assets/Images/long-line.svg").default,
  chartNet: require("../Assets/Images/chart-network-table.svg").default,
  applemap: require("../Assets/Images/apple-map.png"),
  darkGreen: require("../Assets/Images/darkGreen.svg").default,
  ic_gmail: require("../Assets/Images/ic_gmail.svg").default,
  newTopBg: require("../Assets/Images/newTopBg.jpeg").default,
  calIc: require("../Assets/Images/cal.svg").default,
  closeEye: require("../Assets/Images/closeEye.svg").default,
  closeIcon: require("../Assets/Images/closeIcon.svg").default,
  sortDec: require("../Assets/Images/sortDec.svg").default,
  sortAcc: require("../Assets/Images/sortAcc.svg").default,
  whiteSearch: require("../Assets/Images/whiteSearch1.svg").default,
  upArrowList: require("../Assets/Images/upArrowList.svg").default,
  downArrowList: require("../Assets/Images/downArrowList.svg").default,
  PlushBlack: require("../Assets/Images/PlushBlack.svg").default,
  cross: require("../Assets/Images/cross.svg").default,
  Environment: require("../Assets/icon/Icon.png"),
  Environment_black: require("../Assets/icon/Icon (2).png"),
  social: require("../Assets/icon/Subtract.png"),
  social_black: require("../Assets/icon/SubtractBlack.png"),
  bank_black: require("../Assets/icon/bank.png"),
  bank: require("../Assets/icon/Icon (1).png"),
  filter: require("../Assets/Images/filter.png"),
  settingIcon: require("../Assets/Images/settingIcon.png"),
  searchDoc: require("../Assets/Images/searchDoc.png"),
  socialMediaIcon: require("../Assets/Images/message-square-list.svg").default,
  delete: require("../Assets/icon/delete.svg").default,
  postive: require("../Assets/Images/Postive.png"),
  negative: require("../Assets/Images/Negative.png"),
  neutral: require("../Assets/Images/neutral.png"),
  linkedin1: require("../Assets/Images/Linkedin1.svg").default,
  facebook: require("../Assets/Images/Facebook.svg").default,
  Raddit: require("../Assets/Images/Reddit.svg").default,
  XCROP: require("../Assets/Images/Twitter.svg").default,
  un: require("../Assets/Images/UN.svg").default,
  backBtn: require("../Assets/Images/backBtn.svg").default,
  arrowWhiteRight: require("../Assets/Images/arrowWhiteRight.svg").default,
  blueDownArrow: require("../Assets/Images/blueDownArrow.svg").default,
  blueUpArrow: require("../Assets/Images/blueUpArrow.svg").default,
  WhiteDeleteBox: require("../Assets/icon/whitedeletebtn.svg").default,
  plusLarge: require("../Assets/icon/plusLarge.svg").default,
  iconDown: require("../Assets/icon/arrow-down.svg").default,
  searchWhite: require("../Assets/Images/search-white.svg").default,
  sidegraph: require("../Assets/Images/sidegraph.png"),
  bullets: require("../Assets/Images/bullits.svg").default,
  supplyGraph: require("../Assets/Images/supply-chain-graph.png"),
  worldGraph: require("../Assets/Images/world-graph-image.png"),
  environmentTab: require("../Assets/Images/environment.svg").default,
  socialTab: require("../Assets/Images/social-tab.svg").default,
  governanceTab: require("../Assets/Images/governance.svg").default,
  heighSeverityTab: require("../Assets/Images/high-severity.svg").default,
  mediumSeverityTab: require("../Assets/Images/medium-severity.svg").default,
  lowSeverityTab: require("../Assets/Images/low-severity.svg").default,
  sortApple: require("../Assets/Images/short-apple-Image.svg").default,
  chartUser: require("../Assets/Images/chart-user-square.svg").default,
  compImage: require("../Assets/Images/comp-Image-box.svg").default,
  officeImage: require("../Assets/Images/office-Image.svg").default,
  cityImage: require("../Assets/Images/city-Image.svg").default,
  enevImage: require("../Assets/Images/enev-Image.svg").default,
  editTextIcon: require("../Assets/Images/edit-text-icon.svg").default,
  arrowSumUp: require("../Assets/Images/arrow-sm-up.svg").default,
  worning: require("../Assets/Images/worning.svg").default,
  icondown: require("../Assets/Images/gray-errow-btn.svg").default,
  low: require("../Assets/icon/low.png"),
  high: require("../Assets/icon/high.png"),
  regulatorLegal: require("../Assets/Images/Regulator&Legal.svg").default,
  sortButton: require("../Assets/Images/SortButton.svg").default,
  addimage: require("../Assets/Images/plus-large.svg").default,
  whiteArrowDn: require("../Assets/Images/chevron-downSvg.svg").default,
  whiteLikeBtn: require("../Assets/Images/whiteLikeBtn.svg").default,
  whiteDislikeBtn: require("../Assets/Images/whiteDislikeBtn.svg").default,
  auction: require("../Assets/Images/auction.svg").default,
  aiIcon: require("../Assets/Images/aiIcon.svg").default,
  sunBlast: require("../Assets/Images/sun-blast.png"),
  fillenv: require("../Assets/Images/fillenv.svg").default,
  fillSocial: require("../Assets/Images/fillSocial.svg").default,
  fillGov: require("../Assets/Images/fillGov.svg").default,
  whitDots: require("../Assets/icon/whitDots.svg").default,
  penSquare: require("../Assets/icon/pen-square.svg").default,
  chevronUp: require("../Assets/icon/chevronUp.svg").default,
  crossToast: require("../Assets/icon/cross.svg").default,
  tickGreen: require("../Assets/icon/tickGreen.svg").default,
  openIcon: require("../Assets/icon/openIcon.svg").default,
  postRep: require("../Assets/Images/post-replies-map.png"),
  appleFaceIcon: require("../Assets/Images/apple-face-icon.svg").default,
  appleMedium: require("../Assets/Images/appleMedium.svg").default,
  socialLink: require("../Assets/Images/socialLink.svg").default,
  appleLogoW: require("../Assets/Images/appleLogoW.svg").default,
  appleLow: require("../Assets/Images/appleLow.svg").default,
  overViewlogo: require("../Assets/Images/over-view-logo.svg").default,
  twitterIconMedia: require("../Assets/Images/twitter-icon-media.svg").default,
  pieChart: require("../Assets/Images/pie-chart.png"),
  facebookIconMedia: require("../Assets/Images/facebook-icon-media.svg")
    .default,
  redditIconMedia: require("../Assets/Images/reddit-icon-media.svg").default,
  linkdinIconMediaps: require("../Assets/Images/link-din-icon-media.svg")
    .default,
  keyWords: require("../Assets/Images/key-words.png"),
  xIcon: require("../Assets/Images/x-icon.svg").default,
  comment: require("../Assets/Images/comment.svg").default,
  sortIcon: require("../Assets/Images/sortsicons.svg").default,
  avatarTwitter: require("../Assets/Images/avatar-twitter.svg").default,
  playicon: require("../Assets/Images/play-icon.svg").default,
  avatarTwo: require("../Assets/Images/avatar-two.svg").default,
  picAvatar: require("../Assets/Images/pic-avatar.svg").default,
  starBadge: require("../Assets/Images/star-badge.svg").default,
  appleImage: require("../Assets/Images/apple-image-media.png"),
  rebeccaPro: require("../Assets/Images/rebecca.svg").default,
  googleofficeImage: require("../Assets/Images/google-office-ps.png"),
  likeBtnPs: require("../Assets/Images/like-btn-ps.svg").default,
  linkdinIconMedia: require("../Assets/Images/linkdin-icon-media.svg").default,
  searchMediaps: require("../Assets/Images/search-media-ps.svg").default,
  appleofficemedia: require("../Assets/Images/apple-office-media.png"),
  xcroptwitter: require("../Assets/Images/x-crop-twitter.svg").default,
  linkdinLineChart: require("../Assets/Images/linkdin-line-chart.svg").default,
  sideLineChart: require("../Assets/Images/side-line-chart.svg").default,
  radditiconsPs: require("../Assets/Images/reddit-icon-ps.svg").default,
  radditiconssecps: require("../Assets/Images/reddit-icon-sec-ps.svg").default,
  fannews: require("../Assets/Images/fannews.svg").default,
  amazoneOffice: require("../Assets/Images/anazone-ps.png"),
  radditChart: require("../Assets/Images/raddit-graph.svg").default,
  radditlogops: require("../Assets/Images/raddit-logo-ps.svg").default,
  whiteUploade: require("../Assets/Images/white-upload.svg").default,
  applesupply: require("../Assets/Images/apple-supply.svg").default,
  dotsVertical: require("../Assets/Images/dots-vertical.svg").default,
  amazoneSupply: require("../Assets/Images/amazone-supply.svg").default,
  googleSupply: require("../Assets/Images/google-supply.svg").default,
  breadcrumbsSl: require("../Assets/Images/breadcrumbs-sl.svg").default,
  supplyApple: require("../Assets/Images/supply-apple.svg").default,
  greenArrowSumUp: require("../Assets/Images/green-arrow-sm-down.svg").default,
  ellipseImage: require("../Assets/Images/ellipse-dig.png"),
  boltSupply: require("../Assets/Images/bolt-supply.svg").default,
  lineChartSupply: require("../Assets/Images/line-chart.png"),
  donutChart: require("../Assets/Images/Donut-chart.png"),
  searchIcons: require("../Assets/Images/search-md.svg").default,
  chevronRight: require("../Assets/Images/chevron-right.svg").default,
  InfoSmall: require("../Assets/icon/Info.svg").default,
  columnsImages: require("../Assets/Images/columns-3.svg").default,
  tableGraphImage: require("../Assets/Images/table-graph-images-line.png"),
  searchHotel: require("../Assets/Images/search-hotel.svg").default,
  downloadFile: require("../Assets/Images/downloads-file-ps.svg").default,
  industryWindows: require("../Assets/Images/industry-windows.svg").default,
  closeIconslect: require("../Assets/Images/Icon-cross-slect.svg").default,
  checkGrey: require("../Assets/icon/checkGrey.svg").default,
  XCROPNEW: require("../Assets/Images/XCorp.png"),
  linkedinNew: require("../Assets/Images/linkdinNew.png"),
  plusGrey: require("../Assets/Images/plusGrey.png"),
  lineImg: require("../Assets/Images/line.png"),
  avatarTwitter1: require("../Assets/Images/avatarTwitter1.svg").default,
  framedow1: require("../Assets/Images/collectionDownload.svg").default,
  chartNetworkTable: require("../Assets/Images/chart-network-table.svg")
    .default,
  indusBlue: require("../Assets/Images/indus.svg").default,
  sortTableIcon: require("../Assets/Images/sort-table-icon.svg").default,
  chartUserSqure: require("../Assets/Images/chart-user-square-table.svg")
    .default,
  rectangErrow: require("../Assets/Images/rectangle-errow.png"),
  corporateLogo: require("../Assets/Images/corporate-logo.svg").default,
  graphIcons: require("../Assets/Images/graphIcons.svg").default,
  filesList: require("../Assets/Images/table-list.svg").default,
  starIcons: require("../Assets/Images/star-icons.svg").default,
  breachesIcons: require("../Assets/Images/breaches-icons.svg").default,
  pia: require("../Assets/Images/pia-icons.svg").default,
  sdg: require("../Assets/Images/sdg-icons.svg").default,
  unGroup: require("../Assets/Images/un-group-Icons.svg").default,
  colorSky: require("../Assets/Images/color-sky.svg").default,
  newImageOnHover: require("../Assets/Images/Info-hover-icon.svg").default,

  // Map Icon
  // envImage: require("../Assets/icon/Env.node.png").default,
  // socialImage: require("../Assets").default,
  // govImage: require("../Assets/icon/Env. node.png").default,

  goal1: require("../Assets/Images/noPoverity.svg").default,
  goal2: require("../Assets/Images/zeroHunger.svg").default,
  goal3: require("../Assets/Images/goodHealthAndWellBeing.svg").default,
  goal4: require("../Assets/Images/qualityEducation.svg").default,
  goal5: require("../Assets/Images/genderEquality.svg").default,
  goal6: require("../Assets/Images/cleanWaterAndSanitation.svg").default,
  goal7: require("../Assets/Images/affordableAndCleanEnergy.svg").default,
  goal8: require("../Assets/Images/decentWorkAndEcoGrow.svg").default,
  goal9: require("../Assets/Images/industryInnovationAndInfra.svg").default,
  goal10: require("../Assets/Images/reducedInequalities.svg").default,
  goal11: require("../Assets/Images/sustainableCitiesAndComm.svg").default,
  goal12: require("../Assets/Images/responsibleConsumption&Production.svg")
    .default,
  goal13: require("../Assets/Images/climateAction.svg").default,
  goal14: require("../Assets/Images/lifeBelowWater.svg").default,
  goal15: require("../Assets/Images/lifeAndLand.svg").default,
  goal16: require("../Assets/Images/peaceAndJusticeStrongInst.svg").default,
  goal17: require("../Assets/Images/partnershipForTheGoal.svg").default,
  newImageOnHover: require("../Assets/Images/Info.png"),
  infoRed: require("../Assets/Images/infoRed.svg").default,
  errorIcons: require("../Assets/Images/error-icons.svg").default,
  chickIcons: require("../Assets/Images/check-icon-se.svg").default,
  fullScreen: require("../Assets/Images/FullScreen.svg").default,
  validPng: require("../Assets/Images/Valid.png"),
  invalidPng: require("../Assets/Images/invalid.png"),
  plusBtnNew: require("../Assets/Images/plusBtnNew.png"),
  removeBtnNew: require("../Assets/Images/removeBtnNew.png"),
  editIcon: require("../Assets/Images/editIcon.png"),
  portfolioInfo: require("../Assets/Images/portfolioInfo.png"),
  uploadIcon: require("../Assets/icon/uploadIcon.png"),
  trashIcon1: require("../Assets/icon/trashIcon.png"),
};
export default ImageConst;
