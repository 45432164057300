import moment from "moment";
import ImageConst from "../Themes/ImageConstant";
import { structureData } from "./chartCommandFunction";

export const generateData = (baseval, count, yrange) => {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

    series.push([x, y, z]);
    baseval += 86400000;
    i++;
  }
  return series;
};

export const generateData2 = (baseval, count, yrange) => {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

    series.push([x, y, z]);
    baseval += 86400000;
    i++;
  }
  return series;
};

export const scrollTo = (val) => {
  window.scroll(0, val);
};
export const scrollationCompany = (val) => {
  window.scroll(0, val * 100);
};
export const storage = {
  saveSession: (keyName, data) => sessionStorage.setItem(keyName, data),
  saveLocal: (keyName, data) => localStorage.setItem(keyName, data),
  getSession: (keyName) => sessionStorage.getItem(keyName),
  getLocal: (keyName) => localStorage.getItem(keyName),
  clearStorage: () => {
    localStorage.clear();
    sessionStorage.clear();
  },
};

export const getCurrentDate = (data) => {
  const currentDate = new Date();
  if (data === 1) {
    return currentDate.toISOString().slice(0, 10);
  } else {
    return currentDate;
  }
};

export const getToken = () => {
  const token = storage.getLocal("token")
    ? storage.getLocal("token")
    : storage.getSession("token");
  return token;
};

export const getDiffTime = (time) => {
  const current = moment();
  const diffTime = moment(time);
  const timeDiff = current.diff(diffTime, "seconds");

  return timeDiff < 60
    ? timeDiff + " second ago"
    : timeDiff < 3600
      ? moment(timeDiff).minutes() + " minutes ago"
      : timeDiff < 86400
        ? moment(timeDiff).hour() + " hours ago"
        : moment().subtract(timeDiff, "seconds").format("MMM. D, YYYY");
};

export const getDiffTimeNew = (time) => {
  return moment(time).fromNow();
};

export const ToUpperCase = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1).toLowerCase();
};

export const whiteMapOptions = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      { hue: "#EFF1F6" }, // Change the hue to the desired color
      { saturation: 100 }, // Adjust the saturation as needed
      { lightness: 0 }, // Adjust the lightness as needed
    ],
  },
];

//

export const dateButtonObj = [
  {
    type: "24Hour",
    title: "Last 24 hours",
    
  },
  {
    type: "lastWeek",
    title: "Last week",
  },
  {
    type: "LastMonth",
    title: "Last Month",
  },
  {
    type: "Last_3_months",
    title: "Last 3 months",
  },
  {
    type: "Last_6_months",
    title: "Last 6 months",
  },
  {
    type: "Last_year",
    title: "Last year",
  },
];

export function calculatePreviousDate(startDate, span) {
  // return the last date
  // span = 24Hours | lastWeek ...
  const obj = {
    "24Hour": () => new Date(startDate.setDate(startDate.getDate() - 1)),
    lastWeek: () => new Date(startDate.setDate(startDate.getDate() - 7)),
    LastMonth: () => new Date(startDate.setDate(startDate.getDate() - 30)),
    Last_3_months: () => new Date(startDate.setMonth(startDate.getMonth() - 3)),
    Last_6_months: () => new Date(startDate.setMonth(startDate.getMonth() - 6)),
    Last_year: () =>
      new Date(startDate.setFullYear(startDate.getFullYear() - 1)),
  };
  return obj[span]();
}

export const chartMapping = (data) => {
  const finalData = {};
  if (data) {
    for (let i of Object.keys(data)) {
      for (let j of Object.keys(data[i])) {
        if (finalData[j]) {
          const temp = [...finalData[j]];
          temp.push(data[i][j]);
          finalData[j] = temp;
        } else {
          const temp = [];
          temp.push(data[i][j]);
          finalData[j] = [...temp];
        }
      }
    }
    return Object.entries(finalData).map((item) => ({ data: item[1] }));
  }
};

export const getSentimentValue = (data) => {
  if (data) {
    if (data > 0) {
      return "Positive";
    } else if (data < 0) {
      return "Negative";
    }
  } else {
    if (data == 0) {
      return "Neutral";
    }
  }
};

export const severityBarMapping = (data) => {
  const dataEntires = Object?.entries(data);
  const log = [
    {
      data: [
        { x: "Critical", y: 0, fillColor: "#800020", data: [] },
        { x: "High", y: 0, fillColor: "#FF0000", data: [] },
        { x: "Moderate", y: 0, fillColor: "#FF7518", data: [] },
        { x: "Minor", y: 0, fillColor: "#FFBF00", data: [] },
      ],
    },
  ];
  for (let i in dataEntires) {
    for (let j in log[0].data) {
      if (log[0].data[j].x == dataEntires[i][0]) {
        log[0].data[j].y = dataEntires[i][1]?.length;
        log[0].data[j].data = dataEntires[i][1];
      }
    }
  }
  return log;
};

export const BarMapping = (data) => {
  if (data) {
    const dataEntires = [
      "Principle 1",
      "Principle 2",
      "Principle 3",
      "Principle 4",
      "Principle 5",
      "Principle 6",
      "Principle 7",
      "Principle 8",
      "Principle 9",
      "Principle 10",
    ];
    const log = [
      {
        data: [
          { x: "P1", y: 0, fillColor: "#0195CE", data: [] },
          { x: "P2", y: 0, fillColor: "#0195CE", data: [] },
          { x: "P3", y: 0, fillColor: "#653DD4", data: [] },
          { x: "P4", y: 0, fillColor: "#653DD4", data: [] },
          { x: "P5", y: 0, fillColor: "#653DD4", data: [] },
          { x: "P6", y: 0, fillColor: "#653DD4", data: [] },
          { x: "P7", y: 0, fillColor: "#6EBD20", data: [] },
          { x: "P8", y: 0, fillColor: "#6EBD20", data: [] },
          { x: "P9", y: 0, fillColor: "#6EBD20", data: [] },
          { x: "P10", y: 0, fillColor: "#2DC2BF", data: [] },
        ],
      },
    ];
    for (let i in dataEntires) {
      const obj = data?.find((item) => item._id === dataEntires[i]);

      log[0].data[i].y = obj?.incidents.length || 0;
      log[0].data[i].data = obj?.incidents || [];
    }
    return log;
  } else {
    return [];
  }
};
export const ESG = [
  { tittle: "Environment", color: "#6EBD20" },
  { tittle: "Social", color: "#2191FB" },
  { tittle: "Governance", color: "#653DD4" },
];

export const GSE = [
  { tittle: "Environment", color: "#6EBD20" },
  { tittle: "Social", color: "#2191FB" },
  { tittle: "Governance", color: "#653DD4" },
];

export const HMN = [
  { tittle: "High", color: "#D00000" },
  { tittle: "Medium", color: "#F28C28" },
  { tittle: "Neutral", color: "#FFD700" },
];
export const HML = [
  { tittle: "High", color: "#D00000" },
  { tittle: "Medium", color: "#F28C28" },
  { tittle: "Low", color: "#FFD700" },
];
export const CHMM = [
  { tittle: "Critical", color: "#800020" },
  { tittle: "High", color: "#FF0000" },
  { tittle: "Moderate", color: "#FF7518" },
  { tittle: "Minor", color: "#FFBF00" },
];
export const PNN = [
  { tittle: "Positive", color: "#1A9C3E" },
  { tittle: "Negative", color: "#D00000" },
  { tittle: "Neutral", color: "#B6BCC7" },
];

export const Timeline = [{ tittle: "ESG Factors" }];

export const HLEA = [
  { tittle: "Human Rights", color: "#0195CE" },
  { tittle: "Labour", color: "#653DD4" },
  { tittle: "Environment", color: "#6EBD20" },
  { tittle: "Anti-Corruption", color: "#2DC2BF" },
];

export const ESGT = [{ tittle: "Total", color: "#0D0D0E" }, ...ESG];

export const PNNT = [...PNN, { tittle: "Total", color: "#0D0D0E" }];
export const TPNN = [{ tittle: "Total", color: "#0D0D0E" }, ...PNN];
export const getYears = (incidentWithYear) => Object.keys(incidentWithYear);
export const convertIntObj = (obj) => {
  let res = [];
  for (const i in obj) {
    res.push(parseInt(obj[i]));
  }
  return res;
};

export const getIncidentBubbleChartData = (data, type) => {
  let series = [];
  if (data) {
    let data1 = Object.entries(data);
    series = structureData(data1, type, "");
    return series;
  }
};

export const getcategories = (data) => {
  if (data) {
    let data1 = Object.keys(data);
    // return data1
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }
};

export const checkSeverityColor = {
  HIGH: "#D00000",
  CRITICAL: "#800020",
  MODERATE: "#FF7518",
  MINOR: "#FFD700",
};

export const checkRiskColor = {
  HIGH: "#D00000",
  MEDIUM: "#F28C28",
  LOW: "#FFD700",
  SECURE: "#6EBD20",
};
export const checkColor = {
  HIGH: "#D00000",
  CRITICAL: "#800020",
  MODERATE: "#FF7518",
  MINOR: "#FFD700",
  // HIGH: "#D00000",
  // MEDIUM: "#F28C28",
  // LOW: "#FFD700",
};

export const ESGObj = {
  social: "#2191FB",
  governance: "#653DD4",
  environmental: "#6EBD20",
};

export const getIncidentValue = (countBySeverity) => {
  if (countBySeverity.HIGH > 0) {
    return "#B70000";
  } else if (countBySeverity.MEDIUM > 0) {
    return "#F28C28";
  } else if (countBySeverity.LOW > 0) {
    return "#FFD700";
  }
};

export const getColor = (key) => {
  if (key == "Environmental") {
    return "#6EBD20";
  } else if (key == "Governance") {
    return "#653DD4";
  } else if (key == "Social") {
    return "#2191FB";
  }
};

export const getColorForTable = (key) => {
  if (key == "Environmental") {
    return "#D6EDC1";
  } else if (key == "Governance") {
    return "#E0D8F6";
  } else if (key == "Social") {
    return "#C7E3FE";
  }
};

export const getColorForTableText = (key) => {
  if (key == "Environmental") {
    return "#3C6812";
  } else if (key == "Governance") {
    return "#3B237B";
  } else if (key == "Social") {
    return "#145797";
  }
};

export const getServerityMapData = (data) => {
  let mapData = [];

  for (let i in data) {
    let obj = {
      lat: data[i]?.lat,
      lng: data[i]?.lng,
      value: getIncidentValue(data[i].incidentCountBySeverity),
    };
    mapData.push(obj);
  }
  return mapData;
};

export const isDocumentOrPDF = (file) => {
  const jsonMimeType = "application/json";
  const csvMimeType = "text/csv";
  const txt = "text/plain";
  const xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  const xls = "application/vnd.ms-excel"
  const fileMimeType = file.type;
  if (fileMimeType === jsonMimeType) {
    return "json";
  } else if (fileMimeType === csvMimeType) {
    return "csv";
  }
  else if (fileMimeType == txt) {
    return 'text'
  }
  else if (fileMimeType === xls || fileMimeType === xlsx) {
    return 'xlsx'
  }
  else {
    return "other";
  }
};

export const checkArrayLengthWithTrueVal = (arr) => {
  const itemsArr = [
    arr?.alerts?.riskChange,
    arr?.alerts?.sentimentChange,
    arr?.alerts?.highSeverityChange,
  ];
  let labels = ["Risk score change", "Sentiment shift", "Severe incident"];

  // Use filter to get indices of true values
  let trueIndices = itemsArr
    .map((item, index) => (item ? index : -1))
    .filter((index) => index !== -1);

  // Use the trueIndices to get corresponding labels
  let trueLabels = trueIndices.map((index) => labels[index]);

  let modify = trueLabels.join(" / ");
  let data = {
    values: modify,
    arrayVal: trueLabels,
  };

  return data;
};

export const removeEmojis = (string) => {
  let regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  let result = string.replace(regex, "");

  return result.trimLeft();
};

export const errorToastContainer = (message, classText) => {
  return (
    <div style={{ display: "flex" }}>
      <div>
        <img
          style={{ height: "10px", width: "10px", marginRight: "20px" }}
          src={ImageConst.crossToast}
        />
      </div>
      <div
        style={{
          color: "#B70000",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "24px",
        }}
      >
        {message || ""}
      </div>
    </div>
  );
};

export const successToastContainer = (message, classText) => {
  return (
    <div style={{ display: "flex" }}>
      <div>
        <img
          style={{ height: "15px", width: "15px", marginRight: "20px" }}
          src={ImageConst.tickGreen}
        />
      </div>
      <div
        style={{
          color: "#168535",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "24px",
        }}
      >
        {message || ""}
      </div>
    </div>
  );
};

export const esgBarMapping = (data) => {
  const dataEntires = Object.entries(data);
  const log = [
    {
      data: [
        { x: "E", y: 0, fillColor: "#6EBD20", data: [] },
        { x: "S", y: 0, fillColor: "#2191FB", data: [] },
        { x: "G", y: 0, fillColor: "#653DD4", data: [] },
      ],
    },
  ];
  for (let i in dataEntires) {
    log[0].data[i].y = dataEntires[i][1]?.length;
    log[0].data[i].data = dataEntires[i][1];
  }
  return log;
};

export const getlatestDate = (data) => {
  if (data.length > 0) {
    const datetimeValues = data.map(
      (entry) => new Date(entry.datetime ? entry.datetime : entry.date)
    );
    const latestDate = new Date(Math.max(...datetimeValues)) || new Date();
    return latestDate;
  }
};

export const createSeverityScoreData = (dataArr) => {
  let chartData = [
    {
      name: "Minor",
      itemStyle: {
        color: "#FFBF00",
      },
      children: [
        {
          name: "E",
          value: 10,
          itemStyle: {
            color: "#D6EDC1",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 40,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },

        {
          name: "S",
          value: 10,
          itemStyle: {
            color: "#B1D8FE",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },

        {
          name: "G",
          value: 10,
          itemStyle: {
            color: "#C9BBF0",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },
      ],
    },
    {
      name: "Moderate",
      itemStyle: {
        color: "#FF7518",
      },
      children: [
        {
          name: "E",
          value: 10,
          itemStyle: {
            color: "#D6EDC1",
          },
          children: [
            {
              name: "",
              value: 3,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 7,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
            // {
            //   name: "",
            //   value: 1,
            //   label: {
            //     show: false,
            //     padding: 150,
            //     distance: -70,
            //   },
            // },
          ],
        },

        {
          name: "S",
          value: 10,
          itemStyle: {
            color: "#B1D8FE",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },

        {
          name: "G",
          value: 10,
          itemStyle: {
            color: "#C9BBF0",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },
      ],
    },

    {
      name: "High",
      itemStyle: {
        color: "#FF0000",
      },
      children: [
        {
          name: "E",
          value: 10,
          itemStyle: {
            color: "#D6EDC1",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 40,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },

        {
          name: "S",
          value: 10,
          itemStyle: {
            color: "#B1D8FE",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },

        {
          name: "G",
          value: 10,
          itemStyle: {
            color: "#C9BBF0",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },
      ],
    },
    {
      name: "Critical",
      itemStyle: {
        color: "#800020",
      },
      children: [
        {
          name: "E",
          value: 10,
          itemStyle: {
            color: "#D6EDC1",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },

        {
          name: "S",
          value: 10,
          itemStyle: {
            color: "#B1D8FE",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 90,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },

        {
          name: "G",
          value: 10,
          itemStyle: {
            color: "#C9BBF0",
          },
          children: [
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 0,
                distance: 60,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 30,
                distance: 40,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 60,
                distance: 20,
              },
            },
            {
              name: "",
              value: 1,
              label: {
                show: false,
                padding: 100,
                distance: -20,
              },
            },
          ],
        },
      ],
    },
  ];

  for (let i in chartData) {
    for (let j in dataArr) {
      if (chartData[i].name == dataArr[j].severity_level) {
        let Child = chartData[i].children;
        for (let k in chartData[i].children) {
          if (Child[k].name == dataArr[j].main_category.charAt(0)) {
            let subChild = Child[k].children;
            if (subChild.length == 5) {
            }
            for (let l in subChild) {
              if (subChild[l].name.length == 0) {
                subChild[l].name = dataArr[j].keyword;
                subChild[l].label.show = true;
                subChild[l].labelLine = { show: true };
                subChild[l].value = l == 0 ? 2 : l == 1 ? 4 : l == 2 ? 6 : 8;
                break;
              }
            }
          }
        }
      }
    }
  }
  return chartData;
};

export const getPercentage = (data, type, length = null) => {
  let keysArray = data
    ? Object.keys(data).length !== 0
      ? Object.values(data[type])
      : []
    : [];

  length && (keysArray = keysArray.slice(0, length));
  const total = keysArray.reduce((acc, value) => acc + value, 0);
  let res = [0, 0, 0];
  if (total > 0) {
    res = keysArray.map((item) => {
      const roundedPercentage = Math.floor(item * (100 / total));
      return roundedPercentage;
    });
  }

  return res;
};

export const formatFollowers = (val) => {
  if (
    val === "" ||
    val === null ||
    val === undefined ||
    typeof val == "boolean"
  ) {
    return "0";
  } else {
    val = parseInt(val);
    if (val >= 1000 && val < 10000) {
      return val === 1000 ? "1k" : (val / 1000).toFixed(1) + "k";
    } else if (val >= 10000 && val < 1000000) {
      return (val / 1000).toFixed(0) + "k";
    } else if (val === 1000000) {
      return "1M";
    } else if (val >= 1000000 && val < 10000000) {
      return (val / 1000000).toFixed(1) + "M";
    } else if (val >= 10000000 && val < 1000000000) {
      return (val / 1000000).toFixed(0) + "M";
    } else if (val === 1000000000) {
      return "1B";
    } else if (val >= 1000000000) {
      return (val / 1000000000).toFixed(1) + "B";
    } else {
      return val.toString();
    }
  }
};
export const ESGCatgory = {
  esg_factors: "ESG Factors",
  esg_pais: "PAI Indicators",
  esg_sdgs: "SDGs",
};

export const getFilteredArrayforBubbles = (arr, min, max, minMaxArr = []) => {
  if (arr && arr?.length == 0) return arr;
  let filteredValue = arr?.map((item) => {
    item[2] = getBubbleRange(item[2], [min, max], ...minMaxArr);
    return item;
  });

  return filteredValue;
};

export const getBubbleRange = (value, arr, min = 20, max = 35) => {
  if (arr[0] == arr[1]) return min;

  var temp = ((value - arr[0]) / (arr[1] - arr[0])) * (max - min) + min;

  return Math.round(temp);
};

export const checkColor_1 = {
  HIGH: ["#D00000", "#f3bfbf"],
  MEDIUM: ["#F28C28", "#f9d4ae"],
  LOW: ["#FFD700", "#fae79f"],
};

export const isObjEmpty = (obj1) => {
  let flag = true;
  const traverseNode = (arr, id, n, obj) => {
    if (id >= n) return;

    if (obj[arr[id]] instanceof Object) {
      traverseNode(
        Object.keys(obj[arr[id]]),
        0,
        Object.keys(obj[arr[id]]).length,
        obj[arr[id]]
      );
    } else if (obj[arr[id]] !== "") {
      flag = false;

      return;
    } else {
      traverseNode(arr, id + 1, n, obj);
    }
  };
  traverseNode(Object.keys(obj1), 0, Object.keys(obj1).length, obj1);

  return flag;
};

export const CompanyRelations = {
  customers: [2, 4, 6, 8, 10, 12, 14],
  partners: [20, 17, 5, 1, 7, 11, 31, 9, 10],
  suppliers: [],
};

export const validCheckColor = {
  High: "#D00000",
  Critical: "#800020",
  Moderate: "#FF7518",
  Minor: "#FFD700",
};

let timerId = null;
export const debounce = (callback = () => { }, delay = 300) => {
  let value = false;
  if (timerId) {
    clearTimeout(timerId);
  }
  timerId = setTimeout(() => {
    value = callback();
  }, delay);
  return value;
};

export const getBubbleXaxis = (value, groupType) => {
  if (groupType == "month") {
    return moment(value).format("DD-MMM-YYYY");
  } else if (groupType == "month2" || groupType == "month6") {
    return moment(value).format("DD-MMM-YY");
  } else if (groupType == "week") {
    return moment(value).format("DD-MMM");
  } else if (groupType == "day") {
    return moment(value).format("DD-MMM");
  } else if (groupType == "hour") {
    return moment(value).format("HH:mm");
  } else {
    return moment(value).format("MM-YYYY");
  }
};

export const getRiskVal = (val) => {
  val = val ? Math.round(val) : 0;
  if (val >= 0 && val <= 24) {
    return "SECURE";
  }
  if (val >= 25 && val <= 50) {
    return "LOW";
  }
  if (val >= 51 && val <= 75) {
    return "MEDIUM";
  }
  if (val > 75) {
    return "HIGH";
  }
};

export const getRiskValSeverity = (val) => {
  val = val ? Math.round(val) : 0;
  if (val >= 0 && val <= 39) {
    return "LOW";
  }
  if (val >= 40 && val <= 79) {
    return "MODERATE";
  }
  if (val >= 80 && val <= 94) {
    return "HIGH";
  }
  if (val > 95) {
    return "CRITICAL";
  }
};

// export const getGoalImage = (goal = null) {
//   if (goal) {

//   }
// }

export const BarMappingPAI = (data) => {
  if (data) {
    const dataEntires = [
      "GHG emissions scope 1,2,3, total",
      "Carbon footprint",
      "GHG intensity of investee companies",
      "Exposure to companies active in the fossil fuel sector",
      "Share of non-renewable energy consumption and production",
      "Energy consumption intensity per high impact climate sector",
      "Activities negatively affecting biodiversity-sensitive areas",
      "Emissions to water",
      "Hazardous waste and radioactive waste ratio",
      "Violations of UN Global Compact priciples and OECD guidelines",
      "Lack of processes and compliance mechanisms to monitor compliance with UN GC and OECD",
      "Unadjusted gender pay gap",
      "Board gender diversity",
      "Exposure to controversial weapons",
    ];
    const log = [
      {
        data: [
          { x: "1", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "2", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "3", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "4", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "5", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "6", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "7", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "8", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "9", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "10", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "11", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "12", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "13", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "14", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
        ],
      },
    ];
    for (let i in dataEntires) {
      const obj = data.find((item) => item._id === dataEntires[i]);
      if (obj !== undefined && Object.keys(obj).length > 0) {
        log[0].data[i].y = obj?.incidents ? obj?.incidents.length || 0 : 0;
        log[0].data[i].data = obj?.incidents || [];
        log[0].data[i].value = obj?.value || ""
      }
    }
    return log;
  } else {
    return [];
  }
};

export const BarMappingSDG = (data) => {
  if (data) {
    const dataEntires = [
      "No poverty",
      "Zero hunger",
      "Good health and well-being",
      "Quality education",
      "Gender equality",
      "Clean water and sanitation",
      "Affordable and clean energy",
      "Decent work and economic growth",
      "Industry, innovation and infrastructure",
      "Reduced inequalities",
      "Sustainable cities and communities",
      "Responsible consumption and production",
      "Climate action",
      "Life below water",
      "Life on land",
      "Peace, justice and strong institutions",
      "Partnership for the goals",
    ];
    const log = [
      {
        data: [
          { x: "1", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "2", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "3", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "4", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "5", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "6", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "7", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "8", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "9", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "10", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "11", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "12", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "13", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "14", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "15", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "16", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
          { x: "17", y: 0, fillColor: "#2DC2BF", data: [], value: "" },
        ],
      },
    ];
    for (let i in dataEntires) {
      const obj = data.find((item) => item._id === dataEntires[i]);
      if (obj !== undefined && Object.keys(obj).length > 0) {
        log[0].data[i].y = obj?.incidents ? obj?.incidents.length || 0 : 0;
        log[0].data[i].data = obj?.incidents || [];
        log[0].data[i].value = obj?.value || ""
      }
    }
    return log;
  } else {
    return [];
  }
};

export const calculateCompanyRisk = (data, key) => {
  // Extracting the company risks from the last two entries
  let riskPercentageChange = 0;
  const lastCompanyRisk1 = data.length > 1 ? data[data.length - 2] : 0;
  const lastCompanyRisk2 = data.length > 0 ? data[data.length - 1] : 0;

  // Calculating the percentage change in risk
  riskPercentageChange =
    ((lastCompanyRisk2 - lastCompanyRisk1) /
      (lastCompanyRisk1 > 0 ? lastCompanyRisk1 : 1)) *
    100;

  return riskPercentageChange.toFixed(2);
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});


export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
}

export function calculateEndDate(startDate, span) {
  // return the last date
  // span = 24Hours | lastWeek ...
  const obj = {
    1: () => new Date(startDate.setDate(startDate.getDate() - 1)),
    2: () => new Date(startDate.setDate(startDate.getDate() - 7)),
    3: () => new Date(startDate.setDate(startDate.getDate() - 30)),
    4: () => new Date(startDate.setMonth(startDate.getMonth() - 3)),
    5: () => new Date(startDate.setMonth(startDate.getMonth() - 6)),
    6: () =>
      new Date(startDate.setFullYear(startDate.getFullYear() - 1)),
  };
  return obj[span]();
}