import ReactApexChart from "react-apexcharts";
import { formatFollowers } from "../../../Utils";

const MostInfluentialUsersBarChart = ({ likesCount }) => {

    const arrValue = () => {

        let likesCountData =  [] 
        if (likesCount?.length > 0) {
            likesCountData = likesCount.map(user => user.likes)
            if (likesCountData.length == 2) {
                likesCountData.push(0)
            } else if (likesCountData.length == 1) {
                likesCountData.push(0,0)
            }
        } 
        return likesCountData
    }

    const format = {
        series: [
            {
                data: arrValue(),
            },
        ],
        options: {
            xaxis: {
                show: false,
                labels: {
                    show: false,
                    style: {
                        color: 'blue',
                        fontSize: '12px'
                      },              
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
            },
            yaxis: {
                show: false,
                labels: {
                    show: false,
                    style: {
                        color: 'blue',
                        fontSize: '12px'
                      },   
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }

            },

            states: {
                hover: {
                    filter: {
                        type: "none",
                    },
                },
                // active: {
                //   filter: {
                //     type: "none",
                //   },
                // },
            },
            chart: {
                toolbar: {
                    show: false,
                },

            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    borderTopLeftRadius: 100,
                    distributed: true,
                    horizontal: true,
                    barHeight: 25,
                    dataLabels: {
                        position: "bottom",
                    },
                },
            },
            colors: ["#0195CE"],
            legend: {
                show: false,
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: '#8dddfd',
                    type: "horizontal",
                    shadeIntensity: 0.8,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 90, 100],
                    colorStops: []
                
                },
            },
            tooltip: {
                enabled: false,
            },
            grid: {
                padding: {
                    left: -15,
                    right: 0,
                    top: -32,
                    bottom: 0
                  },                
                show: true,
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
                strokeDashArray: 5,
            },
            dataLabels: {
                offsetX: 20,
                formatter: function (val, opts) {
                    return val > 0 ? formatFollowers(val) : "";
                  },
                style: {
                    fontSize: "11px",
                    fontWeight: "500",
                    colors: ["#05476F"]
                  }            
            },
        },
    };

    return <ReactApexChart
        // height={80}
        // height={135}
        height={185}
        options={format.options}
        series={format.series}
        type="bar"
        
    />

}
export default MostInfluentialUsersBarChart