const selectedItemHandler = (prevScreenData, modifiedArray) => {
  for (let i = 0; i <= prevScreenData?.length - 1; i++) {
    for (let j = 0; j <= modifiedArray?.length - 1; j++) {
      if (prevScreenData[i] == modifiedArray[j]?.id) {
        modifiedArray[j].status = true;
      }
    }
  }
  return modifiedArray;
};

export { selectedItemHandler };
