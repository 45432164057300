import React from "react";

function GlobalLoder() {
  return (
    <div className="global-social-media-loader">
      <div className="loader ">
        <div className="circle-gl" id="circleE">
          <p className="gl-p">E</p>
        </div>
        <div className="circle-gl" id="circleS">
          <p className="gl-p">S</p>
        </div>
        <div className="circle-gl" id="circleG">
          <p className="gl-p">G</p>
        </div>
      </div>
    </div>
  );
}

export default GlobalLoder;
