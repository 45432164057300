import { Box, MenuItem, Typography, useTheme } from "@mui/material";

function CustomMenuItem({ label, count, onClick, divider = false }) {
  const theme = useTheme();
  return (
    <MenuItem
      key={label}
      sx={{
        height: `40px`,
        paddingX: 1,
        width: 216,
        borderBottom: divider ? "1px solid #E5E5E5" : "none",
        marginBottom: divider ? 1 : 0,
      }}
      onClick={onClick}
      disabled={count !== undefined && count === 0}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Typography variant="captionXL">{label}</Typography>
        </Box>
        {count !== undefined && (
          <Box>
            <Typography
              variant="captionL"
              sx={{ color: theme.palette.gray[300] }}
            >
              {count}
            </Typography>
          </Box>
        )}
      </Box>
    </MenuItem>
  );
}

export default CustomMenuItem;
