import React from "react";
import ReactSlider from "react-slider";
import "../../App.css";
export default function CustomSlider() {
  return (
    <div className="slider-container">
      <ReactSlider
        className="horizontal-slider"
        marks
        markClassName="example-mark"
        min={0}
        max={10}
        thumbClassName="example-thumb"
        trackClassName="example-track"
        renderThumb={(props, state) => (
          <div {...props} className="thumb">
            {state.valueNow}
          </div>
        )}
      />
    </div>
  );
}