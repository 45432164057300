import React, { useEffect, useState } from "react";
import style from "../style.module.css";
import { Col, Row, Input, AutoComplete } from "antd";
import HeaderComponent from "./HeaderComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { searchCompanyListActionLoad } from "../../../Redux/Action/global";
import useDebouncedApiCall from "../../../hook/useDebouncedSearch";
const Banner = ({ portfolioDetail, backgroundCardColor, colorText }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchCompaniesData } = useSelector((state) => state?.global);
  const { searchTerm, handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );

  const [countryName, setCountryName] = useState(null);

  const handleSearchOperation = (SelectedData) => {
    if (searchCompaniesData?.data?.length !== 0) {
      navigate("/companyPage", { state: { id: SelectedData.id } });
    }
  };
  return (
    <nav
      className={style.bannerContainer}
      style={{
        background: backgroundCardColor
          ? backgroundCardColor
          : portfolioDetail?.cardColor?.background,
      }}
    >
      <div className={style.bannerCon}>
        <Row className={` ${style.bannerMainContainer1}`}>
          <HeaderComponent
            name={"My Portfolios"}
            subHeader={portfolioDetail?.name}
            screen={"/portfolio"}
            breadCrumbColor={portfolioDetail?.cardColor?.color}
          />
          {/* <Col span={12} className={`${style.customCol}`}>My Portfolios /  <span className={`${style.customColSpan}`}>My First Portfolio</span></Col> */}
          <Col
            span={12}
            className={`${style.customCol}`}
            style={{ opacity: "0.8" }}
          >
            <div className="autocomplete-input-search">
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                popupMatchSelectWidth={500}
                className="serchmedia-icon"
                options={searchCompaniesData?.data}
                size="large"
                onSearch={handleSearch}
                value={countryName}
                onSelect={(e, selectedOption) => {
                  const { label } = selectedOption;
                  setCountryName(label);
                  handleSearchOperation(selectedOption);
                }}
                onChange={(e, i) => {
                  setCountryName(e);
                }}
              >
                <Input.Search size="large" placeholder="Search company" />
              </AutoComplete>
            </div>
          </Col>
        </Row>
        <Row className={style.heading1}>
          <span
            className={style.myPort}
            style={{ color: portfolioDetail?.cardColor?.color }}
          >
            {portfolioDetail?.name || ""}
          </span>
          <span
            style={{ zIndex: "99999", cursor: "pointer" }}
            className={style.myPortIcon}
          >
            {colorText && colorText == "#fff" ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() =>
                  navigate("/createPortfolio", {
                    state: {
                      portfolioId: portfolioDetail?._id,
                      editStep: 1,
                      screen: "/portfolioDetails",
                    },
                  })
                }
              >
                <path
                  d="M11 4.00067H7.2C6.0799 4.00067 5.51984 4.00067 5.09202 4.21866C4.71569 4.41041 4.40973 4.71637 4.21799 5.09269C4 5.52052 4 6.08057 4 7.20067V16.8007C4 17.9208 4 18.4808 4.21799 18.9087C4.40973 19.285 4.71569 19.5909 5.09202 19.7827C5.51984 20.0007 6.0799 20.0007 7.2 20.0007H16.8C17.9201 20.0007 18.4802 20.0007 18.908 19.7827C19.2843 19.5909 19.5903 19.285 19.782 18.9087C20 18.4808 20 17.9208 20 16.8007V12.5007M15.5 5.50067L18.3284 8.3291M10.7627 10.2379L17.411 3.58969C18.192 2.80864 19.4584 2.80864 20.2394 3.58969C21.0205 4.37074 21.0205 5.63707 20.2394 6.41812L13.3774 13.2801C12.6158 14.0417 12.235 14.4226 11.8012 14.7254C11.4162 14.9942 11.0009 15.2169 10.564 15.3889C10.0717 15.5827 9.54378 15.6892 8.48793 15.9022L8 16.0007L8.04745 15.6685C8.21536 14.4931 8.29932 13.9055 8.49029 13.3568C8.65975 12.8699 8.89125 12.4069 9.17906 11.9792C9.50341 11.4973 9.92319 11.0775 10.7627 10.2379Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() =>
                  navigate("/createPortfolio", {
                    state: {
                      portfolioId: portfolioDetail?._id,
                      editStep: 1,
                      screen: "/portfolioDetails",
                    },
                  })
                }
              >
                <path
                  d="M11 4.00067H7.2C6.0799 4.00067 5.51984 4.00067 5.09202 4.21866C4.71569 4.41041 4.40973 4.71637 4.21799 5.09269C4 5.52052 4 6.08057 4 7.20067V16.8007C4 17.9208 4 18.4808 4.21799 18.9087C4.40973 19.285 4.71569 19.5909 5.09202 19.7827C5.51984 20.0007 6.0799 20.0007 7.2 20.0007H16.8C17.9201 20.0007 18.4802 20.0007 18.908 19.7827C19.2843 19.5909 19.5903 19.285 19.782 18.9087C20 18.4808 20 17.9208 20 16.8007V12.5007M15.5 5.50067L18.3284 8.3291M10.7627 10.2379L17.411 3.58969C18.192 2.80864 19.4584 2.80864 20.2394 3.58969C21.0205 4.37074 21.0205 5.63707 20.2394 6.41812L13.3774 13.2801C12.6158 14.0417 12.235 14.4226 11.8012 14.7254C11.4162 14.9942 11.0009 15.2169 10.564 15.3889C10.0717 15.5827 9.54378 15.6892 8.48793 15.9022L8 16.0007L8.04745 15.6685C8.21536 14.4931 8.29932 13.9055 8.49029 13.3568C8.65975 12.8699 8.89125 12.4069 9.17906 11.9792C9.50341 11.4973 9.92319 11.0775 10.7627 10.2379Z"
                  stroke="#9A9CA2"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </span>
        </Row>
        <Row
          className={style.portfolioData}
          style={{ color: portfolioDetail?.cardColor?.color }}
        >
          <Col xs={24} lg={18}>
            <Row gutter={24}>
              <Col>
                <Row className={style.portLabel}>Holdings</Row>
                <Row className={style.portValue}>
                  {portfolioDetail?.holdingsCount|| 0}
                </Row>
              </Col>
              <Col>
                <Row className={style.portLabel}>Incidents</Row>
                <Row className={style.portValue}>
                  {portfolioDetail?.incidents || 0}
                </Row>
              </Col>
              <Col>
                <Row className={style.portLabel}>Severity Score</Row>
                <Row className={style.portValue}>
                  {Math.round(portfolioDetail?.severity_score) || 0}
                </Row>
              </Col>
              <Col>
                <Row className={style.portLabel}>Breaches</Row>
                <Row className={style.portValue}>
                  {portfolioDetail?.breaches || 0}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className={style.bannerImage} />
    </nav>
  );
};

export default Banner;
