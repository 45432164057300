/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { useEffect } from "react";
import G6Graph from "./containers/G6Graph";
// import GraphTooltip from "./containers/GraphToolTip";
import PathFindingModal from "./containers/PathFindingModal";
import Toolbox from "./containers/Toolbox";
import CompanyInfoSideBar from "./containers/companyInfoSideBar";
import CompanyActionSideBar from "./containers/companyActionSideBar";
import GraphHeaderBar from "./containers/GraphHeaderBar";
import FilterSideBar from "./containers/FilterSideBar";
import { useAppDispatch } from "./redux/configuareStore";
import { setBaseUrlApi } from "./redux/apiSlice";
import { setCenterNode } from "./redux/GraphExploration/graphSlice";
import { setTimeRange } from "./redux/timeRangeSlice";
import GraphRightClickMenu from "./containers/GraphRightClickMenu";
import SpecificRelationsModal from "./containers/GraphRightClickMenu/ExpandSubMenu/SpecificRelationsModal";
import GraphTooltip from "./containers/GraphToolTip";
import { useAppSelector } from "./redux/configuareStore";

function SyGraphApp({
  onClickBackToSupplyChain,
  baseUrlApi,
  mainCompany,
  dates,
}) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (baseUrlApi) {
      dispatch(setBaseUrlApi(baseUrlApi));
    }
  }, [baseUrlApi, dispatch]);
  useEffect(() => {
    if (mainCompany) {
      dispatch(setCenterNode(mainCompany));
    }
  }, [mainCompany]);
  useEffect(() => {
    dispatch(
      setTimeRange({
        from: dates.from,
        to: dates.to,
      })
    );
  }, []);

  const targetNodeId = useAppSelector(
    (state) => state.rightClickMenu.targetNodeId
  );
  return (
    <Box
      height="100vh"
      width="100vw"
      position="relative"
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
    >
      <GraphHeaderBar onClickBackToSupplyChain={onClickBackToSupplyChain} />
      <GraphRightClickMenu />
      <SpecificRelationsModal />
      <PathFindingModal />
      <G6Graph mainCompany={mainCompany} />
      <Toolbox />
      <GraphTooltip />
      <CompanyInfoSideBar />
      {targetNodeId && <CompanyActionSideBar />}
      <FilterSideBar />
    </Box>
  );
}
export default SyGraphApp;
