import { call, put, takeLatest } from "redux-saga/effects";
import { toast as hotToast } from "react-hot-toast";
import appconstant from "../../Constants/appConstansts";
import { onError, onFail, onSucces, onUnAuth } from "../../Utils/UnAuthFailFn";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  getOverallReactionSentimentApi,
  getOverallRedditReactionApi,
  getRadarChartApi,
  getSocialMediaReactionApi,
  getTotalRedditReactionsApi,
  getTotalSocialMediaReactionsApi,
  getTotalTweetReactionsApi,
  socialMediaDetailApi,
  tweetAndRepliesApi,
  tweetIndividualRepliesApi,
  getRedditWithPostApi,
  getRedditWithCommentApi,
  getMostInfluentialUserApi,
  getSummaryDataApi,
  getSocialMediaMapData,
} from "../api";
import {
  getSocialMediaDetailSuccess,
  getSocialMediaDetailFail,
  getTweetWithRepliesSuccess,
  getTweetWithRepliesFail,
  getIndividualTweetReplySuccess,
  getIndividualTweetReplyFail,
  getSocialMediaReactionSuccess,
  getSocialMediaReactionFail,
  getOverallReactionSentimentSuccess,
  getOverallReactionSentimentFail,
  getOverallRedditReactionSuccess,
  getOverallRedditReactionFail,
  getRadarDataSuccess,
  getRadarDataFail,
  getTotalTweetReactionSuccess,
  getTotalTweetReactionFail,
  getTotalRedditReactionSuccess,
  getTotalRedditReactionFail,
  getTotalSocialMediaReactionSuccess,
  getTotalSocialMediaReactionFail,
  getRedditWithPostSuccess,
  getRedditWithPostFail,
  getRedditWithCommentSuccess,
  getRedditWithCommentFail,
  getMostInfluentialUserSuccess,
  getMostInfluentialUserFail,
  getSummaryDataSuccess,
  getSummaryDataFail,
} from "../Action/socialMedia";
import { toast } from "react-toastify";
import { errorToastContainer, successToastContainer } from "../../Utils";

const { RoutesPath } = appconstant;

function* getSocialMediaDetailSaga(action) {
  try {
    const data = yield call(socialMediaDetailApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getSocialMediaDetailSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getSocialMediaDetailFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getTweetAndRepliesSaga(action) {
  try {
    const data = yield call(tweetAndRepliesApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getTweetWithRepliesSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getTweetWithRepliesFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getIndividualTweetReplySaga(action) {
  try {
    const data = yield call(tweetIndividualRepliesApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getIndividualTweetReplySuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getIndividualTweetReplyFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getSocialMediaReactionsSaga(action) {
  try {
    const data = yield call(getSocialMediaReactionApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getSocialMediaReactionSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getSocialMediaReactionFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getOverallReactionSentimentSaga(action) {
  try {
    const data = yield call(getOverallReactionSentimentApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getOverallReactionSentimentSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getOverallReactionSentimentFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getOverallRedditReactionSaga(action) {
  try {
    const data = yield call(getOverallRedditReactionApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getOverallRedditReactionSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getOverallRedditReactionFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getRadarDataSaga(action) {
  try {
    const data = yield call(getRadarChartApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getRadarDataSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getRadarDataFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getTotalTweetReactionsSaga(action) {
  try {
    const data = yield call(getTotalTweetReactionsApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getTotalTweetReactionSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getTotalTweetReactionFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getTotalRedditReactionsSaga(action) {
  try {
    const data = yield call(getTotalRedditReactionsApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getTotalRedditReactionSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getTotalRedditReactionFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getTotalSocialMediaReactionSaga(action) {
  try {
    const data = yield call(getTotalSocialMediaReactionsApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getTotalSocialMediaReactionSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getTotalSocialMediaReactionFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* getRedditWithPostSaga(action) {
  try {
    const data = yield call(getRedditWithPostApi, action.payload);
    if (data.status === 0) {
      yield put(getRedditWithPostSuccess(data.result.data));
    } else {
      yield put(getRedditWithPostFail(data));
    }
  } catch (error) {
    console.log("error", error);
  }
}
function* getRedditWithCommentSaga(action) {
  try {
    const data = yield call(getRedditWithCommentApi, action.payload);
    if (data.status === 0) {
      yield put(getRedditWithCommentSuccess(data.result));
    } else {
      yield put(getRedditWithCommentFail(data));
    }
  } catch (error) {
    console.log("error", error);
  }
}

function* getMostInfluentialUserSaga(action) {
  try {
    const data = yield call(getMostInfluentialUserApi, action.payload);
    if (data.status === 0) {
      yield put(getMostInfluentialUserSuccess(data.result.data));
    } else {
      yield put(getMostInfluentialUserFail(data));
    }
  } catch (error) {
    console.log("error", error);
  }
}

function* getSummaryDataSaga(action) {
  try {
    const data = yield call(getSummaryDataApi, action.payload);
    if (data.status === 0) {
      yield put(getSummaryDataSuccess(data.result.data));
    } else {
      yield put(getSummaryDataFail(data));
    }
  } catch (error) {
    console.log("error", error);
  }
}

function* socialMediaMapSaga(action) {
  try {
    const data = yield call(getSocialMediaMapData, action.payload);
    if (data.status === 0) {
      yield put({
        type: type.SOCIAL_MEDIA_MAP_SUCCESS,
        payload: data?.result?.data,
      });
    }
  } catch (error) {
    yield call(onError);
  }
}

export default function* rootSocialMediaPageSaga() {
  yield takeLatest(type.GET_SOCIAL_MEDIA_TAGS_LOAD, getSocialMediaDetailSaga);
  yield takeLatest(type.GET_TWEET_WITH_REPLIES_LOAD, getTweetAndRepliesSaga);
  yield takeLatest(
    type.GET_TWEET_INDIVIDUAL_REPLY_LOAD,
    getIndividualTweetReplySaga
  );
  yield takeLatest(
    type.GET_SOCIAL_MEDIA_REACTIONS_LOAD,
    getSocialMediaReactionsSaga
  );
  yield takeLatest(
    type.GET_OVERALL_REACTION_SENTIMENT_LOAD,
    getOverallReactionSentimentSaga
  );
  yield takeLatest(
    type.GET_OVERALL_REDDIT_REACTION_LOAD,
    getOverallRedditReactionSaga
  );
  yield takeLatest(type.GET_RADAR_DATA_LOAD, getRadarDataSaga);
  yield takeLatest(type.TOTAL_TWEET_REACTION_LOAD, getTotalTweetReactionsSaga);
  yield takeLatest(
    type.TOTAL_REDDIT_REACTION_LOAD,
    getTotalRedditReactionsSaga
  );
  yield takeLatest(
    type.TOTAL_SOCIAL_MEDIA_REACTION_LOAD,
    getTotalSocialMediaReactionSaga
  );
  yield takeLatest(type.GET_REDDIT_WITH_POST_LOAD, getRedditWithPostSaga);
  yield takeLatest(type.GET_REDDIT_POST_COMMENT_LOAD, getRedditWithCommentSaga);
  yield takeLatest(
    type.GET_MOST_INFLUENTIAL_USER_LOAD,
    getMostInfluentialUserSaga
  );
  yield takeLatest(type.GET_SUMMARY_DATA_LOAD, getSummaryDataSaga);
  yield takeLatest(type.SOCIAL_MEDIA_MAP_LOAD, socialMediaMapSaga);
}
