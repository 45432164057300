import moment from "moment";
import style from "../Screens/portfolio/style.module.css";
import { incindentAction } from "../Redux/Action/incidents";

export const incidentFilter = (data, dispatch) => {
  let networkData = {
    search: "",
    continent_codes: [],
    countries: [],
    companies: data?.companyArray ? data.companyArray : [],
    locations: data?.countries ? data.countries : [],
    esg_factors: data?.esg_factors ? data.esg_factors : [],
    esg_SDG: data?.esg_SDG ? data.esg_SDG : [],
    esg_PAI: data?.esg_PAI ? data.esg_PAI : [],
    industries: [],
    isAdvanceSearch: false,
    start: data?.start,
    end: data?.end,
    collectionId: null,
    sources: data?.sources ? data.sources : [],
  };
  dispatch(incindentAction(networkData));
};

export const getArrayId = (array, selectedOptionArray) => {
  let Array = [];
  for (let i = 0; i < array?.length; i++) {
    if (array?.length == selectedOptionArray?.length) {
      break;
    }
    for (let j = 0; j < selectedOptionArray?.length; j++) {
      if (array[i]?.name == selectedOptionArray[j]) {
        Array.push(array[i]?.id);
      }
    }
  }
  return Array;
};

export const convertTimestampinFormatedDate = (timestamp, monthType = null) => {
  const date = new Date(timestamp);
  // Get the desired date format (MM-DD-YYYY)
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const options1 = { year: "numeric", month: "short", day: "2-digit" };
  const formattedDate = date.toLocaleDateString(
    "en-US",
    monthType ? options1 : options
  );
  return formattedDate;
};

export function getDateTwoDaysAgo(inputDate) {
  const currentDate = new Date(inputDate);
  currentDate.setDate(currentDate.getDate() - 2);
  const twoDaysAgo = currentDate.toISOString().split("T")[0];
  return twoDaysAgo;
}

export function getLastMonthDates(inputDate) {
  if (typeof inputDate === "string") {
    inputDate = new Date(inputDate);
  }

  // Ensure inputDate is a valid Date object
  if (!(inputDate instanceof Date) || isNaN(inputDate)) {
    throw new Error("Invalid date format");
  }

  // Calculate the first day of the last month
  var firstDayOfLastMonth = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth() - 1,
    1
  );

  // Calculate the last day of the last month
  var lastDayOfLastMonth = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    0
  );

  // Format the dates as 'YYYY-MM-DD'
  var formattedStart = formatDate(firstDayOfLastMonth);
  var formattedEnd = formatDate(lastDayOfLastMonth);

  // Increase one day from the start date
  var adjustedStartDate = new Date(formattedStart);
  adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);
  var formattedAdjustedStart = formatDate(adjustedStartDate);

  var adjustedStartDate = new Date(formattedEnd);
  adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);
  var formattedAdjustedEnd = formatDate(adjustedStartDate);
  // Return an object with start and end dates
  return {
    start: formattedAdjustedStart,
    end: formattedAdjustedEnd,
  };
}
function formatDate(date) {
  return date.toISOString().split("T")[0];
}

export const getPrincipleColors = (key) => {
  if (key == "Human Rights") {
    return "#0195CE";
  }
  if (key == "Labour") {
    return "#653DD4";
  }
  if (key == "Environment") {
    return "#6EBD20";
  }
  if (key == "Anti-Corruption") {
    return "#2DC2BF";
  }
};

export const getGermanSupplyChainColors = (key) => {
  if (key == "Human Rights") {
    return "#0195CE";
  }
  if (key == "Labor Rights") {
    return "#653DD4";
  }
  if (key == "Environment") {
    return "#6EBD20";
  }
  if (key == "Employee Health and Safty") {
    return "#2DC2BF";
  }
};

export const getUCBreachesPrincipleColors = (key) => {
  if (key == "Principle 1" || key == "Principle 2") {
    return "#0195CE";
  }

  if (
    key == "Principle 3" ||
    key == "Principle 4" ||
    key == "Principle 5" ||
    key == "Principle 6"
  ) {
    return "#653DD4";
  }

  if (key == "Principle 7" || key == "Principle 8" || key == "Principle 9") {
    return "#6EBD20";
  }

  if (key == "Principle 10") {
    return "#2DC2BF";
  }
};

export const getPrincipleColor = (key) => {
  if (key == "Principle 1" || key == "Principle 2") {
    return "#0195CE";
  }
  if (
    key == "Principle 3" ||
    key == "Principle 4" ||
    key == "Principle 5" ||
    key == "Principle 6"
  ) {
    return "#653DD4";
  }
  if (key == "Principle 7" || key == "Principle 8" || key == "Principle 9") {
    return "#2DC2BF";
  }
  if (key == "Principle 10") {
    return "#6EBD20";
  }
};

export function getDifferenceFromNow(date) {
  const dateFromNow = moment(date);
  const diffInMinutes = moment().diff(dateFromNow, "minutes");

  if (diffInMinutes < 60) {
    return `${diffInMinutes} min`;
  } else {
    const dateFormatted = dateFromNow.fromNow(true);
    if (dateFormatted.includes("hour")) {
      return dateFormatted
        .replace("an hour", "1" + " h")
        .replace("hours", " h");
    } else if (dateFormatted.includes("minute")) {
      return dateFormatted
        .replace("a minute", "1" + " min")
        .replace("minutes", " min");
    } else if (
      dateFormatted.includes("day") &&
      dateFormatted.includes("a day")
    ) {
      return dateFormatted.replace("a day", "1 day");
    } else {
      return dateFormatted;
    }
  }
}

export function getStrokeColorGradient(riskValue) {
  let firstColorPerc = (10 / 30) * 100;
  let firstColor = `${100 - firstColorPerc}%`;
  let middleColorPerc = ((riskValue - 10) / 30) * 100;
  let middleColor = `${100 - middleColorPerc}%`;
  let lastColorPerc = ((riskValue - 30) / 70) * 100;
  let lastColor = `${100 - lastColorPerc}%`;

  let strokeColor;
  let strokeWidth = 8;
  let riskColorClass = "";

  if (riskValue <= 10) {
    strokeColor = {
      "0%": "#1A9C3E",
      "100%": "#1A9C3E",
    };
    riskColorClass = style.ProgressColor1;
  } else if (riskValue > 10 && riskValue <= 30) {
    strokeColor = {
      "0%": "#1A9C3E",
      [middleColor]: "#FFC000",
      "100%": "#FFC000",
    };
    riskColorClass = style.ProgressColor2;
  } else if (riskValue > 30 && riskValue <= 100) {
    strokeColor = {
      "0%": "#1A9C3E",
      "30%": "#FFC000",
      [lastColor]: "#F28C28",
      "100%": "#D00000",
    };
    riskColorClass = style.ProgressColor3;
  }

  return {
    strokeColor,
    strokeWidth,
    riskColorClass,
  };
}

export function getStrokeColorGradient1(riskValue) {
  let firstColorPerc = (10 / 30) * 100;
  let firstColor = `${100 - firstColorPerc}%`;
  let middleColorPerc = ((riskValue - 10) / 30) * 100;
  let middleColor = `${100 - middleColorPerc}%`;
  let lastColorPerc = ((riskValue - 30) / 70) * 100;
  let lastColor = `${100 - lastColorPerc}%`;

  let strokeColor;
  let strokeWidth = 8;
  let riskColorClass = "";

  if (riskValue <= 10) {
    strokeColor = {
      "0%": "#1A9C3E",
      "100%": "#1A9C3E",
    };
    riskColorClass = style.ProgressColor1;
  } else if (riskValue > 10 && riskValue <= 30) {
    strokeColor = {
      "0%": "#1A9C3E",
      [middleColor]: "#FFC000",
      "100%": "#FFC000",
    };
    riskColorClass = style.ProgressColor2;
  } else if (riskValue > 30 && riskValue <= 100) {
    strokeColor = {
      "0%": "#1A9C3E",
      "30%": "#FFC000",
      [lastColor]: "#F28C28",
      "100%": "#D00000",
    };
    riskColorClass = style.ProgressColor3;
  }

  return {
    strokeColor,
    strokeWidth,
    riskColorClass,
  };
}

export function preventInvalidNumberInput(e, allowDot = false) {
  var invalidChars = ["-", "e", "+", "E", "."];
  if (allowDot) invalidChars = invalidChars.pop();
  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
}

export function openIncidentDetail(id) {
  window.open(`/incidentdetails?${id}`, "_blank");
}

export const getUCBreachesSDGColors = (key) => {
  if (key == "Part1") {
    return "#6EBD20";
  }
  if (key == "Part2") {
    return "#0195CE";
  }
};

export function formatNumber(num) {
  return num % 1 === 0 ? num.toString() : num.toFixed(2);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const highlightMatch = (searchString, companyName, type) => {
  const searchStringEscaped = escapeRegExp(searchString);
  const regex = new RegExp(`(${searchStringEscaped})`, 'gi');
  if (companyName?.length > 0) {
    if (type === "company") {
      return companyName?.replace(regex, '<span style="font-weight: bold;; font-size: 13px; color: #0095CE; fontFamily:inter;">$1</span>');
    } else if (type === "ticker" || type === "alter" || type === "isin") {
      const returnVal = companyName?.replace(regex, '<span style="font-weight: bold;; color: #0095CE">$1</span>');
      return returnVal
    }
  }
};