import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const skeletonArray = [1, 2, 3, 4];
function MenuSkeleton() {
  return (
    <>
      {skeletonArray.map((item) => (
        <Stack
          key={item}
          flexDirection="row"
          height={42}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack key={item} flexDirection="row">
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{ marginRight: 1, marginLeft: 2 }}
            />
            <Skeleton variant="text" width={10 + 90 * Math.random()} />
          </Stack>
          <Stack flexDirection="row">
            <Skeleton
              variant="text"
              width={10 + 20 * Math.random()}
              sx={{ marginRight: 2 }}
            />
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ marginRight: 1 }}
            />
          </Stack>
        </Stack>
      ))}
    </>
  );
}
export default MenuSkeleton;
