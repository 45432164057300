import ImageConst from "../../Themes/ImageConstant";

const ModalCloseButton = () => {
  return (
    <div
      style={{
        height: "24px",
        width: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        style={{ height: "100%", width: "100%" }}
        src={ImageConst.closeIcon}
      />
    </div>
  );
};

export default ModalCloseButton;
