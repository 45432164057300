import React from "react";
import { SvgIcon } from "@mui/material";

function ShortestPathIcon() {
  return (
    <SvgIcon
      style={{
        fontSize: "14px", // Control the size through font size
        margin: "4px",
      }}
      viewBox="0 0 14 14"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33464 5.00033V6.40033C3.33464 7.8938 3.33464 8.64054 3.62528 9.21097C3.88095 9.71273 4.2889 10.1207 4.79066 10.3763C5.36109 10.667 6.10783 10.667 7.6013 10.667H9.0013M2.73464 5.00033H3.93464C4.308 5.00033 4.49469 5.00033 4.6373 4.92766C4.76274 4.86375 4.86472 4.76176 4.92864 4.63632C5.0013 4.49371 5.0013 4.30703 5.0013 3.93366V2.73366C5.0013 2.36029 5.0013 2.17361 4.92864 2.031C4.86472 1.90556 4.76274 1.80357 4.6373 1.73965C4.49469 1.66699 4.308 1.66699 3.93464 1.66699H2.73464C2.36127 1.66699 2.17458 1.66699 2.03198 1.73965C1.90653 1.80357 1.80455 1.90556 1.74063 2.031C1.66797 2.17361 1.66797 2.36029 1.66797 2.73366V3.93366C1.66797 4.30703 1.66797 4.49371 1.74063 4.63632C1.80455 4.76176 1.90653 4.86375 2.03198 4.92766C2.17458 5.00033 2.36127 5.00033 2.73464 5.00033ZM10.068 12.3337H11.268C11.6413 12.3337 11.828 12.3337 11.9706 12.261C12.0961 12.1971 12.1981 12.0951 12.262 11.9697C12.3346 11.827 12.3346 11.6404 12.3346 11.267V10.067C12.3346 9.69362 12.3346 9.50694 12.262 9.36433C12.1981 9.23889 12.0961 9.1369 11.9706 9.07299C11.828 9.00033 11.6413 9.00033 11.268 9.00033H10.068C9.6946 9.00033 9.50792 9.00033 9.36531 9.07299C9.23987 9.1369 9.13788 9.23889 9.07396 9.36433C9.0013 9.50694 9.0013 9.69362 9.0013 10.067V11.267C9.0013 11.6404 9.0013 11.827 9.07396 11.9697C9.13788 12.0951 9.23987 12.1971 9.36531 12.261C9.50792 12.3337 9.6946 12.3337 10.068 12.3337Z"
          stroke="#80838B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default ShortestPathIcon;
