import React from "react";
import { call, takeLatest } from "redux-saga/effects";
import { useNavigate } from "react-router-dom";

import appconstant from "../../Constants/appConstansts";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  LoginApi,
  ForgotPasswordApi,
  checkLinkApi,
  ResetPasswordApi,
  changePasswordApi,
  logoutApi,
  socialLoginApi,
  RegisterApi,
  VerifyEmail,
  VerifyEmailApi,
  ResetPasswordEmail,
  ResetPasswordEmailApi,
  verifySignUpEmailApi,
  resendApi,
} from "../api/index";
import {
  Loader,
  onError,
  onFail,
  onSucces,
  onUnAuth,
} from "../../Utils/UnAuthFailFn";
import { toast } from "react-toastify";
import { storage } from "../../Utils";
const { RoutesPath } = appconstant;

function* LoginSaga(action) {
  try {
    const data = yield call(LoginApi, action.data[0]);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_LOGIN_SUCCESS,
        data,
        navigationLink: RoutesPath.dashboard,
        show: false,
      };
      const { result } = data;

      if (action.data[1]) {
        storage.saveLocal("token", result.data.token);
      } else {
        storage.saveLocal("token", result.data.token);
      }

      yield call(onSucces, obj);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
        show: false,
      };

      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* socialLoginSaga(action) {
  try {
    const data = yield call(socialLoginApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_LOGIN_SUCCESS,
        data,
        navigationLink: RoutesPath.home,
        show: false,
      };
      yield call(onSucces, obj);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* RegisterSaga(action) {
  try {
    const data = yield call(RegisterApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_REGISTER_SUCCESS,
        data,
        navigationLink: RoutesPath.confirmPass,
        show: true,
        navState: { state: action.data },
      };
      yield call(onSucces, obj);
    } // for fail
    else {
      let obj = {
        type: type.API_REGISTER_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* forgotPasswordSaga(action) {
  try {
    const data = yield call(ForgotPasswordApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_FORGOT_PASSWORD_SUCCESS,
        data: { result: true },
        show: false,
      };
      yield call(onSucces, obj);
    }
    // for fail
    else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
// Verify Email Vai Link
function* verifyEmailSaga(action) {
  try {
    const data = yield call(VerifyEmailApi, action.data);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_VERIFY_EMAIL_SUCCESS,
        data: { result: true },
        show: false,
      };
      yield call(onSucces, obj);
    }
    // for fail
    else {
      let obj = {
        type: type.API_VERIFY_EMAIL_FAIL,
        message: data?.result?.message,
        show: false,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* resetPasswordSaga(action) {
  try {
    const data = yield call(ResetPasswordApi, action.data);
    //  on success
    if (data.status === 0) {
      let obj = {
        type: type.API_RESET_PASSWORD_SUCCESS,
        data,
        show: true,
        navigationLink: RoutesPath.login,
      };
      // on fail
      yield call(onSucces, obj);
    } else {
      let obj = {
        type: type.API_RESET_PASSWORD_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
//reset-password Email
function* ResetpasswordEmailSaga(action) {
  try {
    const data = yield call(ResetPasswordEmailApi, action.data);

    if (data.status === 0) {
      let obj = {
        type: type.API_RESET_EMAIL_PASSWORD_SUCCESS,
        data,
        show: true,
      };
      yield call(onSucces, obj);
    }
    // on fail
    else {
      let obj = {
        type: type.API_RESET_EMAIL_PASSWORD_FAIL,
        message: data?.result?.message,
      };

      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
// Change password
function* changePasswordSaga(action) {
  try {
    const data = yield call(changePasswordApi, action.data);
    // for success
    if (data.status === 0) {
      yield call(onSucces, {
        type: type.API_CHANGE_PASSWORD_SUCCESS,
        data,
        show: true,
        navigationLink: RoutesPath.login,
      });
      storage.clearStorage();
    }
    // for unAuth.
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      let obj = {
        type: type.API_CHANGE_PASSWORD_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
// Change password
function* checkLinkSaga(action) {
  try {
    const data = yield call(checkLinkApi, action.id);
    // for success
    if (data.status === 0) {
      yield call(onSucces, {
        type: type.API_CHECK_RESET_PASSWORD_SUCCESS,
        data,
        show: false,
      });
    }
    // for unAuth.
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      yield call(onFail, {
        type: type.API_AUTHENTICATION_FAILED,
        message: data?.result?.message,
        show: false,
      });
    }
  } catch (e) {
    yield call(Loader, type.API_AUTHENTICATION_ERROR);
  }
}

function* logoutSaga() {
  try {
    const data = yield call(logoutApi);
    // for success
    if (data.status === 0) {
      localStorage.clear();
      toast.success(data.result.message);
      yield call(onSucces, {
        navigationLink: appconstant.RoutesPath.Login,
      });
    }
    // for unAuth.
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      yield call(onFail, {
        type: type.API_AUTHENTICATION_FAILED,
        message: data?.result?.message,
        show: false,
      });
    }
  } catch (e) {
    yield call(Loader, type.API_AUTHENTICATION_ERROR);
  }
}

function* verifiedEmailSaga(action) {
  try {
    const data = yield call(verifySignUpEmailApi, action.data);
    // for success
    if (data.status === 0) {
      yield call(onSucces, {
        type: type.API_VERIFIED_PASSWORD_SUCCESS,
        data,
        show: false,
      });
    }
    // for unAuth.
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      yield call(onFail, {
        type: type.API_AUTHENTICATION_FAILED,
        message: data?.result?.message,
        show: false,
      });
    }
  } catch (e) {
    yield call(Loader, type.API_AUTHENTICATION_ERROR);
  }
}

function* resendSaga(action) {
  try {
    const data = yield call(resendApi, action.data);
    // for success
    if (data.status === 0) {
      yield call(onSucces, {
        type: type.API_RESEND_SUCCESS,
        data,
        show: true,
      });
    }
    // for unAuth.
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      yield call(onFail, {
        type: type.API_AUTHENTICATION_FAILED,
        message: data?.result?.message,
        show: false,
      });
    }
  } catch (e) {
    yield call(Loader, type.API_AUTHENTICATION_ERROR);
  }
}

export default function* rootAuthSaga() {
  yield takeLatest(type.API_LOGIN_LOAD, LoginSaga);
  yield takeLatest(type.API_SOCIAL_LOGIN_LOAD, socialLoginSaga);
  yield takeLatest(type.API_REGISTER_LOAD, RegisterSaga);
  yield takeLatest(type.API_VERIFY_EMAIL_LOAD, verifyEmailSaga);
  yield takeLatest(type.API_CHANGE_PASSWORD_LOAD, changePasswordSaga);
  yield takeLatest(type.API_FORGOT_PASSWORD_LOAD, forgotPasswordSaga);
  yield takeLatest(type.API_CHECK_RESET_PASSWORD_LOAD, checkLinkSaga);
  yield takeLatest(type.API_RESET_PASSWORD_LOAD, resetPasswordSaga);
  yield takeLatest(type.API_LOGOUT_LOAD, logoutSaga);
  yield takeLatest(type.API_RESET_EMAIL_PASSWORD_LOAD, ResetpasswordEmailSaga);
  yield takeLatest(type.API_VERIFIED_PASSWORD_LOAD, verifiedEmailSaga);
  yield takeLatest(type.API_RESEND_LOAD, resendSaga);
}
