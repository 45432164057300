export const apiConstants = {
  // ************ AUTHENTICATION ************
  // Global
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",

  // Authentications types
  API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",
  API_AUTHENTICATION_ERROR: "API_AUTHENTICATION_ERROR",

  // Authentications types
  API_RESEND_LOAD: "API_RESEND_LOAD",
  API_RESEND_FAIL: "API_RESEND_FAIL",
  API_RESEND_SUCCESS: "API_RESEND_SUCCESS",

  // Login
  API_LOGIN_LOAD: "API_LOGIN_LOAD",
  API_LOGIN_SUCCESS: "API_LOGIN_SUCCESS",
  API_LOGIN_FAIL: "API_LOGIN_FAIL",
  // Register
  API_REGISTER_LOAD: "API_REGISTER_LOAD",
  API_REGISTER_SUCCESS: "API_REGISTER_SUCCESS",
  API_REGISTER_FAIL: "API_REGISTER_FAIL",

  // socialLogin
  API_SOCIAL_LOGIN_LOAD: "API_SOCIAL_LOGIN_LOAD",
  API_SOCIAL_LOGIN_SUCCESS: "API_SOCIAL_LOGIN_SUCCESS",
  API_SOCIAL_LOGIN_FAIL: "API_SOCIAL_LOGIN_FAIL",

  //Verify Email Via link
  API_VERIFY_EMAIL_LOAD: "API_VERIFY_EMAIL_LOAD",
  API_VERIFY_EMAIL_SUCCESS: "API_VERIFY_EMAIL_SUCCESS",
  API_VERIFY_EMAIL_FAIL: "API_VERIFY_EMAIL_FAIL",

  //Change Password
  API_CHANGE_PASSWORD_LOAD: "API_CHANGE_PASSWORD_LOAD",
  API_CHANGE_PASSWORD_SUCCESS: "API_CHANGE_PASSWORD_SUCCESS",
  API_CHANGE_PASSWORD_FAIL: "API_CHANGE_PASSWORD_FAIL",
  // Reset Password verify
  API_RESET_PASSWORD_LOAD: "API_RESET_PASSWORD_LOAD",
  API_RESET_PASSWORD_SUCCESS: "API_RESET_PASSWORD_SUCCESS",
  API_RESET_PASSWORD_FAIL: "API_RESET_PASSWORD_FAIL",
  //reset-password Email
  API_RESET_EMAIL_PASSWORD_LOAD: "API_RESET_EMAIL_PASSWORD_LOAD",
  API_RESET_EMAIL_PASSWORD_SUCCESS: "API_RESET_EMAIL_PASSWORD_SUCCESS",
  API_RESET_EMAIL_PASSWORD_FAIL: "API_RESET_EMAIL_PASSWORD_FAIL",

  //verified-email Email
  API_VERIFIED_PASSWORD_LOAD: "API_VERIFIED_PASSWORD_LOAD",
  API_VERIFIED_PASSWORD_SUCCESS: "API_VERIFIED_PASSWORD_SUCCESS",
  API_VERIFIED_PASSWORD_FAIL: "API_VERIFIED_PASSWORD_FAIL",

  // Logut
  API_LOGOUT_LOAD: "API_LOGOUT_LOAD",
  API_LOGOUT_SUCCESS: "API_LOGOUT_SUCCESS",

  // forgot password
  API_FORGOT_PASSWORD_LOAD: "API_FORGOT_PASSWORD_LOAD",
  API_FORGOT_PASSWORD_SUCCESS: "API_FORGOT_PASSWORD_SUCCESS",

  // Reset Password
  API_CHECK_RESET_PASSWORD_LOAD: "API_CHECK_RESET_PASSWORD_LOAD",
  API_CHECK_RESET_PASSWORD_SUCCESS: "API_CHECK_RESET_PASSWORD_SUCCESS",

  // ----------- INCIDENT ANALYZER -------------
  API_GET_INCIDENT_ANALYZER_BREACHES_LOAD:
    "API_GET_INCIDENT_ANALYZER_BREACHES_LOAD",
  API_GET_INCIDENT_ANALYZER_BREACHES_SUCCESS:
    "API_GET_INCIDENT_ANALYZER_BREACHES_SUCCESS",

  API_GET_INCIDENT_ANALYZER_SOCIAL_MEDIA_COVERAGE_LOAD:
    "API_GET_INCIDENT_ANALYZER_SOCIAL_MEDIA_COVERAGE_LOAD",
  API_GET_INCIDENT_ANALYZER_SOCIAL_MEDIA_COVERAGE_SUCCESS:
    "API_GET_INCIDENT_ANALYZER_SOCIAL_MEDIA_COVERAGE_SUCCESS",

  GET_BREACHES_VIOLATION_DATA_LOAD: "GET_BREACHES_VIOLATION_DATA_LOAD",
  GET_BREACHES_VIOLATION_DATA_SUCCESS: "GET_BREACHES_VIOLATION_DATA_SUCCESS",
  GET_BREACHES_VIOLATION_DATA_FAIL: "GET_BREACHES_VIOLATION_DATA_FAIL",

  // ************ INCIDENT ************
  API_HOME_PAGE_LOAD: "API_HOME_PAGE_LOAD",
  API_HOME_PAGE_SUCCESS: "API_HOME_PAGE_SUCCESS",

  API_INCIDENT_LOAD: "API_INCIDENT_LOAD_LOAD",
  API_INCIDENT_SUCCESS: "API_INCIDENT_SUCCESS",
  INCIDENT_LIST_MODAL: "INCIDENT_LIST_MODAL",
  FILTER_ESG_MAP_DATA: "FILTER_ESG_MAP_DATA",
  FILTER_SEVERTY_MAP_DATA: "FILTER_SEVERTY_MAP_DATA",

  UPDATE_INCIDENT_STATE: "UPDATE_INCIDENT_STATE",
  UPDATE_ADD_WISHLIST_ID: "UPDATE_ADD_WISHLIST_ID",

  GET_INCIDENT_COUNT_LOAD: "GET_INCIDENT_COUNT_LOAD",
  GET_INCIDENT_COUNT_SUCCESS: "GET_INCIDENT_COUNT_SUCCESS",
  GET_INCIDENT_COUNT_FAIL: "GET_INCIDENT_COUNT_FAIL",
  //***************companies With Incident */
  COMPANY_ID_SEARCH_LIST: "COMPANY_ID_SEARCH_LIST",
  LOCATION_ID_SEARCH_LIST: "LOCATION_ID_SEARCH_LIST",
  API_COMPANIES_WITH_INCIDENT_LOAD: "API_COMPANIES_WITH_INCIDENT_LOAD",
  API_COMPANIES_WITH_INCIDENT_SUCCESS: "API_COMPANIES_WITH_INCIDENT_SUCCESS",

  API_COUNTRIES_WITH_INCIDENT_LOAD: "API_COUNTRIES_WITH_INCIDENT_LOAD",
  API_COUNTRIES_WITH_INCIDENT_SUCCESS: "API_COUNTRIES_WITH_INCIDENT_SUCCESS",

  //*************************esg Factors Incidents */

  API_ESG_FACTORS_INCIDENTS_LOAD: "API_ESG_FACTORS_INCIDENTS_LOAD",
  API_ESG_FACTORS_INCIDENTS_SUCCESS: "API_ESG_FACTORS_INCIDENTS_SUCCESS",
  //*************************esg sdg incidents */
  API_ESG_SDG_INCIDENTS_LOAD: "API_ESG_SDG_INCIDENTS_LOAD",
  API_ESG_SDG_INCIDENTS_SUCCESS: "API_ESG_SDG_INCIDENTS_SUCCESS",
  //*************************esg Pai Incidents */
  API_ESG_PAI_INCIDENTS_LOAD: "API_ESG_PAI_INCIDENTS_LOAD",
  API_ESG_PAI_INCIDENTS_SUCCESS: "API_ESG_PAI_INCIDENTS_SUCCESS",
  // get company incidents
  API_GET_COMPANIES_SUCCESS: "API_GET_COMPANIES_SUCCESS",
  API_GET_COMPANIES_LOAD: "API_GET_COMPANIES_LOAD",
  API_GET_COMPANIES_FAIL: "API_GET_COMPANIES_FAIL",

  UPDATE_COMPANIES_SUCCESS: "UPDATE_COMPANIES_SUCCESS",
  // **********************ESG Category*********************************
  API_ESG_CAT_LOAD: "API_ESG_CAT_LOAD",
  API_ESG_CAT_SUCCESS: "API_ESG_CAT_SUCCESS",
  API_ESG_CAT_FAIL: "API_ESG_CAT_FAIL",
  //incident overtime

  API_ESG_IOT_SUCCESS: "API_ESG_IOT_SUCCESS",
  API_ESG_IOT_LOAD: "API_ESG_IOT_LOAD",
  API_ESG_IOT_FAIL: "API_ESG_IOT_FAIL",

  //get incidence with countries
  API_GET_INCIDENCE_WITH_COUNTRIES_SUCCESS:
    "API_GET_INCIDENCE_WITH_COUNTRIES_SUCCESS",
  API_GET_INCIDENCE_WITH_COUNTRIES_LOAD:
    "API_GET_INCIDENCE_WITH_COUNTRIES_LOAD",
  API_GET_INCIDENCE_WITH_COUNTRIES_FAIL:
    "API_GET_INCIDENCE_WITH_COUNTRIES_FAIL",

  //get source details
  API_GET_SOURCE_DETAILS_SUCCESS: "API_GET_SOURCE_DETAILS_SUCCESS",
  API_GET_SOURCE_DETAILS_LOAD: "API_GET_SOURCE_DETAILS_LOAD",
  API_GET_SOURCE_DETAILS_FAIL: "API_GET_SOURCE_DETAILS_FAIL",
  //get news article data
  API_GET_NEWS_ARTICLE_SUCCESS: "API_GET_NEWS_ARTICLE_SUCCESS",
  API_GET_NEWS_ARTICLE_LOAD: "API_GET_NEWS_ARTICLE_LOAD",
  API_GET_NEWS_ARTICLE_FAIL: "API_GET_NEWS_ARTICLE_FAIL",
  // get Industries
  API_GET_INDUSTRY_SUCCESS: "API_GET_INDUSTRY_SUCCESS",
  API_GET_INDUSTRY_LOAD: "API_GET_INDUSTRY_LOAD",
  API_GET_INDUSTRY_FAIL: "API_GET_INDUSTRY_FAIL",
  UPDATE_INDUSTRIES_DATA: "UPDATE_INDUSTRIES_DATA",

  //get Top Search
  API_GET_TOP_SEARCH_SUCCESS: "API_GET_TOP_SEARCH__SUCCESS",
  API_GET_TOP_SEARCH__LOAD: "API_GET_TOP_SEARCH_LOAD",
  API_GET_TOP_SEARCH__FAIL: "API_GET_TOP_SEARCH_FAIL",
  //get search categories
  API_GET_CATEGORIES_SUCCESS: " API_GET_CATEGORIES_SUCCESS",
  API_GET_CATEGORIES_LOAD: " API_GET_CATEGORIES_LOAD",
  API_GET_CATEGORIES_FAIL: " API_GET_CATEGORIES_FAIL",

  // select companies
  SELECT_COMPANIES: "SELECT_COMPANIES",
  SELECT_COMPANIES_RESET: "SELECT_COMPANIES_RESET",
  //select Top Search
  SELECTED_TOP_SEARCH: "SELECTED_TOP_SEARCH",
  SELECTED_TOP_SEARCH_RESET: "SELECTED_TOP_SEARCH_RESET",

  //selected categories
  SELECTED_CATEGORIES: "SELECTED_CATEGORIES",
  UPDATE_CATEGORIES_DATA: "UPDATE_CATEGORIES_DATA",
  // ****************SETTINGS******************

  // Get Profile

  API_GET_PROFILE_LOAD: "API_GET_PROFILE_LOAD",
  API_GET_PROFILE_DATA_SUCCESS: "API_GET_PROFILE_DATA_SUCCESS",
  API_GET_PROFILE_FAIL: "API_GET_PROFILE_FAIL",

  //save changes

  API_UPDATE_PROFILE_LOAD: "API_UPDATE_PROFILE_LOAD",
  API_UPDATE_PROFILE_FAIL: "API_UPDATE_PROFILE_FAIL",

  API_UPDATE_PROFILE_EMAIL_NOTIFICATION_LOAD:
    "API_UPDATE_PROFILE_EMAIL_NOTIFICATION_LOAD",
  API_UPDATE_PROFILE_EMAIL_NOTIFICATION_SUCCESS:
    "API_UPDATE_PROFILE_EMAIL_NOTIFICATION_SUCCESS",
  API_UPDATE_PROFILE_EMAIL_NOTIFICATION_FAIL:
    "API_UPDATE_PROFILE_EMAIL_NOTIFICATION_FAIL",

  API_PROFILE_DELETE_LOAD: "API_PROFILE_DELETE_LOAD",
  API_PROFILE_DELETE_SUCCESS: "API_PROFILE_DELETE_SUCCESS",

  // ***********************************************************Company Page***************************************************
  API_GET_COUNTRY_LIST_LOAD: "API_GET_COUNTRY_LIST_LOAD",
  API_GET_COUNTRY_LIST_SUCCESS: "API_GET_COUNTRY_LIST_SUCCESS",
  API_GET_COUNTRY_LIST_FAIL: "API_GET_COUNTRY_LIST_FAIL",

  API_UPDATE_COUNTRY_LIST: "API_UPDATE_COUNTRY_LIST",

  API_GET_COMPANY_DETAIL_SUCCESS: "API_GET_COMPANY_DETAIL_SUCCESS",
  API_GET_COMPANY_DETAIL_LOAD: "API_GET_COMPANY_DETAIL_LOAD",
  API_GET_COMPANY_DETAIL_FAIL: "API_GET_COMPANY_DETAIL_FAIL",

  // ***********************************************************Incident Details***************************************************
  API_INCIDENT_DETAILS_LOAD: "API_INCIDENT_DETAILS_LOAD ",
  API_INCIDENT_DETAILS_SUCCESS: "API_INCIDENT_DETAILS_SUCCESS",
  API_INCIDENT_DETAILS_FAIL: "API_INCIDENT_DETAILS_FAIL",
  // ***********************************************************Watch List***************************************************
  //add wishlist
  API_ADD_WISHLIST_LOAD: "API_ADD_WISHLIST_LOAD",
  API_ADD_WISHLIST_SUCCESS: "API_ADD_WISHLIST_SUCCESS",
  API_ADD_WISHLIST_FAIL: "API_ADD_WISHLIST_FAIL",

  //UPLOAD COMPANY wishlist
  UPLOAD_COMPANY_FILE_LOAD: "UPLOAD_COMPANY_FILE_LOAD",
  UPLOAD_COMPANY_FILE_SUCCESS: "UPLOAD_COMPANY_FILE_SUCCESS",
  UPLOAD_COMPANY_FILE_FAIL: "UPLOAD_COMPANY_FILE_FAIL",
  UPLOAD_FILE_LOAD: "UPLOAD_FILE_LOAD",

  //UPLOAD_WATCHLIST_COMPANY_FILE_LOAD
  UPLOAD_WATCHLIST_COMPANY_FILE_LOAD: "UPLOAD_WATCHLIST_COMPANY_FILE_LOAD",
  UPLOAD_WATCHLIST_COMPANY_FILE_SUCCESS:
    "UPLOAD_WATCHLIST_COMPANY_FILE_SUCCESS",
  UPLOAD_WATCHLIST_COMPANY_FILE_FAIL: "UPLOAD_WATCHLIST_COMPANY_FILE_FAIL",

  //get wish list
  API_GET_WISHLIST_LOAD: "API_GET_WISHLIST_LOAD",
  API_GET_WISHLIST_SUCCESS: "API_GET_WISHLIST_SUCCESS",
  API_GET_WISHLIST_FAIL: "API_GET_WISHLIST_FAIL",

  // get wish list details
  API_GET_WISHLIST_DETAILS_LOAD: "API_GET_WISHLIST_DETAILS_LOAD",
  API_GET_WISHLIST_DETAILS_SUCCESS: "API_GET_WISHLIST_DETAILS_SUCCESS",
  API_GET_WISHLIST_DETAILS_FAIL: "API_GET_WISHLIST_DETAILS_FAIL",
  API_GET_WISHLIST_DETAILS_UPDATE: "API_GET_WISHLIST_DETAILS_UPDATE",

  //delete wish List
  API_DELETE_WISHLIST_LOAD: "API_DELETE_WISHLIST_LOAD",
  API_DELETE_WISHLIST_SUCCESS: "API_DELETE_WISHLIST_SUCCESS",
  API_DELETE_WISHLIST_FAIL: "API_DELETE_WISHLIST_FAIL",

  //edit wish list
  API_EDIT_WISHLIST_LOAD: "API_EDIT_WISHLIST_LOAD",
  API_EDIT_WISHLIST_SUCCESS: "API_EDIT_WISHLIST_SUCCESS",
  API_EDIT_WISHLIST_FAIL: "API_EDIT_WISHLIST_FAIL",

  // get current watchlist id
  GET_CURRENT_WATCHLIST: "GET_CURRENT_WATCHLIST",
  SHOW_RESULT: "SHOW_RESULT",

  //  *************************************************Save Incidence************************************************ 12/7/2023 MM

  API_GET_COLLECTION_SUCCESS: "API_GET_COLLECTION_SUCCESS",
  API_GET_COLLECTION_LOAD: "API_GET_COLLECTION_LOAD",
  API_GET_COLLECTION_FAIL: "API_GET_COLLECTION_FAIL",

  // CREATE SAVE INCIDENCE COLLECTION

  API_CREATE_COLLECTION_LOAD: " API_CREATE_COLLECTION_LOAD",
  API_CREATE_COLLECTION_SUCCESS: " API_CREATE_COLLECTION_SUCCESS",
  API_CREATE_COLLECTION_FAIL: " API_CREATE_COLLECTION_FAIL",

  // supplyChain country list
  API_GET_SUPPLYCHAIN_COUNTRY_LIST_LOAD:
    "API_GET_SUPPLYCHAIN_COUNTRY_LIST_LOAD",
  API_GET_SUPPLYCHAIN_COUNTRY_LIST_SUCCESS:
    "API_GET_SUPPLYCHAIN_COUNTRY_LIST_SUCCESS",
  API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL:
    "API_GET_SUPPLYCHAIN_COUNTRY_LIST_FAIL",

  // ADD INCIDENCE TO COLLECTION
  API_ADD_TO_COLLECTION_LOAD: "API_ADD_TO_COLLECTION_LOAD",
  API_ADD_TO_COLLECTION_SUCCESS: "API_ADD_TO_COLLECTION_SUCCESS",
  API_ADD_TO_COLLECTION_FAIL: "API_ADD_TO_COLLECTION_FAIL",
  // DELETE INCIDENCE COLLECTION
  API_DELETE_COLLECTION_LOAD: "API_DELETE_COLLECTION_LOAD",
  API_DELETE_COLLECTION_SUCCESS: "API_DELETE_COLLECTION_SUCCESS",
  API_DELETE_COLLECTION_FAIL: "API_DELETE_COLLECTION_FAIL",

  UPDATE_INCIDENT_COLLECTION_SIDEBAR: "UPDATE_INCIDENT_COLLECTION_SIDEBAR",
  // REMOVE INCIDENCE COLLECTION
  API_REMOVE_COLLECTION_LOAD: "API_REMOVE_COLLECTION_LOAD",
  API_REMOVE_COLLECTION_SUCCESS: "API_REMOVE_COLLECTION_SUCCESS",
  API_REMOVE_COLLECTION_FAIL: "API_REMOVE_COLLECTION_FAIL",

  // remove incident from collection
  API_DELETE_INCIDENT_COLLECTION_LOAD: "API_DELETE_INCIDENT_COLLECTION_LOAD",
  API_DELETE_INCIDENT_COLLECTION_SUCCESS:
    "API_DELETE_INCIDENT_COLLECTION_SUCCESS",
  API_DELETE_INCIDENT_COLLECTION_FAIL: "API_DELETE_INCIDENT_COLLECTION_FAIL",

  //set selected collection
  SET_SELECTED_COLLECTION_LOAD: "SET_SELECTED_COLLECTION_LOAD",

  SET_SELECTED_WATCHLIST: "SET_SELECTED_WATCHLIST",

  //rename colelction name
  SET_RENAME_COLLECTION_LOAD: "SET_RENAME_COLLECTION_LOAD",
  SET_RENAME_COLLECTION_SUCCESS: "SET_RENAME_COLLECTION_SUCCESS",
  SET_RENAME_COLLECTION_FAIL: "SET_RENAME_COLLECTION_FAIL",
  UPDATE_RENAME_COLLECTION_SUCCESS: "UPDATE_RENAME_COLLECTION_SUCCESS",

  UPDATE_SAVE_INCIDENT: "UPDATE_SAVE_INCIDENT",

  API_GET_SupplyChain_Incident_LOAD: "API_GET_SupplyChain_Incident_LOAD",
  API_GET_SupplyChain_Incident_SUCCESS: "API_GET_SupplyChain_Incident_SUCCESS",
  API_GET_SupplyChain_Incident_FAIL: "API_GET_SupplyChain_Incident_FAIL",

  API_GET_Sentiment_Analysis_LOAD: "API_GET_Sentiment_Analysis_LOAD",
  API_GET_Sentiment_Analysis_SUCCESS: "API_GET_Sentiment_Analysis_SUCCESS",
  API_GET_Sentiment_Analysis_FAIL: "API_GET_Sentiment_Analysis_FAIL",

  API_GET_SentimentTrend_LOAD: "API_GET_SentimentTrend_LOAD",
  API_GET_SentimentTrend_SUCCESS: "API_GET_SentimentTrend_SUCCESS",
  API_GET_SentimentTrendt_FAIL: "API_GET_SentimentTrend_FAIL",

  API_GET_SENTIMENTSCORE_LOAD: "API_GET_SENTIMENTSCORE_LOAD",
  API_GET_SENTIMENTSCORE_SUCCESS: "API_GET_SENTIMENTSCORE_SUCCESS",
  API_GET_SENTIMENTSCORE_FAIL: "API_GET_SENTIMENTSCORE_FAIL",

  API_GET_INCIDENT_SEVERITY_LOAD: "API_GET_INCIDENT_SEVERITY_LOAD",
  API_GET_INCIDENT_SEVERITY_SUCCESS: "API_GET_INCIDENT_SEVERITY_SUCCESS",
  API_GET_INCIDENT_SEVERITY_FAIL: "API_GET_INCIDENT_SEVERITY_FAIL",

  API_GET_INCIDENT_WITHYEAR_LOAD: "API_GET_INCIDENT_WITHYEAR_LOAD",
  API_GET_INCIDENT_WITHYEAR_SUCCESS: "API_GET_INCIDENT_WITHYEAR_SUCCESS",
  API_GET_INCIDENT_WITHYEAR_FAIL: "API_GET_INCIDENT_WITHYEAR_FAIL",

  API_GET_INCIDENT_CATEGORIZATION_LOAD: "API_GET_INCIDENT_CATEGORIZATION_LOAD",
  API_GET_INCIDENT_CATEGORIZATION_SUCCESS:
    "API_GET_INCIDENT_CATEGORIZATION_SUCCESS",
  API_GET_INCIDENT_CATEGORIZATION_FAIL: "API_GET_INCIDENT_CATEGORIZATION_FAIL",

  // Peer Companies’ Incident Rate
  API_GET_PEER_COMPANIES_INCIDENT_RATE_LOAD:
    "API_GET_PEER_COMPANIES_INCIDENT_RATE_LOAD",
  API_GET_PEER_COMPANIES_INCIDENT_RATE_SUCCESS:
    "API_GET_PEER_COMPANIES_INCIDENT_RATE_SUCCESS",
  API_GET_PEER_COMPANIES_INCIDENT_RATE_FAIL:
    "API_GET_PEER_COMPANIES_INCIDENT_RATE_FAIL",

  API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_LOAD:
    "API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_LOAD",
  API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_SUCCESS:
    "API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_SUCCESS",
  API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_FAIL:
    "API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_FAIL",

  API_GET_MOST_USED_WORDS_LOAD: "API_GET_MOST_USED_WORDS_LOAD",
  API_GET_MOST_USED_WORDS_SUCCESS: "API_GET_MOST_USED_WORDS_SUCCESS",
  API_GET_MOST_USED_WORDS_FAIL: "API_GET_MOST_USED_WORDS_FAIL",

  API_GET_SERVITY_TREND_LOAD: "API_GET_SERVITY_TREND_LOAD",
  API_GET_SERVITY_TREND_SUCCESS: "API_GET_SERVITY_TREND_SUCCESS",
  API_GET_SERVITY_TREND_FAIL: "API_GET_SERVITY_TREND_FAIL",

  //Sevierty MAp
  API_GET_SEVIERTY_ONMAP_LOAD: "API_GET_SEVIERTY_ONMAP_LOAD",
  API_GET_SEVIERTY_ONMAP_SUCCESS: "API_GET_SEVIERTY_ONMAP_SUCCESS",
  API_GET_SEVIERTY_ONMAP_FAIL: "API_GET_SEVIERTY_ONMAP_FAIL",

  //Incident Map Data
  API_INCIDENT_MAP_DATA_SUCCESS: "API_INCIDENT_MAP_DATA_SUCCESS",
  API_INCIDENT_MAP_DATA_FAIL: "API_INCIDENT_MAP_DATA_FAIL",
  API_INCIDENT_MAP_DATA_LOAD: "API_INCIDENT_MAP_DATA_LOAD",
  GOOGLE_MAP_VIEW_STATE: "GOOGLE_MAP_VIEW_STATE",
  API_MAP_DATA_SUCCESS: "API_MAP_DATA_SUCCESS",
  RESET_MAP_DATA: "RESET_MAP_DATA",
  API_GET_INCIDENT_COMPARISON_LOAD: "API_GET_INCIDENT_COMPARISON_LOAD",
  API_GET_INCIDENT_COMPARISON_SUCCESS: "API_GET_INCIDENT_COMPARISON_SUCCESS",
  API_GET_INCIDENT_COMPARISON_FAIL: "API_GET_INCIDENT_COMPARISON_FAIL",
  REMOVE_INCIDENT_COMPARISON: "REMOVE_INCIDENT_COMPARISON",

  RESET_DATA: "RESET_DATA",

  API_GET_SERVERITY_MAP_LOAD: "API_GET_SERVERITY_MAP_LOAD",
  API_GET_SERVERITY_MAP_SUCCESS: "API_GET_SERVERITY_MAP_SUCCESS",
  API_GET_SERVERITY_MAP_FAIL: "API_GET_SERVERITY_MAP_FAIL",
  //GRAPH VIEW
  API_GET_GRAPH_DATA_SUCCESS: "API_GET_GRAPH_DATA_SUCCESS",
  API_GET_GRAPH_DATA_FAIL: "API_GET_GRAPH_DATA_FAIL",
  API_GET_GRAPH_DATA_LOAD: "API_GET_GRAPH_DATA_LOAD",

  API_GET_SUPPLY_CHAIN_SUCCESS: "API_GET_SUPPLY_CHAIN_SUCCESS",
  API_GET_SUPPLY_CHAIN_FAIL: "API_GET_SUPPLY_CHAIN_FAIL",
  API_GET_SUPPLY_CHAIN_LOAD: "API_GET_SUPPLY_CHAIN_LOAD",

  API_GET_DOWNLOAD_INCIDENT_LOAD: "API_GET_DOWNLOAD_INCIDENT_LOAD",
  API_GET_DOWNLOAD_INCIDENT_SUCCESS: "API_GET_DOWNLOAD_INCIDENT_SUCCESS",
  API_GET_DOWNLOAD_INCIDENT_FAIL: "API_GET_DOWNLOAD_INCIDENT_FAIL",

  //search by params
  API_SEARCH_BY_PARAMS_LOAD: "API_SEARCH_BY_PARAMS_LOAD",
  API_SEARCH_BY_PARAMS_SUCCESS: "API_SEARCH_BY_PARAMS_SUCCESS",
  API_SEARCH_BY_PARAMS_FAIL: "API_SEARCH_BY_PARAMS_FAIL",

  API_GET_EXPORT_INCIDENT_LOAD: "API_GET_EXPORT_INCIDENT_LOAD",
  API_GET_EXPORT_INCIDENT_SUCCESS: "API_GET_EXPORT_INCIDENT_SUCCESS",
  API_GET_EXPORT_INCIDENT_FAIL: "API_GET_EXPORT_INCIDENT_FAIL",
  //get Incident Timeline

  API_GET_INCIDENT_TIMELINE_LOAD: "API_GET_INCIDENT_TIMELINE_LOAD",
  API_GET_INCIDENT_TIMELINE_SUCCESS: "API_GET_INCIDENT_TIMELINE_SUCCESS",
  API_GET_INCIDENT_TIMELINE_FAIL: "API_GET_INCIDENT_TIMELINE_FAIL",
  //get esg factors
  API_GET_ESG_FACTORS_LOAD: "API_GET_ESG_FACTORS_LOAD",
  API_GET_ESG_FACTORS_SUCCESS: "API_GET_ESG_FACTORS_SUCCESS",
  API_GET_ESG_FACTORS_FAIL: "API_GET_ESG_FACTORS_FAIL",

  API_GET_ESG_COUNRTIES_LOAD: "API_GET_ESG_COUNRTIES_LOAD",
  API_GET_ESG_COUNRTIES_SUCCESS: "API_GET_ESG_COUNRTIES_SUCCESS",
  API_GET_ESG_COUNRTIES_FAIL: "API_GET_ESG_COUNRTIES_FAIL",

  API_ESG_CHILD_LIST_FOR_MODAL_LOAD: "API_ESG_CHILD_LIST_FOR_MODAL_LOAD",
  API_ESG_CHILD_LIST_FOR_MODAL_SUCCESS: "API_ESG_CHILD_LIST_FOR_MODAL_SUCCESS",
  API_ESG_CHILD_LIST_FOR_MODAL_FAIL: "API_ESG_CHILD_LIST_FOR_MODAL_FAIL",

  API_COUNTRIES_LIST_FOR_MODAL_LOAD: "API_COUNTRIES_LIST_FOR_MODAL_LOAD",
  API_COUNTRIES_LIST_FOR_MODAL_SUCCESS: "API_COUNTRIES_LIST_FOR_MODAL_SUCCESS",
  API_COUNTRIES_LIST_FOR_MODAL_FAIL: "API_COUNTRIES_LIST_FOR_MODAL_FAIL",

  API_COMPANIES_LIST_FOR_MODAL_LOAD: "API_COMPANIES_LIST_FOR_MODAL_LOAD",
  API_COMPANIES_LIST_FOR_MODAL_SUCCESS: "API_COMPANIES_LIST_FOR_MODAL_SUCCESS",
  API_COMPANIES_LIST_FOR_MODAL_FAIL: "API_COMPANIES_LIST_FOR_MODAL_FAIL",

  API_INCIDENT_LIST_FOR_MODAL_LOAD: "API_INCIDENT_LIST_FOR_MODAL_LOAD",
  API_INCIDENT_LIST_FOR_MODAL_SUCCESS: "API_INCIDENT_LIST_FOR_MODAL_SUCCESS",
  API_INCIDENT_LIST_FOR_MODAL_FAIL: "API_INCIDENT_LIST_FOR_MODAL_FAIL",

  INCIDENT_LIST_LOADER: "INCIDENT_LIST_LOADER",

  //COMPANY NEW
  GET_ESG_RISK_SCORE_LOAD: "GET_ESG_RISK_SCORE_LOAD",
  GET_ESG_RISK_SCORE_SUCCESS: "GET_ESG_RISK_SCORE_SUCCESS",

  GET_REGULATORY_FINES_PENALTIES_LOAD: "GET_REGULATORY_FINES_PENALTIES_LOAD",
  GET_REGULATORY_FINES_PENALTIES_SUCCESS:
    "GET_REGULATORY_FINES_PENALTIES_SUCCESS",

  GET_REGULATORY_LEGAL_ACTION_LOAD: "GET_REGULATORY_LEGAL_ACTION_LOAD",
  GET_REGULATORY_LEGAL_ACTION_SUCCESS: "GET_REGULATORY_LEGAL_ACTION_SUCCESS",

  GET_SOCIAL_MEDIA_COVER_LOAD: "GET_SOCIAL_MEDIA_COVER_LOAD",
  GET_SOCIAL_MEDIA_COVER_SUCCESS: "GET_SOCIAL_MEDIA_COVER_SUCCESS",
  GET_UNGCP_BREACHES_LOAD: "GET_UNGCP_BREACHES_LOAD",
  GET_UNGCP_BREACHES_SUCCESS: "GET_UNGCP_BREACHES_SUCCESS",

  GET_SOCIAL_MEDIA_INCIDENT_LOAD: "GET_SOCIAL_MEDIA_INCIDENT_LOAD",
  GET_SOCIAL_MEDIA_INCIDENT_SUCCESS: "GET_SOCIAL_MEDIA_INCIDENT_SUCCESS",

  GET_FINE_AND_PANELTY_LOAD: "GET_FINE_AND_PANELTY_LOAD",
  GET_FINE_AND_PANELTY_SUCCESS: "GET_FINE_AND_PANELTY_SUCCESS",

  GET_REGULATORY_LEGAL_LOAD: "GET_REGULATORY_LEGAL_LOAD",
  GET_REGULATORY_LEGAL_SUCCESS: "GET_REGULATORY_LEGAL_SUCCESS",

  GET_TIMELINE_GRAPH_LOAD: "GET_TIMELINE_GRAPH_LOAD",
  GET_TIMELINE_GRAPH_SUCCESS: "GET_TIMELINE_GRAPH_SUCCESS",

  POST_FEEDBACK_LOAD: "POST_FEEDBACK_LOAD",
  POST_FEEDBACK_SUCCESS: "POST_FEEDBACK_SUCCESS",

  // Portfolio
  GET_PORTFOLIO_COMPANIES_SEARCH_LIST_INITIATE:
    "GET_PORTFOLIO_COMPANIES_SEARCH_LIST_INITIATE",
  GET_PORTFOLIO_COMPANIES_SEARCH_LIST_SUCCESS:
    "GET_PORTFOLIO_COMPANIES_SEARCH_LIST_SUCCESS",
  GET_PORTFOLIO_COMPANIES_SEARCH_LIST_FAILURE:
    "GET_PORTFOLIO_COMPANIES_SEARCH_LIST_FAILURE",

  GET_PORTFOLIO_LIST_LOAD: "GET_PORTFOLIO_LIST_LOAD",
  GET_PORTFOLIO_LIST_SUCCESS: "GET_PORTFOLIO_LIST_SUCCESS",
  GET_PORTFOLIO_LIST_FAIL: "GET_PORTFOLIO_LIST_FAIL",

  PORTFOLIO_CHANGE_CLR_LOAD: "PORTFOLIO_CHANGE_CLR_LOAD",
  PORTFOLIO_CHANGE_CLR_SUCCESS: "PORTFOLIO_CHANGE_CLR_SUCCESS",
  PORTFOLIO_CHANGE_CLR_FAIL: "PORTFOLIO_CHANGE_CLR_FAIL",

  ADD_PORTFOLIO_LOAD: "ADD_PORTFOLIO_LOAD",
  ADD_PORTFOLIO_SUCCESS: "ADD_PORTFOLIO_SUCCESS",
  ADD_PORTFOLIO_FAIL: "ADD_PORTFOLIO_FAIL",

  EDIT_PORTFOLIO_LOAD: "EDIT_PORTFOLIO_LOAD",
  EDIT_PORTFOLIO_SUCCESS: "EDIT_PORTFOLIO_SUCCESS",
  EDIT_PORTFOLIO_FAIL: "EDIT_PORTFOLIO_FAIL",

  PORTFOLIO_COMP_LOAD: "PORTFOLIO_COMP_LOAD",
  PORTFOLIO_COMP_SUCCESS: "PORTFOLIO_COMP_SUCCESS",
  PORTFOLIO_COMP_FAIL: "PORTFOLIO_COMP_FAIL",

  MOST_FREQUENT_ESG_LOAD: "MOST_FREQUENT_ESG_LOAD",
  MOST_FREQUENT_ESG_SUCCESS: "MOST_FREQUENT_ESG_SUCCESS",
  MOST_FREQUENT_ESG_FAIL: "MOST_FREQUENT_ESG_FAIL",

  PORTFOLIO_DETAIL_LOAD: "PORTFOLIO_DETAIL_LOAD",
  PORTFOLIO_DETAIL_SUCCESS: "PORTFOLIO_DETAIL_SUCCESS",
  PORTFOLIO_DETAIL_FAIL: "PORTFOLIO_DETAIL_FAIL",

  UN_GLOBAL_LOAD: "UN_GLOBAL_LOAD",
  UN_GLOBAL_SUCCESS: "UN_GLOBAL_SUCCESS",
  UN_GLOBAL_FAIL: "UN_GLOBAL_FAIL",

  ALERT_REPORT_LOAD: "ALERT_REPORT_LOAD",
  ALERT_REPORT_SUCCESS: "ALERT_REPORT_SUCCESS",
  ALERT_REPORT_FAIL: "ALERT_REPORT_FAIL",

  PORTFOLIO_ESG_RISK_LOAD: "PORTFOLIO_ESG_RISK_LOAD",
  PORTFOLIO_ESG_RISK_SUCCESS: "PORTFOLIO_ESG_RISK_SUCCESS",
  PORTFOLIO_ESG_RISK_FAIL: "PORTFOLIO_ESG_RISK_FAIL",

  RISK_SCORE_PER_COMP_LOAD: "RISK_SCORE_PER_COMP_LOAD",
  RISK_SCORE_PER_COMP_SUCCESS: "RISK_SCORE_PER_COMP_SUCCESS",
  RISK_SCORE_PER_COMP_FAIL: "RISK_SCORE_PER_COMP_FAIL",

  HIGH_SEVERITY_INCIDENT_LOAD: "HIGH_SEVERITY_INCIDENT_LOAD",
  HIGH_SEVERITY_INCIDENT_SUCCESS: "HIGH_SEVERITY_INCIDENT_SUCCESS",
  HIGH_SEVERITY_INCIDENT_FAIL: "HIGH_SEVERITY_INCIDENT_FAIL",

  UPLOAD_FILE_PORTFOLIO_LOAD: "UPLOAD_FILE_PORTFOLIO_LOAD",
  UPLOAD_FILE_PORTFOLIO_SUCCESS: "UPLOAD_FILE_PORTFOLIO_SUCCESS",
  UPLOAD_FILE_PORTFOLIO_FAIL: "UPLOAD_FILE_PORTFOLIO_FAIL",
  UPDATE_UPLOAD_FILE_PORTFOLIO_SUCCESS: "UPDATE_UPLOAD_FILE_PORTFOLIO_SUCCESS",

  DELETE_PORTFOLIO_LOAD: "DELETE_PORTFOLIO_LOAD",
  DELETE_PORTFOLIO_SUCCESS: "DELETE_PORTFOLIO_SUCCESS",
  DELETE_PORTFOLIO_FAIL: "DELETE_PORTFOLIO_FAIL",

  GET_EDIT_PORTFOLIO_DETAIL_LOAD: "GET_EDIT_PORTFOLIO_DETAIL_LOAD",
  GET_EDIT_PORTFOLIO_DETAIL_SUCCESS: "GET_EDIT_PORTFOLIO_DETAIL_SUCCESS",
  GET_EDIT_PORTFOLIO_DETAIL_FAIL: "GET_EDIT_PORTFOLIO_DETAIL_FAIL",

  DISTRIBUTION_GRAPH_LOAD: "DISTRIBUTION_GRAPH_LOAD",
  DISTRIBUTION_GRAPH_SUCCESS: "DISTRIBUTION_GRAPH_SUCCESS",
  DISTRIBUTION_GRAPH_FAIL: "DISTRIBUTION_GRAPH_FAIL",

  CLEAR_ALERT_RULES_LOAD: "CLEAR_ALERT_RULES_LOAD",
  CLEAR_ALERT_RULES_SUCCESS: "CLEAR_ALERT_RULES_SUCCESS",
  CLEAR_ALERT_RULES_FAIL: "CLEAR_ALERT_RULES_FAIL",

  CHECK_VALID_COMPNY_LOAD: "CHECK_VALID_COMPNY_LOAD",
  CHECK_VALID_COMPNY_SUCCESS: "CHECK_VALID_COMPNY_SUCCESS",
  CHECK_VALID_COMPNY_FAIL: "CHECK_VALID_COMPNY_FAIL",

  GET_PORTFOLIO_STATUS_LOAD: "GET_PORTFOLIO_STATUS_LOAD",
  GET_PORTFOLIO_STATUS_SUCCESS: "GET_PORTFOLIO_STATUS_SUCCESS",
  GET_PORTFOLIO_STATUS_FAIL: "GET_PORTFOLIO_STATUS_FAIL",

  GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_LOAD:
    "GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_LOAD",
  GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_SUCCESS:
    "GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_SUCCESS",
  GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_FAIL:
    "GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_FAIL",

  UPDATE_PORTFOLIO_STATE: "UPDATE_PORTFOLIO_STATE",

  API_GET_INDUSTRIES_LOAD: "API_GET_INDUSTRIES_LOAD",
  API_GET_INDUSTRIES_SUCCESS: "API_GET_INDUSTRIES_SUCCESS",

  GET_PORTFOLIO_COMP_LOAD: "GET_PORTFOLIO_COMP_LOAD",
  GET_PORTFOLIO_COMP_SUCCESS: "GET_PORTFOLIO_COMP_SUCCESS",

  // Social Media
  GET_SOCIAL_MEDIA_TAGS_LOAD: "GET_SOCIAL_MEDIA_TAGS_LOAD",
  GET_SOCIAL_MEDIA_TAGS_SUCCESS: "GET_SOCIAL_MEDIA_TAGS_SUCCESS",
  GET_SOCIAL_MEDIA_TAGS_FAILURE: "GET_SOCIAL_MEDIA_TAGS_FAILURE",

  GET_TWEET_WITH_REPLIES_LOAD: "GET_TWEET_WITH_REPLIES_LOAD",
  GET_TWEET_WITH_REPLIES_SUCCESS: "GET_TWEET_WITH_REPLIES_SUCCESS",
  GET_TWEET_WITH_REPLIES_FAILURE: "GET_TWEET_WITH_REPLIES_FAILURE",

  GET_TWEET_INDIVIDUAL_REPLY_LOAD: "GET_TWEET_INDIVIDUAL_REPLY_LOAD",
  GET_TWEET_INDIVIDUAL_REPLY_SUCCESS: "GET_TWEET_INDIVIDUAL_REPLY_SUCCESS",
  GET_TWEET_INDIVIDUAL_REPLY_FAILURE: "GET_TWEET_INDIVIDUAL_REPLY_FAILURE",

  GET_SOCIAL_MEDIA_REACTIONS_LOAD: "GET_SOCIAL_MEDIA_REACTIONS_LOAD",
  GET_SOCIAL_MEDIA_REACTIONS_SUCCESS: "GET_SOCIAL_MEDIA_REACTIONS_SUCCESS",
  GET_SOCIAL_MEDIA_REACTIONS_FAILURE: "GET_SOCIAL_MEDIA_REACTIONS_FAILURE",

  GET_OVERALL_REACTION_SENTIMENT_LOAD: "GET_OVERALL_REACTION_SENTIMENT_LOAD",
  GET_OVERALL_REACTION_SENTIMENT_SUCCESS:
    "GET_OVERALL_REACTION_SENTIMENT_SUCCESS",
  GET_OVERALL_REACTION_SENTIMENT_FAILURE:
    "GET_OVERALL_REACTION_SENTIMENT_FAILURE",

  GET_OVERALL_REDDIT_REACTION_LOAD: "GET_OVERALL_REDDIT_REACTION_LOAD",
  GET_OVERALL_REDDIT_REACTION_SUCCESS: "GET_OVERALL_REDDIT_REACTION_SUCCESS",
  GET_OVERALL_REDDIT_REACTION_FAILURE: "GET_OVERALL_REDDIT_REACTION_FAILURE",

  GET_RADAR_DATA_LOAD: "GET_RADAR_DATA_LOAD",
  GET_RADAR_DATA_SUCCESS: "GET_RADAR_DATA_SUCCESS",
  GET_RADAR_DATA_FAILURE: "GET_RADAR_DATA_FAILURE",

  TOTAL_TWEET_REACTION_LOAD: "TOTAL_TWEET_REACTION_LOAD",
  TOTAL_TWEET_REACTION_SUCCESS: "TOTAL_TWEET_REACTION_SUCCESS",
  TOTAL_TWEET_REACTION_FAILURE: "TOTAL_TWEET_REACTION_FAILURE",

  TOTAL_REDDIT_REACTION_LOAD: "TOTAL_REDDIT_REACTION_LOAD",
  TOTAL_REDDIT_REACTION_SUCCESS: "TOTAL_REDDIT_REACTION_SUCCESS",
  TOTAL_REDDIT_REACTION_FAILURE: "TOTAL_REDDIT_REACTION_FAILURE",

  TOTAL_SOCIAL_MEDIA_REACTION_LOAD: "TOTAL_SOCIAL_MEDIA_REACTION_LOAD",
  TOTAL_SOCIAL_MEDIA_REACTION_SUCCESS: "TOTAL_SOCIAL_MEDIA_REACTION_SUCCESS",
  TOTAL_SOCIAL_MEDIA_REACTION_FAILURE: "TOTAL_SOCIAL_MEDIA_REACTION_FAILURE",

  GET_REDDIT_WITH_POST_LOAD: "GET_REDDIT_WITH_POST_LOAD",
  GET_REDDIT_WITH_POST_SUCCESS: "GET_REDDIT_WITH_POST_SUCCESS",
  GET_REDDIT_WITH_POST_FAIL: "GET_REDDIT_WITH_POST_FAIL",

  GET_REDDIT_POST_COMMENT_LOAD: "GET_REDDIT_POST_COMMENT_LOAD",
  GET_REDDIT_POST_COMMENT_SUCCESS: "GET_REDDIT_POST_COMMENT_SUCCESS",
  GET_REDDIT_POST_COMMENT_FAIL: "GET_REDDIT_POST_COMMENT_FAIL",

  GET_MOST_INFLUENTIAL_USER_LOAD: "GET_MOST_INFLUENTIAL_USER_LOAD",
  GET_MOST_INFLUENTIAL_USER_SUCCESS: "GET_MOST_INFLUENTIAL_USER_SUCCESS",
  GET_MOST_INFLUENTIAL_USER_FAIL: "GET_MOST_INFLUENTIAL_USER_FAIL",

  GET_SUMMARY_DATA_LOAD: "GET_SUMMARY_DATA_LOAD",
  GET_SUMMARY_DATA_SUCCESS: "GET_SUMMARY_DATA_SUCCESS",
  GET_SUMMARY_DATA_FAIL: "GET_SUMMARY_DATA_FAIL",

  UPDATE_SOCIAL_MEDIA_STATE: "UPDATE_SOCIAL_MEDIA_STATE",
  API_INCIDENT_SEVERITY_CHART_LOAD: "API_INCIDENT_SEVERITY_CHART_LOAD",
  API_INCIDENT_SEVERITY_CHART_SUCCESS: "API_INCIDENT_SEVERITY_CHART_SUCCESS",

  API_SUPPLY_RISK_COMPANY_LOAD: "API_SUPPLY_RISK_COMPANY_LOAD",
  API_SUPPLY_RISK_COMPANY_SUCCESS: "API_SUPPLY_RISK_COMPANY_SUCCESS",

  API_SUPPLY_RISK_CHART_LOAD: "API_SUPPLY_RISK_CHART_LOAD",
  API_SUPPLY_RISK_CHART_SUCCESS: "API_SUPPLY_RISK_CHART_SUCCESS",

  API_GET_SUPPLY_CHAIN_LIST_LOAD: "API_GET_SUPPLY_CHAIN_LIST_LOAD",
  API_GET_SUPPLY_CHAIN_LIST_SUCCESS: "API_GET_SUPPLY_CHAIN_LIST_SUCCESS",

  API_GET_SUPPLY_CHAIN_HISTORY_LOAD: "API_GET_SUPPLY_CHAIN_HISTORY_LOAD",
  API_GET_SUPPLY_CHAIN_HISTORY_SUCCESS: "API_GET_SUPPLY_CHAIN_HISTORY_SUCCESS",

  API_CLEAR_SUPPLY_CHAIN_HISTORY_LOAD: "API_CLEAR_SUPPLY_CHAIN_HISTORY_LOAD",
  API_CLEAR_SUPPLY_CHAIN_HISTORY_SUCCESS:
    "API_CLEAR_SUPPLY_CHAIN_HISTORY_SUCCESS",
  API_CLEAR_SUPPLY_CHAIN_HISTORY_LOAD: "API_CLEAR_SUPPLY_CHAIN_HISTORY_LOAD",
  API_CLEAR_SUPPLY_CHAIN_HISTORY_SUCCESS:
    "API_CLEAR_SUPPLY_CHAIN_HISTORY_SUCCESS",

  FIND_PATH_LOAD: "FIND_PATH_LOAD",
  FIND_PATH_SUCCESS: "FIND_PATH_SUCCESS",

  API_UPDATE_MODAL_WISHLIST_DETAILS: "API_UPDATE_MODAL_WISHLIST_DETAILS",

  DELET_UPLOADED_DATA_LOAD: "DELET_UPLOADED_DATA_LOAD",
  DELET_UPLOADED_DATA_SUCCESS: "DELET_UPLOADED_DATA_SUCCESS",
  DELET_UPLOADED_DATA_FAIL: "DELET_UPLOADED_DATA_FAIL",

  // Advance Search
  GET_ADVNC_TOP_SEARCH_DATA_LOAD: "GET_ADVNC_TOP_SEARCH_DATA_LOAD",
  GET_ADVNC_TOP_SEARCH_DATA_SUCCESS: "GET_ADVNC_TOP_SEARCH_DATA_SUCCESS",
  GET_ADVNC_TOP_SEARCH_DATA_FAILURE: "GET_ADVNC_TOP_SEARCH_DATA_FAILURE",

  GET_ADVNC_COMPANY_DATA_LOAD: "GET_ADVNC_COMPANY_DATA_LOAD",
  GET_ADVNC_COMPANY_DATA_SUCCESS: "GET_ADVNC_COMPANY_DATA_SUCCESS",
  GET_UPDATE_ADVNC_COMPANY_DATA_SUCCESS: "GET_UPDATE_ADVNC_COMPANY_DATA_SUCCESS",
  GET_ADVNC_COMPANY_DATA_FAILURE: "GET_ADVNC_COMPANY_DATA_FAILURE",
  UPDATE_ADVNC_COMPANY_DATA_SUCCESS: "UPDATE_ADVNC_COMPANY_DATA_SUCCESS",

  GET_ADVNC_INC_LOCATION_DATA_LOAD: "GET_ADVNC_INC_LOCATION_DATA_LOAD",
  GET_ADVNC_INC_LOCATION_DATA_SUCCESS: "GET_ADVNC_INC_LOCATION_DATA_SUCCESS",
  GET_ADVNC_INC_LOCATION_DATA_FAILURE: "GET_ADVNC_INC_LOCATION_DATA_FAILURE",

  GET_ADVNC_CATEGORY_DATA_LOAD: "GET_ADVNC_CATEGORY_DATA_LOAD",
  GET_ADVNC_CATEGORY_DATA_SUCCESS: "GET_ADVNC_CATEGORY_DATA_SUCCESS",
  GET_ADVNC_CATEGORY_DATA_FAILURE: "GET_ADVNC_CATEGORY_DATA_FAILURE",

  GET_ADVNC_INDUSTRY_DATA_LOAD: "GET_ADVNC_INDUSTRY_DATA_LOAD",
  GET_ADVNC_INDUSTRY_DATA_SUCCESS: "GET_ADVNC_INDUSTRY_DATA_SUCCESS",
  GET_ADVNC_INDUSTRY_DATA_FAILURE: "GET_ADVNC_INDUSTRY_DATA_FAILURE",

  UPDATE_ADVNC_INDUSTRY_DATA_SUCCESS: "UPDATE_ADVNC_INDUSTRY_DATA_SUCCESS",

  UPDATE_INCIDENT_LOCATION_DATA: "UPDATE_INCIDENT_LOCATION_DATA",

  UPDATE_CATEGORIES_DATA_ADVANCE: "UPDATE_CATEGORIES_DATA_ADVANCE",

  UPDATE_ADVANCE_SEARCH_DATA: "UPDATE_ADVANCE_SEARCH_DATA",

  UPDATE_WATCHLIST_INDUSTRIES: "UPDATE_WATCHLIST_INDUSTRIES",
  DIRECT_UPDATE_WATCHLIST_INDUSTRIES: "DIRECT_UPDATE_WATCHLIST_INDUSTRIES",
  REMOVE_WATCHLIST_INDUSTRIES: "REMOVE_WATCHLIST_INDUSTRIES",
  API_UPDATE_INDUS_WISHLIST_DETAILS: "API_UPDATE_INDUS_WISHLIST_DETAILS",
  CHECK_UPDATE_INDUS_WISHLIST_DETAILS: "CHECK_UPDATE_INDUS_WISHLIST_DETAILS",

  UPDATE_WATCHLIST_SEVER_LOAD: "UPDATE_WATCHLIST_SEVER_LOAD",
  UPDATE_WATCHLIST_SEVER_SUCCESS: "UPDATE_WATCHLIST_SEVER_SUCCESS",
  UPDATE_WATCHLIST_COMPANY: "UPDATE_WATCHLIST_COMPANY",
  UPDATE_WATCHLIST_INDUS_SEARCH: "UPDATE_WATCHLIST_INDUS_SEARCH",

  HOME_ESG_RISK_LOAD: "HOME_ESG_RISK_LOAD",
  HOME_ESG_RISK_SUCCESS: "HOME_ESG_RISK_SUCCESS",
  HOME_ESG_RISK_FAIL: " HOME_ESG_RISK_FAIL",

  HOME_HIGH_SEVERITY_LOAD: "HOME_HIGH_SEVERITY_LOAD",
  HOME_HIGH_SEVERITY_SUCCESS: "HOME_HIGH_SEVERITY_SUCCESS",

  API_HOME_ESG_IOT_LOAD: "API_HOME_ESG_IOT_LOAD",
  API_HOME_ESG_IOT_SUCCESS: "API_HOME_ESG_IOT_SUCCESS",

  INCIDENT_BY_CATEGORY_LOAD: "INCIDENT_BY_CATEGORY_LOAD",
  INCIDENT_BY_CATEGORY_SUCCESS: "INCIDENT_BY_CATEGORY_SUCCESS",

  HOME_SOCIAL_MEDIA_REACTIONS_LOAD: "HOME_SOCIAL_MEDIA_REACTIONS_LOAD",
  HOME_SOCIAL_MEDIA_REACTIONS_SUCCESS: "HOME_SOCIAL_MEDIA_REACTIONS_SUCCESS",

  API_GET_DASHBOARD_LOAD: "API_GET_DASHBOARD_LOAD",
  API_GET_DASHBOARD_SUCCESS: "API_GET_DASHBOARD_SUCCESS",

  API_GET_DASHBOARD_WATCH_LOAD: "API_GET_DASHBOARD_WATCH_LOAD",
  API_GET_DASHBOARD_WATCH_SUCCESS: "API_GET_DASHBOARD_WATCH_SUCCESS",

  SEARCH_COMPANY_LIST_LOAD: "SEARCH_COMPANY_LIST_LOAD",
  SEARCH_COMPANY_LIST_FAIL: "SEARCH_COMPANY_LIST_FAIL",
  SEARCH_COMPANY_LIST_SUCCESS: "SEARCH_COMPANY_LIST_SUCCESS",

  SOCIAL_MEDIA_MAP_LOAD: "SOCIAL_MEDIA_MAP_LOAD",
  SOCIAL_MEDIA_MAP_SUCCESS: "SOCIAL_MEDIA_MAP_SUCCESS",

  GET_RISK_TREND_LOAD: "GET_RISK_TREND_LOAD",
  GET_RISK_TREND_SUCCESS: "GET_RISK_TREND_SUCCESS",
  GET_COMPANY_COUNT_LOAD: "GET_COMPANY_COUNT_LOAD",
  GET_COMPANY_COUNT_SUCCESS: "GET_COMPANY_COUNT_SUCCESS",

  // Corporate Profile
  GET_CORP_SUPPLY_CHAIN_DETAIL_LOAD: "GET_CORP_SUPPLY_CHAIN_DETAIL_LOAD",
  GET_CORP_SUPPLY_CHAIN_DETAIL_SUCCESS: "GET_CORP_SUPPLY_CHAIN_DETAIL_SUCCESS",
  GET_CORP_SUPPLY_CHAIN_DETAIL_FAIL: "GET_CORP_SUPPLY_CHAIN_DETAIL_FAIL",

  GET_CORP_RISK_CHART_DETAIL_LOAD: "GET_CORP_RISK_CHART_DETAIL_LOAD",
  GET_CORP_RISK_CHART_DETAIL_SUCCESS: "GET_CORP_RISK_CHART_DETAIL_SUCCESS",
  GET_CORP_RISK_CHART_DETAIL_FAIL: "GET_CORP_RISK_CHART_DETAIL_FAIL",

  GET_CORP_RISK_PIE_CHART_LOAD: "GET_CORP_RISK_PIE_CHART_LOAD",
  GET_CORP_RISK_PIE_CHART_SUCCESS: "GET_CORP_RISK_PIE_CHART_SUCCESS",
  GET_CORP_RISK_PIE_CHART_FAIL: "GET_CORP_RISK_PIE_CHART_FAIL",

  GET_CORP_INICDENTS_BAR_LOAD: "GET_CORP_INICDENTS_BAR_LOAD",
  GET_CORP_INICDENTS_BAR_SUCCESS: "GET_CORP_INICDENTS_BAR_SUCCESS",
  GET_CORP_INICDENTS_BAR_FAIL: "GET_CORP_INICDENTS_BAR_FAIL",

  GET_CORP_RISK_SCORE_BAR_LOAD: "GET_CORP_RISK_SCORE_BAR_LOAD",
  GET_CORP_RISK_SCORE_BAR_SUCCESS: "GET_CORP_RISK_SCORE_BAR_SUCCESS",
  GET_CORP_RISK_SCORE_BAR_FAIL: "GET_CORP_RISK_SCORE_BAR_FAIL",

  GET_CORP_INICDENTS_ANALYSIS_LOAD: "GET_CORP_INICDENTS_ANALYSIS_LOAD",
  GET_CORP_INICDENTS_ANALYSIS_SUCCESS: "GET_CORP_INICDENTS_ANALYSIS_SUCCESS",
  GET_CORP_INICDENTS_ANALYSIS_FAIL: "GET_CORP_INICDENTS_ANALYSIS_FAIL",

  GET_CORP_ESG_FACTORES_LOAD: "GET_CORP_ESG_FACTORES_LOAD",
  GET_CORP_ESG_FACTORES_SUCCESS: "GET_CORP_ESG_FACTORES_SUCCESS",
  GET_CORP_ESG_FACTORES_FAIL: "GET_CORP_ESG_FACTORES_FAIL",

  GET_CORP_SDG_LOAD: "GET_CORP_SDG_LOAD",
  GET_CORP_SDG_SUCCESS: "GET_CORP_SDG_SUCCESS",
  GET_CORP_SDG_FAIL: "GET_CORP_SDG_FAIL",

  GET_CORP_PAI_INDICATORS_LOAD: "GET_CORP_PAI_INDICATORS_LOAD",
  GET_CORP_PAI_INDICATORS_SUCCESS: "GET_CORP_PAI_INDICATORS_SUCCESS",
  GET_CORP_PAI_INDICATORS_FAIL: "GET_CORP_PAI_INDICATORS_FAIL",

  GET_CORP_COMPANIES_LIST_LOAD: "GET_CORP_COMPANIES_LIST_LOAD",
  GET_CORP_COMPANIES_LIST_SUCCESS: "GET_CORP_COMPANIES_LIST_SUCCESS",
  GET_CORP_COMPANIES_LIST_FAIL: "GET_CORP_COMPANIES_LIST_FAIL",

  UPDATE_CORP_PROF_STATE: "UPDATE_CORP_PROF_STATE",

  GET_COMPANY_RISK_PEER_LOAD: "GET_COMPANY_RISK_PEER_LOAD",
  GET_COMPANY_RISK_PEER_SUCCESS: "GET_COMPANY_RISK_PEER_SUCCESS",

  GET_COMPANY_RISK_LINE_LOAD: "GET_COMPANY_RISK_LINE_LOAD",
  GET_COMPANY_RISK_LINE_SUCCESS: "GET_COMPANY_RISK_LINE_SUCCESS",

  UPDATE_ADVNC_TOP_SEARCH_DATA: "UPDATE_ADVNC_TOP_SEARCH_DATA",
  UPDATE_ADVNC_INC_LOCATION_DATA_SUCCESS:
    "UPDATE_ADVNC_INC_LOCATION_DATA_SUCCESS",

  DASHBOARD_SEARCH_LOAD: "DASHBOARD_SEARCH_LOAD",
  DASHBOARD_SEARCH_SUCCESS: "DASHBOARD_SEARCH_SUCCESS",
  UPLOAD_DATA_LOAD: "UPLOAD_DATA_LOAD",
  UPLOAD_DATA_SUCCESS: "UPLOAD_DATA_SUCCESS",
  UPLOAD_DATA_FAIL: "UPLOAD_DATA_FAIL",

  UPDATE_LOADER: "UPDATE_LOADER",

  SAVE_VALID_SUPPLY_CHAIN_COMPANY_LOAD: "SAVE_VALID_SUPPLY_CHAIN_COMPANY_LOAD",
  SAVE_VALID_SUPPLY_CHAIN_COMPANY_SUCCESS:
    "SAVE_VALID_SUPPLY_CHAIN_COMPANY_SUCCESS",

  SAVE_VALID_SUPPLY_CHAIN_COMPANY_FAIL: "SAVE_VALID_SUPPLY_CHAIN_COMPANY_FAIL",
  GET_COMPANY_FOR_ADD_SUPPLY_CHAIN_LOAD:
    "GET_COMPANY_FOR_ADD_SUPPLY_CHAIN_LOAD",

  SUPPLY_CHAIN_UPLOAD_FILE_LOAD: "SUPPLY_CHAIN_UPLOAD_FILE_LOAD",

  RESET_SEARCH_PAGE_DATA: "RESET_SEARCH_PAGE_DATA",
  RESET_PORTFOLIO_DATA_ON_PAGE_LOAD: "RESET_PORTFOLIO_DATA_ON_PAGE_LOAD",

  REMOVE_WATCHLIST_INDUSTRIES_NEW: "REMOVE_WATCHLIST_INDUSTRIES_NEW",
};

export const appMessages = {
  offline: "Your internet connection appears to be offline. Please try again.",
  // 401 message
  messageStatus401: "The user is not authorized to make the request.",
  messageStatus500: "Something went wrong.",
  resetLinkExpired: "Your password reset link has expired",
  clickBelow: "Click below to generate a new one.",
  wrongPage: "Sorry, we couldn't' find this page",
  tryAgain: "Try again searching or go to",
};
