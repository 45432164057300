import {
  Box,
  Chip,
  Typography,
  useTheme,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import {
  getBackgroundColorFromRiskScore,
  getRiskScoreTitle,
  getTextColorFromRiskScore,
  getTitleColorFromRiskScore,
  handleShortestPath,
} from "../../utils";
import ShortestPathIcon from "./shortestPathIcon";
import { useAppSelector } from "../../redux/configuareStore";
import { useGraphObject } from "../context/MyContext";

// function getColorFromScore(_score) {
//   // TODO: Need to change this to consider score range
//   return '#D00000';
// }
function CompanyRiskItem({ name, index, risk, id }) {
  const theme = useTheme();
  let riskString = getRiskScoreTitle(risk);
  let riskColor = getTextColorFromRiskScore(risk);
  const sideBarNodeId = useAppSelector(
    (state) => state.rightClickMenu.sideBarNodeId
  );
  const { graphObject } = useGraphObject();
  return (
    <>
      <Stack direction="row" gap={1}>
        <div>
          <Chip
            label={index}
            style={{ ...theme.typography.captionS }}
            size="small"
          />
        </div>
        <div style={{ width: "100%" }}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              // justifyContent="space-between"
              alignItems="center"
            >
              <div>
                {/* Component located in the far left */}
                <Typography variant="bodySelected">{name}</Typography>
                <IconButton
                  sx={{
                    borderRadius: "4px",
                    border: `1px solid ${theme.palette.grey[300]}`,
                    margin: "4px",
                  }}
                  onClick={async () => {
                    const sourceNode = sideBarNodeId;
                    const targetNode = id;
                    await handleShortestPath(
                      sourceNode,
                      targetNode,
                      graphObject
                    );
                  }}
                >
                  <ShortestPathIcon />
                </IconButton>
              </div>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ ...theme.typography.captionS }}
            >
              <div style={{ color: riskColor }}>&nbsp; {riskString} </div>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={"36px"}
                height={"24px"}
                sx={{
                  backgroundColor: riskColor,
                  borderRadius: "2px",
                  ...theme.typography.captionXL,
                }}
              >
                <span style={{ color: "white" }}>{Math.floor(risk || 0)}</span>
              </Box>
            </Stack>
            <Divider orientation="horizontal" flexItem />
          </Stack>
        </div>
      </Stack>
    </>
  );
}
export default CompanyRiskItem;
