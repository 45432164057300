// S E N T I M E N T
let positive = {
  name: 8,
  value: 8,
  _name: "Postive",
  parent: "",
  itemStyle: {
    color: {
      colorStops: [
        {
          offset: 0,
          color: "#3aa95a",
        },
        {
          offset: 1,
          color: "#ffffff",
        },
      ],
      x: 0,
      y: 0,
      x2: 0,
      y2: 1.5,
      type: "linear",
      global: false,
    },
  },
};
let negative = {
  name: 8,
  value: 8,
  _name: "negative",
  parent: "S",
  itemStyle: {
    color: {
      colorStops: [
        {
          offset: 0,
          color: "#d72727",
        },
        {
          offset: 1,
          color: "#ffffff",
        },
      ],
      x: 0,
      y: 0,
      x2: 0,
      y2: 1.5,
      type: "linear",
      global: false,
    },
  },
};
let neutral = {
  name: 8,
  value: 8,
  _name: "neutral",
  parent: "S",
  itemStyle: {
    color: {
      colorStops: [
        {
          offset: 0,
          color: "#bec3cd",
        },
        {
          offset: 1,
          color: "#ffffff",
        },
      ],
      x: 0,
      y: 0,
      x2: 0,
      y2: 1.5,
      type: "linear",
      global: false,
    },
  },
};

let parentTweets = {
  name: 8,
  value: 8,
  _name: "Postive",
  parent: "",
  itemStyle: {
    color: {
      colorStops: [
        {
          offset: 0,
          color: "#1DA1F2",
        },
        {
          offset: 1,
          color: "rgba(135, 207, 255, 1)",
        },
      ],
      x: 0,
      y: 0,
      x2: 0,
      y2: 1.5,
      type: "linear",
      global: false,
    },
  },
};
let parentReddit = {
  name: 8,
  value: 8,
  _name: "negative",
  parent: "S",
  itemStyle: {
    color: {
      colorStops: [
        {
          offset: 0,
          color: "#FF4500",
        },
        {
          offset: 1,
          color: "rgba(255, 171, 140, 1)",
        },
      ],
      x: 0,
      y: 0,
      x2: 0,
      y2: 1.5,
      type: "linear",
      global: false,
    },
  },
};
let parentFaceBook = {
  name: 8,
  value: 8,
  _name: "neutral",
  parent: "S",
  itemStyle: {
    color: {
      colorStops: [
        {
          offset: 0,
          color: "#0A66C2",
        },
        {
          offset: 1,
          color: "rgba(89, 172, 255, 1)",
        },
      ],
      x: 0,
      y: 0,
      x2: 0,
      y2: 1.5,
      type: "linear",
      global: false,
    },
  },
};


const makeObj = (type, number, parent) => {
  if (type == "positive") {
    return { ...positive, value: 10, name: number, parent, _name: parent };
  } else if (type == "negative") {
    return { ...negative, value: 10, name: number, parent, _name: parent };
  } else {
    return { ...neutral, value: 10, name: number, parent, _name: parent };
  }
};

const makeParentObj = (type, number, parent) => {
  if (type == "Tweets") {
    return { ...parentTweets, value: 15, name: number, parent, _name: parent };
  } else if (type == "Facebook") {
    return {
      ...parentFaceBook,
      value: 10,
      name: number,
      parent,
      _name: parent,
    };
  } else {
    return { ...parentReddit, value: 15, name: number, parent, _name: parent };
  }
};

let sentiMent_tweets = [];
let sentiMent_reddit_posts = [];
let sentiMent_facebook_posts = [];

let social_positive = [];
let social_negative = [];
let social_neutral = [];

export const sentiment_Obj = (data) => {
  if (Object.keys(data)?.length > 0) {
    const { NEGATIVE, POSITIVE, NEUTRAL } = data;

    const dummyTweets = [
      {
        type: "negative",
        number: NEGATIVE?.tweet,
      },
      {
        type: "positive",
        number: POSITIVE?.tweet,
      },
      {
        type: "neutral",
        number: NEUTRAL?.tweets,
      },
    ];
    const dummyReddit = [
      {
        type: "negative",
        number: NEGATIVE.reddit,
      },
      {
        type: "positive",
        number: POSITIVE.reddit,
      },
      {
        type: "neutral",
        number: NEUTRAL.reddit,
      },
    ];
    const dummyFaceBook = [
      {
        type: "negative",
        number: NEGATIVE.facebook,
      },
      {
        type: "positive",
        number: POSITIVE.facebook,
      },
      {
        type: "neutral",
        number: NEUTRAL.facebook,
      },
    ];
    sentiMent_tweets = dummyTweets.map((val) =>
      makeObj(val.type, val.number, "Tweets")
    );
    sentiMent_reddit_posts = dummyReddit.map((val) =>
      makeObj(val.type, val.number, "Reddit")
    );
    sentiMent_facebook_posts = dummyFaceBook.map((val) =>
      makeObj(val.type, val.number, "FaceBook")
    );
    return {
      tweets: sentiMent_tweets,
      reddit: sentiMent_reddit_posts,
      facebook: sentiMent_facebook_posts,
    };
  } else {
    return {};
  }
};



export const socialMediaObj = (data) => {
  if (Object.keys(data)?.length > 0) {
    const { tweet, facebook, reddit } = data;
    const dummyPositive = [
      {
        type: "Tweets",
        number: tweet?.POSITIVE,
      },
      // {
      //   type: "Facebook",
      //   number: facebook?.POSITIVE,
      // },
      {
        type: "Reddit",
        number: reddit?.POSITIVE,
      },
    ];

    const dummyNegative = [
      {
        type: "Tweets",
        number: tweet?.NEGATIVE,
      },
      // {
      //   type: "Facebook",
      //   number: facebook?.NEGATIVE,
      // },
      {
        type: "Reddit",
        number: reddit?.NEGATIVE,
      },
    ];

    const dummyNeutral = [
      {
        type: "Tweets",
        number: tweet?.NEUTRAL,
      },
      // {
      //   type: "Facebook",
      //   number: facebook?.NEUTRAL,
      // },
      {
        type: "Reddit",
        number: reddit?.NEUTRAL,
      },
    ];

    social_positive = dummyPositive.map((val) => {
      if (val.type !== "Facebook")
        return (
          makeParentObj(val.type, val.number, "Positive")
        )
    }
    );

    social_negative = dummyNegative.map((val) =>{
      if (val.type !== "Facebook")
        return (
          makeParentObj(val.type, val.number, "Negative")

        )
    }
    );

    social_neutral = dummyNeutral.map((val) =>{
      if (val.type !== "Facebook")
        return (
          makeParentObj(val.type, val.number, "Neutral")
        )
    }
    );
    const Positive =
      dummyPositive[0].number +
      dummyPositive[1].number
    const Negative =
      dummyNegative[0].number +
      dummyNegative[1].number
    const Neutral =
      dummyNeutral[0].number + dummyNeutral[1].number 

    const total = Positive + Negative + Neutral;

    const percentageTweets = Positive > 0 ? ((Positive / total) * 100).toFixed(1) : 0;
    const percentageFacebook = Negative > 0 ? ((Negative / total) * 100).toFixed(1) : 0;
    const percentageReddit = Neutral > 0 ? ((Neutral / total) * 100).toFixed(1) : 0;
    return {
      positive: { childArray: social_positive, percentage: percentageTweets },
      negative: { childArray: social_negative, percentage: percentageFacebook },
      neutral: { childArray: social_neutral, percentage: percentageReddit },
    };
  } else {
    return {};
  }
};