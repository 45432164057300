import { Row } from "antd";

export const ChartHeaderTimeRow = () => {
    return <Row
    style={{
      borderBottom: "1px solid",
      display: "flex",
      borderColor: "#DEDEDE",
      paddingBottom: 10,
      alignItems: "center",
    }}
    justify="space-between"
  >
    <div className="incident-timline-graph-year-legend-container">

      {["1 Month", "3 Months", "1 Years", "3 Years", "5 Years", "Max"].map((data, index) => {
        return (
          <span className={index === 0 ? "active" : ""}>{data}</span>
        );
      })}
    </div>

  </Row>
}
