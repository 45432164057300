import { apiConstants as types } from "../../Constants/apiConstants";

export const modalShow = (payload) => {
  return {
    type: types.SHOW_MODAL,
    payload,
  };
};
export const modalHide = (payload) => {
  return {
    type: types.HIDE_MODAL,
    payload,
  };
};

export const searchCompanyListActionLoad = (payload) => {
  return {
    type: types.SEARCH_COMPANY_LIST_LOAD,
    payload,
  };
};

export const searchCompanyListActionSuccess = (payload) => {
  return {
    type: types.SEARCH_COMPANY_LIST_SUCCESS,
    payload,
  };
};

export const searchCompanyListActionFail = (payload) => {
  return {
    type: types.SEARCH_COMPANY_LIST_FAIL,
    payload,
  };
};

export const resetSearchPageDataAction = () => {
  return {
    type: types.RESET_SEARCH_PAGE_DATA
  };
};