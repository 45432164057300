import React, { useState, useEffect, useMemo } from "react";
import Images from "../../Themes/ImageConstant";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  getProfileAction,
  updateProfileAction,
} from "../../Redux/Action/accoutSettngAction";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../style/index.css"; // Make sure the path to your CSS file is correct
import {
  profileUpdateSchema,
  initialProfileValues,
  profileValidationSchema,
} from "../../Utils/schema";
import { AutoComplete, Input, Select } from 'antd';
import useDebouncedApiCall from "../../hook/useDebouncedSearch";
import { searchCompanyListActionLoad } from "../../Redux/Action/global";


const Profile = (props) => {
  const { userEmail, userName, userPhoneNum, userCompId, companyName, countryCode } = useSelector(
    (state) => state?.accountSettingReducer
  );
  const [email, setEmail] = useState(userEmail);
  const { searchCompaniesData } = useSelector((state) => state?.global);
  const [countryName, setCountryName] = useState(companyName);
  const [companyId, setCompanyId] = useState(userCompId);
  const [username, setUsername] = useState(userName);
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNum);
  const [country_Code, setCountryCode] = useState(countryCode);

  const options  =[
  { value: "86", label: "+86" },
  { value: "91", label: "+91" },
  { value: "1", label: "+1" },
  { value: "62", label: "+62" },
  { value: "92", label: "+92" },
  { value: "55", label: "+55" },
  { value: "234", label: "+234" },
  { value: "880", label: "+880" },
  { value: "7", label: "+7" },
  { value: "52", label: "+52" },
  { value: "81", label: "+81" },
  { value: "251", label: "+251" },
  { value: "63", label: "+63" },
  { value: "20", label: "+20" },
  { value: "84", label: "+84" },
  { value: "243", label: "+243" },
  { value: "90", label: "+90" },
  { value: "98", label: "+98" },
  { value: "49", label: "+49" },
  { value: "66", label: "+66" },
  { value: "44", label: "+44" },
  { value: "33", label: "+33" },
  { value: "39", label: "+39" },
  { value: "255", label: "+255" },
  { value: "27", label: "+27" },
  { value: "95", label: "+95" },
  { value: "82", label: "+82" },
  { value: "57", label: "+57" },
  { value: "254", label: "+254" },
  { value: "34", label: "+34" },
  { value: "54", label: "+54" },
  { value: "380", label: "+380" }
];



  const { searchTerm, handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );

  useEffect(() => {
    dispatch(getProfileAction());
  }, []);

  const changeHandler = (e, v) => {
    setCountryCode(v.value)
  }

  useEffect(() => {
    setEmail(userEmail);
    setUsername(userName);
    setPhoneNumber(userPhoneNum);
    setCountryName(companyName)
    setCountryCode(countryCode)
  }, [userEmail, userName, userPhoneNum, companyName,countryCode]);


  const initialProfileValues = {
    username: username,
    phoneNumber: phoneNumber,
    email: email,
  };

  const formik = useFormik({
    initialValues: {
      ...initialProfileValues
    },
    enableReinitialize: true,

    validationSchema: profileValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateProfileAction({ email: values.email, userName: values.username, phoneNumber: values.phoneNumber?.toString(), companyId: companyId ? companyId : null, countryCode: country_Code })
      );
    },
  });

  return (
    <div className="side-padding sodebar-left-padding">
      <div className="pro-page-bg">
        <div className="flex-pro">
          <div className="profile-sec">
            <div className="circle-image">
              <span className="intialsText">
                {username ? username?.charAt(0).toUpperCase() : email?.charAt(0)}
              </span>
            </div>
          </div>
          <div className="proname">
            <h4>{username}</h4>
            <p>{userEmail}</p>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="edit-prof-text-tp mt-4">
            <h5>Edit Profile</h5>

            <div className="us-name-in mt-4">
              <label>Username</label>
              <input
                maxLength={50}
                type="text"
                placeholder={"Username"}
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="error-message">{formik.errors.username}</div>
              ) : null}
            </div>
            <div className="us-name-in mt-4">
              <label>Mobile number</label>
              <div className="countryPicker">
                <Select className="countrySelect" dropdownStyle={{ width: 80,fontSize:12 }} options={options} value={country_Code} onChange={(e, val) => changeHandler(e, val)} />
                <input

                  type="number"
                  placeholder={"Mobile number"}
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className="error-message">{formik.errors.phoneNumber}</div>
                ) : null}
              </div>
            </div>
            <div className="us-name-in mt-4">
              <label>Email</label>
              <input
                type="text"
                placeholder={"Email"}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error-message">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className=" profile-page-auto-complete us-name-in mt-4">
              <label>Corporate Profile</label>
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                popupMatchSelectWidth={"100%"}
                className="profile-input-class"
                options={searchCompaniesData?.data}
                size="large"
                onSearch={handleSearch}
                value={countryName}
                onSelect={(e, selectedOption) => {
                  const { label } = selectedOption;
                  setCountryName(label);
                  setCompanyId(selectedOption.id)
                  // handleSearchOperation(selectedOption);
                }}
                onChange={(e, i) => {
                  setCountryName(e);
                }}
              >
                <Input.Search size="large" placeholder="Corporate Profile" />
              </AutoComplete>
            </div>

            <div className="us-btn mt-5">
              <button type="submit">Save Changes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
