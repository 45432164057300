import { Box, useTheme } from '@mui/material';
import React from 'react';

function ButtonContainer({ children, isActive }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        padding: theme.spacing(0.5),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
        backgroundColor: isActive
          ? theme.palette.gray[50]
          : theme.palette.white.main,
        border: `1px solid ${
          isActive ? theme.palette.gray[100] : 'transparent'
        }`,
        flexDirection: 'column',
        gap: theme.spacing(1),
      }}
    >
      {children}
    </Box>
  );
}
export default ButtonContainer;
