import React, { memo, useCallback } from "react";
import EChartsReact from "echarts-for-react";
import * as echarts from "echarts/core";

var graphic = echarts.graphic;

const color = (color) => {
  return new graphic.LinearGradient(0, 0, 0, 1.5, [
    {
      offset: 0,
      color: color,
    },
    {
      offset: 1,
      color: "rgba(255,255,255,0.8)",
    },
  ]);
};

const SunBrust = ({ data, onClick }) => {
  var option;
  var data = [
    {
      name: "Low",
      itemStyle: {
        color: "#FFBF00",
      },
      children: [
        {
          name: "E",
          value: 10,
          itemStyle: {
            color: "#D6EDC1",
          },
          //   children: [
          //     {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },
          //  {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },
          //   ]
        },

        {
          name: "S",
          value: 10,
          itemStyle: {
            color: "#B1D8FE",
          },
          //   children: [
          //     {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },
          //  {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },

          //   ]
        },

        {
          name: "G",
          value: 10,
          itemStyle: {
            color: "#C9BBF0",
          },
          //   children: [
          //     {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },
          //  {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#FFEB80'
          //       }
          //     },
          //   ]
        },
      ],
    },
    {
      name: "Medium",
      itemStyle: {
        color: "#FF7518",
      },
      children: [
        {
          name: "E",
          value: 10,
          itemStyle: {
            color: "#D6EDC1",
          },
          //   children: [
          //     {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },
          //  {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },
          //   ]
        },

        {
          name: "S",
          value: 10,
          itemStyle: {
            color: "#B1D8FE",
          },
          //   children: [
          //     {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },
          //  {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },
          //   ]
        },

        {
          name: "G",
          value: 10,
          itemStyle: {
            color: "#C9BBF0",
          },
          //   children: [
          //     {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },
          //  {
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },{
          //       name: '',
          //       value: 1,
          //       itemStyle: {
          //         color: '#F8C694'
          //       }
          //     },
          //   ]
        },
      ],
    },

    {
      name: "High",
      itemStyle: {
        color: "#FF0000",
      },
      children: [
        {
          name: "E",
          value: 10,
          itemStyle: {
            color: "#D6EDC1",
          },
        },

        {
          name: "S",
          value: 10,
          itemStyle: {
            color: "#B1D8FE",
          },
        },

        {
          name: "G",
          value: 10,
          itemStyle: {
            color: "#C9BBF0",
          },
          children: [
            {
              name: "money laundering",
              value: 1,
              label: {
                show: true,
              },
            },
            {
              name: "sanction evasion",
              value: 1,
              label: {
                show: true,
              },
            },
          ],
        },
      ],
    },
    {
      name: "Critical",
      itemStyle: {
        color: "#800020",
      },
      children: [
        {
          name: "E",
          value: 10,
          itemStyle: {
            color: "#D6EDC1",
          },
        },

        {
          name: "S",
          value: 10,
          itemStyle: {
            color: "#B1D8FE",
          },
        },

        {
          name: "G",
          value: 10,
          itemStyle: {
            color: "#C9BBF0",
          },
        },
      ],
    },
  ];
  option = {
    series: {
      nodeClick: false,
      type: "sunburst",
      data: data,
      radius: [0, "55%"],
      sort: undefined,
      emphasis: {
        focus: "ancestor",
      },
      levels: [
        {},
        {
          r0: "10%",
          r: "45%",
          itemStyle: {
            borderWidth: 4,
          },
          label: {
            rotate: 0,
            position: "inside",
          },
        },
        {
          r0: "45%",
          r: "60%",
          label: {
            rotate: 0,
          },
        },

        {
          r0: "60%",
          r: "60%",
          label: {
            distance: 25,
            rotate: -5,
            position: "outside",
            padding: 12,
            silent: false,
          },
          labelLine: {
            show: true,
          },
          itemStyle: {
            borderWidth: 3,
          },
        },
      ],
    },
  };

  return (
    <EChartsReact
      className="doubleLayer-pieChart"
      option={option}
      //   onEvents={{
      //     click: onChartClick,
      //     legendselectchanged: onChartLegendselectchanged,
      //   }}
    />
  );
};

export default memo(SunBrust);
