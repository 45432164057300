import React from "react";
import ReactSlider from "react-slider";
import "../../App.css";

export const MultiSlider = ({
  className,
  lableTextColor,
  labelBackground,
  thumbClassName = "example-thumb",
  disabled=true,
  data,
  name,
  keyName,
  widthCustom
}) => {
  return (
    <>
      <div className={`slider-container ${className} ${widthCustom && 'width100'}`}>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName={thumbClassName}
          trackClassName="example-track"
          defaultValue={[-1, 1]}
          value={keyName == "portfolioDetail" ? data?[data?.industrySentiment?.toFixed(2)]:[0] : data?[data?.companySentiment?.toFixed(2),data?.industrySentiment?.toFixed(2)]:[0,1]}
          onChange={(e)=>{}}
          min={1}
          disabled={disabled}
          // marks
          step={0.1}
          max={100}
          renderThumb={(props, { index }) => (
            <>
            {keyName !== "portfolioDetail" ? 
            <div
              {...props}
              style={{
                ...props.style,
                backgroundColor: labelBackground?.[index],
              }}>
              <p
                className={`slider-Lable-Text`}
                style={{ color: lableTextColor?.[index] }}>
                {index === 0 ? name : "Industry"}
              </p>
            </div>
             :
             <div
                 {...props}
                 style={{
                   ...props.style,
                   backgroundColor: 'transparent',
                 }}>
                
               </div> }
               </>
          )}
          pearling
          dots={true}
        />
      </div>
    </>
  );
};
