import CheckBoxIcon from "../../Assets/icon/checkBox.png"
import UnvisibleCheckBoxIcon from "../../Assets/icon/checkBoxUnvisible.png"
import Styles from "./index.module.css"

const CheckBox = ({ visible, onClick }) => {
    return <div onClick={onClick}>
        {visible ? <div className={Styles.checkBoxIconBox}><img src={CheckBoxIcon} className={Styles.checkBoxIcon} /></div> : <div className={Styles.checkBoxIconBox}><img src={UnvisibleCheckBoxIcon} className={Styles.checkBoxIcon} /></div>
        }
    </div >
}

export default CheckBox