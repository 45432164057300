import { Routes, Route } from "react-router-dom";
import Login from "../Screens/Auth/Login";
import Signup from "../Screens/Auth/Signup";
import ForgotPassword from "../Screens/Auth/Forgotpassword";
import ChangePassword from "../Screens/Auth/Changepassword";
import ConfirmEmail from "../Screens/Auth/Confirmemail";
import MainLayout from "../Layouts/MainLayout";
import Home from "../Screens/Home";
import IncidentsList from "../Screens/incidentsList";
import IncidentDetails from "../Screens/incidentDetails";
import SourceLists from "../Screens/sourceLists";
import SupplyChain from "../Screens/supplyChain";
import SearchResult from "../Screens/searchResult";
import Sidebar from "../Layouts/SideBar";
import Profile from "../Screens/Profile";
import Password from "../Screens/Password";
import EmailNotification from "../Screens/emailNotification";
import Plans from "../Screens/Plans";
import CustomizeScoring from "../Screens/customizeScoring";
import Notification from "../Screens/notifications";
import IncidentCollection from "../Screens/incidentCollection";
import WatchList from "../Screens/watchlist";
import Dashboard from "../Screens/dashboardPage";
import FullMap from "../Screens/fullMap";
import { PrivateRoute, PublicRoute } from "./privateRoute";
import MailVerifyed from "../Screens/Auth/mailVerified";
import MailVerified from "../Screens/Auth/mailVerified";
import SideBar from "../Layouts/Header";
import Portfolio from "../Screens/portfolio";
import DashboardNew from "../Screens/dashboardNew";
import CreatePortfolio from "../Screens/portfolio/CreatePortfolio";
import SearchDashboardPage from "../Screens/searchdashboardpage";
import OverviewPage from "../Screens/socialMediaCoverage/overviewPage";
import PortfolioDetails from "../Screens/portfolio/PortfolioDetails";
import SeverityScore from "../Screens/incidentDetails/severityScore";
import SupplyChainGraph from "../Screens/supplyChain/supplyChainGraph";
import CorporateProfile from "../Screens/corporateProfile";
import UploadWatchlist  from "../Screens/watchlist/UploadWatchList";
import UploadSupplyChain from "../Screens/supplyChain/UploadSupplyChain";

const Routers = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path="/forgotpassword"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/changepassword"
        element={
          <PublicRoute>
            <ChangePassword />
          </PublicRoute>
        }
      />
      <Route
        path="/confirmemail"
        element={
          <PublicRoute>
            <ConfirmEmail />
          </PublicRoute>
        }
      />
      <Route
        path="/email-verified"
        element={
          <PublicRoute>
            <MailVerified />
          </PublicRoute>
        }
      />
      <Route path="/sidebar" element={<Sidebar />} />
      <Route path="/SideBar" element={<SideBar />} />
      {/* main layout */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <MainLayout topHeader={true} />
          </PrivateRoute>
        }
      >
        <Route path="/home" element={<Home />} />
        <Route path="/incidentslist" element={<IncidentsList />} />
        <Route path="/incidentdetails" element={<IncidentDetails />} />
        <Route path="/sourcelists" element={<SourceLists />} />
        <Route path="/supplychain" element={<SupplyChain />} />
        <Route path="/searchresult" element={<SearchResult />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/companyPage" element={<Dashboard />} />
        <Route path="/fullMap" element={<FullMap />} />
        <Route path="/graphview" element={<SupplyChainGraph />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/dashboard" element={<DashboardNew />} />
        <Route path="/createPortfolio" element={<CreatePortfolio />} />
        <Route path="/portfolioDetails" element={<PortfolioDetails />} />
        <Route path="/searchdashboardpage" element={<SearchDashboardPage />} />
        <Route path="/socialMediaCoverage" element={<OverviewPage />} />
        <Route path="/severityScore" element={<SeverityScore/>} />
        <Route path="/corporateprofile" element={<CorporateProfile/>} />
        <Route path="/UploadWatchlist" element={<UploadWatchlist/>} />
        <Route path="/UploadSupplyChain" element={<UploadSupplyChain/>}/>


      </Route>

      {/* Mail layout with sidebar */}

      <Route
        path="/"
        element={
          <PrivateRoute>
            <MainLayout sidebar={true} />
          </PrivateRoute>
        }
      >
        <Route path="/profile" element={<Profile />} />
        <Route path="/password" element={<Password />} />
        <Route path="/emailnotification" element={<EmailNotification />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/customizescoring" element={<CustomizeScoring />} />
      
      </Route>

      {/*Main layout with incidentCollectionSidebar */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <MainLayout IncidentCollectionSideBar={true} />
          </PrivateRoute>
        }
      >
        <Route path="/incidentcollection" element={<IncidentCollection />} />
       
      </Route>

      {/* Main layout with watchlistSidebar */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <MainLayout WatchlistSideBar={true} />
          </PrivateRoute>
        }
      >
        <Route path="/watchlist" element={<WatchList />} />


      </Route>
    </Routes>
  );
};
export default Routers;
