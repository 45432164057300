import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { Breadcrumb } from "antd";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  getProfileAction,
  profileEmailNotificationAction,
} from "../../Redux/Action/accoutSettngAction";
import { useSelector } from "react-redux";

const EmailNotification = (props) => {
  const { notificationSettingState } = useSelector(
    (state) => state?.accountSettingReducer
  );
  const { applicationUpdates, wishlist } = notificationSettingState;
  const [Wishlist, setWishlist] = useState(false);
  const [Updates, setUpdates] = useState(false);

  useEffect(() => {
    dispatch(getProfileAction());
    setWishlist(wishlist);
    setUpdates(applicationUpdates);
  }, []);

  useEffect(() => {
    setWishlist(wishlist);
    setUpdates(applicationUpdates);
  }, [notificationSettingState]);

  const updateEmail = () => {
    dispatch(
      profileEmailNotificationAction({
        wishlist: Wishlist,
        applicationUpdates: Updates,
      })
    );
  };

  return (
    <div className="side-padding sodebar-left-padding">
      <div
        style={{ paddingLeft: "8vw", paddingRight: "8vw", paddingTop: "2vw" }}
      >
        <div className="page-size" style={{ width: "830px" }}>
          <div className="breadcrmbs mb-4">
            <Breadcrumb>
              <Breadcrumb.Item key="3" className="lie-link">
                Email notification
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="cust-mize-mail mt-5">
            <div className="mail-info-box">
              <div className="mail-st">
                <span>
                  <img src={Images.emailinfo} />{" "}
                </span>
              </div>
              <div className="imp-up">
                <p>
                  Customize your email notifications to your liking by selecting
                  the specific frequency you wish to receive them, ensuring that
                  you never miss an important update.{" "}
                </p>
              </div>
            </div>

            <div className="info-update mt-4">
              <div className="notification-text-up">
                <div className="traingle">
                  <span>
                    {" "}
                    <img src={Images.traingle} />{" "}
                  </span>
                </div>
                <div className="alert-msg">
                  <p>
                    Please note that receiving too many notifications may result
                    in alert fatigue, so be sure to select only the updates that
                    are most relevant to you.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="see-all-noti mt-4">
            <div className="heading-noti">
              <h5>Notifications</h5>
            </div>
            <div className="see-all-tx">
              <a href="#">Select All</a>
            </div>
          </div>

          <div className="watch-noti mt-4">
            <input
              type="checkbox"
              checked={Wishlist}
              onChange={(e) => {
                setWishlist(!Wishlist);
              }}
            />
            <h6>Watchlist Notifications</h6>
          </div>
          <div className="up-m-watch mt-2">
            <p>
              Email me about all updates of my watchlists. (If turned of, all
              email notifications on your watchlist will be stopped. You still
              be notified if you are in the application).
            </p>
          </div>

          <div className="watch-noti mt-4">
            <input
              type="checkbox"
              checked={Updates}
              onChange={(e) => {
                setUpdates(!Updates);
              }}
            />
            <h6>Application Updates</h6>
          </div>
          <div className="new-feature mt-2">
            <p>Email me about new feature updates of the application.</p>
          </div>

          <div className="up-m-noti-btn mt-5">
            <button type="button" onClick={() => updateEmail()}>
              Update Email Notifications
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailNotification;
