import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { Checkbox, Radio, Select, Space } from "antd";
import { Breadcrumb } from "antd";
import { Pagination } from "antd";
import { Tooltip } from "antd";
import { Modal } from "antd";
import IncidentsListModalTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesAction,
  getEsgCountriesAction,
  getEsgFactorListForModalAction,
  getEsgFactorsAction,
  getCountryListForModalAction,
  incindentAction,
  updateIncidentState,
  getIncidentListForModalAction,
  getCompanyListForModal,
} from "../../Redux/Action/incidents";
import { preventInvalidNumberInput } from "../../Utils/globalFunction";
import { useRef } from "react";
import GlobalLoder from "../../Utils/GlobalLoder";

const text = <span>Download</span>;

const IncidentsListModal = ({ networkData, setModalVisible }) => {
  const incidents = useSelector(
    (state) => state?.incidentReducer?.incidentsForModal
  );
  const { isLoading } = useSelector((state) => state?.incidentReducer);

  const [payloadState, setPayloadState] = useState({
    limit: 25,
    offset: 0,
    ...networkData,
  });
  const [perPageValue, setPerPageValue] = useState("");
  const [tableHeaders, setTableHeaders] = useState([
    "title",
    "severity_score",
    "affected_companies",
    "discovery_time",
    "esg_factors",
    "locations",
  ]);

  const dispatch = useDispatch();
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <span>Previous</span>;
    }
    if (type === "next") {
      return <span>Next</span>;
    }
    return originalElement;
  };

  useEffect(() => {
    dispatch(getEsgCountriesAction());
    dispatch(getEsgFactorListForModalAction());
    dispatch(getCountryListForModalAction());
    dispatch(getCompanyListForModal());
  }, []);
  useEffect(() => {
    dispatch(getIncidentListForModalAction(payloadState));
  }, [payloadState]);
  const handleChange = (value) => {};

  const onPaginationChange = (page) => {
    if (page) {
      setPayloadState((prev) => {
        return { ...prev, offset: (page - 1) * prev.limit };
      });
    }
  };

  const handleLimitChange = (limit) => {
    setPayloadState((prev) => {
      return { ...prev, limit: limit };
    });
  };

  const handeGoToPageInput = (e) => {
    let newVal = e.target.value.replace("e", "");
    if (
      +newVal &&
      +newVal > Math.ceil(incidents.pagination?.totalCount / payloadState.limit)
    ) {
      newVal = Math.ceil(incidents.pagination?.totalCount / payloadState.limit);
    }
    if ((+newVal || newVal === "0") && 1 > +newVal) {
      newVal = 1;
    }
    setPerPageValue(newVal);
  };

  const handleIncidentSourceChange = (item) => {
    setPayloadState((prev) => ({ ...prev, sources: item }));
  };

  const handleColumnCheckboxChange = (e) => {
    if (e.target.checked && tableHeaders.includes(e.target.value)) return;
    if (e.target.checked && !tableHeaders.includes(e.target.value)) {
      setTableHeaders((prev) => [...prev, e.target.value]);
    }
    if (!e.target.checked && tableHeaders.includes(e.target.value)) {
      setTableHeaders((prev) => prev.filter((f) => f !== e.target.value));
    }
  };

  const handleColumnRadioChange = (e) => {
    const colArr = ["esg_factors", "esg_sdgs", "esg_pais"];

    setTableHeaders((prev) => {
      const newArr = prev.filter((e) => !colArr.includes(e));
      return [...newArr, e.target.value];
    });
  };

  const columnsOptions = [
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"title"}
        >
          Incident Title
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"severity_score"}
        >
          Severity
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"affected_companies"}
        >
          Involved Companies
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"locations"}
        >
          Locations
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"discovery_time"}
        >
          Date
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: <hr className="incident-modal-columns-select-divider" />,
      options: [
        {
          label: (
            <Radio.Group
              name="esg_radio"
              onChange={handleColumnRadioChange}
              defaultValue={"esg_factors"}
            >
              <Space direction="vertical">
                <Radio value={"esg_factors"}>ESG Facotrs</Radio>
                <Radio value={"esg_sdgs"}>SDG</Radio>
                <Radio value={"esg_pais"}>PAI</Radio>
                <Radio value={"un_principles"}>UN Principles</Radio>
              </Space>
            </Radio.Group>
          ),
          value: "none",
        },
      ],
    },
  ];

  return (
    <Modal
      className={"incident-page-table-modal-ps"}
      centered
      wrapClassName="incident-list-modal"
      open={true}
      closable={false}
      onCancel={() => setModalVisible(false)}
      footer={[]}
    >
      <div className="user-content-modal-ps">
        <div className="side-padding inc-outer-padding">
          <div className="bg-sec main-layout table-layout ">
            <section className=" incidents-table-modal-ps">
              <div className="breadcrmbs mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item
                    key="1"
                    className="bad-link"
                    onClick={() => {
                      updateIncidentState([], "");
                      setModalVisible(false);
                    }}
                  >
                    {" "}
                    <img src={Images.left} />
                    Back
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="2" className="inc-link">
                    {" "}
                    Incidents
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="3" className="lie-link">
                    {" "}
                    List
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {isLoading && (
                <div className="global-loader-dv">
                  <GlobalLoder />
                </div>
              )}
              <div
                className="table-page-sec "
                style={{ display: isLoading && "none" }}
              >
                <div className="topp-div">
                  <div className="table-head-sec">
                    <div className="serch-bar-in">
                      <span>
                        {" "}
                        <img className="me-2" alt="" src={Images.sear} />
                      </span>
                      <input
                        type="text"
                        placeholder="Search keywords"
                        value={payloadState.search}
                        onChange={(e) => {
                          setPayloadState((prev) => ({
                            ...payloadState,
                            search: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="inci-sour-drop">
                      <div className="select-relation-ps">
                        <Select
                  
                          onChange={handleIncidentSourceChange}
                          placeholder={"Incident Source"}
                          options={[
                            { label: "News Articles", value: "newsArticles" },
                            { label: "NGO Reports", value: "NGOReports" },
                          ]}
                          mode="multiple"
                          // style={{ width: "250px" }}
                          //   value={selectedRelation}
                        ></Select>
                      </div>
                    </div>
                  </div>

                  <div className="right-side-colm">
                    <Tooltip placement="top" title={text}>
                      <div className="table-downl-btn">
                        <img src={Images.framedow} />
                      </div>
                    </Tooltip>

                    <div className="colum-box">
                      <div className="select-relation-ps add-icons-select-box-ps">
                        <div className="col-box-ps">
                          <img src={Images.columns} />
                        </div>
                        <Select
                        
                          onChange={handleChange}
                          placeholder={"Columns"}
                          value={[]}
                          popupClassName="incident-modal-columns-select"
                          options={columnsOptions}
                          mode="multiple"
                          showSearch={false}
                          style={{ width: "200px" }}
                        ></Select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-box incident">
                  <IncidentsListModalTable
                    incidents={incidents}
                    payloadState={payloadState}
                    setPayloadState={setPayloadState}
                    tableHeaderArray={tableHeaders}
                  />
                  {/* <ReferenceTable/>    */}
                </div>
                <div className="page-nation">
                  <div className="row-per">
                    <span>Rows per page:</span>
                    <Select
                      value={payloadState.limit}
                      style={{ width: 60 }}
                      onChange={handleLimitChange}
                      options={[
                        { value: 25, label: "25" },
                        { value: 30, label: "30" },
                        { value: 35, label: "35" },
                      ]}
                    />
                  </div>

                  <div className="page-num">
                    <span>
                      <img src={Images.chevron} />
                    </span>{" "}
                    <Pagination
                      onChange={onPaginationChange}
                      pageSize={payloadState.limit}
                      total={incidents.pagination.totalCount}
                      itemRender={itemRender}
                      current={
                        Math.ceil(payloadState.offset / payloadState.limit) + 1
                      }
                    />{" "}
                    <span>
                      <img src={Images.chevronRighta} />
                    </span>
                  </div>

                  <div className="goto-pg pg-number">
                    <span>Go to page:</span>
                    <input
                      type="number"
                      name="search2"
                      value={perPageValue}
                      onKeyDown={preventInvalidNumberInput}
                      onChange={handeGoToPageInput}
                    />
                    <button
                      type="button"
                      onClick={() => onPaginationChange(perPageValue)}
                    >
                      Go
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default IncidentsListModal;
