import { createSlice } from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "../reducers";

const initialState = {
  toolBox: {
    canRedo: true,
    canUndo: true,
    showLayoutSelector: false,
    showBadgeSelector: false,
    isGroupNode: false,
    showNodeSelector: false,
  },
};
const toolBoxSlice = createSlice({
  name: "toolBoxSlice",
  initialState,
  reducers: {
    setCanRedo: (state, { payload: canRedo }) => {
      // eslint-disable-next-line no-param-reassign
      state.toolBox.canRedo = canRedo;
    },
    setCanUndo: (state, { payload: canUndo }) => {
      // eslint-disable-next-line no-param-reassign
      state.toolBox.canUndo = canUndo;
    },
    setShowLayoutSelector: (state, { payload: showLayoutSelector }) => {
      // eslint-disable-next-line no-param-reassign
      state.toolBox.showLayoutSelector = showLayoutSelector;
    },
    setShowBadgeSelector: (state, { payload: showBadgeSelector }) => {
      // eslint-disable-next-line no-param-reassign
      state.toolBox.showBadgeSelector = showBadgeSelector;
    },
    setIsGroupNode: (state, { payload: isGroupNode }) => {
      // eslint-disable-next-line no-param-reassign
      state.toolBox.isGroupNode = isGroupNode;
    },
    setShowNodeSelector: (state, { payload: showNodeSelector }) => {
      // eslint-disable-next-line no-param-reassign
      state.toolBox.showNodeSelector = showNodeSelector;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});
export const {
  setCanRedo,
  setCanUndo,
  setShowLayoutSelector,
  setShowBadgeSelector,
  setIsGroupNode,
  setShowNodeSelector,
} = toolBoxSlice.actions;
export const toolBoxReducer = toolBoxSlice.reducer;
