import { useState } from "react";
import { useDispatch } from "react-redux";
import { incidentMapViewStateAction } from "../../Redux/Action/incidents";

export const MapTypeButton = ({ className }) => {
  const [btn, setBtn] = useState(true);
  const dispatch = useDispatch();
  const buttonStateHandler = () =>
    setBtn((btn) => {
      if (!btn === false) {
        dispatch(incidentMapViewStateAction("Severity"));
      } else {
        dispatch(incidentMapViewStateAction("ESG Category"));
      }
      return !btn;
    });
  return (
    <div className={`esg-select-btn ${className}`} style={{ width: 280 }}>
      <div
        onClick={buttonStateHandler}
        className={`${
          btn ? "button-esg-active full-map-active" : "button-sent-ps"
        } `}
        style={{ cursor: "pointer", width: 140 }}
      >
        <p>ESG Category</p>
      </div>
      <div
        style={{
          cursor: "pointer",
          width: 140,
          display: "flex",
          justifyContent: "center",
        }}
        onClick={buttonStateHandler}
        className={`${
          !btn ? "button-esg-active full-map-active" : "button-sent-ps"
        }`}
      >
        <p>Severity</p>
      </div>
    </div>
  );
};
