import React from "react";
import { Link } from "react-router-dom";

import { useFormik } from "formik";

import Images from "../../../Themes/ImageConstant";
import { PasswordInput } from "../../../Componets/PasswordInput";
import {
  initialValuesLogin,
  loginSchema,
  validationSchema,
} from "../../../Utils/schema";
import { isInternetConnected } from "../../../Utils/InternetConnection";
import { loginAction } from "../../../Redux/Action/authentication";
import { dispatch } from "../../../Utils/dispatchNavigate";
import { CustomInput } from "../../../Componets/CustomInput";
import { GoogleLogin } from "../../../Componets/GoogleLogin";
import { useSelector } from "react-redux";

const Login = () => {
  const [keepLogin, setKeepLogin] = React.useState(false);
  const login = useSelector((state) => state.authenticationReducer);
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    isInternetConnected() && dispatch(loginAction([values, keepLogin]));
  };
  const formik = useFormik({
    initialValues: initialValuesLogin,
    validationSchema: validationSchema(loginSchema),
    onSubmit,
  });

  return (
    <div className="page-header">
      <div className="left-right-mg">
        <div className="main-div mt-4 mb-4">
          <div className="login-form">
            <div className="login-page-logo">
              <img src={Images.logo} />
            </div>
            <div className="log-side">
              <div className="login-text">
                <h5>Login</h5>
              </div>
              {/* <GoogleLogin keepLogin={keepLogin} /> */}
              <hr></hr>
              {login.status === true ? <div className="auth-fail-div">
                <p className="login-link">
                  {login.message}
                </p>
              </div> : null}

              <form noValidate onSubmit={formik.handleSubmit}>
                <div className="input-text">
                  <label for="fname">Email</label>
                  <CustomInput
                    type="text"
                    id="fname"
                    name="email"
                    placeholder="Enter your email address"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.errors.email}
                  />
                </div>
                <div className="input-text">
                  <PasswordInput
                    value={formik.values.password}
                    showEye={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.errors.password && formik.errors.password}
                    reset={() => {
                      formik.setFieldValue("password", "");
                    }}
                  />
                </div>
                <div className="fog-pass">
                  <div className=" custom-checkbox ">
                    <input
                      onChange={() => setKeepLogin(() => !keepLogin)}
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                    />
                    <label for="vehicle1"> Keep me logged in</label>
                  </div>
                  <div className="fog-link">
                    <Link to="/forgotpassword">Forgot Password?</Link>
                  </div>
                </div>
                <div className="login-link">
                  <button type="submit" className="login-link">
                    Login
                  </button>
                </div>
              </form>

              <div className="forgot-link">
                <span>Don't have any account? </span>
                <Link to="/signup">SIGN UP</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
