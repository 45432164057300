import {
  Box,
  Drawer,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import useAxios from "axios-hooks";
import CloseIcon from "./CloseIcon";
import {
  setRightClickMenuSideBarNodeId,
  setShowCompanyActionSideBar,
} from "../../redux/GraphExploration/rightClickMenuSlice";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
// import { getSelectedNodes } from "../../utils/index";
import GeneralInfo from "./GeneralInfo";
import Top5CompaniesWithMostIncidents from "./Top5CompaniesWithMostIncidents";
import Top5CompaniesWithMostConnections from "./Top5CompaniesWithMostConnections";
import Top5MostRiskiestConnections from "./Top5MostRiskiestConnections";
// import { useGraphObject } from "../context/MyContext";
import { getToken } from "../../../../../Utils";

function CompanyActionSideBar() {
  const showCompanyActionSideBar = useAppSelector(
    (state) => state.rightClickMenu.showCompanyActionSideBar
  );
  const currentAction = useAppSelector(
    (state) => state.rightClickMenu.currentAction
  );
  const from = useAppSelector((state) => state.timeRange.from);
  const to = useAppSelector((state) => state.timeRange.to);
  const dispatch = useAppDispatch();

  const sideBarNodeId = useAppSelector(
    (state) => state.rightClickMenu.sideBarNodeId
  );
  const nodes = useAppSelector((state) => state.graph.nodes);
  const targetNode = nodes.find((node) => node.id === sideBarNodeId);

  const params = {
    from,
    to,
  };
  const token = getToken();
  const baseUrlApi = useAppSelector((state) => state.api.baseUrlApi);

  let actionInUrlName = "";
  switch (currentAction) {
    case "Top 5 riskiest connections":
      actionInUrlName = "top5-riskiest-connections";
      break;

    case "Top 5 with most incidents":
      actionInUrlName = "top5-with-most-incidents";
      break;

    case "Top 5 with most connections":
      actionInUrlName = "top5-with-most-connections";
      break;

    case "The riskiest path":
      actionInUrlName = "riskiest-path";
      break;

    default:
      break;
  }

  const [{ data, loading }] = useAxios(
    {
      url: `${baseUrlApi}/companies/${sideBarNodeId}/actions/${actionInUrlName}`,
      params,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        // Add any other headers if needed
      },
    },
    { manual: !showCompanyActionSideBar }
  );

  const theme = useTheme();
  const handleClose = () => {
    dispatch(setShowCompanyActionSideBar(false));
    dispatch(setRightClickMenuSideBarNodeId(undefined));
  };
  return (
    <Drawer
      anchor="right"
      open={showCompanyActionSideBar}
      onClose={handleClose}
      hideBackdrop
      elevation={1}
      variant="persistent"
    >
      <Box sx={{ width: 464 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            borderBottom: `1px solid ${theme.palette.gray["50"]}`,
            height: "59px",
          }}
        >
          <Box>
            <Typography variant="subtitle3">Actions</Typography>
          </Box>
          <Box>
            <IconButton sx={{ padding: 0 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {loading ? (
          <LinearProgress />
        ) : (
          data && (
            <Stack
              sx={{
                padding: "24px",
                height: "calc(100vh - 59px)",
                overflowY: "auto",
              }}
              gap={4}
            >
              {targetNode?.data && (
                <GeneralInfo
                  data={targetNode?.data}
                  // onViewCompanyProfile={onViewCompanyProfile}
                  showRiskScore={false}
                />
              )}
              {currentAction === "Top 5 with most incidents" &&
                data[0]?.counts !== undefined && (
                  <Top5CompaniesWithMostIncidents data={data} />
                )}
              {currentAction === "Top 5 with most connections" &&
                data[0]?.stat !== undefined && (
                  <Top5CompaniesWithMostConnections data={data} />
                )}
              {currentAction === "Top 5 riskiest connections" &&
                data[0]?.companyRisk !== undefined && (
                  <Top5MostRiskiestConnections data={data} />
                )}
            </Stack>
          )
        )}
      </Box>
    </Drawer>
  );
}
export default CompanyActionSideBar;
