import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

function GlobalSpinner({colorx}) {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          theme="outlined"
          style={{
            fontWeight: "bold",
            fontSize: 24,
            color:colorx?colorx: "#fff",
          }}
        />
      }
    />
  );
}

export default GlobalSpinner;
