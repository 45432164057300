const mapOptions = () => {
  var myOptions = {
    disableDefaultUI: true,
    keyboardShortcuts: false,
    height: 100,
    width: 100,
    mapTypeId: "roadmap",
  };
  const defaultProps = {
    center: {
      lat: 47.4979,
      lng: 19.0402,
    },
    zoom: 11,
  };

  return { myOptions, defaultProps };
};

export default mapOptions;
