import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
  portfolioListLoading: false,
  portfolioList: [],
  portfolioListSuccess: false,
  portfolioChangeClrLoading: false,
  addPortfolioLoading: false,
  addPortfolioSuccess: false,
  companyPortGraphData: [],
  companyPortGraphLoader: false,
  companyPortGraphSuccess: false,
  mostFrequentEsgData: {},
  mostFrequentEsgLoader: false,
  mostFrequentEsgSuccess: false,
  portfolioDetail: {},
  portfolioDetailLoader: false,
  portfolioDetailSuccess: false,
  unGlobalGraphData: {},
  unGlobalGraphLoader: false,
  unGlobalGraphSuccess: false,
  alertReportData: [],
  alertReportLoader: false,
  alertReportSuccess: false,
  esgRiskLoader: false,
  esgRiskData: {},
  riskScoreCompData: [],
  riskScoreCompLoader: false,
  riskScoreCompSuccess: false,
  highSeverityIncidentData: [],
  highSeverityIncidentLoader: false,
  highSeverityIncidentSuccess: false,
  uploadMediaFileLoader: false,
  uploadMediaFileSuccess: false,
  uploadMediaFileData: [],
  deletePortfolioSuccess: false,
  deletePortfolioLoader: false,
  getEditDetail: {},
  getEditDetailLoader: false,
  getEditDetailSuccess: false,
  editPortfolioSuccess: false,
  editPortfolioLoader: false,
  distributionGraphData: [],
  distributionGraphLoader: false,
  distributionGraphSuccess: false,
  clearAlertRulesSuccess: false,
  clearAlertRulesLoader: false,
  checkValidCompanyLoader: false,
  checkValidCompanySuccess: false,
  checkValidCompanyData: {
    holdings: [],
  },
  companiesSearchList: [],
  companiesSearchListPaginationData: {},
  companiesSearchListLoader: false,
  companiesSearchListSuccess: false,
  portfolioStatusData: [],
  portfolioStatusLoader: false,
  portfolioStatusSuccess: false,
  portfolioCompaniesList: [],
  portfolioCompaniesListSuccess: false,
  portfolioCompaniesPagination: {},
  portfolioWeightDistributionData: null,
  portfolioWeightDistributionLoader: false,
  portfolioWeightDistributionSuccess: false,
  validCompany: null,
  invalidCompany: null,
  validStatus: false,
  inValidHoldinggList: [],
  inValidHoldings: 0,
  isUploading: false,
};

function getAllCountries(data) {
  let allCountriesData = [];
  for (let i in data) {
    let obj = {
      id: data[i].uuid,
      value: {
        label: data[i].name,
        value: data[i].uuid,
        compId: data[i].id,
        id: data[i].uuid,
        isin: data[i].isin,
        tickers: data[i].tickers,
        alter_names: data[i].alter_names,
        alter_name: data[i].alter_name

      },
    };

    allCountriesData.push(obj);
  }
  return allCountriesData;
}

export const portfolio = (state = initialState, action) => {
  let { payload, key } = action;
  switch (action.type) {
    case types.GET_PORTFOLIO_LIST_LOAD:
      return {
        ...state,
        portfolioListLoading: true,
      };

    case types.GET_PORTFOLIO_LIST_SUCCESS:
      return {
        ...state,
        portfolioList: payload,
        portfolioListSuccess: true,
        portfolioListLoading: false,
      };

    case types.GET_PORTFOLIO_LIST_FAIL:
      return {
        ...state,
        portfolioListLoading: false,
      };

    case types.PORTFOLIO_CHANGE_CLR_LOAD:
      return {
        ...state,
        portfolioChangeClrLoading: true,
      };

    case types.PORTFOLIO_CHANGE_CLR_SUCCESS:
      let filteredData = [...state.portfolioList];
      filteredData = filteredData.map((itm, index) => {
        let item = { ...itm };
        if (item._id === payload.portfolioId) {
          item.cardColor = {
            ECG__Color: payload?.ECG__Color,
            Portfolio_Color: payload?.Portfolio_Color,
            background: payload?.background,
            color: payload?.color,
            id: payload?.id,
          };
        }
        return item;
      });
      return {
        ...state,
        portfolioList: filteredData,
        portfolioChangeClrLoading: false,
      };

    case types.PORTFOLIO_CHANGE_CLR_FAIL:
      return {
        ...state,
        portfolioChangeClrLoading: false,
      };

    case types.ADD_PORTFOLIO_LOAD:
      return {
        ...state,
        addPortfolioLoading: true,
        validStatus: false,
        inValidHoldings: 0,
      };

    case types.ADD_PORTFOLIO_SUCCESS:
      return {
        ...state,
        addPortfolioSuccess: true,
        addPortfolioLoading: false,
        validStatus: false,
        inValidHoldings: 0,
      };

    case types.ADD_PORTFOLIO_FAIL:
      return {
        ...state,
        addPortfolioLoading: false,
      };

    case types.PORTFOLIO_COMP_LOAD:
      return {
        ...state,
        companyPortGraphLoader: true,
      };

    case types.PORTFOLIO_COMP_SUCCESS:
      return {
        ...state,
        companyPortGraphSuccess: true,
        companyPortGraphLoader: false,
        companyPortGraphData: payload.data,
      };

    case types.PORTFOLIO_COMP_FAIL:
      return {
        ...state,
        companyPortGraphLoader: false,
      };

    case types.MOST_FREQUENT_ESG_LOAD:
      return {
        ...state,
        mostFrequentEsgLoader: true,
      };

    case types.MOST_FREQUENT_ESG_SUCCESS:
      return {
        ...state,
        mostFrequentEsgSuccess: true,
        mostFrequentEsgLoader: false,
        mostFrequentEsgData: payload,
      };

    case types.MOST_FREQUENT_ESG_FAIL:
      return {
        ...state,
        mostFrequentEsgLoader: false,
      };

    case types.PORTFOLIO_DETAIL_LOAD:
      return {
        ...state,
        portfolioDetailLoader: true,
      };

    case types.PORTFOLIO_DETAIL_SUCCESS:
      return {
        ...state,
        portfolioDetailSuccess: true,
        portfolioDetailLoader: false,
        portfolioDetail: payload,
      };

    case types.PORTFOLIO_DETAIL_FAIL:
      return {
        ...state,
        portfolioDetailLoader: false,
      };

    case types.UN_GLOBAL_LOAD:
      return {
        ...state,
        unGlobalGraphLoader: true,
      };

    case types.UN_GLOBAL_SUCCESS:
      return {
        ...state,
        unGlobalGraphSuccess: true,
        unGlobalGraphLoader: false,
        unGlobalGraphData: payload,
      };

    case types.PORTFOLIO_ESG_RISK_LOAD:
      return {
        ...state,
        esgRiskLoader: true,
      };
    case types.PORTFOLIO_ESG_RISK_SUCCESS:
      return {
        ...state,
        esgRiskLoader: false,
        esgRiskData: payload,
      };

    case types.PORTFOLIO_ESG_RISK_FAIL:
      return {
        ...state,
        esgRiskLoader: false,
      };
    case types.UN_GLOBAL_FAIL:
      return {
        ...state,
        unGlobalGraphLoader: false,
      };

    case types.ALERT_REPORT_LOAD:
      return {
        ...state,
        alertReportLoader: true,
      };

    case types.ALERT_REPORT_SUCCESS:
      return {
        ...state,
        alertReportSuccess: true,
        alertReportLoader: false,
        alertReportData: payload,
      };

    case types.ALERT_REPORT_FAIL:
      return {
        ...state,
        alertReportLoader: false,
      };

    case types.RISK_SCORE_PER_COMP_LOAD:
      return {
        ...state,
        riskScoreCompLoader: true,
      };

    case types.RISK_SCORE_PER_COMP_SUCCESS:
      return {
        ...state,
        riskScoreCompSuccess: true,
        riskScoreCompLoader: false,
        riskScoreCompData: payload,
      };

    case types.RISK_SCORE_PER_COMP_FAIL:
      return {
        ...state,
        riskScoreCompLoader: false,
      };

    case types.HIGH_SEVERITY_INCIDENT_LOAD:
      return {
        ...state,
        highSeverityIncidentLoader: true,
      };

    case types.HIGH_SEVERITY_INCIDENT_SUCCESS:
      return {
        ...state,
        highSeverityIncidentSuccess: true,
        highSeverityIncidentLoader: false,
        highSeverityIncidentData: payload,
      };

    case types.HIGH_SEVERITY_INCIDENT_FAIL:
      return {
        ...state,
        highSeverityIncidentLoader: false,
      };

    case types.UPLOAD_FILE_PORTFOLIO_LOAD:
      return {
        ...state,
        uploadMediaFileLoader: true,
        validCompany: null,
        invalidCompany: null,
        validStatus: false,
        isUploading: true,
      };

    case types.UPLOAD_FILE_PORTFOLIO_SUCCESS:
      const getValidData = [...state.uploadMediaFileData].filter(item => (item?.name?.length > 0 && item))
      return {
        ...state,
        uploadMediaFileSuccess: true,
        uploadMediaFileLoader: false,
        uploadMediaFileData: [...payload.companies, ...getValidData],
        validCompany: payload.validCount,
        invalidCompany: payload.invalidCount,
        validPercentage: payload.validPercentage,
        validStatus: true,
        isUploading: false,
      };
    case types.UPDATE_UPLOAD_FILE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        uploadMediaFileData: payload,
      };

    case types.UPLOAD_FILE_PORTFOLIO_FAIL:
      return {
        ...state,
        uploadMediaFileLoader: false,
        isUploading: false
      };

    case types.DELETE_PORTFOLIO_LOAD:
      return {
        ...state,
        deletePortfolioLoader: true,
      };

    case types.DELETE_PORTFOLIO_SUCCESS:
      let tempPortFolioList = [...state.portfolioList];
      let filteredPortfolioListData = tempPortFolioList.filter(
        (item, index) => item._id !== payload.portfolioId
      );
      return {
        ...state,
        deletePortfolioSuccess: true,
        deletePortfolioLoader: false,
        portfolioList: filteredPortfolioListData,
      };

    case types.DELETE_PORTFOLIO_FAIL:
      return {
        ...state,
        deletePortfolioLoader: false,
      };

    case types.GET_EDIT_PORTFOLIO_DETAIL_LOAD:
      return {
        ...state,
        getEditDetailLoader: true,
      };

    case types.GET_EDIT_PORTFOLIO_DETAIL_SUCCESS:
      return {
        ...state,
        getEditDetailSuccess: true,
        getEditDetailLoader: false,
        getEditDetail: payload,
      };

    case types.GET_EDIT_PORTFOLIO_DETAIL_FAIL:
      return {
        ...state,
        getEditDetailLoader: false,
      };

    case types.EDIT_PORTFOLIO_LOAD:
      return {
        ...state,

        editPortfolioLoader: true,
        validStatus: false,
        inValidHoldings: 0,
      };

    case types.EDIT_PORTFOLIO_SUCCESS:
      return {
        ...state,
        editPortfolioSuccess: true,
        editPortfolioLoader: false,
        validStatus: false,
        inValidHoldings: 0,
      };

    case types.EDIT_PORTFOLIO_FAIL:
      return {
        ...state,
        editPortfolioLoader: false,
      };

    case types.DISTRIBUTION_GRAPH_LOAD:
      return {
        ...state,
        distributionGraphLoader: true,
      };

    case types.DISTRIBUTION_GRAPH_SUCCESS:
      return {
        ...state,
        distributionGraphSuccess: true,
        distributionGraphLoader: false,
        distributionGraphData: payload,
      };

    case types.DISTRIBUTION_GRAPH_FAIL:
      return {
        ...state,
        distributionGraphLoader: false,
      };

    case types.CLEAR_ALERT_RULES_LOAD:
      return {
        ...state,
        clearAlertRulesLoader: true,
      };

    case types.CLEAR_ALERT_RULES_SUCCESS:
      return {
        ...state,
        clearAlertRulesSuccess: true,
        clearAlertRulesLoader: false,
      };

    case types.CLEAR_ALERT_RULES_FAIL:
      return {
        ...state,
        clearAlertRulesLoader: false,
      };

    case types.CHECK_VALID_COMPNY_LOAD:
      return {
        ...state,
        inValidHoldinggList: [],
        checkValidCompanyLoader: true,
      };

    case types.CHECK_VALID_COMPNY_SUCCESS:
      return {
        ...state,
        checkValidCompanySuccess: true,
        checkValidCompanyLoader: false,
        checkValidCompanyData: payload,
        inValidHoldinggList: payload.holdings,
        inValidHoldings: payload.inValidHoldings,
      };

    case types.CHECK_VALID_COMPNY_FAIL:
      return {
        ...state,
        checkValidCompanyLoader: false,
      };

    case types.GET_PORTFOLIO_COMPANIES_SEARCH_LIST_INITIATE:
      return {
        ...state,
        companiesSearchListLoader: true,
        companiesSearchList: [],
        companiesSearchListPaginationData: {},
        validStatus: false,
      };

    case types.GET_PORTFOLIO_COMPANIES_SEARCH_LIST_SUCCESS:
      let allCompanies = getAllCountries(payload.countries);
      return {
        ...state,
        companiesSearchListSuccess: true,
        companiesSearchListLoader: false,
        companiesSearchList: allCompanies,
        companiesSearchListPaginationData: payload.pagination,
      };

    case types.GET_PORTFOLIO_COMPANIES_SEARCH_LIST_FAILURE:
      return {
        ...state,
        companiesSearchListLoader: false,
      };

    case types.GET_PORTFOLIO_STATUS_LOAD:
      return {
        ...state,
        portfolioStatusLoader: true,
      };

    case types.GET_PORTFOLIO_STATUS_SUCCESS:
      return {
        ...state,
        portfolioStatusSuccess: true,
        portfolioStatusLoader: false,
        portfolioStatusData: payload,
      };

    case types.GET_PORTFOLIO_STATUS_FAIL:
      return {
        ...state,
        portfolioStatusLoader: false,
      };

    case types.GET_PORTFOLIO_COMP_LOAD:
      return {
        ...state,
        portfolioStatusLoader: payload.export ? false : true,
      };

    case types.GET_PORTFOLIO_COMP_SUCCESS:
      return {
        ...state,
        portfolioCompaniesList: payload?.data,
        portfolioCompaniesPagination: payload?.pagination,
        portfolioCompaniesListSuccess: true,
        portfolioStatusLoader: false,
      };

    case types.GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_LOAD:
      return {
        ...state,
        portfolioWeightDistributionLoader: true,
      };

    case types.GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_SUCCESS:
      return {
        ...state,
        portfolioWeightDistributionSuccess: true,
        portfolioWeightDistributionLoader: false,
        portfolioWeightDistributionData: payload,
      };

    case types.GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_FAIL:
      return {
        ...state,
        portfolioWeightDistributionLoader: false,
      };

    case types.UPDATE_PORTFOLIO_STATE:
      state[key] = payload;
      return { ...state };

      case types.RESET_PORTFOLIO_DATA_ON_PAGE_LOAD:
        return {
          ...state,
          companyPortGraphData: [],
          portfolioDetail: {},
          alertReportData: [],
          riskScoreCompData: [],
          highSeverityIncidentData: [],
          distributionGraphData: [],
          portfolioStatusData: [],
          esgRiskData: {},
          portfolioWeightDistributionData: null,
        };

    default:
      return { ...state };
  }
};
