import React, { memo } from "react";
import { useSelector } from "react-redux";

import Confirmation from "./components/confirmation";
import PopUp from "./components/popup";


// import OTP from "./component/OTP";

/**
 * @description show Modal base upon TYPE MODAL_TYPE
 * @param {String} type
 * @param {boolean} show
 * @param {object} OK
 * @param {object} CANCEL
 * @param {string} header for OTP modal
 * @param {string} icon   success | danger \ logout
 * @returns
 */

const Modal = memo(() => {
  const { show, type, title, message, description, OK, CANCEL, icon } =
    useSelector((state) => state.global.modal);
  return (
    <PopUp show={show} CANCEL={CANCEL} title={title}>
      <Component
        OK={OK}
        icon={icon}
        type={type}
        title={title}
        CANCEL={CANCEL}
        description={description}
        show={show}
      />
    </PopUp>
  );
});

const Component = ({ type = "CONFIRM", ...props }) => {
  switch (type) {
    case "CONFIRM":
      return <Confirmation {...props} />;
  }
};

export default Modal;
