import { updateUploadFilePortFolioAction } from "../../../Redux/Action/portfolio";

export const addEmpty4Fields = (dispatch) => {
  const dummyObj = {
    "Company name": "",
    index: 0,
    isin: "",
    name: "",
    "portfolio weight": "",
    searchBase: null,
    ticker: "",
    tickers: {},
  };
  const clone = Array(4).fill({ ...dummyObj });
  dispatch(
    updateUploadFilePortFolioAction(clone)
  );
}