import React, { useEffect, useRef, useState } from "react";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import BubbleChart from "../../../../Componets/BubbleChart";
import Images from "../../../../Themes/ImageConstant";
import Styles from "./tweetTimeline.module.css";
import {
  structureData,
} from "../../../../Utils/chartCommandFunction";
import { getFilteredArrayforBubbles } from "../../../../Utils";
import { convertTimestampinFormatedDate } from "../../../../Utils/globalFunction";

const TweetTimeline = (networkData) => {

  const [enviromentDataModify, setEenviromentDataModify] = useState([]);

  useEffect(() => {
    if (Object.keys(networkData.data).length > 0) {
      let posiData = Object.entries(networkData.data);
      posiData = structureData(posiData, networkData.groupType ? networkData.groupType : "default", "Calledx");
      const comind = [...posiData.map(e => e[2])].sort((a, b) => a - b)
      const min = comind[0], max = comind[comind.length - 1]
      setEenviromentDataModify(getFilteredArrayforBubbles(posiData, min, max, [30, 40]))
    }
  }, [networkData?.data]);
  let mind = enviromentDataModify?.map(e => e[0]).sort()

  const incidentFormatTooltip = ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }) => {
    const type = w?.config.series[seriesIndex].name;
    const date = convertTimestampinFormatedDate(
      w?.config.series[seriesIndex].data[dataPointIndex][0],
      'monthName'
    );

    const firstNumberTitle =
      w?.config.series[seriesIndex].data[dataPointIndex][1];
    const number =
      w?.config.series[seriesIndex].data[dataPointIndex][3]?.length;
    return `
      <div style="width:152px;padding:12px; border-radius:5px;">
      <div style="font-weight:bold;font-size:14px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color:#676A73">
      ${date}
      </div>
      <div class="tooltip-item" style="display:flex;flex-direction:justify-content-between">
      <div style="display:flex;align-items:center;justify-content: space-around;gap:2px;">
        <div style="height:10px; width:10px; radius:2px; background-color:#0195ce"></div>
        <div>${"Incident"}:</div>
      <div>
      <div>${w?.config.series[seriesIndex].data.length}</div>
    </div>
      `;
  };

  const enviromentOptions = {
    series: [
      {
        name: "Series Data",
        data: networkData?.data ? enviromentDataModify : [],
      },
    ],
    options: {
      chart: {
        height: "100%",
        type: "bubble",
        toolbar: {
          show: false,
          tools: {
            zoom: false,
          },
        },
        // zoom: {
        //   enabled: false,
        // },
      },
      // plotOptions: {
      //   bubble: {
      //     dataLabels: {
      //       enabled: false,
      //       formatter: function (val, opts) {
      //         return "234";
      //       },
      //     },
      //   },
      // },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 0.8,
      },
      xaxis: {
        min: (mind[0] - (mind[0] * (0.1 / 100))) || undefined,
        max: (mind[mind.length - 1] + (mind[mind.length - 1] * (0.1 / 100))) || undefined,

        tickAmount: 12,
        type: "datetime",
        labels: {
          rotate: 0,
        },
      },
      yaxis: {
        max: 2,
        tickAmount: 2,
        show: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      colors: ["#0195CE"],
      theme: {
        palette: "palette2",
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
        custom: incidentFormatTooltip,
      },
    },
  };



  return (
    <section className="left-text">
      <div className="Sentiment-grah">
        <div className="incidentTimeline-tabs-top-div">
          <Chart
            width={networkData.width || '100%'}
            title={"Enviroment"}
            titleImage={Images.greenTile}
            titleIcon={Images.greenGloabe}
            chartData={enviromentOptions}
          />
        </div>
      </div>
    </section>
  );
};

const Chart = ({ title, titleImage, titleIcon, chartData }) => {
  return (
    <div className={Styles.chartContainer}>
      <BubbleChart options={chartData} height={300} width={690} />
    </div>
  );
};

export default TweetTimeline;
