import { Box, Typography, useTheme, Stack } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../redux/configuareStore";
import PathFindingModalButtons from "./PathFindingModalButtons";
import PathFindingModalRadioButtons from "./PathFindingModalRadioButtons";
import ShortestPathColorIcon from "./shortestPathColorIcon";

function PathFindingModal() {
  const theme = useTheme();
  const showPathFindingModal = useAppSelector(
    (state) => state.rightClickMenu.showPathFindingModal
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "64px",
        width: "700px",
        height: "182px",
        borderRadius: "6px",
        gap: "16px",
        padding: "16px 24px",
        zIndex: 1,
        transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transform: `translateX(-50%) scale(${showPathFindingModal ? 1 : 0.8})`,
        opacity: showPathFindingModal ? 1 : 0,
        visibility: showPathFindingModal ? "visible" : "hidden",
        boxShadow:
          "0px 8px 12px 1px rgba(0, 0, 0, 0.10), 0px -2px 8px 0px rgba(0, 0, 0, 0.10);",
        backgroundColor: theme.palette.white.main,
      }}
    >
      <Stack direction="row" gap="12px">
        <ShortestPathColorIcon />
        <Typography
          variant="bodySelected"
          style={{ color: theme.palette.gray[800] }}
        >
          Path Finding Mode Is Active
        </Typography>
      </Stack>
      <Box>
        <Box marginBottom="8px">
          <Typography variant="captionXL" color={theme.palette.gray[400]}>
            Select another node and then choose which type of path you want to
            find:
          </Typography>
        </Box>
        <PathFindingModalRadioButtons />
        <PathFindingModalButtons />
      </Box>
    </Box>
  );
}
export default PathFindingModal;
