// import React, { useCallback, useEffect, useMemo, useState } from "react";
// import { ChartCard } from "../../../Layouts/ChartCard";
// import { dispatch } from "../../../Utils/dispatchNavigate";
// import {
//     IncidentComparisonAction,
//     RemoverIncidentComparisonAction,
// } from "../../../Redux/Action/comapanyPageAction";
// import ImageConst from "../../../Themes/ImageConstant";
// import { AutoComplete, Modal, Row } from "antd";
// import { ChartBottomTitle } from "../../../Componets/ChartBottomTitle";
// import style from "../style.module.css";
// import { useSelector } from "react-redux";
// import ReactEcharts from "echarts-for-react";
// import * as echarts from 'echarts';


// var graphic = echarts.graphic;



// var option = {
//     toolbox: {
//         show: false,
//         feature: {
//             mark: { show: true },
//             dataView: { show: true, readOnly: false },
//             restore: { show: true },
//             saveAsImage: { show: true }
//         }
//     },
//     colors: ["#000"],


//     series: [
//         {
//             name: 'Access From',
//             type: 'pie',
//             radius: [65, 150],
//             center: ['50%', '50%'],
//             avoidLabelOverlap: false,
//             color: ['#85C3FD', '#A38BE5', '#5CBBE0', '#B7DE90'],
//             // emphasis: {
//             //   label: {
//             //     show: true,
//             //     fontSize: 40,
//             //     fontWeight: 'bold'
//             //   }
//             // },
//             itemStyle: {
//                 borderRadius: 0,
//                 borderColor: '#fff',
//                 borderWidth: 2
//             },
//             label: {
//                 // alignTo: 'edge',
//                 formatter: '{fontSize|{b}}\n\n{time|{c}}%',
//                 rich: {
//                     time: {
//                       fontSize: 13,
//                       color: '#2D303A',
//                       fontWeight: 600
//                     },
//                     labelText: {
//                       fontSize: 13,
//                       color: '#3D4353',
//                       fontWeight: 400
//                     },
//                   }
//                 // minMargin: 5,
//                 // edgeDistance: 100,

//                 // lineHeight: 15,
//               },
//             labelLine: {
//                 show: true
//             },
//             data: [

//                 { value: 40, name: 'Apple Inc.  ' },
//                 { value: 30, name: 'Microsoft' },
//                 { value: 20, name: 'Tesla' },
//                 { value: 10, name: 'Airbnb' },
//             ]
//         }
//     ]
// };

// const PieChartComponent = ({
//     id,
//     start,
//     end,
//     companyName,
//     dateChange,
// }) => {
//     const companiesList = useSelector(
//         (state) => state?.incidentReducer?.companiesList
//     );
//     const [options, setOptions] = useState([]);
//     const [countryName, setCountryName] = useState(null);
//     const [comparisonModal, setComparisonModal] = useState(false);

//     useEffect(() => {
//         // dispatch(IncidentComparisonAction({ id, start, end, type: "multiple" }));
//     }, [dateChange]);

//     const { countryList } = useSelector((state) => state?.supplyChainReducer);
//     const { IncidentComparison } = useSelector(
//         (state) => state?.companyPageReducer
//     );

//     useEffect(() => {
//         setOptions(countryList);
//     }, [countryList]);

//     const handleSearch = (value) => {
//         let res = [];
//         if (!value) {
//             res = [];
//         } else {
//             res = countryList.filter((x) =>
//                 x?.label?.toUpperCase()?.includes(value?.toUpperCase().trim())
//             );
//         }
//         setOptions(res);
//     };
//     const Chart = useCallback(() => {
//         return (
//             <div key={"value"} className="main_container_portfolio">
//                 <div className=" supply-chain-pie-chart">
//                     <div className="piechart-portfolio_sub">
//                         <ReactEcharts className={"pieChart_portfolio"} option={option} />
//                     </div>
//                     <div className={style.portfolioRiskChartCenter}>
//                         <div className={style.EsgText}>
//                         ESG
//                         </div>
//                         <div className={style.riskScore}>
//                             Risk Score
//                         </div>
//                         <div className={style.numberSeven}>
//                             75
//                         </div>
//                     </div>
//                 </div>

//             </div>
//         )
//     }, [IncidentComparison]);

//     const renderItem = (title, item) => ({
//         value: item,
//         label: (
//             <div
//                 style={{
//                     pointerEvents: id === item ? "none" : "",
//                     cursor: id === item ? "no-drop" : "pointer",
//                 }}
//             >
//                 {title}
//             </div>
//         ),
//     });
//     // country option on first render
//     const CreateOptions = useMemo(() => {
//         let arr = [];
//         for (let i = 0; i < options?.length - 1; i++) {
//             if (options[i].id === id) continue;
//             arr.push(renderItem(options[i].label, options[i].id));
//         }
//         return arr;
//     }, [options, id]);
//     return (
//         <section className="left-text">
//             <Chart />
//         </section>
//     );
// };

// export default PieChartComponent;
import { useCallback } from "react";
import ReactApexChart from "react-apexcharts";


const getSeries=(data,key)=>{
    let arr=[]
    if(data?.length >0 ){
        for( let i in data){
            if(key == "risk"){
          arr.push(Math.round(data[i][key]))
        }
        else{
            arr.push(data[i][key])
        }
        }
    }
    return arr

}


const PieChartComponent = ({allData,risk}) => {
    const ESG = 495
    const data = {
        series: getSeries(allData,"risk"),
        options: {
            chart: {
                width: 250,
                type: 'donut',
                dropShadow: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    top: 0,
                    left: 0,
                    blur: 3,
                    color: '#000',
                    opacity: 0.35
                }
            },
            labels: getSeries(allData,"company"),
            legend: {
                show: false,
                position: 'bottom'
            },
            
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                    expandOnClick: false,
                    donut: {
                        size: '60%',
                        labels: {
                            transform: "translate(0,5)",
                            show: true,
                            total: {
                                show: true,
                                showAlways: true,
                                color: "#000",
                                fontWeight: 600,
                                offsetY: 15,
                                fontFamily: "Inter",
                                fontSize: "21px",
                                label: `ESG Risk Score`,
                                formatter: function (w) {
                                    return risk
                                }
                            },
                            value: {
                                offsetY: 20,
                                fontFamily: "Inter",
                                color: '#D00000',
                                fontSize: "21px",
                                lineHeight: "24px"
                            },
                                               }

                    }

                },

            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val.toFixed(0) + "%"
                }
            },
            yaxis: {
                show: true,
                showAlways: true,
                showForNullSeries: true,
                seriesName: undefined,
                opposite: false,
                reversed: false,
                logarithmic: false,
                logBase: 10,
                tickAmount: 6,
                min: 6,
                max: 6,
                forceNiceScale: false,
                floating: false,
                decimalsInFloat: undefined,
                labels: {
                    show: true,
                    align: 'right',
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                },
                axisBorder: {
                    show: true,
                    color: '#78909C',
                    offsetX: 0,
                    offsetY: 0
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#78909C',
                    width: 6,
                    offsetX: 0,
                    offsetY: 0
                },
                title: {
                    text: undefined,
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                crosshairs: {
                    show: true,
                    position: 'back',
                    stroke: {
                        color: '#b6b6b6',
                        width: 1,
                        dashArray: 0,
                    },
                },
                tooltip: {
                    enabled: true,
                    offsetX: 0,
                },
            },
            colors: ['#85C3FD', '#A38BE5', '#5CBBE0', '#B7DE90'],
            // fill: {
            //     type: 'gradient',
            // },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                }
            }]
        },


    };
    const Chart = useCallback(() => {
        return (
            <div key={"value"} className="main_container_portfolio">
                <div className=" supply-chain-pie-chart">
                    <div className="piechart-portfolio_sub">
                        <ReactApexChart options={data.options} series={data.series} type="donut" width={"100%"} height={400} />
                    </div>
                </div>
            </div>
        )
    }, [allData]);


    return (
        <section className="left-text">
            <Chart />
        </section>
    )
}
export default PieChartComponent

