import { useEffect, useState } from "react";
import { Input, Pagination, Select } from "antd";
import ImageConst from "../../../Themes/ImageConstant";
import { dispatch } from "../../../Utils/dispatchNavigate";

// This use to change total item render in the page
const offSetPage = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "40", label: "40" },
  { value: "50", label: "50" },
];

// This is use to render next and previous text with icons
const pageNavigation = (_, type, originalElement) => {
  return type === "prev" ? (
    <span>
      <img src={ImageConst.chevron} alt="left arrow" />
      &nbsp;
      <span>Previous</span>
    </span>
  ) : type === "next" ? (
    <span>
      <span>Next</span>&nbsp;
      <img src={ImageConst.chevronRighta} alt="right arrow" />
    </span>
  ) : (
    originalElement
  );
};

const SupplyPagination = ({ pagination, action, data, cb = () => {},limtSet }) => {
  const [TotalItem, setTotalItem] = useState(10);
  const [goToPage, setGoToPage] = useState(1);

  const paginationData = {
    offset: 0,
    limit: +TotalItem,
    ...data,
  };
  // @param value {number}
  const onPageLimitChange = (value) => {
    if (value) {
      const pagination = {
        ...paginationData,
        ...data,
        limit: +value,
      };
      cb(pagination);
      dispatch(action({data:pagination}));
    }
  };

  // @param value {number}
  const onPageChange = (value) => {
    if (value && value > 0) {
      const pagination = {
        ...paginationData,
        ...data,
        offset: (value - 1) * TotalItem,
      };
      cb(pagination);
      dispatch(action({data:pagination}));
    }
    else{
        const pagination = {
            ...paginationData,
            ...data,
            offset: 0,
          };
          cb(pagination);
          dispatch(action({data:pagination}));
    }
  };
  return (
    <div className="row-per CustomPagination paginateSupply">
      <span className="offsetContainer">
        <span>Rows per page:&nbsp;</span>
        <Select
          className="offsetSelectPage"
          defaultValue="10"
          style={{ width: 70,alignItems:'center'}}
          onChange={(e) => (onPageLimitChange(e), setTotalItem(e),limtSet(e))}
          options={offSetPage}
        />
      </span>
      <div className="page-num">
        <Pagination
          current={pagination?.currentPage}
          pageSize={TotalItem}
          total={pagination?.totalCount}
          itemRender={pageNavigation}
          onChange={onPageChange}
        />
      </div>
      <div className="goto-pg">
        <span>Go to page:</span>
        <div className="pg-number">
          <Input
            type="number"
            name="search2"
            onChange={(e) => setGoToPage(e.target.value)}
            style={{ height: "28px" }}
          />
          <button
            type="button"
            onClick={() => onPageChange(goToPage)}
            style={{ height: "28px" }}
          >
            Go
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupplyPagination;
