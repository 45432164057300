import { Box, Typography, useTheme } from "@mui/material";
import CustomSwitch from "../../../components/CustomSwitch";

function ToggleParameter({ label, checked, onChange }) {
  const theme = useTheme();
  const border = `1px solid ${theme.palette.gray[50]}`;
  return (
    <Box
      sx={{
        height: "48px",
        borderBottom: border,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingX: "32px",
        paddingY: "16px",
      }}
    >
      <Box>
        <Typography variant="subtitle3">{label}</Typography>
      </Box>
      <CustomSwitch checked={checked} onChange={() => onChange(!checked)} />
    </Box>
  );
}
export default ToggleParameter;
