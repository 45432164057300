import React, { useCallback, useState } from "react";
import { Select } from "antd";
import { ImageSource } from "mapbox-gl";
import Images from "../../Themes/ImageConstant";

/**
 * @description  show the table header which is selected
 * @param {array} tableHeader
 * @param {callback} cb return the array of element which need to visible
 */

const TableColumn = ({ tableHeader, cb }) => {
  const arr = [...tableHeader];
  const [open, setOpen] = useState(false);
  const [tableHeaders, setTableHeader] = useState(tableHeader);

  const onHandleChange = useCallback(
    (e) => {
      const array = [...tableHeaders];
      const index = array.findIndex((x) => x === e.target.value);
      index > -1 ? array.splice(index, 1) : array.push(e.target.value);
      setTableHeader(array);
      cb(array);
    },
    [tableHeader, tableHeaders]
  );
  return (
    <div className="colum-box new-cloum-box-ps cstm_height">
      <img src={Images.columnsImages} />
      <Select
        suffixIcon={null}
        className="columns2"
        value={"Columns"}
        popupClassName="popUp-unique"
        onClick={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        open={open}
      >
        {arr.map((item, index) => {
          return (
            <div
              className="check-box "
              key={`${item + index}`}
            >
              <input
                className="downl-btn"
                value={item}
                id={item}
                checked={tableHeaders.includes(item)}
                onChange={onHandleChange}
                type="checkbox"
              />
              <label className="downl-btn"  for={item}>{item}</label>
            </div>
          );
        })}
      </Select>
    </div>
  );
};

export default React.memo(TableColumn);
