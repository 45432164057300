import ReactApexChart from "react-apexcharts";
import { getPercentage} from "../../../Utils/index"

const pieChartData = {
    options: {
      chart: {
        type: "pie",
      },
      legend: {
        show: false,
      },
      labels: ["Neutral", "Negative", "Positive"],
      chart: {
        toolbar: {
          show: false,
        },
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 0,
        dashArray: 0, 
    },
    

      
      colors: ["#B7BDC8", "#D00000", "#1A9C3E"],
      fill: {
        type: 'gradient',
    },
      plotOptions: {
        pie: {
          dataLabels: {
            style: {
              textAnchor: "center", // Center-align the text
            },
            offset: -20,
            enabled: true, // Enable data labels
            formatter: function (val) {
              return Math.floor(val) + "%";
            },
          },
        },
      },
    },
  };

const ReactionAnalysesChart = ({ data = null, graphType = null }) => {
  
  
  
    return  <ReactApexChart options={pieChartData.options} series={getPercentage(data, graphType, 3)} type="pie" width={180} />
}
export default ReactionAnalysesChart