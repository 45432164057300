import { Box, Input, Slider, Stack, Typography } from "@mui/material";
import { CustomAccordionDetails } from "../../../../components/CustomeAccordion";
import { useGraphObject } from "../../../context/MyContext";
import { FilterParametersEnum } from "../../../../enums/FilterSideBarEnums";
import { updateHiddenNodes } from "../../../../utils";
import { updateFilterParameterField } from "../../../../redux/GraphExploration/FilterSideBarSlice";
import store, {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/configuareStore";
import CustomNumberInput from "./CustomNumberInput";
import { useState } from "react";

export default function TotalIncidentCountAccordion() {
  const dispatch = useAppDispatch();
  const filterParameters = useAppSelector(
    (state) => state.filterSideBar.filterParameters
  );
  const filterParameter = filterParameters.find(
    (item) => item.id === FilterParametersEnum.TOTAL_INCIDENT_COUNT
  );
  const { graphObject } = useGraphObject();
  const nodes = useAppSelector((state) => state.graph.nodes);

  const [disabled, setDisabled] = useState(false);

  const handleChange = (value) => {
    let newValue = value;
    if (value[0] > filterParameter?.selectedRange?.[1]) {
      newValue = [value[1], value[1]];
    }
    if (value[1] < filterParameter?.selectedRange?.[0]) {
      newValue = [value[0], value[0]];
    }

    store.dispatch(
      updateFilterParameterField({
        itemId: filterParameter?.id,
        fieldToUpdate: "selectedRange",
        newValue: newValue,
      })
    );
    const nodesToHide = nodes.filter((n) => {
      const incidentCount = n.data?.incidentCount || {};

      return (
        incidentCount?.[FilterParametersEnum.TOTAL_INCIDENT_COUNT] < value[0] ||
        incidentCount?.[FilterParametersEnum.TOTAL_INCIDENT_COUNT] > value[1]
      );
    });
    dispatch(
      updateFilterParameterField({
        itemId: FilterParametersEnum.TOTAL_INCIDENT_COUNT,
        fieldToUpdate: "hiddenNodeIds",
        newValue: nodesToHide.map((n) => n.id),
      })
    );
    updateHiddenNodes(graphObject);
  };
  const rangeCheckOverideForLeftInput = (inputValue) => {
    if (inputValue < 0) {
      return 0;
    }
    if (
      inputValue >
      (filterParameter?.selectedRange?.[1] ||
        filterParameter?.rangeLimit?.[1] ||
        1000)
    ) {
      return (
        filterParameter?.selectedRange?.[1] ||
        filterParameter?.rangeLimit?.[1] ||
        1000
      );
    }
    return Math.round(Number(inputValue));
  };

  const rangeCheckOverrideForRightInput = (inputValue) => {
    if (inputValue < filterParameter?.selectedRange?.[0] || 0) {
      return filterParameter?.selectedRange?.[0] || 0;
    }
    const maxValid = filterParameter?.rangeLimit?.[1] || 1000;
    if (inputValue > maxValid) {
      return maxValid;
    }
    return Math.round(Number(inputValue));
  };


  return (
    <CustomAccordionDetails>
      <Stack padding="16px" gap="24px">
        <Stack direction="row" gap={3} alignItems={"center"}>
          <Typography>
            Min: {filterParameter?.selectedRange?.[0] || 0}
          </Typography>
          <CustomNumberInput
            disabled={disabled}
            value={filterParameter?.selectedRange?.[0] || 0}
            onChange={(e, v) => {
              setDisabled(true);
              setTimeout(() => {
                setDisabled(false);
              }, 500);

              const input = Number(v);
              const safeInput = rangeCheckOverideForLeftInput(input);
              handleChange([
                safeInput,
                filterParameter?.selectedRange?.[1] || filterParameter?.rangeLimit?.[1] || 1000,
              ]);
            }}
          />
          <Slider
            min={0}
            max={filterParameter?.rangeLimit?.[1] || 1000}
            value={[
              filterParameter?.selectedRange?.[0] || 0,
              filterParameter?.selectedRange?.[1] ||
                filterParameter?.rangeLimit?.[1] ||
                1000,
            ]}
            // onClick={handleClick}
            valueLabelDisplay="auto"
            onChangeCommitted={(event, value) => {
              handleChange(value);
            }}
          />
          <Typography>
            Max:{" "}
            {filterParameter?.selectedRange?.[1] ||
              filterParameter?.rangeLimit?.[1] ||
              1000}
          </Typography>
          <CustomNumberInput
            disabled={disabled}
            value={
              filterParameter?.selectedRange?.[1] ||
              filterParameter?.rangeLimit?.[1] ||
              1000
            }
            onChange={(e, v) => {
              setDisabled(true);
              setTimeout(() => {
                setDisabled(false);
              }, 500);
              const input = Number(v);
              const safeInput = rangeCheckOverrideForRightInput(input);
              handleChange([
                filterParameter?.selectedRange?.[0] || 0,
                safeInput,
              ]);
            }}
          />
        </Stack>
      </Stack>
    </CustomAccordionDetails>
  );
}
