/* eslint-disable no-param-reassign */
import {
  Box,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Checkbox,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/configuareStore";
import { FilterParametersEnum } from "../../../../enums/FilterSideBarEnums";
import { updateFilterParameterField } from "../../../../redux/GraphExploration/FilterSideBarSlice";
import { useGraphObject } from "../../../context/MyContext";
import {
  underlineAwareToTitleCase,
  updateHiddenNodes,
} from "../../../../utils";
import { CustomAccordionDetails } from "../../../../components/CustomeAccordion";
import CheckBoxLabel from "./CheckBoxLabel";

export default function IncidentCategoryAccordion() {
  const filterParameters = useAppSelector(
    (state) => state.filterSideBar.filterParameters
  );
  const { graphObject } = useGraphObject();
  const dispatch = useAppDispatch();
  const nodes = useAppSelector((state) => state.graph.nodes);
  const handleChange =
    (filterParameter, isParent = false) =>
    (_event, checked) => {
      let nextFilterParametersState = [];
      if (isParent) {
        const contriesFilterParameters = filterParameters.filter(
          (filterParameterItem) =>
            filterParameterItem.parentId === filterParameter.id
        );
        contriesFilterParameters.forEach((countryFilterParameter) => {
          dispatch(
            updateFilterParameterField({
              itemId: countryFilterParameter.id,
              fieldToUpdate: "value",
              newValue: checked,
            })
          );
        });
        nextFilterParametersState = filterParameters.map((item) => {
          if (item.parentId === filterParameter.id) {
            return {
              ...item,
              value: checked,
            };
          }
          return item;
        });
      } else {
        dispatch(
          updateFilterParameterField({
            itemId: filterParameter.id,
            fieldToUpdate: "value",
            newValue: checked,
          })
        );
        nextFilterParametersState = filterParameters.map((item) => {
          if (item.id === filterParameter.id) {
            return {
              ...item,
              value: checked,
            };
          }
          return item;
        });
      }
      const allParentIds = filterParameters
        .filter(
          (filterParameterItem) =>
            filterParameterItem.parentId ===
            FilterParametersEnum.INCIDENT_CATEGORY
        )
        .map((filterParameterItem) => filterParameterItem.id);
      const activeFilterParameters = nextFilterParametersState.filter(
        (item) => allParentIds.includes(item.parentId) && item.value
      );
      if (activeFilterParameters.length > 0) {
        const activeLabels = activeFilterParameters.map((item) => item.label);
        const hiddenNodes = nodes.filter(
          (node) =>
            // nodes that doesn't have any of the active labels in their data.filterTagIds array will be hidden
            !node.data.filterTagIds.some((filterTagId) =>
              activeLabels.includes(filterTagId)
            )
        );
        dispatch(
          updateFilterParameterField({
            itemId: FilterParametersEnum.INCIDENT_CATEGORY,
            fieldToUpdate: "hiddenNodeIds",
            newValue: hiddenNodes.map((n) => n.id),
          })
        );
        updateHiddenNodes(graphObject);
      } else {
        dispatch(
          updateFilterParameterField({
            itemId: FilterParametersEnum.INCIDENT_CATEGORY,
            fieldToUpdate: "hiddenNodeIds",
            newValue: [],
          })
        );
        updateHiddenNodes(graphObject);
      }
    };
  return (
    <CustomAccordionDetails>
      <FormGroup sx={{ paddingLeft: 1 }}>
        {filterParameters
          .filter(
            (filterParameter) =>
              filterParameter.parentId ===
                FilterParametersEnum.INCIDENT_CATEGORY &&
              filterParameter.count > 0 &&
              filterParameter.label !==
                FilterParametersEnum.TOTAL_INCIDENT_COUNT
          )
          .map((taxonomyFilterParameter) => {
            const categoryFilterParameters = filterParameters.filter(
              (filterParameterItem) =>
                filterParameterItem.parentId === taxonomyFilterParameter.id &&
                filterParameterItem.count > 0
            );
            return (
              <Box key={taxonomyFilterParameter.id}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <IconButton
                    onClick={() => {
                      dispatch(
                        updateFilterParameterField({
                          itemId: taxonomyFilterParameter.id,
                          fieldToUpdate: "value",
                          newValue: !taxonomyFilterParameter.value,
                        })
                      );
                    }}
                  >
                    <ChevronRightIcon
                      sx={{
                        transform: taxonomyFilterParameter.value
                          ? "rotate(90deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </IconButton>
                  <FormControlLabel
                    key={taxonomyFilterParameter.label}
                    control={
                      <Checkbox
                        size="small"
                        checked={categoryFilterParameters.every(
                          (item) => item.value
                        )}
                        indeterminate={
                          categoryFilterParameters.some((item) => item.value) &&
                          !categoryFilterParameters.every((item) => item.value)
                        }
                        onChange={handleChange(taxonomyFilterParameter, true)}
                      />
                    }
                    label={
                      <CheckBoxLabel
                        label={taxonomyFilterParameter.label}
                        count={taxonomyFilterParameter.count}
                      />
                    }
                  />
                </Stack>
                <Collapse in={taxonomyFilterParameter.value}>
                  <Box sx={{ display: "flex", flexDirection: "column", ml: 8 }}>
                    {categoryFilterParameters.map((countryFilterParameter) => (
                      <FormControlLabel
                        key={`${countryFilterParameter.id}-${
                          countryFilterParameter.value ? "Yes" : "No"
                        }`}
                        control={
                          <Checkbox
                            size="small"
                            checked={countryFilterParameter.value}
                            onChange={handleChange(
                              countryFilterParameter,
                              false
                            )}
                          />
                        }
                        label={
                          <CheckBoxLabel
                            label={countryFilterParameter.label}
                            count={countryFilterParameter.count}
                          />
                        }
                      />
                    ))}
                  </Box>
                </Collapse>
              </Box>
            );
          })}
      </FormGroup>
    </CustomAccordionDetails>
  );
}
