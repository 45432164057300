import {
  addDropShadow,
  degreeToRadians,
  getColorsBasedOnType,
  getToolTipHtml,
} from "../../../../Utils/d3Helper";
import * as d3 from "d3";

// SVG HANDLER
/**
 * Create the svg element for the graf
 */

export const createGraphSvg = (svgRef, id) => {
  svgRef.current = d3
    .select(`#${id}`)
    .append("svg")
    .attr("id", "companyChart")
    .attr("width", 500)
    .attr("height", 500)
    .append("g")
    .attr("transform", "translate(250,250)");
};
export const getWordArrShorted = (text) => {
  const maps = {
    "Climate Change Mitigation and Adaptation": ["Climate", "Change"],
    "Energy and Greenhouse Gas Management": ["Energy", "Greenhouse"],
    "Hazardous Materials Management": ["Hazardous", "Materials"],
    "Waste & Hazardous Materials Management": [
      "Waste",
      "Management"
    ],
    "Water & Wastewater Management": ["Water &", "waste water"],
    "Air Quality Management": ["Air", "Quality"],
    "Workplace Health and Safety": ["Workplace", "Health"],
    "Access to Basic Services and Information": ["Access", "to", "Basic"],
    "Critical Incident Risk Management": ["Critical", "Incident", "Risk"],
    "Pricing & Billing Transparency": ["Pricing", "Transparency"],
    "Employee Engagement, Diversity & Inclusion": [
      "Employee",
      "Engagement",
    ],
    "Employee Health & Safety": [
      "Employee",
      "Safety",
    ],
    "Fair Labor Practices": ["Fair Labor", "Practices"],
    "Product Quality & Safety": ["Product", "Quality"],
    "Selling Practices & Product Labeling": ["Selling", "Practices"],
    "Human Rights": ["Human", "Rights"],
    "Business Ethics and Compliance": ["Business", "Ethics"],
    "Business Model Resilience": ["Business", "Resilience"],
    "Product Safety and Standards": ["Product", "Safety and", "Standards"],
    "Materials Sourcing & Efficiency": ["Materials", "Sourcing"],
    "ESG Strategy and Operations": ["ESG", "Strategy and", "Operations"],
    "Management of the Legal and Regulatory Environment": [
      "Management",
      "of the Legal",
    ],
    "Market Dynamics and New Entrants": [
      "Market",
      "Dynamics and",
      "New",
      // "Entrants",
    ],
    "Climate Change Impact Mitigation": ["Climate", "mitigation"],
    "Product Design & Lifecycle Management" :["Product Design"],
    "Community relation":["Community relation"]
  };
  return maps[text] || text.split(" ");
};

/**
 * Add/append the tooptip container common for all outer arcs
 */
export const makeTooltipForOuterArc = (tooltipRef) => {
  tooltipRef.current = d3
    .select("body")
    .append("div")
    .attr("class", "sun-chart-container-tooltip")
    .style("display", "none");
};

/**
 * Draw outer Arc
 */
export const drawOuterArc = (initAngle, i, data, svgRef, tooltipRef,modalVisible) => {
  const { color, arrowColor, fontColor, hoverColor } = getColorsBasedOnType(
    data.parent.charAt(0)
  );

  // Create Arc
  var arc = d3
    .arc()
    .innerRadius(110)
    .outerRadius(210)
    .startAngle(degreeToRadians(initAngle.x))
    .endAngle(degreeToRadians(initAngle.y))
    .cornerRadius(3);

  // Splt the text in array to display/render in different lines instead of 1 line
  const textDataArr = data.name.split(" ");

  // Append Arc
  svgRef.current
    .append("path")
    .attr("d", arc)
    .attr("fill", color)
    .attr("id", i + "text")
    .style("opacity", Math.ceil(data.risk) == 0 ? "0.4" : "1")
    .attr("filter", `url(#${addDropShadow(svgRef.current, color)})`)
      .on("click", function (event, d) {
        modalVisible(data)
      }
    )
    .on("mouseover", function (event, d) {
      d3.select(this).transition().duration("50").attr("fill", hoverColor);
      d3.selectAll("." + textDataArr.join("-").replaceAll("&", "")).attr(
        "fill",
        "#FFF"
      );

      d3.selectAll(
        "." + textDataArr.join("-").replaceAll("&", "") + "-risk"
      ).style("fill", "#FFF");

      /* Change opacity of other elements on hover,
       * for example if user hover on any arc which is of "E" then change
       * opacity of others(G and S) to 0.25
       */
      ["E", "S", "G"].forEach((each) => {
        if (each !== data?.parent?.charAt(0)) {
          d3.selectAll("." + each + "-element")
            .transition()
            .duration("100")
            .style("opacity", "0.25");
        }
      });

      // Update Tool tip
      tooltipRef.current
        .html(getToolTipHtml(data))
        .transition()
        .duration(200)
        .style("display", "initial")
        .style("opacity", 1)
        .style("left", event.pageX + "px")
        .style("top", event.pageY - 28 + "px");
    })
    .on("mouseout", function (d, i) {
      d3.select(this)
        .transition()
        .duration("50")
        .attr("opacity", "1")
        .attr("fill", color);
      d3.selectAll("." + textDataArr.join("-").replaceAll("&", "")).attr(
        "fill",
        fontColor
      );
      d3.selectAll(
        "." + textDataArr.join("-").replaceAll("&", "") + "-risk"
      ).style("fill", "#000");
      ["E", "S", "G"].forEach((each) => {
        if (each !== data?.parent?.charAt(0)) {
          d3.selectAll("." + each + "-element")
            .transition()
            .duration("100")
            .style("opacity", "1");
        }
      });
      tooltipRef.current
        .transition()
        .duration(500)
        .style("opacity", 0)
        .style("display", "none");
    });

  // Add text/label on outerArc
  var text = svgRef.current
    .append("text")
    .attr("text-anchor", "middle")
    .attr(
      "class",
      Math.ceil(data.risk) != 0  && data?.parent?.charAt(0) + "-element" + " company-chart-circle-text"
    )
    .style("opacity", Math.ceil(data.risk) == 0 ? "0.4" : "1")
    .attr("transform", function (d) {
      var pos = arc.centroid(d);
      var rotateAngle = (initAngle.y + initAngle.x) / 2
      if (rotateAngle >= 0 && rotateAngle < 180) {
        rotateAngle = rotateAngle - 180
        return `translate(${pos} ) rotate(${rotateAngle + 90})`;
      } else {

        return `translate(${pos} ) rotate(${rotateAngle + 90})`;
      }

    });

  // add the each label word in next line to fit
  // getWordArrShorted(data.name).forEach((element, index) => {
  const esgLabelName = getWordArrShorted(data.name).join(" ")
  text
    .append("tspan")
    .text(esgLabelName)
   
    // .attr("y", index === 1 ? 0 : index === 2 ? 10 : -10)
    .attr("y", 0)
    .attr("x", initAngle.x < 180 ? (41 - esgLabelName.length * 2) : -(41 - esgLabelName.length * 2))
    // .attr("x", -50)
    .attr("font-size", "8px")
    .attr("font-weight", 600)
    .attr("fill", fontColor)
    .attr("class", Math.ceil(data.risk) != 0 &&  textDataArr.join("-").replaceAll("&", ""));
  // });

  // Add risk text
  text
    .append("tspan")
    .text("Risk: " + Math.ceil(data.risk))
    // .attr("y", getWordArrShorted(data.name).length * 6)
    .attr("y", 15)
    .attr("x", initAngle.x < 180 ? 30 : -30)
    .attr("font-size", "8px")
    .attr("fill", "#0D0D0E")
    .attr("class", Math.ceil(data.risk) != 0 &&  textDataArr.join("-").replaceAll("&", "") + "-risk");

  // Add arrows/traingles
  svgRef.current
    .append("path")
    .attr(
      "d",
      "M16.3989 11.6045C17.3005 12.4308 17.1395 14.2869 16.109 14.9456L2.19754 23.8374C1.16706 24.4961 0.0399914 23.4632 0.168813 21.9783L1.9079 1.93181C2.03672 0.446887 3.32482 -0.376428 4.22648 0.449844L16.3989 11.6045Z"
    )
    .attr("transform", function (d) {
      var angle = (initAngle.x + initAngle.y) / 2;
      return `rotate(${angle + 87}) translate(-109, -14) scale(0.6)`;
    })
    .style("fill", arrowColor)
    .attr("rx", 4)
    .attr("ry", 4)
    .attr("fill", arrowColor)
    .attr("stroke", arrowColor)
    .attr("stroke-width", "1px");
};

export const makeCenterPie = (svgRef, data, risk,modalVisible) => {

  const totalRisk = risk
  let centerItem = {
    val: 0,
    type: "",
    bgColor: "",
    textColor: "",
  };
  if (totalRisk >= 0 && totalRisk < 30) {
    centerItem = {
      val: totalRisk,
      type: "Low",
      bgColor: "#BF9000",
      textColor: "#735600",
    };
  } else if (totalRisk >= 30 && totalRisk < 70) {
    centerItem = {
      val: totalRisk,
      type: "Medium",
      bgColor: "#F49D4A",
      textColor: "#B5691E",
    };
  } else if (totalRisk >= 70 && totalRisk < 100) {
    centerItem = {
      val: totalRisk,
      type: "High",
      bgColor: "#E36D6D",
      textColor: "#B70000",
    };
  }
  const width = 215;
  const height = 215;
  const radius = Math.min(width, height) / 2;
  const color = (parms) => parms.color;

  // Make the arc for pie
  const arc = d3
    .arc()
    .outerRadius(radius - 10)
    .innerRadius(0);

  //  Make center pie
  const pie = d3
    .pie()
    .sort(null)
    .value((d) => d?.value);

  // Insert/append pie
  const g = svgRef.current
    .selectAll(".arc")
    .data(pie(data))
      .enter()
    .append("g")
    .attr("class", (d) => "arc " + d.data.label + "-element");

  g.append("path")
    .attr("d", arc)
    .style("fill", (d) => color(d.data))
    .on("click", function (event, d) {
  
   modalVisible(d.data.label,"inner")
    })
    ;

  g.append("text")
    .attr("transform", (d) => "translate(" + arc.centroid(d) + ")")
    .style("font-size", (d) => (d.data.label === undefined ? "12px" : "16px"))
    .style("fill", "#045B86")
    .attr("text-anchor", "middle")
    .selectAll("tspan")
    .data((d) => [
      { lab: d.data.label, index: d.index },
      { lab: "Risk", index: d.index },
      { lab: d.data.lable2.toString(), index: d.index },
    ])
    

    .enter()
    .append("tspan")
    
    .attr("x", (d) => (d.index === 0 ? 10 : d.index === 2 ? -10 : 0))
    .attr("y", (d, i, nodes) => {
      let value = 0;
      if (d.index === 1) {
        value = value + 27;
      }
      if (i == 2) return value + 15;
      return 15 * (i * 1.2 - nodes.length / 2) + value;
    })
    .style("font-size", (d) => (d === "Risk" ? "8px" : "14px"))
    
    .text((d) => (d.lab ? d.lab : d))
    
  

  // make hexagon Gradient
  svgRef.current
    .append("defs")
    .append("radialGradient")
    .attr("id", "myGradient")
    .attr("cx", "50%")
    .attr("cy", "50%")
    .attr("r", "50%")
    .selectAll("stop")
    .data([
      { offset: "0%", color: "#FFF8F8" },
      { offset: "100%", color: centerItem.bgColor },
    ])
    .enter()
    .append("stop")
    .attr("offset", (d) => d.offset)
    .attr("stop-color", (d) => d.color);

  // make hexagon

  svgRef.current
    .append("path")
    .attr(
      "d",
      "M 34 0.6547 C 35.2376 -0.0598 36.7624 -0.0598 38 0.6547 L 69.507 18.8453 C 70.7445 19.5599 71.507 20.8803 71.507 22.3094 V 58.6905 C 71.507 60.1195 70.7445 61.44 69.507 62.1545 L 38 80.3455 C 36.7624 81.06 35.2376 81.06 34 80.3455 L 2.493 62.1545 C 1.2554 61.44 0.493 60.1195 0.493 58.6905 V 22.3094 C 0.493 20.8803 1.2554 19.5599 2.493 18.8453 L 34 0.6547 Z"
    )
    .attr("stroke-width", 1)
    .attr("transform", "translate(-36,-42)")
    .style("fill", "url(#myGradient)");

  svgRef.current
    .append("text")
    .attr("x", -23)
    .attr("y", -15)
    .append("tspan")
    .text("Risk Score")
    .attr("font-size", "10px")
    .attr("fill", centerItem.textColor)
    .append("tspan")
    .attr("y", 7)
    .attr("x", Math.round(totalRisk) === 100 ? -17 : -12)
    .text(totalRisk < 10 ? "0" + Math.ceil(totalRisk) : Math.ceil(totalRisk))
   
    .attr("fill", centerItem.textColor)
    .attr("font-size", "22px")
    .append("tspan")
    .text(centerItem.type)
    .attr("y", 25)
    .attr("x", centerItem.type === "Medium" ? -21 : -14)
    .attr("fill", centerItem.textColor)
    .attr("font-size", centerItem.type === "Medium" ? "12px" : "14px");
};

//DATA HANDLER
export const calculateGraphData = (graphData, props) => {
  const returnData = {
    Environmental: "",
    Social: "",
    Governance: "",
  };
  for (let i = 0; i < graphData?.length; i++) {
    if (returnData[graphData[i].parent]) {
      returnData[graphData[i].parent] =
        returnData[graphData[i].parent] + Math.round(graphData[i].risk);
    } else {
      returnData[graphData[i].parent] = Math.round(graphData[i].risk);
    }
  }
  const data = [
    { value: graphData.filter(e => e.parent === "Environmental").length, lable2: 30, label: "E", color: "#D6EDC1" },
    { value: graphData.filter(e => e.parent === "Social").length, lable2: 65, label: "S", color: "#B1D8FE" },
    { value: graphData.filter(e => e.parent === "Governance").length, lable2: 75, label: "G", color: "#C9BBF0" },
    // { totalRisk: 0 },
  ];
  data[0].lable2 = props.environment?.toFixed(1);
  data[1].lable2 = props.social?.toFixed(1);
  data[2].lable2 = props.governance?.toFixed(1);
  // data[3].totalRisk =
  //   returnData.Environmental + returnData.Social + returnData.Governance;

  return data;
};
