import { ApiFilled } from "@ant-design/icons";
import appconstant from "../../Constants/appConstansts";
import { APIMethod } from "./ApiMethods";

//***********************************************  AUTH ***************************************************************/
//login
export const LoginApi = (data) => {
  return APIMethod.POST("/login", { ...data, ...appconstant.staticPayload });
};
// register
export const RegisterApi = (data) => {
  return APIMethod.POST("/register", { ...data, ...appconstant.staticPayload });
};

export const socialLoginApi = (data) => {
  return APIMethod.POST("/social-login", {
    ...data,
    ...appconstant.staticPayload,
  });
};
export const verifySignUpEmailApi = (data) => {
  return APIMethod.POST("/verify-email", data);
};

//forgot password
export const ForgotPasswordApi = (data) => {
  return APIMethod.POST("admin/forgot", { email: data });
};

// Verify Email Via Link
export const VerifyEmailApi = (data) => {
  return APIMethod.POST("/change-password-link-validity", data);
};
//reset-password Email
export const ResetPasswordEmailApi = (data) => {
  return APIMethod.POST("/change-password", data);
};
//reset password
export const ResetPasswordApi = (data) => {
  return APIMethod.POST("/reset-password", data);
};

//check link forgot password
export const checkLinkApi = (data) => {
  return APIMethod.POST("admin/linkValid", { token: data });
};

//change password
export const changePasswordApi = (data) => {
  return APIMethod.POST("/update-password", data);
};

//user logout
export const logoutApi = () => {
  return APIMethod.POST(`admin/logout`);
};

//user logout
export const resendApi = (data) => {
  return APIMethod.POST(`/resend-verification-email`, data);
};

//*********************************************** INCIDENT ***************************************************************/
export const incidentsApi = (data) => {
  return APIMethod.POST(`/incidents`, data);
};

export const homePageApi = (data) => {
  return APIMethod.POST(`/home-page`, data);
};

export const companiesWithIncidentApi = (data) => {
  return APIMethod.POST(`/companies-with-incident-count`, data);
};

export const countriesWithIncidentApi = (data) => {
  return APIMethod.POST(`/countries-with-incident-count`, data);
};

export const esgFactorsIncidentsApi = (data) => {
  return APIMethod.POST(`/esg-factors-incidents`, data);
};

export const getCompaniesApi = (data) => {
  return APIMethod.POST(`/companies-list`, data);
};

export const esgsdgincidentsApi = (data) => {
  return APIMethod.POST(`/esg-sdg-incidents`, data);
};

export const esgPaiIncidentsApi = (data) => {
  return APIMethod.POST(`/esg-pai-incidents`, data);
};
//Esg category
export const esgCategoryApi = (data) => {
  return APIMethod.POST(`/esg-categories`, data);
};
// ESg Iot
export const esgIotApi = (data) => {
  return APIMethod.POST(`/esg-categories-with-year`, data);
};

export const incidentAnalyzerBreachesApi = (data) =>
  APIMethod.POST(`/ungcp_breaches`, data);

export const incidentAnalyzerSocialMediaCoverageApi = (data) =>
  APIMethod.POST(`/reaction-analysis`, data);

// ****************SETTINGS******************
// get proflie
export const getProfileApi = (data) => {
  return APIMethod.GET("/profile", data);
};
// save changes
export const updateProfileApi = (data) => {
  return APIMethod.POST("/update-profile", data);
};

export const profileEmailNotificationApi = (data) => {
  return APIMethod.POST("/update-email-notification", data);
};

export const profileDeleteApi = () => {
  return APIMethod.POST("/delete-account");
};

export const getIndustryApi = (data) => {
  return APIMethod.POST(
    `/industries-groupped${data?.search ? "?search=" + data?.search : ""}`,
    data
  );
};
// get top search
export const getTopSearchApi = (data) => {
  return APIMethod.GET(
    `/top-searches${data?.search ? "?search=" + data?.search : ""}`,
    data
  );
};
//get search categories
export const getCategoriesApi = (data) => {
  return APIMethod.GET(
    `/esg-data-gropped${data?.search ? "?search=" + data?.search : ""}`,
    data
  );
};
export const getCountriesWithIncidentsApi = (data) => {
  return APIMethod.POST("/countries-with-incident-count", data);
};
export const getSourceDetailApi = (data) => {
  return APIMethod.POST("/incident-source-details", data);
};

export const getNewsArticleApi = (data) => {
  return APIMethod.GET(
    `/news-articles-reports/${data.incident}?limit=${data.limit}&offset=${data.offset}&type=${data.type}&sort=publication_datetime&order=1`
  );
};
// ***********************************************************Company Page***************************************************
//get country list
export const getCountryListApi = (data) => {
  return APIMethod.POST(
    `/countries-groupped${data?.search ? "?search=" + data?.search : ""}`,
    data
  );
};
//get company details
export const getCompanyDetailApi = (data) => {
  return APIMethod.POST(`/company-details/${data.id}`, data);
};

// ***********************************************************Incident details***************************************************

export const incidentDetailsApi = (data) => {
  return APIMethod.GET("/incident-details/" + data);
};
// **********************************************************watch List************************************************
export const addWishListApi = (data) => {
  return APIMethod.POST("/add-wishlist", data);
};
// get wish list
export const getWishListApi = (data) => {
  return APIMethod.GET("/wishlist", data);
};
// get wish list detail
export const getWishListDetailApi = (data) => {
  return APIMethod.GET(`/wishlist-detail/${data}`, data);
};
// delete wish list
export const deleteWishListApi = (data) => {
  return APIMethod.GET(`/delete-wishlist/${data}`, data);
};
// edit wish list
export const editWishListApi = (data) => {
  return APIMethod.PUT(`/edit-wishlist`, data);
};
// *************************************************Save Incidence************************************************
// 13/07/2023 MM

//get collection
export const getCollectionApi = (data) => {
  return APIMethod.GET(`/collection/list`, data);
};

//create save incidence
export const createSaveIncidenceApi = (data) => {
  return APIMethod.POST(`/collection/create`, data);
};

// ADD TO COLLECTION
export const addToCollectionApi = (data) => {
  return APIMethod.POST(`/collection/add-incident`, data);
};

// DELETE COLLECTION
export const deleteCollectionApi = (data) => {
  return APIMethod.DELETE(`/collection/delete/${data.id}`, data);
};

// REMOVE INCIDENCE
export const removeIncidenceApi = (data) => {
  return APIMethod.POST(`/collection/remove-incident`, data);
};

//rename incident
export const renameIncidentApi = (data) => {
  return APIMethod.POST(`/collection/rename/${data.collectionId}`, {
    name: data.name,
  });
};

export const getSupplyChainIncidentApi = (data) => {
  return APIMethod.GET(
    `/company/supply-chain-incidents/${data.id}?start=${data.start}&end=${data.end}&limit=${data.limit}`
  );
};

export const getSentimentTrendApi = (data) => {
  return APIMethod.GET(
    `/company/sentiment-trend/${data.id}?start=${data.start}&end=${data.end}&interval=${data.interval}`
  );
};

export const getSentimentAnalysisApi = (data) => {
  return APIMethod.GET(
    `/company/sentiment-analysis/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getSentimentScoreApi = (data) => {

  return APIMethod.GET(
    `/company/sentiment-score/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getIncidentSeverityApi = (data) => {
  return APIMethod.GET(
    `/company/incident-severity-graph/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getIncidentWithYearApi = (data) => {
  return APIMethod.GET(
    `/company/incident-with-year/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getIncidentCategorizationApi = (data) => {
  return APIMethod.GET(
    `/company/esg-data-with-child/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getPortfolioWeightApi = (data) => {
  return APIMethod.GET(
    `/portfolio/esg-data-with-child/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getPeerCompaniesRate = (data) => {
  return APIMethod.GET(
    `/company/incident-with-highest-incident/${data.id}?start=${data.start}&end=${data.end}`
  );
};
export const getIncidentOverTime = (data) => {
  return APIMethod.GET(
    `/company/incident-with-factors/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const mostUsedWordsAPI = (data) => {
  return APIMethod.GET(
    `/company/most-used-words/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const serverityTrendApi = (data) => {
  return APIMethod.GET(
    `/company/incident-severity-trend/${data.id}?start=${data.start}&end=${data.end}&interval=${data.interval}`
  );
};
export const incidentComparisonApi = (data) => {
  return APIMethod.GET(
    `/company/comparison-chart/${data.id}?start=${data.start}&end=${data.end}&type=${data.type}`
  );
};
export const severityOnMApApi = (data) => {
  return APIMethod.GET(
    `/company/incident-severity-map/${data?.id}?start=${data.start}&end=${data.end}`
  );
};
//Incident Map Data
export const incidentMapDataApi = (data) => {
  return APIMethod.POST("/incidents-map-data");
};

export const incidentMapDataUpdateApi = (data) => {
  return APIMethod.POST("/incidents-map-data-with-esg-and-severity", data);
};

export const getGraphViewDataApi = (data) => {
  return APIMethod.POST(`/supply-chain/graph/${data}`);
};

export const getSupplyChainDataApi = (data) => {
  return APIMethod.POST(`/supply-chain/${data.companyId}`, {
    end: data.end,
    start: data.start,
    search: data.search ? data.search : "",
  });
};

// getSearchByParams
export const searchBYParamsApi = (data) => {
  return APIMethod.GET(`/search-data/${data}`);
};
export const downloadDataApi = (data) => {

  if (data?.id) {
    if (data?.type) {
      return APIMethod.GETDATA(
        `/export/incidentList?collectionId=${data.collectionId}&type=${data?.type}&start=${data?.start}&end=${data?.end}`,
        data?.type
      );
    } else {
      return APIMethod.GETDATA(
        `/export/incidentList/?companyId=${data?.id}&start=${data?.start}&end=${data?.end}&limit=${50}`
      );
    }
  } else if (data?.companies) {
    return APIMethod.GETDATA(
      `/export/incidentList/?companies=${data?.companies}&start=${data?.start}&end=${data?.end}&limit=${50}`
    );
  }

  else {
    return APIMethod.GETDATA(
      `/export/incidentList?start=${data?.start}&end=${data?.end}`
    );
  }
};

export const exportDataApi = (data) => {
  return APIMethod.GETEXPORTDATA(
    `/export/supply-chain-list/${data.id}?start=${data.start}&end=${data.end}`
  );
};
// get incident timeline
export const getIncidentTimelineApi = (data) => {
  return APIMethod.POST(
    `/company/incident-timeline/${data?.id}?start=${data.start}&end=${data?.end}&showSever=${data?.severity}`,
    {
      type:
        data?.type == "esg_PAI"
          ? "esg_PAI"
          : data.type == "esg_factors"
            ? "esg_factors"
            : data?.type == "esg_SDG"
              ? "esg_SDG"
              : "esg_factors",
    }
  );
};
export const getEsgFactorsApi = (data) => {
  return APIMethod.GET(`/esg-factors-list`);
};
export const getEsgCountriesApi = (data) => {
  return APIMethod.GET(`/countries-list`);
};

// COMPANY NEW

export const getESGRiskScoreApi = (data) => {
  return APIMethod.GET(
    `/company/esg-child-risk-chart/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getRegulatoryFinesPenaltiesApi = (data) => {
  return APIMethod.GET(
    data.order
      ? `/company/regulatory-fines-penalties/${data.id}?start=${data.start}&end=${data.end}&sort=${data.sort}&order=${data.order}`
      : `/company/regulatory-fines-penalties/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getRegulatoryLegalActionsApi = (data) => {
  return APIMethod.GET(
    data.order
      ? `/company/regulatory-legal-actions/${data.id}?start=${data.start}&end=${data.end}&sort=${data.sort}&order=${data.order}`
      : `/company/regulatory-legal-actions/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const socialMediaCoverageApi = (data) => {
  return APIMethod.GET(
    `/company/social-media-coverage/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const ungcpBreachesApi = (data) => {
  return APIMethod.GET(
    `/company/ungcp_breaches/${data.id}?start=${data.start}&end=${data.end}&type=${data.type ? "supplyChain" : ""}`
  );
};

export const socialMediaCoverageIncidentApi = (data) => {
  return APIMethod.GET(`/social-media-coverage/${data.id}`);
};
// Portfolio

export const getPortfolioStatusApi = (data) => {
  return APIMethod.GET(
    `/portfolio/getPortfolioStatus/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getCompaniesForPortfolioApi = (data) => {
  return APIMethod.POST(`/companies-list-search`, data);
};

export const getPortfolioListApi = (data) => {
  return APIMethod.GET(`/portfolio/list`);
};

export const portfolioChangeClrApi = (data) => {
  return APIMethod.POST(`/portfolio/change-color`, data);
};

export const addPortfolioApi = (data) => {
  return APIMethod.POST(`/portfolio/create`, data);
};

export const portfolioCompGraphApi = (data) => {
  if (data.export) {
    return APIMethod.GETEXPORTDATA(
      `/portfolio/companies/${data.id}?exportData=${true}`,
      "portfolio"
    );
  } else if (data.limit) {
    return APIMethod.GET(
      data.order
        ? `/portfolio/companies/${data.id}?start=${data.start}&end=${data.end}&limit=${data.limit}&offset=${data.offset}&sort=${data.sort}&order=${data.order}`
        : `/portfolio/companies/${data.id}?start=${data.start}&end=${data.end}&limit=${data.limit}&offset=${data.offset}`
    );
  } else {
    return APIMethod.GET(
      `/portfolio/companies/${data.id}?start=${data.start}&end=${data.end}`
    );
  }
};

export const mostFrequentEsdGraphApi = (data) => {
  return APIMethod.GET(
    `/portfolio/most-frequent-esg-child-incidents/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const portfolioDetailApi = (data) => {
  return APIMethod.GET(
    !data.start
      ? `/portfolio-detail/${data.id}`
      : `/portfolio-detail/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const unGlobalGraphApi = (data) => {
  return APIMethod.POST(
    `/portfolio/ungcp_breaches/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const alertReportGraphApi = (data) => {
  return APIMethod.GET(`/portfolio/getAlerts/${data.id}`);
};

export const portfolioESGRiskApi = (data) => {
  return APIMethod.GET(
    `/portfolio/get-holdings-risk/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const riskScoreCompApi = (data) => {
  return APIMethod.GET(
    `/portfolio/get-holdings-risk/${data.id}?start=${data.start}&end=${data.end}&graph=${true}`
  );
};

export const highSeverityIncidentApi = (data) => {
  return APIMethod.GET(
    `/portfolio/high-severity-incidents/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const distributionGraphApi = (data) => {
  return APIMethod.GET(
    `/portfolio/company-esg-incident-distribution/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const uploadDocPortfolioApi = (data) => {
  return APIMethod.POST(`/portfolio/read-company-from-file/`, data, "formData");
};

export const deletePortfolioApi = (data) => {
  return APIMethod.POST(`/portfolio/delete/`, data);
};

export const getEditPortfolioDetailApi = (data) => {
  return APIMethod.GET(`/portfolio/edit-detail/${data.id}`);
};

export const editPortfolioApi = (data) => {
  return APIMethod.POST(`/portfolio/update`, data);
};

export const clearAlertRules = (data) => {
  return APIMethod.POST(`/portfolio/clearReports`, data);
};

export const checkValidCompanyApi = (data) => {
  return APIMethod.POST(`/portfolio/check-valid-companies`, data);
};

// Social Media
export const socialMediaDetailApi = (data) => {
  return APIMethod.POST(`/social-media/tags-keywords/`, data);
};

export const tweetAndRepliesApi = (data) => {
  return APIMethod.POST(`/social-media/tweet-with-replies`, data);
};

export const tweetIndividualRepliesApi = (data) => {
  return APIMethod.GET(`/social-media/individual-tweet-replies/${data}`);
};

export const getSocialMediaReactionApi = (data) => {
  return APIMethod.POST(`/social-media/reaction-analysis`, data);
};

export const getOverallReactionSentimentApi = (data) => {
  return APIMethod.POST(`/social-media/tweet/overall-reaction`, data);
};

export const getOverallRedditReactionApi = (data) => {
  return APIMethod.POST(`/social-media/overall-reddit-reaction`, data);
};

export const getRadarChartApi = (data) => {
  return APIMethod.POST(`/social-media/radar-chart`, data);
};

export const getTotalTweetReactionsApi = (data) => {
  return APIMethod.POST(`/social-media/total-tweet-reaction`, data);
};

export const getTotalRedditReactionsApi = (data) => {
  return APIMethod.POST(`/social-media/total-reddit-reaction`, data);
};

export const getTotalSocialMediaReactionsApi = (data) => {
  return APIMethod.POST(`/social-media/total-reaction-in-social-media`, data);
};

export const getRedditWithPostApi = (data) => {
  return APIMethod.POST(`/social-media/reddit-posts`, data);
};
export const getRedditWithCommentApi = (data) => {
  return APIMethod.GET(
    `/social-media/reddit-post-comments/?post=${data}&limit=100&offset=0&sort=negative`
  );
};

export const getMostInfluentialUserApi = (data) => {
  return APIMethod.POST(`/social-media/most-influential-users`, data);
};

export const getSummaryDataApi = (data) => {
  return APIMethod.POST(`/social-media/summary-data`, data);
};

export const getSocialMediaMapData = (data) => {
  return APIMethod.POST(`/social-media/get-sentiment-map-data`, data);
};

export const fineAndPaneltyApi = (data) => {
  return APIMethod.GET(
    data.order
      ? `/regulatory-fines-penalties/${data.id}?sort=${data.sort}&order=${data.order}`
      : `/regulatory-fines-penalties/${data.id}`
  );
};
export const legalAndPaneltyApi = (data) => {
  return APIMethod.GET(
    data.order
      ? `/regulatory-legal-actions/${data.id}?sort=${data.sort}&order=${data.order}`
      : `/regulatory-legal-actions/${data.id}`
  );
};

export const timelineGraphApi = (id) => {
  return APIMethod.GET(`/incident-timeline/${id}`);
};

export const feedbackApi = (data) => {
  return APIMethod.POST("/feedback", data);
};

export const getIndustriesApi = (data) => {
  return APIMethod.GET("/industries-list");
};

export const getRiskChartApi = (data) => {
  return APIMethod.POST("/supply-chain/supplychain-risk-charts", data);
};

export const getRiskCompanyApi = (data) => {
  return APIMethod.POST("/supply-chain/risk-per-company", data);
};

export const getSupplyIncidentSeverityApi = (data) => {
  return APIMethod.POST("/supply-chain/incident-number-and-severiy", data);
};

export const getChainListingAPi = (data) => {
  return APIMethod.POST(`/supply-chain/supply-chain-list`, data.data);
};

export const getChainHistoryAPi = () => {
  return APIMethod.GET("/supply-chain/recent-supply-chain-search");
};

export const clearChainHistoryAPi = () => {
  return APIMethod.GET("supply-chain/clear-recent-search");
};

export const homeESGRiskApi = (data) => {
  return APIMethod.POST(`/risk-score-per-company`, data);
};

export const homeHighSeveirtyApi = (data) => {
  return APIMethod.POST(`/high-severity-incidents`, data);
};

export const homeESGIotApi = (data) => {
  return APIMethod.POST(`/incidents-over-time`, data);
};
export const incidentByCategoryAPI = (data) => {
  return APIMethod.POST(`/incident-by-category`, data);
};

export const homeSocialMediaCoverageApi = (data) => {
  return APIMethod.POST(`/social-media-coverage`, data);
};

export const dashboardDataApi = (data) => {
  return APIMethod.GET("/portfolio/dashboard");
};

export const dashboardWatchDataApi = (data) => {
  return APIMethod.GET("/wishlistOverview");
};

export const riskTrendApi = (data) => {
  return APIMethod.GET(
    `/company/risk-trend/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const getCompanyAPI = (data) => {
  return APIMethod.GET("/total-companies-count");
};
export const findPathAPi = (data) => {
  return APIMethod.GET(
    `get_path_finding?source_company_id=${data.sourceCompanyId}&target_company_id=${data.targetCompanyId}&center_node_id=${data.centerNodeId}&all=${data.all}`
  );
};

export const breachesAndViolationsAPI = (data) => {
  return APIMethod.POST("/companies_esg", data);
};

// corporate
export const corporateIncidentBar = (data) => {
  return APIMethod.GET(
    `/company/supply-chain-incidents-count/${data.companyId}?type=${data.type}&start=${data.start}&end=${data.end}`
  );
};

export const corporateRiskScoreBar = (data) => {
  return APIMethod.GET(
    `/supply-chain-risk/${data.companyId}?start=${data.start}&end=${data.end}`
  );
};

export const corporateIncidentAnalysisApi = (data) => {
  return APIMethod.POST(`/supply-chain/incident-count-data`, data);
};

export const peerRiskApi = (data) => {
  return APIMethod.GET(
    `/company/peer-risk-score/${data.id}?start=${data.start}&end=${data.end}`
  );
};

export const riskLineApi = (data) => {
  return APIMethod.GET(`/company/corporate-risk-line-chart/${data.id}`);
};

export const getDashboardCompaniesApi = (data) => {
  return APIMethod.POST(`/companies-list`, data);
};
export const uploadApi = (data) => {
  return APIMethod.POST(`/supply-chain-upload`, data, "formData");
};

export const getEsgTotalIncidentCountApi = (data) => {
  return APIMethod.POST(`/get-esg-incidents-count`, data);
};

export const deleteUploadedDataApi = (data) => {
  return APIMethod.DELETE(`supply-chain-delete/${data.companyId}`);
};

export const uploadCompanyApi = (data) => {
  return APIMethod.POST(`/wishlistImport`, data, "formData");
};

export const uploadWatchlistApi = (data) => {
  return APIMethod.POST(`upload-watchlist-company`, data);
};

export const saveValidSupplyChainCompanyAPI = (data) => {
  return APIMethod.POST(`supply-chain-companies`, data);
};

export const getCompaniesForAddSupplyChainAPI = (data) => {
  return APIMethod.POST(`/companies-list`, data);
};
