import useAxios from "axios-hooks";
import { getToken } from "../../../../../../../Utils";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/configuareStore";
import { Box, LinearProgress } from "@mui/material";
import { getEdgeProperLabel } from "../../../../utils";
import ExpandingItem from "./ExpandingItem";

export default function SpecificRelationsModalTabsPane() {
  const selectedTab = useAppSelector(
    (state) => state.rightClickMenu.currentTab
  );
  const companyId = useAppSelector(
    (state) => state.rightClickMenu.targetNodeId
  );
  const baseUrlApi = useAppSelector((state) => state.api.baseUrlApi);
  const from = useAppSelector((state) => state.timeRange.from);
  const to = useAppSelector((state) => state.timeRange.to);
  const token = getToken();
  const [{ data, loading }] = useAxios({
    url: `${baseUrlApi}/companies/stats`,
    params: {
      ids: companyId,
      from,
      to,
    },
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  if (loading) return <LinearProgress />;
  const processedData = data[0].relationsStat;
  const counts = processedData?.[selectedTab];
  const { total, ...rest } = counts;
  return (
    <Box paddingTop={2}>
      {Object.keys(rest).map((item) => (
        <ExpandingItem
          key={item}
          id={item}
          label={getEdgeProperLabel(item)}
          count={rest[item]}
        />
      ))}
    </Box>
  );
}
