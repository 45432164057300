import style from "../../socialMediaCoverage/index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import ImageConst from "../../../Themes/ImageConstant";
import { AutoComplete, Input, Popover, Row, Tabs } from "antd";
import WorldMapIncidents from "../../../Componets/WorldMapIncidents";
import { useDispatch, useSelector } from "react-redux";
import ReactionAnalysesChart from "../charts/reactionAnalysisChart";
import SocialWordChart from "../charts/socialWordChart";
import BarChart from "../charts/barChart";
import ReplySentimentDonut from "../charts/replySentimentDonut";
import TweetTimeline from "../charts/tweetTimeline";
import MostInfluentialUsersBarChart from "../charts/mostInfluentialUsersBarChart";
import OverviewTimeline from "../charts/socialTimeline";
import {
  getIndividualTweetReplyLoad,
  getOverallReactionSentimentLoad,
  getOverallRedditReactionLoad,
  getRadarDataLoad,
  getSocialMediaDetailLoad,
  getSocialMediaReactionLoad,
  getTotalRedditReactionLoad,
  getTotalSocialMediaReactionLoad,
  getTotalTweetReactionLoad,
  getTweetWithRepliesLoad,
  updateSocialMediaState,
  getRedditWithPostLoad,
  getRedditWithCommentLoad,
  getMostInfluentialUserLoad,
  getSummaryDataLoad,
  socialMediaMapActionLoad,
} from "../../../Redux/Action/socialMedia";
import moment from "moment";
import { getCountryDataforSupplyChain } from "../../../Redux/Action/supplyChainAction";
import RadarChart from "../charts/radarChart";
import {  formatFollowers } from "../../../Utils";
import { searchCompanyListActionLoad } from "../../../Redux/Action/global";
import useDebouncedApiCall from "../../../hook/useDebouncedSearch";

let countryDataObj = {
  AF: "Africa",
  AN: "Antarctica",
  AS: "Asia",
  EU: "Europe",
  NA: "North America",
  OC: "Oceania",
  SA: "South America",
};
const OverviewPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location || {};

  // Destructure portfolioId with a default value if state is null
  const {
    companies = null,
    name = null,
    incident = null,
    incidentDetail = null,
    companyDetail = null,
    allIncidentDetails = null,
  } = state || {};
  const reducerData = useSelector((state) => state.incidentReducer);
  const { severityDataLocationArray } = reducerData;
  const socialMediaReducerData = useSelector((state) => state.socialMedia);
  const {
    tweetAndRepliesData,
    individualTweetReplyData,
    socialMediaReactionData,
    socialMediaTags,
    overAllReactionSentiment,
    overAllRedditReaction,
    radardata,
    totalTweetReaction,
    totalRedditReaction,
    totalSocialMediaReaction,
    redditAllPosts,
    redditPostComments,
    tweetAndRepliesLoader,
    redditAllPostsLoader,
    mostInfluentialUser,
    summaryData,
    tweetAndRepliesDataCount,
    redditPostCommentsCount,
    socialMediaMapData,
    continents,
  } = socialMediaReducerData;

  let update = continents.map((val) =>
    countryDataObj[val?.continent_code]
      ? { ...val, continent_code: countryDataObj[val?.continent_code] }
      : { ...val }
  );
  const { countryList } = useSelector((state) => state?.supplyChainReducer);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [date, setDate] = useState(false);
  const [showReplies, setShowReplies] = useState(null);
  const [showReplies2, setShowReplies2] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [countryName, setCountryName] = useState(null);
  //Tweets
  const [tagsType, setTagsType] = useState("all");
  const [tweetSort, setTweetSort] = useState(null);
  const [tweetOffset, setTweetOffset] = useState(0);
  const [tweetLimit, setTweetLimit] = useState(15);
  //Reddit
  const [redditSort, setRedditSort] = useState(null);
  const [redditOffset, setRedditOffset] = useState(0);
  const [redditLimit, setRedditLimit] = useState(15);

  useEffect(() => {
    dispatch(getCountryDataforSupplyChain());
  }, []);

  useLayoutEffect(() => {
    networkCall();
  }, [activeTab]);

  const handleSearchOperation = (SelectedData) => {
    if (searchCompaniesData?.data?.length !== 0) {
      navigate("/companyPage", { state: { id: SelectedData.id } });
    } else {
    }
  };

  // const calculateTotalRisk = companyDetail?.risk
  //   ? companyDetail?.risk.reduce((totalRisk, item) => totalRisk + item.risk, 0)
  //   : 0;

  const calculateTotalRisk = companyDetail?.risk
    ? companyDetail?.risk?.risk
    : 0;

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleTweetSortChange = (sortValue) => {
    setTweetSort(sortValue);
    dispatch(
      getTweetWithRepliesLoad({
        companies,
        incident,
        type: tagsType,
        order: -1,
        limit: 4,
        offset: 0,
        sort: sortValue,
      })
    );
  };

  const handleRedditSortChange = (sortValue) => {
    setRedditSort(sortValue);
    dispatch(
      getRedditWithPostLoad({
        companies,
        sort: sortValue,
        order: -1,
        limit: redditLimit,
        offset: redditOffset,
      })
    );
  };

  const networkCall = () => {
    dispatch(
      socialMediaMapActionLoad({
        companies,
        incident,
        type: activeTab == "1" ? "all" : activeTab == "2" ? "tweet" : "reddit",
      })
    );
    dispatch(
      getSocialMediaDetailLoad({
        companies,
        incident,
        type: activeTab == "1" ? "all" : activeTab == "2" ? "tweet" : "reddit",
      })
    );
    dispatch(
      getTweetWithRepliesLoad({
        companies,
        incident,
        type: tagsType,
        order: -1,
        limit: tweetLimit,
        offset: tweetOffset,
        sort: tweetSort,
      })
    );
    dispatch(
      getRedditWithPostLoad({
        companies,
        sort: redditSort,
        order: -1,
        limit: redditLimit,
        offset: redditOffset,
      })
    );
    dispatch(getSocialMediaReactionLoad({ companies, incident }));
    dispatch(getOverallReactionSentimentLoad({ companies, incident }));
    dispatch(getOverallRedditReactionLoad({ companies, incident }));
    dispatch(getRadarDataLoad({ companies, incident }));
    dispatch(getTotalTweetReactionLoad({ companies, incident }));
    dispatch(getTotalRedditReactionLoad({ companies, incident }));
    dispatch(getTotalSocialMediaReactionLoad({ companies, incident }));
    dispatch(
      getSummaryDataLoad({
        type: activeTab == "1" ? "all" : activeTab == "2" ? "tweet" : "reddit",
        companies,
        incident,
      })
    );
    if (activeTab == "2" || activeTab == "3") {
      dispatch(
        getMostInfluentialUserLoad({
          companies,
          type: activeTab == "2" ? "tweet" : activeTab == "3" ? "reddit" : "",
          incident,
        })
      );
    }
  };

  const getTotalCount = (keyName) => {
    let total = 0;
    if (Object.keys(socialMediaReactionData)?.length !== 0) {
      const keysArray = Object.values(socialMediaReactionData[keyName]);
      keysArray.pop();
      return (total = keysArray.reduce((acc, value) => acc + value, 0));
    } else {
      return total;
    }
  };

  const { searchCompaniesData } = useSelector((state) => state?.global);
  const { searchTerm, handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );
  return (
    <div className="side-padding ">
      <div className="social-media-container">
        <div className="social-media-top-space">
          <div className="media-breadcrumbs">
            <div className="bread-crumbs-links">
              <p>
                Incidents <span>/</span> Details <span>/</span>{" "}
                <span>Social Media Coverage </span>{" "}
              </p>
            </div>
            <div className="social-media-search autocomplete-input-search">
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                popupMatchSelectWidth={500}
                className="serchmedia-icon"
                options={searchCompaniesData?.data}
                size="large"
                onSearch={handleSearch}
                value={countryName}
                onSelect={(e, selectedOption) => {
                  const { label } = selectedOption;
                  setCountryName(label);
                  // setSelectedData(selectedOption);
                  handleSearchOperation(selectedOption);
                }}
                onChange={(e, i) => {
                  // setSelectedData(i);
                  setCountryName(e);
                }}
              >
                <Input.Search size="large" placeholder="Search company" />
              </AutoComplete>
            </div>
          </div>
        </div>

        <div className="social-media-main-container">
          <div className="row">
            <div className="col-lg-4">
              <div className="social-media-tabs">
                <div className="post-replies-map">
                  {/* <img src={ImageConst.postRep} /> */}
                  <RadarChart
                    path={"socialMediaCoverage"}
                    data={
                      Object.keys(radardata).length !== 0
                        ? radardata
                        : {
                            posts: 0,
                            reposts: 0,
                            users: 0,
                            likes: 0,
                            replies: 0,
                          }
                    }
                    labelObj={{
                      posts: "Posts",
                      reposts: "Reposts",
                      users: "Users",
                      likes: "Likes",
                      replies: "Replies",
                    }}
                  />
                </div>
                <div className="social-media-tab-heading">
                  <h5>Social Media Coverage</h5>

                  <div className="over-view-tabs-section">
                    <Tabs
                      onChange={(e) => setActiveTab(e)}
                      activeKey={activeTab}
                      className="left-side-tabs-social"
                    >
                      <Tabs.TabPane key="1" tab={"Overview"}></Tabs.TabPane>

                      <Tabs.TabPane key="2" tab={"X corp."}></Tabs.TabPane>

                      <Tabs.TabPane key="3" tab={"Reddit"}></Tabs.TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            {activeTab == "1" && (
              <div className="col-lg-8">
                <div className="social-media-content">
                  <div className="social-apple-faces">
                    <div className="over-environmental">
                      <div className="over-envi-icon">
                        <img
                          src={
                            incidentDetail
                              ? incidentDetail?.severity_score?.level ==
                                  "Critical" ||
                                incidentDetail?.severity_score?.level == "High"
                                ? ImageConst.appleFaceIcon
                                : incidentDetail?.severity_score?.level == "Low"
                                  ? ImageConst.appleLow
                                  : ImageConst.appleMedium
                              : companyDetail
                                ? ImageConst.appleLogoW
                                : ImageConst.socialLink
                          }
                          alt="appleFaceIcon"
                        />
                      </div>

                      <div className="apple-face-text">
                        <h5>
                          {incidentDetail
                            ? incidentDetail?.title || ""
                            : companies && companies.length > 0
                              ? location.state?.companyName
                              : "Social Media Coverage"}
                        </h5>
                        <div className="severity">
                          <p>
                            {incidentDetail ? "Severity:" : "Total Incidents:"}{" "}
                            <span>
                              {incidentDetail
                                ? incidentDetail?.severity_score?.level || ""
                                : companyDetail
                                  ? companyDetail.incidents
                                  : allIncidentDetails
                                    ? allIncidentDetails.incidents
                                    : ""}
                            </span>{" "}
                          </p>
                          <p>
                            {incidentDetail
                              ? "Severity Score:"
                              : allIncidentDetails
                                ? "Severe Incidents: "
                                : "Risk Score: "}
                            <span>
                              {incidentDetail
                                ? Math.round(
                                    incidentDetail?.severity_score?.value
                                  ) || 0
                                : allIncidentDetails
                                  ? allIncidentDetails?.severeIncidents
                                  : Math.round(calculateTotalRisk)}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="on-scroll-media">
                    <div className="sentiment-media">
                      <div className="summery-ps">
                        <div className="logo-over-view">
                          <img src={ImageConst.overViewlogo} />
                        </div>
                        <div className="sentiment-summary">
                          <div className="sentiment-heading">
                            <h6>
                              {" "}
                              Sentiment Summary{" "}
                              <span>
                                {" "}
                                <img src={ImageConst.circleInfo} />{" "}
                              </span>{" "}
                            </h6>
                            <p>
                              {summaryData && summaryData?.last_updated
                                ? `Last Updated on  ${moment(
                                    summaryData?.last_updated
                                  ).format("MMM, DD YYYY")}`
                                : ""}
                            </p>
                          </div>
                          <p className="over-text-ps">Overview</p>
                        </div>
                      </div>

                      <div className="over-content">
                        <p>
                          {summaryData?.summary || " "}
                          {/* <span>X corp.</span> and <span>Reddit</span> have
                          mixed reactions towards Apple, with some users
                          praising the company's products in support of the
                          planet while others criticize its environmental
                          activities. In general, <span>X corp.</span> and{" "}
                          <span>Reddit</span> may have a more critical stance
                          towards Apple's ESG performance, while users are
                          positive and supportive of the company's efforts in
                          this area. */}
                        </p>
                      </div>

                      <div className="reaction-box">
                        <h6>
                          Reaction Analysis{" "}
                          <span>
                            <img src={ImageConst.circleInfo} />
                          </span>
                        </h6>
                        <div className="overall-rea">
                          <div className="post-reaction">
                            <p>Overall Reactions (Posts & Tweets):</p>
                            <span>
                              {getTotalCount("tweet") + getTotalCount("reddit")}
                            </span>
                          </div>

                          <div className="separated-by">
                            <p>Separated by Sentiment:</p>
                            <span>
                              {socialMediaReactionData?.total?.positive || 0}
                            </span>
                            <span>
                              {socialMediaReactionData?.total?.negative || 0}
                            </span>
                            <span>
                              {socialMediaReactionData?.total?.neutral || 0}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="media-box">
                        <div className="tweet-reply">
                          <img
                            className="twitter-icons-ps"
                            src={ImageConst.twitterIconMedia}
                          />
                          <div className="numbers-of-tweet">
                            <div className="tweet-heading">
                              <p>Tweet</p>
                              <span>{getTotalCount("tweet")}</span>
                            </div>

                            <div className="tweet-heading">
                              <p>Reply</p>
                              <span>
                                {socialMediaReactionData?.tweet?.replyCount ||
                                  0}
                              </span>
                            </div>
                          </div>

                          <div className="pie-chart-ps">
                            <ReactionAnalysesChart
                              data={socialMediaReactionData}
                              graphType={"tweet"}
                            />
                          </div>
                        </div>

                        <div className="tweet-reply">
                          <img
                            className="twitter-icons-ps"
                            src={ImageConst.redditIconMedia}
                          />
                          <div className="numbers-of-tweet">
                            <div className="tweet-heading">
                              <p>Post</p>
                              <span>{getTotalCount("reddit")}</span>
                            </div>

                            <div className="tweet-heading">
                              <p>Comment</p>
                              <span>
                                {socialMediaReactionData?.reddit?.replyCount ||
                                  0}
                              </span>
                            </div>
                          </div>

                          <div className="pie-chart-ps">
                            {/* <img src={ImageConst.pieChart} /> */}
                            <ReactionAnalysesChart
                              data={socialMediaReactionData}
                              graphType={"reddit"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <OverviewTimeline
                      redditData={totalTweetReaction.reddit || {}}
                      tweetData={totalTweetReaction.tweet || {}}
                    />
                  </div>
                </div>
              </div>
            )}

            {activeTab === "2" && (
              <div className="col-lg-8">
                <div className="social-media-content">
                  <div className="social-apple-faces">
                    <div className="over-environmental">
                      <div className="over-envi-icon">
                        <img
                          src={
                            incidentDetail
                              ? incidentDetail?.severity_score?.level ==
                                  "High" ||
                                incidentDetail?.severity_score?.level ==
                                  "Critical"
                                ? ImageConst.appleFaceIcon
                                : incidentDetail?.severity_score?.level == "Low"
                                  ? ImageConst.appleLow
                                  : ImageConst.appleMedium
                              : companyDetail
                                ? ImageConst.appleLogoW
                                : ImageConst.socialLink
                          }
                          alt="appleFaceIcon"
                        />
                      </div>

                      <div className="apple-face-text">
                        <h5>
                          {incidentDetail
                            ? incidentDetail?.title || ""
                            : companies && companies.length > 0
                              ? location.state?.companyName
                              : "Social Media Coverage"}
                        </h5>
                        <div className="severity">
                          <p>
                            {incidentDetail ? "Severity:" : "Total Incidents:"}{" "}
                            <span>
                              {incidentDetail
                                ? incidentDetail?.severity_score?.level || ""
                                : companyDetail
                                  ? companyDetail.incidents
                                  : allIncidentDetails
                                    ? allIncidentDetails?.incidents
                                    : ""}
                            </span>{" "}
                          </p>
                          <p>
                            {incidentDetail
                              ? "Severity Score:"
                              : allIncidentDetails
                                ? "Severe Incidents: "
                                : "Risk Score: "}
                            <span>
                              {incidentDetail
                                ? Math.round(
                                    incidentDetail?.severity_score?.value
                                  ) || 0
                                : allIncidentDetails
                                  ? allIncidentDetails?.severeIncidents
                                  : Math.round(calculateTotalRisk)}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* <div className="apple-face-text">
                        <h5>
                          Apple Faces Criticism Over Environmental Impact in
                          Supply Chain{" "}
                        </h5>
                        <div className="severity">
                          <p>
                            Severity: <span>High</span>{" "}
                          </p>
                          <p>
                            Severity Score: <span>89</span>{" "}
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="on-scroll-media">
                    <div className="sentiment-media">
                      <div className="summery-ps">
                        <div className="logo-over-view">
                          <img src={ImageConst.xIcon} />
                        </div>
                        <div className="sentiment-summary">
                          <div className="sentiment-heading">
                            <h6>
                              {" "}
                              Sentiment Summary{" "}
                              <span>
                                {" "}
                                <img src={ImageConst.circleInfo} />{" "}
                              </span>{" "}
                            </h6>
                            <div>
                              <p>
                                {summaryData && summaryData?.last_updated
                                  ? `Last Updated on  ${moment(
                                      summaryData?.last_updated
                                    ).format("MMM, DD YYYY")}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <p className="over-text-ps">X Corp.</p>
                        </div>
                      </div>

                      <div className="summary-x">
                        <p>{summaryData?.summary || ""}</p>
                        {/* <p>
                          This is a summary of users’ attitude in X corp.
                          related to user’s search,
                        </p>
                        <p>The most challenging topics on their discussions,</p>
                        <p>
                          What do they think about overall ESG performance of a
                          company (if one company is selected)
                        </p> */}
                      </div>

                      {summaryData?.topics && summaryData?.topics.length > 0 ? (
                        <div className="msot-relevant">
                          <h6>Most Relevant & Recent Topics</h6>
                          <div className="climate-change">
                            {summaryData?.topics.map((item, index) => {
                              return (
                                <>
                                  <h6>
                                    <span></span>
                                    {item?.topic || ""}
                                  </h6>
                                  <p>{item?.summary || ""}</p>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="top-main-keywords-ps">
                      <div className="top-keywords">
                        <div className="reactions-ps overall-rection">
                          <p>
                            Overall Reactions{" "}
                            <span>
                              {" "}
                              <img src={ImageConst.circleInfo} />{" "}
                            </span>{" "}
                          </p>
                        </div>

                        <div className="bar-chart-section-ps">
                          <div className="bar-chart-left-side">
                            <ul>
                              <li>View</li>
                              <li>Likes</li>
                              <li>Retweets</li>
                              <li>Replies</li>
                              <li>Quotes</li>
                            </ul>
                          </div>
                          <div className="bar-chart-ps">
                            <BarChart
                              type={"xcorp"}
                              labels={[
                                "View",
                                "Likes",
                                "Retweets",
                                "Replies",
                                "Quotes",
                              ]}
                              data={
                                Object.keys(overAllReactionSentiment).length !==
                                0
                                  ? overAllReactionSentiment
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="top-keywords">
                        <div className="reactions-ps overall-rection">
                          <p>
                            Overall Replies Sentiment{" "}
                            <span>
                              {" "}
                              <img src={ImageConst.circleInfo} />{" "}
                            </span>{" "}
                          </p>
                        </div>

                        <div className="donut-map-section">
                          <div className="color-pointer">
                            <div className="overall-color">
                              <p>
                                <span>
                                  <img src={ImageConst.darkGreen} />
                                </span>{" "}
                                Positive{" "}
                              </p>
                              <h6>
                                {overAllReactionSentiment?.tweetPositive || 0}
                              </h6>
                            </div>

                            <div className="overall-color">
                              <p>
                                <span>
                                  <img src={ImageConst.brightRed} />
                                </span>{" "}
                                Negative{" "}
                              </p>
                              <h6>
                                {overAllReactionSentiment?.tweetNegative || 0}
                              </h6>
                            </div>

                            <div className="overall-color">
                              <p>
                                <span>
                                  <img src={ImageConst.gray} />
                                </span>{" "}
                                Neutral{" "}
                              </p>
                              <h6>
                                {overAllReactionSentiment?.tweetNeutral || 0}
                              </h6>
                            </div>
                          </div>

                          <div className="top-keyword-map-donut">
                            <ReplySentimentDonut
                              data={
                                Object.keys(overAllReactionSentiment).length !==
                                0
                                  ? [
                                      overAllReactionSentiment?.tweetNegative,
                                      overAllReactionSentiment?.tweetPositive,
                                      overAllReactionSentiment?.tweetNeutral,
                                    ]
                                  : []
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <TweetTimeline data={totalTweetReaction.tweet || {}} />

                    <div className="user-bar-chart-ps">
                      <div className="influential-user-ps">
                        <h5>Most Influential Users</h5>
                      </div>

                      <div className="most-followers-ps">
                        <div className="followers-title-ps">
                          {mostInfluentialUser &&
                            mostInfluentialUser.length > 0 && (
                              <p className="follow-text-on-top">Followers</p>
                            )}

                          {mostInfluentialUser &&
                          mostInfluentialUser.length > 0 ? (
                            mostInfluentialUser.map((elem, index) => {
                              return (
                                <div className="twitter-user-sectoion-ps mt-2">
                                  <div className="elon-musk-ps">
                                    <div className="elon-profile-pic">
                                      {elem?.userData?.image === null ? (
                                        <img src={ImageConst.avatarTwitter1} />
                                      ) : (
                                        <img src={elem?.userData?.image} />
                                      )}
                                    </div>
                                    <div className="user-name-id-ps">
                                      <p>
                                        {elem?.userData?.name}{" "}
                                        <span>@{elem?.userData?.username}</span>{" "}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="num-of-followers-ps">
                                    <p>{formatFollowers(elem?.followers)}</p>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="mostInfluentialUser-no-data">
                              {" "}
                              No Data
                            </div>
                          )}
                        </div>

                        <div className="most-influential-bar-chart">
                          <div className="followers-title-ps">
                            {mostInfluentialUser &&
                              mostInfluentialUser.length > 0 && (
                                <p className="like-text-on-top">Likes</p>
                              )}
                          </div>
                          <MostInfluentialUsersBarChart
                            likesCount={mostInfluentialUser}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="sentiment-media1">
                      <div className="summery-ps">
                        <div className="logo-over-view">
                          <img src={ImageConst.comment} />
                        </div>
                        <div className="sentiment-summary">
                          <div className="sentiment-heading">
                            <h6>
                              {" "}
                              Comment Summary{" "}
                              <span>
                                {" "}
                                <img src={ImageConst.circleInfo} />{" "}
                              </span>{" "}
                            </h6>
                          </div>
                          <p className="over-text-ps">X Corp.</p>
                        </div>
                      </div>

                      <div className="positive-comm">
                        {summaryData &&
                        !summaryData?.replies_summary?.negative_summary &&
                        !summaryData?.replies_summary?.neutral_summary &&
                        !summaryData?.replies_summary?.positive_summary ? (
                          <div className="no-data">No Data...</div>
                        ) : (
                          <>
                            {summaryData?.replies_summary?.positive_summary && (
                              <div className={`all-comments`}>
                                <h6>Positive comments</h6>
                                <p>
                                  {
                                    summaryData?.replies_summary
                                      ?.positive_summary
                                  }
                                </p>
                              </div>
                            )}
                            {summaryData?.replies_summary?.negative_summary && (
                              <div className={`all-comments nagative-comm`}>
                                <h6>Negative comments</h6>
                                <p>
                                  {
                                    summaryData?.replies_summary
                                      ?.negative_summary
                                  }
                                </p>
                              </div>
                            )}
                            {summaryData?.replies_summary?.neutral_summary && (
                              <div className={`all-comments neutral-comm`}>
                                <h6>Neutral comments</h6>
                                <p>
                                  {
                                    summaryData?.replies_summary
                                      ?.neutral_summary
                                  }
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div> */}

                    <div className="tweets-replies">
                      <div className="replies-sections">
                        <h5>Tweets & Replies</h5>
                        <Popover
                          placement="bottomRight"
                          overlayClassName="setting sort-by"
                          content={
                            <div className="set-by-popover">
                              <div className="set-title-ps">
                                <p>Sort by</p>
                              </div>
                              <div className="set-by-list">
                                <ul>
                                  <li
                                    onClick={() =>
                                      handleTweetSortChange("datetime")
                                    }
                                  >
                                    <img
                                      className={`select_li_sort ${
                                        tweetSort == "datetime" && "active_sort"
                                      }`}
                                      src={ImageConst.checkGrey}
                                    />
                                    Most recent
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleTweetSortChange("replies")
                                    }
                                  >
                                    <img
                                      className={`select_li_sort ${
                                        tweetSort == "replies" && "active_sort"
                                      }`}
                                      src={ImageConst.checkGrey}
                                    />
                                    Most replies
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleTweetSortChange("view_count")
                                    }
                                  >
                                    <img
                                      className={`select_li_sort ${
                                        tweetSort == "view_count" &&
                                        "active_sort"
                                      }`}
                                      src={ImageConst.checkGrey}
                                    />
                                    Most popular
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleTweetSortChange("NEGATIVE")
                                    }
                                  >
                                    <img
                                      className={`select_li_sort ${
                                        tweetSort == "NEGATIVE" && "active_sort"
                                      }`}
                                      src={ImageConst.checkGrey}
                                    />
                                    Most negative
                                  </li>
                                </ul>
                              </div>
                            </div>
                          }
                          title=""
                          trigger="click"
                          open={open}
                          onOpenChange={handleOpenChange}
                        >
                          <img src={ImageConst.sortIcon} />
                        </Popover>
                      </div>

                      <div className="john-replies-tweets">
                        {tweetAndRepliesData &&
                        tweetAndRepliesData.length > 0 ? (
                          tweetAndRepliesData.map((item, index) => {
                            return (
                              <>
                                <div className="john-tweets">
                                  <div className="full-tweet-dis">
                                    <div className="twitter-user-profile-image">
                                      <img
                                        src={
                                          item?.user?.image || ImageConst.xIcon
                                        }
                                      />
                                    </div>
                                    <div className="user-twitter-name">
                                      <h6>
                                        {item?.user?.name || ""}{" "}
                                        <span>
                                          {item?.user?.username
                                            ? `@${
                                                item?.user?.username
                                              } . ${moment(
                                                item?.datetime
                                              ).fromNow()}`
                                            : ""}{" "}
                                        </span>{" "}
                                      </h6>

                                      <p>{item?.content || ""} </p>

                                      <div className="view-comm-ps">
                                        <p>
                                          {item?.like_count || 0}{" "}
                                          <span>Likes</span>
                                        </p>
                                        <p>
                                          {item?.retweet_count || 0}{" "}
                                          <span>Retweets</span>
                                        </p>
                                        <p>
                                          {item?.quote_count || 0}{" "}
                                          <span>Quotes</span>
                                        </p>
                                        <p>
                                          {item?.view_count || 0}{" "}
                                          <span>view</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="number-of-replies">
                                  <div className="all-rep-neg-pos">
                                    <h6>
                                      Replies: <span>{item?.replies || 0}</span>
                                    </h6>
                                    <p>
                                      {" "}
                                      <img
                                        src={ImageConst.playicon}
                                      /> Pos. <span>{item?.POSITIVE || 0}</span>
                                    </p>
                                    <p className="neg-box-pg">
                                      Neg. <span>{item?.NEGATIVE || 0}</span>
                                    </p>
                                    <p className="neut-box">
                                      Neut. <span>{item?.NEUTRAL || 0}</span>
                                    </p>
                                  </div>
                                  <div className="view-replies-btn-ps">
                                    {item?.replies > 0 && (
                                      <p
                                        onClick={() => {
                                          dispatch(
                                            updateSocialMediaState(
                                              [],
                                              "individualTweetReplyData"
                                            )
                                          );
                                          setShowReplies((prev) =>
                                            prev == index ? null : index
                                          );
                                          dispatch(
                                            getIndividualTweetReplyLoad(
                                              item?.id
                                            )
                                          );
                                        }}
                                      >
                                        {showReplies == index ? "Hide" : "View"}{" "}
                                        Replies
                                        {showReplies == index ? (
                                          <span>
                                            <img
                                              src={ImageConst.blueUpArrow}
                                            ></img>
                                          </span>
                                        ) : (
                                          <span>
                                            <img
                                              src={ImageConst.blueDownArrow}
                                            />
                                          </span>
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {showReplies == index &&
                                  individualTweetReplyData &&
                                  individualTweetReplyData.length > 0 &&
                                  individualTweetReplyData.map(
                                    (elem, index) => {
                                      return (
                                        <div className="hide-replies">
                                          {/* {individualTweetReplyData} */}
                                          <div
                                            className={`full-tweet-dis ${
                                              index > 0 && "mt-3"
                                            }`}
                                          >
                                            <div className="twitter-user-profile-image">
                                              <img
                                                src={
                                                  elem?.user?.image ||
                                                  ImageConst.avatarTwitter
                                                }
                                              />
                                            </div>
                                            <div className="user-twitter-name ">
                                              <div
                                                className={`rachel-replies ${
                                                  elem?.sentiment?.category ==
                                                    "NEGATIVE" &&
                                                  "zeev-negative-rep"
                                                } ${
                                                  elem?.sentiment?.category ==
                                                    "NEUTRAL" &&
                                                  "meccanica-neutral-rep"
                                                }`}
                                              >
                                                <h6>
                                                  {elem?.user?.name ||
                                                    "Rachel "}
                                                  <span>
                                                    {" "}
                                                    {elem?.user?.username
                                                      ? `@${
                                                          elem?.user?.username
                                                        } . ${moment(
                                                          elem?.datetime
                                                        ).fromNow()}`
                                                      : ""}{" "}
                                                  </span>{" "}
                                                </h6>
                                                <p>
                                                  {elem?.sentiment?.category ||
                                                    ""}
                                                </p>
                                              </div>

                                              <div
                                                className={`rachel-replies-text ${
                                                  elem?.sentiment?.category ==
                                                    "NEGATIVE" &&
                                                  "negative-background"
                                                } ${
                                                  elem?.sentiment?.category ==
                                                    "NEUTRAL" &&
                                                  "neutral-background"
                                                }`}
                                              >
                                                <p>{elem?.content || ""}</p>
                                              </div>

                                              <div className="view-comm-ps">
                                                <p>
                                                  {elem?.like_count || 0}{" "}
                                                  <span>Likes</span>
                                                </p>
                                                <p>
                                                  {elem?.retweet_count || 0}{" "}
                                                  <span>Retweets</span>
                                                </p>
                                                <p>
                                                  {elem?.quote_count || 0}{" "}
                                                  <span>Quotes</span>
                                                </p>
                                                <p>
                                                  {elem?.like_count || 0}{" "}
                                                  <span>view</span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </>
                            );
                          })
                        ) : (
                          <div className="no-data">No Data...</div>
                        )}

                        {!tweetAndRepliesLoader &&
                          tweetLimit < tweetAndRepliesDataCount && (
                            <div className="social-media-loader">
                              <div className="load-more-ps">
                                <p
                                  onClick={() => {
                                    setTweetLimit((prev) => prev + 15);

                                    dispatch(
                                      getTweetWithRepliesLoad({
                                        companies,
                                        incident,
                                        type: tagsType,
                                        order: -1,
                                        limit: tweetLimit + 15,
                                        offset: tweetOffset,
                                        sort: tweetSort,
                                      })
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  Load More Replies...
                                </p>
                              </div>
                            </div>
                          )}

                        {tweetAndRepliesLoader && (
                          <div className="social-media-loader">
                            <div className="loader ">
                              <div className="circle" id="circleE"></div>
                              <div className="circle" id="circleS"></div>
                              <div className="circle" id="circleG"></div>
                            </div>

                            <div className="load-more-ps select-loader">
                              <p>Load More Replies...</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "3" && (
              <div className="col-lg-8">
                <div className="social-media-content">
                  <div className="social-apple-faces">
                    <div className="over-environmental">
                      <div className="over-envi-icon">
                        <img
                          src={
                            incidentDetail
                              ? incidentDetail?.severity_score?.level ==
                                  "High" ||
                                incidentDetail?.severity_score?.level ==
                                  "Critical"
                                ? ImageConst.appleFaceIcon
                                : incidentDetail?.severity_score?.level == "Low"
                                  ? ImageConst.appleLow
                                  : ImageConst.appleMedium
                              : companyDetail
                                ? ImageConst.appleLogoW
                                : ImageConst.socialLink
                          }
                          alt="appleFaceIcon"
                        />
                      </div>

                      <div className="apple-face-text">
                        <h5>
                          {incidentDetail
                            ? incidentDetail?.title || ""
                            : companies && companies.length > 0
                              ? location.state?.companyName
                              : "Social Media Coverage"}
                        </h5>
                        <div className="severity">
                          <p>
                            {incidentDetail ? "Severity:" : "Total Incidents:"}{" "}
                            <span>
                              {incidentDetail
                                ? incidentDetail?.severity_score?.level || ""
                                : companyDetail
                                  ? companyDetail.incidents
                                  : allIncidentDetails
                                    ? allIncidentDetails?.incidents
                                    : ""}
                            </span>{" "}
                          </p>
                          <p>
                            {incidentDetail
                              ? "Severity Score:"
                              : companyDetail
                                ? "Risk Score: "
                                : "Severe Incidents: "}
                            <span>
                              {incidentDetail
                                ? Math.round(
                                    incidentDetail?.severity_score?.value
                                  ) || 0
                                : allIncidentDetails
                                  ? allIncidentDetails?.severeIncidents
                                  : Math.round(calculateTotalRisk)}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* <div className="apple-face-text">
                        <h5>
                          Apple Faces Criticism Over Environmental Impact in
                          Supply Chain{" "}
                        </h5>
                        <div className="severity">
                          <p>
                            Severity: <span>High</span>{" "}
                          </p>
                          <p>
                            Severity Score: <span>89</span>{" "}
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="on-scroll-media">
                    <div className="sentiment-media">
                      <div className="summery-ps">
                        <div className="logo-over-view">
                          <img src={ImageConst.radditlogops} />
                        </div>
                        <div className="sentiment-summary">
                          <div className="sentiment-heading">
                            <h6>
                              {" "}
                              Sentiment Summary{" "}
                              <span>
                                {" "}
                                <img src={ImageConst.circleInfo} />{" "}
                              </span>{" "}
                            </h6>
                            <div>
                              <p>
                                {summaryData && summaryData?.last_updated
                                  ? `Last Updated on  ${moment(
                                      summaryData?.last_updated
                                    ).format("MMM, DD YYYY")}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <p className="over-text-ps">Reddit</p>
                        </div>
                      </div>

                      <div className="summary-x">
                        <p>{summaryData?.summary || ""}</p>
                      </div>

                      {summaryData?.topics && summaryData?.topics.length > 0 ? (
                        <div className="msot-relevant">
                          <h6>Most Relevant & Recent Topics</h6>
                          <div className="climate-change">
                            {summaryData?.topics.map((item, index) => {
                              return (
                                <>
                                  <h6>
                                    <span></span>
                                    {item?.topic || ""}
                                  </h6>
                                  <p>{item?.summary || ""}</p>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="top-main-keywords-ps">
                      <div className="top-keywords">
                        <div className="reactions-ps overall-rection">
                          <p>
                            Overall Reactions{" "}
                            <span>
                              {" "}
                              <img src={ImageConst.circleInfo} />{" "}
                            </span>{" "}
                          </p>
                        </div>

                        <div className="bar-chart-section-ps">
                          <div className="bar-chart-left-side">
                            <ul>
                              <li>Upvotes</li>
                              <li>Downvotes</li>
                              <li>Score</li>
                              <li>Comment</li>
                            </ul>
                          </div>
                          <div className="bar-chart-ps">
                            <BarChart
                              type={"reddit"}
                              height={170}
                              labels={[
                                "Upvotes",
                                "DownVotes",
                                "Score",
                                "Comment",
                              ]}
                              data={
                                Object.keys(overAllRedditReaction).length !== 0
                                  ? overAllRedditReaction
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="top-keywords">
                          <div className="reactions-ps overall-rection">
                            <p>Overall Replies Sentiment <span> <img src={ImageConst.circleInfo} /> </span> </p>
                          </div>
                          <div className="top-keyword-map">
                            <ReplySentimentDonut />
                          </div>
                        </div> */}

                      <div className="top-keywords">
                        <div className="reactions-ps overall-rection">
                          <p>
                            Overall Replies Sentiment{" "}
                            <span>
                              {" "}
                              <img src={ImageConst.circleInfo} />{" "}
                            </span>{" "}
                          </p>
                        </div>

                        <div className="donut-map-section">
                          <div className="color-pointer">
                            <div className="overall-color">
                              <p>
                                <span>
                                  <img src={ImageConst.darkGreen} />
                                </span>{" "}
                                Positive{" "}
                              </p>
                              <h6>{overAllRedditReaction?.positive || 0}</h6>
                            </div>

                            <div className="overall-color">
                              <p>
                                <span>
                                  <img src={ImageConst.brightRed} />
                                </span>{" "}
                                Negative{" "}
                              </p>
                              <h6>{overAllRedditReaction?.negative || 0}</h6>
                            </div>

                            <div className="overall-color">
                              <p>
                                <span>
                                  <img src={ImageConst.gray} />
                                </span>{" "}
                                Neutral{" "}
                              </p>
                              <h6>{overAllRedditReaction?.neutral || 0}</h6>
                            </div>
                          </div>

                          <div className="top-keyword-map-donut">
                            <ReplySentimentDonut
                              data={
                                Object.keys(overAllRedditReaction).length !== 0
                                  ? [
                                      overAllRedditReaction?.negative,
                                      overAllRedditReaction?.positive,
                                      overAllRedditReaction?.neutral,
                                    ]
                                  : []
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-24">
                      <TweetTimeline
                        title={"Post Timeline"}
                        data={totalTweetReaction.reddit || {}}
                      />
                    </div>

                    <div className="user-bar-chart-ps">
                      <div className="influential-user-ps">
                        <h5>Most Influential Users </h5>
                      </div>

                      <div className="most-followers-ps">
                        <div className="followers-title-ps">
                          {mostInfluentialUser &&
                          mostInfluentialUser.length > 0 ? (
                            mostInfluentialUser.map((elem, index) => {
                              return (
                                <div className="twitter-user-sectoion-ps mt-2">
                                  <div className="elon-musk-ps">
                                    <div className="elon-profile-pic">
                                      <img
                                        src={
                                          elem?.userData?.image ||
                                          ImageConst.Raddit
                                        }
                                      />
                                    </div>
                                    <div className="user-name-id-ps">
                                      <p>
                                        {elem?.userData?.name}{" "}
                                        <span>@{elem?.userData?.username}</span>{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="mostInfluentialUser-no-data">
                              {" "}
                              No Data
                            </div>
                          )}
                        </div>

                        <div className="most-influential-bar-chart">
                          <div className="followers-title-ps">
                            {mostInfluentialUser &&
                              mostInfluentialUser.length > 0 && (
                                <p className="like-text-on-top">Likes</p>
                              )}
                          </div>
                          <MostInfluentialUsersBarChart
                            likesCount={mostInfluentialUser}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sentiment-media1">
                      <div className="summery-ps">
                        <div className="logo-over-view">
                          <img src={ImageConst.comment} />
                        </div>
                        <div className="sentiment-summary">
                          <div className="sentiment-heading">
                            <h6>
                              {" "}
                              Comment Summary{" "}
                              <span>
                                {" "}
                                <img src={ImageConst.circleInfo} />{" "}
                              </span>{" "}
                            </h6>
                          </div>
                          <p className="over-text-ps">Reddit</p>
                        </div>
                      </div>

                      <div className="positive-comm">
                        {summaryData &&
                        !summaryData?.replies_summary?.negative_summary &&
                        !summaryData?.replies_summary?.neutral_summary &&
                        !summaryData?.replies_summary?.positive_summary ? (
                          <div className="no-data">No Data...</div>
                        ) : (
                          <>
                            {summaryData?.replies_summary?.positive_summary && (
                              <div className={`all-comments`}>
                                <h6>Positive comments</h6>
                                <p>
                                  {
                                    summaryData?.replies_summary
                                      ?.positive_summary
                                  }
                                </p>
                              </div>
                            )}
                            {summaryData?.replies_summary?.negative_summary && (
                              <div className={`all-comments nagative-comm`}>
                                <h6>Negative comments</h6>
                                <p>
                                  {
                                    summaryData?.replies_summary
                                      ?.negative_summary
                                  }
                                </p>
                              </div>
                            )}
                            {summaryData?.replies_summary?.neutral_summary && (
                              <div className={`all-comments neutral-comm`}>
                                <h6>Neutral comments</h6>
                                <p>
                                  {
                                    summaryData?.replies_summary
                                      ?.neutral_summary
                                  }
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="tweets-replies">
                      <div className="replies-sections">
                        <h5>Post & Comments</h5>

                        <Popover
                          placement="bottomRight"
                          overlayClassName="setting sort-by"
                          content={
                            <div className="set-by-popover">
                              <div className="set-title-ps">
                                <p>Sort by</p>
                              </div>
                              <div className="set-by-list">
                                <ul>
                                  <li
                                    onClick={() =>
                                      handleRedditSortChange("datetime")
                                    }
                                  >
                                    <img
                                      className={`select_li_sort ${
                                        redditSort == "datetime" &&
                                        "active_sort"
                                      }`}
                                      src={ImageConst.checkGrey}
                                    />
                                    Most recent
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleRedditSortChange("comments")
                                    }
                                  >
                                    <img
                                      className={`select_li_sort ${
                                        redditSort == "comments" &&
                                        "active_sort"
                                      }`}
                                      src={ImageConst.checkGrey}
                                    />
                                    Most replies
                                  </li>
                                  <li
                                    onClick={() => handleRedditSortChange(null)}
                                  >
                                    <img
                                      className={`select_li_sort ${
                                        redditSort == "popular" && "active_sort"
                                      }`}
                                      src={ImageConst.checkGrey}
                                    />
                                    Most popular
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleRedditSortChange("NEGATIVE")
                                    }
                                  >
                                    <img
                                      className={`select_li_sort ${
                                        redditSort == "NEGATIVE" &&
                                        "active_sort"
                                      }`}
                                      src={ImageConst.checkGrey}
                                    />
                                    Most negative
                                  </li>
                                </ul>
                              </div>
                            </div>
                          }
                          title=""
                          trigger="click"
                          open={open}
                          onOpenChange={handleOpenChange}
                        >
                          <img src={ImageConst.sortIcon} />
                        </Popover>
                      </div>

                      {/** Sahil Block */}
                      <div className="john-replies-tweets">
                        {redditAllPosts && redditAllPosts?.data?.length > 0 ? (
                          redditAllPosts?.data?.map((item, index) => {
                            return (
                              <>
                                <div className="john-tweets main-rebecca-sec">
                                  <div className="rebecca-outer-space">
                                    <div
                                      className={`full-tweet-dis ${
                                        index > 0 && "mt-3"
                                      }`}
                                    >
                                      <div className="twitter-user-profile-image">
                                        <img src={ImageConst.fannews} />
                                      </div>
                                      <div className="user-twitter-name rebecca-name">
                                        <h6>
                                          {item?.title}{" "}
                                          <span>
                                            {" "}
                                            . Posted by {item?.user?.username}  
                                            1 month ago
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="amazone-text-box">
                                      <div className="amazone-text-desp">
                                        <p>{item?.text}</p>
                                        <a
                                          href={item?.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            cursor: "pointer",
                                            color: "#0195CE",
                                            fontFamily: "Inter",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "16px",
                                            wordBreak: "break-all",
                                          }}
                                        >
                                          {item?.url}
                                        </a>

                                        <div className="amazone-upvotes-ps">
                                          <span>{item?.upvotes} upvotes</span>{" "}
                                          <span>{item?.comments} Comments</span>{" "}
                                          <span>0 Awards</span>
                                        </div>
                                      </div>
                                      <div className="amazone-office-image">
                                        <img
                                          src={ImageConst.Raddit}
                                          height={150}
                                          width={150}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div></div>
                                </div>
                                <div className="number-of-replies">
                                  <div className="all-rep-neg-pos">
                                    <h6>
                                      Replies: <span>{item?.comments}</span>
                                    </h6>
                                    <p>
                                      {" "}
                                      <img
                                        src={ImageConst.playicon}
                                      /> Pos. <span>{item?.POSITIVE}</span>
                                    </p>
                                    <p className="neg-box-pg">
                                      Neg. <span>{item?.NEGATIVE}</span>
                                    </p>
                                    <p className="neut-box">
                                      Neut. <span>{item?.NEUTRAL}</span>
                                    </p>
                                  </div>
                                  <div className="view-replies-btn-ps">
                                    {item?.comments > 0 && (
                                      <p
                                        onClick={() => {
                                          setShowReplies((prev) =>
                                            prev == index ? null : index
                                          );
                                          dispatch(
                                            getRedditWithCommentLoad(item?.id)
                                          );
                                        }}
                                      >
                                        {showReplies == index ? "Hide" : "View"}{" "}
                                        Replies
                                        {showReplies == index ? (
                                          <span>
                                            <img
                                              src={ImageConst.blueUpArrow}
                                            ></img>
                                          </span>
                                        ) : (
                                          <span>
                                            <img
                                              src={ImageConst.blueDownArrow}
                                            />
                                          </span>
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                {showReplies == index &&
                                  redditPostComments &&
                                  redditPostComments.length > 0 && (
                                    <div className={`hide-replies`}>
                                      {redditPostComments.map((elem, index) => {
                                        return (
                                          <div
                                            className={`full-tweet-dis ${
                                              index > 0 && "mt-3"
                                            }`}
                                          >
                                            <div className="twitter-user-profile-image raddit-pro">
                                              <img
                                                src={ImageConst.radditiconsPs}
                                              />
                                            </div>
                                            <div className="user-twitter-name ">
                                              <div
                                                className={`rachel-replies ${
                                                  elem?.postComments?.sentiment
                                                    ?.category == "NEGATIVE" &&
                                                  "zeev-negative-rep"
                                                } ${
                                                  elem?.postComments?.sentiment
                                                    ?.category == "NEUTRAL" &&
                                                  "meccanica-neutral-rep"
                                                }`}
                                              >
                                                <h6>
                                                  {
                                                    elem?.postComments?.author
                                                      ?.name
                                                  }
                                                  <span>
                                                    {" "}
                                                    {moment(
                                                      elem?.postComments
                                                        ?.last_updated_time
                                                    ).fromNow()}
                                                  </span>{" "}
                                                </h6>
                                                <p>
                                                  {
                                                    elem?.postComments
                                                      ?.sentiment?.category
                                                  }
                                                </p>
                                              </div>

                                              <div
                                                className={`rachel-replies-text ${
                                                  elem?.postComments?.sentiment
                                                    ?.category == "NEGATIVE" &&
                                                  "negative-background"
                                                } ${
                                                  elem?.postComments?.sentiment
                                                    ?.category == "NEUTRAL" &&
                                                  "neutral-background"
                                                }`}
                                              >
                                                <p>
                                                  {" "}
                                                  {
                                                    elem?.postComments?.text
                                                  }{" "}
                                                </p>
                                              </div>
                                              <div className={`view-comm-ps`}>
                                                <p>
                                                  <span
                                                    className={`${
                                                      elem?.postComments
                                                        ?.upvotes &&
                                                      +elem?.postComments
                                                        ?.upvotes < 0 &&
                                                      "red_color_upvotes"
                                                    }`}
                                                  >
                                                    {
                                                      elem?.postComments
                                                        ?.upvotes
                                                    }{" "}
                                                    Upvotes
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                              </>
                            );
                          })
                        ) : (
                          <div className="no-data">No Data</div>
                        )}

                        {!redditAllPostsLoader &&
                          redditLimit < redditPostCommentsCount && (
                            <div className="social-media-loader">
                              <div className="load-more-ps">
                                <p
                                  onClick={() => {
                                    setRedditLimit((prev) => prev + 15);
                                    dispatch(
                                      getRedditWithPostLoad({
                                        companies,
                                        sort: redditSort,
                                        order: -1,
                                        limit: redditLimit + 15,
                                        offset: redditOffset,
                                      })
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  Load More Replies...
                                </p>
                              </div>
                            </div>
                          )}
                        {redditAllPostsLoader && (
                          <div className="social-media-loader">
                            <div className="loader ">
                              <div className="circle" id="circleE"></div>
                              <div className="circle" id="circleS"></div>
                              <div className="circle" id="circleG"></div>
                            </div>
                            <div className="load-more-ps select-loader">
                              <p>Load More Replies...</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MapItem = ({ color, name }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: ".6vw",
        height: ".6vw",
        borderRadius: "100%",
        marginRight: ".5vw",
        backgroundColor: color,
      }}
    ></div>
    <div style={{ fontSize: ".8rem" }}>{name}</div>
  </div>
);

export default OverviewPage;
