/* eslint-disable no-param-reassign */
import useAxios from "axios-hooks";
import React, { useEffect, useRef } from "react";
import { Graph, extend, Extensions } from "@antv/g6";
import { useTheme } from "@mui/material";
import { useResize } from "../../utils/useResize";
import store, {
  useAppDispatch,
  useAppSelector,
} from "../../redux/configuareStore";
import {
  setCenterNode,
  setEdgeIdsToSelect,
  setEdges,
  setLoadingLayout,
  setNodeIdToZoom,
  setNodes,
} from "../../redux/GraphExploration/graphSlice";
import {
  setCanRedo,
  setCanUndo,
} from "../../redux/GraphExploration/toolBoxSlice";
import {
  setTooltipLocation,
  setTooltipShow,
  setTooltipTargetEdgeId,
  setTooltipTargetNodeId,
} from "../../redux/GraphExploration/toolTipSlice";
import { FilterParametersEnum } from "../../enums/FilterSideBarEnums";
import {
  prepareIncidentCounts,
  getFilterTagIds,
  updateFilteringParameter,
  updateFilteringParameterCounts,
  getTextColorFromRiskScore,
  updateCombos,
  decodeUnicodeEscapes,
  getBadge,
} from "../../utils";
import { useGraphObject } from "../context/MyContext";
import { getToken } from "../../../../../Utils";
import {
  setRightClickMenuLocation,
  setRightClickMenuShow,
  setRightClickMenuTargetNodeId,
} from "../../redux/GraphExploration/rightClickMenuSlice";

function G6Graph({ mainCompany }) {
  const ref = useRef(null);
  const graphRef = useRef(null); // useRef with a type that allows both IGraph and null
  const componentRef = useRef(null);
  const { height, width } = useResize(componentRef);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const contextMenuContent = (e) => {
    dispatch(setRightClickMenuShow(true));
    dispatch(setRightClickMenuLocation({ x: e.client.x, y: e.client.y }));
    dispatch(setRightClickMenuTargetNodeId(e.itemId));
    return `<div />`;
  };
  const toolTipContent = (e) => {
    if (e.itemType === "edge") {
      dispatch(setTooltipShow(true));
      dispatch(
        setTooltipLocation({
          x: e.client.x,
          y: e.client.y,
        })
      );
      dispatch(setTooltipTargetEdgeId(e.itemId));
      dispatch(setTooltipTargetNodeId(undefined));
    }
    return `<div />`;
  };

  const handleAfterLayout = () => {
    dispatch(setLoadingLayout(false));
    const appState = store.getState();
    const nodeToZoomOn = appState.graph.nodeIdToZoom;
    if (graphRef?.current && nodeToZoomOn !== undefined) {
      graphRef?.current?.focusItem(nodeToZoomOn, {
        duration: 1000,
        easing: "ease-in-out",
      });
      setTimeout(() => {
        graphRef?.current?.zoomTo(3, undefined, {
          duration: 1000,
          easing: "ease-in-out",
        });
      }, 1000);
    }
    dispatch(setNodeIdToZoom(undefined));
    const edgeIdsToSelect = appState.graph.edgeIdsToSelect;
    const allEdgesIds = graphRef?.current?.getAllEdgesData().map((i) => i.id);
    if (edgeIdsToSelect.length > 0) {
      edgeIdsToSelect.forEach((id) => {
        const idParts = id.split("-");
        const reverseId = `${idParts[1]}-${idParts[0]}`;
        if (allEdgesIds.includes(id)) {
          graphRef?.current?.setItemState(id, "selected", true);
        }
        if (allEdgesIds.includes(reverseId)) {
          graphRef?.current?.setItemState(reverseId, "selected", true);
        }
      });
    }
    dispatch(setEdgeIdsToSelect([]));
  };

  const handleHistoryChange = () => {
    if (graphRef?.current) {
      dispatch(setCanUndo(graphRef.current.canUndo()));
      dispatch(setCanRedo(graphRef.current.canRedo()));
      const currentNodes = JSON.parse(
        JSON.stringify(graphRef.current?.getAllNodesData())
      ).filter((n) => !n.data?._isCombo);
      const currentEdges = JSON.parse(
        JSON.stringify(graphRef.current?.getAllEdgesData())
      );
      const currentCombos = JSON.parse(
        JSON.stringify(graphRef.current?.getAllNodesData())
      ).filter((n) => n.data?._isCombo);

      if (currentNodes.length > 1) {
        graphRef.current?.layout();
      }
      dispatch(setNodes(currentNodes));
      dispatch(setEdges(currentEdges));
      updateFilteringParameter();
      updateFilteringParameterCounts();
      updateCombos(
        currentNodes,
        currentEdges,
        currentCombos,
        graphRef?.current
      );
      const centerNodes = currentNodes.filter((n) => n.data.isCenterNode);
      if (centerNodes.length > 0) {
        dispatch(
          setCenterNode({
            id: centerNodes[0].id,
            name: centerNodes[0].data.label,
          })
        );
      }
    }
  };
  const contextMenu = {
    type: "menu",
    key: "my-context-menu",
    trigger: "contextmenu",
    getContent: contextMenuContent,
  };
  const tooltip = {
    type: "tooltip",
    key: "my-tooltip",
    trigger: "click",
    getContent: toolTipContent,
  };
  const from = useAppSelector((state) => state.timeRange.from);
  const to = useAppSelector((state) => state.timeRange.to);
  const baseUrlApi = useAppSelector((state) => state.api.baseUrlApi);
  const params = {
    ids: mainCompany.id,
    from,
    to,
  };
  const token = getToken();
  const { setGraphObject } = useGraphObject();
  const [{ data: companiesStats }] = useAxios(
    {
      url: `${baseUrlApi}/companies/stats`,
      params,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    },
    { manual: !baseUrlApi }
  );
  const mainCompanyStats = companiesStats?.find(
    (company) => `${company.id}` === `${mainCompany.id}`
  );
  const mainCompanyAttributes = {
    incidentCount: prepareIncidentCounts(mainCompanyStats?.incidentCount),
    tier: 0,
    riskScore: mainCompanyStats?.riskScore,
    industry: mainCompanyStats?.industry,
    location: mainCompanyStats?.location,
    label: decodeUnicodeEscapes(mainCompany.name),
    logo: mainCompanyStats?.logo,
  };
  useEffect(() => {
    if (
      companiesStats &&
      ref.current &&
      !graphRef?.current &&
      height > 0 &&
      width > 0
    ) {
      const ExtGraph = extend(Graph, {
        behaviors: {
          "brush-select": Extensions.BrushSelect,
          "lasso-select": Extensions.LassoSelect,
          "hover-activate": Extensions.HoverActivate,
        },
        plugins: {
          history: Extensions.History,
          menu: Extensions.Menu,
          tooltip: Extensions.Tooltip,
        },
        layouts: {
          radial: Extensions.RadialLayout,
          comboCombined: Extensions.ComboCombinedLayout,
          dagre: Extensions.DagreLayout,
        },
        edges: {
          "cubic-horizontal-edge": Extensions.CubicHorizontalEdge,
          "polyline-edge": Extensions.PolylineEdge,
          // "cubic-horizontal-edge": Extensions.CubicHorizontalEdge,
        },
      });
      const defaultBehaviors = [
        "drag-canvas",
        "zoom-canvas",
        "click-select",
        "hover-activate",
        "collapse-expand-combo",
        {
          type: "drag-node",
          enableTransient: false,
          updateComboStructure: false,
        },
        "drag-combo",
      ];
      graphRef.current = new ExtGraph({
        container: ref.current, // Make sure to pass the ref to the current DOM element
        width,
        height,
        autoFit: "center",
        nodeState: {
          selected: {
            keyShape: {
              stroke: "#0195CE",
              lineWidth: 3,
            },
          },
        },
        edgeState: {
          selected: {
            keyShape: {
              stroke: "#0195CE",
              lineWidth: 3,
            },
          },
        },
        data: {
          edges: [],
          nodes: [
            {
              id: mainCompany.id,
              data: {
                isCenterNode: true,
                ...mainCompanyAttributes,
                filterTagIds: getFilterTagIds(mainCompanyAttributes),
                badge: getBadge(mainCompanyAttributes),
              },
            },
          ],
          combos: [
            {
              id: "supplier",
              data: {
                label: "Supplier",
                visible: false,
              },
            },
          ],
        },
        modes: {
          default: defaultBehaviors,
          brushSelect: [...defaultBehaviors, "brush-select"],
          lassoSelect: [...defaultBehaviors, "lasso-select"],
        },
        node: (innerModel) => {
          const iconAddress = innerModel.data?.logo?.url;
          const badgeShapes = [];
          const badge = innerModel.data.badge;
          const score = innerModel.data.riskScore;
          let color = "black";
          if (
            badge &&
            ((typeof badge === "number" && badge > 0) ||
              typeof badge === "string")
          ) {
            color = getTextColorFromRiskScore(score);
            badgeShapes.push({
              text: `${badge}`,
              textAlign: "center",
              color,
              position: "rightTop",
              fontWeight: 500,
            });
          }
          const { tier } = innerModel.data;
          if (tier > 0) {
            badgeShapes.push({
              text: `T-${tier}`,
              textAlign: "center",
              color: "white",
              textColor: "black",
              position: "bottomCenter",
              fontWeight: 500,
            });
          }
          const keyShape = {
            fill: "#fff",
            shadowType: "outer",
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0,0.2)",
            cursor: "pointer",
          };
          if (innerModel.data.isCenterNode) {
            keyShape.r = 40;
          }
          return {
            ...innerModel,
            // type: 'circle-node',
            data: {
              ...innerModel.data,
              keyShape,
              labelShape: {
                text: innerModel.data.label,
                fill: theme.palette.gray[700],
                fontWeight: 500,
                fontFamily: "Inter",
                offsetY: 8,
              },
              iconShape: iconAddress
                ? {
                    src: iconAddress,
                    fontSize: innerModel.data.isCenterNode ? 50 : 15,
                  }
                : undefined,
              animates: {
                buildIn: [
                  {
                    fields: ["size", "opacity"],
                    shapeId: "group",
                    duration: 500,
                  },
                ],
                buildOut: [
                  {
                    fields: ["opacity"],
                    duration: 200,
                  },
                ],
                update: [
                  {
                    fields: ["x", "y"],
                  },
                  {
                    fields: ["opacity"],
                    shapeId: "haloShape",
                    states: ["selected", "active"],
                    duration: 1000,
                  },
                  {
                    fields: ["lineWidth"],
                    shapeId: "keyShape",
                    states: ["selected", "active"],
                    duration: 1000,
                  },
                ],
                show: [
                  {
                    fields: ["opacity"],
                    shapeId: "keyShape",
                    duration: 300,
                  },
                ],
                hide: [
                  {
                    fields: ["opacity"],
                    shapeId: "keyShape",
                    duration: 300,
                  },
                  {
                    fields: ["opacity"],
                    shapeId: "labelShape",
                    duration: 300,
                  },
                ],
              },
              badgeShapes,
              haloShape: {
                stroke: "#5CBBE0",
              },
              labelBackgroundShape: {
                fill: "transparent",
              },
              preventPolylineEdgeOverlap: true,
            },
          };
        },
        edge: (innerModel) => ({
          ...innerModel,
          // type: "line-edge",
          data: {
            // type: "cubic-horizontal-edge",
            // type: "polyline-edge",
            type: innerModel.data.type, // "line-edge",
            visible: false,
            labelShape: {
              text: innerModel.data.shortLabel,
              autoRotate: false,
              fill: theme.palette.gray[300],
              fontFamily: "Inter",
              fontWeight: 500,
              cursor: "pointer",
            },
            labelBackgroundShape: {
              fill: theme.palette.gray.A100,
              opacity: 1,
              padding: 6,
              cursor: "pointer",
            },
            animates: {
              buildIn: [
                {
                  fields: ["opacity"],
                  shapeId: "keyShape",
                  duration: 500,
                },
              ],
              buildOut: [
                {
                  fields: ["opacity"],
                  shapeId: "keyShape",
                  duration: 200,
                },
              ],
              update: [
                {
                  fields: ["opacity"],
                  shapeId: "haloShape",
                  states: ["selected", "active"],
                },
                {
                  fields: ["lineWidth"],
                  shapeId: "keyShape",
                  states: ["selected", "active"],
                },
              ],
              show: [
                {
                  fields: ["opacity"],
                  shapeId: "keyShape",
                  duration: 300,
                },
              ],
              hide: [
                {
                  fields: ["opacity"],
                  shapeId: "keyShape",
                  duration: 300,
                },
              ],
            },
            keyShape: {
              endArrow:
                innerModel.data.shortLabel === FilterParametersEnum.PARTNER
                  ? false
                  : {
                      type: "triangle",
                      width: 4,
                      height: 4,
                    },
            },
          },
        }),
        combo: (innerModel) => ({
          id: innerModel.id,
          data: {
            ...innerModel.data,
            type: "rect-combo",
            keyShape: {
              padding: [10, 20, 30, 40],
              width: innerModel.data.visible ? 50 : 0,
              height: innerModel.data.visible ? 50 : 0,
              fill: "transparent",
            },
            labelShape: {
              text: innerModel.data.visible ? innerModel.data.label : undefined,
            },
          },
        }),
        layout: {
          type: "comboCombined",
          comboPadding: 0,
          workerEnabled: true,
          // outerLayout: new Extensions.ForceLayout({
          //   nodeStrength: 10,
          //   edgeStrength: 10,
          //   preventOverlap: true,
          // }),
          innerLayout: new Extensions.GridLayout({
            preventOverlap: true,
            nodeSize: 70,
          }),
        },
        stackCfg: {
          ignoreDisplayChange: true,
          ignoreLayerChange: true,
          ignoreStateChange: true,
          ignoreUpdate: true,
          stackActive: true,
        },
        renderer: "canvas",
      });
      handleHistoryChange();
      graphRef.current?.on("history:change", () => {
        handleHistoryChange();
      });
      graphRef.current?.on("beforelayout", (e) => {
        dispatch(setLoadingLayout(true));
      });
      graphRef.current?.on("afterlayout", (e) => {
        handleAfterLayout();
        // dispatch(setLoadingLayout(false));
      });
      graphRef.current?.on("canvas:click", (e) => {
        // Deselect all nodes
        graphRef.current
          ?.getAllNodesData()
          .filter((n) => !n.data?._isCombo)
          .forEach((n) => {
            graphRef.current?.setItemState(n.id, "selected", false);
          });
        // Deselect all edges
        graphRef.current?.getAllEdgesData().forEach((n) => {
          graphRef.current?.setItemState(n.id, "selected", false);
        });
      });
      setGraphObject(graphRef.current);
      graphRef.current.addPlugins([contextMenu, tooltip]);
    }
    return () => {};
  }, [
    handleAfterLayout,
    handleHistoryChange,
    dispatch,
    height,
    width,
    contextMenu,
    tooltip,
    companiesStats,
    mainCompany,
  ]);

  useEffect(() => {
    if (graphRef?.current && width > 0 && height > 0) {
      graphRef.current.setSize([width, height]);
    }
  }, [height, width]);
  const showFilterSideBar = useAppSelector(
    (state) => state.filterSideBar.showFilterSideBar
  );
  return (
    <div
      style={{
        height: "100%",
        background: theme.palette.gray.A100,
        userSelect: "none" /* Standard syntax */,
        filter: showFilterSideBar ? "opacity(0.6)" : "opacity(1)",
        transition: "filter 0.3s ease-in-out",
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
      ref={componentRef}
    >
      <div
        ref={ref}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      />
    </div>
  );
}
export default G6Graph;
