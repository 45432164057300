import { useTheme, Typography, Box } from "@mui/material";

export default function CheckBoxLabel({ label, count }) {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="body" sx={{ color: theme.palette.gray["900"] }}>
        {label}
      </Typography>
      {count && (
        <Typography
          variant="body"
          sx={{
            color: theme.palette.gray["300"],
            marginLeft: 1,
          }}
        >
          ({count})
        </Typography>
      )}
    </Box>
  );
}
