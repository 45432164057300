import Images from "../../Themes/ImageConstant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Spin, Tabs } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import PortfolioCard from "../portfolio/components/PortfolioCard";
import SearchMain from "../../Componets/SearchMain";
import { dispatch, navigate } from "../../Utils/dispatchNavigate";
import { useSelector } from "react-redux";
import {
  incindentAction,
  searchByParamsAction,
} from "../../Redux/Action/incidents";
import { getPortfolioListLoad } from "../../Redux/Action/portfolio";
import { getWishlistAction } from "../../Redux/Action/watchListActions";
import moment from "moment";
import {
  getDashboardAction,
  getDashboardWatchlistAction,
} from "../../Redux/Action/incidentAnalyzerAction";
import IncidentsListModal from "../IncidentsListModal";
import { dashboardSearchAction } from "../../Redux/Action/dashboard";
import { debounce } from "../../Utils";

const DashboardNew = (props) => {
  const portfolioList = useSelector((state) => state?.portfolio?.portfolioList);
  const watchList = useSelector((state) => state?.watchListReducer?.watchlist);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [networkData, setNetworkData] = useState({
    companies: [],
    continent_codes: [],
    countries: [],
    end: "",
    esg_PAI: [],
    esg_SDG: [],
    esg_categories: [],
    esg_factors: [],
    industries: [],
    limit: 25,
    locations: [],
    offset: 0,
    search: "",
    start: "",
  });
  const [modal2Open, setModal2Open] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const searchData = useSelector(
    (state) => state?.incidentReducer?.searchByParams
  );
  const { dashBoardCompanySearchList } = useSelector(
    (state) => state?.dashboardReducer
  );

  const { incident } = useSelector((state) => state?.incidentReducer);

  const {
    dashboardData,
    dashboardPORTFOLIOData,
    dashboardCompanies,
    dashboardPORTFOLIOCompanies,
    holdings,
    watchlistUpdatesHoldings
  } = useSelector((state) => state.incidentAnalyzerReducer);

  useLayoutEffect(() => {
    dispatch(getPortfolioListLoad());
    dispatch(getWishlistAction());
    dispatch(
      incindentAction({
        sort: "discovery_time",
        order: -1,
        limit: 5,
        offset: 0,
        withImageKey: true,
        isPortfolio: true,
        last24: true,
      })
    );
    dispatch(getDashboardAction());
    dispatch(getDashboardWatchlistAction());
  }, []);

  useLayoutEffect(() => {
    dispatch(
      incindentAction({
        sort: "discovery_time",
        order: -1,
        limit: 5,
        offset: 0,
        withImageKey: true,
        isPortfolio: activeTab == 1 ? true : false,
        isWatchlist: activeTab == 2 ? true : false,
        last24: true,
      })
    );
  }, [activeTab]);

  const searchComponent = () => {
    return (
      <>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              minWidth: 0,
              flexDirection: "row",
            }}
          >
            <input
              type="text"
              onstart
              autoComplete="off"
              placeholder="Search for companies"
              name="search2"
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
                debounce(() => {
                  dispatch(dashboardSearchAction({ search: e?.target?.value }));
                });
              }}
            />
            <div
              className="topheader-h1-hv"
              onClick={() => {
                if (searchVal != "") {
                  setSearchVal("");
                  setIsSearched(true);
                  // handleNetworkCall(2);
                }
              }}
            >
              <div className="topheader-h1-hv search-bag-color ">
                <img src={Images.searchWhite} />
              </div>
            </div>
          </div>
          {searchVal.length > 0 ? (
            <div className="search-modal-dgn-hv">
              {dashBoardCompanySearchList?.countries?.map((item, index) => {
                return (
                  <Row
                    style={{ padding: 12 }}
                    align={"middle"}
                    justify={"space-between"}
                    key={index}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={(e) => {
                          setSearchVal("");
                          navigate("/companyPage", { state: { id: item.id } });
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          style={{ height: "24px", width: "24px", objectFit: "contain" }}
                          src={`https://logos-svg.komo.systems/${item?.id}.png`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = Images.hotel
                          }}
                        />
                        <div
                          style={{
                            marginLeft: "5px",
                          }}
                        >
                          {item?.name}
                        </div>
                      </div>
                    </div>
                  </Row>
                );
              })}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const HighIncident = () => {
    return (
      <>
        {incident?.data?.map((item, index) => {
          return (
            <div className="involved-companies">
              {item.image ? (
                <div className="comp-apple-image">
                  <img src={item.image} style={{ height: 96, width: 96 }} />
                </div>
              ) : null}
              <div className="discription" style={{ width: "100%" }}>
                <div className="message-timing">
                  <p>
                    Involved companies:{" "}
                    <span>
                      {item?.affected_companies?.map((x, i) => {
                        return i !== item?.affected_companies.length - 1
                          ? x.name + ", "
                          : x.name;
                      })}{" "}
                    </span>
                  </p>
                  <label>{moment(item.discovery_time).fromNow()}</label>
                </div>
                <p
                  onClick={() =>
                    window.open(`/incidentdetails?${item.id}`, "_blank")
                  }
                  className="comp-dis-message"
                >
                  {item.title}
                </p>

                <div className="chart-user-ps">
                  {item?.portfilioData?.map((portfolio, itemPort) => {
                    return (
                      <p>
                        {" "}
                        <span>
                          <img src={Images.chartUser} />
                        </span>{" "}
                        {portfolio.name}
                      </p>
                    )
                  })}
                </div>
                <div className="chart-user-ps">
                  {item?.watchlistData?.map((portfolio, itemPort) => {
                    return (
                      <p>
                        {" "}
                        <span>
                          <img src={Images.chartUser} />
                        </span>{" "}
                        {portfolio?.name || ""}
                      </p>
                    )
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="side-padding ">
      <div className="dashboard-container">
        <div className="dash-board">
          <div className="dash-bord-heading">
            <h2>Dashboard</h2>

            <div className="dash-search">
              <form className="example search-example">
                {searchComponent()}
              </form>
            </div>
            <div className="my-portifolios">
              <Tabs
                onChange={(e) => setActiveTab(e)}
                activeKey={activeTab}
                className="tab-section-ps"
              >
                <Tabs.TabPane key="1" tab={"Portfolio Updates"}>
                  <div className="update-prof-ps ">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className=" up-prof-left-side">
                          <div className="data-update-pro">
                            <h4>Updated Portfolios</h4>
                            {portfolioList.length > 2 && (
                              <button onClick={() => navigate("/portfolio")}>
                                View All
                              </button>
                            )}
                          </div>
                          <div className="map-portfolio">
                            {portfolioList && portfolioList.length
                              ? portfolioList.map((item, index) => {
                                if (index < 2) {
                                  return (
                                    <PortfolioCard
                                      item={item}
                                      name={"My Portfolio"}
                                    />
                                  );
                                }
                              })
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-8 ">
                        <div className="portfolio-over-view-sec">
                          <div className="top-tabs-sec-ps">
                            <div className="over-view-heading">
                              <Link to="/searchdashboardpage">
                                {" "}
                                <h5>
                                  Portfolio Overview{" "}
                                  <span>
                                    <img src={Images.circleInfo} />
                                  </span>
                                </h5>{" "}
                              </Link>
                              <p>Updates for: Last 24 hours</p>
                            </div>
                            <div className="tabs-over-view">
                              <div className="left-side-tab">
                                <div
                                  className="tab-box"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: ["Environmental"],
                                      severity_levels: [],
                                      companies:
                                        holdings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        holdings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.environmentTab} />
                                    </span>{" "}
                                    Environment
                                  </h6>
                                  <p>{dashboardData?.Environmental || 0}</p>
                                </div>

                                <div
                                  className="tab-box social"
                                  onClick={() => {
                                    setModalVisible(true);

                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: ["Social"],
                                      severity_levels: [],
                                      companies:
                                        holdings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        holdings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.socialTab} />
                                    </span>{" "}
                                    Social
                                  </h6>
                                  <p>{dashboardData?.Social || 0}</p>
                                </div>
                                <div
                                  className="tab-box governance"
                                  onClick={() => {
                                    setModalVisible(true);

                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: ["Governance"],
                                      severity_levels: [],
                                      companies:
                                        holdings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        holdings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.governanceTab} />
                                    </span>{" "}
                                    Governance
                                  </h6>
                                  <p>{dashboardData?.Governance || 0}</p>
                                </div>
                              </div>
                              <div className="right-side-tab">
                                <div
                                  className="tab-box low-severity"
                                  onClick={() => {
                                    setModalVisible(true);

                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: [],
                                      severity_levels: ["High"],
                                      companies:
                                        holdings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        holdings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.heighSeverityTab} />
                                    </span>{" "}
                                    High Severity
                                  </h6>
                                  <p style={{ color: "#720000" }}>{dashboardData?.High || 0}</p>
                                </div>

                                <div
                                  className="tab-box medium"
                                  onClick={() => {
                                    setModalVisible(true);

                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: [],
                                      severity_levels: ["Moderate"],
                                      companies:
                                        holdings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        holdings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.mediumSeverityTab} />
                                    </span>{" "}
                                    Moderate Severity
                                  </h6>
                                  <p>{dashboardData?.Moderate || 0}</p>
                                </div>

                                <div
                                  className="tab-box low-severity"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: [],
                                      severity_levels: ["Minor"],
                                      companies:
                                        holdings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        holdings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.lowSeverityTab} />
                                    </span>{" "}
                                    Minor Severity
                                  </h6>
                                  <p>{dashboardData?.Minor || 0}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="high-severity-incidents">
                            <div className="high-sev-top">
                              <h5>High Severity Incidents</h5>
                              <div className="last-login">
                                <p>
                                  All severe incidents from your last 24 hours:{" "}
                                  <span>
                                    {incident?.pagination?.totalCount}
                                  </span>
                                </p>
                                {incident?.pagination?.totalCount > 5 && (
                                  <button
                                    onClick={() => {
                                      setModalVisible(true);
                                      setNetworkData((prevData) => ({
                                        ...prevData,
                                        last24: true,
                                        isPortfolio: activeTab == 1 ? true : false,
                                        isWatchlist: activeTab == 2 ? true : false,
                                      }));
                                    }}
                                  >
                                    View All
                                    <span>
                                      <img src={Images.iconser} />
                                    </span>{" "}
                                  </button>
                                )}
                              </div>
                            </div>

                            <div className="side-sapacing-box">
                              <HighIncident />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane key="2" tab={"Watchlist Updates"}>
                  <div className="update-prof-ps ">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className=" up-prof-left-side">
                          <div className="up-date-text-ps">
                            <h4>Updated Watchlist</h4>
                          </div>
                          {/* <div className="sub-text-port">
                            <p>You don't have any watchlists yet</p>
                          </div> */}
                          {watchList &&
                            watchList.length > 0 &&
                            watchList.map((item, index) => {
                              if (index < 4) {
                                return (
                                  <div className="watchlist-left-side-box">
                                    <h4>
                                      {" "}
                                      {item?.name || ""}{" "}
                                      <span>
                                        <img
                                          style={{ cursor: "pointer" }}
                                          src={Images.editTextIcon}
                                          onClick={() => navigate("/watchlist")}
                                        />
                                      </span>
                                    </h4>
                                    <p>
                                      {item?.companyCount || 0} companies,{" "}
                                      {" " + item?.locationCount || 0}{" "}
                                      locations,
                                      {" " + item?.industryCount} industries
                                    </p>
                                  </div>
                                );
                              }
                            })}

                          {/* <div className="watchlist-left-side-box">
                            <h4> Watchlist 2 <span><img src={Images.editTextIcon} /></span></h4>
                            <p>11 companies, 2 locations, 9 categories
                              3 industries</p>
                          </div>

                          <div className="watchlist-left-side-box">
                            <h4> Watchlist 3 <span><img src={Images.editTextIcon} /></span></h4>
                            <p>11 companies, 2 locations, 9 categories
                              3 industries</p>
                          </div>

                          <div className="watchlist-left-side-box">
                            <h4> Watchlist 4 <span><img src={Images.editTextIcon} /></span></h4>
                            <p>11 companies, 2 locations, 9 categories
                              3 industries</p>
                          </div> */}
                        </div>
                      </div>
                      <div className=" col-lg-8 ">
                        {/* 
                        <div className="row watchlist-right-side" >

                          <div className="col-lg-5">
                            <div className="das-right-graph">
                              <img src={Images.worldGraph} />
                            </div>
                          </div>


                          <div className="col-lg-7">
                            <div className="watch-list-heading-ps">
                              <h5>Watchlist</h5>
                              <div className="port-rules">
                                <ul>
                                  <li>< span><img src={Images.bullets} /></span> Add companies, industries, locations and ESG factors </li>
                                  <li> <span><img src={Images.bullets} /></span> Define notification policy</li>
                                  <li> <span><img src={Images.bullets} /></span> Monitor any change regularly</li>
                                </ul>
                              </div>
                              <div className="watchlist-create-ps">
                                <button>Create Watchlist</button>

                              </div>

                              <div className="watchlist-left-side-box">
                                <h4> Watchlist 2 <span><img src={Images.editTextIcon} /></span></h4>
                                <p>11 companies, 2 locations, 9 categories
                                  3 industries</p>
                              </div>

                              <div className="watchlist-left-side-box">
                                <h4> Watchlist 3 <span><img src={Images.editTextIcon} /></span></h4>
                                <p>11 companies, 2 locations, 9 categories
                                  3 industries</p>
                              </div>

                              <div className="watchlist-left-side-box">
                                <h4> Watchlist 4 <span><img src={Images.editTextIcon} /></span></h4>
                                <p>11 companies, 2 locations, 9 categories
                                  3 industries</p>
                              </div>


                            </div>
                          </div>



                        </div>

                        <div className="row supply-chain-right-side mt-4 " >
                          <div className="col-lg-7">
                            <div className="supply-ch-heading">
                              <h5>Supply Chain</h5>
                              <div className="supply-text-ps">
                                <p>Non soluta dolor explicabo architecto nobis voluptatem. Illo cum dignissimos corporis tenetur voluptates debitis</p>
                              </div>
                              <div className="take-look">
                                <button>Let’s take a look</button>

                              </div>
                            </div>
                          </div>

                          <div className="col-lg-5">
                            <div className="das-right-graph">
                              <img src={Images.supplyGraph} />
                            </div>
                          </div>
                        </div> */}

                        <div className="portfolio-over-view-sec">
                          <div className="top-tabs-sec-ps">
                            <div className="over-view-heading">
                              <h5>
                                {" "}
                                Watchlist Overview{" "}
                                <span>
                                  <img src={Images.circleInfo} />
                                </span>
                              </h5>
                              <p>Updates for: Last 24 hours</p>
                            </div>
                            <div className="tabs-over-view">
                              <div className="left-side-tab">
                                <div
                                  className="tab-box"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: ["Environmental"],
                                      severity_levels: [],
                                      companies:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.environmentTab} />
                                    </span>{" "}
                                    Environment
                                  </h6>
                                  <p>
                                    {dashboardPORTFOLIOData?.Environmental || 0}
                                  </p>
                                </div>

                                <div
                                  className="tab-box social"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: ["Social"],
                                      severity_levels: [],
                                      companies:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.socialTab} />
                                    </span>{" "}
                                    Social
                                  </h6>
                                  <p>{dashboardPORTFOLIOData?.Social || 0}</p>
                                </div>
                                <div
                                  className="tab-box governance"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      esg_categories: ["Governance"],
                                      severity_levels: [],
                                      companies:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.governanceTab} />
                                    </span>{" "}
                                    Governance
                                  </h6>
                                  <p>
                                    {dashboardPORTFOLIOData?.Governance || 0}
                                  </p>
                                </div>
                              </div>

                              <div className="right-side-tab">
                                <div
                                  className="tab-box low-severity"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      severity_levels: ["High"],
                                      esg_categories: [],
                                      last24: true,
                                      companies:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.heighSeverityTab} />
                                    </span>{" "}
                                    High Severity
                                  </h6>
                                  <p style={{ color: "#720000" }}>{dashboardPORTFOLIOData?.High || 0}</p>
                                </div>

                                <div
                                  className="tab-box medium"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      severity_levels: ["Moderate"],
                                      esg_categories: [],
                                      companies:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.mediumSeverityTab} />
                                    </span>{" "}
                                    Moderate Severity
                                  </h6>
                                  <p>{dashboardPORTFOLIOData?.Moderate || 0}</p>
                                </div>

                                <div
                                  className="tab-box low-severity"
                                  onClick={() => {
                                    setModalVisible(true);
                                    setNetworkData((prevData) => ({
                                      ...prevData,
                                      last24: true,
                                      isPortfolio: false,
                                      isWatchlist: false,
                                      severity_levels: ["Minor"],
                                      esg_categories: [],
                                      companies:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.id
                                        ) || [],
                                      companyName:
                                        watchlistUpdatesHoldings?.map(
                                          (item) => item.company
                                        ) || [],
                                    }));
                                  }}
                                >
                                  <h6>
                                    <span>
                                      <img src={Images.lowSeverityTab} />
                                    </span>{" "}
                                    Minor Severity
                                  </h6>
                                  <p>{dashboardPORTFOLIOData?.Minor || 0}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="high-severity-incidents">
                            <div className="high-sev-top">
                              <h5>High Severity Incidents</h5>
                              <div className="last-login">
                                <p>
                                  All severe incidents from your last login:{" "}
                                  <span>
                                    {incident?.pagination?.totalCount}
                                  </span>
                                </p>
                                {incident?.pagination?.totalCount > 5 && (
                                  <button
                                    onClick={() => {
                                      setModalVisible(true);
                                      setNetworkData((prevData) => ({
                                        ...prevData,
                                        last24: true,
                                        isPortfolio: activeTab == 1 ? true : false,
                                        isWatchlist:
                                          activeTab == 2 ? true : false,
                                      }));
                                    }}
                                  >
                                    View All{" "}
                                    <span>
                                      <img src={Images.iconser} />
                                    </span>{" "}
                                  </button>
                                )}
                              </div>
                            </div>

                            <div className="side-sapacing-box">
                              <HighIncident />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {modalVisible ? (
        <IncidentsListModal
          setModalVisible={setModalVisible}
          networkData={networkData}
        />
      ) : null}
      {modal2Open && (
        <SearchMain
          isSearched={isSearched}
          setIsSearched={setIsSearched}
          modal2Open={modal2Open}
          setModal2Open={setModal2Open}
        />
      )}
    </div>
  );
};

export default DashboardNew;
