import GoogleMapReact from "google-map-react";
import React, { memo, useState } from "react";
import mapOptions from "../../Utils/mapOptions";
import ImageConst from "../../Themes/ImageConstant";
import { Modal, Popover, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import BubleIncidentsList from "../../Screens/bubleIncidentsList";
const { myOptions, defaultProps } = mapOptions();

let incidentIdArray = [];

const WorldMapIncidents = ({
  width = "200%",
  height = "200%",
  options,
  marker,
  zoom = defaultProps.zoom,
  maxWidth = width,
}) => {
  let currectLocation = useLocation();
  currectLocation = currectLocation.pathname;
  const [indidentListModal, setIndidentListModal] = useState(false);
  const collectedListOfHoverPop = (list) => {
    incidentIdArray = list.map((val) => val?.id);
    setIndidentListModal(true);
  };

  const Marker = (data) => {
    const markerStyle = {
      display: "flex",
      width: `${
        data.incidentLength < 5 ? 2 : data.incidentLength < 10 ? 3 : 4.5
      }vw`,
      height: `${
        data.incidentLength < 5 ? 2 : data.incidentLength < 10 ? 3 : 4.5
      }vw`,
      borderRadius: "2vw",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor:
        data.val === "Environmental"
          ? "#6EBD20"
          : data.val === "Social"
          ? "#2191FB"
          : data.val === "Governance"
          ? "#653DD4"
          : data.val === "High"
          ? "#D00000"
          : data.val === "Medium"
          ? "#F28C28"
          : data.val === "Low"
          ? "#FFC000"
          : null,
    };

    const imageSrc =
      data.val === "Environmental" || data.val === "Low"
        ? ImageConst.enviromentIcon
        : data.val === "Social"
        ? ImageConst.socialIcon
        : data.val === "Governance" || data.val === "High"
        ? ImageConst.governaceIcon
        : data.val === "Medium"
        ? ImageConst.socialIcon
        : null;

    const incidentNavigate = (id) => {
      navigate("/incidentdetails", {
        state: { id: id },
      });
    };

    const formatDate = (dateString) => {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = new Date(dateString);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${month} ${day}, ${year}`;
    };
    const navigate = useNavigate();
    return (
      <Popover
        trigger="hover"
        content={
          currectLocation !== "/home" ? (
            <div className="popup-save" style={{ width: "240px" }}>
              {data?.incidentList.map((v, i) => {
                return (
                  <>
                    {i == 0 && (
                      <div
                        className="title-save"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "15px",
                        }}
                      >
                        <span style={{ fontSize: 13, fontFamily: "inter" }}>
                          {formatDate(data?.incidentList[0]["discovery_time"])}
                        </span>
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: 13,
                            fontFamily: "inter",
                            textAlign: "right",
                            color: "#0195CE",
                            fontWeight: 700,
                          }}
                          onClick={() =>
                            data?.incidentList?.length > 1
                              ? collectedListOfHoverPop(data?.incidentList)
                              : incidentNavigate(data?.incidentList[0]?.id)
                          }
                        >
                          {data?.incidentList?.length > 1
                            ? "Go to the List"
                            : "Go to Details"}
                        </span>
                      </div>
                    )}

                    <div
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                      }}
                      onClick={() => incidentNavigate(v.id)}
                    >
                      <img
                        style={{
                          width: 10,
                          height: 10,
                          marginTop: 6,
                          marginRight: 6,
                        }}
                        src={
                          v.severity === "HIGH"
                            ? ImageConst.redBullet
                            : v.severity === "MEDIUM"
                            ? ImageConst.orangeBullet
                            : ImageConst.yellowBullet
                        }
                      />
                      <span className="mapIncidentItem">{v.title}</span>
                    </div>
                  </>
                );
              })}
            </div>
          ) : null
        }
        title=""
      >
        <div style={markerStyle}>
          <img style={{ width: "50%", height: "50%" }} src={imageSrc} />
        </div>
      </Popover>
    );
  };
  return (
    <div style={{ height: height, width: width, maxWidth: maxWidth }}>
      <GoogleMapReact
         bootstrapURLKeys={{ key: process.env.REACT_APP_SECRET_KEY }}
        center={defaultProps.center}
        options={{ ...myOptions, styles: options }}
        defaultCenter={defaultProps.center}
        defaultZoom={zoom}
      >
        {marker?.map((placen, index) => {
          return placen?.map((value) => {
            return (
              <Marker
                lat={value?.lat}
                lng={value?.lng}
                val={value?.name}
                incidentList={value?.incidentList}
                incidentLength={value?.incidentList?.length}
              />
            );
          });
        })}
      </GoogleMapReact>

      {indidentListModal ? (
        <Modal
          className="incidentModal"
          open={indidentListModal}
          onCancel={() => setIndidentListModal(false)}
          footer={null}
          width={1600}
          centered
        >
          <BubleIncidentsList
            bubbleData={true}
            incidentIdlist={incidentIdArray}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default memo(WorldMapIncidents);
