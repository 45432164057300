import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { BarMapping } from "../../Utils";
import IncidentsListModal from "../../Screens/IncidentsListModal";
import { useSelector } from "react-redux";

function PrincipleBarChat({ series, networkData }) {
  const [showModal, setShowModal] = useState(false);
  const [principleData, setPrincipleData] = useState([]);

  const dummyPrincipleBarGraphOptions = {
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          dataPointSelection: (event, chartContext, selectedInfo) => {
            if (
              !document.querySelector("div.ant-modal-wrap.ant-modal-centered")
            ) {
              // check if any modal not already open

              selectedInfo.dataPointIndex &&
                setPrincipleData([
                  `Principle ${selectedInfo.dataPointIndex + 1}`,
                ]);

              setShowModal(true);
            }
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      yaxis: {
        tickAmount: 6,
      },

      legend: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityTo: 0.6,
          opacityFrom: 1,
          stops: [0, 90, 100],
          shadeIntensity: 0.1,
        },
      },
      grid: {
        borderColor: "#E7E9F0",
        strokeDashArray: 10,
        padding: {
          right: 25,
          left: 15,
        },
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <>
      <ReactApexChart
        options={dummyPrincipleBarGraphOptions.options}
        series={series}
        type="bar"
      />
      {showModal && (
        <IncidentsListModal
          setModalVisible={setShowModal}
          networkData={{ ...networkData, UNGCP_Breaches: principleData }}
        />
      )}
    </>
  );
}

export default PrincipleBarChat;
