import { Row } from "antd";
import { ChartHeaderTimeRow } from "../../Screens/dashboardPage/chart/ChartHeaderTimeRow";
import Images from "../../Themes/ImageConstant"
export const ChartCard = ({
  children,
  legend,
  title,
  disablePadding,
  childClass,
  className,
  showTimeBarOnTop = true,
  showInfoIcon = true,
  subtitle = false
}) => {
  return (
    <section>
      <div className={`container most-sec-over g-0 ${className} `}>
        <div className="row">
          <div className="col">
            <div className="caot-sec1">
              <div className="caot-sec">
                <div className="over-time">
                  {showInfoIcon ?
                    <h5>
                      <h4 className="chart-cart-head-title">{title}<img src={Images.circleInfo} className="info-icon-company-page" /></h4>
                    </h5>
                    :
                    <h5>{title}</h5>
                  }

                </div>
                <div className="ellips">
                  {legend?.map((data) => {
                    return (
                      <p className="legend">
                        <span
                          className="legendCircle"
                          style={{ background: data.color }}
                        />
                        {data.tittle}
                      </p>
                    );
                  })}
                </div>
              </div>
              {subtitle && <span className="headerSubTitle mt-3">{subtitle}</span> }
            </div>
          </div>

          <div

          >
            {showTimeBarOnTop && <ChartHeaderTimeRow />}
            {children}
          </div>

        </div>
      </div>
    </section>
  );
};
