import React from "react";
import ReactEcharts from "echarts-for-react";
const socialMediaCoverage = {
  posts: "Posts",
  reposts: "Reposts",
  users: "Users",
  likes: "Likes",
  replies: "Replies",
};
const companyObj = {
  "incident Number": "Incident Number",
  risk: "Risk",
  "neg Sentiment": "Neg Sentiment",
  severity: "Severity",
};
const socialMediaCoverageLabel = {
  text: "Posts",
  text: "Reposts",
  text: "Users",
  text: "Likes",
  text: "Replies",
};
const companyObjLabel = {
  text: "Incident Number",
  text: "Severity",
  text: "Neg Sentiment",
  text: "Risk",
};

const RadarChart = ({ data, labelObj, path }) => {
  const labels = (_data) => {
    if (_data !== null) {
      delete _data?._id;
      const res = Object.entries(_data).map((val) => ({
        text: labelObj[val[0]],
      }));
      return res;
    } else {
      if (path !== "socialMediaCoverage") {
        return companyObjLabel;
      } else {
        return socialMediaCoverageLabel;
      }
    }
  };
  const option = {
    radar: [
      {
        indicator: data
          ? labels(data)
          : path == "socialMediaCoverage"
            ? socialMediaCoverageLabel
            : companyObjLabel,
        center: ["50%", "50%"],
        radius: 120,
        startAngle: 90,
        splitNumber: 4,
        shape: "circle",
        axisName: {
          formatter: "{value}",
          color: "#4E515C",
        },
        splitArea: {
          // areaStyle: {
          //   color: ["#E4E6ED", "#D2D7E5", "#E4E6ED", "#D2D7E5"],
          //   shadowColor: "rgba(0, 0, 0, 0.2)",
          //   shadowBlur: 10,
          // },
        },
        axisLine: {
          lineStyle: {
            color: "#fff",
            width: 2,
          },
        },
        splitLine: {
          // lineStyle: {
          //   color: "transparent",
          // },
        },
      },
    ],
    series: [
      {
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        data: [
          {
            value: data ? Object.values(data) : [],
            name: "Data A",
            areaStyle: {
              color: "rgba(255, 228, 52, 0.6)",
            },
            lineStyle: {
              color: "rgba(255, 228, 52, 0.6)",
            },
            itemStyle: {
              color: "rgba(255, 228, 52, 0.6)",
            },
          },
        ],
        label: {
          show: true,
          position: "bottom",
          formatter: function (params) {
            return params.value;
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        height: "400px",
        width: path == "socialMediaCoverage" ? "100%" : "358px",
      }}
    >
      <ReactEcharts option={option} style={{ height: "100%", width: "100%" }} />
    </div>
  );
};

export default RadarChart;
