import React from "react";
import style from "../style.module.css";
import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";

const HeaderComponent = ({ name,subHeader,screen, breadCrumbColor }) => {
  // const item = usePortfolioCardColor(id);
  const navigate = useNavigate()
  return (
    <div  style={{height:44, zIndex:99999}} className={breadCrumbColor == '#fff' ? "white_parent" : "parent_className"}>
         <Breadcrumb>
              <Breadcrumb.Item
                key="1"
                className={`${subHeader?"main-link":"screen-link"} ${breadCrumbColor == '#fff' ? "white_main_link" : "grey_main_link"} ` }
                // style={{ color: breadCrumbColor == '#fff' ? "#fff!important" : "#9a9ca2" }}
                onClick={()=>subHeader && navigate(screen)}
              >
              {name}
              </Breadcrumb.Item>
              {subHeader&&
              <Breadcrumb.Item key="2" className="screen-link">
                <div style={{fontWeight : "600", color: breadCrumbColor == '#fff'? breadCrumbColor : '#2D303A'}}>
              {subHeader}
              </div>
              </Breadcrumb.Item>
}
            </Breadcrumb>
    </div>
  );
};

export default HeaderComponent;
