import CustomSlider from "../CustomSlider";

export const SliderTile = ({ label }) => {
  return (
    <div className="slider-low" style={{ backgroundColor: "#ffffff" }}>
      <div className="ghc-emi">
        <p>{label}</p>
      </div>
      <div className="custom-slider">
        <p className="slider-lables">Low</p>
        <CustomSlider />
        <p className="slider-lables">High</p>
      </div>
    </div>
  );
};
