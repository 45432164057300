import React, { useEffect, useState } from "react";
import BubbleChart from "../../../../Componets/BubbleChart";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import { getFilteredArrayforBubbles, PNN } from "../../../../Utils";
import Images from "../../../../Themes/ImageConstant";
import Styles from "./tweetTimeline.module.css";
import {
    structureData,
} from "../../../../Utils/chartCommandFunction";
import { ChartCard } from "../../../../Layouts/ChartCard";
import { convertTimestampinFormatedDate } from "../../../../Utils/globalFunction";

const TweetTimeline = (networkData) => {
    const [positiveData, setPositiveData] = useState([])
    const [negativeData, setNegativeData] = useState([])
    const [neutralData, setNeutralData] = useState([])


    useEffect(() => {
        
        if (Object.keys(networkData.data).length > 0) {
            let posiData = Object.entries(networkData.data.positive.data);
            posiData = structureData(posiData, "default", "Calledx");

            let negData = Object.entries(networkData.data.negative.data);
            negData = structureData(negData, "default", "Calledx");

            let nueData = Object.entries(networkData.data.neutral.data);
            nueData = structureData(nueData, "default", "Calledx");
            const comind = [...posiData.map(e => e[2]), ...negData.map(e => e[2]), ...nueData.map(e => e[2])].sort((a,b) => a - b)
            const min = comind[0], max=comind[comind.length - 1]
            setPositiveData(getFilteredArrayforBubbles(posiData, min, max, [30, 40]))
            setNegativeData(getFilteredArrayforBubbles(negData, min, max, [30, 40]))
            setNeutralData(getFilteredArrayforBubbles(nueData, min, max, [30, 40]))
        }
    }, [networkData?.data]);




    let seriesData = [
        {
            "name": "Negative",
            "data": negativeData
        },
        {
            "name": "Positive",
            "data": positiveData
        },
        {
            "name": "Neutral",
            "data": neutralData
        }
    ]


    let incidentList;
  const incidentFormatTooltip = ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }) => {
    incidentList = w?.config.series[seriesIndex].data[dataPointIndex][3].map(
      (val) => val.id
    );

    const type = w?.config.series[seriesIndex].name;
    const date = convertTimestampinFormatedDate(
      w?.config.series[seriesIndex].data[dataPointIndex][0],
      'month'
    );

    const firstNumberTitle =
      w?.config.series[seriesIndex].data[dataPointIndex][1];
    const number =
      w?.config.series[seriesIndex].data[dataPointIndex][3]?.length;
    return `
      <div style="width:200px;margin:5px; border-radius:5px;">
      <div style="font-weight:bold;font-size:14px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
      ${date}
      </div>
      <div class="tooltip-item">${type}: ${number}</div>
    </div>
      `;
  };

    let mind = seriesData?.map(e => e.data)?.flat(1)?.map(e => e[0]).sort()
    let maxY = seriesData?.map(e => e.data)?.flat(1)?.map(e => e[1]).sort()

    const enviromentOptions = {
        series: seriesData,
        options: {
            chart: {
              height: 350,
              type: "bubble",
              toolbar: {
                show: false,
                tools: {
                  zoom: false,
                },
              }
            },
            markers: {
              strokeWidth: 0,
            },
      
      
            colors: ["#E88080", "#76C48B", "#B6BCC7"],
            plotOptions: {
              bubble: {
                // zScaling: true,
                // minBubbleRadius: 15,
                // maxBubbleRadius: 1000,
                dataLabels: {
                  enabled: true,
                  formatter: function (val, opts) {
                    return "234";
                  },
                },
              },
            },
      
      
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            xaxis: {
              min: (mind[0] - (mind[0] * (0.1/100))) || undefined,
              max: (mind[mind.length -1 ] + (mind[mind.length -1] * (0.1/100))) || undefined,
              tickAmount: 1,
              type: "datetime",
              labels: {
                rotate: 0,
                show: false
              },
            },
            yaxis: {
              max: maxY[maxY.length - 1] + 1,
              tickAmount: 2,
              show: false
            },
            theme: {
              palette: "palette2",
            },
            tooltip: {
              
              x: {
                format: "dd MMM yyyy",
              },
              custom: incidentFormatTooltip,
            },
          },
          
        defaultColor: "red",
    };

    return (
        <section className="left-text">
            <div className="mt-24">
            <ChartCard
                title={networkData.title ||"Tweet Timeline"}
                network={networkData}
                legend={PNN}
                showTimeBarOnTop={false}
            >
                <div className="Sentiment-grah">
                    <div className="incidentTimeline-tabs-top-div">
                        <Chart
                            title={"Enviroment"}
                            titleImage={Images.greenTile}
                            titleIcon={Images.greenGloabe}
                            chartData={enviromentOptions}
                        />
                    </div>
                </div>
            </ChartCard>
            </div>
        </section>
    );
};

const Chart = ({ chartData }) => {
    return (
        <div className={Styles.chartContainer}>

            <div>
                <ChartBottomTitle>
                    <BubbleChart options={chartData} height={200} width={"235%"} />
                </ChartBottomTitle>
            </div>
        </div>
    );
};

export default TweetTimeline;
