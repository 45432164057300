import { all, fork } from "redux-saga/effects";
import rootAuthenticationSaga from "./authenticationSaga";
import rootIncidentSaga from "./incidentsSaga";
import rootSettingSaga from "./accountSettingSaga";
import rootCompanyPageSaga from "./companyPageSaga";
import rootWatchListSaga from "./watchlistSaga";
import rootSaveIncidenceSaga from "./saveIncidenceSaga";
import rootSupplyChainSaga from "./supplyChainSaga";
import rootPortfolioPageSaga from "./portfolio";
import rootSocialMediaPageSaga from "./socialMedia";
import rootAdvanceSearchSaga from "./advanceSearch";
import rootIncidentAnalyzerSaga from "./incidentAnalyzerSaga";
import globalSaga from "./globalSaga";
import rootCorporateProfilePageSaga from "./corporateProf";
import rootDashboardPageSaga from "./dashboardSaga";

export default function* rootSaga() {
  yield all([
    fork(rootAuthenticationSaga),
    fork(rootIncidentAnalyzerSaga),
    fork(rootIncidentSaga),
    fork(rootSettingSaga),
    fork(rootCompanyPageSaga),
    fork(rootWatchListSaga),
    fork(rootSaveIncidenceSaga),
    fork(rootSupplyChainSaga),
    fork(rootPortfolioPageSaga),
    fork(rootSocialMediaPageSaga),
    fork(rootAdvanceSearchSaga),
    fork(globalSaga),
    fork(rootCorporateProfilePageSaga),
    fork(rootDashboardPageSaga)
  ]);
}
