import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import ShowMoreText from "react-show-more-text";
import { useState } from "react";
import RiskScore from "../../components/RiskScore";
import LocationIcon from "./LocationIcon";
import { decodeUnicodeEscapes } from "../../utils";

function getLabelFromRiskScore(value) {
  if (value > 0 && value < 25) {
    return "Secure";
  }
  if (value >= 25 && value < 50) {
    return "Low Risk";
  }
  if (value >= 50 && value < 75) {
    return "Medium Risk";
  }
  return "High Risk";
}
function GeneralInfo({ data, showRiskScore = true, onViewCompanyProfile }) {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  return (
    <Stack gap={1}>
      <Typography variant="h4">{decodeUnicodeEscapes(data?.label)}</Typography>
      <ShowMoreText
        className={`show-more-text ${expanded ? "expanded" : ""}`}
        lines={1}
        onClick={() => setExpanded(!expanded)}
      >
        {data?.desciption}
      </ShowMoreText>
      <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
        <LocationIcon />
        <Typography
          variant="captionXL"
          sx={{
            color: theme.palette.gray[400],
          }}
        >
          {data?.location?.country_name}, {data?.location?.name}
        </Typography>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          variant="text"
          fullWidth={false}
          sx={{ paddingX: 0 }}
          onClick={onViewCompanyProfile}
        >
          View Company Profile
        </Button>
      </Box>
      {showRiskScore && (
        <RiskScore
          value={data?.riskScore}
          label={getLabelFromRiskScore(data?.riskScore)}
        />
      )}
    </Stack>
  );
}
export default GeneralInfo;
