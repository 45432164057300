import * as d3 from "d3";
import { useRef, useEffect } from "react";
import { degreeToRadians } from "../../../Utils/d3Helper";

const getColor = (i) => {
  switch (i) {
    case 12:
      return "#FFE140";
    case 11:
      return "#FFD700";
    case 10:
      return "#FFC820";
    case 9:
      return "#FFC000";
    case 8:
      return "#F49A43";
    case 7:
      return "#D47B23";
    case 6:
      return "#E15959";
    case 5:
      return "#D00000";
    case 4:
      return "#B70000";
    default:
      return "red";
  }
};

const getRangeValue = (value, max) => {
  const rangeMax = 9;
  const valPerc = (value / max) * 100;
  const rangeValue = rangeMax * (valPerc / 100);

  // rangeMax - rangeValue because the graph is drawn opposite, so when it is 3 (means third bar should fill) we need to sent 9 - 3, 6, so that 6 bars should not fill
  return rangeMax - Math.round(rangeValue);
};



const RiskSevertyComp = (props) => {
  const {
    data
  } = props
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  const riskValue = data?.risk ? data?.risk : 0;
  const sentimentValue = data?.sentiment ? data?.sentiment : 0;
  const severityValue = data?.severity ? data?.severity : 0;

  useEffect(() => {
    tooltipRef.current = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("display", "none");

    svgRef.current = d3
      .select("#svgContainer")
      .append("svg")
      .attr("width", 500)
      .attr("height", 500)

      .append("g")
      .attr("transform", "translate(200,200)");

    for (let i = 4; i < 13; i++) {
      let color1 = getColor(i);
      let color2 = getColor(i);
      let color3 = getColor(i);
      let altI = i - 4;
      if (altI < getRangeValue(riskValue, 100)) {
        color1 = "rgb(231, 233, 240)";
      }
      if (altI < getRangeValue(severityValue, 100)) {
        color2 = "rgb(231, 233, 240)";
      }
      if (altI < getRangeValue(sentimentValue, 100)) {
        color3 = "rgb(231, 233, 240)";
      }

      // Severity
      makeArc(i, color2, {
        x: 250 - i,
        y: 350 + i,
      });

      // Risk
      makeArc(i, color1, {
        x: 10 - i,
        y: 110 + i,
      });

      // Sentiment
      makeArc(i, color3, {
        x: 130 - i,
        y: 230 + i,
      });
    }
    makeArcLabel({ x: 250, y: 350 }, "#E7E9F0", "Severity");
    makeArcLabel({ x: 10, y: 110 }, "#E7E9F0", "Risk");
    makeArcLabel({ x: 230, y: 130 }, "#E7E9F0", "Neg. Sentiment", true);
    return () => {
      if( document.getElementById("svgContainer")){
      document.getElementById("svgContainer").innerHTML = "";
      }
      if (tooltipRef.current) {
        tooltipRef.current.remove();
      }
    };
  }, [svgRef,riskValue,severityValue,sentimentValue]);

  const makeArc = (i, color, initAngle = { x: 260, y: 360 }) => {
    var arc = d3
      .arc()
      .innerRadius(180 - i * 12)
      .outerRadius(170 - i * 12)
      .startAngle(degreeToRadians(initAngle.x + i))
      .endAngle(degreeToRadians(initAngle.y - i))
      .cornerRadius(3);

    svgRef.current
      .append("path")
      .attr("d", arc)
      .attr("fill", color)
      .on("mouseover", function (event, d) {
        d3.select(this).transition().duration("50").attr("opacity", "0.1");
        tooltipRef.current
          .transition()
          .duration(200)
          .style("display", "initial")
          .style("opacity", 0.9);
        // tooltipRef.current
        //   .html("this is the item =>" + i)
        //   .style("left", event.pageX + "px")
        //   .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", function (d, i) {
        d3.select(this).transition().duration("50").attr("opacity", "1");
        tooltipRef.current
          .transition()
          .duration(500)
          .style("opacity", 0)
          .style("display", "none");
      });
  };

  const makeArcLabel = (initAngle, color, textToShow, isInverted) => {
    if (!svgRef) return;
    var arc = d3
      .arc()
      .innerRadius(138)
      .outerRadius(170)
      .startAngle(degreeToRadians(initAngle.x))
      .endAngle(degreeToRadians(initAngle.y))
      .cornerRadius(3);

    svgRef.current
      .append("path")
      .attr("d", arc)
      .attr("id", textToShow)
      .attr("fill", color);

    var text = svgRef.current
      .append("text")
      .attr("x", 0)
      .attr("dy", !isInverted ? 22 : -12);
    text
      .append("textPath")
      .attr("stroke", "#676A73")
      .attr("stroke-width", "0.5")
      .attr("letter-spacing", "2px")
      .style("font-size", "20px")
      .attr("fill", "#676A73")
      .attr("xlink:href", "#" + textToShow)
      .attr("text-anchor", "middle")
      .attr("startOffset", "25%")
      .text(textToShow);
  };

  return (
    <div
      className="risk_severty_container"
      ref={svgRef}
      id="svgContainer"
    ></div>
  );
};

export default RiskSevertyComp;
