import { Box, useTheme } from "@mui/material";
import React from "react";
import ZoomFitViewButtons from "./ZoomFitViewButtons";
import UndoRedoButtons from "./UndoRedoButtons";
import RemoveButton from "./RemoveButton";
import LayoutSelectorButton from "./LayoutSelector/LayoutSelectorButton";
import GroupUnGroupButton from "./GroupUngroupButton";
import BadgeSelectorButton from "./BadgeSelectorButton";
import NodeSelectorButton from "./NodeSelectorButton";

function Toolbox() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        position: "absolute",
        gap: theme.spacing(1),
        right: "12px",
        top: theme.spacing(8),
        zIndex: 2,
      }}
    >
      <NodeSelectorButton />
      <LayoutSelectorButton />
      <BadgeSelectorButton />
      <GroupUnGroupButton />
      <ZoomFitViewButtons />
      <UndoRedoButtons />
      <RemoveButton />
    </Box>
  );
}
export default Toolbox;
