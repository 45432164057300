/* eslint-disable import/no-cycle */
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import appReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../../../../Redux/Saga";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});
sagaMiddleware.run(rootSaga);
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export default store;
