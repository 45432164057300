import React from "react";

const ConfirmModal = ({ icon, description }) => {
  return (
    <div className="reset-setting reset-setting1 center-aling-text">
      <img src={icon} />
      <p>{description}</p>
    </div>
  );
};

export default ConfirmModal;
