import React from "react";
import ReactApexChart from "react-apexcharts";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import { CHMM } from "../../../../Utils";

const IncidentsNumberBySeverity = ({ data, options }) => {
  const dataMapping = () => {
    let finalObj = [];
    let Critical = {name:"Critical",data:[]};
    let High = { name: "high", data: [] };
    let Minor    = { name: "Minor", data: [] };
    let Moderate = { name: "Moderate", data: [] };
    data?.forEach((item, index) => {
      for (let i of Object.keys(item.severity)) {
        if (i === "Critical") {
          Critical.data = [...Critical.data, item.severity[i]];
        }
        if (i === "High") {
          High.data = [...High.data, item.severity[i]];
        }
        if (i === "Minor") {
          Minor.data = [...Minor.data, item.severity[i]];
        }
        if (i === "Moderate") {
          Moderate.data = [...Moderate.data, item.severity[i]];
        }
      }
    });
    finalObj = [Critical,High, Moderate, Minor];
    return finalObj;
  };
  return (
    <ChartBottomTitle legend={CHMM} title={"Incidents numbers by severity"}>
      <ReactApexChart
        options={options}
        series={data ? dataMapping() : []}
        type="bar"
        height={300}
        width={330}
      />
    </ChartBottomTitle>
  );
};

export default IncidentsNumberBySeverity;
