import React from 'react';
import ReactECharts from 'echarts-for-react';
import { navigate } from '../../../../Utils/dispatchNavigate';

const getData = (data, chartKey,mainKey) => {
    let dataArr = []
    if (mainKey === "ESG") {
        for (let j in data) {
            dataArr.push(chartKey == "E" ? data[j].esg_categories.Environmental : chartKey == "S" ? data[j].esg_categories.Social : data[j].esg_categories.Governance
            )
        }
    }
    else {
        for (let j in data) {
            dataArr.push(chartKey == "C" ? data[j].severity.Critical
                : chartKey == "H" ? data[j].severity.High
                    : chartKey == "M" ? data[j].severity.Moderate : data[j].severity.Minor
            )
        }
    }
    dataArr.reverse()
    return dataArr
}

const getName = (data) => {
    let dataArr = []

    for (let j in data) {
        dataArr.push(data[j].name)
    }
    dataArr.reverse()
    return dataArr
}


const getARR=(chartKey,data)=>{
    if(chartKey == "ESG"){
      return [
        {
            type: 'bar',
            data: getData(data, "E",chartKey),
            coordinateSystem: 'polar',
            name: 'Environment',
            stack: 'a',
               label: {
                show: true,
                position: 'start',
                formatter: '{b}'
            },
            emphasis: {
                focus: 'series'
            }
        },
        {
            type: 'bar',
            data: getData(data, "S",chartKey),
            coordinateSystem: 'polar',
            name: 'Social',
            stack: 'a',
            emphasis: {
                focus: 'series'
            }
        },
        {
            type: 'bar',
            data: getData(data, "G",chartKey),
            coordinateSystem: 'polar',
            name: 'Governance',
            stack: 'a',
            emphasis: {
                focus: 'series'
            }
        }] 
    }
    else if (chartKey === "SEVERITY"){
        return  [{
            type: 'bar',
            data: getData(data, "C"),
            coordinateSystem: 'polar',
            name: 'Critical',
            stack: 'a',
            label: {
                show: true,
                position: 'start',
                formatter: '{b}'
            },
            emphasis: {
                focus: 'series'
            }
        },
        {
            type: 'bar',
            data: getData(data, "H"),
            coordinateSystem: 'polar',
            name: 'High',
            stack: 'a',
            emphasis: {
                focus: 'series'
            }
        },
        {
            type: 'bar',
            data: getData(data, "M"),
            coordinateSystem: 'polar',
            name: 'Moderate',
            stack: 'a',
            emphasis: {
                focus: 'series'
            },

        },
        {
            type: 'bar',
            data: getData(data, "L"),
            coordinateSystem: 'polar',
            name: 'Minor',
            stack: 'a',
            emphasis: {
                focus: 'series'
            }
        }
    ]
    }
}

const getMax = (data,mainKey) => {
    if (data.length > 0 && mainKey == "ESG") {
        let max = Math.max.apply(Math, data.map(function (o) { return Math.ceil(o.esg_categories.Environmental+o.esg_categories.Social+o.esg_categories.Governance); })) * 1.33
        return max
    }
    else{
        let max = Math.max.apply(Math, data.map(function (o) { return Math.ceil(o.severity.Critical+o.severity.High+o.severity.Moderate+o.severity.Minor); })) * 1.33
        return max
    }
}


const PolarChartESG = ({ data, chart }) => {
    const handleClick = (params) => {
   const newData =      data.reverse()
        let id = newData[params.dataIndex].id
        navigate("/companyPage", { state: { id: id } });
    };
    let max = data ? getMax(data,chart) : 10
    const option = {
        polar: {
            radius: [20, '80%']
        },
        angleAxis: {
            show: false,
            max:max,
            startAngle: 90
        },
        radiusAxis: {
            type: 'category',
            show: false,
            data:getName(data)
        },
        tooltip: {
            // trigger: 'axis',
            // formatter: function (params) {
            //     console.log(params,"adas6da6s5d456as");
            //  let compName =  data.reverse()
            //     return `
            //     <div style="width:160px;margin:5px; border-radius:5px;">
            //         <div style="font-weight:bold;font-size:13px;color:#000; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            //            ${ data.reverse()[params[0].dataIndex].name}
            //         </div>
            //         <div style="margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            //         <div class="tooltip-item" style="color:${params[0].color}" >${params[0].seriesName}: ${params[0].value}</div>
            //         <div class="tooltip-item" style="color:${params[1].color}" >${params[1].seriesName}: ${params[1].value}</div>
            //         <div class="tooltip-item" style="color:${params[2].color}" >${params[2].seriesName}: ${params[2].value}</div>
                
                   
            //         </div>
                    
            //     </div>
            //       `;
            // },
          },
        series:getARR(chart,data) ,
        color: chart == "ESG" ? ["#6ebd20", '#2191fb', '#653dd4',] : ["#800020", '#ff0000', '#ff7518', '#ffbf00']// Add more colors if needed

    };

    return (
        <>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <ReactECharts
                option={option}
                style={{ height: '350px', width: '550px' }}
                onEvents={{
                    click: handleClick

                }}
            />

        </div>

        {/* <div className="back-and-next">
                {data.map((i,v) => {
                    let name = i.name.split(" ")
                    return (
                        <>
                            <div></div>
                            <p>{(v + 1) + " "}{name[0]}</p>
                        </>
                    )
                })
                }
            </div> */}
        </>
       
    );
};

export default PolarChartESG;