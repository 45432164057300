/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { Box, Stack, useTheme, Button, Typography } from "@mui/material";
import axios from "axios";
import { setShowPathFindingModal } from "../../redux/GraphExploration/rightClickMenuSlice";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
import {
  prepareIncidentCounts,
  getEdgeProperLabel,
  getEdgeShortLabel,
  getFilterTagIds,
  getSelectedNodes,
  decodeUnicodeEscapes,
  getBadge,
  handleShortestPath,
} from "../../utils/index";
import { useGraphObject } from "../context/MyContext";
import { getToken } from "../../../../../Utils";
import { toast } from "react-toastify";
import { setEdgeIdsToSelect } from "../../redux/GraphExploration/graphSlice";

function PathFindingModalButtons() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { graphObject } = useGraphObject();

  const nodes = useAppSelector((state) => state.graph.nodes);
  // const edges = useAppSelector((state) => state.graph.edges);
  const handleClick = async () => {
    const currentNodeIds = (nodes || [])?.map((i) => i.id);
    // const currentEdgeIds = (edges || [])?.map((i) => i.id);
    const selectedNodes = getSelectedNodes(currentNodeIds, graphObject);
    if (selectedNodes.length === 2) {
      const sourceNode = selectedNodes[0];
      const targetNode = selectedNodes[1];
      await handleShortestPath(sourceNode, targetNode, graphObject);
    } else {
      // console.warn("Select select two node to find path between them!"); // TODO: Use Toast in the future
      toast.warn("Select select two node to find path between them!");
    }
    dispatch(setShowPathFindingModal(false));
  };
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      gap="8px"
      alignItems="center"
    >
      <Box>
        <Button
          onClick={() => dispatch(setShowPathFindingModal(false))}
          style={{
            color: theme.palette.gray[400],
            border: `1px solid ${theme.palette.gray.A200}`,
            height: "24px",
            padding: "8px 16px",
            borderRadius: "4px",
          }}
        >
          <Typography variant="buttonS">Cancel</Typography>
        </Button>
      </Box>
      <Box>
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{
            height: "24px",
            padding: "8px 16px",
            borderRadius: "4px",
            "&.Mui-disabled": {
              color: theme.palette.white.main,
              backgroundColor: theme.palette.primary[100], // Set your desired background color for disabled state
            },
          }}
        >
          <Typography variant="buttonS">Find Path(s)</Typography>
        </Button>
      </Box>
    </Stack>
  );
}
export default PathFindingModalButtons;
