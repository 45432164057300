import { Box, Button, Typography, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
import { setShowFilterSideBar } from "../../redux/GraphExploration/FilterSideBarSlice";
import SearchBar from "../SearchBar";
import {
  setHiddenEdges,
  setHiddenNodes,
} from "../../redux/GraphExploration/graphSlice";
import { useGraphObject } from "../context/MyContext";
import { resetFilterParamatersValue } from "../../utils";

function GraphHeaderBar({ onClickBackToSupplyChain }) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const centerNode = useAppSelector((state) => state.graph.centerNode);
  const nodes = useAppSelector((state) => state.graph.nodes);
  const edges = useAppSelector((state) => state.graph.edges);
  const { graphObject } = useGraphObject();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px",
        height: "64px",
        backgroundColor: "transparent",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: 100,
      }}
    >
      <Button
        sx={{
          color: theme.palette.gray["500"],
          ...theme.typography.buttonS,
          backgroundColor: theme.palette.gray.A100,
          "&:hover": {
            backgroundColor: theme.palette.gray[50],
          },
          transition: "all 0.3s ease",
          border: `1px solid ${theme.palette.gray.A200}`,
          borderRadius: "4px",
          height: "40px",
        }}
        onClick={onClickBackToSupplyChain}
      >
        <ArrowBackIcon sx={{ marginRight: "8px" }} />
        Supply Chain
      </Button>
      <SearchBar />
      <Box display="flex" gap="12px" height="40px">
        <Button
          sx={{
            border: `1px solid ${theme.palette.gray.A200}`,
            borderRadius: "4px",
            color: theme.palette.gray["500"],
            ...theme.typography.buttonS,
            backgroundColor: theme.palette.gray.A100,
            "&:hover": {
              backgroundColor: theme.palette.gray[50],
            },
            transition: "all 0.3s ease",
          }}
          onClick={() => {
            dispatch(setHiddenNodes([]));
            dispatch(setHiddenEdges([]));
            const nodeIdsToFilter = nodes
              .map((n) => n.id)
              .filter((n) => n !== centerNode.id);
            const edgeIdsToFilter = edges.map((e) => e.id);
            graphObject?.startHistoryBatch();
            graphObject?.removeData("edge", edgeIdsToFilter);
            graphObject?.removeData("node", nodeIdsToFilter);
            graphObject?.stopHistoryBatch();
            resetFilterParamatersValue();
          }}
        >
          Clear Graph
        </Button>
        <Button
          sx={{
            border: `1px solid ${theme.palette.gray.A200}`,
            borderRadius: "4px",
            color: theme.palette.gray["500"],
            ...theme.typography.buttonS,
            backgroundColor: theme.palette.gray.A100,
            "&:hover": {
              backgroundColor: theme.palette.gray[50],
            },
            transition: "all 0.3s ease",
          }}
          onClick={() => dispatch(setShowFilterSideBar(true))}
        >
          <TuneIcon sx={{ marginRight: "8px" }} fontSize="small" />
          Filter
        </Button>
        <Box
          sx={{
            border: `1px solid ${theme.palette.gray.A200}`,
            borderRadius: "4px",
            color: theme.palette.gray["500"],
            width: "180px",
            display: "flex",
            paddingX: "12px",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            backgroundColor: theme.palette.gray.A100,
            "&:hover": {
              backgroundColor: theme.palette.gray[50],
            },
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{
              /* Web/Input/Placeholder */
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "16px",
            }}
          >
            Last 3 months
          </Typography>
          <ExpandMoreIcon fontSize="small" />
        </Box>
      </Box>
    </Box>
  );
}
export default GraphHeaderBar;
