import React, { useCallback, useState } from "react";
import { Button, Calendar, Row } from "antd";
import ImageConst from "../../Themes/ImageConstant";
import moment from "moment";
import { calculatePreviousDate, dateButtonObj } from "../../Utils";

const CustomDatePIcker = ({ onDatePickerChange, setDateTitle, lastDate }) => {
  const [currentActiveButton, setCurrentActiveButton] = useState(lastDate);
  const [activeButton, setActiveButton] = useState(true);
  const [showCal, setShowCal] = useState(false);
  const [picker1, setPicker1] = useState(false);
  const [picker2, setPicker2] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    calculatePreviousDate(new Date(), lastDate)
  );
  const dateButtonClick = useCallback((type) => {
    // set the current active button
    setCurrentActiveButton(type);
    localStorage.graphDatePickerActiveButton = type;
    // check the type and return the title of the date type => form dateButtonObj
    const title = dateButtonObj.find((item) => item.type === type).title;
    setDateTitle(title);
    localStorage.graphDatePickerActiveButtonTitle = title;
    // set previous selected date
    setStartDate(calculatePreviousDate(new Date(), type));
    // set current date
    setEndDate(new Date());
  }, []);

  // Date button
  const onsetDate = (e) => {
    setDateTitle("Custom Date");
    if (picker1 && moment(e).isBefore(endDate)) {
      setStartDate(e);
    }
    if (picker2 && moment(e).isAfter(startDate)) {
      setEndDate(e);
    }
  };

  const DateButtons = useCallback(() => {
    const Buttons = ({ type, title }) => (
      <Button
        type="button"
        className={currentActiveButton === type ? "selected-button" : ""}
        onClick={() => {
          dateButtonClick(type);
        }}
      >
        {title}
      </Button>
    );
    return dateButtonObj.map((props) => <Buttons {...props} />);
  }, [currentActiveButton]);

  // call on the cancel and submit button both
  const onDateButtonClick = (type) => {
    setActiveButton(!activeButton);
    setPicker1(false);
    setPicker2(false);
    setShowCal(false);
    if (type === "cancel") {
      onDatePickerChange();
    } else {
      onDatePickerChange(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
    }
  };
  const disabledMonth = (month) => {
    if (!endDate) return false;
    return month.isAfter(endDate, 'month');
  };

  const disabledYear = (year) => {
    if (!endDate) return false;
    return year.isAfter(endDate, 'year');
  };
  return (
    <div className="select-date" style={{ width: "290px" }}>
      <div className="daily-sec last-hour">
        <DateButtons />
      </div>

      <div className="specifi-date">
        <div className="enter-date-pop">
          <h5>Pick a specific date</h5>
        </div>
        <div>
          <div>
            <Row
              onClick={() => { }}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  onClick={() => {
                    setShowCal(true);
                    setPicker1(true);
                    setPicker2(false);
                  }}
                  value={moment(startDate).format("ll")}
                  type="text"
                  style={{
                    border: "0.5px solid #80808047",
                    width: 120,
                    backgroundColor: "#F7F8FA",
                    borderRadius: "4px",
                    padding: "6px",
                  }}
                  placeholder="from"
                />
                <div
                  style={{
                    position: "absolute",
                    right: 5,
                    top: "45%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <img src={ImageConst.calIc} alt="" />
                </div>
              </div>
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  onClick={() => {
                    setShowCal(true);
                    setPicker2(true);
                    setPicker1(false);
                  }}
                  value={moment(endDate).format("ll")}
                  type="text"
                  style={{
                    border: "0.5px solid #80808047",
                    width: 120,
                    backgroundColor: "#F7F8FA",
                    borderRadius: "4px",
                    padding: "6px",
                  }}
                  placeholder="to"
                />
                <div
                  style={{
                    position: "absolute",
                    right: 5,
                    top: "45%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <img src={ImageConst.calIc} alt="" />
                  {/* Replace IconComponent with your desired icon */}
                </div>
              </div>
            </Row>
          </div>
        </div>
        {showCal ? (
          <Calendar
            style={{ height: "40%", width: "100%" }}
            className="custom-calendar"
            onSelect={(e) => {
              onsetDate(e.$d);
            }}
            disabledDate={(e) => {
              if (!e) return false;
              return e.isAfter(endDate);
            }}
            // disabledMonth={disabledMonth}
            // disabledYear={disabledYear}
          />
        ) : null}

        <Row style={{ gap: 8, justifyContent: "space-around", marginTop: 15 }}>
          <Button
            onClick={() => onDateButtonClick("cancel")}
            className=" datePickerButton datePickerCancelButton"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onDateButtonClick()}
            className="datePickerButton"
          >
            Apply
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default CustomDatePIcker;
