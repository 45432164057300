import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import style from "../style.module.css";
import * as Yup from "yup";
import clsx from "clsx";
import HeaderComponent from "../components/HeaderComponent";
import {
  Checkbox,
  Radio,
  Select,
  Space,
  Input,
  AutoComplete,
  InputNumber,
  Table,
  Spin,
} from "antd";
import ImageConst from "../../../Themes/ImageConstant";
import { modalHide, modalShow } from "../../../Redux/Action/global";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import {
  addPortfolioLoad,
  editPortfolioLoad,
  getEditPortfolioLoad,
  getPortfolioCompaniesListSearchLoad,
  updatePortfolioState,
  updateUploadFilePortFolioAction,
  uploadPortfolioFileLoad,
} from "../../../Redux/Action/portfolio";
import {
  debounce,
  removeEmojis,
} from "../../../Utils";

import { useLocation, useNavigate } from "react-router-dom";
import { getCountryDataforSupplyChain } from "../../../Redux/Action/supplyChainAction";

import GlobalSpinner from "../../../Utils/GlobalSpinner";
import "./index.css";

import { formatNumber, highlightMatch, highlightMatchTicker } from "../../../Utils/globalFunction";
import { addEmpty4Fields } from "./utils";
import { BottomPagination } from "./utils-comp";
import FileUploadPopUp from "../../../Componets/fileUploadPopup";


import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import ReactCountryFlag from 'react-country-flag';

// Register locales with correct imports
countries.registerLocale(enLocale);
countries.registerLocale(frLocale);

let autoCompletePaginationOffset = 0;
let stopApicallForGetingCompanies = false;
let lastSearchString = "";
let checkingCompanyIndex;

const tableDataModificationHandler = (data) =>
  data.map((item, index) => ({ ...item, index }));


const CreatePortfolio = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location || {};

  // Destructure portfolioId with a default value if state is null
  const {
    portfolioId = null,
    editStep = null,
    screen = null,
    companyName = null,
    companyId = null,
    addStep = false,
    newCreate
  } = state || {};
  const [visibleState, setVisibleState] = useState(false);
  const [addFilePopOver, setAddFilePopOver] = useState(false);
  const [validateFormOnChange, setValidateFormOnChange] = useState(true);
  const [riskValue, setRiskValue] = useState(10);
  const [riskScore, setRiskScore] = useState(70);
  const [loading, setLoading] = useState(false)

  const [options, setOptions] = useState([]);
  const [step, setStep] = useState(1);
  const duplicateSetFieldValue = useRef({
    setValue: null,
    value: null,
    errors: null,
    setErrors: null,
  });
  const [initValues] = useState({
    name: "",
    portFolioSize: "",
    investmentCapital: "",
    currency: "",
    holdings: [
      {
        company: "",
        percentage: "",
        ticker: "",
        isin: "",
      },
    ],
    riskScore: "any increase more than 10 percent",
    sentimentShiftAlert: false,
    highSeverityScoreAlert: false,
  });

  const portfolioData = useSelector((state) => state.portfolio);
  const {
    uploadMediaFileData,
    addPortfolioSuccess,
    getEditDetail,
    getEditDetailSuccess,
    editPortfolioSuccess,
    checkValidCompanySuccess,
    companiesSearchList,
    companiesSearchListLoader,
    addPortfolioLoading,
    editPortfolioLoader,
    validCompany,
    invalidCompany,
    validStatus,
    isUploading,
    companiesSearchListPaginationData,
    inValidHoldinggList,
    validPercentage,
    getEditDetailLoader
  } = portfolioData;
  useLayoutEffect(() => {
    dispatch(getCountryDataforSupplyChain());
    if (portfolioId) {
      setStep(editStep);
      dispatch(getEditPortfolioLoad({ id: portfolioId }));
    }
    if (addStep && screen == "/companyPage") {
      let tempHoldingArr = [];
      tempHoldingArr.push({
        company: companyName?.trim() || "",
        percentage: undefined,
      });
      duplicateSetFieldValue.current.setValue(
        "holdings",
        tempHoldingArr || [{ company: "", percentage: "" }]
      );
      // dispatch(checkValidCmpnyLoad({ holdings: tempHoldingArr }));
    }
    if (newCreate) {
      addEmpty4Fields(dispatch)
    }
  }, []);

  useEffect(() => {
    if (getEditDetailSuccess) {
      duplicateSetFieldValue.current.setValue(
        "name",
        getEditDetail?.name || ""
      );
      duplicateSetFieldValue.current.setValue(
        "sentimentShiftAlert",
        getEditDetail?.alertRules?.sentimentShiftAlert || false
      );
      duplicateSetFieldValue.current.setValue(
        "riskScore",
        getEditDetail?.alertRules?.riskScore ||
        "any increase more than 10 percent"
      );
      duplicateSetFieldValue.current.setValue(
        "highSeverityScoreAlert",
        getEditDetail?.alertRules?.highSeverityScoreAlert || false
      );
      let tempHoldingArr = [];
      getEditDetail?.holdings.map((item, index) => {
        return tempHoldingArr.push({
          "Company name": item["company"]?.trim() || "",
          name: item["company"]?.trim() || "",
          "portfolio weight": item["percentage"],
          ticker: item["ticker"] || "",
          tickers: item["tickers"] || "",
          isin: item["isin"] || "",
          isValid: item["isValid"] || false,
        });
      });
      if (screen == "/companyPage") {
        tempHoldingArr.push({
          company: companyName?.trim() || "",
          percentage: null,
        });
      }

      setTimeout(() => {
        dispatch(updateUploadFilePortFolioAction(tempHoldingArr));
      }, 400);
      duplicateSetFieldValue.current.setValue(
        "holdings",
        tempHoldingArr || [{ company: "", percentage: "" }]
      );
      if (
        getEditDetail?.alertRules?.riskScore ==
        "any increase more than 10 percent"
      ) {
        setRiskValue(getEditDetail?.alertRules?.risk || 10);
      } else {
        setRiskScore(getEditDetail?.alertRules?.risk || 70);
      }

      // dispatch(checkValidCmpnyLoad({ holdings: tempHoldingArr }));
    }


    return () => {
      dispatch(updatePortfolioState(false, "getEditDetailSuccess"));
      dispatch(updatePortfolioState([], "uploadMediaFileData"));
      dispatch(updatePortfolioState({}, "getEditDetail"));
    };
  }, [getEditDetailSuccess]);

  useLayoutEffect(() => {
    if (uploadMediaFileData && uploadMediaFileData?.length > 0) {
      let tempArr = [];
      uploadMediaFileData.map((item, index) => {
        return tempArr.push({
          company:
            item["Company name"]?.trim() ||
            item["ISIN"]?.trim() ||
            item["Ticker"]?.trim() ||
            "",
          percentage: item["portfolio weight"],
          ticker:
            item["tickers"] && Object.keys(item["tickers"])?.length > 0
              ? item["tickers"].tickerId
              : "",
          symbol: item["tickers"] ? item["tickers"].symbol : "",
          isin: item["isin"],
          isValid: item["isValid"],
        });
      });
      // dispatch(updateUploadFilePortFolioAction(tempArr));

      // duplicateSetFieldValue.current.setValue("holdings", tempArr);
      // dispatch(checkValidCmpnyLoad({ holdings: tempArr }));
    }
  }, [uploadMediaFileData]);

  useEffect(() => {
    if (addPortfolioSuccess) {
      if (step == 1) {
        setStep(2);
        dispatch(updatePortfolioState(false, "addPortfolioSuccess"));
      } else if (step == 2) {
        dispatch(modalShow(logoutModal));
        dispatch(updatePortfolioState(false, "addPortfolioSuccess"));
      }
    }
  }, [addPortfolioSuccess]);

  useEffect(() => {
    if (editPortfolioSuccess) {
      if (screen == "/portfolioDetails") {
        navigate(screen, {
          state: {
            id: portfolioId,
            backgroundCardColor: getEditDetail?.cardColor?.background,
          },
        });
      } else if (screen == "/companyPage") {
        navigate(screen, {
          state: {
            id: companyId,
          },
        });
      } else {
        navigate(screen);
      }

      dispatch(updatePortfolioState(false, "editPortfolioSuccess"));
    }
  }, [editPortfolioSuccess]);

  const handleRadioChange = (setValue, e) => {
    setValue("riskScore", e.target.value);
  };

  const logoutModal = {
    show: true,
    OK: {
      name: "OK",
      onClick: () => {
        dispatch(modalHide());
        if (companyId) {
          navigate("/companyPage", {
            state: {
              id: companyId,
            },
          });
        } else {
          navigate("/portfolio");
        }
        // navigate("/cityclinics")
        // dispatch(resetAppData());
        // navigate("/");
      },
    },
    CANCEL: {
      name: "No",
      onClick: () => {
        dispatch(modalHide());
      },
    },
    icon: "logout",
    type: "CONFIRM",
    description: "Are you sure, you want to logout?",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test(function (value, context) {
        if (value?.trim()?.length < 3) {
          return this.createError({
            path: "name",
            message: "Portfolio name must be at least 3 characters long.",
          });
        }
        return true;
      })
      .required("Please enter portfolio name."),

  });

  useEffect(() => {
    if (checkingCompanyIndex !== undefined && inValidHoldinggList?.length > 0) {
      const clone = [...duplicateSetFieldValue.current.value.holdings];
      clone[checkingCompanyIndex].isValid = inValidHoldinggList[0].isValid;
      duplicateSetFieldValue.current.setValue("holdings", clone);
      checkingCompanyIndex = undefined;
    }
  }, [inValidHoldinggList]);

  useEffect(() => {
    setOptions([...options, ...companiesSearchList]);
  }, [companiesSearchList]);

  const handleSearch = (searchString, offset) => {
    lastSearchString = searchString;
    setLoading(true)
    debounce(() => {
      dispatch(
        getPortfolioCompaniesListSearchLoad({
          search: lastSearchString,
          limit: 10,
          offset: offset || 0,
        })
      );
    });
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  };

  useEffect(() => {
    if (checkValidCompanySuccess) {
      duplicateSetFieldValue.current.validateForm();
      dispatch(updatePortfolioState(false, "checkValidCompanySuccess"));
    }
  }, [checkValidCompanySuccess]);

  const handleInputChange = (
    setValue,
    value,
    name,
    type,
    length,
    values,
    index
  ) => {
    let data = value;
    if (type == "number") {
      data.target.value = data.target.value.replace(
        /[-[\]{}()+*+?,\\^$|#\s]/g,
        "\\$&"
      );
      data.target.value = data.target.value.slice(0, length);
      if (data.target.value > 100) {
        data.target.value = 100;
      }
      return setValue(name, data.target.value.trimLeft());
    } else {
      if (values) {
        let allData = [...values.holdings];
        allData[index].company = removeEmojis(data.target.value.trimLeft());
        // dispatch(checkValidCmpnyLoad({ holdings: allData }));
      }
      if (value.target.value[0] === " ") {
        data.target.value = data?.target?.value?.trim();

        return setValue(name, removeEmojis(data.target.value.trimLeft()));
      }
      return setValue(name, removeEmojis(value.target.value.trimLeft()));
    }
  };

  const InputChange = (e) => {
    let formData = new FormData();
    formData.append("file", e);
    dispatch(uploadPortfolioFileLoad(formData));
  };

  // New Functionalities
  const [makeAuto, setMakeAuto] = useState({ state: false, index: null });
  const [editWeight, setEditWeight] = useState({ state: false, index: null });
  const [activeTableRowIndex, setActiveTableRowIndex] = useState(null);
  const [paginationPayload, setPaginationPayload] = useState({
    currentPage: 1,
    rowLimit: 15
  })
  const [inputWeigth, setInputWeight] = useState("");
  const autoCompleteRef = useRef(null)
  const editWeightRef = useRef(null)

  // State
  const tabeldata = useMemo(
    () => tableDataModificationHandler(uploadMediaFileData),
    [uploadMediaFileData]
  );
  const renderTableData = useMemo(() => tabeldata.slice((paginationPayload.currentPage - 1) * paginationPayload.rowLimit, paginationPayload.currentPage * paginationPayload.rowLimit), [paginationPayload, uploadMediaFileData]);
  const tabelDataSelectedItemLength = useMemo(
    () => uploadMediaFileData?.filter((item) => item?.isSelected)?.length,
    [uploadMediaFileData]
  );

  // Handler
  const allSelectTableItemsHandler = useCallback(
    (e) => {
      if (addFilePopOver) {
        setAddFilePopOver(false);
      }
      const startIndex = renderTableData[0].index
      const endIndex = renderTableData[renderTableData?.length - 1].index

      let arr = tabeldata.map((item) => {
        if (item.index >= startIndex && item.index <= endIndex) {
          return { ...item, isSelected: e.target.checked };
        } else {
          return { ...item }
        }
      });

      dispatch(updateUploadFilePortFolioAction(arr));
    },
    [uploadMediaFileData, renderTableData]
  );
  const removeTableSelectedItemsHandler = useCallback(() => {

    let arr = uploadMediaFileData.filter((item) => !item.isSelected);
    if (arr.length == 0) {
      addEmpty4Fields(dispatch)
    } else {
      dispatch(updateUploadFilePortFolioAction(arr));
    }
  }, [uploadMediaFileData]);


  const renderOptions = () => {
    let renderedOptions = options?.map((option, idx) => {
      return {
        value: option?.id || "",
        dataValue: option?.value?.label || "",
        tickers: option?.value?.tickers || "",
        key: option?.id || "",
        isin: option?.value?.isin || "",
        label: (
          <DoneItem
            searchString={lastSearchString}
            companyName={option?.value?.label || ""}
            ticker={option?.value?.tickers || ""}
            compId={option?.value?.compId || ""}
            key={option?.id || ""}
            isin={option?.value?.isin || ""}
            alter_names={option?.value?.alter_names?.length > 0 ? option?.value?.alter_names[0] : ""}
            alter_name={option?.value?.alter_name || ""}
          />
        ),
      }
    })
    if (loading) {
      renderedOptions.push({
        value: 'loading',
        label: (
          <div style={{ textAlign: 'center' }}>
            <Spin size="small" />
          </div>
        ),
      });
    }
    return renderedOptions;
  };


  useEffect(() => {
    const focusInput = (ref) => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    };
    if (makeAuto.state) {
      focusInput(autoCompleteRef);
    } else if (editWeight.state) {
      focusInput(editWeightRef);
    }

  }, [makeAuto, editWeight]);

  const [autoCompleteIndex, setAutoCompleteIndex] = useState(false)

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (v, r, i) => {
        return (
          <div style={{ display: "flex", gap: "8px", }}>
            <div
              style={{
                width: "25px",
                height: "25px",
              }}
            >
              {activeTableRowIndex == i ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer"
                  }}
                  src={ImageConst.plusBtnNew}
                  onClick={() => {
                    const clone = [...tabeldata];
                    const dummyObj = {
                      "Company name": "",
                      index: 0,
                      isValid: null,
                      isin: "",
                      name: "",
                      "portfolio weight": "",
                      searchBase: null,
                      ticker: "",
                      tickers: {},
                    };
                    clone.splice(r.index + 1, 0, dummyObj);
                    dispatch(updateUploadFilePortFolioAction(clone));
                  }}
                />
              ) : null}
            </div>
            <div
              style={{
                minWidth: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "inherit",
              }}
            >
              {activeTableRowIndex == i || r?.isSelected ? (
                <Checkbox
                  checked={r?.isSelected}
                  onChange={(e) => {
                    let arr = [...tabeldata];
                    arr[r.index] = {
                      ...arr[r.index],
                      isSelected: e.target.checked,
                    };
                    dispatch(updateUploadFilePortFolioAction(arr));
                  }}
                />
              ) : (
                <>{r.index + 1}</>
              )}
            </div>
          </div>
        );
      },
      width: 70
    },
    {
      title: "Holding / Ticker Symbol / ISIN",
      dataIndex: "company name",
      key: "name",
      render: (v, r, i) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              background: "inherit",
            }}
            onClick={() => {
              setMakeAuto({ state: true, index: i });
            }}
          >
            <div style={{ width: "10%" }}>
              {r.isValid ? (
                <div className="error">
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "5px",
                    }}
                    src={ImageConst.validPng}
                  />
                </div>
              ) : r.isValid == false ? (
                <div className="error">
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "5px",
                    }}
                    src={ImageConst.invalidPng}
                  />
                </div>
              ) : null}
            </div>
            <div
              style={{
                width: "100%"
              }}

            >
              {makeAuto.state && makeAuto.index == i ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <AutoComplete
                    open={true}
                    style={{ width: "100%" }}
                    value={r["Company name"]}
                    className="autoCompleteDropDown"
                    options={renderOptions()                    }
                    onSearch={(e) => {
                      let arr = [...tabeldata];
                      arr[r.index] = {
                        ...arr[r.index],
                        isValid: false,
                        "Company name": e,
                        name: e,
                      };
                      dispatch(updateUploadFilePortFolioAction(arr));
                      stopApicallForGetingCompanies = false;
                      setOptions([]);
                      handleSearch(e);
                    }}
                    onSelect={(e, selectedOption) => {
                      const clone = [...tabeldata];
                      const makeItem = {
                        ...r,
                        ...selectedOption,
                        isValid: true,
                        "Company name": selectedOption.dataValue,
                        name: selectedOption.dataValue,
                        isin: selectedOption.isin
                          ? selectedOption.isin
                          : r.isin,
                      };
                      clone[makeItem.index] = makeItem;
                      dispatch(updateUploadFilePortFolioAction(clone));
                      setMakeAuto({ state: false, index: null });
                      autoCompletePaginationOffset = 0

                    }}
                    onFocus={(e) => {
                      stopApicallForGetingCompanies = false;
                      setOptions([]);
                      handleSearch(e.target.value, 0);

                    }}
                    notFoundContent={
                      options?.length == 0 &&
                      companiesSearchListLoader && (
                        <div className={style.spinLoader}>
                          <Spin size="small" />
                        </div>
                      )
                    }

                    onBlur={() => {
                      if (r["Company name"]?.length == 0) {
                        let arr = [...tabeldata];
                        arr[r.index] = {
                          ...arr[r.index],
                          isValid: false,
                        };
                        dispatch(updateUploadFilePortFolioAction(arr));
                      }
                      setMakeAuto({ state: false, index: null })
                      autoCompletePaginationOffset = 0
                    }}

                    onPopupScroll={(e) => {

                      if (
                        e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 1
                      ) {
                        autoCompletePaginationOffset =
                          autoCompletePaginationOffset + 10;
                        handleSearch(
                          lastSearchString,
                          autoCompletePaginationOffset
                        );
                      }
                    }}
                  >
                    <Input
                      ref={autoCompleteRef}
                      size="middle"
                    />
                  </AutoComplete>
                </div>
              ) : (
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {r["Company name"]}
                    {/* {r["Company name"].length > 18 ? r["Company name"].slice(0, 18) + "..." : r["Company name"]} */}
                  </div>
                  <div
                    style={{
                      width: "22px",
                      height: "22px",
                    }}
                  >
                    {activeTableRowIndex == i && makeAuto.index !== i && r["Company name"]?.length > 0 ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMakeAuto({ state: true, index: i });
                        }}
                        src={ImageConst.editIcon}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            {makeAuto.index !== i && (
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "inherit",
                  }}
                >
                  <div>
                    {r?.tickers?.symbol?.length > 0 &&
                      r?.tickers?.tickerId?.length > 0 &&
                      r?.tickers?.symbol + ":" + r?.tickers?.tickerId}
                  </div>
                </div>
              </div>
            )}

          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "isin",
      key: "isin",
      width: 50,
      render: (v, r, i) => {
        return (
          <div style={{ paddingRight: 10 }}>
            {r.isin}
          </div>
        );
      },
    },
    {
      title: () => <div style={{
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'center',
        borderLeft: "1px solid #d8dae0",
        fontWeight: "600",
        fontSize: "13px",
        fontFamily: "inter",
        color: "#3d4353",
      }}>Weight</div>,
      dataIndex: "",
      key: "Weight",
      width: 120,
      render: (v, r, i) => {
        return (
          <div
            style={{
              width: "100%",
              borderLeft: "1px solid #d8dae0",
              display: 'flex'
            }}
            onClick={() => {
              setInputWeight(r["portfolio weight"]);
              setEditWeight({ state: true, index: i });
            }}
          >
            {
              editWeight.state && editWeight.index == i ? (
                <div>
                  <InputNumber
                    // max={100}
                    min={0}
                    ref={editWeightRef}
                    size="small"
                    value={inputWeigth}
                    onChange={(e) => {
                      setInputWeight(e?.toString() || "");
                    }}
                    onBlur={(e) => {
                      let getString = Number(e.target.value.replace(",", "."));
                      const clone = [...tabeldata];
                      const makeItem = {
                        ...r,
                        "portfolio weight": getString,
                      };
                      clone[makeItem.index] = makeItem;
                      dispatch(updateUploadFilePortFolioAction(clone));
                      setInputWeight("");
                      setEditWeight((pre) => ({ state: false, index: null }));
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    // gap: "10px",
                    padding: "0px 10px 0px 10px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {
                    <div>{r["portfolio weight"]}</div>
                  }

                  <div
                    style={{
                      width: "22px",
                      height: "22px",
                    }}
                  >
                    {activeTableRowIndex == i && editWeight.index !== i && r["portfolio weight"]?.length > 0 ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setInputWeight(r["portfolio weight"]);
                          setEditWeight({ state: true, index: i });
                        }}
                        src={ImageConst.editIcon}
                      />
                    ) : null}
                  </div>
                </div>

              )
            }
            <div
              style={{
                width: "100%",
                height: "30px",
                cursor: "pointer",
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
              }}
              onClick={() => {
                if (renderTableData?.length > 1) {
                  const clone = [...tabeldata];
                  clone.splice(r.index, 1);
                  if (clone.length == 0) {
                    addEmpty4Fields(dispatch)
                  }
                  else {
                    dispatch(updateUploadFilePortFolioAction(clone));
                  }
                  setInputWeight("");
                  setEditWeight((pre) => ({ state: false, index: null }));
                }
              }
              }
            >
              {activeTableRowIndex == i && renderTableData?.length > 1 ? (
                <img
                  style={{
                    height: "80%",
                    width: "25px",
                  }}
                  src={ImageConst.removeBtnNew}
                />
              ) : null}
            </div>
          </div >
        );
      },
    },
    // {
    //   title: "",
    //   dataIndex: "",
    //   key: "",
    //   render: (v, r, i) => {
    //     return (

    //     );
    //   },
    // },
  ];
 
  const isValidData = useMemo(() => tabeldata?.filter(val => val?.name?.length > 0)?.length, [tabeldata])
  const checkIsValid = useMemo(
    () => tabeldata.filter((item) => !item.isValid && item?.name?.length > 0),
    [uploadMediaFileData]
  )?.length;

  const handleSubmit = (values, { setSubmitting }) => {
    let data = {
      name: values.name,
      advancedPersonalizedSuggestions: {
        portFolioSize: values.portFolioSize,
        investmentCapital: values.investmentCapital,
        currency: values.currency,
      },
      holdings: tabeldata.map((i) => {
       
        return {
          company: i["Company name"],
          percentage: parseFloat(i["portfolio weight"]) || null,
          ticker: i.tickers,
          isin: i.isin,
          isValid: i.isValid,
        };
      })?.filter(val => val?.company?.length > 0 && val),
      alertRules: {
        riskScore: values.riskScore,
        sentimentShiftAlert: values.sentimentShiftAlert,
        highSeverityScoreAlert: values.highSeverityScoreAlert,
        risk: values.riskScore === "higher than 70" ? riskScore : riskValue,
      },
    };
    if (screen && !addStep) {
      data.id = portfolioId;
      dispatch(editPortfolioLoad(data));
    } else {
      if (step == 1) {
        data.step = 1;
        dispatch(addPortfolioLoad(data));
      } else {
        data.step = 2;
        dispatch(addPortfolioLoad(data));
      }
    }
  };
  const rowEdit = (index) => makeAuto.state && makeAuto.index == index || editWeight.state && editWeight.index == index
  return (
    <>
      <div
        className={"containerWidth marginAuto pt20"}>
        <HeaderComponent
          name={portfolioId ? "My Portfolio" : "Portfolio"}
          subHeader={
            portfolioId
              ? step == 1
                ? "Edit Portfolio"
                : "Edit rules"
              : "Create New Portfolio"
          }
          screen={"/portfolio"}
        />
        <div className={clsx(style.containerWidth)}>
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validateOnChange={validateFormOnChange}
            validateOnBlur={true}
          >
            {({
              handleChange,
              setFieldValue,
              setErrors,
              values,
              errors,
              touched,
              validateForm,
            }) => {
              duplicateSetFieldValue.current.setValue = setFieldValue;
              duplicateSetFieldValue.current.value = values;
              duplicateSetFieldValue.current.errors = errors;
              duplicateSetFieldValue.current.setErrors = setErrors;
              duplicateSetFieldValue.current.validateForm = validateForm;
              return (
                <Form>
                  <div
                    className="pv10ph16"
                    style={{ cursor: "pointer", width: "fit-content" }}
                    onClick={() =>
                      step == 1
                        ? navigate("/portfolio")
                        : portfolioId
                          ? navigate("/portfolio")
                          : setStep(1)
                    }
                  >
                    <img
                      style={{ height: 20, width: 20 }}
                      src={ImageConst.backBtn}
                    ></img>
                    <span className="backBtnText"> Back</span>
                  </div>
                  <section className="containerWidth ">
                    <div
                      className={`${style.subComponent} ${style.subComponent1}`}
                    >
                      <span className={style.subComponentText}>
                        {step == 1
                          ? portfolioId
                            ? "Edit Portfolio"
                            : "Create Portfolio"
                          : "Define Alert Rules"}
                      </span>
                      {step == 1 ? (
                        <>
                          {portfolioId ? (
                            <button type="submit" className={style.btnMedium}>
                              <div className={style.nextBtn}>
                                {editPortfolioLoader ? (
                                  <GlobalSpinner />
                                ) : (
                                  "Save Changes"
                                )}
                              </div>
                            </button>
                          ) : (
                            <button
                              type={checkIsValid !== 0 ? "submit" : ""}
                              className={style.smallBtn}
                              style={{
                                width: "fit-content",
                              }}
                              disabled={!isValidData}
                            >
                              <div
                                className={style.nextBtn}
                                style={{
                                  width: "fit-content",
                                }}
                              >
                                {addPortfolioLoading ? (
                                  <GlobalSpinner />
                                ) : checkIsValid == 0 ? (
                                  "Next"
                                ) : (
                                  "Skip Errors and Continue"
                                )}
                                <img
                                  style={{ height: 20, width: 20 }}
                                  src={ImageConst.arrowWhiteRight}
                                />
                              </div>
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {portfolioId ? (
                            <button type="submit" className={style.btn}>
                              <div className={style.nextBtn}>
                                {editPortfolioLoader ? (
                                  <GlobalSpinner />
                                ) : (
                                  "Update Rules"
                                )}
                              </div>
                            </button>
                          ) : (
                            <button type="submit" className={style.btn}>
                              <div className={style.nextBtn}>
                                {addPortfolioLoading ? (
                                  <GlobalSpinner />
                                ) : (
                                  "Create Portfolio"
                                )}
                              </div>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </section>
                  {step == 1 ? (
                    <>
                      <section className="containerWidth pt32">
                        <div className={style.greyGrid}>
                          <div className="row">
                            <span className={style.subtitle1}>
                              {portfolioId
                                ? "Edit Portfolio Name"
                                : "Name your new portfolio"}
                            </span>
                            <div
                              style={{
                                width: "100%",
                                paddingTop: 12,
                                paddingBottom: 8,
                              }}
                            >
                              <Input
                                name="name"
                                value={values.name}
                                onChange={(e) => {
                                  setValidateFormOnChange(true);
                                  handleInputChange(setFieldValue, e, "name");
                                }}
                                className="gridInput"
                                placeholder="Portfolio 1"
                                maxLength={40}
                              />
                              {touched.name && errors.name && (
                                <div className="error">
                                  <img
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginRight: "5px",
                                    }}
                                    src={ImageConst.dangerIcon}
                                  />
                                  {errors.name}
                                </div>
                              )}
                            </div>
                            <div className={clsx(style.containerWidth)}>
                              <div
                                className="settingsDiv"
                                style={{ cursor: "pointer" }}
                                onClick={() => setVisibleState(!visibleState)}
                              >
                                <span className="backBtnText">
                                  More Settings
                                </span>
                                <img
                                  style={{
                                    height: visibleState ? 11 : 20,
                                    width: visibleState ? 10 : 20,
                                    marginLeft: visibleState ? "5px" : "0",
                                  }}
                                  src={
                                    visibleState
                                      ? ImageConst.openIcon
                                      : ImageConst.blueDownArrow
                                  }
                                ></img>
                              </div>
                            </div>
                            {visibleState && (
                              <section className="pt32">
                                <div style={{ padding: 0 }}>
                                  <span className={style.subtitle4}>
                                    Advanced Personalized Suggestions
                                  </span>
                                </div>
                                <span className={style.webBody}>
                                  You will receive investment suggestions based
                                  on your portfolio size and available funds.
                                </span>

                                <div className={style.inputFormPortfolio}>
                                  <div className="width280">
                                    <span className={style.input_label}>
                                      {"Total Portfolio Size"}
                                    </span>
                                    <div className={style.input_field}>
                                      <Input
                                        className={`${style.gridInput} ${style.customInputPlaceholder}`}
                                        placeholder="The value of your portfolio"
                                      />
                                    </div>
                                  </div>
                                  <div className="width280">
                                    <span className={style.input_label}>
                                      {"Investment Capital"}
                                    </span>
                                    <div className={style.input_field}>
                                      <Input
                                        className={`${style.gridInput} ${style.customInputPlaceholder}`}
                                        placeholder="Your available funds for new investments"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <span className={style.input_label}>
                                      {"Currency Symbol"}
                                    </span>
                                    <div className={style.input_field}>
                                      <Select
                                        className="jak-option-dropNew home-Watchlist font12  fontColor"
                                        optionFilterProp="children"

                                        style={{
                                          width: 128,
                                          color: "red",
                                          height: "40px",
                                        }}
                                        placeholder="EUR"
                                        optionLabelProp="label"
                                        dropdownClassName="custom-dropdown"
                                        onChange={(value) =>
                                          setFieldValue(value, "currency value")
                                        }
                                        options={[
                                          {
                                            value: "Euro",
                                            label: "Euro",
                                          },
                                          {
                                            value: "US Dollar",
                                            label: "US Dollar",
                                          },
                                          {
                                            value: "Pound",
                                            label: "Pound",
                                          },
                                          {
                                            value: "Yen",
                                            label: "Yen",
                                          },
                                        ]}
                                      />
                                    </div>
                                  </div>                                </div>
                              </section>
                            )}
                          </div>
                        </div>
                      </section>

                      <section className="containerWidth pt48">
                        <div className={style.subComponent}>
                          <span className={style.subtitle1}>
                            {portfolioId
                              ? "Edit holdings manually"
                              : "Add your holdings manually"}
                          </span>
                          <div
                            style={{
                              gap: 8,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {values?.holdings?.length > 1 && (
                              <button
                                type="button"
                                className={style.removebtn}
                                onClick={() =>
                                  setFieldValue("holdings", [
                                    { company: "", percentage: "" },
                                  ])
                                }
                              >
                                <img
                                  style={{
                                    height: 20,
                                    width: 20,
                                    marginRight: 8,
                                  }}
                                  src={ImageConst.WhiteDeleteBox}
                                ></img>
                                Remove all
                              </button>
                            )}
                            {/* Add a file popover */}
                            <FileUploadPopUp
                              screenType={"portfolio"}
                              uploadFileHadler={(data) => InputChange(data)}
                              children={<button
                                style={{ marginLeft: 5 }}
                                type="button"
                                className={style.secondryBtnM}
                              >
                                <img
                                  style={{ height: 20, width: 20 }}
                                  src={ImageConst.uploadIcon}
                                ></img>
                                {isUploading ? (
                                  <GlobalSpinner colorx={"#4295CE"} />
                                ) : (
                                  "Upload File"
                                )}
                              </button>}
                            />
                          </div>
                        </div>
                      </section>
                      <section className="containerWidth pt16">
                        <span className={style.webBody}>
                          You can manage your holdings in multiple ways. You can
                          manually add holdings directly into the table below,
                          upload a list of holdings, or both. You can also
                          review and edit all holdings here. In order to view a
                          company's return and stock price, you need to enter
                          its <b>ticker symbol</b>.
                        </span>
                        <br />
                        <span
                          className={style.webBody}
                          style={{ paddingBottom: 8 }}
                        >
                          You can set holding <b>percentages</b> to show their
                          share in your portfolio in order to get a more precise
                          portfolio risk score. If not specified, we'll
                          distribute equally among companies
                        </span>
                      </section>
                      {validStatus && (
                        <section className="containerWidth pt32">
                          <div className={style.sucessView}>
                            <img
                              style={{
                                height: "32px",
                                width: "32px",
                                marginRight: "5px",
                              }}
                              src={ImageConst.portfolioInfo}
                            />
                            <div>
                              <span className={style.successMessage}>
                                {invalidCompany == 0
                                  ? "Your list of holdings has been processed successfully!"
                                  : `Your list of holdings has been processed. Recognized holdings have been added to your portfolio. Any unrecognized or incorrectly formatted entries can be corrected or skipped by you. Currently, ${validPercentage}% of the holdings from your file are covered.`}
                                {invalidCompany > 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 5,
                                    }}
                                  >
                                    <div>
                                      <img
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                        src={ImageConst.validPng}
                                      />
                                      <span className={style.successMessage}
                                        style={{ marginLeft: 5 }}

                                      >
                                        {"Successfully Uploaded:"}
                                        <span
                                          className={style.successItem}
                                        >{`${validCompany} Items`}</span>
                                      </span>
                                    </div>
                                    <div>
                                      <img
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                        }}
                                        src={ImageConst.invalidPng}
                                      />
                                      <span
                                        className={style.successMessage}
                                        style={{ marginLeft: 5 }}
                                      >
                                        {"Failed to Upload:"}
                                        <span
                                          className={style.successItem}
                                        >{`${invalidCompany} Items`}</span>
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </section>
                      )}

                      <section
                        className="containerWidth "
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 20,
                          borderBottom: "1px solid #D8DAE0",
                          borderTop: "1px solid #D8DAE0",
                        }}
                      >
                        <div
                          style={{
                            borderRight: ".5px solid #D8DAE0",
                            padding: "6px 10px 6px 10px",
                          }}
                        >
                          <Checkbox
                            onChange={allSelectTableItemsHandler}
                            indeterminate={
                              renderTableData?.length > 0 &&
                              renderTableData?.filter((item) => item?.isSelected)
                                ?.length > 0 &&
                              renderTableData?.filter((item) => item?.isSelected)
                                ?.length !== renderTableData.length
                            }
                            checked={
                              renderTableData?.length > 0 &&
                              renderTableData?.filter((item) => item?.isSelected)
                                ?.length === renderTableData?.length
                            }
                          />
                        </div>
                        <div
                          style={{
                            padding: "8px 0px 8px 10px",
                          }}
                        >
                          <div
                            style={{
                              borderRight: ".5px solid #D8DAE0",
                              color: "#0095CE",
                              paddingRight: "10px",
                              fontSize: 13,
                              fontWeight: 600,
                            }}
                          >
                            {tabelDataSelectedItemLength} selected
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "6px 0px 6px 6px",
                            marginLeft: 18,
                            cursor: "pointer",
                          }}
                          onClick={removeTableSelectedItemsHandler}
                        >
                          <img
                            style={{
                              height: 16,
                              width: 16,
                              marginRight: 9,
                            }}
                            src={ImageConst.trashIcon1}
                          />
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          >
                            Remove
                          </div>
                        </div>
                      </section>

                      <section className="containerWidth"
                        style={{
                          marginBottom: 20,
                        }}
                      >
                        <Table
                          className="tableCreatePortfolio"
                          dataSource={renderTableData}
                          columns={columns}
                          rowClassName={(record, index) => rowEdit(index) && "redBG"}
                          // pagination={{ pageSize: 20 }}
                          pagination={false}
                          onRow={(record, rowIndex) => {
                            return {
                              onMouseEnter: () =>
                                setActiveTableRowIndex(rowIndex),
                              onMouseLeave: () => {
                                setActiveTableRowIndex(null);
                              },
                            };
                          }}
                          loading={getEditDetailLoader}
                        />
                        {tabeldata?.length > 10 ? (<div style={{ marginBottom: "12px" }}>
                          <BottomPagination
                            paginationHandler={setPaginationPayload}
                            paginationPayload={paginationPayload}
                            dataLength={tabeldata?.length || 0}
                          />
                        </div>) : <div style={{ paddingBottom: 10 }} />}
                        {!tabeldata?.length > 0 && (
                          <div className={style.inputContainer2N}>
                            <div
                              style={{
                                display: "flex",
                                marginLeft: 10,
                                padding: "8px 0px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const clone = [];
                                const dummyObj = {
                                  "Company name": "",
                                  index: 0,
                                  isin: "",
                                  name: "",
                                  "portfolio weight": "",
                                  searchBase: null,
                                  ticker: "",
                                  tickers: {},
                                };
                                clone.push(dummyObj);
                                dispatch(
                                  updateUploadFilePortFolioAction(clone, "10")
                                );
                              }}
                            >
                              + Add New Row
                            </div>
                          </div>
                        )}
                        {/* <div className={style.inputContainer2}>
                          <div></div>
                          <div>
                            <div
                              className="width532"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: "20px 0 20px 0px",
                              }}
                            >
                              <span className={style.subtitle4}>
                                {`Total Percentage: ${formatNumber(totalAdd)}`}
                              </span>
                            </div>
                          </div>
                        </div> */}
                      </section>
                    </>
                  ) : (
                    <>
                      <section className="containerWidth">
                        <span
                          className={`${style.subtitle4} ${style.subtitle41}`}
                        >
                          You can define alert rules for your portfolio to be
                          immediately informed about the last updates. You can
                          skip this step and visit the advanced settings page
                          later to complete it at your convenience.
                        </span>
                      </section>
                      <section className="containerWidth pt16">
                        <div className={style.subComponentNoPadding}>
                          <span className={style.subtitle2}>Risk Score</span>
                        </div>
                      </section>
                      <section className="containerWidth pt12">
                        <div style={{ display: "grid", gap: "16px" }}>
                          <span className={style.webBody}>
                            Give me alerts when a company risk score gets:
                          </span>
                          <Radio.Group
                            onChange={(e) =>
                              handleRadioChange(setFieldValue, e)
                            }
                            value={values.riskScore}
                          >
                            <Space direction="vertical">
                              <Radio
                                className="portfolio_radio"
                                name="riskScore"
                                value={"higher than 70"}
                              >
                                Higher than{" "}
                                <InputNumber
                                  min={0}
                                  max={100}
                                  value={riskScore}
                                  onChange={(e) => setRiskScore(e)}
                                />
                              </Radio>
                              <Radio
                                className="portfolio_radio"
                                name="riskScore"
                                value={"any increase more than 10 percent"}
                              >
                                Any increase more than{" "}
                                <InputNumber
                                  min={0}
                                  max={100}
                                  value={riskValue}
                                  onChange={(e) => setRiskValue(e)}
                                />{" "}
                                percent
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </div>
                      </section>
                      <section className="containerWidth pt16">
                        <div className={style.subComponentNoPadding}>
                          <span className={style.subtitle2}>
                            Sentiment Shift
                          </span>
                        </div>
                      </section>
                      <section className="containerWidth pt12">
                        <div style={{ display: "grid", gap: "16px" }}>
                          <span className={style.webBody}>
                            Monitor companies that experience a significant
                            negative shift in their sentiment analysis and give
                            me an instant email notification
                          </span>
                          <Checkbox
                            className="portfolio_checkboxes"
                            onChange={handleChange}
                            name="sentimentShiftAlert"
                            checked={values.sentimentShiftAlert}
                          >
                            Sentiment Shift Alert
                          </Checkbox>
                        </div>
                      </section>
                      <section className="containerWidth pt16">
                        <div className={style.subComponentNoPadding}>
                          <span className={style.subtitle2}>
                            High Severity Incident
                          </span>
                        </div>
                      </section>
                      <section className="containerWidth pt12 pb139">
                        <div style={{ display: "grid", gap: "16px" }}>
                          <span className={style.webBody}>
                            Identify companies with ESG incidents that have a
                            high severity score and give me an instant email
                            notification
                          </span>
                          <Checkbox
                            className="portfolio_checkboxes"
                            onChange={handleChange}
                            name="highSeverityScoreAlert"
                            checked={values.highSeverityScoreAlert}
                          >
                            High Severity Incident Alert
                          </Checkbox>
                        </div>
                      </section>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreatePortfolio;

const DoneItem = ({
  searchString,
  companyName,
  companyIcon,
  ticker,
  key,
  compId = "",
  isin = "",
  alter_name = "",
}) => {
  const highlightedCompanyName = highlightMatch(searchString, companyName?.slice(0, 20), "company");
  const companyEllipse = companyName?.length > 25 ? "..." : ""
  const highlightedTickerSymbol = highlightMatch(searchString, ticker.symbol, "ticker");
  const highlightedTickerID = highlightMatch(searchString, ticker.tickerId, "ticker");
  const highlightedAlter_names = alter_name?.length > 0 ? highlightMatch(searchString, alter_name?.slice(0, 10), "alter") : "";
  const alterEllipse = alter_name?.length > 10 ? "..." : ""
  let highlightedIsin = highlightMatch(searchString, isin, "isin");
  const countryName = ticker?.country?.length > 0 && countries.getName(ticker?.country, "en") || ""
  const countryCode = countryName?.length > 0 && countries.getAlpha2Code(countryName, 'en') || ""

  return (
    <div
      key={key}
      style={{
        display: "flex",
        paddingBottom: 5,
        justifyContent: "space-between",
        alignItems: "end",
        borderBottom: ".5px solid rgb(216,218,224)",
      }}
    >
      <div style={{ width: "100%", height: "35px", gap: "4px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ width: "12%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={`https://logos-svg.komo.systems/${compId}.png` || companyIcon}
            alt="companyImage"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ImageConst.Apple;
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "65%", }}>
          <div
            style={{
              fontSize: "13px",
              fontFamily: "inter",
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: highlightedCompanyName?.trim() + companyEllipse }} />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "10px",
            color: "#4E515C",
            fontFamily: "inter",
          }}>
            <div style={{ overflow: "hidden" }} >
              {countryCode && <ReactCountryFlag countryCode={countryCode} svg={countryName} style={{ width: 12, height: 12, borderRadius: "100%" }} />}
            </div>
            {ticker?.symbol?.length > 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
              {" : "}
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : ticker?.symbol?.length > 0 && ticker?.tickerId?.length == 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
            </div> : ticker?.symbol?.length == 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : null}
          </div>
        </div>
        <div style={{
          width: "28%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          fontSize: "10px",
          color: "#4E515C",
          fontFamily: "inter",
          textAlign: "end"
        }}>
          <span dangerouslySetInnerHTML={{ __html: highlightedAlter_names?.trim() + alterEllipse }} />
          <span dangerouslySetInnerHTML={{ __html: highlightedIsin }} />
        </div>
      </div>
    </div >
  );
};


// src/components/CountrySelector.js





const CountrySelector = ({ language = 'en' }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const countryNames = countries.getNames(language, "en");
    const countryOptions = Object.entries(countryNames).map(([alpha2, name]) => {
      const alpha3 = countries.alpha2ToAlpha3(alpha2);
      return {
        value: alpha3,
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ReactCountryFlag countryCode={alpha2} svg style={{ marginRight: '10px' }} />
            {name}
          </div>
        ),
      };
    });
    setOptions(countryOptions);
  }, [language]);

  return options;
};



