import React from "react";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import ReactApexChart from "react-apexcharts";
import { ESG } from "../../../../Utils";

const IncidentsNumbers = ({ data, options }) => {
  const getPeerIncident = (data) => {
    let series = [
      {
        data: [],
      },
      {
        data: [],
      },
      {
        data: [],
      },
    ];
    if (data) {
      for (let i in data) {
        let esg_categories = data[i].esg_categories;
            series[0].data.push(esg_categories.Environmental);
            series[1].data.push(esg_categories.Social);
            series[2].data.push(esg_categories.Governance);
        }
    }
    return series;
  };

  return (
    <ChartBottomTitle icon={true} legend={ESG} title={"Incidents numbers"}>
      <ReactApexChart
        options={options}
        series={getPeerIncident(data)}
        type="bar"
        height={300}
        width={330}
      />
    </ChartBottomTitle>
  );
};

export default IncidentsNumbers;
