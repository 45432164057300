import React from 'react';
import ButtonContainer from '../ButtonContainer';
import UndoButton from './UndoButton';
import RedoButton from './RedoButton';

function UndoRedoButtons() {
  return (
    <ButtonContainer>
      <UndoButton />
      <RedoButton />
    </ButtonContainer>
  );
}
export default UndoRedoButtons;
