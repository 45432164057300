import { ValidationConstant } from "../Constants/validationConstant";
import * as Yup from "yup";

export const loginSchema = {
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ValidationConstant.validEmail
    )
    .email(ValidationConstant.validEmail)
    .max(255)
    .required(ValidationConstant.emptyEmail),
  password: Yup.string()
    .required(ValidationConstant.emptyPass)
    .matches(/^(\S+$)/, ValidationConstant.validPass),
};

export const signSchema = {
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ValidationConstant.validEmail
    )
    .email(ValidationConstant.validEmail)
    .required(ValidationConstant.emptyEmail),

  password: Yup.string()
    .required(ValidationConstant.emptyPass)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      ValidationConstant.validatePassword
    ),

  // firstName: Yup.string()
  //   .required(ValidationConstant.vaildFirstname)
  //   .matches(/^[A-Za-z]+$/, ValidationConstant.validFirstName)
  //   .min(3, ValidationConstant.entrvalidFirstName),

  // lastName: Yup.string()
  //   .required(ValidationConstant.vaildLastname)
  //   .matches(/^[A-Za-z]+$/, ValidationConstant.validLastName)
  //   .min(3, ValidationConstant.entrvalidFirstName),

  // mobileNumber: Yup.string()
  //   .required(ValidationConstant.reqMobile)
  //   .matches(
  //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  //     ValidationConstant.validMobile
  //   )
  //   .min(10, "Minimum length of mobile number should be 10."),
};

export const forgotSchema = {
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ValidationConstant.validEmail
    )
    .email(ValidationConstant.validEmail)
    .max(255)
    .required(ValidationConstant.emptyEmail),
};

export const changePassSchema = {
  oldPassword: Yup.string().required(ValidationConstant.plzEntrOldPass),
  newPassword: Yup.string()
    .min(6, ValidationConstant.minPass)
    .required(ValidationConstant.validatePassword),
};

export const resetPasswordSchema = {
  newPassword: Yup.string()
    .required(ValidationConstant.plzEntrNewPass)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      ValidationConstant.validatePassword
    ),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], ValidationConstant.passShouldBeSame)
    .required(ValidationConstant.plzEntrNewPass)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      ValidationConstant.validatePassword
    ),
};

export const validationSchema = (schema) => {
  return Yup.object().shape(schema);
};

export const formikFormat = (initalValues, Schema, onSubmit) => {
  return {
    initialValues: initalValues,
    validationSchema: validationSchema(Schema),
    onSubmit,
  };
};

export const profileValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email address"
    )
    .required("Email is required"),
});


// ********************INITIAL VALUE*****************************

export const initialProfileValues = {
  username: "",
  phoneNumber: "",
  email: "",
};

export const initialValuesLogin = {
  email: "",
  password: "",
};

export const initalValuesSignup = {
  email: "",
  password: "",
  // firstName: "",
  // lastName: "",
  // mobileNumber: "",
};

export const initalValuesChangePass = {
  oldPassword: "",
  newPassword: "",
};

export const iv_ForgotPassword = {
  email: "",
};
export const initailValueResetPass = {
  newPassword: "",
  repeatPassword: "",
};
