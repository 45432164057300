import { useMemo, useState } from 'react';
import { setHiddenNodes } from '../../../../../redux/GraphExploration/graphSlice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../redux/configuareStore';
import { useGraphObject } from '../../../../context/MyContext';
import { getConnectingEdgesToShow } from '../../../../../utils';
import { CustomAccordionDetails } from '../../../../../components/CustomeAccordion';
import SearchBar from '../../../../../components/SearchBar';
import ListItem from './ListItem';

function CompanyListAccordion() {
  const nodes = useAppSelector((state) => state.graph.nodes);
  const edges = useAppSelector((state) => state.graph.edges);
  const nodeIds = nodes.map((node) => node.id);
  const edgeIds = edges.map((edge) => edge.id);
  const hiddenNodes = useAppSelector((state) => state.graph.hiddenNodes);
  const allItemsAreHidden = useMemo(
    () => nodeIds.every((id) => hiddenNodes.includes(id)),
    [nodeIds, hiddenNodes],
  );
  const dispatch = useAppDispatch();
  const { graphObject } = useGraphObject();
  const [searchQuery, setSearchQuery] = useState('');
  if (nodes.length === 0) {
    return null;
  }
  let filteredNodes = nodes.filter((item) =>
    item.data.label?.toLowerCase().startsWith(searchQuery.toLowerCase()),
  );
  if (searchQuery.length === 0) {
    filteredNodes = [{ id: 'all', data: { label: 'All' } }, ...filteredNodes];
  }
  return (
    <CustomAccordionDetails>
      <SearchBar
        value={searchQuery}
        onChange={(value) => {
          setSearchQuery(value);
        }}
      />
      {filteredNodes.map((node) => {
        const label = `${node.data.label}`;
        const isShown =
          node.id === 'all'
            ? !allItemsAreHidden
            : !hiddenNodes.includes(node.id);
        const itemId = node.id;
        return (
          <ListItem
            key={itemId}
            label={label}
            isShown={isShown}
            onClick={() => {
              if (isShown) {
                if (itemId === 'all') {
                  dispatch(setHiddenNodes(nodeIds));
                  graphObject?.hideItem(nodeIds);
                } else {
                  graphObject?.hideItem(itemId);
                  if (!hiddenNodes.includes(itemId))
                    dispatch(setHiddenNodes([...hiddenNodes, itemId]));
                }
              } else if (itemId === 'all') {
                graphObject?.showItem(nodeIds);
                graphObject?.showItem(edgeIds);
                dispatch(setHiddenNodes([]));
              } else {
                graphObject?.showItem(itemId);
                const newHiddenNodes = hiddenNodes.filter(
                  (id) => id !== itemId,
                );
                const shownNodes = nodeIds.filter(
                  (id) => !newHiddenNodes.includes(id),
                );
                const edgesToShow = getConnectingEdgesToShow(shownNodes);
                graphObject?.showItem(edgesToShow);
                dispatch(setHiddenNodes(newHiddenNodes));
              }
            }}
          />
        );
      })}
    </CustomAccordionDetails>
  );
}
export default CompanyListAccordion;
