import { Button } from "antd";
import { useEffect, useState } from "react";
import { dispatch } from "../../Utils/dispatchNavigate";
import { getIncidentTimelineAction } from "../../Redux/Action/comapanyPageAction";

export const ChartCardNew = ({
  children,
  legend,
  title,
  disablePadding,
  childClass,
  className,
  network,
}) => {
  const [activeTab, setActivetab] = useState("ESG Factors");
  // useEffect(() => {
  //   dispatch(
  //     getIncidentTimelineAction({
  //       ...network,
  //       ...{
  //         type:
  //           activeTab == "ESG Factors"
  //             ? "esg_factors"
  //             : activeTab == "SDG"
  //             ? "esg_SDG"
  //             : activeTab == "PAI"
  //             ? "esg_PAI"
  //             : "esg_factors",
  //       },
  //     })
  //   );
  // }, [activeTab]);
  return (
    <section>
      <div className={`container most-sec-over g-0 mt-32 ${className}`}>
        <div className="row">
          <div className="col ">
            <div className="caot-sec1">
              <div className="over-time">
                <h5>{title}</h5>
              </div>
              {/*   <div className="ellips">
                     {legend?.map((data) => {
                    return (
                      <Button  onClick={()=>{setActivetab(data?.tittle)}} className="legendButton"
                      style={{backgroundColor:activeTab===data?.tittle?'#0195CE':"#fff"}}
                      >
                        <p>
                        {data.tittle}
                        </p>
                     
                      </Button>
                    );
                  })} 
                </div>*/}
            </div>
          </div>
          <div
            className={
              (!disablePadding || childClass) &&
              `line-chart incident-timeline-chart ${childClass}`
            }
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};
