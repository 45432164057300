import { memo } from "react";
import ImageConst from "../../../Themes/ImageConstant";
import style from "../style.module.css";
import portFolioStyle from "../../../Screens/portfolio/style.module.css";

const Confirmation = ({ OK, CANCEL, description, icon }) => {
  return (
    <div>
      <img style={{ height: 56, width: 56, marginTop: '12px' }} src={ImageConst.FrameTick} />
      <div className={style.confirmation_text_container}>
        <span className={style.confirmation_text}>
          Your portfolio has been created!<br/> You can view it now!

        </span>
        <span className={style.confirmation_text  }>
        <br/>
        Note*: We are calculating the risk and you will be able to check your risk in few minutes. Please refresh in sometime to check risk
        </span>

      </div>
      <button onClick={() =>  OK.onClick()} type="submit" className={portFolioStyle.btn}>
        <div className={style.nextBtn}>
        {OK.name}
        </div>
      </button>
    </div>
  );
};

export default memo(Confirmation);
