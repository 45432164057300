import { apiConstants as types } from "../../Constants/apiConstants";

// Login Action
export const loginAction = (data) => ({
  type: types.API_LOGIN_LOAD,
  data,
});
// Register Action
export const registerAction = (data) => ({
  type: types.API_REGISTER_LOAD,
  data,
});
// Verify Email Via link
export const verifyMailAction = (data) => ({
  type: types.API_VERIFY_EMAIL_LOAD,
  data,
});

// Social Login Action
export const socialloginAction = (data) => ({
  type: types.API_SOCIAL_LOGIN_LOAD,
  data,
});

// Logout Action
export const logoutAction = () => ({
  type: types.API_LOGOUT_LOAD,
});

// forgot password Action
export const forgotPasswordAction = (data) => ({
  type: types.API_FORGOT_PASSWORD_LOAD,
  data,
});
//reset-password Email
export const resetPasswordEmail = (data) => (
  {
    type: types.API_RESET_EMAIL_PASSWORD_LOAD,
    data,
  }
);
export const resetPasswordAction = (data) => ({
  type: types.API_RESET_PASSWORD_LOAD,
  data,
});

export const checkResetPasswordAction = (id) => ({
  type: types.API_CHECK_RESET_PASSWORD_LOAD,
  id,
});

export const changePasswordAction = (data) => ({
  type: types.API_CHANGE_PASSWORD_LOAD,
  data,
});

export const verifiedEmailAction = (data) => ({
  type: types.API_VERIFIED_PASSWORD_LOAD,
  data,
});

export const resendAction = (data) => ({
  type: types.API_RESEND_LOAD,
  data,
});
