import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import { useSelector } from "react-redux";

var graphic = echarts.graphic;

const dataBar = (value, color) => ({
  value: value,
  itemStyle: {
    borderRadius: [5, 5, 0, 0],
    color: new graphic.LinearGradient(0, 0, 0, 1.5, [
      {
        offset: 0,
        color: color,
      },
      {
        offset: 1,
        color: "rgba(255,255,255,0.8)",
      },
    ]),
  },
});

const options = (data) => ({
  label: {
    show: false,
  },
  toolbox: {
    show: false,
  },
  tooltip: {
    show: false,
  },
  xAxis: {
    data: ["E S G", "E S G", "E S G"],
    axisLine: { onZero: false },
    splitLine: { show: false },
    splitArea: { show: false },
  },
  yAxis: {},
  grid: {
    bottom: 100,
  },
  legend: {
    show: false,
  },

  series: data && [
    {
      name: "bar1",
      type: "bar",
      stack: "one",
      data: [
        dataBar(data?.Environmental?.positive?.length, "#1A9C3E"),
        dataBar(data?.Environmental?.negative?.length, "#D00000"),
        dataBar(data?.Environmental?.neutral?.length, "#B6BCC7"),
      ],
    },
    {
      name: "bar2",
      type: "bar",
      stack: "two",
      data: [
        dataBar(data?.Social?.positive?.length, "#1A9C3E"),
        dataBar(data?.Social?.negative?.length, "#D00000"),
        dataBar(data?.Social?.neutral?.length, "#B6BCC7"),
      ],
    },
    {
      name: "bar3",
      type: "bar",
      stack: "three",
      data: [
        dataBar(data?.Governance?.positive?.length, "#1A9C3E"),
        dataBar(data?.Governance?.negative?.length, "#D00000"),
        dataBar(data?.Governance?.neutral?.length, "#B6BCC7"),

      ],
    },
  ],
});

const TotalSentiment = ({ data }) => {
  return (
      <div className="barchart-ps">
        <ReactEcharts className={"groupChart"} option={options(data)}  />
        <p className="chart-bottom-title">
          Total sentiment on all sources separated by ESG category
        </p>
      </div>
  );
};

export default TotalSentiment;
