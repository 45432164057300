import React from "react";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  let tokenCheck = localStorage.token;
  let sessiontokenCheck = sessionStorage.token;
  
  return tokenCheck || sessiontokenCheck ? children : <Navigate to={`/`} />;
};

export const PublicRoute = ({ children }) => {
  let tokenCheck = localStorage.token;
  let sessiontokenCheck = sessionStorage.token;

  return tokenCheck || sessiontokenCheck ? <Navigate to="/dashboard" /> : children;
};
