import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  baseUrlApi: '',
};
const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setBaseUrlApi: (state, { payload: baseUrlApi }) => {
      // eslint-disable-next-line no-param-reassign
      state.baseUrlApi = baseUrlApi;
    },
  },
});
export const { setBaseUrlApi } = apiSlice.actions;
export const apiReducer = apiSlice.reducer;
