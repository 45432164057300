import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import { useFormik } from "formik";
import {
  changePassSchema,
  formikFormat,
  initalValuesChangePass,
} from "../../Utils/schema";
import { dispatch, navigate } from "../../Utils/dispatchNavigate";
import { changePasswordAction } from "../../Redux/Action/authentication";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { PasswordInput } from "../../Componets/PasswordInput";
import { useSelector } from "react-redux";
import { getProfileAction,profileDeleteAction } from "../../Redux/Action/accoutSettngAction";

const Password = (props) => {
  const [modal2Open, setModal2Open] = useState(false);
  const { userEmail, userName } = useSelector(
    (state) => state?.accountSettingReducer
  );
  useEffect(() => {
    dispatch(getProfileAction());
  }, []);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    isInternetConnected() && dispatch(changePasswordAction(values));
  };

  const formik = useFormik(
    formikFormat(initalValuesChangePass, changePassSchema, onSubmit)
  );

  return (
    <div className="side-padding sodebar-left-padding">
      <div className="pro-page-bg padding-top-pass">
       
        <div className="flex-pro">
          <div className="profile-sec">
            <div className="circle-image">
              <span className="intialsText">
                {userName
                  ? userName?.charAt(0).toUpperCase()
                  : userEmail?.charAt(0)}
              </span>
            </div>
          </div>
          <div className="proname">
            <h4>{userName}</h4>
            <p>{userEmail}</p>
          </div>
        </div>

        <div className="edit-prof-text-tp mt-4">
          <h5>Password</h5>
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="us-name-in mt-4">
              <PasswordInput
                placeholder=""
                label={"Old Password"}
                name={"oldPassword"}
                onChange={formik.handleChange}
                value={formik.values.oldPassword}
                error={formik.errors.oldPassword}
                iconClsEye={"pass-iconClsEye-um"}
                iconClsX={"pass-iconClsX-um"}
              />
            </div>
            <div className="us-name-in mt-4">
              <PasswordInput
                placeholder=""
                label={"New Password"}
                name={"newPassword"}
                onChange={formik.handleChange}
                error={formik.errors.newPassword}
                value={formik.values.newPassword}
                iconClsEye={"pass-iconClsEye-um"}
                iconClsX={"pass-iconClsX-um"}
              />
              {/* <p>Minimum 6 characters</p> */}
            </div>

            <div className="delete-sec mt-5 mb-5">
              <div className="delete-btn-link">
                <Button
                  className="dlt-acc-um"
                  type="primary"
                  onClick={() => setModal2Open(true)}
                >
                  Delete Account
                </Button>
                {modal2Open && (
                  <Modal
                    className="cross-model deleteModal"
                    title=""
                    centered
                    open={modal2Open}
                    closable={false}
                    onCancel={() => setModal2Open(false)}
                    footer={[
                      <div style={{ paddingBottom: 6, marginTop: "48px" }}>
                        <button
                          className="first-btn"
                          onClick={() => setModal2Open(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="sec-btn"
                          onClick={() => dispatch(profileDeleteAction())}
                        >
                          Delete My Account
                        </button>
                      </div>,
                    ]}
                  >
                    <div className="delete-popup">
                      <div className="popup-pic">
                        <img src={Images.global} />
                      </div>
                      <div className="pop-heading">
                        <h4>We’re sorry to see you go</h4>

                        <p className="dlt-mgs-txt-um">
                          If your email notifications are too many, you can
                          change your notification settings{" "}
                          <a onClick={() => navigate("/emailnotification")}>
                            here
                          </a>
                          , or if you want to change your subscription plan you
                          can do that{" "}
                          <a onClick={() => navigate("/plans")}>here</a>.
                        </p>

                        <h6>
                          Be aware that account deletion is final. There will be
                          no way to restore your account.
                        </h6>
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
              <div className="us-btn">
                <button type="submit">Save Changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Password;
