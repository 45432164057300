import moment from "moment";
import { convertTimestampinFormatedDate } from "./globalFunction";
import { navigate } from "./dispatchNavigate";

let activeTooptilpsId = 0;

export const formatTooltip = ({ series, seriesIndex, dataPointIndex, w }) => {
  activeTooptilpsId = w?.config.series[seriesIndex].data[dataPointIndex][3][1];
  const title = w?.config.series[seriesIndex].data[dataPointIndex][3][0];
  let type = w?.config.series[seriesIndex].name;

  if (type === "Incident Time Distribution") {
    type = "Number of Articles";
  }
  const date = convertTimestampinFormatedDate(
    w?.config.series[seriesIndex].data[dataPointIndex][0],
    "month"
  );
  const number = w?.config.series[seriesIndex].data[dataPointIndex][1];

  return `
    <div style="width:200px;margin:5px; border-radius:5px;">
    <div style="font-weight:bold;font-size:14px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
      ${date}
      </div>
    <div style="font-weight:bold;font-size:14px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
    ${title?.title || title}
    </div>
    <div class="tooltip-item">${type}: ${number}</div>
  </div>
    `;
};

export const tooltipsHandler = ({}) => {
  // navigate("/incidentdetails", {
  //   state: { id: activeTooptilpsId },
  // });
  window.open(`/incidentdetails?${activeTooptilpsId}`, "_blank");
};

export const structureData = (dataList, type, key) => {
  const formattedData = [];
  for (const [dateString, deepArray] of dataList) {
    let dateInMilliseconds;
    if (type == "hour") {
      let date = moment(dateString).format("MMM DD, YYYY, HH:mm:ss");
      dateInMilliseconds = parseInt(moment(date).format("x"));
    } else if (type == "day") {
      if (key == "severity") {
        let date = moment(dateString).format("DD");
        dateInMilliseconds = parseInt(moment(dateString).format("x"));
      } else {
        let date = moment(dateString).format("DD");
        dateInMilliseconds = parseInt(moment(dateString).format("x"));
      }
    } else if (type == "week") {
      const date = moment(dateString);
      const weekNumber = date.week();
      const formattedDate = date.format("MMM DD, YYYY, HH:mm:ss");
      dateInMilliseconds = parseInt(moment(dateString).format("x"));
    } else if (type == "year") {
      let date = moment(dateString).format("DD");
      dateInMilliseconds = parseInt(moment(dateString).format("x"));
    } else {
      dateInMilliseconds = parseInt(moment(dateString).format("x"));
    }
    if (deepArray?.length > 0) {
      if (type === "Incident Timeline") {
        formattedData.push([
          dateInMilliseconds,
          6.5,
          deepArray?.length > 2 ? deepArray?.length : 4,
          deepArray,
        ]);
      } else if (type === "disData") {
        for (let i in deepArray) {
          formattedData.push([
            dateInMilliseconds,
            1,
            deepArray?.length,
            deepArray,
          ]);
        }
      } else if (key === "Calledxx") {
        for (let i in deepArray) {
          formattedData.push([
            dateInMilliseconds,
            1,
            deepArray?.length,
            deepArray,
            deepArray[i].esg_categories[0],
          ]);
        }
      } else if (key === "Calledx") {
        for (let i in deepArray) {
          formattedData.push([
            dateInMilliseconds,
            1,
            deepArray?.length,
            deepArray,
          ]);
        }
      } else if (key === "disOverTime") {
        for (let i in deepArray) {
          formattedData.push([
            dateInMilliseconds,
            deepArray?.length,
            deepArray?.length,
            deepArray,
          ]);
        }
      } else if (key === "Calledx2") {
        for (let i in deepArray) {
          formattedData.push([
            dateInMilliseconds,
            2,
            deepArray?.length,
            deepArray,
          ]);
        }
      } else {
        for (let i in deepArray) {
          formattedData.push([
            dateInMilliseconds,
            deepArray[i].count,
            deepArray[i].count > 10 ? deepArray[i].count : 10,
            [deepArray[i].title, deepArray[i].id],
          ]);
        }
      }
    }
  }

  return formattedData;
};

// Function to convert a date and time string to Unix timestamp
const dateTimeStringToTimestamp = (dateTimeString) => {
  const timestamp = new Date(dateTimeString).getTime();
  return timestamp;
};


export const structureDataNew = (dataList, type, key) => {
  const formattedData = [];
  for (const [dateString, deepArray] of dataList) {


  //   let dateInMilliseconds;
  //   if (type == "hour") {
  //     let date = moment(dateString).format("MMM DD, YYYY, HH:mm:ss");
  //     dateInMilliseconds = parseInt(moment(date).format("x"));
  //   } else if (type == "day") {
  //     if (key == "severity") {
  //       let date = moment(dateString).format("DD");
  //       dateInMilliseconds = parseInt(moment(dateString).format("x"));
  //     } else {
  //       let date = moment(dateString).format("DD");
  //       dateInMilliseconds = parseInt(moment(dateString).format("x"));
  //     }
  //   } else if (type == "week") {
  //     const date = moment(dateString);
  //     const weekNumber = date.week();
  //     const formattedDate = date.format("MMM DD, YYYY, HH:mm:ss");
  //     dateInMilliseconds = parseInt(moment(dateString).format("x"));
  //   } else if (type == "year") {
  //     let date = moment(dateString).format("DD");
  //     dateInMilliseconds = parseInt(moment(dateString).format("x"));
  //   } else {
  //     dateInMilliseconds = parseInt(moment(dateString).format("x"));
  //   }
  //   if (deepArray?.length > 0) {
  //     if (type === "Incident Timeline") {
  //       formattedData.push([
  //         dateInMilliseconds,
  //         6.5,
  //         deepArray?.length > 2 ? deepArray?.length : 4,
  //         deepArray,
  //       ]);
  //     } else if (type === "disData") {
  //       for (let i in deepArray) {
  //         formattedData.push([
  //           dateInMilliseconds,
  //           1,
  //           deepArray?.length,
  //           deepArray,
  //         ]);
  //       }
  //     } else if (key === "Calledxx") {
  //       for (let i in deepArray) {
  //         formattedData.push([
  //           dateInMilliseconds,
  //           1,
  //           deepArray?.length,
  //           deepArray,
  //           deepArray[i].esg_categories[0],
  //         ]);
  //       }
  //     } else if (key === "Calledx") {
  //       for (let i in deepArray) {
  //         formattedData.push([
  //           dateInMilliseconds,
  //           1,
  //           deepArray?.length,
  //           deepArray,
  //         ]);
  //       }
  //     } else if (key === "disOverTime") {
  //       for (let i in deepArray) {
  //         formattedData.push([
  //           dateInMilliseconds,
  //           deepArray?.length,
  //           deepArray?.length,
  //           deepArray,
  //         ]);
  //       }
  //     } else if (key === "Calledx2") {
  //       for (let i in deepArray) {
  //         formattedData.push([
  //           dateInMilliseconds,
  //           2,
  //           deepArray?.length,
  //           deepArray,
  //         ]);
  //       }
  //     } else {
  //       for (let i in deepArray) {
  //         formattedData.push([
  //           dateInMilliseconds,
  //           deepArray[i].count,
  //           deepArray[i].count > 10 ? deepArray[i].count : 10,
  //           [deepArray[i].title, deepArray[i].id],
  //         ]);
  //       }
  //     }
  //   }
  // }
  }
  return formattedData;
};
