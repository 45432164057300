import {
  makeFullMap_ESGFactorData,
  makeFullMap_severityData,
} from "../../Componets/MapGL/ map_utils";
import { apiConstants as types } from "../../Constants/apiConstants";
import {
  createESGMapLocationArray,
  createSeverityMapLocationArray,
} from "../../Screens/fullMap/utils-data-map-function";
import { ESGCatgory } from "../../Utils";
import { getPrincipleColor } from "../../Utils/globalFunction";

const initialState = {
  isLoading: false,
  companyListLoding: false,
  result: {},
  incident: [],
  companiesWithIncident: [],
  countriesWithIncident: [],
  esgFactors: [],
  esgSdg: [],
  esgPai: [],
  iotData: {},
  companiesList: [],
  newCompaniesList: [],
  changeData: false,
  industryList: [],
  industryListStatic: [],
  topSearch: [],
  getCategories: [],
  _categoriesListStatic: [],
  companies: [],
  selectedTopSearch: [],
  incidentDetails: [],
  updatedCategories: [],
  allData: [],
  activeCategories: [],
  mapCountries: [],
  sourceDetails: [],
  mapData: [],
  searchByParams: [],
  downloadData: [],
  mapDataLoad: false,
  esg_categories_map_data: [],
  severity_map_data: {},
  ESGLocationArray: [],
  severityDataLocationArray: [],
  googleMapViewState: "ESG Category",
  incidentListModal: false,
  companyIdSearchList: [], // For Advance Search
  locationIdSearchList: [], // For Advance Search
  continents: [],
  bubbleIncidentIdList: [],
  regulatry_legal_actions: [],
  penalties_and_fines: [],
  filterESGMapData: {
    env: false,
    soc: false,
    gov: false,
  },
  filterSevertyMapData: {
    critical: false,
    high: false,
    low: false,
    med: false,
  },
  news_articles: [],
  ngo_reports: [],
  socialMediaCoverage: {
    socialMedia: {},
    sentiment: {},
  },
  timeline_graph: {
    data: {},
  },
  activeCollectionIndex: 0,
  searchValue: "",
  Successpop: false,
  supplyChainEsgCountry: [],
  esgChildListForModal: [],
  countriesListForModal: [],
  companiesListForModal: [],
  incidentsForModal: {
    data: [],
    pagination: {},
  },
  totalEsgIncidentCount: {},
  totalEsgIncidentCountLoader: false,
};

function getTopSearches(data) {
  const updatedData = [];
  for (let i in data) {
    const updatedNestedData = data[i]?.data?.map((item) => {
      return {
        ...item,
        status: false,
      };
    });

    const updatedObject = {
      ...data[i],
      data: updatedNestedData,
    };

    updatedData.push(updatedObject);
  }
  return updatedData;
}

function getAllUpdatedData(data, allData) {
  let perData = [];
  for (let i in allData) {
    if (data === allData[i]?.name) {
      const value = allData[i].value;
      const index = perData.indexOf(value);
      if (index === -1) {
        perData.push(value);
      } else {
        perData.splice(index, 1);
      }
    }
  }
  return perData;
}

function getUpdatedIncidentData(data) {
  let year = [];
  let bottomLineData = [];
  let middleLineData = [];
  let topLineData = [];

  if (data) {
    year = Object.keys(data[0].year);
    bottomLineData = data[0].year ? Object.values(data[0].year) : [];
    middleLineData = data[1].year ? Object.values(data[1].year) : [];
    topLineData = data[2]?.year ? Object.values(data[2].year) : [];
  }
  return {
    year,
    bottomLineData,
    middleLineData,
    topLineData,
  };
}

function getDataSupply(data) {
  let arr = [];
  if (data.length > 0) {
    for (let i in data) {
      let obj = {
        label: data[i].name,
        value: data[i].name,
        id: data[i]._id,
      };
      arr.push(obj);
    }
  }
  return arr;
}

function updateIndus(payload, data, status) {
  let arr = [];
  if (payload?.Arr?.length > 0) {
    let dataA = payload.Arr
    if (dataA.length > 0) {
      for (let i in dataA) {
        if (payload.key == "parent") {
          if (dataA[i]._id == payload.data._id) {
            dataA[i].status = !dataA[i].status;
            for (let j in dataA[i].data) {
              dataA[i].data[j].status = dataA[i].status;
            }
          }
        } else {
          if (dataA[i]._id === payload.parent._id) {
            for (let j in dataA[i].data) {
              if (payload.data.name === dataA[i].data[j].name) {
                dataA[i].data[j].status = status
                  ? status
                  : !dataA[i].data[j].status;
                let filterParent = dataA[i].data.filter((itm) => itm.status);
                if (filterParent && filterParent.length == dataA[i].data.length) {
                  dataA[i].status = true;
                } else {
                  dataA[i].status = false;
                }
              }
            }
          }
        }
      }
    }
    arr = dataA;
    return arr;
  } else {
    if (data.length > 0) {
      for (let i in data) {
        if (payload.key == "parent") {
          if (data[i]._id == payload.data._id) {
            data[i].status = !data[i].status;
            for (let j in data[i].data) {
              data[i].data[j].status = data[i].status;
            }
          }
        } else {
          if (data[i]._id === payload.parent._id) {
            for (let j in data[i].data) {
              if (payload.data.name === data[i].data[j].name) {
                data[i].data[j].status = status
                  ? status
                  : !data[i].data[j].status;
                let filterParent = data[i].data.filter((itm) => itm.status);
                if (filterParent && filterParent.length == data[i].data.length) {
                  data[i].status = true;
                } else {
                  data[i].status = false;
                }
              }
            }
          }
        }
      }
    }
    arr = data;
    return arr;
  }
}

function removeIndus(data) {
  let arr = [];
  if (data.length > 0) {
    for (let i in data) {
      data[i].status = false;
      for (let j in data[i].data) {
        data[i].data[j].status = false;
      }
    }
  }
  arr = data;
  return arr;
}

function checkIndusData(categories, selectedCategories) {
  function recursiveUpdate(category) {
    const selectedCategory = selectedCategories.find(
      (selected) => selected.id === category.id
    );
    if (selectedCategory) {
      category.status = true;
    } else if (category.childs && category.childs.length > 0) {
      const anyChildSelected = category.childs.some((child) =>
        recursiveUpdate(child)
      );
      category.status = anyChildSelected;
    } else {
      category.status = false;
    }

    return category.status;
  }

  categories.forEach((category) => recursiveUpdate(category));
  return categories;
}
function sortTheData(data) {
  data.sort((a, b) => {
    // Convert names to lowercase for case-insensitive sorting
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    // Compare the names
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; // names are equal
  });

  return data ? data : [];
}

export const incidentReducer = (state = initialState, action) => {
  let { payload, key } = action;
  switch (action.type) {
    case types.API_INCIDENT_LOAD:
      return { ...state, isLoading: true };
    case types.COMPANY_ID_SEARCH_LIST:
      return { ...state, companyIdSearchList: action.data };
    case types.LOCATION_ID_SEARCH_LIST:
      return { ...state, locationIdSearchList: action.data };
    case types.API_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        incident: payload.data,
      };
    case types.API_HOME_PAGE_LOAD:
      return { ...state, isLoading: true };
    case types.API_HOME_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: payload.data,
      };
    case types.API_COMPANIES_WITH_INCIDENT_LOAD:
      return { ...state, isLoading: true };
    case types.API_COMPANIES_WITH_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companiesWithIncident: payload.data,
      };
    case types.FILTER_ESG_MAP_DATA:
      return { ...state, filterESGMapData: action.payload };
    case types.FILTER_SEVERTY_MAP_DATA:
      return { ...state, filterSevertyMapData: action.payload };
    case types.API_COUNTRIES_WITH_INCIDENT_LOAD:
      return { ...state, isLoading: true };
    case types.API_COUNTRIES_WITH_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countriesWithIncident: payload.data,
      };
    case types.INCIDENT_LIST_MODAL:
      return { ...state, isLoading: true, incidentListModal: payload };
    case types.API_ESG_FACTORS_INCIDENTS_LOAD:
      return { ...state, isLoading: true };

    case types.API_ESG_FACTORS_INCIDENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        esgFactors: payload?.data,
      };

    case types.API_ESG_CHILD_LIST_FOR_MODAL_SUCCESS:
      const newData = {
        esg_factors:
          payload?.data?.esg_factors?.map((e) => e.data).flat(1) || [],
      };

      newData.esg_factors = sortTheData(newData.esg_factors);
      return {
        ...state,
        isLoading: false,
        esgChildListForModal: newData,
      };

    case types.API_COUNTRIES_LIST_FOR_MODAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countriesListForModal: payload?.data,
      };

    case types.API_COMPANIES_LIST_FOR_MODAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companiesListForModal: payload?.data?.countries,
      };

    case types.API_ESG_SDG_INCIDENTS_LOAD:
      return { ...state, isLoading: true };
    case types.API_ESG_SDG_INCIDENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        esgSdg: payload.data,
      };

    case types.API_ESG_PAI_INCIDENTS_LOAD:
      return { ...state, isLoading: true };
    case types.API_ESG_PAI_INCIDENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        esgPai: payload.data,
      };

    //ESG category
    case types.API_ESG_CAT_LOAD:
      return { ...state, isLoading: true };
    case types.API_ESG_CAT_SUCCESS:
      const payLoadData = payload.data;
      const array = [payLoadData[0], payLoadData[2], payLoadData[1]];
      return {
        ...state,
        isLoading: false,
        esgCategory: array,
      };
    //ESG IOT
    case types.API_ESG_IOT_LOAD:
      return { ...state, isLoading: true };
    case types.API_ESG_IOT_SUCCESS:
      let updatedData = {
        year: [],
        topLineData: [],
        middleLineData: [],
        bottomLineData: [],
      };
      if (payload.data?.length > 0) {
        updatedData = getUpdatedIncidentData(payload.data);
      }
      return {
        ...state,
        isLoading: false,
        iotData: updatedData,
      };
    //get Companies List
    case types.API_GET_COMPANIES_LOAD:
      return {
        ...state,
        isLoading: true,
        companyListLoding: true,
      };
    case types.API_GET_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companyListLoding: false,
        companiesList: payload?.data?.countries,
        newCompaniesList: payload?.data?.countries,
      };
    case types.UPDATE_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companiesList: action.data,
        newCompaniesList: action.data,
      };
    //get industry list
    case types.API_GET_INDUSTRY_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_INDUSTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        industryList: payload?.data,
        industryListStatic: payload?.data,
      };
    case types.UPDATE_INDUSTRIES_DATA:
      return {
        ...state,
        industryList: payload,
      };
    case types.API_GET_TOP_SEARCH__LOAD:
      return { ...state, isLoading: true };

    case types.API_GET_TOP_SEARCH_SUCCESS:
      let topSearchData = [];
      if (payload?.data?.length > 0) {
        topSearchData = getTopSearches(payload.data);
      }
      return { ...state, isLoading: false, topSearch: topSearchData };
    //get categories
    case types.API_GET_CATEGORIES_LOAD:
      return { ...state, isLoading: true };

    case types.API_GET_CATEGORIES_SUCCESS:
      const categories = Object.keys(payload?.data).map((key) => ({
        name: ESGCatgory[key],
        // name: key
        // .replace(/_/g, " ")
        // .replace(/\b\w/g, (match) => match.toUpperCase()),
        status: false,
        value: payload?.data[key],
      }));
      // for (let i = 0; i <= categories?.length - 1; i++) {
      //   let val = categories[i]["value"];
      //   for (let j = 0; j <= val?.length - 1; j++) {
      //     let item = val[j]["data"];
      //     for (let k = 0; k <= item?.length - 1; k++) {
      //       if (!item[k]["status"]) {
      //         item[k]["status"] = false;
      //       }
      //     }
      //   }
      // }
      categories.push({
        name: "UN global compact principles",
        status: false,
        value: [
          {
            _id: "Human Rights",
            data: [
              {
                id: "PRINCIPLE 1",
                name: "PRINCIPLE 1",
                status: false,
              },
              {
                id: "PRINCIPLE2",
                name: "PRINCIPLE 2",
                status: false,
              },
            ],
          },
          {
            _id: "Labour",
            data: [
              {
                id: "PRINCIPLE 3",
                name: "PRINCIPLE 3",
                status: false,
              },
              {
                id: "PRINCIPLE 4",
                name: "PRINCIPLE 4",
                status: false,
              },
              {
                id: "PRINCIPLE 5",
                name: "PRINCIPLE 5",
                status: false,
              },
              {
                id: "PRINCIPLE 6",
                name: "PRINCIPLE 6",
                status: false,
              },
            ],
          },
          {
            _id: "Environment",
            data: [
              {
                id: "PRINCIPLE 7",
                name: "PRINCIPLE 7",
                status: false,
              },
              {
                id: "PRINCIPLE 8",
                name: "PRINCIPLE 8",
                status: false,
              },
              {
                id: "PRINCIPLE 9",
                name: "PRINCIPLE 9",
                status: false,
              },
            ],
          },
          {
            _id: "Anti-Corruption",
            data: [
              {
                id: "PRINCIPLE 10",
                name: "PRINCIPLE 10",
                status: false,
              },
            ],
          },
        ],
      });
      return {
        ...state,
        getCategories: categories,
        _categoriesListStatic: categories,
      };

    case types.UPDATE_CATEGORIES_DATA:
      return {
        ...state,
        getCategories: payload,

      };

    case types.SELECTED_TOP_SEARCH:
      const selectedTopSearch = state.selectedTopSearch?.some(
        (Search) => Search === action.data
      );

      if (selectedTopSearch) {
        const updatedSearch = state.selectedTopSearch?.filter(
          (Search) => Search !== action.data
        );
        return { ...state, Search: updatedSearch };
      }

    case types.API_INCIDENT_DETAILS_LOAD:
      return { ...state, isLoading: true };
    case types.API_INCIDENT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        incidentDetails: payload.data,
      };
    case types.API_GET_INCIDENCE_WITH_COUNTRIES_SUCCESS:
      return { ...state, isLoading: false, mapCountries: payload?.data };
    case types.API_GET_INCIDENCE_WITH_COUNTRIES_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_SOURCE_DETAILS_LOAD:
      return { ...state, isLoading: false };
    case types.API_GET_SOURCE_DETAILS_SUCCESS:
      return { ...state, isLoading: false, sourceDetails: payload?.data };

    case types.API_GET_NEWS_ARTICLE_LOAD:
      return { ...state, isLoading: false };
    case types.API_GET_NEWS_ARTICLE_SUCCESS:
      if (payload.oldData == "news") {
        state.ngo_reports = payload.data;
      } else {
        state.news_articles = payload.data;
      }
      return {
        ...state,
        isLoading: false,
      };
    case types.API_INCIDENT_MAP_DATA_LOAD:
      return { ...state, isLoading: true, mapDataLoad: true };
    case types.API_INCIDENT_MAP_DATA_SUCCESS:
      return { ...state, isLoading: false, mapData: payload?.data, mapDataLoad: false };
    case types.API_MAP_DATA_SUCCESS:
      const get_ESG_Data = payload?.data[0]?.esg_categories;
      const modification_get_ESG_Data =
        get_ESG_Data.Environmental !== null &&
        get_ESG_Data.Governance !== null &&
        get_ESG_Data.Social !== null &&
        makeFullMap_ESGFactorData(get_ESG_Data);
      const get_severity_map_data = payload?.data[0]?.severity;
      const modification_get_severity_map_data = makeFullMap_severityData(
        get_severity_map_data
      );

      return {
        ...state,
        isLoading: false,
        continents: payload?.data[0]?.continents,
        esg_categories_map_data: get_ESG_Data && get_ESG_Data,
        ESGLocationArray:
          modification_get_ESG_Data && modification_get_ESG_Data,
        severity_map_data: get_severity_map_data && get_severity_map_data,
        severityDataLocationArray:
          modification_get_severity_map_data &&
          modification_get_severity_map_data,
        mapDataLoad: false
      };
    case types.RESET_MAP_DATA:
      return {
        ...state,
        continents: [],
        esg_categories_map_data: [],
        ESGLocationArray: [],
        severity_map_data: [],
        severityDataLocationArray: [],
      };

    case types.GOOGLE_MAP_VIEW_STATE:
      return {
        ...state,
        isLoading: false,
        googleMapViewState: action?.data,
      };
    case types.API_SEARCH_BY_PARAMS_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case types.API_SEARCH_BY_PARAMS_SUCCESS:
      return { ...state, isLoading: false, searchByParams: payload?.data };
    case types.API_GET_DOWNLOAD_INCIDENT_LOAD:
      return { ...state, isLoading: false };
    case types.API_GET_DOWNLOAD_INCIDENT_SUCCESS:
      return { ...state, isLoading: false };
    case types.API_GET_ESG_FACTORS_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_ESG_FACTORS_SUCCESS:
      return { ...state, isLoading: false, esgFactorList: payload?.data };
    case types.API_GET_ESG_COUNRTIES_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_ESG_COUNRTIES_SUCCESS:
      state.supplyChainEsgCountry = getDataSupply(payload?.data);
      return { ...state, isLoading: false, esgCountryList: payload?.data };
    case types.API_GET_DOWNLOAD_INCIDENT_SUCCESS:
      return { ...state, isLoading: false, downloadData: payload?.data };

    case types.GET_SOCIAL_MEDIA_INCIDENT_LOAD:
      return { ...state, isLoading: true };
    case types.GET_SOCIAL_MEDIA_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        socialMediaCoverage: {
          socialMedia: payload?.data?.socialMedia,
          sentiment: payload?.data.sentiment,
        },
      };

    case types.GET_FINE_AND_PANELTY_LOAD:
      return { ...state, isLoading: true };

    case types.GET_FINE_AND_PANELTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        penalties_and_fines: payload.data || [],
      };

    case types.GET_REGULATORY_LEGAL_LOAD:
      return { ...state, isLoading: true };
    case types.GET_REGULATORY_LEGAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        regulatry_legal_actions: payload.data || [],
      };

    case types.GET_TIMELINE_GRAPH_LOAD:
      return { ...state, isLoading: true };
    case types.GET_TIMELINE_GRAPH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        timeline_graph: payload.data || [],
      };

    case types.POST_FEEDBACK_LOAD:
      return { ...state, isLoading: true, Successpop: false };
    case types.POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Successpop: true,
      };

    case types.API_INCIDENT_LIST_FOR_MODAL_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case types.API_INCIDENT_LIST_FOR_MODAL_SUCCESS:
      return {
        ...state,
        incidentsForModal: payload.data,
        isLoading: false,
      };
    case types.UPDATE_WATCHLIST_INDUSTRIES:
      let data = state.industryList;
      let updatedIndusData = updateIndus(action.payload, data);
      return {
        ...state,
        industryList: updatedIndusData,
      };
    case types.DIRECT_UPDATE_WATCHLIST_INDUSTRIES:
      return {
        ...state,
        industryList: action.payload,
      };
    case types.REMOVE_WATCHLIST_INDUSTRIES:
      let IndusData = state.industryList;
      let removeIndusData = removeIndus(IndusData);
      state.industryList = removeIndusData;
      return {
        ...state,
      };

    case types.API_UPDATE_INDUS_WISHLIST_DETAILS:
      if (action.data.industries.length > 0) {
        for (let i in action.data.industries) {
          if (state.industryList.length > 0) {
            let All_data = state.industryList;
            let _payload = {
              data: action.data.industries[i],
              key: "child",
              parent: {
                id: action.data.industries[i].parent,
                status: false,
              },
            };
            let updatedIndusData = updateIndus(_payload, All_data, true);
            state.industryList = updatedIndusData;
          }
        }
      } else {
        let IndusData = state.industryList;
        let removeIndusData = removeIndus(IndusData);
        state.industryList = removeIndusData;
      }
      return {
        ...state,
      };

    case types.CHECK_UPDATE_INDUS_WISHLIST_DETAILS:
      let allListIndus = state.industryList;
      if (allListIndus?.length > 0 && action.data?.industries?.length > 0) {
        let data = checkIndusData(allListIndus, action.data.industries);
        state.industryList = data;
      } else {
        if (allListIndus?.length > 0) {
          let IndusData = state.industryList;
          let removeIndusData = removeIndus(IndusData);
          state.industryList = removeIndusData;
        }
      }
      return {
        ...state,
      };

    case types.UPDATE_INCIDENT_STATE:
      state[key] = payload;
      return { ...state };

    case types.UPDATE_WATCHLIST_INDUS_SEARCH:
      if (action.data.key) {
        let dataSelected = state.industryListStatic;
        for (let i in dataSelected) {
          for (let j in action.data.data) {
            if (action.data.data[j].id === dataSelected[i].id) {
              dataSelected[i].status = action.data.data[j].status;
              for (let k in dataSelected[i].childs) {
                for (let l in action.data.data[j].childs) {
                  if (
                    action.data.data[j].childs[l].id ===
                    dataSelected[i].childs[k].id
                  ) {
                    dataSelected[i].childs[k].status =
                      action.data.data[j].childs[l].status;
                  }
                }
              }
            }
          }
          state.industryList = dataSelected;
          state.industryListStatic = dataSelected;
        }
      } else {
        state.industryList = action.data.data;
      }
      return { ...state };

    case types.UPDATE_WATCHLIST_COMPANY:
      if (action.data.key) {
        let dataSelected = state.newCompaniesList;
        for (let i in dataSelected) {
          for (let j in action.data.data) {
            if (action.data.data[j].id === dataSelected[i].id) {
              dataSelected[i].status = action.data.data[j].status;
            }
          }
        }

        state.companiesList = dataSelected;
        state.newCompaniesList = dataSelected;
      } else {
        state.companiesList = action.data.data;
      }
      return { ...state };

    case types.GET_INCIDENT_COUNT_LOAD:
      return {
        ...state,
        totalEsgIncidentCountLoader: true,
      };

    case types.GET_INCIDENT_COUNT_SUCCESS:
      return {
        ...state,
        totalEsgIncidentCountLoader: false,
        totalEsgIncidentCount: action.payload,
      };

    case types.GET_INCIDENT_COUNT_FAIL:
      return {
        ...state,
        totalEsgIncidentCountLoader: false,
      };
    case types.RESET_SEARCH_PAGE_DATA:
      return {
        ...state,
        supplyChainEsgCountry: [],
        _categoriesListStatic: []
      };

    case types.RESET_PORTFOLIO_DATA_ON_PAGE_LOAD:
      return {
        ...state,
        incident: [],
        severityDataLocationArray: [],
        continents: [],
        totalEsgIncidentCount: {},
      };

    case types.REMOVE_WATCHLIST_INDUSTRIES_NEW:
      return {
        ...state
      }
    //Default case
    default:
      return { ...state };
  }
};
