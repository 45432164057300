import { apiConstants as types } from "../../Constants/apiConstants";

const initialStates = {
  loding: false,
  modal: {
    show: false,
    OK: {
      name: "Ok",
      onClick: () => { },
    },
    CANCEL: {
      name: "",
      onClick: () => { },
    },
    title: "",
    description: "",
    type: null,
    icon: null,
    openFromLogin: true,
  },
  searchCompaniesData: {
    data: [],
    paginationData: {},
  },
};

function getAllCountries(data) {
  let allCountriesData = [];
  for (let i in data) {
    let obj = {
      label: data[i].name,
      value: data[i].id,
      id: data[i].id,
      isin: data[i].isin,
      tickers: data[i].tickers,
    };
    allCountriesData.push(obj);
  }
  return allCountriesData;
}

export const global = (state = initialStates, action) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return { ...state, modal: { ...action.payload } };
    case types.HIDE_MODAL:
      return { ...state, modal: { ...state.modal, ...initialStates.modal } };
    case types.SEARCH_COMPANY_LIST_LOAD:
      return { ...state, loding: true, searchCompaniesData: action.payload };
    case types.SEARCH_COMPANY_LIST_FAIL:
      return {
        ...state,
        loding: false,
        searchCompaniesData: { data: [], paginationData: {} },
      };
    case types.SEARCH_COMPANY_LIST_SUCCESS:
      const companyListCorrection = getAllCountries(action?.payload?.countries);
      return {
        ...state,
        loding: false,
        searchCompaniesData: {
          data: companyListCorrection,
          paginationData: action?.payload?.pagination,
        },
      };
    case types.RESET_SEARCH_PAGE_DATA:
      return {
        ...state,
        searchCompaniesData: {
          data: [],
          paginationData: {},
        },
      };
    default:
      return { ...state };
  }
};
