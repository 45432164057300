import ReactApexChart from "react-apexcharts";

const ReplySentimentDonut = (props) => {

    const getResArr = (arr) => {
        const total = arr.reduce((acc, value) => acc + value, 0);
        let res = arr.map((item) => {
            return item * (100/total)
        })

        return res
    }

    const total = props?.data?.reduce((acc, value) => acc + value, 0)
    const data = {
        series: getResArr(props.data) || [45, 35, 20],
        options: {
            chart: {
                width: 250,
                type: 'donut',
                dropShadow: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    top: 0,
                    left: 0,
                    blur: 3,
                    color: '#000',
                    opacity: 0.35
                }
            },
            legend: {
                show: false,
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                    donut: {
                        size: '50%',
                        labels: {
                            transform: "translate(0,5)",
                            show: true,                       
                            
                            // offsetY: -15,
                            total: {
                                
                                show: true,
                                showAlways: true,
                                color: "#000",
                                fontWeight: 600,
                                offsetY: 15,
                                fontFamily: "Inter",
                                fontSize: "13px",
                                                
                                label: total,                           
                                formatter: function (w) {
                                    return "Replies"
                                }
                            },
                            value:{
                                offsetY: -10,
                                fontFamily: "Inter",
                                color:'#80838B',
                                fontSize: "12px"
                                
                              }
                        }                   
    
                    }
                },

            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return Math.floor(val) + "%"
                }
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (val) {
                        return Math.floor(val) + "%"; // Rounds the hover values
                    }
                }
            },
            
            colors: ["#D00000", "#1A9C3E", "#B6BCC7"],
            fill: {
                type: 'gradient',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },


    };
    return <div className="donut-container"><ReactApexChart options={data.options} series={data.series} type="donut" width={220} /></div>
}
export default ReplySentimentDonut
