import { SvgIcon } from "@mui/material";

export default function ExpandIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66797 6.0013V3.73464C2.66797 3.36127 2.66797 3.17458 2.74063 3.03198C2.80455 2.90654 2.90653 2.80455 3.03197 2.74063C3.17458 2.66797 3.36127 2.66797 3.73463 2.66797L6.0013 2.66797M2.66797 10.0013V12.268C2.66797 12.6413 2.66797 12.828 2.74063 12.9706C2.80455 13.0961 2.90653 13.1981 3.03197 13.262C3.17458 13.3346 3.36127 13.3346 3.73463 13.3346L6.0013 13.3346M10.0013 2.66797H12.268C12.6413 2.66797 12.828 2.66797 12.9706 2.74063C13.0961 2.80455 13.1981 2.90653 13.262 3.03198C13.3346 3.17458 13.3346 3.36127 13.3346 3.73464V6.0013M13.3346 10.0013V12.268C13.3346 12.6413 13.3346 12.828 13.262 12.9706C13.1981 13.0961 13.0961 13.1981 12.9706 13.262C12.828 13.3346 12.6413 13.3346 12.268 13.3346H10.0013"
          stroke="#9A9CA2"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
