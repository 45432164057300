import { Modal, Row, Table, Tabs, Spin, Progress } from "antd";
import Images from "../../Themes/ImageConstant";
import IncidentsList from "../incidentsList";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { incindentAction } from "../../Redux/Action/incidents";
import appconstant from "../../Constants/appConstansts";

const IncidentAnalysisComp = ({

  date,
  showModal = false,
  screenName,
  incidentBarData = [],
  incidentTabChange
}) => {
  const [modalVisible, setModalVisible] = useState(showModal);
  const [tabKey, setTabKey] = useState("1");
  
  const [noOfIncidentShowMore, setNoOfIncidentShowMore] = useState(false);

  const dispatch = useDispatch();

  const onChangeTab = (key) => {
    setTabKey(key);
    if (key == 2) {
      // dispatch(incindentAction({ ...date, esg_categories: ["Supplier"] }));
      // tabValue[1]("Customer");
      incidentTabChange("Customer")
    }
    if (key == 1) {
      // dispatch(incindentAction({ ...date, esg_categories: ["Customer"] }));
      // tabValue[1]("Supplier");
      incidentTabChange("Supplier")
    }
    if (key == 3) {
      // dispatch(incindentAction({ ...date, esg_categories: ["Partner"] }));
      incidentTabChange("Supplier")
    }
  };

  let previousPercentageData = {
    percentage: "",
    value: 0,
  };

  const getProcessBarPercentage = (index, value) => {
    const percentageObj = {
      0: "50%",
      1: "45%",
      2: "35%",
      3: "30%",
      4: "25%",
      5: "20%",
      6: "15%",
      7: "13%",
      8: "10%",
      9: "8%",
    };
    if (value == previousPercentageData.value) {
      return previousPercentageData.percentage;
    }
    previousPercentageData = {
      percentage: percentageObj[index],
      value,
    };
    return percentageObj[index];
  };

  return (
    <section>
      <div className="container g-0 mt-4">
        <div className="row">
          <div className="col">
            <div
              className={
                screenName !== "Home"
                  ? `recent-table`
                  : `recent-table containerWidth`
              }
            >
              <div className="tab-top">
                <div className="left-text">
                  <Row>
                    <h4>Total Number of Incidents / Critical Incidents</h4>

                  </Row>
                </div>

              </div>
              <Tabs
                type="card"
                // style={{padding:10}}
                className="incident-table-tabs"
                activeKey={tabKey}
                onChange={onChangeTab}
              >
                <Tabs.TabPane
                  key={"1"}
                  style={{ textAlign: "center" }}
                  tab={<>{"Supplier"}</>}
                >
                  <div className="">
                    <div className="portfolio_container_1 custom_container_portfolio_1">
                      {
                        incidentBarData &&
                        incidentBarData.length > 0 &&
                        incidentBarData.map((item, index) => {
                          return (
                            <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{ width: "50%" }}
                                  className="leftDataContainer"
                                >
                                  <div
                                    className="CaptionXLOriginal"
                                    style={{
                                      background: "#EFF1F6",
                                      height: "20px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0 7px",
                                    }}
                                  >
                                    <span style={{ minWidth: "" }}>
                                      {index + 1}
                                    </span>
                                  </div>
                                  {/* <Badge count={9}></Badge> */}
                                  <span
                                    // onClick={() =>
                                    //   navigate("/companyPage", {
                                    //     state: { id: item.id },
                                    //   })
                                    // }
                                    className="body"
                                    style={{
                                      paddingLeft: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <p>{item?.name || ""}</p>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    width: getProcessBarPercentage(
                                      index,
                                      item.incidentCount
                                    ),
                                    display: "flex",
                                  }}
                                >
                                  <span
                                    className="BodySelected1"
                                    style={{ marginRight: "16px",color:"#5e0000" }}
                                  >
                                    {item?.CriticalCount
                                      ? Math.ceil(item?.CriticalCount)
                                      : 0}
                                  </span>
                                  <Progress
                                    className="progress_portfolio_first"
                                    percent={
                                      item?.CriticalCount
                                        ? Math.ceil(((item?.CriticalCount / item?.incidentCount) * 100).toFixed(3))
                                        : "0"
                                    }
                                    showInfo={false}
                                  />
                                  <span
                                    className="BodySelected1"
                                    style={{ marginLeft: "16px",color:'#676a73' }}
                                  >
                                    {item?.incidentCount || 0}
                                  </span>
                                </div>
                              </div>
                          )
                        })
                      }
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {incidentBarData &&
                          incidentBarData.length >= 10  &&
                          !noOfIncidentShowMore && (
                            <div
                              className="viewMoreContainer"
                              onClick={() => setNoOfIncidentShowMore(true)}
                            >
                              <span className="buttonM">
                                View More
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane key={"2"} tab={<>{"Customer"}</>}>
                <div className="">
                    <div className="portfolio_container_1 custom_container_portfolio_1">
                      {
                        incidentBarData &&
                        incidentBarData.length > 0 &&
                        incidentBarData.map((item, index) => {
                         return (
                          <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{ width: "50%" }}
                                  className="leftDataContainer"
                                >
                                  <div
                                    className="CaptionXLOriginal"
                                    style={{
                                      background: "#EFF1F6",
                                      height: "20px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0 7px",
                                    }}
                                  >
                                    <span style={{ minWidth: "" }}>
                                      {index + 1}
                                    </span>
                                  </div>
                                 
                                  <span
                                    className="body"
                                    style={{
                                      paddingLeft: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <p>{item?.name || ""}</p>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    width: getProcessBarPercentage(
                                      index,
                                      item.incidentCount
                                    ),
                                    display: "flex",
                                  }}
                                >
                                  <span
                                    className="BodySelected1"
                                    style={{ marginRight: "16px",color:"#5e0000" }}
                                  >
                                    {item?.CriticalCount
                                      ? Math.ceil(item?.CriticalCount)
                                      : 0}
                                  </span>
                                  <Progress
                                    className="progress_portfolio_first"
                                    percent={
                                      item?.CriticalCount
                                        ? Math.ceil(((item?.CriticalCount / item?.incidentCount) * 100).toFixed(3))
                                        : 0
                                    }
                                    showInfo={false}
                                  />
                                  <span
                                    className="BodySelected1"
                                    style={{ marginLeft: "16px",color:'#676a73' }}
                                  >
                                    {item?.incidentCount || 0}
                                  </span>
                                </div>
                              </div>
                         )
                        })
                      }
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {incidentBarData &&
                          incidentBarData.length >= 10  &&
                          !noOfIncidentShowMore && (
                            <div
                              className="viewMoreContainer"
                              onClick={() => setNoOfIncidentShowMore(true)}
                            >
                              <span className="buttonM">
                                View More
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane key={"3"} tab={<>{"Partner"}</>}>
                <div className="">
                    <div className="portfolio_container_1 custom_container_portfolio_1">
                      {
                        incidentBarData &&
                        incidentBarData.length > 0 &&
                        incidentBarData.map((item, index) => {
                          return (
                            <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{ width: "50%" }}
                                  className="leftDataContainer"
                                >
                                  <div
                                    className="CaptionXLOriginal"
                                    style={{
                                      background: "#EFF1F6",
                                      height: "20px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0 7px",
                                    }}
                                  >
                                    <span style={{ minWidth: "" }}>
                                      {index + 1}
                                    </span>
                                  </div>
                                  {/* <Badge count={9}></Badge> */}
                                  <span
                                    // onClick={() =>
                                    //   navigate("/companyPage", {
                                    //     state: { id: item.id },
                                    //   })
                                    // }
                                    className="body"
                                    style={{
                                      paddingLeft: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <p>{item?.name || ""}</p>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    width: getProcessBarPercentage(
                                      index,
                                      item.incidentCount
                                    ),
                                    display: "flex",
                                  }}
                                >
                                  <span
                                    className="BodySelected1"
                                    // className={`${style.BodySelected} ${style.customProgressColor1}`}
                                    style={{ marginRight: "16px",color:"#5e0000" }}
                                  >
                                    {item?.CriticalCount
                                      ? Math.ceil(item?.CriticalCount)
                                      : 0}
                                  </span>
                                  <Progress
                                    className="progress_portfolio_first"
                                    percent={
                                      item?.CriticalCount
                                        ? Math.ceil(((item?.CriticalCount / item?.incidentCount) * 100).toFixed(3))
                                        : 0
                                    }
                                    showInfo={false}
                                  />
                                  <span
                                    className="BodySelected1"
                                    // className={`${style.BodySelected} ${style.customProgressColor2}`}
                                    style={{ marginLeft: "16px",color:'#676a73' }}
                                  >
                                    {item?.incidentCount || 0}
                                  </span>
                                </div>
                              </div>
                          )
                        })
                      }
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {incidentBarData &&
                          incidentBarData.length >= 10  &&
                          !noOfIncidentShowMore && (
                            <div
                              className="viewMoreContainer"
                              onClick={() => setNoOfIncidentShowMore(true)}
                            >
                              <span className="buttonM">
                                View More
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default IncidentAnalysisComp;
