import React from 'react';
import ReactECharts from 'echarts-for-react';
import { navigate } from '../../../../Utils/dispatchNavigate';


export function getBackgroundColorFromRiskScore(value) {
    if (value > 0 && value < 25) {
        return "#00a36c";
    }
    if (value >= 25 && value < 50) {
        return "#ffbf00";
    }
    if (value >= 50 && value < 75) {
        return "#ff7518";
    }
    return "#ff0000";
}
const getName = (data) => {
    let dataArr = []
    for (let j in data) {
        dataArr.push(data[j].name,)
    }
    return dataArr
}
const getData = (data) => {
    let dataArr = []
    for (let j in data) {
        let obj = {
            name: data[j].name,
            value: data[j].risk ? Math.ceil(data[j].risk) : data[j].risk,
            id: data[j].id
        }
        dataArr.push(obj)
    }
    dataArr.reverse()
    return dataArr
}

const getMax = (data) => {
    if (data.length > 0) {
        let max = Math.max.apply(Math, data.map(function (o) { return Math.ceil(o.risk); })) * 1.33
        return max
    }
}

const PolarChart = ({ data }) => {
    const handleClick = (params) => {
        navigate("/companyPage", { state: { id: params.data.id } });
    };
    let max = data ? getMax(data) : 10
    const option = {
        polar: {
            radius: [20, '80%']
        },
        angleAxis: {
            show: false,
            max: max,
            startAngle: 90
        },
        radiusAxis: {
            type: 'category',
            show: false,
            // data: ["google", "meta", "Tiktok","Snap","Alphabet"]
        },
        tooltip: {},
        series: {
            type: 'bar',
            data: getData(data),
            coordinateSystem: 'polar',
            label: {
                show: true,
                position: 'start',
                formatter: '{b} ({c})'
            },
            itemStyle: {
                color: function (params) {
                    return getBackgroundColorFromRiskScore(params.data.value);
                }
            }
        },

    };

    return (
        <>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <ReactECharts
                    option={option}
                    style={{ height: '350px', width: '550px' }}
                    onEvents={{
                        click: handleClick

                    }}
                />


            </div>

       
        </>
    );
};

export default PolarChart;