import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Images from "../../Themes/ImageConstant";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";

import { Modal, Row, Progress, Spin, Collapse, Tabs } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import moment from "moment/moment";
import { FacebookIcon, LinkedinIcon } from "react-share";
import {
  BarMapping,
  BarMappingPAI,
  BarMappingSDG,
  HLEA,
  PNN,
  calculatePreviousDate,
  dateButtonObj,
  nFormatter,
  scrollTo,
} from "../../Utils";
import {
  companiesWithIncidenAction,
  countriesWithIncidenAction,
  esgFactorsAction,
  esgPaiAction,
  esgSdgAction,
  getCategoriesAction,
  getCountriesWithIncidenceAction,
  getDownloadDataAction,
  getSocialMediaReactionAction,
  homeHighSeverityLoad,
  homePageAction,
  homeRiskScoreLoad,
  incidentMapAction,
  incindentAction,
  getIncidentCountLoad,
} from "../../Redux/Action/incidents";
import { dispatch, navigate } from "../../Utils/dispatchNavigate";
import { useSelector } from "react-redux";
import { SocialBox } from "../../Componets/SocialBox";
import { CompanyIncidentTable } from "../../Componets/CompanyIncidentTable";

import { esgCategoryAction } from "../../Redux/Action/incidents";
import { getCountryListAction } from "../../Redux/Action/comapanyPageAction";
import {
  addWishListAction,
  editWishListAction,
  getWishlistAction,
  addWishListItemIdAction,
} from "../../Redux/Action/watchListActions";
import DatePicker from "../../Componets/DatePicker";
import { columns1 } from "../dashboardPage/companyColumns";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import BubleIncidentsList from "../bubleIncidentsList";
import IncidentsList from "../incidentsList";

import EChartsReact from "echarts-for-react";
import NewDashboaredHeader from "../../Layouts/newDashboardHeader";
import { sentiment_Obj, socialMediaObj } from "../incidentDetails/Chart/utils";
import {
  getBreachesViolationDataLoad,
  getIncidentAnalyzerBreaches,
  incidentByCategoryAction,
} from "../../Redux/Action/incidentAnalyzerAction";
import { ChartCard } from "../../Layouts/ChartCard";
import HomeIncidentTable from "../../Componets/homeIncidentTable";
import style from "../Home/style.module.css";
import UngcpBreaches from "../dashboardPage/utils-components/ungcpBreaches";

import SearchMain from "../../Componets/SearchMain";
import PrincipleBarChat from "../../Componets/PrincipleBarChat";
import IncidentsListModal from "../IncidentsListModal";
import FullMap from "../../Componets/MapGL/full-map";
import GlobalLoder from "../../Utils/GlobalLoder";
import PAIBarChat from "../../Componets/PrincipleBarChat/PAI";
import SDGBarChat from "../../Componets/PrincipleBarChat/SDG";

import IncidentOverTime from "../Home/chart/incidentOverTime";

const { Panel } = Collapse;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const lastDate = dateButtonObj[3].type;

let countryDataObj = {
  AF: "Africa",
  AN: "Antarctica",
  AS: "Asia",
  EU: "Europe",
  NA: "North America",
  OC: "Oceania",
  SA: "South America",
};

let networkData = { ...topHeaderAPIObject };
networkData["end"] = moment().format("YYYY-MM-DD");
networkData["start"] = moment(
  calculatePreviousDate(new Date(), lastDate)
).format("YYYY-MM-DD");

let previousPercentageData = {
  percentage: "",
  value: 0,
};

const getProcessBarPercentage = (index, value) => {
  const percentageObj = {
    0: "50%",
    1: "45%",
    2: "35%",
    3: "30%",
    4: "25%",
    5: "20%",
    6: "15%",
    7: "13%",
    8: "10%",
    9: "8%",
  };
  if (value == previousPercentageData.value) {
    return previousPercentageData.percentage;
  }
  previousPercentageData = {
    percentage: percentageObj[index],
    value,
  };
  return percentageObj[index];
};
const getActiveItem = (number) => {
  if (number == 0 || (number > 0 && number < 150)) {
    return "Overview";
  } else if (number == 150 || (number > 90 && number < 1400)) {
    return "Company Risk Score";
  } else if (number == 1430 || (number > 1400 && number < 4830)) {
    return "Incident Analysis";
  } else if (number == 4880 || (number > 4830 && number < 5690)) {
    return "Social Media Coverage";
  } else if (number == 5750 || (number > 5690 && number < 8790)) {
    return "Breaches & violation";
  }
};

let piaChartData = {};
let selectedEsgCategories = [];
let modalOpenDone = false
const SearchDashboardPage = (props) => {
  const location = useLocation();

  const { state } = location;
  const [modalVisible, setModalVisible] = useState(false);
  const reducerData = useSelector((state) => state.incidentReducer);
  const {
    incidentAnalyzer_UN_Breaches,
    homeESGRiskData,
    homeESGRiskDataViewBtn,
    homeHIGHSeverityData,
    homeHIGHSeverityDataViewBtn,
    sepratedCategory,
    socialMediaData,
    stateLoader,
    breachesAndViolationData,
    breachesAndViolationDataLoader,
  } = useSelector((state) => state.incidentAnalyzerReducer);
  const sentimentObj = sentiment_Obj(socialMediaData?.sentiment || {});
  const socialObj = socialMediaObj(socialMediaData?.socialMedia || {});
  const [homeESGRiskDataMore, sethomeESGRiskDataMore] = useState(false);
  const [noOfIncidentShowMore, setNoOfIncidentShowMore] = useState(false);
  const [advanceModalOpen, setAdvanceModalOpen] = useState(false);
  const [advanceModalOpenState, setAdvanceModalOpenState] = useState(false)

  const [prevPageData, setPrevPageData] = useState({ companies: [], esg_factors: [], locations: [], industries: [], ungc_breaches: [] });
  const [tilesData, setTilesData] = useState(null);
  const [riskScorePerCompOffset, setRiskScorePerCompOffset] = useState(0);
  const [noOfIncidentOffset, setNoOfIncidentOffset] = useState(0);
  const [activeTab, setActiveTab] = useState("0");
  const [isHovered, setIsHovered] = useState(false);
  const [breachesViolationLimit, setBreachesViolationLimit] = useState(10);
  const [breachesViolationPaiLimit, setBreachesViolationPaiLimit] =
    useState(10);
  const [breachesViolationSdgLimit, setBreachesViolationSdgLimit] =
    useState(10);
  const [tabKey, setTabKey] = useState("1");
  const [tabVal, setTabVal] = useState("Environmental");
  useEffect(() => {
    setPrevPageData(state?.mergedData || null);
    setTilesData(state?.selectedTile || null);
    localStorage.interval = "Last 3 months";
  }, [state]);

  useEffect(() => {
    if (modalOpenDone) {
      setAdvanceModalOpenState(!advanceModalOpenState)
    }

  }, [advanceModalOpen])
  const companyRiskScoreMoreData_Handler = (type) => {
    if (type == "riskScore") {
      sethomeESGRiskDataMore(true);
    } else {
      setNoOfIncidentShowMore(true);
    }
  };

  const {
    result: severeAndSocial,
    companies: selectedArray,
    incident: IPLData,
    companiesWithIncident,
    countriesWithIncident,
    esgCategory: esgCategoryData,
    locationIdSearchList,
    continents,
    severityDataLocationArray,
    totalEsgIncidentCount,
  } = reducerData;

  let update = continents.map((val) =>
    countryDataObj[val?.continent_code]
      ? { ...val, continent_code: countryDataObj[val?.continent_code] }
      : { ...val }
  );

  const [scroll, setScroll] = useState(false);

  window.addEventListener(
    "scroll",
    () => {
      if (window.pageYOffset > 50) setScroll(true);
      else setScroll(false);
    },
    { passive: true }
  );

  const removeTileFromSelectedSearch = (Key_val, item) => {
    let KeyName = Key_val == "countries" ? "locations" : Key_val;
    let filteredData = { ...prevPageData };
    let filteredAllData = { ...tilesData };

    filteredAllData[KeyName] =
      typeof filteredAllData[KeyName] == Boolean ? false : [];
    state.selectedTile[KeyName] =
      typeof filteredAllData[KeyName] == Boolean ? false : [];
    filteredData[KeyName] =
      typeof filteredData[KeyName] == Boolean ? false : [];
    state.mergedData[KeyName] =
      typeof filteredData[KeyName] == Boolean ? false : [];
    setPrevPageData(filteredData);
    setTilesData(filteredAllData);
    navigate(".", {
      replace: true,
      state: { mergedData: state.mergedData, selectedTile: state.selectedTile },
    });
    networkCall({
      ...state.mergedData,
      ungc_breaches:
        state?.mergedData?.ungc_breaches?.map((item) => item.id) || [],
      start: networkData.start,
      end: networkData.end,
    });
  };

  const socialBox = [
    {
      img: Images.file,
      label: "News Articles",
      count: severeAndSocial?.totalArticles,
    },
    {
      img: Images.buil,
      label: "NGO Reports",
      count: severeAndSocial?.totalNgoReports,
    },
    {
      img: Images.socialMediaIcon,
      label: "Social Media",
      count: severeAndSocial?.totalTweets,
    },
  ];

  function onChartClick(param) {
    const parent = {
      S: "Social",
      G: "Governance",
      E: "Environmental",
    };
    let _parent = param?.data.parent;
    let Parent = parent[_parent];
    networkData = {
      ...networkData,
      esg_categories: [param?.data?.esgName || Parent],
    };
    setModalVisible(true);
  }

  const legend = [
    {
      name: "X corp.",
      image: Images.XCROP,
    },

    {
      name: "Reddit",
      image: Images.Raddit,
    },
  ];

  const [newWatchlist, setWatchlist] = useState();
  const navigate = useNavigate();
  let TopLevelIncidentListModalPayload = { ...topHeaderAPIObject };
  TopLevelIncidentListModalPayload["end"] = moment().format("YYYY-MM-DD");
  TopLevelIncidentListModalPayload["start"] = moment(
    calculatePreviousDate(new Date(), lastDate)
  ).format("YYYY-MM-DD");
  const [topLevelIncidentListModalPayload] = useState({
    ...TopLevelIncidentListModalPayload,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCategoriesModal, setSelectedCategoriesModal] = useState(false);
  const [watchlistAddedIds, setWatchListAddedIds] = useState([]);
  const [rootLevelIncidentModalItem, setRootLevelIncidentModalItem] =
    useState(false);

  const watchListData = useSelector(
    (state) => state?.watchListReducer?.watchlist
  );

  const { isLoading, addWatchListID } = useSelector(
    (state) => state?.watchListReducer
  );

  const [modal2Open, setModal2Open] = useState(false);

  const onDatePickerChange = useCallback((startDate, previousDate) => {
    if (startDate && previousDate) {
      networkData.companies =
        state?.selectedTile?.companies?.length > 0
          ? state?.selectedTile?.companies?.map((x) => x.id)
          : [];
      networkData.locations =
        locationIdSearchList?.length > 0 ? locationIdSearchList : [];
      networkData.start = startDate;
      networkData.end = previousDate;
      setRiskScorePerCompOffset(0);
      setNoOfIncidentOffset(0);
      networkCall({ start: startDate, end: previousDate });
    }
  });

  ChartJS.register(ArcElement, Tooltip, Legend);

  const newData = { offset: 0, limit: 10 };

  const mergedData = {
    ...networkData,
    ...newData,
    ...state?.mergedData,
    ungc_breaches:
      state?.mergedData?.ungc_breaches?.map((item) => item.id) || [],
  };

  const networkCall = (data) => {
    let alldata = data;
    if (state) {
      alldata = { ...alldata, ...state?.mergedData };
    }
    const newData = { ...networkData, ...alldata };
    newData.offset = 0;
    dispatch(
      getBreachesViolationDataLoad({
        ...newData,
        limit:
          activeTab == 1
            ? breachesViolationLimit
            : activeTab == 3
              ? breachesViolationPaiLimit
              : activeTab == 4
                ? breachesViolationSdgLimit
                : breachesViolationLimit,
        type:
          activeTab == 1
            ? "esg_supply_chain_act"
            : activeTab == 0
              ? "german_supply_chain_act"
              : activeTab == 2
                ? "esg_SDG"
                : "esg_PAI",
      })
    );
    dispatch(getIncidentCountLoad({ ...newData }));
    dispatch(incidentMapAction({ ...newData, type: "home" }));
    dispatch(incidentByCategoryAction(newData));
    dispatch(
      getIncidentAnalyzerBreaches({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      incindentAction({
        ...newData,
        sort: "discovery_time",
        order: -1,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      homePageAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      companiesWithIncidenAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      esgFactorsAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      esgSdgAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      esgPaiAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      esgCategoryAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      homeHighSeverityLoad({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      getCountriesWithIncidenceAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      getCountryListAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
    dispatch(
      getSocialMediaReactionAction({
        ...newData,
        start: networkData.start,
        end: networkData.end,
      })
    );
  };

  useLayoutEffect(() => {
    dispatch(getIncidentCountLoad({ ...networkData, ...state?.mergedData }));
  }, []);

  useEffect(() => {
    if (Object.entries(totalEsgIncidentCount).length > 0) {
      if (totalEsgIncidentCount?.Environmental > 0) {
        setTabKey("1");
        dispatch(
          incindentAction({
            ...mergedData,
            esg_categories: ["Environmental"],
            sort: "discovery_time",
            order: -1,
            start: networkData.start,
            end: networkData.end,
          })
        );
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social > 0
      ) {
        setTabKey("2");
        dispatch(
          incindentAction({
            ...mergedData,
            esg_categories: ["Social"],
            sort: "discovery_time",
            order: -1,
            start: networkData.start,
            end: networkData.end,
          })
        );
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social == 0 &&
        totalEsgIncidentCount?.Governance > 0
      ) {
        setTabKey("3");
        dispatch(
          incindentAction({
            ...mergedData,
            esg_categories: ["Governance"],
            sort: "discovery_time",
            order: -1,
            start: networkData.start,
            end: networkData.end,
          })
        );
      } else {
        setTabKey("1");
      }
    }
  }, [totalEsgIncidentCount]);

  useEffect(() => {
    networkData.companies = selectedArray;
  }, [selectedArray]);

  useEffect(() => {
    mergedData.offset = noOfIncidentOffset;
    dispatch(homeHighSeverityLoad(mergedData));
  }, [noOfIncidentOffset]);

  useEffect(() => {
    mergedData.offset = riskScorePerCompOffset;
    dispatch(homeRiskScoreLoad(mergedData));
  }, [riskScorePerCompOffset]);

  useEffect(() => {
    if (state) dispatch(getWishlistAction());
    mergedData.start = networkData.start;
    mergedData.end = networkData.end;
    dispatch(
      getBreachesViolationDataLoad({
        ...mergedData,
        limit:
          activeTab == 1
            ? breachesViolationLimit
            : activeTab == 3
              ? breachesViolationPaiLimit
              : activeTab == 4
                ? breachesViolationSdgLimit
                : breachesViolationLimit,
        type:
          activeTab == 1
            ? "esg_supply_chain_act"
            : activeTab == 0
              ? "german_supply_chain_act"
              : activeTab == 2
                ? "esg_SDG"
                : "esg_PAI",
      })
    );
    dispatch(incidentMapAction({ ...mergedData, type: "home" }));
    dispatch(incidentByCategoryAction(mergedData));
    dispatch(getIncidentAnalyzerBreaches(mergedData));
    dispatch(homePageAction(mergedData));
    dispatch(countriesWithIncidenAction(mergedData));
    dispatch(
      incindentAction({
        ...mergedData,
        esg_categories: [tabVal],
        sort: "discovery_time",
        order: -1,
      })
    );
    dispatch(getCategoriesAction());
    dispatch(companiesWithIncidenAction(mergedData));
    dispatch(esgFactorsAction(mergedData));
    dispatch(esgSdgAction(mergedData));
    dispatch(esgPaiAction(mergedData));
    dispatch(esgCategoryAction(mergedData));
    // dispatch(esgCategoryAction(mergedData));
    mergedData.offset = riskScorePerCompOffset;
    dispatch(homeRiskScoreLoad(mergedData));
    mergedData.offset = noOfIncidentOffset;
    dispatch(homeHighSeverityLoad(mergedData));
    mergedData.offset = 0;
    dispatch(getSocialMediaReactionAction(mergedData));

    return () => {
      modalOpenDone = false
      setAdvanceModalOpenState(false)
    }
  }, [state]);

  const callDownloadApi = () => {
    dispatch(getDownloadDataAction(null, networkData.start, networkData.end));
  };

  const [activeItem, setActiveItem] = useState("Overview");

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setActiveItem(getActiveItem(scrollTop));
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const totalCount = (arr) => {
    let totalCount = 0;
    totalCount = arr?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.incidents.length;
    }, 0);

    return totalCount;
  };

  useEffect(() => {
    if (activeTab != 2) {
      dispatch(
        getBreachesViolationDataLoad({
          ...mergedData,
          limit:
            activeTab == 1
              ? breachesViolationLimit
              : activeTab == 3
                ? breachesViolationPaiLimit
                : activeTab == 4
                  ? breachesViolationSdgLimit
                  : breachesViolationLimit,
          type:
            activeTab == 1
              ? "esg_supply_chain_act"
              : activeTab == 0
                ? "german_supply_chain_act"
                : activeTab == 4
                  ? "esg_SDG"
                  : "esg_PAI",
        })
      );
    } else {
      dispatch(
        getIncidentAnalyzerBreaches({
          ...mergedData,
          limit: breachesViolationLimit,
        })
      );
    }
  }, [
    activeTab,
    breachesViolationLimit,
    breachesViolationPaiLimit,
    breachesViolationSdgLimit,
  ]);


  const getChildData = (data, key) => {
    let arr = [];

    if (data?.length > 0) {
      let totalCount = data?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.incidentCount;
      }, 0);
      for (let i in data) {
        const obj = {
          name: `${((data[i].incidentCount / totalCount) * 100).toFixed(1)}%`,
          percentage:
            ((data[i].incidentCount / totalCount) * 100).toFixed(1) + "%",
          value: data[i].incidentCount,
          _name: "Postive",
          data: data[i],
          parent:
            key == "Governance" ? "G" : key == "Environmental" ? "E" : "S",
          itemStyle: {
            color: {
              colorStops: [
                {
                  offset: 0.3,
                  color:
                    key == "Governance"
                      ? "rgba(101, 61, 212, 0.3)"
                      : key == "Environmental"
                        ? "rgba(97, 166, 28, 0.3)"
                        : "rgba(61, 155, 207, 0.25)",
                },
                {
                  offset: 1,
                  color:
                    key == "Governance"
                      ? "rgba(101, 61, 212, 0.3)"
                      : key == "Environmental"
                        ? "#6EAD2F"
                        : "#4DA0F9",
                },
              ],
              x: 0,
              y: 0,
              x2: 0,
              y2: 1.5,
              type: "linear",
              global: false,
            },
          },
        };
        arr.push(obj);
      }
      return arr;
    }
    return arr;
  };

  const getPercentage = (value) => {
    let totalValue =
      sepratedCategory?.Environmental?.total +
      sepratedCategory?.Social?.total +
      sepratedCategory?.Governance?.total;

    let percentage = `${((value / totalValue) * 100).toFixed(1)}%`;
    return percentage;
  };

  const titleImg = (parent) => {
    return parent == "Social"
      ? Images.darkblue
      : parent == "Environment"
        ? Images.green
        : Images.pup;
  };

  const childFuncRef = useRef();

  if (esgCategoryData !== undefined) {
    piaChartData = {
      options: {
        chart: {
          type: "pie",
        },
        legend: {
          show: false,
        },
        labels: ["Environmental", "Social", "Governance"],
        chart: {
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (event, chartContext, { dataPointIndex }) => {
              const itemObj = {
                0: "Environmental",
                1: "Social",
                2: "Governance",
              };
              selectedEsgCategories = [itemObj[dataPointIndex]];
              // setSelectedCategoriesModal(true);
            },
          },
        },
        colors: ["#6EBD20", "#2191FB", "#653DD4"],
        plotOptions: {
          pie: {
            dataLabels: {
              style: {
                textAnchor: "center", // Center-align the text
              },
              enabled: true, // Enable data labels
            },
          },
        },
      },
      series: [
        esgCategoryData[1]?.incidents,
        esgCategoryData[0]?.incidents,
        esgCategoryData[2]?.incidents,
      ],
    };
  }

  const SeprateCategoryComponent = ({ name, incidents }) => {
    return (
      <div className="flx-color-se">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img style={{ width: ".8vw", height: ".8vw" }} src={titleImg(name)} />
          <h5
            style={{
              marginLeft: "6px",
              fontSize: "12px",
            }}
          >
            {name}
          </h5>
        </div>
        <div
          className="colors"
          style={{
            display: "flex",
            backgroundColor: "red",
            alignSelf: "flex-start",
          }}
        ></div>
        <div className="number">
          <p>{incidents}</p>
        </div>
      </div>
    );
  };
  return (
    <div
      onClick={() => {
        if (childFuncRef?.current && childFuncRef?.current?.handleSearch) {
          childFuncRef?.current?.handleSearch();
        }
      }}
      className="side-padding aaa"
    >
      <div
        className={
          scroll ? "main-layout top-bar-margin-handle" : "main-layout "
        }
      >
        <div className="container mt-4">
          <NewDashboaredHeader
            tilesData={prevPageData}
            updateTilesData={setPrevPageData}
            tilesArr={tilesData}
            updateTitleArr={setTilesData}
            setAdvanceModalOpen={() => {
              // advanceModalOpenState = true
              modalOpenDone = true
              setAdvanceModalOpen(true)

            }}
            removeTileFromSelectedSearch={removeTileFromSelectedSearch}
          />
          <div className="row new-dashboared-mg-top">
            <div className="col-lg-4">
              <div className="hone-left-side">
                <div className="add-to-wat">
                  <button type="button" onClick={() => setModal2Open(true)}>
                    <img src={Images.plusWhite} /> Add to watchlist
                  </button>
                </div>
                <Modal
                  title="Add to Watchlist"
                  centered
                  className="model-watch cross-model"
                  open={modal2Open}
                  onCancel={() => setModal2Open(false)}
                  footer={[
                    <button
                      className="can-btn"
                      onClick={() => setModal2Open(false)}
                    >
                      Cancel
                    </button>,
                    <button
                      className="rest-btn done-btn"
                      onClick={() => setModal2Open(false)}
                    >
                      Done
                    </button>,
                  ]}
                >
                  <div className="watchlistModalContent">
                    {watchListData?.map((item, index) => (
                      <div key={index} className="empty-watch">
                        <div className="first-watch">
                          <h5>{item?.name}</h5>
                          <p>{` ${item.companyCount} companies ,${item.locationCount} locations, ${item.industryCount} industry`}</p>
                        </div>
                        <div
                          onClick={() => {
                            const payload = {
                              ...mergedData,
                              name: item.name,
                              wishlist: item._id,
                            };
                            delete payload.start;
                            delete payload.end;
                            delete payload.limit;
                            delete payload.offset;
                            if (!watchlistAddedIds.includes(item._id)) {
                              setWatchListAddedIds([
                                ...watchlistAddedIds,
                                item._id,
                              ]);
                            }
                            dispatch(addWishListItemIdAction(item?._id));

                            dispatch(editWishListAction(payload));
                          }}
                          className="plus-add-btn plus-add-btn"
                        >
                          {isLoading && item._id == addWatchListID ? (
                            <button type="button">
                              <Spin />
                            </button>
                          ) : watchlistAddedIds.includes(item._id) ? (
                            <button type="button">
                              <img src={Images.check} />
                              Added
                            </button>
                          ) : (
                            <button
                              type="button"
                              style={{ backgroundColor: "none" }}
                            >
                              <img src={Images.plus} />
                              Add
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="list-nam-md">
                    <label>List Name</label>
                    <div className="text-fild">
                      <input
                        type="text"
                        placeholder="Watchlist 2"
                        onChange={(e) => {
                          if (e.target.value > 3) {
                            setWatchlist(e.target.value);
                          } else {
                            setWatchlist(e.target.value);
                          }
                        }}
                      />
                      <button
                        onClick={() => {
                          dispatch(
                            addWishListAction({
                              name: newWatchlist,
                              locations: [],
                              companies: [],
                              esg_factors: [],
                              esg_sdgs: [],
                              esg_pais: [],
                              industries: [],
                            })
                          );
                        }}
                        type="button"
                      >
                        <img src={Images.plusWhite} />
                        Create
                      </button>
                    </div>
                  </div>
                </Modal>
                <div className="home-left-list mt-3">
                  <ul>
                    <li
                      className={activeItem == "Overview" ? "tab-active" : ""}
                    >
                      <a onClick={() => scrollTo(0)}> Overview </a>
                    </li>
                    <li
                      className={
                        activeItem == "Company Risk Score" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(150)}>1 Company Risk Score</a>
                    </li>
                    <li
                      className={
                        activeItem == "Incident Analysis" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(1430)}>2 Incident Analysis</a>
                    </li>
                    <li
                      className={
                        activeItem == "Social Media Coverage"
                          ? "tab-active"
                          : ""
                      }
                    >
                      <a onClick={() => scrollTo(4880)}>
                        3 Social Media Coverage
                      </a>
                    </li>
                    <li
                      className={
                        activeItem == "Breaches & violation" ? "tab-active" : ""
                      }
                    >
                      <a onClick={() => scrollTo(5750)}>
                        4 Breaches & violation
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="top-right-home-drop">
                <div>
                  {/* <a href=""> */}
                  <img
                    onClick={() => {
                      callDownloadApi();
                    }}
                    src={Images.framedow}
                  />
                  {/* </a> */}
                </div>
                {/* social  share button  model MM  */}
                <Modal
                  title={"Share"}
                  centered
                  className="share"
                  open={isModalOpen}
                  footer={false}
                  onCancel={() => setIsModalOpen(false)}
                >
                  <FacebookShareButton url={"www.google.com"} quote={"asasas"}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>

                  <LinkedinShareButton url={"www.google.com"} title={"asasas"}>
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>

                  <TwitterShareButton url={"www.google.com"} title={"asasasa"}>
                    <XIcon size={32} round={true} />
                  </TwitterShareButton>
                </Modal>
                <DatePicker
                  onDatePickerChange={onDatePickerChange}
                  lastDate={lastDate}
                />
              </div>
              <section>
                <div className="container g-0 mainHomeContainer">
                  <div className="row">
                    <div className="col mt-5">
                      <div className="inciden-box">
                        <div className="total-box">
                          <h5>All Incidents</h5>
                          <h1>{nFormatter(severeAndSocial?.totalIncidents)}</h1>
                        </div>
                        {socialBox.map(({ img, label, count }) => (
                          <SocialBox
                            img={img}
                            label={label}
                            count={nFormatter(count)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col mt-3">
                      <div className="seve-main-boxes" style={{ gap: "16px" }}>
                        <div className="fir-box">
                          <img src={Images.circle} alt="" />
                          <p>
                            Severe
                            <span>
                              <img
                                className="hover-page"
                                src={Images.circleInfo}
                                alt=""
                              />
                              <div className="infohover-home">
                                <p>
                                  We use AI to determine the severity of an
                                  incident based on its content. However, since
                                  it is often a subjective matter, this
                                  description should not be solely relied on. It
                                  is intended as a helpful clue to assist users.
                                </p>
                              </div>
                            </span>
                          </p>

                          <h5 className="gap">
                            {nFormatter(severeAndSocial?.severeHighCount)}
                          </h5>
                        </div>

                        <div
                          className="fir-box"
                        // onClick={() => {
                        //   let dummyTopLevelIncidentListModalPayload = {
                        //     ...networkData,
                        //   };
                        //   const getDate = getDateTwoDaysAgo(
                        //     dummyTopLevelIncidentListModalPayload.end
                        //   );
                        //   dummyTopLevelIncidentListModalPayload.start =
                        //     getDate;
                        //   delete dummyTopLevelIncidentListModalPayload.showSever;
                        //   setTopLevelIncidentListModalPayload(
                        //     dummyTopLevelIncidentListModalPayload
                        //   );
                        //   setRootLevelIncidentModalItem(true);
                        // }}
                        >
                          <img src={Images.felame} />
                          <p>New</p>
                          <p>(last 2 days)</p>
                          <h5> {nFormatter(severeAndSocial?.lastTwoDays)}</h5>
                        </div>

                        <div className="fir-box">
                          <img src={Images.clockGreen} />
                          <p>Recent</p>
                          <p>(last month)</p>
                          <h5>{nFormatter(severeAndSocial?.lastMonth)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="collapse-section-ps containerWidth">
                <Collapse
                  className="customizing-colaps-ps"
                  defaultActiveKey={["1", "2", "3", "4"]}
                  expandIcon={({ isActive }) => {
                    return (
                      <img
                        src={Images.icondown}
                        style={{
                          transform: `rotate(${isActive ? "0" : "-90"}deg)`,
                        }}
                      />
                    );
                  }}
                >
                  <Collapse.Panel header=" 1 Company Risk Score" key="1">
                    <section className="left-text">
                      <ChartCard
                        showTimeBarOnTop={false}
                        title={"Risk Score Per Company"}
                      >
                        <CompanyRiskScore
                          data={homeESGRiskData}
                          viewMoreState={homeESGRiskDataMore}
                          viewMoreHandler={companyRiskScoreMoreData_Handler}
                          isViewBtnShow={homeESGRiskDataViewBtn}
                          stateLoader={stateLoader}
                          setRiskScorePerCompOffset={setRiskScorePerCompOffset}
                        />
                      </ChartCard>
                    </section>

                    <div className="mt-4">
                      <ChartCard
                        showTimeBarOnTop={false}
                        title={
                          "Total Number of Incidents / High Severity Incidents"
                        }
                      >
                        <CompanyHighSeverity
                          data={homeHIGHSeverityData}
                          viewMoreState={noOfIncidentShowMore}
                          viewMoreHandler={companyRiskScoreMoreData_Handler}
                          isViewBtnShow={homeHIGHSeverityDataViewBtn}
                          setNoOfIncidentOffset={setNoOfIncidentOffset}
                        />
                      </ChartCard>
                    </div>
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          2
                        </span>
                        Incident Analysis
                      </h4>
                    }
                    key="2"
                  >
                    {Object.entries(totalEsgIncidentCount).length > 0 &&
                      (totalEsgIncidentCount?.Environmental != 0 ||
                        totalEsgIncidentCount?.Social != 0 ||
                        totalEsgIncidentCount?.Governance != 0) && (
                        <HomeIncidentTable
                          columns={columns1}
                          id={selectedCountry}
                          icon={true}
                          title={"Incidents List Preview"}
                          data={IPLData?.data?.slice(0, 4)}
                          showModal={state?.visible}
                          date={{
                            ...networkData,
                            locations: prevPageData?.locations,
                            esg_factors: prevPageData?.esg_factors,
                            esg_categories: prevPageData?.esg_categories,
                            companies: prevPageData?.companies,
                          }}
                          idsArray={
                            state?.selectedTile?.companies?.length > 0
                              ? state?.selectedTile?.companies?.map((x) => x.id)
                              : []
                          }
                          companyName={
                            state?.selectedTile?.companies?.length > 0
                              ? state?.selectedTile?.companies?.map(
                                (x) => x.name
                              )
                              : []
                          }
                          tabKey={[tabKey, setTabKey]}
                          tabVal={[tabVal, setTabVal]}
                          tabShowCount={totalEsgIncidentCount}
                        />
                      )}
                    <div className="inc-map-pre mt-4 containerWidth">
                      <div className="tab-top">
                        <div className="left-text">
                          <Row>
                            <h4>Incidents Preview</h4>
                            <img
                              className="hover-page"
                              style={{ marginLeft: 5 }}
                              src={Images.circleInfo}
                              alt=""
                            />
                          </Row>
                        </div>
                        <div
                          className="righ-side"
                          onClick={() =>
                            navigate("/fullMap", {
                              state: {
                                mergedData,
                                comeFrom: "searchdashboardpage",
                              },
                            })
                          }
                        >
                          <a>
                            View Full Map
                            <span>
                              <img src={Images.iconser} />
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="container">
                        <div className="row">
                          <div
                            className="col-10 mt-3 mb-3"
                            style={{
                              position: "relative",
                              height: "326px",
                            }}
                          >
                            <div className="map-pre">
                              <div
                                style={{
                                  left: "1vw",
                                  bottom: "6px",
                                  padding: ".6vw",
                                  position: "absolute",
                                  display: "flex",
                                  zIndex: "1",
                                  backgroundColor: "#ffffff",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  style={{
                                    marginBottom: ".4vw",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Severity
                                </span>
                                <div>
                                  <MapItem color={"#B70000"} name={"High"} />
                                  <MapItem color={"#F28C28"} name={"Medium"} />
                                  <MapItem color={"#FFD700"} name={"Low"} />
                                </div>
                              </div>

                              <FullMap
                                width={"600px"}
                                height={"326px"}
                                zoom={0}
                                data={severityDataLocationArray || []}
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <div
                              className="asia-cont"
                              style={{
                                minWidth: "100px",
                              }}
                            >
                              {update?.map((item, index) => {
                                return (
                                  <div className="africa-co">
                                    <h5>{item.continent_code}</h5>
                                    <p>{item.incidents}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container g-0 pie-char-main-sec mt-4">
                      <div className="category">
                        <Row>
                          <h5>Incidents separated by Category </h5>
                          <img
                            className="hover-page"
                            style={{ marginLeft: 5 }}
                            src={Images.circleInfo}
                            alt=""
                          />
                        </Row>
                      </div>
                      <div className="row pb-4">
                        <div className="col-md-7 pie-chart-sec">
                          <div className="cat-name">
                            <SeprateCategoryComponent
                              name={"Environment"}
                              incidents={sepratedCategory?.Environmental?.total}
                            />
                            <SeprateCategoryComponent
                              name={"Social"}
                              incidents={sepratedCategory?.Social?.total}
                            />
                            <SeprateCategoryComponent
                              name={"Governance"}
                              incidents={sepratedCategory?.Governance?.total}
                            />
                          </div>
                        </div>
                        <div className="col-md-5 ">
                          <div
                            className="pie-chart-new"
                            style={{ textAlign: "center" }}
                          >
                            <EChartsReact
                              className="doubleLayer-pieChart"
                              option={{
                                silent: false,
                                tooltip: {
                                  show: true,
                                  formatter: (params) => {
                                    let value = params.data?.name;
                                    if (value.includes("\n")) {
                                    }
                                    return `${params.data?.esgName ||
                                      params.data?.data?.name
                                      } ${params.data?.percentage}`;
                                  },
                                },
                                series: [
                                  {
                                    label: {
                                      color: "#07132f",
                                      textBorderColor: "#fff",
                                    },

                                    itemStyle: {
                                      borderRadius: 1,
                                      borderWidth: 2,
                                    },
                                    radius: ["0", "80%"],
                                    type: "sunburst",
                                    emphasis: {
                                      focus: "ancestor",
                                    },

                                    data: [
                                      {
                                        children: getChildData(
                                          sepratedCategory?.Governance?.data,
                                          "Governance"
                                        ),
                                        name: `G\n${getPercentage(
                                          sepratedCategory?.Governance?.total
                                        )}`,
                                        esgName: "Governance",
                                        percentage: getPercentage(
                                          sepratedCategory?.Governance?.total
                                        ),
                                        label: {
                                          rotate: 0,
                                        },
                                        value:
                                          sepratedCategory?.Governance?.data?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                currentValue?.incidentCount
                                              );
                                            },
                                            0
                                          ),
                                        itemStyle: {
                                          color: {
                                            colorStops: [
                                              {
                                                offset: 0.3,
                                                color:
                                                  "rgba(101, 61, 212, 0.3)",
                                              },
                                              {
                                                offset: 1,
                                                color: "#653DD4",
                                              },
                                            ],
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1.5,
                                            type: "linear",
                                            global: false,
                                          },
                                        },
                                      },

                                      {
                                        children: getChildData(
                                          sepratedCategory?.Environmental?.data,
                                          "Environmental"
                                        ),
                                        name: `E\n${getPercentage(
                                          sepratedCategory?.Environmental?.total
                                        )}`,
                                        esgName: "Environmental",
                                        percentage: getPercentage(
                                          sepratedCategory?.Environmental?.total
                                        ),
                                        label: {
                                          rotate: 0,
                                        },
                                        value:
                                          sepratedCategory?.Environmental?.data?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                currentValue?.incidentCount
                                              );
                                            },
                                            0
                                          ),
                                        itemStyle: {
                                          color: {
                                            colorStops: [
                                              {
                                                offset: 0.3,
                                                color: "rgba(97, 166, 28, 0.3)",
                                              },
                                              {
                                                offset: 1,
                                                color: "#6EAD2F",
                                              },
                                            ],
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1.5,
                                            type: "linear",
                                            global: false,
                                          },
                                        },
                                      },

                                      {
                                        children: getChildData(
                                          sepratedCategory?.Social?.data,
                                          "Social"
                                        ),
                                        name: `S\n${getPercentage(
                                          sepratedCategory?.Social?.total
                                        )}`,
                                        esgName: "Social",
                                        percentage: getPercentage(
                                          sepratedCategory?.Social?.total
                                        ),
                                        label: {
                                          rotate: 0,
                                        },
                                        value:
                                          sepratedCategory?.Social?.data?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                currentValue?.incidentCount
                                              );
                                            },
                                            0
                                          ),
                                        itemStyle: {
                                          color: {
                                            colorStops: [
                                              {
                                                offset: 0.3,
                                                color:
                                                  "rgba(61, 155, 207, 0.25)",
                                              },
                                              {
                                                offset: 1,
                                                color: "#4DA0F9",
                                              },
                                            ],
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1.5,
                                            rotate: 0,
                                            type: "linear",
                                            global: false,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              }}
                              onEvents={{
                                click: onChartClick,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <IncidentOverTime {...mergedData} />

                    <div className="container g-0 containerWidth">
                      <div className="row mt-4">
                        <div className="col-lg-6 ">
                          <CompanyIncidentTable
                            key={1}
                            data={companiesWithIncident}
                            title={"Companies with Highest Incident Rate"}
                            titleImg={Images.hotel}
                            subTitle={"Company"}
                          />
                        </div>

                        <div className="col-lg-6 ">
                          <CompanyIncidentTable
                            key={2}
                            data={countriesWithIncident}
                            title={"Locations with Highest Incident Rate"}
                            titleImg={Images.flag}
                            subTitle={"Country"}
                            date={{
                              start: networkData.start,
                              end: networkData.end,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          3
                        </span>
                        Social Media Coverage
                      </h4>
                    }
                    key="3"
                  >
                    <ChartCard
                      showTimeBarOnTop={false}
                      title={"Social Media Coverage"}
                      legend={PNN}
                    >
                      <div className="line-chart">
                        <Row>
                          <div className="col-sm-6 socialChart">
                            <span className="captionXl">
                              Social Media Sentiment Distribution
                            </span>
                            <EChartsReact
                              className="doubleLayer-pieChart mt-4"
                              option={{
                                silent: false,
                                series: [
                                  {
                                    nodeClick: false,
                                    label: {
                                      color: "#fff",
                                      fontSize: "18",
                                      textBorderColor: "#fff",
                                    },
                                    itemStyle: {
                                      borderRadius: 10,
                                      borderWidth: 5,
                                    },
                                    radius: ["0", "100%"],
                                    type: "sunburst",
                                    emphasis: {
                                      focus: "ancestor",
                                    },
                                    data: [
                                      {
                                        children: sentimentObj?.tweets,
                                        name: "Tweets",
                                        value: 30,
                                        itemStyle: {
                                          color: {
                                            colorStops: [
                                              {
                                                offset: 0,
                                                color: "#1DA1F2",
                                              },
                                              {
                                                offset: 1,
                                                color: "rgba(135, 207, 255, 1)",
                                              },
                                            ],
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1.5,
                                            type: "linear",
                                            global: false,
                                          },
                                        },
                                      },
                                      {
                                        children: sentimentObj?.reddit,
                                        name: "Reddit",
                                        value: 30,
                                        itemStyle: {
                                          color: {
                                            colorStops: [
                                              {
                                                offset: 0,
                                                color: "#FF4500",
                                              },
                                              {
                                                offset: 1,
                                                color: "rgba(255, 171, 140, 1)",
                                              },
                                            ],
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1.5,
                                            type: "linear",
                                            global: false,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              }}
                            />
                          </div>
                          <div className="col-sm-6  socialChart">
                            <span className="captionXl">
                              Sentiment Distribution by Social Media Platforms
                            </span>
                            <EChartsReact
                              className="doubleLayer-pieChart  mt-4"
                              option={{
                                silent: false,

                                series: [
                                  {
                                    label: {
                                      color: "#fff",
                                      fontSize: "18",
                                      textBorderColor: "#fff",
                                    },
                                    nodeClick: false,
                                    itemStyle: {
                                      borderRadius: 10,
                                      borderWidth: 5,
                                    },
                                    radius: ["0", "100%"],
                                    type: "sunburst",
                                    emphasis: {
                                      focus: "ancestor",
                                    },
                                    data: [
                                      {
                                        children:
                                          socialObj?.positive?.childArray,
                                        name:
                                          socialObj?.positive?.percentage +
                                          " %",
                                        value: 30,
                                        itemStyle: {
                                          color: {
                                            colorStops: [
                                              {
                                                offset: 0,
                                                color: "#3aa95a",
                                              },
                                              {
                                                offset: 1,
                                                color: "#ffffff",
                                              },
                                            ],
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1.5,
                                            type: "linear",
                                            global: false,
                                          },
                                        },
                                      },
                                      {
                                        children:
                                          socialObj?.negative?.childArray,
                                        name:
                                          socialObj?.negative?.percentage +
                                          " %",
                                        value: 30,
                                        itemStyle: {
                                          color: {
                                            colorStops: [
                                              {
                                                offset: 0,
                                                color: "#d72727",
                                              },
                                              {
                                                offset: 1,
                                                color: "#ffffff",
                                              },
                                            ],
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1.5,
                                            type: "linear",
                                            global: false,
                                          },
                                        },
                                      },
                                      {
                                        children:
                                          socialObj?.neutral?.childArray,
                                        name:
                                          socialObj?.neutral?.percentage + " %",
                                        value: 30,
                                        itemStyle: {
                                          color: {
                                            colorStops: [
                                              {
                                                offset: 0,
                                                color: "#bec3cd",
                                              },
                                              {
                                                offset: 1,
                                                color: "#ffffff",
                                              },
                                            ],
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1.5,
                                            type: "linear",
                                            global: false,
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              }}
                            />
                          </div>
                        </Row>
                        <div className="mainLegendDiv">
                          {legend?.map((e, i) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  className="legendImg"
                                  src={e.image}
                                  alt={e.name}
                                />
                                <div
                                  className="legendSpan"
                                  style={{ marginLeft: "10px" }}
                                >
                                  {" "}
                                  {e.name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </ChartCard>
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="company-collapse-header"
                    header={
                      <h4>
                        <span className="company-page-collable-label-text">
                          4
                        </span>
                        Breaches & violation
                      </h4>
                    }
                    key="4"
                  >
                    <div
                      className="most-sec-over containerWidth"
                      style={{ padding: 20 }}
                    >
                      <div className="violations-ps">
                        <h2>Breaches and Violations</h2>
                      </div>
                      <Tabs
                        onChange={(e) => {
                          setActiveTab(e);
                        }}
                        activeKey={activeTab}
                        className="supply-chain-act-px "
                      >
                        <Tabs.TabPane key="0" tab={"LkSG"}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.breachesIcons}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      German Supply Chain Act (LkSG)
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {totalCount(
                                        breachesAndViolationData?.most_frequent ||
                                        []
                                      )}{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of violations
                                  </span>
                                </Row>
                                <div></div>
                              </div>

                              <div>
                                {breachesAndViolationData?.german_supply_chain_act &&
                                  breachesAndViolationData
                                    .german_supply_chain_act.length > 0 &&
                                  breachesAndViolationData?.german_supply_chain_act.map(
                                    (item, index) => {
                                      return (
                                        <div className="companiesDataDiv">
                                          <span>
                                            {index + 1 + " "}
                                            <b
                                              onClick={() =>
                                                navigate("/companyPage", {
                                                  state: { id: item?.id },
                                                })
                                              }
                                            >
                                              {item.name + " "}
                                            </b>
                                          </span>

                                          <div
                                            style={{
                                              width: 100,
                                              height: 19,
                                              display: "flex",
                                            }}
                                          >
                                            {item.incidentCount + " incidents"}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>

                              {breachesAndViolationData?.esg_supply_chain_act &&
                                breachesAndViolationData.esg_supply_chain_act
                                  .length > 9 && (
                                  <div
                                    className="reactionAnalyses"
                                    onClick={() => {
                                      // setModalVisible(true);
                                      setBreachesViolationLimit(200);
                                    }}
                                  >
                                    <span className="reactionAnalysesSpan downl-btn">
                                      View All
                                    </span>
                                  </div>
                                )}

                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent violations
                                </span>
                              </div>
                              {activeTab == 0 &&
                                breachesAndViolationData?.most_frequent &&
                                breachesAndViolationData.most_frequent.length >
                                0 &&
                                breachesAndViolationData?.most_frequent?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                        value={val.value}
                                        dataKey={val._id}
                                      />
                                    );
                                  }
                                )}

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="1" tab={"EU Supply Chain Act."}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.breachesIcons}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      Supply Chain Act Violations
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {totalCount(
                                        breachesAndViolationData?.most_frequent ||
                                        []
                                      )}{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of violations
                                  </span>
                                </Row>
                                <div></div>
                              </div>

                              <div>
                                {breachesAndViolationData?.esg_supply_chain_act &&
                                  breachesAndViolationData.esg_supply_chain_act
                                    .length > 0 &&
                                  breachesAndViolationData?.esg_supply_chain_act.map(
                                    (item, index) => {
                                      return (
                                        <div className="companiesDataDiv">
                                          <span>
                                            {index + 1 + " "}
                                            <b
                                              onClick={() =>
                                                navigate("/companyPage", {
                                                  state: { id: item?.id },
                                                })
                                              }
                                            >
                                              {item.name + " "}
                                            </b>
                                          </span>

                                          <div
                                            style={{
                                              width: 100,
                                              height: 19,
                                              display: "flex",
                                            }}
                                          >
                                            {item.incidentCount + " incidents"}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>

                              {breachesAndViolationData?.esg_supply_chain_act &&
                                breachesAndViolationData.esg_supply_chain_act
                                  .length > 9 && (
                                  <div
                                    className="reactionAnalyses"
                                    onClick={() => {
                                      setBreachesViolationLimit(200);
                                    }}
                                  >
                                    <span className="reactionAnalysesSpan downl-btn">
                                      View All
                                    </span>
                                  </div>
                                )}

                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent violations
                                </span>
                              </div>
                              {activeTab == 1 &&
                                breachesAndViolationData?.most_frequent &&
                                breachesAndViolationData.most_frequent.length >
                                0 &&
                                breachesAndViolationData?.most_frequent?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                        value={val.value}
                                        dataKey={val.value}
                                      />
                                    );
                                  }
                                )}

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="2" tab={"UNGC"}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.un}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      UN global compact principles breaches
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {activeTab == "2" &&
                                        totalCount(
                                          incidentAnalyzer_UN_Breaches?.most_frequent_breaches ||
                                          []
                                        )}{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of breaches
                                  </span>
                                </Row>
                                <div></div>
                              </div>

                              <div>
                                {activeTab == 2 &&
                                  incidentAnalyzer_UN_Breaches?.companies_with_breaches?.map(
                                    (e, index) => (
                                      <div className="companiesDataDiv">
                                        <span>
                                          {index + 1 + " "}
                                          <b
                                            onClick={() =>
                                              navigate("/companyPage", {
                                                state: { id: e?.id },
                                              })
                                            }
                                          >
                                            {e.name + " "}
                                          </b>
                                        </span>

                                        <div
                                          style={{
                                            width: 100,
                                            height: 19,
                                            display: "flex",
                                          }}
                                        >
                                          {e.incidentCount + " incidents"}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent breaches
                                </span>
                              </div>
                              {incidentAnalyzer_UN_Breaches?.most_frequent_breaches?.map(
                                (val, ind) => {
                                  return (
                                    <UngcpBreaches
                                      networkData={networkData}
                                      activeTab={activeTab}
                                      type={val._id}
                                      list={val?.incidents?.slice(0, 4)}
                                      totalIncidentCount={
                                        val?.incidents?.length
                                      }
                                      dataKey={val._id}
                                    />
                                  );
                                }
                              )}
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Total incidents on each principle
                                </span>
                              </div>
                              <>
                                <PrincipleBarChat
                                  series={BarMapping(
                                    incidentAnalyzer_UN_Breaches?.most_frequent_breaches
                                  )}
                                  networkData={networkData}
                                />

                                <div className="ellips chart-bottom-legend-New">
                                  {HLEA?.map((data) => {
                                    return (
                                      <p className="legend">
                                        <span
                                          className="legendCircle"
                                          style={{ background: data.color }}
                                        />
                                        {data.tittle}
                                      </p>
                                    );
                                  })}
                                </div>
                              </>

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="3" tab={"PAI"}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.pia}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      Principal Adverse Impact (PAI) Violations
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {
                                        breachesAndViolationData.mostFrequentCount
                                      }{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of breaches
                                  </span>
                                </Row>
                                <div></div>
                              </div>

                              <div>
                                {activeTab == 3 &&
                                  breachesAndViolationData?.esg_PAI &&
                                  breachesAndViolationData?.esg_PAI.length >
                                  0 &&
                                  breachesAndViolationData?.esg_PAI?.map(
                                    (e, index) => (
                                      <div className="companiesDataDiv">
                                        <span>
                                          {index + 1 + " "}
                                          <b
                                            onClick={() =>
                                              navigate("/companyPage", {
                                                state: { id: e?.id },
                                              })
                                            }
                                          >
                                            {e.name + " "}
                                          </b>
                                        </span>

                                        <div className="Incident_on_ESG">
                                          {e.incidentCount + " incidents"}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>

                              {activeTab == 3 &&
                                breachesAndViolationData?.esg_PAI &&
                                breachesAndViolationData?.esg_PAI.length >
                                9 && (
                                  <div
                                    className="reactionAnalyses"
                                    onClick={() => {
                                      setBreachesViolationPaiLimit(200);
                                    }}
                                  >
                                    <span className="reactionAnalysesSpan downl-btn">
                                      View All
                                    </span>
                                  </div>
                                )}
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent breaches
                                </span>
                              </div>
                              {activeTab == 3 &&
                                breachesAndViolationData?.most_frequent &&
                                breachesAndViolationData?.most_frequent.length >
                                0 &&
                                breachesAndViolationData?.most_frequent?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                      />
                                    );
                                  }
                                )}
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Total Number of incidents
                                </span>
                              </div>
                              <>
                                {activeTab == 3 &&
                                  breachesAndViolationData?.most_frequent &&
                                  breachesAndViolationData?.most_frequent
                                    .length > 0 &&
                                  !breachesAndViolationDataLoader && (
                                    <PAIBarChat
                                      series={BarMappingPAI(
                                        breachesAndViolationData?.most_frequent
                                      )}
                                      networkData={networkData}
                                    />
                                  )}
                              </>
                              <div className="pia-bott-hover">
                                <p
                                  className="onhover-image"
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                >
                                  <span>
                                    <img
                                      className={isHovered}
                                      src={
                                        isHovered
                                          ? Images.newImageOnHover
                                          : Images.circleInfo
                                      }
                                      alt=""
                                    />
                                  </span>
                                  14 Principal Adverse Impact (PAI)
                                </p>

                                <div className="hover-content-ps">
                                  <div className="adverse-heading-ps">
                                    <h5>
                                      {" "}
                                      <span>
                                        <img src={Images.colorSky} />
                                      </span>
                                      14 Principal Adverse Impact (PAI)
                                    </h5>
                                  </div>
                                  <div className="number-of-impact-ps">
                                    <p>1. GHG emissions scope 1,2,3, total</p>
                                    <p>2. Carbon footprint</p>
                                    <p>
                                      3. GHG intensity of investee companies
                                    </p>
                                    <p>
                                      4. Exposure to companies active in the
                                      fossil fuel sector
                                    </p>
                                    <p>
                                      5. Share of non-renewable energy
                                      consumption and production
                                    </p>
                                    <p>
                                      6. Energy consumption intensity per high
                                      impact climate sector
                                    </p>
                                    <p>
                                      7. Activities negatively affecting
                                      biodiversity-sensitive areas
                                    </p>
                                    <p>8. Emissions to water</p>
                                    <p>
                                      9. Hazardous waste and radioactive waste
                                      ratio
                                    </p>
                                    <p>
                                      10. Violations of UN Global Compact
                                      principles and OECD guidelines{" "}
                                    </p>
                                    <p>
                                      11. Lack of processes and compliance
                                      mechanisms to monitor compliance with UN
                                      GC and OECD
                                    </p>
                                    <p>12. Unadjusted gender pay gap</p>
                                    <p>13. Board gender diversity</p>
                                    <p>14. Exposure to controversial weapons</p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>

                        <Tabs.TabPane key="4" tab={"SDG"}>
                          <section>
                            <div className="container  g-0  ">
                              <div className="UNclass">
                                <img
                                  className="sq104px"
                                  src={Images.sdg}
                                  alt="UN"
                                />
                                <div className="d-flex justify-content row">
                                  <Row>
                                    <span className="unSpan">
                                      UN global compact principles breaches
                                      <img
                                        className="hover-page"
                                        style={{ marginLeft: 5 }}
                                        src={Images.circleInfo}
                                        alt=""
                                      />
                                    </span>
                                  </Row>

                                  <div className="d-flex justify-content">
                                    <span className="unSpanCount color05476F pl-16">
                                      {
                                        breachesAndViolationData.mostFrequentCount
                                      }{" "}
                                      Incidents
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content">
                                <Row>
                                  <span className="unSpanCount">
                                    Companies with the most number of breaches
                                  </span>
                                </Row>
                                <div></div>
                              </div>

                              <div>
                                {activeTab == 4 &&
                                  breachesAndViolationData?.esg_SDG &&
                                  breachesAndViolationData?.esg_SDG.length >
                                  0 &&
                                  breachesAndViolationData?.esg_SDG?.map(
                                    (e, index) => (
                                      <div className="companiesDataDiv">
                                        <span>
                                          {index + 1 + " "}
                                          <b
                                            onClick={() =>
                                              navigate("/companyPage", {
                                                state: { id: e?.id },
                                              })
                                            }
                                          >
                                            {e.name + " "}
                                          </b>
                                        </span>

                                        <div className="Incident_on_ESG">
                                          {e.incidentCount + " incidents"}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>

                              {activeTab == 4 &&
                                breachesAndViolationData?.esg_SDG &&
                                breachesAndViolationData?.esg_SDG.length >
                                9 && (
                                  <div
                                    className="reactionAnalyses"
                                    onClick={() => {
                                      setBreachesViolationSdgLimit(200);
                                    }}
                                  >
                                    <span className="reactionAnalysesSpan downl-btn">
                                      View All
                                    </span>
                                  </div>
                                )}

                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Most frequent breaches
                                </span>
                              </div>
                              {activeTab == 4 &&
                                breachesAndViolationData?.most_frequent &&
                                breachesAndViolationData?.most_frequent.length >
                                0 &&
                                breachesAndViolationData?.most_frequent?.map(
                                  (val, ind) => {
                                    return (
                                      <UngcpBreaches
                                        networkData={networkData}
                                        activeTab={activeTab}
                                        type={val._id}
                                        list={val?.incidents?.slice(0, 4)}
                                        totalIncidentCount={
                                          val?.incidents?.length
                                        }
                                      />
                                    );
                                  }
                                )}
                              <div className="d-flex justify-content">
                                <span className="unSpanCount">
                                  Total Number of incidents on each goal
                                </span>
                              </div>
                              <>
                                {activeTab == 4 &&
                                  breachesAndViolationData?.most_frequent &&
                                  breachesAndViolationData?.most_frequent
                                    .length > 0 &&
                                  !breachesAndViolationDataLoader && (
                                    <SDGBarChat
                                      series={BarMappingSDG(
                                        breachesAndViolationData?.most_frequent
                                      )}
                                      networkData={networkData}
                                    />
                                  )}
                              </>
                              <div className="pia-bott-hover">
                                <p
                                  className="onhover-image"
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                >
                                  <span>
                                    <img
                                      className={isHovered}
                                      src={
                                        isHovered
                                          ? Images.newImageOnHover
                                          : Images.circleInfo
                                      }
                                      alt=""
                                    />
                                  </span>
                                  17 Sustainable Development Goals
                                </p>

                                <div className="hover-content-ps">
                                  <div className="adverse-heading-ps">
                                    <h5>
                                      {" "}
                                      <span>
                                        <img src={Images.colorSky} />
                                      </span>
                                      17 Sustainable Development Goals
                                    </h5>
                                  </div>
                                  <div className="number-of-impact-ps">
                                    <p>1. No poverty</p>
                                    <p>2. Zero hunger</p>
                                    <p>3. Good health and well-being</p>
                                    <p>4. Quality education</p>
                                    <p>5. Gender equality</p>
                                    <p>6. Clean water and sanitation</p>
                                    <p>7. Affordable and clean energy</p>
                                    <p>8. Decent work and economic growth</p>
                                    <p>
                                      9. Industry, innovation and infrastructure
                                    </p>
                                    <p>10. Reduced inequalities </p>
                                    <p>
                                      11. Sustainable cities and communities
                                    </p>
                                    <p>
                                      12. Responsible consumption and production
                                    </p>
                                    <p>13. Climate action</p>
                                    <p>14. Life below water</p>
                                    <p>15. Life on land</p>
                                    <p>
                                      16. Peace, justice and strong institutions
                                    </p>
                                    <p>17. Partnership for the goals</p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="reactionAnalyses"
                                onClick={() => {
                                  setModalVisible(true);
                                }}
                              >
                                <span className="reactionAnalysesSpan downl-btn">
                                  View All Incidents
                                </span>
                              </div>
                            </div>
                          </section>
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
        {rootLevelIncidentModalItem && (
          <Modal
            className="incidentModal"
            open={rootLevelIncidentModalItem}
            onCancel={() => setRootLevelIncidentModalItem(false)}
            footer={null}
            width={1600}
            centered
          >
            <IncidentsList
              payloadRootLevel={topLevelIncidentListModalPayload}
              isComeFromModal={true}
            />
          </Modal>
        )}
        {modalVisible ? (
          <IncidentsListModal
            setModalVisible={(e) => {
              setModalVisible(e);
              if (!e) {
                networkData = { ...networkData, esg_categories: [] };
              }
            }}
            networkData={networkData}
          />
        ) : null}
        {selectedCategoriesModal && (
          <Modal
            className="incidentModal"
            open={selectedCategoriesModal}
            onCancel={() => setSelectedCategoriesModal(false)}
            footer={null}
            width={1600}
            centered
          >
            <BubleIncidentsList
              apiPayload={networkData}
              bubbleData={true}
              esg_categories={
                selectedEsgCategories?.length > 0
                  ? selectedEsgCategories
                  : ["ss"]
              }
            />
          </Modal>
        )}
      </div>
      {advanceModalOpenState ? (
        <SearchMain
          prevScreenData={prevPageData}
          updatePrevScreenData={setPrevPageData}
          modal2Open={advanceModalOpenState}
          setModal2Open={() => {
            setAdvanceModalOpen(false)
          }}
        />
      ) : null}
    </div>
  );
};

// Define a rendering function called MapItem that takes color and name as parameters
const MapItem = ({ color, name }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: ".6vw",
        height: ".6vw",
        borderRadius: "100%",
        marginRight: ".5vw",
        backgroundColor: color,
      }}
    ></div>
    <div style={{ fontSize: ".8rem" }}>{name}</div>
  </div>
);
const CompanyRiskScore = ({
  data,
  viewMoreState,
  viewMoreHandler,
  isViewBtnShow,
  setRiskScorePerCompOffset,
  stateLoader,
}) => {
  return (
    <div className="portfolio_container_1 custom_container_portfolio_1 riskScoreOverflow">
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          if (!viewMoreState && index < 10) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "50%" }}
                  className={style.leftDataContainer}
                >
                  <div
                    className={style.CaptionXL}
                    style={{
                      background: "#EFF1F6",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 7px",
                    }}
                  >
                    <span style={{ minWidth: "" }}>{index + 1}</span>
                  </div>
                  <span
                    onClick={() =>
                      navigate("/companyPage", { state: { id: item.id } })
                    }
                    className={style.body}
                    style={{ paddingLeft: "8px" }}
                  >
                    <p>{item?.company || ""}</p>
                  </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      height: "20px",
                      minWidth: "30px",
                    }}
                  >
                    {item?.risk
                      ? Math.ceil(item?.risk) >= 75 && (
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                          src={Images.dangerIcon}
                        />
                      )
                      : ""}
                  </div>
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                    style={{ marginRight: "16px" }}
                  >
                    {item?.risk ? Math.ceil(item?.risk) : 0}
                  </span>
                  <Progress
                    className="home_progress_portfolio_sec"
                    percent={item?.risk || 0}
                    showInfo={false}
                  />
                </div>
              </div>
            );
          } else if (viewMoreState) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "50%" }}
                  className={style.leftDataContainer}
                >
                  <div
                    className={style.CaptionXL}
                    style={{
                      background: "#EFF1F6",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 7px",
                    }}
                  >
                    <span style={{ minWidth: "" }}>{index + 1}</span>
                  </div>
                  <span
                    onClick={() =>
                      navigate("/companyPage", { state: { id: item.company } })
                    }
                    className={style.body}
                    style={{ paddingLeft: "8px" }}
                  >
                    <p>{item?.company || ""}</p>
                  </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      height: "20px",
                      minWidth: "30px",
                    }}
                  >
                    {item?.risk
                      ? Math.ceil(item?.risk) >= 75 && (
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                          src={Images.dangerIcon}
                        />
                      )
                      : ""}
                  </div>
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                    style={{ marginRight: "16px" }}
                  >
                    {item?.risk ? Math.ceil(item?.risk) : 0}
                  </span>
                  <Progress
                    className="home_progress_portfolio_sec"
                    percent={item?.risk || 0}
                    showInfo={false}
                  />
                </div>
              </div>
            );
          }
        })}
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {isViewBtnShow && (
          <div
            className={style.viewMoreContainer}
            style={{ height: stateLoader ? 47 : 40 }}
            onClick={() => {
              setRiskScorePerCompOffset((prev) => prev + 10);
              viewMoreHandler("riskScore");
            }}
          >
            <span className={style.buttonM}>
              {stateLoader ? <GlobalLoder /> : "View More"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const CompanyHighSeverity = ({
  data,
  viewMoreState,
  viewMoreHandler,
  isViewBtnShow,
  setNoOfIncidentOffset,
}) => {
  return (
    <div className="portfolio_container_1 custom_container_portfolio_1 riskScoreOverflow">
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          if (!viewMoreState && index < 10) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "50%" }}
                  className={style.leftDataContainer}
                >
                  <div
                    className={style.CaptionXL}
                    style={{
                      background: "#EFF1F6",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 7px",
                    }}
                  >
                    <span style={{ minWidth: "" }}>{index + 1}</span>
                  </div>
                  <span
                    onClick={() =>
                      navigate("/companyPage", { state: { id: item._id } })
                    }
                    className={style.body}
                    style={{ paddingLeft: "8px" }}
                  >
                    <p>{item?.name || 0}</p>
                  </span>
                </div>
                <div
                  style={{
                    width: getProcessBarPercentage(index, item.incidentCount),
                    display: "flex",
                  }}
                >
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                    style={{ marginRight: "16px" }}
                  >
                    {item?.severCount ? Math.ceil(item?.severCount) : 0}
                  </span>

                  <Progress
                    className="progress_portfolio_first"
                    percent={
                      item?.severCount
                        ? Math.ceil(
                          (
                            (item?.severCount / item.incidentCount) *
                            100
                          ).toFixed(3)
                        )
                        : 0
                    }
                    showInfo={false}
                  />

                  <span
                    className={`${style.BodySelected} ${style.customProgressColor2}`}
                    style={{ marginLeft: "16px" }}
                  >
                    {item.incidentCount}
                  </span>
                </div>
              </div>
            );
          } else if (viewMoreState) {
            return (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "50%" }}
                  className={style.leftDataContainer}
                >
                  <div
                    className={style.CaptionXL}
                    style={{
                      background: "#EFF1F6",
                      height: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 7px",
                    }}
                  >
                    <span style={{ minWidth: "" }}>{index + 1}</span>
                  </div>
                  <span
                    onClick={() =>
                      navigate("/companyPage", { state: { id: item.id } })
                    }
                    className={style.body}
                    style={{ paddingLeft: "8px" }}
                  >
                    <p>{item?.name || 0}</p>
                  </span>
                </div>
                <div
                  style={{
                    width:
                      index > 9
                        ? "10%"
                        : getProcessBarPercentage(index, item.incidentCount),
                    display: "flex",
                  }}
                >
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor1}`}
                    style={{ marginRight: "16px" }}
                  >
                    {item?.severCount ? Math.ceil(item?.severCount) : 0}
                  </span>
                  <Progress
                    className="progress_portfolio_first"
                    percent={
                      item?.severCount
                        ? Math.ceil(
                          (
                            (item?.severCount / item.incidentCount) *
                            100
                          ).toFixed(3)
                        )
                        : 0
                    }
                    showInfo={false}
                  />
                  <span
                    className={`${style.BodySelected} ${style.customProgressColor2}`}
                    style={{ marginLeft: "16px" }}
                  >
                    {item.incidentCount}
                  </span>
                </div>
              </div>
            );
          }
        })}

      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {isViewBtnShow && (
          <div
            className={style.viewMoreContainer}
            onClick={() => {
              setNoOfIncidentOffset((prev) => prev + 10);
              viewMoreHandler("highSeverity");
            }}
          >
            <span className={style.buttonM}>View More</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchDashboardPage;
