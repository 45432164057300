import ImageConst from "../../Themes/ImageConstant";

export const ChartBottomTitle = ({ children, title, legend,icon=false }) => {
  return (
    <div>
      {children}
      {legend && (
        <div className="ellips chart-bottom-legend">
          {legend?.map((data) => {
            return (
              <p className="legend">
                <span
                  className="legendCircle"
                  style={{ background: data.color }}
                />
                {data.tittle}
              </p>
            );
          })}
        </div>
      )}
      {title &&
      <div className="chartTitle mb-2">
        <div className="chart-bottom-title">{title}
        {icon &&
        <img src={ImageConst.circleInfo} className="info-icon-company-page" />}
        </div>
        
      </div>
}
    </div>
  );
};
