import React, { memo, useCallback } from "react";
import EChartsReact from "echarts-for-react";
import * as echarts from "echarts/core";
import { createSeverityScoreData } from "../../../../Utils";

var graphic = echarts.graphic;

const color = (color) => {
  return new graphic.LinearGradient(0, 0, 0, 1.5, [
    {
      offset: 0,
      color: color,
    },
    {
      offset: 1,
      color: "rgba(255,255,255,0.8)",
    },
  ]);
};

const SunBrust = ({ data, onClick,dataVal }) => {
  var option;
  var data =createSeverityScoreData(dataVal)
  option = {
    series: {
      nodeClick: false,
      type: "sunburst",
      data: data,
      
      radius: [0, "55%"],
      sort: undefined,
      emphasis: {
        focus: "ancestor",
        labelLine: {
          lineStyle: {
            type: "solid",
            cap: "round",
            join: "round"
          }
        }
      },
      labelLine: {
        length2 : 20,
        minTurnAngle : 10,
      },
      levels: [
        {},
        {
          r0: "10%",
          r: "45%",
          itemStyle: {
            borderWidth: 4,
          },
          label: {
            rotate: 0,
            position: "inside",
          },
        },
        {
          r0: "45%",
          r: "60%",
          label: {
            rotate: 0,
          },
        },

        {
          r0: "60%",
          r: "60%",
          label: {
            distance: -20,
            rotate: 0,
            position: "outside",
            padding: 12,
            silent: false,
            formatter: (x)=>{
              function splitStringFromMiddle(str) {
                const middleIndex = Math.ceil(str.length / 2);
                const spaceIndex = str.indexOf(" ", middleIndex);
              
                if (spaceIndex !== -1) {
                  const firstPart = str.substring(0, spaceIndex);
                  const secondPart = str.substring(spaceIndex + 1);
                  return [firstPart, secondPart];
                } else {
                  return [str];
                }
              }
              return splitStringFromMiddle(x.data.name).join("\n")
            }
          },
          labelLine: {
            show: false,
            lineStyle: {
              type: "solid",
              cap: "round",
              join: "round",
            }
  
          },
          itemStyle: {
            borderWidth: 3,

          },
        },
      ],
    },
  };

  return (
    <EChartsReact
      className=""
      style={{width: "100%", height: "500px"}}
      option={option}

      //   onEvents={{
      //     click: onChartClick,
      //     legendselectchanged: onChartLegendselectchanged,
      //   }}
    />
  );
};

export default memo(SunBrust);
