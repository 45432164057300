/* eslint-disable no-param-reassign */
import {
  useTheme,
  Box,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
import { shadowNameToIndex } from "../../styles/utils";
import {
  prepareIncidentCounts,
  getFilterTagIds,
  toTitleCase,
  getSelectedNodes,
  getBadge,
  MIN_NODE_COUNT_TO_ZOOM_WHEN_ADDING,
} from "../../utils/index";
import SearchIcon from "./searchIcon";
import PlusIcon from "./plusIcon";
import { useGraphObject } from "../context/MyContext";
import { getToken } from "../../../../../Utils";
import {
  setCenterNode,
  setNodeIdToZoom,
} from "../../redux/GraphExploration/graphSlice";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import StopIcon from "@mui/icons-material/Stop";

function LoadingLayout() {
  const { graphObject } = useGraphObject();

  useEffect(() => {
    // Define the timeout
    const timer = setTimeout(() => {
      graphObject?.stopLayout();
    }, 30000); // 30 seconds

    // Cleanup function to clear the timeout if the component is unmounted before the timeout is completed
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return (
    <Box
      style={{
        position: "relative",
        display: "inline-flex", // Use flex to center children
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      }}
    >
      <Box
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip title="Loading Layout">
          <CircularProgress color="secondary" size={25} />
        </Tooltip>
      </Box>
      <Box>
        <IconButton
          onClick={() => {
            graphObject?.stopLayout();
            toast.info("Stopping layout ...", { autoClose: 5000 });
          }}
        >
          <StopIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

function Control({ children, ...props }) {
  const loadingData = useAppSelector((state) => state.graph.loadingData);
  const loadingLayout = useAppSelector((state) => state.graph.loadingLayout);

  return (
    <components.Control {...props}>
      <Box sx={{ marginX: "8px" }}>
        <SearchIcon />
      </Box>{" "}
      {children}
      <Box sx={{ marginX: "8px", display: "flex", alignItems: "center" }}>
        {loadingData && (
          <Tooltip title="Loading Data">
            <CircularProgress size={25} />
          </Tooltip>
        )}
        {loadingLayout && <LoadingLayout />}
      </Box>
    </components.Control>
  );
}
function Option({ children, ...props }) {
  return (
    <components.Option {...props}>
      <Box display="flex" alignItems="center">
        <Box sx={{ paddingRight: "6px", marginBottom: 0.5 }}>
          <PlusIcon />
        </Box>
        <Box sx={{ marginBottom: 1 }}>{children}</Box>
      </Box>
    </components.Option>
  );
}
function IndicatorsContainer() {
  return null;
}
function SearchBar() {
  const { graphObject } = useGraphObject();
  const centerNodeId = useAppSelector((state) => state.graph.centerNode).id;
  const from = useAppSelector((state) => state.timeRange.from);
  const to = useAppSelector((state) => state.timeRange.to);
  const nodes = useAppSelector((state) => state.graph.nodes);
  const baseUrlApi = useAppSelector((state) => state.api.baseUrlApi);
  const dispatch = useAppDispatch();
  const searchEntities = async (searchQuery) => {
    // const headers = {
    //   Authorization: `Bearer ${tigerGraphToken}`,
    // };
    try {
      const params = {
        q: toTitleCase(searchQuery),
        from,
        to,
      };
      if (centerNodeId && nodes.length > 0) {
        params.centerNodeId = centerNodeId;
      }
      const token = getToken();
      const elasticResponse = await axios.get(`${baseUrlApi}/companies`, {
        params,
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      return elasticResponse?.data?.map((item) => ({
        label: item?.name,
        id: item?.id,
        value: item?.id,
        incidentCount: item?.incidentCount,
        tier: item?.tier,
        riskScore: item?.riskScore,
        industry: item?.industry,
        location: item?.location,
        logo: item?.logo,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error:", error);
      return null;
    }
  };
  const updateState = (item) => {
    const currentNodeIds = nodes.map((node) => node.id);
    if (currentNodeIds?.indexOf(item.id) === -1) {
      const nodeAttributes = {
        incidentCount: prepareIncidentCounts(item.incidentCount),
        tier: item.tier ? Math.abs(item.tier) : undefined,
        sort: item.tier ? 4 - Math.abs(item.tier) : 0,
        layer: item.tier ? Math.abs(item.tier) : 4,
        riskScore: item.riskScore,
        industry: item.industry,
        location: item.location,
        label: item.label,
      };
      const isCenterNode = currentNodeIds.length === 0;
      graphObject?.addData("node", {
        id: `${item.id}`,
        data: {
          ...nodeAttributes,
          logo: item.logo,
          isCenterNode,
          filterTagIds: getFilterTagIds(nodeAttributes),
          badge: getBadge(nodeAttributes),
        },
      });
      if (nodes.length > MIN_NODE_COUNT_TO_ZOOM_WHEN_ADDING) {
        dispatch(setNodeIdToZoom(item.id));
      }
      if (isCenterNode) {
        dispatch(
          setCenterNode({
            id: `${item.id}`,
            name: item.label,
          })
        );
      }
      // fitViewGraph(graphObject);
    } else {
      // zoom on the node
      graphObject?.focusItem(`${item.id}`, {
        duration: 1000,
        easing: "ease-in-out",
      });
    }
    // get all selected nodes and unselect them
    getSelectedNodes(currentNodeIds, graphObject).forEach((node) => {
      graphObject?.setItemState(`${node}`, "selected", false);
    });
    graphObject?.setItemState(`${item.id}`, "selected", true);
    graphObject?.frontItem(`${item.id}`);
  };
  const theme = useTheme();
  const showFilterSideBar = useAppSelector(
    (state) => state.filterSideBar.showFilterSideBar
  );

  return (
    <Box
      sx={{
        width: "480px",
        borderRadius: "8px",
        zIndex: 1,
        height: "40px",
      }}
    >
      <AsyncSelect
        loadOptions={(inputValue, callback) => {
          if (showFilterSideBar) {
            callback([]);
            return;
          }
          searchEntities(inputValue).then((res) => {
            callback(res);
          });
        }}
        autoFocus
        components={{ Control, IndicatorsContainer, Option }}
        theme={(thm) => ({
          ...thm,
          colors: {
            ...thm.colors,
            primary: "transparent",
          },
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "transparent",
            border: "1px solid #b3b3b3",
            height: "40px",
            backgroundColor: "rgba(247, 248, 250, 0.85)",
            borderRadius: "8px",
            borderBottomRightRadius: state.menuIsOpen ? "0px" : "8px",
            borderBottomLeftRadius: state.menuIsOpen ? "0px" : "8px",
            boxShadow: state.menuIsOpen
              ? theme.shadows[shadowNameToIndex("SearchBar")]
              : "0px",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            marginTop: 0,
            borderTop: `1px solid ${theme.palette.gray[50]}`,
            boxShadow: "0px 8px 12px 1px rgba(0, 0, 0, 0.10)",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: "36px",
            padding: "8px 16px",
            backgroundColor: state.isFocused
              ? theme.palette.gray.A100
              : undefined,
            cursor: "pointer",
          }),
        }}
        noOptionsMessage={() => {
          if (showFilterSideBar) {
            return "Please first 'Apply' or 'Cancel' your filters.";
          }
          return "No Option";
        }}
        onMenuOpen={() => {
          if (showFilterSideBar) {
            toast.warn("Please first 'Apply' or 'Cancel' your filters.", {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }}
        onChange={(newValue) => updateState(newValue)}
        value={{}}
      />
    </Box>
  );
}
export default SearchBar;
