import { call, put, takeLatest } from "redux-saga/effects";
import appconstant from "../../Constants/appConstansts";
import { onError, onFail } from "../../Utils/UnAuthFailFn";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  getDashboardCompaniesApi
} from "../api";
import {
  getCorpSupplyChainDetailFail,
} from "../Action/corporateProf";
import { dashboardSearchActionSuccess } from "../Action/dashboard";
function* dashboardSearchSaga(action) {
   
  try {
    const data = yield call(getDashboardCompaniesApi, action.data);
    if (data.status === 0) {
      yield put(dashboardSearchActionSuccess(data.result.data));
    }
    else {
      yield put(getCorpSupplyChainDetailFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    yield call(onError);
  }
}


export default function* rootDashboardPageSaga() {
  yield takeLatest(type.DASHBOARD_SEARCH_LOAD, dashboardSearchSaga);
  
}
