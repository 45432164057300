import { Select, Tag } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getArrayId } from "../../../Utils/globalFunction";

const Dropdown = ({
  width,
  placeholder,
  type,
  multiple = false,
  handleChange = () => {},
}) => {
  const [checkedItemArray, setCheckedItemArray] = useState({
    companies: [],
    location: [],
    ESGfactor: [],
  });
  const esgFactorList = useSelector(
    (state) => state?.incidentReducer?.esgFactorList
  );

  const esgCountryList = useSelector(
    (state) => state?.incidentReducer?.esgCountryList
  );

  const companiesList = useSelector(
    (state) => state?.incidentReducer?.companiesList
  );

  const locationCheckBox = () => {
    let arr = [];
    let ids = [];
    let i = 0;
    while (i < esgCountryList?.length) {
      arr.push({
        data: esgCountryList[i]._id,
        value: esgCountryList[i].name,
        label: (
          <div className="check-box">
            <input
              type="checkbox"
              id={esgCountryList?.[i].name}
              // checked={esgCountryList?.includes(esgCountryList[i]?.name)}
              checked={checkedItemArray.location?.includes(
                esgCountryList[i]?.name
              )}
            ></input>
            <p>{esgCountryList[i].name}</p>
          </div>
        ),
      });
      i++;
    }
    return arr;
  };

  const mapCheckBox = () => {
    let arr = [];
    let ids = [];
    let i = 0;
    while (i < companiesList?.length) {
      arr.push({
        data: companiesList[i]._id,
        value: companiesList[i].name,
        label: (
          <div className="check-box">
            <input
              type="checkbox"
              id={companiesList?.[i].name}
              checked={checkedItemArray?.companies?.includes(
                companiesList[i]?.name
              )}
            ></input>
            <p>{companiesList[i].name}</p>
          </div>
        ),
      });
      i++;
    }
    return arr;
  };

  const esgCheckBox = () => {
    let arr = [];
    let ids = [];
    let i = 0;
    while (i < esgFactorList?.length) {
      arr.push({
        data: esgFactorList[i]._id,
        value: esgFactorList[i].name,
        label: (
          <div className="check-box">
            <input
              type="checkbox"
              id={esgFactorList?.[i].name}
              checked={checkedItemArray?.ESGfactor?.includes(
                esgFactorList[i]?.name
              )}
            ></input>
            <p>{esgFactorList[i].name}</p>
          </div>
        ),
      });
      i++;
    }

    return arr;
  };
  const CustomTagRender = (props) => {
    const { label, closable, onClose } = props;
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {/* <span>{label}</span> */}
      </div>
    );
  };

  const customStyle = {
    backgroundColor: "#ffffff",
    color: "black",
    border: "1px solid #1890ff", // You can adjust the border style
  };

  const tagRender = (e) => {
    // return <Tag style={customStyle}>{e.value}</Tag>;
    return null;
  };
  const storeSelectedItem = (itemName, data) => {
    const dataString = JSON.stringify(data);
    localStorage.setItem(itemName, dataString);
  };
  const getSelectedItem = () => {
    let countries = localStorage.getItem("location");
    let companyArray = localStorage.getItem("company");
    let esg_factors = localStorage.getItem("ESG_factor");

    countries = JSON.parse(countries);
    companyArray = JSON.parse(companyArray);
    esg_factors = JSON.parse(esg_factors);

    return {
      countries,
      companyArray,
      esg_factors,
    };
  };
  const handleLocationChange = (e) => {
    storeSelectedItem("location", e);
    const combineData = getSelectedItem();
    handleChange(combineData);
  };

  const onHandleChange = (e) => {
    let result = getArrayId(companiesList, e);
    storeSelectedItem("company", result);
    const combineData = getSelectedItem();
    handleChange(combineData);
  };

  const handleEsgfactorChange = (e) => {
    let result = getArrayId(esgFactorList, e);
    storeSelectedItem("ESG_factor", result);
    const combineData = getSelectedItem();
    handleChange(combineData);
  };

  return type == 1 ? (
    <Select
      placeholder={placeholder}
      mode={multiple && "multiple"}
      style={{
        minWidth: 170,
        maxWidth: { width },
      }}
      onChange={onHandleChange}
      options={companiesList && mapCheckBox()}
      tagRender={tagRender}

      // value={[]}
      // sele
    ></Select>
  ) : type == 2 ? (
    <Select
      placeholder={placeholder}
      mode={multiple && "multiple"}
      style={{
        minWidth: 170,
        maxWidth: { width },
      }}
      onChange={handleEsgfactorChange}
      options={esgFactorList && esgCheckBox()}
      tagRender={tagRender}
    ></Select>
  ) : type == 3 ? (
    <Select
      placeholder={placeholder}
      mode={multiple && "multiple"}
      style={{
        minWidth: 170,
        maxWidth: { width },
        zIndex: 9999999,
      }}
      onChange={handleLocationChange}
      options={esgCountryList && locationCheckBox()}
      tagRender={tagRender}
    ></Select>
  ) : null;
};

export default Dropdown;
