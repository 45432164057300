import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
  supplyChainDataLoader: false,
  supplyChainData: {},
  supplyChainDataSuccess: false,
  riskLineChartData: [],
  riskLineChartDataLoader: false,
  riskLineChartDataSuccess: false,
  riskPieChartData: [],
  riskPieChartDataLoader: false,
  riskPieChartDataSuccess: false,
  incidentBarData: [],
  incidentBarDataLoader: false,
  incidentBarDataSuccess: false,
  riskScoreBarData: [],
  riskScoreBarDataLoader: false,
  riskScoreBarDataSuccess: false,
  incidentAnalysisData: [],
  incidentAnalysisDataLoader: false,
  incidentAnalysisDataSuccess: false,
  esgFactorsData: [],
  esgFactorsLoader: false,
  esgFactorsSuccess: false,
  sdgData: [],
  sdgLoader: false,
  sdgSuccess: false,
  paiIndicatorsData: [],
  paiIndicatorsLoader: false,
  paiIndicatorsSuccess: false,
  companiesList: [],
  companiesListSuccess: false,
  companiesListLoader: false,
  companiesPagination: {},
};

export const corporateProfile = (state = initialState, action) => {
  let { payload, key } = action;
  switch (action.type) {
    case types.GET_CORP_SUPPLY_CHAIN_DETAIL_LOAD:
      return {
        ...state,
        supplyChainDataLoader: true,
      };

    case types.GET_CORP_SUPPLY_CHAIN_DETAIL_SUCCESS:
      return {
        ...state,
        supplyChainData: payload,
        supplyChainDataSuccess: true,
        supplyChainDataLoader: false,
      };

    case types.GET_CORP_SUPPLY_CHAIN_DETAIL_FAIL:
      return {
        ...state,
        supplyChainDataLoader: false,
      };

    case types.GET_CORP_RISK_CHART_DETAIL_LOAD:
      return {
        ...state,
        riskLineChartDataLoader: true,
      };

    case types.GET_CORP_RISK_CHART_DETAIL_SUCCESS:
      return {
        ...state,
        riskLineChartData: payload,
        riskLineChartDataSuccess: true,
        riskLineChartDataLoader: false,
      };

    case types.GET_CORP_RISK_CHART_DETAIL_FAIL:
      return {
        ...state,
        riskLineChartDataLoader: false,
      };

    case types.GET_CORP_RISK_PIE_CHART_LOAD:
      return {
          ...state,
          riskPieChartDataLoader: true,
      };

    case types.GET_CORP_RISK_PIE_CHART_SUCCESS:
      return {
        ...state,
        riskPieChartData: payload !== null ? payload : {},
        riskPieChartDataSuccess: true,
        riskPieChartDataLoader: false,
      };

    case types.GET_CORP_RISK_PIE_CHART_FAIL:
      return {
        ...state,
        riskPieChartDataLoader: false,
      };

    case types.GET_CORP_INICDENTS_BAR_LOAD:
      return {
          ...state,
          incidentBarDataLoader: true,
      };

    case types.GET_CORP_INICDENTS_BAR_SUCCESS:
      return {
        ...state,
        incidentBarData: payload,
        incidentAnalysisDataSuccess: true,
        incidentBarDataLoader: false,
      };

    case types.GET_CORP_INICDENTS_BAR_FAIL:
      return {
        ...state,
        incidentBarDataLoader: false,
      };

    case types.GET_CORP_RISK_SCORE_BAR_LOAD:
      return {
          ...state,
          riskScoreBarDataLoader: true,
      };

    case types.GET_CORP_RISK_SCORE_BAR_SUCCESS:
      return {
        ...state,
        riskScoreBarData: payload,
        riskScoreBarDataSuccess: true,
        riskScoreBarDataLoader: false,
      };

    case types.GET_CORP_RISK_SCORE_BAR_FAIL:
      return {
        ...state,
        riskScoreBarDataLoader: false,
      };
      
    case types.GET_CORP_INICDENTS_ANALYSIS_LOAD:
      return {
          ...state,
          incidentAnalysisDataLoader: true,
      };

    case types.GET_CORP_INICDENTS_ANALYSIS_SUCCESS:
      return {
        ...state,
        incidentAnalysisData: payload,
        incidentAnalysisDataSuccess: true,
        incidentAnalysisDataLoader: false,
      };

    case types.GET_CORP_INICDENTS_ANALYSIS_FAIL:
      return {
        ...state,
        incidentAnalysisDataLoader: false,
      };

    case types.GET_CORP_ESG_FACTORES_LOAD:
      return {
          ...state,
          esgFactorsLoader: true,
      };

    case types.GET_CORP_ESG_FACTORES_SUCCESS:
      return {
        ...state,
        esgFactorsData: payload,
        esgFactorsSuccess: true,
        esgFactorsLoader: false,
      };

    case types.GET_CORP_ESG_FACTORES_FAIL:
      return {
        ...state,
        esgFactorsLoader: false,
      };

    case types.GET_CORP_SDG_LOAD:
      return {
          ...state,
          sdgLoader: true,
      };

    case types.GET_CORP_SDG_SUCCESS:
      return {
        ...state,
        sdgData: payload,
        sdgSuccess: true,
        sdgLoader: false,
      };

    case types.GET_CORP_SDG_FAIL:
      return {
        ...state,
        sdgLoader: false,
      };

    case types.GET_CORP_PAI_INDICATORS_LOAD:
      return {
        ...state,
        paiIndicatorsLoader: true,
      };

    case types.GET_CORP_PAI_INDICATORS_SUCCESS:
      return {
        ...state,
        paiIndicatorsData: payload,
        paiIndicatorsSuccess: true,
        paiIndicatorsLoader: false,
      };

    case types.GET_CORP_PAI_INDICATORS_FAIL:
      return {
        ...state,
        paiIndicatorsLoader: false,
      };

    case types.GET_CORP_COMPANIES_LIST_LOAD:
      return {
        ...state,
        companiesListLoader: true,
      };

    case types.GET_CORP_COMPANIES_LIST_SUCCESS:
      return {
        ...state,
        companiesList: payload?.data,
        companiesPagination: payload?.pagination,
        companiesListSuccess: true,
        companiesListLoader: false,
      };

    case types.GET_CORP_COMPANIES_LIST_FAIL:
      return {
        ...state,
        companiesListLoader: false,
      };

    case types.UPDATE_CORP_PROF_STATE:
      state[key] = payload
      return { ...state }

    default:
      return { ...state };
  }
};
