import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import CustomTooltip from '../GraphToolTip/CustomTooltip';
import SupplyChainActIcon from './SupplyChainActViolationsIcon';
import ShowMoreIcon from './ShowMoreIcon';
import IncidentItem from './incidentItem';

function SupplyChainActViolations({ data }) {
  const supplyChainActViolationsKeys = Object.keys(
    data?.supplyChainActViolations,
  );
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box>
      <Stack direction="row" gap={1}>
        <SupplyChainActIcon />
        <Typography variant="subtitle1" sx={{ marginBottom: '16px' }}>
          Supply Chain Act Violations
        </Typography>
      </Stack>
      <Stack gap={1}>
        {supplyChainActViolationsKeys?.map((item) => (
          <Accordion
            key={item}
            sx={{
              boxShadow: 'none',
              '&.MuiAccordion-root::before': {
                height: 0,
                // Add more styles as needed
              },
            }}
            expanded={expanded === item}
            onChange={handleChange(item)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: theme.palette.screenBackground.main,
                paddingY: '4px',
                borderRadius: '6px',
                '&.Mui-expanded': {
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '0px',
                },
              }}
            >
              <Stack
                direction="row"
                gap={0}
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center" gap="4px" flex={1}>
                  <Typography variant="bodySelected">
                    <ShowMoreText
                      lines={1}
                      onClick={() => {}}
                      expanded={false}
                      more={null}
                      expandByClick={false}
                      truncatedEndingComponent={null}
                      width={320}
                    >
                      {item}
                    </ShowMoreText>
                  </Typography>
                  <Typography>...</Typography>
                  <Box>
                    <Icon sx={{ width: 'unset', height: 'unset' }}>
                      <CustomTooltip title={item}>
                        <Box>
                          <ShowMoreIcon />
                        </Box>
                      </CustomTooltip>
                    </Icon>
                  </Box>
                </Box>
                
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: theme.palette.screenBackground.main,
                borderBottomLeftRadius: '6px',
                borderBottomRightRadius: '6px',
              }}
            >
              <Stack gap="16px">
                {data?.supplyChainActViolations[item]?.map(
                  (supplyChainActViolationsItem, index) => (
                    <IncidentItem
                      key={supplyChainActViolationsItem?.title}
                      title={supplyChainActViolationsItem.title}
                      index={index + 1}
                      severity={supplyChainActViolationsItem?.severity}
                      date={supplyChainActViolationsItem?.date}
                      id={supplyChainActViolationsItem?.id}
                    />
                  ),
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Box>
  );
}
export default SupplyChainActViolations;
