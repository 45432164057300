let topHeaderAPIObject = {
  start: "",
  end: "",
  companies: [],
  continent_codes: [],
  countries: [],
  esg_factors: [],
  esg_SDG: [],
  esg_PAI: [],
  industries: [],
  search: "",
  locations: [],
  esg_categories: [],
};


const dummySupplyChainCompanyData = [
  {
    name: "Amazon",
    risk: 85,
    risk_change: 10,
    relation: "Customer",
    incident_count: 10
  },
  {
    name: "Google",
    risk: 85,
    risk_change: 10,
    relation: "Customer",
    incident_count: 10
  },
  {
    name: "Tesla",
    risk: 45,
    risk_change: 10,
    relation: "Customer",
    incident_count: 10
  },
  {
    name: "SpaceX",
    risk: 45,
    risk_change: 10,
    relation: "Customer",
    incident_count: 10
  },
  {
    name: "Microsoft",
    risk: 15,
    risk_change: -10,
    relation: "Customer",
    incident_count: 10
  },
]

export { topHeaderAPIObject, dummySupplyChainCompanyData };
