import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChartCard } from "../../../Layouts/ChartCard";
import { dispatch } from "../../../Utils/dispatchNavigate";
import {
  IncidentComparisonAction,
  RemoverIncidentComparisonAction,
} from "../../../Redux/Action/comapanyPageAction";
import ImageConst from "../../../Themes/ImageConstant";
import { AutoComplete, Modal, Row } from "antd";
import { getCountryDataforSupplyChain } from "../../../Redux/Action/supplyChainAction";
import { ChartBottomTitle } from "../../../Componets/ChartBottomTitle";
import style from "../style.module.css";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import * as echarts from 'echarts';
import moment from "moment";


var graphic = echarts.graphic;



const optionsValue = {
  chart: {
      type: 'area',
      zoom: false,
      toolbar: {
          show: false, // Hide the chart controller
      },
     
  },

  yaxis: {
      show: true,
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (e) => moment(e).format("MMM"),
    }
    // categories: ["Shubham", "Shivam", "Sourav"]
  },
  dataLabels: {
      enabled: false,
  },
};

const StockChartComponent = ({
  id,
  start,
  end,
  companyName,
  dateChange,
  data
}) => {
  const companiesList = useSelector(
    (state) => state?.incidentReducer?.companiesList
  );
  const [options, setOptions] = useState([]);
  const [countryName, setCountryName] = useState(null);
  const [comparisonModal, setComparisonModal] = useState(false);

  useEffect(() => {
    // dispatch(getCountryDataforSupplyChain());
    dispatch(IncidentComparisonAction({ id, start, end, type: "multiple" }));
  }, [dateChange]);

  const { countryList } = useSelector((state) => state?.supplyChainReducer);
  const { IncidentComparison } = useSelector(
    (state) => state?.companyPageReducer
  );

  useEffect(() => {
    setOptions(countryList);
  }, [countryList]);

  const handleSearch = (value) => {
    let res = [];
    if (!value) {
      res = [];
    } else {
      res = countryList.filter((x) =>
        x?.label?.toUpperCase()?.includes(value?.toUpperCase().trim())
      );
    }
    setOptions(res);
  };

  const newData = useCallback(() => {
    return data?.map((e) => {
      let mixed = [];
      if (e.esg_factors) mixed = mixed.concat(e.esg_factors);
      if (e.esg_pais) mixed = mixed.concat(e.esg_pais);
      if (e.esg_sdgs) mixed = mixed.concat(e.esg_sdgs);
      mixed = mixed?.map((e) => {
        const graphData = { x: "", y: 0 };
        graphData.x = e.name;
        graphData.y = e.incidents;
        return graphData;
      });
      e.mixed = mixed;
      return e;
    });
  }, [data]);

  const Series = (title) => [
    { data: newData()?.find((e) => e?.name === title)?.mixed || [] },
  ];

    const Chart = ({ title, color, className }) => {
        return (
        <div
            className={`${style[className]} ${style.innerContainer}`}
            style={{ border: `1px solid${color} ` }}
        >
            <ReactApexChart
                options={optionsValue}
                series={[{ data }]}
                type="area"
                height={500}
                width={"100%"}
            />
        </div>
        );
    };    

  const renderItem = (title, item) => ({
    value: item,
    label: (
      <div
        style={{
          pointerEvents: id === item ? "none" : "",
          cursor: id === item ? "no-drop" : "pointer",
        }}
      >
        {title}
      </div>
    ),
  });
  // country option on first render
  const CreateOptions = useMemo(() => {
    let arr = [];
    for (let i = 0; i < options?.length - 1; i++) {
      if (options[i].id === id) continue;
      arr.push(renderItem(options[i].label, options[i].id));
    }
    return arr;
  }, [options, id]);
  return (
    <section className="left-text">
      <ChartCard
      showTimeBarOnTop={false}
        title={"Portfolio Value & Incidents by Severity"}
      >
          
        <div className="pie-portfolio-chart-container">
        <Chart />
        </div>
      </ChartCard>
      <Modal
        open={comparisonModal}
        closable
        title={"Advanced Search"}
        width={1000}
        onCancel={() => setComparisonModal(false)}
        footer={() => { }}
        className="comparisonModal"
      >
        <div style={{ overflow: "hidden" }}>
          <AutoComplete
            placeholder={"Search company"}
            style={{
              width: "100%",
              margin: "18px 0",
              border: "none",
              boxShadow: "none",
            }}
            suffixIcon={<img src={ImageConst.search} alt="" />}
            className="autocomplete-input"
            onSearch={handleSearch}
            value={countryName}
            options={CreateOptions}
            onSelect={(e, i) => {
              i.value !== id &&
                dispatch(
                  IncidentComparisonAction({
                    id: i.value,
                    start,
                    end,
                    type: "single",
                  })
                );
              setCountryName(i.label);
              setComparisonModal(false);
            }}
            onChange={(e, i) => {
              setCountryName(e);
            }}
          />
        </div>
        {/* <form className="example supply-chan-search"> */}

        {/* </form> */}
      </Modal>
    </section>
  );
};

export default StockChartComponent;
