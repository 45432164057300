import moment from "moment";
import ImageConst from "../../Themes/ImageConstant";
import {
  CompanyRelations,
  checkRiskColor,
  checkSeverityColor,
  getRiskVal,
  validCheckColor,
} from "../../Utils";
import { navigate } from "../../Utils/dispatchNavigate";

export const columns1 = [
  {
    title: "Incident Title",
    dataIndex: "title",
    key: "title",
    width: "252px",
    render: (text, _, i) => {
      return (
        <a style={{ display: "flex" }}>
          <span className="col-index">{`${i + 1} `}</span>
          <a
            onClick={() => window.open(`/incidentdetails?${_.id}`, "_blank")}
            className="titleText"
            style={{
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxLines: 1,
              textOverflow: "ellipsis",
            }}
          >{` ${text}`}</a>
        </a>
      );
    },
    className: "companyPage-col1",
  },
  {
    title: "Severity",
    dataIndex: "severity_score",
    className: `company-supplyChain`,
    ellipsis: true,
    width: "12%",
    render: (_, item) => (
      <div
        style={{
          backgroundColor: validCheckColor[item?.severity_score?.level],
          display: "flex",
          justifyContent: "center",
          marginLeft: 10,
          width: "36px",
          height: "24px",
          alignItems: "center",
        }}
      >
        <span>
          <p style={{ color: "white", width: "fit-content", fontWeight: 500 }}>
            {item?.severity_score?.value?.toFixed(0)}
          </p>
        </span>
      </div>
      // <div
      //   style={{
      //     backgroundColor: checkSeverityColor[item.severity],
      //     display: "flex",
      //     justifyContent: "center",
      //     marginLeft: 10,
      //     width: "40px",
      //     height: "40px",
      //     alignItems: "center",
      //   }}
      // >
      //   <span>
      //     <p style={{ width: "fit-content", fontWeight: 500 }}>
      //     {item?.severity_score?.category}
      //     </p>
      //   </span>
      // </div>
    ),
  },
  {
    title: "Involved Companies",
    dataIndex: "affected_companies",
    key: "affected_companies",
    align: "left",
    width: "20%",
    ellipsis: true,
    sort: false,
    render: (affected_companies) => (
      <div
        style={{
          maxWidth: "100%",
          display: "block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxLines: 1,
          textOverflow: "ellipsis",
        }}
      >
        {affected_companies[0]?.name}
      </div>
    ),
  },
  {
    title: "ESG Factors",
    key: "esg_factors",
    dataIndex: "esg_factors",
    align: "left",
    width: "16%",
    render: (esg_factors) => (
      <div
        style={{
          maxWidth: "100%",
          display: "block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <a
          className="titleText"
          style={{
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxLines: 1,
            textOverflow: "ellipsis",
          }}
        >{` ${esg_factors[0]?.name || "N/A"}`}</a>
      </div>
    ),
  },
  {
    title: "Date",
    key: "discovery_time",
    dataIndex: "discovery_time",
    align: "right",
    width: "12%",
    className: "inidentTblCol-5",
    render: (discovery_time) => <span>{moment(discovery_time).fromNow()}</span>,
  },
];

export const columns = [
  {
    title: "Company",
    dataIndex: "name",
    key: "name",
    render: (text, _, i) => {
      return (
        <a>
          <a className="col-index">{`${i + 1} `}</a>
          {` ${text}`}
        </a>
      );
    },
    className: "companyPage-col1",
  },
  {
    title: "Avg. Importance Score",
    dataIndex: "score",
    key: "score",
    align: "left",
    render: (val) => {
      return (
        <div
          className={`list-impo${val < 3 ? " gray" : val < 7 ? " orange" : ""}`}
        >
          {val}
        </div>
      );
    },
    className: "companyPage-col2",
  },
  {
    title: "Relation",
    dataIndex: "relation",
    key: "relation",
    align: "left",
  },
  {
    title: "Incident Numbers",
    key: "incidentsCount",
    dataIndex: "incidentsCount",
    align: "left",
  },
  {
    title: "last incident",
    key: "lastIncident",
    dataIndex: "lastIncident",
    align: "left",
    className: "inidentTblCol-5",
    render: (lastIncident) => {
      return <span>{moment(lastIncident).fromNow()}</span>;
    },
  },
];

export const supplyChainColumns = [
  {
    title: "Company",
    dataIndex: "name",
    key: "name",

    render: (text, _, i) => {
      return (
        <a style={{ display: "flex" }}>
          <span className="col-index">{`${i + 1} `}</span>
          <a
            className="titleText theme-dark-text"
            style={{
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxLines: 1,
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              navigate("/companyPage", { state: { id: _.id } });
            }}
          >{`${text}`}</a>
        </a>
      );
    },
    className: "companyPage-col1",
  },
  {
    title: "ESG Risk",
    dataIndex: "risk",
    className: `company-supplyChain`,

    render: (risk, _, item) => (
      <div
        style={{
          backgroundColor:
          checkRiskColor[getRiskVal(risk)],
          display: "flex",
          justifyContent: "center",
          marginLeft: 10,
          width: "36px",
          height: "24px",
          alignItems: "center",
        }}
      >
        <span>
          <p style={{ color: "white", width: "fit-content", fontWeight: 500 }}>
            {Math.round(_?.risk)}
          </p>
        </span>
      </div>
    ),
  },
  {
    title: "Risk Change",
    dataIndex: "risk_change",
    className: `company-supplyChain`,

    render: (text, _, item) => (
      <div
        style={{
          backgroundColor:
            text > 0 ? "rgba(252, 237, 237, 1)" : "rgba(232, 245, 236, 1)",
          display: "flex",
          justifyContent: "center",
          marginLeft: 10,
          width: "60px",
          alignItems: "center",
        }}
      >
        <span>
          <p
            style={{
              color: text > 0 ? "#D00000" : "rgba(26, 156, 62, 1)",
              width: 48,
              fontWeight: 500,
              height: 24,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={text < 0 ? ImageConst.low : ImageConst.high}
              style={{ height: 16, width: 16 }}
              alt="Risk"
            />
            {Math.abs(text.toFixed(2))}%
          </p>
        </span>
      </div>
    ),
  },
  {
    title: "Relation",
    dataIndex: "relation",
    className: `company-supplyChain`,

    render: (text, _, item) => (
      <span className="theme-dark-text">
        {text}
              </span>
    ),
  },
  {
    title: "Incidents",
    dataIndex: "incidentsCount",
    className: `company-supplyChain`,
    width: "9%",
    render: (text, _, item) => (
      <span className="supply-chain-incident-column">
        <a className="theme-link-style">{text}</a>
      </span>
    ),
  },
];
