import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { Switch } from "antd";

import { dispatch, navigate } from "../../Utils/dispatchNavigate";
import {
  editWishListAction,
  getWishListDetailAction,
  showResult,
  updateDataForWatchlist,
  uploadCompanyFileLoad,
} from "../../Redux/Action/watchListActions";
import { useSelector } from "react-redux";
import ImageConst from "../../Themes/ImageConstant";
import SearchDocEmptyDpc from "../../Componets/searchDocEmptyCom";
import { WatchListAdvanceSearch } from "../../Componets/WatchlistAdvanceSearch";
import { getCountryListAction } from "../../Redux/Action/comapanyPageAction";
import {
  getIndustryAction,
  updateIndustriesForWatchlist,
} from "../../Redux/Action/incidents";
import { updateAdvncCompanyDataSuccess } from "../../Redux/Action/advanceSearch";
import GlobalSpinner from "../../Utils/GlobalSpinner";
import FileUploadPopUp from "../../Componets/fileUploadPopup";

const WatchList = () => {
  const [open, setOpen] = useState(false);
  const [addModalTopItems, setAddModalTopItems] = useState([]);

  const [show, setShow] = useState(true);
  const [searchTypeTab, setSearchTypeTab] = useState();

  const watchListData = useSelector(
    (state) => state?.watchListReducer?.watchlist
  );

  const { selectedWatchList, uploadLoading } = useSelector(
    (state) => state.watchListReducer
  );
  const testData = useSelector((state) => state?.watchListReducer);
  const watchListId = useSelector(
    (state) => state?.watchListReducer?.watchListId
  );
  const watchListdetails = useSelector(
    (state) => state?.watchListReducer?.watchListDetails
  );
  const [currentActiveButton, setCurrentActiveButton] = useState(null);
  const [notificationChecked, setNotificationChecked] = useState(false);

  const industryList = useSelector(
    (state) => state?.incidentReducer?.industryList
  );

  useEffect(() => {
    dispatch(getCountryListAction());
    dispatch(getIndustryAction());
  }, []);

  const emailButtonClickHandle = (searchTypeTab) => {
    setCurrentActiveButton(searchTypeTab);
    dispatch(
      editWishListAction({
        emailNotification: searchTypeTab,
        wishlist: watchListId.length > 0 ? watchListId : watchListData[0]?._id,
      })
    );
  };

  const onToggleNotification = (toggle) => {
    setNotificationChecked(toggle);
    setCurrentActiveButton(toggle ? "Instantly" : null);
    dispatch(
      editWishListAction({
        emailNotification: toggle ? "Instantly" : null,
        wishlist: watchListId.length > 0 ? watchListId : watchListData[0]?._id,
      })
    );
  };

  const countryList = useSelector(
    (state) => state?.companyPageReducer?.allData
  );
  const notes = useSelector(
    (state) => state?.watchListReducer?.watchListDetails?.description
  );
  const [newNotes, setNewNotes] = useState(notes);

  useEffect(() => {
    setNewNotes(notes);
  }, [notes]);

  useEffect(() => {
    if (watchListId) dispatch(getWishListDetailAction(watchListId));
  }, []);

  useEffect(() => {
    if (testData.isUpadted == true) {
      if (watchListdetails?.locations?.length > 0) {
        dispatch(updateDataForWatchlist(watchListdetails));
      }
      if (watchListdetails?.industries?.length > 0) {
        dispatch(updateIndustriesForWatchlist(watchListdetails));
      }
      setShow(testData.isUpadted);
    }
  }, [
    testData.isUpadted,
    watchListdetails?.locations,
    watchListdetails?.industries,
  ]);

  useEffect(() => {
    setCurrentActiveButton(watchListdetails?.emailNotification);

    if (watchListdetails?.emailNotification == null) {
      setNotificationChecked(false);
    } else {
      setNotificationChecked(true);
    }
  }, [watchListdetails?.emailNotification]);

  useEffect(() => {

    if (testData.isUploaded) {
      // dispatch(editWishListAction(networkData));
      navigate("/uploadWatchlist", {
        state: {
          id: watchListId,
          detail: watchListdetails,
          data: testData.uploadeData,
        },
      });
      testData.isUploaded = false;
    }
  }, [testData.isUploaded]);

  const networkData = {
    wishlist: watchListId === "" ? watchListData[0]?._id : watchListId,
    description: newNotes,
    name: null,
    locations: null,
    companies: null,
    esg_factors: null,
    industries: null,
  };
  const dataArrayObject = {
    companies: watchListdetails?.companies,
    locations: watchListdetails?.locations,
    esg_factors: watchListdetails?.esg_factors,
    industries: watchListdetails?.industries,
    principles: watchListdetails?.principles,
  };

  const removeItem = (id, arrayType) => {
    const idArray = [];
    let cloneArray;
    if (arrayType === "locations") {
      cloneArray = dataArrayObject[arrayType]?.filter((val) => {
        if (val._id !== id) {
          idArray.push(val._id);
          return true;
        }
        return false;
      });
    } else {
      cloneArray = dataArrayObject[arrayType]?.filter((val) => {
        if (val.id !== id) {
          idArray.push(val.id);
          return true;
        }
        return false;
      });
    }
    const apiPayload = { ...networkData };
    apiPayload[arrayType] = idArray;
    dispatch(editWishListAction(apiPayload));
  };

  const getselectedData = (searchTypeTab) => {
    if (searchTypeTab == "Company") {
      return watchListdetails?.companies || [];
    }
    if (searchTypeTab == "Location") {
      return watchListdetails.locations;
    }
    if (searchTypeTab == "Categories") {
      const Categories = {
        esg_factor: watchListdetails.esg_factors,
        principles: watchListdetails.principles,
      };
      return Categories;
    }
    if (searchTypeTab == "Industry") {
      return watchListdetails.industries;
    }
  };

  const InputChange = (e) => {
    let formData = new FormData();
    formData.append("file", e);
    formData.append("wishlistId", networkData.wishlist);
    dispatch(uploadCompanyFileLoad(formData));
    document.getElementById("fileupload").value = "";
  };

  const correctionCompanyAddedItems = (item) => ({
    company_id: item?.id,
    name: item?.name,
    isRemovedItem: false,
  });

  const selectedItemHandler = (selectedItemArray, callRemove) => {
    if (callRemove) {
      setAddModalTopItems([]);
    } else {
      const gotItem = [];
      let cloneAddModalTopItems = [...addModalTopItems];
      for (let index = 0; index < selectedItemArray.length; index++) {
        const element = selectedItemArray[index];
        let isExist = false;
        for (let j = 0; j < cloneAddModalTopItems.length; j++) {
          if (cloneAddModalTopItems[j].company_id == element.company_id) {
            isExist = true;
            cloneAddModalTopItems = cloneAddModalTopItems.filter(
              (_, index) => index !== j
            );
            break;
          }
        }
        if (isExist == false) {
          gotItem.push(element);
        }
      }
      setAddModalTopItems([...cloneAddModalTopItems, ...gotItem]);
    }
  };

  return (
    <div className="side-padding sodebar-left-padding">
      <div className="margin-both-side watch-page-top-space">
        <div className="">
          <div className="breadcrmbs mb-4 mx-2">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  marginRight: "10px",
                  fontWeight: "600",
                  fontSize: "16px",
                  letterSpacing: "1px",
                  color: "#9A9CA2",
                }}
              >
                Watchlists
              </div>
              {selectedWatchList && (
                <div style={{ marginRight: "10px", color: "#9A9CA2" }}>/</div>
              )}
              <div
                style={{
                  marginRight: "10px",
                  fontWeight: "600",
                  color: "#263049",
                  letterSpacing: "1px",
                  fontSize: "16px",
                }}
              >
                {selectedWatchList}
              </div>
            </div>
          </div>
          <div className="row  mb-4">
            {watchListdetails?.companies?.length > 0 ||
              watchListdetails?.locations?.length > 0 ||
              watchListdetails?.esg_factors?.length > 0 ||
              watchListdetails?.esg_pais?.length > 0 ||
              watchListdetails?.esg_sdgs?.length > 0 ||
              watchListdetails?.industries?.length > 0 ? (
              <div
                className="col-lg-3 empty-watch-list-ps "
                style={{ width: "28.3%" }}
              >
                {testData.isUpadted === true && show === true ? (
                  <div className="empty-watchlist-page mt-lg-5 mt-sm-2">
                    <div className="new-up-date">
                      <img src={Images.update} />
                    </div>
                    <div className="up-date-text">
                      <img
                        onClick={() => {
                          setShow(!show);
                        }}
                        src={Images.xmark}
                      />
                      <h5>New updates!</h5>
                      <p>
                        There are new updates on your saved search.
                        <a
                          onClick={() => {
                            dispatch(showResult(watchListId));
                            navigate("/searchdashboardpage", {
                              state: {
                                mergedData: {
                                  companies: watchListdetails?.companies?.map(
                                    (x) => x?.id
                                  ),
                                  locations: watchListdetails?.locations?.map(
                                    (x) => x?.name
                                  ),
                                  industries: watchListdetails?.industries?.map(
                                    (x) => x?.id
                                  ),
                                  esg_factors:
                                    watchListdetails?.esg_factors?.map(
                                      (x) => x?.id
                                    ),
                                  ungc_breaches:
                                    watchListdetails?.principles?.map((x) => x),
                                },
                                selectedTile: {
                                  companies: watchListdetails?.companies,
                                  locations: watchListdetails?.locations,
                                  esg_factors: watchListdetails?.esg_factors,
                                  industries: watchListdetails?.industries,
                                  ungc_breaches: watchListdetails?.principles,
                                },
                              },
                            });
                            window.location.reload();
                          }}
                        >
                          View Incidents
                        </a>
                      </p>
                    </div>
                  </div>
                ) : null}

                <div className="email-up mt-4">
                  <div className="toggle-emil">
                    <Switch
                      onChange={(e) => onToggleNotification(e)}
                      checked={notificationChecked}
                    />
                  </div>
                  <div className="email-message-ex">
                    <p>Email me when there are new updates on my watchlist</p>
                  </div>
                </div>

                <div
                  className="daily-sec watch-btn-list "
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <button
                    className={
                      currentActiveButton == "Instantly"
                        ? "selected-button"
                        : ""
                    }
                    onClick={() => emailButtonClickHandle("Instantly")}
                  >
                    Instantly
                  </button>
                  <button
                    className={
                      currentActiveButton == "Daily" ? "selected-button" : ""
                    }
                    onClick={() => emailButtonClickHandle("Daily")}
                  >
                    Daily
                  </button>
                  <button
                    className={
                      currentActiveButton == "Weekly" ? "selected-button" : ""
                    }
                    onClick={() => emailButtonClickHandle("Weekly")}
                  >
                    Weekly
                  </button>
                  <button
                    className={
                      currentActiveButton == "Monthly" ? "selected-button" : ""
                    }
                    onClick={() => emailButtonClickHandle("Monthly")}
                  >
                    Monthly
                  </button>
                </div>

                <div className="note-pad mt-4">
                  <h5>My Notes</h5>
                  <textarea
                    rows="10"
                    maxLength={150}
                    placeholder="Please enter notes"
                    value={newNotes}
                    onChange={(e) => setNewNotes(e.target.value)}
                    onBlur={() => {
                      dispatch(editWishListAction(networkData));
                    }}
                  ></textarea>
                  <span> We auto save your changes here. Do not worry!</span>
                </div>
              </div>
            ) : (
              <SearchDocEmptyDpc
                title={"Your watchlist list is empty!"}
                msg={`You can add  companies, locations, ESG categories or industries to your watchlist to monitor them consistently as they get updated`}
                key={"watchlist"}
              />
            )}
            <div
              className="col-lg-9 witch-right-side-ps"
              style={{ width: "71%" }}
            >
              {watchListData.length > 0 && (
                <div className="add-to-withc">
                  <div className="w-my-list">
                    <h5>Watchlist Criteria</h5>
                  </div>
                  <div className="w-show-res">
                    <a
                      style={{ color: "#0281B6" }}
                      onClick={() => {
                        dispatch(updateAdvncCompanyDataSuccess([]));
                        watchListData.length > 0 &&
                          dispatch(showResult(watchListId));
                        navigate("/searchdashboardpage", {
                          replace: true,
                          state: {
                            mergedData: {
                              companies: watchListdetails?.companies?.map(
                                (x) => x.id
                              ),
                              locations: watchListdetails?.locations?.map(
                                (x) => x.name
                              ),
                              industries: watchListdetails?.industries?.map(
                                (x) => x.id
                              ),
                              esg_factors: watchListdetails?.esg_factors?.map(
                                (x) => x.id
                              ),
                              ungc_breaches: watchListdetails?.principles?.map(
                                (x) => x
                              ),
                            },
                            selectedTile: {
                              companies: watchListdetails?.companies,
                              locations: watchListdetails?.locations,
                              esg_factors: watchListdetails?.esg_factors,
                              industries: watchListdetails?.industries,
                              ungc_breaches: watchListdetails?.principles,
                            },
                          },
                        });
                        // window.location.reload();
                      }}
                    >
                      View Watchlist Incidents
                    </a>
                  </div>
                </div>
              )}

              <div>
                <div className="comp-add-w mt-3">
                  <div
                    className={
                      watchListdetails?.companies?.length !== 0 &&
                      "comp-name-list-w"
                    }
                    style={
                      watchListdetails?.companies?.length == 0
                        ? {
                          borderRadius: "6px 6px 0px 0px",
                          padding: "10px",
                          backgroundColor: "#f7f8fa",
                        }
                        : null
                    }
                  >
                    <p className="name-of-the-comp">
                      <div
                        className={
                          watchListdetails?.companies?.length !== 0 &&
                          "blue-name-of-the-comp"
                        }
                      >
                        <span className="add-to-my-list-item ">
                          <img src={Images.searchHotel} />
                        </span>
                        Company
                      </div>
                    </p>
                  </div>
                  <div
                    className="list-of-com-w"
                    style={
                      watchListdetails?.companies?.length === 0
                        ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                        : {} // Empty object for no additional styling when companies exist
                    }
                  >
                    <ul>
                      {watchListData?.length > 0 &&
                        watchListdetails?.companies?.length > 0 ? (
                        watchListdetails?.companies?.map((item, index) => (
                          <ListItemComponents
                            key={index}
                            onClick={() => removeItem(item.id, "companies")}
                            title={item?.name}
                          />
                        ))
                      ) : (
                        <span className="no-companies-add-ps">
                          No companies added
                        </span>
                      )}
                    </ul>
                  </div>

                  {watchListData?.length > 0 ? (
                    <div className="add-company-two-tabs">
                      <div
                        className="w-add-com-link mt-2"
                        onClick={() => {
                          if (watchListdetails?.companies?.length >= 0) {
                            const makeSelectedData =
                              watchListdetails?.companies?.map((val) =>
                                correctionCompanyAddedItems(val)
                              );
                            selectedItemHandler(makeSelectedData);
                          }
                          setSearchTypeTab("Company");
                          setOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "50%"

                        }}
                      >
                        <div className="add-company-two-tabs">
                          <p>
                            <span>
                              <img src={Images.plus} />
                            </span>
                            Add Company
                          </p>
                        </div>
                      </div>
                      <div style={{ width: "50%", cursor: "pointer", }}>
                        <FileUploadPopUp
                          screenType={"watchList"}
                          uploadFileHadler={(data) => InputChange(data)}
                          children={
                            <div style={{}}>
                              <div style={{ cursor: "pointer", width: "100%  " }}>
                                <label
                                  className="w-add-com-link mt-2"
                                  style={{ cursor: "pointer", }}
                                >
                                  <div className="">
                                    <p>
                                      {uploadLoading ? (
                                        <GlobalSpinner colorx={"#4295CE"} />
                                      ) : (
                                        <span>
                                          <img src={Images.uplode} />
                                        </span>
                                      )}
                                      Import File
                                    </p>
                                  </div>
                                </label>
                              </div>
                            </div>

                          }
                        />
                      </div>
                    </div>
                  ) : null}

                  {watchListData?.length === 0 ? (
                    <div
                      className="w-add-com-link mt-2"
                      onClick={() => {
                        watchListData?.length > 0 && setOpen(true);
                        setSearchTypeTab("Company");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="">
                        <p>
                          <span>
                            <img src={Images.plus} />
                          </span>
                          Add Company
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* {watchListData?.length > 0 ? (
                  <div className="download-samp-file-ps">
                    <p>
                      <a
                        href={companyCSV}
                        download="company.csv"
                        rel="noreferrer"
                        className="downloadSample"
                      >
                        <span>
                          <img src={Images.downloadFile} />
                        </span>
                        Download Sample File
                      </a>
                    </p>
                  </div>
                ) : null} */}
              </div>
              <div>
                <div className="comp-add-w mt-3">
                  <div
                    className={
                      watchListdetails?.locations?.length !== 0 &&
                      "comp-name-list-w"
                    }
                    style={
                      watchListdetails?.locations?.length == 0
                        ? {
                          borderRadius: "6px 6px 0px 0px",
                          padding: "10px",
                          backgroundColor: "#f7f8fa",
                        }
                        : null
                    }
                    onClick={() => { }}
                  >
                    <p className="name-of-the-comp">
                      <div
                        className={
                          watchListdetails?.locations?.length !== 0 &&
                          "blue-name-of-the-comp"
                        }
                      >
                        <span className="add-to-my-list-item ">
                          <img src={Images.locationIcon} />
                        </span>
                        Incident Location
                      </div>
                    </p>
                  </div>
                  <div
                    className="list-of-com-w"
                    style={
                      watchListdetails?.locations?.length === 0
                        ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                        : {} // Empty object for no additional styling when companies exist
                    }
                  >
                    <ul>
                      {watchListData?.length > 0 &&
                        watchListdetails?.locations?.length > 0 ? (
                        watchListdetails?.locations?.map((item, index) => (
                          <ListItemComponents
                            onClick={() => removeItem(item._id, "locations")}
                            title={item?.name}
                          />
                        ))
                      ) : (
                        <span
                          className="no-companies-add-ps"
                          style={{ color: "#676A73" }}
                        >
                          No locations added
                        </span>
                      )}
                    </ul>
                  </div>

                  <div
                    className="w-add-com-link mt-2"
                    onClick={() => {
                      watchListData?.length > 0 &&
                        setTimeout(() => {
                          if (watchListdetails?.locations?.length >= 0) {
                            const makeSelectedData =
                              watchListdetails?.locations?.map((val) => ({
                                id: val._id,
                                name: val.name,
                              }));
                            selectedItemHandler(makeSelectedData);
                          }
                          setOpen(true);
                          setSearchTypeTab("Location");
                        }, 100);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>
                      <span>
                        <img src={Images.plus} />
                      </span>
                      Add Location
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="comp-add-w mt-3">
                  <div
                    className={
                      (watchListdetails == undefined) == true
                        ? "comp-name-list-w"
                        : ((watchListdetails?.esg_factors !== undefined &&
                          watchListdetails?.esg_factors?.length !== 0) ||
                          (watchListdetails?.esg_pais !== undefined &&
                            watchListdetails?.esg_pais?.length !== 0) ||
                          (watchListdetails?.esg_sdgs !== undefined &&
                            watchListdetails?.esg_sdgs?.length !== 0) ||
                          (watchListdetails?.principles !== undefined &&
                            watchListdetails?.principles?.length !== 0)) ==
                        true && "comp-name-list-w"
                    }
                    style={
                      ((watchListdetails?.esg_factors !== undefined &&
                        watchListdetails?.esg_factors?.length !== 0) ||
                        (watchListdetails?.esg_pais !== undefined &&
                          watchListdetails?.esg_pais?.length !== 0) ||
                        (watchListdetails?.esg_sdgs !== undefined &&
                          watchListdetails?.esg_sdgs?.length !== 0) ||
                        (watchListdetails?.principles !== undefined &&
                          watchListdetails?.principles?.length !== 0)) == false
                        ? (watchListdetails == undefined) == true
                          ? {}
                          : {
                            borderRadius: "6px 6px 0px 0px",
                            padding: "10px",
                            backgroundColor: "#f7f8fa",
                          }
                        : null
                    }
                  >
                    <p className="name-of-the-comp">
                      <div
                        className={
                          (watchListdetails == undefined) == true
                            ? "blue-name-of-the-comp"
                            : ((watchListdetails?.esg_factors !== undefined &&
                              watchListdetails?.esg_factors?.length !== 0) ||
                              (watchListdetails?.esg_pais !== undefined &&
                                watchListdetails?.esg_pais?.length !== 0) ||
                              (watchListdetails?.esg_sdgs !== undefined &&
                                watchListdetails?.esg_sdgs?.length !== 0) ||
                              (watchListdetails?.principles !== undefined &&
                                watchListdetails?.principles?.length !==
                                0)) == true && "blue-name-of-the-comp"
                        }
                      >
                        <span className="add-to-my-list-item">
                          <img src={Images.digram} />
                        </span>
                        Category
                      </div>
                    </p>
                  </div>

                  <div
                    className="list-of-com-w"
                    style={
                      (watchListdetails?.esg_factors?.length == 0 ||
                        watchListdetails?.esg_factors == undefined) &&
                        (watchListdetails?.esg_pais?.length == 0 ||
                          watchListdetails?.esg_pais == undefined) &&
                        (watchListdetails?.esg_sdgs?.length == 0 ||
                          watchListdetails?.esg_sdgs == undefined) &&
                        (watchListdetails?.principles?.length == 0 ||
                          watchListdetails?.principles == undefined)
                        ? (watchListdetails == undefined) == true
                          ? {}
                          : {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                        : {} // Empty object for no additional styling when companies exist
                    }
                  >
                    <ul>
                      {watchListdetails?.esg_factors?.length > 0
                        ? watchListdetails?.esg_factors?.map((item, index) => (
                          <ListItemComponents
                            onClick={() => removeItem(item.id, "esg_factors")}
                            title={item?.name}
                          />
                        ))
                        : null}
                      {watchListdetails?.esg_pais?.length > 0
                        ? watchListdetails?.esg_pais?.map((item, index) => {
                          return (
                            <ListItemComponents
                              onClick={() => removeItem(item.id, "esg_pais")}
                              title={item?.name}
                            />
                          );
                        })
                        : null}
                      {watchListdetails?.esg_sdgs?.length > 0
                        ? watchListdetails?.esg_sdgs?.map((item, index) => (
                          <ListItemComponents
                            onClick={() => removeItem(item.id, "esg_sdgs")}
                            title={item?.name}
                          />
                        ))
                        : null}
                      {watchListdetails?.principles?.length > 0
                        ? watchListdetails?.principles?.map((item, index) => (
                          <ListItemComponents
                            onClick={() => removeItem(item.id, "principles")}
                            title={item?.name}
                          />
                        ))
                        : null}
                      {(watchListdetails?.esg_factors?.length == 0 ||
                        watchListdetails?.esg_factors == undefined) &&
                        (watchListdetails?.esg_pais?.length == 0 ||
                          watchListdetails?.esg_pais == undefined) &&
                        (watchListdetails?.esg_sdgs?.length == 0 ||
                          watchListdetails?.esg_sdgs == undefined) &&
                        (watchListdetails?.principles?.length == 0 ||
                          watchListdetails?.principles == undefined) ? (
                        <span
                          className="no-companies-add-ps"
                          style={{ color: "#676A73" }}
                        >
                          No categories added
                        </span>
                      ) : null}
                    </ul>
                  </div>
                  <div
                    className="w-add-com-link mt-2"
                    onClick={() => {
                      watchListData?.length > 0 && setOpen(true);
                      setSearchTypeTab("Categories");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>
                      <span>
                        <img src={Images.plus} />
                      </span>
                      Add Category
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="comp-add-w mt-3">
                  <div
                    className={
                      watchListdetails?.industries?.length !== 0 &&
                      "comp-name-list-w"
                    }
                    style={
                      watchListdetails?.industries?.length == 0
                        ? {
                          borderRadius: "6px 6px 0px 0px",
                          padding: "10px",
                          backgroundColor: "#f7f8fa",
                        }
                        : null
                    }
                  >
                    <p className="name-of-the-comp">
                      <div
                        className={
                          watchListdetails?.industries?.length !== 0 &&
                          "blue-name-of-the-comp"
                        }
                      >
                        <span className="add-to-my-list-item ">
                          <img src={Images.industryWindows} />
                        </span>
                        Industry
                      </div>
                    </p>
                  </div>
                  <div
                    className="list-of-com-w"
                    style={
                      watchListdetails?.industries?.length == 0
                        ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                        : {} // Empty object for no additional styling when companies exist
                    }
                  >
                    <ul>
                      {watchListData?.length > 0 &&
                        watchListdetails?.industries?.length > 0 ? (
                        watchListdetails?.industries?.map((item, index) => (
                          <>
                            <ListItemComponents
                              onClick={() => removeItem(item.id, "industries")}
                              title={item?.name}
                            />
                          </>
                        ))
                      ) : (
                        <span
                          className="no-companies-add-ps"
                          style={{ color: "#676A73" }}
                        >
                          No industries added
                        </span>
                      )}
                    </ul>
                  </div>
                  <div
                    className="w-add-com-link mt-2"
                    onClick={() => {
                      watchListData?.length > 0 && setOpen(true);
                      setSearchTypeTab("Industry");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>
                      <span>
                        <img src={Images.plus} />
                      </span>
                      Add Industry
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <WatchListAdvanceSearch
          title={"Add Company"}
          open={open}
          addModalTopSelectedItems={addModalTopItems}
          addModalTopItemsUpdate={setAddModalTopItems}
          correctionCompanyAddedItems={correctionCompanyAddedItems}
          selectedItemHandler={selectedItemHandler}
          typeBar={searchTypeTab}
          onclose={() => {
            setOpen(!open);
            dispatch(updateIndustriesForWatchlist(watchListdetails));
          }}
          selectedData={getselectedData(searchTypeTab) || []}
          watchListdetails={watchListdetails}
          _countryList={countryList}
          industryList={industryList}
        />
      )}
    </div>
  );
};

const ListItemComponents = ({ title, onClick }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      padding: "0 1vw 0 1vw",
      marginBottom: ".6vw",
    }}
  >
    <li>{title}</li>
    <img
      style={{ cursor: "pointer" }}
      onClick={onClick}
      src={ImageConst.closeIconslect}
    />
  </div>
);
export default WatchList;
