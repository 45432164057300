import React from "react";
import { IconButton, SvgIcon, Tooltip, useTheme } from "@mui/material";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
// import ButtonContainer from "./ButtonContainer";
// import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
import { setIsGroupNode } from "../../../redux/GraphExploration/toolBoxSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import ButtonContainer from "../ButtonContainer";
import CustomTooltip from "../../GraphToolTip/CustomTooltip";
import { useGraphObject } from "../../context/MyContext";
import {
  augmentNodesWithParentId,
  createCombos,
  removeCombos,
  toTitleCase,
} from "../../../utils";
import { Extensions } from "@antv/g6";
import { LayoutEnum } from "../../../enums/LayoutsEnum";

function GroupUnGroupButton() {
  const dispatch = useAppDispatch();
  const isGroupNode = useAppSelector(
    (state) => state.toolBox.toolBox.isGroupNode
  );
  const centerNodeId = useAppSelector((state) => state.graph.centerNode).id;
  const currentLayout = useAppSelector((state) => state.graph.layout);
  const { graphObject } = useGraphObject();
  const nodes = useAppSelector((state) => state.graph.nodes);
  const edges = useAppSelector((state) => state.graph.edges);

  // const removeCombos = () => {
  //   // remove data.parentId from all items of nodes
  //   const nodesWithoutParentId = nodes?.map((n) => {
  //     const { parentId, ...rest } = n.data;
  //     return {
  //       ...n,
  //       data: rest,
  //     };
  //   });
  //   graphObject.layout({
  //     type: "comboCombined",
  //     comboPadding: 0,
  //     workerEnabled: true,
  //     innerLayout: new Extensions.GridLayout({
  //       preventOverlap: true,
  //       nodeSize: 70,
  //     }),
  //   });
  //   graphObject?.changeData(
  //     {
  //       nodes: nodesWithoutParentId,
  //       combos: [
  //         { id: "supplier", data: { label: "Supplier", visible: false } },
  //         { id: "partner", data: { label: "Partner", visible: false } },
  //         { id: "customer", data: { label: "Customer", visible: false } },
  //       ],
  //       edges,
  //     },
  //     "mergeReplace"
  //   );
  // };

  // const createCombos = () => {
  //   // find neibours of the target node and devide them into groups 'customer', 'supplier' and 'partner'
  //   const neighboursNodes = graphObject?.getNeighborNodesData(centerNodeId);
  //   const augmentedNeighbouringNodesWithParentId = augmentNodesWithParentId(
  //     neighboursNodes,
  //     edges,
  //     centerNodeId
  //   );
  //   // Merge arrays based on 'id' key
  //   const augmentedNodesWithParentId = nodes.map((item) => {
  //     const subsetItem = augmentedNeighbouringNodesWithParentId.find(
  //       (sItem) => sItem.id === item.id
  //     );
  //     return subsetItem || item;
  //   });
  //   const uniqComboIds = augmentedNodesWithParentId.reduce((acc, item) => {
  //     if (item?.data?.parentId && !acc.includes(item?.data?.parentId)) {
  //       acc.push(item?.data?.parentId);
  //     }
  //     return acc;
  //   }, []);
  //   const newCombos = uniqComboIds.map((parentId) => ({
  //     id: parentId,
  //     data: {
  //       label: toTitleCase(parentId),
  //       visible: true,
  //     },
  //   }));
  //   if (currentLayout !== LayoutEnum.COMBO_COMBINED_LAYOUT) {
  //     graphObject.layout({
  //       type: "comboCombined",
  //       comboPadding: 0,
  //       workerEnabled: true,
  //       // outerLayout: new Extensions.ForceLayout({
  //       //   nodeStrength: 10,
  //       //   edgeStrength: 10,
  //       //   preventOverlap: true,
  //       // }),
  //       innerLayout: new Extensions.GridLayout({
  //         preventOverlap: true,
  //         nodeSize: 70,
  //       }),
  //     });
  //   }

  //   graphObject?.changeData(
  //     { nodes: augmentedNodesWithParentId, combos: newCombos, edges },
  //     "replace"
  //   );
  // };

  return (
    <ButtonContainer isActive={isGroupNode}>
      <CustomTooltip
        title={isGroupNode ? "Ungroup" : "Group Neighbour Nodes by Edge Type"}
        placement="left"
      >
        <span>
          <IconButton
            disabled={edges.length === 0}
            onClick={() => {
              if (isGroupNode) {
                removeCombos(nodes, edges, graphObject);
              } else {
                createCombos(
                  nodes,
                  edges,
                  graphObject,
                  centerNodeId,
                  currentLayout
                );
              }
              dispatch(setIsGroupNode(!isGroupNode));
            }}
          >
            <GroupWorkIcon />
          </IconButton>
        </span>
      </CustomTooltip>
    </ButtonContainer>
  );
}

export default GroupUnGroupButton;
