import EChartsReact from "echarts-for-react";
import { useEffect, useRef, useState } from "react";

export function EchartsSunBurstChartComponent({
  sepratedCategory,
  onChartClick,
}) {
  let echartsRef = useRef(null);
  // let clickedOnParent = useRef(false);
  useEffect(() => {
    const echarts = echartsRef.current.getEchartsInstance();
    echarts.on("click", "series", (e) => {
      if (e.data?.name !== undefined && e.data?.children === undefined) {
        onChartClick(e);
      }
    });
  }, []);

  const colors = {
    Governance: [
      "rgba(101, 61, 212, 1)",
      "rgba(101, 61, 212, 0.3)",
      "rgba(111, 81, 174, 0.25)",
    ],
    Social: [
      "rgba(33, 145, 251, 1)",
      "rgba(33, 145, 251, 0.3)",
      "rgba(40, 148, 208, 0.25)",
    ],
    Environmental: [
      "rgba(97, 166, 28, 1)",
      "rgba(97, 166, 28, 0.3)",
      "rgba(110, 189, 32, 0.25)",
    ],
  };
  const getChildData = (data, key) => {
    let arr = [];

    if (data?.length > 0) {
      let totalCount = data?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.incidentCount;
      }, 0);
      for (let i in data) {
        const obj = {
          name: `${((data[i].incidentCount / totalCount) * 100).toFixed(0)} %`,
          percentage:
            ((data[i].incidentCount / totalCount) * 100).toFixed(0) + " %",
          value: data[i].incidentCount,
          _name: "Postive",
          data: data[i],
          parent:
            key == "Governance" ? "G" : key == "Environmental" ? "E" : "S",
          itemStyle: {
            color: {
              colorStops: [
                {
                  offset: 0.1,
                  color: colors[key][0],
                },
                {
                  offset: 1,
                  color: colors[key][1],
                },
              ],
              x: 0,
              y: 0,
              x2: 0,
              y2: 1.3,
              type: "linear",
              global: false,
            },
            borderRadius: 5,
            borderWidth: 2,
            borderColor: "#f7f8fa",
            shadowBlur: 8,
            shadowColor: colors[key][2],
            shadowOffsetY: 4,
            shadowOffsetX: 0,
          },
        };
        arr.push(obj);
      }
      return arr;
    }
    return arr;
  };

  const getPercentage = (value) => {
    let totalValue =
      sepratedCategory?.Environmental?.total +
      sepratedCategory?.Social?.total +
      sepratedCategory?.Governance.total;

    let percentage = `${((value / totalValue) * 100).toFixed(0)} %`;
    return percentage;
  };

  return (
    <EChartsReact
      className="doubleLayer-pieChart"
      ref={(e) => {
        echartsRef.current = e;
      }}
      option={{
        silent: false,
        tooltip: {
          show: true,
          formatter: (params) => {
            if (!params.data?.percentage) {
              return "Click to go back.";
            }

            return `${
              params.data?.esgName || params.data?.data?.name
            } ${params.data?.percentage}`;
          },
        },
        series: [
          {
            label: {
              color: "#fff",
              rotate: 0,
              formatter: function (params) {
                if (params.value === 228) console.log("params", params);
                let percentValue =
                  parseInt(
                    params?.treePathInfo[1]?.name
                      ?.split("\n")?.[1]
                      ?.split(" ")?.[0],
                    10
                  ) / 100;
                if (params.treePathInfo.length > 2) {
                  percentValue =
                    (percentValue *
                      parseInt(
                        params?.treePathInfo?.[2]?.name?.split(" ")?.[0],
                        10
                      )) /
                    100;
                }
                return percentValue > 0.02 ? params?.data?.name : " ";
              },
              position: "inside",
              fontSize: 10,
              fontWeight: "bold",
            },

            itemStyle: {
              borderRadius: 0,
              borderWidth: 3,
              borderColor: "#f7f8fa",
            },
            radius: ["0", "100%"],
            type: "sunburst",
            emphasis: {
              focus: "ancestor",
            },

            data: [
              {
                children: getChildData(
                  sepratedCategory?.Governance?.data,
                  "Governance"
                ),
                name: `G\n${getPercentage(
                  sepratedCategory?.Governance?.total
                )}`,
                esgName: "Governance",
                percentage: getPercentage(sepratedCategory?.Governance?.total),
                value: sepratedCategory?.Governance?.data?.reduce(
                  (accumulator, currentValue) => {
                    return accumulator + currentValue?.incidentCount;
                  },
                  0
                ),
                itemStyle: {
                  color: {
                    colorStops: [
                      {
                        offset: 0.1,
                        color: colors["Governance"][0], // "rgba(101, 61, 212, 0.3)",
                      },
                      {
                        offset: 1,
                        color: colors["Governance"][1], // "#653DD4",
                      },
                    ],
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1.3,
                    type: "linear",
                    global: false,
                  },
                },
                shadowBlur: 8,
                shadowColor: colors["Governance"][2],
                shadowOffsetY: 4,
                shadowOffsetX: 0,
              },
              {
                children: getChildData(
                  sepratedCategory?.Environmental?.data,
                  "Environmental"
                ),
                name: `E\n${getPercentage(
                  sepratedCategory?.Environmental?.total
                )}`,
                esgName: "Environmental",
                percentage: getPercentage(
                  sepratedCategory?.Environmental?.total
                ),
                value: sepratedCategory?.Environmental?.data?.reduce(
                  (accumulator, currentValue) => {
                    return accumulator + currentValue?.incidentCount;
                  },
                  0
                ),
                itemStyle: {
                  color: {
                    colorStops: [
                      {
                        offset: 0.1,
                        color: colors["Environmental"][0],
                      },
                      {
                        offset: 1,
                        color: colors["Environmental"][1],
                      },
                    ],
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1.3,
                    type: "linear",
                    global: false,
                  },
                  shadowBlur: 8,
                  shadowColor: colors["Environmental"][2],
                  shadowOffsetY: 4,
                  shadowOffsetX: 0,
                },
              },
              {
                children: getChildData(
                  sepratedCategory?.Social?.data,
                  "Social"
                ),
                name: `S\n${getPercentage(sepratedCategory?.Social?.total)}`,
                esgName: "Social",
                percentage: getPercentage(sepratedCategory?.Social?.total),
                value: sepratedCategory?.Social?.data?.reduce(
                  (accumulator, currentValue) => {
                    return accumulator + currentValue?.incidentCount;
                  },
                  0
                ),
                itemStyle: {
                  color: {
                    colorStops: [
                      {
                        offset: 0.1,
                        color: colors["Social"][0],
                      },
                      {
                        offset: 1,
                        color: colors["Social"][1],
                      },
                    ],
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1.3,
                    rotate: 0,
                    type: "linear",
                    global: false,
                  },
                  shadowBlur: 8,
                  shadowColor: colors["Social"][2],
                  shadowOffsetY: 4,
                  shadowOffsetX: 0,
                },
              },
            ],
            levels: [
              {},
              {
                itemStyle: {
                  borderWidth: 0,
                },
              },
              {
                r0: 85,
                r: 140,
              },
            ],
          },
        ],
      }}
      onEvents={
        {
          // click: onChartClick,
        }
      }
    />
  );
}
