import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyListForModal } from "../../Redux/Action/incidents";
import Images from "../../Themes/ImageConstant";
import { openIncidentDetail } from "../../Utils/globalFunction";
import { useCallback, useEffect } from "react";
import {
	calculateCompanyRisk,
	checkColor,
	checkRiskColor,
	debounce,
	getColorForTable,
	getColorForTableText,
	getRiskVal,
	validCheckColor,
} from "../../Utils";
import { Select, Table, Tooltip } from "antd";
import appconstant from "../../Constants/appConstansts";
import SupplyTableLineChart from "../searchResult/components/supplyTableLineChart";

const CompaniesListModalTable = (props) => {
	const { payloadState, setPayloadState, loading } = props;
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const companiesListForModal = useSelector(
		(state) => state?.incidentReducer?.companiesListForModal
	);
	const countriesListForModal = useSelector(
		(state) => state?.incidentReducer?.countriesListForModal
	);
	const esgChildListForModal = useSelector(
		(state) => state?.incidentReducer?.esgChildListForModal
	);
	const onNameClick = (item) => {
		openIncidentDetail(item?.id);
	};

	const handleSort = (e, f, sort) => {
		const sortKey = sort.column?.dataIndex == "severity_score" ? "severity_score.value" : sort.column?.dataIndex
		setPayloadState((prev) => ({
			...prev,
			sort: sortKey,
			order: sort.order == "descend" ? -1 : 1,
		}));
	};

	const handleInvolvedCompanyChange = (item) => {
		setPayloadState((prev) => ({ ...prev, companies: item }));
	};

	const handleEsgFactorChange = (item, key = "esg_factors") => {
		setPayloadState((prev) => ({ ...prev, [key]: item }));
	};

	const handleCountriesChange = (item) => {
		setPayloadState((prev) => ({ ...prev, locations: item }));
	};

	useEffect(() => {
	}, [companiesListForModal])

	let series2 = (data) => {
		return [
			{
				data: data,
			},
		];
	};

	const columns = useCallback(
		() => [
			...(props.tableHeaderArray.includes("name")
				? [
					{
						title: "Company",
						// title: "Name",
						dataIndex: "name",
						width: "180px",
						className: `company-supplyChain ${!props.tableHeaderArray.includes("name") ? "displayNone" : ""
							}`,
						render: (text, item, index) => {
							return (
								<a style={{ display: "flex" }}>
									<span className="col-index">{`${index + 1 + payloadState.offset} `}</span>
									<a
										onClick={() => navigate("/companyPage", { state: { id: item.id } })}
										className="titleText theme-dark-text"
										style={{
											display: "block",
											whiteSpace: "nowrap",
											overflow: "hidden",
											maxLines: 1,
											textOverflow: "ellipsis",
										}}
									>{`${text}`}</a>
								</a>
							);
						},
					},
				]
				: []),
			...(props.tableHeaderArray.includes("risk")
				? [
					{
						title: "ESG Risk",
						dataIndex: "risk",
						className: `company-supplyChain`,
						sorter: true,
						// ellipsis: true,
						width: "120px",
						className: `${
							!props.tableHeaderArray.includes("risk") ? "displayNone" : ""
						  }`,
						render: (_, item) => (
							<div
							style={{
								backgroundColor:
								checkRiskColor[getRiskVal(item?.risk)],
								display: "flex",
								justifyContent: "center",
								height: "40px",
								alignItems: "center",
							  }} 
								className="red-esg-risk"
							>
								 <p
									style={{
										borderRadius: '2px',
										textAlign: 'center',
										padding: '4px 8px',
										color: "#fff",
										fontFamily: 'Inter',
										fontSize: '12px',
										fontStyle: 'normal',
										fontWeight: '500',
										lineHeight: '16px'
									}}
								 >{(item?.risk && Math.round(item?.risk)) || "0"}</p>
							</div>
						),
					},
				]
				: []),
			...(props.tableHeaderArray.includes("Risk Change")
				? [
					{
						className: `company-supplyChain`,
						width: "120px",
						title: "Risk Change",
						dataIndex: "involvedCompanies",
						className: ` riskchange ${!props.tableHeaderArray.includes("Risk Change") ? "displayNone" : ""
							}`,
						render: (_, item) => {
							let value = (item?.riskTimeline && item?.riskTimeline.length > 0) ? calculateCompanyRisk(item?.riskTimeline) : 0;
							return (
								<div className="risk-change-ten-per">
									<span
										style={{
											color: value > 0 ? "#1A9C3E" : "#d00000",
											background: value > 0 ? "#E8F5EC" : "#FCEDED",
										}}
									>
										<img
											src={value > 0 ? Images.greenArrowSumUp : Images.arrowSumUp}
										/>
										{Math.abs(value || 0) + "%"}
									</span>
								</div>
							);
						},
					},
				]
				: []),
			...(props.tableHeaderArray.includes("Risk Change")
				? [
					{
						title: "",
						dataIndex: "totalIncidents",
						key: "totalIncidents",
						width: "160px",
						className: ` riskchange ${!props.tableHeaderArray.includes("Risk Change") ? "displayNone" : ""
							}`,
						sorter: false,
						render: (name, item) => {
							let value = calculateCompanyRisk(item.riskTimeline);
							return (
								<>
									<div className="">
										<SupplyTableLineChart
											data={"chartData"}
											colors={value > 0 ? ["#72EB93", "green"] : ["#d00000", "red"]}
											series={series2(item.riskTimeline)}
										/>
									</div>
								</>
							);
						},
					},
				]
				: []),
			...(props.tableHeaderArray.includes("Incidents")
				? [
					{
						title: "Incidents",
						dataIndex: "incidents",
						key: "incidents",
						width: "120px",
						className: `${!props.tableHeaderArray.includes("Incidents") ? "displayNone" : ""
							}`,
						sorter: true,
						render: (name, item) => {
							return (
								<span
									className="sup-data-Um"
									onClick={() => {
										// if (item.incidents > 0) {
										//   setModalVisible(true);
										//   setCompanyId(item.id);
										// }
									}}
								>
									{item.incidents}
								</span>
							);
						},
					},
				]
				: []),
			...(props.tableHeaderArray.includes("Relationship Type")
				? [
					{
						title: "Relationship Type",
						dataIndex: "relation",
						width: "160px",
						key: "relation",
						className: `${!props.tableHeaderArray.includes("Relationship Type") ? "displayNone" : ""
							}`,
						render: (relation) => (
							<div>
								<span
									style={{
										textTransform: "capitalize",
										fontFamily: "inter",
										fontSize: 13,
									}}
								>
									{relation}
								</span>
							</div>
						),
					},
				]
				: []),
			...(props.tableHeaderArray.includes("Industry")
				? [
					{
						title: "Industry",
						dataIndex: "industries",
						key: "industries",
						width: "160px",
						className: `${!props.tableHeaderArray.includes("Industry") ? "displayNone" : ""
							}`,
						render: (industries) => <div>{industries?.name}</div>

					},
				]
				: []),

			...(props.tableHeaderArray.includes("locations")
				? [
					{
						title: "Location",
						dataIndex: "location",
						width: "160px",
						key: "location",
						className: `${!props.tableHeaderArray.includes("locations") ? "displayNone" : ""
							}`,
						render: (location) => <div>{<span>{location}</span>}</div>,
					},
				]
				: [])
		],
		[
			props.tableHeaderArray,
			payloadState,
			esgChildListForModal,
			companiesListForModal,
			countriesListForModal,
		]
	);
	return (
		<Table
			scroll={{
				x: 1200,
				y: 500,
			}}
			onChange={handleSort}
			columns={columns()}
			sortDirections={["ascend", "descend", "ascend"]}
			dataSource={props.incidents || []}
			pagination={false}
			loading={loading}
		/>
	);
};

export default CompaniesListModalTable;
