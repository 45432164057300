import { call, put, takeLatest } from "redux-saga/effects";
import appconstant from "../../Constants/appConstansts";
import { onError, onFail, onSucces, onUnAuth } from "../../Utils/UnAuthFailFn";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  getCompanyDetailApi,
  getCountryListApi,
  getIncidentSeverityApi,
  getIncidentWithYearApi,
  getSentimentAnalysisApi,
  getSentimentScoreApi,
  getSentimentTrendApi,
  getSupplyChainIncidentApi,
  getIncidentCategorizationApi,
  getPeerCompaniesRate,
  getIncidentOverTime,
  mostUsedWordsAPI,
  serverityTrendApi,
  incidentComparisonApi,
  severityOnMApApi,
  getIncidentTimelineApi,
  getESGRiskScoreApi,
  getRegulatoryFinesPenaltiesApi,
  getRegulatoryLegalActionsApi,
  socialMediaCoverageApi,
  ungcpBreachesApi,
  riskTrendApi,
  peerRiskApi,
  riskLineApi,
} from "../api";

const { RoutesPath } = appconstant;

function* getCountryListSaga(action) {
  try {
    const data = yield call(getCountryListApi, action.payload);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_COUNTRY_LIST_SUCCESS,
        data,
        show: false,
      };
      yield call(onSucces, obj);
    }
    // on fail
    else {
      let obj = {
        type: type.API_GET_COUNTRY_LIST_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getCompaniesDetailSaga(action) {
  try {
    const data = yield call(getCompanyDetailApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_COMPANY_DETAIL_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_COMPANY_DETAIL_FAIL,
        message: data?.API_GET_COMPANY_DETAIL_FAIL,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getSupplyChainIncidentSaga(action) {
  try {
    const data = yield call(getSupplyChainIncidentApi, action.payload);

    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_SupplyChain_Incident_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_SupplyChain_Incident_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getSentimentTrendSaga(action) {
  try {
    const data = yield call(getSentimentTrendApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_SentimentTrend_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_SentimentTrendt_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getSentimentAnalysisSaga(action) {
  try {
    const data = yield call(getSentimentAnalysisApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_Sentiment_Analysis_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_Sentiment_Analysis_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getSentimentScoreSaga(action) {
  try {
    const data = yield call(getSentimentScoreApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_SENTIMENTSCORE_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_SENTIMENTSCORE_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getIncidentSeveritySaga(action) {
  try {
    const data = yield call(getIncidentSeverityApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_INCIDENT_SEVERITY_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_INCIDENT_SEVERITY_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getIncidentWithYearSaga(action) {
  try {
    const data = yield call(getIncidentWithYearApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_INCIDENT_WITHYEAR_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_INCIDENT_WITHYEAR_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* incidentCategorization(action) {
  try {
    const data = yield call(getIncidentCategorizationApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_INCIDENT_CATEGORIZATION_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_INCIDENT_CATEGORIZATION_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* peerCompaniesIncidentRate(action) {
  try {
    const data = yield call(getPeerCompaniesRate, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_PEER_COMPANIES_INCIDENT_RATE_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_PEER_COMPANIES_INCIDENT_RATE_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* incidentDistributionOverTime(action) {
  try {
    const data = yield call(getIncidentOverTime, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_FAIL,
        type: type.API_GET_MOST_USED_WORDS_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* mostUsedWordsSaga(action) {
  try {
    const data = yield call(mostUsedWordsAPI, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_MOST_USED_WORDS_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_FAIL,
        type: type.API_GET_MOST_USED_WORDS_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* serverityTrendSaga(action) {
  try {
    const data = yield call(serverityTrendApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_SERVITY_TREND_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_SERVITY_TREND_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* incidentComparisonSaga(action) {
  try {
    const data = yield call(incidentComparisonApi, action.payload);
    // on success
    if (data.status === 0) {
      // let obj = {
      //   type:
      //   show: false,
      //   data,
      //   dataType:action.payload.type
      // };
      // yield call(onSucces, obj);
      yield put({
        type: type.API_GET_INCIDENT_COMPARISON_SUCCESS,
        payload: data.result,
        dataType: action.payload.type,
      });
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_INCIDENT_COMPARISON_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* serverityMapSaga(action) {
  try {
    const data = yield call(severityOnMApApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_SERVERITY_MAP_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_SERVERITY_MAP_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* getIncidentTimelineSaga(action) {
  try {
    const data = yield call(getIncidentTimelineApi, action?.payload);
    if (data?.status === 0) {
      let obj = {
        type: type.API_GET_INCIDENT_TIMELINE_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    } else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_GET_INCIDENT_TIMELINE_FAIL,
        message: data?.message,
        show: false,
      };
      onFail(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

//NEW
function* getESGRiskScoreSaga(action) {
  try {
    const data = yield call(getESGRiskScoreApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_ESG_RISK_SCORE_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getRegulatoryFinesPenaltiesSaga(action) {
  try {
    const data = yield call(getRegulatoryFinesPenaltiesApi, action.payload);
    if (data.status === 0) {
      let obj = {
        type: type.GET_REGULATORY_FINES_PENALTIES_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getRegulatoryLegalActionSaga(action) {
  try {
    const data = yield call(getRegulatoryLegalActionsApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_REGULATORY_LEGAL_ACTION_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* getSocialMediaCoverageSaga(action) {
  try {
    const data = yield call(socialMediaCoverageApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_SOCIAL_MEDIA_COVER_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* ungcpBreachesSaga(action) {
  try {
    const data = yield call(ungcpBreachesApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_UNGCP_BREACHES_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* riskTrendSaga(action) {
  try {
    const data = yield call(riskTrendApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_RISK_TREND_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* peerRiskSaga(action) {
  try {
    const data = yield call(peerRiskApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_COMPANY_RISK_PEER_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* riskLineSaga(action) {
  try {
    const data = yield call(riskLineApi, action.payload);
    // on success
    if (data.status === 0) {
      let obj = {
        type: type.GET_COMPANY_RISK_LINE_SUCCESS,
        show: false,
        data,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
export default function* rootCompanyPageSaga() {
  yield takeLatest(type.API_GET_COUNTRY_LIST_LOAD, getCountryListSaga);
  yield takeLatest(
    type.API_GET_INCIDENT_TIMELINE_LOAD,
    getIncidentTimelineSaga
  );
  yield takeLatest(type.API_GET_COMPANY_DETAIL_LOAD, getCompaniesDetailSaga);
  yield takeLatest(
    type.API_GET_SupplyChain_Incident_LOAD,
    getSupplyChainIncidentSaga
  );
  yield takeLatest(
    type.API_GET_Sentiment_Analysis_LOAD,
    getSentimentAnalysisSaga
  );
  yield takeLatest(type.API_GET_SentimentTrend_LOAD, getSentimentTrendSaga);
  yield takeLatest(type.API_GET_SENTIMENTSCORE_LOAD, getSentimentScoreSaga);
  yield takeLatest(
    type.API_GET_INCIDENT_SEVERITY_LOAD,
    getIncidentSeveritySaga
  );
  yield takeLatest(
    type.API_GET_INCIDENT_WITHYEAR_LOAD,
    getIncidentWithYearSaga
  );
  yield takeLatest(
    type.API_GET_INCIDENT_CATEGORIZATION_LOAD,
    incidentCategorization
  );
  yield takeLatest(
    type.API_GET_PEER_COMPANIES_INCIDENT_RATE_LOAD,
    peerCompaniesIncidentRate
  );

  yield takeLatest(
    type.API_GET_INCIDENT_DISTRIBUTION_OVER_TIME_LOAD,
    incidentDistributionOverTime
  );
  yield takeLatest(type.API_GET_MOST_USED_WORDS_LOAD, mostUsedWordsSaga);
  yield takeLatest(type.API_GET_SERVITY_TREND_LOAD, serverityTrendSaga);
  yield takeLatest(
    type.API_GET_INCIDENT_COMPARISON_LOAD,
    incidentComparisonSaga
  );
  yield takeLatest(type.API_GET_SERVERITY_MAP_LOAD, serverityMapSaga);

  // NEW
  yield takeLatest(type.GET_ESG_RISK_SCORE_LOAD, getESGRiskScoreSaga);

  yield takeLatest(
    type.GET_REGULATORY_FINES_PENALTIES_LOAD,
    getRegulatoryFinesPenaltiesSaga
  );
  yield takeLatest(
    type.GET_REGULATORY_LEGAL_ACTION_LOAD,
    getRegulatoryLegalActionSaga
  );
  yield takeLatest(
    type.GET_SOCIAL_MEDIA_COVER_LOAD,
    getSocialMediaCoverageSaga
  );
  yield takeLatest(type.GET_UNGCP_BREACHES_LOAD, ungcpBreachesSaga);
  yield takeLatest(type.GET_RISK_TREND_LOAD, riskTrendSaga);
  yield takeLatest(type.GET_COMPANY_RISK_PEER_LOAD, peerRiskSaga);
  yield takeLatest(type.GET_COMPANY_RISK_LINE_LOAD, riskLineSaga);
}
