import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { Checkbox, Radio, Select, Space } from "antd";
import { Breadcrumb } from "antd";
import { Pagination } from "antd";
import { Tooltip } from "antd";
import { Modal } from "antd";
import CompaniesListModalTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesAction,
  getEsgCountriesAction,
  getEsgFactorListForModalAction,
  getEsgFactorsAction,
  getCountryListForModalAction,
  incindentAction,
  updateIncidentState,
  getIncidentListForModalAction,
  getCompanyListForModal,
} from "../../Redux/Action/incidents";
import { preventInvalidNumberInput } from "../../Utils/globalFunction";
import { useRef } from "react";
import GlobalLoder from "../../Utils/GlobalLoder";
import { getCorpCompaniesListLoad, getCorpIncidentBarLoad } from "../../Redux/Action/corporateProf";
import { useNavigate } from "react-router-dom";

const text = <span>Download</span>;

const CompaniesListModalWithAllData = ({ networkData, setModalVisible }) => {

  const navigate = useNavigate()

  const incidents = useSelector(
    (state) => state?.incidentReducer?.incidentsForModal
  );
  const { isLoading } = useSelector((state) => state?.incidentReducer);
  const {
    companiesList,
    companiesPagination,
    companiesListLoader
  } = useSelector(state => state.corporateProfile)

  const [payloadState, setPayloadState] = useState({
    limit: 25,
    offset: 0,
    ...networkData,
  });
  const [perPageValue, setPerPageValue] = useState("");
  const [tableHeaders, setTableHeaders] = useState([
    "name",
    "risk",
    "Risk Change",
    "Incidents",
    "Relationship Type",
    "Industry",
    "locations",
  ]);

  const dispatch = useDispatch();
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <span>Previous</span>;
    }
    if (type === "next") {
      return <span>Next</span>;
    }
    return originalElement;
  };

  useEffect(() => {
    // dispatch(getEsgCountriesAction());
    // dispatch(getEsgFactorListForModalAction());
    // dispatch(getCountryListForModalAction());
    // dispatch(getCompanyListForModal());
    dispatch(getCorpCompaniesListLoad({ ...payloadState, companyId: payloadState.companies }));
  }, []);
  useEffect(() => {
    
    // dispatch(getIncidentListForModalAction(payloadState));
    dispatch(getCorpCompaniesListLoad({ ...payloadState, companyId: payloadState.companies }));
  }, [payloadState]);
  const handleChange = (value) => { };

  const onPaginationChange = (page) => {
    if (page) {
      setPayloadState((prev) => {
        return { ...prev, offset: (page - 1) * prev.limit };
      });
    }
  };

  const handleLimitChange = (limit) => {
    setPayloadState((prev) => {
      return { ...prev, limit: limit };
    });
  };

  const handeGoToPageInput = (e) => {
    let newVal = e.target.value.replace("e", "");
    if (
      +newVal &&
      +newVal > Math.ceil(companiesPagination?.totalCount / payloadState.limit)
    ) {
      newVal = Math.ceil(companiesPagination?.totalCount / payloadState.limit);
    }
    if ((+newVal || newVal === "0") && 1 > +newVal) {
      newVal = 1;
    }
    setPerPageValue(newVal);
  };

  const handleIncidentSourceChange = (item) => {
    setPayloadState((prev) => ({ ...prev, sources: item }));
  };

  const handleColumnCheckboxChange = (e) => {
    if (e.target.checked && tableHeaders.includes(e.target.value)) return;
    if (e.target.checked && !tableHeaders.includes(e.target.value)) {
      setTableHeaders((prev) => [...prev, e.target.value]);
    }
    if (!e.target.checked && tableHeaders.includes(e.target.value)) {
      setTableHeaders((prev) => prev.filter((f) => f !== e.target.value));
    }
  };

  const handleColumnRadioChange = (e) => {
    const colArr = ["esg_factors", "esg_sdgs", "esg_pais"];

    setTableHeaders((prev) => {
      const newArr = prev.filter((e) => !colArr.includes(e));
      return [...newArr, e.target.value];
    });
  };

  const columnsOptions = [
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"Incidents"}
        >
          Incidents
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"Relationship Type"}
        >
          Relationship Type
        </Checkbox>
      ),
      value: "title",
    },
    {
      label: (
        <Checkbox
          onChange={handleColumnCheckboxChange}
          defaultChecked={true}
          value={"locations"}
        >
          Locations
        </Checkbox>
      ),
      value: "title",
    },
  ];

  return (
    <Modal
      className="incident-page-table-modal-ps "
      centered
      open={true}
      closable={false}
      onCancel={() => setModalVisible(false)}
      footer={[]}
    >
      <div className="user-content-modal-ps">
        <div className="side-padding inc-outer-padding">
          <div className="bg-sec main-layout table-layout ">
            <section className=" incidents-table-modal-ps">
              <div className="breadcrmbs mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item
                    key="1"
                    className="bad-link"
                    onClick={() => {
                      updateIncidentState([], "");
                      setModalVisible(false);
                    }}
                  >
                    {" "}
                    <img src={Images.left} />
                    Back
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="2" className="inc-link">
                    {" "}
                    Corporate Profile
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="3" className="lie-link">
                    {" "}
                    Supply Chain
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {/* {companiesListLoader &&
                <div className="global-loader-dv">
                  <GlobalLoder />
                </div>
              } */}
              <div className="table-page-sec " style={{ display: isLoading && "none" }}>
                <div className="risk_levels_cmpny_table_container">
                  <div style={{ marginRight: '5px'}}>
                    Risk level:
                  </div>
                  <div className="risk_values_container">
                    <div className="compny_table_child_1">
                      Secure
                    </div>
                    <div className="compny_table_child_2" >
                      Low
                    </div>
                    <div className="compny_table_child_3">
                      Medium
                    </div>
                    <div className="compny_table_child_4">
                      High
                    </div>
                  </div>
                </div>
                <div className="topp-div">
                  <div className="table-head-sec">
                    <div className="serch-bar-in">
                      <span>
                        {" "}
                        <img className="me-2" alt="" src={Images.sear} />
                      </span>
                      <input
                        type="text"
                        placeholder="Search keywords"
                        value={payloadState.search}
                        onChange={(e) => {
                          setPayloadState((prev) => ({
                            ...payloadState,
                            search: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="right-side-colm">
                    <Tooltip placement="top" title={text}>
                      <div className="table-downl-btn">
                        <img src={Images.framedow} />
                      </div>
                    </Tooltip>

                    <div className="colum-box">
                      <div className="select-relation-ps add-icons-select-box-ps">
                        <div className="col-box-ps">
                          <img src={Images.columns} />
                        </div>
                        <Select
                          onChange={handleChange}
                          placeholder={"Columns"}
                          value={[]}
                          popupClassName="incident-modal-columns-select"
                          options={columnsOptions}
                          mode="multiple"
                          showSearch={false}
                          style={{ width: "200px" }}
                        ></Select>
                      </div>
                    </div>
                    <div
                      className="chart-btn cstm_height_chart_btn"
                      onClick={() => {
                        navigate("/graphview", {
                          state: {
                            id: payloadState?.companies,
                            name: payloadState?.companyName,
                            start: payloadState.start,
                            end: payloadState.end,
                          },
                        });
                      }}
                    >
                      <button style={{ padding: "0px 18px 0px 18px" }}>
                        <img src={Images.chart} />
                        Graph View
                      </button>
                    </div>
                  </div>
                </div>

                <div className="table-box incident">
                  <CompaniesListModalTable
                    incidents={companiesList}
                    payloadState={payloadState}
                    setPayloadState={setPayloadState}
                    tableHeaderArray={tableHeaders}
                    loading={companiesListLoader}
                  />
                  {/* <ReferenceTable/>    */}
                </div>

                <div className="page-nation">
                  <div className="row-per">
                    <span>Rows per page:</span>
                    <Select
                      value={payloadState.limit}
                      style={{ width: 60 }}
                      onChange={handleLimitChange}
                      options={[
                        { value: 25, label: "25" },
                        { value: 30, label: "30" },
                        { value: 35, label: "35" },
                      ]}
                    />
                  </div>

                  <div className="page-num">
                    <span>
                      <img src={Images.chevron} />
                    </span>{" "}
                    <Pagination
                      onChange={onPaginationChange}
                      pageSize={payloadState.limit}
                      total={companiesPagination.totalCount}
                      itemRender={itemRender}
                      current={
                        Math.ceil(payloadState.offset / payloadState.limit) +
                        1
                      }
                    />{" "}
                    <span>
                      <img src={Images.chevronRighta} />
                    </span>
                  </div>

                  <div className="goto-pg pg-number">
                    <span>Go to page:</span>
                    <input
                      type="number"
                      name="search2"
                      value={perPageValue}
                      onKeyDown={preventInvalidNumberInput}
                      onChange={handeGoToPageInput}
                    />
                    <button
                      type="button"
                      onClick={() => onPaginationChange(perPageValue)}
                    >
                      Go
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CompaniesListModalWithAllData;
