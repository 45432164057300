import { apiConstants as types } from "../../Constants/apiConstants";

const initialState = {
  isLoading: false,
  result: {},
  wishListData: [],
  watchlist: [],
  watchListDetails: [],
  watchListId: "",
  isUpadted: false,
  addWatchListID: null,
  uploadLoading: false,
  isUploaded: false,
  uploadeData: {},
  fileData: [],
  uploadFileSuccess: false,
  uploadFileLoading: false,
  saveSelectedWatchlistCompany: false,
};
export const watchListReducer = (state = initialState, action) => {
  let { payload } = action;
  switch (action.type) {
    case types.API_ADD_WISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wishListData: payload.data,
        uploadFileSuccess: false,
      };
    case types.API_ADD_WISHLIST_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_WISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        watchlist: payload?.data,
        uploadFileSuccess: false,
      };
    case types.API_GET_WISHLIST_LOAD:
      return { ...state, isLoading: true };
    case types.API_GET_WISHLIST_DETAILS_SUCCESS:
      const data = payload.data;
      const principleArray = data?.principles?.map((val) => ({
        id: val,
        name: val,
      }));
      data.principles = principleArray;
      return {
        ...state,
        isLoading: false,
        watchListDetails: payload.data,
        uploadFileSuccess: false,
      };
    case types.API_GET_WISHLIST_DETAILS_LOAD:
      return { ...state, isLoading: false };
    case types.API_GET_WISHLIST_DETAILS_UPDATE:
      return {
        ...state,
        watchListDetails: payload,
      };
    case types.API_DELETE_WISHLIST_SUCCESS:
      return { ...state, isLoading: false, uploadFileSuccess: false };
    case types.API_DELETE_WISHLIST_LOAD:
      return { ...state, isLoading: true, uploadFileSuccess: false };
    case types.GET_CURRENT_WATCHLIST:
      return { ...state, watchListId: action.data, uploadFileSuccess: false };
    case types.SET_SELECTED_WATCHLIST:
      return {
        ...state,
        selectedWatchList: action.data,
        uploadFileSuccess: false,
      };
    case types.API_EDIT_WISHLIST_LOAD:
      return { ...state, isLoading: true, uploadFileSuccess: false };
    case types.API_EDIT_WISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpadted: true,
        uploadFileSuccess: false,
      };
    case types.UPDATE_WATCHLIST_SEVER_LOAD:
      return { ...state, isLoading: false };
    case types.SHOW_RESULT:
      return { ...state, currentWatchListId: action.data };
    case types.UPDATE_ADD_WISHLIST_ID:
      return {
        ...state,
        isLoading: false,
        addWatchListID: payload?.data,
        uploadFileSuccess: false,
      };

    case types.UPLOAD_COMPANY_FILE_LOAD:
      return {
        ...state,
        uploadLoading: true,
        isLoading: true,
        isUploaded: false,
        uploadData: {},
      };
    case types.UPLOAD_COMPANY_FILE_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        isLoading: false,
        isUploaded: true,
        uploadeData: payload.data,
        uploadFileSuccess: false,
      };
    case types.UPLOAD_COMPANY_FILE_FAIL:
      return { ...state, uploadLoading: false, isLoading: false };
    case types.UPLOAD_FILE_LOAD:
      return {
        ...state,
        fileData: payload,
      };


    case types.UPLOAD_WATCHLIST_COMPANY_FILE_LOAD:
      return { ...state, isLoading: true, uploadFileSuccess: false, uploadFileLoading: true };
    case types.UPLOAD_WATCHLIST_COMPANY_FILE_SUCCESS:
      console.log("Calllled");
      return { ...state, isLoading: false, uploadFileSuccess: true, uploadFileLoading: false };
    case types.SAVE_VALID_SUPPLY_CHAIN_COMPANY_LOAD:
      return {
        ...state,
        saveSelectedWatchlistCompany: true,
      };
    case types.SAVE_VALID_SUPPLY_CHAIN_COMPANY_SUCCESS:
      return {
        ...state,
        saveSelectedWatchlistCompany: false,
      };

    case types.SAVE_VALID_SUPPLY_CHAIN_COMPANY_FAIL:
      return {
        ...state,
        saveSelectedWatchlistCompany: false,
      };
    case types.UPLOAD_WATCHLIST_COMPANY_FILE_FAIL:
      return { ...state, isLoading: false };
    default:
      return { ...state };
  }
};
