import React, { memo, useState } from "react";
import { Popover } from "antd";

import CustomDatePIcker from "../CustomDatePIcker";

const DatePicker = ({ onDatePickerChange, lastDate, buttonTitle, title, resetFullMapFilter }) => {
  const [dataTitle, setDateTitle] = useState(buttonTitle || "Last 3 Months");
  const [open, setOpen] = useState(false);
  const onChange = (startDate, previousDate) => {
    localStorage.interval = dataTitle;
    resetFullMapFilter && resetFullMapFilter()
    setOpen(!open);
    onDatePickerChange(startDate, previousDate);
  };
  const locationPath = window.location.pathname;
  return (
    <Popover
      placement="bottomRight"
      content={
        <CustomDatePIcker
          onDatePickerChange={onChange}
          setDateTitle={setDateTitle}
          dataTitle={dataTitle}
          lastDate={lastDate}
        />
      }
      title="Select a date"
      trigger="click"
      open={open}
      onOpenChange={() => setOpen(!open)}
    >
      <div
        className="placeholder-drop down"
        style={{
          backgroundColor: locationPath === "/fullMap" ? "#f7f8fa" : null,
          border: "1px solid #CED2E0",
        }}
      >
        {dataTitle}
      </div>
    </Popover>
  );
};

export default memo(DatePicker);
