import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCompanyListForModal } from "../../Redux/Action/incidents";
import Images from "../../Themes/ImageConstant";
import { openIncidentDetail } from "../../Utils/globalFunction";
import { useCallback, useEffect } from "react";
import {
  checkColor,
  debounce,
  getColorForTable,
  getColorForTableText,
  validCheckColor,
} from "../../Utils";
import { Select, Table, Tooltip } from "antd";
import appconstant from "../../Constants/appConstansts";
import moment from "moment";

const IncidentsListModalTable = (props) => {
  const { payloadState, setPayloadState } = props;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const companiesListForModal = useSelector(
    (state) => state?.incidentReducer?.companiesListForModal
  );
  const countriesListForModal = useSelector(
    (state) => state?.incidentReducer?.countriesListForModal
  );
  const esgChildListForModal = useSelector(
    (state) => state?.incidentReducer?.esgChildListForModal
  );

  const isLoading = useSelector((state) => state?.incidentReducer?.isLoading);

  const onNameClick = (item) => {
    openIncidentDetail(item?.id);
  };

  const handleSort = (e, f, sort) => {
    const sortKey =
      sort.column?.dataIndex == "severity_score"
        ? "severity_score.value"
        : sort.column?.dataIndex;
    setPayloadState((prev) => ({
      ...prev,
      sort: sortKey,
      order: sort.order == "descend" ? -1 : 1,
    }));
  };

  const handleInvolvedCompanyChange = (item, val) => {
    let companyName = val.map((a, k) => {
      if (a.label) return a.label;
    });
    let companies = val.map((a, j) => {
      if (a.value) return a.value;
      else {
        return item[j];
      }
    });
    setPayloadState((prev) => ({ ...prev, companies, companyName }));
  };

  const handleEsgFactorChange = (item, key = "esg_factors") => {
    setPayloadState((prev) => ({ ...prev, [key]: item }));
  };

  const handleCountriesChange = (item) => {
    setPayloadState((prev) => ({ ...prev, locations: item }));
  };

  useEffect(() => {}, [companiesListForModal]);

  const columns = useCallback(
    () => [
      ...(props.tableHeaderArray.includes("title")
        ? [
            {
              title: "Incident Title",
              // title: "Name",
              dataIndex: "title",
              // width: 300,
              className: `company-supplyChain`,
              render: (_, item, index) => (
                <div
                  onClick={() => onNameClick(item)}
                  style={{
                    height: "96px",
                    fontWeight: 600,
                    cursor: "pointer",
                    display: "flex",
                    marginLeft: "14px",
                    // justifyContent:"center"
                    alignItems: "flex-start",
                  }}
                >
                  <span style={{ color: "#9A9CA2" }}>
                    {index + payloadState?.offset + 1}
                  </span>
                  &nbsp;&nbsp;
                  <div className="table-txt-sz">
                    <a>
                      <p>{item.title}</p>
                      <span>
                        <img src={Images.flieIcon} />
                      </span>{" "}
                      <span>
                        <img src={Images.ngo} />
                      </span>
                    </a>
                  </div>
                </div>
              ),
            },
          ]
        : []),
      ...(props.tableHeaderArray.includes("severity_score")
        ? [
            {
              title: "Severity",
              dataIndex: "severity_score",
              className: `company-supplyChain`,
              sorter: () => {},
              ellipsis: true,
              width: 100,
              render: (_, item) => (
                <div
                  style={{
                    height: "92px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor:
                        validCheckColor[item.severity_score.level],
                      width: "35%",
                      height: "32px",
                    }}
                  >
                    <p style={{ color: "white", width: "fit-content" }}>
                      {Math.round(item?.severity_score.value)}
                    </p>
                  </div>
                </div>
              ),
            },
          ]
        : []),
      ...(props.tableHeaderArray.includes("affected_companies")
        ? [
            {
              className: `company-supplyChain`,
              // width: 250,
              title: (
                <div className="select-relation-ps extra-width-ps">
                  <Select
                    onChange={handleInvolvedCompanyChange}
                    onSearch={(search) => {
                      debounce(() => {
                        dispatch(getCompanyListForModal({ search: search }));
                      });
                    }}
                    placeholder={"Involved Companies"}
                    defaultValue={payloadState?.companies?.map((e, i) => {
                      return {
                        value: payloadState?.companies[i],
                        label: payloadState?.companyName[i],
                      };
                    })}
                    filterOption={false}
                    options={companiesListForModal.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    mode="multiple"
                    // style={{ width: "250px" }}
                    //   value={selectedRelation}
                  ></Select>
                </div>
              ),
              dataIndex: "involvedCompanies",
              render: (_, item) => {
                return (
                  <div className="companies-name-ps">
                    {item.affected_companies.map((eachCompany) => {
                      return (
                        <div className="align-property-ps">
                          <span>{eachCompany.name} </span>
                          {/* <span className="hover-trigger">
                            <img src={Images.chartUser} />
                          </span> */}
                          <div className="portfolios-popover-ps">
                            <div className="popover-text-ps">
                              <img src={Images.chartUserSqure} />
                              <div className="place-holder-title-ps">
                                <span>Portfolios:</span>
                                <label>1. My Portfolio 1</label>
                                <label>2. My Portfolio 222 </label>
                                <label>3. My Portfolio 987</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              },
            },
          ]
        : []),
      ...(props.tableHeaderArray.includes("esg_factors")
        ? [
            {
              className: `company-supplyChain`,
              // width: 250,
              title: (
                <div className="select-relation-ps">
                  <Select
                    onChange={(e) => handleEsgFactorChange(e, "esg_factors")}
                    placeholder={"ESG Factors"}
                    filterOption={(inputValue, option) => {
                      return option.label
                        .toLocaleLowerCase()
                        .includes(inputValue.toLowerCase());
                    }}
                    options={esgChildListForModal.esg_factors?.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    mode="multiple"
                    defaultValue={payloadState.esg_factors || []}
                    // style={{ width: "250px" }}
                    //   value={selectedRelation}
                  ></Select>
                </div>
              ),
              dataIndex: "ESGFactors",
              ellipsis: {
                showTitle: false,
              },
              render: (_, item) => {
                return item?.esg_factors?.slice(0, 3)?.map((data) =>
                  data?.length === 0 ? (
                    <p>"N/A</p>
                  ) : (
                    <div className="esg-factors-text-ps">
                      <Tooltip placement="top" title={data?.name}>
                        <p
                          style={{ color: getColorForTableText(data?.parent) }}
                        >
                          <span
                            style={{
                              backgroundColor: getColorForTable(data?.parent),
                              color: getColorForTableText(data?.parent),
                            }}
                          >
                            {data?.parent?.charAt(0)}
                          </span>{" "}
                          {data?.name}
                        </p>
                      </Tooltip>
                    </div>
                  )
                );
              },
            },
          ]
        : []),
      ...(props.tableHeaderArray.includes("esg_sdgs")
        ? [
            {
              className: `company-supplyChain`,
              // width: 250,
              title: (
                <div className="select-relation-ps">
                  <Select
                    onChange={(e) => handleEsgFactorChange(e, "esg_sdgs")}
                    placeholder={"SDG"}
                    filterOption={(inputValue, option) => {
                      return option.label
                        .toLocaleLowerCase()
                        .includes(inputValue.toLowerCase());
                    }}
                    options={esgChildListForModal.esg_sdgs.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    mode="multiple"
                    // style={{ width: "250px" }}
                    //   value={selectedRelation}
                  ></Select>
                </div>
              ),
              dataIndex: "esg_SDG",
              ellipsis: {
                showTitle: false,
              },
              render: (_, item) => {
                return item?.esg_SDG?.slice(0, 3)?.map((data) =>
                  data?.length === 0 ? (
                    <p>"N/A</p>
                  ) : (
                    <div className="esg-factors-text-ps">
                      <Tooltip placement="top" title={data?.name}>
                        <p
                          style={{ color: getColorForTableText(data?.parent) }}
                        >
                          <span
                            style={{
                              backgroundColor: getColorForTable(data?.parent),
                              color: getColorForTableText(data?.parent),
                            }}
                          >
                            {data?.parent?.charAt(0)}
                          </span>{" "}
                          {data?.name}
                        </p>
                      </Tooltip>
                    </div>
                  )
                );
              },
            },
          ]
        : []),
      ...(props.tableHeaderArray.includes("esg_pais")
        ? [
            {
              className: `company-supplyChain`,
              // width: 250,
              title: (
                <div className="select-relation-ps">
                  <Select
                    onChange={(e) => handleEsgFactorChange(e, "esg_pais")}
                    placeholder={"PAI"}
                    filterOption={(inputValue, option) => {
                      return option.label
                        .toLocaleLowerCase()
                        .includes(inputValue.toLowerCase());
                    }}
                    options={esgChildListForModal.esg_pais.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    mode="multiple"
                    // style={{ width: "250px" }}
                    //   value={selectedRelation}
                  ></Select>
                </div>
              ),
              dataIndex: "esg_PAI",
              ellipsis: {
                showTitle: false,
              },
              render: (_, item) => {
                return item?.esg_PAI?.slice(0, 3)?.map((data) =>
                  data?.length === 0 ? (
                    <p>"N/A</p>
                  ) : (
                    <div className="esg-factors-text-ps">
                      <Tooltip placement="top" title={data?.name}>
                        <p
                          style={{ color: getColorForTableText(data?.parent) }}
                        >
                          <span
                            style={{
                              backgroundColor: getColorForTable(data?.parent),
                              color: getColorForTableText(data?.parent),
                            }}
                          >
                            {data?.parent?.charAt(0)}
                          </span>{" "}
                          {data?.name}
                        </p>
                      </Tooltip>
                    </div>
                  )
                );
              },
            },
          ]
        : []),

      ...(props.tableHeaderArray.includes("un_principles")
        ? [
            {
              className: `company-supplyChain`,
              // width: 250,
              title: (
                <div className="select-relation-ps">
                  <Select
                    onChange={(e) => handleEsgFactorChange(e, "un_principles")}
                    placeholder={"UN Principles"}
                    filterOption={(inputValue, option) => {
                      return option.label
                        .toLocaleLowerCase()
                        .includes(inputValue.toLowerCase());
                    }}
                    options={Object.keys(appconstant.principle)?.map((e) => ({
                      label: e,
                      value: e,
                    }))}
                    mode="multiple"
                    // style={{ width: "250px" }}
                    //   value={selectedRelation}
                  ></Select>
                </div>
              ),
              dataIndex: "UNGCP_Breaches",
              ellipsis: {
                showTitle: false,
              },
              render: (_, item) => {
                return item?.UNGCP_Breaches?.slice(0, 3)?.map((data) =>
                  data?.length === 0 ? (
                    <p>"N/A</p>
                  ) : (
                    <div className="esg-factors-text-ps">
                      <Tooltip placement="top" title={data?.name}>
                        <p>{data?.name}</p>
                      </Tooltip>
                    </div>
                  )
                );
              },
            },
          ]
        : []),
      ...(props.tableHeaderArray.includes("locations")
        ? [
            {
              className: `company-supplyChain`,
              width: 250,
              title: (
                <div className="select-relation-ps">
                  <Select
                    onChange={handleCountriesChange}
                    placeholder={"Locations"}
                    options={countriesListForModal.map((e) => ({
                      label: e.name,
                      value: e.name,
                    }))}
                    mode="multiple"
                  ></Select>
                </div>
              ),
              dataIndex: "Locations",
              render: (_, item) => (
                <div
                  style={{
                    height: "92px",
                    display: "flex",
                  }}
                >
                  {item.locations.map((item) => item.name).join(", ")}
                </div>
              ),
            },
          ]
        : []),
      ...(props.tableHeaderArray.includes("discovery_time")
        ? [
            {
              className: `company-supplyChain`,
              width: 100,
              title: "Date",
              dataIndex: "discovery_time",
              sorter: () => {},
              render: (_, item) => (
                <div
                  style={{
                    height: "92px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {moment(item?.discovery_time).fromNow()}
                </div>
              ),
            },
          ]
        : []),
    ],
    [
      props.tableHeaderArray,
      payloadState,
      esgChildListForModal,
      companiesListForModal,
      countriesListForModal,
    ]
  );
  return (
    <Table
      scroll={{
        x: 1200,
        y: 500,
      }}
      onChange={handleSort}
      columns={columns()}
      sortDirections={["ascend", "descend", "ascend"]}
      dataSource={props.incidents?.data || []}
      pagination={false}
      loading={isLoading}
    />
  );
};

export default IncidentsListModalTable;
