import React, { memo } from "react";
import Chart from "react-apexcharts";


let series1 = [
  {
    data: [5, 10, 7, 11, 13, 10, 9, 11, 5],
  },
  {
    data: [5, 8, 5, 9, 7, 5, 5, 7, 5],
  },
];

const LineChart = ({ series, colors }) => {
    var options = {
        chart: {
          stacked: false,
          // hide the x and y axis div acc to me
          sparkline: {
            enabled: true,
          },
          //  hide the background grid or line
          grid: {
            show: false,
          },
          // hide the toolbar (Home zoom etc)
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        // hide the data point value
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.7,
            opacityTo: 0.2,
            stops: [0, 100]
          },
        },
        colors: colors || ["#d00000", "red"],  

      
        stroke: {
          curve: "smooth",
          width: 4,
        },
      
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          // min:0,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
        },
      
        // hide the tooltip
        tooltip: {
          enabled: false,
        },
        // hide Y-axis
        yaxis: {
          show: false,
          // min:0,
          // max:100
        },
        // hide the chat bottom label and color dot
        legend: {
          show: false,
        },
      
      };
      
  return (
    <Chart
      options={options}
      series={series ?? series1}
      type="area"
      height="100"
      width={'100%'}

    />
  );
};

export default memo(LineChart);
