import { useAppSelector } from "../../../redux/configuareStore";
import { getToken } from "../../../../../../Utils";
import useAxios from "axios-hooks";
import MenuSkeleton from "../Skeleton";
import { FilterParametersEnum } from "../../../enums/FilterSideBarEnums";
import SpecificRelations from "./SpecificRelations";
import ExpandVariant from "./ExpandVariant";

function ExpandSubMenu() {
  const rightClickMenu = useAppSelector((state) => state.rightClickMenu);
  const baseUrlApi = useAppSelector((state) => state.api.baseUrlApi);
  const from = useAppSelector((state) => state.timeRange.from);
  const to = useAppSelector((state) => state.timeRange.to);
  const token = getToken();
  const [{ data, loading }] = useAxios({
    url: `${baseUrlApi}/companies/stats`,
    params: {
      ids: rightClickMenu.targetNodeId,
      from,
      to,
    },
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  if (loading) return <MenuSkeleton />;
  if (!data) return null;
  const processedData = data[0].relationsStat;
  const customerCount = processedData?.CUSTOMER?.total;
  const partnerCount = processedData?.PARTNER?.total;
  const supplierCount = processedData?.SUPPLIER?.total;
  const allCount = customerCount + partnerCount + supplierCount;
  return (
    <div>
      <ExpandVariant variant="ALL" count={allCount} />
      <ExpandVariant
        count={supplierCount}
        variant={FilterParametersEnum.SUPPLIER}
      />
      <ExpandVariant
        count={customerCount}
        variant={FilterParametersEnum.CUSTOMER}
      />
      <ExpandVariant
        count={partnerCount}
        variant={FilterParametersEnum.PARTNER}
      />
      <SpecificRelations />
    </div>
  );
}

export default ExpandSubMenu;
