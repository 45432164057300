import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import Images from "../../Themes/ImageConstant";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Tabs,
  Modal,
  Breadcrumb,
  Collapse,
  Row,
  Input,
  Tooltip as ToolTip,
  AutoComplete,
  Progress,
  Spin,
} from "antd";
import { getPortfolioListLoad } from "../../Redux/Action/portfolio";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import {
  BarMapping,
  BarMappingPAI,
  BarMappingSDG,
  HLEA,
  PNN,
  calculatePreviousDate,
  dateButtonObj,
  scrollTo,
  getRiskVal,
  nFormatter,
} from "../../Utils";
import IncidentTable from "../../Componets/IncidentTable";
import {
  getCompaniesAction,
  getDownloadDataAction,
  getIncidentCountLoad,
  incindentAction,
  resetMapData,
} from "../../Redux/Action/incidents";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  getCompaniesDetailsAction,
  sentimentScoreAction,
  supplyChainIncidentAction,
  ResetDataAction,
  getIncidentseverityAction,
  getESGRiskScoreAction,
  getRegulatoryFinesPenaltiesAction,
  getRegulatoryLegalAction,
  getSocialMediaCoveragelAction,
  incidentCategorizationAction,
  ungcpBreachesAction,
  getRiskTrendAction,
  getCompanyPeerRiskAction,
  getCompanyRiskLineAction,
  PeerCompaniesIncidentRate,
} from "../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import {
  columns1,
  companyListColumn,
} from "./companyColumns";

import {
  PeerCompanyIncidentGraph,
  IncidentSeverity,
  IncidentDistributionOverTime,
  WordChart,
  PeerCompaniesIncidentComparison,
} from "./chart";
import SeverityTrend from "./chart/sentimentTrend/ServerityTrend";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import DatePicker from "../../Componets/DatePicker";
import moment from "moment";
import IncidentTimeline from "./chart/IncidentTimeline";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";
import BubleIncidentsList from "../bubleIncidentsList";
import IncidentsByESGCategories from "./chart/IncidentCategorization/IncidentsByESGCategories";
import EChartsReact from "echarts-for-react";
import { ChartCard } from "../../Layouts/ChartCard";
import TimeLine from "./chart/TimeLine";
import {
  sentimentChildObj,
  socialMediaChildObj,
} from "../../Utils/socialMediaChartObj";
import UngcpBreaches from "../dashboardPage/utils-components/ungcpBreaches";
import FullMap from "../../Componets/MapGL/full-map";
import IncidentsListModal from "../IncidentsListModal";
import PrincipleBarChat from "../../Componets/PrincipleBarChat";
import useDebouncedApiCall from "../../hook/useDebouncedSearch";
import { searchCompanyListActionLoad } from "../../Redux/Action/global";
import ImageConst from "../../Themes/ImageConstant";
import PieChartComponent from "../../Screens/searchResult/components/pieChart";
import {
  getCorpCompaniesListLoad,
  getCorpEsgFactorsLoad,
  getCorpIncidentAnalysisLoad,
  getCorpIncidentBarLoad,
  getCorpPaiIndicatorsLoad,
  getCorpRiskChartDataLoad,
  getCorpRiskPieChartLoad,
  getCorpRiskScoreBarLoad,
  getCorpSdgLoad,
  getCorpSupplyChainDetailLoad,
} from "../../Redux/Action/corporateProf";
import LineChart from "../searchResult/components/lineChart";
import IncidentAnalysisComp from "./incidentAnalysis";
import SocialNetworkTrend from "./chart/socialNetworkTrend/SocialNetworkTrend";
import SDGBarChat from "../../Componets/PrincipleBarChat/SDG";
import PAIBarChat from "../../Componets/PrincipleBarChat/PAI";
import { getBreachesViolationDataLoad } from "../../Redux/Action/incidentAnalyzerAction";
import SunChart from "../dashboardPage/chart/SunChart";
import CompaniesTable from "./companiesTable";
import { getProfileAction } from "../../Redux/Action/accoutSettngAction";
import SearchDocEmptyDpc from "../../Componets/searchDocEmptyCom";
import GlobalLoder from "../../Utils/GlobalLoder";
import CompaniesListModalWithAllData from "../CompaniesListModal";
import RegulatoryFinesPenaltiesCard from "../dashboardPage/utils-components/regulatoryFines&PenaltiesCard";
import PolarChart from "./chart/PolarChart/PolarChart";
import PolarChartESG from "./chart/PolarChartESG/PolarChartESG";
import RegulatoryLegalActionsCard from "../dashboardPage/utils-components/regulatoryLegalActionsCard";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import ReactCountryFlag from "react-country-flag";
import { highlightMatch } from "../../Utils/globalFunction";
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';

// Register locales with correct imports
countries.registerLocale(enLocale);
countries.registerLocale(frLocale);
let lastSearchString = "";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const lastDate = dateButtonObj[3].type;
let networkData = { ...topHeaderAPIObject };


const getActiveItem = (number) => {
  console.log(number, "Severity Analysis");
  if (number == 0 || (number > 0 && number < 2152)) {
    return "Incidents";
  } else if (number >= 2153 && number <= 3885) {
    return "IncidentsAnalysis";
  } else if (number >= 3886 && number <= 4700) {
    return "severityAnalysis";
  } else if (number >= 4701 && number < 6700) {
    return "SocialMediaCoverage";
  } else if (number >= 6701 && number <= 8000) {
    return "PeerComparison";
  } else if (number >= 8001 && number <= 9000) {
    return "CarbonFootprint";
  } else if (number >= 9001 && number <= 10000) {
    return "UNPrinciple";
  } else if (number >= 10001 && number <= 15400) {
    return "Regulatory&LegalAction";
  }
};

const getActiveItemTab2 = (number) => {
  console.log(number, "activeTabactiveTabactiveTab");
  if (number == 0 || (number > 0 && number < 2151)) {
    return "RiskAnalysis";
  } else if (number >= 2152 && number <= 2700) {
    return "TopHighRisks";
  } else if (number >= 2701 && number <= 4850) {
    return "IncidentsAnalysis";
  } else if (number >= 4851 && number < 7559) {
    return "UNPrinciple";
  }
};

const legend = [
  {
    name: "X corp.",
    image: Images.XCROP,
  },
  {
    name: "Reddit",
    image: Images.Raddit,
  },
];

let countryDataObj = {
  AF: "Africa",
  AN: "Antarctica",
  AS: "Asia",
  EU: "Europe",
  NA: "North America",
  OC: "Oceania",
  SA: "South America",
};

const CorporateProfile = () => {
  const [id, setId] = useState(null);

  useEffect(() => {
    dispatch(getProfileAction());
  }, []);

  const { userCompId, isLoading, nameComp } = useSelector(
    (state) => state?.accountSettingReducer
  );
  const [activeUngcTab, setActiveUngcTab] = useState("0");

  const [headerSmallState, setHeaderSmallState] = useState(false);
  const [activeItem, setActiveItem] = useState("Incidents");
  const [selectedType, setSelectedType] = useState("risk")
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const location = useLocation();
  const [potfolioModel, setPotfolioModel] = useState(false);
  const [tabValForIncidentBars, setTabValForIncidentBars] =
    useState("Supplier");
  const [companiesRelationType, setCompaniesRelationType] =
    useState("Supplier");


  const navigate = useNavigate();
  const companyData = useSelector(
    (state) => state?.companyPageReducer?.getCompanyDetails
  );
  let { totalEsgIncidentCount } = useSelector(
    (state) => state?.incidentReducer
  );
  const {
    continentData,
    serverityMap,
    ESGRiskScoreList,
    getRegulatoryFinesPenaltiesList,
    regulatoryLegalActionsData,
    socialMediaCoverage,
    incidentCategorization,
    ungcpBreaches,
    riskTrendData,
    peerRiskScore,
    riskLineChart,
  } = useSelector((state) => state?.companyPageReducer);
  let update = continentData.map((val) =>
    countryDataObj[val?.continent_code]
      ? { ...val, continent_code: countryDataObj[val?.continent_code] }
      : { ...val }
  );
  const portfolioList = useSelector((state) => state?.portfolio?.portfolioList);

  const sentimentObj = sentimentChildObj(socialMediaCoverage?.sentiment);
  const socialObj = socialMediaChildObj(socialMediaCoverage?.socialMedia);
  const { incident } = useSelector(
    (state) => state?.incidentReducer
  );
  const {
    supplyChainData,
    riskLineChartData,
    riskPieChartData,
    incidentBarData,
    riskScoreBarData,
    incidentAnalysisData,
    companiesList,
  } = useSelector((state) => state.corporateProfile);

  const [timelineIndidentList, setTimelineIndidentList] = useState(false);
  const [incidentIdArray, setIncidentIdArray] = useState([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [date, setDate] = useState(false);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [legalOrder, setLegalOrder] = useState(true);
  const [order, setOrder] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [breachesViolationLimit, setBreachesViolationLimit] = useState(10);
  const [breachesViolationPaiLimit, setBreachesViolationPaiLimit] =
    useState(10);
  const [breachesViolationSdgLimit, setBreachesViolationSdgLimit] =
    useState(10);
  const [modalVisibleCompList, setModalVisibleCompList] = useState(false);
  const [tabKey, setTabKey] = useState("1");
  const [tabVal, setTabVal] = useState("Environment");

  const { breachesAndViolationData, breachesAndViolationDataLoader } =
    useSelector((state) => state.incidentAnalyzerReducer);
  const [activeTab, setActiveTab] = useState("1");
  const { peerCompaniesRate } = useSelector(
    (state) => state?.companyPageReducer
  );

  useLayoutEffect(() => {
    if (userCompId) {
      dispatch(
        getIncidentCountLoad({ ...networkData, companies: [userCompId] })
      );
    }
  }, [userCompId]);

  useEffect(() => {
    if (Object.entries(totalEsgIncidentCount).length > 0) {
      if (totalEsgIncidentCount?.Environmental > 0) {
        setTabKey("1");
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social > 0
      ) {
        setTabKey("2");
      } else if (
        totalEsgIncidentCount?.Environmental == 0 &&
        totalEsgIncidentCount?.Social == 0 &&
        totalEsgIncidentCount?.Governance > 0
      ) {
        setTabKey("3");
      } else {
        setTabKey("1");
      }
    }
  }, [totalEsgIncidentCount, activeTab]);

  useEffect(() => {
    dispatch(
      incindentAction({
        ...networkData,
        companies: [id],
        sort: "discovery_time",
        order: -1,
        supplyChain: activeTab == "1" ? false : true,
        esg_categories: [
          tabKey == "1"
            ? "Environment"
            : tabKey == "2"
              ? "Social"
              : "Governance",
        ],
      })
    );
  }, [tabKey, activeTab]);

  useEffect(() => {
    dispatch(getPortfolioListLoad());
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setHeaderSmallState(true);
      }
      if (scrollTop < 100) {
        setHeaderSmallState(false);
      }
      const activeTb =
        activeTab == "1"
          ? getActiveItem(scrollTop)
          : getActiveItemTab2(scrollTop);
      console.log(activeTab, "activeTab", activeTb);

      setActiveItem(activeTb);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    scrollTo(0.0);
    if (userCompId) {
      setId(userCompId);
      networkData["end"] = moment().format("YYYY-MM-DD");
      networkData["start"] = moment(
        calculatePreviousDate(new Date(), lastDate)
      ).format("YYYY-MM-DD");
      networkCall(networkData, userCompId);
      dispatch(getCompaniesAction());
    }
    return () => {
      dispatch(ResetDataAction());
    };
  }, [location, userCompId]);

  const networkCall = (data, id) => {
    dispatch(getCompaniesDetailsAction({ ...data, id, supplyChain: activeTab == "1" ? false : true, }));
    dispatch(getRiskTrendAction({ ...data, id }));
    dispatch(getCompanyRiskLineAction({ ...data, id }));
    dispatch(getCompanyPeerRiskAction({ ...data, id }));
    dispatch(PeerCompaniesIncidentRate({ ...data, id }))
    dispatch(
      getBreachesViolationDataLoad({
        ...data,
        companies: [id],
        limit:
          activeUngcTab == 1
            ? breachesViolationLimit
            : activeUngcTab == 3
              ? breachesViolationPaiLimit
              : activeUngcTab == 4
                ? breachesViolationSdgLimit
                : breachesViolationLimit,
        supplyChain: activeTab == "1" ? false : true,
        type:
          activeUngcTab == 1
            ? "esg_supply_chain_act"
            : activeUngcTab == 0
              ? "german_supply_chain_act"
              : activeUngcTab == 3
                ? "esg_SDG"
                : "esg_PAI",
      })
    );
    dispatch(
      incindentAction({
        ...data,
        companies: [id],
        sort: "discovery_time",
        supplyChain: activeTab == "1" ? false : true,
        order: -1,
        esg_categories: ["Environmental"],
      })
    );
    dispatch(supplyChainIncidentAction({ ...data, id, limit: 4 }));
    dispatch(sentimentScoreAction({ ...data, id }));
    dispatch(getIncidentseverityAction({ ...data, id }));
    //New
    dispatch(getESGRiskScoreAction({ ...data, id }));
    dispatch(getRegulatoryFinesPenaltiesAction({ ...data, id }));
    dispatch(getRegulatoryLegalAction({ ...data, id }));
    dispatch(getSocialMediaCoveragelAction({ ...data, id }));
    dispatch(incidentCategorizationAction({ ...data, id }));
    dispatch(ungcpBreachesAction({
      ...data, id,
      type: activeTab == "1" ? false : true
    }));
    //supply chain
    dispatch(getCorpSupplyChainDetailLoad({ ...data, companyId: id }));
    dispatch(getCorpRiskChartDataLoad({ ...data, companyId: id }));
    dispatch(getCorpRiskPieChartLoad({ ...data, companyId: id }));
    dispatch(
      getCorpIncidentBarLoad({ ...data, companyId: id, type: "Supplier" })
    );
    dispatch(getCorpRiskScoreBarLoad({ ...data, companyId: id, }));
    dispatch(getCorpIncidentAnalysisLoad({ ...data, companyId: id }));
    dispatch(getCorpEsgFactorsLoad({ ...data, companies: [id] }));
    dispatch(getCorpSdgLoad({ ...data, companies: [id] }));
    dispatch(getCorpPaiIndicatorsLoad({ ...data, companies: [id] }));
    dispatch(
      getCorpCompaniesListLoad({
        ...data,
        companyId: id,
        relation: [companiesRelationType],
      })
    );
  };

  useEffect(() => {
    console.log(activeUngcTab, activeTab, tabKey, "activeUngcTab");
    if (id) {
      if (activeUngcTab != 2) {
        dispatch(
          getBreachesViolationDataLoad({
            ...networkData,
            companies: [id],
            limit:
              activeUngcTab == 1
                ? breachesViolationLimit
                : activeUngcTab == 3
                  ? breachesViolationPaiLimit
                  : activeUngcTab == 4
                    ? breachesViolationSdgLimit
                    : breachesViolationLimit,
            type:
              activeUngcTab == 1
                ? "esg_supply_chain_act"
                : activeUngcTab == 0
                  ? "german_supply_chain_act"
                  : activeUngcTab == 4
                    ? "esg_SDG"
                    : "esg_PAI",
            supplyChain: activeTab == "1" ? false : true
          })
        );
      } else {
        dispatch(ungcpBreachesAction({ ...networkData, id, type: activeTab == "1" ? false : true }));
      }
    }

  },
    [
      activeUngcTab,
      breachesViolationLimit,
      breachesViolationPaiLimit,
      breachesViolationSdgLimit,
    ]
  );

  const incidentTabChange = (type) => {
    dispatch(getCorpIncidentBarLoad({ ...networkData, companyId: id, type }));
  };

  const createCSeriesData = (data, groupType) => {

    const finalObj = {
      data: [],
      label: [],
    };
    try {
      let a = Object.keys(data).values && Object.keys(data);
      a.map((i, j) => {
        finalObj.data = Object.values(data).map((i) =>
          i.toFixed(2)
        );
        finalObj.label = a.map((i) => {
          let dataArr = i

          return moment(dataArr).format("DD MMM YY HH:mm")
        })
        finalObj.allLabel = a.map((i) => {
          let start_Date = moment(i).format("DD MMM YY HH:mm")
          return start_Date
        });
      });
      return { ...finalObj };
    } catch (e) { }
  };
  const seriesData = useCallback(
    () => createCSeriesData(riskTrendData.Risk, riskTrendData.groupType),
    [riskTrendData]
  );
  const data = riskTrendData && seriesData();
  const onDatePickerChange = (startDate, previousDate) => {
    if (startDate && previousDate) {
      networkData.start = startDate;
      networkData.end = previousDate;
      networkCall({ start: startDate, end: previousDate }, userCompId);
      setDate(!date);
    }
  };

  const callDownloadApi = () => {
    dispatch(getDownloadDataAction({ id, start: networkData.start, end: networkData.end }));
  };

  const linesOption =
  {
    color: ["#e74c3c"],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      },
      formatter: function (params) {
        return `
        <div style="width:160px;margin:5px; border-radius:5px;">
            <div style="font-weight:bold;font-size:13px;color:#000; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            ${data.allLabel[params[0].dataIndex]}
            </div>
            <div style="margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            <div class="tooltip-item" >${params[0].seriesName}: ${params[0].data}</div>
            </div>
        </div>
          `;
      },
    },
    toolbox: {
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: data?.label
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    dataZoom: [
      {
        type: "slider", // This is a slider at the bottom
        zoomOnMouseWheel: "ctrl", // Requires the Ctrl key to be pressed to zoom with the mouse wheel
      },
      {
        type: "inside", // This enables zooming and panning with mouse actions
        zoomOnMouseWheel: "ctrl", // Also requires the Ctrl key for the slider, for consistency
      },
    ],
    series: [
      {
        name: 'Risk Score',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        label: {
          show: true,
          position: 'top'
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#e74c3c"
            },
            {
              offset: 1,
              color: "#e74c3c"
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: data?.data ? data?.data : [],
      },

    ]
  };

  const totalCount = (arr) => {
    let totalCount = 0;
    totalCount = arr?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.incidents.length;
    }, 0);

    return totalCount;
  };

  const calculateRisk = (data, key) => {
    // Extracting the company risks from the last two entries
    const lastCompanyRisk1 =
      data.length > 1
        ? data[data.length - 2]?.risk || data[data.length - 2]?.companyRisk
        : 0;
    const lastCompanyRisk2 =
      data.length > 0
        ? data[data.length - 1]?.risk || data[data.length - 1]?.companyRisk
        : 0;
    // Calculating the percentage change in risk
    const riskPercentageChange =
      ((lastCompanyRisk2 - lastCompanyRisk1) /
        (lastCompanyRisk1 > 0 ? lastCompanyRisk1 : 1)) *
      100;
    return riskPercentageChange.toFixed(2);
  };

  const series = (chart_Data) => [
    {
      data: chart_Data.map((entry) => entry?.risk || entry?.companyRisk),
    },
  ];


  const ChartComponent = ({ data, title, count }) => {
    if (data) {
      let value = calculateRisk(data);
      return (
        <div className="supply-chain-risk-grph-ps">
          <h5>{title}</h5>
          <div className={`today-per-ps ${value < 0 && "green-ps-map"}`}>
            <p>Today</p>
            {data && (
              <>
                <span>
                  {value != 0 && (
                    <img
                      src={
                        value < 0 ? Images.greenArrowSumUp : Images.arrowSumUp
                      }
                    />
                  )}
                  {Math.abs(value || 0) + "%"}
                </span>
                <h6>
                  {data
                    ? data[data.length - 1]?.risk?.toFixed(2) ||
                    data[data.length - 1]?.companyRisk?.toFixed(2)
                    : 0}
                </h6>
              </>
            )}{" "}
          </div>
          <div className="apple-map-supply">
            <LineChart
              data={"chartData"}
              colors={value < 0 ? ["#1A9C3E", "green"] : ["#d00000", "red"]}
              series={series(data)}
            />
          </div>
        </div>
      );
    }
  };

  const ChartComponentNew = ({ data, title, count }) => {
    if (data.details) {
      let value = data.riskDetails.risk_change
      return (
        <div className="supply-chain-risk-grph-ps">
          <h5>{title}</h5>
          <div className={`today-per-ps ${value < 0 && "green-ps-map"}`}>
            <p>Today</p>
            {data && (
              <>
                <span>
                  {value != 0 && (
                    <img
                      src={
                        value < 0 ? Images.greenArrowSumUp : Images.arrowSumUp
                      }
                    />
                  )}
                  {value && Math.abs(value.toFixed(2) || 0) + "%"}
                </span>
                <h6>
                  {data?.riskDetails?.risk && data.riskDetails?.risk.toFixed(2)}
                </h6>
              </>
            )}{" "}
          </div>
          <div className="apple-map-supply">
            <LineChart
              data={"chartData"}
              colors={value < 0 ? ["#1A9C3E", "green"] : ["#d00000", "red"]}
              series={series(data.details)}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {id ? (
        <div className="side-padding aaa ">
          <div
            className="dashbored-head"
            style={headerSmallState ? { height: "186px" } : {}}
          >
            <BreadcrumbComponent companyName={companyData?.name} />
          </div>
          <div
            className=" top-space"
            style={headerSmallState ? { marginTop: "223px" } : {}}
          >
            <div className="container mt-3">
              <div className="apple-logo complete-pro-logo">
                <div className="apple-image">
                  {(companyData?.id || userCompId) && (
                    <img
                      style={{ height: 128, width: 128, objectFit: "contain" }}
                      src={`https://logos-svg.komo.systems/${companyData?.id || userCompId}.png`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = ImageConst.Apple;
                      }}
                    />
                  )}
                </div>
                <div className="page-title">
                  <div className="add-header-btn">
                    <h5>{companyData?.name || nameComp}</h5>
                  </div>
                  <p>{companyData?.tickers && companyData?.tickers[0]}</p>
                </div>
              </div>

              <div className="corporate-pof-tab-fixed-ps">
                <Tabs
                  onChange={(e) => {
                    setActiveTab(e);
                    setActiveItem(e == "1" ? "Incidents" : "RiskAnalysis");
                    setTabKey("1");
                    dispatch(
                      getCompaniesDetailsAction({
                        ...networkData,
                        id,
                        supplyChain: e == "1" ? false : true,
                      })
                    );
                    dispatch(
                      getIncidentCountLoad({
                        ...networkData,
                        companies: [userCompId],
                        supplyChain: e == "1" ? false : true,
                      })
                    );
                    dispatch(ungcpBreachesAction({ ...networkData, id, type: e == "1" ? false : true }));

                    dispatch(
                      getBreachesViolationDataLoad({
                        ...networkData,
                        companies: [id],
                        limit:
                          activeUngcTab == 1
                            ? breachesViolationLimit
                            : activeUngcTab == 3
                              ? breachesViolationPaiLimit
                              : activeUngcTab == 4
                                ? breachesViolationSdgLimit
                                : breachesViolationLimit,
                        supplyChain: e == "1" ? false : true,
                        type:
                          activeUngcTab == 1
                            ? "esg_supply_chain_act"
                            : activeUngcTab == 0
                              ? "german_supply_chain_act"
                              : activeUngcTab == 3
                                ? "esg_SDG"
                                : "esg_PAI",
                      })
                    );
                  }}
                  activeKey={activeTab}
                  className="tab-section-ps fixed-tab-sec"
                >
                  <Tabs.TabPane key="1" tab={"Corporate Details"}>
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-lg-3  containerMidWidth ">
                        <div className="hone-left-side1">
                          <div className="home-left-list ">
                            <ul>
                              <li
                                className={
                                  activeItem == "Incidents" ? "tab-active" : ""
                                }
                              >
                                <a onClick={() => scrollTo(0)}>
                                  1 Risk Analysis{" "}
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "IncidentsAnalysis"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(2153)}>
                                  2 Incidents Analysis{" "}
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "severityAnalysis"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(3886)}>
                                  3 Severity Analysis{" "}
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "SocialMediaCoverage"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(4701)}>
                                  {/* // <a onClick={() => document.getElementById("ESGCAT").scrollIntoView()}> */}
                                  4 ESG Categories
                                </a>
                              </li>

                              <li
                                className={
                                  activeItem == "PeerComparison"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(6701)}>
                                  5 Peer Companies{" "}
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "CarbonFootprint"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(8001)}>
                                  6 Social Media Coverage
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "UNPrinciple"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(9001)}>
                                  7 Breaches & violation
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "Regulatory&LegalAction"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(10001)}>
                                  8 Regulatory & Legal Actions
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 containerWidth ml-32 scrolling-corporate-page">
                        <section>
                          <div className="containerWidth g-0">
                            <div className="row  mt-2">
                              <div className={`top-right-home-drop`}>
                                <div>
                                  {/* <a href=""> */}
                                  <img
                                    onClick={() => {
                                      callDownloadApi();
                                    }}
                                    src={Images.framedow}
                                  />
                                  {/* </a> */}
                                </div>
                                {/* social  share button  model MM  */}
                                <Modal
                                  title={"Share"}
                                  centered
                                  className="share"
                                  open={isModalOpen}
                                  footer={false}
                                  onCancel={() => setIsModalOpen(false)}
                                  mask
                                >
                                  <div style={{ display: "flex", gap: "22px" }}>
                                    <FacebookShareButton
                                      url={"www.google.com"}
                                      quote={"asasas"}
                                    >
                                      <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>

                                    <LinkedinShareButton
                                      url={"www.google.com"}
                                      title={"asasas"}
                                    >
                                      <LinkedinIcon size={32} round={true} />
                                    </LinkedinShareButton>

                                    <TwitterShareButton
                                      url={"www.google.com"}
                                      title={"asasasa"}
                                    >
                                      <XIcon size={32} round={true} />
                                    </TwitterShareButton>
                                  </div>
                                </Modal>
                                <DatePicker
                                  onDatePickerChange={onDatePickerChange}
                                  lastDate={lastDate}
                                />
                              </div>
                            </div>
                          </div>
                        </section>

                        <div className="collapse-section-ps containerWidth">
                          <Collapse
                            defaultActiveKey={[
                              "1",
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                            ]}
                            className="company-detail-collapse"
                            expandIcon={({ isActive }) => {
                              return (
                                <img
                                  src={Images.iconDown}
                                  style={{
                                    transform: `rotate(${isActive ? "0" : "-90"}deg)`,
                                  }}
                                />
                              );
                            }}
                          >
                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    1
                                  </span>
                                  Risk Analysis
                                </h4>
                              }
                              key="1"
                            >
                              <section>
                                <div className="risk-per-comp   ">
                                  <div className="row">
                                    <div className="">
                                      <ChartComponentNew
                                        data={riskLineChart}
                                        title={"Company ESG Risk"}
                                        count={12}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section>
                                <div className="inc-map-pre">
                                  <div className="tab-top">
                                    <div className="left-text">
                                      <Row>
                                        <span className="subtitle1">
                                          {" "}
                                          ESG Risk Score
                                        </span>
                                        <img
                                          className="hover-page"
                                          style={{ marginLeft: 5 }}
                                          src={Images.circleInfo}
                                          alt=""
                                        />
                                      </Row>
                                    </div>
                                    <div
                                      className="righ-side"

                                    // onClick={() => navigate("/fullMap")}
                                    ></div>
                                  </div>
                                  <div className="sun-chart-container">
                                    <SunChart
                                      networkData={networkData}
                                      Id={id}
                                      companyName={[companyData?.name]}
                                      risk={ESGRiskScoreList?.risk}
                                      graphData={
                                        ESGRiskScoreList.data?.length > 0
                                          ? ESGRiskScoreList.data
                                          : []
                                      }
                                      id={"companyChartSvgContainer"}
                                      environment={
                                        ESGRiskScoreList.environmental
                                      }
                                      social={ESGRiskScoreList.social}
                                      governance={ESGRiskScoreList.governance}
                                    />
                                  </div>
                                </div>
                              </section>
                              <section>
                                <div className="mt-32">
                                  <div className="container esg-risk-inner-cont">
                                    <div className="esg-heading mb-3">
                                      <div className="map-change-buttons-ps">
                                        <div className="text-esg-m">
                                          <h5>
                                            Company vs. Peer companies Risk score
                                            <sapn>
                                              <img
                                                src={Images.circleInfo}
                                                className="info-icon-company-page"
                                              />
                                            </sapn>
                                          </h5>
                                          <div className="numbers-of-button mt-3">
                                            <button style={{ color: selectedType == "risk" ? "#0195ce" : "#000", borderColor: selectedType == "risk" ? "#0195ce" : "#000" }} onClick={() => setSelectedType("risk")}>Risk</button>
                                            <button style={{ color: selectedType == "ESG" ? "#0195ce" : "#000", borderColor: selectedType == "ESG" ? "#0195ce" : "#000" }} onClick={() => setSelectedType("ESG")}>ESG</button>
                                            <button style={{ color: selectedType == "SEVERITY" ? "#0195ce" : "#000", borderColor: selectedType == "SEVERITY" ? "#0195ce" : "#000" }} onClick={() => setSelectedType("SEVERITY")}>Severity</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {selectedType == "risk"
                                      ? <PolarChart data={peerRiskScore} />
                                      : selectedType == "ESG" ?
                                        <PolarChartESG data={peerCompaniesRate} chart={"ESG"} />
                                        : <PolarChartESG data={peerCompaniesRate} chart={"SEVERITY"} />
                                    }

                                  </div>
                                </div>
                              </section>
                              <section>
                                <div className="inc-map-pre mt-32">
                                  <div className="tab-top backgroundColor">
                                    <div className="left-text">
                                      <Row>
                                        <span className="subtitle1">
                                          {" "}
                                          Risk Trend
                                        </span>
                                        <img
                                          className="hover-page"
                                          style={{ marginLeft: 5 }}
                                          src={Images.circleInfo}
                                          alt=""
                                        />
                                      </Row>
                                    </div>
                                  </div>

                                  <div className="line-chart">
                                    <ReactECharts
                                      echarts={echarts}
                                      option={linesOption}
                                      notMerge={true}
                                      lazyUpdate={true}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                </div>
                              </section>
                            </Collapse.Panel>
                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    2
                                  </span>
                                  Incident Analysis
                                </h4>
                              }
                              key="2"
                            >
                              <section>
                                <div className="containerWidth g-0 mb-4">
                                  <div className="row">
                                    <div className="col ">
                                      <div className="inciden-box">
                                        <div
                                          className="total-box"
                                          onClick={() => setModalVisible(true)}
                                        >
                                          <h5>All Incidents</h5>
                                          <h1>{nFormatter(companyData?.totalIncidents)}</h1>
                                        </div>

                                        <div
                                          className="new-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              esg_categories: ["Environmental"],
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                        >
                                          <img src={Images.globeblue} />
                                          <p>Environmental</p>
                                          <h5>
                                            {nFormatter(companyData?.environmentIncidents)}
                                          </h5>
                                        </div>

                                        <div
                                          className="new-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              esg_categories: ["Social"],
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                        >
                                          <img src={Images.socialblue} />
                                          <p>Social</p>
                                          <h5>
                                            {nFormatter(companyData?.socialIncidents)}
                                          </h5>
                                        </div>

                                        <div
                                          className="new-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              esg_categories: ["Governance"],
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                        >
                                          <img src={Images.bankblue} />
                                          <p>Governance</p>
                                          <h5>
                                            {nFormatter(companyData?.governanceIncidents)}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col mt-3">
                                      <div
                                        className="seve-main-boxes"
                                        style={{ gap: "16px" }}
                                      >
                                        <div
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              showSever: true,
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                          className="fir-box"
                                        >
                                          <img src={Images.circle} />
                                          <p>
                                            Severe
                                            <span>
                                              <img
                                                className="hover-page"
                                                src={Images.circleInfo}
                                              />
                                              <div className="infohover-home">
                                                <p>
                                                  We use AI to determine the
                                                  severity of an incident based
                                                  on its content. However, since
                                                  it is often a subjective
                                                  matter, this description
                                                  should not be solely relied
                                                  on. It is intended as a
                                                  helpful clue to assist users.
                                                </p>
                                              </div>
                                            </span>
                                          </p>

                                          <h5 className="gap">
                                            {nFormatter(companyData?.highSeverIncidents)}
                                          </h5>
                                        </div>

                                        <div
                                          className="fir-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              start: moment()
                                                .subtract(30, "day")
                                                .format("YYYY-MM-DD"),
                                              end: moment().format(
                                                "YYYY-MM-DD"
                                              ),
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                        >
                                          <img src={Images.clockGreen} />
                                          <p>Recent</p>
                                          <p>(last month)</p>
                                          <h5>{nFormatter(companyData?.lastMonth)}</h5>
                                        </div>
                                        <div
                                          className="fir-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              start: moment()
                                                .subtract(2, "day")
                                                .format("YYYY-MM-DD"),
                                              end: moment().format(
                                                "YYYY-MM-DD"
                                              ),
                                            });
                                          }}
                                        >
                                          <img src={Images.felame} />
                                          <p>New</p>
                                          <p>(last 2 days)</p>
                                          <h5>{nFormatter(companyData?.lastTwoDays)}</h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <IncidentTable
                                id={companyData?.id}
                                companyName={[companyData?.name]}
                                data={incident?.data?.slice(0, 4)}
                                columns={columns1}
                                date={{
                                  ...networkData,
                                  supplyChain: activeTab == "1" ? false : true,
                                }}
                                goTo={"/companyPage"}
                                title={
                                  <h4>
                                    Incidents List
                                    <img
                                      src={Images.circleInfo}
                                      className="info-icon-company-page"
                                    />
                                  </h4>
                                }
                                tabKey={[tabKey, setTabKey]}
                                tabVal={[tabVal, setTabVal]}
                                tabShowCount={{
                                  Environmental: 5,
                                  Governance: 2,
                                  Social: 5,
                                }}
                              />

                              <IncidentTimeline
                                incidentListHandler={(data) => {
                                  setTimelineIndidentList(data.modalState);
                                  setIncidentIdArray(data.listData);
                                }}
                                {...networkData}
                                id={id}
                                dateChange={date}
                              />
                              {/* <IncidentDistributionTotal
                                {...networkData}
                                id={id}
                                dateChange={date}
                              /> */}
                              <IncidentDistributionOverTime
                                {...networkData}
                                id={id}
                                dateChange={date}
                              />
                            </Collapse.Panel>

                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    3
                                  </span>
                                  Severity Analysis
                                </h4>
                              }
                              key="3"
                            >
                              <IncidentSeverity
                                {...networkData}
                                id={id}
                                dateChange={date}
                              />
                              {serverityMap.length > 0 && (
                                <section>
                                  <div className="inc-map-pre mt-4 containerWidth">
                                    <div className="tab-top">
                                      <div className="left-text">
                                        <Row>
                                          <h4>Incidents severity Map</h4>
                                          <img
                                            className="hover-page"
                                            style={{ marginLeft: 5 }}
                                            src={Images.circleInfo}
                                            alt=""
                                          />
                                        </Row>
                                      </div>
                                      <div
                                        className="righ-side"
                                        onClick={() => navigate("/fullMap")}
                                      >
                                        <a>
                                          View Full Map
                                          <span>
                                            <img src={Images.iconser} />
                                          </span>
                                        </a>
                                      </div>
                                    </div>

                                    <div className="container">
                                      <div className="row">
                                        <div
                                          className="col-10 mt-3 mb-3"
                                          style={{
                                            position: "relative",
                                            height: "326px",
                                          }}
                                        >
                                          <div className="map-pre">
                                            <div
                                              style={{
                                                left: "1vw",
                                                opacity: 0.9,
                                                bottom: "6px",
                                                padding: ".6vw",
                                                position: "absolute",
                                                display: "flex",
                                                zIndex: "1",
                                                backgroundColor: "#ffffff",
                                                alignItems: "center",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  marginBottom: ".4vw",
                                                  fontSize: "1rem",
                                                }}
                                              >
                                                Severity
                                              </span>
                                              <div>
                                                <MapItem
                                                  color={"#800020"}
                                                  name={"Critical"}
                                                />
                                                <MapItem
                                                  color={"#B70000"}
                                                  name={"High"}
                                                />
                                                <MapItem
                                                  color={"#F28C28"}
                                                  name={"Moderate"}
                                                />
                                                <MapItem
                                                  color={"#FFD700"}
                                                  name={"Minor"}
                                                />
                                              </div>
                                            </div>
                                            <FullMap
                                              width={"600px"}
                                              height={"326px"}
                                              zoom={0}
                                              data={serverityMap || []}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-2">
                                          <div
                                            className="asia-cont"
                                            style={{
                                              minWidth: "100px",
                                            }}
                                          >
                                            {update?.map((item, index) => {
                                              return (
                                                <div className="africa-co">
                                                  <h5>{item.continent_code}</h5>
                                                  <p>{item.incidents || 0}</p>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                              <SeverityTrend
                                {...networkData}
                                id={id}
                                dateChange={date}
                              />
                            </Collapse.Panel>

                            <Collapse.Panel
                              id="ESGCAT"
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    4
                                  </span>
                                  ESG Categories
                                </h4>
                              }
                              key="4"
                            >
                              <IncidentsByESGCategories
                                data={incidentCategorization}
                              />
                              <WordChart
                                {...networkData}
                                id={id}
                                dateChange={date}
                              />
                            </Collapse.Panel>

                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    5
                                  </span>
                                  Peer Companies
                                </h4>
                              }
                              key="5"
                            >
                              <PeerCompaniesIncidentComparison
                                {...networkData}
                                id={id}
                                dateChange={date}
                                companyName={companyData?.name}
                              />
                              <PeerCompanyIncidentGraph
                                {...networkData}
                                id={id}
                                dateChange={date}
                              />
                            </Collapse.Panel>

                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    6
                                  </span>
                                  Social Media Coverage
                                </h4>
                              }
                              key="6"
                            >
                              <section>
                                <ChartCard
                                  showTimeBarOnTop={false}
                                  title={"Social Media Coverage"}
                                  legend={PNN}
                                >
                                  <div className="line-chart">
                                    <Row>
                                      <div className="col-sm-6 socialChart">
                                        <span className="captionXl">
                                          Social Media Sentiment Distribution
                                        </span>
                                        <EChartsReact
                                          className="doubleLayer-pieChart"
                                          option={{
                                            silent: false,
                                            series: [
                                              {
                                                label: {
                                                  color: "#fff",
                                                  fontSize: "18",
                                                  textBorderColor: "#fff",
                                                },
                                                itemStyle: {
                                                  borderRadius: 10,
                                                  borderWidth: 5,
                                                },
                                                radius: ["0", "100%"],
                                                type: "sunburst",
                                                emphasis: {
                                                  focus: "ancestor",
                                                },
                                                data: [
                                                  {
                                                    children:
                                                      sentimentObj?.tweets,
                                                    name: "Tweets",
                                                    value: 30,
                                                    itemStyle: {
                                                      color: {
                                                        colorStops: [
                                                          {
                                                            offset: 0,
                                                            color: "#1DA1F2",
                                                          },
                                                          {
                                                            offset: 1,
                                                            color:
                                                              "rgba(135, 207, 255, 1)",
                                                          },
                                                        ],
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1.5,
                                                        type: "linear",
                                                        global: false,
                                                      },
                                                    },
                                                  },

                                                  {
                                                    children:
                                                      sentimentObj?.reddit_posts,
                                                    name: "Reddit",
                                                    value: 30,
                                                    itemStyle: {
                                                      color: {
                                                        colorStops: [
                                                          {
                                                            offset: 0,
                                                            color: "#FF4500",
                                                          },
                                                          {
                                                            offset: 1,
                                                            color:
                                                              "rgba(255, 171, 140, 1)",
                                                          },
                                                        ],
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1.5,
                                                        type: "linear",
                                                        global: false,
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          }}
                                        />
                                      </div>
                                      <div className="col-sm-6  socialChart">
                                        <span className="captionXl">
                                          Sentiment Distribution by Social Media
                                          Platforms
                                        </span>
                                        <EChartsReact
                                          className="doubleLayer-pieChart"
                                          option={{
                                            silent: false,
                                            series: [
                                              {
                                                label: {
                                                  color: "#fff",
                                                  fontSize: "18",
                                                  textBorderColor: "#fff",
                                                },
                                                itemStyle: {
                                                  borderRadius: 10,
                                                  borderWidth: 5,
                                                },
                                                radius: ["0", "100%"],
                                                type: "sunburst",
                                                emphasis: {
                                                  focus: "ancestor",
                                                },
                                                data: [
                                                  {
                                                    children:
                                                      socialObj?.positive
                                                        ?.childArray,
                                                    name:
                                                      socialObj?.positive
                                                        ?.percentage + " %",
                                                    value: 30,
                                                    itemStyle: {
                                                      color: {
                                                        colorStops: [
                                                          {
                                                            offset: 0,
                                                            color: "#3aa95a",
                                                          },
                                                          {
                                                            offset: 1,
                                                            color: "#ffffff",
                                                          },
                                                        ],
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1.5,
                                                        type: "linear",
                                                        global: false,
                                                      },
                                                    },
                                                  },
                                                  {
                                                    children:
                                                      socialObj?.negative
                                                        ?.childArray,
                                                    name:
                                                      socialObj?.negative
                                                        ?.percentage + " %",
                                                    value: 30,
                                                    itemStyle: {
                                                      color: {
                                                        colorStops: [
                                                          {
                                                            offset: 0,
                                                            color: "#d72727",
                                                          },
                                                          {
                                                            offset: 1,
                                                            color: "#ffffff",
                                                          },
                                                        ],
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1.5,
                                                        type: "linear",
                                                        global: false,
                                                      },
                                                    },
                                                  },
                                                  {
                                                    children:
                                                      socialObj?.neutral
                                                        ?.childArray,
                                                    name:
                                                      socialObj?.neutral
                                                        ?.percentage + " %",
                                                    value: 30,
                                                    itemStyle: {
                                                      color: {
                                                        colorStops: [
                                                          {
                                                            offset: 0,
                                                            color: "#bec3cd",
                                                          },
                                                          {
                                                            offset: 1,
                                                            color: "#ffffff",
                                                          },
                                                        ],
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1.5,
                                                        type: "linear",
                                                        global: false,
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          }}
                                        />
                                      </div>
                                    </Row>
                                    <div className="mainLegendDiv">
                                      {legend.map((e, i) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              className="legendImg"
                                              src={e.image}
                                              alt={e.name}
                                            />
                                            <div
                                              className="legendSpan"
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {" "}
                                              {e.name}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div
                                      className="reactionAnalyses"
                                      onClick={() =>
                                        navigate("/socialMediaCoverage", {
                                          state: {
                                            companies: [id],
                                            incident: "",
                                            name: "companyPage",
                                            companyName: companyData?.name,
                                            companyDetail: {
                                              incidents:
                                                companyData?.totalIncidents,
                                              risk: ESGRiskScoreList,
                                            },
                                          },
                                        })
                                      }
                                    >
                                      <span className="reactionAnalysesSpan">
                                        View All Reaction Analyses
                                      </span>
                                    </div>
                                  </div>
                                </ChartCard>
                                <SocialNetworkTrend
                                  {...networkData}
                                  id={id}
                                  dateChange={date}
                                />
                              </section>

                              {/* <SentimentChart
                      {...networkData}
                      id={id}
                      dateChange={date}
                    />
                    <SentimentTrend
                      {...networkData}
                      id={id}
                      dateChange={date}
                    /> */}
                            </Collapse.Panel>

                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    7
                                  </span>
                                  Breaches & violation
                                </h4>
                              }
                              key="7"
                            >
                              <section>
                                <div
                                  className="most-sec-over containerWidth"
                                  style={{ padding: 20 }}
                                >
                                  <div className="violations-ps">
                                    <h2>Breaches and Violations</h2>
                                  </div>
                                  <Tabs
                                    onChange={(e) => {
                                      setActiveUngcTab(e);
                                    }}
                                    activeKey={activeUngcTab}
                                    className="supply-chain-act-px "
                                  >
                                    <Tabs.TabPane key="0" tab={"LkSG"}>
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.breachesIcons}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  German Supply Chain Act (LkSG)
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {totalCount(
                                                    breachesAndViolationData?.most_frequent ||
                                                    []
                                                  )}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content">
                                            <Row>
                                              <span className="unSpanCount">
                                                Companies with the most number
                                                of violations
                                              </span>
                                            </Row>
                                            <div></div>
                                          </div>


                                          <div>
                                            {breachesAndViolationData?.german_supply_chain_act &&
                                              breachesAndViolationData
                                                .german_supply_chain_act
                                                .length > 0 &&
                                              breachesAndViolationData?.german_supply_chain_act.map(
                                                (item, index) => {
                                                  return (
                                                    <div className="companiesDataDiv">
                                                      <span>
                                                        {index + 1 + " "}
                                                        <b
                                                          onClick={() =>
                                                            navigate(
                                                              "/companyPage",
                                                              {
                                                                state: {
                                                                  id: item?.id,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {item.name + " "}
                                                        </b>

                                                      </span>

                                                      <div
                                                        style={{
                                                          width: 100,
                                                          height: 19,
                                                          display: "flex",
                                                        }}
                                                      >
                                                        {item.incidentCount +
                                                          " incidents"}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>

                                          {breachesAndViolationData?.esg_supply_chain_act &&
                                            breachesAndViolationData
                                              .esg_supply_chain_act.length >
                                            9 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  // setModalVisible(true);
                                                  setBreachesViolationLimit(
                                                    200
                                                  );
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All
                                                </span>
                                              </div>
                                            )}

                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Most frequent violations
                                            </span>
                                          </div>
                                          {activeUngcTab == 0 &&
                                            breachesAndViolationData?.most_frequent &&
                                            breachesAndViolationData
                                              .most_frequent.length > 0 &&
                                            breachesAndViolationData?.most_frequent?.map(
                                              (val, ind) => {
                                                return (
                                                  <UngcpBreaches
                                                    networkData={networkData}
                                                    activeTab={activeUngcTab}
                                                    type={val._id}
                                                    list={val?.incidents?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    totalIncidentCount={
                                                      val?.incidents?.length
                                                    }
                                                    value={val._id}
                                                    dataKey={val.value}
                                                  />
                                                );
                                              }
                                            )}
                                          <div
                                            className="reactionAnalyses"
                                            onClick={() => {
                                              setModalVisible(true);
                                              setModalData({
                                                ...modalData,
                                                voilation:
                                                  "german_supply_chain_act",
                                                start: networkData.start,
                                                end: networkData.end,
                                              });
                                            }}
                                          >
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View All Incidents
                                            </span>
                                          </div>
                                        </div>
                                      </section>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                      key="1"
                                      tab={"EU Supply Chain Act."}
                                    >
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.breachesIcons}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  Supply Chain Act Violations
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {totalCount(
                                                    breachesAndViolationData?.most_frequent ||
                                                    []
                                                  )}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {breachesAndViolationData?.esg_supply_chain_act &&
                                            breachesAndViolationData
                                              ?.esg_supply_chain_act?.length >
                                            0 && (
                                              <div className="d-flex justify-content">
                                                <Row>
                                                  <span className="unSpanCount">
                                                    Companies with the most
                                                    number of violations
                                                  </span>
                                                </Row>
                                                <div></div>
                                              </div>
                                            )}

                                          <div>
                                            {breachesAndViolationData?.esg_supply_chain_act &&
                                              breachesAndViolationData
                                                ?.esg_supply_chain_act?.length >
                                              0 &&
                                              breachesAndViolationData?.esg_supply_chain_act.map(
                                                (item, index) => {
                                                  return (
                                                    <div className="companiesDataDiv">
                                                      <span>
                                                        {index + 1 + " "}
                                                        <b
                                                          onClick={() =>
                                                            navigate(
                                                              "/companyPage",
                                                              {
                                                                state: {
                                                                  id: item?.id,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {item.name + " "}
                                                        </b>

                                                      </span>

                                                      <div
                                                        style={{
                                                          width: 100,
                                                          height: 19,
                                                          display: "flex",
                                                        }}
                                                      >
                                                        {item.incidentCount +
                                                          " incidents"}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>

                                          {breachesAndViolationData?.esg_supply_chain_act &&
                                            breachesAndViolationData
                                              ?.esg_supply_chain_act?.length >
                                            9 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  // setModalVisible(true);
                                                  setBreachesViolationLimit(
                                                    200
                                                  );
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All
                                                </span>
                                              </div>
                                            )}

                                          {breachesAndViolationData
                                            ?.most_frequent?.length > 0 && (
                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount">
                                                  Most frequent violations
                                                </span>
                                              </div>
                                            )}
                                          {activeUngcTab == 1 &&
                                            breachesAndViolationData?.most_frequent &&
                                            breachesAndViolationData
                                              .most_frequent.length > 0 &&
                                            breachesAndViolationData?.most_frequent?.map(
                                              (val, ind) => {
                                                return (
                                                  <UngcpBreaches
                                                    networkData={networkData}
                                                    activeTab={activeUngcTab}
                                                    type={val._id}
                                                    list={val?.incidents?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    totalIncidentCount={
                                                      val?.incidents?.length
                                                    }
                                                    value={val.value}
                                                    dataKey={val.value}
                                                  />
                                                );
                                              }
                                            )}

                                          {breachesAndViolationData
                                            ?.most_frequent?.length > 0 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  setModalVisible(true);
                                                  setModalData({
                                                    ...modalData,
                                                    voilation:
                                                      "esg_supply_chain_act",
                                                    start: networkData.start,
                                                    end: networkData.end,
                                                  });
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All Incidents
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                      </section>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane key="2" tab={"UNGC"}>
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.un}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  UN global compact principles
                                                  breaches
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {ungcpBreaches?.mostFrequentCount || 0}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Most frequent breaches
                                            </span>
                                          </div>
                                          {ungcpBreaches && ungcpBreaches?.data?.map((val, ind) => {
                                            return (
                                              <UngcpBreaches
                                                networkData={networkData}
                                                activeTab={activeUngcTab}
                                                type={val._id}
                                                list={val?.incidents?.slice(
                                                  0,
                                                  4
                                                )}
                                                totalIncidentCount={
                                                  val?.incidents?.length
                                                }
                                                dataKey={val._id}
                                              />
                                            );
                                          })}
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Total incidents on each principle
                                            </span>
                                          </div>
                                          <>
                                            <PrincipleBarChat
                                              series={BarMapping(ungcpBreaches?.data)}
                                              networkData={networkData}
                                            />

                                            <div className="ellips chart-bottom-legend-New">
                                              {HLEA?.map((data) => {
                                                return (
                                                  <p className="legend">
                                                    <span
                                                      className="legendCircle"
                                                      style={{
                                                        background: data.color,
                                                      }}
                                                    />
                                                    {data.tittle}
                                                  </p>
                                                );
                                              })}
                                            </div>
                                          </>

                                          <div
                                            className="reactionAnalyses"
                                            onClick={() => {
                                              setModalVisible(true);
                                              setModalData({
                                                ...modalData,
                                                voilation: "UNGCP_Breaches",
                                                start: networkData.start,
                                                end: networkData.end,
                                              });
                                            }}
                                          >
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View All Incidents
                                            </span>
                                          </div>
                                        </div>
                                      </section>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane key="3" tab={"PAI"}>
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.pia}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  Principal Adverse Impact (PAI)
                                                  Violations
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {breachesAndViolationData.mostFrequentCount}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content">
                                            <Row>
                                              <span className="unSpanCount">
                                                Companies with the most number
                                                of breaches
                                              </span>
                                            </Row>
                                            <div></div>
                                          </div>

                                          <div>
                                            {activeUngcTab == 3 &&
                                              breachesAndViolationData?.esg_PAI &&
                                              breachesAndViolationData?.esg_PAI
                                                ?.length > 0 &&
                                              breachesAndViolationData?.esg_PAI?.map(
                                                (e, index) => (
                                                  <div className="companiesDataDiv">
                                                    <span>
                                                      {index + 1 + " "}
                                                      <b
                                                        onClick={() =>
                                                          navigate(
                                                            "/companyPage",
                                                            {
                                                              state: {
                                                                id: e?.id,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        {e.name + " "}
                                                      </b>
                                                    </span>

                                                    <div className="Incident_on_ESG">
                                                      {e.incidentCount +
                                                        " incidents"}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </div>

                                          {activeUngcTab == 3 &&
                                            breachesAndViolationData?.esg_PAI &&
                                            breachesAndViolationData?.esg_PAI
                                              ?.length > 9 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  // setModalVisible(true);
                                                  setBreachesViolationPaiLimit(
                                                    200
                                                  );
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All
                                                </span>
                                              </div>
                                            )}
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Most frequent breaches
                                            </span>
                                          </div>
                                          {activeUngcTab == 3 &&
                                            breachesAndViolationData?.most_frequent &&
                                            breachesAndViolationData
                                              ?.most_frequent?.length > 0 &&
                                            breachesAndViolationData?.most_frequent?.map(
                                              (val, ind) => {
                                                return (
                                                  <UngcpBreaches
                                                    networkData={networkData}
                                                    activeTab={activeUngcTab}
                                                    type={val._id}
                                                    list={val?.incidents?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    totalIncidentCount={
                                                      val?.incidents?.length
                                                    }
                                                    dataKey={val.value}
                                                  />
                                                );
                                              }
                                            )}
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Total Number of incidents
                                            </span>
                                          </div>
                                          <>
                                            {activeUngcTab == 3 &&
                                              breachesAndViolationData?.most_frequent &&
                                              breachesAndViolationData
                                                ?.most_frequent?.length > 0 &&
                                              !breachesAndViolationDataLoader && (
                                                <PAIBarChat
                                                  series={BarMappingPAI(
                                                    breachesAndViolationData?.most_frequent
                                                  )}
                                                  networkData={{ ...networkData, companies: [companyData.id], companyName: [companyData.name] }}
                                                />
                                              )}
                                          </>
                                          <div className="pia-bott-hover">
                                            <p
                                              className="onhover-image"
                                              onMouseEnter={() =>
                                                setIsHovered(true)
                                              }
                                              onMouseLeave={() =>
                                                setIsHovered(false)
                                              }
                                            >
                                              <span>
                                                <img
                                                  className={isHovered}
                                                  src={
                                                    isHovered
                                                      ? Images.newImageOnHover
                                                      : Images.circleInfo
                                                  }
                                                  alt=""
                                                />
                                              </span>
                                              14 Principal Adverse Impact (PAI)
                                            </p>

                                            <div className="hover-content-ps">
                                              <div className="adverse-heading-ps">
                                                <h5>
                                                  {" "}
                                                  <span>
                                                    <img
                                                      src={Images.colorSky}
                                                    />
                                                  </span>
                                                  14 Principal Adverse Impact
                                                  (PAI)
                                                </h5>
                                              </div>
                                              <div className="number-of-impact-ps">
                                                <p>
                                                  1. GHG emissions scope 1,2,3,
                                                  total
                                                </p>
                                                <p>2. Carbon footprint</p>
                                                <p>
                                                  3. GHG intensity of investee
                                                  companies
                                                </p>
                                                <p>
                                                  4. Exposure to companies
                                                  active in the fossil fuel
                                                  sector
                                                </p>
                                                <p>
                                                  5. Share of non-renewable
                                                  energy consumption and
                                                  production
                                                </p>
                                                <p>
                                                  6. Energy consumption
                                                  intensity per high impact
                                                  climate sector
                                                </p>
                                                <p>
                                                  7. Activities negatively
                                                  affecting
                                                  biodiversity-sensitive areas
                                                </p>
                                                <p>8. Emissions to water</p>
                                                <p>
                                                  9. Hazardous waste and
                                                  radioactive waste ratio
                                                </p>
                                                <p>
                                                  10. Violations of UN Global
                                                  Compact principles and OECD
                                                  guidelines{" "}
                                                </p>
                                                <p>
                                                  11. Lack of processes and
                                                  compliance mechanisms to
                                                  monitor compliance with UN GC
                                                  and OECD
                                                </p>
                                                <p>
                                                  12. Unadjusted gender pay gap
                                                </p>
                                                <p>
                                                  13. Board gender diversity
                                                </p>
                                                <p>
                                                  14. Exposure to controversial
                                                  weapons
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="reactionAnalyses"
                                            onClick={() => {
                                              setModalVisible(true);
                                              setModalData({
                                                ...modalData,
                                                voilation: "esg_PAI",
                                                start: networkData.start,
                                                end: networkData.end,
                                              });
                                            }}
                                          >
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View All Incidents
                                            </span>
                                          </div>
                                        </div>
                                      </section>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane key="4" tab={"SDG"}>
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.sdg}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  UN global compact principles
                                                  breaches
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {breachesAndViolationData.mostFrequentCount}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content">
                                            <Row>
                                              <span className="unSpanCount">
                                                Companies with the most number
                                                of breaches
                                              </span>
                                            </Row>
                                            <div></div>
                                          </div>

                                          <div>
                                            {activeUngcTab == 4 &&
                                              breachesAndViolationData?.esg_SDG &&
                                              breachesAndViolationData?.esg_SDG
                                                ?.length > 0 &&
                                              breachesAndViolationData?.esg_SDG?.map(
                                                (e, index) => (
                                                  <div className="companiesDataDiv">
                                                    <span>
                                                      {index + 1 + " "}
                                                      <b
                                                        onClick={() =>
                                                          navigate(
                                                            "/companyPage",
                                                            {
                                                              state: {
                                                                id: e?.id,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        {e.name + " "}
                                                      </b>
                                                    </span>

                                                    <div className="Incident_on_ESG">
                                                      {e.incidentCount +
                                                        " incidents"}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </div>

                                          {activeUngcTab == 4 &&
                                            breachesAndViolationData?.esg_SDG &&
                                            breachesAndViolationData?.esg_SDG
                                              ?.length > 9 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  // setModalVisible(true);
                                                  setBreachesViolationSdgLimit(
                                                    200
                                                  );
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All
                                                </span>
                                              </div>
                                            )}

                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Most frequent breaches
                                            </span>
                                          </div>
                                          {activeUngcTab == 4 &&
                                            breachesAndViolationData?.most_frequent &&
                                            breachesAndViolationData
                                              ?.most_frequent?.length > 0 &&
                                            breachesAndViolationData?.most_frequent?.map(
                                              (val, ind) => {
                                                return (
                                                  <UngcpBreaches
                                                    networkData={networkData}
                                                    activeTab={activeUngcTab}
                                                    type={val._id}
                                                    list={val?.incidents?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    totalIncidentCount={
                                                      val?.incidents?.length
                                                    }
                                                    dataKey={val.value}
                                                  />
                                                );
                                              }
                                            )}
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Total Number of incidents on each
                                              goal
                                            </span>
                                          </div>
                                          <>
                                            {activeUngcTab == 4 &&
                                              breachesAndViolationData?.most_frequent &&
                                              breachesAndViolationData
                                                ?.most_frequent?.length > 0 &&
                                              !breachesAndViolationDataLoader && (
                                                <SDGBarChat
                                                  series={BarMappingSDG(
                                                    breachesAndViolationData?.most_frequent
                                                  )}
                                                  networkData={{ ...networkData, companies: [companyData.id], companyName: [companyData.name] }}
                                                />
                                              )}
                                          </>
                                          <div className="pia-bott-hover">
                                            <p
                                              className="onhover-image"
                                              onMouseEnter={() =>
                                                setIsHovered(true)
                                              }
                                              onMouseLeave={() =>
                                                setIsHovered(false)
                                              }
                                            >
                                              <span>
                                                <img
                                                  className={isHovered}
                                                  src={
                                                    isHovered
                                                      ? Images.newImageOnHover
                                                      : Images.circleInfo
                                                  }
                                                  alt=""
                                                />
                                              </span>
                                              17 Sustainable Development Goals
                                            </p>

                                            <div className="hover-content-ps">
                                              <div className="adverse-heading-ps">
                                                <h5>
                                                  {" "}
                                                  <span>
                                                    <img
                                                      src={Images.colorSky}
                                                    />
                                                  </span>
                                                  17 Sustainable Development
                                                  Goals
                                                </h5>
                                              </div>
                                              <div className="number-of-impact-ps">
                                                <p>1. No poverty</p>
                                                <p>2. Zero hunger</p>
                                                <p>
                                                  3. Good health and well-being
                                                </p>
                                                <p>4. Quality education</p>
                                                <p>5. Gender equality</p>
                                                <p>
                                                  6. Clean water and sanitation
                                                </p>
                                                <p>
                                                  7. Affordable and clean energy
                                                </p>
                                                <p>
                                                  8. Decent work and economic
                                                  growth
                                                </p>
                                                <p>
                                                  9. Industry, innovation and
                                                  infrastructure
                                                </p>
                                                <p>10. Reduced inequalities </p>
                                                <p>
                                                  11. Sustainable cities and
                                                  communities
                                                </p>
                                                <p>
                                                  12. Responsible consumption
                                                  and production
                                                </p>
                                                <p>13. Climate action</p>
                                                <p>14. Life below water</p>
                                                <p>15. Life on land</p>
                                                <p>
                                                  16. Peace, justice and strong
                                                  institutions
                                                </p>
                                                <p>
                                                  17. Partnership for the goals
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="reactionAnalyses"
                                            onClick={() => {
                                              setModalVisible(true);
                                              setModalData({
                                                ...modalData,
                                                voilation: "esg_SDG",
                                                start: networkData.start,
                                                end: networkData.end,
                                              });
                                            }}
                                          >
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View All Incidents
                                            </span>
                                          </div>
                                        </div>
                                      </section>
                                    </Tabs.TabPane>
                                  </Tabs>
                                </div>
                              </section>
                            </Collapse.Panel>

                            <Collapse.Panel
                              id="9Key"
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    8
                                  </span>
                                  Regulatory & Legal Actions
                                </h4>
                              }
                              key="8"
                            >
                              <section>
                                <div
                                  className="container most-sec-over g-0 containerWidth"
                                  style={{ padding: 20 }}
                                >
                                  <div className="UNclass">
                                    <img
                                      className="sq104px"
                                      src={Images.regulatorLegal}
                                      alt="RL"
                                    />
                                    <div className="d-flex row">
                                      <Row>
                                        <span className="unSpan">
                                          Regulatory & Legal Actions
                                          <img
                                            className="hover-page"
                                            style={{ marginLeft: 5 }}
                                            src={Images.circleInfo}
                                            alt=""
                                          />
                                        </span>
                                      </Row>

                                      <div className="d-flex">
                                        <span className="subtitle1 color05476F pl-16">
                                          {companyData?.name}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span className="CaptionRegularFit pl-16 pt-1">{`Last updated on ${moment(
                                          companyData?.createdAt
                                        ).format("MMM,DD YYYY")}`}</span>
                                        <ToolTip
                                          placement="left"
                                          title={"Sort"}
                                        >
                                          <img
                                            className="hover-page"
                                            onClick={() => {
                                              dispatch(
                                                getRegulatoryLegalAction({
                                                  ...networkData,
                                                  id: id,
                                                  sort: "datetime",
                                                  order: legalOrder ? 1 : -1,
                                                })
                                              );
                                              setLegalOrder(!legalOrder);
                                            }}
                                            style={{ marginLeft: 5 }}
                                            src={Images.sortButton}
                                            alt=""
                                          />
                                        </ToolTip>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-4">
                                    {regulatoryLegalActionsData?.list?.map(
                                      (val, i) => {
                                        return (
                                          <RegulatoryLegalActionsCard
                                            index={i}
                                            {...val.legal_and_regulatory_actions

                                            }
                                            id={val.id}
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className="d-flex flex-column justify-content">
                                    <span className="unSpanCount pt-2 pb-0">
                                      Regulatory & Legal Actions Timeline
                                    </span>
                                    {Object.keys(
                                      regulatoryLegalActionsData?.timelineList
                                    )?.length > 0 && (
                                        <TimeLine
                                          {...networkData}
                                          id={id}
                                          data={
                                            regulatoryLegalActionsData?.timelineList
                                          }
                                        />
                                      )}
                                  </div>
                                </div>
                              </section>
                              <section>
                                <div
                                  className="container most-sec-over g-0 mt-4 containerWidth"
                                  style={{ padding: 20 }}
                                >
                                  <div className="UNclass">
                                    <img
                                      className="sq104px"
                                      src={Images.regulatorLegal}
                                      alt="RL"
                                    />
                                    <div className="d-flex row">
                                      <Row>
                                        <span className="unSpan">
                                          Regulatory Fines & Penalties
                                          <img
                                            className="hover-page"
                                            style={{ marginLeft: 5 }}
                                            src={Images.circleInfo}
                                            alt=""
                                          />
                                        </span>
                                      </Row>

                                      <div className="d-flex">
                                        <span className="subtitle1 color05476F pl-16">
                                          {companyData?.name}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span className="CaptionRegularFit pl-16 pt-1">{`Last updated on ${moment().format(
                                          "MMM,DD YYYY"
                                        )}`}</span>
                                        <ToolTip
                                          placement="left"
                                          title={"Sort"}
                                        >
                                          <img
                                            className="hover-page"
                                            style={{ marginLeft: 5 }}
                                            src={Images.sortButton}
                                            alt=""
                                            onClick={() => {
                                              dispatch(
                                                getRegulatoryFinesPenaltiesAction(
                                                  {
                                                    ...networkData,
                                                    id: id,
                                                    sort: "datetime",
                                                    order: order ? 1 : -1,
                                                  }
                                                )
                                              );
                                              setOrder(!order);
                                            }}
                                          />
                                        </ToolTip>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mt-4">
                                    {getRegulatoryFinesPenaltiesList.map(
                                      (val, i) => {
                                        return (
                                          <RegulatoryFinesPenaltiesCard
                                            id={val.id}
                                            {...val.penalties_and_fines}
                                            index={i}
                                          />
                                        );
                                      }
                                    )}
                                    <div></div>
                                  </div>
                                  {getRegulatoryFinesPenaltiesList?.length >
                                    0 ? (
                                    <>
                                      {getRegulatoryFinesPenaltiesList?.length >
                                        20 && (
                                          <div className="reactionAnalyses">
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View More
                                            </span>
                                          </div>
                                        )}
                                    </>
                                  ) : (
                                    <div className="noDataLayout">
                                      <span className="noDataText">
                                        {" "}
                                        There are no significant fines or
                                        penalties{" "}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </section>
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </Tabs.TabPane>

                  <Tabs.TabPane key="2" tab={"Supply Chain Details "}>
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-lg-3  containerMidWidth ">
                        <div className="hone-left-side1">
                          <div className="home-left-list ">
                            <ul>
                              <li
                                className={
                                  activeItem == "RiskAnalysis"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(400)}>
                                  1 Risk Analysis{" "}
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "TopHighRisks"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(2152)}>
                                  2 Top High Risks{" "}
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "IncidentsAnalysis"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(2701)}>
                                  3 Incident Analysis{" "}
                                </a>
                              </li>
                              <li
                                className={
                                  activeItem == "UNPrinciple"
                                    ? "tab-active"
                                    : ""
                                }
                              >
                                <a onClick={() => scrollTo(4851)}>
                                  4 Breaches & violation{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 containerWidth ml-32 scrolling-corporate-page">
                        <section>
                          <div className="containerWidth g-0">
                            <div className="row  mt-2">
                              <div className={`top-right-home-drop`}>
                                <div>
                                  {/* <a href=""> */}
                                  <img
                                    onClick={() => {
                                      callDownloadApi();
                                    }}
                                    src={Images.framedow}
                                  />
                                  {/* </a> */}
                                </div>
                                {/* social  share button  model MM  */}
                                <Modal
                                  title={"Share"}
                                  centered
                                  className="share"
                                  open={isModalOpen}
                                  footer={false}
                                  onCancel={() => setIsModalOpen(false)}
                                  mask
                                >
                                  <div style={{ display: "flex", gap: "22px" }}>
                                    <FacebookShareButton
                                      url={"www.google.com"}
                                      quote={"asasas"}
                                    >
                                      <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>

                                    <LinkedinShareButton
                                      url={"www.google.com"}
                                      title={"asasas"}
                                    >
                                      <LinkedinIcon size={32} round={true} />
                                    </LinkedinShareButton>

                                    <TwitterShareButton
                                      url={"www.google.com"}
                                      title={"asasasa"}
                                    >
                                      <XIcon size={32} round={true} />
                                    </TwitterShareButton>
                                  </div>
                                </Modal>
                                <DatePicker
                                  onDatePickerChange={onDatePickerChange}
                                  lastDate={lastDate}
                                />
                              </div>
                            </div>
                          </div>
                        </section>
                        <section>
                          <div className="all-supply-chain-ps containerWidth g-0 mt-4">
                            <div className="inner-supply-box">
                              <div className="supply-image-xp">
                                <img src={Images.supply} />
                              </div>

                              <div className="all-text-box-supply">
                                <p>All Supply Chain Companies</p>
                                <h2>
                                  {Object.entries(supplyChainData)
                                    ? supplyChainData?.countData
                                      ?.allCompaniesCount || 0
                                    : 0}
                                </h2>
                              </div>

                              <div className="partners-grid-box">
                                <div className="customers-text-box-ps">
                                  <span>Suppliers</span>
                                  <p>
                                    {Object.entries(supplyChainData)
                                      ? supplyChainData?.countData?.suppliers ||
                                      0
                                      : 0}
                                  </p>
                                </div>
                                <div className="customers-text-box-ps">
                                  <span>Customers</span>
                                  <p>
                                    {Object.entries(supplyChainData)
                                      ? supplyChainData?.countData?.customers ||
                                      0
                                      : 0}
                                  </p>
                                </div>

                                <div className="customers-text-box-ps">
                                  <span>Partners</span>
                                  <p>
                                    {Object.entries(supplyChainData)
                                      ? supplyChainData?.countData?.partners ||
                                      0
                                      : 0}
                                  </p>
                                </div>
                              </div>

                              <div className="supply-companies-lest-ps">
                                <div
                                  className="companies-button-box-ps"
                                  onClick={() => setModalVisibleCompList(true)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <p>
                                    <span>
                                      <img src={Images.filesList} />
                                    </span>{" "}
                                    View Company List
                                  </p>
                                </div>
                                <div className="companies-button-box-ps"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/graphview", {
                                      state: {
                                        id: companyData.id,
                                        name: companyData.name,
                                        start: networkData.start,
                                        end: networkData.end,
                                      },
                                    });
                                  }}
                                >
                                  <p>
                                    <span>
                                      <img src={Images.graphIcons} />
                                    </span>{" "}
                                    View Graph
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <div className="collapse-section-ps containerWidth">
                          <Collapse
                            defaultActiveKey={[
                              "1",
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                            ]}
                            className="company-detail-collapse"
                            expandIcon={({ isActive }) => {
                              return (
                                <img
                                  src={Images.iconDown}
                                  style={{
                                    transform: `rotate(${isActive ? "0" : "-90"}deg)`,
                                  }}
                                />
                              );
                            }}
                          >
                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    1
                                  </span>
                                  Risk Analysis
                                </h4>
                              }
                              key="1"
                            >
                              <section>
                                <div className="risk-per-comp containerWidth g-0 mt-4 ">
                                  <div className="row">
                                    <div className="supply-number-side-ps">
                                      <ChartComponent
                                        data={
                                          Object.entries(riskLineChartData)
                                            ? riskLineChartData?.supplyChain
                                            : []
                                        }
                                        title={"Supply Chain Risk"}
                                        count={
                                          Object.entries(riskLineChartData)
                                            ? riskLineChartData?.countData
                                              ?.total
                                            : 0
                                        }
                                      />

                                      <ChartComponent
                                        data={
                                          Object.entries(riskLineChartData)
                                            ? riskLineChartData?.suppliers
                                            : []
                                        }
                                        title={"Suppliers Risk"}
                                        count={
                                          Object.entries(riskLineChartData)
                                            ? riskLineChartData?.countData
                                              ?.suppliers
                                            : 0
                                        }
                                      />

                                      <ChartComponent
                                        data={
                                          Object.entries(riskLineChartData)
                                            ? riskLineChartData?.customers
                                            : []
                                        }
                                        title={"Customers Risk"}
                                        count={
                                          Object.entries(riskLineChartData)
                                            ? riskLineChartData?.countData
                                              ?.customers
                                            : 0
                                        }
                                      />

                                      <ChartComponent
                                        data={
                                          Object.entries(riskLineChartData)
                                            ? riskLineChartData?.partners
                                            : []
                                        }
                                        title={"Partners Risk"}
                                        count={
                                          Object.entries(riskLineChartData)
                                            ? riskLineChartData?.countData
                                              ?.partners
                                            : 0
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <section>
                                <div className="risk-per-company-right-ps containerWidth g-0">
                                  <h5>Risk Per Company</h5>
                                  <div className="ellipse-graph-ps">
                                    <PieChartComponent
                                      allData={
                                        Object.entries(riskPieChartData)
                                          ? riskPieChartData?.data
                                          : []
                                      }
                                      risk={
                                        Object.entries(riskPieChartData)
                                          ? Math.round(riskPieChartData?.risk)
                                          : 0
                                      }
                                    />
                                  </div>
                                </div>
                              </section>
                              <div className=" mt-4">
                                <section className="left-text">
                                  <ChartCard
                                    showTimeBarOnTop={false}
                                    title={"Risk Score Per Company"}
                                  >
                                    <div className="portfolio_container_1 custom_container_portfolio_1">
                                      {riskScoreBarData &&
                                        riskScoreBarData?.length > 0 &&
                                        riskScoreBarData.map((item, index) => {
                                          return (
                                            <div
                                              className=""
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                style={{ width: "50%" }}
                                                className="leftDataContainer"
                                              >
                                                <div
                                                  className="CaptionXL"
                                                  style={{
                                                    background: "#EFF1F6",
                                                    height: "20px",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "0 7px",
                                                  }}
                                                >
                                                  <span
                                                    style={{ minWidth: "" }}
                                                  >
                                                    {index + 1}
                                                  </span>
                                                </div>
                                                <span
                                                  className="body"
                                                  style={{
                                                    paddingLeft: "8px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <p
                                                    onClick={() =>
                                                      navigate("/companyPage", {
                                                        state: { id: item?.id },
                                                      })
                                                    }
                                                  >
                                                    {item?.name || ""}
                                                  </p>
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  width: "50%",
                                                  display: "flex",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    height: "20px",
                                                    minWidth: "30px",
                                                  }}
                                                >
                                                  {item?.risk
                                                    ? Math.ceil(item?.risk) >=
                                                    75 && (
                                                      <img
                                                        style={{
                                                          height: "20px",
                                                          width: "20px",
                                                        }}
                                                        src={
                                                          Images.dangerIcon
                                                        }
                                                      />
                                                    )
                                                    : ""}
                                                </div>
                                                <span
                                                  className={`BodySelected1`}
                                                  style={{
                                                    marginRight: "16px",
                                                    color: `${getRiskVal(item?.risk) ==
                                                      "SECURE"
                                                      ? "#00A36C"
                                                      : getRiskVal(
                                                        item?.risk
                                                      ) == "LOW"
                                                        ? "#FFBF00"
                                                        : getRiskVal(
                                                          item?.risk
                                                        ) == "MEDIUM"
                                                          ? "#FF7518"
                                                          : "#FF0000"
                                                      }`,
                                                  }}
                                                >
                                                  {item?.risk
                                                    ? Math.ceil(item?.risk)
                                                    : 0}
                                                </span>
                                                <Progress
                                                  className="progress_portfolio_sec"
                                                  percent={item?.risk}
                                                  showInfo={false}
                                                  strokeLinecap="round"
                                                  strokeColor={
                                                    getRiskVal(item?.risk) ==
                                                      "SECURE"
                                                      ? "#00A36C"
                                                      : getRiskVal(
                                                        item?.risk
                                                      ) == "LOW"
                                                        ? "#FFBF00"
                                                        : getRiskVal(
                                                          item?.risk
                                                        ) == "MEDIUM"
                                                          ? "#FF7518"
                                                          : "#FF0000"
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}

                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          className="viewMoreContainer"
                                        // onClick={() =>
                                        //   setRiskScorePerCompanyShowMore(true)
                                        // }
                                        >
                                          <span className="buttonM">
                                            View More
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </ChartCard>
                                </section>
                              </div>
                              <CompaniesTable
                                id={companyData?.id}
                                companyName={[companyData?.name]}
                                data={
                                  companiesList.length
                                    ? companiesList.slice(0, 4)
                                    : []
                                }
                                columns={companyListColumn}
                                date={{ ...networkData }}
                                goTo={"/companyPage"}
                                title={
                                  <h4>
                                    Supply Chain Companies
                                    <img
                                      src={Images.circleInfo}
                                      className="info-icon-company-page"
                                    />
                                  </h4>
                                }
                                type={tabValForIncidentBars}
                              />
                            </Collapse.Panel>
                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    2
                                  </span>
                                  Top High Risks
                                </h4>
                              }
                              key="2"
                            >
                              <section>
                                <div className="incident-sup-box-ps mt-4 containerWidth g-0">
                                  {Object.entries(supplyChainData) &&
                                    supplyChainData?.topData?.suppliers
                                      ?.length > 0 && (
                                      <div className="first-suppli">
                                        <div className="incidents-suppliers">
                                          <h6>Top High Risk Suppliers</h6>
                                          <p>Incidents</p>
                                        </div>
                                        {supplyChainData?.topData?.suppliers.map(
                                          (x, i) => {
                                            return (
                                              <div
                                                className={`hon-precision ${i == 0 && "mt-3"}`}
                                              >
                                                <div className="hon-text">
                                                  <p
                                                    onClick={() => {
                                                      navigate("/companyPage", {
                                                        state: { id: x.id },
                                                      });
                                                    }}
                                                  >
                                                    <span>{i + 1}</span>
                                                    {x.name || ""}
                                                  </p>
                                                </div>
                                                <div className="num-text">
                                                  <p
                                                    onClick={() => {
                                                      setModalVisible(true);
                                                      setModalData({
                                                        ...networkData,
                                                        companies: [x.id],
                                                        companyName: [x.name],
                                                      });
                                                    }}
                                                  >
                                                    {x.totalIncidents || 0}
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  {Object.entries(supplyChainData) &&
                                    supplyChainData?.topData?.customers
                                      ?.length > 0 && (
                                      <div className="first-suppli">
                                        <div className="incidents-suppliers">
                                          <h6>Top High Risk Customers</h6>
                                          <p>Incidents</p>
                                        </div>
                                        {supplyChainData?.topData?.customers.map(
                                          (x, i) => {
                                            return (
                                              <div
                                                className={`hon-precision ${i == 0 && "mt-3"}`}
                                              >
                                                <div className="hon-text">
                                                  <p
                                                    onClick={() => {
                                                      navigate("/companyPage", {
                                                        state: { id: x.id },
                                                      });
                                                    }}
                                                  >
                                                    <span>{i + 1}</span>
                                                    {x.name || ""}
                                                  </p>
                                                </div>
                                                <div className="num-text">
                                                  <p
                                                    onClick={() => {
                                                      setModalVisible(true);
                                                      setModalData({
                                                        ...networkData,
                                                        companies: [x.id],
                                                        companyName: [x.name],
                                                      });
                                                    }}
                                                  >
                                                    {x.totalIncidents || 0}
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  {Object.entries(supplyChainData) &&
                                    supplyChainData?.topData?.partners?.length >
                                    0 && (
                                      <div className="first-suppli">
                                        <div className="incidents-suppliers">
                                          <h6>Top High Risk Partners</h6>
                                          <p>Incidents</p>
                                        </div>
                                        {supplyChainData?.topData?.partners.map(
                                          (x, i) => {
                                            return (
                                              <div
                                                className={`hon-precision ${i == 0 && "mt-3"}`}
                                              >
                                                <div className="hon-text">
                                                  <p
                                                    onClick={() => {
                                                      navigate("/companyPage", {
                                                        state: { id: x.id },
                                                      });
                                                    }}
                                                  >
                                                    <span>{i + 1}</span>
                                                    {x.name || ""}
                                                  </p>
                                                </div>
                                                <div className="num-text">
                                                  <p
                                                    onClick={() => {
                                                      setModalVisible(true);
                                                      setModalData({
                                                        ...networkData,
                                                        companies: [x.id],
                                                        companyName: [x.name],
                                                      });
                                                    }}
                                                  >
                                                    {x.totalIncidents || 0}
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  {Object.entries(supplyChainData) &&
                                    supplyChainData?.topData?.industries
                                      ?.length > 0 && (
                                      <div className="first-suppli">
                                        <div className="incidents-suppliers">
                                          <h6>Top High Risk Industries</h6>
                                          <p>Incidents</p>
                                        </div>
                                        {supplyChainData?.topData?.industries.map(
                                          (x, i) => {
                                            return (
                                              <div
                                                className={`hon-precision ${i == 0 && "mt-3"}`}
                                              >
                                                <div className="hon-text">
                                                  <p
                                                  // onClick={() => {
                                                  //   navigate("/companyPage", {
                                                  //     state: { id: x.id },
                                                  //   });
                                                  // }}
                                                  >
                                                    <span>{i + 1}</span>
                                                    {x.name || ""}
                                                  </p>
                                                </div>
                                                <div className="num-text">
                                                  <p
                                                  // onClick={() => {
                                                  //   setModalVisible(true);
                                                  //   setModalData({ ...networkData, companies:[id],companyName:[companyData.name],industries:[x.name]  })
                                                  // }}
                                                  >
                                                    {x.totalIncidents || 0}
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                </div>
                              </section>
                            </Collapse.Panel>
                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    3
                                  </span>
                                  Incident Analysis
                                </h4>
                              }
                              key="3"
                            >
                              <section>
                                <div className="sup-liers-grid-ps containerWidth g-0 mt-4">
                                  <div className="first-sup-liers-box">
                                    <div className="sup-con-tent-ps">
                                      <h5>Suppliers</h5>
                                      <p>
                                        {Object.entries(incidentAnalysisData)
                                          ? incidentAnalysisData?.suppliers
                                            ?.count
                                          : 0}
                                      </p>
                                    </div>
                                    <div className="incident-sup-liers-ps">
                                      <div className="star-icon-text-ps">
                                        <p>
                                          <span>
                                            <img src={Images.starIcons} />
                                          </span>{" "}
                                          Incidents
                                        </p>
                                      </div>
                                      <div className="incident-num-text-ps">
                                        <p>
                                          {Object.entries(incidentAnalysisData)
                                            ? incidentAnalysisData?.suppliers
                                              ?.severCount
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="first-sup-liers-box">
                                    <div className="sup-con-tent-ps">
                                      <h5>Customers</h5>
                                      <p>
                                        {Object.entries(incidentAnalysisData)
                                          ? incidentAnalysisData?.customers
                                            ?.count
                                          : 0}
                                      </p>
                                    </div>
                                    <div className="incident-sup-liers-ps">
                                      <div className="star-icon-text-ps">
                                        <p>
                                          <span>
                                            <img src={Images.starIcons} />
                                          </span>{" "}
                                          Incidents
                                        </p>
                                      </div>
                                      <div className="incident-num-text-ps">
                                        <p>
                                          {Object.entries(incidentAnalysisData)
                                            ? incidentAnalysisData?.customers
                                              ?.severCount
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="first-sup-liers-box">
                                    <div className="sup-con-tent-ps">
                                      <h5>Partners</h5>
                                      <p>
                                        {Object.entries(incidentAnalysisData)
                                          ? incidentAnalysisData?.partners
                                            ?.count
                                          : 0}
                                      </p>
                                    </div>
                                    <div className="incident-sup-liers-ps">
                                      <div className="star-icon-text-ps">
                                        <p>
                                          <span>
                                            <img src={Images.starIcons} />
                                          </span>{" "}
                                          Incidents
                                        </p>
                                      </div>
                                      <div className="incident-num-text-ps">
                                        <p>
                                          {Object.entries(incidentAnalysisData)
                                            ? incidentAnalysisData?.partners
                                              ?.severCount
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section>
                                <div className="containerWidth g-0">
                                  <div className="row">
                                    <div className="col mt-4">
                                      <div className="inciden-box">
                                        <div
                                          className="total-box"
                                          onClick={() => setModalVisible(true)}
                                        >
                                          <h5>All Incidents</h5>
                                          <h1>{nFormatter(companyData?.totalIncidents)}</h1>
                                        </div>

                                        <div
                                          className="new-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              esg_categories: ["Environmental"],
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                        >
                                          <img src={Images.globeblue} />
                                          <p>Environmental</p>
                                          <h5>
                                            {nFormatter(companyData?.environmentIncidents)}
                                          </h5>
                                        </div>

                                        <div
                                          className="new-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              esg_categories: ["Social"],
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                        >
                                          <img src={Images.socialblue} />
                                          <p>Social</p>
                                          <h5>
                                            {nFormatter(companyData?.socialIncidents)}
                                          </h5>
                                        </div>
                                        <div
                                          className="new-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              esg_categories: ["Governance"],
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                        >
                                          <img src={Images.bankblue} />
                                          <p>Governance</p>
                                          <h5>
                                            {nFormatter(companyData?.governanceIncidents)}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col mt-3">
                                      <div
                                        className="seve-main-boxes"
                                        style={{ gap: "16px" }}
                                      >
                                        <div
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              showSever: true,
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                          className="fir-box"
                                        >
                                          <img src={Images.circle} />
                                          <p>
                                            Severe
                                            <span>
                                              <img
                                                className="hover-page"
                                                src={Images.circleInfo}
                                              />
                                              <div className="infohover-home">
                                                <p>
                                                  We use AI to determine the
                                                  severity of an incident based
                                                  on its content. However, since
                                                  it is often a subjective
                                                  matter, this description
                                                  should not be solely relied
                                                  on. It is intended as a
                                                  helpful clue to assist users.
                                                </p>
                                              </div>
                                            </span>
                                          </p>

                                          <h5 className="gap">
                                            {nFormatter(companyData?.highSeverIncidents)}
                                          </h5>
                                        </div>

                                        <div
                                          className="fir-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              start: moment()
                                                .subtract(30, "day")
                                                .format("YYYY-MM-DD"),
                                              end: moment().format(
                                                "YYYY-MM-DD"
                                              ),
                                              companies: [id],
                                              companyName: [companyData?.name],
                                            });
                                          }}
                                        >
                                          <img src={Images.clockGreen} />
                                          <p>Recent</p>
                                          <p>(last month)</p>
                                          <h5>{nFormatter(companyData?.lastMonth)}</h5>
                                        </div>
                                        <div
                                          className="fir-box"
                                          onClick={() => {
                                            setModalVisible(true);
                                            setModalData({
                                              ...networkData,
                                              start: moment()
                                                .subtract(2, "day")
                                                .format("YYYY-MM-DD"),
                                              end: moment().format(
                                                "YYYY-MM-DD"
                                              ),
                                            });
                                          }}
                                        >
                                          <img src={Images.felame} />
                                          <p>New</p>
                                          <p>(last 2 days)</p>
                                          <h5>{nFormatter(companyData?.lastTwoDays)}</h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <IncidentAnalysisComp
                                incidentBarData={incidentBarData}
                                data={data}
                                showModal={false}
                                screenName={"Home"}
                                tabValue={[
                                  tabValForIncidentBars,
                                  setTabValForIncidentBars,
                                ]}
                                incidentTabChange={incidentTabChange}
                              />
                              <IncidentTable
                                id={companyData?.id}
                                companyName={[companyData?.name]}
                                data={incident?.data?.slice(0, 4)}
                                columns={columns1}
                                date={{
                                  ...networkData,
                                  supplyChain: activeTab == "1" ? false : true,
                                }}
                                goTo={"/companyPage"}
                                title={
                                  <h4>
                                    Incidents List
                                    <img
                                      src={Images.circleInfo}
                                      className="info-icon-company-page"
                                    />
                                  </h4>
                                }
                                tabKey={[tabKey, setTabKey]}
                                tabVal={[tabVal, setTabVal]}
                                tabShowCount={totalEsgIncidentCount}
                              />
                              <section>
                                <div className="inc-map-pre mt-4 containerWidth">
                                  <div className="tab-top">
                                    <div className="left-text">
                                      <Row>
                                        <h4>Incidents severity Map</h4>
                                        <img
                                          className="hover-page"
                                          style={{ marginLeft: 5 }}
                                          src={Images.circleInfo}
                                          alt=""
                                        />
                                      </Row>
                                    </div>
                                    <div
                                      className="righ-side"
                                      onClick={() => {
                                        dispatch(resetMapData())
                                        navigate("/fullMap", { state: { ...networkData, companyId: userCompId, } })
                                      }}
                                    >
                                      <a>
                                        View Full Map
                                        <span>
                                          <img src={Images.iconser} />
                                        </span>
                                      </a>
                                    </div>
                                  </div>

                                  <div className="container">
                                    <div className="row">
                                      <div
                                        className="col-10 mt-3 mb-3"
                                        style={{
                                          position: "relative",
                                          height: "326px",
                                        }}
                                      >
                                        <div className="map-pre">
                                          <div
                                            style={{
                                              left: "1vw",
                                              opacity: 0.9,
                                              bottom: "6px",
                                              padding: ".6vw",
                                              position: "absolute",
                                              display: "flex",
                                              zIndex: "1",
                                              backgroundColor: "#ffffff",
                                              alignItems: "center",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <span
                                              style={{
                                                marginBottom: ".4vw",
                                                fontSize: "1rem",
                                              }}
                                            >
                                              Severity
                                            </span>
                                            <div>
                                              <MapItem
                                                color={"#800020"}
                                                name={"Critical"}
                                              />
                                              <MapItem
                                                color={"#B70000"}
                                                name={"High"}
                                              />
                                              <MapItem
                                                color={"#F28C28"}
                                                name={"Moderate"}
                                              />
                                              <MapItem
                                                color={"#FFD700"}
                                                name={"Minor"}
                                              />
                                            </div>
                                          </div>
                                          <FullMap
                                            width={"600px"}
                                            height={"326px"}
                                            zoom={0}
                                            data={serverityMap || []}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-2">
                                        <div
                                          className="asia-cont"
                                          style={{
                                            minWidth: "100px",
                                          }}
                                        >
                                          {update?.map((item, index) => {
                                            return (
                                              <div className="africa-co">
                                                <h5>{item.continent_code}</h5>
                                                <p>{item.incidents || 0}</p>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>


                            </Collapse.Panel>
                            <Collapse.Panel
                              className="company-collapse-header"
                              header={
                                <h4>
                                  <span className="company-page-collable-label-text">
                                    4
                                  </span>
                                  Breaches & violation
                                </h4>
                              }
                              key="4"
                            >
                              <section>
                                <div
                                  className="most-sec-over containerWidth"
                                  style={{ padding: 20 }}
                                >
                                  <div className="violations-ps">
                                    <h2>Breaches and Violations</h2>
                                  </div>
                                  <Tabs
                                    onChange={(e) => {
                                      setActiveUngcTab(e);
                                    }}
                                    activeKey={activeUngcTab}
                                    className="supply-chain-act-px "
                                  >
                                    <Tabs.TabPane key="0" tab={"LkSG"}>
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.breachesIcons}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  German Supply Chain Act (LkSG)
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {totalCount(
                                                    breachesAndViolationData?.most_frequent ||
                                                    []
                                                  )}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content">
                                            <Row>
                                              <span className="unSpanCount">
                                                Companies with the most number
                                                of violations
                                              </span>
                                            </Row>
                                            <div></div>
                                          </div>


                                          <div>
                                            {breachesAndViolationData?.german_supply_chain_act &&
                                              breachesAndViolationData
                                                .german_supply_chain_act
                                                .length > 0 &&
                                              breachesAndViolationData?.german_supply_chain_act.map(
                                                (item, index) => {
                                                  return (
                                                    <div className="companiesDataDiv">
                                                      <span>
                                                        {index + 1 + " "}
                                                        <b
                                                          onClick={() =>
                                                            navigate(
                                                              "/companyPage",
                                                              {
                                                                state: {
                                                                  id: item?.id,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {item.name + " "}
                                                        </b>

                                                      </span>

                                                      <div
                                                        style={{
                                                          width: 100,
                                                          height: 19,
                                                          display: "flex",
                                                        }}
                                                      >
                                                        {item.incidentCount +
                                                          " incidents"}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>

                                          {breachesAndViolationData?.esg_supply_chain_act &&
                                            breachesAndViolationData
                                              .esg_supply_chain_act.length >
                                            9 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  // setModalVisible(true);
                                                  setBreachesViolationLimit(
                                                    200
                                                  );
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All
                                                </span>
                                              </div>
                                            )}

                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Most frequent violations
                                            </span>
                                          </div>
                                          {activeUngcTab == 0 &&
                                            breachesAndViolationData?.most_frequent &&
                                            breachesAndViolationData
                                              .most_frequent.length > 0 &&
                                            breachesAndViolationData?.most_frequent?.map(
                                              (val, ind) => {
                                                return (
                                                  <UngcpBreaches
                                                    networkData={networkData}
                                                    activeTab={activeUngcTab}
                                                    type={val._id}
                                                    list={val?.incidents?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    totalIncidentCount={
                                                      val?.incidents?.length
                                                    }
                                                    value={val._id}
                                                    dataKey={val.value}
                                                  />
                                                );
                                              }
                                            )}

                                          <div
                                            className="reactionAnalyses"
                                            onClick={() => {
                                              setModalVisible(true);
                                              setModalData({
                                                ...modalData,
                                                voilation:
                                                  "german_supply_chain_act",
                                                start: networkData.start,
                                                end: networkData.end,
                                              });
                                            }}
                                          >
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View All Incidents
                                            </span>
                                          </div>
                                        </div>
                                      </section>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                      key="1"
                                      tab={"Supply Chain Act"}
                                    >
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.breachesIcons}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  Supply Chain Act Violations
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {totalCount(
                                                    breachesAndViolationData?.most_frequent ||
                                                    []
                                                  )}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {breachesAndViolationData?.esg_supply_chain_act &&
                                            breachesAndViolationData
                                              ?.esg_supply_chain_act?.length >
                                            0 && (
                                              <div className="d-flex justify-content">
                                                <Row>
                                                  <span className="unSpanCount">
                                                    Companies with the most
                                                    number of violations
                                                  </span>
                                                </Row>
                                                <div></div>
                                              </div>
                                            )}

                                          <div>
                                            {breachesAndViolationData?.esg_supply_chain_act &&
                                              breachesAndViolationData
                                                ?.esg_supply_chain_act?.length >
                                              0 &&
                                              breachesAndViolationData?.esg_supply_chain_act.map(
                                                (item, index) => {
                                                  return (
                                                    <div className="companiesDataDiv">
                                                      <span>
                                                        {index + 1 + " "}
                                                        <b
                                                          onClick={() =>
                                                            navigate(
                                                              "/companyPage",
                                                              {
                                                                state: {
                                                                  id: item?.id,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {item.name + " "}
                                                        </b>

                                                      </span>

                                                      <div
                                                        style={{
                                                          width: 100,
                                                          height: 19,
                                                          display: "flex",
                                                        }}
                                                      >
                                                        {item.incidentCount +
                                                          " incidents"}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>

                                          {breachesAndViolationData?.esg_supply_chain_act &&
                                            breachesAndViolationData
                                              ?.esg_supply_chain_act?.length >
                                            9 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  // setModalVisible(true);
                                                  setBreachesViolationLimit(
                                                    200
                                                  );
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All
                                                </span>
                                              </div>
                                            )}

                                          {breachesAndViolationData
                                            ?.most_frequent?.length > 0 && (
                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount">
                                                  Most frequent violations
                                                </span>
                                              </div>
                                            )}
                                          {activeUngcTab == 1 &&
                                            breachesAndViolationData?.most_frequent &&
                                            breachesAndViolationData
                                              .most_frequent.length > 0 &&
                                            breachesAndViolationData?.most_frequent?.map(
                                              (val, ind) => {
                                                return (
                                                  <UngcpBreaches
                                                    networkData={networkData}
                                                    activeTab={activeUngcTab}
                                                    type={val._id}
                                                    list={val?.incidents?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    totalIncidentCount={
                                                      val?.incidents?.length
                                                    }
                                                    value={val.value}
                                                    dataKey={val.value}
                                                  />
                                                );
                                              }
                                            )}

                                          {breachesAndViolationData
                                            ?.most_frequent?.length > 0 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  setModalVisible(true);
                                                  setModalData({
                                                    ...modalData,
                                                    voilation:
                                                      "esg_supply_chain_act",
                                                    start: networkData.start,
                                                    end: networkData.end,
                                                  });
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All Incidents
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                      </section>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane key="2" tab={"UNGC"}>
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.un}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  UN global compact principles
                                                  breaches
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {ungcpBreaches?.mostFrequentCount || 0}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Most frequent breaches
                                            </span>
                                          </div>
                                          {ungcpBreaches && ungcpBreaches?.data?.map((val, ind) => {
                                            return (
                                              <UngcpBreaches
                                                networkData={networkData}
                                                activeTab={activeUngcTab}
                                                type={val._id}
                                                list={val?.incidents?.slice(
                                                  0,
                                                  4
                                                )}
                                                totalIncidentCount={
                                                  val?.incidents?.length
                                                }
                                                dataKey={val._id}
                                              />
                                            );
                                          })}
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Total incidents on each principle
                                            </span>
                                          </div>
                                          <>
                                            <PrincipleBarChat
                                              series={BarMapping(ungcpBreaches?.data)}
                                              networkData={networkData}
                                            />

                                            <div className="ellips chart-bottom-legend-New">
                                              {HLEA?.map((data) => {
                                                return (
                                                  <p className="legend">
                                                    <span
                                                      className="legendCircle"
                                                      style={{
                                                        background: data.color,
                                                      }}
                                                    />
                                                    {data.tittle}
                                                  </p>
                                                );
                                              })}
                                            </div>
                                          </>

                                          <div
                                            className="reactionAnalyses"
                                            onClick={() => {
                                              setModalVisible(true);
                                              setModalData({
                                                ...modalData,
                                                voilation: "UNGCP_Breaches",
                                                start: networkData.start,
                                                end: networkData.end,
                                              });
                                            }}
                                          >
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View All Incidents
                                            </span>
                                          </div>
                                        </div>
                                      </section>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane key="3" tab={"PAI"}>
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.pia}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  Principal Adverse Impact (PAI)
                                                  Violations
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {breachesAndViolationData.mostFrequentCount}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content">
                                            <Row>
                                              <span className="unSpanCount">
                                                Companies with the most number
                                                of breaches
                                              </span>
                                            </Row>
                                            <div></div>
                                          </div>

                                          <div>
                                            {activeUngcTab == 3 &&
                                              breachesAndViolationData?.esg_PAI &&
                                              breachesAndViolationData?.esg_PAI
                                                ?.length > 0 &&
                                              breachesAndViolationData?.esg_PAI?.map(
                                                (e, index) => (
                                                  <div className="companiesDataDiv">
                                                    <span>
                                                      {index + 1 + " "}
                                                      <b
                                                        onClick={() =>
                                                          navigate(
                                                            "/companyPage",
                                                            {
                                                              state: {
                                                                id: e?.id,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        {e.name + " "}
                                                      </b>
                                                    </span>

                                                    <div className="Incident_on_ESG">
                                                      {e.incidentCount +
                                                        " incidents"}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </div>

                                          {activeUngcTab == 3 &&
                                            breachesAndViolationData?.esg_PAI &&
                                            breachesAndViolationData?.esg_PAI
                                              ?.length > 9 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  // setModalVisible(true);
                                                  setBreachesViolationPaiLimit(
                                                    200
                                                  );
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All
                                                </span>
                                              </div>
                                            )}
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Most frequent breaches
                                            </span>
                                          </div>
                                          {activeUngcTab == 3 &&
                                            breachesAndViolationData?.most_frequent &&
                                            breachesAndViolationData
                                              ?.most_frequent?.length > 0 &&
                                            breachesAndViolationData?.most_frequent?.map(
                                              (val, ind) => {
                                                return (
                                                  <UngcpBreaches
                                                    networkData={networkData}
                                                    activeTab={activeUngcTab}
                                                    type={val._id}
                                                    list={val?.incidents?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    totalIncidentCount={
                                                      val?.incidents?.length
                                                    }
                                                    dataKey={val.value}
                                                  />
                                                );
                                              }
                                            )}
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Total Number of incidents
                                            </span>
                                          </div>
                                          <>
                                            {activeUngcTab == 3 &&
                                              breachesAndViolationData?.most_frequent &&
                                              breachesAndViolationData
                                                ?.most_frequent?.length > 0 &&
                                              !breachesAndViolationDataLoader && (
                                                <PAIBarChat
                                                  series={BarMappingPAI(
                                                    breachesAndViolationData?.most_frequent
                                                  )}
                                                  networkData={{ ...networkData, companies: [companyData.id], companyName: [companyData.name] }}
                                                />
                                              )}
                                          </>
                                          <div className="pia-bott-hover">
                                            <p
                                              className="onhover-image"
                                              onMouseEnter={() =>
                                                setIsHovered(true)
                                              }
                                              onMouseLeave={() =>
                                                setIsHovered(false)
                                              }
                                            >
                                              <span>
                                                <img
                                                  className={isHovered}
                                                  src={
                                                    isHovered
                                                      ? Images.newImageOnHover
                                                      : Images.circleInfo
                                                  }
                                                  alt=""
                                                />
                                              </span>
                                              14 Principal Adverse Impact (PAI)
                                            </p>

                                            <div className="hover-content-ps">
                                              <div className="adverse-heading-ps">
                                                <h5>
                                                  {" "}
                                                  <span>
                                                    <img
                                                      src={Images.colorSky}
                                                    />
                                                  </span>
                                                  14 Principal Adverse Impact
                                                  (PAI)
                                                </h5>
                                              </div>
                                              <div className="number-of-impact-ps">
                                                <p>
                                                  1. GHG emissions scope 1,2,3,
                                                  total
                                                </p>
                                                <p>2. Carbon footprint</p>
                                                <p>
                                                  3. GHG intensity of investee
                                                  companies
                                                </p>
                                                <p>
                                                  4. Exposure to companies
                                                  active in the fossil fuel
                                                  sector
                                                </p>
                                                <p>
                                                  5. Share of non-renewable
                                                  energy consumption and
                                                  production
                                                </p>
                                                <p>
                                                  6. Energy consumption
                                                  intensity per high impact
                                                  climate sector
                                                </p>
                                                <p>
                                                  7. Activities negatively
                                                  affecting
                                                  biodiversity-sensitive areas
                                                </p>
                                                <p>8. Emissions to water</p>
                                                <p>
                                                  9. Hazardous waste and
                                                  radioactive waste ratio
                                                </p>
                                                <p>
                                                  10. Violations of UN Global
                                                  Compact principles and OECD
                                                  guidelines{" "}
                                                </p>
                                                <p>
                                                  11. Lack of processes and
                                                  compliance mechanisms to
                                                  monitor compliance with UN GC
                                                  and OECD
                                                </p>
                                                <p>
                                                  12. Unadjusted gender pay gap
                                                </p>
                                                <p>
                                                  13. Board gender diversity
                                                </p>
                                                <p>
                                                  14. Exposure to controversial
                                                  weapons
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="reactionAnalyses"
                                            onClick={() => {
                                              setModalVisible(true);
                                              setModalData({
                                                ...modalData,
                                                voilation: "esg_PAI",
                                                start: networkData.start,
                                                end: networkData.end,
                                              });
                                            }}
                                          >
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View All Incidents
                                            </span>
                                          </div>
                                        </div>
                                      </section>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane key="4" tab={"SDG"}>
                                      <section>
                                        <div className="container  g-0  ">
                                          <div className="UNclass">
                                            <img
                                              className="sq104px"
                                              src={Images.sdg}
                                              alt="UN"
                                            />
                                            <div className="d-flex justify-content row">
                                              <Row>
                                                <span className="unSpan">
                                                  UN global compact principles
                                                  breaches
                                                  <img
                                                    className="hover-page"
                                                    style={{ marginLeft: 5 }}
                                                    src={Images.circleInfo}
                                                    alt=""
                                                  />
                                                </span>
                                              </Row>

                                              <div className="d-flex justify-content">
                                                <span className="unSpanCount color05476F pl-16">
                                                  {breachesAndViolationData.mostFrequentCount}{" "}
                                                  Incidents
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content">
                                            <Row>
                                              <span className="unSpanCount">
                                                Companies with the most number
                                                of breaches
                                              </span>
                                            </Row>
                                            <div></div>
                                          </div>

                                          <div>
                                            {activeUngcTab == 4 &&
                                              breachesAndViolationData?.esg_SDG &&
                                              breachesAndViolationData?.esg_SDG
                                                ?.length > 0 &&
                                              breachesAndViolationData?.esg_SDG?.map(
                                                (e, index) => (
                                                  <div className="companiesDataDiv">
                                                    <span>
                                                      {index + 1 + " "}
                                                      <b
                                                        onClick={() =>
                                                          navigate(
                                                            "/companyPage",
                                                            {
                                                              state: {
                                                                id: e?.id,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        {e.name + " "}
                                                      </b>
                                                    </span>

                                                    <div className="Incident_on_ESG">
                                                      {e.incidentCount +
                                                        " incidents"}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </div>

                                          {activeUngcTab == 4 &&
                                            breachesAndViolationData?.esg_SDG &&
                                            breachesAndViolationData?.esg_SDG
                                              ?.length > 9 && (
                                              <div
                                                className="reactionAnalyses"
                                                onClick={() => {
                                                  // setModalVisible(true);
                                                  setBreachesViolationSdgLimit(
                                                    200
                                                  );
                                                }}
                                              >
                                                <span className="reactionAnalysesSpan downl-btn">
                                                  View All
                                                </span>
                                              </div>
                                            )}

                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Most frequent breaches
                                            </span>
                                          </div>
                                          {activeUngcTab == 4 &&
                                            breachesAndViolationData?.most_frequent &&
                                            breachesAndViolationData
                                              ?.most_frequent?.length > 0 &&
                                            breachesAndViolationData?.most_frequent?.map(
                                              (val, ind) => {
                                                return (
                                                  <UngcpBreaches
                                                    networkData={networkData}
                                                    activeTab={activeUngcTab}
                                                    type={val._id}
                                                    list={val?.incidents?.slice(
                                                      0,
                                                      4
                                                    )}
                                                    totalIncidentCount={
                                                      val?.incidents?.length
                                                    }
                                                    dataKey={val.value}
                                                  />
                                                );
                                              }
                                            )}
                                          <div className="d-flex justify-content">
                                            <span className="unSpanCount">
                                              Total Number of incidents on each
                                              goal
                                            </span>
                                          </div>
                                          <>
                                            {activeUngcTab == 4 &&
                                              breachesAndViolationData?.most_frequent &&
                                              breachesAndViolationData
                                                ?.most_frequent?.length > 0 &&
                                              !breachesAndViolationDataLoader && (
                                                <SDGBarChat
                                                  series={BarMappingSDG(
                                                    breachesAndViolationData?.most_frequent
                                                  )}
                                                  networkData={{ ...networkData, companies: [companyData.id], companyName: [companyData.name] }}
                                                />
                                              )}
                                          </>
                                          <div className="pia-bott-hover">
                                            <p
                                              className="onhover-image"
                                              onMouseEnter={() =>
                                                setIsHovered(true)
                                              }
                                              onMouseLeave={() =>
                                                setIsHovered(false)
                                              }
                                            >
                                              <span>
                                                <img
                                                  className={isHovered}
                                                  src={
                                                    isHovered
                                                      ? Images.newImageOnHover
                                                      : Images.circleInfo
                                                  }
                                                  alt=""
                                                />
                                              </span>
                                              17 Sustainable Development Goals
                                            </p>

                                            <div className="hover-content-ps">
                                              <div className="adverse-heading-ps">
                                                <h5>
                                                  {" "}
                                                  <span>
                                                    <img
                                                      src={Images.colorSky}
                                                    />
                                                  </span>
                                                  17 Sustainable Development
                                                  Goals
                                                </h5>
                                              </div>
                                              <div className="number-of-impact-ps">
                                                <p>1. No poverty</p>
                                                <p>2. Zero hunger</p>
                                                <p>
                                                  3. Good health and well-being
                                                </p>
                                                <p>4. Quality education</p>
                                                <p>5. Gender equality</p>
                                                <p>
                                                  6. Clean water and sanitation
                                                </p>
                                                <p>
                                                  7. Affordable and clean energy
                                                </p>
                                                <p>
                                                  8. Decent work and economic
                                                  growth
                                                </p>
                                                <p>
                                                  9. Industry, innovation and
                                                  infrastructure
                                                </p>
                                                <p>10. Reduced inequalities </p>
                                                <p>
                                                  11. Sustainable cities and
                                                  communities
                                                </p>
                                                <p>
                                                  12. Responsible consumption
                                                  and production
                                                </p>
                                                <p>13. Climate action</p>
                                                <p>14. Life below water</p>
                                                <p>15. Life on land</p>
                                                <p>
                                                  16. Peace, justice and strong
                                                  institutions
                                                </p>
                                                <p>
                                                  17. Partnership for the goals
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="reactionAnalyses"
                                            onClick={() => {
                                              setModalVisible(true);
                                              setModalData({
                                                ...modalData,
                                                voilation: "esg_SDG",
                                                start: networkData.start,
                                                end: networkData.end,
                                              });
                                            }}
                                          >
                                            <span className="reactionAnalysesSpan downl-btn">
                                              View All Incidents
                                            </span>
                                          </div>
                                        </div>
                                      </section>
                                    </Tabs.TabPane>
                                  </Tabs>
                                </div>
                              </section>
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
          <Modal
            title={<ModalHeader title={"Add to Watchlist"} />}
            centered
            closeIcon={<ModalCloseButton />}
            className="model-watch"
            open={modal2Open}
            onCancel={() => setModal2Open(false)}
            footer={[
              <button className="can-btn" onClick={() => setModal2Open(false)}>
                Cancel
              </button>,
              <button className="rest-btn" onClick={() => setModal2Open(false)}>
                Done
              </button>,
            ]}
          >
            <div className="empty-watch">
              <div className="first-watch">
                <h5>Watchlist 1</h5>
                <p>Watchlist is empty</p>
              </div>
              <div className="plus-add-btn">
                <button type="button" onclick={() => { }}>
                  <img src={Images.plus} />
                  Add
                </button>
              </div>
            </div>

            <div className="empty-watch">
              <div className="first-watch">
                <h5>Watchlist 2</h5>
                <p>3 companies, 8 locations, 1 industry</p>
              </div>
              <div className="plus-add-btn tcik-added">
                <button type="button">
                  <img src={Images.check} alt="check" />
                  Added
                </button>
              </div>
            </div>

            <div className="list-nam-md">
              <label>List Name</label>
              <div className="text-fild">
                <input type="text" placeholder="Watchlist 2" />
                <button type="button">
                  <img src={Images.plusWhite} alt="plusWhite" />
                  Create
                </button>
              </div>
            </div>
          </Modal>
          {modalVisible ? (
            <IncidentsListModal
              setModalVisible={(e) => {
                setModalVisible(e);
                setModalData(null);
                if (!e) {
                  networkData = { ...networkData, esg_categories: [] };
                }
              }}
              networkData={
                modalData
                  ? { ...modalData }
                  : {
                    ...networkData,
                    companies: [id],
                    companyName: [companyData?.name],
                  }
              }
            />
          ) : null}
          {timelineIndidentList ? (
            <Modal
              className="incidentModal"
              open={timelineIndidentList}
              onCancel={() => setTimelineIndidentList(false)}
              footer={null}
              width={1600}
              centered
            >
              <BubleIncidentsList
                bubbleData={true}
                incidentIdlist={incidentIdArray}
              />
            </Modal>
          ) : null}
          {potfolioModel &&
            (portfolioList?.length === 0 ? (
              <Modal
                title={"Create a portfolio first..."}
                className="potfolio-modal"
                centered
                onCancel={() => {
                  setPotfolioModel(false);
                }}
                closable={false}
                open={potfolioModel}
                destroyOnClose={true}
                footer={[]}
              >
                <div style={{ paddingRight: "25px", paddingLeft: "25px" }}>
                  <p>
                    To add a company to your portfolio, you need to create a
                    portfolio first.
                  </p>
                  <div
                    style={{
                      display: "flex",

                      justifyContent: "center",
                    }}
                  >
                    <img
                      className="potfolio-img"
                      src={ImageConst.potfolio}
                      alt="portfolio"
                    />
                  </div>
                  <p>
                    A portfolio helps you organize and track your investments
                    effectively.
                  </p>
                  <div className="create-potfolio-btn">
                    <button
                      className="can-btn"
                      onClick={() => setPotfolioModel(false)}
                    >
                      Cancel
                    </button>

                    <button
                      className="rest-btn"
                      onClick={() => {
                        navigate("/createPortfolio", {
                          state: {
                            companyName: companyData?.name,
                            companyId: companyData?.id,
                          },
                        });
                      }}
                    >
                      Create New Portfolio
                    </button>
                  </div>
                </div>
              </Modal>
            ) : (
              <Modal
                title={<ModalHeader title={"Add to Portfolio"} />}
                centered
                closeIcon={<ModalCloseButton />}
                className="model-watch"
                open={potfolioModel}
                onCancel={() => setPotfolioModel(false)}
                footer={[
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingLeft: "15px",
                      }}
                    >
                      <div className="plus-btn-text-ps">
                        <img src={Images.plus} alt="plusIcon" />
                        <button
                          className="can-btn-goto"
                          onClick={() =>
                            navigate("/createPortfolio", {
                              state: {
                                companyName: companyData?.name,
                                companyId: companyData?.id,
                              },
                            })
                          }
                        >
                          Create New Portfolio
                        </button>
                      </div>
                    </div>
                    <div>
                      <button
                        className="can-btn"
                        onClick={() => setPotfolioModel(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="rest-btn"
                        onClick={() => setPotfolioModel(false)}
                      >
                        Done
                      </button>
                    </div>
                  </div>,
                ]}
              >
                {portfolioList.map((item) => (
                  <div className="empty-watch">
                    <div className="first-watch">
                      <h5>{item.name}</h5>
                      <p>{`${item?.holdings?.length} companies`}</p>
                    </div>
                    <div
                      className={
                        item?.holdings?.filter(
                          (item) => item.company === companyData.name
                        ).length > 0
                          ? "plus-added-btn"
                          : "plus-add-btn"
                      }
                    >
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/createPortfolio", {
                            state: {
                              portfolioId: item?._id,
                              editStep: 1,
                              screen: "/companyPage",
                              companyName: companyData?.name,
                              companyId: companyData?.id,
                            },
                          });
                        }}
                        disabled={
                          item?.holdings?.filter(
                            (item) => item.company == companyData.name
                          ).length > 0 || false
                        }
                      >
                        {item?.holdings?.filter(
                          (item) => item.company == companyData.name
                        ).length > 0 ? (
                          <img src={Images.check} />
                        ) : (
                          <img src={Images.plus} />
                        )}
                        {item?.holdings?.filter(
                          (item) => item.company == companyData.name
                        ).length > 0
                          ? "Added"
                          : "Add"}
                      </button>
                    </div>
                  </div>
                ))}
              </Modal>
            ))}
          {modalVisibleCompList ? (
            <CompaniesListModalWithAllData
              setModalVisible={setModalVisibleCompList}
              networkData={{
                ...networkData,
                companies: id,
                companyName: companyData?.name,
              }}
            />
          ) : null}
        </div>
      ) : isLoading ? (
        <div className="loaderCorporate">
          <Spin
            spinning={isLoading}
            indicator={
              <div className="outer-table-loader">
                <GlobalLoder />
              </div>
            }
          ></Spin>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
            // height: "85vh",
          }}
        >
          <SearchDocEmptyDpc
            title={"Corporate profile"}
            msg={"Please update the corporate profile"}
          />
        </div>
      )}
    </>
  );
};

const BreadcrumbComponent = ({ companyName }) => {
  const navigate = useNavigate();
  const { searchCompaniesData } = useSelector((state) => state?.global);
  const { searchTerm, handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );
  const [countryName, setCountryName] = useState(null);
  const handleSearchOperation = (SelectedData) => {
    if (searchCompaniesData?.data?.length !== 0) {
      navigate("/companyPage", { state: { id: SelectedData.value } });
    }
  };
  return (
    <div
      className="container g-0 mt-2 d-flex"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          paddingRight: "30px",
        }}
      >
        <Breadcrumb separator="">
          <Breadcrumb.Item key="1" className="apple-top-bread">
            <Link to="/home">Corporate Profile </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="autocomplete-input-search"
          style={{ backgroundColor: "#ffffff" }}
        >
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={500}
            className="serchmedia-icon"
            // options={searchCompaniesData?.data}
            options={renderOptions(searchCompaniesData)}
            size="large"
            onSearch={handleSearch}
            value={countryName}
            onSelect={(e, selectedOption) => {
              const { value } = selectedOption;
              setCountryName(value);
              handleSearchOperation(selectedOption);
            }}
            onChange={(e, i) => {
              setCountryName(e);
            }}
          >
            <Input.Search size="large" placeholder="Search company" />
          </AutoComplete>
        </div>
      </div>
    </div>
  );
};

const MapItem = ({ color, name }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: ".6vw",
        height: ".6vw",
        borderRadius: "100%",
        marginRight: ".5vw",
        backgroundColor: color,
      }}
    ></div>
    <div style={{ fontSize: ".8rem" }}>{name}</div>
  </div>
);

const renderOptions = (searchCompaniesData) => {
  let renderedOptions = searchCompaniesData?.data?.map((option, idx) => {
    return {
      value: option?.id || "",
      dataValue: option?.label || "",
      tickers: option?.tickers || "",
      key: option?.id || "",
      isin: option?.isin || "",
      label: (
        <DoneItem
          searchString={lastSearchString}
          companyName={option?.label || ""}
          ticker={option?.tickers || ""}
          compId={option?.id || ""}
          key={option?.id || ""}
          isin={option?.isin || ""}
          alter_names={option?.alter_names?.length > 0 ? option?.alter_names[0] : ""}
          alter_name={option?.alter_name || ""}
        />
      ),
    }
  })
  return renderedOptions;
};

const DoneItem = ({
  searchString,
  companyName,
  companyIcon,
  ticker,
  key,
  compId = "",
  isin = "",
  alter_name = "",
}) => {
  const highlightedCompanyName = highlightMatch(searchString, companyName?.slice(0, 20), "company");
  const companyEllipse = companyName?.length > 25 ? "..." : ""
  const highlightedTickerSymbol = highlightMatch(searchString, ticker.symbol, "ticker");
  const highlightedTickerID = highlightMatch(searchString, ticker.tickerId, "ticker");
  const highlightedAlter_names = alter_name?.length > 0 ? highlightMatch(searchString, alter_name?.slice(0, 10), "alter") : "";
  const alterEllipse = alter_name?.length > 10 ? "..." : ""
  let highlightedIsin = highlightMatch(searchString, isin, "isin");
  const countryName = ticker?.country?.length > 0 && countries.getName(ticker?.country, "en") || ""
  const countryCode = countryName?.length > 0 && countries.getAlpha2Code(countryName, 'en') || ""
  return (
    <div
      key={key}
      style={{
        display: "flex",
        paddingBottom: 5,
        justifyContent: "space-between",
        alignItems: "end",
        borderBottom: ".5px solid rgb(216,218,224)",
      }}
    >
      <div style={{ width: "100%", height: "35px", gap: "4px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ width: "12%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={`https://logos-svg.komo.systems/${compId}.png` || companyIcon}
            alt="companyImage"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ImageConst.Apple;
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "65%", }}>
          <div
            style={{
              fontSize: "13px",
              fontFamily: "inter",
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: highlightedCompanyName?.trim() + companyEllipse }} />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "10px",
            color: "#4E515C",
            fontFamily: "inter",
          }}>
            <div style={{ overflow: "hidden" }} >
              {countryCode && <ReactCountryFlag countryCode={countryCode} svg={countryName} style={{ width: 12, height: 12, borderRadius: "100%" }} />}
            </div>
            {ticker?.symbol?.length > 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
              {" : "}
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : ticker?.symbol?.length > 0 && ticker?.tickerId?.length == 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
            </div> : ticker?.symbol?.length == 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : null}
          </div>
        </div>
        <div style={{
          width: "28%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          fontSize: "10px",
          color: "#4E515C",
          fontFamily: "inter",
          textAlign: "end"
        }}>
          <span dangerouslySetInnerHTML={{ __html: highlightedAlter_names?.trim() + alterEllipse }} />
          <span dangerouslySetInnerHTML={{ __html: highlightedIsin }} />
        </div>
      </div>
    </div >
  );
};
export default CorporateProfile;
