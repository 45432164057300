import { createStore, applyMiddleware } from "redux";
import logger from 'redux-logger';
import rootReducer from "./Reducer";
// Dev tools
// import { composeWithDevTools } from "redux-devtools-extension";

import createSagaMiddleware from "redux-saga";
import rootSaga from "./Saga";
const sagaMiddleware = createSagaMiddleware();

export const configureStore = () => {
  const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(rootSaga);
  return store;
};
