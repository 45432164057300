import React, { useEffect, useRef, useState } from "react";
import { ChartBottomTitle } from "../../../Componets/ChartBottomTitle";
import {  Row } from "antd";
import { ESG,  } from "../../../Utils";
import Images from "../../../Themes/ImageConstant";
import Styles from "../../dashboardPage/chart/IncidentTimeline/IncidentTimeline.module.css";
import { ChartCard } from "../../../Layouts/ChartCard";
import moment from "moment";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";


const EsgIncidentCategoriesChart = (networkData) => {
  let enviromentDataModify = [];


  const structureData = (dataList, type, key) => {
    const formattedData = [];
    let arr =Object.entries(dataList)
    for (const [dateString, deepArray] of arr) {
      if (deepArray?.length > 0) {
          formattedData.push([
            moment(dateString).format("DD-MM-YYYY HH:mm"),
            1,
            deepArray[0].count,
            deepArray,
            deepArray[0].esg_categories[0]
          ]);
      }
    }
    return formattedData;
  };

  if (networkData?.data?.data && networkData?.data?.data.length > 0) {


    let value = networkData?.data?.data

    value?.forEach((elem, idex) => {
      const series = [
        { name: "Environment", data: [] },
      ];
            let modifiedData = structureData(elem["esg_categories"], elem["esg_categories"]?.groupType, "Calledx");
            series[0].data = modifiedData
      enviromentDataModify.push(series);
    })
  }


  const options = [];


const itemStyle = {
  opacity: 0.8,
  shadowBlur: 10,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowColor: 'rgba(0,0,0,0.3)'
};

  enviromentDataModify?.forEach((each, index) => {

    
    const enviromentOptions = {
      grid: {
        left: '10%',
        right: 150,
        top: '18%',
        bottom: '40%'
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
        
          return `
            <div style="width:400px;margin:5px; border-radius:5px;">
              <div style="font-weight:bold;font-size:11px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                ${params.value[3][0].title}
              </div>
              <div style="margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                ${params.value[0]}
              </div>
              <div class="tooltip-item">Article Count: ${params.value[1]}</div>
              <div class="tooltip-item">Category: ${params.value[4]}</div>
          </div>
            `;
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start:0,
          end:20
        },
        {
          type: 'slider',
          start:0,
          end:20
        }
      ],
      animation: false,
      xAxis: {
        type: 'category',
        splitLine: {
          show: false
        },
      },
      yAxis: {
        type: 'value',
        show:false,
        min:0,
         max: 2,
        nameTextStyle: {
          fontSize: 16
        },
        splitLine: {
          show: false
        }
      },
     
  series: [
    {
      name: each[0].name,
      type: 'scatter',
      itemStyle:{...itemStyle,
      color:function (data) {
        return data.value[4] == "Governance"?"#653dd4": data.value[4] == "Social"? "#2191fb":'#6ebd20'
      }},
      data: each[0].data,
      symbolSize: function (data) {
        return Math.log(Math.sqrt(data[2]) * 17 + 1) * 4; // Adjust bubble size
      },
    },
  ]
    };
    options.push(enviromentOptions);
  })

  const onEvents = {
    click: function (params) {
      window.open(`/incidentdetails?${params.value[3][0].id}`, "_blank");
    },
  };

  return (
    <section className="left-text">
      <ChartCard
        showTimeBarOnTop={false}
        title={"Company ESG Incident Distribution"}
        network={networkData}
        legend={ESG}
      >
        <div className="Sentiment-grah">
          <div className="incidentTimeline-tabs-top-div">
            {networkData?.data?.data.map((each, index) => {

              return (
                <Chart
                  title={each.name}
                  titleImage={Images.blueTile}
                  chartData={options[index]}
                  onEvents={onEvents}
                />
              );
            })}
          </div>
        </div>
      </ChartCard>
    </section>
  );
};


const Chart = ({ title, titleImage, chartData,onEvents }) => {
  return (
    <div className={Styles.chartContainer}>
      <div className={Styles.chartBox}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            style={{ height: "24px", width: "7px", marginRight: "5px" }}
            src={titleImage}
          />
          <p>{title}</p>
        </div>
      </div>
      <div>
        <ChartBottomTitle>
          <ReactECharts
            echarts={echarts}
            option={chartData}
            onEvents={onEvents}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: "190px", width: "650px" }}
          />
        </ChartBottomTitle>
      </div>
    </div>
  );
};

export default EsgIncidentCategoriesChart;
