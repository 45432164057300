import React from 'react';
import { SvgIcon } from '@mui/material';

function SupplyChainActIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
      >
        <path
          d="M15.0001 12.9719V8.98739C14.9999 8.8127 14.9484 8.64114 14.8509 8.4899C14.7534 8.33866 14.6133 8.21307 14.4445 8.12573L10.5556 6.13346C10.3867 6.04603 10.1951 6 10 6C9.805 6 9.61339 6.04603 9.44448 6.13346L5.55556 8.12573C5.38682 8.21307 5.24666 8.33866 5.14915 8.4899C5.05164 8.64114 5.0002 8.8127 5 8.98739V12.9719C5.0002 13.1466 5.05164 13.3182 5.14915 13.4694C5.24666 13.6207 5.38682 13.7463 5.55556 13.8336L9.44448 15.8259C9.61339 15.9133 9.805 15.9593 10 15.9593C10.1951 15.9593 10.3867 15.9133 10.5556 15.8259L14.4445 13.8336C14.6133 13.7463 14.7534 13.6207 14.8509 13.4694C14.9484 13.3182 14.9999 13.1466 15.0001 12.9719Z"
          stroke="url(#paint0_linear_13867_45786)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.14844 8.46875L9.99848 10.984L14.8485 8.46875"
          stroke="url(#paint1_linear_13867_45786)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 16.001V10.9805"
          stroke="url(#paint2_linear_13867_45786)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="10.0009"
          cy="11.0009"
          r="9.06731"
          stroke="url(#paint3_linear_13867_45786)"
          strokeWidth="1.25"
        />
        <circle
          cx="10.1523"
          cy="2"
          r="1.375"
          fill="white"
          stroke="url(#paint4_linear_13867_45786)"
          strokeWidth="1.25"
        />
        <circle
          cx="2"
          cy="6"
          r="1.375"
          fill="white"
          stroke="url(#paint5_linear_13867_45786)"
          strokeWidth="1.25"
        />
        <circle
          cx="2"
          cy="16"
          r="1.375"
          fill="white"
          stroke="url(#paint6_linear_13867_45786)"
          strokeWidth="1.25"
        />
        <circle
          cx="18"
          cy="16"
          r="1.375"
          fill="white"
          stroke="url(#paint7_linear_13867_45786)"
          strokeWidth="1.25"
        />
        <circle
          cx="18"
          cy="6"
          r="1.375"
          fill="white"
          stroke="url(#paint8_linear_13867_45786)"
          strokeWidth="1.25"
        />
        <circle
          cx="10.1523"
          cy="20"
          r="1.375"
          fill="white"
          stroke="url(#paint9_linear_13867_45786)"
          strokeWidth="1.25"
        />
        <defs>
          <linearGradient
            id="paint0_linear_13867_45786"
            x1="14.8685"
            y1="6"
            x2="3.65011"
            y2="8.14448"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_13867_45786"
            x1="14.7209"
            y1="8.46875"
            x2="7.39201"
            y2="13.8496"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_13867_45786"
            x1="10.9868"
            y1="10.9805"
            x2="9.82569"
            y2="11.0245"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_13867_45786"
            x1="19.4381"
            y1="1.30859"
            x2="-2.31434"
            y2="5.44981"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_13867_45786"
            x1="12.0997"
            y1="-3.93914e-07"
            x2="7.6111"
            y2="0.854536"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_13867_45786"
            x1="3.94737"
            y1="4"
            x2="-0.541239"
            y2="4.85454"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_13867_45786"
            x1="3.94737"
            y1="14"
            x2="-0.541239"
            y2="14.8545"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_13867_45786"
            x1="19.9474"
            y1="14"
            x2="15.4588"
            y2="14.8545"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_13867_45786"
            x1="19.9474"
            y1="4"
            x2="15.4588"
            y2="4.85454"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_13867_45786"
            x1="12.0997"
            y1="18"
            x2="7.6111"
            y2="18.8545"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#04DDBC" />
            <stop offset="1" stopColor="#0096CD" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}
export default SupplyChainActIcon;
