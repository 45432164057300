import React from "react";
import { IconButton, SvgIcon } from "@mui/material";

import ButtonContainer from "../ButtonContainer";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import { setShowLayoutSelector } from "../../../redux/GraphExploration/toolBoxSlice";
import LayoutSelectorPopOver from "./LayoutSelectorPopOver";
import CustomTooltip from "../../GraphToolTip/CustomTooltip";

function LayoutSelectorButton() {
  const dispatch = useAppDispatch();
  const showLayoutSelector = useAppSelector(
    (state) => state.toolBox.toolBox.showLayoutSelector
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <>
      <ButtonContainer isActive={showLayoutSelector}>
        <CustomTooltip title="Layout" placement="left">
          <IconButton
            onClick={(event) => {
              dispatch(setShowLayoutSelector(!showLayoutSelector));
              setAnchorEl(event.currentTarget);
            }}
          >
            <SvgIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 13C9 15.2091 10.7909 17 13 17C14.1046 17 15.1046 16.5523 15.8284 15.8284M9 13H5M9 13C9 11.448 9.88388 10.1025 11.1757 9.43928M5 13C5 13.5523 4.55228 14 4 14C3.44772 14 3 13.5523 3 13C3 12.4477 3.44772 12 4 12C4.55228 12 5 12.4477 5 13ZM19.2929 6.70711C19.1119 6.52614 19 6.27614 19 6C19 5.44772 19.4477 5 20 5C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7C19.7239 7 19.4739 6.88807 19.2929 6.70711ZM19.2929 6.70711L15.8284 10.1716M9.37149 4.92872C9.73983 4.78126 10 4.42101 10 4C10 3.44772 9.55228 3 9 3C8.44772 3 8 3.44772 8 4C8 4.55228 8.44772 5 9 5C9.13127 5 9.25664 4.97471 9.37149 4.92872ZM9.37149 4.92872L11.1757 9.43928M15.8284 15.8284C16.5523 15.1046 17 14.1046 17 13C17 11.8954 16.5523 10.8954 15.8284 10.1716M15.8284 15.8284L19.2929 19.2929M19.2929 19.2929C19.1119 19.4739 19 19.7239 19 20C19 20.5523 19.4477 21 20 21C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19C19.7239 19 19.4739 19.1119 19.2929 19.2929ZM15.8284 10.1716C15.1046 9.44772 14.1046 9 13 9C12.3428 9 11.7227 9.15847 11.1757 9.43928"
                  stroke="#4E515C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
          </IconButton>
        </CustomTooltip>
      </ButtonContainer>
      <LayoutSelectorPopOver anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
}

export default LayoutSelectorButton;
