import React, { useLayoutEffect } from "react";
import PortfolioCard from "./components/PortfolioCard";
import { useSelector } from "react-redux";
import style from "./style.module.css";
import clsx from "clsx";
import HeaderComponent from "./components/HeaderComponent";
import { navigate } from "../../Utils/dispatchNavigate";
import { useDispatch } from "react-redux";
import { getPortfolioListLoad, updateUploadFilePortFolioAction } from "../../Redux/Action/portfolio";
import SearchDocEmptyDpc from "../../Componets/searchDocEmptyCom";
import { useState } from "react";
import GlobalSpinner from "../../Utils/GlobalSpinner";
import { addEmpty4Fields } from "./CreatePortfolio/utils";

const Portfolio = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const portfolioList = useSelector((state) => state?.portfolio?.portfolioList);
  const handleButtonClick = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setLoading(false);
    navigate("/createPortfolio", {
      state: {
        newCreate: true
      }
    });
  };
  useLayoutEffect(() => {
    dispatch(getPortfolioListLoad());
  }, []);

  return (
    <>
      <div
        className={`${clsx(
          portfolioList && portfolioList.length > 0
            ? style.maxContainer
            : style.maxContainerCstmHeight
        )}`}
      >
        {portfolioList && portfolioList.length > 0 ? (
          <div className={clsx(style.mainContainer)}>
            <HeaderComponent name={"My Portfolios"} />
            <div className={style.subComponent}>
              <span className={style.subComponentText}>{"My Portfolios"}</span>
              <div>
                <button
                  type="submit"
                  className={style.btn}
                  onClick={handleButtonClick}
                  disabled={loading}
                >
                  {"Create New Portfolio"}
                </button>
              </div>
            </div>
            <div style={{ height: 32, width: "100%" }}></div>
            <div className={style.gridCard}>
              {portfolioList &&
                portfolioList.length > 0 &&
                portfolioList?.map((item) => (
                  <PortfolioCard item={item} code={"portfolio"} />
                ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
              // height: "85vh",
            }}
          >
            <SearchDocEmptyDpc
              title={"Your Portfolio is empty!"}
              msg={"Create portfolios to organize and track your investments "}
              showBtn
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Portfolio;
