import React, { memo, useCallback } from "react";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import EChartsReact from "echarts-for-react";
import { ESGObj, checkColor } from "../../../../Utils";
import * as echarts from "echarts/core";

var graphic = echarts.graphic;

const color = (color) => {
  return new graphic.LinearGradient(0, 0, 0, 1.5, [
    {
      offset: 0,
      color: color,
    },
    {
      offset: 1,
      color: "rgba(255,255,255,0.8)",
    },
  ]);
};

const IncidentDistribution = ({ data, onClick }) => {
  const option = {
    chart: {
      width: 200,
    },

    silent: false,
    // title : {
    //   text: '某站点用户访问来源',
    //   subtext: '纯属虚构',
    //   x:'center'
    // },
    // tooltip : {
    //   trigger: 'item',
    //   formatter: "{a} <br/>{b} : {c} ({d}%)"
    // },
    // legend: {
    //   orient: 'vertical',
    //   left: 'left',
    //   data: ['直接访问','邮件营销','联盟广告','视频广告','搜索引擎']
    // },
    series: [
      {
        label: {
          color: "#fff",
          textBorderColor: "#fff",
        },
        itemStyle: {
          borderRadius: 7,
          borderWidth: 5,
        },
        radius: ["0", "80%"],
        type: "sunburst",
        emphasis: {
          focus: "ancestor",
        },
      },
    ],
  };
  const dataMapping = useCallback(
    (data) => {
      const finalArr = [];
      if (data) {
        for (let i of Object.keys(data)) {
          const parentObj = {};
          parentObj["children"] = [];
          parentObj.name = i.split("").slice(0, 1)[0];
          const itemArrayLength = {};
          const OBJ = data[i];
          for (let j in OBJ) {
            itemArrayLength[j] = OBJ[j]?.length;
          }

          parentObj.value = Object.values(itemArrayLength).reduce(
            (acc, item) => acc + item
          );

          parentObj.itemStyle = {
            color: color(ESGObj[i?.toLowerCase()]),
          };
          Object.entries(itemArrayLength)?.forEach((item) => {
            const innerObj = {};
            innerObj.name = item[1];
            innerObj.value = item[1];
            innerObj._name = item[0];
            innerObj.parent = parentObj.name;
            innerObj.itemStyle = {
              color: new graphic.LinearGradient(0, 0, 0, 1.5, [
                {
                  offset: 0,
                  color: checkColor[item[0].toUpperCase()],
                },
                {
                  offset: 1,
                  color: "rgba(255,255,255,0.8)",
                },
              ]),
            };
            parentObj.children.push(innerObj);
          });
          finalArr.push(parentObj);
        }
      }
      option.series[0].data = finalArr;
      return option;
    },
    [data]
  );



  function onChartClick(param) {
    const parent = {
      S: "Social",
      G: "Governance",
      E: "Environmental",
    };
    let _parent = param.data.parent;
    let Parent = parent[_parent];
    let child = param.data._name;

    onClick({ Parent, child, _parent, param });
  }

  function onChartLegendselectchanged(param, echarts) {
    
  }

  // const onEvents = {
  //   brush: onChartClick,
  // };

  return (
    <>
      <EChartsReact
        className="doubleLayer-pieChart"
        option={JSON.parse(JSON.stringify(dataMapping(data)))}
        onEvents={{
          click: onChartClick,
          legendselectchanged: onChartLegendselectchanged,
        }}
      />
    </>
  );
};

export default memo(IncidentDistribution);
