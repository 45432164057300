import { InputBase, Stack, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchBar({ value, onChange }) {
  const theme = useTheme();
  // const selectedRelation = useAppSelector(
  //   (state) => state.contextMenu.selectedRelation,
  // );
  return (
    <Stack flexDirection="row" gap={1} height="40px" padding="4px 12px">
      <InputBase
        placeholder="Search company name"
        fullWidth
        value={value}
        style={{ ...theme.typography.captionS }}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        sx={{
          border: `1px solid ${theme.palette.gray.A200}`,
          background: theme.palette.white.main,
          borderRadius: '6px',
          px: 1,
          bgcolor: 'white',
          height: theme.constants.contextMenu.searchIconHeight,
        }}
        startAdornment={
          <SearchIcon
            sx={{
              color: theme.palette.mediumGray2.main,
              fontSize: '22px',
              paddingRight: '8px',
            }}
          />
        }
      />
    </Stack>
  );
}
export default SearchBar;
