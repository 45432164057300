/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { FilterParametersEnum } from "../../enums/FilterSideBarEnums";
import { RESET_APP_STATE } from "../reducers";

const initialState = {
  location: undefined,
  loading: false,
  targetNodeId: undefined,
  sideBarNodeId: undefined,
  show: false,
  comboNodeId: undefined,
  showPathFindingModal: false,
  pathFindingMode: "shortest",
  showCompanyInfoSideBar: false,
  setShowCompanyActionSideBar: false,
  currentAction: undefined,
  showSpecificRelationsModal: false,
  selectedRelation: undefined,
  currentTab: FilterParametersEnum.CUSTOMER,
  selectedRelationDirection: undefined,
  selectedEntities: [],
  searchQuery: "",
};
const rightClickMenuSlice = createSlice({
  name: "rightClickMenu",
  initialState,
  reducers: {
    setRightClickMenuLocation: (state, { payload: contextMenuLocation }) => {
      state.location = contextMenuLocation;
    },
    setRightClickMenuLoading: (state, { payload: contextMenuLoading }) => {
      state.loading = contextMenuLoading;
    },
    setRightClickMenuTargetNodeId: (state, { payload: targetNodeId }) => {
      state.targetNodeId = targetNodeId;
    },
    setRightClickMenuSideBarNodeId: (state, { payload: sideBarNodeId }) => {
      state.sideBarNodeId = sideBarNodeId;
    },
    setRightClickMenuShow: (state, { payload: show }) => {
      state.show = show;
    },
    setComboNodeId: (state, { payload: comboNode }) => {
      state.comboNodeId = comboNode;
    },
    setShowPathFindingModal: (state, { payload: showPathFindingModal }) => {
      state.showPathFindingModal = showPathFindingModal;
    },
    setPathFindingMode: (state, { payload: pathFindingMode }) => {
      state.pathFindingMode = pathFindingMode;
    },
    setShowCompanyInfoSideBar: (state, { payload: showCompanyInfoSideBar }) => {
      state.showCompanyInfoSideBar = showCompanyInfoSideBar;
    },
    setShowCompanyActionSideBar: (
      state,
      { payload: showCompanyActionSideBar }
    ) => {
      state.showCompanyActionSideBar = showCompanyActionSideBar;
    },
    setCurrentAction: (state, { payload: currentAction }) => {
      state.currentAction = currentAction;
    },
    setShowSpecificRelationsModal: (
      state,
      { payload: showSpecificRelationsModal }
    ) => {
      state.showSpecificRelationsModal = showSpecificRelationsModal;
    },
    setModalCurrentTab: (state, { payload: currentTab }) => {
      state.currentTab = currentTab;
    },
    setSelectedRelation: (state, { payload: selectedRelation }) => {
      state.selectedRelation = selectedRelation;
    },
    setSelectedEntities: (state, { payload: selectedEntities }) => {
      state.selectedEntities = selectedEntities;
    },
    setSearchQuery: (state, { payload: searchQuery }) => {
      state.searchQuery = searchQuery;
    },
    setSelectedRelationDirection: (
      state,
      { payload: selectedRelationDirection }
    ) => {
      state.selectedRelationDirection = selectedRelationDirection;
    },
    resetRightClickMenu: (state) => {
      state.currentTab = initialState.currentTab;
      state.show = initialState.show;
      state.selectedRelation = initialState.selectedRelation;
      state.selectedRelationDirection = initialState.selectedRelationDirection;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});
export const {
  setRightClickMenuLocation,
  setRightClickMenuLoading,
  setRightClickMenuTargetNodeId,
  setRightClickMenuShow,
  resetRightClickMenu,
  setComboNodeId,
  setShowPathFindingModal,
  setPathFindingMode,
  setShowCompanyInfoSideBar,
  setShowCompanyActionSideBar,
  setCurrentAction,
  setShowSpecificRelationsModal,
  setModalCurrentTab,
  setSelectedRelation,
  setSelectedRelationDirection,
  setSearchQuery,
  setSelectedEntities,
  setRightClickMenuSideBarNodeId,
} = rightClickMenuSlice.actions;
export const RightClickMenuReducer = rightClickMenuSlice.reducer;
