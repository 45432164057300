import { apiConstants as types } from "../../Constants/apiConstants";

export const homePageAction = (data) => ({
  type: types.API_HOME_PAGE_LOAD,
  data,
});

export const incindentAction = (data) => ({
  type: types.API_INCIDENT_LOAD,
  data,
});

export const companiesWithIncidenAction = (data) => ({
  type: types.API_COMPANIES_WITH_INCIDENT_LOAD,
  data,
});

export const companiesIdSearchListAction = (data) => {
  return {
    type: types.COMPANY_ID_SEARCH_LIST,
    data,
  };
};

export const locationIdSearchListAction = (data) => {
  return {
    type: types.LOCATION_ID_SEARCH_LIST,
    data,
  };
};

export const countriesWithIncidenAction = (data) => {
  return {
    type: types.API_COUNTRIES_WITH_INCIDENT_LOAD,
    data,
  };
};

export const esgFactorsAction = (data) => ({
  type: types.API_ESG_FACTORS_INCIDENTS_LOAD,
  data,
});
export const esgSdgAction = (data) => ({
  type: types.API_ESG_SDG_INCIDENTS_LOAD,
  data,
});
export const esgPaiAction = (data) => ({
  type: types.API_ESG_PAI_INCIDENTS_LOAD,
  data,
});
// esg category
export const esgCategoryAction = (data) => ({
  type: types.API_ESG_CAT_LOAD,
  data,
});
//esg iot
export const esgIotAction = (data) => ({
  type: types.API_ESG_IOT_LOAD,
  data,
});
// get companies
export const getCompaniesAction = (data) => ({
  type: types.API_GET_COMPANIES_LOAD,
  data,
});
//get industry filter
export const getIndustryAction = (data) => ({
  type: types.API_GET_INDUSTRY_LOAD,
  data,
});

export const updateIncidentData = (data) => {
  return {
    type: types.UPDATE_INDUSTRIES_DATA,
    payload: data,
  };
};
export const updateCategoriesData = (data) => {
  return {
    type: types.UPDATE_CATEGORIES_DATA,
    payload: data,
  };
};

export const getTopSearchAction = (data) => ({
  type: types.API_GET_TOP_SEARCH__LOAD,
  data,
});
//get search categories
export const getCategoriesAction = (data) => ({
  type: types.API_GET_CATEGORIES_LOAD,
  data,
});
//selected  companies
export const selectCompaninesAction = (data) => ({
  type: types.SELECT_COMPANIES,
  data,
});
//selected Top Search
export const selectedTopSearch = (data) => ({
  type: types.SELECTED_TOP_SEARCH,
  data,
});
//selected  companies
export const selectCompaninesResetAction = (data) => ({
  type: types.SELECT_COMPANIES,
});
//selected Top Search
export const selectedTopResetSearch = (data) => ({
  type: types.SELECTED_TOP_SEARCH,
});
//incident Detail

export const incidentDetailAction = (data) => ({
  type: types.API_INCIDENT_DETAILS_LOAD,
  data,
});

// selected categories
export const selectedCategories = (data) => ({
  type: types.SELECTED_CATEGORIES,
  data,
});
export const updateCategories = (data) => ({
  type: types.UPDATE_CATEGORIES_DATA,
  data,
});

export const updateIncidentLoactionData = (data) => {
  return {
    type: types.UPDATE_INDUSTRIES_DATA,
    payload: data,
  };
};
export const getCountriesWithIncidenceAction = (data) => ({
  type: types.API_GET_INCIDENCE_WITH_COUNTRIES_LOAD,
  data,
});
export const getSourceDetailsAction = (data) => ({
  type: types.API_GET_SOURCE_DETAILS_LOAD,
  data,
});

export const getNewsArticleAction = (data) => ({
  type: types.API_GET_NEWS_ARTICLE_LOAD,
  data,
});
//Incident Map Data
export const incidentMapAction = (data) => {
  return {
    type: types.API_INCIDENT_MAP_DATA_LOAD,
    data,
  };
};
export const resetMapData = () => {
  return {
    type: types.RESET_MAP_DATA
  };
};

export const severityMapDataAction = (data) => ({
  type: types.SEVERITY_MAP_DATA_ARRAY,
  payload: data,
});

export const incidentMapViewStateAction = (data) => ({
  type: types.GOOGLE_MAP_VIEW_STATE,
  data,
});

//search by params
export const searchByParamsAction = (data) => ({
  type: types.API_SEARCH_BY_PARAMS_LOAD,
  data,
});
export const getDownloadDataAction = (data) => ({
  type: types.API_GET_DOWNLOAD_INCIDENT_LOAD,
  data,
});

export const getExportSupplyAction = (data) => ({
  type: types.API_GET_EXPORT_INCIDENT_LOAD,
  data,
});
export const getEsgFactorsAction = (data) => ({
  type: types.API_GET_ESG_FACTORS_LOAD,
  data,
});

export const getEsgFactorListForModalAction = (data) => ({
  type: types.API_ESG_CHILD_LIST_FOR_MODAL_LOAD,
  data,
});

export const getCountryListForModalAction = (data) => ({
  type: types.API_COUNTRIES_LIST_FOR_MODAL_LOAD,
  data,
});

export const getCompanyListForModal = (data) => ({
  type: types.API_COMPANIES_LIST_FOR_MODAL_LOAD,
  data,
});
export const getEsgCountriesAction = (data) => ({
  type: types.API_GET_ESG_COUNRTIES_LOAD,
});

export const incindentListModalAction = (data) => ({
  type: types.INCIDENT_LIST_MODAL,
  data,
});

export const filterESGMapDataAction = (payload) => ({
  type: types.FILTER_ESG_MAP_DATA,
  payload,
});

export const filterSevertyMapDataAction = (payload) => ({
  type: types.FILTER_SEVERTY_MAP_DATA,
  payload,
});

export const getSocialMediaIncidentAction = (payload) => ({
  type: types.GET_SOCIAL_MEDIA_INCIDENT_LOAD,
  payload,
});

export const getFineAndpenaltyAction = (payload) => ({
  type: types.GET_FINE_AND_PANELTY_LOAD,
  payload,
});

export const geRegulatorAndLegalAction = (payload) => ({
  type: types.GET_REGULATORY_LEGAL_LOAD,
  payload,
});

export const getTimeLineGraphAction = (payload) => ({
  type: types.GET_TIMELINE_GRAPH_LOAD,
  payload,
});

export const feedbackAction = (payload) => ({
  type: types.POST_FEEDBACK_LOAD,
  payload,
});

export const updateIncidentState = (data, key) => ({
  type: types.UPDATE_INCIDENT_STATE,
  payload: data,
  key,
});

export const getIncidentListForModalAction = (data) => ({
  type: types.API_INCIDENT_LIST_FOR_MODAL_LOAD,
  data: data,
});

export const updateParentInduAction = (payload) => ({
  type: types.UPDATE_WATCHLIST_INDUSTRIES,
  payload,
});

export const directUpdateParentInduAction = (payload) => ({
  type: types.DIRECT_UPDATE_WATCHLIST_INDUSTRIES,
  payload,
});

export const removeParentInduAction = (payload) => ({
  type: types.REMOVE_WATCHLIST_INDUSTRIES,
  payload,
});

export const updateIndustriesForWatchlist = (data) => {
  return {
    type: types.API_UPDATE_INDUS_WISHLIST_DETAILS,
    data,
  };
};

export const IndustriesForWatchlistAction = (data) => {
  return {
    type: types.CHECK_UPDATE_INDUS_WISHLIST_DETAILS,
    data,
  };
};

export const updateCompanyArr = (data) => {
  return {
    type: types.UPDATE_WATCHLIST_COMPANY,
    data,
  };
};

export const updateIndustryArr = (data) => ({
  type: types.UPDATE_WATCHLIST_INDUS_SEARCH,
  data,
});

export const homeRiskScoreLoad = (data) => ({
  type: types.HOME_ESG_RISK_LOAD,
  data,
});

export const homeRiskScoreSuccess = (data, obj) => ({
  type: types.HOME_ESG_RISK_SUCCESS,
  payload: data,
  key: obj,
});

export const homeHighSeverityLoad = (data) => ({
  type: types.HOME_HIGH_SEVERITY_LOAD,
  data,
});

export const homeHighSeveritySuccess = (data, obj) => ({
  type: types.HOME_HIGH_SEVERITY_SUCCESS,
  payload: data,
  key: obj,
});

export const HOMEIotAction = (data) => ({
  type: types.API_HOME_ESG_IOT_LOAD,
  data,
});

export const getSocialMediaReactionAction = (data) => ({
  type: types.HOME_SOCIAL_MEDIA_REACTIONS_LOAD,
  payload: data
});

export const getIncidentCountLoad = (data) => ({
  type: types.GET_INCIDENT_COUNT_LOAD,
  payload: data,
});

export const getIncidentCountSuccess = (data) => ({
  type: types.GET_INCIDENT_COUNT_SUCCESS,
  payload: data,
});

export const getIncidentCountFailure = (data) => ({
  type: types.GET_INCIDENT_COUNT_FAIL,
  payload: data,
});

export const updateCompaniesAction = (data) => ({
  type: types.UPDATE_COMPANIES_SUCCESS,
  data,
});


export const updateLoader = (data) => ({
  type: types.UPDATE_LOADER,
  data
})

export const updateParentAction = (payload) => ({
  type: types.REMOVE_WATCHLIST_INDUSTRIES_NEW,
  payload

})