import React from "react";
import { IconButton, SvgIcon } from "@mui/material";

import ButtonContainer from "../ButtonContainer";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import { setShowNodeSelector } from "../../../redux/GraphExploration/toolBoxSlice";
import CustomTooltip from "../../GraphToolTip/CustomTooltip";
import NodeSelectorPopOver from "./NodeSelectorPopOver";

function NodeSelectorButton() {
  const dispatch = useAppDispatch();
  const showNodeSelector = useAppSelector(
    (state) => state.toolBox.toolBox.showNodeSelector
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <>
      <ButtonContainer isActive={showNodeSelector}>
        <CustomTooltip title="Node Selector" placement="left">
          <IconButton
            onClick={(event) => {
              dispatch(setShowNodeSelector(!showNodeSelector));
              setAnchorEl(event.currentTarget);
            }}
          >
            <SvgIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 3H6.8C5.81991 3 5.32986 3 4.95552 3.19074C4.62623 3.35852 4.35852 3.62623 4.19074 3.95552C4 4.32986 4 4.81991 4 5.8V6.5M4 9.125V10.875M4 13.5V14.2C4 15.1801 4 15.6701 4.19074 16.0445C4.35852 16.3738 4.62623 16.6415 4.95552 16.8093C5.32986 17 5.81991 17 6.8 17H7.5M10.125 17H11.875M14.5 17H15.2C16.1801 17 16.6701 17 17.0445 16.8093C17.3738 16.6415 17.6415 16.3738 17.8093 16.0445C18 15.6701 18 15.1801 18 14.2V13.5M18 10.875V9.125M18 6.5V5.8C18 4.81991 18 4.32986 17.8093 3.95552C17.6415 3.62623 17.3738 3.35852 17.0445 3.19074C16.6701 3 16.1801 3 15.2 3H14.5M11.875 3H10.125"
                  stroke="#4E515C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.9149 16.3605C22.251 16.2298 22.419 16.1645 22.4681 16.0703C22.5106 15.9887 22.5106 15.8915 22.4682 15.8098C22.4193 15.7156 22.2513 15.65 21.9154 15.5189L12.3817 11.7985C12.0784 11.6801 11.9268 11.6209 11.8299 11.6533C11.7458 11.6814 11.6797 11.7474 11.6516 11.8316C11.6193 11.9285 11.6784 12.0801 11.7968 12.3834L15.5172 21.917C15.6483 22.253 15.7139 22.4209 15.8081 22.4699C15.8898 22.5123 15.987 22.5123 16.0687 22.4697C16.1628 22.4207 16.2282 22.2526 16.3589 21.9165L17.8425 18.1014C17.8691 18.0332 17.8823 17.9991 17.9028 17.9704C17.921 17.9449 17.9432 17.9226 17.9687 17.9045C17.9974 17.884 18.0315 17.8707 18.0998 17.8442L21.9149 16.3605Z"
                  fill="#4E515C"
                />
              </svg>
            </SvgIcon>
          </IconButton>
        </CustomTooltip>
      </ButtonContainer>
      <NodeSelectorPopOver anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
}

export default NodeSelectorButton;
