import { SvgIcon } from "@mui/material";

export default function MagicWandIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33502 4.66536V7.33203M6.66836 1.33203V3.9987M12.0017 10.6654V13.332M2.00169 5.9987H4.66836M5.33502 2.66536H8.00169M10.6684 11.9987H13.335M9.33502 4.66536L11.2206 6.55098M13.0128 2.49611L13.3899 2.87323C13.6539 3.13724 13.7859 3.26925 13.8354 3.42147C13.8789 3.55536 13.8789 3.6996 13.8354 3.83349C13.7859 3.98571 13.6539 4.11772 13.3899 4.38173L4.352 13.4196C4.08799 13.6836 3.95599 13.8156 3.80377 13.8651C3.66987 13.9086 3.52564 13.9086 3.39175 13.8651C3.23953 13.8156 3.10752 13.6836 2.84351 13.4196L2.46639 13.0425C2.20237 12.7785 2.07037 12.6465 2.02091 12.4943C1.9774 12.3604 1.9774 12.2161 2.02091 12.0822C2.07037 11.93 2.20237 11.798 2.46639 11.534L11.5043 2.49611C11.7683 2.2321 11.9003 2.10009 12.0525 2.05063C12.1864 2.00713 12.3306 2.00713 12.4645 2.05063C12.6168 2.10009 12.7488 2.2321 13.0128 2.49611Z"
          stroke="#9A9CA2"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
