import React, { useState, useEffect } from "react";
import { Alert, Input, Layout, Menu, Row } from "antd";
import { useLocation } from "react-router";
import { Button, Popover } from "antd";
import { Modal } from "antd";
import { Switch } from "antd";
import { Select, Space } from "antd";

import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import Images from "../../Themes/ImageConstant";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import {toast as hotToast } from 'react-hot-toast';
import { toast } from "react-toastify";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  addWishListAction,
  deleteWishlistAction,
  editWishListAction,
  getCurrentWatchlistAction,
  getWishListDetailAction,
  getWishlistAction,
  selectedIncidentWatchList,
} from "../../Redux/Action/watchListActions";
import Item from "antd/es/list/Item";
import ImageConst from "../../Themes/ImageConstant";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";
import { errorToastContainer } from "../../Utils";
import { isInternetConnected } from "../../Utils/InternetConnection";

const { Header, Content, Footer, Sider } = Layout;

const WatchlistSideBar = (props) => {
  const [currentActiveButton, setCurrentActiveButton] = useState("weekly");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [count, setCount] = useState(0);

  const [updateListName, setUpdateListName] = useState({
    inputVal: "",
    isCard: false,
  });
  const watchListData = useSelector(
    (state) => state?.watchListReducer?.watchlist
  );
  // .......dropdown

  const [age, setAge] = React.useState("");
  const [listName, setListname] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  // ...........

  const emailButtonClickHandle = (type) => {
    setCurrentActiveButton(type);
  };

  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [currentId, setCurrentId] = useState(watchListData[0]?._id);
  const watchListId = useSelector(
    (state) => state?.watchListReducer?.watchListId
  );
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const handleToggle = () => {
    setCollapse(!collapse);
  };

  const hide = (id, name) => {
    setUpdateListName({ inputVal: name, isCard: true });
    setCurrentId(id);
    setModal2Open(true);
    setOpen(false);
  };

  const deleteButtonClick = (i, id, data) => {
    setCurrentId(id);
    setOpen(false);
    setOpenDeleteModel(open);
    setListname(data.name);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const checkName = (data, number) => {
    let newName = `Watchlist ${number}`;
    if (!data.includes(newName)) {
      return newName;
    } else {
      return checkName(data, number + 1);
    }
  };

  let navigate = useNavigate();
  let location = useLocation();

  const onMenuClick = (e) => {
    navigate(e.key);
    // history.push(e.key);
  };

  let activeKey = "";
  switch (location.pathname) {
    case "/watchlist":
      activeKey = "/watchlist";
      break;
  }
  if (currentIndex === 0) {
    dispatch(getWishListDetailAction(watchListData[0]?._id));
  }

  useEffect(() => {
    dispatch(getWishlistAction());
    // dispatch(getWishListDetailAction( watchListData[0]?._id))
  }, []);

  useEffect(() => {
    dispatch(
      selectedIncidentWatchList(
        watchListData[currentIndex ? currentIndex : 0]?.name
      )
    );
  }, [watchListData]);

  return (
    <div className="left-side-padding">
      <Sider collapsed={collapse}>
        <div className="admin-text">
          {collapse ? (
            <>
              <p>Watchlist</p>
            </>
          ) : (
            <h2>Watchlists</h2>
          )}
        </div>

        <div className="main-sidebar-div">
          <div>
            <Menu
              mode="inline"
              defaultSelectedKeys={"1"}
              defaultOpenKeys={[activeKey]}
              selectedKeys={[activeKey]}
            >
              {watchListData?.map((item, index) => (
                <Menu.Item
                  key={item.id}
                  aria-rowindex={item.id + index}
                  title={"name"}
                  className={index === currentIndex ? "selected" : ""}
                >
                  <div
                    style={{ width: "100%" }}
                    onClick={() => {
                      setCurrentIndex(index);
                      setListname(item?.name);
                      dispatch(selectedIncidentWatchList(item?.name));
                      dispatch(getWishListDetailAction(item._id));
                      dispatch(getCurrentWatchlistAction(item._id));
                    }}
                    className="dots"
                  >
                    <span className="line_dash">
                      {collapse ? item.name.slice(-4) : item.name}
                    </span>
                  </div>
                  {/* </Link> */}
                  <span>
                    <Popover
                      key={item?._id}
                      overlayClassName="threeDot-pop"
                      placement="bottomRight"
                      content={
                        <div className="popup" style={{ width: "160px" }}>
                          {index === currentIndex ? (
                            <div
                              onClick={() => {
                                hide(item?._id, item?.name);
                              }}
                              className="edit-rename bg-edit"
                            >
                              <a onClick={() => {}}>
                                <img src={Images.edit} />
                                Rename
                              </a>
                            </div>
                          ) : null}

                          {index === currentIndex ? (
                            <div
                              onClick={() => {
                                deleteButtonClick(index, item?._id, item);
                              }}
                              className="delet-btn-in"
                            >
                              <a onClick={() => {}}>
                                <img src={Images.deleteRed} />
                                Delete
                              </a>
                            </div>
                          ) : null}
                        </div>
                      }
                      title=""
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <img
                        onClick={() => {
                          setCurrentIndex(index);
                        }}
                        src={Images.dots}
                      />
                    </Popover>
                  </span>
                </Menu.Item>
              ))}

              {/* <div className="angle-btn-bott">
                {collapse ? (
                  <RightOutlined onClick={handleToggle} />
                ) : (
                  <LeftOutlined onClick={handleToggle} />
                )}
              </div> */}
            </Menu>
          </div>
        </div>
        <div className="foot-sec">
          {collapse ? (
            <>
              <a
                onClick={() => {
                  dispatch(
                    addWishListAction({
                      name: `Watchlist ${watchListData?.length + 1}`,
                      locations: [],
                      companies: [],
                      esg_factors: [],
                      esg_sdgs: [],
                      esg_pais: [],
                      industries: [],
                    })
                  );
                }}
                href="#"
              >
                <img src={Images.plus} />{" "}
              </a>
            </>
          ) : (
            <a
              style={{
                color: "#0195CE",
                display: "flex",

                justifyContent: "center",
              }}
              onClick={() => {
                const watchlistNameArray = watchListData.map(
                  (item) => item.name
                );
                let name = checkName(
                  watchlistNameArray,
                  watchListData?.length + 1
                );
                dispatch(
                  addWishListAction({
                    name: name,
                    locations: [],
                    companies: [],
                    esg_factors: [],
                    esg_sdgs: [],
                    esg_pais: [],
                    industries: [],
                  })
                );
              }}
            >
              <img src={Images.plus} />
              <p
                style={{
                  marginLeft: "5px",
                  color: "#0195CE",
                  fontStyle: "Inter",
                }}
              >
                Create New Watchlist
              </p>
            </a>
          )}
        </div>
      </Sider>
      <Modal
        title={<ModalHeader title={"Rename Watchlist"} />}
        className="rename-modal-ps"
        centered
        open={modal2Open}
        closeIcon={<ModalCloseButton />}
        onCancel={() => setModal2Open(false)}
        footer={[
          <button
            className="can-btn chang"
            onClick={() => setModal2Open(false)}
          >
            Cancel
          </button>,
          <button
            className="rest-btn"
            onClick={() => {
              if (isInternetConnected()) { 
                if (updateListName.inputVal.trim() == "") {
                  toast.error("The watchlist name should not be empty.", {
                    position: toast.POSITION.TOP_RIGHT,
                    id: 'validFolder'
                  });
                } else {
                  
              const watchlistNameArray = watchListData.map((item) => item.name);
              if (!watchlistNameArray.includes(updateListName.inputVal)) {
                dispatch(
                  editWishListAction({
                    wishlist: currentId,
                    name: updateListName.inputVal,
                    locations: null,
                    companies: null,
                    esg_factors: null,
                    esg_sdgs: null,
                    esg_pais: null,
                    industries: null,
                  })
                );
                setModal2Open(false);
              } else {
                return  toast.error( "Watchlist name already exists. Choose a different one.", { toastId: "fail" });
              }
            }
            }}
          }
          >
            Save
          </button>,
        ]}
      >
        <div className="reset-setting folder-num">
          <p>Watchlist Name</p>
          <div className="in-put-popup" style={{ paddingRight: "0px" }}>
            {updateListName.isCard ? (
              <div
                style={{
                  border: "1px solid #4295ce",
                  padding: "12px",
                  display: "flex",
                  borderRadius: "4px",
                }}
                onClick={() =>
                  setUpdateListName({ ...updateListName, isCard: false })
                }
              >
                <div
                  style={{
                    paddingLeft: "3px",
                    paddingRight: "3px",

                    backgroundColor: "#d7f0fa",
                  }}
                >
                  {updateListName.inputVal}
                </div>
              </div>
            ) : (
              <Input
                className="input-watch"
                maxLength={15}
                prefix={
                  <img
                    src={ImageConst.closeIcon}
                    onClick={() =>
                      setUpdateListName({ inputVal: "", isCard: false })
                    }
                  />
                }
                value={updateListName.inputVal}
                type="text"
                onChange={(e) => {
                  if (e.target.value > 3) {
                    setUpdateListName({
                      inputVal: e.target.value,
                      isCard: false,
                    });
                  } else {
                    setUpdateListName({
                      inputVal: e.target.value,
                      isCard: false,
                    });
                  }
                }}
              />
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title={<ModalHeader title={"Delete Watchlist"} />}
        centered
        closeIcon={<ModalCloseButton />}
        className="custom-second delete-watchlist-ps"
        open={openDeleteModel}
        onCancel={() => setOpenDeleteModel(false)}
        footer={[
          <div
            className="modalButtonContainer"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <button
              className="can-btn can-del"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "36px",
              }}
              onClick={() => setOpenDeleteModel(false)}
            >
              Cancel
            </button>
            <button
              className="rest-btn del-red"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "36px",
              }}
              onClick={() => {
                dispatch(deleteWishlistAction(currentId));
                dispatch(getWishlistAction());
                // setCount(count++);
                setOpenDeleteModel(false);
              }}
            >
              Delete
            </button>
          </div>,
        ]}
      >
        <div className="reset-setting del-test">
          <div className="text-center">
            <div className="del-image">
              <img src={Images.deletError} />
            </div>
            <div className="delete-second-text">
              <p>{`Are you sure you want to delete "${listName}"?`}</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default WatchlistSideBar;
