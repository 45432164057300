import { Modal, Row, Switch, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import Images from "../../Themes/ImageConstant";
import { useSelector } from "react-redux";
import CheckBox from "../checkBox";
import { dispatch } from "../../Utils/dispatchNavigate";
import {
  getCategoriesAction,
  getCompaniesAction,
  incidentMapAction,
  updateCategoriesData,
  updateParentInduAction,
  IndustriesForWatchlistAction,
  updateCompanyArr,
  updateIndustryArr,
  updateCompaniesAction,
  directUpdateParentInduAction,
} from "../../Redux/Action/incidents";
import {
  editWishListAction,
  editWishListSevereAction,
  getWishListDetailAction,
  updateDataForWatchlist,
  updateWatchListData,
} from "../../Redux/Action/watchListActions";

import moment from "moment/moment";
import { calculatePreviousDate, dateButtonObj, debounce } from "../../Utils";
import ModalCloseButton from "../modalCloseButton";
import ModalHeader from "../modalHeaderComp";
import CompanyLoader from "../companyLoader";

let countries = [];

const selectedSearchArrayItem = [
  { groupName: "Company", selectedItemLength: 0, data: [] },
  { groupName: "Incident Location", selectedItemLength: 0, data: [] },
  { groupName: "Categories", selectedItemLength: 0, data: [] },
  { groupName: "Industry", selectedItemLength: 0, data: [] },
  { groupName: "ESG Factors", selectedItemLength: 0, data: [] },
  { groupName: "Esg ", selectedItemLength: 0, data: [] },
  { groupName: "Esg Pais", selectedItemLength: 0, data: [] },
  { groupName: "SDGs", selectedItemLength: 0, data: [] },
  { groupName: "PAI Indicators", selectedItemLength: 0, data: [] },
  {
    groupName: "UN global compact principles",
    selectedItemLength: 0,
    data: [],
  },
];

let numberOfSelectedCompanyItems = 0;
let companyListOffset = 0;
// let companyPushIem = [];

// --------- LOCATION -- ARRAY -- FUNCTIONS --------------

const updateLocationArray = (array, locationStatehandler, section, item, status, item_Index = null) => {

  let resultArray = []
  if (section == "all") {
    let cloneArray = {
      ...array
    }
    cloneArray.allStatus = status
    const resultArrayForAll = array.continents.map(item => ({ ...item, continent_status: status, countries: item.countries.map(Item => ({ ...Item, status, continent_code: item.continent_code })) })).flat()
    cloneArray.continents = resultArrayForAll
    if (status == false) {
      resultArray = []
    } else {
      resultArray = array.continents.map(item => item.countries.map(Item => ({ ...Item, status, continent_code: item.continent_code }))).flat().map(item => item.name)
    }
    locationStatehandler(pre => cloneArray)
  } else if (section == "SA") {
    const cloneArray = {
      ...array
    }
    let cloneContinents = cloneArray.continents
    let itemIndex;
    const sectionObj = cloneContinents?.filter((item, index) => {
      if (item?.continent_code == "SA") {
        itemIndex = index
        return true;
      }
      return false
    })[0]
    if (item_Index !== null) {
      sectionObj.countries[item_Index] = { ...item, status }
      sectionObj.continent_status = checkAllSelected(sectionObj.countries)
      cloneContinents[itemIndex] = { ...sectionObj }
    } else {
      cloneContinents[itemIndex] = updateFullyContinents(sectionObj, status)
    }
    cloneArray.allStatus = cloneArray.continents.map(item => item.continent_status).includes(false) ? false : true
    locationStatehandler(pre => cloneArray)
    resultArray = getResultArray(cloneArray)

  } else if (section == "AN") {
    const cloneArray = {
      ...array
    }
    let cloneContinents = cloneArray.continents
    let itemIndex;
    const sectionObj = cloneContinents?.filter((item, index) => {
      if (item?.continent_code == "AN") {
        itemIndex = index
        return true;
      }
      return false
    })[0]
    if (item_Index !== null) {
      sectionObj.countries[item_Index] = { ...item, status }
      sectionObj.continent_status = checkAllSelected(sectionObj.countries)
      cloneContinents[itemIndex] = { ...sectionObj }
    } else {
      cloneContinents[itemIndex] = updateFullyContinents(sectionObj, status)
    }
    cloneArray.allStatus = cloneArray.continents.map(item => item.continent_status).includes(false) ? false : true
    locationStatehandler(pre => cloneArray)
    resultArray = getResultArray(cloneArray)
  } else if (section == "EU") {
    const cloneArray = {
      ...array
    }
    let cloneContinents = cloneArray.continents
    let itemIndex;
    const sectionObj = cloneContinents?.filter((item, index) => {
      if (item?.continent_code == "EU") {
        itemIndex = index
        return true;
      }
      return false
    })[0]
    if (item_Index !== null) {
      sectionObj.countries[item_Index] = { ...item, status }
      sectionObj.continent_status = checkAllSelected(sectionObj.countries)
      cloneContinents[itemIndex] = { ...sectionObj }
    } else {
      cloneContinents[itemIndex] = updateFullyContinents(sectionObj, status)
    }
    cloneArray.allStatus = cloneArray.continents.map(item => item.continent_status).includes(false) ? false : true
    locationStatehandler(pre => cloneArray)
    resultArray = getResultArray(cloneArray)
  } else if (section == "OC") {
    const cloneArray = {
      ...array
    }
    let cloneContinents = cloneArray.continents
    let itemIndex;
    const sectionObj = cloneContinents?.filter((item, index) => {
      if (item?.continent_code == "OC") {
        itemIndex = index
        return true;
      }
      return false
    })[0]
    if (item_Index !== null) {
      sectionObj.countries[item_Index] = { ...item, status }
      sectionObj.continent_status = checkAllSelected(sectionObj.countries)
      cloneContinents[itemIndex] = { ...sectionObj }
    } else {
      cloneContinents[itemIndex] = updateFullyContinents(sectionObj, status)
    }
    cloneArray.allStatus = cloneArray.continents.map(item => item.continent_status).includes(false) ? false : true
    locationStatehandler(pre => cloneArray)
    resultArray = getResultArray(cloneArray)
  } else if (section == "NA") {
    const cloneArray = {
      ...array
    }
    let cloneContinents = cloneArray.continents
    let itemIndex;
    const sectionObj = cloneContinents?.filter((item, index) => {
      if (item?.continent_code == "NA") {
        itemIndex = index
        return true;
      }
      return false
    })[0]
    if (item_Index !== null) {
      sectionObj.countries[item_Index] = { ...item, status }
      sectionObj.continent_status = checkAllSelected(sectionObj.countries)
      cloneContinents[itemIndex] = { ...sectionObj }
    } else {
      cloneContinents[itemIndex] = updateFullyContinents(sectionObj, status)
    }
    cloneArray.allStatus = cloneArray.continents.map(item => item.continent_status).includes(false) ? false : true
    locationStatehandler(pre => cloneArray)
    resultArray = getResultArray(cloneArray)
  } else if (section == "AS") {
    const cloneArray = {
      ...array
    }
    let cloneContinents = cloneArray.continents
    let itemIndex;
    const sectionObj = cloneContinents?.filter((item, index) => {
      if (item?.continent_code == "AS") {
        itemIndex = index
        return true;
      }
      return false
    })[0]
    if (item_Index !== null) {
      sectionObj.countries[item_Index] = { ...item, status }
      sectionObj.continent_status = checkAllSelected(sectionObj.countries)
      cloneContinents[itemIndex] = { ...sectionObj }
    } else {
      cloneContinents[itemIndex] = updateFullyContinents(sectionObj, status)
    }
    cloneArray.allStatus = cloneArray.continents.map(item => item.continent_status).includes(false) ? false : true
    locationStatehandler(pre => cloneArray)
    resultArray = getResultArray(cloneArray)
  } else if (section == "AF") {
    const cloneArray = {
      ...array
    }
    let cloneContinents = cloneArray.continents
    let itemIndex;
    const sectionObj = cloneContinents?.filter((item, index) => {
      if (item?.continent_code == "AF") {
        itemIndex = index
        return true;
      }
      return false
    })[0]
    if (item_Index !== null) {
      sectionObj.countries[item_Index] = { ...item, status }
      sectionObj.continent_status = checkAllSelected(sectionObj.countries)
      cloneContinents[itemIndex] = { ...sectionObj }
    } else {
      cloneContinents[itemIndex] = updateFullyContinents(sectionObj, status)
    }
    cloneArray.allStatus = cloneArray.continents.map(item => item.continent_status).includes(false) ? false : true
    locationStatehandler(pre => cloneArray)
    resultArray = getResultArray(cloneArray)
  }
  return resultArray
}

const updateLocationsWithSelectedItem = (arrayObj, selectedItem, activeItem) => {
  const selectedItemId = selectedItem.map(item => item.name)
  let resultArray = { ...arrayObj }
  let Continents = resultArray.continents
  let activeItemIndex = null
  for (let index = 0; index <= Continents?.length - 1; index++) {
    const continentsData = Continents[index].countries;
    let selectedCount = 0
    for (let j = 0; j <= continentsData.length - 1; j++) {
      if (selectedItemId.includes(continentsData[j].name)) {
        continentsData[j].status = true
        selectedCount += 1
      }
      if (activeItem) {
        if (continentsData[j].id == activeItem.id) {
          activeItemIndex = j
        }
      }
    }
    Continents[index].continent_status = selectedCount == continentsData?.length
  }
  resultArray.continents = Continents
  const statusListContinents = Continents.map(item => item.continent_status)
  resultArray.allStatus = statusListContinents.includes(false) ? false : true
  return { resultArray, activeItemIndex }
}

const updateFullyContinents = (item, status) => ({
  ...item, continent_status: status, countries: item.countries.map(item => ({ ...item, status }))
})

const getResultArray = (array) => array.continents.map(item => item.countries.filter(Item => Item.status && ({ ...Item, continent_code: item.continent_code }))).flat().map(item => item.name)

const getSelectedLocationItems = (array, selectedItems) => {
  const selectedArrayString = selectedItems?.map(item => item.name)
  let resultArray = []
  const makeLocationSectionObj = {
    SA: array.continents?.filter(item => item?.continent_code == "SA")[0],
    AN: array.continents?.filter(item => item?.continent_code == "AN")[0],
    EU: array.continents?.filter(item => item?.continent_code == "EU")[0],
    OC: array.continents?.filter(item => item?.continent_code == "OC")[0],
    NA: array.continents?.filter(item => item?.continent_code == "NA")[0],
    AS: array.continents?.filter(item => item?.continent_code == "AS")[0],
    AF: array.continents?.filter(item => item?.continent_code == "AF")[0]
  }
  makeLocationSectionObj.SA.countries = firstUpdateLocationItemArray(makeLocationSectionObj.SA.countries, selectedArrayString)
  makeLocationSectionObj.SA.continent_status = checkAllSelected(makeLocationSectionObj.SA.countries)
  makeLocationSectionObj.AN.countries = firstUpdateLocationItemArray(makeLocationSectionObj.AN.countries, selectedArrayString)
  makeLocationSectionObj.AN.continent_status = checkAllSelected(makeLocationSectionObj.AN.countries)
  makeLocationSectionObj.EU.countries = firstUpdateLocationItemArray(makeLocationSectionObj.EU.countries, selectedArrayString)
  makeLocationSectionObj.EU.continent_status = checkAllSelected(makeLocationSectionObj.EU.countries)
  makeLocationSectionObj.OC.countries = firstUpdateLocationItemArray(makeLocationSectionObj.OC.countries, selectedArrayString)
  makeLocationSectionObj.OC.continent_status = checkAllSelected(makeLocationSectionObj.OC.countries)
  makeLocationSectionObj.NA.countries = firstUpdateLocationItemArray(makeLocationSectionObj.NA.countries, selectedArrayString)
  makeLocationSectionObj.NA.continent_status = checkAllSelected(makeLocationSectionObj.NA.countries)
  makeLocationSectionObj.AS.countries = firstUpdateLocationItemArray(makeLocationSectionObj.AS.countries, selectedArrayString)
  makeLocationSectionObj.AS.continent_status = checkAllSelected(makeLocationSectionObj.AS.countries)
  makeLocationSectionObj.AF.countries = firstUpdateLocationItemArray(makeLocationSectionObj.AF.countries, selectedArrayString)
  makeLocationSectionObj.AF.continent_status = checkAllSelected(makeLocationSectionObj.AF.countries)
  resultArray = [makeLocationSectionObj.SA.countries, makeLocationSectionObj.AN.countries, makeLocationSectionObj.EU.countries, makeLocationSectionObj.OC.countries, makeLocationSectionObj.NA.countries, makeLocationSectionObj.AS.countries, makeLocationSectionObj.AF.countries].flat().filter(item => item.status).map(item => item.name)
  const continents = [makeLocationSectionObj.SA, makeLocationSectionObj.AN, makeLocationSectionObj.EU, makeLocationSectionObj.OC, makeLocationSectionObj.NA, makeLocationSectionObj.AS, makeLocationSectionObj.AF]
  let allStatus = continents?.map(item => item.continent_status)?.includes(false) ? false : true
  const makeObj = {
    allStatus,
    continents
  }
  return { locationArray: makeObj, selectedItem: resultArray }
}
const firstUpdateLocationItemArray = (array, preArray) => array?.map(Item => preArray?.includes(Item?.name) ? { ...Item, status: true } : Item)

const checkAllSelected = (array) => {
  const clone = [...array].filter(item => item.status)
  return clone.length == array.length
}

const removeLocationItem = (array, location) => {
  let haveItem = null
  const cloneArray = [...array]
  for (let index = 0; index < cloneArray.length; index++) {
    if (cloneArray[index].name == location) {
      cloneArray[index] = { ...cloneArray[index], status: false }
      haveItem = false
    }
  }
  return { array: cloneArray, status: haveItem }
}

let removeFromCross = false;
let removeFromCrossItem = []
let selectedLocationItems = []
let cloneLocationBasedOnSearch = null

let cloneCatergory = null
let workingWithLocationSelectedState = null

//// For company
function mergeAndRemoveDuplicates(arr1, arr2) {
  const combinedArray = [...arr1, ...arr2];

  const uniqueIds = new Set();

  const result = combinedArray.filter(item => {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id);
      return true;
    }
    return false;
  });

  return result;
}

export const WatchListAdvanceSearch = ({
  open,
  onclose,
  typeBar,
  selectedData,
  watchListdetails,
  _countryList,
  industryList,
  addModalTopSelectedItems,
  addModalTopItemsUpdate,
  selectedItemHandler,
}) => {

  const [companyPushIem, setCompanyPushItem] = useState([]);

  const watchListData = useSelector(
    (state) => state?.watchListReducer?.watchlist
  );

  const categoriesList = useSelector(
    (state) => state?.incidentReducer?.getCategories
  );
  const watchListId = useSelector(
    (state) => state?.watchListReducer?.watchListId
  );
  const [selectedSearchItems, setSelectedSearchItems] = useState(
    selectedSearchArrayItem
  );
  const [localCategories, setLocalCategories] = useState({ esg_factor: [], principles: [] })
  const [showSevere, setShowSevere] = useState(watchListdetails.showSever);
  const [searchChips, setSearchChips] = useState(true);
  const [search, setSearch] = useState("");
  const [countryList, setCountryList] = useState();
  const [activeCategoriesItemNumber, setActiveCategoriesItemNumber] =
    useState(0);
  const [CategoriesList, setCategoriesList] = useState();
  const rightIndusRef = useRef([]);

  const [currentTab, setCurrentTab] = useState("");

  const [companyList, setCompanyList] = useState([]);
  const [canApplyCompanyListPagination, setCanApplyCompanyListPagination] =
    useState(false);
  const [offset, setOffset] = useState(0);

  let CompaniesList = useSelector(
    (state) => state?.incidentReducer?.companiesList
  );
  let newCompaniesList = useSelector(
    (state) => state?.incidentReducer?.newCompaniesList
  );

  let changeData = useSelector((state) => state?.incidentReducer?.changeData);

  const companyListLoding = useSelector(
    (state) => state?.incidentReducer?.companyListLoding
  );

  useEffect(() => {
    if (
      typeBar == "Company" &&
      CompaniesList?.length > 0
    ) {
      const companyListClone = [...CompaniesList];
      let numberOfSelectedItems = 0;
      let arr = [];
      for (let i in companyListClone) {
        for (let j in selectedData) {
          if (companyListClone[i].id === selectedData[j].id) {
            companyListClone[i].status = true;
            numberOfSelectedItems += 1;
            arr.push(selectedData[j].name);
          }
        }
      }

      numberOfSelectedCompanyItems =
        numberOfSelectedCompanyItems + numberOfSelectedItems;
      let cloneAddModalTopSelectedItems = addModalTopSelectedItems

      // -----Playing with previous data Start-----
      if (companyPushIem.length > 0) {
        for (let i = 0; i < companyPushIem?.length; i++) {
          const element = companyPushIem[i];
          const isRemovedItem = element.isRemovedItem;
          let isExist = false;
          if (!isRemovedItem) {
            for (let j = 0; j < cloneAddModalTopSelectedItems?.length; j++) {
              if (
                element.company_id ==
                cloneAddModalTopSelectedItems[j].company_id
              ) {
                isExist = true;
                break;
              }
            }
            if (isExist == false) {
              cloneAddModalTopSelectedItems.push(element);
            }
          } else {
            for (let j = 0; j < cloneAddModalTopSelectedItems?.length; j++) {
              if (
                element.company_id ==
                cloneAddModalTopSelectedItems[j].company_id
              ) {
                cloneAddModalTopSelectedItems = [
                  ...cloneAddModalTopSelectedItems.slice(0, j),
                  ...cloneAddModalTopSelectedItems.slice(j + 1),
                ];
                break;
              }
            }
          }
        }
        console.log("1");
        addModalTopItemsUpdate(cloneAddModalTopSelectedItems);
      } else {
        setCompanyPushItem(cloneAddModalTopSelectedItems);
      }
      // -----Playing with previous data End-----
      const selectedSearchArrayItem = [
        {
          groupName: "Company",
          selectedItemLength: cloneAddModalTopSelectedItems.length,
          data: cloneAddModalTopSelectedItems.map((val) => val.name),
        },
      ];
      if (canApplyCompanyListPagination == false) {
        setCanApplyCompanyListPagination(true);
      }
      setSelectedSearchItems(selectedSearchArrayItem);
      if (!removeFromCross) {
        const filterDuplicate = mergeAndRemoveDuplicates(companyListClone, companyList)
        setCompanyList(filterDuplicate);
      }
    } else {
      setCompanyList(CompaniesList);
    }
  }, [CompaniesList, selectedData, typeBar, changeData]);

  useEffect(() => {
    if (canApplyCompanyListPagination) {
      companyListOffset += 20;
      setCanApplyCompanyListPagination(false);
      dispatch(getCompaniesAction({ offset: companyListOffset, limit: 20 }));
    }
  }, [offset]);

  const hanlderShowMoreCompany = () => {
    setCanApplyCompanyListPagination(true);
    setOffset((pre) => pre + 20);
  };

  const lastDataArray0 =
    CategoriesList?.length > 0
      ? [
        ...CategoriesList?.[0]?.value[0]?.data,
        ...CategoriesList?.[0]?.value[1]?.data,
        ...CategoriesList?.[0]?.value[2]?.data,
      ]
      : [];
  const topSearch = useSelector((state) => state?.incidentReducer?.topSearch);
  const [TopSearch, setTopSearch] = useState(topSearch);
  let esg_factors = lastDataArray0
    ?.filter((item) => item.status)
    ?.map((item) => item.id);
  const esg_factorsArr = lastDataArray0
    ?.filter((item) => item.status)
    ?.map((item) => item.name);
  const lastDataArray3 =
    CategoriesList?.length > 0
      ? [
        ...CategoriesList?.[1]?.value[0]?.data,
        ...CategoriesList?.[1]?.value[1]?.data,
        ...CategoriesList?.[1]?.value[2]?.data,
        ...CategoriesList?.[1]?.value[3]?.data,
      ]
      : [];
  const principles = lastDataArray3
    ?.filter((item) => item.status)
    ?.map((item) => item.id);

  let networkData = {};

  const industriesall = (key) => {
    if (key) {
      let res = [];
      
      industryList?.filter((e) => {
        if (e?.data && e?.data?.length > 0) {
          return e?.data?.filter((item) => {
            if (item.status) {
              return res.push(item.name);
            }
          });
        }
      });
      return res;
    } else {
      let res = [];
      industryList?.filter((e) => {
        if (e?.data && e?.data?.length > 0) {
          return e?.data?.filter((item) => {
            if (item.status) {
              return res.push(item.id);
            }
          });
        }
      });
      return res;
    }
  };

  const mergedData = {
    ...networkData,
    countries: countryList?.continents.map(item => item?.countries.filter(item => item?.status)).flat().map(item => item.id),
    countriesArr: countries
      ?.filter((i) => {
        if (i.status) {
          return i.id;
        }
      })
      ?.map((i) => i.name),
    companies: companyPushIem
      ?.filter((i) => i?.isRemovedItem == false)?.map(item => item?.company_id.toString()),
    companiesArr: companyList
      ?.filter((i) => {
        if (i.status) {
          return i.name;
        }
      })
      ?.map((i) => i.name),
    industries: industriesall(),
    industriesArr: industriesall("key"),
    esg_factors,
    principles,
  };

  networkData = {
    wishlist: watchListId === "" ? watchListData[0]?._id : watchListId,
    name: null,
    locations:
      typeBar === "Location" || typeBar === "fullMap"
        ? mergedData?.countries
        : null,
    companies: currentTab === "2" ? companyPushIem?.filter((i) => i?.isRemovedItem == false)?.map(item => item?.company_id.toString()) : null,
    esg_factors:
      typeBar === "Categories" || typeBar === "fullMap"
        ? mergedData.esg_factors
        : null,
    principles:
      typeBar === "Categories" || typeBar === "fullMap"
        ? mergedData.principles
        : null,
    industries:
      typeBar === "Industry" || typeBar === "fullMap"
        ? mergedData?.industries
        : null,
  };

  useEffect(() => {
    dispatch(getCompaniesAction({ limit: 20, offset: 0 }));
    dispatch(getCategoriesAction());
    dispatch(updateDataForWatchlist(watchListdetails));
    dispatch(IndustriesForWatchlistAction(watchListdetails));
  }, []);

  useEffect(() => {
    let count = 0;
    selectedSearchItems?.forEach((val) => {
      if (val.selectedItemLength > 0) {
        count += val.selectedItemLength;
      }
    });
    if (count == 0) {
      setSearchChips(false);
    } else {
      setSearchChips(true);
    }
  }, [selectedSearchItems]);
  const [checkState, setCheckState] = useState(false);

  useEffect(() => {
    if (typeBar === "Company" || typeBar === "fullMap") {
      setCurrentTab("2");
    } else if (typeBar === "Location") {
      setCurrentTab("3");
    } else if (typeBar === "Categories") {
      setCurrentTab("4");
    } else if (typeBar === "Industry") {
      setCurrentTab("5");
    }
  }, [typeBar]);

  // ------- I N D U S T R Y  ---------- 

  useEffect(() => {
    if (typeBar == "Industry") {
      const selectedItemStringArray = []
      if (industryList && industryList.length > 0) {
        for (let i in industryList) {
          for (let j in industryList[i].data) {
            if (
              watchListdetails?.industries &&
              watchListdetails?.industries.length > 0
            ) {
              for (let k in watchListdetails?.industries) {
                if (
                  watchListdetails?.industries[k].name ==
                  industryList[i].data[j].name
                ) {
                  selectedItemStringArray.push(watchListdetails?.industries[k]?.name)
                  industryList[i].data[j].status = true;
                  let filterParent = industryList[i].data.filter(
                    (itm) => itm.status
                  );
                  if (
                    filterParent &&
                    filterParent.length == industryList[i].data.length
                  ) {
                    industryList[i].status = true;
                  } else {
                    industryList[i].status = false;
                  }
                }
              }
            }
          }
        }
      }
      let groupName = "Industry";
      updateSelectSearchArray(groupName, selectedItemStringArray?.length, selectedItemStringArray);
    }
  }, []);

  // ------- L O C A T I O N ---------- 
  useEffect(() => {
    if (typeBar == "Location") {
      const onlySelectedItems = getSelectedLocationItems(_countryList, watchListdetails.locations)
      setCountryList(onlySelectedItems.locationArray);
      let groupName = "Incident Location";
      updateSelectSearchArray(groupName, onlySelectedItems.selectedItem?.length, onlySelectedItems.selectedItem);
    }
  }, []);

  // Handler for categories
  useEffect(() => {
    if (
      typeBar == "Categories" &&
      checkState == false &&
      categoriesList.length > 0
    ) {

      const { esg_factor, principles } = workingWithLocationSelectedState ? localCategories : selectedData;
      let cloneCategoriesList = [...categoriesList];

      let esgFactorValueArray = cloneCategoriesList[0]?.value;
      let esgFactorItemLength;
      let esgFactorSelectedItem = 0;

      let esg_Environmental = esgFactorValueArray && esgFactorValueArray[0];
      const esg_Environmental_Modified = esg_Environmental?.data;
      esgFactorItemLength = esg_Environmental_Modified?.length;
      for (let i = 0; i < esg_factor?.length; i++) {
        for (let j = 0; j < esg_Environmental_Modified?.length; j++) {
          if (esg_factor[i].id == esg_Environmental_Modified[j].id) {
            esg_Environmental_Modified[j] = {
              ...esg_Environmental_Modified[j],
              status: true,
            };
            esgFactorSelectedItem += 1;
          }
        }
      }

      let esg_Social = esgFactorValueArray && esgFactorValueArray[1];
      const esg_Social_Modified = esg_Social?.data;
      esgFactorItemLength += esg_Social_Modified?.length;
      for (let i = 0; i < esg_factor?.length; i++) {
        for (let j = 0; j < esg_Social_Modified?.length; j++) {
          if (esg_factor[i].id == esg_Social_Modified[j].id) {
            esg_Social_Modified[j] = {
              ...esg_Social_Modified[j],
              status: true,
            };
            esgFactorSelectedItem += 1;
          }
        }
      }

      let governance = esgFactorValueArray && esgFactorValueArray[2];
      const esg_Governance_Modified = governance?.data;
      esgFactorItemLength += esg_Governance_Modified?.length;
      for (let i = 0; i < esg_factor?.length; i++) {
        for (let j = 0; j < esg_Governance_Modified?.length; j++) {
          if (esg_factor[i].id == esg_Governance_Modified[j].id) {
            esg_Governance_Modified[j] = {
              ...esg_Governance_Modified[j],
              status: true,
            };
            esgFactorSelectedItem += 1;
          }
        }
      }
      if (esgFactorSelectedItem == esgFactorItemLength) {
        cloneCategoriesList[0].status = true;
      }

      // For Principle
      let principlesValueArray = cloneCategoriesList[1]?.value;
      let principlesItemLength;
      let principlesSelectedItem = 0;
      let principles_humanRights =
        principlesValueArray && principlesValueArray[0];
      const principles_humanRights_Modified = principles_humanRights?.data;
      principlesItemLength = principles_humanRights_Modified?.length;
      for (let i = 0; i < principles?.length; i++) {
        for (let j = 0; j < principles_humanRights_Modified?.length; j++) {
          if (principles[i].id == principles_humanRights_Modified[j].id) {
            principles_humanRights_Modified[j] = {
              ...principles_humanRights_Modified[j],
              status: true,
            };
            principlesSelectedItem += 1;
            break;
          }
        }
      }
      let principles_labour = principlesValueArray && principlesValueArray[1];
      const principles_labour_Modified = principles_labour?.data;
      principlesItemLength += principles_labour_Modified?.length;
      for (let i = 0; i < principles?.length; i++) {
        for (let j = 0; j < principles_labour_Modified?.length; j++) {
          if (principles[i].id == principles_labour_Modified[j].id) {
            principles_labour_Modified[j] = {
              ...principles_labour_Modified[j],
              status: true,
            };
            principlesSelectedItem += 1;
            break;
          }
        }
      }
      let principles_environment =
        principlesValueArray && principlesValueArray[2];
      const principles_environment_Modified = principles_environment?.data;
      principlesItemLength += principles_environment_Modified?.length;
      for (let i = 0; i < principles?.length; i++) {
        for (let j = 0; j < principles_environment_Modified?.length; j++) {
          if (principles[i].id == principles_environment_Modified[j].id) {
            principles_environment_Modified[j] = {
              ...principles_environment_Modified[j],
              status: true,
            };
            principlesSelectedItem += 1;
            break;
          }
        }
      }
      let principles_antiCorruption =
        principlesValueArray && principlesValueArray[3];
      const principles_antiCorruption_environment_Modified =
        principles_antiCorruption?.data;
      principlesItemLength +=
        principles_antiCorruption_environment_Modified?.length;
      for (let i = 0; i < principles?.length; i++) {
        for (
          let j = 0;
          j < principles_antiCorruption_environment_Modified?.length;
          j++
        ) {
          if (
            principles[i].id ==
            principles_antiCorruption_environment_Modified[j].id
          ) {
            principles_antiCorruption_environment_Modified[j] = {
              ...principles_antiCorruption_environment_Modified[j],
              status: true,
            };
            principlesSelectedItem += 1;
            break;
          }
        }
      }
      if (principlesItemLength == principlesSelectedItem) {
        cloneCategoriesList[1].status = true;
      }
      const selectedItemESGString = cloneCategoriesList?.filter(item => item.name == "ESG Factors")[0].value?.map(item => item.data)?.flat()?.filter(item => item.status).map(item => item.name)
      const selectedItemUNGString = cloneCategoriesList?.filter(item => item.name == "UN global compact principles")[0].value?.map(item => item.data)?.flat()?.filter(item => item.status).map(item => item.name)
      let selectedItem = [
        {
          groupName: "ESG Factors",
          selectedItemLength: esgFactorSelectedItem,
          data: selectedItemESGString
        },
        {
          groupName: "UN global compact principles",
          selectedItemLength: principlesSelectedItem,
          data: selectedItemUNGString
        },
      ];
      setCheckState(true);
      setCategoriesList(cloneCategoriesList);
      setSelectedSearchItems(selectedItem);
    }
  }, [categoriesList]);


  const industryListStatic = useSelector(
    (state) => state?.incidentReducer?.industryListStatic
  );

  const _categoriesListStatic = useSelector(
    (state) => state?.incidentReducer?._categoriesListStatic
  );

  const onchangeSearchData = (e) => {
    setSearch(e);

    if (currentTab == 1) {
      if (e.trim()?.length > 0) {
        let newArr = JSON.parse(JSON.stringify(topSearch));
        for (let i in newArr) {
          let _Arr = newArr[i].data;
          newArr[i].data = _Arr.filter((x) =>
            x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
          );
          setTopSearch(newArr);
        }
      } else {
        setTopSearch(topSearch);
      }
    }
    if (currentTab == 2) {
      const compArr = JSON.parse(JSON.stringify(newCompaniesList));
      if (e.trim()?.length > 0) {
        let arr = JSON.parse(JSON.stringify(compArr));
        arr = arr.filter((x) =>
          x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
        );
        dispatch(updateCompanyArr({ data: arr }));
      } else {
        dispatch(updateCompanyArr({ data: newCompaniesList }));
      }
    }
    if (currentTab == 3) {
      if (e.trim()?.length > 0) {
        let arrLoc = JSON.parse(JSON.stringify(countryList));
        if (cloneLocationBasedOnSearch == null) {
          cloneLocationBasedOnSearch = countryList
        }
        selectedLocationItems = [...selectedLocationItems, ...arrLoc.continents.map(item => item.countries).flat().filter(item => item.status)]
        for (let n in arrLoc?.continents) {
          let _Arr = arrLoc?.continents[n].countries;
          arrLoc.continents[n].countries = _Arr.filter((x) =>
            x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
          );
        }
        setCountryList(arrLoc);
      } else {
        setCountryList(cloneLocationBasedOnSearch);
        cloneLocationBasedOnSearch = null
      }
    }
    if (currentTab == 4) {
      if (e.trim()?.length > 0) {
        if (cloneCatergory == null) {
          cloneCatergory = CategoriesList
        }
        const arr = JSON.parse(JSON.stringify(_categoriesListStatic));
        let arrLoc = arr;
        for (let n in arrLoc) {
          for (let k in arrLoc[n]?.value) {
            let _Arr = arrLoc[n]?.value[k].data;
            arrLoc[n].value[k].data = _Arr.filter((x) =>
              x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
            );
          }
        }
        let arrLoc1 = arrLoc;
        setCategoriesList(arrLoc1);
      } else {
        setCategoriesList(_categoriesListStatic);
      }
    }
    if (currentTab == 5) {
      if (e.trim()?.length > 0) {
        const arrLoc = JSON.parse(JSON.stringify(industryListStatic));
        for (let n in arrLoc) {
          let _Arr = arrLoc[n]?.data;
          arrLoc[n].data = _Arr.filter((x) =>
            x?.name?.toUpperCase()?.includes(e?.toUpperCase().trim())
          );
        }
        dispatch(updateIndustryArr({ data: arrLoc }));
      } else {
        dispatch(updateIndustryArr({ data: industryListStatic }));
      }
    }
  };

  const removeSearchSelectedItem = (groupName, val, mainkey, key) => {
    switch (groupName) {
      case "Company":
        const companyLocationString = val?.data[key]
        const cloneCompanyPushIem = companyPushIem.map(item => item.name == companyLocationString ? { ...item, isRemovedItem: true } : item)
        let cloneCompanySelectedArray = val.data.filter(item => item !== companyLocationString)
        const cloneCompanyData = companyList.map(item => item.name == companyLocationString ? { ...item, status: false } : item)
        const updateAddModal = addModalTopSelectedItems.filter(item => item.name !== companyLocationString)
        removeFromCross = true
        updateSelectSearchArray("Company", cloneCompanySelectedArray?.length, cloneCompanySelectedArray);
        setCompanyList(cloneCompanyData)
        setTimeout(() => {
          addModalTopItemsUpdate(updateAddModal)
          setCompanyPushItem(pre => cloneCompanyPushIem)
          setTimeout(() => {
            dispatch(updateWatchListData({ ...watchListdetails, companies: selectedData.filter(item => item.name !== companyLocationString) }))
          }, 400);
        }, 400);
        break;
      case "Top Searches":
        topSearch?.forEach((search) => {
          search?.data?.forEach((data) => (data.status = false));
        });
        updateSelectSearchArray("Top Searches", 0);
        break;
      case "Incident Location":
        let newRecord = search.length > 0 ? updateLocationsWithSelectedItem(_countryList, selectedLocationItems, null).resultArray : countryList;
        if (search.length > 0) {
          setSearch("")
        }
        const locationString = val?.data[key]
        newRecord.continents = newRecord?.continents.map(item => ({ ...item, continent_status: removeLocationItem(item.countries, locationString).status == null ? item.continent_status : removeLocationItem(item.countries, locationString).status, countries: removeLocationItem(item.countries, locationString).array }))
        newRecord.allStatus = false;
        const selectedArray = val?.data?.filter(item => item !== locationString)
        const onlySelectedItems = getSelectedLocationItems(newRecord, selectedArray)
        setCountryList(onlySelectedItems.locationArray);
        let groupName = "Incident Location";
        updateSelectSearchArray(groupName, onlySelectedItems.selectedItem?.length, onlySelectedItems.selectedItem);
        break;
      case "Health Care":
      case "ESG Factors":
        workingWithLocationSelectedState = true

        let cloneEsgFactors = search.length > 0 ? cloneCatergory : [...CategoriesList];
        const data = cloneEsgFactors[0].value;
        for (let i in data) {
          for (let j in data[i].data) {
            if (data[i].data[j].name == val.data[key]) {
              data[i].data[j].status = false
              cloneEsgFactors[0].status = false
            }
          }
        }
        let selectedSearchItemsArr = selectedSearchItems
        selectedSearchItemsArr[mainkey].data.splice(key, 1)
        cloneEsgFactors[0].value = data
        setCategoriesList(cloneEsgFactors)
        setSelectedSearchItems(selectedSearchItemsArr)
        break;
      case "SDGs":
        let cloneSdgs = [...CategoriesList];
        const dataSdgs = cloneSdgs[1].value;
        cloneSdgs[1].status = false;
        for (let i = 0; i < dataSdgs.length; i++) {
          const item = dataSdgs[i].data;
          for (let j = 0; j < item.length; j++) {
            item[j] = { ...item[j], status: false };
          }
        }
        const update_SelectChips = [...selectedSearchItems];
        update_SelectChips[2] = { groupName: "SDGs", selectedItemLength: 0 };
        setSelectedSearchItems(update_SelectChips);
        setCategoriesList(cloneSdgs);
        break;
      case "Esg Pais":
        let clonePais = [...CategoriesList];
        const dataPais = clonePais[2].value;
        clonePais[2].status = false;
        for (let i = 0; i < dataPais.length; i++) {
          const item = dataPais[i].data;
          for (let j = 0; j < item.length; j++) {
            item[j] = { ...item[j], status: false };
          }
        }
        const update_Select_Chips = [...selectedSearchItems];
        update_Select_Chips[1] = {
          groupName: "Esg Pais",
          selectedItemLength: 0,
          data: [],
        };
        setSelectedSearchItems(update_Select_Chips);
        setCategoriesList(clonePais);
        break;
      case "UN global compact principles":
        let clonePrinciple = [...CategoriesList];
        const dataPrinciple = clonePrinciple[1].value;
        for (let i in dataPrinciple) {
          for (let j in dataPrinciple[i].data) {
            if (dataPrinciple[i].data[j].name == val.data[key]) {
              dataPrinciple[i].data[j].status = false
              clonePrinciple[1].status = false
            }
          }
        }
        let selectedSearchItemsArray = selectedSearchItems
        selectedSearchItemsArray[mainkey].data.splice(key, 1)
        clonePrinciple[1].value = dataPrinciple
        setCategoriesList(clonePrinciple)
        setSelectedSearchItems(selectedSearchItemsArray)
        break;
      case "Industry":
        let cloneIndustryList = industryList;
        const targetItem = val.data[key]
        const industryArraySelectedItem = val?.data?.filter(item => item !== targetItem)
        for (let index = 0; index < cloneIndustryList.length; index++) {
          const element = cloneIndustryList[index].data;
          let countSelected = 0
          for (let i = 0; i <= element.length - 1; i++) {
            if (element[i].name == targetItem) {
              element[i].status = false
            } else if (element[i].status) {
              countSelected += 1
            }
          }
          cloneIndustryList[index].status = countSelected == element.length
        }
        dispatch(directUpdateParentInduAction(cloneIndustryList));
        updateSelectSearchArray("Industry", industryArraySelectedItem?.length, industryArraySelectedItem);
      default:
        break;
    }
  };

  const updateSelectSearchArray = (groupName, selectedItem, data = []) => {
    let updateSearchSelectedItemArray = [...selectedSearchItems];
    updateSearchSelectedItemArray = updateSearchSelectedItemArray.map((val) =>
      val.groupName === groupName
        ? {
          ...val,
          selectedItemLength: selectedItem,
          data: selectedItem > 0 ? data : [],
        }
        : val
    );
    setSelectedSearchItems(updateSearchSelectedItemArray);
  };

  const networkCall = () => {
    const newData = { ...networkData };
    delete newData["wishlist"];
    delete newData["name"];
    const lastDate = dateButtonObj[3].type;
    newData["end"] = moment().format("YYYY-MM-DD");
    newData["start"] = moment(
      calculatePreviousDate(new Date(), lastDate)
    ).format("YYYY-MM-DD");
    dispatch(incidentMapAction(newData));
  };

  const checkTypeBar = (groupName) => {
    if (groupName == "Company") {
      return groupName;
    } else if (groupName == "Incident Location") {
      return "Location";
    } else if (groupName == "Categories") {
      return "Categories";
    } else if (groupName == "ESG Factors") {
      return "Categories";
    } else if (groupName == "UN global compact principles") {
      return "Categories";
    } else if (groupName == "Industry") {
      return "Industry";
    } else if (groupName == "Incident Location") {
      return groupName;
    } else if (groupName == "Incident Location") {
      return groupName;
    }
  };

  const updateParentIndus = (data, key, parent, childIndex) => {
    if (key == "parent") {
      if (search.length > 0) {
        dispatch(updateParentInduAction({ data, key, Arr: industryListStatic }));
      }
      else {
        dispatch(updateParentInduAction({ data, key }));
      }
    } else {
      if (search.length > 0) {
        dispatch(updateParentInduAction({ data, key, parent, childIndex, Arr: industryListStatic }));
      } else {
        dispatch(updateParentInduAction({ data, key, parent, childIndex }));
      }

    }
  };

  const setSeverAction = (e) => {
    setShowSevere(e);
    dispatch(
      editWishListSevereAction({
        showSever: e,
        wishlist: watchListId === "" ? watchListData[0]?._id : watchListId,
      })
    );
  };

  const handleScrollIndus = (index, itemName) => {
    rightIndusRef.current[index].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      {open && (
        <Modal
          title={
            <ModalHeader
              title={
                "Add" + " " + (typeBar == "Categories" ? "Category" : typeBar)
              }
            />
          }
          className="advanced advanced-search-modal cross-model add-location-os-ps watchModal"
          centered
          closeIcon={<ModalCloseButton />}
          onCancel={() => {
            removeFromCross = false
            numberOfSelectedCompanyItems = 0;
            companyListOffset = 0;
            countries = [];
            removeFromCrossItem = []
            selectedLocationItems = []
            cloneLocationBasedOnSearch = null
            cloneCatergory = null
            workingWithLocationSelectedState = null
            setCanApplyCompanyListPagination(false);
            setSelectedSearchItems(selectedSearchArrayItem);
            dispatch(updateCompaniesAction([]));
            setCompanyList([]);
            setCountryList()
            onclose();
            selectedItemHandler("", true);
            if (removeFromCrossItem?.length > 0) {
              dispatch(updateWatchListData({ ...watchListdetails, companies: [...watchListdetails.companies, ...removeFromCrossItem] }))
            }
          }}
          open={open}
          destroyOnClose={true}
          footer={[
            <div className="foot-sec-md2">
              <div className="md-toggle-btn">
                <p>
                  <Switch
                    checked={showSevere}
                    onChange={(e) => setSeverAction(e)}
                  />{" "}
                  Show severe incidents only
                </p>
              </div>
              <div className="foot-but-md">
                <button
                  className="can-btn chang"
                  onClick={() => {
                    companyListOffset = 0;
                    removeFromCross = false
                    numberOfSelectedCompanyItems = 0;
                    countries = [];
                    removeFromCrossItem = []
                    selectedLocationItems = []
                    cloneLocationBasedOnSearch = null
                    cloneCatergory = null
                    workingWithLocationSelectedState = null
                    setCanApplyCompanyListPagination(false);
                    setSelectedSearchItems(selectedSearchArrayItem);
                    dispatch(updateCompaniesAction([]));
                    setCompanyList([]);
                    setCountryList()
                    onclose();
                    // advanceSearchCancel();
                    selectedItemHandler("", true);
                    if (removeFromCrossItem?.length > 0) {
                      dispatch(updateWatchListData({ ...watchListdetails, companies: [...watchListdetails.companies, ...removeFromCrossItem] }))
                      removeFromCrossItem = []
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  className="rest-btn"
                  onClick={() => {
                    if (typeBar === "Company") {
                      removeFromCross = false
                      numberOfSelectedCompanyItems = 0;
                      companyListOffset = 0;
                      setCanApplyCompanyListPagination(false);
                      setSelectedSearchItems(selectedSearchArrayItem);
                      dispatch(updateCompaniesAction([]));
                      setCompanyList([]);
                      selectedItemHandler("", true);
                    }
                    if (typeBar !== "fullMap") {
                      dispatch(editWishListAction(networkData));
                      dispatch(getWishListDetailAction(watchListId));
                      setCountryList()
                      setSelectedSearchItems(selectedSearchArrayItem);
                      onclose();
                    } else {
                      networkCall();
                      onclose();
                    }
                  }}
                >
                  Add to Watchlist
                </button>
              </div>
            </div>,
          ]}
        >
          <div className="mod-search-bar">
            <input
              className="search-mad-icon"
              type="text"
              value={search}
              placeholder="Search "
              onChange={({ target }) => {
                if (typeBar === "Company") {
                  setSearch(target.value);
                  removeFromCross = false
                  target?.value?.length > 0 && debounce(() => dispatch(getCompaniesAction({ search: target.value })))
                } else {
                  onchangeSearchData(target.value);
                }
              }}
            />
            {/* {typeBar == "Industry" ? (
              mergedData?.industries?.length > 0 ? (
                <div className="selecteditem-searchbar">
                  {mergedData.industriesArr.map((v, i) => {
                    return (
                      <div className="selectedItemBox" key={typeBar}>
                        <img
                          style={{ width: "15px", height: "15px" }}
                          src={Images.indusBlue}
                        />
                        <div className="selectedItemText">{v}</div>
                        <div
                          className="deleteSelectedItem"
                          onClick={() => {
                            removeIndusFunc(v, i)
                            // dispatch(removeParentInduAction());
                          }}
                        >
                          X
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <input
                  className="search-mad-icon"
                  type="text"
                  value={search}
                  placeholder="Search "
                  onChange={({ target }) => onchangeSearchData(target.value)}
                />
              )
            ) : ( */}
            <div className="selecteditem-searchbar">
              {
                selectedSearchItems?.map((val, key) => {
                  if (
                    typeBar == checkTypeBar(val.groupName)
                  ) {
                    if (val?.data) {
                      return (
                        val?.data.length > 0 &&
                        val.data.map((x, y) => {
                          return (
                            <div className="selectedItemBox" key={key}>
                              {key == 0 &&
                                typeBar == "Company" &&
                                val.selectedItemLength > 0 ? (
                                <img
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    color: "blue",
                                  }}
                                  src={Images.hotelBlue}
                                />
                              ) : key == 1 &&
                                typeBar == "Location" &&
                                val.selectedItemLength > 0 ? (
                                <img
                                  style={{ width: "15px", height: "15px" }}
                                  src={Images.location}
                                />
                              ) : key == 3 &&
                                typeBar == "Industry" &&
                                val.selectedItemLength > 0 ? (
                                <img
                                  style={{ width: "15px", height: "15px", marginRight: "10px" }}
                                  src={Images.digramBlue}
                                />
                              ) : key == 4 &&
                                typeBar == "Categories" &&
                                val.selectedItemLength > 0 ? (
                                <img
                                  style={{ width: "15px", height: "15px" }}
                                  src={Images.digramBlue}
                                />
                              ) : key == 7 &&
                                typeBar == "Categories" &&
                                val.selectedItemLength > 0 ? (
                                <img src={Images.digramBlue} />
                              ) : key == 8 &&
                                typeBar == "Categories" &&
                                val.selectedItemLength > 0 ? (
                                <img src={Images.digramBlue} />
                              ) : key == 9 &&
                                typeBar == "Categories" &&
                                val.selectedItemLength > 0 ? (
                                <img src={Images.digramBlue} />
                              ) : key == 6 &&
                                typeBar == "Categories" &&
                                val.selectedItemLength > 0 ? (
                                <img src={Images.digramBlue} />
                              ) : key == 5 &&
                                typeBar == "Categories" &&
                                val.selectedItemLength > 0 ? (
                                <img src={Images.digramBlue} />
                              ) : null}
                              <div className="selectedItemText">{x}</div>
                              <div
                                className="deleteSelectedItem"
                                onClick={() => {
                                  removeSearchSelectedItem(val.groupName, val, key, y);
                                  selectedItemHandler("", true);
                                }}
                              >
                                X
                              </div>
                            </div>
                          );
                        })
                      );
                    }
                  }
                })}
            </div>
            {/* // )} */}
          </div>
          <div className="model-tabs">
            <Tabs
              activeKey={currentTab}
              onChange={(e) => {
                setCurrentTab(e);
              }}
              tabBarExtraContent={<div></div>}
            >
              {typeBar === "Company" && (
                <Tabs.TabPane
                  tab={
                    <div className="md-icons">
                      <img
                        className={currentTab == 2 ? "filter-img" : ""}
                        src={Images?.hotel}
                        alt="img"
                      />
                      Company
                    </div>
                  }
                  key="2"
                >
                  <div className="md-compy-tc">
                    {companyList?.length > 0 && (
                      <div>
                        {companyList?.map((item, index) => (
                          <div className="md-flex-div" key={index}>
                            <div
                              className="custom-checkbox-top mt-2 mb-1"
                              style={{ cursor: "pointer" }}
                            >
                              <CheckBox
                                visible={item?.status}
                                onClick={() => {
                                  let itemHave = false;
                                  removeFromCross = false
                                  const topCloneCompanyPushIem = companyPushIem;
                                  let firstForLoopWorkable = false;
                                  for (
                                    let i = 0;
                                    i <= topCloneCompanyPushIem?.length - 1;
                                    i++
                                  ) {
                                    // -----Playing with previous data Start-----
                                    const checkTrue =
                                      topCloneCompanyPushIem[i].company_id ==
                                      item.id &&
                                      topCloneCompanyPushIem[i].isRemovedItem;
                                    const checkFalse =
                                      topCloneCompanyPushIem[i].company_id ==
                                      item.id &&
                                      topCloneCompanyPushIem[i].isRemovedItem ==
                                      false;
                                    if (checkTrue) {
                                      topCloneCompanyPushIem[i] = {
                                        company_id: Number(item?.id),
                                        name: item?.name,
                                        isRemovedItem: false,
                                      };
                                      firstForLoopWorkable = true;
                                      itemHave = true;
                                      break;
                                    } else if (checkFalse) {
                                      topCloneCompanyPushIem[i] = {
                                        company_id: Number(item?.id),
                                        name: item?.name,
                                        isRemovedItem: true,
                                      };
                                      firstForLoopWorkable = true;
                                      itemHave = true;
                                      break;
                                    }
                                  }

                                  if (firstForLoopWorkable) {
                                    setCompanyPushItem(topCloneCompanyPushIem);
                                  }

                                  let updateCloneAddModalTopSelectedItems =
                                    addModalTopSelectedItems;
                                  for (
                                    let i = 0;
                                    i <= updateCloneAddModalTopSelectedItems.length - 1;
                                    i++
                                  ) {
                                    const element =
                                      updateCloneAddModalTopSelectedItems[i];
                                    if (element.company_id == item.id) {
                                      updateCloneAddModalTopSelectedItems = [
                                        ...updateCloneAddModalTopSelectedItems.slice(
                                          0,
                                          i
                                        ),
                                        ...updateCloneAddModalTopSelectedItems.slice(
                                          i + 1
                                        ),
                                      ];
                                      console.log("3");
                                      addModalTopItemsUpdate(
                                        updateCloneAddModalTopSelectedItems
                                      );
                                      itemHave = true;
                                      break;
                                    }
                                  }

                                  if (itemHave == false) {
                                    topCloneCompanyPushIem.push({
                                      company_id: item?.id,
                                      name: item?.name,
                                      isRemovedItem: false,
                                    });
                                    setCompanyPushItem(topCloneCompanyPushIem);
                                  }
                                  // -----Playing with previous data End------

                                  companyList.map((val) =>
                                    val.id === item.id
                                      ? (val.status = !val.status)
                                      : null
                                  );
                                  if (
                                    search.length > 0 &&
                                    addModalTopSelectedItems?.length > 0
                                  ) {
                                    dispatch(
                                      updateCompanyArr({
                                        data: companyList,
                                        key: "oncheck",
                                      })
                                    );
                                  }
                                  let cloneAddModalTopSelectedItems =
                                    addModalTopSelectedItems;
                                  // -----Playing with previous data Start-----
                                  if (topCloneCompanyPushIem.length > 0) {
                                    for (
                                      let i = 0;
                                      i <= topCloneCompanyPushIem?.length - 1;
                                      i++
                                    ) {
                                      const element = topCloneCompanyPushIem[i];
                                      const isRemovedItem =
                                        element.isRemovedItem;
                                      let isExist = false;
                                      if (!isRemovedItem) {
                                        for (
                                          let j = 0;
                                          j <= cloneAddModalTopSelectedItems?.length - 1;
                                          j++
                                        ) {
                                          if (
                                            element.company_id ==
                                            cloneAddModalTopSelectedItems[j]
                                              .company_id
                                          ) {
                                            isExist = true;
                                            break;
                                          }
                                        }
                                        if (isExist == false) {
                                          cloneAddModalTopSelectedItems.push(
                                            element
                                          );
                                        }
                                      } else {
                                        for (
                                          let j = 0;
                                          j <= cloneAddModalTopSelectedItems?.length - 1;
                                          j++
                                        ) {
                                          if (
                                            element.company_id ==
                                            cloneAddModalTopSelectedItems[j]
                                              .company_id
                                          ) {
                                            cloneAddModalTopSelectedItems = [
                                              ...cloneAddModalTopSelectedItems.slice(
                                                0,
                                                j
                                              ),
                                              ...cloneAddModalTopSelectedItems.slice(
                                                j + 1
                                              ),
                                            ];
                                            break;
                                          }
                                        }
                                      }
                                    }

                                    addModalTopItemsUpdate(
                                      cloneAddModalTopSelectedItems
                                    );
                                  }
                                  const selectedSearchArrayItem = [
                                    {
                                      groupName: "Company",
                                      selectedItemLength:
                                        cloneAddModalTopSelectedItems.length,
                                      data: cloneAddModalTopSelectedItems.map(
                                        (val) => val.name
                                      ),
                                    },
                                  ];
                                  setSelectedSearchItems(
                                    selectedSearchArrayItem
                                  );
                                }}
                              />
                              <label htmlFor="vehicle1">{item?.name}</label>
                            </div>
                            <div className="md-ads">
                              <p>
                                {item.tickers && item.tickers.length > 0
                                  ? item.tickers[0]
                                  : ""}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {companyListLoding ? (
                      <CompanyLoader />
                    ) : companyList?.length > 10 && (
                      <div
                        onClick={() => {
                          removeFromCross = false
                          hanlderShowMoreCompany()
                        }}
                        className="showMoreButton"
                      >
                        Show More
                      </div>
                    )}
                    {companyList?.length == 0 && companyListLoding == false && (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h6>No companies found</h6>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              )}
              {typeBar === "Location" && (
                <Tabs.TabPane
                  tab={
                    <div className="md-icons">
                      <img
                        className={currentTab == 3 ? "filter-img" : ""}
                        src={Images?.locationIcon}
                        alt="img"
                      />
                      Incident Location
                    </div>
                  }
                  key="3"
                >
                  <div className="md-incident-loc1">
                    <div className="container">
                      <div
                        className="row "
                        style={{ overflow: "auto", height: "500px" }}
                      >
                        <div className="col-sm-5 p-0 border-right-md">
                          <div className=" custom-checkbox-top incident-md-active right-arrow">
                            <input
                              style={{ cursor: "pointer" }}
                              type="checkbox"
                              checked={countryList?.allStatus}
                              onClick={(e) => {
                                const UpdateLocationArray = updateLocationArray(_countryList, setCountryList, "all", {}, e.target.checked, null)
                                let groupName = "Incident Location";
                                if (search.length > 0) {
                                  setSearch("")
                                }
                                updateSelectSearchArray(groupName, UpdateLocationArray?.length, UpdateLocationArray);
                              }}
                            />
                            <label for="vehicle1">All</label>
                          </div>
                          {countryList?.continents?.map((item, index) => (
                            <div className=" custom-checkbox-top incident-md right-arrow">
                              <input
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                                checked={item.continent_status}
                                onClick={(e) => {
                                  const updateAllLocationArray = search?.length > 0 && updateLocationsWithSelectedItem(_countryList, selectedLocationItems, null)
                                  const UpdateLocationArray = updateLocationArray(search.length > 0 ? updateAllLocationArray.resultArray : countryList, setCountryList, item.continent_code, {}, e.target.checked, null)
                                  selectedLocationItems = []
                                  let groupName = "Incident Location";
                                  if (search.length > 0) {
                                    setSearch("")
                                  }
                                  updateSelectSearchArray(groupName, UpdateLocationArray?.length, UpdateLocationArray);
                                }}
                              />
                              <label for="vehicle1">
                                {item?.continent_code}
                              </label>
                            </div>
                          ))}
                        </div>

                        <div
                          className="col-sm-7 "
                          style={{ overflow: "auto", height: "500px" }}
                        >
                          {countryList?.continents?.map(
                            (item, continentsIndex) => {
                              return item?.countries?.map(
                                (itemNew, CountryIndex) => (
                                  <div className=" custom-checkbox-top mt-2">
                                    <input
                                      type="checkbox"
                                      style={{ cursor: "pointer" }}
                                      checked={itemNew?.status}
                                      value={itemNew.id}
                                      onClick={(e) => {
                                        const updateAllLocationArray = search?.length > 0 && updateLocationsWithSelectedItem(cloneLocationBasedOnSearch, selectedLocationItems, itemNew)
                                        const UpdateLocationArray = updateLocationArray(search.length > 0 ? updateAllLocationArray.resultArray : countryList, setCountryList, item.continent_code, itemNew, e.target.checked, search.length > 0 ? updateAllLocationArray.activeItemIndex : CountryIndex)
                                        selectedLocationItems = []
                                        let groupName = "Incident Location";
                                        if (search.length > 0) {
                                          cloneLocationBasedOnSearch = null
                                          setSearch("")
                                          setCountryList(pre => ({ continents: updateAllLocationArray.resultArray.continents, allStatus: false }))
                                        }
                                        updateSelectSearchArray(groupName, UpdateLocationArray?.length, UpdateLocationArray);
                                      }}
                                    />
                                    <label for="vehicle1">
                                      {itemNew?.name}
                                    </label>
                                  </div>
                                )
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              )}
              {typeBar === "Categories" && (
                <Tabs.TabPane
                  tab={
                    <div className="md-icons">
                      <img
                        className={currentTab == 4 ? "filter-img" : ""}
                        src={Images?.digram}
                        alt="img"
                      />
                      Category
                    </div>
                  }
                  key="4"
                >
                  <div className="md-incident-loc1">
                    <div className="container">
                      <div
                        className="row"
                        style={{ overflow: "auto", maxHeight: "500px" }}
                      >
                        <div
                          className="col-sm-5 p-0 border-right-md"
                          style={{ overflow: "auto", height: "500px" }}
                        >
                          {CategoriesList?.map((item, index) => (
                            <div
                              onClick={() =>
                                setActiveCategoriesItemNumber(index)
                              }
                              style={{ cursor: "pointer" }}
                              className=" custom-checkbox-top incident-md right-arrow"
                            >
                              <CheckBox
                                visible={item.status}
                                onClick={() => {
                                  workingWithLocationSelectedState = true
                                  let categories = search.length > 0 ? cloneCatergory : [...CategoriesList];
                                  let count = 0;
                                  let data = [];
                                  categories[index].status =
                                    !categories[index].status;
                                  let val = categories[index].value;
                                  for (let j = 0; j < val?.length; j++) {
                                    let item = val[j].data;
                                    for (let k = 0; k < item?.length; k++) {
                                      item[k].status = categories[index].status;
                                      if (item[k].status) {
                                        count = count + 1;
                                        data.push(item[k].name);
                                      }
                                    }
                                  }
                                  if (search?.length > 0) {
                                    cloneCatergory = null
                                    setSearch("")
                                  }



                                  const selectedItemESGString = categories?.filter(item => item.name == "ESG Factors")[0].value?.map(item => item.data)?.flat()?.filter(item => item.status)
                                  const selectedItemUNGString = categories?.filter(item => item.name == "UN global compact principles")[0].value?.map(item => item.data)?.flat()?.filter(item => item.status)
                                  setLocalCategories({ principles: selectedItemUNGString, esg_factor: selectedItemESGString })
                                  dispatch(updateCategoriesData(categories));
                                  updateSelectSearchArray(
                                    categories[index].name == "PAI Indicators"
                                      ? "Esg Pais"
                                      : categories[index].name,
                                    count,
                                    data
                                  );
                                  setCheckState(false)
                                  setTimeout(() => {

                                    setTimeout(() => {

                                    }, 400);
                                  }, 400);


                                }}
                              />
                              <label for="vehicle1">{item.name}</label>
                            </div>
                          ))}
                        </div>
                        <div
                          className="col-sm-7 p-0"
                          style={{ overflow: "auto", height: "500px" }}
                        >
                          {CategoriesList &&
                            CategoriesList[activeCategoriesItemNumber] &&
                            CategoriesList[activeCategoriesItemNumber][
                              "value"
                            ]?.map((itemx, index) => (
                              <div
                                className=" p-0"
                                style={{ overflow: "auto" }}
                              >
                                <div className="model-comp">
                                  <p>{itemx._id}</p>
                                </div>
                                <div className="px-3">
                                  {itemx?.data?.map((itemy, index) => {
                                    return (
                                      <div className="check-box-style">
                                        <div
                                          className="custom-checkbox-top mt-2"
                                          key={index}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <input
                                            type="checkbox"
                                            checked={itemy.status}
                                            onClick={(e) => {
                                              workingWithLocationSelectedState = true
                                              if (itemy["status"]) {
                                                itemy["status"] = false;
                                              } else {
                                                itemy["status"] = true;
                                              }
                                              let count = 0;
                                              let data = [];
                                              let categories = search.length > 0 ? cloneCatergory : [...CategoriesList];
                                              let targetElement = categories[activeCategoriesItemNumber].value;
                                              for (let index = 0; index <= targetElement.length - 1; index++) {
                                                const Element = targetElement[index].data;
                                                let selectedCount = 0
                                                for (let j = 0; j < Element.length; j++) {
                                                  const element = Element[j];
                                                  if (element.name == itemy.name) {
                                                    element.status = e.target.checked
                                                    selectedCount += 1
                                                  }
                                                }
                                                targetElement[index].status = selectedCount == Element.length
                                              }
                                              const combineStatus = categories[activeCategoriesItemNumber]?.value?.map(item => item.status);
                                              categories[activeCategoriesItemNumber].status = combineStatus.includes(false) ? false : true
                                              const selectedItemESGString = categories?.filter(item => item.name == "ESG Factors")[0].value?.map(item => item.data)?.flat()?.filter(item => item.status)
                                              const selectedItemUNGString = categories?.filter(item => item.name == "UN global compact principles")[0].value?.map(item => item.data)?.flat()?.filter(item => item.status)
                                              setLocalCategories({ principles: selectedItemUNGString, esg_factor: selectedItemESGString })
                                              dispatch(
                                                updateCategoriesData(
                                                  categories
                                                )
                                              );
                                              updateSelectSearchArray(
                                                categories[
                                                  activeCategoriesItemNumber
                                                ].name == "PAI Indicators"
                                                  ? "Esg Pais"
                                                  : categories[
                                                    activeCategoriesItemNumber
                                                  ].name,
                                                count,
                                                data
                                              );
                                              setCheckState(false)

                                              if (search.length > 0) {
                                                cloneCatergory = null
                                                setSearch("")
                                              }
                                            }}
                                          />
                                          <label htmlFor="vehicle1">
                                            {itemy.name}
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              )}
              {typeBar === "Industry" && (
                <Tabs.TabPane
                  tab={
                    <div className="md-icons">
                      <img
                        className={currentTab == 5 ? "filter-img" : ""}
                        src={Images?.indust}
                        alt="img"
                      />
                      Industry
                    </div>
                  }
                  key="5"
                >
                  <div className="md-incident-loc1">
                    <div className="container">
                      <div
                        className="row"
                        style={{ overflow: "auto", maxHeight: "500px" }}
                      >
                        <div
                          className="col-md-5 p-0 border-right-md"
                          style={{ overflow: "auto", height: "500px" }}
                        >
                          {industryList?.map((item, index) => (
                            <Row>
                              <div
                                key={index}
                                style={{ cursor: "pointer" }}
                                className=" custom-checkbox-top incident-md right-arrow"
                                onClick={() =>
                                  handleScrollIndus(index, item?._id)
                                }
                              >

                                <input type="checkbox"
                                  checked={item?.status}
                                  onClick={(e) => {
                                    const action = e.target.checked
                                    const cloneSelectediTem = [...selectedSearchItems.filter(item => item.groupName == "Industry")[0].data]
                                    const groupArray = item.data.map(item => item.name).filter(item => cloneSelectediTem.includes(item) ? false : true)
                                    if (action) {
                                      const combineArray = [...cloneSelectediTem, ...groupArray]
                                      updateSelectSearchArray("Industry", combineArray?.length, combineArray);
                                    } else {
                                      const deleteGroupArray = item.data.map(item => item.name)
                                      const cloneSelectediTem = selectedSearchItems.filter(item => item.groupName == "Industry")[0].data.filter(item => deleteGroupArray.includes(item) ? false : true)
                                      updateSelectSearchArray("Industry", cloneSelectediTem?.length, cloneSelectediTem);
                                    }
                                    updateParentIndus(item, "parent");
                                    if (search.length > 0) {
                                      setSearch("")
                                    }
                                  }}
                                />
                                <label for="vehicle1">{item._id}</label>
                              </div>
                            </Row>
                          ))}
                        </div>
                        <div
                          className="col-md-7 p-0"
                          style={{ overflow: "auto", maxHeight: "500px" }}
                        >
                          {industryList?.map((item, index) => (
                            <div className="p-0">
                              <div
                                className="model-comp"
                                ref={(ref) =>
                                  (rightIndusRef.current[index] = ref)
                                }
                              >
                                <p>{item._id}</p>
                              </div>
                              {item?.data?.map((itemx, Index) => (
                                <div className="px-3">
                                  <div
                                    style={{ cursor: "pointer" }}
                                    className=" custom-checkbox-top mt-2 mb-1"
                                  >
                                    <input type="checkbox"
                                      checked={itemx.status}
                                      onClick={(e) => {
                                        const action = e.target.checked
                                        if (action) {
                                          const cloneSelectediTem = [...selectedSearchItems.filter(item => item.groupName == "Industry")[0].data, itemx.name]
                                          updateSelectSearchArray("Industry", cloneSelectediTem?.length, cloneSelectediTem);
                                        } else {
                                          const cloneSelectediTem = selectedSearchItems.filter(item => item.groupName == "Industry")[0].data.filter(item => item !== itemx.name)
                                          updateSelectSearchArray("Industry", cloneSelectediTem?.length, cloneSelectediTem);
                                        }

                                        updateParentIndus(
                                          itemx,
                                          "child",
                                          item,
                                          Index
                                        );
                                        if (search.length > 0) {
                                          setSearch("")
                                        }
                                      }}
                                    />
                                    <label for="vehicle1">{itemx.name}</label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
              )}
            </Tabs>
          </div>
        </Modal>
      )}
    </>
  );
};
