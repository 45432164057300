import { call, put, takeLatest } from "redux-saga/effects";
import { toast as hotToast } from 'react-hot-toast';
import appconstant from "../../Constants/appConstansts";
import { onError, onFail, onSucces, onUnAuth } from "../../Utils/UnAuthFailFn";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  addPortfolioApi,
  getPortfolioListApi,
  mostFrequentEsdGraphApi,
  portfolioChangeClrApi,
  portfolioCompGraphApi,
  portfolioDetailApi,
  unGlobalGraphApi,
  portfolioESGRiskApi,
  alertReportGraphApi,
  riskScoreCompApi,
  highSeverityIncidentApi,
  uploadDocPortfolioApi,
  getEditPortfolioDetailApi,
  deletePortfolioApi,
  editPortfolioApi,
  distributionGraphApi,
  clearAlertRules,
  checkValidCompanyApi,
  getCompaniesForPortfolioApi,
  getPortfolioStatusApi,
  getPortfolioWeightApi
} from "../api";
import {
  getPortfolioListSuccess,
  getPortfolioListFail,
  portfolioChangeClrSuccess,
  portfolioChangeClrFail,
  addPortfolioSuccess,
  addPortfolioFail,
  portfolioCompSuccess,
  portfolioCompFail,
  mostFrequentEsgSuccess,
  mostFrequentEsgFail,
  portfolioDetialSuccess,
  portfolioDetialFail,
  unGlobalgraphSuccess,
  unGlobalgraphFail,
  PortfolioESGRiskFail,
  PortfolioESGRiskSuccess,
  getAlertReportSuccess,
  getAlertReportFail,
  getRiskScorePerCompSuccess,
  getRiskScorePerCompFail,
  getHighSeverityIncidentSuccess,
  getHighSeverityIncidentFail,
  uploadPortfolioFileSuccess,
  uploadPortfolioFileFail,
  deletePortfolioSuccess,
  deletePortfolioFail,
  getEditPortfolioSuccess,
  getEditPortfolioFail,
  editPortfolioSuccess,
  editPortfolioFail,
  distributionGraphSuccess,
  distributionGraphFail,
  clearAlertRulesSuccess,
  clearAlertRulesFail,
  checkValidCmpnySuccess,
  checkValidCmpnyFail,
  getPortfolioCompaniesListSearchSuccess,
  getPortfolioCompaniesListSearchFailure,
  getPortfolioStatusSuccess,
  getPortfolioStatusFailure,
  portfolioCompanySuccess,
  getPortfolioWeightSuccess,
  getPortfolioWeightFailure
} from "../Action/portfolio";
import { toast } from "react-toastify";
import { errorToastContainer, successToastContainer } from "../../Utils";

const { RoutesPath } = appconstant;

function* getPortfolioListSaga(action) {
  try {
    const data = yield call(getPortfolioListApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getPortfolioListSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getPortfolioListFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* portfolioChangeClrSaga(action) {
  try {
    const data = yield call(portfolioChangeClrApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(portfolioChangeClrSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(portfolioChangeClrFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* addPortfolioSaga(action) {
  try {
    const data = yield call(addPortfolioApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(addPortfolioSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(addPortfolioFail(data));
      toast.error(data.result.message, { toastId: "fail" });
      // yield call(onFail, { });
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* portfolioCompanyGraphSaga(action) {
  try {
    const data = yield call(portfolioCompGraphApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(portfolioCompSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(portfolioCompFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* mostFrequentEsgGraphSaga(action) {
  try {
    const data = yield call(mostFrequentEsdGraphApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(mostFrequentEsgSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(mostFrequentEsgFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}

function* portfolioDetailSaga(action) {
  try {
    const data = yield call(portfolioDetailApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(portfolioDetialSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(portfolioDetialFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}

function* unGlobalGraphSaga(action) {
  try {
    const data = yield call(unGlobalGraphApi, action.payload);
    if (data.status === 0) {
      yield put(unGlobalgraphSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(unGlobalgraphFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}
function* portfolioESGRiskSaga(action) {
  try {
    const data = yield call(portfolioESGRiskApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(PortfolioESGRiskSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(PortfolioESGRiskFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    yield call(onError);
  }
}

function* alertReportGraphSaga(action) {

  try {
    const data = yield call(alertReportGraphApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getAlertReportSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getAlertReportFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}

function* riskScoreCompGraphSaga(action) {

  try {
    const data = yield call(riskScoreCompApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getRiskScorePerCompSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getRiskScorePerCompFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}

function* highSeverityIncidentGraphSaga(action) {

  try {
    const data = yield call(highSeverityIncidentApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getHighSeverityIncidentSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getHighSeverityIncidentFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}

function* uploadPortfolioFileSaga(action) {

  try {
    const data = yield call(uploadDocPortfolioApi, action.payload);
    if (data.status === 0) {
      yield put(uploadPortfolioFileSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(uploadPortfolioFileFail(data));
      hotToast.success(
        errorToastContainer(data?.result?.message),
        { id: "invalidFileUpload" }
      )
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log("this is catch error = ", e)
    yield call(onError);
  }
}

function* deletePortfolioSaga(action) {

  try {
    const data = yield call(deletePortfolioApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(deletePortfolioSuccess(action.payload));
    }
    // on fail
    else {
      yield put(deletePortfolioFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log("this is catch error = ", e)
    yield call(onError);
  }
}

function* getEditPortfolioDetailSaga(action) {

  try {
    const data = yield call(getEditPortfolioDetailApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getEditPortfolioSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getEditPortfolioFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log("this is catch error = ", e)
    yield call(onError);
  }
}

function* distributionGraphSaga(action) {

  try {
    const data = yield call(distributionGraphApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(distributionGraphSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(distributionGraphFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log("this is catch error = ", e)
    yield call(onError);
  }
}

function* clearAlertRulesSaga(action) {

  try {
    const data = yield call(clearAlertRules, action.payload);
    // for success
    if (data.status === 0) {
      yield put(clearAlertRulesSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(clearAlertRulesFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}

function* checkValidCompanySaga(action) {

  try {
    const data = yield call(checkValidCompanyApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(checkValidCmpnySuccess(data.result.data));
    }
    // on fail
    else {
      yield put(checkValidCmpnyFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log("this is catch error = ", e)
    yield call(onError);
  }
}

function* editPortfolioSaga(action) {

  try {
    const data = yield call(editPortfolioApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(editPortfolioSuccess(data.result.data));
      hotToast.success(
        successToastContainer(data?.result?.message),
        { id: "invalidFileUpload", className: "success" }
      )

    }
    // on fail
    else {
      yield put(editPortfolioFail(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log("this is catch error = ", e)
    yield call(onError);
  }
}

function* searchCompaniesListSaga(action) {

  try {
    const data = yield call(getCompaniesForPortfolioApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getPortfolioCompaniesListSearchSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getPortfolioCompaniesListSearchFailure(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log("this is catch error = ", e)
    yield call(onError);
  }
}

function* getPortfolioStatusSaga(action) {

  try {
    const data = yield call(getPortfolioStatusApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getPortfolioStatusSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getPortfolioStatusFailure(data));
      // yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log("this is catch error = ", e)
    yield call(onError);
  }
}

function* getCompaniesListSaga(action) {
  try {
    const data = yield call(portfolioCompGraphApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(portfolioCompanySuccess(data.result.data));
    }
    // on fail
    else {
      yield put(portfolioCompFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getPortfolioWeightSaga(action) {
  try {
    const data = yield call(getPortfolioWeightApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getPortfolioWeightSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getPortfolioWeightFailure(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}

export default function* rootPortfolioPageSaga() {
  yield takeLatest(type.GET_PORTFOLIO_LIST_LOAD, getPortfolioListSaga);
  yield takeLatest(type.PORTFOLIO_CHANGE_CLR_LOAD, portfolioChangeClrSaga);
  yield takeLatest(type.ADD_PORTFOLIO_LOAD, addPortfolioSaga);
  yield takeLatest(type.PORTFOLIO_COMP_LOAD, portfolioCompanyGraphSaga);
  yield takeLatest(type.MOST_FREQUENT_ESG_LOAD, mostFrequentEsgGraphSaga);
  yield takeLatest(type.PORTFOLIO_DETAIL_LOAD, portfolioDetailSaga);
  yield takeLatest(type.UN_GLOBAL_LOAD, unGlobalGraphSaga);
  yield takeLatest(type.PORTFOLIO_ESG_RISK_LOAD, portfolioESGRiskSaga);
  yield takeLatest(type.ALERT_REPORT_LOAD, alertReportGraphSaga);
  yield takeLatest(type.RISK_SCORE_PER_COMP_LOAD, riskScoreCompGraphSaga);
  yield takeLatest(type.HIGH_SEVERITY_INCIDENT_LOAD, highSeverityIncidentGraphSaga);
  yield takeLatest(type.UPLOAD_FILE_PORTFOLIO_LOAD, uploadPortfolioFileSaga);
  yield takeLatest(type.DELETE_PORTFOLIO_LOAD, deletePortfolioSaga);
  yield takeLatest(type.GET_EDIT_PORTFOLIO_DETAIL_LOAD, getEditPortfolioDetailSaga);
  yield takeLatest(type.EDIT_PORTFOLIO_LOAD, editPortfolioSaga);
  yield takeLatest(type.DISTRIBUTION_GRAPH_LOAD, distributionGraphSaga);
  yield takeLatest(type.CLEAR_ALERT_RULES_LOAD, clearAlertRulesSaga);
  yield takeLatest(type.CHECK_VALID_COMPNY_LOAD, checkValidCompanySaga);
  yield takeLatest(type.GET_PORTFOLIO_COMPANIES_SEARCH_LIST_INITIATE, searchCompaniesListSaga);
  yield takeLatest(type.GET_PORTFOLIO_STATUS_LOAD, getPortfolioStatusSaga);
  yield takeLatest(type.GET_PORTFOLIO_COMP_LOAD, getCompaniesListSaga);
  yield takeLatest(type.GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_LOAD, getPortfolioWeightSaga);
}
