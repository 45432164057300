import React, { memo, useCallback, useState } from "react";

import EChartsReact from "echarts-for-react";
import { ESGObj, checkColor } from "../../../Utils";
import * as echarts from "echarts/core";
import IncidentsListModal from "../../IncidentsListModal";

var graphic = echarts.graphic;

const color = (color, color2) => {
  return new graphic.LinearGradient(1, 0, 0, 0, [
    {
      offset: 0,
      color: color,
    },
    {
      offset: 1,
      color: color2,
    },
  ]);
};
const color2 = (color, color2, x1 = 0, y1 = 0, x2 = 0, y2 = 0) => {
  return new graphic.LinearGradient(x1, y1, x2, y2, [
    {
      offset: 0,
      color: color,
    },
    {
      offset: 1,
      color: color2,
    },
  ]);
};

const IncidentSeverity = ({ data, onClick }) => {

  const [showModal, setShowModal] = useState(false)
  const [specificIncidentIds, setSpecificIncidentIds] = useState([])

  const option = {
    silent: false,

    series: [
      {
        label: {
          color: "#fff",
          fontWeight: 400,
          itemStyle: {
            // shadowBlur: 1,
            shadowColor: "#000000",
            color: "red",
          },
          // textBorderColor: '#fff',
          textBorderWidth: 2,
          formatter: function (param) {
            var depth = param.treePathInfo.length;
            if (depth === 2) {
              return "radial";
            } else if (depth === 3) {
              return "tangential";
            } else if (depth === 4) {
              return "0";
            }
            return "345345";
          },
        },
        levels: [
          {},
          {
            itemStyle: {
              color: "#CD4949",
              borderRadius: 0,
              borderJoin: "bevel",
            },
            r: "35%",
            label: {
              rotate: 0,
              fontSize: 22,
              fontWeight: 600,
              fontFamily: "Inter",
            },
          },
          {
            itemStyle: {
              color: "#fff",
              borderWidth: 0,
              borderRadius: 0,
              borderJoin: "bevel",
            },
            r0: "35%",
            r: "40%",
          },
          {
            r0: "45%",
            r: "70%",
            itemStyle: {
              borderRadius: 7,
              borderWidth: 1,
            },
            label: {
              rotate: 0,
            },
          },
        ],

        nodeClick: false,
        radius: ["0", "80%"],
        type: "sunburst",
        emphasis: {
          focus: "ancestor",
        },
      },
    ],
  };
  const dataMapping = useCallback(
    (data) => {
      option.series[0].data = [
        {
          value: 6,
          name: "E",
          itemStyle: {
            color: color("#61A61C", "#61A61C4D"),
          },
          children: [
            {

              value: 6,
              children: [
                {
                  itemStyle: {
                    color: "#fff",
                  },
                  value: 0.3,
                  name: "",
                },
                {
                  itemStyle: {
                    color: color2(
                      "#FFDC20",
                      "rgba(255, 220, 32, 0.3)",
                      1,
                      0,
                      0,
                      1
                    ),
                    rotate: 90,
                  },
                  fontFamily: "Inter",
                  value: 1.25,
                  name: data?.Environmental?.Minor?.length,
                  _name: "Minor",
                  parent: "E",
                },
                {
                  itemStyle: {
                    color: color2(
                      "#F28C28",
                      "rgba(242, 140, 40, 0.30)",
                      1,
                      0,
                      0,
                      1
                    ),
                  },
                  value: 1.25,
                  fontFamily: "Inter",
                  name: data?.Environmental?.Moderate?.length,
                  _name: "Moderate",
                  parent: "E",
                },
                {
                  itemStyle: {
                    color: color2(
                      "#FF0000",
                      "rgba(255, 0, 0, 0.3)",
                      1,
                      1,
                      0,
                      1
                    ),
                  },
                  value: 1.25,
                  fontFamily: "Inter",
                  name: data?.Environmental?.High?.length,
                  _name: "High",
                  parent: "E",
                },
                {
                  itemStyle: {
                    color: color2(
                      "#800020",
                      "rgba(128, 0, 32, 0.3)",
                      0,
                      0,
                      1,
                      1
                    ),
                  },
                  fontFamily: "Inter",
                  value: 1.25,
                  name: data?.Environmental?.Critical?.length || 0,
                  _name: "Critical",
                  parent: "E",
                },
                {
                  itemStyle: {
                    color: "#fff",
                  },
                  value: 0.3,
                  name: "",
                },
              ],
            },
          ],
        },
        {
          value: 6,
          name: "S",
          itemStyle: {
            color: new graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "#2191FB",
              },
              {
                offset: 1,
                color: "#2191FB4D",
              },
            ]),
          },
          children: [
            {
              children: [
                {
                  itemStyle: {
                    color: color2(
                      "#FFDC20",
                      "rgba(255, 220, 32, 0.3)",
                      1,
                      1,
                      0,
                      0
                    ),
                  },
                  fontFamily: "Inter",
                  value: 1.25,
                  name: data?.Social?.Minor?.length,
                  _name:"Minor",
                  parent:"S",
                },

                {
                  itemStyle: {
                    color: color2(
                      "#F28C28",
                      "rgba(242, 140, 40, 0.30)",
                      0,
                      1,
                      0,
                      0
                    ),
                  },
                  value: 1.25,
                  fontFamily: "Inter",
                  name: data?.Social?.Moderate?.length,
                  _name:"Moderate",
                  parent:"S",
                },
                {
                  itemStyle: {
                    color: color2(
                      "#FF0000",
                      "rgba(255, 0, 0, 0.3)",
                      0,
                      1,
                      1,
                      0
                    ),
                  },
                  value: 1.25,
                  fontFamily: "Inter",
                  name: data?.Social?.High?.length,
                  _name:"High",
                  parent:"S",
                },
                {
                  itemStyle: {
                    color: color2(
                      "#800020",
                      "rgba(128, 0, 32, 0.3)",
                      0,
                      0,
                      1,
                      1
                    ),
                  },
                  fontFamily: "Inter",
                  value: 1.25,
                  name: data?.Social?.Critical?.length || 0,
                  _name:"Critical",
                  parent:"S",
                },
                {
                  itemStyle: {
                    color: "#fff",
                  },
                  value: 0.6,
                  name: "",
                },
              ],
            },
          ],
        },
        {
          value: 6,
          name: "G",

          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 0,
                color: "#653DD4",
              },
              {
                offset: 1,
                color: "#653DD44D",
              },
            ]),
          },
          children: [
            {
              children: [
                {
                  itemStyle: {
                    color: color2(
                      "#FFDC20",
                      "rgba(255, 220, 32, 0.3)",
                      0,
                      0,
                      1,
                      0
                    ),
                  },
                  fontFamily: "Inter",
                  value: 1.25,
                  name: data?.Governance?.Minor?.length,
                  _name:"Minor",
                  parent:"G",
                },

                {
                  itemStyle: {
                    color: color2(
                      "#F28C28",
                      "rgba(242, 140, 40, 0.30)",
                      0,
                      0,
                      1,
                      0
                    ),
                  },
                  fontFamily: "Inter",
                  value: 1.25,
                  name: data?.Governance?.Moderate?.length,
                  _name:"Moderate",
                  parent:"G",
                },
                {
                  itemStyle: {
                    color: color2(
                      "#FF0000",
                      "rgba(255, 0, 0, 0.3)",
                      0,
                      0,
                      1,
                      1
                    ),
                  },
                  fontFamily: "Inter",
                  value: 1.25,
                  name: data?.Governance?.High?.length,
                  _name:"High",
                  parent:"G",
                },
                {
                  itemStyle: {
                    color: color2(
                      "#800020",
                      "rgba(128, 0, 32, 0.3)",
                      0,
                      0,
                      1,
                      1
                    ),
                  },
                  fontFamily: "Inter",
                  value: 1.25,
                  name: data?.Governance?.Critical?.length || 0,
                  _name:"Critical",
                  parent:"G",
                },
                {
                  itemStyle: {
                    color: "#fff",
                  },
                  value: 0.6,
                  name: "",
                },
              ],
            },
          ],
        },
      ];
      return option;
    },
    [data]
  );

  function onChartClick(param) {
    const parent = {
      S: "Social",
      G: "Governance",
      E: "Environmental",
    };
    let _parent = param.data.parent;
    let Parent = parent[_parent];
    let child = param.data._name;

    makeidListArray({ Parent, child, _parent, param });
  }

  const makeidListArray = (allData) => {
    let Critical = [];
    let allArr = [];
    let Moderate = [];
    let High = [];
    let Minor = [];

    if (
      allData.param.name == "S" ||
      allData.param.name == "G" ||
      allData.param.name == "E"
    ) {
      const Data =
        data[
        allData.param.name == "S"
          ? "Social"
          : allData.param.name == "E"
            ? "Environmental"
            : "Governance"
        ];
      Critical = Data["Critical"]?.map((x, i) => x.id);
      Moderate = Data["Moderate"]?.map((x, i) => x.id);
      High = Data["High"]?.map((x, i) => x.id);
      Minor = Data["Minor"]?.map((x, i) => x.id);
      allArr = Critical?.concat(Moderate, High, Minor);

      setSpecificIncidentIds(allArr);
    } else {
      const Data = data[allData?.Parent];
      let listArray = Data[allData.child]?.map((x, i) => x.id);
      setSpecificIncidentIds(listArray);
    }
    if (!document.querySelector("div.ant-modal-wrap.ant-modal-centered")) {
      // check if any modal not already open
      setShowModal(true);
    }
  };



  return (
    <>
      <EChartsReact
        className="doubleLayer-pieChart"
        option={data ? JSON.parse(JSON.stringify(dataMapping(data))) : {}}
        onEvents={{
          click: onChartClick,
          // legendselectchanged: onChartLegendselectchanged,
        }}
      />
      {showModal && (
        <IncidentsListModal
          setModalVisible={setShowModal}
          networkData={{ specificIncidentIds }}
        />
      )}
    </>

  );
};

export default memo(IncidentSeverity);
