import React, { useState, useEffect } from "react";
import Images from "../../../Themes/ImageConstant";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AutoComplete, Breadcrumb, Modal, Pagination } from "antd";
import { Input } from 'antd';


import { useDispatch, useSelector } from "react-redux";
import SunBrust from "./charts/Sunburst";
import { getCountryDataforSupplyChain } from "../../../Redux/Action/supplyChainAction";
import { searchCompanyListActionLoad } from "../../../Redux/Action/global";
import useDebouncedApiCall from "../../../hook/useDebouncedSearch";
import { checkRiskColor, checkSeverityColor, getRiskVal, getRiskValSeverity } from "../../../Utils";
const SeverityScore = (props) => {
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();
    const [countryName, setCountryName] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const { state, search } = useLocation();

    const getColor = (data) => {
        if ((data == "High")) {
            return "#FF0000";
        }
        if ((data == "Minor")) {
            return "#FFBF00";
        }
        if ((data == "Moderate")) {
            return "#FF7518";
        }
        if ((data == "Critical")) {
            return "#800020";
        }
    };

    const { countryList } = useSelector((state) => state?.supplyChainReducer);
    useEffect(() => {
        dispatch(getCountryDataforSupplyChain());
    }, []);
    const { searchCompaniesData } = useSelector((state) => state?.global);
    const { searchTerm, handleSearch } = useDebouncedApiCall(
        searchCompanyListActionLoad,
        { offset: 0, limit: 20 }
    );

    const handleSearchOperation = (SelectedData) => {
        if (searchCompaniesData?.data?.length !== 0) {
            navigate("/companyPage", { state: { id: SelectedData.id } });
        }
    };
    // TABLE COLUMN
    return (
        <div className="side-padding">
            <div className="main-layout table-layout">
                <div className="container">
                    <section className="supply-chain-top-ps ">
                        <div className="supply-chain-breadcrumb">
                            <div className="breadcrmbs">
                                <Breadcrumb>
                                    <Breadcrumb.Item key="1" className="inc-bad-link">
                                        <Link to="/incidentdetails" state={{ id: state.id }}>Incidents </Link>{" "}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item key="1" className="inc-bad-link">
                                        <Link to="/incidentdetails" state={{ id: state.id }}>Incident Details </Link>{" "}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item key="3" className="detail-lie-link">
                                        Severity Score
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <div className="social-media-search supply-chain-search-ps">
                                <div className="autocomplete-input-search">
                                    <AutoComplete
                                        popupClassName="certain-category-search-dropdown"
                                        popupMatchSelectWidth={500}
                                        className="serchmedia-icon"
                                        size="large"
                                        onSearch={handleSearch}
                                        value={countryName}
                                        options={searchCompaniesData?.data}
                                        onSelect={(e, selectedOption) => {
                                            const { label } = selectedOption;
                                            setCountryName(label);
                                            setSelectedData(selectedOption);
                                            handleSearchOperation(selectedOption);
                                        }}
                                        onChange={(e, i) => {
                                            setSelectedData(i);
                                            setCountryName(e);
                                        }}
                                    >
                                        <Input.Search size="large" placeholder="Search company" />
                                    </AutoComplete>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>

                        <div className="top-severity-section">

                            <div className="top-severity-colors-ps">
                                <span><img src={Images.redBullet} /></span>
                                <span><img src={Images.orangeBullet} /></span>
                                <span><img src={Images.yellowBullet} /></span>
                                <span><img src={Images.yellowBullet} /></span>
                            </div>
                            <div className="score-severity-ps">
                                <h2>Incident Severity Score</h2>
                            </div>
                            <div className="number-of-score-ps">
                                <h1>{state?.severity_score?.value?.toFixed(0)}</h1>
                            </div>

                            <div className="moderate-btn">
                                <button
                                    style={{
                                        backgroundColor: getColor(state?.severity_score?.level),
                                    }}
                                >
                                    {state?.severity_score?.level?.toUpperCase()}
                                </button>

                            </div>

                            <div className="severity-disp-text-ps">
                                <p> {`"Incident severity score" is calculated by aggeregating different proportion of impact and topic scores`}</p>
                                {/* <p>Severity score is calculated by the average of ESG Category scoring factors and AI scaling Evidence. Both scoring type are explained below</p> */}
                            </div>
                        </div>

                        <div className="errow-image-ps ">
                            <img src={Images.rectangErrow} alt="errow" />
                        </div>


                        <div className="severity-socre-page">
                            <div className="main-severity-socre-div">
                                <div className="medium-severity-section">

                                    <div className="score-severity-ps">
                                        <h2>Topic Score</h2>
                                    </div>

                                    <div className="key-factors-severity">
                                        <h3>ESG Category Score</h3>
                                    </div>

                                    <div className="number-of-score-ps">
                                        <h2>{state?.severity_risk?.score?.toFixed(0)}</h2>
                                    </div>

                                    <div className="minor-btn">
                                        <button
                                            style={{
                                                backgroundColor: getColor(state?.severity_risk?.most_riskiest_keyword?.severity_level),
                                            }}
                                        >
                                            {state?.severity_risk?.most_riskiest_keyword?.severity_level?.toUpperCase()}
                                        </button>
                                    </div>

                                    <div className="esg-disp-text-ps">
                                        <span> <img src={Images.grayinfo} /> </span>
                                        <p>{`"Topic score" is calculated by quantifying the level of severity based on the associated topics`}</p>
                                    </div>
                                </div>
                                <div className="severity-graph-sec">
                                    <div className="graph-inner-section-div" >
                                        {/* <img src={Images.severitySunGraph} />    */}
                                        <SunBrust dataVal={state?.graphData || []} />
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="severity-socre-page">
                            <div className="main-severity-socre-div">
                                <div className="medium-severity-section">

                                    <div className="score-severity-ps">
                                        <h2>Impact Score</h2>
                                    </div>

                                    <div className="number-of-score-ps">
                                        <h2>{state?.aiScore?.score?.toFixed(0)}</h2>
                                    </div>

                                    <div className="high-btn-ps" >
                                        <button style={{
                                            backgroundColor: checkSeverityColor[getRiskValSeverity(state?.aiScore?.score?.toFixed(0))],
                                        }} type="submit">{getRiskValSeverity(state?.aiScore?.score?.toFixed(0))}</button>
                                    </div>

                                    <div className="esg-disp-text-ps">
                                        <span> <img src={Images.grayinfo} /> </span>
                                        <p> {`"Impact score" calculated by assessing the scale of negative impact from incidents on the company.`}</p>
                                    </div>
                                </div>
                                <div className="ai-severity-graph-sec">
                                    <div className="al-avidence">
                                        <div className="al-reason">
                                            <h5>AI Evidence & Reason for this scoring:</h5>
                                            {state.aiScore.description.map((val, ind) => {
                                                return (
                                                    <div className="number-of-reason">
                                                        <div className="index-box-ps">
                                                            <span>{ind + 1}</span>
                                                        </div>
                                                        <div className="ai-text-disp-ps">
                                                            <p>{val}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </section>

                </div>


            </div>


        </div>
    );
};



export default SeverityScore;
