/* eslint-disable no-param-reassign */
import { Box, Checkbox, MenuItem, Typography, useTheme } from "@mui/material";
import {
  prepareIncidentCounts,
  getEdgeProperLabel,
  getFilterTagIds,
  decodeUnicodeEscapes,
  getBadge,
} from "../../../../../utils/index";
import { useAppSelector } from "../../../../../redux/configuareStore";
import { useGraphObject } from "../../../../context/MyContext";

const MenuItemSxProps = {
  height: "32px",
  padding: "8px 4px",
};
function EntityItemContent({ entity, checked }) {
  const theme = useTheme();
  const nodes = useAppSelector((state) => state.graph.nodes);
  const currentNodeIds = nodes.map((n) => n.id);
  return (
    <Box>
      <Checkbox
        checked={checked || currentNodeIds?.includes(entity.id)}
        sx={{
          padding: "4px",
          color: theme.palette.gray[200],
          marginRight: "4px",
        }}
      />
      <Typography
        noWrap
        display="inline"
        sx={{ color: theme.palette.gray[700] }}
      >
        {decodeUnicodeEscapes(entity.name)}
      </Typography>
    </Box>
  );
}
export function TheAllItem({ allItemIsChecked, handleOnAllItemClick }) {
  return (
    <MenuItem
      key="item-the-all"
      onClick={handleOnAllItemClick}
      sx={MenuItemSxProps}
    >
      <EntityItemContent
        entity={{ id: "ALL_NEIGHBOURS", name: "All" }}
        checked={allItemIsChecked}
      />
    </MenuItem>
  );
}
function EntityItem({ entity, selectedRelation, selectedRelationDirection }) {
  // const graphContextMenu = useAppSelector((state) => state.contextMenu);
  const { graphObject } = useGraphObject();
  const nodes = useAppSelector((state) => state.graph.nodes);
  const currentNodeIds = nodes.map((n) => n.id);
  const targetNodeId = useAppSelector(
    (state) => state.rightClickMenu.targetNodeId
  );
  return (
    <MenuItem
      onClick={() => {
        const source =
          selectedRelationDirection === "OUT_GOING" ? targetNodeId : entity.id;
        const target =
          selectedRelationDirection === "OUT_GOING" ? entity.id : targetNodeId;
        const newNodeId = entity.id;
        const newEdgeId = `${source}-${target}`;
        const edgeLabel = getEdgeProperLabel(selectedRelation || "");
        graphObject?.startHistoryBatch();
        if (!currentNodeIds?.includes(entity.id)) {
          const nodeAttributes = {
            incidentCount: prepareIncidentCounts(entity.incidentCount),
            tier: entity.tier ? Math.abs(entity.tier) : undefined,
            sort: entity.tier ? 4 - Math.abs(entity.tier) : 0,
            layer: entity.tier ? Math.abs(entity.tier) : 4,
            riskScore: entity.riskScore,
            industry: entity.industry,
            location: entity.location,
            label: decodeUnicodeEscapes(entity.name),
          };
          const nodeData = {
            id: newNodeId,
            data: {
              isCenterNode: false,
              ...nodeAttributes,
              logo: entity.logo,
              filterTagIds: getFilterTagIds(nodeAttributes),
              badge: getBadge(nodeAttributes),
            },
          };
          graphObject?.addData("node", nodeData);
          const edgeData = {
            id: newEdgeId,
            source,
            target,
            data: {
              label: edgeLabel,
              shortLabel: entity.shortRelationName,
            },
          };
          graphObject?.addData("edge", edgeData);
        } else {
          graphObject?.removeData("node", newNodeId);
          graphObject?.removeData("edge", newEdgeId);
        }
        // graphObject?.layout();
        graphObject?.stopHistoryBatch();
        graphObject?.layout();
      }}
      sx={MenuItemSxProps}
    >
      <EntityItemContent entity={entity} />
    </MenuItem>
  );
}
export default EntityItem;
