import {
  Chip,
  Typography,
  useTheme,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import ShortestPathIcon from "./shortestPathIcon";
import { useGraphObject } from "../context/MyContext";
import { handleShortestPath } from "../../utils";
import { useAppSelector } from "../../redux/configuareStore";

// function getColorFromScore(_score) {
//   // TODO: Need to change this to consider score range
//   return '#D00000';
// }
function CompanyConnectionItem({ name, id, index, total, stat }) {
  const theme = useTheme();
  const { graphObject } = useGraphObject();
  const sideBarNodeId = useAppSelector(
    (state) => state.rightClickMenu.sideBarNodeId
  );
  return (
    <>
      <Stack direction="row" gap={1}>
        <div>
          <Chip
            label={index}
            style={{ ...theme.typography.captionS }}
            size="small"
          />
        </div>
        <div style={{ width: "100%" }}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                {/* Component located in the far left */}
                <Typography variant="bodySelected">{name}</Typography>
                <IconButton
                  sx={{
                    borderRadius: "4px",
                    border: `1px solid ${theme.palette.grey[300]}`,
                    margin: "4px",
                  }}
                  onClick={async () => {
                    const sourceNode = sideBarNodeId;
                    const targetNode = id;
                    await handleShortestPath(
                      sourceNode,
                      targetNode,
                      graphObject
                    );
                  }}
                >
                  <ShortestPathIcon />
                </IconButton>
              </div>
              <div>
                {/* Component located in the far right */}
                <div
                  variant="contained"
                  style={{ ...theme.typography.captionL }}
                >
                  {total} Connection(s)
                </div>
              </div>
            </Stack>
            <Stack direction="column" spacing={0}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ ...theme.typography.captionS }}
              >
                <div>&nbsp; Suppliers </div>
                <div>{stat.SUPPLIER.total || 0}</div>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ ...theme.typography.captionS }}
              >
                <div>&nbsp; Customers </div>
                <div>{stat.CUSTOMER.total || 0}</div>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ ...theme.typography.captionS }}
              >
                <div>&nbsp; Partners </div>
                <div>{stat.PARTNER.total || 0}</div>
              </Stack>
            </Stack>
            <Divider orientation="horizontal" flexItem />
          </Stack>
        </div>
      </Stack>
    </>
  );
}
export default CompanyConnectionItem;
