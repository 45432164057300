// 13/07/2023 MM
import { call, put, take, takeLatest, takeLeading } from "redux-saga/effects";
import appconstant from "../../Constants/appConstansts";
import {
  Loader,
  onError,
  onFail,
  onSucces,
  onUnAuth,
} from "../../Utils/UnAuthFailFn";
import { toast } from "react-toastify";
import { storage } from "../../Utils";
import { apiConstants as type } from "../../Constants/apiConstants";
import { message } from "antd";
import { createSaveIncidenceApi, deleteCollectionApi, getCollectionApi, removeIncidenceApi, renameIncidentApi } from "../api";
import { dispatch } from "../../Utils/dispatchNavigate";
import { getCollectionAction, } from "../Action/saveIncidence";
import { incindentAction, updateIncidentState } from "../Action/incidents";
const { RoutesPath } = appconstant;

// get collection saga 13/07/2023
function* getIncidenceColletionListSaga(action) {
  try {
    let data = yield call(getCollectionApi, action?.data)
    //on success
  
    if (data?.status === 0) {
      let obj = {
        type: type?.API_GET_COLLECTION_SUCCESS,
        data,
        show: false,
        navigationLink: false,
      }
      yield call(onSucces,obj);

    }
    //on auth fail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message);
    }
    else if (data?.status === 1) {
      let obj = {
        type: type?.API_GET_COLLECTION_FAIL,
        message: data?.result?.message,
      }
      yield call(onFail,obj)
    }

  } catch (e) {
    yield call(onError)
  }

}

// create save incidence
function* createSaveIncidenceSaga(action) {
  
  try {
    let data = yield call(createSaveIncidenceApi,action?.data)
    // on success 
    if (data?.status === 0) {
      let obj = {
        type: type.API_CREATE_COLLECTION_SUCCESS,
        data,
        show: false,
        navigationLink: false
      }
      
      yield call(onSucces, obj);
      dispatch(getCollectionAction())
    } 
    //on authfail
    else if (data?.status === 2) {
      onUnAuth(data?.result?.message)
    } 
    // on fail
    else if (data?.status === 1) {
      let obj = {
        type: type?.API_CREATE_COLLECTION_FAIL,
        message: data?.result?.message,
      }
      yield call(onFail, obj)
    }
  } catch (e) {
    yield call(onError)

  }
}
function* deleteCollectionSaga(action){
try{
let data= yield call(deleteCollectionApi,action.data);
// on success
if (data?.status===0){
  let obj ={
    type:type.API_DELETE_COLLECTION_SUCCESS,
    data,
    navigationLink:false,
    show:false
  }
  yield call (onSucces,obj);
  dispatch(getCollectionAction())
}
// on unAuth
else if(data?.status===2){
  onUnAuth(data?.result?.message);
}
// on fail
else if (data?.status===1){
  let obj ={
    type:type.API_DELETE_COLLECTION_FAIL
    ,message:data?.result?.message
  }
  yield call(onFail,obj);
}

}catch(e){
 yield call (onError);
}
}
// REMOVE INCIDENCE
function* removeIncidenceSaga(action){
try{
let data = yield call (removeIncidenceApi,action.data);
if(data?.status===0){
  let obj={
    type:type.API_REMOVE_COLLECTION_SUCCESS,
    data,
    show:true,
    navigationLink:false
  }
    yield put({
      type:type.API_INCIDENT_LOAD,
      data:{
          collectionId:action.data.collectionId,
        }
    })
  yield call(onSucces,obj);
 

}
else if(data.status===2){
  onUnAuth(data?.result?.message);

}else if(data.status===1){
  let obj ={
    type:type.API_REMOVE_COLLECTION_FAIL,
    message :data?.result?.message
  }

}
}catch(e){
  console.log(e, "error");
    yield call(onError);
}
}

// REMOVE INCIDENCE
function* renameIncidenceSaga(action){
  try{
  let data = yield call (renameIncidentApi,action.data);
  if(data?.status===0){
    let obj={
      type:type.SET_RENAME_COLLECTION_SUCCESS,
      data,
      show:false,
      navigationLink:false
    }
    yield call(onSucces,obj);
      
    yield put({
      type:type.UPDATE_RENAME_COLLECTION_SUCCESS,
      data:action.data
    })
  
  }
  else if(data.status===2){
    onUnAuth(data?.result?.message);
  
  }else if(data.status===1){
    let obj ={
      type:type.SET_RENAME_COLLECTION_FAIL,
      message :data?.result?.message
    }
    yield call(onFail,obj)
  }
  }catch(e){
    console.log(e, "error:::::::::::");
    yield call(onError);
  }
  }


export default function* rootSaveIncidenceSaga() {
  yield takeLatest(type.API_GET_COLLECTION_LOAD, getIncidenceColletionListSaga);
  yield takeLatest(type.API_CREATE_COLLECTION_LOAD, createSaveIncidenceSaga);
  yield takeLatest(type.API_DELETE_COLLECTION_LOAD,deleteCollectionSaga);
yield takeLatest(type.API_REMOVE_COLLECTION_LOAD,removeIncidenceSaga)
yield takeLatest(type.SET_RENAME_COLLECTION_LOAD,renameIncidenceSaga)
}