import appconstant from "../../Constants/appConstansts";
import axios from "axios";
import { getToken } from "../../Utils";

let APIKit = axios.create({
  baseURL: appconstant.URL.SERVER_URL,
  timeout: 600000,
});

APIKit.interceptors.request.use(async (config) => {
  if (getToken()) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  return config;
});
export default APIKit;
