import { call, put, take, takeEvery, takeLatest } from "redux-saga/effects";

import appconstant from "../../Constants/appConstansts";
import {
  Loader,
  onError,
  onFail,
  onSucces,
  onUnAuth,
} from "../../Utils/UnAuthFailFn";
import { toast } from "react-toastify";
import { storage, successToastContainer } from "../../Utils";
import { toast as hotToast } from "react-hot-toast";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  getCategoriesApi,
  getCompaniesApi,
  getTopSearchApi,
  getCountryListApi,
  getIndustryApi,
} from "../api";
import { message } from "antd";
import {
  getAdvncTopSearchDataSuccess,
  getAdvncTopSearchDataFailure,
  getAdvncCompanyDataSuccess,
  getAdvncCompanyDataFail,
  getAdvncIncLocationDataSuccess,
  getAdvncIncLocationDataFail,
  getAdvncCategoryDataSuccess,
  getAdvncCategoryDataFail,
  getAdvncIndustryDataSuccess,
  getAdvncIndustryDataFail,
} from "../Action/advanceSearch";
const { RoutesPath } = appconstant;

function* getAdvncTopSearchListSaga(action) {
  try {
    const data = yield call(getTopSearchApi, action.data);
    // for success
    if (data.status === 0) {
      yield put(getAdvncTopSearchDataSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getAdvncTopSearchDataFailure(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getAdvncCompanyListSaga(action) {
  try {
    const data = yield call(getCompaniesApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getAdvncCompanyDataSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getAdvncCompanyDataFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getAdvncIncLocationListSaga(action) {
  try {
    const data = yield call(getCountryListApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getAdvncIncLocationDataSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getAdvncIncLocationDataFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getAdvncCategoryListSaga(action) {
  try {
    const data = yield call(getCategoriesApi, action.payload);
    // for success
    if (data.status === 0) {
      yield put(getAdvncCategoryDataSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getAdvncCategoryDataFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

function* getAdvncIndustryListSaga(action) {
  try {
    const data = yield call(getIndustryApi, action.payload);

    let update = data?.result?.data?.map((val) =>
      !val.status ? { ...val, status: false } : val
    );
    for (let i = 0; i <= update?.length - 1; i++) {
      for (let j = 0; j <= update[i].childs?.length - 1; j++) {
        if (!update[i].childs[j].status) {
          update[i].childs[j] = {
            ...update[i].childs[j],
            status: false,
          };
        }
      }
    }
    data.result["data"] = update;

    // for success
    if (data.status === 0) {
      yield put(getAdvncIndustryDataSuccess(data.result.data));
    }
    // on fail
    else {
      yield put(getAdvncIndustryDataFail(data));
      yield call(onFail, {});
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield call(onError);
  }
}

export default function* rootAdvanceSearchSaga() {
  yield takeLatest(
    type.GET_ADVNC_TOP_SEARCH_DATA_LOAD,
    getAdvncTopSearchListSaga
  );
  yield takeLatest(type.GET_ADVNC_COMPANY_DATA_LOAD, getAdvncCompanyListSaga);
  yield takeLatest(
    type.GET_ADVNC_INC_LOCATION_DATA_LOAD,
    getAdvncIncLocationListSaga
  );
  yield takeLatest(type.GET_ADVNC_CATEGORY_DATA_LOAD, getAdvncCategoryListSaga);
  yield takeLatest(type.GET_ADVNC_INDUSTRY_DATA_LOAD, getAdvncIndustryListSaga);
}
