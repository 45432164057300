export const RISK_SCORE_THRESH_ENUM = {
  HIGH_RISK: 75,
  MEDIUM_RISK: 50,
  LOW_RISK: 25,
  SECURE: 0,
};

/*
- 0-39: Low
- 40-79: Moderate
- 80-94: High
- 95-100: Critical
*/
export const SEVERITY_THRESH_ENUM = {
  LOW: 40,
  MODERATE: 80,
  HIGH: 95,
  CRITICAL: 100,
};
