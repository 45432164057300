import React, { useEffect, useState } from "react";
import { Row, Tabs } from "antd";
import Images from "../../../../Themes/ImageConstant";
import {
  BarMapping,
  BarMappingPAI,
  BarMappingSDG,
  checkSeverityColor,
  getDiffTime,
  HLEA,
} from "../../../../Utils";
import {
  getGermanSupplyChainColors,
  getPrincipleColor,
  getPrincipleColors,
  getUCBreachesPrincipleColors,
  getUCBreachesSDGColors,
} from "../../../../Utils/globalFunction";
import "../../../../style/index.css";
import appconstant from "../../../../Constants/appConstansts";
import IncidentsListModal from "../../../IncidentsListModal";
import PrincipleBarChat from "../../../../Componets/PrincipleBarChat";
import { useSelector } from "react-redux";
import SDGBarChat from "../../../../Componets/PrincipleBarChat/SDG";
import UngcpBreaches from "../../../dashboardPage/utils-components/ungcpBreaches";
import PAIBarChat from "../../../../Componets/PrincipleBarChat/PAI";
import {
  getBreachesViolationDataLoad,
  getIncidentAnalyzerBreaches,
  incidentAnalyzerSocialMediaCoverageAction,
  incidentByCategoryAction,
} from "../../../../Redux/Action/incidentAnalyzerAction";
import { dispatch, navigate } from "../../../../Utils/dispatchNavigate";
import { unGlobalgraphLoad } from "../../../../Redux/Action/portfolio";

const UnPrinciple = ({ mergedData, portfolioDetail }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [breachesViolationLimit, setBreachesViolationLimit] = useState(10);
  const [breachesViolationPaiLimit, setBreachesViolationPaiLimit] =
    useState(10);
  const [breachesViolationSdgLimit, setBreachesViolationSdgLimit] =
    useState(10);
  const [isHovered, setIsHovered] = useState(false);
  const {
    incidentAnalyzer_UN_Breaches,
    breachesAndViolationData,
    breachesAndViolationDataLoader,
  } = useSelector((state) => state.incidentAnalyzerReducer);
  let networkData = mergedData;

  const { unGlobalGraphData } = useSelector((state) => state?.portfolio);

  const PrincipleArr = [
    {
      name: "Human Rights",
      value: 10,
    },
    {
      name: "Labour",
      value: 20,
    },
    {
      name: "Environment",
      value: 30,
    },
    {
      name: "Anti-Corruption",
      value: 40,
    },
  ];

  const GermanSupplyArr = [
    {
      name: "Human Rights",
      value: 10,
    },
    {
      name: "Labor Rights",
      value: 20,
    },
    {
      name: "Employee Health and Safty",
      value: 30,
    },
    {
      name: "Environment",
      value: 40,
    },
  ];

  const supplyChainPrincipleArr = [
    {
      name: "Part 1",
      value: 10,
      color: "#0195CE",
    },
    {
      name: "Part 2",
      value: 10,
      color: "#6EBD20",
    },
  ];

  const totalCount = (arr) => {
    let totalCount = 0;
    totalCount = arr?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.incidents.length;
    }, 0);

    return totalCount;
  };

  useEffect(() => {
    networkCall();
  }, [portfolioDetail]);

  useEffect(() => {
    if (activeTab != 2) {
      dispatch(
        getBreachesViolationDataLoad({
          ...mergedData,
          companies:
            portfolioDetail?.holdingsArray?.length > 0
              ? portfolioDetail?.holdingsArray.map((item) => item.id)
              : [],
          limit:
            activeTab == 1
              ? breachesViolationLimit
              : activeTab == 3
                ? breachesViolationPaiLimit
                : activeTab == 4
                  ? breachesViolationSdgLimit
                  : breachesViolationLimit,
          type:
            activeTab == 1
              ? "esg_supply_chain_act"
              : activeTab == 0
                ? "german_supply_chain_act"
                : activeTab == 4
                  ? "esg_SDG"
                  : "esg_PAI",
        })
      );
    } else {
      dispatch(
        unGlobalgraphLoad({
          id: portfolioDetail._id,
          start: networkData.start,
          end: networkData.end,
        })
      );
    }
  }, [
    activeTab,
    breachesViolationLimit,
    breachesViolationPaiLimit,
    breachesViolationSdgLimit,
  ]);

  const networkCall = () => {
    const newData = { ...networkData };
    newData.offset = 0;
    dispatch(
      getBreachesViolationDataLoad({
        ...newData,
        companies:
          portfolioDetail?.holdingsArray?.length > 0
            ? portfolioDetail?.holdingsArray.map((item) => item.id)
            : [],
        limit:
          activeTab == 1
            ? breachesViolationLimit
            : activeTab == 3
              ? breachesViolationPaiLimit
              : activeTab == 4
                ? breachesViolationSdgLimit
                : breachesViolationLimit,
        type:
          activeTab == 1
            ? "esg_supply_chain_act"
            : activeTab == 0
              ? "german_supply_chain_act"
              : activeTab == 2
                ? "esg_SDG"
                : "esg_PAI",
      })
    );
  };
  return (
    <>
      <section>
        <div className="most-sec-over containerWidth" style={{ padding: 20 }}>
          <div className="violations-ps">
            <h2>Breaches and Violations</h2>
          </div>
          <Tabs
            onChange={(e) => {
              setActiveTab(e);
            }}
            activeKey={activeTab}
            className="supply-chain-act-px "
          >
            <Tabs.TabPane key="0" tab={"LkSG"}>
              <section>
                <div className="container  g-0  ">
                  <div className="UNclass">
                    <img
                      className="sq104px"
                      src={Images.breachesIcons}
                      alt="UN"
                    />
                    <div className="d-flex justify-content row">
                      <Row>
                        <span className="unSpan">
                          German Supply Chain Act (LkSG)
                          <img
                            className="hover-page"
                            style={{ marginLeft: 5 }}
                            src={Images.circleInfo}
                            alt=""
                          />
                        </span>
                      </Row>

                      <div className="d-flex justify-content">
                        <span className="unSpanCount color05476F pl-16">
                          {breachesAndViolationData?.mostFrequentCount}{" "}
                          Incidents
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content">
                    <Row>
                      <span className="unSpanCount">
                        Companies with the most number of violations
                      </span>
                    </Row>
                    <div></div>
                  </div>

          
                  <div>
                    {breachesAndViolationData?.german_supply_chain_act &&
                      breachesAndViolationData.german_supply_chain_act.length >
                      0 &&
                      breachesAndViolationData?.german_supply_chain_act.map(
                        (item, index) => {
                          return (
                            <div className="companiesDataDiv">
                              <span>
                                {index + 1 + " "}
                                <b
                                  onClick={() =>
                                    navigate("/companyPage", {
                                      state: { id: item?.id },
                                    })
                                  }
                                >
                                  {item.name + " "}
                                </b>
                               
                              </span>

                              <div
                                style={{
                                  width: 100,
                                  height: 19,
                                  display: "flex",
                                }}
                              >
                           {item.incidentCount + " incidents"}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>

                  {breachesAndViolationData?.esg_supply_chain_act &&
                    breachesAndViolationData.esg_supply_chain_act.length >
                    9 && (
                      <div
                        className="reactionAnalyses"
                        onClick={() => {
                          // setModalVisible(true);
                          setBreachesViolationLimit(200);
                        }}
                      >
                        <span className="reactionAnalysesSpan downl-btn">
                          View All
                        </span>
                      </div>
                    )}

                  <div className="d-flex justify-content">
                    <span className="unSpanCount">
                      Most frequent violations
                    </span>
                  </div>
                  {activeTab == 0 &&
                    breachesAndViolationData?.most_frequent &&
                    breachesAndViolationData.most_frequent.length > 0 &&
                    breachesAndViolationData?.most_frequent?.map((val, ind) => {
                      return (
                        <UngcpBreaches
                          networkData={networkData}
                          activeTab={activeTab}
                          type={val._id}
                          list={val?.incidents?.slice(0, 4)}
                          totalIncidentCount={val?.incidents?.length}
                          value={val._id}
                          dataKey={val.value}
                        />
                      );
                    })}

                  <div
                    className="reactionAnalyses"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <span className="reactionAnalysesSpan downl-btn">
                      View All Incidents
                    </span>
                  </div>
                </div>
              </section>
            </Tabs.TabPane>
            <Tabs.TabPane key="1"  tab={"EU Supply Chain Act."}>
              <section>
                <div className="container  g-0  ">
                  <div className="UNclass">
                    <img
                      className="sq104px"
                      src={Images.breachesIcons}
                      alt="UN"
                    />
                    <div className="d-flex justify-content row">
                      <Row>
                        <span className="unSpan">
                          Supply Chain Act Violations
                          <img
                            className="hover-page"
                            style={{ marginLeft: 5 }}
                            src={Images.circleInfo}
                            alt=""
                          />
                        </span>
                      </Row>

                      <div className="d-flex justify-content">
                        <span className="unSpanCount color05476F pl-16">
                          {totalCount(
                            breachesAndViolationData?.most_frequent || []
                          )}{" "}
                          Incidents
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content">
                    <Row>
                      <span className="unSpanCount">
                        Companies with the most number of violations
                      </span>
                    </Row>
                    <div></div>
                  </div>

                  <div>
                    {breachesAndViolationData?.esg_supply_chain_act &&
                      breachesAndViolationData.esg_supply_chain_act.length >
                      0 &&
                      breachesAndViolationData?.esg_supply_chain_act.map(
                        (item, index) => {
                          return (
                            <div className="companiesDataDiv">
                              <span>
                                {index + 1 + " "}
                                <b
                                  onClick={() =>
                                    navigate("/companyPage", {
                                      state: { id: item?.id },
                                    })
                                  }
                                >
                                  {item.name + " "}
                                </b>
                              
                              </span>

                              <div
                                style={{
                                  width: 100,
                                  height: 19,
                                  display: "flex",
                                }}
                              >
                                  {item.incidentCount + " incidents"}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>

                  {breachesAndViolationData?.esg_supply_chain_act &&
                    breachesAndViolationData.esg_supply_chain_act.length >
                    9 && (
                      <div
                        className="reactionAnalyses"
                        onClick={() => {
                          // setModalVisible(true);
                          setBreachesViolationLimit(200);
                        }}
                      >
                        <span className="reactionAnalysesSpan downl-btn">
                          View All
                        </span>
                      </div>
                    )}

                  <div className="d-flex justify-content">
                    <span className="unSpanCount">
                      Most frequent violations
                    </span>
                  </div>
                  {activeTab == 1 &&
                    breachesAndViolationData?.most_frequent &&
                    breachesAndViolationData.most_frequent.length > 0 &&
                    breachesAndViolationData?.most_frequent?.map((val, ind) => {
                      return (
                        <UngcpBreaches
                          networkData={networkData}
                          activeTab={activeTab}
                          type={val._id}
                          list={val?.incidents?.slice(0, 4)}
                          totalIncidentCount={val?.incidents?.length}
                          value={val.value}
                          dataKey={val.value}
                        />
                      );
                    })}

                  <div
                    className="reactionAnalyses"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <span className="reactionAnalysesSpan downl-btn">
                      View All Incidents
                    </span>
                  </div>
                </div>
              </section>
            </Tabs.TabPane>

            <Tabs.TabPane key="2" tab={"UNGC"}>
              <section>
                <div className="container  g-0  ">
                  <div className="UNclass">
                    <img className="sq104px" src={Images.un} alt="UN" />
                    <div className="d-flex justify-content row">
                      <Row>
                        <span className="unSpan">
                          UN global compact principles breaches
                          <img
                            className="hover-page"
                            style={{ marginLeft: 5 }}
                            src={Images.circleInfo}
                            alt=""
                          />
                        </span>
                      </Row>

                      <div className="d-flex justify-content">
                        <span className="unSpanCount color05476F pl-16">
                          {activeTab == "2" &&
                            totalCount(
                              unGlobalGraphData?.most_frequent_breaches || []
                            )}{" "}
                          Incidents
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content">
                    <Row>
                      <span className="unSpanCount">
                        Companies with the most number of breaches
                      </span>
                    </Row>
                    <div></div>
                  </div>

                 
                  <div>
                    {activeTab == 2 &&
                      unGlobalGraphData?.companies_with_breaches?.map(
                        (e, index) => (
                          <div className="companiesDataDiv">
                            <span>
                              {index + 1 + " "}
                              <b
                                onClick={() =>
                                  navigate("/companyPage", {
                                    state: { id: e?.id },
                                  })
                                }
                              >
                                {e.name + " "}
                              </b>
                            
                            </span>

                            <div
                              style={{
                                width: 100,
                                height: 19,
                                display: "flex",
                              }}
                            >
                               {e?.incident_count + " incidents"}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                  <div className="d-flex justify-content">
                    <span className="unSpanCount">Most frequent breaches</span>
                  </div>
                  {unGlobalGraphData?.most_frequent_breaches?.map(
                    (val, ind) => {
                      return (
                        <UngcpBreaches
                          networkData={networkData}
                          activeTab={activeTab}
                          type={val._id}
                          list={val?.incidents?.slice(0, 4)}
                          totalIncidentCount={val?.incidents?.length}
                          dataKey={val._id}

                        />
                      );
                    }
                  )}
                  <div className="d-flex justify-content">
                    <span className="unSpanCount">
                      Total incidents on each principle
                    </span>
                  </div>
                  <>
                    <PrincipleBarChat
                      series={BarMapping(
                        unGlobalGraphData?.most_frequent_breaches
                      )}
                      networkData={networkData}
                    />

                    <div className="ellips chart-bottom-legend-New">
                      {HLEA?.map((data) => {
                        return (
                          <p className="legend">
                            <span
                              className="legendCircle"
                              style={{ background: data.color }}
                            />
                            {data.tittle}
                          </p>
                        );
                      })}
                    </div>
                  </>

                  <div
                    className="reactionAnalyses"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <span className="reactionAnalysesSpan downl-btn">
                      View All Incidents
                    </span>
                  </div>
                </div>
              </section>
            </Tabs.TabPane>

            <Tabs.TabPane key="3" tab={"PAI"}>
              <section>
                <div className="container  g-0  ">
                  <div className="UNclass">
                    <img className="sq104px" src={Images.pia} alt="UN" />
                    <div className="d-flex justify-content row">
                      <Row>
                        <span className="unSpan">
                          Principal Adverse Impact (PAI) Violations
                          <img
                            className="hover-page"
                            style={{ marginLeft: 5 }}
                            src={Images.circleInfo}
                            alt=""
                          />
                        </span>
                      </Row>

                      <div className="d-flex justify-content">
                        <span className="unSpanCount color05476F pl-16">
                          {breachesAndViolationData.mostFrequentCount}{" "}
                          Incidents
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content">
                    <Row>
                      <span className="unSpanCount">
                        Companies with the most number of breaches
                      </span>
                    </Row>
                    <div></div>
                  </div>

                  <div>
                    {activeTab == 3 &&
                      breachesAndViolationData?.esg_PAI &&
                      breachesAndViolationData?.esg_PAI.length > 0 &&
                      breachesAndViolationData?.esg_PAI?.map((e, index) => (
                        <div className="companiesDataDiv">
                          <span>
                            {index + 1 + " "}
                            <b
                              onClick={() =>
                                navigate("/companyPage", {
                                  state: { id: e?.id },
                                })
                              }
                            >
                              {e.name + " "}
                            </b>
                          </span>

                          <div className="Incident_on_ESG">
                            {e.incidentCount + " incidents"}
                          </div>
                        </div>
                      ))}
                  </div>

                  {activeTab == 3 &&
                    breachesAndViolationData?.esg_PAI &&
                    breachesAndViolationData?.esg_PAI.length > 9 && (
                      <div
                        className="reactionAnalyses"
                        onClick={() => {
                          // setModalVisible(true);
                          setBreachesViolationPaiLimit(200);
                        }}
                      >
                        <span className="reactionAnalysesSpan downl-btn">
                          View All
                        </span>
                      </div>
                    )}
                  <div className="d-flex justify-content">
                    <span className="unSpanCount">Most frequent breaches</span>
                  </div>
                  {activeTab == 3 &&
                    breachesAndViolationData?.most_frequent &&
                    breachesAndViolationData?.most_frequent.length > 0 &&
                    breachesAndViolationData?.most_frequent?.map((val, ind) => {
                      return (
                        <UngcpBreaches
                          networkData={networkData}
                          activeTab={activeTab}
                          type={val._id}
                          list={val?.incidents?.slice(0, 4)}
                          totalIncidentCount={val?.incidents?.length}
                          dataKey={val.value}
                        />
                      );
                    })}
                  <div className="d-flex justify-content">
                    <span className="unSpanCount">
                      Total Number of incidents
                    </span>
                  </div>
                  <>
                    {activeTab == 3 &&
                      breachesAndViolationData?.most_frequent &&
                      breachesAndViolationData?.most_frequent.length > 0 &&
                      !breachesAndViolationDataLoader && (
                        <PAIBarChat
                          series={BarMappingPAI(
                            breachesAndViolationData?.most_frequent
                          )}
                          networkData={{...networkData,companies:portfolioDetail?.holdingsArray.map((x)=>x.id),companyName:portfolioDetail?.holdingsArray.map((x)=>x.name)}}
                        />
                      )}
                  </>
                  <div className="pia-bott-hover">
                    <p
                      className="onhover-image"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <span>
                        <img
                          className={isHovered}
                          src={
                            isHovered
                              ? Images.newImageOnHover
                              : Images.circleInfo
                          }
                          alt=""
                        />
                      </span>
                      14 Principal Adverse Impact (PAI)
                    </p>

                    <div className="hover-content-ps">
                      <div className="adverse-heading-ps">
                        <h5>
                          {" "}
                          <span>
                            <img src={Images.colorSky} />
                          </span>
                          14 Principal Adverse Impact (PAI)
                        </h5>
                      </div>
                      <div className="number-of-impact-ps">
                        <p>1. GHG emissions scope 1,2,3, total</p>
                        <p>2. Carbon footprint</p>
                        <p>3. GHG intensity of investee companies</p>
                        <p>
                          4. Exposure to companies active in the fossil fuel
                          sector
                        </p>
                        <p>
                          5. Share of non-renewable energy consumption and
                          production
                        </p>
                        <p>
                          6. Energy consumption intensity per high impact
                          climate sector
                        </p>
                        <p>
                          7. Activities negatively affecting
                          biodiversity-sensitive areas
                        </p>
                        <p>8. Emissions to water</p>
                        <p>9. Hazardous waste and radioactive waste ratio</p>
                        <p>
                          10. Violations of UN Global Compact principles and
                          OECD guidelines{" "}
                        </p>
                        <p>
                          11. Lack of processes and compliance mechanisms to
                          monitor compliance with UN GC and OECD
                        </p>
                        <p>12. Unadjusted gender pay gap</p>
                        <p>13. Board gender diversity</p>
                        <p>14. Exposure to controversial weapons</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="reactionAnalyses"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <span className="reactionAnalysesSpan downl-btn">
                      View All Incidents
                    </span>
                  </div>
                </div>
              </section>
            </Tabs.TabPane>

            <Tabs.TabPane key="4" tab={"SDG"}>
              <section>
                <div className="container  g-0  ">
                  <div className="UNclass">
                    <img className="sq104px" src={Images.sdg} alt="UN" />
                    <div className="d-flex justify-content row">
                      <Row>
                        <span className="unSpan">
                          UN global compact principles breaches
                          <img
                            className="hover-page"
                            style={{ marginLeft: 5 }}
                            src={Images.circleInfo}
                            alt=""
                          />
                        </span>
                      </Row>

                      <div className="d-flex justify-content">
                        <span className="unSpanCount color05476F pl-16">
                          {breachesAndViolationData.mostFrequentCount}{" "}
                          Incidents
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content">
                    <Row>
                      <span className="unSpanCount">
                        Companies with the most number of breaches
                      </span>
                    </Row>
                    <div></div>
                  </div>

                  <div>
                    {activeTab == 4 &&
                      breachesAndViolationData?.esg_SDG &&
                      breachesAndViolationData?.esg_SDG.length > 0 &&
                      breachesAndViolationData?.esg_SDG?.map((e, index) => (
                        <div className="companiesDataDiv">
                          <span>
                            {index + 1 + " "}
                            <b
                              onClick={() =>
                                navigate("/companyPage", {
                                  state: { id: e?.id },
                                })
                              }
                            >
                              {e.name + " "}
                            </b>
                          </span>

                          <div className="Incident_on_ESG">
                            {e.incidentCount + " incidents"}
                          </div>
                        </div>
                      ))}
                  </div>

                  {activeTab == 4 &&
                    breachesAndViolationData?.esg_SDG &&
                    breachesAndViolationData?.esg_SDG.length > 9 && (
                      <div
                        className="reactionAnalyses"
                        onClick={() => {
                          // setModalVisible(true);
                          setBreachesViolationSdgLimit(200);
                        }}
                      >
                        <span className="reactionAnalysesSpan downl-btn">
                          View All
                        </span>
                      </div>
                    )}

                  <div className="d-flex justify-content">
                    <span className="unSpanCount">Most frequent breaches</span>
                  </div>
                  {activeTab == 4 &&
                    breachesAndViolationData?.most_frequent &&
                    breachesAndViolationData?.most_frequent.length > 0 &&
                    breachesAndViolationData?.most_frequent?.map((val, ind) => {
                      return (
                        <UngcpBreaches
                          networkData={networkData}
                          activeTab={activeTab}
                          type={val._id}
                          list={val?.incidents?.slice(0, 4)}
                          totalIncidentCount={val?.incidents?.length}
                          dataKey={val.value}
                        />
                      );
                    })}
                  <div className="d-flex justify-content">
                    <span className="unSpanCount">
                      Total Number of incidents on each goal
                    </span>
                  </div>
                  <>
                    {activeTab == 4 &&
                      breachesAndViolationData?.most_frequent &&
                      breachesAndViolationData?.most_frequent.length > 0 &&
                      !breachesAndViolationDataLoader && (
                        <SDGBarChat
                          series={BarMappingSDG(
                            breachesAndViolationData?.most_frequent
                          )}
                          networkData={{...networkData,companies:portfolioDetail?.holdingsArray.map((x)=>x.id),companyName:portfolioDetail?.holdingsArray.map((x)=>x.name)}}
                        />
                      )}
                  </>
                  <div className="pia-bott-hover">
                    <p
                      className="onhover-image"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <span>
                        <img
                          className={isHovered}
                          src={
                            isHovered
                              ? Images.newImageOnHover
                              : Images.circleInfo
                          }
                          alt=""
                        />
                      </span>
                      17 Sustainable Development Goals
                    </p>

                    <div className="hover-content-ps">
                      <div className="adverse-heading-ps">
                        <h5>
                          {" "}
                          <span>
                            <img src={Images.colorSky} />
                          </span>
                          17 Sustainable Development Goals
                        </h5>
                      </div>
                      <div className="number-of-impact-ps">
                        <p>1. No poverty</p>
                        <p>2. Zero hunger</p>
                        <p>3. Good health and well-being</p>
                        <p>4. Quality education</p>
                        <p>5. Gender equality</p>
                        <p>6. Clean water and sanitation</p>
                        <p>7. Affordable and clean energy</p>
                        <p>8. Decent work and economic growth</p>
                        <p>9. Industry, innovation and infrastructure</p>
                        <p>10. Reduced inequalities </p>
                        <p>11. Sustainable cities and communities</p>
                        <p>12. Responsible consumption and production</p>
                        <p>13. Climate action</p>
                        <p>14. Life below water</p>
                        <p>15. Life on land</p>
                        <p>16. Peace, justice and strong institutions</p>
                        <p>17. Partnership for the goals</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="reactionAnalyses"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <span className="reactionAnalysesSpan downl-btn">
                      View All Incidents
                    </span>
                  </div>
                </div>
              </section>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </section>
      {modalVisible ? (
        <IncidentsListModal
          setModalVisible={(e) => {
            setModalVisible(e);
            if (!e) {
              networkData = { ...networkData, esg_categories: [] };
            }
          }}
          networkData={networkData}
        />
      ) : null}
    </>
  );
};

export default UnPrinciple;
