import { SvgIcon, useTheme } from "@mui/material";

function RadialLayoutIcon({ selected }) {
  const theme = useTheme();
  return (
    <SvgIcon>
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.0013 11C9.55359 11 10.0013 11.4477 10.0013 12C10.0013 12.5523 9.55359 13 9.0013 13C8.44902 13 8.0013 12.5523 8.0013 12C8.0013 11.4477 8.44902 11 9.0013 11ZM11.5013 12C11.5013 10.8806 10.7656 9.93302 9.7513 9.61445V8.13555C10.7656 7.81698 11.5013 6.86941 11.5013 5.75C11.5013 5.61528 11.4906 5.48305 11.4701 5.35411L13.037 4.57069C13.4915 5.04094 14.1289 5.33333 14.8346 5.33333C16.2153 5.33333 17.3346 4.21404 17.3346 2.83333C17.3346 1.45262 16.2153 0.333333 14.8346 0.333333C13.4539 0.333333 12.3346 1.45262 12.3346 2.83333C12.3346 2.96805 12.3453 3.10028 12.3658 3.22922L10.799 4.01264C10.3444 3.54239 9.707 3.25 9.0013 3.25C8.26232 3.25 7.59823 3.57063 7.14055 4.08036L5.60726 3.38341C5.647 3.20641 5.66797 3.02232 5.66797 2.83333C5.66797 1.45262 4.54868 0.333333 3.16797 0.333333C1.78726 0.333333 0.667969 1.45262 0.667969 2.83333C0.667969 4.21404 1.78726 5.33333 3.16797 5.33333C3.81428 5.33333 4.40331 5.08808 4.84707 4.68555L6.51962 5.44581C6.50753 5.54551 6.5013 5.64703 6.5013 5.75C6.5013 6.86941 7.23703 7.81698 8.2513 8.13555V9.61445C7.23703 9.93302 6.5013 10.8806 6.5013 12C6.5013 13.3807 7.62059 14.5 9.0013 14.5C10.382 14.5 11.5013 13.3807 11.5013 12ZM15.8346 2.83333C15.8346 2.28105 15.3869 1.83333 14.8346 1.83333C14.2823 1.83333 13.8346 2.28105 13.8346 2.83333C13.8346 3.38562 14.2823 3.83333 14.8346 3.83333C15.3869 3.83333 15.8346 3.38562 15.8346 2.83333ZM3.16797 1.83333C3.72025 1.83333 4.16797 2.28105 4.16797 2.83333C4.16797 3.38562 3.72025 3.83333 3.16797 3.83333C2.61568 3.83333 2.16797 3.38562 2.16797 2.83333C2.16797 2.28105 2.61568 1.83333 3.16797 1.83333Z"
          fill={selected ? theme.palette.primary.main : "#B6BCC7"}
        />
      </svg>
    </SvgIcon>
  );
}

export default RadialLayoutIcon;
