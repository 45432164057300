import {
  setFilterParameters,
  updateFilterParameterField,
} from "../redux/GraphExploration/FilterSideBarSlice";
import {
  setEdgeIdsToSelect,
  setHiddenEdges,
  setHiddenNodes,
} from "../redux/GraphExploration/graphSlice";
import store from "../redux/configuareStore";
import {
  FilterParametersEnum,
  RISK_SCORE_ENUM,
  TabEnum,
  TierEnum,
} from "../enums/FilterSideBarEnums";
import { Extensions } from "@antv/g6";
import { LayoutEnum } from "../enums/LayoutsEnum";
import { BadgeEnum } from "../enums/BadgeEnum";
import { RISK_SCORE_THRESH_ENUM } from "../enums/ThresholdEnum";
import { UndefinedEnum } from "../enums/index";
import { timeout } from "d3";
import { getToken } from "../../../../Utils";
import axios from "axios";
import { toast } from "react-toastify";

export function toTitleCase(str) {
  if (!str) return str;
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );
}

export function getRiskScoreTitle(riskScore) {
  if (riskScore > RISK_SCORE_THRESH_ENUM.HIGH_RISK) {
    return RISK_SCORE_ENUM.HIGH_RISK;
  }
  if (riskScore > RISK_SCORE_THRESH_ENUM.MEDIUM_RISK) {
    return RISK_SCORE_ENUM.MEDIUM_RISK;
  }
  if (riskScore > RISK_SCORE_THRESH_ENUM.LOW_RISK) {
    return RISK_SCORE_ENUM.LOW_RISK;
  }
  return RISK_SCORE_ENUM.SECURE;
}

export function getBackgroundColorFromRiskScore(value) {
  if (value > RISK_SCORE_THRESH_ENUM.HIGH_RISK) {
    return "#FCEDED";
  }
  if (value > RISK_SCORE_THRESH_ENUM.MEDIUM_RISK) {
    return "#FDF1E4";
  }
  if (value > RISK_SCORE_THRESH_ENUM.LOW_RISK) {
    return "#FFF7DF";
  }
  return "#E8F5EC";
}

export function getTitleColorFromRiskScore(value) {
  if (value > RISK_SCORE_THRESH_ENUM.HIGH_RISK) {
    return "#5E0000";
  }
  if (value > RISK_SCORE_THRESH_ENUM.MEDIUM_RISK) {
    return "#794614";
  }
  if (value > RISK_SCORE_THRESH_ENUM.LOW_RISK) {
    return "#735600";
  }
  return "#0A3E19";
}

export function getTextColorFromRiskScore(value) {
  if (value > RISK_SCORE_THRESH_ENUM.HIGH_RISK) {
    return "#FF0000";
  }
  if (value > RISK_SCORE_THRESH_ENUM.MEDIUM_RISK) {
    return "#FF7518";
  }
  if (value > RISK_SCORE_THRESH_ENUM.LOW_RISK) {
    return "#FFBF00";
  }
  return "#00A36C";
}

export function checkImage(url, callback) {
  const img = new Image();

  // Event handler for successful image load
  img.onload = function () {
    return callback(true);
  };

  // Event handler for error in loading image
  img.onerror = function () {
    callback(false);
  };

  // Set the source of the image
  img.src = url;
}

export function underlineAwareToTitleCase(inputString) {
  // Split the input string into an array of words
  const words = inputString.toLowerCase().split("_");
  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  // Join the words back into a string
  const resultString = words.join(" ");
  return resultString;
}
export function uniqueArrayByProperty(arr, prop) {
  return arr?.filter(
    (obj, index, self) => index === self.findIndex((o) => o[prop] === obj[prop])
  );
}
export function getSelectedNodes(nodeIds, graph) {
  const selectedNodes = [];
  nodeIds?.forEach((i) => {
    if (graph?.getItemAllStates(i).includes("selected")) {
      selectedNodes.push(i);
    }
  });
  return selectedNodes;
}
export const PARTNER_EDGE_MAPPING = {
  REVERSED_COMPANY__STRATEGIC_ALLIANCE: "Strategic Alliance ⟶ Company ",
  COMPANY__STRATEGIC_ALLIANCE: "Company ⟶ Strategic Alliance",
  REVERSED_INVESTOR_RELATIONS_FIRM__CLIENT_IR:
    "Investor Relations Firm ⟶ Client IR",
  INVESTOR_RELATIONS_FIRM__CLIENT_IR: "Client IR ⟶ Investor Relations Firm",
  CURRENT_FUND_SPONSOR: "Fund Sponsor",
  CURRENT_INVESTMENT: "Investment",
  CURRENT_FUND_INVESTOR: "Fund Investor",
  CURRENT_LENDER: "Lender",
  CURRENT_INVESTMENT_ARM: "Investment Arm",
  MERGED_ENTITY: "Merged Entity",
  CURRENT_AFFILIATE: "Affiliate",
  CURRENT_SUBSIDIARYOPERATING_UNIT: "Subsidiary Operating Unit",
};
export function getEdgeProperLabel(edgeLabel) {
  if (PARTNER_EDGE_MAPPING[edgeLabel]) {
    return PARTNER_EDGE_MAPPING[edgeLabel];
  }
  let processedEdgeLabel = edgeLabel;
  const index = edgeLabel?.indexOf("__");
  if (index !== -1 && index !== undefined) {
    let left = edgeLabel.substring(0, index);
    const right = edgeLabel.substring(index + 2);
    if (left.startsWith("REVERSED_")) {
      left = left.substring(9);
      processedEdgeLabel = `${underlineAwareToTitleCase(
        right
      )} ⟶ ${underlineAwareToTitleCase(left)}`;
    } else {
      processedEdgeLabel = `${underlineAwareToTitleCase(
        left
      )} ⟶ ${underlineAwareToTitleCase(right)}`;
    }
  }
  return processedEdgeLabel;
}

export const getEdgeShortLabel = (edgeLabel) => {
  if (Object.keys(PARTNER_EDGE_MAPPING).includes(edgeLabel)) {
    return FilterParametersEnum.PARTNER;
  }
  return edgeLabel.startsWith("REVERSED_")
    ? FilterParametersEnum.SUPPLIER
    : FilterParametersEnum.CUSTOMER;
};
// export function getProperEdgeShortLabelEnum(edgeLabel: string) {
//   switch (edgeLabel) {
//     case 'SUPPLIER':
//       return ShortEdgeLabelEnum.SUPPLIER;
//     case 'CUSTOMER':
//       return ShortEdgeLabelEnum.CUSTOMER;
//     case 'PARTNER':
//       return ShortEdgeLabelEnum.PARTNER;
//     default:
//       return edgeLabel;
//   }
// }
export function augmentNodesWithParentId(nodes, edges, targetNodeId) {
  return nodes.map((n) => {
    const foundEdge = edges.find(
      (edge) =>
        (edge.source === targetNodeId && edge.target === n.id) ||
        (edge.source === n.id && edge.target === targetNodeId)
    );
    const parentId = foundEdge?.data?.shortLabel;
    const data = { ...n.data, parentId };
    const output = { ...n, data };
    return output;
  });
}
export function createCombosFromNodesWithParentId(
  nodesWithParentId,
  graphObject
) {
  const nodesByParentId = nodesWithParentId.reduce((acc, node) => {
    if (!acc[node.data.parentId]) {
      acc[node.data.parentId] = [];
    }
    acc[node.data.parentId].push(node.id);
    return acc;
  }, {});
  Object.keys(nodesByParentId).forEach((parentId) => {
    graphObject?.addCombo(
      {
        id: parentId,
        data: {
          label: toTitleCase(parentId),
        },
      },
      nodesByParentId[parentId]
    );
  });
}
export function getTier(targetId, nodes) {
  const tier = nodes.find((n) => n.id === targetId)?.data?.tier;
  return tier !== undefined ? tier : 0; // Use 0 as a default value, replace it with your desired default.
}
export function fitViewGraph(graphObject) {
  if (graphObject) {
    // add timeout 1 second for fitView
    setTimeout(() => {
      graphObject?.fitView(
        { padding: 10 },
        { duration: 1000, easing: "ease-in-out" }
      );
    }, 1000);
    graphObject?.fitCenter("layout", {
      duration: 1000,
      easing: "ease-in-out",
    });
  }
}

export function updateCombos(
  currentNodes,
  currentEdges,
  currentCombos,
  graphObject
) {
  if (currentCombos.length === 0) return;
  const combosToHide = currentCombos
    .filter(
      (combo) => !currentNodes.some((node) => node.data.parentId === combo.id)
    )
    .map((combo) => combo.id);
  graphObject?.changeData(
    {
      nodes: currentNodes,
      combos: currentCombos.map((combo) => ({
        ...combo,
        data: {
          ...combo.data,
          visible: !combosToHide.includes(combo.id),
        },
      })),
      edges: currentEdges,
    },
    "mergeReplace"
  );
}

export function updateFilteringParameterCounts() {
  const appState = store.getState();
  appState.filterSideBar.filterParameters
    .filter((filterParameter) => filterParameter.tab === TabEnum.NODE)
    .forEach((filterParameter) => {
      const { nodes } = appState.graph;
      // count nodes that has this filter id in their data.filterTagIds array
      const count = nodes.filter((node) => {
        if (node.data.filterTagIds) {
          return node.data.filterTagIds.includes(filterParameter.id);
        }
        return false;
      }).length;
      // update state with count
      store.dispatch(
        updateFilterParameterField({
          itemId: filterParameter.id,
          fieldToUpdate: "count",
          newValue: count,
        })
      );
      // update rangeLimit
      const maxValue = nodes.reduce((maxVal, node) => {
        if (
          node.data.incidentCount &&
          node.data.incidentCount[filterParameter.id]
        ) {
          return Math.max(maxVal, node.data.incidentCount[filterParameter.id]);
        }
        return maxVal;
      }, 0);
      // const minValue = nodes.reduce(
      //   (minVal, node) =>
      //     Math.min(minVal, node.data.incidentCount?.[filterParameter.id] || 0),
      //   1000
      // );
      // const safeMinValue = minValue === maxValue ? 0 : minValue;
      store.dispatch(
        updateFilterParameterField({
          itemId: filterParameter.id,
          fieldToUpdate: "rangeLimit",
          newValue: [0, maxValue],
        })
      );
    });
  appState.filterSideBar.filterParameters
    .filter((filterParameter) => filterParameter.tab === TabEnum.EDGE)
    .forEach((filterParameter) => {
      const { edges } = appState.graph;
      // count nodes that has this filter id in their data.filterTagIds array
      const count = edges.filter(
        (edge) =>
          edge.data.label === filterParameter.id ||
          edge.data.shortLabel === filterParameter.id
      ).length;
      store.dispatch(
        updateFilterParameterField({
          itemId: filterParameter.id,
          fieldToUpdate: "count",
          newValue: count,
        })
      );
    });
}
export function addIndustryFilterParameters(industry) {
  const appState = store.getState();
  const { filterParameters } = appState.filterSideBar;
  const currentIndustries = filterParameters.filter(
    (filterParameter) =>
      filterParameter.parentId === FilterParametersEnum.INDUSTRY
  );
  const labelId = industry.name;
  // check if industry id is already in currentIndustries
  const existingIndustry = currentIndustries.find(
    (filterParameter) =>
      filterParameter.parentId === FilterParametersEnum.INDUSTRY &&
      filterParameter.id === labelId
  );
  if (existingIndustry) {
    return;
  }
  const newFilterParameters = [
    ...filterParameters,
    {
      id: industry.name,
      label: industry.name,
      parentId: FilterParametersEnum.INDUSTRY,
      tab: TabEnum.NODE,
      count: 0,
      level: 1,
      value: false,
    },
  ];
  store.dispatch(setFilterParameters(newFilterParameters));
}
function getContinentFullName(continentCode) {
  if (!continentCode) return "";
  switch (continentCode.toUpperCase()) {
    case "AF":
      return "Africa";
    case "NA":
      return "North America";
    case "OC":
      return "Oceania";
    case "AN":
      return "Antarctica";
    case "AS":
      return "Asia";
    case "EU":
      return "Europe";
    case "SA":
      return "South America";
    default:
      return continentCode;
  }
}

const getSafeContinentCode = (continentCode) => {
  return continentCode ? `${continentCode}` : UndefinedEnum.UNDEFINED_CONTINENT;
};

const getSafeCountryCode = (countryCode) => {
  return countryCode ? `${countryCode}` : UndefinedEnum.UNDEFINED_COUNTRY;
};

export function addCompanyLocationFilterParameters(companyLocationObject) {
  const continentCode = getSafeContinentCode(
    companyLocationObject.continent_code
  );
  const countryGeonameId = getSafeCountryCode(
    companyLocationObject.country_geoname_id
  );
  const appState = store.getState();
  const { filterParameters } = appState.filterSideBar;
  const newFilterParameters = [...filterParameters];
  // check to see if we have already inserted the continent
  const existingCompanyContinent = filterParameters.find(
    (filterParameter) =>
      filterParameter.parentId === FilterParametersEnum.COMPANY_LOCATION &&
      filterParameter.id === continentCode
  );
  if (!existingCompanyContinent) {
    newFilterParameters.push({
      id: continentCode,
      label: getContinentFullName(continentCode),
      parentId: FilterParametersEnum.COMPANY_LOCATION,
      tab: TabEnum.NODE,
      count: 0,
      level: 1,
      value: false,
    });
  }
  // check to see if we have already inserted the country
  const existingCompanyCountry = filterParameters.find(
    (filterParameter) =>
      filterParameter.parentId === continentCode &&
      filterParameter.id === countryGeonameId
  );
  if (!existingCompanyCountry) {
    newFilterParameters.push({
      id: countryGeonameId,
      label: companyLocationObject.country_name,
      parentId: continentCode,
      tab: TabEnum.NODE,
      count: 0,
      level: 2,
      value: false,
    });
  }
  if (newFilterParameters.length > filterParameters.length) {
    store.dispatch(setFilterParameters(newFilterParameters));
  }
}
export function addIncidentCategoryFilterParameters(incidentCategoryObject) {
  const appState = store.getState();
  const { filterParameters } = appState.filterSideBar;
  const newFilterParameters = [...filterParameters];
  Object.keys(incidentCategoryObject).forEach((taxonomyLabel) => {
    if (taxonomyLabel === FilterParametersEnum.TOTAL_INCIDENT_COUNT) {
      return;
    }
    const existingTaxonomyFilteringParameters = filterParameters.find(
      (filterParameter) =>
        filterParameter.parentId === FilterParametersEnum.INCIDENT_CATEGORY &&
        filterParameter.id === taxonomyLabel
    );
    if (!existingTaxonomyFilteringParameters) {
      newFilterParameters.push({
        id: taxonomyLabel,
        label: taxonomyLabel,
        parentId: FilterParametersEnum.INCIDENT_CATEGORY,
        tab: TabEnum.NODE,
        count: 0,
        level: 1,
        value: false,
      });
    }
    Object.keys(incidentCategoryObject[taxonomyLabel]).forEach(
      (incidentCategoryLabel) => {
        const existingIncidentCategoryFilteringParameters =
          filterParameters.find(
            (filterParameter) =>
              filterParameter.parentId === taxonomyLabel &&
              filterParameter.id === incidentCategoryLabel
          );
        if (!existingIncidentCategoryFilteringParameters) {
          newFilterParameters.push({
            id: incidentCategoryLabel,
            label: incidentCategoryLabel,
            parentId: taxonomyLabel,
            tab: TabEnum.NODE,
            count: 0,
            level: 2,
            value: false,
          });
        }
      }
    );
  });
  if (newFilterParameters.length > filterParameters.length) {
    store.dispatch(setFilterParameters(newFilterParameters));
  }
}
export function prepareIncidentCounts(incidentCounts) {
  if (!incidentCounts) {
    return {};
  }
  const { total, ...incidentCount } = incidentCounts;
  // const incidentCategoryCount = Object.keys(incidentCount).reduce(
  //   (acc: any, taxonomy: any) => {
  //     Object.keys(incidentCount[taxonomy]).forEach((label: any) => {
  //       acc[`${taxonomy}_${label}`] = incidentCount[taxonomy][label];
  //     });
  //     return acc;
  //   },
  //   {},
  // );
  return {
    [FilterParametersEnum.TOTAL_INCIDENT_COUNT]: total,
    // ...incidentCategoryCount,
    ...incidentCount,
  };
}

export function getBadge({ incidentCount, riskScore }) {
  const appState = store.getState();
  const badge = appState.graph.badge;
  if (badge === BadgeEnum.INCIDENT_COUNT) {
    return incidentCount[FilterParametersEnum.TOTAL_INCIDENT_COUNT];
  }
  if (badge === BadgeEnum.RISK_SCORE) {
    return getRiskScoreTitle(riskScore);
  }
}

export function getFilterTagIds({
  label,
  incidentCount,
  tier,
  riskScore,
  industry,
  location,
}) {
  const filterTagIds = [];
  if (incidentCount !== undefined) {
    const taxonomies = Object.keys(incidentCount).filter(
      (item) => item !== FilterParametersEnum.TOTAL_INCIDENT_COUNT
    );
    filterTagIds.push(...taxonomies);
    taxonomies.forEach((taxonomy) => {
      Object.keys(incidentCount[taxonomy]).forEach((incidentCategoryLabel) => {
        filterTagIds.push(incidentCategoryLabel);
      });
    });
  }
  if (tier !== undefined) {
    switch (tier) {
      case 1:
        filterTagIds.push(TierEnum.TIER1);
        break;
      case 2:
        filterTagIds.push(TierEnum.TIER1_TIER2);
        break;
      case 3:
        filterTagIds.push(TierEnum.TIER1_TIER2_TIER3);
        break;
      default:
        break;
    }
  }
  if (riskScore !== undefined) {
    filterTagIds.push(getRiskScoreTitle(riskScore));
  }
  if (industry !== undefined) {
    filterTagIds.push(industry.name);
  }
  if (location !== undefined) {
    filterTagIds.push(getSafeContinentCode(location.continent_code));
    filterTagIds.push(getSafeCountryCode(location.country_geoname_id));
  }
  if (label !== undefined) {
    filterTagIds.push(label);
  }
  return filterTagIds;
}
function addRiskScoreFilterParameters(riskScore) {
  const appState = store.getState();
  const { filterParameters } = appState.filterSideBar;
  const existingRiskScoreFilteringParameters = filterParameters.find(
    (filterParameter) =>
      filterParameter.parentId === FilterParametersEnum.RISK_SCORE &&
      filterParameter.id === getRiskScoreTitle(riskScore)
  );
  if (existingRiskScoreFilteringParameters) {
    return;
  }
  if (riskScore !== undefined) {
    store.dispatch(
      setFilterParameters([
        ...filterParameters,
        {
          id: getRiskScoreTitle(riskScore),
          label: getRiskScoreTitle(riskScore),
          parentId: FilterParametersEnum.RISK_SCORE,
          tab: TabEnum.NODE,
          count: 0,
          level: 1,
          value: false,
        },
      ])
    );
  }
}
function addTierFilterParameters(tier) {
  const appState = store.getState();
  const { filterParameters } = appState.filterSideBar;
  if (tier) {
    let labelId = "";
    if (tier === 1) {
      labelId = TierEnum.TIER1;
    } else if (tier === 2) {
      labelId = TierEnum.TIER1_TIER2;
    } else {
      labelId = TierEnum.TIER1_TIER2_TIER3;
    }
    if (!labelId) {
      return;
    }
    const existingTierFilteringParameters = filterParameters.find(
      (filterParameter) =>
        filterParameter.parentId === FilterParametersEnum.TIER_TYPE &&
        filterParameter.id === labelId
    );
    if (existingTierFilteringParameters) {
      return;
    }
    store.dispatch(
      setFilterParameters([
        ...filterParameters,
        {
          id: labelId,
          label: labelId,
          parentId: FilterParametersEnum.TIER_TYPE,
          tab: TabEnum.NODE,
          count: 0,
          level: 1,
          value: false,
        },
      ])
    );
  }
}
function addEdgeLabelFilterParameters(edgeData) {
  const appState = store.getState();
  const { filterParameters } = appState.filterSideBar;
  const existingEdgeLabelFilteringParameters = filterParameters.find(
    (filterParameter) =>
      filterParameter.tab === TabEnum.EDGE &&
      filterParameter.id === edgeData.label
  );
  if (existingEdgeLabelFilteringParameters) {
    return;
  }
  if (edgeData) {
    store.dispatch(
      setFilterParameters([
        ...filterParameters,
        {
          id: edgeData.label,
          label: edgeData.label,
          parentId: edgeData.shortLabel,
          tab: TabEnum.EDGE,
          count: 0,
          level: 1,
          value: false,
        },
      ])
    );
  }
}
export function updateFilteringParameter() {
  const appState = store.getState();
  const { nodes, edges } = appState.graph;
  nodes.forEach((item) => {
    // addTierFilterParameters(item.data.tier);
    addRiskScoreFilterParameters(item.data.riskScore);
    addIndustryFilterParameters(item.data.industry);
    addCompanyLocationFilterParameters(item.data.location);
    addIncidentCategoryFilterParameters(item.data.incidentCount);
  });
  edges.forEach((item) => {
    addEdgeLabelFilterParameters(item.data);
  });
}
const getConnectingEdgesToHide = (nodeIds) => {
  const appState = store.getState();
  const { edges } = appState.graph;
  const connectingEdges = edges.filter(
    (edge) => nodeIds.includes(edge.source) || nodeIds.includes(edge.target)
  );
  return connectingEdges.map((edge) => edge.id);
};
export const getConnectingEdgesToShow = (nodeIds) => {
  const appState = store.getState();
  const { edges } = appState.graph;
  const connectingEdges = edges.filter(
    (edge) => nodeIds.includes(edge.source) && nodeIds.includes(edge.target)
  );
  return connectingEdges.map((edge) => edge.id);
};
export const getNearEdgeData = (nodeId, edges) =>
  edges.filter((edge) => edge.source === nodeId || edge.target === nodeId);
export const getConnectingNodesToHide = (edgesToHide, _graphObject) => {
  const appState = store.getState();
  const { nodes, edges } = appState.graph;
  const connectingNodes = nodes.filter((node) => {
    const connectingEdgesId = getNearEdgeData(node.id, edges)?.map((n) => n.id);
    const edgesToHideId = edgesToHide.map((edge) => edge.id);
    return connectingEdgesId.every((id) => edgesToHideId.includes(id));
  });
  return connectingNodes.map((node) => node.id);
};
export function fixG6RenderingBug(graphObject, padding = 10, duration = 1000) {
  graphObject?.fitView(
    { padding: [10, 10, 10, padding] },
    { duration, easing: "ease-in-out" }
  );
}

function isExpandedNode(nodeId) {
  const appState = store.getState();
  const { expandedNodes } = appState.graph;
  return expandedNodes.includes(nodeId);
  // TODO: Check if the expanded node is connected to the center node in one or multiple hops.
}

function arraysHaveSameItems(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export const MIN_NODE_COUNT_TO_FIX_G6_RENDERING_BUG = 100;
export const MIN_NODE_COUNT_TO_ZOOM_WHEN_ADDING = 100;

export function updateHiddenNodes(graphObject) {
  const appState = store.getState();
  const {
    filterParameters,
    excludeMainCompanyFromFiltering,
    excludeExpandedNodesFromFiltering,
  } = appState.filterSideBar;
  const { id: centerNodeId } = appState.graph.centerNode;
  const { nodes, hiddenEdges, hiddenNodes } = appState.graph;
  const nodesToHide = nodes.filter((node) => {
    if (excludeMainCompanyFromFiltering) {
      if (node.id === centerNodeId) {
        return false;
      }
    }
    if (excludeExpandedNodesFromFiltering) {
      if (isExpandedNode(node.id)) {
        return false;
      }
    }
    // check all filterParameters on level 0, if current node id is in any of their hiddenNodeIds field, return true
    const foundFilterParameter = filterParameters.find(
      (filterParameter) =>
        filterParameter.level === 0 &&
        filterParameter.hiddenNodeIds?.includes(node.id)
    );
    return !!foundFilterParameter;
  });

  const nodesToHideIds = nodesToHide.map((node) => node.id);
  store.dispatch(setHiddenNodes(nodesToHideIds));
  const edgesToHideIds = getConnectingEdgesToShow(nodesToHideIds);
  store.dispatch(setHiddenEdges(edgesToHideIds));
  // Check with the previous hidden nodes to see if we need to update the hidden nodes
  const previousHiddenNodes = appState.graph.hiddenNodes;
  // subtract them
  const addNodesToHidden = nodesToHideIds.filter(
    (nodeId) => !previousHiddenNodes.includes(nodeId)
  );
  graphObject?.hideItem(addNodesToHidden);
  const addEdgesToHideIds = getConnectingEdgesToHide(addNodesToHidden);
  graphObject?.hideItem(addEdgesToHideIds);
  const nodesToShowIds = nodes
    .filter((node) => !nodesToHideIds.includes(node.id))
    .map((node) => node.id);
  graphObject?.showItem(nodesToShowIds);
  const edgesToShowIds = getConnectingEdgesToShow(nodesToShowIds);
  graphObject?.showItem(edgesToShowIds);
  if (
    !arraysHaveSameItems(hiddenNodes, nodesToHideIds) ||
    !arraysHaveSameItems(hiddenEdges, edgesToHideIds) ||
    nodes.length > MIN_NODE_COUNT_TO_FIX_G6_RENDERING_BUG
  ) {
    fixG6RenderingBug(graphObject, 300, 1);
  }
}
export function resetFilterParamatersValue() {
  const appState = store.getState();
  const { filterParameters } = appState.filterSideBar;
  filterParameters.forEach((filterParameter) => {
    if (filterParameter.id === FilterParametersEnum.TIER_TYPE) {
      store.dispatch(
        updateFilterParameterField({
          itemId: filterParameter.id,
          fieldToUpdate: "value",
          newValue: TierEnum.TIER1_TIER2_TIER3,
        })
      );
    } else if (
      filterParameter.id === FilterParametersEnum.TOTAL_INCIDENT_COUNT
    ) {
      store.dispatch(
        updateFilterParameterField({
          itemId: filterParameter.id,
          fieldToUpdate: "selectedRange",
          newValue: filterParameter.rangeLimit,
        })
      );
    } else {
      store.dispatch(
        updateFilterParameterField({
          itemId: filterParameter.id,
          fieldToUpdate: "value",
          newValue: false,
        })
      );
    }
    store.dispatch(
      updateFilterParameterField({
        itemId: filterParameter.id,
        fieldToUpdate: "hiddenNodeIds",
        newValue: [],
      })
    );
  });
}

export function decodeUnicodeEscapes(str) {
  return str.replace(/\\u([\d\w]{4})/gi, (_, g) =>
    String.fromCharCode(parseInt(g, 16))
  );
}

export function removeCombos(nodes, edges, graphObject) {
  // remove data.parentId from all items of nodes
  const nodesWithoutParentId = nodes?.map((n) => {
    const { parentId, ...rest } = n.data;
    return {
      ...n,
      data: rest,
    };
  });

  graphObject?.changeData(
    {
      nodes: nodesWithoutParentId,
      combos: [
        { id: "supplier", data: { label: "Supplier", visible: false } },
        { id: "partner", data: { label: "Partner", visible: false } },
        { id: "customer", data: { label: "Customer", visible: false } },
      ],
      edges,
    },
    "mergeReplace"
  );
}

export function createCombos(
  nodes,
  edges,
  graphObject,
  centerNodeId,
  currentLayout
) {
  const neighboursNodes = graphObject?.getNeighborNodesData(centerNodeId);
  const augmentedNeighbouringNodesWithParentId = augmentNodesWithParentId(
    neighboursNodes,
    edges,
    centerNodeId
  );
  // Merge arrays based on 'id' key
  const augmentedNodesWithParentId = nodes.map((item) => {
    const subsetItem = augmentedNeighbouringNodesWithParentId.find(
      (sItem) => sItem.id === item.id
    );
    return subsetItem || item;
  });
  const uniqComboIds = augmentedNodesWithParentId.reduce((acc, item) => {
    if (item?.data?.parentId && !acc.includes(item?.data?.parentId)) {
      acc.push(item?.data?.parentId);
    }
    return acc;
  }, []);
  const newCombos = uniqComboIds.map((parentId) => ({
    id: parentId,
    data: {
      label: toTitleCase(parentId),
      visible: true,
    },
  }));

  graphObject?.changeData(
    { nodes: augmentedNodesWithParentId, combos: newCombos, edges },
    "replace"
  );

  if (currentLayout !== LayoutEnum.FORCE_LAYOUT) {
    graphObject.layout({
      type: "comboCombined",
      comboPadding: 0,
      workerEnabled: true,
      innerLayout: new Extensions.GridLayout({
        preventOverlap: true,
        nodeSize: 70,
      }),
    });
  }
}

export async function handleShortestPath(sourceNode, targetNode, graphObject) {
  try {
    const appState = store.getState();
    const dispatch = store.dispatch;
    const pathFindingMode = appState.rightClickMenu.pathFindingMode;
    const centerNodeId = appState.graph.centerNode?.id;
    const baseUrlApi = appState.api.baseUrlApi;
    const from = appState.timeRange.from;
    const to = appState.timeRange.to;

    const params = {
      targetId: targetNode,
      all: pathFindingMode === "all",
      from,
      to,
    };
    if (centerNodeId) {
      params.centerNodeId = centerNodeId;
    }
    const token = getToken();
    const data = await axios.get(
      `${baseUrlApi}/companies/${sourceNode}/paths`,
      {
        params,
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          // Add any other headers if needed
        },
      }
    );
    const currentNodeIds = appState.graph.nodes.map((n) => n.id);
    const currentEdgeIds = appState.graph.edges.map((n) => n.id);
    const response = data.data;
    const newNodes = response.companies
      ?.filter((item) => currentNodeIds?.indexOf(item.id) === -1)
      .map((n) => {
        const nodeAttributes = {
          incidentCount: prepareIncidentCounts(n.incidentCount),
          tier: n.tier ? Math.abs(n.tier) : undefined,
          sort: n.tier ? 4 - Math.abs(n.tier) : 0,
          layer: n.tier ? Math.abs(n.tier) : 4,
          riskScore: n.riskScore,
          industry: n.industry,
          location: n.location,
          label: decodeUnicodeEscapes(n.label),
        };
        return {
          id: n.id,
          data: {
            isCenterNode: false,
            ...nodeAttributes,
            logo: n.logo,
            filterTagIds: getFilterTagIds(nodeAttributes),
            badge: getBadge(nodeAttributes),
          },
        };
      });
    const newEdges = response.relations
      ?.filter((item) => {
        const idParts = item.id.split("-");
        const reverseId = `${idParts[1]}-${idParts[0]}`;
        return (
          currentEdgeIds?.indexOf(item.id) === -1 &&
          currentEdgeIds?.indexOf(reverseId) === -1
        );
      })
      .map((e) => ({
        ...e,
        data: {
          label: getEdgeProperLabel(e.name),
          shortLabel: e.shortName,
        },
      }));
    graphObject?.startHistoryBatch();
    if (newNodes.length > 0) {
      graphObject?.addData("node", newNodes); // nodesWithParentId);
    }
    if (newEdges.length > 0) {
      graphObject?.addData("edge", newEdges);
    } else if (response.relations.length === 0) {
      toast.warn("No path is found");
    } else {
      toast.warn("Path is already added.");
    }
    graphObject?.stopHistoryBatch();
    const edgesToSelect = [];
    response.relations.forEach((item) => {
      const idParts = item.id.split("-");
      const reverseId = `${idParts[1]}-${idParts[0]}`;
      if (currentEdgeIds?.indexOf(item.id) === -1) {
        edgesToSelect.push(item.id);
      } else if (currentEdgeIds?.indexOf(reverseId) === -1) {
        edgesToSelect.push(reverseId);
      }
    });
    dispatch(setEdgeIdsToSelect(edgesToSelect));
    graphObject?.layout();
  } catch (err) {
    console.error(err);
  }
}
