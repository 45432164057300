import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AutoComplete, Breadcrumb, Input, Select } from "antd";
import { Tabs } from "antd";
import { Chart as ChartJS, Tooltip, ArcElement } from "chart.js";
import { dispatch } from "../../Utils/dispatchNavigate";
import { getSourceDetailsAction } from "../../Redux/Action/incidents";
import { useSelector } from "react-redux";
import { getCountryDataforSupplyChain } from "../../Redux/Action/supplyChainAction";
import {  getDiffTimeNew } from "../../Utils";

const option = {
  options: {
    dataLabels: {
      style: {
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: "bold",
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        dataLabels: {
          offset: -25,
        },
      },
    },
    fill: {
      type: "gradient",
      colors: [
        "rgba(182, 188, 199, 1)",
        "rgba(26, 156, 62, 1)",
        "rgba(208, 0, 0, 1)",
      ],
    },
    seriesName: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    chart: {
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 6,
        color: "#000",
        opacity: 0.5,
      },
    },
  },
};

const SourceLists = (props) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(1);
  const [sortSelected, SetSortSelected] = useState(1);
  const [options, setOptions] = useState([]);
  const [countryName, setCountryName] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [sortBy, setSortBy] = useState("publication_datetime");

  const { countryList } = useSelector((state) => state?.supplyChainReducer);
  useEffect(() => {
    dispatch(getCountryDataforSupplyChain());
  }, []);

  ChartJS.register(ArcElement, Tooltip);
  const location = useLocation();
  const sourceData = useSelector(
    (state) => state?.incidentReducer?.sourceDetails
  );
  const { id, key, title } = location.state;

  useEffect(() => {
    let sortkey = "1";
    switch (key) {
      case "articles":
        setCurrentTab("2");
        sortkey = 2;
        break;
      case "reports":
        setCurrentTab("3");
        sortkey = 3;
        break;
      default:
        break;
    }
    getSourceData(id, key, 0, null, sortBy, 1);
  }, []);

  const getSourceData = (
    id,
    type,
    offset = 0,
    limit = null,
    sort = "createdAt"
  ) => {
    dispatch(
      getSourceDetailsAction({
        incident: id,
        type: type,
        offset: offset,
        limit: limit,
        sort: sort,
        order: sort == "publication_datetime" ? -1 : 1,
      })
    );
  };

  const ArticleComponent = (data) => {
    return (
      <div className="message-section">
        <div className="user-steve mt-3">
          <div className="user-image">
            <a target="_blank" href={data.data.url}>
              <span>{data.data.title}</span>
            </a>
          </div>
        </div>

        <div className="user-tweet mt-2">
          <p>
            <a target="_blank" href={data.data.url}>
              {data.data.body}
            </a>
          </p>
        </div>
        <div className="main-source-div">
          <div className="message-time">
            <span>
            <span>  <img src={Images.fileAltGray} /> {data.data.source.title+" "}</span>
              <img src={Images.locationIcon} />
              {data.data.source_country} {data.data.source_country && "-" + " "}
              <span> {getDiffTimeNew(data.data.publication_datetime)}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const ReportsComponent = (data) => {
    if (data.data) {
      return (
        <div className="message-section">
          <div className="user-steve mt-3">
            <div className="user-image">
              <a target="_blank" href={data.data.url}>
                <span style={{ fontFamily: "inter" }}>{data.data.title}</span>
              </a>
            </div>
          </div>

          <div className="user-tweet mt-2">
            <a target="_blank" href={data.data.url}>
              <p style={{ fontFamily: "inter" }}>{data.data.body}</p>
            </a>
          </div>
          <div className="main-source-div">
            <div className="message-time">
              <span>
                <img src={Images.locationIcon} />
                {data.data.source_country}{" "}
                {data.data.source_country && "-" + " "}
                <span> {getDiffTimeNew(data.data.publication_datetime)}</span>
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  const NoReportsFound = ({ message }) => {
    return (
      <div className="noDataDiv">
        <span className="noDataSourceList">{`No related ${message} found for this incidents!`}</span>
      </div>
    );
  };

  const getPostiveVal = (data) => {
    let score = {
      Neutral: 0,
      Positive: 0,
      Negative: 0,
    };
    for (let i in data) {
      let sentiment = data[i].sentiment;
      if (sentiment?.score > 0) {
        score.Positive = score.Positive + 1;
      } else if (sentiment?.score < 0) {
        score.Negative = score.Negative + 1;
      } else {
        score.Neutral = score.Neutral + 1;
      }
    }
    return score;
  };

  const getType = (e) => {
    if (e == 2) {
      return "articles";
    }
    if (e == 3) {
      return "reports";
    }
  };

  const SourceChart = (data) => {
    if (data?.data?.length > 0) {
      const { Positive, Negative, Neutral } = getPostiveVal(data?.data);
      const series = Object.values(getPostiveVal(data?.data));

      return (
        <div className="row agg-sent mt-3">
          <div className="col-md-6">
            <div className="aggregated-sec">
              <h6 style={{ fontFamily: "inter" }}>
                {`Aggregated ${key == "articles" ? " News Articles" : key} Sentiment`}{" "}
              </h6>
              <div className="agg-sentiment">
                <div className="cat-name mt-3 ">
                  <div className="flx-color-se">
                    <div className="colors">
                      <h5 style={{ fontFamily: "inter" }}>
                        <img src={Images.darkGreen} />
                        Positive
                      </h5>
                    </div>
                    <div className="number">
                      <p style={{ fontFamily: "inter" }}>{Positive}</p>
                    </div>
                  </div>

                  <div className="flx-color-se">
                    <div className="colors">
                      <h5 style={{ fontFamily: "inter" }}>
                        <img src={Images.brightRed} />
                        Negative
                      </h5>
                    </div>
                    <div className="number">
                      <p style={{ fontFamily: "inter" }}>{Negative}</p>
                    </div>
                  </div>

                  <div className="flx-color-se">
                    <div className="colors">
                      <h5 style={{ fontFamily: "inter" }}>
                        <img src={Images.gray} />
                        Neutral
                      </h5>
                    </div>
                    <div className="number">
                      <p style={{ fontFamily: "inter" }}> {Neutral}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="pie-wrap"></div>
          </div>
        </div>
      );
    }
  };

  const optionsArr = [
    {
      value: "publication_datetime",
      label: "Sort by date",
    },
    {
      value: "title",
      label: "Sort by name",
    },
  ];

  const handleSearch = (value) => {
    let res = [];
    if (!value) {
      res = [];
    } else {
      res = countryList.filter((x) =>
        x?.label?.toUpperCase()?.includes(value?.toUpperCase().trim())
      );
    }

    setOptions(
      res.map((i) => ({
        ...i,
        label: (
          <div>
            <span>
              <img src={Images.searchHotel} style={{ marginRight: "10px" }} />
            </span>{" "}
            {i.label}
          </div>
        ),
      }))
    );
    if (value?.length >= 3) setSelectedData(res[0]);
  };
  const handleSearchOperation = (SelectedData) => {
    if (options?.length !== 0) {
      navigate("/companyPage", {
        state: { id: SelectedData.id },
      });
    }
  };

  return (
    <div className="side-padding">
      <div className="main-layout table-layout">
        <div className="container">
          <section className="mt-3 mb-4 d-flex justify-content-between align-items-center">
            <div className="breadcrmbs mb-4">
              <Breadcrumb>
                <Breadcrumb.Item key="1" className="inc-bad-link">
                  <Link to="/home">Incident </Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item key="2" className="inc-bad-link">
                  <Link to="/incidentdetails" state={{ id: id }}>
                    Incidents Details
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item key="3" className="detail-lie-link">
                  Sources
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="autocomplete-input-search">
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                popupMatchSelectWidth={500}
                className="serchmedia-icon"
                size="large"
                onSearch={handleSearch}
                value={countryName}
                options={options}
                onSelect={(e, selectedOption) => {
                  const { label } = selectedOption;
                  setCountryName(label);
                  setSelectedData(selectedOption);
                  handleSearchOperation(selectedOption);
                }}
                onChange={(e, i) => {
                  setSelectedData(i);
                  setCountryName(e);
                }}
              >
                <Input.Search size="large" placeholder="Search company" />
              </AutoComplete>
            </div>
          </section>

          <div className="tab-sec mt-4">
            <div className="titleDiv">
              <h1></h1>
              <span>{title}</span>
            </div>
            <div className="tab-left-side mt-4">
              <Tabs
                onChange={(e) => {
                  setCurrentTab(e);
                  getSourceData(id, getType(e), 0, null, sortBy);
                }}
                activeKey={currentTab}
                className="SourceList"
                tabBarExtraContent={
                  <div className="web-view">
                    <div className="tab-right-side ">
                      <Select
                        style={{ minWidth: 160, marginBottom: 12 }}
                        className="sortComponent"
                        placeholder="Sort by date"
                        value={sortBy}
                        onChange={(e) => {
                          getSourceData(id, getType(currentTab), 0, null, e);
                          setSortBy(e);
                        }}
                        options={optionsArr}
                      ></Select>
                    </div>
                  </div>
                }
              >
                <Tabs.TabPane
                  tab={
                    <div className="md-icons" style={{ fontFamily: "inter" }}>
                      <img
                        className={currentTab == 2 ? "filter-img" : ""}
                        src={Images?.fileAltGray}
                        alt="img"
                      />
                      News Articles
                    </div>
                  }
                  key="2"
                >
                  {/* <SourceChart data={sourceData?.news_articles} /> */}
                  {sourceData?.news_articles?.length > 0 ? (
                    sourceData?.news_articles?.map((item) => (
                      <ArticleComponent data={item} />
                    ))
                  ) : (
                    <NoReportsFound message="news articles" />
                  )}
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <div className="md-icons" style={{ fontFamily: "Inter" }}>
                      <img
                        className={currentTab == 3 ? "filter-img" : ""}
                        src={Images?.ngo}
                        alt="img"
                      />
                      NGO Reports
                    </div>
                  }
                  key="3"
                >
                  <SourceChart data={sourceData?.ngo_reports} />
                  {sourceData?.ngo_reports?.length > 0 ? (
                    sourceData?.ngo_reports?.map((item) => (
                      <ReportsComponent data={item} />
                    ))
                  ) : (
                    <NoReportsFound message="ngo reports" />
                  )}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SourceLists;
