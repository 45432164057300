import React from "react";
import { Box, IconButton, SvgIcon, useTheme } from "@mui/material";
import { useGraphObject } from "../../context/MyContext";
import { fitViewGraph } from "../../../utils";
import CustomTooltip from "../../GraphToolTip/CustomTooltip";

function FitViewButton() {
  const { graphObject } = useGraphObject();
  const theme = useTheme();
  return (
    <Box>
      <CustomTooltip title="FitView" placement="left">
        <IconButton
          onClick={() => {
            fitViewGraph(graphObject);
          }}
        >
          {/* FitView Icon */}
          <SvgIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14 10L20 4M20 4H15.5M20 4V8.5M4 4L10 10M4 4V8.5M4 4H8.5M14 14L20 20M20 20V15.5M20 20H15.5M10 14L4 20M4 20H8.5M4 20L4 15.5"
                stroke={theme.palette.gray.main}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </SvgIcon>
        </IconButton>
      </CustomTooltip>
    </Box>
  );
}
export default FitViewButton;
