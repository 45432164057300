import ReactApexChart from "react-apexcharts";

const BarChart = (props) => {
  const arr = props.data
    ? props.type == "xcorp"
      ? [
          0,
          props?.data?.like_count,
          props?.data?.retweet_count,
          props?.data?.reply_count,
          props?.data?.quote_count,
        ]
      : [
          props?.data?.upvotes,
          props?.data?.downvotes,
          props?.data?.score,
          props?.data?.comments,
        ]
    : [];
  const format = {
    series: [
      {
        data: arr || [],
      },
    ],
    options: {
      xaxis: {
        categories: props.labels,
        axisTicks: {
          show: false, // Set this to false to hide x-axis ticks
        },
      },
      yaxis: {
        show: false,
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
          style: {
            color: "blue",
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },

      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        // active: {
        //   filter: {
        //     type: "none",
        //   },
        // },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          borderTopLeftRadius: 100,
          distributed: true,
          horizontal: true,
          barHeight: 20,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#0195CE"],
      legend: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "#8dddfd",
          type: "horizontal",
          shadeIntensity: 0.7,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 95, 100],
          colorStops: [],
        },
      },

      tooltip: {
        enabled: false,
      },

      grid: {
        show: false,
      },
      dataLabels: {
        offsetX: 20,
        formatter: function (val, opts) {
          return val > 999 ? val + "K" : val;
        },
        style: {
          fontSize: "11px",
          fontWeight: "500",
          colors: ["#05476F"],
        },
      },
    },
  };

  return (
    <ReactApexChart
      height={props.height || 200}
      options={format.options}
      series={format.series}
      type="bar"
    />
  );
};
export default BarChart;
