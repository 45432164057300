import { Box, Typography } from "@mui/material";

function BadgeItem({ label, isSelected, sx, onClick }) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: "8px", ...sx }}
      onClick={onClick}
    >
      <Typography variant={isSelected ? "bodySelected" : "body"}>
        {label}
      </Typography>
    </Box>
  );
}

export default BadgeItem;
