/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "../reducers";

const initialState = {
  location: undefined,
  targetNodeId: undefined,
  targetEdgeId: undefined,
  show: false,
};
const toolTipSlice = createSlice({
  name: "toolTip",
  initialState,
  reducers: {
    setTooltipLocation: (state, { payload: contextMenuLocation }) => {
      state.location = contextMenuLocation;
    },
    setTooltipTargetNodeId: (state, { payload: targetNodeId }) => {
      state.targetNodeId = targetNodeId;
    },
    setTooltipTargetEdgeId: (state, { payload: targetEdgeId }) => {
      state.targetEdgeId = targetEdgeId;
    },
    setTooltipShow: (state, { payload: show }) => {
      state.show = show;
    },

    resetTooltip: (state) => {
      state.location = initialState.location;
      state.targetNodeId = initialState.targetNodeId;
      state.targetEdgeId = initialState.targetEdgeId;
      state.show = initialState.show;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});
export const {
  setTooltipLocation,
  resetTooltip,
  setTooltipTargetNodeId,
  setTooltipTargetEdgeId,
  setTooltipShow,
} = toolTipSlice.actions;
export const toolTipReducer = toolTipSlice.reducer;
