import React from "react";
import Styles from "./importanceScoringPopup.module.css";
import ImageConst from "../../Themes/ImageConstant";

const ImportanceScoringPopup = () => {
  return (
    <div style={{padding:"1vw",borderRadius:"10px"}}>
      <div style={{textAlign:"justify",fontSize:"15px",marginBottom:".4vw"}}>
        Scoring system for measuring “importance level” is based on our Advanced
        AI based scoring system. you can also customize the scores based on your
        requirements in settings on “score customization” page.
      </div>
      <div className={Styles.settingActionBox}>
        <img className={Styles.settingIcon} src={ImageConst.settingIcon} />
        <span className={Styles.settingText}>Go to Settings</span>
      </div>
    </div>
  );
};

export default ImportanceScoringPopup;
