import React, { useState } from "react";
import Images from "../../../Themes/ImageConstant";
import InputFeild from "../../../Componets/InputFeild";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { PasswordInput } from "../../../Componets/PasswordInput";
import { useFormik } from "formik";
import {
  initalValuesSignup,
  signSchema,
  validationSchema,
} from "../../../Utils/schema";
import { isInternetConnected } from "../../../Utils/InternetConnection";
import { dispatch } from "../../../Utils/dispatchNavigate";
import { registerAction } from "../../../Redux/Action/authentication";
import { CustomInput } from "../../../Componets/CustomInput";
import { GoogleLogin } from "../../../Componets/GoogleLogin";
import { useSelector } from "react-redux";

const Signup = () => {
  const loding = useSelector((state) => state.authenticationReducer.isLoading);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    isInternetConnected() &&
      dispatch(
        registerAction({
          ...values,
       
        })
      );
  };

  const formik = useFormik({
    initialValues: initalValuesSignup,
    validationSchema: validationSchema(signSchema),
    onSubmit,
  });

  return (
    <div className="page-header">
      <div className="left-right-mg">
        <div className="main-div mt-4 mb-4">
          <div className="login-form">
            <div className="login-page-logo">
              <img src={Images.logo} />
            </div>

            <div className="log-side">
              <div className="login-text">
                <h5>Sign up</h5>
              </div>
              {/* <GoogleLogin /> */}
              <hr></hr>
              <form noValidate onSubmit={formik.handleSubmit}>
                <div className="main-input-sec">
                  {/* <div className="input-text">
                    <label for="fname">First name</label>
                    <CustomInput
                      type="text"
                      id="fname"
                      name="firstName"
                      placeholder="Enter your first name"
                      onChange={({ target }) =>
                        formik.setFieldValue(
                          "firstName",
                          target.value.trimStart()
                        )
                      }
                      value={formik.values.firstName}
                      error={formik.errors.firstName}
                      maxLength={20}
                    />
                  </div>

                  <div className="input-text">
                    <label for="fname">Last name</label>
                    <CustomInput
                      type="text"
                      id="fname"
                      name="lastName"
                      placeholder="Enter your last name"
                      onChange={({ target }) =>
                        formik.setFieldValue(
                          "lastName",
                          target.value.trimStart()
                        )
                      }
                      value={formik.values.lastName}
                      error={formik.errors.lastName}
                      maxLength={20}
                    />
                  </div> */}

                  <div className="input-text">
                    <label for="fname">Email</label>
                    <CustomInput
                      type="text"
                      id="fname"
                      name="email"
                      placeholder="Enter your email address"
                      onChange={({ target }) =>
                        formik.setFieldValue("email", target.value.trimStart())
                      }
                      value={formik.values.email}
                      error={formik.errors.email}
                      maxLength={320}
                    />
                  </div>

                  {/* <div className="input-text">
                    <label for="fname"> Mobile number </label>
                    <CustomInput
                      type="text"
                      id="fname"
                      isMobile={true}
                      name="mobileNumber"
                      placeholder="Enter your mobile number"
                      onChange={({ target }) =>
                        formik.setFieldValue(
                          "mobileNumber",
                          target.value.trim()
                        )
                      }
                      value={formik.values.mobileNumber.toString()}
                      error={formik.errors.mobileNumber}
                      maxLength={15}
                    />
                  </div> */}

                  <div className="input-text">
                    {/* <label for="fname"> Password </label> */}
                    <PasswordInput
                      type="password"
                      id="fname"
                      name="password"
                      placeholder="Enter your password"
                      onChange={({ target }) =>
                        formik.setFieldValue("password", target.value.trim())
                      }
                      value={formik.values.password}
                      error={formik.errors.password}
                      maxLength={128}
                      reset={() => {
                        formik.setFieldValue("password", "");
                      }}
                    />
                  </div>

                  <div className="term-cond">
                    <span> By signing up you agree to our </span>
                    <a
                      target="_blank"
                      href="https://generator.lorem-ipsum.info/terms-and-conditions">
                      Terms and Conditions
                    </a>
                  </div>
                </div>

                <div className="login-link">
                  <button
                    disabled={loding}
                    className="login-link"
                    type="submit">
                    Sign up
                  </button>
                </div>
                <div className="forgot-link">
                  <span>Already have an account? </span>
                  <Link to="/">LOGIN</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
