import { useEffect, useState } from "react";

import ImageConst from "../../Themes/ImageConstant";
import appconstant from "../../Constants/appConstansts";

const { placeHolder, lable } = appconstant;

export const PasswordInput = ({
  onChange,
  label = lable.pass,
  value,
  placeholder = placeHolder.entrPass,
  error,
  name = "password",
  reset,
  maxLength = 256,
  iconClsEye,
  iconClsX,
}) => {
  const [showTxt, setShowTxt] = useState(false);

  return (
    <>
      <label for="fname">{label}</label>
      <div className="relative-wrap">
        <input
          value={value}
          type={showTxt ? "text" : "password"}
          id="fname"
          name={name}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={onChange}
        />
        {value?.length ? (
          <div className="input-icon-um">
            <img
              style={{ top: showTxt && "25px" }}
              onClick={() => setShowTxt(() => !showTxt)}
              // className={`input-eye ${iconClsEye}`}
              src={!showTxt ? ImageConst.eyeOpen : ImageConst.closeEye}
            />
            <img
              onClick={reset}
              // className={`input-x ${iconClsX}`}
              src={ImageConst.cross}
            />
          </div>
        ) : null}
      </div>
      {error ? <div className="error">{error}</div> : null}
    </>
  );
};
