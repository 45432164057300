import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  AutoComplete,
  Breadcrumb,
  Button,
  Input,
  Row,
  notification,
} from "antd";
import { Tooltip } from "antd";
import { Modal } from "antd";
import { Popover } from "antd";
import { ChartCard } from "../../Layouts/ChartCard";
import BubbleChart from "../../Componets/BubbleChart";
import {
  PNN,
  generateData,
  getColor,
  getColorForTableText,
  getDiffTimeNew,
  getlatestDate,
  scrollTo,
  whiteMapOptions,
  getFilteredArrayforBubbles,
  getBubbleXaxis,
} from "../../Utils";
import ImageConst from "../../Themes/ImageConstant";
import { navigate } from "../../Utils/dispatchNavigate";
import {
  getNewsArticleAction,
  getSourceDetailsAction,
  getTimeLineGraphAction,
  incidentDetailAction,
  getSocialMediaIncidentAction,
  getFineAndpenaltyAction,
  geRegulatorAndLegalAction,
  feedbackAction,
  updateIncidentState,
  incidentMapAction,
} from "../../Redux/Action/incidents";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import WorldMap from "../../Componets/WorldMap";
import {
  addToCollectionAction,
  createSaveIncidenceAction,
  getCollectionAction,
} from "../../Redux/Action/saveIncidence";
import IncidentsList from "../incidentsList";
import {
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";
import CopyToClipboard from "react-copy-to-clipboard";
import EChartsReact from "echarts-for-react";
import RegulatoryLegalActionsCard from "../dashboardPage/utils-components/regulatoryLegalActionsCard";
import RegulatoryFinesPenaltiesCard from "../dashboardPage/utils-components/regulatoryFines&PenaltiesCard";
import SunBrust from "./Chart/SunBrust";
import { getCountryDataforSupplyChain } from "../../Redux/Action/supplyChainAction";
import Timeline from "../dashboardPage/chart/TimeLine";
import { sentiment_Obj, socialMediaObj } from "./Chart/utils";
import { structureData } from "../../Utils/chartCommandFunction";
import TextArea from "antd/es/input/TextArea";
import { convertTimestampinFormatedDate } from "../../Utils/globalFunction";
import { toast } from "react-toastify";
import FullMap from "../../Componets/MapGL/full-map";
import useDebouncedApiCall from "../../hook/useDebouncedSearch";
import { searchCompanyListActionLoad } from "../../Redux/Action/global";
import RegulatoryLegalCard from "../dashboardPage/utils-components/regulatoryLegalCard";
import ReactCountryFlag from "react-country-flag";
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import { highlightMatch } from "../../Utils/globalFunction";

const text = <span>Incidents</span>;
const text2 = <span>Supply chain</span>;

// Register locales with correct imports
countries.registerLocale(enLocale);
countries.registerLocale(frLocale);
let lastSearchString = "";

const SummaryDetails = ({ data, supChainIcon, title, titleIcon, id, show }) => {
  return (
    <div className="comp-name-tex comp-name-text_new">
      <h5 style={{ display: "flex", alignItems: "center" }}>
        <div>
          <span>
            <img src={titleIcon} />
          </span>
        </div>
        <div
          className="disLikeImg"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {title}
          <div>
            <img
              style={{ marginLeft: 16, marginRight: 0 }}
              src={ImageConst.whiteLikeBtn}
            />
            <img style={{ marginRight: 0 }} src={ImageConst.whiteDislikeBtn} />
          </div>
        </div>
      </h5>

      {title == "Location" && data?.name && (
        <ul>
          <li>{<span style={{ fontWeight: 'bold' }}>{data.name}</span>}</li>
        </ul>
      )}

      {title !== "Location" && (
        <ul className="bullet">
          {data?.map((i) => (
            <li>
              {
                <span
                  style={{ fontWeight: 'bold' }}
                  onClick={() =>
                    navigate("/companyPage", {
                      state: { id: i.id },
                    })
                  }
                >
                  {i.name}
                </span>
              }
              <Tooltip placement="bottom" title={text}>
                <span>
                  <img
                    onClick={() => {
                      show(i.id);
                    }}
                    src={Images.icon}
                  />
                </span>
              </Tooltip>
              {supChainIcon && (
                <>
                  <img src={Images.lineImg} />
                  <Tooltip placement="bottom" title={text2}>
                    <img
                      onClick={() => {
                        let data = {
                          id: i.id,
                          label: i.name,
                        };
                        navigate("/searchresult", {
                          state: { record: data },
                        });
                      }}
                      src={Images.supplytwo}
                    />
                  </Tooltip>
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const NewsArticleComponent = ({ data }) => {
  return data?.map(({ source, title, body, publication_datetime, url }) => (
    <div className="tweet-steve">
      <div className="user-steve">
        <div className="user-prof">
          <div className="user-image">
            <a target="_blank" href={url}>
              <span>{source?.title || ""}</span>
            </a>
          </div>
        </div>

        <div className="twitter-logo">
          <span>{getDiffTimeNew(publication_datetime)}</span>
        </div>
      </div>

      <div className="user-tweet mt-2">
        <ul>
          <li>
            <a target="_blank" href={url}>
              {title || ""}
            </a>
          </li>
        </ul>
      </div>
    </div>
  ));
};

const IncidentDetails = (props) => {
  const [isSaveCollection, setIsSaveCollection] = useState(0);
  const [visibleInput, setVisibleInput] = useState(false);
  const [collectionId, setCollectionId] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [seriesDataTimeline, setSeriesDataTimeline] = useState([]);
  const incidentData = useSelector(
    (state) => state?.incidentReducer?.incidentDetails
  );

  const {
    ngo_reports,
    news_articles,
    socialMediaCoverage,
    regulatry_legal_actions,
    penalties_and_fines,
    timeline_graph,
    successpop,
    severityDataLocationArray,
  } = useSelector((state) => state?.incidentReducer);
  const collectionList = useSelector(
    (state) => state?.saveIncidenceReducer?.result
  );

  const createCollectionArr = useSelector(
    (state) => state?.saveIncidenceReducer?.createCollectionData
  );

  const sentimentObj = sentiment_Obj(socialMediaCoverage?.sentiment || {});
  const socialObj = socialMediaObj(socialMediaCoverage?.socialMedia || {});
  const {
    tags = [],
    highlights = [],
    affected_companies,
    company_responses,
    risk_mitigation_strategies = [],
    financial_figures,
    environment_metrics,
    scale_of_the_incident,
    regulatry_legal_timeline,
    stakeholder_perspectives,
    nature_of_the_incident,
    contextual_information,
    pai,
    sdg,
    factors,
    severity_score,
    severity_keywords,
    ai_severity_impact_scale,
    severity_risk,
  } = incidentData;
  const [collectionName, setCollectionName] = useState({
    inputVal: "",
    isCard: false,
  });

  useEffect(() => {
    if (createCollectionArr) {
      let newCollectionObj = {
        isCollectionSelected: false,
        name: createCollectionArr?.name,
        _id: createCollectionArr?._id,
      };
      let totalData = { ...incidentData };
      if (!totalData?.collections?.includes(newCollectionObj)) {
        totalData?.collections?.push(newCollectionObj);
        dispatch(updateIncidentState(totalData, "incidentDetails"));
      }
    }
  }, [createCollectionArr]);

  const [enviromentDataModify, setEenviromentDataModify] = useState({});
  const [ngoDataModify, setNgoDataModify] = useState({});

  useEffect(() => {
    if (timeline_graph?.news_articles?.data) {
      let data = Object.entries(timeline_graph?.news_articles?.data);
      data = structureData(
        data,
        timeline_graph?.news_articles?.groupType,
        "Calledx"
      );
      const comind = [...data.map((e) => e[2])].sort((a, b) => a - b);
      const min = comind[0],
        max = comind[comind.length - 1];
      setEenviromentDataModify(
        getFilteredArrayforBubbles(data, min, max, [10, 50])
      );
    }

    if (timeline_graph?.ngo_reports?.data) {
      let data = Object.entries(timeline_graph?.ngo_reports?.data);
      data = structureData(
        data,
        timeline_graph?.ngo_reports?.groupType,
        "Calledx"
      );
      const comind = [...data.map((e) => e[2])].sort((a, b) => a - b);
      const min = comind[0],
        max = comind[comind.length - 1];
      setNgoDataModify(getFilteredArrayforBubbles(data, min, max, [10, 50]));
    }
  }, [timeline_graph]);
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const [open1, setOpen1] = useState(false);
  const [showModel, setShowModel] = useState(false);

  const [options, setOptions] = useState([]);
  const [countryName, setCountryName] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [legalOrder, setLegalOrder] = useState(0);
  const [order, setOrder] = useState(0);

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { searchCompaniesData } = useSelector((state) => state?.global);
  const { countryList } = useSelector((state) => state?.supplyChainReducer);
  useEffect(() => {
    dispatch(getCountryDataforSupplyChain());
  }, []);

  useEffect(() => {
    if (
      incidentData &&
      incidentData.collections &&
      incidentData.collections.length > 0
    ) {
      let tempSelectedKeys = [];
      incidentData.collections.map((item) => {
        if (item.isCollectionSelected) {
          return tempSelectedKeys.push(item._id);
        }
      });
      setCollectionId(tempSelectedKeys);
    }
  }, [incidentData]);
  const { searchTerm, handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );

  const handleSearchOperation = (SelectedData) => {
    if (searchCompaniesData?.data?.length !== 0) {
      navigate("/companyPage", { state: { id: SelectedData.value } });
    }
  };
  const [incidentId, setIncidentId] = useState(
    state?.id ? state.id : search?.split("?")[1]
  );
  const handleModel = () => {
    setCollectionName({
      inputVal: `New collection ${collectionList.length + 1}`,
      isCard: true,
    });
    setOpen1(false);
    setShowModel(true);
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [companiesId, setCompaniesId] = useState([]);
  const openModal = (e) => {
    setCompaniesId(e);
    setModalVisible(!modalVisible);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleOpenChange1 = (newOpen) => {
    setOpen1(newOpen);
  };

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    scrollTo(0.0);
    dispatch(incidentDetailAction(incidentId));
    dispatch(getCollectionAction());
    dispatch(incidentMapAction({ specificIncidentIds: [incidentId] }));
    dispatch(
      getNewsArticleAction({
        incident: incidentId,
        type: "articles",
        offset: 0,
        limit: 3,
      })
    );
    dispatch(
      getNewsArticleAction({
        incident: incidentId,
        type: "news",
        offset: 0,
        limit: 3,
      })
    );
    dispatch(
      getSocialMediaIncidentAction({
        id: incidentId,
      })
    );
    //Regulatory Fines & Penalties
    dispatch(
      getFineAndpenaltyAction({
        id: incidentId,
      })
    );

    //Regulatory & Legal Actions
    dispatch(
      geRegulatorAndLegalAction({
        id: incidentId,
      })
    );

    dispatch(getTimeLineGraphAction(incidentId));
  }, [incidentId]);

  const checkImage = () => {
    let image = null;
    if (ngo_reports?.length > 0) {
      for (let i in ngo_reports) {
        if (ngo_reports[i].image) {
          image = ngo_reports[i].image;
          return image;
        }
      }
    } else if (news_articles?.length > 0) {
      for (let i in news_articles) {
        if (news_articles[i].image) {
          image = news_articles[i].image;
          return image;
        }
      }
    }
    return image;
  };

  const legend = [
    {
      name: "X corp.",
      image: Images.XCROP,
    },

    {
      name: "Reddit",
      image: Images.Raddit,
    },
  ];
  const SocialMediaComponent = () => {
    return (
      <ChartCard
        showTimeBarOnTop={false}
        title={"Social Media Coverage"}
        legend={PNN}
        className={"mt-4"}
      >
        <div className="line-chart">
          <Row>
            <div className="col-sm-6 socialChart">
              <span className="captionXl">
                Social Network Perception Distribution
              </span>
              <EChartsReact
                className="doubleLayer-pieChart mt-4"
                option={{
                  silent: false,
                  series: [
                    {
                      nodeClick: false,
                      label: {
                        color: "#fff",
                        fontSize: "18",
                        textBorderColor: "#fff",
                      },
                      itemStyle: {
                        borderRadius: 10,
                        borderWidth: 5,
                      },
                      radius: ["0", "100%"],
                      type: "sunburst",
                      emphasis: {
                        focus: "ancestor",
                      },
                      data: [
                        {
                          children: sentimentObj?.tweets,
                          name: "Tweets",
                          value: 30,
                          itemStyle: {
                            color: {
                              colorStops: [
                                {
                                  offset: 0,
                                  color: "#1DA1F2",
                                },
                                {
                                  offset: 1,
                                  color: "rgba(135, 207, 255, 1)",
                                },
                              ],
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1.5,
                              type: "linear",
                              global: false,
                            },
                          },
                        },

                        {
                          children: sentimentObj?.reddit,
                          name: "Reddit",
                          value: 30,
                          itemStyle: {
                            color: {
                              colorStops: [
                                {
                                  offset: 0,
                                  color: "#FF4500",
                                },
                                {
                                  offset: 1,
                                  color: "rgba(255, 171, 140, 1)",
                                },
                              ],
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1.5,
                              type: "linear",
                              global: false,
                            },
                          },
                        },
                      ],
                    },
                  ],
                }}
              />
            </div>
            <div className="col-sm-6  socialChart">
              <span className="captionXl">
                Social Network Perception Distribution by Platforms
              </span>
              <EChartsReact
                className="doubleLayer-pieChart  mt-4"
                option={{
                  silent: false,

                  series: [
                    {
                      label: {
                        color: "#fff",
                        fontSize: "18",
                        textBorderColor: "#fff",
                      },
                      nodeClick: false,
                      itemStyle: {
                        borderRadius: 10,
                        borderWidth: 5,
                      },
                      radius: ["0", "100%"],
                      type: "sunburst",
                      emphasis: {
                        focus: "ancestor",
                      },
                      data: [
                        {
                          children: socialObj?.positive?.childArray,
                          name: socialObj?.positive?.percentage + " %",
                          value: 30,
                          itemStyle: {
                            color: {
                              colorStops: [
                                {
                                  offset: 0,
                                  color: "#3aa95a",
                                },
                                {
                                  offset: 1,
                                  color: "#ffffff",
                                },
                              ],
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1.5,
                              type: "linear",
                              global: false,
                            },
                          },
                        },
                        {
                          children: socialObj?.negative?.childArray,
                          name:
                            Math.round(socialObj?.negative?.percentage) + " %",
                          value: 30,
                          itemStyle: {
                            color: {
                              colorStops: [
                                {
                                  offset: 0,
                                  color: "#d72727",
                                },
                                {
                                  offset: 1,
                                  color: "#ffffff",
                                },
                              ],
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1.5,
                              type: "linear",
                              global: false,
                            },
                          },
                        },
                        {
                          children: socialObj?.neutral?.childArray,
                          name: socialObj?.neutral?.percentage + " %",
                          value: 30,
                          itemStyle: {
                            color: {
                              colorStops: [
                                {
                                  offset: 0,
                                  color: "#bec3cd",
                                },
                                {
                                  offset: 1,
                                  color: "#ffffff",
                                },
                              ],
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1.5,
                              type: "linear",
                              global: false,
                            },
                          },
                        },
                      ],
                    },
                  ],
                }}
              />
            </div>
          </Row>
          <div className="mainLegendDiv">
            {legend.map((e, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img className="legendImg" src={e.image} alt={e.name} />
                  <div className="legendSpan" style={{ marginLeft: "10px" }}>
                    {" "}
                    {e.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="reactionAnalyses"
            onClick={() =>
              navigate("/socialMediaCoverage", {
                state: {
                  companies: [],
                  incident: incidentId,
                  name: "companyPage",
                  incidentDetail: incidentData,
                },
              })
            }
          >
            <span className="reactionAnalysesSpan">
              View All Reaction Analyses
            </span>
          </div>
        </div>
      </ChartCard>
    );
  };

  const getImageESG = (title) => {
    if (title == "Environment" || title == "Environmental") {
      return ImageConst.fillenv;
    } else if (title == "Social") {
      return ImageConst.fillSocial;
    } else if (title == "Governance") {
      return ImageConst.fillGov;
    }
  };
  const ESGCATGORIES = ({ title, data = [] }) => {
    if (data.length > 0) {
      return (
        <>
          <div
            className={`comp-name-tex-new ${title == "ESG Factors" ? "pt-0" : "pt-4"
              }`}
          >
            <h5
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {title}
              <div
                className="disLikeImg"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  style={{ marginLeft: 16, marginRight: 0 }}
                  src={ImageConst.whiteLikeBtn}
                />
                <img
                  style={{ marginRight: 0 }}
                  src={ImageConst.whiteDislikeBtn}
                />
              </div>
            </h5>
          </div>
          {data.map((a, i) => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    paddingTop: 16,
                  }}
                >
                  {a.parent && (
                    <img
                      style={{ width: 24, height: 24 }}
                      src={getImageESG(a.parent)}
                    />
                  )}
                  <span
                    className="esgTitle"
                    style={{ color: getColorForTableText(a.parent) }}
                  >
                    {a.parent}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    paddingTop: 16,
                  }}
                >
                  <span className="esgSubTitle">{a.name}</span>
                </div>
              </>
            );
          })}
        </>
      );
    }
  };
  const getColor = (data) => {
    if (data == "High") {
      return "#FF0000";
    }
    if (data == "Minor") {
      return "#FFBF00";
    }
    if (data == "Moderate") {
      return "#FF7518";
    }
    if (data == "Critical") {
      return "#800020";
    }
  };

  const Quantitative = ({ data, title, icon }) => {
    if (Array.isArray(data) && data.length > 0) {
      return (
        <>
          <div className="innerLayout">
            <span className="bodySelected">{title}</span>
            {icon && (
              <div
                className="disLikeImg"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  style={{ marginLeft: 16, marginRight: 0 }}
                  src={ImageConst.whiteLikeBtn}
                />
                <img
                  style={{ marginRight: 0 }}
                  src={ImageConst.whiteDislikeBtn}
                />
              </div>
            )}
          </div>
          <ul>
            {data.map((val, ind) => {
              return (
                <li>
                  <span className="titleVal">{val}</span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };
  let incidentList;
  const incidentFormatTooltip = ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }) => {
    incidentList = w?.config.series[seriesIndex].data[dataPointIndex][3].map(
      (val) => val.id
    );

    const type = w?.config.series[seriesIndex].name;
    const date = convertTimestampinFormatedDate(
      w?.config.series[seriesIndex].data[dataPointIndex][0],
      "monthName"
    );
    const firstNumberTitle =
      w?.config.series[seriesIndex].data[dataPointIndex][1];
    const number =
      w?.config.series[seriesIndex].data[dataPointIndex][3]?.length;
    return `
      <div style="width:152px;padding:12px; border-radius:5px;">
      <div style="font-weight:bold;font-size:14px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color:#676A73">
      ${date}
      </div>
      <div class="tooltip-item" style="display:flex;flex-direction:justify-content-between">
      <div style="display:flex;align-items:center;justify-content: space-around;gap:2px;">
        <div style="height:10px; width:10px; radius:2px; background-color:${type == "news_articles" ? "#7450D8" : "#0195ce"
      }"></div>
        <div style="margin-left:6px">${type} : </div>
      <div>
      <div> ${firstNumberTitle}</div>
      </div>
    </div>
      `;
  };
  let seriesData = [
    {
      name: "news_articles",
      data: timeline_graph?.news_articles?.data ? enviromentDataModify : [],
    },
    {
      name: "ngo_reports",
      data: timeline_graph?.ngo_reports?.data ? ngoDataModify : [],
    },
  ];

  let mind = seriesData
    ?.map((e) => (e.data ? e.data : ""))
    ?.flat(1)
    ?.map((e) => e[0])
    .sort();

  const chartData = {
    series: seriesData,
    options: {
      chart: {
        height: "100%",
        type: "bubble",
        toolbar: {
          show: false,
          tools: {
            zoom: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
        // formatter: ()
      },
      fill: {
        opacity: 0.8,
      },
      xaxis: {
        min: mind[0] - mind[0] * (0.1 / 100) || undefined,
        max:
          mind[mind.length - 1] + mind[mind.length - 1] * (0.1 / 100) ||
          undefined,
        tickAmount: 12,
        type: "category",
        labels: {
          formatter: function (value) {
            return getBubbleXaxis(
              value,
              timeline_graph?.news_articles?.groupType
            );
          },
        },
      },
      yaxis: {
        max: 2,
        tickAmount: 2,
        show: false,
      },
      grid: {
        padding: {
          left: 25,
        },
      },
      legend: {
        show: false,
      },
      colors: ["#A38BE5", "#5CBBE0"],
      theme: {
        palette: "palette2",
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
        custom: incidentFormatTooltip,
      },
    },
  };

  return (
    <div className="side-padding">
      <div className="main-layout table-layout">
        <div className="container1360">
          <section className="mt-2 mb-4 d-flex justify-content-between align-items-center">
            <div className="breadcrmbs">
              <Breadcrumb separator={"/"}>
                <Breadcrumb.Item
                  key="1"
                  className="inc-bad-link"
                  separator={"/"}
                >
                  Incident
                  {/* <img src={Images.chevronRighta} /> */}
                </Breadcrumb.Item>

                <Breadcrumb.Item
                  key="3"
                  className="detail-lie-link"
                  separator={"/"}
                >
                  Incident Details
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="autocomplete-input-search">
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                popupMatchSelectWidth={500}
                className="serchmedia-icon"
                // options={searchCompaniesData?.data}
                options={renderOptions(searchCompaniesData)}
                size="large"
                onSearch={handleSearch}
                value={countryName}
                onSelect={(e, selectedOption) => {
                  const { dataValue } = selectedOption;
                  setCountryName(dataValue);
                  handleSearchOperation(selectedOption);
                }}
                onChange={(e, i) => {
                  setCountryName(e);
                }}
              >
                <Input.Search size="large" placeholder="Search company" />
              </AutoComplete>
            </div>
          </section>
          <div className="row">
            <div className=" col-xl-7 col-lg-6">
              <div className="top-main-heading-text">
                <div className="apple-face">
                  <h4>{incidentData.title}</h4>
                </div>
              </div>
              <div className="summ-ry mt-4 mb-2">
                <div className="sum-text">
                  <h4>Summary</h4>
                </div>
                <div className="save-btn">
                  <Popover
                    content={
                      <div className="popup-save" style={{ width: "210px" }}>
                        <div className="title-save">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={handleModel}
                          >
                            <div>
                              <img
                                style={{
                                  width: "17px",
                                  height: "17px",
                                  marginRight: "2px",
                                }}
                                src={Images.plusGrey}
                              />
                            </div>
                            <div
                              style={{
                                fontSize: "12px",
                                marginTop: "5px",
                                fontWeight: "500",
                                color: "#07132F",
                                fontFamily: "inter",
                                cursor: "pointer",
                              }}
                            >
                              Create new incident collection
                            </div>
                          </div>
                          <div className="save-collection">
                            <img src={Images.bookmark} />
                            <span>Save to</span>
                          </div>
                          {incidentData?.collections &&
                            incidentData?.collections.length > 0 &&
                            incidentData?.collections.map((item, index) => (
                              <div className="checkbox-collection">
                                <input
                                  onClick={() => {
                                    let collectionArr = collectionId;
                                    if (collectionArr.includes(item._id)) {
                                      const matchIndex = collectionArr.indexOf(
                                        item._id
                                      );
                                      collectionArr.splice(matchIndex, 1);
                                    } else {
                                      collectionArr.push(item._id);
                                    }
                                    setIsSaveCollection(collectionArr?.length);
                                    setCollectionId(collectionArr);
                                  }}
                                  type="checkbox"
                                  id="cb1"
                                  checked={collectionId.includes(item?._id)}
                                ></input>
                                <p>{item?.name}</p>
                              </div>
                            ))}
                          {isSaveCollection > 0 ? (
                            <div className="save-btn">
                              <button
                                onClick={() => {
                                  setOpen1(false);
                                  dispatch(
                                    addToCollectionAction({
                                      incidentId: incidentId,
                                      collectionIds: collectionId,
                                    })
                                  );
                                }}
                              >
                                Save
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    }
                    title=""
                    trigger="click"
                    open={open1}
                    onOpenChange={handleOpenChange1}
                  >
                    <img src={Images.save} />
                  </Popover>

                  <Modal
                    className="cross-modelNEW  cross-modelNEW1"
                    title={
                      <ModalHeader title={"New Collection"} padding={true} />
                    }
                    centered
                    closeIcon={<ModalCloseButton />}
                    open={showModel}
                    onCancel={() => setShowModel(false)}
                    footer={[
                      <div className="cross-modelNew1-footer">
                        <button
                          className="can-btn can-del"
                          onClick={() => setShowModel(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="rest-btn"
                          onClick={() => {
                            if (collectionName.inputVal.trim() == "") {
                              toast.error("Please enter folder name.", {
                                position: toast.POSITION.TOP_RIGHT,
                                id: "validFolderName",
                              });
                            } else {
                              setShowModel(!showModel);
                              dispatch(
                                createSaveIncidenceAction({
                                  name: collectionName.inputVal.trim(),
                                })
                              );
                              // setCollectionName("");
                              setCollectionName({
                                inputVal: "",
                                isCard: false,
                              });
                            }
                          }}
                        >
                          Create
                        </button>
                      </div>,
                    ]}
                  >
                    <div className="reset-setting folder-num">
                      <p>Folder Name</p>
                      <div className="in-put-popup">
                        {collectionName.isCard ? (
                          <div
                            style={{
                              border: "1px solid #4295ce",
                              padding: "6px",
                              display: "flex",
                            }}
                            onClick={() =>
                              setCollectionName({
                                ...collectionName,
                                isCard: false,
                              })
                            }
                          >
                            <div
                              style={{
                                paddingLeft: "3px",
                                paddingRight: "3px",

                                backgroundColor: "#d7f0fa",
                              }}
                            >
                              {collectionName.inputVal}
                            </div>
                          </div>
                        ) : (
                          <Input
                            className="input-watch"
                            style={{ paddingRight: "40px" }}
                            prefix={
                              <img
                                src={ImageConst.closeIcon}
                                style={{ marginTop: "-8px" }}
                                onClick={() =>
                                  setCollectionName({
                                    inputVal: "",
                                    isCard: false,
                                  })
                                }
                              />
                            }
                            value={collectionName.inputVal}
                            type="text"
                            onChange={(e) => {
                              if (e.target.value > 3) {
                                setCollectionName({
                                  inputVal: e.target.value,
                                  isCard: false,
                                });
                              } else {
                                setCollectionName({
                                  inputVal: e.target.value,
                                  isCard: false,
                                });
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Modal>

                  <span>
                    <Popover
                      content={
                        <div className="popup" style={{ width: "240px" }}>
                          <div className="twit-logo" style={{ marginTop: "" }}>
                            <a onClick={hide}>
                              <TwitterShareButton
                                url={window.location.href}
                                title={
                                  incidentData.title +
                                  "\n" +
                                  incidentData.summary?.substring(0, 100)
                                }
                              >
                                <img
                                  src={Images.XCROPNEW}
                                  width={24}
                                  height={24}
                                />
                              </TwitterShareButton>
                            </a>
                          </div>
                          <div className="linkedin mt-2">
                            <a onClick={hide}>
                              <LinkedinShareButton
                                url={window.location.href}
                                title={
                                  incidentData.title +
                                  "\n" +
                                  incidentData.summary
                                }
                              >
                                <img
                                  src={Images.linkedinNew}
                                  width={24}
                                  height={24}
                                />
                              </LinkedinShareButton>
                            </a>
                          </div>
                          <div className="copy ml-5">
                            <p>or copy link</p>
                            <div className="copy-box">
                              <CopyToClipboard
                                text={window.location.href}
                                onCopy={(text, result) =>
                                  notification.success({
                                    message: "Copied to Clipboard.",
                                  })
                                }
                              >
                                <img src={Images.copy} />
                              </CopyToClipboard>
                              <span className="ml-5">
                                {window.location.href}
                              </span>
                            </div>
                          </div>
                        </div>
                      }
                      title=""
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <img src={Images.share} />
                    </Popover>
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-xl">
                  <div className="apple-sum">
                    <p>{incidentData.summary}</p>
                  </div>
                </div>
                {checkImage() && (
                  <div className="col-xl-4">
                    <div className="apple-pic">
                      <img
                        style={{ objectFit: "contain", borderRadius: 10 }}
                        src={checkImage()}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="feedbackLayout mt-4">
                <div
                  className="d-flex justify-content-between"
                  style={{ display: "flex", width: "100%" }}
                >
                  <div style={{ display: "flex", width: "50%" }}>
                    <span className="feedbackBody">
                      Was this summary helpful?
                    </span>
                    <div className="disLikeImg">
                      <img
                        style={{ marginLeft: 16 }}
                        src={ImageConst.whiteLikeBtn}
                      />
                      <img
                        style={{ marginLeft: 8 }}
                        src={ImageConst.whiteDislikeBtn}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <span
                      className="feedbackBtn"
                      onClick={() => setVisibleInput(!visibleInput)}
                    >
                      I want to give additional feedback
                    </span>
                  </div>
                </div>
                {visibleInput && (
                  <div className="feedbackInput">
                    <TextArea
                      onChange={(e) => setFeedback(e.target.value)}
                      value={feedback}
                      style={{
                        width: "100%",
                        maxHeight: 72,
                        height: 72,
                        minHeight: 72,
                      }}
                    ></TextArea>
                    <div className="d-flex justify-content-end">
                      <Button
                        disabled={feedback.length > 0 ? false : true}
                        onClick={() => {
                          dispatch(
                            feedbackAction({
                              message: feedback,
                              id: incidentId,
                            })
                          );
                          setVisibleInput(false);
                        }}
                        className="feedbackSubmitBtn"
                      >
                        submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="comp-loc mt-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <SummaryDetails
                  data={affected_companies}
                  supChainIcon
                  show={(e) => {
                    openModal(e);
                  }}
                  id={incidentId}
                  title={"Companies"}
                  titleIcon={Images.hotelBlue}
                />

                <SummaryDetails
                  title={"Location"}
                  show={openModal}
                  data={incidentData?.incidentLocation || []}
                  titleIcon={Images.location}
                />

                <div className="comp-name-tex">
                  <h5 style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <span>
                        <img src={Images.clock} />
                      </span>
                    </div>
                    <div
                      className="disLikeImg"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>Occurrence</span>
                      <div>
                        <img
                          style={{ marginLeft: 16, marginRight: 0 }}
                          src={ImageConst.whiteLikeBtn}
                        />
                        <img
                          style={{ marginRight: 0 }}
                          src={ImageConst.whiteDislikeBtn}
                        />
                      </div>
                    </div>
                  </h5>
                  <p style={{ fontWeight: 'bold' }}>
                    {moment(incidentData?.discovery_time).format(
                      "MMMM DD, YYYY HH:mm A"
                    )}
                  </p>
                </div>
              </div>
              <section>
                <div
                  className=" most-sec-over g-0 mt-4"
                  style={{ padding: 20 }}
                >
                  <div className="UNclass">
                    <img
                      className="sq104px"
                      src={Images.regulatorLegal}
                      alt="RL"
                    />
                    <div className="d-flex row">
                      <div
                        className="disLikeImg"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          style={{ marginLeft: 16, marginRight: 0 }}
                          src={ImageConst.whiteLikeBtn}
                        />
                        <img
                          style={{ marginRight: 0 }}
                          src={ImageConst.whiteDislikeBtn}
                        />
                      </div>
                      <Row>
                        <span className="unSpan pt-0 pb-0">
                          Regulatory & Legal Actions
                          <img
                            className="hover-page"
                            style={{ marginLeft: 5 }}
                            src={Images.circleInfo}
                            alt=""
                          />
                        </span>
                      </Row>

                      <div className="d-flex justify-content-between align-items-start">
                        <span className="CaptionRegularFit pl-16 pt-1">{`Last updated on ${moment(
                          getlatestDate(regulatry_legal_actions)
                        ).format("MMM, DD YYYY")}`}</span>
                        <Tooltip placement="left" title={"Sort"}>
                          <img
                            onClick={() => {
                              dispatch(
                                geRegulatorAndLegalAction({
                                  id: incidentId,
                                  sort: "datetime",
                                  order: !legalOrder,
                                })
                              );
                              setLegalOrder(!legalOrder);
                            }}
                            className="hover-page"
                            style={{ marginLeft: 5, height: 32, width: 32 }}
                            src={Images.sortButton}
                            alt=""
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    {regulatry_legal_actions?.length > 0 ? (
                      regulatry_legal_actions?.map((val, i) => {
                        return (
                          <RegulatoryLegalActionsCard
                            index={i}
                            {...val}
                            screen={"Incident"}
                          />
                        );
                      })
                    ) : (
                      <div className="noDataLayout">
                        <span className="noDataText">
                          There are no regulatory & legal actions timeline
                        </span>
                      </div>
                    )}
                  </div>
                  {regulatry_legal_timeline?.data &&
                    Object.values(regulatry_legal_timeline?.data).filter(
                      (o) => o.length > 0
                    ).length > 0 && (
                      <div className="d-flex flex-column justify-content">
                        <span className="unSpanCount pt-2 pb-0">
                          Regulatory & Legal Actions Timeline
                        </span>
                        {regulatry_legal_timeline?.data && (
                          <Timeline
                            group={regulatry_legal_timeline.groupType}
                            data={regulatry_legal_timeline.data}
                          />
                        )}
                      </div>
                    )}
                </div>
              </section>

              <section>
                <div className="most-sec-over g-0 mt-4" style={{ padding: 20 }}>
                  <div className="UNclass">
                    <img className="sq104px" src={Images.auction} alt="RL" />
                    <div className="d-flex row" style={{ width: "100%" }}>
                      <div
                        className="disLikeImg"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          style={{ marginLeft: 16, marginRight: 0 }}
                          src={ImageConst.whiteLikeBtn}
                        />
                        <img
                          style={{ marginRight: 0 }}
                          src={ImageConst.whiteDislikeBtn}
                        />
                      </div>
                      <Row>
                        <span className="unSpan pt-0 pb-0">
                          Regulatory Fines & Penalties
                          <img
                            className="hover-page"
                            style={{ marginLeft: 5 }}
                            src={Images.circleInfo}
                            alt=""
                          />
                        </span>
                      </Row>

                      <div className="d-flex justify-content-between align-items-start">
                        <span className="CaptionRegularFit pl-16 pt-1">
                          {penalties_and_fines.length > 0 &&
                            penalties_and_fines[0]?.date &&
                            `Last updated on ${moment(
                              getlatestDate(penalties_and_fines)
                            ).format("MMM,DD YYYY")}`}
                        </span>
                        <Tooltip placement="left" title={"Sort"}>
                          <img
                            onClick={() => {
                              dispatch(
                                getFineAndpenaltyAction({
                                  id: incidentId,
                                  sort: "datetime",
                                  order: !order,
                                })
                              );
                              setOrder(!order);
                            }}
                            className="hover-page"
                            style={{ marginLeft: 5, height: 32, width: 32 }}
                            src={Images.sortButton}
                            alt=""
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    {penalties_and_fines.map((val, i) => {
                      return (
                        <RegulatoryFinesPenaltiesCard
                          {...val}
                          id={val.id}
                          index={i}
                          screen={"Incident"}
                        />
                      );
                    })}
                    <div></div>
                  </div>
                  {penalties_and_fines.length > 0 ? (
                    <>
                      {penalties_and_fines.length > 4 ? (
                        <div className="reactionAnalyses">
                          <span className="reactionAnalysesSpan downl-btn">
                            View More
                          </span>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="noDataLayout">
                      <span className="noDataText">
                        {" "}
                        There are no significant fines or penalties{" "}
                      </span>
                    </div>
                  )}
                </div>
              </section>

              <section>
                <div className="inc-map-pre mt-32">
                  <div className="tab-top">
                    <div className="left-text">
                      <Row>
                        <span className="subtitle1">Highlights</span>
                        <img
                          className="hover-page"
                          style={{ marginLeft: 5 }}
                          src={Images.circleInfo}
                          alt=""
                        />
                      </Row>
                    </div>
                    <div className="righ-side">
                      <div
                        className="disLikeImg"
                        style={{ display: "flex", width: "50%" }}
                      >
                        <img
                          style={{ marginLeft: 16 }}
                          src={ImageConst.whiteLikeBtn}
                        />
                        <img
                          style={{ marginLeft: 8 }}
                          src={ImageConst.whiteDislikeBtn}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="HighLightLayout">
                    <div className="highLightView">
                      {highlights.map((i, a) => {
                        return (
                          <div className="highLightTextView">
                            <span className="highLightText">{i}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>

              {incidentData.incidentLocation && (
                <section>
                  <div className="container inc-map-pre mt-4">
                    <div className="row">
                      <div className="postion-map">
                        <div className="sev-title">
                          <h6>Severity</h6>
                          <p>
                            <span>
                              <img src={Images.circlered} />
                            </span>
                            High
                          </p>
                          <p>
                            <span>
                              <img src={Images.cricleOrange} />
                            </span>
                            Medium
                          </p>
                          <p>
                            <span>
                              <img src={Images.cricleYellow} />
                            </span>
                            Low
                          </p>
                        </div>
                        <div className="mt-3 mb-3 sverity-map-ID">
                          <FullMap
                            width={"650px"}
                            height={"326px"}
                            zoom={0}
                            data={severityDataLocationArray || []}
                            path={"incidentDetails"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {company_responses?.length > 0 && (
                <div className="responses mt-4">
                  <div className="reactions">
                    <h5>Company Reactions and Responses</h5>
                    <div
                      className="disLikeImg"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ marginLeft: 16, marginRight: 0 }}
                        src={ImageConst.whiteLikeBtn}
                      />
                      <img
                        style={{ marginRight: 0 }}
                        src={ImageConst.whiteDislikeBtn}
                      />
                    </div>
                  </div>
                  <div className="CRR_layout">
                    <ul>
                      {company_responses?.map((i) => (
                        <li className="CRR_LayoutSpan">{i.statement}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {/* <section>
                <div className="inc-map-pre mt-32">
                  <div className="tab-top">
                    <div className="left-text">
                      <Row>
                        <span className="subtitle1">Incident Timeline</span>
                        <img
                          className="hover-page"
                          style={{ marginLeft: 5 }}
                          src={Images.circleInfo}
                          alt=""
                        />
                      </Row>
                    </div>
                    <div className="righ-side">
                      <div className="ellips">
                        {[
                          { tittle: "News Articles", color: "#7450D8" },
                          { tittle: "NGO Reports", color: "#1FA2D4" },
                        ]?.map((data) => {
                          return (
                            <p className="legend">
                              <span
                                className="legendCircle"
                                style={{ background: data.color }}
                              />
                              {data.tittle}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <BubbleChart
                      disableClick={true}
                      options={chartData}
                      from={"incidentdetails"}
                      height={300}
                    />
                  </div>
                </div>
              </section> */}
              <SocialMediaComponent />

            </div>
            <div className=" col-xl-5 col-lg-6">
              <div className="left-details-side">
                <div className="inc-map-pre">
                  <div className="tab-top1">
                    <div className="left-text">
                      <Row>
                        <span className="subtitle1">
                          Incident Severity Score
                        </span>
                        <img
                          className="hover-page"
                          style={{ marginLeft: 5 }}
                          src={Images.circleInfo}
                          alt=""
                        />
                      </Row>
                    </div>
                    <div className="righ-side">
                      <div style={{ display: "flex" }}>
                        <img
                          style={{ marginLeft: 16 }}
                          src={ImageConst.aiIcon}
                        />
                        <p className="aiText">{"AI Generator Disclaimer"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="incident-severity-score-ps">
                    <div className="incident-severity-inner-sec">
                      <div className="incident-medium-ps">
                        <p>Severity Score</p>
                        <h2>{severity_score?.value?.toFixed(0)}</h2>
                        <button
                          style={{
                            backgroundColor: getColor(severity_score?.level),
                          }}
                        >
                          {severity_score?.level}
                        </button>
                      </div>
                    </div>

                    <div
                      className="view-evaluation-btn-ps"
                      style={{ marginTop: "15px" }}
                    >
                      <button
                        onClick={() => {
                          navigate("/severityScore", {
                            state: {
                              graphData: severity_keywords,
                              severity_score: severity_score,
                              id: incidentId,
                              aiScore: ai_severity_impact_scale,
                              severity_risk: severity_risk,
                            },
                          });
                        }}
                      >
                        View Evaluation Metrics
                      </button>
                    </div>
                  </div>
                </div>
                <div className="inc-map-pre mt-4">
                  <div className="tab-top1">
                    <div className="left-text">
                      <Row>
                        <span className="subtitle1">ESG Criteria</span>
                        <img
                          className="hover-page"
                          style={{ marginLeft: 5 }}
                          src={Images.circleInfo}
                          alt=""
                        />
                      </Row>
                    </div>
                  </div>
                  <div className="p-4">
                    <ESGCATGORIES title={"ESG Factors"} data={factors} />
                    <ESGCATGORIES title={"SDG"} data={sdg} />
                    <ESGCATGORIES title={"PAI"} data={pai} />
                  </div>
                </div>
                <div className="new-source mt-4">
                  <p>Source & News converge of the Incident</p>
                  <div className="grid-sec-source">
                    <div className="twitte-sec">
                      <img src={Images.file} />
                      <p>News Articles</p>
                      <h6>{incidentData.news_articles}</h6>
                    </div>
                    <div className="twitte-sec">
                      <img src={Images.buil} />
                      <p>NGO Reports</p>
                      <h6>{incidentData.ngo_reports}</h6>
                    </div>
                  </div>
                </div>
                <div className="tweets mt-4">
                  <div className="mentio-tweet">
                    <div className="tw-text">
                      <h5>
                        <span>
                          <img src={Images.fileAlt} />
                        </span>
                        News Articles{" "}
                        <span>({incidentData.news_articles} Articles)</span>
                      </h5>
                    </div>
                    <div
                      className="see-all-link downl-btn"
                      onClick={() =>
                        navigate("/sourceLists", {
                          state: {
                            id: incidentId,
                            key: "articles",
                            title: incidentData.title,
                          },
                        })
                      }
                    >
                      <p>
                        View all
                        <span>
                          <img src={Images.arrowRight} />
                        </span>
                      </p>
                    </div>
                  </div>
                  <NewsArticleComponent data={news_articles?.slice(0, 3)} />
                </div>

                <div className="tweets mt-4">
                  <div className="mentio-tweet">
                    <div className="tw-text">
                      <h5>
                        <span>
                          <img src={Images.builBlock} />
                        </span>{" "}
                        NGO Reports{" "}
                        <span>({incidentData.ngo_reports} Report)</span>
                      </h5>
                    </div>
                    <div
                      className="see-all-link downl-btn"
                      onClick={() =>
                        navigate("/sourceLists", {
                          state: {
                            id: incidentId,
                            key: "reports",
                            title: incidentData.title,
                          },
                        })
                      }
                    >
                      <p>
                        View all
                        <span>
                          <img src={Images.arrowRight} />
                        </span>
                      </p>
                    </div>
                  </div>
                  <NewsArticleComponent data={ngo_reports?.slice(0, 3)} />
                </div>
                <div className="tag-like-dislike">
                  <div className="tag-deta-protection">
                    <div className="like-tag-box-ps">
                      <div>
                        <h5>Tags</h5>
                      </div>
                      <div
                        className="disLikeImg"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          style={{ marginLeft: 16, marginRight: 0 }}
                          src={ImageConst.whiteLikeBtn}
                        />
                        <img
                          style={{ marginRight: 0 }}
                          src={ImageConst.whiteDislikeBtn}
                        />
                      </div>
                    </div>

                    <div className="cybersecurity-box-ps">
                      <div className="cyber-secu-box">
                        {tags.map((value, ind) => {
                          return (
                            <div className="security-ps">
                              <p>{value}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalVisible ? (
        <Modal
          className="incidentModal"
          open={modalVisible}
          onCancel={closeModal}
          footer={null}
          width={1600}
          centered
        >
          <IncidentsList
            companiesId={companiesId}
            goTo={"/incidentdetails"}
            isComeFromModal={true}
            setModal={setModalVisible}
          />
        </Modal>
      ) : null}
    </div>
  );
};

const renderOptions = (searchCompaniesData) => {
  let renderedOptions = searchCompaniesData?.data?.map((option, idx) => {
    return {
      value: option?.id || "",
      dataValue: option?.label || "",
      tickers: option?.tickers || "",
      key: option?.id || "",
      isin: option?.isin || "",
      label: (
        <DoneItem
          searchString={lastSearchString}
          companyName={option?.label || ""}
          ticker={option?.tickers || ""}
          compId={option?.id || ""}
          key={option?.id || ""}
          isin={option?.isin || ""}
          alter_names={option?.alter_names?.length > 0 ? option?.alter_names[0] : ""}
          alter_name={option?.alter_name || ""}
        />
      ),
    }
  })
  return renderedOptions;
};

const DoneItem = ({
  searchString,
  companyName,
  companyIcon,
  ticker,
  key,
  compId = "",
  isin = "",
  alter_name = "",
}) => {
  const highlightedCompanyName = highlightMatch(searchString, companyName?.slice(0, 20), "company");
  const companyEllipse = companyName?.length > 25 ? "..." : ""
  const highlightedTickerSymbol = highlightMatch(searchString, ticker.symbol, "ticker");
  const highlightedTickerID = highlightMatch(searchString, ticker.tickerId, "ticker");
  const highlightedAlter_names = alter_name?.length > 0 ? highlightMatch(searchString, alter_name?.slice(0, 10), "alter") : "";
  const alterEllipse = alter_name?.length > 10 ? "..." : ""
  let highlightedIsin = highlightMatch(searchString, isin, "isin");
  const countryName = ticker?.country?.length > 0 && countries.getName(ticker?.country, "en") || ""
  const countryCode = countryName?.length > 0 && countries.getAlpha2Code(countryName, 'en') || ""
  return (
    <div
      key={key}
      style={{
        display: "flex",
        paddingBottom: 5,
        justifyContent: "space-between",
        alignItems: "end",
        borderBottom: ".5px solid rgb(216,218,224)",
      }}
    >
      <div style={{ width: "100%", height: "35px", gap: "4px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ width: "12%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={`https://logos-svg.komo.systems/${compId}.png` || companyIcon}
            alt="companyImage"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ImageConst.Apple;
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "65%", }}>
          <div
            style={{
              fontSize: "13px",
              fontFamily: "inter",
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: highlightedCompanyName?.trim() + companyEllipse }} />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "10px",
            color: "#4E515C",
            fontFamily: "inter",
          }}>
            <div style={{ overflow: "hidden" }} >
              {countryCode && <ReactCountryFlag countryCode={countryCode} svg={countryName} style={{ width: 12, height: 12, borderRadius: "100%" }} />}
            </div>
            {ticker?.symbol?.length > 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
              {" : "}
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : ticker?.symbol?.length > 0 && ticker?.tickerId?.length == 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
            </div> : ticker?.symbol?.length == 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : null}
          </div>
        </div>
        <div style={{
          width: "28%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          fontSize: "10px",
          color: "#4E515C",
          fontFamily: "inter",
          textAlign: "end"
        }}>
          <span dangerouslySetInnerHTML={{ __html: highlightedAlter_names?.trim() + alterEllipse }} />
          <span dangerouslySetInnerHTML={{ __html: highlightedIsin }} />
        </div>
      </div>
    </div >
  );
};
export default IncidentDetails;
