import { apiConstants as types } from "../../Constants/apiConstants";

export const getPortfolioListLoad = (data) => ({
  type: types.GET_PORTFOLIO_LIST_LOAD,
  data,
});

export const getPortfolioListSuccess = (data) => ({
  type: types.GET_PORTFOLIO_LIST_SUCCESS,
  payload: data,
});

export const getPortfolioListFail = (data) => ({
  type: types.GET_PORTFOLIO_LIST_FAIL,
  payload: data,
});

export const portfolioChangeClrLoad = (data) => ({
  type: types.PORTFOLIO_CHANGE_CLR_LOAD,
  payload: data,
});

export const portfolioChangeClrSuccess = (data) => ({
  type: types.PORTFOLIO_CHANGE_CLR_SUCCESS,
  payload: data,
});

export const portfolioChangeClrFail = (data) => ({
  type: types.PORTFOLIO_CHANGE_CLR_FAIL,
  payload: data,
});

export const addPortfolioLoad = (data) => ({
  type: types.ADD_PORTFOLIO_LOAD,
  payload: data,
});

export const addPortfolioSuccess = (data) => ({
  type: types.ADD_PORTFOLIO_SUCCESS,
  payload: data,
});

export const addPortfolioFail = (data) => ({
  type: types.ADD_PORTFOLIO_FAIL,
  payload: data,
});

// Graph
export const portfolioCompLoad = (data) => ({
  type: types.PORTFOLIO_COMP_LOAD,
  payload: data,
});

export const portfolioCompSuccess = (data) => ({
  type: types.PORTFOLIO_COMP_SUCCESS,
  payload: data,
});

export const portfolioCompFail = (data) => ({
  type: types.PORTFOLIO_COMP_FAIL,
  payload: data,
});

export const mostFrequentEsgLoad = (data) => ({
  type: types.MOST_FREQUENT_ESG_LOAD,
  payload: data,
});

export const mostFrequentEsgSuccess = (data) => ({
  type: types.MOST_FREQUENT_ESG_SUCCESS,
  payload: data,
});

export const mostFrequentEsgFail = (data) => ({
  type: types.MOST_FREQUENT_ESG_FAIL,
  payload: data,
});

export const portfolioDetialLoad = (data) => ({
  type: types.PORTFOLIO_DETAIL_LOAD,
  payload: data,
});

export const portfolioDetialSuccess = (data) => ({
  type: types.PORTFOLIO_DETAIL_SUCCESS,
  payload: data,
});

export const portfolioDetialFail = (data) => ({
  type: types.PORTFOLIO_DETAIL_FAIL,
  payload: data,
});

export const unGlobalgraphLoad = (data) => ({
  type: types.UN_GLOBAL_LOAD,
  payload: data,
});

export const unGlobalgraphSuccess = (data) => ({
  type: types.UN_GLOBAL_SUCCESS,
  payload: data,
});

export const unGlobalgraphFail = (data) => ({
  type: types.UN_GLOBAL_FAIL,
  payload: data,
});

export const getAlertReportLoad = (data) => ({
  type: types.ALERT_REPORT_LOAD,
  payload: data,
});

export const getAlertReportSuccess = (data) => ({
  type: types.ALERT_REPORT_SUCCESS,
  payload: data,
});

export const getAlertReportFail = (data) => ({
  type: types.ALERT_REPORT_FAIL,
  payload: data,
});

export const PortfolioESGRiskLoad = (data) => ({
  type: types.PORTFOLIO_ESG_RISK_LOAD,
  payload: data,
});

export const PortfolioESGRiskSuccess = (data) => ({
  type: types.PORTFOLIO_ESG_RISK_SUCCESS,
  payload: data,
});

export const PortfolioESGRiskFail = (data) => ({
  type: types.PORTFOLIO_ESG_RISK_SUCCESS,
  payload: data,
});

export const getRiskScorePerCompLoad = (data) => ({
  type: types.RISK_SCORE_PER_COMP_LOAD,
  payload: data,
});

export const getRiskScorePerCompSuccess = (data) => ({
  type: types.RISK_SCORE_PER_COMP_SUCCESS,
  payload: data,
});

export const getRiskScorePerCompFail = (data) => ({
  type: types.RISK_SCORE_PER_COMP_FAIL,
  payload: data,
});

export const getHighSeverityIncidentLoad = (data) => ({
  type: types.HIGH_SEVERITY_INCIDENT_LOAD,
  payload: data,
});

export const getHighSeverityIncidentSuccess = (data) => ({
  type: types.HIGH_SEVERITY_INCIDENT_SUCCESS,
  payload: data,
});

export const getHighSeverityIncidentFail = (data) => ({
  type: types.HIGH_SEVERITY_INCIDENT_FAIL,
  payload: data,
});

export const uploadPortfolioFileLoad = (data) => ({
  type: types.UPLOAD_FILE_PORTFOLIO_LOAD,
  payload: data,
});

export const uploadPortfolioFileSuccess = (data) => ({
  type: types.UPLOAD_FILE_PORTFOLIO_SUCCESS,
  payload: data,
});

export const uploadPortfolioFileFail = (data) => ({
  type: types.UPLOAD_FILE_PORTFOLIO_FAIL,
  payload: data,
});

export const deletePortfolioLoad = (data) => ({
  type: types.DELETE_PORTFOLIO_LOAD,
  payload: data,
});

export const deletePortfolioSuccess = (data) => ({
  type: types.DELETE_PORTFOLIO_SUCCESS,
  payload: data,
});

export const deletePortfolioFail = (data) => ({
  type: types.DELETE_PORTFOLIO_FAIL,
  payload: data,
});

export const getEditPortfolioLoad = (data) => ({
  type: types.GET_EDIT_PORTFOLIO_DETAIL_LOAD,
  payload: data,
});

export const getEditPortfolioSuccess = (data) => ({
  type: types.GET_EDIT_PORTFOLIO_DETAIL_SUCCESS,
  payload: data,
});

export const getEditPortfolioFail = (data) => ({
  type: types.GET_EDIT_PORTFOLIO_DETAIL_FAIL,
  payload: data,
});

export const editPortfolioLoad = (data) => ({
  type: types.EDIT_PORTFOLIO_LOAD,
  payload: data,
});

export const editPortfolioSuccess = (data) => ({
  type: types.EDIT_PORTFOLIO_SUCCESS,
  payload: data,
});

export const editPortfolioFail = (data) => ({
  type: types.EDIT_PORTFOLIO_FAIL,
  payload: data,
});

export const distributionGraphLoad = (data) => ({
  type: types.DISTRIBUTION_GRAPH_LOAD,
  payload: data,
});

export const distributionGraphSuccess = (data) => ({
  type: types.DISTRIBUTION_GRAPH_SUCCESS,
  payload: data,
});

export const distributionGraphFail = (data) => ({
  type: types.DISTRIBUTION_GRAPH_FAIL,
  payload: data,
});

export const clearAlertRulesLoad = (data) => ({
  type: types.CLEAR_ALERT_RULES_LOAD,
  payload: data,
});

export const clearAlertRulesSuccess = (data) => ({
  type: types.CLEAR_ALERT_RULES_SUCCESS,
  payload: data,
});

export const clearAlertRulesFail = (data) => ({
  type: types.CLEAR_ALERT_RULES_FAIL,
  payload: data,
});

export const checkValidCmpnyLoad = (data) => ({
  type: types.CHECK_VALID_COMPNY_LOAD,
  payload: data,
});

export const checkValidCmpnySuccess = (data) => ({
  type: types.CHECK_VALID_COMPNY_SUCCESS,
  payload: data,
});

export const checkValidCmpnyFail = (data) => ({
  type: types.CHECK_VALID_COMPNY_FAIL,
  payload: data,
});

export const getPortfolioCompaniesListSearchLoad = (data) => ({
  type: types.GET_PORTFOLIO_COMPANIES_SEARCH_LIST_INITIATE,
  payload: data,
});

export const getPortfolioCompaniesListSearchSuccess = (data) => ({
  type: types.GET_PORTFOLIO_COMPANIES_SEARCH_LIST_SUCCESS,
  payload: data,
});

export const getPortfolioCompaniesListSearchFailure = (data) => ({
  type: types.GET_PORTFOLIO_COMPANIES_SEARCH_LIST_FAILURE,
  payload: data,
});

export const getPortfolioStatusLoad = (data) => ({
  type: types.GET_PORTFOLIO_STATUS_LOAD,
  payload: data,
});

export const getPortfolioStatusSuccess = (data) => ({
  type: types.GET_PORTFOLIO_STATUS_SUCCESS,
  payload: data,
});

export const getPortfolioStatusFailure = (data) => ({
  type: types.GET_PORTFOLIO_STATUS_FAIL,
  payload: data,
});

export const updatePortfolioState = (data, key) => ({
  type: types.UPDATE_PORTFOLIO_STATE,
  payload: data,
  key,
});

export const getPortCompaniesListLoad = (data) => ({
  type: types.GET_PORTFOLIO_COMP_LOAD,
  payload: data,
});

export const portfolioCompanySuccess = (data) => ({
  type: types.GET_PORTFOLIO_COMP_SUCCESS,
  payload: data,
});

export const getPortfolioWeightLoad = (data) => ({
  type: types.GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_LOAD,
  payload: data,
});

export const getPortfolioWeightSuccess = (data) => ({
  type: types.GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_SUCCESS,
  payload: data,
});

export const getPortfolioWeightFailure = (data) => ({
  type: types.GET_PORTFOLIO_WEIGHT_RISK_DSTRBTN_FAIL,
  payload: data,
});

export const updateUploadFilePortFolioAction = (data) => {
  return {
    type: types.UPDATE_UPLOAD_FILE_PORTFOLIO_SUCCESS,
    payload: data,
  };
};

export const resetPortFolioPageDataAction = (data) => {
  return {
    type: types.RESET_PORTFOLIO_DATA_ON_PAGE_LOAD,
  };
};
