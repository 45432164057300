import React, { useEffect } from "react";
import Images from "../../../Themes/ImageConstant";
import { useSearchParams } from "react-router-dom";
import { PasswordInput } from "../../../Componets/PasswordInput";
import { dispatch, navigate } from "../../../Utils/dispatchNavigate";
import { isInternetConnected } from "../../../Utils/InternetConnection";
import {
  resetPasswordEmail,
  verifyMailAction,
} from "../../../Redux/Action/authentication";
import { useFormik } from "formik";
import {
  formikFormat,
  initailValueResetPass,
  resetPasswordSchema,
} from "../../../Utils/schema";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const virefied = useSelector((state) => state.authenticationReducer);
  const notUserFunction = () => { }
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("token");
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    isInternetConnected() &&
      dispatch(
        resetPasswordEmail({ password: values.repeatPassword, token: token })
      );
  };

  const formik = useFormik(
    formikFormat(initailValueResetPass, resetPasswordSchema, onSubmit)
  );
  useEffect(() => {
    dispatch(verifyMailAction({ token: token }));
  }, []);

  return (
    <div className="page-header">
      <div className="left-right-mg">
        <div className="main-div mt-4 mb-4">
          <div className="login-form">
            <div className="login-page-logo">
              <img src={Images.logo} />
            </div>

            <div className="log-side">
              {virefied.chngPassLinkVerify ? (
                <>
                  <div className="login-text">
                    <h5>Change your password </h5>
                  </div>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="input-text">
                      <PasswordInput
                        label="New Password"
                        placeholder="Enter your new password"
                        name="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        error={formik.errors.newPassword}
                        reset={() => formik.setFieldValue("newPassword", "")}
                      />
                    </div>
                    <div className="input-text">
                      <PasswordInput
                        label="Repeat New Password"
                        placeholder="Repeat your new password"
                        name="repeatPassword"
                        value={formik.values.repeatPassword}
                        onChange={formik.handleChange}
                        error={formik.errors.repeatPassword}
                        reset={() => formik.setFieldValue("repeatPassword", "")}
                      />
                    </div>

                    <div className="login-link conf-btn">
                      <button className="login-link">Reset password</button>
                    </div>
                  </form>
                  <div className="login-link conf-btn">
                    <button onClick={() => {
                      navigate("/")
                    }} className="login-link">Log in</button>
                  </div>
                </>
              ) : virefied.passChanged ? (
                <h5
                  style={{ paddingInline: "20%" }}
                  className="verified-um-padding">
                  Password changed successfully.
                </h5>
              ) : (
                <h5 className="verified-um-padding">
                  Tokken has been Expired.
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
