import React, { useState, useEffect } from "react";
import Images from "../../Themes/ImageConstant";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { Collapse } from "antd";
import { Button, Modal } from "antd";
import { Tabs } from "antd";
import { Slider } from "antd";
import { SliderTile } from "../../Componets/SliderTile copy";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";

const { Panel } = Collapse;
const CustomizeScoring = (props) => {
  const [activeNumber, setActiveNumber] = useState("1");
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [val, setVal] = useState(10);

  const marks = {
    0: {
      label: false,
    },
    1: {
      label: false,
    },
    2: {
      label: false,
    },
    3: {
      label: false,
    },
    4: {
      label: false,
    },
    5: {
      label: false,
    },
    6: {
      label: false,
    },
    7: {
      label: false,
    },
    8: {
      label: false,
    },
    9: {
      label: false,
    },
    10: {
      label: false,
    },
  };

  const handleTabClick = (key) => {
    setActiveNumber(key);
    // if (key === "1") {
    //   // Perform your desired action when "ESG Factors" tab is clicked
    //   // You can add your logic here, such as navigating to a different route
    // }
  };

  return (
    <div className="side-padding sodebar-left-padding">
      <div
        style={{ paddingLeft: "8vw", paddingRight: "8vw", paddingTop: "2vw" }}
      >
        <div className="page-size" style={{ width: "830px" }}>
          <div className="breadcrmbs mb-4">
            <Breadcrumb>
              <Breadcrumb.Item key="2" className="inc-link">
                Benita Avi
              </Breadcrumb.Item>
              <Breadcrumb.Item key="3" className="lie-link">
                Customize Scoring
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="paln-and-bill mt-4">
            <div className="plan-heading">
              <h4>Importance Score</h4>
              <p>
                You can assign your default “importance level” for every ESG
                category. Importance scoring on incidents will be sorted
                according to your default settings. You can reset the settings
                anytime and it will be back on Syenah’s AI scoring engine.
              </p>
            </div>

            <Tabs
              onTabClick={handleTabClick}
              activeKey={activeNumber}
              className="customizing-tab-um"
              tabBarExtraContent
            >
              <Tabs.TabPane tab="ESG Factors" key="1">
                <div className="envier-collap mt-4">
                  <Collapse className="customizing-colaps-um">
                    <Panel
                      header="Environment"
                      key="1"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      {/* <div className="slider-low">
                      <div className="ghc-emi">
                        <p>GHG Emissions</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          onChange={(e) => setVal(e * 35)}
                          rootClassName="rootSlider"
                          tooltip={false}
                          marks={marks}
                          dots={true}
                          max={10}
                        />
                        <div
                          className="sliderVal"
                          style={{ left: !val ? 10 : val + 2 }}>
                          5
                        </div>
                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <SliderTile label={"GHG Emissions"} />
                      <br />
                      <SliderTile label={"Air Quality"} />
                      <br />
                      <SliderTile label={"Energy Management"} />

                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Air Quality</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Water & Wastewater Management"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Energy Management</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}

                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Water & Wastewater Management</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile
                        label={"Waste & Hazardous Materials Management"}
                      />

                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Waste & Hazardous Materials Management</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Ecological Impacts"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Ecological Impacts</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                    </Panel>
                  </Collapse>

                  <Collapse className="customizing-colaps-um">
                    <Panel
                      header="Social "
                      key="2"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <SliderTile
                        label={"Human Rights & Community Relations"}
                      />
                      {/* <div className="slider-low">
                      <div className="ghc-emi">
                        <p>Human Rights & Community Relations</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Customer Privacy"} />

                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Customer Privacy</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Data Security"} />

                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Data Security</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}

                      <br />
                      <SliderTile label={"Access & Affordability"} />

                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Access & Affordability</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Product Quality & Safety"} />

                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Product Quality & Safety</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Customer Welfare"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Customer Welfare</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}

                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Selling Practices & Product Labeling</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile
                        label={"Selling Practices & Product Labeling"}
                      />
                      <br />
                      <SliderTile label={"Labor Practices"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Labor Practices</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile
                        label={"Employee Engagement, Diversity & Inclusion"}
                      />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Employee Engagement, Diversity & Inclusion</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Employee Health & Safety"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Employee Health & Safety</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                    </Panel>
                  </Collapse>

                  <Collapse>
                    <Panel
                      header="Governance"
                      key="3"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <SliderTile
                        label={"Product Design & Lifecycle Management"}
                      />
                      {/* 
                    <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Product Design & Lifecycle Management</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile
                        label={"Product Design & Lifecycle Management"}
                      />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Business Model Resilience</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Business Model Resilience"} />
                      <br />
                      <SliderTile label={"Supply Chain Management"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Supply Chain Management</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Materials Sourcing & Efficiency"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Materials Sourcing & Efficiency</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile
                        label={"Physical Impacts of Climate Change"}
                      />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Physical Impacts of Climate Change</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Business Ethics"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Business Ethics</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Competitive Behavior"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Competitive Behavior</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile
                        label={"Management of the Legal & Regulatory Env..."}
                      />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Management of the Legal & Regulatory Env...</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Critical Incident Risk Management"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Critical Incident Risk Management</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                      <br />
                      <SliderTile label={"Systemic Risk Management"} />
                      {/* <div className="slider-low mt-2">
                      <div className="ghc-emi">
                        <p>Systemic Risk Management</p>
                      </div>
                      <div className="custom-slider">
                        <div className="low-text-temp">
                          <p>Low</p>
                        </div>
                        <Slider
                          rootClassName="rootSlider"
                          tooltip={false}
                          handleStyle={{}}
                          marks={marks}
                          dots={true}
                          max={10}
                        />

                        <div className="high-text-temp">
                          <p>High</p>
                        </div>
                      </div>
                    </div> */}
                    </Panel>
                  </Collapse>
                </div>
              </Tabs.TabPane>

         
            </Tabs>

            <div className="reset-btns mt-5">
              <div className="text-rest">
                <Button
                  onClick={() => setModal2Open(true)}
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.background = "#0195ce";
                    e.target.style.color = "#FFFFFF";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.background = "#FFFFFF";
                    e.target.style.color = "#0195ce";
                  }}
                >
                  Reset
                </Button>
                <Modal
                  title={<ModalHeader title={"Reset Settings"} />}
                  className="cross-model"
                  centered
                  closeIcon={<ModalCloseButton />}
                  open={modal2Open}
                  onCancel={() => setModal2Open(false)}
                  footer={[
                    <button
                      className="can-btn"
                      onClick={() => setModal2Open(false)}
                    >
                      Cancel
                    </button>,
                    <button
                      className="rest-btn"
                      onClick={() => setModal2Open(false)}
                    >
                      Reset
                    </button>,
                  ]}
                >
                  <div className="reset-setting">
                    <p>
                      Are you sure you want to reset all “importance score”
                      settings?
                    </p>
                  </div>
                </Modal>
              </div>

              <div className="text-save-btns">
                <button type="button">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomizeScoring;
