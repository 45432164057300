import { makeSocialMediaMapData } from "../../Componets/MapGL/ map_utils";
import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
  socialMediaTagsLoading: false,
  socialMediaTags: {},
  socialMediaTagsSuccess: false,
  tweetAndRepliesData: [],
  tweetAndRepliesDataCount: 0,
  tweetAndRepliesSuccess: false,
  tweetAndRepliesLoader: false,
  individualTweetReplyData: [],
  individualTweetReplyLoader: false,
  individualTweetReplySuccess: false,
  socialMediaReactionData: {},
  socialMediaReactionLoader: false,
  socialMediaReactionSuccess: false,
  overAllReactionSentiment: {},
  overAllReactionSentimentLoader: false,
  overAllReactionSentimentSuccess: false,
  overAllRedditReaction: {},
  overAllRedditReactionLoader: false,
  overAllRedditReactionSuccess: false,
  radardata: {},
  radardataLoader: false,
  radardataSuccess: false,
  totalTweetReaction: {},
  totalTweetReactionLoader: false,
  totalTweetReactionSuccess: false,
  totalRedditReaction: {},
  totalRedditReactionSuccess: false,
  totalRedditReactionLoader: false,
  totalSocialMediaReaction: {},
  totalSocialMediaReactionLoader: false,
  totalSocialMediaReactionSuccess: false,
  redditPostComments: [],
  redditPostCommentsCount: 0,
  redditPostCommentsSuccess: false,
  redditPostCommentsLoader: false,
  redditAllPosts: [],
  redditAllPostsSuccess: false,
  redditAllPostsLoader: false,
  mostInfluentialUser: [],
  mostInfluentialUserSuccess: false,
  mostInfluentialUserLoader: false,
  summaryData: [],
  summaryDataLoader: false,
  summaryDataSuccess: false,
  socialMediaMapData: [],
  continents: [],
};

export const socialMedia = (state = initialState, action) => {
  let { payload, key } = action;
  switch (action.type) {
    case types.GET_SOCIAL_MEDIA_TAGS_LOAD:
      return {
        ...state,
        socialMediaTagsLoading: true,
      };

    case types.GET_SOCIAL_MEDIA_TAGS_SUCCESS:
      return {
        ...state,
        socialMediaTags: payload,
        socialMediaTagsSuccess: true,
        socialMediaTagsLoading: false,
      };

    case types.GET_SOCIAL_MEDIA_TAGS_FAILURE:
      return {
        ...state,
        socialMediaTagsLoading: false,
      };

    case types.GET_TWEET_WITH_REPLIES_LOAD:
      return {
        ...state,
        tweetAndRepliesLoader: true,
      };

    case types.GET_TWEET_WITH_REPLIES_SUCCESS:
      return {
        ...state,
        tweetAndRepliesData: payload.data,
        tweetAndRepliesDataCount: payload.totalCount,
        tweetAndRepliesSuccess: true,
        tweetAndRepliesLoader: false,
      };

    case types.GET_TWEET_WITH_REPLIES_FAILURE:
      return {
        ...state,
        tweetAndRepliesLoader: false,
      };

    case types.GET_TWEET_INDIVIDUAL_REPLY_LOAD:
      return {
        ...state,
        individualTweetReplyLoader: true,
      };

    case types.GET_TWEET_INDIVIDUAL_REPLY_SUCCESS:
      return {
        ...state,
        individualTweetReplyData: payload,
        individualTweetReplySuccess: true,
        individualTweetReplyLoader: false,
      };

    case types.GET_TWEET_INDIVIDUAL_REPLY_FAILURE:
      return {
        ...state,
        individualTweetReplyLoader: false,
      };

    case types.GET_SOCIAL_MEDIA_REACTIONS_LOAD:
      return {
        ...state,
        socialMediaReactionLoader: true,
      };

    case types.GET_SOCIAL_MEDIA_REACTIONS_SUCCESS:
      return {
        ...state,
        socialMediaReactionData: payload,
        socialMediaReactionSuccess: true,
        socialMediaReactionLoader: false,
      };

    case types.GET_SOCIAL_MEDIA_REACTIONS_FAILURE:
      return {
        ...state,
        socialMediaReactionLoader: false,
      };

    case types.GET_OVERALL_REACTION_SENTIMENT_LOAD:
      return {
        ...state,
        overAllReactionSentimentLoader: true,
      };

    case types.GET_OVERALL_REACTION_SENTIMENT_SUCCESS:
      return {
        ...state,
        overAllReactionSentiment: payload,
        overAllReactionSentimentSuccess: true,
        overAllReactionSentimentLoader: false,
      };

    case types.GET_OVERALL_REACTION_SENTIMENT_FAILURE:
      return {
        ...state,
        overAllReactionSentimentLoader: false,
      };

    case types.GET_OVERALL_REDDIT_REACTION_LOAD:
      return {
        ...state,
        overAllRedditReactionLoader: true,
      };

    case types.GET_OVERALL_REDDIT_REACTION_SUCCESS:
      return {
        ...state,
        overAllRedditReaction: payload,
        overAllRedditReactionSuccess: true,
        overAllRedditReactionLoader: false,
      };

    case types.GET_OVERALL_REDDIT_REACTION_FAILURE:
      return {
        ...state,
        overAllRedditReactionLoader: false,
      };

    case types.GET_RADAR_DATA_LOAD:
      return {
        ...state,
        radardataLoader: true,
      };

    case types.GET_RADAR_DATA_SUCCESS:
      return {
        ...state,
        radardata: payload,
        radardataSuccess: true,
        radardataLoader: false,
      };

    case types.GET_RADAR_DATA_FAILURE:
      return {
        ...state,
        radardataLoader: false,
      };

    case types.TOTAL_TWEET_REACTION_LOAD:
      return {
        ...state,
        totalTweetReactionLoader: true,
      };

    case types.TOTAL_TWEET_REACTION_SUCCESS:
      return {
        ...state,
        totalTweetReaction: payload,
        totalTweetReactionSuccess: true,
        totalTweetReactionLoader: false,
      };

    case types.TOTAL_TWEET_REACTION_FAILURE:
      return {
        ...state,
        totalTweetReactionLoader: false,
      };

    case types.TOTAL_REDDIT_REACTION_LOAD:
      return {
        ...state,
        totalRedditReactionLoader: true,
      };

    case types.TOTAL_REDDIT_REACTION_SUCCESS:
      return {
        ...state,
        totalRedditReaction: payload,
        totalRedditReactionSuccess: true,
        totalRedditReactionLoader: false,
      };

    case types.TOTAL_REDDIT_REACTION_FAILURE:
      return {
        ...state,
        totalRedditReactionLoader: false,
      };

    case types.TOTAL_SOCIAL_MEDIA_REACTION_LOAD:
      return {
        ...state,
        totalSocialMediaReactionLoader: true,
      };

    case types.TOTAL_SOCIAL_MEDIA_REACTION_SUCCESS:
      return {
        ...state,
        totalSocialMediaReaction: payload,
        totalSocialMediaReactionSuccess: true,
        totalSocialMediaReactionLoader: false,
      };

    case types.TOTAL_SOCIAL_MEDIA_REACTION_FAILURE:
      return {
        ...state,
        totalSocialMediaReactionLoader: false,
      };

    case types.GET_REDDIT_WITH_POST_LOAD:
      return {
        ...state,
        redditAllPostsLoader: true,
      };

    case types.GET_REDDIT_WITH_POST_SUCCESS:
      return {
        ...state,
        redditAllPosts: payload,
        redditAllPostsSuccess: true,
        redditAllPostsLoader: false,
      };
    case types.GET_REDDIT_WITH_POST_FAIL:
      return {
        ...state,
        redditAllPostsLoader: false,
      };

    case types.GET_REDDIT_POST_COMMENT_LOAD:
      return {
        ...state,
        redditPostCommentsLoader: true,
      };

    case types.GET_REDDIT_POST_COMMENT_SUCCESS:
      return {
        ...state,
        redditPostComments: payload.data,
        redditPostCommentsCount: payload.totalCount,
        redditPostCommentsSuccess: true,
        redditPostCommentsLoader: false,
      };
    case types.GET_REDDIT_POST_COMMENT_FAIL:
      return {
        ...state,
        redditPostCommentsLoader: false,
      };

    case types.GET_MOST_INFLUENTIAL_USER_LOAD:
      return {
        ...state,
        mostInfluentialUserLoader: true,
      };

    case types.GET_MOST_INFLUENTIAL_USER_SUCCESS:
      return {
        ...state,
        mostInfluentialUserSuccess: true,
        mostInfluentialUser: payload,
        mostInfluentialUserLoader: false,
      };
    case types.GET_MOST_INFLUENTIAL_USER_FAIL:
      return {
        ...state,
        mostInfluentialUserLoader: false,
      };

    case types.GET_SUMMARY_DATA_LOAD:
      return {
        ...state,
        summaryDataLoader: true,
      };

    case types.GET_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        summaryData: payload,
        summaryDataSuccess: true,
        summaryDataLoader: false,
      };
    case types.GET_SUMMARY_DATA_FAIL:
      return {
        ...state,
        summaryDataLoader: false,
      };

    case types.UPDATE_SOCIAL_MEDIA_STATE:
      state[key] = payload;
      return { ...state };
    case types.SOCIAL_MEDIA_MAP_SUCCESS:
      const data = makeSocialMediaMapData(payload?.sentiments);
      return {
        ...state,
        socialMediaMapData: data,
        continents: payload?.continents,
      };

    default:
      return { ...state };
  }
};
