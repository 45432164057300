import React from "react";
import { Icon, Menu, Typography, useTheme } from "@mui/material";
import { NestedMenuItem, IconMenuItem } from "mui-nested-menu";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandIcon from "./icons/ExpandIcon";
import MagicWandIcon from "./icons/MagicWandIcon";
import FindPathIcon from "./icons/FindPathIcon";
import CompanyDetailsIcon from "./icons/CompanyDetailsIcon";
import TrashIcon from "./icons/TrashIcon";
import {
  resetRightClickMenu,
  setRightClickMenuSideBarNodeId,
  setShowCompanyActionSideBar,
  setShowCompanyInfoSideBar,
  setShowPathFindingModal,
} from "../../redux/GraphExploration/rightClickMenuSlice";
import ExpandSubMenu from "./ExpandSubMenu";
import ActionSubMenu from "./ActionSubMenu";
import { useGraphObject } from "../context/MyContext";
import { toast } from "react-toastify";

function GraphRightClickMenu() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const graphRightClickMenuLocation = useAppSelector(
    (state) => state.rightClickMenu.location
  );
  const graphRightClickMenuShow = useAppSelector(
    (state) => state.rightClickMenu.show
  );
  const showFilterSideBar = useAppSelector(
    (state) => state.filterSideBar.showFilterSideBar
  );
  const targetNodeId = useAppSelector(
    (state) => state.rightClickMenu.targetNodeId
  );
  const { graphObject } = useGraphObject();

  const menuItemHeight = 48;
  const menuItemWidth = 216;
  const deleteNode = () => {
    const selectedNodesIds = [targetNodeId];
    const connectingEdges = graphObject
      ?.getAllEdgesData()
      .filter(
        (edge) =>
          selectedNodesIds.includes(edge.source) ||
          selectedNodesIds.includes(edge.target)
      );
    const affectedEdgesIds = Array.from(
      new Set([...(connectingEdges?.map((e) => e.id) || [])])
    );
    graphObject?.startHistoryBatch();
    if (affectedEdgesIds.length > 0) {
      graphObject?.removeData("edge", affectedEdgesIds);
    }
    if (selectedNodesIds && selectedNodesIds.length > 0) {
      graphObject?.removeData("node", selectedNodesIds);
    }
    graphObject?.stopHistoryBatch();
    dispatch(resetRightClickMenu());
  };

  const listItems = [
    {
      icon: () => <ExpandIcon fontSize="small" />,
      label: "Expand",
      hasChild: true,
    },
    {
      icon: () => <MagicWandIcon fontSize="small" />,
      label: "Actions",
      hasChild: true,
    },
    {
      icon: () => <FindPathIcon fontSize="small" />,
      label: "Find Path",
      hasChild: false,
      onClick: () => {
        dispatch(setShowPathFindingModal(true));
        dispatch(resetRightClickMenu());
      },
    },
    {
      icon: () => <CompanyDetailsIcon fontSize="small" />,
      label: "Company details",
      hasChild: false,
      onClick: () => {
        dispatch(setShowCompanyInfoSideBar(true));
        dispatch(setShowCompanyActionSideBar(false));
        dispatch(setRightClickMenuSideBarNodeId(targetNodeId));
        dispatch(resetRightClickMenu());
      },
    },
    {
      icon: () => <TrashIcon fontSize="small" />,
      label: "Delete node from graph",
      hasChild: false,
      onClick: deleteNode,
    },
  ];

  const getProperSubMenu = (item) => {
    if (item.label === "Expand") {
      return <ExpandSubMenu />;
    }
    if (item.label === "Actions") {
      return <ActionSubMenu />;
    }
  };

  if (graphRightClickMenuShow && showFilterSideBar) {
    // show it bottom center
    toast.warn("Please first 'Apply' or 'Cancel' your filters.", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    dispatch(resetRightClickMenu());
    return null;
  }

  return graphRightClickMenuShow ? (
    <div>
      <Menu
        sx={{
          ".MuiMenu-list": {
            paddingTop: 0,
            paddingBottom: 0,
          },
          ".MuiMenu-paper": {
            borderRadius: "4px",
          },
        }}
        open={graphRightClickMenuShow}
        anchorReference="anchorPosition"
        onClose={() => {
          dispatch(resetRightClickMenu());
        }}
        anchorPosition={
          graphRightClickMenuShow
            ? {
                top: graphRightClickMenuLocation?.y + 3,
                left: graphRightClickMenuLocation?.x + 3,
              }
            : undefined
        }
      >
        {listItems.map((item) =>
          item.hasChild ? (
            <NestedMenuItem
              key={item.label}
              leftIcon={item.icon()}
              rightIcon={
                item.hasChild && (
                  <Icon
                    sx={{ height: "unset", color: theme.palette.gray[200] }}
                    fontSize="small"
                  >
                    <ChevronRightIcon />
                  </Icon>
                )
              }
              label={
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: theme.palette.gray[800],
                  }}
                >
                  {item.label}
                </Typography>
              }
              sx={{
                "@media (min-width: 600px)": {
                  height: `${menuItemHeight}px`,
                  borderBottom: `1px solid ${theme.palette.gray[50]}`,
                  width: `${menuItemWidth}px`,
                  paddingX: 1,
                },
              }}
              parentMenuOpen={graphRightClickMenuShow}
            >
              {getProperSubMenu(item)}
            </NestedMenuItem>
          ) : (
            <IconMenuItem
              key={item.label}
              leftIcon={item.icon()}
              onClick={item.onClick}
              label={
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: theme.palette.gray[800],
                  }}
                >
                  {item.label}
                </Typography>
              }
              sx={{
                "@media (min-width: 600px)": {
                  height: `${menuItemHeight}px`,
                  borderBottom: `1px solid ${theme.palette.gray[50]}`,
                  width: `${menuItemWidth}px`,
                  paddingX: 1,
                },
              }}
            />
          )
        )}
      </Menu>
    </div>
  ) : (
    <div />
  );
}

export default GraphRightClickMenu;
