import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import { setShowBadgeSelector } from "../../../redux/GraphExploration/toolBoxSlice";
import { useGraphObject } from "../../context/MyContext";
import { LayoutEnum } from "../../../enums/LayoutsEnum";
import {
  setBadge,
  setLayout,
} from "../../../redux/GraphExploration/graphSlice";
import { BadgeEnum } from "../../../enums/BadgeEnum";
import BadgeItem from "./BadgeItem";
import { getBadge } from "../../../utils";

const badgeTypes = [
  {
    value: BadgeEnum.INCIDENT_COUNT,
  },
  {
    value: BadgeEnum.RISK_SCORE,
  },
  {
    value: BadgeEnum.CONNECTION_COUNT,
  },
];

function BadgeSelectorPopOver({ anchorEl, setAnchorEl }) {
  const nodes = useAppSelector((state) => state.graph.nodes);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setShowBadgeSelector(false));
  };

  const selectedBadge = useAppSelector((state) => state.graph.badge);
  const { graphObject } = useGraphObject();
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      elevation={0}
      sx={{
        transform: "translateX(-13px) translateY(-4px)", // Adjust this value to set the offset
        "& .MuiPopover-paper": {
          padding: "12px",
          borderRadius: "6px",
          boxShadow: "0px 8px 12px 1px #0000001A, 0px -2px 8px 0px #0000001A",
        },
      }}
    >
      <Stack divider={<Divider />}>
        {badgeTypes.map((item) => {
          const isSelected = selectedBadge === item.value;
          const disabled = item.value === BadgeEnum.CONNECTION_COUNT;
          return (
            <BadgeItem
              key={item.value}
              label={item.value}
              isSelected={isSelected}
              sx={{
                cursor: disabled ? "default" : "pointer",
                width: "240px",
                padding: "8px",
                color: (theme) =>
                  disabled ? theme.palette.gray[100] : theme.palette.gray[800],
                transition: "background-color 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: (theme) =>
                    disabled ? "transparent" : theme.palette.gray["A100"],
                },
              }}
              onClick={() => {
                if (disabled) {
                  return;
                }
                dispatch(setBadge(item.value));
                handleClose();
                const updateNodes = nodes.map((node) => {
                  return {
                    id: node.id,
                    data: {
                      ...node.data,
                      badge: getBadge(node.data),
                    },
                  };
                });
                graphObject.updateData("node", updateNodes);
              }}
            />
          );
        })}
      </Stack>
    </Popover>
  );
}

export default BadgeSelectorPopOver;
