import { MenuItem, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import {
  resetRightClickMenu,
  setShowCompanyActionSideBar,
  setCurrentAction,
  setRightClickMenuSideBarNodeId,
  setShowCompanyInfoSideBar,
} from "../../../redux/GraphExploration/rightClickMenuSlice";

function ActionSubMenu() {
  const items = [
    "Top 5 riskiest connections",
    "Top 5 with most incidents",
    "Top 5 with most connections",
    // "The riskiest path",
  ];
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const targetNodeId = useAppSelector(
    (state) => state.rightClickMenu.targetNodeId
  );
  return (
    <div>
      {items.map((item, index, array) => (
        <MenuItem
          key={item}
          sx={{
            height: `40px`,
            borderBottom:
              index !== array.length - 1
                ? `1px solid ${theme.palette.gray[50]}`
                : "none",
            paddingX: 1,
            width: 216,
          }}
          onClick={() => {
            dispatch(setCurrentAction(item));
            dispatch(setShowCompanyActionSideBar(true));
            dispatch(setShowCompanyInfoSideBar(false));
            dispatch(resetRightClickMenu());
            dispatch(setRightClickMenuSideBarNodeId(targetNodeId));
          }}
        >
          <Typography variant="captionXL">{item}</Typography>
        </MenuItem>
      ))}
    </div>
  );
}

export default ActionSubMenu;
