const ModalHeader = ({ title , padding, height  }) => {
  return (
    <div style={{ display: "flex", justifyContent: "start", height: height ? "auto" : "26px",  padding : padding ? "20px 24px 20px 2px" : "0px" }}>
      {title}
    </div>
  );
};

export default ModalHeader;

