import React from "react";
import Images from "../../../Themes/ImageConstant";
import { dispatch } from "../../../Utils/dispatchNavigate";
import { useSearchParams } from "react-router-dom";
import { verifiedEmailAction } from "../../../Redux/Action/authentication";
import { useSelector } from "react-redux";

const MailVerified = () => {
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("token");

  const virefied = useSelector((state) => state.authenticationReducer);

  React.useEffect(() => {
    dispatch(verifiedEmailAction({ token }));
  }, []);

  return (
    <div className="page-header confirm-mk">
      <div className="main-div">
        <div className="login-form">
          <div className="login-page-logo">
            <img src={Images.logo} />
          </div>
          <div
            style={{ paddingInline: "200px" }}
            className="em-add log-side login-text new-col verified-um-padding">
            <img src={Images.envelop} />
            {virefied?.isExpired ? (
              <h5>Email verified successfully.</h5>
            ) : (
              <h5>Email is already verified.</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailVerified;
