import { Col, Modal } from "antd";
import React from "react";
import { modalHide } from "../../../Redux/Action/global";
import { useDispatch } from "react-redux";

const PopUp = ({ show, title, CANCEL, children }) => {
    const dispatch = useDispatch()
  return (
    <Modal
      title={title}
      open={show}
      onCancel={() => {
        CANCEL?.onClick();
        dispatch(modalHide());
      }}
      footer={null}
      closable={false}
      width={400}
      style={{
        textAlign: "center",
        fontSize: 20,
        borderRadius: 40,
        content: {
          backdropFilter: "blur(0px)",
        },
      }}
      centered
      maskClosable={true}
    >
      <Col>{children}</Col>
    </Modal>
  );
};

export default PopUp;
