import { apiConstants as types } from "../../Constants/apiConstants";

export const getIncidentAnalyzerBreaches = (data) => ({
  type: types.API_GET_INCIDENT_ANALYZER_BREACHES_LOAD,
  data,
});

export const incidentAnalyzerSocialMediaCoverageAction = (data) => ({
  type: types.API_GET_INCIDENT_ANALYZER_SOCIAL_MEDIA_COVERAGE_LOAD,
  data,
});

export const incidentByCategoryAction = (data) => ({
  type: types.INCIDENT_BY_CATEGORY_LOAD,
  data,
});

export const getDashboardAction = (data) => ({
  type: types.API_GET_DASHBOARD_LOAD,
  data,
});

export const getDashboardWatchlistAction = (data) => ({
  type: types.API_GET_DASHBOARD_WATCH_LOAD,
  data,
});

export const getBreachesViolationDataLoad = (data) => ({
  type: types.GET_BREACHES_VIOLATION_DATA_LOAD,
  payload: data
})

export const getBreachesViolationDataSuccess = (data) => ({
  type: types.GET_BREACHES_VIOLATION_DATA_SUCCESS,
  payload: data
})

export const getBreachesViolationDataFail = (data) => ({
  type: types.GET_BREACHES_VIOLATION_DATA_FAIL,
  payload: data
})