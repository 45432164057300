import React, { useState, useEffect, useMemo, useCallback } from "react";
import Images from "../../Themes/ImageConstant";
import { Link, useLocation } from "react-router-dom";
import { Select } from "antd";
import { Breadcrumb } from "antd";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesAction,
  getDownloadDataAction,
  getEsgCountriesAction,
  getEsgFactorsAction,
  incidentDetailAction,
  incindentAction,
} from "../../Redux/Action/incidents";
import CustomPagination from "../../Componets/Pagination";
import CustomTable from "./components/table.component";
import Dropdown from "./components/dropdown";
import {
  checkColor,
  getColorForTable,
  getColorForTableText,
} from "../../Utils";
import { navigate } from "../../Utils/dispatchNavigate";
import TableColumn from "../../Componets/TableColumn";
import Search from "./components/search";
import { incidentFilter } from "../../Utils/globalFunction";
import ImportanceScoringPopup from "../../Componets/importanceScoringPopup";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import moment from "moment";

const tableColumn = [
  "Severity",
  "Involved Companies",
  "ESG Factors",
  "Locations",
  "Date",
  "Name",
];

var incidentSourceTrueOption = [];
const IncidentsList = (props) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const IPLData = useSelector((state) => state?.incidentReducer?.incident);
  const [incidentSource, setIncidentSource] = useState({
    all: false,
    newsArticles: false,
    NGOReports: false,
    twitter: false,
  });
  const [openDrop, setOpenDrop] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  let networkData = {
    companies: props?.companiesId?.length > 0 ? [props?.companiesId] : [],
  };

  const [paginationData, setPaginationData] = useState({
    offset: 0,
    limit: 10,
  });

  const [tableHeaderArray, setTableHeaderArray] = useState(tableColumn);

  const esgFactorList = useSelector(
    (state) => state?.incidentReducer?.esgFactorList
  );

  const [esgFactor, setEsgFactorList] = useState([]);
  let simpleApiPayload = { ...topHeaderAPIObject, ...props.date };
  useEffect(() => {
    dispatch(getEsgCountriesAction());
    dispatch(getEsgFactorsAction());
    dispatch(getCompaniesAction());
    localStorage.removeItem("location");
    localStorage.removeItem("company");
    localStorage.removeItem("ESG_factor");
    return () => {};
  }, []);

  useEffect(() => {
    if (props?.goTo == "/incidentdetails") {
      dispatch(incindentAction(networkData));
    } else if (props?.goTo == "/company") {
      dispatch(incindentAction({ ...networkData, ...props.date }));
    } else {
      dispatch(
        incindentAction({ ...simpleApiPayload, ...props.payloadRootLevel })
      );
    }
  }, []);

  useEffect(() => {
    if (esgFactorList?.length > 0) {
      setEsgFactorList(esgFactorList);
    }
  }, [esgFactorList]);

  const onNameClick = (item) => {
    if (pathname == "/incidentdetails") {
      dispatch(incidentDetailAction(item?.id));
      props.setModal((prv) => !prv);
    } else {
      // navigate("/incidentdetails", {
      //   state: { id: item.id },
      // });
      window.open(`/incidentdetails?${item.id}`, "_black");
    }
  };

  const handleIncidentFilter = (data) => {
    let Data = {
      ...data,
      start: props?.date?.start,
      end: props?.date?.end,
    };
    incidentFilter(Data, dispatch);
  };

  const handleIncidentSources = (data) => {
    const updateIncidentSource = { ...incidentSource };
    for (const source in updateIncidentSource) {
      if (data == "all") {
        updateIncidentSource[source] = true;
      } else {
        if (source == "all") {
          updateIncidentSource["all"] =
            source === data ? !updateIncidentSource[source] : false;
        }
      }
    }
    if (data !== "all") {
      updateIncidentSource[data] = !updateIncidentSource[data];
    }
    setIncidentSource(updateIncidentSource);

    if (data === "all") {
      incidentSourceTrueOption = ["newsArticles", "NGOReports", "twitter"];
    } else {
      incidentSourceTrueOption = [];
      for (const source in updateIncidentSource) {
        if (updateIncidentSource[source] && source !== "all") {
          incidentSourceTrueOption.push(source);
        }
      }
    }
    let Data = {
      sources: incidentSourceTrueOption,
      start: props?.date?.start,
      end: props?.date?.end,
    };
    incidentFilter(Data, dispatch);
  };

  const columns = useCallback(
    () => [
      {
        title: <div style={{ textAlign: "left", fontWeight: 600 }}>Name</div>,
        // title: "Name",
        dataIndex: "title",
        width: 300,
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Name") ? "displayNone" : ""
        }`,
        render: (_, item, index) => (
          <div
            onClick={() => onNameClick(item)}
            style={{
              height: "96px",
              fontWeight: 600,
              cursor: "pointer",
              display: "flex",
              marginLeft: "14px",
              // justifyContent:"center"
              alignItems: "flex-start",
            }}
          >
            <span style={{ color: "#9A9CA2" }}>
              {index + paginationData.offset + 1}
            </span>
            &nbsp;&nbsp;
            <span className="incidentTextHover">{item.title}</span>
          </div>
        ),
      },
      {
        title: (
          <div style={{ textAlign: "left", fontWeight: 600 }}>Severity</div>
        ),
        dataIndex: "severity",
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Severity") ? "displayNone" : ""
        }`,
        sorter: () => {},
        ellipsis: true,
        width: 100,
        render: (_, item) => (
          <div
            style={{
              height: "92px",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: checkColor[item.severity],
                width: "35%",
                height: "32px",
              }}
            >
              <p style={{ color: "white", width: "fit-content" }}>
                {item?.severity_score.category}
              </p>
            </div>
          </div>
        ),
      },
      {
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Involved Companies") ? "displayNone" : ""
        }`,
        width: 250,
        title: (
          <Dropdown
            type={1}
            width={200}
            handleChange={handleIncidentFilter}
            multiple={true}
            placeholder="Involved Companies"
          />
        ),
        dataIndex: "involvedCompanies",
        render: (_, item) => {
          return (
            <div
              style={{
                height: "92px",
                display: "flex",
                alignItems: "flex-start",
                // justifyContent: "center",
              }}
            >
              {item?.affected_companies?.slice(0, 3).map((data) => (
                <p>{data?.name}</p>
              ))}
            </div>
          );
        },
      },
      {
        className: `company-supplyChain ${
          !tableHeaderArray.includes("ESG Factors") ? "displayNone" : ""
        }`,
        width: 250,
        title: (
          <Dropdown
            type={2}
            width={200}
            handleChange={handleIncidentFilter}
            multiple={true}
            placeholder="ESG Factors"
          />
        ),
        dataIndex: "ESGFactors",
        ellipsis: {
          showTitle: false,
        },
        render: (_, item) => {
          return item?.esg_factors?.slice(0, 3)?.map((data) =>
            data?.length === 0 ? (
              <p>"N/A</p>
            ) : (
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div className="air-que">
                  <h5
                    style={{
                      backgroundColor: getColorForTable(
                        data?.esg_category?.name
                      ),
                      color: getColorForTableText(data?.esg_category?.name),
                    }}
                  >
                    {data.esg_category?.name
                      ? data.esg_category?.name?.charAt(0)
                      : ""}
                  </h5>
                  <Tooltip placement="top" title={<span>{data?.name}</span>}>
                    <p
                      style={{
                        color: getColorForTableText(data?.esg_category?.name),
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.name}
                    </p>
                  </Tooltip>
                </div>
              </div>
            )
          );
        },
      },
      {
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Locations") ? "displayNone" : ""
        }`,
        width: 250,
        title: (
          <Dropdown
            type={3}
            width={200}
            handleChange={handleIncidentFilter}
            multiple={true}
            placeholder="Locations"
          />
        ),
        dataIndex: "Locations",
        render: (_, item) => (
          <div
            style={{
              height: "92px",
              display: "flex",
            }}
          >
            {item.locations.map((item) => item.name).join(", ")}
          </div>
        ),
      },
      {
        className: `company-supplyChain ${
          !tableHeaderArray.includes("Date") ? "displayNone" : ""
        }`,
        width: 65,
        title: "Date",
        dataIndex: "date",
        sorter: () => {},
        render: (_, item) => (
          <div
            style={{
              height: "92px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {moment(item?.discovery_time).fromNow()}
          </div>
        ),
      },
    ],
    [tableHeaderArray, paginationData]
  );

  const handleDropdownVisibleChange = (open) => {
    setIsOpen(open);
    setOpenDrop(!openDrop);
  };

  const selectArrowIcon = isOpen ? (
    <img src={Images.upArrowList} />
  ) : (
    <img src={Images.downArrowList} />
  );

  const callDownloadApi = () => {
    dispatch(getDownloadDataAction());
  };

  const [setingPopupShow, setSetingPopupShow] = useState(false);
  const paginationPayloadData = {
    ...props.apiPayload,
    ...props.date,
    companies: props?.companiesId?.length > 0 ? [props?.companiesId] : [],
    ...props.payloadRootLevel,
  };
  return (
    <div className="side-padding list">
      <div className="main-layout table-layout">
        <section className="bg-sec mb-4">
          <div className="breadcrmbs mb-4">
            <Breadcrumb>
              <Breadcrumb.Item
                key="1"
                className="bad-link"
                onClick={() => props?.isComeFromModal && props.setModal(false)}
              >
                <Link to={props.goTo ? props.goTo : "/home"}>
                  <img src={Images.left} />
                  Back
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item key="2" className="inc-link">
                Incidents
              </Breadcrumb.Item>
              <Breadcrumb.Item key="3" className="lie-link">
                List
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="container">
            <div className="table-page-sec">
              <div
                onMouseEnter={() => setSetingPopupShow(true)}
                onMouseLeave={() => setSetingPopupShow(false)}
                className="top-info-section"
                style={{ position: "relative" }}
              >
                <p className="mb-3">
                  <img className="me-2" src={Images.circleInfo} />
                  Importance Scoring
                </p>
                {setingPopupShow && (
                  <div
                    style={{
                      width: "20vw",
                      position: "absolute",
                      backgroundColor: "#ffffff",
                      zIndex: "10",
                    }}
                  >
                    <ImportanceScoringPopup />
                  </div>
                )}
              </div>
              <div className="topp-div">
                <div className="table-head-sec">
                  <Search
                    action={incindentAction}
                    cb={(search) => setSearch(search)}
                    obj={paginationPayloadData}
                  />
                  <div className="inci-sour-drop">
                    <Select
                      defaultValue="Incident Source"
                      style={{ width: 150 }}
                      value={"Incident Source"}
                      open={openDrop}
                      onDropdownVisibleChange={handleDropdownVisibleChange}
                      suffixIcon={selectArrowIcon}
                    >
                      <Select.Option value="all">
                        <div
                          onClick={() => handleIncidentSources("all")}
                          className="check-box"
                        >
                          <input checked={incidentSource.all} type="checkbox" />
                          <p>All</p>
                        </div>
                      </Select.Option>
                      <Select.Option value="news">
                        <div
                          onClick={() => handleIncidentSources("newsArticles")}
                          className="check-box"
                        >
                          <input
                            checked={incidentSource.newsArticles}
                            type="checkbox"
                          />
                          <p>News Articles</p>
                        </div>
                      </Select.Option>
                      <Select.Option value="ngo">
                        <div
                          onClick={() => handleIncidentSources("NGOReports")}
                          className="check-box"
                        >
                          <input
                            checked={incidentSource.NGOReports}
                            type="checkbox"
                          />
                          <p>NGO Reports</p>
                        </div>
                      </Select.Option>
                      <Select.Option value="twitter">
                        <div
                          onClick={() => handleIncidentSources("twitter")}
                          className="check-box"
                        >
                          <input
                            checked={incidentSource.twitter}
                            type="checkbox"
                          />
                          <p>Twitter</p>
                        </div>
                      </Select.Option>
                    </Select>
                  </div>
                </div>

                <div className="right-side-colm">
                  <Tooltip placement="top" title={"Download"}>
                    <div className="downl-btn" style={{ height: "40px" }}>
                      <img
                        onClick={() => callDownloadApi()}
                        src={Images.framedow}
                        style={{ height: "100%" }}
                      />
                    </div>
                  </Tooltip>
                  {/* TABLE COLUMN */}
                  <TableColumn
                    tableHeader={tableColumn}
                    cb={(arr) => setTableHeaderArray([...arr])}
                  />
                </div>
              </div>
              <div className="table-box incident">
                <CustomTable dataSource={IPLData?.data} columns={columns()} />
              </div>
              <CustomPagination
                pagination={IPLData.pagination}
                action={incindentAction}
                data={paginationPayloadData}
                cb={(pagination) => {
                  setPaginationData(pagination);
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default IncidentsList;
