import { Tooltip, tooltipClasses, styled } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.gray['700'],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.gray['700'],
    borderRadius: 5,
  },
}));
export default CustomTooltip;
