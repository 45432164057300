import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import {
  setIsGroupNode,
  setShowLayoutSelector,
} from "../../../redux/GraphExploration/toolBoxSlice";
import ForceLayoutIcon from "./ForceLayoutIcon";
import HierarchicalLayoutIcon from "./HierarchicalLayoutIcon";
import RadialLayoutIcon from "./RadialLayoutIcon";
import GridLayoutIcon from "./GridLayoutIcon";
import CoCentricLayoutIcon from "./CoCentricLayoutIcon";
import LayoutItem from "./LayoutItem";
import { useGraphObject } from "../../context/MyContext";
import { LayoutEnum } from "../../../enums/LayoutsEnum";
import { setLayout } from "../../../redux/GraphExploration/graphSlice";
import { removeCombos } from "../../../utils";

const layouts = [
  {
    icon: (isSelected) => <ForceLayoutIcon selected={isSelected} />,
    value: LayoutEnum.FORCE_LAYOUT,
    layoutOptions: {
      type: "force",
      preventOverlap: true,
      workerEnabled: true,
    },
  },
  {
    icon: (isSelected) => <RadialLayoutIcon selected={isSelected} />,
    value: LayoutEnum.RADIAL_LAYOUT,
    layoutOptions: {
      type: "radial",
      maxIteration: 5000,
      preventOverlap: true,
      nodeSpacing: 100,
    },
  },
  {
    icon: (isSelected) => <GridLayoutIcon selected={isSelected} />,
    value: LayoutEnum.GRID_LAYOT,
    layoutOptions: {
      type: "grid",
      sortBy: "sort",
    },
  },
  {
    icon: (isSelected) => <CoCentricLayoutIcon selected={isSelected} />,
    value: LayoutEnum.CIRCULAR_LAYOUT,
    layoutOptions: {
      type: "circular",
      ordering: "degree",
      workerEnabled: true,
    },
  },
  {
    icon: (isSelected) => <HierarchicalLayoutIcon selected={isSelected} />,
    value: LayoutEnum.HIERARCHICAL_LAYOUT,
    layoutOptions: {
      type: "mindmap",
      getHGap: () => 500,
      getSide: () => "left",
    },
  },
];

function LayoutSelectorPopOver({ anchorEl, setAnchorEl }) {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setShowLayoutSelector(false));
  };

  // const [selectedLayout, setSelectedLayout] = useState(LayoutEnum.FORCE_LAYOUT);
  const selectedLayout = useAppSelector((state) => state.graph.layout);
  const { graphObject } = useGraphObject();
  const isGroupNode = useAppSelector(
    (state) => state.toolBox.toolBox.isGroupNode
  );
  const nodes = useAppSelector((state) => state.graph.nodes);
  const edges = useAppSelector((state) => state.graph.edges);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      elevation={0}
      sx={{
        transform: "translateX(-13px) translateY(-4px)", // Adjust this value to set the offset
        "& .MuiPopover-paper": {
          padding: "12px",
          borderRadius: "6px",
          boxShadow: "0px 8px 12px 1px #0000001A, 0px -2px 8px 0px #0000001A",
        },
      }}
    >
      <Stack divider={<Divider />}>
        {layouts.map((item) => {
          const isSelected = selectedLayout === item.value;
          return (
            <LayoutItem
              key={item.value}
              icon={item.icon(isSelected)}
              label={item.value}
              isSelected={isSelected}
              sx={{
                cursor: "pointer",
                width: "240px",
                padding: "16px 8px",
                color: (theme) => theme.palette.gray[800],
                transition: "background-color 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.gray["A100"],
                },
              }}
              onClick={() => {
                handleClose();
                const updateEdges = graphObject
                  .getAllEdgesData()
                  .map((edge) => ({
                    id: edge.id,
                    data: {
                      ...edge.data,
                      type:
                        item.value === LayoutEnum.HIERARCHICAL_LAYOUT
                          ? "cubic-horizontal-edge"
                          : "line-edge",
                    },
                  }));
                graphObject.updateData("edge", updateEdges);
                dispatch(setLayout(item.value));
                graphObject.layout(item.layoutOptions);
                if (isGroupNode) {
                  dispatch(setIsGroupNode(false));
                  removeCombos(nodes, edges, graphObject);
                }
              }}
            />
          );
        })}
      </Stack>
    </Popover>
  );
}

export default LayoutSelectorPopOver;
