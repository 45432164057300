import React, { useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { ChartCard } from "../../../../Layouts/ChartCard";
import { ChartBottomTitle } from "../../../../Componets/ChartBottomTitle";
import Images from "../../../../Themes/ImageConstant";
import style from "./style.module.css";
import { ESG } from "../../../../Utils";

const treeLegend = [
  { name: "Environment", img: Images.ellipseGreen },
  { name: "Social", img: Images.ellipseblue },
  { name: "Governance", img: Images.ellipsebng },
];

const TreeOption = (color, title) => ({
  legend: {
    show: false,
  },
  chart: {
    height: 350,
    type: "treemap",
    toolbar: {
      show: false,
    },
  },
  title: {
    text: title,
    style: {
      fontSize: "16px",
      fontWeight: "900",
      fontFamily: "Inter",
      color: color,
    },
  },

  dataLabels: {
    show: false,
    enabled: true,
     
    formatter: function(name,incidents) {
      return [name,incidents.value]
    },
  },

  plotOptions: {
    treemap: {
      color: {
        borderColor: [color],
        borderWidth: 5,
      },
      enableShades: false,
      shadeIntensity: 0.5,
      reverseNegativeShade: true,
    },
  },

  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      opacityTo: 0.7,
      opacityFrom: 1,
      stops: [0, 90, 100],
      shadeIntensity: 0.1,
    },
  },
  colors: [color],
});

const makeDataArray = (data) => {
  const returnArray = data?.esg_factors?.map((val) => ({
    x: val.name.startsWith(". ") ? val.name.slice(2) : val.name,
    y: val.incidents,
  }));
  return returnArray;
};
const IncidentsByESGCategories = ({ data, title }) => {
  const newData = useCallback(() => {
    return data?.map((e) => {
      let mixed = [];
      if (e.esg_factors) mixed = mixed.concat(e.esg_factors);
      if (e.esg_pais) mixed = mixed.concat(e.esg_pais);
      if (e.esg_sdgs) mixed = mixed.concat(e.esg_sdgs);
      mixed = mixed?.map((e) => {
        const graphData = { x: "", y: 0 };
        graphData.x = e.name;
        graphData.y = e.incidents;
        return graphData;
      });
      e.mixed = mixed;
      return e;
    });
  }, [data]);

  const Series = (data) => [{ data: data?.length > 0 ? data : [] }];
  const Chart = ({ title, color, className, data }) => {
    return (
      <div
        className={`${style[className]} ${style.innerContainer}`}
        style={{ border: `2px solid${color} ` }}
      >
        <ReactApexChart
          options={TreeOption(color, title)}
          series={Series(data)}
          type="treemap"
          height={500}
          width={"100%"}
        />  
      </div>
    );
  };

  return (
    <section>
      <ChartCard
        title={title ? title : "Incidents by ESG Categories"}
        legend={ESG}
        showTimeBarOnTop={false}
      >
        {/* <ChartBottomTitle title={"Sentiment by source"}> */}
        <div className={style.container}>
          <div className={style.social}>
            <Chart
              data={makeDataArray(data?.Governance)}
              title={"Governance"}
              color={"#653DD4"}
            />
            <Chart
              data={makeDataArray(data?.Social)}
              title={"Social"}
              color={"#2191FB"}
            />
          </div>
          <div className={style.social}>
            <Chart
              title={"Environment"}
              color={"#6EBD20"}
              className={"innerContainerENV"}
              data={makeDataArray(data?.Environmental)}
            />
          </div>
        </div>
        {/* </ChartBottomTitle> */}
      </ChartCard>
    </section>
  );
};

export default IncidentsByESGCategories;
