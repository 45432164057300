import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
  isLoading: false,
  userEmail: "",
  userName: "",
  userPhoneNum: "",
  notificationSettingState: {},
  userCompId: null,
  companyName: null,
  countryCode:null,
};
export const accountSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_GET_PROFILE_LOAD:
      return { ...state, isLoading: true, };
    case types.API_GET_PROFILE_DATA_SUCCESS:
      var { email, userName, notificationSetting, phoneNumber, companyId, company,countryCode
      } = action.payload?.data?.user ?action.payload?.data?.user :action.payload?.data
      return {
        ...state,
        isLoading: false,
        userEmail: email,
        userName: userName,
        userPhoneNum: phoneNumber,
        notificationSettingState: notificationSetting?.email,
        userCompId: companyId,
        companyName: company ? company?.name : null,
        nameComp: company ? company?.name : null,
        countryCode:countryCode?countryCode:"1"
      };
    case types.API_GET_PROFILE_FAIL:
      return { ...state, isLoading: false };

    case types.API_UPDATE_PROFILE_LOAD:
      return { ...state, isLoading: true };
    default:
      return { ...state };
  }
};