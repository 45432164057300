import { createContext, useState, useContext, useMemo } from 'react';

const defaultValue = {
  graphObject: undefined,
  setGraphObject: () => {},
};
const MyContext = createContext(defaultValue);
export function GraphObjectProvider({ children }) {
  const [graphObject, setGraphObject] = useState(undefined);
  const value = useMemo(
    () => ({ graphObject, setGraphObject }),
    [graphObject, setGraphObject],
  );
  return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
}
export const useGraphObject = () => useContext(MyContext);
export default MyContext;
