import React, { useState } from "react";
import Images from "../../Themes/ImageConstant";
import { Table } from "antd";
import { Breadcrumb } from "antd";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { dispatch, navigate } from "../../Utils/dispatchNavigate";
import { removeIncidenceAction } from "../../Redux/Action/saveIncidence";
import CustomPagination from "../../Componets/Pagination";
import { getDownloadDataAction, incindentAction } from "../../Redux/Action/incidents";
import ConfirmationModal from "../../Componets/ConfirmationModal";
import Search from "../incidentsList/components/search";
import ModalCloseButton from "../../Componets/modalCloseButton";
import ModalHeader from "../../Componets/modalHeaderComp";
import SearchDocEmptyDpc from "../../Componets/searchDocEmptyCom";
import { checkSeverityColor } from "../../Utils";
import { isInternetConnected } from "../../Utils/InternetConnection";

const IncidentCollection = (props) => {
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { result } = useSelector((state) => state?.saveIncidenceReducer);
  const collectionList = result;
  const DateConverter = (date) => {
    const dateObject = new Date(date);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    const formattedDate = dateObject.toLocaleString("en-US", options);
    return formattedDate;
  };
  const IPLData = useSelector((state) => state.incidentReducer.incident);
  const { selectedCollection, selectedCollectionName, selectedCollectionIncidents } = useSelector(
    (state) => state.saveIncidenceReducer
  );
  const column = [
    {
      width: 378,
      title: <div style={{ fontSize:'13px', fontWeight: '400', fontFamily: 'Inter'}}>Incident Title</div>,
      dataIndex: "title",
      key: "title",
      height: 48,
      render: (_, record, index) => (
        <div style={{ display: "flex", flexDirection: "row", height: "72px" }}>
          <span
            style={{
              color: "#9A9CA2",
              fontFamily: "Inter",
              marginRight: 10,
              fontSize: 11,
              display: "flex",
              alignItems: "center",
            }}
          >
            {(IPLData?.pagination?.currentPage - 1) * 10 + index + 1}
          </span>
          <span
            className="titleCss"
            style={{
              display: "flex",
              fontFamily: "Inter",
              fontSize: 13,
              lineHeight: "24px",
              color: "#07132F",
              fontWeight: "700",
              height: "72px",
              alignItems: "center",
            }}
            onClick={() =>window.open(`/incidentdetails?${record.id}`, "_blank")
            }
          >
            {_?.length > 120 ? _ : _?.substring(0, 120)}
          </span>
        </div>
      ),
    },
    {
      
      title: <div style={{ fontSize:'13px', fontWeight: '400', fontFamily: 'Inter'}}>Severity</div>,
      dataIndex: "severity_score",
      fixed: 'right',
      key: "severity_score",
      render: (record) => (
        <div
        style={{
          backgroundColor: checkSeverityColor[record?.value > 66 ? "HIGH" : record?.value > 33 ? "MEDIUM" : "LOW"],
            display: "flex",
            justifyContent: "center",
            // marginLeft: 10,
            padding: "4px 12px",
            width: "36px",
            alignItems: "center",
            borderRadius: "2px",
        }}
      >
        <span>
          <p style={{ color: "white", width: "fit-content", fontWeight: 500 }}>
            {record?.value ? Math.round(record?.value) : ''}
          </p>
        </span>
      </div>
      ),
    },
    {
      width: 178,
      title: <div style={{ fontSize:'13px', fontWeight: '400', fontFamily: 'Inter', textAlign: "center" }}>Locations</div>,
      dataIndex: "locations",
      key: "locations",
      render: (services) => (
        <div style={{
          height: "72px",
          display: "flex",
          alignItems: "center",
          fontFamily: "Inter",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "500px",
          justifyContent: "center",
          lineHeight: "16px",
          color: "#2D303A",
          fontWeight: 500

        }}>
          {services.map((service) => service.country_name).join()}
        </div>
      ),
    },
    {
      title: <div style={{ fontSize:'13px', fontWeight: '400', fontFamily: 'Inter' }}>Date</div>,
      dataIndex: "discovery_time",
      key: "discovery_time",
      render: (time, record) => (
        <div style={{ height: "72px", display: "flex", alignItems: "center", flexDirection: "row-reverse" }}>
          {DateConverter(time)}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      style: { background: "red" },
      render: (a, record) => (
        <>
          <div
             style={{ marginTop: "0", height: "72px", cursor: "pointer", alignItems: "center", display: 'flex' }}
            id={a}
            onClick={(e, i) => {
              setVisible(true);
              setSelectedRecord(record);
            }}
          >
            <img height={20} width={20} src={Images.trash} />
          </div>
        </>
      ),
    },
  ];

  const callDownloadApi = (collectionId) => {
    dispatch(getDownloadDataAction({collectionId, type: "incidentCollection"}));
  };

  return (
    <div className="side-padding sodebar-left-padding">
      <div className="pd-right-side-r mt-5 mb-5">
        <div className="container">
          <div style={{ marginLeft: "4vw" }}>
          {(collectionList?.length > 0 && IPLData?.pagination?.totalCount > 0) || (selectedCollectionIncidents && selectedCollectionIncidents.length > 0) ? (
              <>
                <div className="breadcrmbs mb-4">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <div
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        fontFamily: "Inter",
                        color: "#263049",
                        fontStyle: "normal",
                        lineHeight: "24px",
                        color: "#9A9CA2"

                      }}
                    >
                      Saved Incidents
                    </div>
                    <div style={{ margin: "0 10px", fontSize:'12px', color: "#9A9CA2" }}>/</div>
                    <div
                      style={{
                        fontWeight: "600",
                        color: "#263049",
                        letterSpacing: "1px",
                        fontSize: "16px",
                        wordBreak: "break-all"
                      }}
                    >
                      {selectedCollectionName}
                    </div>
                  </div>
                </div>

                <div className="table-page-sec mt-5  ">
                  <div className="topp-div">
                    <div className="table-head-sec">
                      <Search obj={
                         selectedCollection
                        }
                      action={incindentAction}
                      />
                    </div>
                    <div className="right-side-colm">
                      <Tooltip placement="top" title={"Download"}>
                        <div className="downl-btn1" onClick={() => callDownloadApi(selectedCollection)}>
                        <img src={Images.framedow1} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <Table
                    className="table-style"
                    dataSource={IPLData.data}
                    columns={column}
                    pagination={false}
                  />
                  {/* IPLData?.pagination?.totalCount > 15 && */}
                  {selectedCollection && (
                    <CustomPagination
                      pagination={IPLData.pagination}
                      action={incindentAction}
                      data={{
                        collectionId: selectedCollection,
                      }}
                    />
                  )}
                  {visible && (
                    <ConfirmationModal
                      title={<ModalHeader title={"Remove Incident"} />}
                      visible={visible}
                      closeIcon={<ModalCloseButton />}
                      cancel={{
                        cancelText: "Cancel",
                        onCancel: () => setVisible(false),
                      }}
                      ok={{
                        okText: "Remove",
                        onOK: () => {
                          if (isInternetConnected()) {
                            dispatch(
                              removeIncidenceAction({
                                collectionId: selectedCollection,
                                incidentId: selectedRecord.id,
                              })
                            );
                            setVisible(false);
                          }
                        },
                      }}
                      icon={Images.deletError}
                      description={"Remove the incident from this collection?"}
                      contentCenter={true}
                    />
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "85vh",
                }}
              >
                <SearchDocEmptyDpc
                  title={"Your collection list is empty!"}
                  msg={"You can save Incidents and add them to collections"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default IncidentCollection;
