import React from 'react';
import { SvgIcon } from '@mui/material';
// add sx correct type to props to add it to SvgIcon
function FilterIcon({ sx }) {
  return (
    <SvgIcon sx={sx}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
      >
        <line
          x1="0.75"
          y1="3.25"
          x2="19.25"
          y2="3.25"
          stroke="#4E515C"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle
          cx="14"
          cy="3"
          r="2"
          fill="white"
          stroke="#4E515C"
          strokeWidth="1.5"
        />
        <line
          x1="0.75"
          y1="9.25"
          x2="19.25"
          y2="9.25"
          stroke="#4E515C"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle
          cx="6"
          cy="9"
          r="2"
          fill="white"
          stroke="#4E515C"
          strokeWidth="1.5"
        />
        <line
          x1="0.75"
          y1="15.25"
          x2="19.25"
          y2="15.25"
          stroke="#4E515C"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle
          cx="13"
          cy="15"
          r="2"
          fill="white"
          stroke="#4E515C"
          strokeWidth="1.5"
        />
      </svg>
    </SvgIcon>
  );
}
export default FilterIcon;
