import moment from "moment";

const RegulatoryLegalActionsCard = ({ statement, datetime, index, screen, target_companies,id}) => {
  return (
    <div className="principleBox">
      <div className="headerDiv">
        <div className="row">
          <div className="col">
            <div className="srNoView">{index + 1}</div>
          </div>
        </div>
        <div className="col">
          <span
            style={{
              marginLeft: 12,
              display: "flex",
              fontWeight: 'bold'
            }}
          >
            {statement}
          </span>
          <div className="row">
            <div className="col-8">
              {screen == "Incident" ?
                <div className="principleTitle ml-12 ">
                  Involved Companies: <span style={{ fontWeight: 600 }}>{target_companies?.map((v, i) => { return (v + (i == target_companies.length - 1 ? "" : ", ")) })}</span>
                </div>
                :
                <div onClick={()=>
                  window.open(`/incidentdetails?${id}`, "_blank")
                } className="principleTitle ml-12 color0195CE downl-btn">
                  View the Incident
                </div>
              }
            </div>
            <div className="col-4">
              <div className="principleTitle ml-12 d-flex justify-content-end">
                {moment(datetime).format("MMM, YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegulatoryLegalActionsCard
