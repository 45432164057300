import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { useLocation } from "react-router";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import Images from "../../Themes/ImageConstant";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
const { Header, Content, Footer, Sider } = Layout;

const Sidebar = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  const [collapse, setCollapse] = useState(
    window.innerWidth <= 600 ? true : false
  );

  const handleToggle = () => {
    setCollapse(!collapse);
  };

  const onMenuClick = (e) => {
    navigate(e.key);
    // history.push(e.key);
  };

  let activeKey = "";
  switch (location.pathname) {
    case "/profile":
      activeKey = "/profile";
      break;
    case "/password":
      activeKey = "/password";
      break;
    case "/emailnotification":
      activeKey = "/emailnotification";
      break;
    case "/plans":
      activeKey = "/plans";
      break;
    case "/customizescoring":
      activeKey = "/customizescoring";
      break;
  }

  const item = [
    "Profile",
    "Password",
    "Email Notification",
    "Customize Scoring",
    "Plans",
  ];
  return (
    <div className="left-side-padding">
      <Sider
        width={280}
        collapsed={collapse}>
        <div className="admin-text">
          <h2>Settings</h2>
        </div>

        <Menu
          mode="inline"
          defaultSelectedKeys={"1"}
          defaultOpenKeys={[activeKey]}
          selectedKeys={[activeKey]}
          _internalDisableMenuItemTitleTooltip={false}
          rootClassName="menuBg">
          <Menu.Item
            key="1"
            title="Profile"
            className={activeKey == "/profile" ? "selected" : ""}>
            <Link to="/profile">
              <div className="sidebar-item-wrapper">
                <span style={{ paddingLeft: collapse ? 12 : 0 }} className="sidebar-cions">
                  <img src={Images.userprof} />
                </span>
                {!collapse ? <span className="line_dash">Profile</span> : null}
              </div>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="2"
            title="Password"
            className={activeKey == "/password" ? "selected" : ""}>
            <Link to="/password">
              <div className="sidebar-item-wrapper">
                <span style={{ paddingLeft: collapse ? 12 : 0 }} className="sidebar-cions">
                  <img src={Images.password} />
                </span>
                {!collapse ? <span className="line_dash">Password</span> : null}
              </div>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="3"
            title="Email Notification"
            className={activeKey == "/emailnotification" ? "selected" : ""}>
            <Link to="/emailnotification">
              <div className="sidebar-item-wrapper">
                <span style={{ paddingLeft: collapse ? 12 : 0 }} className="sidebar-cions">
                  <img src={Images.mailOpen} />
                </span>
                {!collapse ? (
                  <span className="line_dash">Email Notification</span>
                ) : null}
              </div>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="4"
            title="Customize Scoring"
            className={activeKey == "/customizescoring" ? "selected" : ""}>
            <Link to="/customizescoring">
              <div className="sidebar-item-wrapper">
                <span style={{ paddingLeft: collapse ? 12 : 0 }} className="sidebar-cions">
                  <img src={Images.settingIcons} />
                </span>
                {!collapse ? (
                  <span className="line_dash">Customize Scoring </span>
                ) : null}
              </div>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="5"
            title="Plans"
            className={activeKey == "/plans" ? "selected" : ""}>
            <Link to="/plans">
              <div className="sidebar-item-wrapper">
                <span style={{ paddingLeft: collapse ? 12 : 0 }} className="sidebar-cions">
                  <img src={Images.list} />
                </span>
                {!collapse ? <span className="line_dash">Plans</span> : null}
              </div>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
};
export default Sidebar;
