import {
  Box,
  Drawer,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import useAxios from "axios-hooks";
import CloseIcon from "./CloseIcon";
import {
  setRightClickMenuSideBarNodeId,
  setShowCompanyInfoSideBar,
} from "../../redux/GraphExploration/rightClickMenuSlice";
import { useAppDispatch, useAppSelector } from "../../redux/configuareStore";
import { getSelectedNodes } from "../../utils/index";
import GeneralInfo from "./GeneralInfo";
import SupplyChainActViolations from "./SupplyChainActViolations";
import RecentIncidents from "./RecentIncidents";
import { getToken } from "../../../../../Utils";
import { navigate } from "../../../../../Utils/dispatchNavigate";
import { useState } from "react";
import { useGraphObject } from "../context/MyContext";
import IncidentsListModal from "../../../../IncidentsListModal";

function CompanyInfoSideBar() {
  const showCompanyInfoSideBar = useAppSelector(
    (state) => state.rightClickMenu.showCompanyInfoSideBar
  );
  const from = useAppSelector((state) => state.timeRange.from);
  const to = useAppSelector((state) => state.timeRange.to);
  const [modalOpen, SetModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const sideBarNodeId = useAppSelector(
    (state) => state.rightClickMenu.sideBarNodeId
  );
  const params = {
    from,
    to,
  };
  const token = getToken();
  const baseUrlApi = useAppSelector((state) => state.api.baseUrlApi);
  const [{ data, loading }] = useAxios(
    {
      url: `${baseUrlApi}/companies/${sideBarNodeId}`,
      params,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        // Add any other headers if needed
      },
    },
    { manual: !showCompanyInfoSideBar }
  );
  const theme = useTheme();
  const handleClose = () => {
    dispatch(setShowCompanyInfoSideBar(false));
    dispatch(setRightClickMenuSideBarNodeId(undefined));
  };

  const onViewCompanyProfile = () => {
    // navigate("/companyPage", { state: { id: sideBarNodeId } });
    localStorage.companyId = sideBarNodeId;
    const newTab = window.open("/companyPage", "_blank");
    newTab.location.state = { id: sideBarNodeId };
  };

  const onViewAllIncidents = () => {
    SetModalOpen(true);
  };

  return (
    <>
      {modalOpen ? (
        <IncidentsListModal
          networkData={{
            companies: [sideBarNodeId],
            companyName: [data.name],
            start: params.from,
            end: params.to,
          }}
          setModalVisible={SetModalOpen}
        />
      ) : null}
      <Drawer
        anchor="right"
        open={showCompanyInfoSideBar}
        onClose={handleClose}
        hideBackdrop
        elevation={1}
        variant="persistent"
      >
        <Box sx={{ width: 464 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
              borderBottom: `1px solid ${theme.palette.gray["50"]}`,
              height: "59px",
            }}
          >
            <Box>
              <Typography variant="subtitle3">Company Info</Typography>
            </Box>
            <Box>
              <IconButton sx={{ padding: 0 }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {loading ? (
            <LinearProgress />
          ) : (
            data && (
              <Stack
                sx={{
                  padding: "24px",
                  height: "calc(100vh - 59px)",
                  overflowY: "auto",
                }}
                gap={4}
              >
                <GeneralInfo
                  data={data}
                  onViewCompanyProfile={onViewCompanyProfile}
                />
                <SupplyChainActViolations data={data} />
                <RecentIncidents
                  data={data}
                  onViewAllIncidents={onViewAllIncidents}
                />
              </Stack>
            )
          )}
        </Box>
      </Drawer>
    </>
  );
}
export default CompanyInfoSideBar;
