import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import style from "./style.module.css";
import "./watchlistStyle.css"
import * as Yup from "yup";
import clsx from "clsx";
import HeaderComponent from "../portfolio/components/HeaderComponent";
import { Checkbox, AutoComplete, Spin, Table, Input } from "antd";
import ImageConst from "../../Themes/ImageConstant";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import {
  getPortfolioCompaniesListSearchLoad,
} from "../../Redux/Action/portfolio";
import { fileUploadAction, updateWatchList } from "../../Redux/Action/watchListActions";
import { debounce } from "../../Utils";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalSpinner from "../../Utils/GlobalSpinner";

import { formatNumber, highlightMatch } from "../../Utils/globalFunction";
import { BottomPagination } from "../portfolio/CreatePortfolio/utils-comp";


import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import ReactCountryFlag from 'react-country-flag';

// Register locales with correct imports
countries.registerLocale(enLocale);
countries.registerLocale(frLocale);

let autoCompletePaginationOffset = 0;
let stopApicallForGetingCompanies = false;
let lastSearchString = "";
let checkingCompanyIndex;
const tableDataModificationHandler = (data) =>
  data?.length > 0 ? data?.map((item, index) => ({ ...item, index })) : [];

const emptyObj = {
  "company": "",
  index: 0,
  isin: "",
  name: "",
  "portfolio weight": "",
  searchBase: null,
  ticker: "",
  tickers: {},
};

const empArr = [emptyObj, emptyObj, emptyObj, emptyObj]

const UploadWatchlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const invalidCompany = state.data.invalidCount || 0;
  const validCompany = state.data.validCount || 0;
  const uploadMediaFileData = state.data.companies || [];
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false) 

  const duplicateSetFieldValue = useRef({
    setValue: null,
    value: null,
    errors: null,
    setErrors: null,
  });
  const [initValues] = useState({
    holdings: [
      {
        company: "",
        ticker: "",
        isin: "",
      },
    ],
  });
  const portfolioData = useSelector((state) => state.portfolio);
  const { fileData, uploadFileSuccess, uploadFileLoading } = useSelector((state) => state?.watchListReducer);

  const {
    companiesSearchListLoader,
    companiesSearchList,
    companiesSearchListPaginationData,
    inValidHoldinggList,
  } = portfolioData;

  useEffect(() => {
    if (checkingCompanyIndex !== undefined && inValidHoldinggList.length > 0) {
      const clone = [...duplicateSetFieldValue.current.value.holdings];
      clone[checkingCompanyIndex].isValid = inValidHoldinggList[0].isValid;
      duplicateSetFieldValue.current.setValue("holdings", clone);
      checkingCompanyIndex = undefined;
    }
  }, [inValidHoldinggList]);

  useLayoutEffect(() => {
    if (uploadMediaFileData && uploadMediaFileData.length > 0) {
      let tempArr = [];
      uploadMediaFileData?.map((item, index) => {
        return tempArr?.push({
          company:
            item["Company name"]?.trim() ||
            item["ISIN"]?.trim() ||
            item["Ticker"]?.trim() ||
            "",
          id: item.id || "",
          ticker:
            item["tickers"] && Object.keys(item["tickers"])?.length > 0
              ? item["tickers"]?.tickerId
              : "",
          symbol:
            item["tickers"] && item["tickers"]?.symbol ? item["tickers"]?.symbol : "",
          isin: item["isin"],
          isValid: item["isValid"],
        });
      });
      dispatch(fileUploadAction(tempArr))
    }
  }, [uploadMediaFileData]);

  const validationSchema = Yup.object().shape({
    holdings: Yup.array().of(
      Yup.object().shape({
        company: Yup.string().test(function (value, context) {
          if (
            this.options.index == 0 &&
            (value ? value.trim() == "" : value == undefined)
          ) {
            return this.createError({
              path: this.path,
              message: "Please enter company name or ticker symbol or ISIN.",
            });
          } else if (
            this.options.context.holdings.filter(
              (item, index) =>
                item.company &&
                item.company == value &&
                index != this.options.index
            ).length > 0
          ) {
            return this.createError({
              path: this.path,
              message: "Entered company name should not be same",
            });
          } else {
            return this.resolve({
              path: this.path,
              message: "",
            });
          }
        }),
      })
    ),
  });

  useEffect(() => {
    setOptions([...options, ...companiesSearchList]);
  }, [companiesSearchList]);

  const handleSearch = (searchString, offset) => {
    lastSearchString = searchString;
    setLoading(true)
    debounce(() => {
      dispatch(
        getPortfolioCompaniesListSearchLoad({
          search: lastSearchString,
          limit: 10,
          offset: offset || 0,
        })
      );
    });
    setTimeout(() => {
      setLoading(false)      
    }, 2000);
  };

  useLayoutEffect(() => {
    if (uploadFileSuccess) {
      navigate("/watchlist");
    }
  }, [uploadFileSuccess]);

  // New Functionalities
  const [makeAuto, setMakeAuto] = useState({ state: false, index: null });
  const [editWeight, setEditWeight] = useState({ state: false, index: null });
  const [activeTableRowIndex, setActiveTableRowIndex] = useState(null);
  const [paginationPayload, setPaginationPayload] = useState({
    currentPage: 1,
    rowLimit: 15
  })
  const autoCompleteRef = useRef(null)
  const editWeightRef = useRef(null)

  // State
  const [addFilePopOver, setAddFilePopOver] = useState(false);

  const tabeldata = useMemo(
    () => tableDataModificationHandler(fileData),
    [fileData]
  );

  const renderTableData = useMemo(() => tabeldata.slice((paginationPayload.currentPage - 1) * paginationPayload.rowLimit, paginationPayload.currentPage * paginationPayload.rowLimit), [paginationPayload, fileData]);
  const tabelDataSelectedItemLength = useMemo(
    () => fileData?.length > 0 ? fileData?.filter((item) => item?.isSelected)?.length : [],
    [fileData]
  );

  // Handler
  const allSelectTableItemsHandler = useCallback(
    (e) => {
      if (addFilePopOver) {
        setAddFilePopOver(false);
      }
      const startIndex = renderTableData[0].index
      const endIndex = renderTableData[renderTableData.length - 1].index
      let arr = tabeldata.map((item) => {
        if (item.index >= startIndex && item.index <= endIndex) {
          return { ...item, isSelected: e.target.checked };
        } else {
          return { ...item }
        }
      })
      dispatch(fileUploadAction(arr));
    },
    [fileData, renderTableData]
  );
  const removeTableSelectedItemsHandler = useCallback(() => {
    let arr = fileData.filter((item) => !item.isSelected);
    if (arr.length === 0) {
      dispatch(fileUploadAction(empArr))
    }
    else {
      dispatch(fileUploadAction(arr));

    }
  }, [fileData]);

  useEffect(() => {
    const focusInput = (ref) => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    };
    if (makeAuto.state) {
      focusInput(autoCompleteRef);
    } else if (editWeight.state) {
      focusInput(editWeightRef);
    }
  }, [makeAuto, editWeight]);

  const renderOptions= () => {
    let renderedOptions =  options?.map((option, idx) => {  
        return {
        value: option?.id || "",
        dataValue: option?.value?.label || "",
        ticker: option?.value?.tickers || "",
        key: option?.id || "",
        isin: option?.value?.isin || "",
        label: (
          <DoneItem
            searchString={lastSearchString}
            companyName={option?.value?.label || ""}
            ticker={option?.value?.tickers || ""}
            compId={option?.value?.compId || ""}
            key={option?.id || ""}
            isin={option?.value?.isin || ""}
            alter_name={option?.value?.alter_name || ""}
            alter_names={option?.value?.alter_names?.length > 0 ? option?.value?.alter_names[0] : ""}
          />
        ),
        }
    })
    if (loading) {
      renderedOptions.push({
        value: 'loading',
        label: (
          <div style={{ textAlign: 'center' }}>
            <Spin size="small" />
          </div>
        ),
      });
    }
    return renderedOptions;
  };


  const rowEdit = (index) => makeAuto.state && makeAuto.index == index

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (v, r, i) => {
        return (
          <div style={{ display: "flex", gap: "8px" }}>
            <div
              style={{
                marginLeft: 15,
                width: "25px",
                height: "25px",
              }}
            >
              {activeTableRowIndex == i ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer"
                  }}
                  src={ImageConst.plusBtnNew}
                  onClick={() => {
                    const clone = [...tabeldata];
                    const dummyObj = {
                      "company": "",
                      index: 0,
                      isValid: null,
                      isin: "",
                      name: "",
                      "portfolio weight": "",
                      searchBase: null,
                      ticker: "",
                      tickers: {},
                    };
                    clone.splice(r.index + 1, 0, dummyObj);
                    dispatch(fileUploadAction(clone))
                  }}
                />
              ) : null}
            </div>
            <div
              style={{

                minWidth: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "inherit",
              }}
            >
              {activeTableRowIndex == i || r?.isSelected ? (
                <Checkbox
                  checked={r?.isSelected}
                  onChange={(e) => {
                    let arr = [...tabeldata];
                    arr[r.index] = {
                      ...arr[r.index],
                      isSelected: e.target.checked,
                    };
                    dispatch(fileUploadAction(arr))
                  }}
                />
              ) : (
                <>{r.index + 1}</>
              )}
            </div>
          </div>
        );
      },
      width: 70
    },
    {
      title: " Holding / Ticker Symbol / ISIN",
      dataIndex: "company",
      key: "name",
      render: (v, r, i) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              background: "inherit",
            }}
            onClick={() => {
              setMakeAuto({ state: true, index: i });
            }}
          >
            <div style={{ width: "10%" }}>
              {r.isValid ? (
                <div className="error">
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "5px",
                    }}
                    src={ImageConst.validPng}
                  />
                </div>
              ) : r.isValid == false ? (
                <div className="error">
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "5px",
                    }}
                    src={ImageConst.invalidPng}
                  />
                </div>
              ) : null}
            </div>
            <div
              style={{
                flexGrow: 1,
              }}
            >
              {makeAuto.state && makeAuto.index == i ? (
                <div
                  onClick={(e) => e.stopPropagation()}
                >
                  <AutoComplete
                    open={true}
                    style={{ width: "98%" }}
                    value={r["company"]}
                    options={renderOptions() }
                    onSearch={(e) => {
                      let arr = [...tabeldata];
                      arr[r.index] = {
                        ...arr[r.index],
                        isValid: false,
                        "company": e,
                        name: e,
                      };
                      dispatch(fileUploadAction(arr))
                      stopApicallForGetingCompanies = false;
                      setOptions([]);
                      handleSearch(e);
                    }}
                    onSelect={(e, selectedOption) => {
                      const clone = [...tabeldata];
                      const makeItem = {
                        index: r.index,
                        isValid: true,
                        company: selectedOption.dataValue,
                        symbol: selectedOption?.ticker ? selectedOption?.ticker?.symbol : "",
                        ticker: selectedOption?.ticker?.tickerId,
                        isin: selectedOption.isin
                          ? selectedOption.isin
                          : r.isin,
                      };
                      clone[makeItem.index] = makeItem;
                      dispatch(fileUploadAction(clone))
                      setMakeAuto({ state: false, index: null });
                      autoCompletePaginationOffset = 0
                    }}
                    onFocus={(e) => {
                      stopApicallForGetingCompanies = false;
                      setOptions([]);
                      handleSearch(e.target.value, 0);
                    }}
                    notFoundContent={
                      options?.length == 0 &&
                      companiesSearchListLoader && (
                        <div className={style.spinLoader}>
                         <Spin size="small" />
                        </div>
                      )
                    }
                    onBlur={() => {
                      if (r["company"].length == 0) {
                        let arr = [...tabeldata];
                        arr[r.index] = {
                          ...arr[r.index],
                          isValid: false,
                        };
                        dispatch(fileUploadAction(arr));
                      }
                      setMakeAuto({ state: false, index: null })
                      autoCompletePaginationOffset = 0

                    }

                    }

                    onPopupScroll={(e) => {
                    if (
                        e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 1
                      ) {
                        console.log('Fetching more data...');
                        autoCompletePaginationOffset =
                          autoCompletePaginationOffset + 5;
                        handleSearch(
                          lastSearchString,
                          autoCompletePaginationOffset
                        );
                      }
                    }}
                  >
                    <Input
                      ref={autoCompleteRef}
                      size="middle"
                    />
                  </AutoComplete>
                </div>
              ) : (
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {r["company"]}
                  </div>
                  <div
                    style={{
                      width: "22px",
                      height: "22px",
                    }}
                  >
                    {activeTableRowIndex == i && makeAuto.index !== i && r["company"]?.length > 0 ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMakeAuto({ state: true, index: i });
                        }}
                        src={ImageConst.editIcon}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            {makeAuto.index !== i && (
              <div style={{ width: "30%" }}>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "inherit",
                  }}
                >
                  <div>
                    {r?.ticker || ""}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "isin",
      key: "isin",
      width: 70
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (v, r, i) => {
        return (
          <div
            style={{
              marginLeft: 15,
              marginRight: 15,
              height: "26px",
              width: "25px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (renderTableData?.length > 1) {
                const clone = [...tabeldata];
                clone.splice(r.index, 1);
                if (clone.length === 0) {
                  dispatch(fileUploadAction(empArr))
                }
                else {
                  dispatch(fileUploadAction(clone));
                }
                setEditWeight((pre) => ({ state: false, index: null }));
              }
            }}
          >
            {activeTableRowIndex == i && renderTableData?.length > 1 ? (
              <img
                style={{
                  height: "100%",
                  width: "100%",
                }}
                src={ImageConst.removeBtnNew}
              />
            ) : null}
          </div>
        );
      },
      width: 40
    },
  ];
  const isValidData = useMemo(() => tabeldata?.filter(val => val?.company?.length > 0)?.length, [tabeldata])
  const checkIsValid = useMemo(
    () => tabeldata.filter((item) => !item.isValid && item?.company?.length > 0),
    [fileData]
  )?.length;

  const handleSubmit = () => {
    let data = {
      companies: tabeldata.filter((item) => item.isValid),
      wishlistId: state.detail._id,
    };
    dispatch(updateWatchList(data));
  };
  return (
    <>
      <div className={"containerWidth marginAuto pt20"}>
        <HeaderComponent
          name={"Watchlist"}
          subHeader={"Upload File"}
          screen={"/watchlist"}
        />
        <div className={clsx(style.containerWidth)}>
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({ setFieldValue, setErrors, values, errors, validateForm }) => {
              duplicateSetFieldValue.current.setValue = setFieldValue;
              duplicateSetFieldValue.current.value = values;
              duplicateSetFieldValue.current.errors = errors;
              duplicateSetFieldValue.current.setErrors = setErrors;
              duplicateSetFieldValue.current.validateForm = validateForm;
              return (
                <Form>
                  <div
                    className="pv10ph16"
                    style={{ cursor: "pointer", width: "fit-content" }}
                    onClick={() => navigate("/watchlist")}
                  >
                    <img
                      style={{ height: 20, width: 20 }}
                      src={ImageConst.backBtn}
                    ></img>
                    <span className="backBtnText"> Back</span>
                  </div>
                  <section className="containerWidth ">
                    <div
                      className={`${style.subComponent} ${style.subComponent1}`}
                    >
                      <span className={style.subComponentText}>My List</span>
                      <>
                        <button
                          onClick={handleSubmit}
                          className={style.smallBtn}
                          style={{
                            width: "fit-content",
                          }}
                          disabled={!isValidData}
                        >
                          <div
                            className={style.nextBtn}
                            style={{
                              width: "fit-content",
                            }}
                          >
                            {uploadFileLoading ? (
                              <GlobalSpinner />
                            ) : checkIsValid == 0 ? (
                              "Save"
                            ) : (
                              "Skip Errors and Continue"
                            )}
                            <img
                              style={{ height: 20, width: 20 }}
                              src={ImageConst.arrowWhiteRight}
                            />
                          </div>
                        </button>
                      </>
                    </div>
                  </section>
                  <>
                    <section className="containerWidth">
                      <span className={style.webBody}>
                        You can review and edit all your list here.
                      </span>
                    </section>
                    {
                      <section className="containerWidth pt32">
                        <div className={style.sucessView}>
                          <img
                            style={{
                              height: "32px",
                              width: "32px",
                              marginRight: "5px",
                            }}
                            src={ImageConst.portfolioInfo}
                          />
                          <div>
                            <span className={style.successMessage}>
                              {invalidCompany == 0
                                ? "Your list of companies has been processed successfully!"
                                : `Your list of companies has been processed. Recognized companies have been added to your watchlist. Any unrecognized or incorrectly formatted entries can be corrected or skipped by you. Currently, ${formatNumber(state.data.validPercentage)}% of the holdings from your file are covered.`}
                              {invalidCompany > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 5,
                                  }}
                                >
                                  <div>
                                    <img
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                      }}
                                      src={ImageConst.validPng}
                                    />
                                    <span className={style.successMessage}
                                      style={{ marginLeft: 5 }}
                                    >
                                      {"Successfully Uploaded:"}
                                      <span
                                        className={style.successItem}
                                      >{`${validCompany} Items`}</span>
                                    </span>
                                  </div>
                                  <div>
                                    <img
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                      }}
                                      src={ImageConst.invalidPng}
                                    />
                                    <span
                                      className={style.successMessage}
                                      style={{ marginLeft: 5 }}
                                    >
                                      {"Failed to Upload:"}
                                      <span
                                        className={style.successItem}
                                      >{`${invalidCompany} Items`}</span>
                                    </span>
                                  </div>
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                      </section>
                    }

                    <section
                      className="containerWidth "
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        borderBottom: "1px solid #D8DAE0",
                        borderTop: "1px solid #D8DAE0",
                      }}
                    >
                      <div
                        style={{
                          borderRight: ".5px solid #D8DAE0",
                          padding: "6px 10px 6px 10px",
                        }}
                      >
                        <Checkbox
                          onChange={allSelectTableItemsHandler}
                          indeterminate={
                            renderTableData?.length > 0 &&
                            renderTableData?.filter((item) => item?.isSelected)
                              ?.length > 0 &&
                            renderTableData?.filter((item) => item?.isSelected)
                              ?.length !== renderTableData.length
                          }
                          checked={
                            renderTableData?.length > 0 &&
                            renderTableData?.filter((item) => item?.isSelected)
                              ?.length === renderTableData?.length
                          }
                        />
                      </div>
                      <div
                        style={{
                          padding: "8px 0px 8px 10px",
                        }}
                      >
                        <div
                          style={{
                            borderRight: ".5px solid #D8DAE0",
                            color: "#0095CE",
                            paddingRight: "10px",
                            fontSize: 13,
                            fontWeight: 600,
                          }}
                        >
                          {tabelDataSelectedItemLength} selected
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "6px 0px 6px 6px",
                          marginLeft: 18,
                          cursor: "pointer",
                        }}
                        onClick={removeTableSelectedItemsHandler}
                      >
                        <img
                          style={{
                            height: 16,
                            width: 16,
                            marginRight: 9,
                          }}
                          src={ImageConst.trashIcon}
                        />
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          Remove
                        </div>
                      </div>
                    </section>

                    <section className="containerWidth"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <Table
                        className="tableWatchList"
                        dataSource={renderTableData}
                        columns={columns}
                        rowClassName={(record, index) => rowEdit(index) && "redBG"}

                        // pagination={{ pageSize: 20 }}
                        pagination={false}
                        onRow={(record, rowIndex) => {
                          return {
                            onMouseEnter: () =>
                              setActiveTableRowIndex(rowIndex),
                            onMouseLeave: () => {
                              setActiveTableRowIndex(null);
                            },
                          };
                        }}
                      />
                      {tabeldata?.length > 10 ? (<div style={{ marginBottom: "12px" }}>
                        <BottomPagination
                          paginationHandler={setPaginationPayload}
                          paginationPayload={paginationPayload}
                          dataLength={tabeldata?.length || 0}
                        />
                      </div>) : <div style={{ paddingBottom: 10 }} />}
                      {!tabeldata?.length > 0 && (
                        <div className={style.inputContainer2N}>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: 10,
                              padding: "8px 0px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const clone = [];
                              const dummyObj = {
                                "company": "",
                                index: 0,
                                isin: "",
                                name: "",
                                "portfolio weight": "",
                                searchBase: null,
                                ticker: "",
                                tickers: {},
                              };
                              clone.push(dummyObj);
                              dispatch(
                                fileUploadAction(clone, "10")
                              );
                            }}
                          >
                            + Add New Row
                          </div>
                        </div>
                      )}
                    </section>
                  </>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UploadWatchlist;

const DoneItem = ({
  searchString,
  companyName,
  companyIcon,
  ticker,
  key,
  compId = "",
  isin = "",
  alter_name = "",
}) => {
  const highlightedCompanyName = highlightMatch(searchString, companyName?.slice(0, 20), "company");
  const companyEllipse = companyName?.length > 25 ? "..." : ""
  const highlightedTickerSymbol = highlightMatch(searchString, ticker.symbol, "ticker");
  const highlightedTickerID = highlightMatch(searchString, ticker.tickerId, "ticker");
  const highlightedAlter_names = alter_name?.length > 0 ? highlightMatch(searchString, alter_name?.slice(0, 10), "alter") : "";
  const alterEllipse = alter_name?.length > 10 ? "..." : ""
  let highlightedIsin = highlightMatch(searchString, isin, "isin");
  const countryName = ticker?.country?.length > 0 && countries.getName(ticker?.country, "en") || ""
  const countryCode = countryName?.length > 0 && countries.getAlpha2Code(countryName, 'en') || ""


  return (
    <div
      key={key}
      style={{
        display: "flex",
        paddingBottom: 5,
        justifyContent: "space-between",
        alignItems: "end",
        borderBottom: ".5px solid rgb(216,218,224)",
      }}
    >
      <div style={{ width: "100%", height: "35px", gap: "4px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ width: "12%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={`https://logos-svg.komo.systems/${compId}.png` || companyIcon}
            alt="companyImage"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ImageConst.Apple;
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "65%", }}>
          <div
            style={{
              fontSize: "13px",
              fontFamily: "inter",
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: highlightedCompanyName?.trim() + companyEllipse }} />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "10px",
            color: "#4E515C",
            fontFamily: "inter",
          }}>
            <div style={{ overflow: "hidden" }} >
              {countryCode && <ReactCountryFlag countryCode={countryCode} svg={countryName} style={{ width: 12, height: 12, borderRadius: "100%" }} />}
            </div>
            {ticker?.symbol?.length > 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
              {" : "}
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : ticker?.symbol?.length > 0 && ticker?.tickerId?.length == 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerSymbol }} />
            </div> : ticker?.symbol?.length == 0 && ticker?.tickerId?.length > 0 ? <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedTickerID }} />
            </div> : null}
          </div>
        </div>
        <div style={{
          width: "28%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          fontSize: "10px",
          color: "#4E515C",
          fontFamily: "inter",
          textAlign: "end"
        }}>
          <span dangerouslySetInnerHTML={{ __html: highlightedAlter_names?.trim() + alterEllipse }} />
          <span dangerouslySetInnerHTML={{ __html: highlightedIsin }} />
        </div>
      </div>
    </div >
  );
};
