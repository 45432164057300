import { Modal, Row, Table, Tabs, Spin } from "antd";
import Images from "../../Themes/ImageConstant";
import IncidentsList from "../../Screens/incidentsList";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { topHeaderAPIObject } from "../../Assets/dummyData";
import { incindentAction } from "../../Redux/Action/incidents";
import appconstant from "../../Constants/appConstansts";
import CompaniesListModal from "../../Screens/CompaniesListModal";
import GlobalLoder from "../../Utils/GlobalLoder";
import { getCorpCompaniesListLoad } from "../../Redux/Action/corporateProf";
import SupplyChainCompList from "../SupplyChainCompList/index";

const CompaniesTable = ({
  data,
  title,
  type,
  columns,
  scroll,
  id,
  date,
  showModal = false,
  screenName,
  goTo,
  icon,
  idsArray = null,
  companyName,
  heading,
}) => {
  const [modalVisible, setModalVisible] = useState(showModal);
  const [tabKey, setTabKey] = useState("1");
  let simpleApiPayload = { ...topHeaderAPIObject, ...date };
  const { incidentListLoader } = useSelector(
    (state) => state.companyPageReducer
  );
  const {
    companiesListLoader
  } = useSelector(state => state.corporateProfile)
  const dispatch = useDispatch();
  const openModal = () => {
    setModalVisible(true);
  };

  const onChangeTab = (key) => {
    setTabKey(key);
    if (key == 2) {
      dispatch(getCorpCompaniesListLoad({ ...date, companyId: id, relation: ["Customer"] }))
    }
    if (key == 1) {
      dispatch(getCorpCompaniesListLoad({ ...date, companyId: id, relation: ["Supplier"] }))
    }
    if (key == 3) {
      dispatch(getCorpCompaniesListLoad({ ...date, companyId: id, relation: ["Partner"] }))
    }
  };
 

  return (
    <section className="mt-4">
      <div className="container g-0">
        <div className="row">
          <div className="col">
            <div
              className={
                screenName !== "Home"
                  ? `recent-table`
                  : `recent-table containerWidth`
              }
            >
              <div className="tab-top">
                <div className="left-text">
                  <Row>
                    <h4>{title}</h4>
                    {icon ? (
                      <img
                        className="hover-page"
                        style={{ marginLeft: 5 }}
                        src={Images.circleInfo}
                        alt=""
                      />
                    ) : null}
                  </Row>
                </div>
                <div
                  className="righ-side"
                  onClick={() => (openModal())}
                >
                  <span>
                    <span>{heading ? heading : "View all Companies"}</span>
                    <span>
                      <img src={Images.iconser} />
                    </span>
                  </span>
                </div>
              </div>
              <Tabs
                type="card"
                // style={{padding:10}}
                className="incident-table-tabs"
                activeKey={tabKey}
                onChange={onChangeTab}
              >
                <Tabs.TabPane
                  key={"1"}
                  style={{ textAlign: "center" }}
                  tab={<>{"Supplier"}</>}
                >
                  <div className="table-box">
                    <Spin
                      spinning={companiesListLoader}
                      indicator={
                        <div className="outer-table-loader">
                          <GlobalLoder />
                        </div>
                      }
                    >
                      <Table
                        rowClassName={"incidentTbl"}
                        className="Antdtable-component"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        scroll={scroll}
                      />
                    </Spin>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane key={"2"} tab={<>{"Customer"}</>}>
                  <div className="table-box">
                    <Spin
                      spinning={companiesListLoader}
                      indicator={
                        <div className="outer-table-loader">
                          <GlobalLoder />
                        </div>
                      }
                    >
                      <Table
                        rowClassName={"incidentTbl"}
                        className="Antdtable-component"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        scroll={scroll}
                      />
                    </Spin>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane key={"3"} tab={<>{"Partner"}</>}>
                  <div className="table-box">
                    <Spin
                      spinning={companiesListLoader}
                      indicator={
                        <div className="outer-table-loader">
                          <GlobalLoder />
                        </div>
                      }
                    >
                      <Table
                        rowClassName={"incidentTbl"}
                        className="Antdtable-component"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        scroll={scroll}
                      />
                    </Spin>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {modalVisible ? (
        <SupplyChainCompList
          setModalVisible={setModalVisible}
          networkData={{
            ...simpleApiPayload,
            companies: idsArray ? idsArray : [id],
            companyName: companyName,
            type: tabKey == '1' ? "Supplier" : tabKey == '2' ? "Customer" : "Partner"
          }}
        />
      ) : null}
      {/* {modalVisible ? (
        <CompaniesListModal
          setModalVisible={setModalVisible}
          networkData={{
            ...simpleApiPayload,
            companies: idsArray ? idsArray : [id],
            companyName: companyName,
            type: type
          }}
        />
      ) : null} */}
    </section>
  );
};
export default memo(CompaniesTable);
