import React from "react";

export const CustomInput = ({
  type,
  id,
  name,
  placeholder,
  onChange,
  value,
  error,
  maxLength,
}) => {
  const numCheck = (e) => {
    const check = ["+", "e", ".", "-"];
    if (type === "number" && check.includes(e.key)) {
      return e.preventDefault();
    } else if (e.target.value?.length > maxLength) {
      return (e.target.value = e.target.value.slice(0, 16));
    }
  };
  return (
    <>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        onKeyDown={numCheck}
        onWheel={(e) => e.target.blur()}
      />
      {error ? <div className="error">{error}</div> : null}
    </>
  );
};
