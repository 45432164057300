import { useState } from "react";
import appconstant from "../../../Constants/appConstansts";
import ImageConst from "../../../Themes/ImageConstant";
import { checkSeverityColor, getDiffTime } from "../../../Utils";
import IncidentsListModal from "../../IncidentsListModal";
import { openIncidentDetail } from "../../../Utils/globalFunction";
import { Modal } from "antd";
import moment from "moment";
const UngcpBreaches = ({ type, incidentCount, header, list, allIds,activeTab, totalIncidentCount, value = "",dataKey, networkData={} }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [allData,setAllData]= useState({
    esg_supply_chain_act: [],
    german_supply_chain_act: [],
    esg_pais: [],
    esg_sdgs: [],
    esg_factors: []    ,
    UNGCP_Breaches: [],
  })
  
  return (
    <>
      <div className="principleBox">
        <div className="headerDiv">
          <span className="principleTitle">
            {
              activeTab == "1" ? 
              type || "" :
              activeTab == "3" ?
              Object.entries(appconstant.impact).find((element) => element[1] == type)?.[0] :
              activeTab == "4" ?
              Object.entries(appconstant.goals).find((element) => element[1] == type)?.[0] :
               type
            }
            <span className="principleSubTitle">
              {" "}
              ( {totalIncidentCount || 0} Incidents)
              {/* {"ungcpBreaches?.humanRights["PRINCIPLE 1"].length"} */}
            </span>
          </span>
          {totalIncidentCount > 2 && (
            <div className="righ-side">
              <a
                onClick={() => {
                  setModalVisible(true);
                  setAllData({
                    esg_supply_chain_act: activeTab == "1" ?  [dataKey]:[],
                    german_supply_chain_act:activeTab == "0" ?  [dataKey]:[],
                    esg_pais:activeTab == "3" ?  [dataKey]:[],
                    esg_sdgs: activeTab == "4" ? [dataKey]:[],
                    UNGCP_Breaches: activeTab == "2" ?  [dataKey]:[],
                    start:networkData.start,
                    end:networkData.end
                  })
                }}
              >
                View All
                <span>
                  <img src={ImageConst.iconser} />
                </span>
              </a>
            </div>
          )}
        </div>
        <div style={{ display: "flex", marginTop: 16 }}>
          <div  className="line-flex-ps"
          ></div>
          <div>
            {
              activeTab == 4 &&
              <div>
                <span>
                  <img
                    src={ImageConst[Object.entries(appconstant.goals).find((element) => element[1] == type)?.[0].toLowerCase().replace(/ /g, '')]}
                  />
                </span>
              </div>
            }
            <div>
              <span className="titleDiv">
                {
                  activeTab == "1" ?
                  value || "" :
                  activeTab == "3" ?
                  Object.entries(appconstant.impact).find((element) => element[1] == type)?.[1] :
                  activeTab == "4" ?
                  Object.entries(appconstant.goals).find((element) => element[1] == type)?.[1] :
                  appconstant.principle[type]
                }
              </span>
            </div>
          </div>
        </div>
        <div className="text-list" style={{ padding: 0 }}>
          {list?.map(({ title, discovery_time, severity_score, id }, index) => {
            return (
              <div className="list list1">
                <span className="indexCircle">{index + 1}</span>
                <a onClick={() => openIncidentDetail(id)}>
                  <img src={Image.ellipse} />
                  {" " + title + " "}
                  <span style={{ color: severity_score.level && checkSeverityColor[severity_score.level.toUpperCase()] }}>({severity_score.level})</span> -
                  <span> {moment(discovery_time).fromNow()} </span>
                </a>
              </div>
            );
          })}
        </div>
      </div>
      {modalVisible && (
        // <Modal open={true} onCancel={()=>setModalVisible(false)}></Modal>
        <IncidentsListModal
          setModalVisible={setModalVisible}
          networkData={{
            ...allData,
            order: -1,
            sort: "discovery_time",
          }}
        />
      )}
    </>
  );
};

export default UngcpBreaches;
