import { SvgIcon } from "@mui/material";

export default function TrashIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66797 4H13.3346M10.668 4L10.4875 3.45871C10.3127 2.93417 10.2253 2.67189 10.0631 2.47799C9.91992 2.30676 9.73602 2.17421 9.5283 2.09252C9.29306 2 9.0166 2 8.46368 2H7.53892C6.986 2 6.70954 2 6.47431 2.09252C6.26658 2.17421 6.08269 2.30676 5.93949 2.47799C5.77734 2.67189 5.68991 2.93417 5.51506 3.45871L5.33464 4M12.0013 4V10.8C12.0013 11.9201 12.0013 12.4802 11.7833 12.908C11.5916 13.2843 11.2856 13.5903 10.9093 13.782C10.4815 14 9.92141 14 8.8013 14H7.2013C6.0812 14 5.52114 14 5.09332 13.782C4.717 13.5903 4.41104 13.2843 4.21929 12.908C4.0013 12.4802 4.0013 11.9201 4.0013 10.8V4M9.33464 6.66667V11.3333M6.66797 6.66667V11.3333"
          stroke="#9A9CA2"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
