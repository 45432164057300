import { call, put, take, takeEvery, takeLatest } from "redux-saga/effects";

import appconstant from "../../Constants/appConstansts";
import {
  Loader,
  onError,
  onFail,
  onSucces,
  onUnAuth,
} from "../../Utils/UnAuthFailFn";
import { toast } from "react-toastify";
import { storage, successToastContainer } from "../../Utils";
import { toast as hotToast } from "react-hot-toast";
import { apiConstants as type } from "../../Constants/apiConstants";
import {
  homeESGIotApi,
  homeESGRiskApi,
  homeHighSeveirtyApi,
  homeSocialMediaCoverageApi,
  incidentAnalyzerBreachesApi,
  incidentAnalyzerSocialMediaCoverageApi,
  incidentByCategory,
  incidentByCategoryAPI,
  portfolioESGRiskApi,
  dashboardDataApi,
  dashboardWatchDataApi,
  breachesAndViolationsAPI,
} from "../api";
import {
  homeHighSeveritySuccess,
  homeRiskScoreSuccess,
} from "../Action/incidents";
import {
  getBreachesViolationDataSuccess,
  getBreachesViolationDataFail
} from "../Action/incidentAnalyzerAction"
const { RoutesPath } = appconstant;

function* incidentAnalyzerUNGCP_BreachesSaga(action) {
  try {
    const data = yield call(incidentAnalyzerBreachesApi, action.data);
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_INCIDENT_ANALYZER_BREACHES_SUCCESS,
        data,
        show: false,
        id: "data1",
        navigationLink: false,
      };

      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* incidentAnalyzerSocialMediaCoverageSaga(action) {
  try {
    const data = yield call(
      incidentAnalyzerSocialMediaCoverageApi,
      action.data
    );
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_INCIDENT_ANALYZER_SOCIAL_MEDIA_COVERAGE_SUCCESS,
        data,
        show: false,
        id: "data1",
        navigationLink: false,
      };

      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* homeESGRiskSaga(action) {
  try {
    const data = yield call(homeESGRiskApi, action.data);
    // for success
    if (data.status === 0) {
      yield put(homeRiskScoreSuccess(data.result.data, action.data));
    }
    // on fail
    else {
      yield call(onFail, {});
    }
  } catch (e) {
    yield call(onError);
  }
}
function* homeHIGHSeveirySaga(action) {
  try {
    const data = yield call(homeHighSeveirtyApi, action.data);
    if (data.status === 0) {
      yield put(homeHighSeveritySuccess(data.result.data, action.data));
    } else {
      yield call(onFail, {});
    }
  } catch (e) {
    yield call(onError);
  }
}
function* HOME_IotSaga(action) {
  try {
    const data = yield call(homeESGIotApi, action.data);
    if (data.status === 0) {
      let obj = {
        type: type.API_HOME_ESG_IOT_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_ESG_IOT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}
function* incidentByCategorySaga(action) {
  try {
    const data = yield call(incidentByCategoryAPI, action.data);
    if (data.status === 0) {
      let obj = {
        type: type.INCIDENT_BY_CATEGORY_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    }
    // on auth fail
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: type.API_ESG_IOT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e, "error");
    yield call(onError);
  }
}

function* SocialMediaReactionsSaga(action) {
  try {
    const data = yield call(homeSocialMediaCoverageApi, action.payload);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.HOME_SOCIAL_MEDIA_REACTIONS_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // on fail
    else {
      let obj = {
        type: type.API_ESG_IOT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    // yield call(onError);
  }
}

function* DashboardDataSaga(action) {
  try {
    const data = yield call(dashboardDataApi, action.payload);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_DASHBOARD_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // on fail
    else {
      let obj = {
        type: type.HOME_ESG_RISK_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
  }
}

function* DashboardWatchlistDataSaga(action) {
  try {
    const data = yield call(dashboardWatchDataApi, action.payload);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_DASHBOARD_WATCH_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield call(onSucces, obj);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // on fail
    else {
      let obj = {
        type: type.HOME_ESG_RISK_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
  }
}

function* breachesViolationSaga(action) {
  try {
    const data = yield call(breachesAndViolationsAPI, action.payload);
    // for success
    if (data.status === 0) {
      let obj = {
        type: type.API_GET_DASHBOARD_WATCH_SUCCESS,
        data,
        navigationLink: false,
        show: false,
        toastId: 1,
      };
      yield put(getBreachesViolationDataSuccess(data.result.data));
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
      yield put(getBreachesViolationDataFail(data.result.data));
    }
    // on fail
    else {
      yield put(getBreachesViolationDataFail(data.result.data));
      
    }
  } catch (e) {
    //if some thing went wrong from api side
    console.log(e, "error");
    yield put(getBreachesViolationDataFail(e));
  }
}

export default function* rootIncidentAnalyzerSaga() {
  yield takeLatest(
    type.API_GET_INCIDENT_ANALYZER_BREACHES_LOAD,
    incidentAnalyzerUNGCP_BreachesSaga
  );
  yield takeLatest(
    type.API_GET_INCIDENT_ANALYZER_SOCIAL_MEDIA_COVERAGE_LOAD,
    incidentAnalyzerSocialMediaCoverageSaga
  );
  yield takeLatest(type.HOME_ESG_RISK_LOAD, homeESGRiskSaga);
  yield takeLatest(type.HOME_HIGH_SEVERITY_LOAD, homeHIGHSeveirySaga);
  yield takeLatest(type.API_HOME_ESG_IOT_LOAD, HOME_IotSaga);
  yield takeLatest(type.INCIDENT_BY_CATEGORY_LOAD, incidentByCategorySaga);
  yield takeLatest(
    type.HOME_SOCIAL_MEDIA_REACTIONS_LOAD,
    SocialMediaReactionsSaga
  );
  yield takeLatest(type.API_GET_DASHBOARD_LOAD, DashboardDataSaga);
  yield takeLatest(
    type.API_GET_DASHBOARD_WATCH_LOAD,
    DashboardWatchlistDataSaga
  );
  yield takeLatest(type.GET_BREACHES_VIOLATION_DATA_LOAD, breachesViolationSaga);
}
