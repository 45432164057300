import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useHover } from '@uidotdev/usehooks';
import VisibleIcon from './VisibleIcon';
import InvisibleIcon from './InvisibleIcon';

function ListItem({ label, isShown, onClick }) {
  const theme = useTheme();
  const [ref, hovering] = useHover();
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '24px',
        transition: 'background-color 0.3s ease',
        backgroundColor: isShown
          ? theme.palette.screenBackground.main
          : theme.palette.gray.A100,
        '&:hover': {
          backgroundColor: theme.palette.gray.A100,
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '6px 16px',
      }}
      ref={ref}
    >
      <Box>
        <Typography variant="body">{label}</Typography>
      </Box>
      <Box
        sx={{
          opacity: hovering || !isShown ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <IconButton onClick={onClick}>
          {isShown ? <VisibleIcon /> : <InvisibleIcon />}
        </IconButton>
      </Box>
    </Box>
  );
}
export default ListItem;
