// 13/7/2023 MM
import { apiConstants as types } from "../../Constants/apiConstants";
const initialState = {
  isLoading: false,
  result: [],
  selectedCollection: null,
  selectedCollectionName: "",
  selectedCollectionIncidents: [],
  deleteCollectionSuccess: false,
  createCollectionData: [],
};

// recuders for save incidence tab
export const saveIncidenceReducer = (state = initialState, action) => {
  let { payload, key } = action;
  switch (action.type) {
    case types.API_GET_COLLECTION_SUCCESS:
      return { ...state, isLoading: false, result: action?.payload?.data };
    case types.API_GET_COLLECTION_LOAD:
      return { ...state, isLoading: true };
    case types.API_CREATE_COLLECTION_LOAD:
      return { ...state, isLoading: true };
    case types.API_CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createCollectionData: payload.data
      };
    case types.API_ADD_TO_COLLECTION_LOAD:
      return { ...state, isLoading: true };
    case types.API_ADD_TO_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.SET_SELECTED_COLLECTION_LOAD:
      return {
        ...state,
        isLoading: false,
        selectedCollection: action.data._id,
        selectedCollectionName: action.data.name,
        selectedCollectionIncidents: action.data.incidentIds
      };

    case types.SET_RENAME_COLLECTION_LOAD:
      return { ...state, isLoading: true };
    case types.SET_RENAME_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case types.UPDATE_RENAME_COLLECTION_SUCCESS:
      let oldResult = state.result ? state.result : [];
      let updateResultIndex = oldResult.findIndex(
        (x) => x._id === action.data.collectionId
      );
      if (updateResultIndex > -1) {
        oldResult[updateResultIndex].name = action.data.name;
      }
      state.selectedCollectionName = action.data.name;
      return {
        ...state,
        isLoading: false,
        result: oldResult,
      };

    case types.API_DELETE_COLLECTION_LOAD:
      return {
        deleteCollectionSuccess: false
      }

    case types.API_DELETE_COLLECTION_SUCCESS:
      return {
        deleteCollectionSuccess: true
      }

    case types.API_DELETE_COLLECTION_FAIL:
      return {
        deleteCollectionSuccess: false
      }

      case types.UPDATE_INCIDENT_COLLECTION_SIDEBAR:
          state[key] = payload
          return { ...state }

    default:
      return { ...state };
  }
};
