import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  from: '',
  to: '',
};
const timeRangeSlice = createSlice({
  name: 'timeRange',
  initialState,
  reducers: {
    setTimeRange: (state, { payload: { from, to } }) => {
      // eslint-disable-next-line no-param-reassign
      state.from = from;
      // eslint-disable-next-line no-param-reassign
      state.to = to;
    },
  },
});
export const { setTimeRange } = timeRangeSlice.actions;
export const timeRangeReducer = timeRangeSlice.reducer;
