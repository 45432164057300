import React, { Suspense, lazy, memo } from "react";
import { Card, Col, Row, Skeleton } from "antd";
import clsx from "clsx";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

import style from "../style.module.css";
import CoverColorPopOver from "./popover";
import Images from "../../../Themes/ImageConstant";
const LineChart = lazy(() => import("./lineChart"));

const PortfolioCard = ({ chartData, loading = false, code = "not", item }) => {
  const navigate = useNavigate();

  const RenderValue = ({ label, value, change, color,changeValue }) => {
    if (label !== "Risk Score")
      return (
        <p className={style.label}>
          {label}
          &nbsp;
          <span>{value}</span>
        </p>
      );
    else {
      return (
        <div className={style.riskScorePs}>
          <p className={style.label}>{label}</p>
          <div className={style.todayText}>
            {change && (
              <>
                <p style={{ color: color }}>Today</p>
                <div className={style.uplodePer} style={{ background: changeValue < 0 ? "#E8F5EC" : "#FCEDED" }}>
                  <span
                    style={{
                      color: changeValue < 0 ? "#1A9C3E" : "#d00000",
                      background: changeValue < 0 ? "#E8F5EC" : "#FCEDED",
                    }}
                  >
                    <img
                      src={changeValue < 0 ? Images.greenArrowSumUp : Images.arrowSumUp}
                    />
                    {change + "%"}
                  </span>
                </div>
              </>
            )}
            <label>{value}</label>
          </div>
        </div>
      );
    }
  };

  const RenderDashboard = ({ item }) => {
    return (
      <Col sx={12}>
        <RenderDashboardCard label={"Risk Score:"} value={item?.risk?.toFixed(1) || 0} change={Math.abs(item?.riskChange?.toFixed(1) || 0)} checkValue={item?.riskChange} />
        <RenderDashboardCard label={"Holdings:"} value={item?.holdings?.length || 0} />
        <RenderDashboardCard label={"Value"} />
      </Col>
    );
  };
  const RenderDashboardCard = ({ label, value, change,checkValue }) => {
    if (label !== "Value")
      return (
        <>
          <div className={style.riskScorePs}>
            <div className={style.riskText}>
              <p>{label}</p>
            </div>
            <div className={style.todayText}>
              {change && (
                <>
                  <p>Today</p>
                  <div className={style.uplodePer} style={{ background: checkValue < 0 ? "#E8F5EC" : "#FCEDED" }}>
                    <span
                      style={{
                        color: checkValue < 0 ? "#1A9C3E" : "#d00000",
                        background: checkValue < 0 ? "#E8F5EC" : "#FCEDED",
                      }}
                    >
                      <img src={checkValue < 0 ? Images.greenArrowSumUp : Images.arrowSumUp} />
                      {change + "%"}
                    </span>
                  </div>
                </>
              )}
              {label == "Holdings:" ?
                <p className={style.label}>
                  <span>{value}</span>
                </p>
                :
                <label >{value}</label>
              }
            </div>
          </div>
        </>
      );
    // else {
    //   return (
    //     // <div className={style.value}>
    //     //   <p>Value</p>
    //     // </div>
    //   );
    // }
  };

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      className={style.card}
      style={{
        background: item?.cardColor?.background,
        color: item?.cardColor?.color,
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.stopPropagation();
        navigate("/portfolioDetails", {
          state: {
            id: item?._id,
            backgroundCardColor: item?.cardColor?.background,
            colorText: item?.cardColor?.color
          },
        });
      }}
    >
      <Skeleton loading={loading} active={true} paragraph={{ rows: 7 }}>
        <div className={style.header}>
          <div className={style.cardTitleName}>
            <span>{item?.name}</span>
          </div>
          <div className={style.threeDots}>
            {code == "portfolio" ? (
              <CoverColorPopOver
                active={item?.cardColor?.background}
                item={item}
              />
            ) : null}
          </div>
        </div>
        {code == "portfolio" ? (
          <>
            <RenderValue label={"Risk Score"} value={item?.risk.toFixed(1) || 0} change={Math.abs(item?.riskChange?.toFixed(1))} color={item?.cardColor?.color} changeValue={item?.riskChange} />
            <Row className={style.label} gutter={10}>
              <Col sx={12}>
                <>
                  <RenderValue
                    label={"Holdings:"}
                    value={item?.holdings?.length || 0}
                  />
                  <RenderValue
                    label={"Incidents:"}
                    value={item?.incidentCount || 0}
                  />
                  <RenderValue label={"Severity Score:"} value={item?.severity_score ? item?.severity_score.toFixed() : 0} />
                  <RenderValue label={"Breaches:"} value={item?.breaches || 0} />
                </>
              </Col>

              <Col sx={12} className={clsx([style.text])}>
                <div style={{ color: item?.cardColor?.Portfolio_Color }}>
                  {/* Portfolio Value */}
                </div>
                <div style={{ color: item?.cardColor?.ECG__Color }}>
                  {/* ESG Risk */}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <RenderDashboard item={item} />
        )}

        {/* <Row className={style.chart}>
          <Suspense fallback={""}>
            <LineChart data={chartData} />
          </Suspense>
        </Row> */}
        <p className={style.createdAt} style={{ color: item?.cardColor?.color == "#fff" ? item?.cardColor?.color : "#4E515C" }}>
          Created: {moment(item?.createdAt).format("MMM. DD, YYYY")}
        </p>
      </Skeleton>
    </Card>
  );
};

export default memo(PortfolioCard);
