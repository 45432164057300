import { put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import appconstant from "../Constants/appConstansts";
import { navigate } from "./dispatchNavigate";
import { storage } from ".";

const { RoutesPath } = appconstant;

export function* onError(type, id) {
  // yield put({ type });
  // toast.error(appconstant.STWW, { toastId: "error" });
}

export function* onFail({ type, message, show = true, status }) {
  yield put({ type, message });
  show && toast.error(message, { toastId: "fail" });
}

export function* Loader(type) {
  yield put({ type });
}

export function* onSucces({
  type,
  data,
  show = true,
  navigationLink,
  id = "success",
  navState,
}) {
  navigationLink && navigate(navigationLink, navState);
  yield put({ type: type, payload: data.result });
  show && toast.success(data.result.message, { toastId: id });
}

export function onUnAuth(message) {
  localStorage.clear();
  sessionStorage.clear();
  navigate(RoutesPath.login);
  toast.error(message, {
    toastId: 0
  });
}
