import React, { useState, useEffect, useCallback } from "react";
import style from "../../Screens/searchResult/index.css";
import Images from "../../Themes/ImageConstant";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AutoComplete, Breadcrumb, Modal, Pagination, Space, Tag } from "antd";
import { Select } from "antd";
import { Collapse } from "antd";
import { Tooltip } from "antd";
import { Table } from "antd";
import CustomDropDown from "../../Componets/InputFeild/customDropDown";
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  TagsFilled,
  UpOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryDataforSupplyChain,
  getIndustriesAction,
  incidentSeverityChartAction,
  riskChartAction,
  riskCompanyAction,
  supplyChainAction,
  getSupplyChainDataAction,
  findPathAction,
  deleteUploadedDataLoad,
} from "../../Redux/Action/supplyChainAction";
import {
  calculatePreviousDate,
  checkRiskColor,
  dateButtonObj,
  getRiskVal,
} from "../../Utils";
import DatePicker from "../../Componets/DatePicker";
import moment from "moment";
import {
  getCategoriesAction,
  getEsgCountriesAction,
  getExportSupplyAction,
} from "../../Redux/Action/incidents";
import Icon from "@ant-design/icons/lib/components/Icon";
import TableColumn from "../../Componets/TableColumn";
import IncidentsList from "../incidentsList";
import LineChart from "./components/lineChart";
import { Checkbox } from "antd";
import { color } from "d3";
import PieChartComponent from "./components/pieChart";
import IncidentSeverityGraph from "./components/incidentSeverityGraph";
import IncidentsNumberGraph from "./components/incidentsNumberGraph";
import SupplyTableLineChart from "./components/supplyTableLineChart";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import CustomPagination from "../../Componets/Pagination";
import SupplyPagination from "./components/paginateSupply";
import IncidentsListModal from "../IncidentsListModal";
import useDebouncedApiCall from "../../hook/useDebouncedSearch";
import { resetSearchPageDataAction, searchCompanyListActionLoad } from "../../Redux/Action/global";
import ImageConst from "../../Themes/ImageConstant";
import ConfirmationModal from "../../Componets/ConfirmationModal";

const renderTitle = (title) => (
  <span>
    {title}
    <a
      style={{
        float: "right",
      }}
      href="https://www.google.com/search?q=antd"
      target="_blank"
      rel="noopener noreferrer"
    >
      more
    </a>
  </span>
);
const renderItem = (title, count) => ({
  value: title,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {title}
      <span>
        <UserOutlined /> {count}
      </span>
    </div>
  ),
});
const options = [
  {
    label: renderTitle("Libraries"),
    options: [
      renderItem("AntDesign", 10000),
      renderItem("AntDesign UI", 10600),
    ],
  },
  {
    label: renderTitle("Solutions"),
    options: [
      renderItem("AntDesign UI FAQ", 60100),
      renderItem("AntDesign FAQ", 30010),
    ],
  },
  {
    label: renderTitle("Articles"),
    options: [renderItem("AntDesign design language", 100000)],
  },
];

let series = (chart_Data) => [
  {
    data: chart_Data.map((entry) => entry.companyRisk),
  },
];
let series2 = (data) => {
  return [
    {
      data: data,
    },
  ];
};

const { Panel } = Collapse;

const text = <span>Download</span>;

const tableColumn = [
  "Company",
  "ESG Risk",
  "Risk Change",
  "Incidents",
  "Relationship Type",
  "Industry",
  "Location",
];

const getCompanyRelationLength = (array, type) =>
  array?.filter((val) => val.relation == type);

const currentIndexExpand = 0;
const SearchResult = (props) => {
  const [modal2Open, setModal2Open] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { searchCompaniesData } = useSelector((state) => state?.global);
  const { searchTerm, handleSearch } = useDebouncedApiCall(
    searchCompanyListActionLoad,
    { offset: 0, limit: 20 }
  );
  const record = location?.state?.record;
  const [countryName, setCountryName] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [companyModalName, setCompanyModalName] = useState("");
  const [companyModalId, setCompanyModalId] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [sortOrder, setSortOrder] = useState();
  const [sort_By, setSort_by] = useState();

  const [modalVisible, setModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState({});

  // TABLE COLUMN
  const [tableHeaderArray, setTableHeaderArray] = useState(tableColumn);

  const dispatch = useDispatch();
  const lastDate = dateButtonObj[3].type;
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedRelation, setSelectedRelation] = useState([]);

  const handleChange = (value) => { };

  const [activeCategoriesItemNumber, setActiveCategoriesItemNumber] =
    useState(0);
  const [networkData, setNetworkData] = useState({
    end: moment().format("YYYY-MM-DD"),
    start: moment(calculatePreviousDate(new Date(), lastDate)).format(
      "YYYY-MM-DD"
    ),
  });
  const {
    pagination,
    companies,
    countryList,
    resultData,
    industries,
    totalIncidents,
    severityData,
    totalHigh,
    riskCharts,
    riskCompany,
  } = useSelector((state) => state?.supplyChainReducer);
  const { supplyChainEsgCountry } = useSelector(
    (state) => state?.incidentReducer
  );

  const _categoriesListStatic = useSelector(
    (state) => state?.incidentReducer?._categoriesListStatic
  );

  const [categoriesList, setCategoriesList] = useState([]);
  // const [categories, setCategories] = useState(_categoriesListStatic);

  const [industriesList, setIndustriesList] = useState(industries);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [imageId, setImageId] = useState(null);
  const [selectedTier, setSelectedTier] = useState();
  const [searchArr, setSearchArr] = useState([]);
  const [textValue, settextValue] = useState("");
  const [findPathDataList, setFindPathDataList] = useState();

  useEffect(() => {
    let a = JSON.parse(JSON.stringify(_categoriesListStatic));
    setCategoriesList(a);
  }, [_categoriesListStatic]);

  useEffect(() => {
    setIndustriesList(industries);
  }, [industries]);

  useEffect(() => {
    localStorage.removeItem("graphDatePickerActiveButton");
    localStorage.removeItem("graphDatePickerActiveButtonTitle");
    if (record) {
      setCompanyName(record.label);
      setCompanyId(record?.id);
      setCompanyModalName(record.label);
      setCompanyModalId(record?.id);
      setImageId(record?.id);
      callApi(networkData, record?.id);
      callListingAction();
      dispatch(getCategoriesAction());
      dispatch(getIndustriesAction());
      dispatch(getCountryDataforSupplyChain());
      dispatch(getEsgCountriesAction());
    } else {
      navigate("/supplychain");
      dispatch(resetSearchPageDataAction());
    }
  }, []);

  const callApi = (data, companyId, search) => {
    if (search) {
      dispatch(supplyChainAction({ ...data, companyId }));
    } else {
      dispatch(supplyChainAction({ ...data, companyId }));
    }
    dispatch(riskCompanyAction({ ...data, companyId }));
    dispatch(riskChartAction({ ...data, companyId }));
    dispatch(incidentSeverityChartAction({ ...data, companyId }));
  };

  useEffect(() => {
    if (selectedSearch.id) {
      setCompanyName(selectedSearch.label);
      setCompanyId(selectedSearch.id);
      setCompanyModalName(selectedSearch.label);
      setCompanyModalId(selectedSearch.id);
      setImageId(selectedSearch?.id);
      callApi(networkData, selectedSearch.id);
      callListingAction(
        limit,
        searchText,
        sortOrder,
        sort_By,
        networkData,
        selectedSearch.id
      );
    }
  }, [selectedSearch]);

  const handleSearchOperation = (SelectedData) => {
    if (searchCompaniesData?.data?.length !== 0) {
      setSelectedSearch(SelectedData);
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: "80px",
      key: "",
      render: (name, item, index) => (
        <div className="index-tabel-ps">
          {(pagination?.currentPage - 1) * limit + index + 1}
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "name",
      key: "name",
      width: "160px",
      className: `company-supplyChain ${!tableHeaderArray.includes("Company") ? "displayNone" : ""
        }`,

      render: (name, item) => {
        return (
          <span
            className="sup-data-Um"
            onClick={() => {
              navigate("/companyPage", { state: { id: item.id } });
            }}
          >
            {item?.incidents >= 5 ? (
              <img src={Images.red} />
            ) : item?.incidents == 0 ? (
              <img src={Images.green} />
            ) : (
              <img src={Images.orange} />
            )}
            {item?.name}
          </span>
        );
      },
    },
    {
      title: "ESG Risk",
      dataIndex: "risk",
      key: "risk",
      width: "120px",

      className: `${!tableHeaderArray.includes("ESG Risk") ? "displayNone" : ""
        }`,
      sorter: true,
      render: (name, item) => {
        return (
          <>
            <div
              style={{
                backgroundColor: checkRiskColor[getRiskVal(item?.risk)],
                display: "flex",
                justifyContent: "center",
                height: "40px",
                alignItems: "center",
              }}
              className="red-esg-risk"
            >
              <p>{(item?.risk && item?.risk.toFixed(2)) || "0"}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "Risk Change",
      dataIndex: "totalIncidents",
      key: "totalIncidents",
      width: "120px",
      className: ` riskchange ${!tableHeaderArray.includes("Risk Change") ? "displayNone" : ""
        }`,
      sorter: false,
      render: (name, item) => {
        let value = calculateRisCoulmn(item.riskTimeline);
        return (
          <>
            <div className="risk-change-ten-per">
              <span
                style={{
                  color: value < 0 ? "#1A9C3E" : "#d00000",
                  background: value < 0 ? "#E8F5EC" : "#FCEDED",
                }}
              >
                <img
                  src={value < 0 ? Images.greenArrowSumUp : Images.arrowSumUp}
                />
                {Math.abs(value || 0) + "%"}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "totalIncidents",
      key: "totalIncidents",
      width: "160px",
      className: ` riskchange ${!tableHeaderArray.includes("Risk Change") ? "displayNone" : ""
        }`,
      sorter: false,
      render: (name, item) => {
        let value = calculateRisCoulmn(item.riskTimeline);
        return (
          <>
            <div className="">
              <SupplyTableLineChart
                data={"chartData"}
                colors={value < 0 ? ["#72EB93", "green"] : ["#d00000", "red"]}
                series={series2(item.riskTimeline)}
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Incidents",
      dataIndex: "incidents",
      key: "incidents",
      className: `${!tableHeaderArray.includes("Incidents") ? "displayNone" : ""
        }`,
      sorter: true,
      render: (name, item) => {
        return (
          <span
            className="sup-data-Um"
            onClick={() => {
              if (item.incidents > 0) {
                setModalVisible(true);
                setCompanyModalId(item.id);
                setCompanyModalName(item.name);
              }
            }}
          >
            {item.incidents}
          </span>
        );
      },
    },
    {
      title: "Relationship Type",
      dataIndex: "relation",
      width: "160px",
      key: "relation",
      className: `${!tableHeaderArray.includes("Relationship Type") ? "displayNone" : ""
        }`,
      render: (relation) => (
        <div>

          {relation}
        </div>
      ),
    },
    {
      title: "Industry",
      dataIndex: "industries",
      key: "industries",
      width: "160px",
      className: `${!tableHeaderArray.includes("Industry") ? "displayNone" : ""
        }`,
      render: (industries) => <div>{industries?.name}</div>,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: "160px",
      key: "location",
      className: `${!tableHeaderArray.includes("Location") ? "displayNone" : ""
        }`,
      render: (location) => <div>{<span>{location}</span>}</div>,
    },
  ];

  const onDatePickerChange = (startDate, previousDate) => {
    if (startDate && previousDate) {
      let date = networkData;
      date.end = previousDate;
      date.start = startDate;
      setNetworkData(date);
      callApi(networkData, companyId);
      callListingAction(limit, searchText, sortOrder, sort_By, date);
    }
  };

  const callDownloadApi = () => {
    dispatch(
      getExportSupplyAction({
        start: networkData.start,
        end: networkData.end,
        id: companyId,
      })
    );
  };

  function updateStatusBasedOnParent(obj) {
    let newObj = obj;
    for (const factor of obj.value) {
      const parentStatus = obj.status;
      for (const subchild of factor.data) {
        subchild.status = parentStatus;
      }
    }
    return newObj;
  }

  const searchArrFunc = (_categoriesList) => {
    let arr = [];
    _categoriesList.map((x) => {
      return x.value.map((j, i) => {
        return j.data.map((k, l) => {
          if (k.status) {
            arr.push(k);
          }
        });
      });
    });
    setTimeout(() => {
      setSearchArr(arr);
    }, 100);
  };

  const relationshipArr = [
    { label: "Customer", value: "customer", status: false },
    { label: "Supplier", value: "supplier", status: false },
    { label: "Partner", value: "partner", status: false },
  ];
  const tierArr = [
    { label: "Tier-1 + Tier-2 + Tier-3", value: 3 },
    { label: "Tier-1 + Tier-2", value: 2 },
    { label: "Tier-1", value: 1 },
  ];
  const handleCategoryChecked = (e, key) => {
    let index = categoriesList.findIndex((x) => x.name === e.name);
    categoriesList[index].status = !categoriesList[index].status;
    categoriesList[index] = updateStatusBasedOnParent(categoriesList[index]);
    searchArrFunc(categoriesList);
    setCategoriesList(JSON.parse(JSON.stringify(categoriesList)));
  };

  function hasFalseValue(obj) {
    for (const factor of obj.value) {
      for (const subchild of factor.data) {
        if (subchild.status === false) {
          return false; // Return true if any subchild has a value of false
        }
      }
    }
    return true;
  }

  const handleSubCategoryChecked = (e, parentIndex, key) => {
    let index = categoriesList[activeCategoriesItemNumber].value[
      parentIndex
    ].data.findIndex((x) => x.name == e.name);
    categoriesList[activeCategoriesItemNumber].value[parentIndex].data[
      index
    ].status =
      !categoriesList[activeCategoriesItemNumber].value[parentIndex].data[index]
        .status;
    const hasFalse = hasFalseValue(categoriesList[activeCategoriesItemNumber]);
    categoriesList[activeCategoriesItemNumber].status = hasFalse;
    searchArrFunc(categoriesList);
    setCategoriesList(JSON.parse(JSON.stringify(categoriesList)));
  };

  const findParentByChildId = (parent, childId) => {
    if (Array.isArray(parent)) {
      for (const item of parent) {
        const result = findParentByChildId(item, childId);
        if (result) {
          return result;
        }
      }
    } else if (typeof parent === "object") {
      if (parent.value && Array.isArray(parent.value)) {
        for (const item of parent.value) {
          if (item.data && Array.isArray(item.data)) {
            for (const dataItem of item.data) {
              if (dataItem.id === childId) {
                dataItem.status = !dataItem.status;
                let ind = categoriesList.findIndex(
                  (x) => x.name === parent.name
                );
                categoriesList[ind].status = false;
                setCategoriesList(JSON.parse(JSON.stringify(categoriesList)));
                return categoriesList;
              }
            }
          }
        }
      }
    }

    return null;
  };

  const handleCloseClicked = (obj, e) => {
    const parentObject = findParentByChildId(categoriesList, obj.id);
    searchArrFunc(parentObject);
  };

  const calculateRisCoulmn = (data, key) => {
    // Extracting the company risks from the last two entries
    const lastCompanyRisk1 = data.length > 1 ? data[data.length - 2] : 0;
    const lastCompanyRisk2 = data.length > 0 ? data[data.length - 1] : 0;

    // Calculating the percentage change in risk
    const riskPercentageChange =
      ((lastCompanyRisk2 - lastCompanyRisk1) /
        (lastCompanyRisk1 > 0 ? lastCompanyRisk1 : 1)) *
      100;
    return riskPercentageChange.toFixed(2);
  };

  const calculateRisk = (data, key) => {
    // Extracting the company risks from the last two entries
    const lastCompanyRisk1 =
      data.length > 1 ? data[data.length - 2]?.companyRisk : 0;
    const lastCompanyRisk2 =
      data.length > 0 ? data[data.length - 1]?.companyRisk : 0;

    // Calculating the percentage change in risk
    const riskPercentageChange =
      ((lastCompanyRisk2 - lastCompanyRisk1) /
        (lastCompanyRisk1 > 0 ? lastCompanyRisk1 : 1)) *
      100;
    return riskPercentageChange.toFixed(2);
  };

  const ChartComponent = ({ data, title, count }) => {
    if (data) {
      let value = calculateRisk(data);
      return (
        <div className="supply-chain-risk-grph-ps">
          <h5>{title}</h5>
          <div className={`today-per-ps ${value < 0 && "green-ps-map"}`}>
            <p>Today</p>
            {data && (
              <>
                <span>
                  {value != 0 && (
                    <img
                      src={
                        value > 0 ? Images.arrowSumUp : Images.greenArrowSumUp
                      }
                    />
                  )}
                  {Math.abs(value || 0) + "%"}
                </span>
                <h6>
                  {data ? data[data.length - 1]?.companyRisk.toFixed(2) : 0}
                </h6>
              </>
            )}{" "}
          </div>
          <div className="apple-map-supply">
            <LineChart
              data={"chartData"}
              colors={value > 0 ? ["#d00000", "red"] : ["#1A9C3E", "#1A9C3E"]}
              series={series(data)}
            />
          </div>
          <div className="bott-incidents-ps">
            <p>{`${count} ${count > 1 ? " Incidents" : " Incident"}`}</p>
          </div>
        </div>
      );
    }
  };
  const handleIndustries = (e, obj) => {
    setSelectedIndustries(obj);
  };

  const handleRelation = (e, obj) => {
    setSelectedRelation(obj);
  };

  const getCategoriesArr = () => {
    let arr = [];
    if (searchArr.length > 0) {
      for (let i in searchArr) {
        arr.push(searchArr[i].id);
      }
    }
    return arr;
  };
  const getRelation = () => {
    let arr = [];
    if (selectedRelation.length > 0) {
      for (let i in selectedRelation) {
        arr.push(selectedRelation[i].label);
      }
    }
    return arr;
  };
  const getIndustries = () => {
    let arr = [];
    if (selectedIndustries.length > 0) {
      for (let i in selectedIndustries) {
        arr.push(selectedIndustries[i].id);
      }
    }
    return arr;
  };
  const getLocation = () => {
    let arr = [];
    if (selectedCountries.length > 0) {
      for (let i in selectedCountries) {
        arr.push(selectedCountries[i].label);
      }
    }
    return arr;
  };

  const callListingAction = (
    limitData = limit,
    search = searchText,
    sort = sortOrder,
    sortBy = sort_By,
    date,
    company = companyId || record?.id
  ) => {
    let data = {
      companyId: company,
      start: date ? date.start : networkData.start,
      end: date ? date.end : networkData.end,
      limit: limitData,
      esg_categories: getCategoriesArr(), // id of esg_factors, esg_sdgs or esg_pais
      relation: getRelation(), // customer, supplier, partner
      tier: "", // should be number between 1, 2 or 3
      industries: getIndustries(), // industry id example: "HC-BP"
      locations: getLocation(), // country_name of locations
      sort: sort ? sortBy : null,
      order: sort ? (sort == "ascend" ? 1 : sort !== 0 ? -1 : null) : null,
      search: search,
      offset: offset,
    };

    dispatch(getSupplyChainDataAction({ data }));
  };

  const clearAll = (
    limitData = limit,
    search = searchText,
    sort = sortOrder,
    sortBy = sort_By
  ) => {
    let data = {
      companyId: companyId || record?.id,
      start: networkData.start,
      end: networkData.end,
      limit: limitData,
      esg_categories: [], // id of esg_factors, esg_sdgs or esg_pais
      relation: [], // customer, supplier, partner
      tier: "", // should be number between 1, 2 or 3
      industries: [], // industry id example: "HC-BP"
      locations: [], // country_name of locations
      sort: sort ? sortBy : null,
      order: sort ? (sort == "ascend" ? 1 : -1) : null,
      search: search,
      offset: offset,
    };
    setSelectedCountries([]);
    setSearchArr([]);
    setCategoriesList(JSON.parse(JSON.stringify(_categoriesListStatic)));
    setSelectedIndustries([]);
    setSelectedRelation([]);
    setSelectedTier();
    dispatch(getSupplyChainDataAction({ data }));
  };

  const onChangeTable = (pageinate, obj, sortData) => {
    setSort_by(sortData.columnKey);
    setSortOrder(sortData.order);

    callListingAction(
      limit,
      searchText,
      sortData.order == undefined ? 0 : sortData.order,
      sortData.columnKey
    );
  };
  const [number, setNumber] = useState();
  return (
    <div className="side-padding">
      <div className="main-layout table-layout">
        <div className="supply-chain-container">
          <section className="supply-chain-top-ps ">
            <div className="supply-chain-breadcrumb">
              <div className="breadcrmbs">
                <Breadcrumb>
                  <Breadcrumb.Item key="1" className="inc-bad-link">
                    <Link to="/supplychain" onClick={() => dispatch(resetSearchPageDataAction())}>Supply chain </Link>{" "}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="3" className="detail-lie-link">
                    {companyName}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>

              <div className="social-media-search supply-chain-search-ps">
                <div className="autocomplete-input-search">
                  <AutoComplete
                    popupClassName="certain-category-search-dropdown"
                    popupMatchSelectWidth={500}
                    className="serchmedia-icon"
                    size="large"
                    onSearch={handleSearch}
                    options={searchCompaniesData?.data}
                    onSelect={(e, selectedOption) => {
                      const { label } = selectedOption;
                      setCountryName(label);
                      setSelectedData(selectedOption);
                      handleSearchOperation(selectedOption);
                    }}
                    onChange={(e, i) => {
                      setSelectedData(i);
                      setCountryName(e);
                    }}
                    value={countryName}
                  >
                    <Input.Search
                      size="large"
                      placeholder="Search company supply chain"
                    />
                  </AutoComplete>
                </div>
              </div>
            </div>
          </section>

          <div className="heading-ap bottom-space-supply-ps ">
            <div className="main-top-apple">
              <h1>
                <span>
                  <img
                    style={{ height: 50, width: 50, objectFit: "contain" }}
                    src={`https://logos-svg.komo.systems/${imageId}.png`}
                    alt="companyImage"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = ImageConst.Apple;
                    }}
                  />
                </span>{" "}
                {companyName}
              </h1>
            </div>
            <div className="top-checkbox">
              <DatePicker
                onDatePickerChange={onDatePickerChange}
                lastDate={lastDate}
              />
            </div>
          </div>
          {resultData?.selfUploaded && (
            <div className="own-supply-chain-ps">
              <div
                className="supply-cahin-message-ps"
                onClick={() => setVisible(true)}
              >
                <p>
                  {`To remove your own supply chain list for ${companyName}`}
                  <span> Click here </span>{" "}
                </p>
              </div>
            </div>
          )}
          <section>
            <div className="all-grid-sys ">
              <div className="grid-comp">
                <h6>Supply Chain Companies</h6>
                <p>{resultData?.countData?.allCompaniesCount || "0"}</p>
              </div>
              <div className="supp-grid-sys">
                <p>Suppliers</p>
                <span>{resultData?.countData?.suppliers || "0"}</span>
              </div>

              <div className="supp-grid-sys">
                <p>Customers</p>
                <span>{resultData?.countData?.customers || "0"}</span>
              </div>

              <div className="supp-grid-sys">
                <p>Partners</p>
                <span>{resultData?.countData?.partners || "0"}</span>
              </div>
            </div>
          </section>

          <section>
            <div className="risk-per-comp">
              <div className="row">
                <div className="col-lg-6">
                  <div className="supply-chain-left-side-ps">
                    {riskCharts.supplyChain && (
                      <ChartComponent
                        data={riskCharts.supplyChain}
                        title={"Supply Chain Risk"}
                        count={riskCharts.countData.total}
                      />
                    )}
                    {riskCharts.suppliers && (
                      <ChartComponent
                        data={riskCharts.suppliers}
                        title={"Suppliers Risk"}
                        count={riskCharts.countData.suppliers}
                      />
                    )}
                    {riskCharts.customers && (
                      <ChartComponent
                        data={riskCharts.customers}
                        title={"Customers Risk"}
                        count={riskCharts.countData.customers}
                      />
                    )}
                    {riskCharts.partners && (
                      <ChartComponent
                        data={riskCharts.partners}
                        title={"Partners Risk"}
                        count={riskCharts.countData.partners}
                      />
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="risk-per-company-right-ps">
                    <h5>Risk Per Company</h5>
                    <div className="ellipse-graph-ps">
                      <PieChartComponent
                        allData={riskCompany?.data}
                        risk={
                          riskCompany?.risk ? Math.round(riskCompany?.risk) : 0
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="number-of-incidents-ps">
              <div className="row">
                <div className="col-lg-6">
                  <div className="incidents-number-and-severity">
                    <h5>Incidents Number & Severity</h5>
                    <div className="lincidents-line-chart">
                      <div className="line-chart-box-ps">
                        <div className="total-num-incidents-ps">
                          <div>
                            <img src={Images.boltSupply} />
                          </div>
                          <div className="collection-incidents">
                            <h6>Incidents Number</h6>
                            <p>{`${totalIncidents} Incident`}</p>
                          </div>
                        </div>
                        <div className="line-chart-supply">
                          <IncidentsNumberGraph allData={severityData} />
                        </div>
                      </div>

                      <div className="line-chart-box-ps">
                        <div className="total-num-incidents-ps">
                          <div>
                            <img src={Images.boltSupply} />
                          </div>
                          <div className="collection-incidents">
                            <h6>Incidents Severity</h6>
                            <p>{`${totalHigh} High`}</p>
                          </div>
                        </div>
                        <div className="donut-chart-supply">
                          <IncidentSeverityGraph data={severityData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="incidents-number-and-severity">
                    <h5>Top High Risks</h5>
                    <div className="high-risk-suppliers-ps">
                      {resultData?.topData?.suppliers?.length > 0 && (
                        <div className="first-suppli">
                          <div className="incidents-suppliers">
                            <h6>Top High Risk Suppliers</h6>
                            <p>Incidents</p>
                          </div>
                          {resultData?.topData?.suppliers.map((x, i) => {
                            return (
                              <div
                                className={`hon-precision ${i == 0 && "mt-3"}`}
                              >
                                <div className="hon-text">
                                  <p
                                    onClick={() => {
                                      navigate("/companyPage", {
                                        state: { id: x.id },
                                      });
                                    }}
                                  >
                                    <span>{i + 1}</span>
                                    {x.name}
                                  </p>
                                </div>
                                <div className="num-text">
                                  <p
                                    onClick={() => {
                                      setModalVisible(true);
                                      setCompanyModalId(x.id);
                                      setCompanyModalName(x.name);
                                    }}
                                  >
                                    {x.totalIncidents}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {resultData?.topData?.customers?.length > 0 && (
                        <div className="first-suppli">
                          <div className="incidents-suppliers">
                            <h6>Top High Risk Customers</h6>
                            <p>Incidents</p>
                          </div>
                          {resultData?.topData?.customers.map((x, i) => {
                            return (
                              <div
                                className={`hon-precision ${i == 0 && "mt-3"}`}
                              >
                                <div className="hon-text">
                                  <p
                                    onClick={() =>
                                      navigate("/companyPage", {
                                        state: { id: x.id },
                                      })
                                    }
                                  >
                                    <span>{i + 1}</span>
                                    {x.name}
                                  </p>
                                </div>
                                <div className="num-text">
                                  <p
                                    onClick={() => {
                                      setModalVisible(true);
                                      setCompanyModalName(x.name);
                                      setCompanyModalId(x.id);
                                    }}
                                  >
                                    {x.totalIncidents}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {resultData?.topData?.partners?.length > 0 && (
                        <div className="first-suppli">
                          <div className="incidents-suppliers">
                            <h6>Top High Risk Partners</h6>
                            <p>Incidents</p>
                          </div>
                          {resultData?.topData?.partners.map((x, i) => {
                            return (
                              <div
                                className={`hon-precision ${i == 0 && "mt-3"}`}
                              >
                                <div className="hon-text">
                                  <p
                                    onClick={() =>
                                      navigate("/companyPage", {
                                        state: { id: x.id },
                                      })
                                    }
                                  >
                                    <span>{i + 1}</span>
                                    {x.name}
                                  </p>
                                </div>
                                <div className="num-text">
                                  <p
                                    onClick={() => {
                                      setModalVisible(true);
                                      setCompanyModalName(x.name);
                                      setCompanyModalId(x.id);
                                    }}
                                  >
                                    {x.totalIncidents}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {resultData?.topData?.industries?.length > 0 && (
                        <div className="first-suppli">
                          <div className="incidents-suppliers">
                            <h6>Top High Risk Industries</h6>
                            <p>Incidents</p>
                          </div>
                          {resultData?.topData?.industries?.map((x, i) => {
                            return (
                              <div
                                className={`hon-precision ${i == 0 && "mt-3"}`}
                              >
                                <div className="hon-text">
                                  <p>
                                    <span>{i + 1}</span>
                                    {x.name}
                                  </p>
                                </div>
                                <div className="num-text">
                                  <p>{x.totalIncidents}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="filt-bg ">
            <div className="filters-sec">
              <div className="heding-filter">
                <h5>Filters</h5>
              </div>
              <div className="clear-text">
                <h5 className="downl-btn" onClick={() => clearAll()}>
                  Clear filters
                </h5>
              </div>
            </div>
            <div className="all-filt-drop">
              <div class="grid-container-accor mt-3">
                <div className="grid-inner-accor">
                  <div className="text-comp">
                    <h6> Category </h6>
                    <div className="combo-box">
                      <div className="top-checkbox supply-filter border-line-ps">
                        <input
                          value={
                            searchArr.length > 0
                              ? `${searchArr.length} ${searchArr.length > 1
                                ? "categories"
                                : "category"
                              } selected `
                              : ""
                          }
                          onClick={() => setModal2Open(true)}
                          className="categories-box"
                          type="text"
                          placeholder="Select categories"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid-inner-accor">
                  <div className="text-comp">
                    <h6>Company Relationship Type </h6>
                    <div className="combo-box">
                      <div className="top-checkbox supply-filter border-line-ps subClass tierClass">
                        <Select
                          onChange={(e, i) => handleRelation(e, i)}
                          placeholder={"Select relationship type"}
                          options={relationshipArr}
                          mode="multiple"
                          style={{ width: "100%" }}
                          value={selectedRelation}
                        ></Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-inner-accor">
                  <div className="text-comp">
                    <h6>Industry </h6>
                    <div className="combo-box">
                      <div className="top-checkbox supply-filter subClass tierClass">
                        <Select
                          onChange={(e, i) => handleIndustries(e, i)}
                          placeholder={"Select Industries"}
                          options={industriesList}
                          mode="multiple"
                          style={{ width: "100%" }}
                          value={selectedIndustries}
                        ></Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-inner-accor">
                  <div className="text-comp">
                    <h6>Company Location </h6>
                    <div className="combo-box">
                      <div className="top-checkbox supply-filter subClass tierClass">
                        <Select
                          onChange={(e, i) => setSelectedCountries(i)}
                          placeholder={"Select locations"}
                          options={supplyChainEsgCountry}
                          mode="multiple"
                          style={{ width: "100%" }}
                          value={selectedCountries}
                        ></Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="apply-btn">
                <button onClick={() => callListingAction()}>Apply</button>
              </div>
            </div>

            <div className="uploade-my-list-modal">
              <Modal
                title="Select Categories"
                className="uploade-chain select-categories-modal"
                centered
                maskClosable={true}
                onCancel={() => {
                  settextValue("");
                  setModal2Open(false);
                }}
                open={modal2Open}
                footer={[
                  <button
                    className="can-btn"
                    onClick={() => {
                      setModal2Open(false);
                      settextValue("");
                    }}
                  >
                    Cancel
                  </button>,
                  <button
                    className="rest-btn"
                    onClick={() => {
                      setModal2Open(false);
                      settextValue("");
                    }}
                  >
                    Done
                  </button>,
                ]}
              >
                <div className="select-categ-container">
                  <div className="search-actegories-ps">
                    {searchArr.length == 0 && (
                      <input
                        type="text"
                        onChange={(e) => settextValue(e.target.value)}
                        value={textValue}
                      />
                    )}
                    <img
                      className="search-actegories-psimg"
                      src={Images.searchIcons}
                    />
                    {searchArr.length > 0 && (
                      <div className="tagViewDiv">
                        {searchArr.map((a) => {
                          return (
                            <div className="energy-mang-ps">
                              <span>{a.name}</span>
                              <img
                                className="energy-mang-psimg"
                                onClick={() => handleCloseClicked(a)}
                                src={Images.xmark}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="environment-factor">
                    <div className="env-factor-div">
                      {categoriesList.map((x, index) => {
                        return (
                          <div
                            className="right-search-section-ps"
                            onClick={() => setActiveCategoriesItemNumber(index)}
                            style={{
                              backgroundColor:
                                activeCategoriesItemNumber == index
                                  ? "#f7f8fa"
                                  : "#FFFFFF",
                            }}
                          >
                            <div className="esg-factors-ps active-item ">
                              <div className="d-flex ">
                                <Checkbox
                                  checked={x.status}
                                  onChange={() =>
                                    handleCategoryChecked(x, "parent")
                                  }
                                ></Checkbox>
                                <p> {x.name}</p>
                              </div>
                              <div>
                                <img src={Images.chevronRight} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="env-factor-divNew">
                      {categoriesList &&
                        categoriesList[activeCategoriesItemNumber] &&
                        categoriesList[activeCategoriesItemNumber][
                          "value"
                        ]?.map((val, Index) => {
                          return (
                            <div className="environment-side-ps">
                              <div className="righ-side-scrolling">
                                <div className="env-heading-tab-ps">
                                  <h6>{val._id}</h6>
                                </div>
                                {val.data.map((sub, subIndex) => {
                                  return (
                                    <div className="d-flex envi-name-ps">
                                      <Checkbox
                                        checked={sub.status}
                                        onChange={() =>
                                          handleSubCategoryChecked(
                                            sub,
                                            Index,
                                            subIndex
                                          )
                                        }
                                      ></Checkbox>
                                      <p> {sub.name} </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </section>
          <section>
            <div className="overflow-sec">
              <div className="risk-leve mt-4">
                <p>
                  {" "}
                  <span>Risk level:</span>
                </p>

                <div className="risk_values_container">
                  <div className="compny_table_child_1">Secure</div>
                  <div className="compny_table_child_2">Low</div>
                  <div className="compny_table_child_3">Medium</div>
                  <div className="compny_table_child_4">High</div>
                </div>
              </div>

              <div className="topp-div mt-2">
                <div className="table-head-sec">
                  <div className="serch-bar-in">
                    <span>
                      <img className="me-2" src={Images.sear} />
                    </span>
                    <input
                      placeholder="Search company by name"
                      style={{
                        color: searchText?.length > 0 ? "black" : "grey",
                        fontSize: 12,
                      }}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        callListingAction(limit, e.target.value);
                      }}
                      value={searchText}
                      type="text"
                    />
                  </div>
                </div>

                <div className="right-side-colm">
                  <Tooltip placement="top" title={text}>
                    <div className="downl-btn">
                      <img
                        onClick={() => callDownloadApi()}
                        src={Images.framedow}
                      />
                    </div>
                  </Tooltip>
                  {/* TABLE COLUMN */}
                  <TableColumn
                    tableHeader={tableColumn}
                    cb={(arr) => setTableHeaderArray([...arr])}
                  />
                  <div
                    className="chart-btn"
                    onClick={() => {
                      navigate("/graphview", {
                        state: {
                          id: record.id,
                          name: companyName,
                          start: networkData.start,
                          end: networkData.end,
                        },
                      });
                    }}
                  >
                    <button style={{ padding: "0px 18px 0px 18px" }}>
                      <img src={Images.chart} />
                      Graph View
                    </button>
                  </div>
                </div>
              </div>

              <div className="coll-table">
                <Table
                  rowKey={(id) => id}
                  onChange={(e, f, g) => onChangeTable(e, f, g)}
                  columns={columns}
                  pagination={false}
                  sortDirections={["ascend", "descend", "ascend"]}
                  dataSource={companies || []}
                />
                
                {companies && (
                  <SupplyPagination
                    pagination={pagination}
                    action={getSupplyChainDataAction}
                    data={{
                      companyId: companyId || record?.id,
                      start: networkData.start,
                      end: networkData.end,
                      limit: limit,
                      esg_categories: getCategoriesArr(), // id of esg_factors, esg_sdgs or esg_pais
                      relation: getRelation(), // customer, supplier, partner
                      tier: "", // should be number between 1, 2 or 3
                      industries: getIndustries(), // industry id example: "HC-BP"
                      locations: getLocation(), // country_name of locations
                      sort: sort_By ? sort_By : null,
                      order: sort_By ? (sortOrder == "ascend" ? 1 : -1) : null,
                      search: searchText,
                      offset: offset,
                    }}
                    limtSet={(e) => setLimit(e)}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div >

      {
        modalVisible ? (
          <IncidentsListModal
            setModalVisible={setModalVisible}
            networkData={{
              ...networkData,
              companies: [companyModalId],
              companyName: [companyModalName],
            }}
          />
        ) : null}
      <ConfirmationModal
        type="modal3"
        title="Remove Supply Chain"
        visible={visible}
        cancel={{
          cancelText: "Cancel",
          onCancel: () => setVisible(false),
        }}
        ok={{
          okText: "Delete",
          onOK: () => {
            dispatch(deleteUploadedDataLoad({ companyId }));
            setVisible(false);

          },
        }}
        itemName={companyName}
        icon={Images.deletError}
        description="This will revert the supply chain data for the selected company to its original state, erasing any changes you've made"
      />
    </div >
  );
};

export default SearchResult;
