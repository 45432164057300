import { Box, SvgIcon } from '@mui/material';

function ShortestPathColorIcon() {
  return (
    <Box
      sx={{
        borderRadius: '4px',
        background: 'linear-gradient(259deg, #04DDBC 1.11%, #0096CD 98.79%)',
        padding: '2px',
      }}
    >
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6.5 9V11.1C6.5 13.3402 6.5 14.4603 6.93597 15.316C7.31947 16.0686 7.93139 16.6805 8.68404 17.064C9.53969 17.5 10.6598 17.5 12.9 17.5H15M5.6 9H7.4C7.96005 9 8.24008 9 8.45399 8.89101C8.64215 8.79513 8.79513 8.64215 8.89101 8.45399C9 8.24008 9 7.96005 9 7.4V5.6C9 5.03995 9 4.75992 8.89101 4.54601C8.79513 4.35785 8.64215 4.20487 8.45399 4.10899C8.24008 4 7.96005 4 7.4 4H5.6C5.03995 4 4.75992 4 4.54601 4.10899C4.35785 4.20487 4.20487 4.35785 4.10899 4.54601C4 4.75992 4 5.03995 4 5.6V7.4C4 7.96005 4 8.24008 4.10899 8.45399C4.20487 8.64215 4.35785 8.79513 4.54601 8.89101C4.75992 9 5.03995 9 5.6 9ZM16.6 20H18.4C18.9601 20 19.2401 20 19.454 19.891C19.6422 19.7951 19.7951 19.6422 19.891 19.454C20 19.2401 20 18.9601 20 18.4V16.6C20 16.0399 20 15.7599 19.891 15.546C19.7951 15.3578 19.6422 15.2049 19.454 15.109C19.2401 15 18.9601 15 18.4 15H16.6C16.0399 15 15.7599 15 15.546 15.109C15.3578 15.2049 15.2049 15.3578 15.109 15.546C15 15.7599 15 16.0399 15 16.6V18.4C15 18.9601 15 19.2401 15.109 19.454C15.2049 19.6422 15.3578 19.7951 15.546 19.891C15.7599 20 16.0399 20 16.6 20Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}
export default ShortestPathColorIcon;
