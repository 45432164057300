import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { GraphObjectProvider } from "./containers/context/MyContext";
import appconstant from "../../../Constants/appConstansts";
import store from "./redux/configuareStore";
import theme from "./styles/theme";
// import { createTheme } from '@mui/material/styles';

import { ErrorBoundary } from "react-error-boundary";
import "./styles/globals.css";
import SyGraphApp from "./SyGraphApp";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { RESET_APP_STATE } from "./redux/reducers";

function Fallback({ error, resetErrorBoundary }) {
  useEffect(() => {
    toast.error(`Something went wrong: ${error.message}`);

    // Set a timer to reset the error boundary
    const timer = setTimeout(() => {
      resetErrorBoundary();
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, [resetErrorBoundary, error]);

  // Don't render a fallback UI
  return null;
}

function SupplyChainGraph() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name, start, end } = location.state;
  // const id = 24937;
  // const name = 'Apple Inc.';
  // const start = '2023-11-12';
  // const end = '2024-02-12';

  return (
    <GraphObjectProvider>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary fallback={Fallback}>
            <SyGraphApp
              onClickBackToSupplyChain={() => {
                navigate(-1);
                store.dispatch({ type: RESET_APP_STATE });
              }}
              baseUrlApi={appconstant.URL.SERVER_URL}
              mainCompany={{ name, id }}
              dates={{
                from: moment(start).toISOString(),
                to: moment(end).toISOString(),
              }}
            />
          </ErrorBoundary>
        </ThemeProvider>
      </ReduxProvider>
    </GraphObjectProvider>
  );
}
export default SupplyChainGraph;
