import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  getConnectingEdgesToShow,
  resetFilterParamatersValue,
} from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../redux/configuareStore";
import { useGraphObject } from "../../context/MyContext";
import {
  setHiddenEdges,
  setHiddenNodes,
} from "../../../redux/GraphExploration/graphSlice";
import FilterIcon from "./FilterIcon";

function ClearFilterBar({ handleCancelFilter }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { graphObject } = useGraphObject();
  const nodes = useAppSelector((state) => state.graph.nodes);
  const border = `1px solid ${theme.palette.gray[50]}`;
  return (
    <Box
      sx={{
        height: "48px",
        backgroundColor: theme.palette.screenBackground.main,
        // borderTop: border,
        borderBottom: border,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingX: "16px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
        <FilterIcon sx={{ fontSize: "small" }} />
        <Typography variant="body">Node Filters</Typography>
      </Box>
      <Button
        sx={{
          ...theme.typography.body,
          color: theme.palette.gray[400],
          ...theme.typography.buttonS,
        }}
        onClick={() => {
          handleCancelFilter(false);
        }}
      >
        Clear Filter
      </Button>
    </Box>
  );
}
export default ClearFilterBar;
