const CompanyLoader = () => {
  return (
    <div
      style={{
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="social-media-loader">
        <div className="loader ">
          <div className="circle" id="circleE"></div>
          <div className="circle" id="circleS"></div>
          <div className="circle" id="circleG"></div>
        </div>
      </div>
    </div>
  );
};
export default CompanyLoader;
