import { Popover } from "antd";
import style from "./style.module.css";
// PortFolio
import portfoSampleCSV from "../../Assets/dummyData/portfoSample.csv";
import portfoSampleXLSX from "../../Assets/dummyData/portfoSample.xlsx";
import portfoSampleTXT from "../../Assets/dummyData/portfoSample.txt";

// Watchlist
import watchListSimpleCSV from "../../Assets/dummyData/watchListSimple.csv";
import watchListSimpleXLSX from "../../Assets/dummyData/watchListSimple.xlsx";

import watchListSimpleTXT from "../../Assets/dummyData/watchListSimple.txt";

// SupplyChain
import supplyChainSimpleCSV from "../../Assets/dummyData/supplyChainSimple.csv";
import supplyChainSimpleXLSX from "../../Assets/dummyData/supplyChainSimple.xlsx";
import supplyChainSimpleTXT from "../../Assets/dummyData/supplyChainSimple.txt";

import { InputChange, downloadJsonFile } from "./utils";
import ImageConst from "../../Themes/ImageConstant";
import { useState } from "react";

const FileUploadPopUp = ({ children, uploadFileHadler, shouldOpen = true, screenType }) => {
    const [isPopup, setIsPopup] = useState(false)
   
    return <Popover
        content={
            <div
                className="popup-save"
                style={{ width: "210px" }}
            >
                <div className="title-addFile">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <input
                            type="file"
                            className="uploadInput"
                            style={{
                                position: "absolute",
                                opacity: "0",
                                width: "40%",
                                cursor: "pointer",
                            }}
                            accept=".csv"
                            id="fileupload"
                            onChange={(e) => InputChange(e, "csv", uploadFileHadler, setIsPopup)}
                        />
                        <div>
                            <img
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    marginRight: "2px",
                                }}
                                src={ImageConst.PlushBlack}
                            />
                        </div>
                        <div
                            style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#3D4353",
                                fontFamily: "inter",
                            }}
                        >
                            CSV
                        </div>
                    </div>
                    <div className={style.downloadSample}>
                        <div>
                            <a
                                href={screenType === "portfolio" ? portfoSampleCSV : screenType === "supplyChain" ? supplyChainSimpleCSV : watchListSimpleCSV}
                                download="custom.csv"
                                rel="noreferrer"
                                className="downloadSample"
                            >
                                Download Sample
                            </a>
                        </div>
                    </div>
                </div>
                <div className="title-addFile">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    // onClick={handleModel}
                    >
                        <div>
                            <input
                                type="file"
                                className="uploadInput"
                                style={{
                                    position: "absolute",
                                    opacity: "0",
                                    width: "40%",
                                }}
                                id="fileupload"
                                accept=".json"
                                onChange={(e) =>
                                    InputChange(e, "json", uploadFileHadler, setIsPopup)
                                }
                            />
                            <img
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    marginRight: "2px",
                                }}
                                src={ImageConst.PlushBlack}
                            />
                        </div>
                        <div
                            style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#3D4353",
                                fontFamily: "inter",
                            }}
                        >
                            JSON
                        </div>
                    </div>
                    <div className={style.downloadSample}>
                        <div
                            className="downloadSample"
                            onClick={() => downloadJsonFile(screenType)}
                        >
                            Download Sample
                        </div>
                    </div>
                </div>
                <div className="title-addFile">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    // onClick={handleModel}
                    >
                        <div>
                            <input
                                type="file"
                                className="uploadInput"
                                style={{
                                    position: "absolute",
                                    opacity: "0",
                                    width: "40%",
                                }}
                                accept=".txt"
                                id="fileupload"
                                onChange={(e) => InputChange(e, "text", uploadFileHadler, setIsPopup)}
                            />
                            <img
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    marginRight: "2px",
                                }}
                                src={ImageConst.PlushBlack}
                            />
                        </div>
                        <div
                            style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#3D4353",
                                fontFamily: "inter",
                            }}
                        >
                            TXT
                        </div>
                    </div>
                    <div className={style.downloadSample}>
                        <div>
                            <a
                                href={screenType == "portfolio" ? portfoSampleTXT : screenType == "supplyChain" ? supplyChainSimpleTXT : watchListSimpleTXT}
                                download="custom.txt"
                                rel="noreferrer"
                                className="downloadSample"
                            >
                                Download Sample{" "}
                            </a>
                        </div>
                    </div>

                </div>
                <div className="title-addFile">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    // onClick={handleModel}
                    >
                        <div>
                            <input
                                type="file"
                                className="uploadInput"
                                style={{
                                    position: "absolute",
                                    opacity: "0",
                                    width: "40%",
                                }}
                                id="fileupload"
                                accept=".xlsx"
                                onChange={(e) =>
                                    InputChange(e, "xlsx", uploadFileHadler, setIsPopup)
                                }
                            />
                            <img
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    marginRight: "2px",
                                }}
                                src={ImageConst.PlushBlack}
                            />
                        </div>
                        <div
                            style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#3D4353",
                                fontFamily: "inter",
                            }}
                        >
                            XLSX
                        </div>
                    </div>
                    <div className={style.downloadSample}>
                        <div>
                            <a
                                href={screenType == "portfolio" ? portfoSampleXLSX : screenType == "supplyChain" ? supplyChainSimpleXLSX : watchListSimpleXLSX}
                                download="custom.xlsx"
                                rel="noreferrer"
                                className="downloadSample"
                            >
                                Download Sample{" "}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        }
        title=""
        trigger="click"
        open={isPopup}
        onOpenChange={() => shouldOpen && setIsPopup(!isPopup)}
        overlayClassName="threeDot-pop"
        placement="bottomRight"
    >
        {children}
        {/* <button
            style={{ marginLeft: 5 }}
            type="button"
            className={style.secondryBtnM}
        >
            <img
                style={{ height: 20, width: 20 }}
                src={ImageConst.uploadIcon}
            ></img>
            {isUploading ? (
                <GlobalSpinner colorx={"#4295CE"} />
            ) : (
                "Upload File"
            )}
        </button> */}
    </Popover>
}

export default FileUploadPopUp