import { checkColor_1 } from "../../Utils";

export const getViewPort = (type) => {
  if (type == "fullMap") {
    return {
      maxBounds: [
        [-180, -90], // Southwest coordinates
        [180, 90],
      ],
    };
  } else {
    return {
      latitude: 41.40338,
      longitude: 2.17403,
      zoom: 0,
      maxBounds: [
        [-180, -90], // Southwest coordinates
        [180, 90],
      ],
    };
  }
};

export const makeFullMap_ESGFactorData = (data) => {
  const resultArray = [];

  const environmentArray = data["Environmental"];
  const governanceArray = data["Governance"];
  const socialArray = data["Social"];

  environmentArray.forEach((element) => {
    const { lat, lng, name, incidents } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "environment",
        color: "#6EBD20",
        name,
        incidents,
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });
  governanceArray.forEach((element) => {
    const { lat, lng, name, incidents } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "governance",
        color: "#653DD4",
        name,
        incidents,
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });
  socialArray.forEach((element) => {
    const { lat, lng, name, incidents } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "social",
        color: "#2191FB",
        name,
        incidents,
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });

  return {
    type: "FullMapDataCollection",
    features: resultArray,
  };
};

export const makeFullMap_severityData = (data) => {
  const resultArray = [];

  const criticalArray = data["Critical"];
  const highArray = data["High"];
  const lowArray = data["Minor"];
  const mediumArray = data["Moderate"];

  criticalArray.forEach((element) => {
    const { lat, lng, name, incidents } = element;

    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "critical",
        color: "#800020",
        name,
        incidents,
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });

  highArray.forEach((element) => {
    const { lat, lng, name, incidents } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "high",
        color: "#FF0000",
        name,
        incidents,
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });

  lowArray.forEach((element) => {
    const { lat, lng, name, incidents } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "low",
        color: "#FFD700",
        name,
        incidents,
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });

  mediumArray.forEach((element) => {
    const { lat, lng, name, incidents } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "medium",
        color: "#FF7518",
        name,
        incidents,
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });

  return {
    type: "FullMapDataCollection",
    features: resultArray,
  };
};

export const make_severityData = (data) => {
  const crticalArray = [];
  const highArray = [];
  const lowArray = [];
  const mediumArray = [];

  data.forEach((item) => {
    let Critical = item.incidentCountBySeverity.Critical;

    let High = item.incidentCountBySeverity.High;
    let Minor = item.incidentCountBySeverity.Minor;
    let Moderate = item.incidentCountBySeverity.Moderate;
    let maxValue = Math.max(Critical, High, Minor, Moderate);
    if (Critical == maxValue) {
      crticalArray.push(item);
    } else if (High == maxValue) {
      highArray.push(item);
    } else if (Minor == maxValue) {
      lowArray.push(item);
    } else if (Moderate == maxValue) {
      mediumArray.push(item);
    }
  });
  return {
    Critical: crticalArray,
    High: highArray,
    Minor: lowArray,
    Moderate: mediumArray,
  };
};

export const makeSocialMediaMapData = (data) => {
  const resultArray = [];
  const positiveArray = data["POSITIVE"];
  const negativeArray = data["NEGATIVE"];
  const neutralArray = data["NEUTRAL"];
  positiveArray.forEach((element) => {
    const { lat, lng } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "positive",
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });
  negativeArray.forEach((element) => {
    const { lat, lng } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "negative",
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });
  neutralArray.forEach((element) => {
    const { lat, lng } = element;
    const pushObject = {
      type: "fullMapData",
      properties: {
        category: "neutral",
      },
      geometry: { type: "Point", coordinates: [lng, lat] },
    };
    resultArray.push(pushObject);
  });
  return {
    type: "FullMapDataCollection",
    features: resultArray,
  };
};

export const getColorMap = (data) => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (value === 1) {
        return checkColor_1[key];
      }
    }
  }
};

// This function only check lengths of severityDataLocationArray
export function areAllArraysEmpty(arr) {
  return arr.every(
    (innerArr) => Array.isArray(innerArr) && innerArr.length === 0
  );
}
