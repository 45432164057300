import React, { useEffect } from "react";
import { ChartCard } from "../../../../Layouts/ChartCard";
import IncidentsNumbers from "./IncidentsNumbers";
import IncidentsNumberBySeverity from "./IncidentsNumberBySeverity";
import { ESG } from "../../../../Utils";
import { dispatch } from "../../../../Utils/dispatchNavigate";
import { PeerCompaniesIncidentRate } from "../../../../Redux/Action/comapanyPageAction";
import { useSelector } from "react-redux";
import Images from  "../../../../Themes/ImageConstant";
const options = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 2,
      dataLabels:{
       enabled:false
      }
    },
  },
  dataLabels:{
    enabled:false
  },
  stroke:{
    show:false
  },
  xaxis: {
    type: "category",
  },
  legend: {
    show: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      opacityTo: 0.6,
      opacityFrom: 1,
      stops: [0, 90, 100],
      shadeIntensity: 0.1,
    },
  },
  grid: {
    borderColor: "#E7E9F0",
    strokeDashArray: 10,
    padding: {
      right: 25,
      left: 15,
    },
  },
  tooltip: {
    enabled: false,
  },
};

const PeerCompanyIncidentGraph = (networkData) => {
  useEffect(() => {
    networkData && dispatch(PeerCompaniesIncidentRate(networkData));
  }, [networkData.dateChange]);

  const { peerCompaniesRate } = useSelector(
    (state) => state?.companyPageReducer
  );
  const Category = (color) => {
    const option = JSON.parse(JSON.stringify(options));
    let obj = peerCompaniesRate?.map((item) => item.name.split(" ")[0]);
    option.xaxis["categories"] = obj;
    option.colors = [...color];
    return option;
  };

  return (
    <section className="left-text">
      <ChartCard title={"Peer Companies’ Incidents"} className={"mt-32"} showTimeBarOnTop={false}>
        <div className="Sentiment-grah">
          <IncidentsNumbers
            data={peerCompaniesRate}
            options={Category(["#6EBD20", "#2191FB", "#653DD4"])}
          />
          <IncidentsNumberBySeverity
            data={peerCompaniesRate}
            options={Category(["#800020","#DC4040", "#F28C28", "#FFD700"])}
          />
        </div>
      </ChartCard>
    </section>
  );
};

export default PeerCompanyIncidentGraph;
